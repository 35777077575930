import { useEffect, useRef, useState } from "react";
import type { FrontMatterUser } from "../../../../../types";
import { isFrontMatterUser } from "../../../../../utils";
import { useFocused, useFrontMatterValue } from "../../hooks";
import { FieldContentContainer } from "../shared";
import { UserSelector, type UserSelectorHandle } from "./UserSelector";
import { UserSuggestionsMenu } from "./UserSuggestionsMenu";

type Props = Readonly<{
  onChange: (value: FrontMatterUser | undefined) => void;
  readOnly?: boolean;
  id: string;
}>;

/**
 * Front-matter cell that is used to select a single user (like for instance an incident commander)
 * - When in edit mode, uses an input to filter user suggestions
 */
export function FieldUser({ id, onChange, readOnly }: Props) {
  const user = useFrontMatterValue(id, isFrontMatterUser);
  const focused = useFocused(id);
  const [initialFilterText, setInitialFilterText] = useState<string>();
  const ref = useRef<HTMLDivElement>(null);
  const selectorRef = useRef<UserSelectorHandle>(null);

  useEffect(() => {
    if (initialFilterText === undefined) {
      selectorRef.current?.focus();
    }
  }, [initialFilterText]);
  return (
    <FieldContentContainer ref={ref} data-testid="user-selector">
      <UserSelector
        ref={selectorRef}
        focused={focused}
        hideClearButton={focused === false || user === undefined}
        readOnly={readOnly}
        onClear={() => onChange(undefined)}
        user={user}
        openContextMenu={setInitialFilterText}
        placeholder={focused && !readOnly ? "Select a user" : "Empty"}
      />
      {initialFilterText !== undefined && (
        <UserSuggestionsMenu
          filterText={initialFilterText}
          element={ref.current}
          onActivate={(user) => {
            onChange(user);
          }}
          onClose={() => {
            setInitialFilterText(undefined);
          }}
        />
      )}
    </FieldContentContainer>
  );
}
