import type { FormEvent } from "react";
import { css, styled } from "styled-components";

import { Select } from "@fiberplane/ui";
import { Container } from "../Container";
import { Label } from "../Label";
import { TextInput } from "../TextInput";

/** @deprecated */
export const ModalForm = styled.form<{ onSubmit: (event: FormEvent) => void }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

/** @deprecated */
export const ModalLabel = styled.label(
  ({ theme }) => css`
    font: ${theme.font.body.md.regular};
    color: ${theme.color.fg.default};
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
);

/** @deprecated */
export const ModalLabelText = styled(Label).attrs({ as: "span" })`
  display: inline;
`;

/** @deprecated */
export const ModalSelectInput = styled(Select)`
  display: flex;
  width: 100%;
`;

/** @deprecated */
export const ModalTextInput = styled(TextInput)`
  display: flex;
  width: 100%;
`;

/** @deprecated */
export const ModalRadioGroup = styled(Container)`
  flex-wrap: wrap;
  gap: 8px 32px;
`;

/** @deprecated */
export const ModalErrorHint = styled(ModalLabelText)`
  color: ${({ theme }) => theme.colorDanger500};
`;
