import { useHandler } from "@fiberplane/hooks";

import { Icon } from "@fiberplane/ui";
import type { CellFocus, TimeRange, Timestamp } from "../../../types";
import { cellFocusesAreEqual, withField } from "../../../utils";
import {
  TimeRangeMenuButton,
  TimeRangeSelectorMenu,
} from "../../TimeRangeSelectorMenu";
import {
  KeyValueCellContentKey,
  KeyValueCellContentRow,
  KeyValueCellContentValueContainer,
} from "../KeyValueCellContent";
import { TimeRangeForm, getTimeRangeFocusField } from "../TimeRangeForm";

type Props = {
  from: Timestamp;
  to: Timestamp;
  focus: CellFocus;
  readOnly: boolean;
  onChange(timeRange: TimeRange): void;
  onFocusChange(focus: CellFocus): void;
  label?: React.ReactNode;
  resetToGlobalTimeRange?: () => void;
};

export function TimeRangeSelectorCellContent(props: Props) {
  const {
    focus,
    from,
    to,
    readOnly,
    onChange,
    onFocusChange,
    label,
    resetToGlobalTimeRange,
  } = props;

  const field = getTimeRangeFocusField(focus);

  const updateFocus = useHandler((newFocus: CellFocus) => {
    // Make sure the new focus is really different, before
    // calling `onFocusChange`:
    const currentFocus = withField(focus, field ?? "from");

    // FIXME: support for offset / fields for global time range cells
    // For now we assume that if the focus type is collapsed that
    // one of the inputs has focus
    if (currentFocus.type === "collapsed") {
      // We're assuming that you're at position 0 if no position is specified
      currentFocus.offset ??= 0;
    }

    const focusWithField = withField(newFocus, field ?? "from");
    if (!cellFocusesAreEqual(focusWithField, currentFocus)) {
      onFocusChange(focusWithField);
    }
  });

  return (
    <KeyValueCellContentRow>
      <KeyValueCellContentKey>
        <Icon iconType="time_duotone" />
        {label}
      </KeyValueCellContentKey>
      <KeyValueCellContentValueContainer>
        <TimeRangeForm
          from={from}
          to={to}
          focus={focus}
          onChange={onChange}
          onFocusChange={onFocusChange}
          readOnly={readOnly}
        />
        {
          // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
          !readOnly && !resetToGlobalTimeRange && (
            <TimeRangeSelectorMenu
              onChange={onChange}
              placement="bottom-end"
              value={{ from, to }}
            >
              {({ opened }) => (
                <TimeRangeMenuButton
                  active={opened}
                  onFocusChange={updateFocus}
                />
              )}
            </TimeRangeSelectorMenu>
          )
        }
      </KeyValueCellContentValueContainer>
    </KeyValueCellContentRow>
  );
}
