import type { ConfigField as Field, ProviderConfig } from "../../../../types";
import type { ValidationStatus } from "../ValidationStatusIcon";
import { CheckboxField } from "./CheckboxField";
import { IntegerField } from "./IntegerField";
import { SelectField } from "./SelectField";
import { TextField } from "./TextField";

type Props = {
  autoFocus?: boolean;
  config?: ProviderConfig;
  field: Field;
  onChange: (value: unknown) => void;
  onKeyDown: (event: React.KeyboardEvent) => void;
  validationStatus: ValidationStatus;
};

export function ConfigField({
  autoFocus,
  config,
  field,
  onChange,
  onKeyDown,
  validationStatus,
}: Props): JSX.Element {
  const value: unknown = config[field.name];

  const fieldProps = {
    autoFocus,
    onChange,
    onKeyDown,
    validationStatus,
    value,
  };

  switch (field.type) {
    case "checkbox":
      return <CheckboxField {...field} {...fieldProps} />;
    case "integer":
      return <IntegerField {...field} {...fieldProps} />;
    case "select":
      return <SelectField {...field} {...fieldProps} />;
    case "text":
      return <TextField {...field} {...fieldProps} />;
  }
}
