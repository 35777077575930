import { noop } from "../../../utils";
import {
  type MenuKeyboardListenerParams,
  createKeyboardListener,
} from "../Menu";

type FilterMenuKeyboardListenerParams = MenuKeyboardListenerParams & {
  filterText?: string;
  noResultsText?: string;
};

export const createFilterKeyboardListener = (
  params: FilterMenuKeyboardListenerParams,
) => {
  const { filterText, items, onClose: close } = params;

  const defaultListener = createKeyboardListener(params);

  return (event: KeyboardEvent) => {
    switch (event.code) {
      case "ArrowRight": {
        // Make sure the event can still bubble up.
        event.stopPropagation = noop;
        return defaultListener(event);
      }

      case "Backspace": {
        if (!filterText) {
          return defaultListener(event);
        }

        // Don't interfere so the keypress can update the filter text.
        break;
      }

      case "Enter":
      case "Tab": {
        if (items.length > 0) {
          return defaultListener(event);
        }

        // Close context menu when no results were available to confirm.
        close({ reason: "focus_lost" });
        break;
      }

      default:
        return defaultListener(event);
    }
  };
};
