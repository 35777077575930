import { useHandler } from "@fiberplane/hooks";
import { type RefObject, useEffect, useMemo, useRef } from "react";
import { styled } from "styled-components";

import { useClosePopupHandlers } from "../../../hooks";
import { setZeroTimeout } from "../../../utils";
import { MenuContainer } from "./MenuContainer";
import { type CloseReason, MenuContext } from "./MenuContext";
import { flattenItems } from "./flattenItems";
import {
  type MenuKeyboardListenerParams,
  createKeyboardListener,
} from "./keyboardListener";
import type { Selection } from "./types";
import { useSelection } from "./useSelection";

export type MenuProps = {
  /**
   * The menu component can render any type of React components, but is
   * specifically optimized for rendering menu items (any component type listed
   * under `MENU_CHILD_TYPES`).
   */
  children: React.ReactNode;

  className?: string;

  /**
   * The menu component installs a listener to allow for keyboard navigation.
   *
   * By default, it uses its internal `createKeyboardListener()` for creating
   * the listener, but if desired, you may specify a custom factory for the
   * listener.
   *
   * If you do implement your own factory, you likely want to import
   * `createKeyboardListener()` and use the default listener as a fallback.
   */
  createKeyboardListener?: (
    params: MenuKeyboardListenerParams,
  ) => (event: KeyboardEvent) => void;

  /**
   * By default, the menu (items) grab focus. You may want to disable this for
   * menu items that are displayed while the user is still typing in the RTE
   * (like the slash menu).
   */
  grabsFocus?: boolean;

  /**
    If `true`, the menu can also be closed by pressing the left arrow.
   */
  isSubmenu?: boolean;

  onClick?: (event: React.MouseEvent) => void;

  /**
   * Called when the menu is closed.
   */
  onClose: (info: { reason: CloseReason }) => void;

  /**
   * Called when the selection changes.
   */
  onSelectionChange?: (selection: Selection) => void;

  preventDefaultSelection?: boolean;

  overridePopupRef?: RefObject<HTMLElement>;
};

export function Menu(props: MenuProps): JSX.Element | null {
  const {
    children,
    className,
    onClick,
    onClose: close,
    overridePopupRef,
  } = props;

  // While `children` are rendered directly, keyboard navigation is a little
  // bit more tricky, because some components may contain nested items that we
  // should be able to navigate through. For example, `MenuItemGroup`.
  // For this reason, we extract `items` to contain the flattened menu items for
  // keyboard navigation.
  const items = useMemo(() => flattenItems(children), [children]);
  const selectableItems = items.filter((item) => !item.props.disabled);
  const [selection, setSelection] = useSelection(selectableItems, props);

  // Any click outside of the menu should close it:
  const baseRef = useRef<HTMLDivElement>(null);

  const menuRef = overridePopupRef || baseRef;
  const onClose = useHandler((options) => setZeroTimeout(() => close(options)));
  useClosePopupHandlers({ onClose, popupRef: menuRef });

  const keyboardListener = useMemo(() => {
    const factory = props.createKeyboardListener ?? createKeyboardListener;
    return factory({
      ...props,
      items,
      menuRef,
      selection,
      setSelection,
    });
  }, [props, items, selection, setSelection, menuRef]);

  useEffect(() => {
    document.addEventListener("keydown", keyboardListener, true);
    return () =>
      document.removeEventListener("keydown", keyboardListener, true);
  }, [keyboardListener]);

  return (
    <StyledMenuContainer
      className={className}
      data-menu
      onClick={onClick}
      ref={baseRef}
    >
      <MenuContext.Provider value={{ close, selection, setSelection }}>
        {children}
      </MenuContext.Provider>
    </StyledMenuContainer>
  );
}

const StyledMenuContainer = styled(MenuContainer)`
  max-height: 365px;
  width: 236px;
`;
