import { AttachedPopup, cancelEvent } from "@fiberplane/ui";
import type { VirtualElement } from "@popperjs/core";
import { useEffect, useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import useWindowSize from "react-use/lib/useWindowSize";
import { createSelector } from "reselect";
import { css, styled } from "styled-components";

import { toggleFormatting } from "../../../../actions";
import {
  makeCellDraftsSelector,
  makeCellSelector,
  makeThreadItemsSelector,
  selectContextMenu,
  selectShowOwnCursor,
} from "../../../../selectors";
import { useActiveNotebookDispatch } from "../../../../store";
import type { ActiveFormatting, CellFocus } from "../../../../types";
import {
  getCursorOffset,
  getRichCellTextUsingExternalSources,
  isMac,
} from "../../../../utils";
import { ButtonBar } from "../../../UI";
import type { ExtendedFormatting } from "../types";
import { getCursorRectForCellFieldAtOffset } from "../utils";
import { ToolbarButton } from "./ToolbarButton";

type ToolbarProps = {
  cellId: string;
  container: HTMLElement;
  activeFormatting: ActiveFormatting;
  focus: CellFocus;
  field?: string;
  text: string;
  formatting: ExtendedFormatting | undefined;
  onUrlEditorRequested: (event: React.MouseEvent | React.KeyboardEvent) => void;
};

export function Toolbar({
  activeFormatting,
  cellId,
  container,
  focus,
  field,
  text,
  onUrlEditorRequested,
}: ToolbarProps) {
  const dispatch = useActiveNotebookDispatch();

  const [virtualElement, setVirtualElement] = useState<VirtualElement | null>(
    null,
  );
  const windowSize = useWindowSize();

  const { allowBold, contextMenu, formatting, showToolbar } = useToolbar(
    cellId,
    field,
  );

  const focusOffset = getCursorOffset(focus, field);

  // biome-ignore lint/correctness/useExhaustiveDependencies: intentional
  useEffect(() => {
    if (focusOffset === undefined) {
      setVirtualElement(null);
      return;
    }

    setVirtualElement({
      getBoundingClientRect: () => {
        const cursorRect = getCursorRectForCellFieldAtOffset(
          cellId,
          field,
          focusOffset,
        );

        const { x, y, width, height } = cursorRect || { x: 0, y: 0 };
        return new DOMRect(x, y, width, height);
      },
      contextElement: container,
    });
  }, [cellId, field, container, focusOffset, formatting, text, windowSize]);

  if (
    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
    !showToolbar ||
    !virtualElement ||
    contextMenu?.menuType === "cell_actions"
  ) {
    return null;
  }

  return (
    <AttachedPopup element={virtualElement} placement="top" offset={[0, 8]}>
      <Container>
        {allowBold && (
          <ToolbarButton
            tooltipText="Toggle Bold"
            shortcut={`${isMac ? "cmd" : "ctrl"}+B`}
            active={activeFormatting.bold}
            onMouseDown={(event) => {
              cancelEvent(event);
              dispatch(toggleFormatting({ type: "start_bold" }));
            }}
            iconType="text_bolder"
          />
        )}
        <ToolbarButton
          shortcut={`${isMac ? "cmd" : "ctrl"}+I`}
          tooltipText="Toggle Italic"
          active={activeFormatting.italics}
          onMouseDown={(event) => {
            cancelEvent(event);
            dispatch(toggleFormatting({ type: "start_italics" }));
          }}
          iconType="text_italic"
        />
        <ToolbarButton
          active={activeFormatting.strikethrough}
          data-tooltip-placement="top"
          tooltipText="Toggle Strikethrough"
          onMouseDown={(event) => {
            cancelEvent(event);
            dispatch(toggleFormatting({ type: "start_strikethrough" }));
          }}
          iconType="text_strikethrough"
        />
        <ToolbarButton
          data-tooltip-placement="top"
          tooltipText="Toggle Underline"
          shortcut={`${isMac ? "cmd" : "ctrl"}+U`}
          active={activeFormatting.underline}
          onMouseDown={(event) => {
            cancelEvent(event);
            dispatch(toggleFormatting({ type: "start_underline" }));
          }}
          iconType="text_underline"
        />
        <ToolbarButton
          active={activeFormatting.highlight}
          data-tooltip-placement="top"
          tooltipText="Toggle Highlight"
          onMouseDown={(event) => {
            cancelEvent(event);
            dispatch(toggleFormatting({ type: "start_highlight" }));
          }}
          iconType="highlighter_circle"
        />
        <ToolbarButton
          active={activeFormatting.code}
          data-tooltip-placement="top"
          tooltipText="Toggle Code Block"
          onMouseDown={(event) => {
            cancelEvent(event);
            dispatch(toggleFormatting({ type: "start_code" }));
          }}
          iconType="code"
        />
        <ToolbarButton
          data-tooltip-placement="top"
          tooltipText="Add/Edit Link"
          shortcut={`${isMac ? "cmd" : "ctrl"}+K`}
          active={activeFormatting.link != null}
          onMouseDown={onUrlEditorRequested}
          iconType="link"
        />
      </Container>
    </AttachedPopup>
  );
}

function useToolbar(cellId: string, field: string | undefined) {
  const toolbarSelector = useMemo(
    () => makeToolbarSelector(cellId, field),
    [cellId, field],
  );
  return useSelector(toolbarSelector);
}

const makeToolbarSelector = (cellId: string, field: string | undefined) => {
  const selectCell = makeCellSelector(cellId);
  const selectCellDrafts = makeCellDraftsSelector(cellId);
  const selectThreadItems = makeThreadItemsSelector(cellId);
  return createSelector(
    [
      selectCell,
      selectCellDrafts,
      selectContextMenu,
      selectShowOwnCursor,
      selectThreadItems,
    ],
    (cell, drafts, contextMenu, showOwnCursor, threadItems) => ({
      allowBold: cell?.type !== "heading",
      contextMenu,
      formatting:
        cell &&
        getRichCellTextUsingExternalSources(cell, drafts, threadItems, field)
          ?.formatting,
      showToolbar: showOwnCursor,
    }),
    { memoizeOptions: { resultEqualityCheck: shallowEqual } },
  );
};

const Container = styled(ButtonBar)(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    background: ${theme.color.bg.elevated.default};
    color: ${theme.color.fg.default};
    border: 1px solid ${theme.color.border.muted};
    border-radius: ${theme.radius.default};
    padding: 4px 10px;
    gap: 4px;
    box-shadow: ${theme.effect.shadow.m};
  `,
);
