import { useHandler } from "@fiberplane/hooks";
import { type FormEvent, useContext, useState } from "react";
import { styled } from "styled-components";

import { Button, cancelEvent } from "@fiberplane/ui";
import type { EditLinkModalProps } from "../../types";
import {
  ButtonBar,
  Modal,
  ModalContext,
  ModalForm,
  ModalLabel,
  ModalLabelText,
  ModalTextInput,
} from "../UI";

export function EditLinkModal(props: EditLinkModalProps) {
  const { requestClose } = useContext(ModalContext);
  const [url, setUrl] = useState(props.initialUrl);

  const onConfirm = useHandler((event: FormEvent) => {
    cancelEvent(event);
    props.onConfirm(url);
  });

  return (
    <StyledModal
      title={props.isNewLink ? "Insert link" : "Edit link"}
      icon="link"
    >
      <ModalForm onSubmit={onConfirm}>
        <ModalLabel>
          <ModalLabelText>URL</ModalLabelText>
          <ModalTextInput
            autoFocus
            placeholder="https://example.com"
            onChange={(event) => setUrl(event.target.value.trim())}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                props.onConfirm(url);
              }
            }}
            value={url}
            type="url"
          />
        </ModalLabel>
      </ModalForm>

      <ButtonBar>
        <Button onClick={requestClose} buttonStyle="secondary">
          Cancel
        </Button>
        {props.isNewLink ? null : (
          <Button buttonStyle="danger" onClick={props.onDelete}>
            Delete link
          </Button>
        )}
        <Button onClick={onConfirm}>
          {props.isNewLink ? "Create link" : "Update link"}
        </Button>
      </ButtonBar>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  max-width: 400px;
`;
