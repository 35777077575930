import { Select } from "@fiberplane/ui";
import type { ComponentProps } from "react";
import {
  type FieldPath,
  type FieldValues,
  useController,
} from "react-hook-form";
import { FieldDescription } from "../styled";
import type { FiberFormFieldProps } from "../types";
import { FiberFormFieldContainer } from "./FiberFormFieldContainer";

/**
 * A component for rendering a select form field using Zod schema validation.
 * @param {FiberFormFieldProps<Values, FieldName>} props - Props for the FiberFormSelectField component.
 * @param {ComponentProps<typeof Select>["options"]} props.options - Options for the select field.
 * @returns {JSX.Element} The FiberFormSelectField component.
 * @template Values - The type of form field values.
 * @template FieldName - The type of field name.
 */
export function FiberFormSelectField<
  Values extends FieldValues,
  FieldName extends FieldPath<Values>,
>({
  control,
  description,
  label,
  name,
  rules,
  options,
  ...props
}: FiberFormFieldProps<Values, FieldName> &
  Omit<ComponentProps<typeof Select>, "onChange">): JSX.Element {
  const { field, fieldState } = useController({ control, name, rules });

  const hasError = Boolean(fieldState.error);

  return (
    <FiberFormFieldContainer label={label} name={name} fieldState={fieldState}>
      <Select
        options={options}
        {...props}
        {...field}
        data-invalid={hasError}
        aria-invalid={hasError}
      />
      {description && <FieldDescription>{description}</FieldDescription>}
    </FiberFormFieldContainer>
  );
}
