import { Icon, IconButton, cancelEvent } from "@fiberplane/ui";
import { motion } from "framer-motion";
import type { MouseEventHandler } from "react";
import { styled } from "styled-components";

import format from "date-fns/format";
// biome-ignore lint/style/useNamingConvention: use the name of the package
import parseISO from "date-fns/parseISO";
import { useAppDispatch } from "../../../store";
import { showEventDeleteConfirmation } from "../../../thunks";
import type { ApiEvent } from "../../../types";
import { convertApiLabelToClientLabel, noop } from "../../../utils";
import { MOTION_PROPS } from "./const";
import {
  EventGrid,
  GridAreaActions,
  GridAreaLabels,
  GridAreaMeta,
  StyledLabelEditor,
} from "./style";

export const EventRow = ({
  event,
  onClick,
  readOnly,
}: {
  event: ApiEvent;
  onClick: MouseEventHandler;
  readOnly: boolean;
}) => {
  const dispatch = useAppDispatch();
  return (
    <EventContainer key={event.id} onClick={onClick}>
      <EventGrid>
        <GridAreaMeta>
          <TitleContainer>{event.title}</TitleContainer>
          <TimeContainer>
            <Icon iconType="clock" height={12} width={12} />
            {format(parseISO(event.occurrenceTime), "HH:mm")}
          </TimeContainer>
        </GridAreaMeta>
        <GridAreaLabels>
          <StyledLabelEditor
            type="event_modal"
            editor={undefined}
            labels={convertApiLabelToClientLabel(event.labels)}
            onBlur={noop}
            newLabelButtonLabel="Filter on label"
          />
        </GridAreaLabels>
        {!readOnly && (
          <GridAreaActions>
            <IconButton
              iconType="trash"
              onClick={(clickEvent) => {
                cancelEvent(clickEvent);
                dispatch(
                  showEventDeleteConfirmation({
                    eventId: event.id,
                    eventTitle: event.title,
                  }),
                );
              }}
            />
          </GridAreaActions>
        )}
      </EventGrid>
    </EventContainer>
  );
};

const TitleContainer = styled.div`
  font: ${(p) => p.theme.font.body.md.regular};
  color: ${(p) => p.theme.color.fg.default};
  margin-bottom: 10px;
`;

const TimeContainer = styled.div`
  color: ${(p) => p.theme.color.fg.muted};
  font: ${(p) => p.theme.font.body.sm.regular};
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`;

export const EventContainer = styled(motion.div).attrs(MOTION_PROPS)`
  border-bottom: 1px solid ${(p) => p.theme.color.border.muted};
  cursor: pointer;
  transition: background 0.075s ease-in-out;

  &:hover {
    background: ${(p) => p.theme.color.bg.subtle};
  }

  ${GridAreaActions} {
    transition: opacity 0.13s ease-out;
  }

  @media (min-width: 1024px) {
    ${GridAreaActions} {
      opacity: 0;
    }

    &:hover ${GridAreaActions} {
      opacity: 1;
    }
  }
`;
