import { push } from "redux-first-history";

import {
  CLEAR_AUTHENTICATION,
  HIDE_ALL_MODALS,
  STORE_AUTH_TOKEN,
  setActiveWorkspaceName,
} from "../actions";
import { api, userApi, workspacesApi } from "../api";
import { AUTHENTICATE_URL, ROUTES } from "../constants";
import { UnauthenticatedError } from "../errors";
import {
  selectAuthentication,
  selectHasActiveNotebook,
  selectIsAuthenticated,
  selectNotebookId,
  selectNotebookVisibility,
} from "../selectors";
import { Api, Auth } from "../services";
import type { Thunk } from "../store";
import type { OidcProvider } from "../types";
import { getLastActiveWorkspaceName, hasFeature } from "../utils";
import {
  authenticateCourier,
  disconnect,
  subscribeToWorkspace,
} from "./courierThunks";
import { loadNotebookById } from "./notebookThunks";

export const authenticate =
  (
    provider: OidcProvider,
    {
      returnUrl,
      cliRedirectPort,
    }: { returnUrl?: string; cliRedirectPort?: string } = {},
  ): Thunk =>
  (_dispatch, getState) => {
    // if cliRedirectPort is set, we may be already authed, but we still need
    // to trigger auth for fp
    if (!cliRedirectPort && selectIsAuthenticated(getState())) {
      return;
    }

    let url = `${AUTHENTICATE_URL}/${provider}`;

    // redirect port param is used by fp CLI, and in that case we don't need the redirect param
    if (cliRedirectPort) {
      url += `?cli_redirect_port=${cliRedirectPort}`;
    } else if (returnUrl) {
      url += `?redirect=${encodeURIComponent(returnUrl)}`;
    }

    document.location.assign(url);
  };

export const fetchAuthUser =
  // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: FIXME
  (): Thunk<Promise<void>> => async (dispatch, getState) => {
    const { token } = selectAuthentication(getState());
    if (!token) {
      throw new UnauthenticatedError("No token set");
    }

    try {
      const [{ data: authUser }, { data: workspaces = [] }] = await Promise.all(
        [
          dispatch(userApi.endpoints.getOwnProfile.initiate()),
          dispatch(workspacesApi.endpoints.listWorkspaces.initiate()),
        ],
      );

      if (!authUser) {
        throw new UnauthenticatedError("No user found");
      }

      const localWorkspaceName = getLastActiveWorkspaceName();
      const activeWorkspaceName =
        localWorkspaceName &&
        workspaces.some(({ name }) => name === localWorkspaceName)
          ? localWorkspaceName
          : authUser.defaultWorkspaceName;

      dispatch(setActiveWorkspaceName(activeWorkspaceName));

      if (hasFeature("courier-workspaces")) {
        const workspaceId = workspaces.find(
          ({ name }) => name === activeWorkspaceName,
        )?.id;

        if (workspaceId) {
          dispatch(subscribeToWorkspace(workspaceId));
        }
      }
    } catch (error) {
      if (error instanceof UnauthenticatedError) {
        // No worries, this is expected to happen when our session expired.
      } else {
        throw error;
      }
    }
  };

export const setAuthToken =
  (token: string): Thunk =>
  (dispatch) => {
    Api.authenticate(token);

    dispatch({ type: STORE_AUTH_TOKEN, payload: token });

    dispatch(authenticateCourier());
  };

/**
 * @param unescapedRedirect - The (optional) redirect URL that will be put
 * inside a query param on the signin page, so that the user gets redirected
 * properly once they sign in
 */
export const signOut =
  (unescapedRedirect?: string): Thunk =>
  async (dispatch, getState) => {
    const state = getState();
    const hasActiveNotebook = selectHasActiveNotebook(state);
    const isPublic = selectNotebookVisibility(state) === "public";
    const notebookId = selectNotebookId(state);

    await Auth.clearToken("api");

    Api.signOut();

    dispatch({ type: CLEAR_AUTHENTICATION });
    dispatch(disconnect());

    dispatch(userApi.endpoints.logout.initiate());
    dispatch(api.util.resetApiState());
    dispatch({ type: HIDE_ALL_MODALS });

    if (hasActiveNotebook && isPublic) {
      // Reload the notebook without auth
      dispatch(loadNotebookById(notebookId));
      return;
    }

    if (unescapedRedirect) {
      const redirect = encodeURIComponent(unescapedRedirect);
      dispatch(push(`${ROUTES.SignIn}?redirect=${redirect}`));
    } else {
      dispatch(push(ROUTES.SignIn));
    }
  };

export const storeAuthToken =
  (token: string): Thunk =>
  async (dispatch) => {
    dispatch(setAuthToken(token));

    await Auth.setToken("api", token);
  };
