import {
  type AppAction,
  FETCH_TRIGGERS_FULFILLED,
  FETCH_TRIGGERS_PENDING,
  FETCH_TRIGGERS_REJECTED,
} from "../actions";
import type { TriggerState } from "../state";

export const initialState: TriggerState = {
  loading: false,
  triggers: [],
  error: undefined,
};

export function triggersReducer(
  state = initialState,
  action: AppAction,
): TriggerState {
  switch (action.type) {
    case FETCH_TRIGGERS_PENDING:
      return {
        ...state,
        loading: true,
      };

    case FETCH_TRIGGERS_FULFILLED:
      return {
        ...state,
        loading: false,
        triggers: action.payload,
        error: undefined,
      };

    case FETCH_TRIGGERS_REJECTED:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
}
