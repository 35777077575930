import type {
  ModalTypeProperties,
  TooltipAnchor,
  TooltipContent,
  TooltipOptions,
} from "../types";

export type ContextMenuType = "auto_suggest_labels";

export type ContextMenuInfo = {
  readonly element: HTMLElement | null;
  readonly menuType: ContextMenuType;
};

/**
 * Information related to the currently active drag.
 */
export type DragInfo = {
  /**
   * ID of the cell being dragged, if applicable.
   */
  readonly draggedCellId?: string;

  /**
   * Indicates whether we support dropping things in the dragged format.
   */
  readonly isFormatSupported: boolean;

  /**
   * The target where we would drop if the active drag were released now.
   */
  readonly target: DropTarget;
};

/**
 * Information related to the drop position for the active drag.
 */
export type DropTarget =
  | { readonly type: "after_cell"; readonly cellId: string }
  | { readonly type: "before_cell"; readonly cellId: string }
  // NOTE: This is effectively the same as `after_cell` where the ID of the last
  //       cell is specified. The main advantage is so that `<EmptyCellArea />`
  //       doesn't need to know what the last cell is, and wouldn't have any
  //       issues should the notebook somehow end up without cells (which
  //       *should* never happen).
  | { readonly type: "end_of_notebook" };

export type ModalState = {
  modal: ModalTypeProperties;
  onAfterClose?: () => void;
  onRequestClose: () => void;
};

export type TooltipState = {
  // We have to use factories for these state values, because tools like Storybook will
  // trip over attempting to serialize these otherwise :(
  anchor: () => TooltipAnchor;
  content: () => TooltipContent;
  options: TooltipOptions;
};

export type UiState = {
  /**
   * The state of the active context menu, if there is any.
   */
  readonly activeContextMenu: ContextMenuInfo | null;

  /**
   * The currently active drag, or `null` if there is none.
   */
  readonly activeDrag: DragInfo | null;

  /**
   * State of the active tooltip, if there is any.
   */
  readonly activeTooltip: TooltipState | null;

  /**
   * Whether the engage menu is open
   */
  readonly engageMenuIsOpen: boolean;

  /**
   * Indicates what kind of action the user last performed within the notebook.
   * This is currently limited to a distinction between "typing" and
   * "scrolling".
   *
   * Whenever the user is typing, it is most important that we keep their cursor
   * within the viewport, and ideally keep the cursor position stable in the
   * presence of activity from users (if we can).
   *
   * But when the user is scrolled away from the cursor, we don't care about the
   * cursor position anymore. At most, we would like to keep the cell at the top
   * of the viewport at a stable position if we can.
   */
  readonly lastUserAction: UserActionKind;

  /**
   * Whether the notebook is currently focused.
   *
   * Note the notebook can be focused even though the window is not (see
   * `isWindowFocused`). This simply means the notebook will grab the focus
   * again when the focus is returned to the window.
   */
  readonly isNotebookFocused: boolean;

  /**
   * Whether the window is currently focused.
   */
  readonly isWindowFocused: boolean;

  /**
   * Map of all the modals shown.
   */
  readonly modals: ReadonlyMap<string, ModalState>;

  /**
   * Whether the copilot side menu is open.
   */
  readonly sideMenuIsOpen: boolean;

  /**
   * Determines the display type for the views overview page
   */
  readonly viewsDisplayType: "grid" | "list";
};

export type UserActionKind = "typing" | "scrolling";
