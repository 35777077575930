import { shallowEqual } from "react-redux";

import type { AppAction } from "../actions";
import { api } from "../api";
import { integrationsSlice, pagerDutySlice, profileSlice } from "../slices";
import type { RootState } from "../state";
import type { SideEffectDescriptor } from "../types";
import { authenticationReducer } from "./authenticationReducer";
import { avatarsReducer } from "./avatarsReducer";
import { commandMenuReducer } from "./commandMenuReducer";
import { courierReducer } from "./courierReducer";
import { dataSourcesReducer } from "./dataSourcesReducer";
import { discussionsReducer } from "./discussionsReducer";
import { eventsReducer } from "./eventsReducer";
import { labelsReducer } from "./labelsReducer";
import { notebooksReducer } from "./notebooksReducer";
import { notificationsReducer } from "./notificationsReducer";
import { presenceReducer } from "./presenceReducer";
import { proxiesReducer } from "./proxiesReducer";
import { routerReducer } from "./routerReducer";
import { triggersReducer } from "./triggersReducer";
import { uiReducer } from "./uiReducer";
import { viewsReducer } from "./viewsReducer";
import { workspaceReducer } from "./workspaceReducer";

// We maintain a list of side effects outside of the reducer.
// This is so we can dispatch them after the store has been updated and before
// the next store update. We keep collecting them across dispatches until our
// event listener has had a chance to consume them, at which point it will call
// `clearSideEffects()` and we start over.
let scheduledSideEffects: Array<SideEffectDescriptor> = [];

// Used by the middleware for side effects to clear the list once consumed.
export function consumeScheduledSideEffects(): Array<SideEffectDescriptor> {
  const sideEffects = scheduledSideEffects;
  scheduledSideEffects = [];
  return sideEffects;
}

export function rootReducer(
  state: RootState | undefined,
  action: AppAction,
): RootState {
  const { state: courier, sideEffects: courierSideEffects } = courierReducer(
    state?.courier,
    action,
  );
  if (courierSideEffects && courierSideEffects.length > 0) {
    scheduledSideEffects.push(...courierSideEffects);
  }

  const { state: presence, sideEffects: presenceSideEffects } = presenceReducer(
    state?.presence,
    action,
  );
  if (presenceSideEffects && presenceSideEffects.length > 0) {
    scheduledSideEffects.push(...presenceSideEffects);
  }

  const { state: notebooks, sideEffects: notebooksSideEffects } =
    notebooksReducer(state?.notebooks, action);
  if (notebooksSideEffects && notebooksSideEffects.length > 0) {
    scheduledSideEffects.push(...notebooksSideEffects);
  }

  const newState: RootState = {
    [api.reducerPath]: api.reducer(state?.[api.reducerPath], action),
    [integrationsSlice.name]: integrationsSlice.reducer(
      state?.[integrationsSlice.name],
      action,
    ),
    [pagerDutySlice.name]: pagerDutySlice.reducer(
      state?.[pagerDutySlice.name],
      action,
    ),
    [profileSlice.name]: profileSlice.reducer(
      state?.[profileSlice.name],
      action,
    ),
    presence,
    authentication: authenticationReducer(state?.authentication, action),
    avatars: avatarsReducer(state?.avatars, action),
    commandMenu: commandMenuReducer(state?.commandMenu, action),
    courier,
    dataSources: dataSourcesReducer(state?.dataSources, action),
    discussions: discussionsReducer(state?.discussions, action),
    events: eventsReducer(state?.events, action),
    labels: labelsReducer(state?.labels, action),
    notebooks,
    notifications: notificationsReducer(state?.notifications, action),
    proxies: proxiesReducer(state?.proxies, action),
    router: routerReducer(state?.router, action),
    sideEffects: scheduledSideEffects,
    triggers: triggersReducer(state?.triggers, action),
    ui: uiReducer(state, action),
    views: viewsReducer(state, action),
    workspaces: workspaceReducer(state?.workspaces, action),
  };

  return state && shallowEqual(state, newState) ? state : newState;
}
