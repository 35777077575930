import { useHandler } from "@fiberplane/hooks";
import { useEffectOnce } from "react-use";
import { css, styled } from "styled-components";

import { Icon } from "@fiberplane/ui";
import { withActiveNotebook } from "../../../actions";
import { useProvider } from "../../../hooks";
import { DataSources } from "../../../services";
import { dispatch } from "../../../store";
import { LinkStyle } from "../../../theme";
import { invokeProviderCell, showModal } from "../../../thunks";
import type { ProviderCell } from "../../../types";
import { type Intent, formatProviderType } from "../../../utils";
import { Text } from "../../UI";
import { CellContent } from "../CellContent";
import { FieldContainer } from "../codeStyling";
import { ErrorContainer } from "./ErrorContainer";
import { QueryForm } from "./QueryForm";
import { useIsLive, useQueryDataMonitor, useWhenLive } from "./hooks";

export type ProviderCellInnerContentProps = {
  cell: { type: "provider" } & ProviderCell;
  intent: Intent;
  readOnly: boolean;
};

export function ProviderCellInnerContent({
  cell,
  intent,
  readOnly,
}: ProviderCellInnerContentProps) {
  const { id, output, queryData } = cell;

  const loadProviderResult = useProvider(intent);
  const { providerType, queryType } = intent;

  const setLive = useHandler((live: boolean) => {
    dispatch(
      withActiveNotebook({
        type: "set_cell_live",
        payload: { cellId: id, live },
      }),
    );

    // When disabling live mode, do a reload of the cell to make sure everyone's
    // seeing the same data again
    if (!live) {
      dispatch(invokeProviderCell(id));
    }
  });

  useWhenLive({
    queryData,
    timeout: 30 * 1000,
    onInterval: useHandler(() => {
      dispatch(invokeProviderCell(id));
    }),
  });

  const isLive = useIsLive(queryData);
  useQueryDataMonitor({
    queryData,
    fields: ["query"],
    onChanged: useHandler(() => {
      if (isLive) {
        setLive(false);
      }
    }),
  });

  useEffectOnce(() => {
    // in case of timeline cell we want to invoke provider immediately
    // because the new event button is in inner cell
    if (queryType === "event-timeline") {
      dispatch(invokeProviderCell(id));
    }
  });

  if (!loadProviderResult) {
    return (
      <ProviderContainer>
        <Placeholder>
          <Icon iconType="database" />
          <Text>Loading {formatProviderType(providerType)} provider.</Text>
        </Placeholder>
      </ProviderContainer>
    );
  }

  return (
    <ProviderContainer>
      {loadProviderResult instanceof Error ? (
        <ErrorMessage error={loadProviderResult} providerType={providerType} />
      ) : (
        <QueryForm
          cellId={id}
          providerType={providerType}
          queryData={queryData}
          readOnly={readOnly}
          schema={loadProviderResult.schema}
          setLive={setLive}
        />
      )}
      {output && output.length > 0 && (
        <OutputContainer>
          {output.map((outputCell) => (
            <CellContent
              cell={outputCell}
              key={outputCell.id}
              readOnly={
                outputCell.type === "timeline"
                  ? readOnly
                  : outputCell.type !== "graph"
              }
            />
          ))}
        </OutputContainer>
      )}
    </ProviderContainer>
  );
}

type ErrorMessageProps = {
  error: Error;
  providerType: string;
};

function ErrorMessage({ error, providerType }: ErrorMessageProps): JSX.Element {
  const onClick = () =>
    dispatch(showModal({ type: "directAccess", providerType }));

  return (
    <ErrorContainer>
      Cannot execute new queries:
      {error instanceof DataSources.NoDataSourceError ? (
        <Link onClick={onClick}>
          Configure {formatProviderType(providerType)} data source
        </Link>
      ) : (
        ` ${error}`
      )}
    </ErrorContainer>
  );
}

const Placeholder = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const Link = styled(Text)`
  ${LinkStyle}
`;

const OutputContainer = styled(FieldContainer)`
  flex-direction: column;
  /* Flex container, so set the min-width in order to prevent overflow/resize issues */
  min-width: 0;
`;

export const ProviderContainer = styled.div(
  ({ theme }) => css`
    display: grid;
    border: 1px solid ${theme.color.border.default};
    border-radius: ${theme.radius.rounded};
    gap: 12px;
    padding: ${theme.height.xx.small};
    position: relative;
    background: ${theme.color.notebook.cell.bg.default};
  `,
);
