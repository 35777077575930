import { memo } from "react";

import { AttachedPopup, type AttachedPopupProps } from "@fiberplane/ui";
import { FilterMenu, type FilterMenuProps } from "./FilterMenu";

export type PositionedFilterMenuProps = FilterMenuProps &
  Pick<AttachedPopupProps, "element" | "offset" | "placement">;

/**
 * A filter menu that is positioned relative to a reference element.
 *
 * The reference element can be either a DOM element, or a virtual element.
 * See also: https://popper.js.org/docs/v2/virtual-elements/
 */
export const PositionedFilterMenu = memo(function PositionedFilterMenu({
  element,
  offset = [0, 0],
  placement = "bottom",
  ...menuProps
}: PositionedFilterMenuProps) {
  return (
    <AttachedPopup element={element} offset={offset} placement={placement}>
      <FilterMenu {...menuProps} />
    </AttachedPopup>
  );
});
