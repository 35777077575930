import type React from "react";
import { styled } from "styled-components";

import { convertShortcutKey } from "../../../utils";
import { Key, ModalLabelText } from "../../UI";

type Props = {
  children: React.ReactNode;
  shortcut: string;
};

export function Shortcut(props: Props): JSX.Element {
  const keys = props.shortcut.split("+").map((key) => convertShortcutKey(key));
  return (
    <StyledShortcut>
      <StyledDescription>{props.children}</StyledDescription>

      <StyledShortcutKeys>
        {keys.map((key) => (
          <Key key={key}>{key}</Key>
        ))}
      </StyledShortcutKeys>
    </StyledShortcut>
  );
}

const StyledShortcut = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  gap: 8px;
`;

const StyledDescription = styled(ModalLabelText)`
  flex: 1;
  white-space: nowrap;
`;

const StyledShortcutKeys = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 0;
`;
