import { useHandler } from "@fiberplane/hooks";
import { forwardRef, useImperativeHandle, useReducer } from "react";
import useAsync from "react-use/lib/useAsync";

import { DataSources } from "../../../services";
import type {
  ConfigField as ConfigFieldType,
  ConfigSchema,
} from "../../../types";
import { ErrorScreen } from "../../ErrorBoundary";
import type { ConfigEditorHandle, ConfigEditorProps } from "./ConfigEditor";
import { ConfigField } from "./ConfigField";

export const ConfigEditorFromSchema = forwardRef(
  function ConfigEditorFromSchema(
    { providerType, ...props }: ConfigEditorProps,
    ref: React.Ref<ConfigEditorHandle>,
  ) {
    const schemaResult = useAsync(() => {
      const provider = DataSources.getNewProviderOfType(providerType);
      return provider.getConfigSchema();
    });
    if (schemaResult.error) {
      return <ErrorScreen error={schemaResult.error} />;
    }

    if (!schemaResult.value) {
      return null;
    }

    return (
      <ConfigEditorWithSchema
        {...props}
        forwardedRef={ref}
        schema={schemaResult.value}
      />
    );
  },
);

type Props = Omit<ConfigEditorProps, "providerType"> & {
  forwardedRef: React.Ref<ConfigEditorHandle>;
  schema: ConfigSchema;
};

function ConfigEditorWithSchema({
  autoFocus,
  config: initialConfig,
  forwardedRef,
  onSubmit,
  onValidationStatusChange,
  schema,
  validationStatus,
}: Props): JSX.Element {
  const [config, dispatch] = useReducer(configReducer, initialConfig || {});

  const validateAndSubmit = useHandler(() => {
    const valid = !schema.some(
      (field) => field.required && !hasValue(config, field),
    );
    if (valid) {
      onSubmit(config);
    } else {
      onValidationStatusChange("invalid");
    }
  });
  useImperativeHandle(forwardedRef, () => ({ validateAndSubmit }));

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      validateAndSubmit();
    }
  };

  return (
    <>
      {schema.map((field, index) => (
        <ConfigField
          autoFocus={autoFocus && index === 0}
          config={config}
          field={field}
          key={field.name}
          onChange={(value) =>
            dispatch({ type: "update", payload: { key: field.name, value } })
          }
          onKeyDown={onKeyDown}
          validationStatus={validationStatus}
        />
      ))}
    </>
  );
}

type ConfigAction = {
  type: "update";
  payload: { key: string; value: unknown };
};

function configReducer(state: object, action: ConfigAction): object {
  const { key, value } = action.payload;
  return { ...state, [key]: value };
}

function hasValue(config: object, field: ConfigFieldType): boolean {
  // @ts-ignore
  const value: unknown = config[field.name];

  switch (field.type) {
    case "checkbox":
      return value === true;
    case "integer":
      return typeof value === "number";
    case "select":
      return typeof value === "string";
    case "text":
      return typeof value === "string" && value.length > 0;
  }
}
