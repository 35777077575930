import { useHandler } from "@fiberplane/hooks";
import { memo } from "react";

import type { NotebookFocus } from "../../../../../types";
import { isString } from "../../../../../utils";
import { useFrontMatterValue } from "../../hooks";
import { FrontMatterEditor } from "../FrontMatterEditor";

type Props = Readonly<{
  id: string;
  onChange: (value: string, options?: { focus?: NotebookFocus }) => void;
  readOnly?: boolean;
}>;

export const FieldString = memo(function FieldString({
  id,
  onChange,
  readOnly,
}: Props) {
  const value = useFrontMatterValue(id, isString);
  const handleChange = useHandler((value: string, focus: NotebookFocus) => {
    onChange(value, { focus });
  });

  return (
    <div>
      <FrontMatterEditor
        id={id}
        value={value ?? ""}
        onChange={handleChange}
        readOnly={readOnly}
        placeholder="Empty"
      />
    </div>
  );
});
