import { Icon } from "@fiberplane/ui";
import { type HTMLAttributes, memo } from "react";
import { css, styled } from "styled-components";

type CellIconProps = {
  insertAfter?: boolean;
} & HTMLAttributes<HTMLButtonElement>;

export const CellAddIcon = memo(function CellAddIcon({
  insertAfter,
  ...attributes
}: CellIconProps): JSX.Element {
  return (
    <CellAddIconButton insertAfter={insertAfter} {...attributes}>
      <Icon iconType="plus" width="16" height="16" />
    </CellAddIconButton>
  );
});

export const CellAddIconButton = styled.button<{ insertAfter?: boolean }>(
  ({ insertAfter, theme }) => css`
    width: 16px;
    height: 16px;
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
    color: ${theme.colorBase600};
    border-radius: ${theme.borderRadius500};
    overflow: hidden;
    opacity: 0;
    pointer-events: none;

    &:hover {
      background: ${theme.colorBaseAlpha100};
    }

    ${
      insertAfter &&
      css`
      top: unset;
      bottom: -16px;
    `
    }
  `,
);
