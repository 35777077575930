import React from "react";

import { noop } from "../../../utils";
import type { Selection } from "./types";

export type CloseReason = "close_requested" | "focus_lost" | "item_activated";

export type MenuContextValue = {
  close: (info: { reason: CloseReason }) => void;
  selection: Selection;
  setSelection: (selection: Selection) => void;
};

export const MenuContext = React.createContext<MenuContextValue>({
  close: noop,
  selection: { itemId: undefined, selectedBy: "mouse" },
  setSelection: noop,
});
