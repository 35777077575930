/**
 * Partitions an array and creates two new arrays from it.
 *
 * The first array (`truthy_items`) contains all the items for which the
 * predicate returned `true`, and the second array (`falsy_items`) the items
 * for which the predicate returned `false`.
 */
export function partition<T>(
  array: Array<T>,
  predicate: (item: T) => boolean,
): [truthy_items: Array<T>, falsy_items: Array<T>] {
  const truthy = [];
  const falsy = [];
  for (const item of array) {
    if (predicate(item)) {
      truthy.push(item);
    } else {
      falsy.push(item);
    }
  }

  return [truthy, falsy];
}
