import { type KeyboardEvent, useRef } from "react";

type KeyboardHandler<Element> = (event: KeyboardEvent<Element>) => void;

type KeyboardHandlers<Element> = {
  onKeyDown: KeyboardHandler<Element>;
  onKeyUp: KeyboardHandler<Element>;
};

/**
 * Convenience hook for defining keyboard handlers.
 *
 * If propagation is stopped or the default prevented on the "keydown" event,
 * the same is automatically applied to the "keyup" event to prevent common
 * issues.
 */
export function useKeyboardHandlers<Element = HTMLElement>(
  handler: KeyboardHandler<Element> | KeyboardHandlers<Element>,
): KeyboardHandlers<Element> {
  const eventRef = useRef({ preventDefault: false, stopPropagation: false });

  const onKeyDown = typeof handler === "function" ? handler : handler.onKeyDown;
  const onKeyUp = typeof handler === "function" ? undefined : handler.onKeyUp;

  return {
    onKeyDown: (event: KeyboardEvent<Element>) => {
      eventRef.current.preventDefault = false;
      eventRef.current.stopPropagation = false;

      onKeyDown({
        ...event,
        preventDefault: () => {
          event.preventDefault();
          eventRef.current.preventDefault = true;
        },
        stopPropagation: () => {
          event.stopPropagation();
          eventRef.current.stopPropagation = true;
        },
      });
    },
    onKeyUp: (event: KeyboardEvent<Element>) => {
      if (eventRef.current.stopPropagation) {
        event.stopPropagation();
        eventRef.current.stopPropagation = false;
      }

      if (eventRef.current.preventDefault) {
        event.preventDefault();
        eventRef.current.preventDefault = false;
        return;
      }

      onKeyUp?.(event);
    },
  };
}
