import { Input } from "@fiberplane/ui";
import { type Control, useController } from "react-hook-form";
import { css, styled } from "styled-components";

import type { Webhook, WebhookCategory } from "../../../../types";
import { Label, ModalDescription, ModalLabel } from "../../../UI";

type EventFieldOption = {
  value: WebhookCategory;
  label: string;
  description: string;
};

type EventsFieldProps = {
  options: Array<EventFieldOption>;
  control: Control<Webhook>;
};

export function EventsField({ options, control }: EventsFieldProps) {
  const {
    field: { onChange, value = [] },
  } = useController({ control, name: "events" });

  const addValue = (webhookCategory: WebhookCategory) =>
    onChange([...value, webhookCategory]);

  const removeValue = (webhookCategory: WebhookCategory) =>
    onChange(value.filter((current) => current !== webhookCategory));

  return (
    <Container>
      <ModalLabel>Webhook triggers</ModalLabel>
      <ModalDescription>
        Select the type of notebook changes which will trigger this webhook
      </ModalDescription>

      <Options>
        {options.map((option) => {
          const checked = value.includes(option.value);

          return (
            <OptionContainer key={option.value}>
              <Input
                type="checkbox"
                id={option.value}
                checked={checked}
                onChange={() =>
                  (checked ? removeValue : addValue)(option.value)
                }
              />
              <Title htmlFor={option.value}>{option.label}</Title>
              <Description htmlFor={option.value}>
                {option.description}
              </Description>
            </OptionContainer>
          );
        })}
      </Options>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Options = styled.div`
  margin-top: 20px;
  display: grid;
  gap: 16px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const OptionContainer = styled.div`
  display: grid;
  grid:
    "checkbox title" auto
    "checkbox description" auto
    / auto 1fr;
  gap: 4px 8px;
`;

const Title = styled(Label)(
  ({ theme }) => css`
    grid-area: title;
    color: ${theme.colorForeground};
    font: ${theme.fontStudioStrongRegularShortHand};
    letter-spacing: ${theme.fontStudioStrongRegularLetterSpacing};
    cursor: pointer;
    width: max-content;
  `,
);

const Description = styled(Title)(
  ({ theme }) => css`
    grid-area: description;
    color: ${theme.colorBase600};
    font: ${theme.fontStudioBodyCopySmallShortHand};
    letter-spacing: ${theme.fontStudioBodyCopySmallLetterSpacing};
  `,
);
