import { replaceText } from ".";
import type {
  AnnotationWithOffset,
  CellWithEntityFormatting,
  NotebookAction,
  User,
} from "../types";
import { charCount, charIndex, charSlice } from "../utils";

type InsertMentionPayload = {
  cell: CellWithEntityFormatting;
  content: string;
  field?: string;
  user: User;
  initialFocusOffset: number;
  currentFocusOffset: number;
};

export const insertMention = ({
  cell,
  content,
  field,
  user,
  initialFocusOffset,
  currentFocusOffset,
}: InsertMentionPayload): NotebookAction => {
  const { id: userId, name } = user;

  // Make sure the operation starts before the `@`:
  const offset = initialFocusOffset - 1;

  const newAnnotation: AnnotationWithOffset = {
    offset: 0,
    type: "mention",
    name,
    userId,
  };

  let newText = `@${name} `;
  if (offset > 0 && !isWhiteSpace(content, offset - 1)) {
    newText = ` ${newText}`;
    newAnnotation.offset += 1;
  }

  if (
    currentFocusOffset < charCount(content) &&
    !isWhiteSpace(content, currentFocusOffset)
  ) {
    newText = `${newText} `;
  }

  return replaceText({
    cellId: cell.id,
    offset,
    newText,
    newFormatting: [newAnnotation],
    oldText: charSlice(content, offset, currentFocusOffset),
    field,
  });
};

function isWhiteSpace(text: string, offset: number): boolean {
  const charCode = text.charCodeAt(charIndex(text, offset));
  return charCode === 10 || charCode === 32;
}
