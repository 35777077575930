import type { ActiveExtendedFormatting } from "./types";

export const CURSOR_WIDTH = 2; // px
export const LINE_HEIGHT = 28; // px

export const DASH_CHAR_CODE = 45;
export const NEWLINE_CHAR_CODE = 10;
export const SPACE_CHAR_CODE = 32;

export const ZERO_WIDTH_SPACE = "\u200B";

export const defaultFormatting: ActiveExtendedFormatting = {
  bold: false,
  code: false,
  highlight: false,
  italics: false,
  link: undefined,
  selection: [],
  strikethrough: false,
  underline: false,
  timestamp: undefined,
};
