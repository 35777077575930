import { decode } from "@msgpack/msgpack";

import { Api } from "../..";
import { selectActiveWorkspaceIdOrThrow } from "../../../selectors";
import { getState } from "../../../store";
import type {
  Blob,
  Cell,
  DataSource,
  Provider,
  ProviderError,
  ProviderRequest,
  Result,
  SupportedQueryType,
} from "../../../types";
import { unwrap } from "../../../utils";
import { getProviderForDataSource } from "../dataSourceRegistry";
import { NoProviderError } from "../errors";
import {
  createLegacyLogRequest,
  createLogOutputCells,
  getSupportedQueryTypesForLegacyLogProvider,
  interpretLegacyLogResponse,
} from "../legacy";

const proxyProvider: Provider = {
  createCells,
  getConfigSchema,
  getSupportedQueryTypes,
  invoke,
  extractData,
};

export function createProxyProvider(): Provider {
  return proxyProvider;
}

function createCells(
  queryType: string,
  response: Blob,
  dataSource: DataSource,
): Promise<Array<Cell>> {
  const { name, protocolVersion, proxyName } = dataSource;
  if (protocolVersion === 1) {
    return Promise.resolve(createLogOutputCells());
  }

  if (!proxyName) {
    throw new Error("Proxy provider invoked for non-proxied data-source");
  }

  try {
    // We try to delegate `createCells()` to a local WASM provider to avoid
    // another roundtrip. Note this creates a potential for version mismatches
    // between providers loaded in the remote proxy and those loaded in Studio,
    // but this is something we can solve once we have our own provider
    // registry.
    const provider = getProviderForDataSource({
      ...dataSource,
      proxyName: undefined,
    });

    return provider.createCells(queryType, response, dataSource);
  } catch (error) {
    if (error instanceof NoProviderError) {
      // If there is no local provider of this type, we need to forward the
      // request to the proxy.
      const workspaceId = selectActiveWorkspaceIdOrThrow(getState());
      return Api.relayCreateCells(workspaceId, proxyName, name, {
        queryType,
        response,
      }).then((binaryResponse) => {
        /* TODO: proper error handling
         * Note that the Error type is bogus here, as we call unwrap on the result immediately after.*/
        const result = decode(binaryResponse) as Result<Array<Cell>, Error>;
        return unwrap(result);
      });
    } else {
      throw error;
    }
  }
}

function getConfigSchema(): never {
  throw new Error("Cannot configure proxy provider locally");
}

function extractData(
  response: Blob,
  mimeType: string,
  query: string | null,
  dataSource: DataSource,
): Promise<Result<Blob, ProviderError>> {
  const { name, protocolVersion, proxyName } = dataSource;
  if (protocolVersion === 1) {
    throw new Error("Cannot invoke extract_data on v1 providers");
  }

  if (!proxyName) {
    throw new Error("Proxy provider invoked for non-proxied data-source");
  }

  try {
    // We try to delegate `extractData()` to a local WASM provider to avoid
    // another roundtrip. Note this creates a potential for version mismatches
    // between providers loaded in the remote proxy and those loaded in Studio,
    // but this is something we can solve once we have our own provider
    // registry.
    const provider = getProviderForDataSource({
      ...dataSource,
      proxyName: undefined,
    });

    return provider.extractData(response, mimeType, query, dataSource);
  } catch (error) {
    if (error instanceof NoProviderError) {
      // If there is no local provider of this type, we need to forward the
      // request to the proxy.
      const workspaceId = selectActiveWorkspaceIdOrThrow(getState());
      return Api.relayExtractData(workspaceId, proxyName, name, {
        response,
        mimeType,
        query: query ?? undefined,
      }).then(
        (binaryResponse) =>
          decode(binaryResponse) as Result<Blob, ProviderError>,
      );
    } else {
      throw error;
    }
  }
}

function getSupportedQueryTypes(
  dataSource: DataSource,
): Promise<Array<SupportedQueryType>> {
  if (dataSource.protocolVersion === 1) {
    return Promise.resolve(
      getSupportedQueryTypesForLegacyLogProvider(dataSource.providerType),
    );
  }

  try {
    // We try to delegate `getSupportedQueryTypes()` to a local WASM provider to
    // avoid another roundtrip. Note this creates a potential for version
    // mismatches between providers loaded in the remote proxy and those loaded
    // in Studio, but this is something we can solve once we have our own
    // provider registry.
    const provider = getProviderForDataSource({
      ...dataSource,
      proxyName: undefined,
    });

    return provider.getSupportedQueryTypes(dataSource);
  } catch (error) {
    if (error instanceof NoProviderError) {
      const { name, proxyName } = dataSource;
      if (!proxyName) {
        throw error; // Rethrow if there's no proxy to forward to.
      }

      // Forward the request to the proxy.
      const workspaceId = selectActiveWorkspaceIdOrThrow(getState());
      return Api.relayGetSupportedQueryTypes(workspaceId, proxyName, name).then(
        (binaryResponse) => decode(binaryResponse) as Array<SupportedQueryType>,
      );
    } else {
      throw error;
    }
  }
}

async function invoke(
  request: ProviderRequest,
  dataSource: DataSource,
): Promise<Result<Blob, ProviderError>> {
  const { name: dataSourceName, protocolVersion, proxyName } = dataSource;
  if (!proxyName) {
    throw new Error("Proxy provider invoked for non-proxied data-source");
  }

  const workspaceId = selectActiveWorkspaceIdOrThrow(getState());

  if (protocolVersion === 1) {
    return invokeLegacy(workspaceId, proxyName, dataSourceName, request);
  }

  const response = await Api.invokeRelay(
    workspaceId,
    proxyName,
    dataSourceName,
    request,
  );

  return decode(response) as Result<Blob, ProviderError>;
}

async function invokeLegacy(
  workspaceId: string,
  proxyName: string,
  dataSourceName: string,
  request: ProviderRequest,
) {
  const response = await Api.invokeLegacyRelay(
    workspaceId,
    proxyName,
    dataSourceName,
    createLegacyLogRequest(request),
  );

  return interpretLegacyLogResponse(response);
}
