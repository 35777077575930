import type { IconVisualType } from "../../../../types";
import BinIcon from "./Bin.svg";
import CheckIcon from "./Check.svg";
import FiberplaneAppLogoIcon from "./FiberplaneAppLogo.svg";
import InfoIcon from "./Info.svg";
import WarningOctagonIcon from "./WarningOctagon.svg";

/**
 * Get the icon based on the visual type.
 */
export function getIcon(iconVisualType: IconVisualType) {
  switch (iconVisualType) {
    case "success":
      return CheckIcon;
    case "danger":
      return BinIcon;
    case "default":
      return FiberplaneAppLogoIcon;
    case "warning":
      return WarningOctagonIcon;
    default:
      return InfoIcon;
  }
}
