import type { AppAction, CourierAction, PresenceAction } from "../actions";
import { FiberKit, Sentry } from "../services";
import type { PresenceState } from "../state";
import type { StateWithSideEffects } from "../types";

export const initialState: PresenceState = {
  notebookPresence: {},
};

export function presenceReducer(
  state = initialState,
  action: AppAction,
): StateWithSideEffects<PresenceState> {
  if (action.type.startsWith("courier/")) {
    return handleCourierAction(state, action as CourierAction);
  }

  return { state };
}

function handleCourierAction(
  state: PresenceState,
  courierAction: CourierAction,
): StateWithSideEffects<PresenceState> {
  const action: PresenceAction = { type: "courier", payload: courierAction };

  try {
    const start = Date.now();

    const result = FiberKit.presenceReducer(action);

    const timeSpent = Date.now() - start;
    if (timeSpent > 100) {
      Sentry.captureWarning(
        `Presence reducer took ${timeSpent}ms`,
        Sentry.hasSensitiveDataCollection() ? { action } : undefined,
      );
    }

    return result;
  } catch (error) {
    Sentry.captureError("FiberKit error while processing courier action", {
      action: Sentry.hasSensitiveDataCollection() ? action : undefined,
      error,
    });
  }

  return { state };
}
