import {
  applyOwnOperation,
  showError,
  withActiveNotebook,
  withNotebook,
} from "../actions";
import { selectActiveNotebook } from "../selectors";
import type { NotebookState } from "../state";
import type { Thunk } from "../store";
import type { Cell, TimeRange } from "../types";
import {
  formatTimeRange,
  getQueryField,
  getTimeRangeFieldName,
  isSameTimeRange,
  parseIntent,
} from "../utils";
import { getProviderForIntent } from "./dataSourcesThunks";
import { focusCell } from "./editorThunks";
import { addNotification } from "./notificationsThunks";
import { invokeProviderCell, updateQueryField } from "./providerThunks";

export const updateNotebookTimeRange =
  (timeRange: TimeRange): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const notebookState = selectActiveNotebook(state);
    const oldTimeRange = notebookState.timeRange;

    if (isSameTimeRange(timeRange, oldTimeRange)) {
      return;
    }

    dispatch(
      withActiveNotebook(
        applyOwnOperation({
          type: "update_notebook_time_range",
          oldTimeRange,
          timeRange,
        }),
      ),
    );

    dispatch(
      invokeProviderCellsUsingNotebookTimeRange(notebookState, oldTimeRange),
    );
  };

const invokeProviderCellsUsingNotebookTimeRange =
  (notebookState: NotebookState, oldTimeRange: TimeRange): Thunk =>
  (dispatch) => {
    for (const cellId of notebookState.cellIds) {
      const cell = notebookState.cellsById[cellId]?.cell;
      if (cell) {
        dispatch(
          invokeProviderCellUsingNotebookTimeRange(cell, oldTimeRange),
        ).catch((error) => {
          dispatch(
            withNotebook(
              notebookState.id,
              showError(
                {
                  type: "other",
                  message: `Could not invoke provider cell: ${error}`,
                },
                { cellId },
              ),
            ),
          );
        });
      }
    }
  };

const invokeProviderCellUsingNotebookTimeRange =
  (cell: Cell, oldTimeRange: TimeRange): Thunk<Promise<void>> =>
  async (dispatch) => {
    if (cell.type !== "provider") {
      return;
    }

    const cellId = cell.id;
    const intent = parseIntent(cell.intent);
    const { schema } = await dispatch(getProviderForIntent(intent));
    const fieldName = getTimeRangeFieldName(schema);
    if (fieldName && !getQueryField(cell.queryData, fieldName)) {
      if (cell.readOnly) {
        dispatch(
          updateQueryField(cellId, fieldName, formatTimeRange(oldTimeRange)),
        );
        dispatch(showLockedCellNotification(cellId));
      } else {
        dispatch(invokeProviderCell(cellId));
      }
    }
  };

export const showLockedCellNotification =
  (cellId: string): Thunk =>
  (dispatch) => {
    dispatch(
      addNotification({
        type: "info",
        disableAutoHide: true,
        title:
          "A locked cell has been updated to stay on a specific time range",
        action: (dispatch) => dispatch(focusCell({ cellId, highlight: true })),
      }),
    );
  };
