import type { CellFocus, CellFocusPosition } from "../types";

export type SelectionDirection = HTMLInputElement["selectionDirection"];

export function getFocusFromInput(
  input: HTMLInputElement | HTMLTextAreaElement,
  field?: string,
): CellFocus {
  const { selectionStart, selectionEnd, selectionDirection } = input;
  if (selectionStart === null || selectionEnd === null) {
    return { type: "none" };
  }

  if (selectionStart === selectionEnd) {
    return { type: "collapsed", offset: selectionStart, field };
  }

  const start = { offset: selectionStart };
  const end = { offset: selectionEnd };
  return {
    type: "selection",
    start,
    end,
    focus: getFocusForDirection(start, end, selectionDirection),
  };
}

export function getFocusForDirection(
  start: CellFocusPosition,
  end: CellFocusPosition,
  direction: SelectionDirection,
): CellFocusPosition | undefined {
  switch (direction) {
    case "backward":
      return start;
    case "forward":
      return end;
  }
}
