import type { OidcProvider } from "../../types";
import {
  getRequest,
  parseJsonResponse,
  postJsonRequestDeprecated,
} from "./utils";

export type Connection = { provider: OidcProvider; linked: boolean };

export const listConnections = ({ signal }: { signal: AbortSignal }) =>
  getRequest<Array<Connection>>("/api/profile/connections", parseJsonResponse, {
    signal,
  });

export const linkupProvider = ({
  provider,
  redirect,
  signal,
}: {
  provider: OidcProvider;
  redirect?: string;
  signal?: AbortSignal;
}) => {
  // TODO (FP-3532): Fix encoding
  const searchParams = new URLSearchParams();
  if (redirect) {
    searchParams.set("redirect", redirect);
  }

  return postJsonRequestDeprecated<{ location: string }>(
    `/api/oidc/linkup/${provider}?${searchParams.toString()}`,
    null,
    async (response) => {
      const { location } = await parseJsonResponse<{ location: string }>(
        response,
      );

      return {
        location: decodeURIComponent(location),
      };
    },
    { signal },
  );
};

export const unlinkProvider = ({
  provider,
  signal,
}: {
  provider: OidcProvider;
  signal?: AbortSignal;
}) =>
  postJsonRequestDeprecated<void>(
    `/api/oidc/unlink/${provider}`,
    null,
    parseJsonResponse,
    { signal },
  );
