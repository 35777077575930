import notebookPreview from "url:./images/notebook-preview-getting-started.jpeg";
import { Button } from "@fiberplane/ui";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { ROUTES } from "../../../constants";
import { selectActiveWorkspaceNameOrThrow } from "../../../selectors";
import { track } from "../../../utils";
import {
  GradientModal,
  GradientModalBodyRegularText,
  GradientModalHeading,
  ModalButtonBar,
  ModalContext,
  NotebookLabel,
} from "../../UI";

export function ExploreNotebooksModal() {
  const workspaceName = useSelector(selectActiveWorkspaceNameOrThrow);

  const { requestClose } = useContext(ModalContext);

  useEffect(() => {
    track("onboarding | explore notebooks", { action: "open" });

    return () => {
      track("onboarding | explore notebooks", { action: "close" });
    };
  }, []);

  return (
    <GradientModal
      gradient="1"
      illustration={<FanCards />}
      footer={
        <ModalButtonBar>
          <LinkButton
            as={Link}
            buttonStyle="secondary"
            onClick={requestClose}
            state={{
              fromOnboarding: true,
            }}
            to={generatePath(ROUTES.Notebooks, {
              workspaceName,
            })}
          >
            Explore Notebooks
          </LinkButton>
        </ModalButtonBar>
      }
    >
      <GradientModalHeading>Explore Onboarding Notebooks</GradientModalHeading>
      <GradientModalBodyRegularText>
        <b>
          Look for Notebooks with the label{" "}
          <NotebookLabel label={{ key: "onboarding", value: "" }} />
        </b>{" "}
        to help familiarize you with the tools at your disposal within
        Fiberplane Studio. The onboarding notebooks will help introduce concepts
        like Data Sources and Templates, as well as give you sample data to play
        with.
      </GradientModalBodyRegularText>
    </GradientModal>
  );
}

const LinkButton = styled(Button)``;

const FanCards = () => (
  <CardContainer>
    <Card zIndex={3} rotate={0}>
      <CardInfo>
        <img src={notebookPreview} alt="An example onboarding notebook" />
      </CardInfo>
    </Card>
    <Card zIndex={2} rotate={-3}>
      <CardInfo />
    </Card>
    <Card zIndex={1} rotate={-6}>
      <CardInfo />
    </Card>
  </CardContainer>
);

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 250px;
  height: 160px;
  margin-top: 80px;
  overflow-y: clip;
`;

const Card = styled.div<{ rotate: number; zIndex: number }>(
  ({ theme, zIndex, rotate }) => css`
    box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.08), inset 0px 2px 4px #fafafa;
    border-radius: ${theme.borderRadius400};
    position: absolute;
    width: 100%;
    height: 200%;
    top: 0;
    background-color: ${theme.colorBackground};
    z-index: ${zIndex};
    transform: rotate(${rotate}deg);
    transform-origin: center center;
  `,
);

const CardInfo = styled.div`
  position: absolute;
  z-index: 4;

  img {
    width: 100%;
    border-radius: 4px;
  }
`;
