import { css, styled } from "styled-components";

import { NotebookLabel } from "../UI";

export const FadedNotebookLabel = styled(NotebookLabel)<{ faded: boolean }>`
  ${({ faded }) =>
    faded &&
    css`
      opacity: 0.25;
    `}
`;

export const StyledListItem = styled.li`
  margin: 0;
  padding: 0;
  display: flex;
`;
