import { useHandler } from "@fiberplane/hooks";
import { Button, Icon, cancelEvent } from "@fiberplane/ui";
import { AnimatePresence } from "framer-motion";
import {
  type PropsWithoutRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { replace } from "redux-first-history";
import { styled } from "styled-components";

import { useListRecentNotebooksQuery } from "../../../api";
import { MEDIUM_SIZE_QUERY } from "../../../constants";
import { useNotebookPreference } from "../../../hooks";
import {
  selectActiveWorkspaceIdOrThrow,
  selectActiveWorkspaceNameOrThrow,
  selectLocation,
} from "../../../selectors";
import { dispatch } from "../../../store";
import {
  addNotification,
  createNotebookAndAddGraph,
  loadNotebookAndAddGraph,
} from "../../../thunks";
import { formatLabel, noop, track } from "../../../utils";
import { ButtonBar, Modal, ModalContext, TextInput, useShake } from "../../UI";
import { NotebookOption } from "./NotebookOption";
import { WorkspaceToggle } from "./WorkspaceToggle";

export function AddGraphToNotebookModal() {
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const workspaceName = useSelector(selectActiveWorkspaceNameOrThrow);
  const { data: recentNotebooks, isFetching } =
    useListRecentNotebooksQuery(workspaceId);

  const { preference, updatePreference } = useNotebookPreference();

  const [filterText, setFilterText] = useState("");
  const loweredFilterText = filterText.toLocaleLowerCase();

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const { requestClose } = useContext(ModalContext);

  const hasNotebooks = recentNotebooks && recentNotebooks.length > 0;
  const location = useSelector(selectLocation);
  const state = location?.state ?? {};
  const initialQuery =
    (typeof state === "object" &&
      "query" in state &&
      typeof state.query === "string" &&
      state.query) ||
    null;
  const queryRef = useRef(initialQuery);

  useEffect(() => {
    if (location?.pathname) {
      dispatch(replace(location.pathname, {}));
    }
  }, [location?.pathname]);

  const { shake, shakeClassName } = useShake();

  const filteredList = recentNotebooks?.filter(
    ({ title, labels }) =>
      title.toLocaleLowerCase().includes(loweredFilterText) ||
      labels?.some((label) =>
        formatLabel(label).toLocaleLowerCase().includes(loweredFilterText),
      ),
  );

  const onSubmit = useHandler(
    async (event: React.FormEvent<HTMLFormElement>) => {
      cancelEvent(event);

      if (queryRef.current === null) {
        return;
      }

      const data = new FormData(event.currentTarget);
      const notebookId = data.get("notebook");
      if (notebookId === "new-notebook") {
        // Close modal and then proceed
        requestClose();

        const id = await dispatch(createNotebookAndAddGraph(queryRef.current));
        updatePreference(workspaceName, id);
        track("Graph to notebook | add to new notebook");
      }

      if (!notebookId) {
        dispatch(
          addNotification({
            type: "warning",
            title: "Please select a notebook to add a graph/query to",
          }),
        );
      }

      const visibleNotebook =
        filteredList?.some((notebook) => notebook.id === notebookId) &&
        typeof notebookId === "string";

      if (!visibleNotebook) {
        shake();
        return;
      }

      // Close modal and then proceed
      requestClose();
      track("Graph to notebook | add to existing notebook");
      await dispatch(loadNotebookAndAddGraph(notebookId, queryRef.current));
      updatePreference(workspaceName, notebookId);
    },
  );

  useEffect(() => {
    if (queryRef.current === null) {
      requestClose();
    }
  }, [requestClose]);

  useEffect(() => {
    track("Graph to notebook | opened");
    return () => {
      track("Graph to notebook | closed");
    };
  }, []);

  if (queryRef.current === null) {
    return null;
  }

  const selectedNotebook = filteredList?.find(
    ({ id }) => id === preference?.notebookId,
  );

  const onDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    buttonRef.current?.click();
  };

  return (
    <Modal
      title="Add graph to notebook"
      description="Select a notebook to add your Prometheus graph to"
      icon="autometrics"
    >
      <form onSubmit={onSubmit}>
        <Content>
          {hasNotebooks && (
            <NotebooksSearch>
              <WorkspaceToggle />
              <TextInput
                autoFocus={true}
                trailingIcon={(
                  props: PropsWithoutRef<React.SVGProps<SVGSVGElement>>,
                ) => (
                  <MagnifyingGlassIcon {...props} iconType="magnifying_glass" />
                )}
                onChange={(event) => setFilterText(event.target.value)}
                placeholder="Search for Notebooks"
                value={filterText}
              />
            </NotebooksSearch>
          )}
          <Container>
            <AnimatePresence>
              {filteredList && (
                <>
                  <NotebookOption
                    key="new-notebook"
                    id="new-notebook"
                    title="New empty notebook"
                    onDoubleClick={onDoubleClick}
                  />
                  {selectedNotebook && (
                    <NotebookOption
                      selected={true}
                      {...selectedNotebook}
                      onDoubleClick={onDoubleClick}
                    />
                  )}
                  {filteredList.map(
                    ({ id, ...props }, index) =>
                      preference?.notebookId !== id && (
                        <NotebookOption
                          key={id}
                          id={id}
                          selected={index === 0 && !selectedNotebook}
                          {...props}
                          onDoubleClick={onDoubleClick}
                        />
                      ),
                  )}
                </>
              )}

              {!isFetching && hasNotebooks === false && (
                <div>Nothing or loading</div>
              )}
            </AnimatePresence>
          </Container>
        </Content>
        <ButtonBar>
          <Button buttonStyle="secondary" onClick={requestClose}>
            Cancel
          </Button>
          <Button ref={buttonRef} onClick={noop} className={shakeClassName}>
            Add graph
          </Button>
        </ButtonBar>
      </form>
    </Modal>
  );
}

const Content = styled.div`
  display: grid;
  margin: 0;
`;

const NotebooksSearch = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: 80vw;

  @media ${MEDIUM_SIZE_QUERY} {
    gap: 12px;
    display: grid;
    grid-template-columns: 33% auto;
  }
`;

const MagnifyingGlassIcon = styled(Icon)`
  color: ${({ theme }) => theme.colorBase600};
  pointer-events: none;
`;

const Container = styled.div`
  --height: calc(100vh - 400px);
  overflow: hidden auto;
  margin-top: 24px;
  height: min(var(--height), 380px);
`;
