import type { AppAction, NotebookAction } from ".";

export const CLOSE_NOTEBOOK = "notebooks/closeNotebook";
export const OPEN_NOTEBOOK = "notebooks/openNotebook";
export const SET_ACTIVE_NOTEBOOK = "notebooks/setActiveNotebook";
export const WITH_ACTIVE_NOTEBOOK = "notebooks/withActiveNotebook";
export const WITH_NOTEBOOK = "notebooks/withNotebook";

export type NotebooksAction =
  | CloseNotebookAction
  | ForkNotebookAction
  | OpenNotebookAction
  | SetActiveNotebookAction
  | WrappedActiveNotebookAction
  | WrappedNotebookAction;

type CloseNotebookAction = {
  type: typeof CLOSE_NOTEBOOK;
  payload: { notebookId: string; newActiveNotebookId?: string };
};

export type ForkNotebookAction = NotebookAction & { type: "fork_notebook" };

type OpenNotebookAction = {
  type: typeof OPEN_NOTEBOOK;
  payload: string;
};

type SetActiveNotebookAction = {
  type: typeof SET_ACTIVE_NOTEBOOK;
  payload: string;
};

export function setActiveNotebook(notebookId: string): SetActiveNotebookAction {
  return { type: SET_ACTIVE_NOTEBOOK, payload: notebookId };
}

export type WrappedActiveNotebookAction = {
  type: `${typeof WITH_ACTIVE_NOTEBOOK}(${NotebookAction["type"]})`;
  payload: NotebookAction;
};

export function isActiveNotebookAction(
  action: AppAction,
): action is WrappedActiveNotebookAction {
  return action.type.startsWith(WITH_ACTIVE_NOTEBOOK);
}

/**
 * Decorates an action so it is applied to the active notebook.
 *
 * When dispatching from a React component, you probably want to use
 * `useActiveNotebookDispatch()` instead of `useAppDispatch()` so this is
 * automatically applied for you.
 */
export function withActiveNotebook(
  action: NotebookAction,
): WrappedActiveNotebookAction {
  return {
    type: `${WITH_ACTIVE_NOTEBOOK}(${action.type})` as const,
    payload: action,
  };
}

type WrappedNotebookAction = {
  type: typeof WITH_NOTEBOOK;
  payload: { action: NotebookAction; notebookId: string };
};

/**
 * Decorates an action so it is applied to the tab with the given ID.
 */
export function withNotebook(
  notebookId: string,
  action: NotebookAction,
): WrappedNotebookAction {
  return { type: WITH_NOTEBOOK, payload: { action, notebookId } };
}
