import Cookie from "universal-cookie";

type UnsubscribeFn = () => void;

const cookies = new Cookie();

export function getCookieValue(name: string): string | undefined {
  return cookies.get(name);
}

export function setCookieValue(name: string, value: string) {
  cookies.set(name, value);
}

export function subscribeToCookieValue(
  name: string,
  callback: (value: string | undefined) => void,
): UnsubscribeFn {
  let value = getCookieValue(name);

  const listener = () => {
    const newValue = getCookieValue(name);
    if (newValue !== value) {
      callback(newValue);
      value = newValue;
    }
  };

  cookies.addChangeListener(listener);
  return () => cookies.removeChangeListener(listener);
}
