import { Icon } from "@fiberplane/ui";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { css, styled } from "styled-components";

import { useGetSnippetBodyQuery, useGetSnippetByNameQuery } from "../../../api";
import { selectActiveWorkspaceIdOrThrow } from "../../../selectors";
import { dispatch } from "../../../store";
import { addNotification } from "../../../thunks";
import type { ModalTypeProperties, Snippet } from "../../../types";
import { GridContainer, Modal, ModalContext, Text, useShake } from "../../UI";
import { SnippetsForm, SnippetsLabel } from "./SnippetsForm";
import { SnippetsPreview } from "./SnippetsPreview";

type SnippetsProps = Extract<
  ModalTypeProperties,
  { type: "createSnippet" } | { type: "editSnippet" }
>;

export function SnippetsModal(props: SnippetsProps) {
  const { requestClose } = useContext(ModalContext);
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const isNewSnippet = props.type === "createSnippet";

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
    setValue,
  } = useForm<Snippet>({
    defaultValues: { name: "", description: "" },
    mode: "onChange",
  });

  const { data: snippetByName, isLoading: isLoadingSnippetByName } =
    useGetSnippetByNameQuery(
      { workspaceId, snippetName: isNewSnippet ? "" : props.snippetName },
      { skip: isNewSnippet, refetchOnMountOrArgChange: true },
    );

  const { data: newSnippetBody, isLoading: isLoadingNewSnippetBody } =
    useGetSnippetBodyQuery(
      {
        startCellId: isNewSnippet ? props.startCellId : "",
        endCellId: isNewSnippet ? props.endCellId : "",
        notebookId: isNewSnippet ? props.notebookId : "",
      },
      { skip: !isNewSnippet, refetchOnMountOrArgChange: true },
    );

  const isLoading = isNewSnippet
    ? isLoadingNewSnippetBody
    : isLoadingSnippetByName;

  useEffect(() => {
    if (isNewSnippet) {
      if (!isLoadingNewSnippetBody) {
        if (newSnippetBody) {
          setValue("body", newSnippetBody);
          return;
        }

        dispatch(
          addNotification({
            title:
              "An error occurred while trying to create a snippet, please try again later",
          }),
        );
        requestClose();
      }
    } else if (!isLoadingSnippetByName) {
      if (snippetByName) {
        reset(snippetByName);
        return;
      }

      dispatch(
        addNotification({
          title:
            "An error occurred while trying to load a snippet, please try again later",
        }),
      );
      requestClose();
    }
  }, [
    isLoadingNewSnippetBody,
    isLoadingSnippetByName,
    isNewSnippet,
    newSnippetBody,
    requestClose,
    reset,
    setValue,
    snippetByName,
  ]);

  const { shake, shakeClassName } = useShake();

  useEffect(() => {
    if (errors.root) {
      shake();
    }
  }, [errors.root, shake]);

  return (
    <SnippetsModalContainer
      title={`${isNewSnippet ? "Save" : "Edit"} your snippet`}
      description="Snippets allow you to reuse your content in any notebook"
      icon="scissors"
    >
      {isLoading ? (
        <p>Loading snippets...</p>
      ) : (
        <>
          {isNewSnippet && props.hasUnsupportedCellType && (
            <SnippetsWarningContainer>
              <SnippetsWarningIcon iconType="warning" />
              <SnippetsWarningTitle>
                Snippet creation for image and discussion cells is currently not
                supported
              </SnippetsWarningTitle>
              <SnippetsWarningDescription>
                Support for these cells will be added in the future.
              </SnippetsWarningDescription>
            </SnippetsWarningContainer>
          )}

          {errors.root && (
            <ErrorMessageContainer>
              <ErrorMessage className={shakeClassName}>
                {errors.root.message}
              </ErrorMessage>
            </ErrorMessageContainer>
          )}

          <SnippetsPreviewContainer>
            <SnippetsLabel>Generated code</SnippetsLabel>
            <SnippetsPreview control={control} />
          </SnippetsPreviewContainer>

          <SnippetsForm
            control={control}
            handleSubmit={handleSubmit}
            isNewSnippet={isNewSnippet}
            setError={setError}
          />
        </>
      )}
    </SnippetsModalContainer>
  );
}

const SnippetsModalContainer = styled(Modal)`
  min-width: unset;
  max-width: 800px;
  width: min(100%, calc(100vw - 80px));
`;

const SnippetsPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ErrorMessageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ErrorMessage = styled.p(
  ({ theme }) => css`
    padding: 8px;
    border-radius: ${theme.borderRadius500};
    background-color: ${theme.colorWarning200};
    display: inline-block;
    font-size: ${theme.fontStudioBodyCopyRegularFontSize};
    line-height: 1;
  `,
);

const SnippetsWarningContainer = styled(GridContainer)(
  ({ theme }) => css`
    grid:
      "icon title" auto
      "icon description" auto / auto 1fr;
    grid-gap: 4px 12px;
    margin-bottom: 24px;
    padding: 12px;
    border-radius: ${theme.borderRadius500};
    border: 1px solid ${theme.colorWarning500};
    background-color: ${theme.colorWarning100};
  `,
);

const SnippetsWarningIcon = styled(Icon)`
  grid-area: icon;
  color: ${({ theme }) => theme.colorWarning500};
`;

const SnippetsWarningTitle = styled(Text)(
  ({ theme }) => css`
    grid-area: title;
    font: ${theme.fontStudioStrongRegularShortHand};
    letter-spacing: ${theme.fontStudioStrongRegularLetterSpacing};
    color: ${theme.colorForeground};
  `,
);

const SnippetsWarningDescription = styled(Text)(
  ({ theme }) => css`
    grid-area: description;
    font: ${theme.fontStudioBodyCopySmallShortHand};
    letter-spacing: ${theme.fontStudioBodyCopySmallLetterSpacing};
    color: ${theme.colorBase600};
  `,
);
