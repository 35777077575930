import fiberKitUrl from "url:../../fiberkit.wasm";

import { type Exports, type Imports, createRuntime } from "./ts-bindings";
import type {
  CourierAction,
  CourierReducerResult,
  NotebookAction,
  NotebookReducerResult,
  NotebookState,
  PendingOperation,
  PresenceAction,
  PresenceReducerResult,
  // biome-ignore lint/nursery/useImportRestrictions: generated types
} from "./ts-bindings/types";

type ThenArg<T> = T extends PromiseLike<infer U> ? ThenArg<U> : T;

let FiberKit: ThenArg<Required<Omit<Exports, "init">>>;

export function courierReducer(action: CourierAction): CourierReducerResult {
  if (!FiberKit) {
    throw new Error("FiberKit not yet initialized");
  }

  return FiberKit.courierReducerJs(action);
}

/**
 * Allow the app to wait for FiberKit to be initialized before starting the app.
 */
export async function init() {
  if (!checkWebAssemblySupport()) {
    throw new Error(
      // this error message doesn't include articles like "the" for the purposes of readability
      "WebAssembly is required for Fiberplane Studio to work. Make sure it's enabled in your browser, you are using latest version of Safari, Firefox, or Chrome and privacy features are disabled for this domain (Lockdown mode, Resist fingerprinting or Private mode)",
    );
  }

  if (!FiberKit) {
    const imports: Imports = {
      log(message) {
        // biome-ignore lint/suspicious/noConsoleLog: seems appropriate here
        console.log(`FiberKit log: ${message}`);
      },
    };

    const exports = await createRuntime(fetch(fiberKitUrl), imports);

    const {
      courierReducerJs,
      getNotebookState,
      getUnsentPendingOperationsForNotebook,
      init,
      notebookReducerJs,
      presenceReducerJs,
    } = exports;
    if (
      // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version (which is less readable)
      !courierReducerJs ||
      !getNotebookState ||
      !getUnsentPendingOperationsForNotebook ||
      !init ||
      !notebookReducerJs ||
      !presenceReducerJs
    ) {
      throw new Error("Missing export in FiberKit plugin");
    }

    FiberKit = {
      courierReducerJs,
      getNotebookState,
      getUnsentPendingOperationsForNotebook,
      notebookReducerJs,
      presenceReducerJs,
    };

    init();
  }
}

export function getNotebookState(notebookId: string): NotebookState | null {
  if (!FiberKit) {
    throw new Error("FiberKit not yet initialized");
  }

  return FiberKit.getNotebookState(notebookId);
}

export function getUnsentPendingOperationsForNotebook(
  notebookId: string,
): Array<PendingOperation> | null {
  if (!FiberKit) {
    throw new Error("FiberKit not yet initialized");
  }

  return FiberKit.getUnsentPendingOperationsForNotebook(notebookId);
}

export function notebookReducer(
  notebookId: string,
  action: NotebookAction,
): NotebookReducerResult {
  if (!FiberKit) {
    throw new Error("FiberKit not yet initialized");
  }

  return FiberKit.notebookReducerJs(notebookId, action);
}

function checkWebAssemblySupport() {
  try {
    if (
      typeof WebAssembly === "object" &&
      typeof WebAssembly.instantiate === "function"
    ) {
      return true;
    }
  } catch {
    return false;
  }
}

export function presenceReducer(action: PresenceAction): PresenceReducerResult {
  if (!FiberKit) {
    throw new Error("FiberKit not yet initialized");
  }

  return FiberKit.presenceReducerJs(action);
}
