import { useHandler } from "@fiberplane/hooks";

import { IMAGE_MIME_TYPES } from "../../../constants";
import { type FileInputStateHandler, useFileInput } from "../../../hooks";
import { selectActiveNotebookId } from "../../../selectors";
import { getState } from "../../../store";
import { track, validateImageFileSize } from "../../../utils";

const acceptFileTypes = IMAGE_MIME_TYPES.join(", ");

type FileInputContainerProps = {
  id: string;
  children: (onSelectFile: () => void) => JSX.Element;
  onUploadStart: (file: File) => void;
  onUploadError: (message: string) => void;
};

/**
 * Wrapping container exposing `onSelectFile` callback function to components
 * with render props.
 */
export function FileInputContainer({
  id,
  children,
  onUploadStart,
  onUploadError,
}: FileInputContainerProps): JSX.Element {
  const handler = useHandler<FileInputStateHandler>((changedState) => {
    // Is something still in progress? if so, cancel it
    if (changedState.state === "selected") {
      const errorMessage = validateImageFileSize(changedState.file);
      if (errorMessage) {
        onUploadError(errorMessage);
        return;
      }

      const notebookId = selectActiveNotebookId(getState());
      track("notebook | add cell", {
        notebookId,
        cellType: "image",
        source: "drop image on notebook ",
      });

      onUploadStart(changedState.file);
    }
  });

  const onSelectFile = useFileInput({ id, acceptFileTypes, handler });

  return children(onSelectFile);
}
