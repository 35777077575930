import { Icon } from "@fiberplane/ui";

import type { AvatarImage } from "../../types";
import { AvatarIconContainer, LazilyLoadedImage } from "../UI";

export type ProfileIconProps = {
  name?: string;
  size?: number;
  showTooltip?: boolean;

  /**
   * Either the user or workspace id. Used for selecting the accent colors.
   */
  id?: string;
} & React.HTMLAttributes<HTMLElement> &
  Partial<AvatarImage>;

export function AvatarIcon({
  id,
  src,
  onLoad,
  onError,
  name,
  size = 20,
  showTooltip = true,
  ...restProps
}: ProfileIconProps) {
  const initials = name?.[0]?.toUpperCase();

  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
  if (!id && !initials) {
    return (
      <AvatarIconContainer
        aria-label={showTooltip ? "Anonymous" : undefined}
        $size={size}
        {...restProps}
      >
        <Icon iconType="user_circle" />
      </AvatarIconContainer>
    );
  }

  return (
    <AvatarIconContainer
      $id={id}
      $size={size}
      aria-label={showTooltip ? name : undefined}
      {...restProps}
    >
      {src ? (
        <LazilyLoadedImage src={src} onLoad={onLoad} onError={onError} />
      ) : (
        initials
      )}
    </AvatarIconContainer>
  );
}
