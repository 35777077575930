import { Icon } from "@fiberplane/ui";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

import { useListMembersQuery } from "../../../../../api";
import { selectActiveWorkspaceOrThrow } from "../../../../../selectors";
import type { Membership, User } from "../../../../../types";
import { sortBy } from "../../../../../utils";
import { ProfileAvatar } from "../../../../Avatar";
import { getPriority } from "../../../../ContextMenu";
import {
  type FilterMenuItem,
  PositionedEmbeddedSearchMenu,
  type PositionedFilterMenuProps,
} from "../../../../UI";

const EMPTY_MEMBERS_LIST: Array<Membership> = [];

type Props = {
  onActivate: (user: User) => void;
  selectedList?: Array<string>;
} & Omit<PositionedFilterMenuProps, "items">;

const EMPTY_LIST: Array<string> = [];

export function UserSuggestionsMenu({
  onActivate,
  filterText = "",
  selectedList = EMPTY_LIST,
  ...menuProps
}: Props): JSX.Element | null {
  const workspace = useSelector(selectActiveWorkspaceOrThrow);
  const { data: members = EMPTY_MEMBERS_LIST } = useListMembersQuery(
    {
      workspaceId: workspace.id,
    },
    { refetchOnMountOrArgChange: true },
  );

  const items = useMemo((): Array<FilterMenuItem> => {
    if (!members) {
      return [];
    }

    return [
      {
        id: "users",
        type: "item_group",
        title: "Users",
        items: members.map(
          (user): FilterMenuItem => ({
            type: "item_with_icons",
            id: user.id,
            title: user.name,
            iconLeft: (
              <ProfileAvatar
                userId={user.id}
                name={user.name}
                showTooltip={false}
              />
            ),
            iconRight: {
              icon: (
                <Icon
                  iconType={
                    selectedList.includes(user.id) ? "check" : "key_return"
                  }
                />
              ),
              showOnlyOnSelection: !selectedList.includes(user.id),
            },
            onActivate: () => onActivate(user),
          }),
        ),
      },
    ];
  }, [members, onActivate, selectedList]);

  const filtered = useMemo(
    () => sortBy(items, getPriority(filterText)),
    [items, filterText],
  );

  return (
    <StyledPositionedMenu
      {...menuProps}
      filterText={filterText}
      items={filtered}
      noResultsText="No results"
      placement="bottom-start"
    />
  );
}

/// Styles

const StyledPositionedMenu = styled(PositionedEmbeddedSearchMenu).attrs({
  offset: [0, 5],
  grabsFocus: false,
})`
  max-height: 300px;
  width: 292px;
`;
