import { Button } from "@fiberplane/ui";
import cx from "classnames";
import {
  type ButtonHTMLAttributes,
  type ComponentRef,
  type ForwardedRef,
  forwardRef,
} from "react";

export const AddButton = forwardRef(
  (
    {
      active,
      focused,
      ...props
    }: ButtonHTMLAttributes<HTMLButtonElement> & {
      active?: boolean;
      focused?: boolean;
    },
    ref: ForwardedRef<ComponentRef<typeof Button>>,
  ) => {
    return (
      <Button
        buttonStyle="tertiary-grey"
        {...props}
        ref={ref}
        className={cx({ active, focused })}
      >
        {props.children}
      </Button>
    );
  },
);

AddButton.displayName = "AddButton";
