import { Icon } from "@fiberplane/ui";
import { keyframes, styled } from "styled-components";

export type ValidationStatus = "initial" | "pending" | "valid" | "invalid";

type Props = {
  containerStyle?: React.CSSProperties;
  status: ValidationStatus;
};

export function ValidationStatusIcon({
  containerStyle,
  status,
}: Props): JSX.Element {
  return (
    <StatusContainer style={containerStyle}>
      <StatusIndicator status={status} />
    </StatusContainer>
  );
}

const StatusContainer = styled.div`
  width: 16px;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 6px;
  top: 0;
  pointer-events: none;
`;

type StatusIndicatorProps = {
  status: ValidationStatus;
};

function StatusIndicator(props: StatusIndicatorProps) {
  const IconComponent = getStatusIcon(props.status);

  return <IconContainer status={props.status}>{IconComponent}</IconContainer>;
}

const IconContainer = styled.div<StatusIndicatorProps>`
  color: ${(props) => (props.status === "invalid" ? "#ff5a47" : "unset")};
  height: 16px;
  line-height: 16px;
`;

const CheckIcon = styled(Icon)`
  color: ${({ theme }) => theme.colorSuccess400};
`;

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const RefreshIcon = styled(Icon)`
  animation: ${spinAnimation} 0.6s ease-in-out forwards infinite;
`;

function getStatusIcon(status: ValidationStatus) {
  switch (status) {
    case "pending":
      return <RefreshIcon iconType="arrows_clockwise" />;
    case "invalid":
      return <Icon iconType="warning_circle" />;
    case "valid":
      return <CheckIcon iconType="check" />;
    default:
      return null;
  }
}
