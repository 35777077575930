import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { UPDATE_AVATAR_IMAGE } from "../../actions";
import { makeAvatarImageSelector } from "../../selectors";
import type { AvatarType } from "../../state";
import { useAppDispatch } from "../../store";
import type { AvatarImage } from "../../types";

const CHECK_INTERVAL = 5 * 60 * 1000;

/**
 * Handles logic for loading images. It returns either an object with src/onLoad/onError for use on an image or undefined if no image should be rendered
 *
 * @param avatarType the type of image to get
 * @param id user or workspace id (or undefined if we're displaying an anonymous user profile image)
 * @param checkInterval Allows for overriding the interval when no image can be loaded for a user
 * @returns undefined for anonymous users (id === undefined) and also when there's no image according to the redux store. Otherwise it will return an object that contains src/onError/onLoad for use on an image tag.
 */
export function useAvatarImage(
  avatarType: AvatarType,
  id?: string,
  checkInterval = CHECK_INTERVAL,
): AvatarImage | undefined {
  const dispatch = useAppDispatch();

  const selector = useMemo(() => {
    if (!id) {
      return () => {
        // We need to explicitly return undefined to satisfy typescript
        return undefined;
      };
    }

    return makeAvatarImageSelector(avatarType, id);
  }, [id, avatarType]);
  const imageData = useSelector(selector);

  useEffect(() => {
    if (imageData || !id) {
      return;
    }

    dispatch({
      payload: {
        avatarType,
        data: {
          // Set it to true so the browser tries to load the image
          hasImage: true,
          lastUpdated: Date.now(),
        },
        id,
      },
      type: UPDATE_AVATAR_IMAGE,
    });
  }, [dispatch, imageData, id, avatarType]);

  const onLoad = useCallback(() => {
    if (!id) {
      return;
    }

    dispatch({
      payload: {
        avatarType,
        data: {
          hasImage: true,
        },
        id,
      },
      type: UPDATE_AVATAR_IMAGE,
    });
  }, [id, dispatch, avatarType]);

  const onError = useCallback(() => {
    if (!id) {
      return;
    }

    dispatch({
      payload: {
        avatarType,
        data: {
          hasImage: false,
        },
        id,
      },
      type: UPDATE_AVATAR_IMAGE,
    });
  }, [id, dispatch, avatarType]);

  useEffect(() => {
    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
    if (!id || !imageData || imageData.hasImage) {
      return;
    }

    const timeoutId = setTimeout(() => {
      dispatch({
        type: UPDATE_AVATAR_IMAGE,
        payload: {
          avatarType,
          id,
          data: {
            hasImage: true,
            lastUpdated: Date.now(),
          },
        },
      });
    }, checkInterval);

    return () => clearTimeout(timeoutId);
  }, [id, dispatch, imageData, checkInterval, avatarType]);

  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
  if (!id || !imageData || imageData.hasImage === false) {
    return;
  }

  const url = createUrl(
    avatarType,
    id,
    imageData.forceUpdate ? imageData.lastUpdated : undefined,
  );

  return {
    src: url,
    onLoad,
    onError,
  };
}

function createUrl(type: AvatarType, id: string, queryValue?: number) {
  const resource = type === "profile" ? "users" : "workspaces";

  return `/api/${resource}/${id}/picture${
    queryValue ? `?q=${queryValue}` : ""
  }`;
}
