import { AvatarIcon } from "./AvatarIcon";
import { useAvatarImage } from "./useAvatarImage";

type ProfileAvatarProps = {
  userId?: string;
} & Omit<
  React.ComponentProps<typeof AvatarIcon>,
  "id" | "src" | "onLoad" | "onError"
>;

export function ProfileAvatar({ userId, ...props }: ProfileAvatarProps) {
  const image = useAvatarImage("profile", userId);

  return <AvatarIcon id={userId} {...image} {...props} />;
}
