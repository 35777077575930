import { css, styled } from "styled-components";

export const MenuContainer = styled.div(
  ({ theme }) => css`
    cursor: default;
    overflow-y: scroll;
    text-align: left;
    user-select: none;
    padding: 16px 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    background-color: ${theme.color.bg.elevated.default};
    box-shadow: ${theme.effect.shadow.m};
    border-radius: ${theme.radius.default};
  `,
);
