import {
  FAIL_LOADING_DATA_SOURCE_LIST,
  FINISH_LOADING_DATA_SOURCE_LIST,
  START_LOADING_DATA_SOURCE_LIST,
  addDataSource as addDataSourceAction,
  applyOwnOperation,
  expandConsole,
  setSupportedQueryTypes,
  showError,
  withActiveNotebook,
  withNotebook,
} from "../actions";
import {
  makeDataSourceSupportedQueryTypesSelector,
  selectActiveNotebookId,
  selectActiveWorkspaceIdOrThrow,
  selectDataSource,
  selectDataSources,
  selectSelectedDataSources,
} from "../selectors";
import { Api, DataSources } from "../services";
import type { Thunk } from "../store";
import type {
  DataSource,
  Provider,
  QuerySchema,
  SelectedDataSources,
  SupportedQueryType,
} from "../types";
import {
  type Intent,
  formatDataSourceKey,
  isSameDataSource,
  pick,
} from "../utils";
import { runAllCells } from "./notebookThunks";
import { addNotification } from "./notificationsThunks";

export const addDataSource =
  (newDataSource: Api.NewDataSource): Thunk<Promise<DataSource>> =>
  async (dispatch, getState) => {
    const state = getState();
    const notebookId = selectActiveNotebookId(state);
    const workspaceId = selectActiveWorkspaceIdOrThrow(state);

    try {
      const dataSource = await Api.createDataSource(workspaceId, newDataSource);

      dispatch(addDataSourceAction(dataSource));
      dispatch(setSelectedDataSource(dataSource));

      return dataSource;
    } catch (error) {
      dispatch(
        addNotification({
          title: "Something went wrong adding this data source",
          description: "Check console for more information",
          action: (dispatch) => dispatch(withActiveNotebook(expandConsole())),
        }),
      );

      dispatch(
        withNotebook(
          notebookId,
          showError({
            type: "other",
            message: `Cannot add data source: ${error}`,
          }),
        ),
      );

      throw error;
    }
  };

export type ProviderForIntentResult =
  ProviderForDataSourceWithQueryTypeResult & {
    dataSource: DataSource;
  };

/**
 * Returns the provider to use for a given intent.
 *
 * The provider will be invoked and queried for its supported query types to
 * verify it supports the query type specified in the intent. The supported
 * MIME types and schema used with the query type will be returned as well.
 *
 * Throws if there is no matching data sources or the query type is not
 * supported.
 */
export const getProviderForIntent =
  (intent: Intent): Thunk<Promise<ProviderForIntentResult>> =>
  (dispatch, getState) => {
    const state = getState();
    const dataSources = selectDataSources(state);
    const selectedDataSources = selectSelectedDataSources(state);

    return dispatch(
      getProviderForIntentWithDataSources(
        dataSources,
        selectedDataSources,
        intent,
      ),
    );
  };

/**
 * Same as `getProviderForIntent()`, but explicitly passes the data sources to
 * select from.
 */
export const getProviderForIntentWithDataSources =
  (
    dataSources: ReadonlyArray<DataSource>,
    selectedDataSources: SelectedDataSources,
    intent: Intent,
  ): Thunk<Promise<ProviderForIntentResult>> =>
  async (dispatch) => {
    const { providerType, dataSourceKey, queryType } = intent;

    const dataSource = selectDataSource(
      dataSources,
      selectedDataSources,
      providerType,
      dataSourceKey,
    );
    if (!dataSource) {
      throw new DataSources.NoDataSourceError(providerType, dataSourceKey);
    }

    const result = await dispatch(
      getProviderForDataSourceWithQueryType(dataSource, queryType),
    );
    return { ...result, dataSource };
  };

type ProviderForDataSourceWithQueryTypeResult = {
  provider: Provider;
  mimeTypes: Array<string>;
  schema: QuerySchema;
};

/**
 * Returns the provider to use for the given data source.
 *
 * The provider will be invoked and queried for its supported query types to
 * verify it supports the given query type. The supported MIME types and schema
 * used with the query type will be returned as well.
 *
 * Throws if the given query type is not supported.
 */
export const getProviderForDataSourceWithQueryType =
  (
    dataSource: DataSource,
    queryType: string,
  ): Thunk<Promise<ProviderForDataSourceWithQueryTypeResult>> =>
  async (dispatch) => {
    const supportedQueryTypes = await dispatch(
      getSupportedQueryTypes(dataSource),
    );

    const supportedQueryType = supportedQueryTypes.find(
      ({ queryType: supportedQueryType }) => supportedQueryType === queryType,
    );
    if (!supportedQueryType) {
      throw new DataSources.UnsupportedQueryTypeError(queryType);
    }

    return {
      provider: DataSources.getProviderForDataSource(dataSource),
      mimeTypes: supportedQueryType.mimeTypes,
      schema: supportedQueryType.schema,
    };
  };

/**
 * Returns the supported query types for a data source.
 *
 * Results are cached in the store.
 */
export const getSupportedQueryTypes =
  (dataSource: DataSource): Thunk<Promise<ReadonlyArray<SupportedQueryType>>> =>
  async (dispatch, getState) => {
    const selectSupportedQueryTypes = makeDataSourceSupportedQueryTypesSelector(
      dataSource.name,
    );

    let supportedQueryTypes = selectSupportedQueryTypes(getState());
    if (!supportedQueryTypes) {
      const provider = DataSources.getProviderForDataSource(dataSource);
      supportedQueryTypes = await provider.getSupportedQueryTypes(dataSource);
      dispatch(
        setSupportedQueryTypes(
          formatDataSourceKey(dataSource),
          supportedQueryTypes,
        ),
      );
    }

    return supportedQueryTypes;
  };

export const loadDataSources =
  (workspaceId: string): Thunk<Promise<void>> =>
  async (dispatch) => {
    dispatch({ type: START_LOADING_DATA_SOURCE_LIST });

    try {
      const dataSources = await Api.listDataSources(workspaceId);

      dispatch({ type: FINISH_LOADING_DATA_SOURCE_LIST, payload: dataSources });
    } catch (error: unknown) {
      dispatch({
        type: FAIL_LOADING_DATA_SOURCE_LIST,
        payload: `${error}`,
      });
    }
  };

export const removeDataSource =
  (dataSource: DataSource): Thunk =>
  async (dispatch, getState) => {
    const state = getState();
    const notebookId = selectActiveNotebookId(state);
    const workspaceId = selectActiveWorkspaceIdOrThrow(state);

    try {
      await Api.deleteDataSource(workspaceId, dataSource.name);

      const selectedDataSources = selectSelectedDataSources(state);
      const selectedDataSource = selectedDataSources[dataSource.providerType];
      if (isSameDataSource(dataSource, selectedDataSource)) {
        dispatch(
          withNotebook(
            notebookId,
            applyOwnOperation({
              type: "set_selected_data_source",
              providerType: dataSource.providerType,
              oldSelectedDataSource: selectedDataSource,
            }),
          ),
        );
      }

      dispatch(loadDataSources(workspaceId));
    } catch (error) {
      dispatch(
        withNotebook(
          notebookId,
          showError({
            type: "other",
            message: `Cannot remove data source: ${error}`,
          }),
        ),
      );
    }
  };

export const setSelectedDataSource =
  (dataSource: DataSource): Thunk =>
  (dispatch, getState) => {
    const selectedDataSources = selectSelectedDataSources(getState());

    dispatch(
      withActiveNotebook(
        applyOwnOperation({
          type: "set_selected_data_source",
          providerType: dataSource.providerType,
          newSelectedDataSource: pick(dataSource, "proxyName", "name"),
          oldSelectedDataSource: selectedDataSources[dataSource.providerType],
        }),
      ),
    );

    dispatch(runAllCells());
  };

export const updateDataSource =
  (dataSource: DataSource): Thunk =>
  async (dispatch, getState) => {
    const state = getState();
    const notebookId = selectActiveNotebookId(state);
    const workspaceId = selectActiveWorkspaceIdOrThrow(state);

    try {
      await Api.updateDataSource(workspaceId, dataSource);
      dispatch(loadDataSources(workspaceId));
    } catch (error) {
      dispatch(
        withNotebook(
          notebookId,
          showError({
            type: "other",
            message: `Cannot update data source: ${error}`,
          }),
        ),
      );
    }
  };
