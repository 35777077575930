// Import fetchBaseQuery from query instead of query/react, otherwise parcel builds break, see:
// https://github.com/parcel-bundler/parcel/issues/7622
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { createApi } from "@reduxjs/toolkit/query/react";

import { selectAuthentication } from "../selectors";
import type { RootState } from "../state";

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "/api",
    prepareHeaders: (headers, baseQueryApi) => {
      const { token } = selectAuthentication(
        baseQueryApi.getState() as RootState,
      );

      if (token) {
        headers.append("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: () => ({}),
  reducerPath: "api",
});
