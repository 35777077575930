import { useHandler } from "@fiberplane/hooks";
import { Button, Icon } from "@fiberplane/ui";
import { forwardRef } from "react";
import { css, styled } from "styled-components";

import { useActiveNotebookDispatch } from "../../../store";
import { changeDiscussionStatus } from "../../../thunks";
import type { ThreadDetails, ThreadStatus, User } from "../../../types";
import { MenuItem, ToggleMenu } from "../../UI";

type DiscussionStatusProps = {
  thread: ThreadDetails;
  user: User;
};

type DiscussionStatusToggleProps = {
  opened: boolean;
  isResolved: boolean;
};

const DiscussionStatusToggle = forwardRef<
  HTMLDivElement,
  DiscussionStatusToggleProps
>(function DiscussionStatusToggle({ isResolved }, ref) {
  return (
    <div ref={ref}>
      <StatusButton>
        <Icon iconType={isResolved ? "check" : "chats_teardrop"} />
        {isResolved ? "Resolved discussion" : "Open discussion"}
        <Icon iconType="caret_down" height={20} width={20} />
      </StatusButton>
    </div>
  );
});

export function DiscussionStatus({ thread, user }: DiscussionStatusProps) {
  const dispatch = useActiveNotebookDispatch();

  const isResolved = thread.status === "resolved";

  const setDiscussionStatus = useHandler((newStatus: ThreadStatus) => {
    if (thread.status !== newStatus) {
      dispatch(
        changeDiscussionStatus({
          newStatus,
          threadId: thread.id,
          user,
        }),
      );
    }
  });

  if (thread.itemCount === 0) {
    return (
      <StatusButtonPlaceholder>
        <Icon iconType="chats_teardrop" />
        Open discussion
      </StatusButtonPlaceholder>
    );
  }

  return (
    <ToggleMenu
      offset={[0, 4]}
      placement="bottom-start"
      toggleElement={({ anchorRef, opened }) => (
        <DiscussionStatusToggle
          ref={anchorRef}
          opened={opened}
          isResolved={isResolved}
        />
      )}
    >
      <MenuItem
        iconLeft={<CheckIcon iconType="check" $isVisible={!isResolved} />}
        id="discussion-open"
        title="Open discussion"
        onActivate={() => setDiscussionStatus("open")}
      />
      <MenuItem
        iconLeft={<CheckIcon iconType="check" $isVisible={isResolved} />}
        id="discussion-resolved"
        title="Resolved discussion"
        onActivate={() => setDiscussionStatus("resolved")}
      />
    </ToggleMenu>
  );
}

const StatusButton = styled(Button)(
  ({ theme }) => css`
    min-height: unset;
    max-height: ${theme.height.small};
    border-radius: ${theme.radius.minimal};
    padding: 4px 12px;
    box-shadow: none;

    &,
    &:hover,
    &:active,
    &:focus {
      background-color: ${theme.color.bg.success};
      color: ${theme.color.fg.success};
    }
  `,
);

export const StatusButtonPlaceholder = styled(StatusButton).attrs({
  forwardedAs: "div",
})`
  display: inline-flex;

  &,
  &:hover {
    cursor: text;
  }
`;

const CheckIcon = styled(Icon)<{ $isVisible: boolean }>(
  ({ $isVisible, theme }) => css`
    visibility: ${$isVisible ? "visible" : "hidden"};
    color: ${theme.color.fg.default};
  `,
);
