import { memo } from "react";

import type { Cell } from "../../types";
import { CheckboxCellContent } from "./CheckboxCellContent";
import { CodeCellContent } from "./CodeCellContent";
import { DiscussionCellContent } from "./DiscussionCell";
import { DividerCellContent } from "./DividerCellContent";
import { GraphCellContent } from "./GraphCellContent";
import { HeadingCellContent } from "./HeadingCellContent";
import { ImageCellContent } from "./ImageCellContent";
import { ListItemCellContent } from "./ListItemCellContent";
import { LogCellContent } from "./LogCellContent";
import { ProviderCellContent } from "./ProviderCell";
import { TableCellContent } from "./TableCellContent";
import { TextCellContent } from "./TextCellContent";
import { TimelineCellContent } from "./TimelineCellContent";

export type CellContentProps = {
  cell: Cell;
  readOnly: boolean;
};

export const CellContent = memo(function CellContent({
  cell,
  readOnly,
}: CellContentProps): JSX.Element {
  switch (cell.type) {
    case "checkbox":
      return <CheckboxCellContent cell={cell} readOnly={readOnly} />;
    case "code":
      return <CodeCellContent cell={cell} readOnly={readOnly} />;
    case "divider":
      return <DividerCellContent cell={cell} readOnly={readOnly} />;
    case "discussion":
      return <DiscussionCellContent cell={cell} readOnly={readOnly} />;
    case "graph":
      return <GraphCellContent cell={cell} readOnly={readOnly} />;
    case "heading":
      return <HeadingCellContent cell={cell} readOnly={readOnly} />;
    case "image":
      return <ImageCellContent cell={cell} readOnly={readOnly} />;
    case "list_item":
      return <ListItemCellContent cell={cell} readOnly={readOnly} />;
    case "log":
      return <LogCellContent cell={cell} readOnly={readOnly} />;
    case "provider":
      return <ProviderCellContent cell={cell} readOnly={readOnly} />;
    case "table":
      return <TableCellContent cell={cell} readOnly={readOnly} />;
    case "text":
      return <TextCellContent cell={cell} readOnly={readOnly} />;
    case "timeline":
      return <TimelineCellContent cell={cell} readOnly={readOnly} />;
  }
});
