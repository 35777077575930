import { useCellFocus } from "../../../../hooks";
import type { QueryField as Field, QuerySchema } from "../../../../types";
import { hasFocusPosition, noCellFocus } from "../../../../utils";
import { ProviderTypeIcon } from "../../../UI";
import { useIsLive, useQueryData } from "../hooks";
import { IntegerField } from "./IntegerField";
import { LabelField } from "./LabelField";
import { LiveButton } from "./LiveButton";
import { SelectField } from "./SelectField";
import { TextField } from "./TextField";
import { TimeRangeField } from "./TimeRangeField";

type Props = {
  cellId: string;
  field: Field;
  providerType: string;
  queryData?: string;
  readOnly?: boolean;
  schema: QuerySchema;
  setLive: (live: boolean) => void;
};

export function QueryField({
  cellId,
  field,
  providerType,
  queryData,
  readOnly,
  schema,
  setLive,
}: Props): JSX.Element | null {
  const cellFocus = useCellFocus(cellId, field.name);
  const focus = hasFocusPosition(cellFocus, field.name)
    ? cellFocus
    : noCellFocus;

  const value = useQueryData(queryData, field.name);

  const isLive = useIsLive(queryData);
  const liveButton = supportsLive(schema) ? (
    <LiveButton
      cellId={cellId}
      readOnly={readOnly}
      isLive={isLive}
      setLive={setLive}
    />
  ) : undefined;

  const showCta = isCtaField(field, schema);

  switch (field.type) {
    case "date_time_range":
      return (
        <TimeRangeField
          cellId={cellId}
          field={field.name}
          focus={focus}
          readOnly={readOnly}
          value={value}
          liveButton={liveButton}
        />
      );

    case "label":
      return (
        <LabelField
          cellId={cellId}
          field={field.name}
          icon={
            showCta ? (
              <ProviderTypeIcon type={providerType} offline />
            ) : undefined
          }
          multiple={field.multiple}
          readOnly={readOnly}
          showSearchCta={showCta}
          value={value}
        />
      );

    case "integer":
      return (
        <IntegerField
          cellId={cellId}
          field={field.name}
          focus={focus}
          max={field.max}
          min={field.min}
          placeholder={field.placeholder || field.label}
          readOnly={readOnly}
          step={field.step}
          value={value}
        />
      );

    case "select":
      return (
        <SelectField
          cellId={cellId}
          field={field.name}
          focus={focus}
          label={field.label}
          options={field.options}
          placeholder={field.placeholder}
          value={value}
        />
      );

    case "text":
      return (
        <TextField
          cellId={cellId}
          field={field.name}
          focus={focus}
          icon={
            showCta ? (
              <ProviderTypeIcon type={providerType} offline />
            ) : undefined
          }
          placeholder={field.placeholder || field.label}
          readOnly={readOnly}
          showRunCta={showCta}
          value={value}
          supportsSuggestions={field.supportsSuggestions}
        />
      );

    case "checkbox": {
      if (field.name === "live") {
        // Special case for 'live' checkbox, this is rendered somewhere else
        // so we'll ignore it for now.
        return null;
      }

      throw new Error(
        `Query field of type ${field.type} not implemented other than for 'live mode'`,
      );
    }

    default:
      throw new Error(`Query field of type ${field.type} not implemented`);
  }
}

/**
 * We use a simple heuristic to decide whether to show the "Run"/"Search"
 * Call-to-Action on a field: we simply show it on the last eligible field.
 */
function isCtaField(field: Field, schema: QuerySchema): boolean {
  for (let i = schema.length - 1; i >= 0; i--) {
    if (schema[i] === field) {
      return true;
    }

    const type = schema[i]?.type;
    if (type === "text" || type === "label") {
      return false;
    }
  }

  return false;
}

/**
 * Determines whether this schema supports live mode
 */
function supportsLive(schema: QuerySchema): boolean {
  return schema.some(
    (field) => field.type === "checkbox" && field.name === "live",
  );
}
