import { Button } from "@fiberplane/ui";
import { type FormEvent, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath } from "react-router";
import { push } from "redux-first-history";

import { useCreateTemplateMutation } from "../../api";
import { ROUTES } from "../../constants";
import { ValidationError } from "../../errors";
import {
  selectActiveWorkspaceIdOrThrow,
  selectActiveWorkspaceNameOrThrow,
} from "../../selectors";
import { dispatch } from "../../store";
import { ButtonBar, useShake } from "../UI";
import {
  InfoContainer,
  InfoInput,
  InfoLabel,
  StyledForm,
  StyledFormContainer,
} from "./shared";

const BODY_PLACEHOLDER = `For example:
local fp = import 'fiberplane.libsonnet';
function(title='title')
  fp.notebook.new(title)
      .setTimeRangeRelative(60)
      .addProxyDataSource(type='prometheus');
`;

export function NewTemplate(): JSX.Element {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [currentError, setCurrentError] = useState<Error>();
  const [body, setBody] = useState("");
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const workspaceName = useSelector(selectActiveWorkspaceNameOrThrow);
  const [createTemplate, { isLoading }] = useCreateTemplateMutation();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    createTemplate({ workspaceId, newTemplate: { body, name, description } })
      .unwrap()
      .then((value) => {
        dispatch(
          push(
            generatePath(ROUTES.Template, {
              workspaceName,
              templateName: value.name,
            }),
          ),
        );
      })
      .catch((error) => {
        shake();
        setCurrentError(
          error instanceof Error ? error : new Error("Unknown error occurred"),
        );
      });
  };

  const { shake, shakeClassName } = useShake();

  return (
    <StyledFormContainer>
      <StyledForm onSubmit={handleSubmit}>
        <InfoContainer>
          <h1>Add a template</h1>
          <p>
            Templates can be used to quickly create notebooks that already
            contain useful graphs/queries and steps to help speed up creating
            rich notebooks. Define your own templates using jsonnet. For more
            information see our template library on{" "}
            <a
              href="https://github.com/fiberplane/templates"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github
            </a>
          </p>
          {currentError &&
            currentError instanceof ValidationError &&
            currentError.property === undefined && (
              <p>Unable to add template: ${currentError.message}</p>
            )}
          <div>
            <InfoLabel htmlFor="newTemplateName">Name</InfoLabel>
            <InfoInput
              id="newTemplateName"
              type="text"
              name="name"
              placeholder="Enter the name"
              defaultValue={name}
              // HELP: add name validation logic, since it needs to be a k8s-like name now
              onChange={(event) => setName(event.target.value)}
              className={
                currentError instanceof ValidationError &&
                currentError.property === "name"
                  ? "error"
                  : ""
              }
            />
          </div>
          <div>
            <InfoLabel htmlFor="newTemplateDescription">Description</InfoLabel>
            <InfoInput
              id="newTemplateDescription"
              type="text"
              name="description"
              placeholder="Enter the description "
              defaultValue={description}
              className={
                currentError instanceof ValidationError &&
                currentError.property === "description"
                  ? "error"
                  : ""
              }
              onChange={(event) => setDescription(event.target.value)}
            />
          </div>
          <div>
            <InfoLabel htmlFor="newTemplateBody">Template</InfoLabel>
            <InfoInput
              as="textarea"
              id="newTemplateBody"
              name="templateBody"
              placeholder={BODY_PLACEHOLDER}
              defaultValue={body}
              className={
                currentError instanceof ValidationError &&
                currentError.property === "body"
                  ? "error"
                  : ""
              }
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                setBody(event.target.value)
              }
              style={{ height: "12em" }}
            />
          </div>
          <ButtonBar>
            <Button
              disabled={isLoading}
              type="submit"
              className={shakeClassName}
            >
              Create
            </Button>
          </ButtonBar>
        </InfoContainer>
      </StyledForm>
    </StyledFormContainer>
  );
}
