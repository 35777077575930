import { useSelector } from "react-redux";

import { selectContextMenu } from "../../../../selectors";
import type { ExtendedFormatting } from "../types";

export function useEntityInputFormatting(
  cellId: string,
  field: string | undefined,
): ExtendedFormatting {
  const contextMenu = useSelector(selectContextMenu);
  const shouldFormatForMenu =
    contextMenu?.menuType === "at_menu" ||
    contextMenu?.menuType === "date_picker";
  if (
    shouldFormatForMenu &&
    contextMenu.cellId === cellId &&
    contextMenu.field === field
  ) {
    const { typeAheadOffset, typeAheadText = "" } = contextMenu;

    if (typeAheadOffset) {
      return [
        {
          type: "entity_input",
          offset: typeAheadOffset - 1,
          typeAheadText,
        },
      ];
    }
  }

  return [];
}
