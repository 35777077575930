import {
  type Cell,
  type CellTypeProperties,
  isCellWithFormattingField,
} from "../../types";

/**
 * Helper for creating a new cell of any given type.
 */
export function makeCell(id: string, properties: CellTypeProperties): Cell {
  switch (properties.type) {
    case "checkbox":
      return {
        id,
        ...properties,
        checked: properties.checked ?? false,
        content: "",
        formatting: [],
        level: properties.level,
        readOnly: false,
      };

    case "divider":
      return { id, ...properties, readOnly: false };

    case "discussion":
      return { id, readOnly: false, ...properties };

    case "heading":
      return {
        id,
        ...properties,
        content: "",
        formatting: [],
        readOnly: false,
      };

    case "list_item":
      return {
        id,
        ...properties,
        content: "",
        formatting: [],
        level: properties.level,
        readOnly: false,
      };

    case "log":
      return { id, ...properties, dataLinks: [] };

    case "provider":
      return { id, ...properties, output: [] };

    case "table":
      return { id, ...properties };

    default: {
      const { type } = properties;
      if (isCellWithFormattingField(type)) {
        return { id, type, content: "", formatting: [], readOnly: false };
      }

      return { id, type, content: "", readOnly: false };
    }
  }
}
