import { type ReactNode, useRef, useState } from "react";
import { css, styled } from "styled-components";

import { AttachedPopup } from "@fiberplane/ui";
import { PresenceOverflowIcon } from "./PresenceOverflowIcon";

type OverflowProps = {
  count: number;
  popupChildren: ReactNode;
};

export function Overflow({ count, popupChildren }: OverflowProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [show, setShow] = useState(false);
  const hideTimeout = useRef<ReturnType<typeof setTimeout>>();

  const onMouseEnter = () => {
    clearTimeout(hideTimeout.current);
    setShow(true);
  };

  const onMouseLeave = () => {
    hideTimeout.current = setTimeout(() => setShow(false), 500);
  };

  const onTouchStart = () => {
    setShow((show) => !show);
  };

  return (
    <>
      <PresenceOverflowIcon
        ref={containerRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onTouchStart={onTouchStart}
        count={count}
        data-testid="presence-overflow-icon"
      />

      {show && containerRef.current && (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <AttachedPopup
            element={containerRef.current}
            offset={[8, 8]}
            placement="bottom-end"
          >
            <PopupContent
              data-testid="overflow-content"
              onMouseEnter={onMouseEnter}
            >
              {popupChildren}
            </PopupContent>
          </AttachedPopup>
        </div>
      )}
    </>
  );
}

const PopupContent = styled.div(
  ({ theme }) => css`
    background-color: ${theme.color.bg.elevated.default};
    display: flex;
    flex-wrap: wrap;
    padding: 8px;
    gap: 8px;
    max-width: 148px; /* 5 users p/row */
    border-radius: ${theme.radius.default};
    box-shadow: ${theme.effect.shadow.m};
  `,
);
