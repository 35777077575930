import { createSelector } from "reselect";

import type { RootState } from "../state";
import { selectCurrentUser } from "./authenticationSelectors";
import {
  selectActiveWorkspace,
  selectActiveWorkspaceOwnerId,
  selectActiveWorkspaceRole,
  selectActiveWorkspaceType,
} from "./workspaceSelectors";

export const selectCanCreateNotebook = (state: RootState) =>
  selectActiveWorkspaceRole(state) !== "read";

export const selectCanCreateView = (state: RootState) =>
  selectActiveWorkspaceRole(state) !== "read";

export const selectCanCreateWebhook = (state: RootState) =>
  selectActiveWorkspaceRole(state) !== "read";

export const selectCanDeleteNotebook = (state: RootState) =>
  selectActiveWorkspaceRole(state) !== "read";

export const selectCanDeleteView = (state: RootState) =>
  selectActiveWorkspaceRole(state) !== "read";

export const selectCanDeleteWebhook = (state: RootState) =>
  selectActiveWorkspaceRole(state) !== "read";

export const selectCanDeleteWorkspace = createSelector(
  [selectActiveWorkspace, selectActiveWorkspaceOwnerId, selectCurrentUser],
  (workspace, ownerId, currentUser) =>
    workspace &&
    workspace.type !== "personal" &&
    ownerId &&
    currentUser &&
    ownerId === currentUser.id,
);

export const selectCanInviteWorkspaceMember = (state: RootState) =>
  selectActiveWorkspaceRole(state) === "admin" &&
  selectActiveWorkspaceType(state) !== "personal";

export const selectCanDeleteInvites = (state: RootState) =>
  selectActiveWorkspaceRole(state) === "admin" &&
  selectActiveWorkspaceType(state) !== "personal";

export const selectCanUpdateNotebook = (state: RootState) =>
  selectActiveWorkspaceRole(state) !== "read";

export const selectCanUpdateView = (state: RootState) =>
  selectActiveWorkspaceRole(state) !== "read";

export const selectCanUpdateWebhook = (state: RootState) =>
  selectActiveWorkspaceRole(state) !== "read";

export const selectCanUpdateWorkspace = (state: RootState) =>
  selectActiveWorkspaceRole(state) === "admin";
