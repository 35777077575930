import { Button } from "@fiberplane/ui";
import { memo } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

import { selectConnectionStatus, selectIsAuthenticated } from "../../selectors";
import { useAppDispatch } from "../../store";
import { authenticate, reconnect } from "../../thunks";
import type { ConnectionStatus } from "../../types";
import { ConsoleHeaderGroup } from "../UI";

export const ConnectionStatusIndicator = memo(
  function ConnectionStatusIndicator(): JSX.Element | null {
    const dispatch = useAppDispatch();
    const connectionStatus = useSelector(selectConnectionStatus);
    const isAuthenticated = useSelector(selectIsAuthenticated);

    const description = getDescription(connectionStatus, isAuthenticated);
    if (!description) {
      return null;
    }

    return (
      <Container data-testid="connection-indicator">
        {description}
        {connectionStatus === "disconnected" &&
          (isAuthenticated ? (
            <Button buttonSize="small" onClick={() => dispatch(reconnect())}>
              Reconnect now
            </Button>
          ) : (
            <>
              <Button
                buttonSize="small"
                onClick={() => dispatch(authenticate("google"))}
              >
                Sign in with Google
              </Button>
              <Button
                buttonSize="small"
                onClick={() => dispatch(authenticate("github"))}
              >
                Sign in with GitHub
              </Button>
            </>
          ))}
      </Container>
    );
  },
);

function getDescription(
  connectionStatus: ConnectionStatus,
  isAuthenticated: boolean,
): string {
  switch (connectionStatus) {
    case "connected":
      return "";
    case "connecting":
      return "Connecting...";
    case "disconnected":
      return isAuthenticated ? "Offline" : "Not signed in";
    case "idle":
      return "";
  }
}

const Container = styled(ConsoleHeaderGroup)`
  display: flex;
  align-items: center;
  gap: 8px;
`;
