import { createSelector } from "reselect";

import type { AvatarData, AvatarState, AvatarType, RootState } from "../state";

export const selectProfileImage = (state: RootState): AvatarState =>
  state.avatars;

export const makeAvatarImageSelector = (avatarType: AvatarType, id: string) =>
  createSelector(
    [selectProfileImage],
    (avatarsState: AvatarState): AvatarData | null =>
      avatarsState[avatarType].get(id) ?? null,
  );
