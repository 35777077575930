import type { FrontMatterGitHubPullRequest } from "../../../../../types";

export function mergePrStatus(pr: FrontMatterGitHubPullRequest): string {
  if (pr.state === "closed" && pr.merged) {
    return "merged";
  }

  if (pr.state === "open" && pr.draft) {
    return "draft";
  }

  return pr.state;
}
