import { useEffect, useState } from "react";

import { type CursorCoordinates, getCoordinatesForOffset } from "..";
import type { User } from "../../../../types";
import { getCursorOffset } from "../../../../utils";
import type { CellSubscriber } from "./useCellSubscribers";

type PresenceCursorCoordinates = CursorCoordinates & { user: User };

export function usePresenceCursorCoordinates(
  container: HTMLElement,
  field: string | undefined,
  subscribers: Array<CellSubscriber>,
  text: string,
  fontLoading: boolean,
  notebookContainerWidth: number | undefined,
): Array<PresenceCursorCoordinates | null> {
  const [coords, setCoords] =
    useState<Array<PresenceCursorCoordinates | null>>(noCoords);

  // biome-ignore lint/correctness/useExhaustiveDependencies: intentional
  useEffect(() => {
    if (subscribers.length === 0) {
      setCoords(noCoords);
      return;
    }

    const result: Array<PresenceCursorCoordinates | null> = subscribers.map(
      ({ focus, user }) => {
        const focusOffset = getCursorOffset(focus, field);

        const coordinates =
          focusOffset === undefined
            ? null
            : getCoordinatesForOffset(container, text, focusOffset);
        if (!coordinates) {
          return null;
        }

        return { ...coordinates, user };
      },
    );
    setCoords(result);
  }, [
    container,
    field,
    fontLoading,
    notebookContainerWidth,
    subscribers,
    text,
  ]);

  return coords;
}

const noCoords: Array<never> = [];
