import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectLabelsEditorExistingLabelKeys } from "../../../selectors";
import type { LabelsEditorState } from "../../../state";
import { validateLabelsEditor } from "../../../utils";

export const useValidation = (editorState: LabelsEditorState | undefined) => {
  const existingKeys = useSelector(selectLabelsEditorExistingLabelKeys);

  return useMemo(
    () => validateLabelsEditor(editorState, existingKeys),
    [editorState, existingKeys],
  );
};
