import { useHandler } from "@fiberplane/hooks";
import { Button, IconButton, Input } from "@fiberplane/ui";
import { useContext } from "react";
import { styled } from "styled-components";

import { dispatch } from "../../../store";
import { addNotification } from "../../../thunks";
import { ButtonBar, Modal, ModalContext } from "../../UI";

export function WebhookSecret({ secret }: { secret: string }) {
  const { requestClose } = useContext(ModalContext);
  const handleClickCopy = useHandler(() => {
    navigator.clipboard.writeText(secret);
    dispatch(
      addNotification({
        title: "Signing secret has been copied to your clipboard",
      }),
    );
  });

  return (
    <Modal
      title="Signing secret"
      description="Use this secret token to sign your webhook payload"
    >
      <InputContainer>
        <Input type="text" value={secret} readOnly />
        <CopyIconButton
          iconType="copy"
          onClick={handleClickCopy}
          buttonStyle="tertiary-grey"
        />
      </InputContainer>

      <ButtonBar>
        <Button onClick={requestClose} buttonStyle="secondary">
          Close
        </Button>
      </ButtonBar>
    </Modal>
  );
}

const InputContainer = styled.div`
  display: grid;
  position: relative;
`;

const CopyIconButton = styled(IconButton)`
  position: absolute;
  /* Offset the input's border */
  right: 1px;
  top: 1px;
`;
