import bgGradientCreated from "url:./images/proxy-created-bg-gradient.jpeg";
import bgGradientDeploy from "url:./images/setup-instructions-bg-gradient.jpeg";
import { Button, IconButton } from "@fiberplane/ui";
import { motion } from "framer-motion";
import { useContext } from "react";
import { css, styled } from "styled-components";

import {
  BaseModal,
  ModalButtonBar,
  ModalContent,
  ModalContext,
  Text,
} from "../../UI";

export const StyledModal = styled(BaseModal)`
  width: 100%;
  max-width: 600px;
  min-height: 480px;
  max-height: max(768px, 80vh);
  position: relative;
`;

type Gradient = "setup" | "deploy";

export const StyledModalContent = styled(ModalContent)<{ gradient?: Gradient }>(
  ({ gradient }) => {
    const img = gradient === "deploy" ? bgGradientDeploy : bgGradientCreated;
    return css`
      /* NOTE - in the future, prefer using <img/> tags instead of css background images */
      background-image: url("${img}");
      background-position: top;
      background-repeat: no-repeat;
      background-attachment: local;
      background-size: 100%;
      text-align: center;

      & + ${ModalButtonBar} {
        /* NOTE - undoing a rule from ModalContent that makes our spacing uneven here */
        padding-top: 24px;
      }
    `;
  },
);

export const IllustrationContainer = styled.div`
  padding-bottom: 0;
  border-bottom: 0;
  min-height: 160px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function ModalClose() {
  const { requestClose } = useContext(ModalContext);

  return (
    <ModalCloseContainer>
      <ModalCloseIconButton iconType="close" onClick={requestClose} />
    </ModalCloseContainer>
  );
}

export const ModalCloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
`;

export const ModalCloseIconButton = styled(IconButton)`
  background: ${({ theme }) => theme.colorBaseAlpha400};
  border-radius: 50%;
  color: ${({ theme }) => theme.colorBase100};
`;

export const ModalHeading = styled.h2`
  font: ${({ theme }) => theme.fontStudioHeadingsH2ShortHand};
  text-align: center;
`;

export const ModalBodyRegularText = styled(Text)(
  ({ theme }) => css`
    color: ${theme.colorBase600};
    font: ${theme.fontStudioBodyCopyRegularShortHand};
    line-height: ${theme.fontStudioBodyCopyRegularLineHeight};
  `,
);

export const ButtonFooter = styled(ModalButtonBar)`
  min-height: 100px;
  align-self: center;
  display: grid;
  grid:
    "back page next"
    / 140px auto 140px;
  justify-content: space-between;
  width: 100%;
`;

// NOTE - PageIndicator components were based off of what's in `Onboarding.tsx`,
//        then I added the ability to make them bounce with motion
export const PageIndicatorList = styled(motion.div).attrs({
  variants: {
    initial: { transition: { staggerChildren: 0.2 } },
    animate: { transition: { staggerChildren: 0.2 } },
  },
})`
  grid-area: page;
  display: flex;
  gap: 6px;
  margin: 0;
  padding: 0;
  justify-self: center;
`;

const pageIndicatorVariants = {
  initial: {
    y: "0%",
  },
  animate: {
    y: "100%",
  },
};

const pageIndicatorTransition = {
  duration: 0.5,
  // NOTE - yoyo is deprecated but I couldn't find the equivalent way to express it in our version of Framer
  yoyo: Number.POSITIVE_INFINITY,
  ease: "easeInOut",
};

export const PageIndicator = styled(motion.span).attrs({
  variants: pageIndicatorVariants,
  transition: pageIndicatorTransition,
})<{ $active?: boolean }>(
  ({ $active, theme }) => css`
    margin: 0;
    list-style: none;
    height: 10px;
    width: 10px;
    background-color: ${theme.colorBase300};
    border-radius: ${theme.borderRadiusRound};

    ${
      $active &&
      css`
      background-color: ${theme.colorBase600};
      border-color: ${theme.colorBase600};
    `
    }
  `,
);

export const BackButton = styled(Button)`
  grid-area: back;
`;
export const NextButton = styled(Button)`
  grid-area: next;
`;
