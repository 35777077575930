export type AvatarData = {
  lastUpdated: number; // timestamp
  hasImage: boolean;
  forceUpdate?: boolean;
};

export type AvatarState = {
  profile: ReadonlyMap<string, AvatarData>;
  workspace: ReadonlyMap<string, AvatarData>;
};

export type AvatarType = keyof AvatarState;
