import { css, styled } from "styled-components";

import type { Api } from "../../../../../services";
import type { DataSourceConnectionStatus } from "../../../../../types";

export type StatusIndicatorProps = {
  status: DataSourceConnectionStatus | Api.ProxyConnectionStatus;
};

export function StatusIndicator(props: StatusIndicatorProps): JSX.Element {
  return <StyledStatusDot status={props.status} />;
}

const StyledStatusDot = styled.div<StatusIndicatorProps>(
  ({ theme, status }) => css`
    flex-grow: 0;
    background: ${
      /* sc-custom "ignore this" */ status === "connected"
        ? theme.color.fg.success
        : theme.color.fg.danger
    };
    border-radius: ${theme.radius.full};
    width: 8px;
    height: 8px;
  `,
);
