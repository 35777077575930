import { matchPath } from "react-router";

import { ROUTES, WORKSPACE_ROUTE } from "../constants";

export function containsMultipleParams(path: string) {
  return path.split("/:").length > 2;
}

export function containsWorkspaceName(pathname: string) {
  return pathname.startsWith(WORKSPACE_ROUTE);
}
export function findMatchingRoute(path: string) {
  for (const pattern of Object.values(ROUTES)) {
    const match = matchPath(pattern, path);
    if (match) {
      return match;
    }
  }
}

export const getWorkspaceNameParameter = (pathname: string) => {
  const match = matchPath(`${WORKSPACE_ROUTE}/*`, pathname);
  const workspaceName = match?.params.workspaceName;
  if (
    workspaceName &&
    // Make sure the match is not a known route like /notebooks
    !Object.values<string>(ROUTES).includes(`/${workspaceName}`)
  ) {
    return workspaceName;
  }
};
