import { useMemo } from "react";
import { useSelector } from "react-redux";

import { makeIsHighlightedSelector } from "../selectors";

export const FOCUS_HIGHLIGHT_DURATION = 150; // ms

export function useCellHighlight(cellId: string): boolean {
  const isHighlightedSelector = useMemo(
    () => makeIsHighlightedSelector(cellId),
    [cellId],
  );

  return useSelector(isHighlightedSelector);
}
