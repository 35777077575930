import { useEffect } from "react";

type Options = {
  /**
   * If `false`, the close handlers are disabled.
   */
  isActive?: boolean;

  /**
   * Callback that will be invoked when a close action is triggered. This can
   * be explicit (`reason: "close_requested"`, when the user pressed Escape, for
   * instance) or implicit because the focus is lost (`reason: "focus_lost"`).
   */
  onClose: ({ reason }: { reason: "close_requested" | "focus_lost" }) => void;

  /**
   * Optional DOM element for the popup container. Clicks that happen inside
   * this container will not trigger close actions.
   */
  popupRef: { current: HTMLElement | null };
};

/**
 * Registers handlers that will take to
 */
export function useClosePopupHandlers({
  isActive,
  onClose,
  popupRef,
}: Options) {
  useEffect(() => {
    if (isActive === false) {
      return;
    }

    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose({ reason: "close_requested" });
      }
    };

    const mouseDownHandler = (event: MouseEvent) => {
      const { target } = event;
      if (target instanceof Node && !popupRef.current?.contains(target)) {
        onClose({ reason: "focus_lost" });
      }
    };

    document.addEventListener("click", mouseDownHandler, true);
    document.addEventListener("keydown", keyDownHandler, true);
    return () => {
      document.removeEventListener("click", mouseDownHandler, true);
      document.removeEventListener("keydown", keyDownHandler, true);
    };
  }, [isActive, onClose, popupRef]);
}
