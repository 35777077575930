import { useHandler, usePrevious } from "@fiberplane/hooks";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Executes the callback when the location changes.
 * @param callback - The callback to execute when the location changes.
 * @example
 * function MyComponent() {
 *   useOnLocationChange(() => {
 *     console.log("Location changed!");
 *   });
 * }
 */
export function useOnLocationChange(
  callback: Parameters<typeof useHandler>[0],
) {
  const { pathname } = useLocation();
  const initialPath = usePrevious(pathname);

  const memoizedCallback = useHandler(callback);

  useEffect(() => {
    if (initialPath && initialPath !== pathname) {
      memoizedCallback();
    }
  }, [memoizedCallback, initialPath, pathname]);
}
