import { useEffect, useRef, useState } from "react";

import { Button, Input } from "@fiberplane/ui";
import type { TimeRange } from "../../../types";
import { timestampToDate, validateTimeRange } from "../../../utils";
import { MonthTable } from "./MonthTable";
import {
  Container,
  Form,
  FormContent,
  FormErrorMessage,
  FormHeader,
} from "./styled";

export type TimeRangePickerProps = {
  value: TimeRange;
  onChange: (value: TimeRange) => void;
};

export function TimeRangePicker(props: TimeRangePickerProps) {
  const [startTime, setStartTime] = useState(props.value.from);
  const [endTime, setEndTime] = useState(props.value.to);

  const startDate = timestampToDate(startTime);
  const endDate = timestampToDate(endTime);

  const localStartTime = startDate.toISOString();
  const localEndTime = endDate.toISOString();

  const [startError, setStartError] = useState<undefined | string>();
  const [endError, setEndError] = useState<undefined | string>();
  const [globalError, setGlobalError] = useState<undefined | string>();

  const handleSubmit = (event: React.FormEvent | React.KeyboardEvent) => {
    event.preventDefault();

    // Refs shouldn't be null, but it they are there isn't much to validate
    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
    if (!startRef.current || !endRef.current) {
      return;
    }

    const { errors, values } = validateTimeRange({
      from: startRef.current.value,
      to: endRef.current.value,
    });

    const hasErrors =
      Object.values(errors).findIndex((value) => !!value) !== -1;

    // Update state
    setStartError(errors.from);
    setEndError(errors.to);
    setGlobalError(errors.global);

    if (hasErrors) {
      return;
    }

    props.onChange(values);
  };

  const startRef = useRef<HTMLInputElement | null>(null);
  const endRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    startRef.current?.focus();
  }, []);

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.target !== startRef.current && event.target !== endRef.current) {
      return;
    }

    if (event.key !== "ArrowLeft" && event.key !== "ArrowRight") {
      return;
    }

    event.stopPropagation();
  };

  return (
    <Container>
      <MonthTable
        startTime={startTime}
        endTime={endTime}
        setStartTime={setStartTime}
        setEndTime={setEndTime}
      />
      <Form onSubmit={handleSubmit} onKeyDown={onKeyDown}>
        <FormContent>
          <FormHeader>Absolute time range</FormHeader>
          {globalError && <FormErrorMessage>{globalError}</FormErrorMessage>}
          <Input
            key={startTime}
            placeholder="From"
            type="text"
            name="startTime"
            className={startError || globalError ? "error" : ""}
            aria-invalid={startError || globalError ? true : undefined}
            defaultValue={localStartTime}
            ref={startRef}
            data-disable-notebook-keyboard-handlers
          />
          {startError && <FormErrorMessage>{startError}</FormErrorMessage>}
          <Input
            key={endTime}
            placeholder="To"
            type="text"
            defaultValue={localEndTime}
            className={endError || globalError ? "error" : ""}
            name="endTime"
            aria-invalid={endError || globalError ? true : undefined}
            data-disable-notebook-keyboard-handlers
            ref={endRef}
          />
          {endError && <FormErrorMessage>{endError}</FormErrorMessage>}
          <Button
            type="submit"
            buttonStyle="primary"
            onKeyUp={(event: React.KeyboardEvent<HTMLButtonElement>) => {
              if (event.key === " " || event.key === "Enter") {
                event.stopPropagation();
                handleSubmit(event);
              }
            }}
          >
            Apply
          </Button>
        </FormContent>
      </Form>
    </Container>
  );
}
