import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import useTitle from "react-use/lib/useTitle";

import {
  selectNotebookError,
  selectNotebookRevision,
  selectNotebookTitle,
  selectWindowFocused,
} from "../../../selectors";

export function useRevisionTitle() {
  const title = useSelector(selectNotebookTitle);
  const error = useSelector(selectNotebookError);
  const revision = useSelector(selectNotebookRevision);

  const lastSeenRevision = useRef(revision);
  const hasFocus = useSelector(selectWindowFocused);

  useEffect(() => {
    if (hasFocus) {
      lastSeenRevision.current = revision;
    }
  }, [hasFocus, revision]);

  const showChangeIndicator = !hasFocus && revision > lastSeenRevision.current;

  useTitle(error ? "Error" : showChangeIndicator ? `* ${title}` : title);
}
