// PromQL functions
// (https://prometheus.io/docs/prometheus/latest/querying/functions/)
export const promQlFunctions = [
  "abs",
  "absent",
  "ceil",
  "changes",
  "clamp_max",
  "clamp_min",
  "day_of_month",
  "day_of_week",
  "days_in_month",
  "delta",
  "deriv",
  "exp",
  "floor",
  "histogram_quantile",
  "holt_winters",
  "hour",
  "idelta",
  "increase",
  "irate",
  "label_join",
  "label_replace",
  "ln",
  "log2",
  "log10",
  "minute",
  "month",
  "predict_linear",
  "rate",
  "resets",
  "round",
  "scalar",
  "sort",
  "sort_desc",
  "sqrt",
  "time",
  "timestamp",
  "vector",
  "year",
  "avg_over_time",
  "min_over_time",
  "max_over_time",
  "sum_over_time",
  "count_over_time",
  "quantile_over_time",
  "stddev_over_time",
  "stdvar_over_time",
];
