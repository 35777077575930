import { Input } from "@fiberplane/ui";
import {
  type FieldPath,
  type FieldValues,
  useController,
} from "react-hook-form";
import { FieldDescription } from "../styled";
import type { FiberFormFieldProps } from "../types";
import { FiberFormFieldContainer } from "./FiberFormFieldContainer";

/**
 * A component for rendering a text input form field using Zod schema validation.
 * @param {FiberFormFieldProps<Values, FieldName>} props - Props for the FiberFormTextInputField component.
 * @returns {JSX.Element} The FiberFormTextInputField component.
 * @template Values - The type of form field values.
 * @template FieldName - The type of field name.
 */
export function FiberFormTextInputField<
  Values extends FieldValues,
  FieldName extends FieldPath<Values>,
>({
  control,
  description,
  label,
  name,
  rules,
}: FiberFormFieldProps<Values, FieldName>): JSX.Element {
  const { field, fieldState } = useController({ control, name, rules });

  const hasError = Boolean(fieldState.error);

  return (
    <FiberFormFieldContainer label={label} name={name} fieldState={fieldState}>
      <Input
        type="text"
        id={name}
        {...field}
        data-invalid={hasError}
        aria-invalid={hasError}
      />
      {description && <FieldDescription>{description}</FieldDescription>}
    </FiberFormFieldContainer>
  );
}
