import type { TableColumnDefinition, TableRow } from "../../../types";
import { TableCellField } from "./TableCellField";

type Props = {
  cellId: string;
  columnDefs: Array<TableColumnDefinition>;
  first?: boolean;
  hoveredColumnId: string | undefined;
  hoveredRowId: string | undefined;
  onHover: (field: string) => void;
  readOnly: boolean;
  row: TableRow;
};

export function TableCellRow({
  columnDefs,
  row,
  ...props
}: Props): JSX.Element {
  return (
    <>
      {row.values.map((value, index) => {
        const columnId = columnDefs[index]?.id;
        return columnId ? (
          <TableCellField
            {...props}
            columnId={columnId}
            index={index}
            key={columnId}
            rowId={row.id}
            value={value}
          />
        ) : null;
      })}
    </>
  );
}
