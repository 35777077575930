import { CELLS_MIME_TYPE, RICH_TEXT_MIME_TYPE } from "../../../../constants";
import {
  selectCellIds,
  selectCellsInSelection,
  selectNotebookFocus,
  selectSelectedRichTextInCell,
} from "../../../../selectors";
import { dispatch, getState } from "../../../../store";
import {
  exportCellsAsMarkdown,
  exportCellsAsPlainText,
  exportCellsJson,
} from "../../../../thunks";
import { getSelection, only } from "../../../../utils";
import { replaceSelection } from "../../thunks";

/**
 * Copies the selected text to the clipboard.
 */
export function copySelection(event: React.ClipboardEvent) {
  const state = getState();

  const focus = selectNotebookFocus(state);
  if (focus.type !== "selection") {
    return;
  }

  const cellIds = selectCellIds(state);
  const selection = getSelection(cellIds, focus);
  const cells = selectCellsInSelection(state, selection);

  event.clipboardData.setData(
    "text/plain",
    exportCellsAsPlainText(state, cells, focus),
  );

  const onlyCell = only(cells);
  if (onlyCell) {
    event.clipboardData.setData(
      `${RICH_TEXT_MIME_TYPE}+json`,
      JSON.stringify(
        selectSelectedRichTextInCell(state, onlyCell, focus),
        null,
        2,
      ),
    );
  } else {
    event.clipboardData.setData(
      "text/markdown",
      exportCellsAsMarkdown(state, cells, focus),
    );
    event.clipboardData.setData(
      `${CELLS_MIME_TYPE}+json`,
      JSON.stringify(exportCellsJson(state, cells, focus), null, 2),
    );
  }
}

/**
 * Copies the selected text to the clipboard and removes it from the cell.
 */
export function cutSelection(event: React.ClipboardEvent) {
  const focus = selectNotebookFocus(getState());
  if (focus.type === "selection") {
    copySelection(event);
    dispatch(replaceSelection(focus, ""));
  }
}
