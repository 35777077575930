import { Icon } from "@fiberplane/ui";
import {
  type ForwardedRef,
  type HTMLAttributes,
  forwardRef,
  useMemo,
} from "react";
import { css, styled } from "styled-components";

import type { LabelsEditorState } from "../../state";
import type { Label, LabelsEditorType } from "../../types";
import { formatLabel, omit, pick } from "../../utils";
import { StyledLabel } from "../UI";
import { EditedLabelItem } from "./EditedLabelItem";
import { LabelItem } from "./LabelItem";
import { StyledListItem } from "./styled";
import {
  LABEL_ITEM_OPTION_KEYS,
  type LabelItemOptions,
  type LabelsEditorOptions,
} from "./types";

type Props = LabelsEditorOptions &
  HTMLAttributes<HTMLUListElement> & {
    editor: LabelsEditorState | undefined;
    labels: ReadonlyArray<Label>;
    newLabelButtonLabel?: string;
    readOnly?: boolean;
    type: LabelsEditorType;
  };

function LabelsEditorComponent(
  {
    editor,
    labels,
    newLabelButtonLabel = "Add label",
    onBlur,
    onCreate,
    readOnly,
    type,
    ...props
  }: Props,
  ref: ForwardedRef<HTMLUListElement | null>,
): JSX.Element {
  const itemOptions: LabelItemOptions = pick(props, ...LABEL_ITEM_OPTION_KEYS);

  const htmlAttributes: HTMLAttributes<HTMLUListElement> = omit(
    props,
    ...LABEL_ITEM_OPTION_KEYS,
  );

  const popperKey = useMemo(
    () => labels.map((current) => formatLabel(current)).join(",") || "none",
    [labels],
  );

  const appendNewEditor =
    editor &&
    !(
      editor.original &&
      labels.some((label) => label.key === editor.original?.key)
    );

  return (
    <Container ref={ref} {...htmlAttributes}>
      {labels.map((label) =>
        editor?.original?.key === label.key ? (
          <EditedLabelItem
            editor={editor}
            key={label.key}
            labels={labels}
            onBlur={onBlur}
            popperKey={popperKey}
            type={type}
            {...itemOptions}
          />
        ) : (
          <LabelItem
            faded={!!editor?.original && editor.original.key !== label.key}
            key={label.key}
            label={label}
            popperKey={popperKey}
            readOnly={readOnly}
            {...itemOptions}
          />
        ),
      )}
      {appendNewEditor && (
        <EditedLabelItem
          editor={editor}
          labels={labels}
          onBlur={onBlur}
          popperKey={popperKey}
          type={type}
          {...itemOptions}
        />
      )}
      {onCreate && !readOnly && !editor && (
        <StyledListItem>
          <StyledLabelButton
            data-testid="add-label"
            forwardedAs="button"
            onClick={onCreate}
          >
            <StyledIcon iconType="plus" />
            {newLabelButtonLabel}
          </StyledLabelButton>
        </StyledListItem>
      )}
    </Container>
  );
}

export const LabelsEditor = forwardRef(LabelsEditorComponent);

const Container = styled.ul`
  align-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  height: 1em;
  width: 1em;
`;

const StyledLabelButton = styled(StyledLabel)(
  ({ theme }) => css`
    background-color: transparent;
    color: ${theme.color.fg.subtle};
    cursor: pointer;

    &:hover {
      background-color: ${theme.color.bg.elevated.hover};
    }
  `,
);
