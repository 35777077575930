import { addErrorNotification } from ".";
import { selectActiveNotebook, selectActiveWorkspaceId } from "../selectors";
import { Api } from "../services";
import type { Thunk } from "../store";
import type { Event } from "../types";
import { invokeProviderCell } from "./providerThunks";

export const upsertEvent = ({
  id,
  title,
  labels,
  occurrenceTime,
}: Event): Thunk => {
  return async (_, getState) => {
    const state = getState();
    const workspaceId = selectActiveWorkspaceId(state);
    if (!workspaceId) {
      return;
    }

    if (id) {
      await Api.deleteEvent(id);
    }

    return await Api.createEvent(workspaceId, {
      title,
      labels,
      time: occurrenceTime,
    });
  };
};

export const revalidateEvents = (): Thunk => {
  return (dispatch, getState) => {
    const notebook = selectActiveNotebook(getState());
    const providerCells = notebook?.cellIds.filter((id) => {
      const { cell } = notebook?.cellsById[id] || {};
      return (
        cell?.type === "provider" && cell?.intent.startsWith("fiberplane,event")
      );
    });
    for (const cellId of providerCells) {
      dispatch(invokeProviderCell(cellId));
    }
  };
};

export const deleteEvent =
  (id: string): Thunk =>
  async (dispatch) => {
    try {
      await Api.deleteEvent(id);
    } catch (error) {
      dispatch(addErrorNotification(`Cannot remove event. Reason: ${error}`));
    }
  };
