import {
  ADD_VIEW_EDITOR_LABEL,
  type AppAction,
  CLEAR_VIEW_EDITOR,
  EDIT_VIEW,
  REMOVE_VIEW_EDITOR_LABEL,
  UPDATE_VIEW_EDITOR_LABEL,
} from "../actions";
import type { RootState, ViewsState } from "../state";
import { formatLabel } from "../utils";

const initialState: ViewsState = {
  editor: undefined,
};

export function viewsReducer(
  rootState: RootState | undefined,
  action: AppAction,
): ViewsState {
  const state = rootState?.views ?? initialState;

  switch (action.type) {
    case ADD_VIEW_EDITOR_LABEL: {
      if (!state.editor) {
        return state;
      }

      return {
        ...state,
        editor: {
          ...state.editor,
          labels: {
            ...state.editor.labels,
            value: [...state.editor.labels.value, action.payload],
          },
        },
      };
    }
    case CLEAR_VIEW_EDITOR:
      return { ...state, editor: initialState.editor };

    case EDIT_VIEW:
      return {
        ...state,
        editor: {
          labels: { value: action.payload?.labels ?? [] },
        },
      };

    case REMOVE_VIEW_EDITOR_LABEL: {
      if (!state.editor) {
        return state;
      }

      return {
        ...state,
        editor: {
          ...state.editor,
          labels: {
            ...state.editor.labels,
            value: state.editor.labels.value.filter(
              (label) => formatLabel(label) !== formatLabel(action.payload),
            ),
          },
        },
      };
    }
    case UPDATE_VIEW_EDITOR_LABEL: {
      if (!state.editor) {
        return state;
      }

      return {
        ...state,
        editor: {
          ...state.editor,
          labels: {
            ...state.editor.labels,
            value: state.editor.labels.value.map((label) => {
              if (label.key === action.payload.original.key) {
                return action.payload.updated;
              }

              return label;
            }),
          },
        },
      };
    }

    default:
      return state;
  }
}
