import { charCount } from "../../../../utils";
import {
  type CursorCoordinates,
  getLineHeightForContainer,
} from "./coordinates";

/**
 * Returns the offset in the text for the given coordinates.
 *
 * See [Measuring coordinates](../../../../../docs/EDITOR.md).
 */
export function getOffsetForCoordinatesInternal(
  containerEl: HTMLElement,
  text: string,
  coords: CursorCoordinates,
  getCoordinatesForOffset: (
    containerEl: HTMLElement,
    text: string,
    offset: number,
  ) => CursorCoordinates | null,
): number {
  const halfLineHeight = getLineHeightForContainer(containerEl) / 2;

  let min = 0;
  let minCoords = getCoordinatesForOffset(containerEl, text, min);
  if (!minCoords || isGreater(minCoords, coords, halfLineHeight)) {
    return min;
  }

  let max = charCount(text);
  let maxCoords = getCoordinatesForOffset(containerEl, text, max);
  if (!maxCoords || isGreater(coords, maxCoords, halfLineHeight)) {
    return max;
  }

  // Find the nearest offset using a binary search approach on the coordinates
  // for each offset. When determining which coordinate is closer, the Y
  // coordinate takes precedence over the X coordinate. This is because it is
  // more important to end up on the right line than it is to end up at the
  // right column. Additionally, the binay search algorithm wouldn't work
  // otherwise, because offsets on the same line share their Y coordinate,
  // meaning that checking for the X coordinate first could cause the algorithm
  // to "zoom in" on a local optimum on the wrong line.
  while (max > min) {
    if (min === max - 1) {
      // We only have two offsets left to choose from, so return the nearest:
      return getNearestOffsetFromCoordinates(
        coords,
        min,
        minCoords,
        max,
        maxCoords,
      );
    }

    const mid = Math.floor((min + max) / 2);
    const midCoords = getCoordinatesForOffset(containerEl, text, mid);
    if (!midCoords) {
      break;
    }

    if (isGreater(midCoords, coords, halfLineHeight)) {
      max = mid;
      maxCoords = midCoords;
    } else {
      min = mid;
      minCoords = midCoords;
    }
  }

  return min;
}

/**
 * Returns the nearest offset based on which coordinate is closest to the actual
 * coordinate.
 */
function getNearestOffsetFromCoordinates(
  { x: actualX, y: actualY }: CursorCoordinates,
  minOffset: number,
  { x: minX, y: minY }: CursorCoordinates,
  maxOffset: number,
  { x: maxX, y: maxY }: CursorCoordinates,
): number {
  return minY === maxY
    ? getNearestOffsetFromValues(actualX, minOffset, minX, maxOffset, maxX)
    : getNearestOffsetFromValues(actualY, minOffset, minY, maxOffset, maxY);
}

/**
 * Returns the nearest offset based on which value is closest to the actual
 * value.
 */
function getNearestOffsetFromValues(
  actualValue: number,
  minOffset: number,
  minValue: number,
  maxOffset: number,
  maxValue: number,
): number {
  if (actualValue < minValue) {
    return minOffset;
  }

  if (actualValue > maxValue) {
    return maxOffset;
  }

  const minDiff = actualValue - minValue;
  const maxDiff = maxValue - actualValue;
  return minDiff < maxDiff ? minOffset : maxOffset;
}

/**
 * Returns whether one set of cursor coordinates is greater than (comes after)
 * another.
 */
function isGreater(
  coords: CursorCoordinates,
  other: CursorCoordinates,
  halfLineHeight: number,
) {
  if (coords.y > other.y + halfLineHeight) {
    return true; // The Y coordinate is at least one line below.
  }

  // Greater if they're on the same line, but the X coordinate is to the right.
  return coords.y >= other.y - halfLineHeight && coords.x > other.x;
}
