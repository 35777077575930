import { useState } from "react";

import { ONE_MINUTE } from "../../constants";
import { useTimeout } from "../../hooks";
import { formatDate, formatRelativeDate } from "../../utils";
import { Text, type TextProps } from "./Text";

type Props = TextProps & {
  timestamp: Date | string;
};

export function RelativeTimestamp({ timestamp, ...rest }: Props): JSX.Element {
  const date = typeof timestamp === "string" ? new Date(timestamp) : timestamp;

  const [relative, setRelative] = useState(() => formatRelativeDate(date));
  useTimeout(() => {
    const newRelative = formatRelativeDate(date);
    if (newRelative !== relative) {
      setRelative(newRelative);
    }
  }, ONE_MINUTE * 1000);

  rest.title ??= formatDate(date);

  return <Text {...rest}>{relative}</Text>;
}
