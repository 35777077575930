import { useMemo } from "react";
import { useSelector } from "react-redux";
import { push } from "redux-first-history";
import { createSelector } from "reselect";

import { setCommandMenuVariant } from "../../../../../actions";
import { useListNotebooksQuery } from "../../../../../api";
import { DEFAULT_NOTEBOOK_TITLE } from "../../../../../constants";
import {
  selectActiveWorkspaceIdOrThrow,
  selectActiveWorkspaceName,
  selectCommandMenuVariantType,
} from "../../../../../selectors";
import { dispatch } from "../../../../../store";
import type {
  NotebooksCommand as NotebookCommand,
  NotebookSummary,
} from "../../../../../types";
import { createNotebookLink, sortBy } from "../../../../../utils";

const EMPTY_NOTEBOOKS_LIST: Array<NotebookSummary> = [];
const MAX_RECENT_NOTEBOOKS = 4;

export function useRecentNotebooksCommands(): Array<NotebookCommand> {
  const { variantType, workspaceId, workspaceName } = useSelector(
    selectNotebooksCommandsState,
  );

  const { data: recentNotebooks = EMPTY_NOTEBOOKS_LIST } =
    useListNotebooksQuery({ workspaceId }, { skip: !workspaceId });

  return useMemo(() => {
    if (variantType === "none" && recentNotebooks.length > 0 && workspaceName) {
      return sortBy([...recentNotebooks], ({ updatedAt }) => updatedAt, true)
        .slice(0, MAX_RECENT_NOTEBOOKS)
        .map(
          (notebook): NotebookCommand => ({
            key: `notebooks/open/${notebook.id}`,
            type: "recentNotebooks",
            title: notebook.title || DEFAULT_NOTEBOOK_TITLE,
            notebook,
            icon: "notebook",
            onActivate: () => {
              dispatch(
                push(
                  createNotebookLink(
                    workspaceName,
                    notebook.id,
                    notebook.title,
                  ),
                ),
              );
              dispatch(setCommandMenuVariant({ type: "none" }));
            },
          }),
        );
    }

    return [];
  }, [variantType, recentNotebooks, workspaceName]);
}

const selectNotebooksCommandsState = createSelector(
  [
    selectActiveWorkspaceIdOrThrow,
    selectActiveWorkspaceName,
    selectCommandMenuVariantType,
  ],
  (workspaceId, workspaceName, variantType) => ({
    variantType,
    workspaceId,
    workspaceName,
  }),
);
