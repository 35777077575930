export function formatBullet(level: number | undefined): string {
  switch (level) {
    case 1:
      return "◦";
    case 2:
      return "▪";
    default:
      return "•";
  }
}

const CAPITAL_A_CHAR_CODE = 65;
const LOWERCASE_A_CHAR_CODE = 97;

export function formatCounter(
  {
    level0Preface,
    level1Preface,
    level2Preface,
  }: {
    level0Preface: number;
    level1Preface: number;
    level2Preface: number;
  },
  level: number | undefined,
): string {
  switch (level) {
    case 1:
      return formatAlphabeticCounter(level1Preface, CAPITAL_A_CHAR_CODE);
    case 2:
      return formatAlphabeticCounter(level2Preface, LOWERCASE_A_CHAR_CODE);
    default:
      return level0Preface.toString();
  }
}

export function formatAlphabeticCounter(
  n: number,
  baseCharCode: number,
): string {
  let counter = "";
  let remaining = n;
  while (true) {
    const rest = (remaining - 1) % 26;
    counter = String.fromCodePoint(baseCharCode + rest) + counter;
    remaining -= rest + 1;
    if (remaining === 0) {
      break;
    }

    remaining /= 26;
  }

  return counter;
}
