import { useHandler } from "@fiberplane/hooks";
import { Icon, IconButton } from "@fiberplane/ui";
import type { Transition, Variants } from "framer-motion";
import { motion } from "framer-motion";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { css, styled } from "styled-components";

import { replaceText, setFocus } from "../../../actions";
import { useCellFocus, useTimeAgo } from "../../../hooks";
import { makeCellDraftSelector } from "../../../selectors";
import { useActiveNotebookDispatch } from "../../../store";
import { deleteComment, updateComment } from "../../../thunks";
import {
  type CommentThreadItem,
  type ThreadItem as Item,
  type ThreadStatus,
  type User,
  isCommentDeleteThreadItem,
  isCommentThreadItem,
  isStatusChangeThreadItem,
} from "../../../types";
import { charCount } from "../../../utils";
import { ProfileAvatar as BaseProfileAvatar } from "../../Avatar";
import { MenuItem, ToggleMenu } from "../../UI";
import { StyledRichTextInput } from "./StyledRichTextInput";

type ThreadItemProps = {
  cellId: string;
  firstComment: boolean;
  item: Item;
  threadId: string;
  threadStatus: ThreadStatus;
  user: User;
};

export function ThreadItem({
  cellId,
  firstComment,
  item,
  threadId,
  threadStatus,
  user,
}: ThreadItemProps) {
  const [isEditing, setIsEditing] = useState(false);

  const dispatch = useActiveNotebookDispatch();

  const field = item.id;
  const focus = useCellFocus(cellId, field);

  const draftSelector = useMemo(
    () => makeCellDraftSelector(cellId, field),
    [cellId, field],
  );
  const { formatting: draftFormatting, text: draftText } =
    useSelector(draftSelector);

  const formattedTime = useTimeAgo(item.createdAt);

  const handleEditComment = useHandler(() => {
    if (isCommentThreadItem(item)) {
      setIsEditing(true);

      // Will initialize the draft as a side-effect.
      dispatch(
        setFocus({
          type: "collapsed",
          cellId,
          field,
          offset: charCount(item.content),
        }),
      );
    }
  });

  const handleCancelEditComment = useHandler(() => {
    if (isCommentThreadItem(item)) {
      setIsEditing(false);

      // Replace draft with item's original content.
      dispatch(
        replaceText({
          cellId,
          field,
          offset: 0,
          newFormatting: item.formatting,
          newText: item.content,
          oldText: draftText,
        }),
      );
    }
  });

  const handleDeleteComment = useHandler((comment: CommentThreadItem) => {
    dispatch(deleteComment({ comment, threadId }));
  });

  const handleUpdateComment = useHandler(() => {
    dispatch(
      updateComment({
        commentId: item.id,
        threadId,
        content: draftText,
        formatting: draftFormatting,
      }),
    );

    setIsEditing(false);
  });

  return (
    <ThreadItemContainer
      $threadResolved={threadStatus === "resolved"}
      variants={threadItemVariants}
      transition={threadItemTransition}
      // When performing a refresh only the top comment is visible. In that case
      // we don't want the animation to be triggered.
      initial={firstComment ? false : "initial"}
      animate="animate"
      exit="initial"
    >
      <ProfileAvatar name={item.createdBy.name} userId={item.createdBy.id} />

      <AuthorContainer>
        {isCommentThreadItem(item) && (
          <>
            <AuthorName>{item.createdBy.name}</AuthorName>
            <BulletContainer>&bull;</BulletContainer>
            <Timestamp>{formattedTime} </Timestamp>

            {item.createdAt !== item.updatedAt && (
              <>
                <BulletContainer>&bull;</BulletContainer>
                <EditedIndicator>edited</EditedIndicator>
              </>
            )}
          </>
        )}

        {isCommentDeleteThreadItem(item) && (
          <ThreadMessage>This reply has been deleted</ThreadMessage>
        )}
        {isStatusChangeThreadItem(item) && (
          <ThreadMessage>
            Thread {item.status === "resolved" ? "resolved" : "reopened"} by{" "}
            {item.createdBy.name}
          </ThreadMessage>
        )}
      </AuthorContainer>

      <VerticalLine />

      {isCommentThreadItem(item) ? (
        <>
          <ContentWrapper $firstComment={firstComment}>
            <StyledRichTextInput
              cellId={cellId}
              field={field}
              focus={focus}
              readOnly={!isEditing}
              formatting={isEditing ? draftFormatting : item.formatting}
              value={isEditing ? draftText : item.content}
            />

            {isEditing && (
              <EditContainer>
                <EditButton onClick={handleCancelEditComment}>
                  Cancel
                </EditButton>

                <EditButtonSpacer />

                <EditButton onClick={handleUpdateComment}>Save</EditButton>
              </EditContainer>
            )}
          </ContentWrapper>

          {item.createdBy.id === user.id && (
            <IconButtonWrapper>
              <ToggleMenu
                offset={[0, 2]}
                absolutePositioned
                placement="bottom-end"
                toggleElement={({ anchorRef }) => (
                  <div ref={anchorRef}>
                    <IconButton
                      iconType="ellipsis"
                      buttonStyle="tertiary-grey"
                    />
                  </div>
                )}
              >
                <MenuItem
                  id="comment-edit"
                  onActivate={handleEditComment}
                  title={firstComment ? "Edit discussion text" : "Edit reply"}
                  iconLeft={
                    <Icon iconType="pencil_simple" height={16} width={16} />
                  }
                />
                {firstComment ? null : (
                  <MenuItem
                    id="comment-delete"
                    onActivate={() => handleDeleteComment(item)}
                    title="Delete reply"
                    iconLeft={<Icon iconType="trash" height={16} width={16} />}
                  />
                )}
              </ToggleMenu>
            </IconButtonWrapper>
          )}
        </>
      ) : (
        // This element adds spacing below the ThreadMessage & the vertical
        // line underneath the user icon when a comment is deleted or the thread
        // status has changed.
        <ThreadMessageSpacer />
      )}
    </ThreadItemContainer>
  );
}

const threadItemVariants: Variants = {
  initial: {
    height: 0,
    opacity: 0,
  },
  animate: {
    height: "unset",
    opacity: 1,
    transition: {
      duration: 0.1,
    },
  },
};

const threadItemTransition: Transition = {
  type: "just",
};

const ProfileAvatar = styled(BaseProfileAvatar)`
  margin: auto;
  grid-column: 1 / 2;
`;

const ThreadItemContainer = styled(motion.div)<{ $threadResolved: boolean }>(
  ({ $threadResolved }) => css`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    gap: 2px 16px;
    position: relative;

    /* Hide the verical line & spacer elements in case the thread is resolved */
    ${
      $threadResolved &&
      css`
      &:last-child > div:nth-last-child(-n + 2) {
        display: none;
      }
    `
    }
  `,
);

const AuthorContainer = styled.div`
  grid-column: 2 / 3;
  display: flex;
  align-items: center;
`;

const AuthorName = styled.p(
  ({ theme }) => css`
    margin: 0;
    font: ${theme.font.body.md.medium};
    color: ${theme.color.fg.default};
  `,
);

const VerticalLine = styled.div`
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  width: 1px;
  height: calc(100% - 2px);
  justify-self: center;
  background-color: ${({ theme }) => theme.color.border.default};
  padding-bottom: 2px;
`;

const ContentWrapper = styled.div<{ $firstComment: boolean }>(
  ({ $firstComment }) => css`
    position: relative;
    margin-bottom: 20px;

    ${
      $firstComment &&
      css`
        margin: 0;
        grid-column: 1 / 3;
      `
    }
  `,
);

const BulletContainer = styled.p(
  ({ theme }) => css`
    margin: 0 8px;
    font-size: ${theme.font.body.md.regular};
    color: ${theme.color.bg.subtle};
  `,
);

const Timestamp = styled.p(
  ({ theme }) => css`
    margin: 0;
    font: ${theme.font.body.sm.medium};
    color: ${theme.color.fg.subtle};
  `,
);

const EditedIndicator = styled(Timestamp)`
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  font-style: italic;
`;

/**
 * Element that will be rendered to display a message in case a comment gets
 * deleted, or the discussion status changes.
 */
const ThreadMessage = styled(Timestamp)`
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  font-style: italic;
`;

const ThreadMessageSpacer = styled.div`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  height: 20px;
`;

const IconButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const EditContainer = styled.div(
  ({ theme }) => css`
    position: absolute;
    bottom: 0;
    right: 0;
    display: grid;
    grid-template-columns: auto 1px auto;
    gap: 8px;
    border-radius: ${theme.radius.minimal};
    margin: 6px;
    padding: 4px 12px;
    border: 1px solid ${theme.color.border.muted};
    background-color: ${theme.color.bg.default};
  `,
);

const EditButtonSpacer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.color.border.default};
`;

const EditButton = styled.button(
  ({ theme }) => css`
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    background: unset;
    font: ${theme.font.headings.h5};
    color: ${theme.color.fg.muted};
    cursor: pointer;
  `,
);
