import type { ThreadItem } from ".";

export type CommentThreadItem = Extract<ThreadItem, { type: "comment" }>;
export type CommentDeleteThreadItem = Extract<
  ThreadItem,
  { type: "comment_delete" }
>;
export type StatusChangeThreadItem = Extract<
  ThreadItem,
  { type: "status_change" }
>;

export function isCommentThreadItem(
  item: ThreadItem,
): item is CommentThreadItem {
  return item.type === "comment";
}

export function isCommentDeleteThreadItem(
  item: ThreadItem,
): item is CommentDeleteThreadItem {
  return item.type === "comment_delete";
}

export function isStatusChangeThreadItem(
  item: ThreadItem,
): item is StatusChangeThreadItem {
  return item.type === "status_change";
}
