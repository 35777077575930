import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CLEAR_AUTHENTICATION } from "../../actions";
import { normalizeException } from "../../errors";
import { selectActiveWorkspaceIdOrThrow } from "../../selectors";
import { Api } from "../../services";
import { dispatch, getState } from "../../store";
import type {
  LoadableData,
  LoadableMutation,
  NewPagerDutyReceiver,
  PagerDutyReceiver,
  UpdatePagerDutyReceiver,
} from "../../types";
import {
  addLoadableDataReducerCases,
  addLoadableMutationReducerCases,
  getInitialLoadableDataState,
  getInitialLoadableMutationState,
} from "../utils";
import { IntegrationType, loadIntegrationById } from "./integrations";

export interface PagerDutyState {
  create: LoadableMutation<PagerDutyReceiver>;
  receivers: LoadableData<Array<PagerDutyReceiver>>;
}

const initialState: PagerDutyState = {
  create: getInitialLoadableMutationState(),
  receivers: getInitialLoadableDataState(),
};

export const loadReceivers = createAsyncThunk(
  "pagerduty/loadReceivers",
  async (_, { signal }) => {
    const workspaceId = selectActiveWorkspaceIdOrThrow(getState());
    return await Api.listPagerDutyReceivers({ signal, workspaceId });
  },
);

export const createReceiver = createAsyncThunk(
  "pagerduty/createReceiver",
  async (
    {
      pagerDutyReceiverName,
      ...newPagerDutyReceiver
    }: {
      pagerDutyReceiverName: string;
    } & NewPagerDutyReceiver,
    { signal },
  ) => {
    const workspaceId = selectActiveWorkspaceIdOrThrow(getState());

    try {
      const result = await Api.createPagerDutyReceiver({
        signal,
        newPagerDutyReceiver,
        workspaceId,
        pagerDutyReceiverName,
      });

      if ("error" in result) {
        return result;
      }

      await dispatch(loadReceivers());

      await dispatch(
        loadIntegrationById({
          type: IntegrationType.Workspace,
          id: "pagerdutywebhook",
        }),
      );

      return result;
    } catch (exception) {
      const error = normalizeException(exception);
      return error;
    }
  },
);

export const deleteReceiver = createAsyncThunk(
  "pagerduty/deleteReceiver",
  async (name: PagerDutyReceiver["name"], { signal }) => {
    const workspaceId = selectActiveWorkspaceIdOrThrow(getState());
    await Api.deletePagerDutyReceiver({ name, signal, workspaceId });
    await dispatch(loadReceivers());
    await dispatch(
      loadIntegrationById({
        type: IntegrationType.Workspace,
        id: "pagerdutywebhook",
      }),
    );
  },
);

export const updateReceiver = createAsyncThunk(
  "pagerduty/updateReceiver",
  async (
    {
      name,
      updatePagerDutyReceiver,
    }: { name: string; updatePagerDutyReceiver: UpdatePagerDutyReceiver },
    { signal },
  ) => {
    const workspaceId = selectActiveWorkspaceIdOrThrow(getState());
    const receiver = await Api.updatePagerDutyReceiver({
      name,
      signal,
      updatePagerDutyReceiver,
      workspaceId,
    });
    await dispatch(loadReceivers());
    await dispatch(
      loadIntegrationById({
        type: IntegrationType.Workspace,
        id: "pagerdutywebhook",
      }),
    );
    return receiver;
  },
);

export const pagerDutySlice = createSlice({
  name: "pagerduty",
  initialState,
  reducers: {
    unloadReceivers(state) {
      state.receivers = getInitialLoadableDataState();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CLEAR_AUTHENTICATION, () => ({ ...initialState }));

    addLoadableDataReducerCases(
      builder,
      loadReceivers,
      (state) => state.receivers,
    );

    addLoadableMutationReducerCases(
      builder,
      createReceiver,
      (state) => state.create,
    );
  },
});
