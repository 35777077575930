import { normalizeException } from "../../errors";
import { createAuthHeaders, informSessionExpiredListeners } from "./auth";

// TODO: Move request handlers to ./client.ts to support known error types returned by the API, currently only post is supported

/**
 * Performs a DELETE request.
 */
export function deleteRequest(
  url: string,
  options?: RequestInit,
): Promise<void> {
  return fetch(url, {
    ...options,
    headers: createAuthHeaders(),
    method: "DELETE",
  })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
    })
    .catch(handleError);
}

/**
 * Performs a GET request and uses `responseHandler` to parse or pass along the
 * response data.
 */
export function getRequest<ResponseData>(
  url: string,
  responseHandler: (response: Response) => Promise<ResponseData>,
  options?: RequestInit,
): Promise<ResponseData> {
  return fetch(url, { ...options, headers: createAuthHeaders() })
    .then((response) => {
      if (response.ok) {
        return responseHandler(response);
      } else {
        throw response;
      }
    })
    .catch(handleError);
}

/**
 * Performs a PATCH request with a `multipart/form-data` payload.
 */
export function patchJsonRequest<ResponseData, RequestData = object>(
  url: string,
  postData: RequestData,
  responseHandler: (response: Response) => ResponseData | Promise<ResponseData>,
  options?: RequestInit,
): Promise<ResponseData> {
  return fetch(url, {
    ...options,
    body: JSON.stringify(postData),
    headers: { ...createAuthHeaders(), "Content-Type": "application/json" },
    method: "PATCH",
  })
    .then((response) => {
      if (response.ok) {
        return responseHandler(response);
      } else {
        throw response;
      }
    })
    .catch(handleError);
}

type RawRequestParams<ResponseData> = {
  url: string;
  headers?: Record<string, string>;
  body?: FormData | Uint8Array | string;
  responseHandler: (response: Response) => ResponseData | Promise<ResponseData>;
  options?: RequestInit;
};

/**
 * Performs a POST request with a plain string or binary payload.
 */
export function postRawRequest<ResponseData>({
  url,
  headers,
  body,
  responseHandler,
  options,
}: RawRequestParams<ResponseData>): Promise<ResponseData> {
  return fetch(url, {
    ...options,
    body,
    headers: { ...createAuthHeaders(), ...headers },
    method: "POST",
  })
    .then((response) => {
      if (response.ok) {
        return responseHandler(response);
      } else {
        throw response;
      }
    })
    .catch(handleError);
}

/**
 * Performs a POST request with a JSON payload.
 * @deprecated This client is incompatible with errors returned as JSON. The new client is implemented in ./client.ts
 */
export function postJsonRequestDeprecated<
  ResponseData,
  RequestData = object | null,
>(
  url: string,
  postData: RequestData,
  responseHandler: (response: Response) => ResponseData | Promise<ResponseData>,
  options?: RequestInit,
): Promise<ResponseData> {
  return postRawRequest<ResponseData>({
    url,
    headers: { "Content-Type": "application/json" },
    body: postData === null ? undefined : JSON.stringify(postData),
    responseHandler,
    options,
  });
}

/**
 * Performs a POST request with a `multipart/form-data` payload.
 */
export function postMultipartFormRequest<
  ResponseData,
  RequestData extends Record<string, string | Blob> = Record<
    string,
    string | Blob
  >,
>(
  url: string,
  postData: RequestData,
  responseHandler: (response: Response) => ResponseData | Promise<ResponseData>,
  options?: RequestInit,
): Promise<ResponseData> {
  const body = new FormData();
  for (const [key, value] of Object.entries(postData)) {
    body.append(key, value);
  }

  return postRawRequest({ url, body, responseHandler, options });
}

export function withQueryArgs(url: string, args: object): string {
  const params: Array<string> = [];
  for (const [key, value] of Object.entries(args)) {
    params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
  }

  return `${url}?${params.join("&")}`;
}

export function binaryResponse(response: Response): Promise<Uint8Array> {
  return response.arrayBuffer().then((buffer) => new Uint8Array(buffer));
}

export function parseJsonResponse<T>(response: Response): Promise<T> {
  return response.json() as Promise<T>;
}

export function textResponse(response: Response): Promise<string> {
  return response.text();
}

function handleError(error_: unknown): never {
  const error = normalizeException(error_);
  informSessionExpiredListeners(error);
  throw error;
}
