import { parseColor } from "./parseColor";

/**
 * Returns a color with the opacity set to the given opacity.
 *
 * Any existing alpha channel of the color will be ignored.
 */
export function withOpacity(color: string, opacity: number): string {
  const [r, g, b] = parseColor(color);
  return `rgba(${r},${g},${b},${opacity})`;
}
