import { Icon, stopPropagation } from "@fiberplane/ui";
import { type Variants, motion, useReducedMotion } from "framer-motion";
import type React from "react";
import { css, styled } from "styled-components";

import { useNotebookIsPinnedQuery } from "../../../api";
import { DEFAULT_NOTEBOOK_TITLE } from "../../../constants";
import type {
  CreatedBy,
  Label as LabelModel,
  NotebookVisibility,
} from "../../../types";
import { getCreatedByLabel } from "../../../utils";
import {
  LabelList,
  PageContentItem,
  RadioButton,
  RelativeTimestamp,
  Text,
} from "../../UI";

const MAX_LABELS = 4;

type Props = {
  id: string;
  createdAt?: string;
  createdBy?: CreatedBy;
  labels?: Array<LabelModel>;
  title: string;
  visibility?: NotebookVisibility;
  selected?: boolean;
  onDoubleClick: (event: React.MouseEvent<HTMLElement>) => void;
};

export function NotebookOption({
  id,
  createdAt,
  createdBy,
  title,
  visibility,
  labels,
  selected,
  onDoubleClick,
}: Props): JSX.Element {
  const shouldReduceMotion = useReducedMotion();

  const hasLabels = !!(labels && labels.length > 0);
  const hasMeta = !!createdAt || !!createdBy || visibility;
  const { data: isPinned } = useNotebookIsPinnedQuery(id);

  const pageContentItemStyles = getPageContentItemStyles(
    !!hasLabels,
    !!hasMeta,
  );

  return (
    <motion.div
      variants={shouldReduceMotion ? reducedMotionVariants : motionVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <label onDoubleClick={onDoubleClick}>
        <PageContentItem
          data-testid="notebook-link"
          data-notebook-id={id}
          tabIndex={0}
          style={pageContentItemStyles}
        >
          <Option>
            <StyledRadioButton
              value={id}
              defaultChecked={selected}
              name="notebook"
            />
          </Option>

          <Title onClick={stopPropagation} data-testid="notebook-title">
            {title || DEFAULT_NOTEBOOK_TITLE}
            {isPinned && (
              <PinnedIcon iconType="push_pin" data-testid="pin-icon" />
            )}
          </Title>
          {hasMeta && (
            <Meta>
              <Text>
                {createdAt && (
                  <>
                    Created <RelativeTimestamp timestamp={createdAt} />
                  </>
                )}
                {createdBy && ` ${getCreatedByLabel(createdBy)}`}
              </Text>

              {visibility === "public" && (
                <PublicIndicator>
                  <GlobeIcon iconType="globe" />
                  <Text>Public</Text>
                </PublicIndicator>
              )}
            </Meta>
          )}

          {hasLabels && (
            <LabelsContainer>
              <LabelList labels={labels} max={MAX_LABELS} />
            </LabelsContainer>
          )}
        </PageContentItem>
      </label>
    </motion.div>
  );
}

const reducedMotionVariants: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { type: "tween" },
};

const motionVariants: Variants = {
  initial: {
    x: 0,
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
  exit: { x: -50, opacity: 0, height: 0 },
  transition: { type: "tween" },
};

function getPageContentItemStyles(
  hasLabels: boolean,
  hasMeta: boolean,
): React.CSSProperties {
  const gridTemplateAreas = (() => {
    if (hasMeta && hasLabels) {
      return `"option title" "option meta" "option labels"`;
    }

    if (hasMeta) {
      return `"option title" "option meta"`;
    }

    if (hasLabels) {
      return `"option title" "option labels"`;
    }

    return "option title";
  })();

  return {
    display: "grid",
    gridTemplateColumns: "40px auto",
    rowGap: "10px",
    alignItems: "center",
    gridTemplateAreas,
  };
}

const Option = styled.div`
  grid-area: option;
  display: grid;
  align-items: start;
  padding: 0 10px;
`;

const StyledRadioButton = styled(RadioButton)`
  display: block;
  align-self: center;
`;

const GlobeIcon = styled(Icon)`
  height: 14px;
  margin: 0 6px 0 8px;
  vertical-align: text-bottom;
  width: 14px;
`;

const PublicIndicator = styled(Text)`
  color: ${({ theme }) => theme.colorBase800};
`;

const Title = styled.div(
  ({ theme }) => css`
    grid-area: title;
    display: flex;
    text-decoration: none;
    line-height: 1;
    font-size: ${theme.fontNotebooksBaseFontSize};
    font-weight: ${theme.fontStudioStrongRegularFontWeight};
    color: ${theme.colorBase800};
  `,
);

const PinnedIcon = styled(Icon)`
  height: 1em;
  width: 1em;
  margin-left: 8px;
`;

const Meta = styled(Text)(
  ({ theme }) => css`
    color: ${theme.colorBase600};
    font-size: ${theme.fontNotebooksSmallerFontSize};
    font-weight: ${theme.fontNotebooksSmallerFontWeight};
    line-height: 1;
  `,
);

const LabelsContainer = styled.div`
  grid-area: labels;
  height: 100%;
  color: ${({ theme }) => theme.colorBase500};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
