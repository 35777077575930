import { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectActiveTooltip } from "../../selectors";
import { dispatch } from "../../store";
import { hideTooltipWithTimeout, showTooltip } from "../../thunks";
import { TooltipContainer } from "../UI";

export function ActiveTooltip(): JSX.Element {
  const tip = useSelector(selectActiveTooltip);

  useEffect(() => {
    if (tip) {
      document.body.addEventListener("mouseout", mouseOut);
      document.body.addEventListener("click", blur);
      document.body.addEventListener("mousedown", blur);

      return () => {
        document.body.removeEventListener("mouseout", mouseOut);
        document.body.removeEventListener("click", blur);
        document.body.removeEventListener("mousedown", blur);
      };
    }
  }, [tip]);

  return (
    <TooltipContainer
      showTooltip={(...args) => dispatch(showTooltip(...args))}
      tip={tip}
    />
  );
}

function blur({ target }: MouseEvent) {
  if (!(target as HTMLElement).closest("[data-tooltip-container]")) {
    dispatch(hideTooltipWithTimeout(0));
  }
}

function mouseOut(event: MouseEvent) {
  const { relatedTarget } = event;

  const isElement = relatedTarget instanceof Element;
  const isTooltipElement =
    (isElement && relatedTarget.hasAttribute("aria-label")) ||
    (relatedTarget instanceof HTMLElement && relatedTarget.dataset.shortcut);

  if (
    relatedTarget instanceof Node === false ||
    isTooltipElement ||
    (isElement &&
      relatedTarget.closest("[aria-label],[data-shortcut]") !== null)
  ) {
    return;
  }

  // NOTE - Animation delays for exiting tooltips can be handled via the component itself
  //        Remember that if you add a timeout here, we still have to consider the time
  //        for the exit animation to execute. So for now let's handle delays in the component.
  return dispatch(hideTooltipWithTimeout(0));
}
