import type { CALL_HISTORY_METHOD } from "redux-first-history";

import type { PresenceAction } from "../types";
import type { AuthenticationAction } from "./authenticationActions";
import type { AvatarsAction } from "./avatarsActions";
import type { CommandMenuAction } from "./commandMenuActions";
import type { CourierAction } from "./courierActions";
import type { DataSourcesAction } from "./dataSourcesActions";
import type { DiscussionsAction } from "./discussionsActions";
import type { EventsAction } from "./eventsActions";
import type { LabelsEditorAction } from "./labelsEditorActions";
import type { NotebooksAction } from "./notebooksActions";
import type { NotificationsAction } from "./notificationsActions";
import type { ProxiesAction } from "./proxiesActions";
import type { TriggerAction } from "./triggerActions";
import type { UiAction } from "./uiActions";
import type { ViewsAction } from "./viewsActions";
import type { WorkspaceAction } from "./workspaceActions";

type CallHistoryMethodAction = {
  type: typeof CALL_HISTORY_METHOD;
};

export type { NotebookAction, PresenceAction } from "../types";

export * from "./authenticationActions";
export * from "./avatarsActions";
export * from "./commandMenuActions";
export * from "./consoleActions";
export * from "./courierActions";
export * from "./dataSourcesActions";
export * from "./discussionsActions";
export * from "./editorActions";
export * from "./eventsActions";
export * from "./labelsEditorActions";
export * from "./mentionsActions";
export * from "./notebookActions";
export * from "./notebooksActions";
export * from "./notificationsActions";
export * from "./proxiesActions";
export * from "./triggerActions";
export * from "./uiActions";
export * from "./viewsActions";
export * from "./workspaceActions";

export type AppAction =
  | AuthenticationAction
  | AvatarsAction
  | CallHistoryMethodAction
  | CommandMenuAction
  | CourierAction
  | DataSourcesAction
  | DiscussionsAction
  | EventsAction
  | LabelsEditorAction
  | NotebooksAction
  | NotificationsAction
  | PresenceAction
  | ProxiesAction
  | TriggerAction
  | UiAction
  | ViewsAction
  | WorkspaceAction;
