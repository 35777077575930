import { useMemo } from "react";
import { useSelector } from "react-redux";

import { type UseFuseOptions, useFuse } from "../../../../../hooks";
import { selectCommandMenuQuery } from "../../../../../selectors";
import type { Command } from "../../../../../types";
import { formatLabel, getCreatedByLabel, sortBy } from "../../../../../utils";
import {
  CATEGORY_INDEX_KEY,
  CREATED_BY_INDEX_KEY,
  DESCRIPTION_INDEX_KEY,
  KEYWORDS_INDEX_KEY,
  LABELS_INDEX_KEY,
  TITLE_INDEX_KEY,
} from "../../constants";
import type { GroupedResults } from "../../types";
import { useCommands } from "../useCommands";

export type SearchResult = {
  groupedResults: GroupedResults;
  total: number;
};

export function useSearch(): SearchResult {
  const query = useSelector(selectCommandMenuQuery);
  const commands = useCommands();
  const results = useFuse(commands, query, options);

  return useMemo(() => {
    // Create a variable where we are going to store the results sorted by score
    const sortedResults: GroupedResults = [];

    // Loop over all fuse results so we can group them by type
    for (const result of results) {
      const { type } = result.item;

      const index = sortedResults.findIndex(([key]) => key === type);

      // If the type group doesn't exist yet, we'll create it with the result
      if (index === -1) {
        sortedResults.push([type, [result]]);
        continue;
      }

      // The type group didn't exist yet so we have to figure out where to insert the result
      let insertAtIndex = 0;
      // biome-ignore lint/style/noNonNullAssertion: We checked the index above
      const [, resultsInCategory] = sortedResults[index]!;

      // Loop over the existing results in the category until we find the index
      for (const previousResult of resultsInCategory) {
        // In Fuse a score of 0 is a perfect match and 1 is no match at all
        if ((previousResult.score ?? 1) > (result.score ?? 1)) {
          break;
        }

        insertAtIndex++;
      }

      // Finally insert the result in the right index and update the type group
      resultsInCategory.splice(insertAtIndex, 0, result);
      sortedResults[index] = [type, resultsInCategory];
    }

    // Now that all the commands are grouped by type and sorted by score,
    // we can sort the groups based on the score of the first result
    const groupedResults = sortBy(
      sortedResults,
      // In Fuse a score of 0 is a perfect match and 1 is no match at all
      ([, [result]]) => result?.score ?? 1,
    );

    return {
      groupedResults,
      total: results.length,
    };
  }, [results]);
}

const options: UseFuseOptions<Command> = {
  shouldFilter: true,
  includeScore: true,
  includeMatches: true,
  minMatchCharLength: 3,
  keys: [
    {
      name: TITLE_INDEX_KEY,
      getFn: (command) => command.title,
      weight: 8,
    },
    {
      name: KEYWORDS_INDEX_KEY,
      getFn: (command) => command.keywords ?? [],
      weight: 7,
    },
    {
      name: CATEGORY_INDEX_KEY,
      getFn: (command) => {
        switch (command.type) {
          case "navigation":
          case "notebooks":
          case "templates":
          case "views":
          case "workspaces":
            return command.type;
          default:
            return "";
        }
      },
      weight: 5,
    },
    {
      name: CREATED_BY_INDEX_KEY,
      getFn: (command) => {
        if (command.type === "notebooks" && command.notebook) {
          return getCreatedByLabel(command.notebook.createdBy);
        }

        return "";
      },
    },
    {
      name: LABELS_INDEX_KEY,
      getFn: (command) => {
        switch (command.type) {
          case "notebooks": {
            if (command.notebook?.labels) {
              return command.notebook.labels.map(formatLabel);
            }

            break;
          }

          case "views": {
            if (command.view?.labels) {
              return command.view.labels.map(formatLabel);
            }

            break;
          }
        }

        return [];
      },
      weight: 2,
    },
    {
      name: DESCRIPTION_INDEX_KEY,
      getFn: (command) => command.description ?? "",
      weight: 1,
    },
  ],
};
