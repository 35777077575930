import { Icon, type IconType } from "@fiberplane/ui";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import useAsync from "react-use/lib/useAsync";

import { showError, withActiveNotebook } from "../../../../actions";
import { normalizeException } from "../../../../errors";
import { useFeature } from "../../../../hooks";
import {
  selectActiveWorkspaceIdOrThrow,
  selectDataSourcesList,
  selectSelectedDataSources,
} from "../../../../selectors";
import { dispatch } from "../../../../store";
import { getSupportedQueryTypes, loadDataSources } from "../../../../thunks";
import type {
  DataSource,
  LoadableData,
  ProviderType,
  SupportedQueryType,
} from "../../../../types";
import { compact, isSameDataSource } from "../../../../utils";
import type { FilterMenuItem } from "../../../UI";
import { IconContainer, iconRight } from "./common";
import { type SourceType, changeIntoOrAddProviderCell } from "./thunks";

type Props = {
  source?: SourceType;
};

type ProviderQueryActionInfo = {
  providerType: string;
  queryType: string;
  label: string;
  icon: IconType;
};

type SupportedQueryTypesPerProvider = {
  [key: ProviderType]: ReadonlyArray<SupportedQueryType>;
};

export function useSlashCommandProviderItems({
  source = "slash command",
}: Props): Array<FilterMenuItem> {
  const dataSources = useSelector(selectDataSourcesList);
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const [hasAutometricsFeature] = useFeature("autometrics-provider");
  const [hasHttpsProviderFeature] = useFeature("https-provider");
  const [hasSentryFeature] = useFeature("sentry");

  // Load data sources if they're not loaded yet:
  useEffect(() => {
    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
    if (!dataSources.data && !dataSources.loading && !dataSources.error) {
      dispatch(loadDataSources(workspaceId));
    }
  }, [dataSources, workspaceId]);

  const supportedQueryTypes = useSupportedQueryTypesPerProvider(dataSources);

  // Built all the actions for the menu:
  const actions = useMemo(() => {
    const builtInActions = getStudioProviderQueryActions({
      hasAutometricsFeature,
      hasHttpsProviderFeature,
      hasSentryFeature,
    });

    const { value } = supportedQueryTypes;
    const dynamicActions = (value ? Object.entries(value) : []).flatMap(
      ([providerType, queryTypes]) =>
        queryTypes.map(
          (queryType): ProviderQueryActionInfo => ({
            providerType,
            queryType: queryType.queryType,
            label: queryType.label,
            icon:
              builtInActions.find(
                (action) => action.providerType === providerType,
              )?.icon ?? "source",
          }),
        ),
    );

    // Returns dynamic actions for all the configured data sources, and
    // built-in actions for all the provider types that are bundled with Studio,
    // but which are not yet configured:
    return [
      ...dynamicActions,
      ...builtInActions.filter(
        (action) =>
          !dynamicActions.some(
            (dynamicAction) =>
              dynamicAction.providerType === action.providerType,
          ),
      ),
    ];
  }, [
    supportedQueryTypes,
    hasAutometricsFeature,
    hasHttpsProviderFeature,
    hasSentryFeature,
  ]);

  return actions.map(({ icon, label, providerType, queryType }) => {
    const id = `${providerType}-${queryType}`;
    return {
      type: "item_with_icons",
      id,
      title: label,
      iconLeft: (
        <IconContainer>
          <Icon iconType={icon} />
        </IconContainer>
      ),
      iconRight,
      onActivate: () =>
        dispatch(
          changeIntoOrAddProviderCell({ providerType, queryType, source }),
        ),
    };
  });
}

/**
 * Returns a list of query actions for providers that are bundled with Studio.
 *
 * These actions are always available in the menu, even if no data source is
 * configured for them.
 */
function getStudioProviderQueryActions({
  hasAutometricsFeature,
  hasHttpsProviderFeature,
  hasSentryFeature,
}: {
  hasAutometricsFeature: boolean;
  hasHttpsProviderFeature: boolean;
  hasSentryFeature: boolean;
}): Array<ProviderQueryActionInfo> {
  const actions: Array<ProviderQueryActionInfo> = [
    {
      providerType: "elasticsearch",
      queryType: "events",
      label: "Elasticsearch query",
      icon: "elasticsearch",
    },
    {
      providerType: "fiberplane",
      queryType: "event-timeseries",
      label: "Events timeseries",
      icon: "timeseries",
    },
    {
      providerType: "loki",
      queryType: "events",
      label: "Loki query",
      icon: "loki",
    },
    {
      providerType: "prometheus",
      queryType: "timeseries",
      label: "Prometheus chart",
      icon: "prometheus",
    },
  ];

  if (hasAutometricsFeature) {
    actions.push({
      providerType: "autometrics",
      queryType: "x-autometrics-function",
      label: "Autometrics function",
      icon: "autometrics",
    });
  }

  if (hasHttpsProviderFeature) {
    actions.push({
      providerType: "https",
      queryType: "x-https-query",
      label: "HTTPS API Request",
      icon: "plugs_connected",
    });
  }

  if (hasSentryFeature) {
    actions.push({
      providerType: "sentry",
      queryType: "x-issues-overview",
      label: "Sentry issues",
      icon: "caret_right",
    });
  }

  actions.push({
    providerType: "fiberplane",
    queryType: "event-timeline",
    label: "Timeline",
    icon: "clock",
  });

  return actions;
}

/**
 * Returns the supported query types for all the configured data sources,
 * per provider type.
 */
function useSupportedQueryTypesPerProvider(
  dataSources: LoadableData<ReadonlyArray<DataSource>>,
) {
  const selectedDataSources = useSelector(selectSelectedDataSources);

  return useAsync(async () => {
    const { data } = dataSources;
    if (!data) {
      return {};
    }

    // Limit ourselves to data sources that have been selected at the notebook
    // level, especially given that we have no UI yet to select alternative data
    // sources per cell.
    const defaultDataSources = compact(
      Object.values(selectedDataSources).map((selectedDataSource) =>
        data.find((dataSource) =>
          isSameDataSource(dataSource, selectedDataSource),
        ),
      ),
    );

    const supportedQueryTypesArray = await Promise.allSettled(
      defaultDataSources.map((dataSource) =>
        dispatch(getSupportedQueryTypes(dataSource)),
      ),
    );
    return defaultDataSources.reduce(
      (supportedQueryTypes, dataSource, index) => {
        const promise = supportedQueryTypesArray[index];
        if (promise?.status === "fulfilled") {
          // Only select query types that have a label:
          supportedQueryTypes[dataSource.providerType] = promise.value.filter(
            (queryType) => !!queryType.label,
          );
        } else {
          const error = promise
            ? normalizeException(promise.reason)
            : { message: "Unknown promise" };
          dispatch(
            withActiveNotebook(
              showError({
                type: "other",
                message: `Cannot determine supported query types for data source "${dataSource.name}": ${error.message}`,
              }),
            ),
          );
        }

        return supportedQueryTypes;
      },
      {} as SupportedQueryTypesPerProvider,
    );
  }, [dataSources, selectedDataSources]);
}
