import { Icon } from "@fiberplane/ui";

import { CellById } from "../../../../CellById";
import { useFeature } from "../../../../hooks";
import { selectCell } from "../../../../selectors";
import { dispatch, getState } from "../../../../store";
import type {
  CellTypeProperties,
  HeadingType,
  NotebookContextMenuInfo,
} from "../../../../types";
import { compact, generateEmptyTable, sortBy } from "../../../../utils";
import type { FilterMenuItem } from "../../../UI";
import { getPriority } from "../getPriority";
import { IconContainer, iconRight } from "./common";
import {
  type SourceType,
  changeIntoOrAddDiscussionCell,
  changeOrAddCellType,
} from "./thunks";
import { useSlashCommandProviderItems } from "./useSlashCommandProviderItems";
import { useSlashCommandSnippetItems } from "./useSlashCommandSnippetItems";

type Params = {
  contextMenu: NotebookContextMenuInfo;
  source: SourceType;
};

/**
 * Returns the menu items to display in the slash command menu.
 */
export function useSlashCommandItems({
  contextMenu,
  source,
}: Params): Array<FilterMenuItem> {
  const activateCell = (properties: CellTypeProperties) => {
    if (!contextMenu) {
      throw new Error("No context menu opened");
    }

    const cell = selectCell(getState(), contextMenu.cellId);
    if (cell?.readOnly) {
      CellById.get(cell.id)?.shake();
      return;
    }

    dispatch(changeOrAddCellType({ contextMenu, properties, source }));
  };

  const hasTableCell = useFeature("table-cell");

  const providerItems = useSlashCommandProviderItems({ source });
  const snippetItems = useSlashCommandSnippetItems();

  const headingItems: Array<FilterMenuItem> = [1, 2, 3].map((level) => {
    const headingType = `h${level}` as HeadingType;
    return {
      type: "item_with_icons",
      id: headingType,
      title: `Heading ${level}`,
      iconLeft: (
        <IconContainer>
          <Icon
            iconType={`text_h_${
              level === 1 ? "one" : level === 2 ? "two" : "three"
            }`}
          />
        </IconContainer>
      ),
      iconRight,
      onActivate: () => activateCell({ type: "heading", headingType }),
    };
  });

  const textItems: Array<FilterMenuItem> = [
    {
      type: "item_with_icons",
      id: "text",
      title: "Text",
      iconLeft: (
        <IconContainer>
          <Icon iconType="text_t" />
        </IconContainer>
      ),
      iconRight,
      onActivate: () => activateCell({ type: "text" }),
    },
    {
      type: "item_with_icons",
      id: "ol",
      title: "Ordered List",
      iconLeft: (
        <IconContainer>
          <Icon iconType="list_numbers" />
        </IconContainer>
      ),
      iconRight,
      onActivate: () =>
        activateCell({ type: "list_item", listType: "ordered" }),
    },
    {
      type: "item_with_icons",
      id: "ul",
      title: "Unordered List",
      iconLeft: (
        <IconContainer>
          <Icon iconType="list_bullets" />
        </IconContainer>
      ),
      iconRight,
      onActivate: () =>
        activateCell({ type: "list_item", listType: "unordered" }),
    },
    {
      type: "item_with_icons",
      id: "checkbox",
      title: "Checkbox",
      iconLeft: (
        <IconContainer>
          <Icon iconType="check_square" />
        </IconContainer>
      ),
      iconRight,
      onActivate: () => activateCell({ type: "checkbox" }),
    },
    {
      type: "item_with_icons",
      id: "code",
      title: "Code",
      iconLeft: (
        <IconContainer>
          <Icon iconType="code" />
        </IconContainer>
      ),
      iconRight,
      onActivate: () => activateCell({ type: "code" }),
    },
  ];

  const items = compact<FilterMenuItem>([
    {
      type: "item_group",
      id: "heading-cells",
      title: "Headings",
      items: headingItems,
    },
    {
      type: "item_group",
      id: "text-cells",
      title: "Text cells",
      items: textItems,
    },
    {
      type: "item_group",
      id: "query-cells",
      title: "Query cells",
      items: providerItems,
    },
    hasTableCell && {
      type: "item_with_icons",
      id: "table",
      title: "Table cell",
      iconLeft: (
        <IconContainer>
          <Icon iconType="table" />
        </IconContainer>
      ),
      iconRight,
      onActivate: () =>
        activateCell(generateEmptyTable({ numColumns: 2, numRows: 2 })),
    },
    {
      type: "item_with_icons",
      id: "discussion",
      title: "Discussion",
      iconLeft: (
        <IconContainer>
          <Icon iconType="chats_circle" />
        </IconContainer>
      ),
      iconRight,
      onActivate: () => dispatch(changeIntoOrAddDiscussionCell(source)),
    },
    {
      type: "item_with_icons",
      id: "image",
      title: "Image",
      iconLeft: (
        <IconContainer>
          <Icon iconType="image" />
        </IconContainer>
      ),
      iconRight,
      onActivate: () => activateCell({ type: "image" }),
    },
    {
      type: "item_with_icons",
      id: "divider",
      title: "Divider",
      iconLeft: (
        <IconContainer>
          <Icon iconType="divider" />
        </IconContainer>
      ),
      iconRight,
      onActivate: () => activateCell({ type: "divider" }),
    },
    snippetItems,
  ]);

  const filterText = contextMenu.typeAheadText;
  return filterText ? sortBy(items, getPriority(filterText)) : items;
}
