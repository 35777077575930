import type { Timestamp } from "../types";

export const dateToSeconds = (date: Date): number => date.getTime() / 1000;

export const secondsToTimestamp = (seconds: number): Timestamp =>
  new Date(seconds * 1000).toISOString();

export const timestampToDate = (timestamp: Timestamp): Date =>
  new Date(timestamp);

export const timestampToSeconds = (timestamp: Timestamp): number =>
  new Date(timestamp).getTime() / 1000;

export const timestampToMs = (value: Timestamp) => {
  const date = new Date(value);
  return date.getTime();
};

// Rust implementation of Timestamp formatting uses the "default" time crate Rfc3339 format,
// which strips all unsignificant numbers past seconds.
//
// This function formats a Timestamp to this format as well
//
// Ref: https://github.com/time-rs/time/blob/7a6a800843e999807ddc4a317e95705f4ed82da5/time/src/formatting/formattable.rs#L244-L267
export const timestampToString = (value: Timestamp) => {
  const dateStr = new Date(value).toISOString();
  const re = /(\.000Z|0+Z)/gi;

  return dateStr.replace(re, "Z");
};
