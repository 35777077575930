import { useHandler } from "@fiberplane/hooks";
import { useContext, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

import { showError, withActiveNotebook } from "../../../actions";
import { formatFiberplaneError } from "../../../errors";
import { useFilePaste, useFocus } from "../../../hooks";
import {
  makeCellErrorSelector,
  selectActiveNotebookId,
} from "../../../selectors";
import { dispatch, getState } from "../../../store";
import { uploadImage } from "../../../thunks";
import type { ImageCell } from "../../../types";
import {
  track,
  validateImageFileSize,
  validateImageFileType,
} from "../../../utils";
import { FileDropContext, useFileDropZone } from "../../UI";
import { noOutlineStyling } from "../CellContainer";
import { Done } from "./Done";
import { Empty } from "./Empty";
import { Failed } from "./Failed";
import { FileInputContainer } from "./FileInputContainer";
import { Progress } from "./Progress";
import { UploadReady } from "./UploadReady";

export type ImageCellContentProps = {
  cell: { type: "image" } & ImageCell;
  readOnly: boolean;
};

export function ImageCellContent({ cell, readOnly }: ImageCellContentProps) {
  const { id: cellId } = cell;

  const notebookId = useSelector(selectActiveNotebookId);
  const selectCellError = useMemo(
    () => makeCellErrorSelector(cellId),
    [cellId],
  );
  const error = useSelector(selectCellError);

  const onUploadStart = (file: File) => {
    dispatch(uploadImage(cellId, file));
  };

  const onUploadError = (reason: string) => {
    dispatch(
      withActiveNotebook(
        showError(
          {
            type: "other",
            message: reason,
          },
          { cellId },
        ),
      ),
    );
  };

  const ref = useRef<HTMLDivElement | null>(null);
  useFocus(cellId, ref);

  const onPaste = useFilePaste(cellId);

  const onFileDropped = useHandler((files: FileList) => {
    const file = files[0];

    if (!file) {
      return;
    }

    const error = validateImageFileSize(file);
    if (error) {
      onUploadError(error);
      return;
    }

    const notebookId = selectActiveNotebookId(getState());
    track("notebook | change cell type", {
      notebookId,
      cellType: "image",
      source: "drop image on image cell",
    });
    onUploadStart(file);
  });

  const { files } = useContext(FileDropContext);
  const { state, onDragEnter, onDragLeave, onDrop } = useFileDropZone({
    validator: validateImageFileType,
    onSelectFile: onFileDropped,
  });

  const idProps = {
    "data-testid": "image-cell-content",
    tabIndex: -1,
    ref,
  };

  const { fileId, width, height, preview, progress } = cell;
  if (fileId) {
    return (
      <Container onPaste={onPaste} {...idProps}>
        <Done
          width={width}
          height={height}
          fileId={fileId}
          preview={preview}
          notebookId={notebookId}
        />
      </Container>
    );
  }

  if (state === "hover" || (state === "valid" && files.length > 0)) {
    return (
      <Container
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        {...idProps}
      >
        <UploadReady hovered={state === "valid"} />
      </Container>
    );
  }

  if (typeof progress === "number") {
    return <StyledProgress {...idProps} value={progress} />;
  }

  return (
    <Container
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      {...idProps}
    >
      <FileInputContainer
        id={cellId}
        onUploadStart={onUploadStart}
        onUploadError={onUploadError}
      >
        {(onSelectFile) => {
          if (error || state === "invalid") {
            const reasonText =
              state === "invalid"
                ? "File type not supported"
                : error
                  ? formatFiberplaneError(error)
                  : "Unexpected error occurred";

            return (
              <Failed
                onSelectFile={onSelectFile}
                reason={reasonText}
                readOnly={readOnly}
              />
            );
          }

          return <Empty onSelectFile={onSelectFile} readOnly={readOnly} />;
        }}
      </FileInputContainer>
    </Container>
  );
}

const StyledProgress = styled(Progress)`
  ${noOutlineStyling}
`;

const Container = styled.div`
  ${noOutlineStyling}
`;
