import { useMemo } from "react";

import { TIMESERIES_MIME_TYPE } from "../../../constants";
import { useProviderData } from "../../../hooks";
import type { GraphCell, Timeseries } from "../../../types";
import { compact, getParentCellId, parseTimeseriesBlob } from "../../../utils";

const EMPTY_LINKS_LIST: Array<string> = [];

export function useTimeseriesData(cell: GraphCell): Array<Timeseries> {
  const rawData = useProviderData(
    getParentCellId(cell.id),
    cell.dataLinks ?? EMPTY_LINKS_LIST,
    TIMESERIES_MIME_TYPE,
    parseTimeseriesBlob,
  );

  return useMemo(() => compact(rawData).flat(), [rawData]);
}
