import { useCallback, useReducer } from "react";

import { Api } from "../../../services";
import { chooseRandom } from "../../../utils";

// Model
type ProxyDetails = { name: string; token: string };

// States
type InitialState = { screen: "initial"; isLoading: boolean };

type ErrorState = { screen: "error"; isLoading: false };

type LoadedState =
  | { screen: "created"; proxy: ProxyDetails; isLoading: false }
  | { screen: "next-steps"; proxy: ProxyDetails; isLoading: false };

type AddProxyWizardState = InitialState | ErrorState | LoadedState;

// Actions
type AddProxyWizardAction =
  | { type: "CREATE_PROXY_REQUESTED" }
  | { type: "CREATE_PROXY_ERROR" }
  | { type: "CREATE_PROXY_DONE"; proxy: ProxyDetails }
  | { type: "INSTRUCTIONS_SHOW"; proxy: ProxyDetails };

// Hook to handle wizard state
export function useAddProxyWizard({ workspaceId }: { workspaceId: string }) {
  const initialState: InitialState = { screen: "initial", isLoading: false };
  const [wizardState, dispatch] = useReducer(wizardReducer, initialState);

  const requestCreateProxy = useCallback(() => {
    dispatch({
      type: "CREATE_PROXY_REQUESTED",
    });

    // NOTE - You can use `__createProxyMock` instead of createProxy when developing locally
    createProxy(workspaceId).then(
      (proxy) => {
        // NOTE - The schema definition in our api spec marks the token as optional,
        //        since normally we do not return the token with the proxy response.
        //        We could update the api spec to differentiate between a recently created proxy
        //        and a normal proxy details response... but that seems like a whole lot of faff.
        if (!proxy.token) {
          dispatch({ type: "CREATE_PROXY_ERROR" });
          return;
        }

        return dispatch({
          type: "CREATE_PROXY_DONE",
          proxy: {
            name: proxy.name,
            token: proxy.token,
          },
        });
      },
      () => dispatch({ type: "CREATE_PROXY_ERROR" }),
    );
  }, [workspaceId]);

  return {
    currentScreen: wizardState.screen,
    wizardState,
    requestCreateProxy,
    // NOTE - this transition is a noop if we aren't on the `next-steps` screen
    toProxyCreated: () => {
      if (wizardState.screen === "next-steps") {
        dispatch({ type: "CREATE_PROXY_DONE", proxy: wizardState.proxy });
      }
    },
    // NOTE - this transition is a noop if we aren't on the `created` screen
    toDeployInstructions: () => {
      if (wizardState.screen === "created") {
        dispatch({ type: "INSTRUCTIONS_SHOW", proxy: wizardState.proxy });
      }
    },
  };
}

// Reducer
function wizardReducer(
  state: AddProxyWizardState,
  action: AddProxyWizardAction,
): AddProxyWizardState {
  switch (action.type) {
    case "CREATE_PROXY_REQUESTED":
      return { screen: "initial", isLoading: true };
    case "CREATE_PROXY_DONE":
      return { screen: "created", proxy: action.proxy, isLoading: false };
    case "INSTRUCTIONS_SHOW":
      return { screen: "next-steps", proxy: action.proxy, isLoading: false };
    case "CREATE_PROXY_ERROR":
      return { screen: "error", isLoading: false };
    default:
      return state;
  }
}

// Guards
export function isOnCreatedScreen(
  state: AddProxyWizardState,
): state is LoadedState {
  return state.screen === "created";
}

export function isOnDeployScreen(
  state: AddProxyWizardState,
): state is LoadedState {
  return state.screen === "next-steps";
}

/**
 * Wrapper for API call to create a proxy.
 *
 * Uses a random name and fixed description,
 * where the description indicates that the proxy
 * was created from the Getting Started cards
 *
 */
function createProxy(workspaceId: string): Promise<Api.Proxy> {
  const name = randomName();
  const description = "Created in Getting Started guide";
  return Api.createProxy(workspaceId, { name, description });
}

/**
 * Utility for randomly generating a proxy name
 * Chance of name collision is very small :)
 */
function randomName() {
  const adjectives = [
    "serene",
    "austere",
    "magnanimous",
    "whimsical",
    "billowy",
  ];
  const id = Math.floor(
    Math.random() * Math.floor(Math.random() * (99_999 - 10_000 + 1)) + 10_000,
  );
  const adjective = chooseRandom(adjectives);
  return `getting-started-${adjective}-proxy-${id}`;
}

/**
 * Mock of createProxy api call, to test locally without hitting the API
 *
 * @param shouldThrowFakeError - Force an error, useful when testing error screens
 * @returns Promise<ProxyDetails>
 */
export function __createProxyMock(
  shouldThrowFakeError?: boolean,
): Promise<ProxyDetails> {
  const tokenValue = "super-token-12312312312";
  const proxy = { name: randomName(), token: tokenValue };

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (shouldThrowFakeError) {
        reject(new Error("Fake error"));
      } else {
        resolve(proxy);
      }
    }, 3000);
  });
}
