import type { DataSource, Provider, ProviderType } from "../../types";
import { formatDataSourceKey } from "../../utils";
import { NoProviderError } from "./errors";
import { createProxyProvider } from "./providers";

type ProviderFactory = () => Provider;

const providerFactories = new Map<string, ProviderFactory>();

// Providers are mutable entities that have a one-to-one relationship with
// dataSources.type (i.e. proxy or prometheus).
const registry = new Map<string, Provider>();

export class MissingDataSourceError extends Error {
  public sourceId: string;

  constructor(sourceId: string, message: string) {
    super(message);
    this.sourceId = sourceId;
  }
}

/**
 * Returns the Provider to use for invoking queries on the given data source.
 *
 * Providers are cached per data source, so querying repetitively for the same
 * data source will yield the same provider again.
 */
export function getProviderForDataSource(dataSource: DataSource): Provider {
  const key = formatDataSourceKey(dataSource);
  let provider = registry.get(key);
  if (provider) {
    return provider;
  }

  provider = dataSource.proxyName
    ? createProxyProvider()
    : getNewProviderOfType(dataSource.providerType);

  registry.set(key, provider);
  return provider;
}

/**
 * Returns a new Provider for the given type, without using any caching.
 */
export function getNewProviderOfType(providerType: ProviderType): Provider {
  const providerFactory = providerFactories.get(providerType);
  if (!providerFactory) {
    throw new NoProviderError(providerType);
  }

  return providerFactory();
}

/**
 * Registers the factory for creating providers for the given cell type.
 */
export function registerProviderFactory(
  type: string,
  factory: ProviderFactory,
) {
  providerFactories.set(type, factory);
}
