/**
 * Parses a color into its RGB components.
 */
export function parseColor(color: string): [number, number, number] {
  if (color.startsWith("#")) {
    return [
      Number.parseInt(color.slice(1, 3), 16),
      Number.parseInt(color.slice(3, 5), 16),
      Number.parseInt(color.slice(5, 7), 16),
    ];
  } else if (color.startsWith("rgb")) {
    const startIndex = color.indexOf("(") + 1;
    const endIndex = color.indexOf(")");
    if (startIndex > -1 && endIndex > -1) {
      const [r, g, b] = color.slice(startIndex, endIndex).split(",");
      return [
        r ? Number.parseInt(r, 10) : 0,
        g ? Number.parseInt(g, 10) : 0,
        b ? Number.parseInt(b, 10) : 0,
      ];
    }
  }

  throw new Error(`Cannot parse color: ${color}`);
}
