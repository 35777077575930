import { Icon, cancelEvent } from "@fiberplane/ui";
import styled, { css } from "styled-components";

import { useHandler } from "@fiberplane/hooks";
import {
  type IntegrationType,
  connectIntegration,
} from "../../../../../slices";
import { dispatch } from "../../../../../store";
import type {
  PersonalIntegrationSummary,
  WorkspaceIntegrationSummary,
} from "../../../../../types";
import { Box } from "../../../../UI";
import { IntegrationIcon } from "./IntegrationIcon";
import { getIntegrationProfile } from "./utils";

type IntegrationCardProps = {
  type: IntegrationType;
  onClick: () => void;
  summary: PersonalIntegrationSummary | WorkspaceIntegrationSummary;
};

export function IntegrationCard({
  type,
  onClick,
  summary,
}: IntegrationCardProps) {
  const profile = getIntegrationProfile(summary);

  const isEnabled = summary.status.type !== "disconnected";

  const toggleEnable = useHandler(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!isEnabled) {
        cancelEvent(event);
        dispatch(connectIntegration({ ...summary, type }));
      }
      // TODO: Decided to disable disconnect for now
      // We only support Github atm and it's linked to your personal account,
      // else {
      //   cancelEvent(event);
      //   dispatch(disconnectIntegration(summary.id));
      // }
    },
  );

  return (
    <Card onClick={onClick}>
      <StyledIntegrationIcon integrationId={summary.id} />
      <Name>{profile.name}</Name>
      {summary.status.type === "attention_required" && (
        <AttentionRequired>
          <Dot /> Action needed
        </AttentionRequired>
      )}

      <Description>{profile.description}</Description>
      <Enabled $enabled={isEnabled} onClick={toggleEnable}>
        {isEnabled ? (
          <>
            <Icon iconType="check" />
            Enabled
          </>
        ) : (
          "Enable"
        )}
      </Enabled>
    </Card>
  );
}

const AttentionRequired = styled.span(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 6px;
    grid-area: attention_required;
    color: ${theme.color.fg.danger};
    font: ${theme.font.body.xs.medium};
  `,
);

const Card = styled.div(
  ({ theme }) => css`
    display: grid;
    grid:
      "icon name" auto
      "description description" auto
      "enabled enabled" auto
      / auto 1fr;
    gap: 0 16px;
    padding: 12px 16px;
    border-radius: ${theme.radius.default};
    border: 1px solid ${theme.color.border.muted};
    background: ${theme.color.button.secondary.bg};
    box-shadow: ${theme.effect.shadow.xxs};
    cursor: pointer;

    &:has(${AttentionRequired}) {
      grid:
        "icon name" auto
        "icon attention_required" auto
        "description description" auto
        "enabled enabled" auto
        / auto 1fr;
    }
  `,
);

const StyledIntegrationIcon = styled(IntegrationIcon)(
  () => css`
    grid-area: icon;
  `,
);

const Name = styled.strong(
  () => css`
    grid-area: name;
    align-self: center;
  `,
);

const Description = styled.p(
  ({ theme }) => css`
    grid-area: description;
    margin: 8px 0 16px;
    color: ${theme.color.fg.muted};
    font: ${theme.font.body.sm.regular};
  `,
);

const Enabled = styled.button<{ $enabled: boolean }>(
  ({ theme, $enabled }) => css`
    grid-area: enabled;

    background: transparent;

    display: flex;
    max-width: max-content;
    height: ${theme.height.small};
    padding: 0px 16px;
    align-items: center;
    gap: 6px;
    border-radius: ${theme.radius.full};
    border: 1px solid ${theme.color.fg.subtle};
    color: ${theme.color.fg.muted};
    font: ${theme.font.body.md.medium};

    ${
      $enabled &&
      css`
      border-radius: ${theme.radius.full};
      border: 1px solid ${theme.color.fg.success};
      color: ${theme.color.fg.success};
    `
    }
  `,
);

const Dot = styled(Box)(
  () => css`
    display: inline-flex;
    border-radius: 100%;
    background: currentColor;
    width: 6px;
    height: 6px;
    content: " ";
  `,
);
