import { Button } from "@fiberplane/ui";
import { type FormEvent, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, useParams } from "react-router-dom";
import { push } from "redux-first-history";
import { css, styled } from "styled-components";

import { useGetTemplateByNameQuery } from "../../api";
import { ROUTES } from "../../constants";
import { normalizeException } from "../../errors";
import {
  selectActiveWorkspaceIdOrThrow,
  selectActiveWorkspaceNameOrThrow,
} from "../../selectors";
import { dispatch } from "../../store";
import { ButtonBar, useShake } from "../UI";
import {
  InfoContainer,
  InfoInput,
  InfoLabel,
  StyledForm,
  StyledFormContainer,
} from "./shared";

const StyledInfoInput = styled(InfoInput)<{ expandError?: boolean }>`
  ${(props) => props.expandError && errorStyling}
`;

type Params = {
  templateName: string;
  workspaceId: string;
};

const errorStyling = css`
  border-color: #ff5a47;
`;

export function TemplateWithName(): JSX.Element {
  const { templateName: templateNameParams } = useParams<Params>();
  const templateName = templateNameParams ?? "";
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const workspaceName = useSelector(selectActiveWorkspaceNameOrThrow);

  const { isFetching, error, data } = useGetTemplateByNameQuery(
    { workspaceId, templateName },
    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version (which is less readable)
    { skip: !workspaceId || !templateName },
  );

  const [expandPayload, setExpandPayload] = useState(
    `{ "title": "A templated notebook" }`,
  );
  const [expandError, setExpandError] = useState<Error>();

  const { shake, shakeClassName } = useShake();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const parsed = JSON.parse(expandPayload);
      if (typeof parsed !== "object") {
        throw new SyntaxError("Invalid expandPayload");
      }

      const parameters = new URLSearchParams(parsed);
      dispatch(
        push(
          `${generatePath(ROUTES.ExpandTemplate, {
            workspaceName,
            templateName,
          })}?${parameters.toString()}`,
        ),
      );
    } catch (error) {
      shake();
      if (error instanceof Error) {
        setExpandError(error);
      } else {
        setExpandError(new Error("Unexpected error occurred"));
      }
    }
  };

  if (isFetching) {
    return (
      <StyledFormContainer>
        <div>Loading</div>
      </StyledFormContainer>
    );
  }

  if (error) {
    return (
      <StyledFormContainer>
        <div>{normalizeException(error).message}</div>
      </StyledFormContainer>
    );
  }

  return (
    <StyledFormContainer>
      <InfoContainer>
        <h1>{data?.name}</h1>
        <div>
          <InfoLabel htmlFor="newTemplateDescription">Description</InfoLabel>
          <div>{data?.description}</div>
        </div>
        <div>
          <InfoLabel htmlFor="newTemplateBody">Template</InfoLabel>
          <pre>
            <code>{data?.body}</code>
          </pre>
        </div>
        <hr />
        <h3>Create notebook from template</h3>
        <StyledForm onSubmit={handleSubmit}>
          <InfoContainer>
            <p>Some templates may support or even require parameters.</p>
            <div>
              <InfoLabel htmlFor="templatePayload">Payload (as JSON)</InfoLabel>
              <StyledInfoInput
                as="textarea"
                expandError={!!expandError}
                defaultValue={expandPayload}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setExpandPayload(event.target.value)
                }
              />
              {expandError && <div>{expandError.message}</div>}
            </div>
            <ButtonBar>
              <Button type="submit" className={shakeClassName}>
                Expand
              </Button>
            </ButtonBar>
          </InfoContainer>
        </StyledForm>
      </InfoContainer>
    </StyledFormContainer>
  );
}
