import { useMemo } from "react";

import { getQueryField } from "../../../../utils";

/**
 * Returns a specific field from the query data.
 */
export function useQueryData(
  queryData: string | undefined,
  fieldName: string,
): string {
  return useMemo(
    () => getQueryField(queryData, fieldName),
    [queryData, fieldName],
  );
}
