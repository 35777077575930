import { Icon } from "@fiberplane/ui";
import { css, styled } from "styled-components";

import { LabelList, StyledLabel } from "../../../UI";

export const ResultHeader = styled.header`
  grid-row: 1 / 2;
  align-self: center;

  &:last-child {
    grid-row: 1 / 3;
  }
`;

export const ResultTitle = styled.strong`
  ${({ theme }) => css`
    font: ${theme.font.body.md.regular};
    color: ${theme.color.fg.default};
  `};
`;

export const ResultSubTitle = styled(ResultTitle).attrs({ as: "span" })`
  color: ${({ theme }) => theme.color.fg.muted};

  &::before {
    content: " - ";
  }
`;

export const ResultIcon = styled(Icon)`
  grid-row: 1 / 3;
  align-self: center;
  justify-self: center;
  color: ${({ theme }) => theme.color.fg.default};
`;

export const ResultLabels = styled.div`
  grid-row: 2;
`;

export const StyledResultLabelList = styled(LabelList)`
  ${StyledLabel} {
    cursor: pointer;
  }
`;
