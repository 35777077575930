import { useCallback } from "react";
import { useSelector } from "react-redux";
import { selectNotebookFrontMatterSchema } from "../../../selectors";
import { useAppDispatch } from "../../../store";
import {
  appendFrontMatterSchema,
  updateFrontMatterValue,
} from "../../../thunks";
import { getSimilarNotebookDetails } from "./config";
import type { CopilotAction } from "./types";

const commanderSchemaDef = {
  type: "user" as const,
  displayName: "Commander",
};

/**
 * Executes side-effects of mocked actions from copilot
 * At writing the only side-effect it performs is assigning someone as the notebook commander
 */
export function useActionSideEffectHandler() {
  const dispatch = useAppDispatch();
  const currentSchema = useSelector(selectNotebookFrontMatterSchema);
  const hasCurrentCommander = currentSchema.some((s) => s.key === "commander");

  return useCallback(
    (action: CopilotAction) => {
      switch (action.type) {
        case "ASSIGN_COMMANDER": {
          if (!hasCurrentCommander) {
            dispatch(appendFrontMatterSchema("commander", commanderSchemaDef));
          }
          const { commander } = getSimilarNotebookDetails();
          dispatch(
            updateFrontMatterValue("commander", {
              id: commander.id,
              name: commander.name,
            }),
          );
          break;
        }
        default:
          break;
      }
    },
    [dispatch, hasCurrentCommander],
  );
}
