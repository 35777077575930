import { cancelEvent } from "@fiberplane/ui";
import { IMAGE_MIME_TYPES } from "../constants";
import {
  selectActiveNotebookId,
  selectCellIds,
  selectFocusedCell,
  selectNotebookFocus,
} from "../selectors";
import { dispatch, getState } from "../store";
import { addImageCell, changeCellType, splitCellWithImage } from "../thunks";
import { isContentCell } from "../types";
import {
  charCount,
  emptyDrafts,
  getCellFocus,
  getCursorOffset,
  noCellFocus,
  noThreadItems,
  track,
  validateImageFileSize,
} from "../utils";

const validateFileType = (file: File) => IMAGE_MIME_TYPES.includes(file?.type);

/**
 * Hook te be used on cell level where the user is able to paste an image onto.
 *
 * @returns onPaste event handler callback function
 */
export function useFilePaste(cellId: string) {
  return (event: React.ClipboardEvent<HTMLElement>) => {
    const file = event.clipboardData.files[0];
    if (!file) {
      return;
    }

    cancelEvent(event);

    const validFile = validateFileType(file);
    if (!validFile) {
      return;
    }

    const fileError = validateImageFileSize(file);

    const dispatchImageCell = (file: File, position: "before" | "after") => {
      const notebookId = selectActiveNotebookId(getState());
      track("notebook | add cell", {
        notebookId,
        position,
        cellType: "image",
        source: "paste image",
      });

      dispatch(
        addImageCell({ relatedId: cellId, position, file, error: fileError }),
      );
    };

    // Select current state when the callback is invoked, so we don't need to
    // inject values during rendering:
    const state = getState();
    const notebookFocus = selectNotebookFocus(state);
    const cell = selectFocusedCell(state);
    const cellFocus = cell
      ? getCellFocus(
          selectCellIds(state),
          cell,
          emptyDrafts,
          noThreadItems,
          notebookFocus,
        )
      : noCellFocus;

    // If no option object is given, assume the cell isn't a free input
    // field; insert image below
    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version (which is less readable)
    if (!cell || !isContentCell(cell) || cellFocus.type === "none") {
      dispatchImageCell(file, "after");
      return;
    }

    // When pasting in an empty cell: call thunk which changes the cell into
    // an image cell and triggers the file upload immediately
    const cellContentLength = charCount(cell.content);
    if (!cellContentLength) {
      const notebookId = selectActiveNotebookId(state);
      track("notebook | change cell type", {
        notebookId,
        cellType: "image",
        source: "paste image",
      });

      dispatch(changeCellType(cellId, { type: "image" }, { file, fileError }));
      return;
    }

    // When cursor is at beginning of text input: insert new cell above and
    // trigger file upload
    const focusOffset = getCursorOffset(cellFocus);
    if (focusOffset === 0) {
      dispatchImageCell(file, "before");
      return;
    }

    // When cursor is at end of text input: insert new cell below and trigger
    // file upload
    if (focusOffset === cellContentLength) {
      dispatchImageCell(file, "after");
      return;
    }

    // Otherwise: split cell
    dispatch(
      splitCellWithImage({
        focus: selectNotebookFocus(getState()),
        file,
        error: fileError,
      }),
    );
  };
}
