import type React from "react";
import { type ComponentProps, useMemo } from "react";
import { useSelector } from "react-redux";

import { cancelEvent } from "@fiberplane/ui";
import { useThrottledLoadingState } from "../../../../hooks";
import { makeIsRunningSelector } from "../../../../selectors";
import { dispatch } from "../../../../store";
import { invokeProviderCell } from "../../../../thunks";
import type { CellFocus } from "../../../../types";
import { isMac } from "../../../../utils";
import { SupportedSyntax, useHighlighter } from "../../RTE";
import {
  QueryButton,
  QueryFieldContainer,
  QueryIconContainer,
  QueryInput,
} from "../../codeStyling";
import { useQueryFieldValidationError } from "./QueryError";

type Props = {
  cellId: string;
  field: string;
  focus: CellFocus;
  icon?: React.ReactNode;
  placeholder?: string;
  readOnly?: boolean;
  showRunCta?: boolean;
  value: string;
  supportsSuggestions: boolean;
};

export function TextField({
  cellId,
  field,
  focus,
  icon,
  placeholder,
  readOnly,
  showRunCta,
  value,
  supportsSuggestions,
}: Props): JSX.Element {
  const isRunningSelector = useMemo(
    () => makeIsRunningSelector(cellId),
    [cellId],
  );

  const isRunning = useSelector(isRunningSelector);
  // NOTE FP-2592: We will use this to force the Run Query button to stay in a loading state for at least 2 seconds
  //               The query result itself, though, may render in the cell more quickly
  const throttledIsRunning = useThrottledLoadingState(isRunning, 3000);
  const { ref, errorOutput } = useQueryFieldValidationError<HTMLDivElement>(
    cellId,
    field,
  );

  // FIXME FP-1998: HACK
  const Input = placeholder?.includes("Prometheus") ? PromQlInput : QueryInput;

  return (
    <>
      <QueryFieldContainer ref={ref} $error={!!errorOutput}>
        {icon && <QueryIconContainer>{icon}</QueryIconContainer>}
        <Input
          cellId={cellId}
          field={field}
          focus={focus}
          placeholder={placeholder}
          readOnly={readOnly}
          value={value}
          // https://linear.app/fiberplane/issue/AM-189/styled-components-v6-fix-ts-expect-error-exclusions
          // @ts-expect-error prop isn't inferred properly
          supportsSuggestions={supportsSuggestions}
        />
        {showRunCta && !readOnly && (
          <QueryButton
            aria-label="Run cell"
            data-run-cell
            data-shortcut={`${isMac ? "cmd" : "ctrl"}+enter`}
            disabled={throttledIsRunning}
            onClick={(event: React.MouseEvent) => {
              cancelEvent(event);
              dispatch(invokeProviderCell(cellId));
            }}
          >
            Run
          </QueryButton>
        )}
      </QueryFieldContainer>
      {errorOutput}
    </>
  );
}

// FIXME FP-1998: Providers should become responsible for their own syntax
//                highlighting.
function PromQlInput(props: ComponentProps<typeof QueryInput>) {
  const formatting = useHighlighter(SupportedSyntax.promql, props.value);

  return <QueryInput {...props} formatting={formatting} disableSpellCheck />;
}
