import type { Event } from "../types";

export function isPartial<T extends Record<string, unknown>, U extends keyof T>(
  object: T | Partial<T>,
  ...keys: Array<U>
): object is Partial<T> {
  for (const key of keys) {
    if (key in object === false) {
      return true;
    }
  }

  return false;
}

export function isPartialEvent(
  event: Event | Partial<Event>,
): event is Partial<Event> {
  return isPartial(event, "title", "occurrenceTime");
}
