import { useHandler } from "@fiberplane/hooks";
import { useEffect, useState } from "react";

import type { MenuProps } from "./Menu";
import type { Selection } from "./types";

export function useSelection(
  items: Array<React.ReactElement>,
  { onSelectionChange, preventDefaultSelection }: MenuProps,
): [selection: Selection, setSelection: (selection: Selection) => void] {
  const [selection, setSelectionInternal] = useState<Selection>({
    itemId: undefined,
    selectedBy: "mouse",
  });

  const setSelection = useHandler((newSelection: Selection) => {
    setSelectionInternal(newSelection);
    onSelectionChange?.(newSelection);
  });

  // If the selection changes, check if it still exists. If the selected item
  // doesn't exist anymore, reset the selection:
  useEffect(() => {
    const selectedItemExists = items.some(
      (item) => item.props.id === selection.itemId,
    );
    if (selectedItemExists) {
      return;
    }

    const newItem = items[0];
    if (newItem && "id" in newItem.props && !preventDefaultSelection) {
      setSelection({ itemId: newItem.props.id, selectedBy: "key" });
    }
  }, [selection, items, setSelection, preventDefaultSelection]);

  return [selection, setSelection];
}
