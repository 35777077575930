import { FRONT_MATTER_CELL_ID } from "../../../../../constants";
import type {
  CellFocus,
  CellFocusPosition,
  NotebookFocus,
} from "../../../../../types";
import { charCount, charIndex, getCursorOffset } from "../../../../../utils";
import { getLineHeightForContainer } from "../../../RTE";

export type Position = {
  type: "collapsed" | "selection";
  start: number;
  end: number;
  direction: "forward" | "backward";
};

export function positionToNotebookFocus(
  field: string,
  position: Position,
): NotebookFocus {
  return position.type === "collapsed"
    ? {
        type: "collapsed",
        cellId: FRONT_MATTER_CELL_ID,
        field,
        offset: position.end,
      }
    : {
        type: "selection",
        anchor: {
          cellId: FRONT_MATTER_CELL_ID,
          field,
          offset:
            position.direction === "forward" ? position.start : position.end,
        },
        focus: {
          cellId: FRONT_MATTER_CELL_ID,
          field,
          offset:
            position.direction === "forward" ? position.end : position.start,
        },
      };
}

export function createCellFocus(
  editorNode: HTMLElement,
  field: string,
): CellFocus {
  let endOffset = 0;
  let startOffset = 0;
  let direction: "forward" | "backward" = "forward";

  const selection = window.getSelection();
  if (selection?.rangeCount) {
    const range = selection.getRangeAt(0);
    if (range.commonAncestorContainer.parentNode === editorNode) {
      const text = editorNode.textContent ?? "";
      endOffset = charCount(text, range.endOffset);
      startOffset = charCount(text, range.startOffset);
      direction =
        selection.anchorOffset > selection.focusOffset ? "backward" : "forward";
    }
  }

  if (startOffset === endOffset) {
    return {
      type: "collapsed",
      field,
      offset: endOffset,
    };
  }

  const start: CellFocusPosition = {
    field,
    offset: startOffset,
  };

  const end: CellFocusPosition = {
    field,
    offset: endOffset,
  };

  return {
    type: "selection",
    start,
    end,
    focus: direction === "forward" ? end : start,
  };
}

/**
 * Determines if the cursor is at the top or bottom of the editor
 *
 * cellFocus (optional) parameter can be used instead for using a
 * custom cellFocus instead of what the browser currently has
 */
export function isCursorAtTopOrBottom(
  editorNode: HTMLElement,
  field: string,
  cellFocus: CellFocus = createCellFocus(editorNode, field),
): {
  atTop: boolean;
  atBottom: boolean;
  cellFocus?: CellFocus;
} {
  const selection = window.getSelection();
  const editorRect = editorNode.getBoundingClientRect();
  const rangeContainer = editorNode.firstChild;

  const offset = getCursorOffset(cellFocus, field);

  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
  if (!selection || !rangeContainer || offset === undefined) {
    return { atTop: false, atBottom: false, cellFocus: undefined };
  }

  const newRange = document.createRange();

  const text = rangeContainer.textContent ?? "";
  const domOffset = charIndex(text, offset);
  newRange.setStart(rangeContainer, domOffset);
  newRange.setEnd(rangeContainer, Math.min(domOffset + 1, charCount(text)));

  const cursorRect = newRange.getBoundingClientRect();
  const lineHeight = getLineHeightForContainer(editorNode);
  const atTop = cursorRect.top <= editorRect.top + lineHeight;
  const atBottom = cursorRect.bottom >= editorRect.bottom - lineHeight;

  return { atTop, atBottom, cellFocus };
}
