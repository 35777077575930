import { useMemo } from "react";

import { TIMELINE_MIME_TYPE } from "../../../../constants";
import { useProviderData } from "../../../../hooks";
import type { Timeline, TimelineCell } from "../../../../types";
import { compact, getParentCellId, parseTimelineBlob } from "../../../../utils";

export const useTimelineData = (cell: TimelineCell): Array<Timeline> => {
  const rawData = useProviderData(
    getParentCellId(cell.id),
    cell.dataLinks ?? [],
    TIMELINE_MIME_TYPE,
    parseTimelineBlob,
  );

  return useMemo(() => {
    return compact(rawData).flat();
  }, [rawData]);
};
