import { Icon, type IconType } from "@fiberplane/ui";
import type { ComponentProps } from "react";
import { useSelector } from "react-redux";
import { generatePath, matchPath } from "react-router";
import { Link } from "react-router-dom";
import { createSelector } from "reselect";
import { css, styled } from "styled-components";

import { ROUTES } from "../../../constants";
import {
  selectActiveView,
  selectActiveWorkspace,
  selectNotebookError,
  selectNotebookTitle,
  selectPathname,
} from "../../../selectors";
import { shallowEqualArrays } from "../../../utils";

type BreadcrumbPart = {
  title: string;
  icon?: IconType;
  path?: string;
};

export function Breadcrumb(): JSX.Element | null {
  const parts = useSelector(selectBreadcrumb);

  return (
    <Container>
      {parts.map(({ title, icon, path }, index) => (
        <BreadcrumbItem key={title}>
          {path ? (
            <BreadcrumbLink to={path}>
              {icon && <BreadcrumbIcon iconType={icon} />}
              {title}
            </BreadcrumbLink>
          ) : (
            <BreadcrumbText>
              {icon && <BreadcrumbIcon iconType={icon} />}
              {title}
            </BreadcrumbText>
          )}
          {index < parts.length - 1 && <Icon iconType="caret_right" />}
        </BreadcrumbItem>
      ))}
    </Container>
  );
}

const selectBreadcrumb = createSelector(
  [
    selectPathname,
    selectActiveWorkspace,
    selectNotebookTitle,
    selectNotebookError,
    selectActiveView,
  ],
  (
    pathname,
    workspace,
    notebookTitle,
    notebookError,
    view,
  ): Array<BreadcrumbPart> => {
    const parts: Array<BreadcrumbPart> = [];

    if (pathname && workspace) {
      const { name: workspaceName, displayName } = workspace;

      const path = generatePath(ROUTES.GettingStarted, { workspaceName });
      parts.push({ title: displayName, path });

      if (matchPath(ROUTES.Notebooks, pathname)) {
        parts.push({ title: "Notebooks", icon: "notebook_menu" });
      } else if (matchPath(ROUTES.Notebook, pathname)) {
        parts.push({
          title: "Notebooks",
          icon: "notebook_menu",
          path: generatePath(ROUTES.Notebooks, { workspaceName }),
        });

        if (!notebookError) {
          parts.push({ title: notebookTitle });
        }
      } else if (matchPath(ROUTES.Templates, pathname)) {
        parts.push({ title: "Templates", icon: "templates" });
      } else if (matchPath(ROUTES.Views, pathname)) {
        parts.push({ title: "Views", icon: "views" });
      } else if (matchPath(ROUTES.View, pathname) && view) {
        parts.push(
          {
            title: "Views",
            icon: "views",
            path: generatePath(ROUTES.Views, { workspaceName }),
          },
          { title: view.displayName },
        );
      } else if (matchPath(ROUTES.GettingStarted, pathname)) {
        parts.push({ title: "Getting Started", icon: "rocket" });
      }
    }

    return parts;
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqualArrays } },
);

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  font: ${({ theme }) => theme.font.headings.h5};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
`;

const BreadcrumbItem = styled.span`
  display: inline-flex;
  align-items: center;
`;

const BreadcrumbText = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
`;

const BreadcrumbLink = styled(BreadcrumbText).attrs({ as: Link })<
  ComponentProps<typeof Link>
>(
  ({ theme }) => css`
    text-decoration: none;
    color: ${theme.color.fg.muted};
    border-radius: ${theme.radius.minimal};

    &:hover {
      background: ${theme.color.bg.hover};
    }
  `,
);

const BreadcrumbIcon = styled(Icon)`
  width: 16px;
  height: 16px;
`;
