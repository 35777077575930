import { useSelector } from "react-redux";
import { css, styled } from "styled-components";

import { useTimeAgo } from "../../hooks";
import { selectNotebookCreatedAt } from "../../selectors";
import { CellGridContainer } from "../Cell";

export function NotebookCreatedAtIndicator() {
  const createdAt = useSelector(selectNotebookCreatedAt);
  const formattedCreatedAt = useTimeAgo(createdAt, { strict: true });

  return (
    <CellGridContainer>
      <CreatedAndUpdatedText>
        Created {formattedCreatedAt}
      </CreatedAndUpdatedText>
    </CellGridContainer>
  );
}

const CreatedAndUpdatedText = styled.p(
  ({ theme }) => css`
    grid-area: main;
    margin: 0 0 8px;
    font: ${theme.font.body.sm.regular};
    color: ${theme.color.fg.subtle};
  `,
);
