export function getTokenFromLocationHash(): undefined | string {
  const { location } = document;
  const { token } = parseHashParams(location.hash);
  return token;
}

export function parseHashParams(value: string): Record<string, string> {
  const cleanValue = value.startsWith("#") ? value.slice(1) : value;
  const items = cleanValue.split("|");
  const params: Record<string, string> = {};
  for (const item of items) {
    const [key, ...values] = item.split("=");
    if (key) {
      params[key] = values.join("=");
    }
  }

  return params;
}
