import { css, styled } from "styled-components";

import { RichTextInput } from "../RTE";

/**
 * Styled `RichTextInput` component for comment input/replies. Renders input
 * styled with border, background color, padding, and font when not read-only.
 */
export const StyledRichTextInput = styled(RichTextInput)(
  ({ readOnly, theme }) =>
    readOnly
      ? css`
          cursor: text;
        `
      : css`
          border-radius: ${theme.radius.default};
          border: 1px solid ${theme.color.input.border.default};
          background-color: ${theme.color.input.bg};
          padding: 6px 12px;
          font: ${theme.font.body.md.regular};
        `,
);
