import {
  FAIL_LOADING_PROXY,
  FAIL_LOADING_PROXY_LIST,
  FINISH_LOADING_PROXY,
  FINISH_LOADING_PROXY_LIST,
  START_LOADING_PROXY,
  START_LOADING_PROXY_LIST,
} from "../actions";
import { selectActiveWorkspaceIdOrThrow } from "../selectors";
import { Api } from "../services";
import type { Thunk } from "../store";
import { addNotification, updateNotification } from "./notificationsThunks";

export const createProxyAndRefreshList =
  (newProxy: Api.NewProxy): Thunk<Promise<Api.Proxy>> =>
  async (dispatch, getState) => {
    const workspaceId = selectActiveWorkspaceIdOrThrow(getState());

    const proxy = await Api.createProxy(workspaceId, newProxy);
    dispatch(loadProxyList());
    return proxy;
  };

export const deleteProxyAndRefreshList =
  (proxyName: string): Thunk =>
  async (dispatch, getState) => {
    let key = dispatch(
      addNotification({
        title: "Deleting FPD instance",
        disableAutoHide: true,
        hideCloseButton: true,
      }),
    );

    try {
      const workspaceId = selectActiveWorkspaceIdOrThrow(getState());

      await Api.deleteProxy(workspaceId, proxyName);
      key = dispatch(
        updateNotification(key, {
          title: "FPD instance deleted",
        }),
      );

      dispatch(loadProxyList());
    } catch {
      dispatch(
        updateNotification(key, {
          title: "Failed to delete FPD instance",
          type: "danger",
        }),
      );
    }
  };

export const loadProxy =
  (name: string): Thunk =>
  async (dispatch, getState) => {
    dispatch({ type: START_LOADING_PROXY, payload: { name } });

    try {
      const workspaceId = selectActiveWorkspaceIdOrThrow(getState());

      const proxy = await Api.getProxy(workspaceId, name);
      dispatch({ type: FINISH_LOADING_PROXY, payload: proxy });
    } catch (error) {
      console.error(error);

      dispatch({
        type: FAIL_LOADING_PROXY,
        payload: { name, error: "Failed to load FPD details" },
      });
    }
  };

export const loadProxyList = (): Thunk => async (dispatch, getState) => {
  dispatch({ type: START_LOADING_PROXY_LIST });

  try {
    const workspaceId = selectActiveWorkspaceIdOrThrow(getState());

    const proxies = await Api.listProxies(workspaceId);
    dispatch({ type: FINISH_LOADING_PROXY_LIST, payload: proxies });
  } catch (error) {
    console.error(error);

    dispatch({
      type: FAIL_LOADING_PROXY_LIST,
      payload: "Failed to load FPD list",
    });
  }
};
