import { useMemo } from "react";
import { useSelector } from "react-redux";
import useAsync from "react-use/lib/useAsync";
import { styled } from "styled-components";

import debounce from "debounce-promise";
import { selectNotebookFocus } from "../../../selectors";
import { dispatch } from "../../../store";
import {
  applySuggestion,
  fetchSuggestionsForFocusPosition,
} from "../../../thunks";
import type { FocusPosition, NotebookContextMenuInfo } from "../../../types";
import {
  type FilterMenuItem,
  PositionedFilterMenu,
  type PositionedFilterMenuProps,
} from "../../UI";

type Props = {
  contextMenu: NotebookContextMenuInfo;
} & Omit<PositionedFilterMenuProps, "items">;

const debouncedFetchSuggestions = debounce(
  (
    focus: {
      type: "collapsed";
    } & FocusPosition,
  ) => dispatch(fetchSuggestionsForFocusPosition(focus)),
  1000,
);

export function AutoSuggestionsMenu({
  contextMenu,
  ...menuProps
}: Props): JSX.Element | null {
  const focus = useSelector(selectNotebookFocus);

  const suggestions = useAsync(
    () =>
      focus?.type === "collapsed"
        ? debouncedFetchSuggestions(focus)
        : Promise.resolve([]),
    [focus],
  );

  const items: Array<FilterMenuItem> | undefined = useMemo(
    () =>
      suggestions.value?.map(({ text, description, from }) => ({
        type: "item_with_description",
        id: text,
        title: text,
        description,
        onActivate: () =>
          dispatch(applySuggestion({ contextMenu, text, from })),
      })),
    [contextMenu, suggestions],
  );
  if (!items) {
    return null;
  }

  return (
    <StyledPositionedMenu
      {...menuProps}
      items={items}
      noResultsText="No suggestions"
      placement="bottom-start"
    />
  );
}

const StyledPositionedMenu = styled(PositionedFilterMenu)`
  max-height: 300px;
  width: 345px;
`;
