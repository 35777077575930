import { useSelector } from "react-redux";
import { styled } from "styled-components";

import { Input } from "@fiberplane/ui";
import { showError, withNotebook } from "../../../../actions";
import { selectNotebookVisibility } from "../../../../selectors";
import { useAppDispatch } from "../../../../store";
import {
  setNotebookVisibility,
  showNotebookVisibilityConfirmation,
} from "../../../../thunks";
import { useShake } from "../../../UI";

type Props = {
  id: string;
};

export function PublicToggle({ id }: Props) {
  const dispatch = useAppDispatch();
  const visibility = useSelector(selectNotebookVisibility);
  const { shake, shakeClassName } = useShake();

  const onToggle = ({
    target: { checked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      dispatch(showNotebookVisibilityConfirmation(id));
      return;
    }

    dispatch(setNotebookVisibility(id, "private")).catch((error) => {
      dispatch(
        withNotebook(
          id,
          showError({
            type: "other",
            message: `Failed to set notebook to ${visibility}: ${error}`,
          }),
        ),
      );
      shake();
    });
  };

  return (
    <Container>
      <Content>
        <PublicText>Make public</PublicText>
        <PublicDescription>Anyone with the link can view</PublicDescription>
      </Content>

      <Input
        type="lightswitch"
        checked={visibility === "public"}
        onChange={onToggle}
        className={shakeClassName}
        data-testid="toggle"
        aria-checked={visibility === "public"}
      />
    </Container>
  );
}

const Container = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 20px;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const PublicText = styled.div`
  font: ${({ theme }) => theme.font.body.md.medium};
`;

const PublicDescription = styled.div`
  color: ${({ theme }) => theme.color.fg.muted};
`;
