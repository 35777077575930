import type { CreatedBy } from "../types";

export function getCreatedByLabel(createdBy: CreatedBy) {
  switch (createdBy.type) {
    case "user":
      return `by ${createdBy.name}`;

    case "trigger":
      return `by trigger ${createdBy.title}`;

    case "onboarding":
      return "by Fiberplane";

    default:
      return "";
  }
}
