// Define fallbacks
const SIMILAR_NOTEBOOK_TITLE = "Incident: API Latency Spike (2024-04-16)";
const SIMILAR_NOTEBOOK_ID = "abc123";
const SIMILAR_COMMANDER_USER_NAME = "Jacco Flenter";
const SIMILAR_COMMANDER_USER_ID = "jacco-flenter";

// NOTE - This query is a template, you need to replace %SMOOTHING_WINDOW with the appropriate window for the PromQL query
const HIGH_LATENCY_QUERY_DEFAULT = `
histogram_quantile(
  0.99,
  sum by (le, function, module, commit, version, service_name) (
    rate({
      __name__=~"function_calls_duration(_seconds)?_bucket",
      objective_name="Animal API SLO for Super Low Latency",
    }[%SMOOTHING_WINDOW])
    # Attach the version and commit labels from the build_info metric
    * on (instance, job) group_left(version, commit) (
      last_over_time(build_info[5s])
      or on (instance, job) up
    )
  )
)
`.trim();

/**
 * Helper that can get a configuration for the demo from localStorage
 * Allows us to dynamically define the details (title, id) 
 * of the notebook that shows up as a similar notebook.
 * 
 * See the function body for which localStorage keys are used.
 * 
 * To set this config yourself, run:
   ```js
   localStorage.setItem('aidemo.commanderName', 'Ted Lasso');
   localStorage.setItem('aidemo.commanderId', 'id-for-commander');
   localStorage.setItem('aidemo.notebookTitle', 'Title for similar notebook');
   localStorage.setItem('aidemo.notebookId', 'id-for-similar-notebook');
   ```

  * @NOTE - In some cases, you need to provide a *real* user to be able to set the commander without an error:
   ```js
   localStorage.setItem('aidemo.commanderName', 'Org1 User');
   localStorage.setItem('aidemo.commanderId', 'wD4ny8pOT4y7vHPCe6bjZg');
   ```
 */
export const getSimilarNotebookDetails = () => {
  const commanderName = localStorage.getItem("aidemo.commanderName");
  const commanderId = localStorage.getItem("aidemo.commanderId");
  const notebookTitle = localStorage.getItem("aidemo.notebookTitle");
  const notebookId = localStorage.getItem("aidemo.notebookId");

  return {
    title: notebookTitle ?? SIMILAR_NOTEBOOK_TITLE,
    id: notebookId ?? SIMILAR_NOTEBOOK_ID,
    commander: {
      name: commanderName ?? SIMILAR_COMMANDER_USER_NAME,
      id: commanderId ?? SIMILAR_COMMANDER_USER_ID,
    },
  };
};

/**
 * Helper that can get the latency query for the demo from localStorage
 * This will be the query that the copilot suggests to run in a notebook
 */
export const getLatencyQuery = () => {
  return (
    localStorage.getItem("aidemo.latencyQuery") ?? HIGH_LATENCY_QUERY_DEFAULT
  );
};
