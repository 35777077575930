import { useHandler } from "@fiberplane/hooks";
import { AttachedPopup } from "@fiberplane/ui";
import { useEffect, useRef, useState } from "react";
import { useClickAway } from "react-use";
import styled, { css } from "styled-components";
import { LARGE_SIZE_QUERY, MEDIUM_SIZE_QUERY } from "../../../../../constants";
import { isGithubPullRequest } from "../../../../../utils";
import { AvatarIcon } from "../../../../Avatar";
import { useFocused, useFrontMatterValue } from "../../hooks";
import { GitHubPullRequestMetaData } from "./GitHubPullRequestMetaData";
import { PrStatusPill } from "./PrStatusPill";
import { mergePrStatus } from "./utils";

export function FieldGitHubPullRequest({ id }: { id: string }) {
  // const { value, onChange } = useFieldGitHubPullRequest(id);
  const value = useFrontMatterValue(id, isGithubPullRequest);
  const focus = useFocused(id);

  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  const [visible, setVisible] = useState(false);
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout>>();
  const popupRef = useRef<HTMLDivElement | null>(null);

  const handleClose = useHandler(() => {
    setVisible(false);
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = undefined;
    }
  });

  useClickAway(popupRef, () => {
    handleClose();
  });

  useEffect(() => {
    if (ref && focus) {
      ref.focus();
    }

    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = undefined;
    }
    if (focus) {
      timeoutIdRef.current = setTimeout(() => {
        setVisible(true);
        timeoutIdRef.current = undefined;
      }, 500);
    } else {
      handleClose();
    }
  }, [ref, focus, handleClose]);
  if (!value) {
    return null;
  }

  const mergedStatus = mergePrStatus(value);

  return (
    <>
      <Container
        ref={setRef}
        tabIndex={focus ? 0 : undefined}
        className={focus ? "active" : undefined}
        onClick={() => {
          if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
          }
          setVisible(true);
        }}
        onKeyDown={(event) => {
          if (event.key === "Escape") {
            handleClose();
          }

          if (event.key === " ") {
            if (timeoutIdRef.current) {
              clearTimeout(timeoutIdRef.current);
            }

            setVisible(!visible);
          }
        }}
      >
        <NumberContainer>
          <GitHubAvatarIcon
            name={value.author}
            src={value.authorAvatarUrl}
            size={20}
          />
          <PrNumber>#{value.number}</PrNumber>
        </NumberContainer>
        <Title arial-label={value.title}>{value.title}</Title>
        <PillContainer>
          <PrStatusPill status={mergedStatus} />
        </PillContainer>
      </Container>
      {visible && (
        <AttachedPopup element={ref} placement="bottom-start" offset={[0, 4]}>
          <div ref={popupRef}>
            <GitHubPullRequestMetaData {...value} requestClose={handleClose} />
          </div>
        </AttachedPopup>
      )}
    </>
  );
}

const Container = styled.div(
  ({ theme }) => css`
  display: grid;
  grid: "number title status" / auto 1fr auto;
  min-width: 0;
  
  gap: 8px;
  height: 100%;
  border-radius: ${theme.radius.default};
  padding: 8px;
  margin-top: -4px;
  margin-bottom: -4px;
  cursor: pointer;
  
  grid: "number status" auto
  "title title" / auto 1fr auto;
  @media ${LARGE_SIZE_QUERY} {
    grid: "number title status" / auto 1fr auto;
  }

  &.active,
  &:hover {
    background: ${theme.color.bg.hover};
  }

  &:focus {
    outline: none;
  }
`,
);

const NumberContainer = styled.span`
  grid-area: number;
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px; 
`;
const PrNumber = styled.span(
  ({ theme }) => css`
  color: ${theme.color.fg.muted};
`,
);

const Title = styled.span`
  grid-area: title;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const PillContainer = styled.div`
  width: fit-content;
  grid-area: status;
`;

const GitHubAvatarIcon = styled(AvatarIcon)`
  display: none;
  @media ${MEDIUM_SIZE_QUERY} {
    display: inline;
  }
  `;
