import { useHandler } from "@fiberplane/hooks";
import { type DragEvent, type ReactNode, useCallback, useState } from "react";

import { FileDropContext, type FileDropContextType } from "./context";

type Props = {
  className?: string;
  children: ReactNode;
};

export function FileDropContainer(props: Props) {
  const [files, setFiles] = useState<FileDropContextType["files"]>([]);

  const onDrop = useHandler((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    // Set the list of files to an empty array when dropping files
    setFiles([]);
  });

  const onDragEnter = useHandler((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    // Create a new array of files that contain the most relevant data
    const newItems = [];
    for (const item of event.dataTransfer.items) {
      newItems.push({ type: item.type, kind: item.kind });
    }

    setFiles(newItems);
  });

  const onDragLeave = useHandler((event: DragEvent<HTMLDivElement>) => {
    // If the related target is inside the current target
    // assume not actually leaving
    if (
      event.relatedTarget instanceof Node &&
      event.currentTarget.contains(event.relatedTarget)
    ) {
      return;
    }

    // Set the item list to an empty array (i.e. nothing is selected)
    setFiles([]);
  });

  const onDragOver = useCallback((event: DragEvent) => {
    // This preventDefault is required to prevent the default drag/drop behavior of the browser
    // (i.e. open the file in a (current/new) tab)
    event.preventDefault();
  }, []);

  return (
    <FileDropContext.Provider value={{ files, setFiles }}>
      <div
        className={props.className}
        onDragLeave={onDragLeave}
        onDragEnter={onDragEnter}
        onDrop={onDrop}
        onDragOver={onDragOver}
      >
        {props.children}
      </div>
    </FileDropContext.Provider>
  );
}
