import type { FocusPosition } from ".";

export type CellFocusPosition = Omit<FocusPosition, "cellId">;

/**
 * Specifies the user's focus and optional selection within a specific cell.
 */
export type CellFocus =
  /**
   * The user has no focus within the cell.
   */
  | { type: "none" }

  /**
   * The user focus is within the cell and the focus is on a single
   * position. I.e. there is no selection.
   */
  | ({ type: "collapsed" } & CellFocusPosition)

  /**
   * The user has a selection within the cell that runs from the `start` to the
   * `end`.
   *
   * If the user's focus position is inside the cell, `focus` is set too. To
   * avoid the need for deep comparisons, `focus` will be strictly equal to
   * either `start` or `end` if set.
   */
  | {
      type: "selection";
      start: CellFocusPosition;
      end: CellFocusPosition;
      focus?: CellFocusPosition;
    };
