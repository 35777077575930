import type { Placement, VirtualElement } from "@popperjs/core";

export type CloseTooltipFn = () => void;

export type TooltipContent = string | React.ReactElement<{ text: string }>;

export type ShowTooltipFn = (
  anchor: TooltipAnchor,
  content: TooltipContent,
  options?: TooltipOptions,
) => CloseTooltipFn;

export type TooltipAnchor = HTMLElement | VirtualElement;

export type TooltipOptions = {
  /**
   * By default, tooltips don't receive any mouse events and will hide if you
   * attempt to hover over them. By setting this to `false`, the user is able
   * to interact with the tooltip.
   */
  hideOnHover?: boolean;

  placement?: Placement;

  onEnterDelayMs?: number;
};
