import { useHandler, useLocalStorage } from "@fiberplane/hooks";
import { Suspense, lazy, memo, useRef } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { styled } from "styled-components";

import { CONSOLE_COLLAPSED_HEIGHT } from "../../constants";
import { useFeature, useUndoRedoKeyboardHandlers } from "../../hooks";
import {
  selectActiveNotebook,
  selectActiveWorkspaceRole,
  selectConsole,
  selectIsAuthenticated,
} from "../../selectors";
import type { RootState } from "../../state";
import { pick } from "../../utils";
import {
  Cell,
  CellGridContainer,
  FrontMatterCells,
  GlobalTimeSelectorCell,
  LabelsCell,
  TitleCell,
} from "../Cell";
import { DragAndDropContainer } from "../DragAndDropContainer";
import { FileDropContainer, HorizontalRule } from "../UI";
import { useSubscribeToWorkspace } from "../Workspaces";
import { CopilotContainer, CopilotSidebar } from "./CopilotSidebar";
import { DataSourcesLoader } from "./DataSourcesLoader";
import { EmptyCellArea } from "./EmptyCellArea";
import { NotebookCreatedAtIndicator } from "./NotebookCreatedAtIndicator";
import { NotebookError } from "./NotebookError";
import { ReadOnlyBanner } from "./ReadOnlyBanner";
import { UnrecoverableErrorBanner } from "./UnrecoverableErrorBanner";
import {
  useCellDeepLinking,
  useFocusHighlight,
  useNotebookEventHandlers,
  useRevisionTitle,
} from "./hooks";

const TableOfContents = lazy(() =>
  import("./TableOfContents").then(({ TableOfContents }) => ({
    default: TableOfContents,
  })),
);

export const Notebook = memo(function Notebook(): JSX.Element | null {
  const [isCopilotEnabled] = useFeature("ai-copilot");
  const [hasTocEnabled] = useFeature("notebook-table-of-contents");

  const isSideMenuOpen = useSelector(
    (state: RootState) => state.ui.sideMenuIsOpen,
  );

  // HACK - localstorage is updated with new value for width by our resizing logic in the child component lollllll
  const [storedCopilotWidth] = useLocalStorage<number>(
    "studio.copilotSidebarWidth",
    500,
  );

  const {
    cellIds,
    error,
    loading,
    readOnly: notebookReadonly,
    unrecoverable,
  } = useSelector(selectNotebookState, shallowEqual);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const role = useSelector(selectActiveWorkspaceRole);

  const readOnly = !role || role === "read" || notebookReadonly;
  const isRoleLoading = isAuthenticated ? !role : false;

  useCellDeepLinking(cellIds);
  useFocusHighlight();
  useRevisionTitle();
  useUndoRedoKeyboardHandlers(readOnly);
  useSubscribeToWorkspace();

  const containerRef = useRef<HTMLElement>();
  const { onBeforeInput, onBlur, onFocus, onKeyDown, onKeyUp } =
    useNotebookEventHandlers(containerRef, readOnly);

  const registerBeforeInputHandler = useBeforeInputHandler(
    containerRef,
    onBeforeInput,
  );

  const { expanded, height } = useSelector(selectConsole);

  if (loading || isRoleLoading) {
    return null;
  }

  if (error) {
    return (
      <NotebookError
        error={error instanceof Error ? error : new Error(error)}
      />
    );
  }

  const notebook = (
    <NotebookContainer
      data-function="notebook-container"
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      ref={registerBeforeInputHandler}
      tabIndex={0}
      $consoleHeight={CONSOLE_COLLAPSED_HEIGHT + (expanded ? height : 0)}
    >
      <DataSourcesLoader />
      <TitleCell readOnly={readOnly} />
      <NotebookCreatedAtIndicator />
      <GlobalTimeSelectorCell readOnly={readOnly} />
      <LabelsCell readOnly={readOnly} />
      <FrontMatterCells readOnly={readOnly} />

      {/* We don't have a global loading indicator yet, hence no fallback */}
      {hasTocEnabled && (
        <Suspense fallback={null}>
          <TableOfContents />
        </Suspense>
      )}

      <CellGridContainer>
        <StyledHorizontalRule />
      </CellGridContainer>

      {cellIds.map((id) => (
        <Cell key={id} id={id} readOnly={readOnly} />
      ))}

      {!readOnly && <EmptyCellArea />}
    </NotebookContainer>
  );

  if (unrecoverable) {
    return (
      <>
        <UnrecoverableErrorBanner />
        {notebook}
      </>
    );
  }

  if (readOnly) {
    return (
      <>
        <ReadOnlyBanner />
        {notebook}
      </>
    );
  }

  const dndNotebook = (
    <DragAndDropContainer>
      <StyledFileDropContainer>{notebook}</StyledFileDropContainer>
    </DragAndDropContainer>
  );

  if (!isCopilotEnabled) {
    return dndNotebook;
  }

  return (
    <CopilotContainer isOpen={isSideMenuOpen} width={storedCopilotWidth}>
      {dndNotebook}
      <CopilotSidebar isSideMenuOpen={isSideMenuOpen} />
    </CopilotContainer>
  );
});

const selectNotebookState = createSelector([selectActiveNotebook], (notebook) =>
  pick(notebook, "cellIds", "error", "loading", "readOnly", "unrecoverable"),
);

// Because React does not deal with the "beforeinput" event correctly, this
// handler installs the callback for that event directly. We cannot use
// `useRef()` for tracking the container ref, because we wouldn't know when it
// becomes available. Using `useState()` would trigger another rerender on this
// component that is already performance-heavy, so we use a custom registration
// handler instead...
function useBeforeInputHandler(
  containerRef: React.MutableRefObject<HTMLElement | undefined>,
  onBeforeInput?: (event: InputEvent) => void,
) {
  // Guarantee a stable identity for the handler:
  const beforeInputHandler = useHandler((event: InputEvent) => {
    if (onBeforeInput) {
      onBeforeInput(event);
    }
  });

  return useHandler((container: HTMLDivElement | null) => {
    if (container && container !== containerRef.current) {
      container.addEventListener("beforeinput", beforeInputHandler);
      containerRef.current = container;
    } else {
      // We don't care about removing the event handler, because it gets
      // implicitly unregistered when the container unmounts anyway.
    }
  });
}

const NotebookContainer = styled.div<{ $consoleHeight: number }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  /* TODO (Oscar): subject to change, we need to find our sweet spot */
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 16px;
  outline: none;
  width: 100%;
  min-height: calc(100vh - ${({ $consoleHeight }) => 68 + $consoleHeight}px);

  @media (min-width: 1580px) {
    contain: content;
  }
`;

const StyledFileDropContainer = styled(FileDropContainer)`
  display: flex;
  flex: 1;
`;

const StyledHorizontalRule = styled(HorizontalRule)`
  grid-area: main;
  margin: 40px 0 32px;
  width: 100%;
`;
