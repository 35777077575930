import { css, styled } from "styled-components";

import { convertShortcutKey } from "../../../utils";

type TextTooltipProps = {
  shortcut?: string;
  text: string;
};

export function TextTooltip({ text, shortcut }: TextTooltipProps) {
  const keys = shortcut?.split("+");

  return (
    <>
      {text}
      {keys && (
        <StyledShortcutKeys>
          {keys.map((keyValue, index) => (
            <Key key={index}>{convertShortcutKey(keyValue.trim())}</Key>
          ))}
        </StyledShortcutKeys>
      )}
    </>
  );
}

const StyledShortcutKeys = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 0;
`;

const Key = styled.div(
  ({ theme }) => css`
    display: grid;
    place-items: center;
    border: 1px solid ${theme.color.border.default};
    border-radius: ${theme.radius.lowest};
    font: ${theme.font.body.sm.medium};
    color: ${theme.color.fg.onemphasis.subtle};
    line-height: 1;
    height: 20px;
    padding: 0 4px;
  `,
);
