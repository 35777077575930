import { PageContainer, PageContent, PageHeader, PageTitle } from "../UI";

export function WorkspaceError() {
  return (
    <PageContainer data-testid="workspaces-error-page">
      <PageHeader>
        <PageTitle>This workspace does not exist</PageTitle>
      </PageHeader>
      <PageContent>
        <p>Please verify the URL for typo&apos;s.</p>
      </PageContent>
    </PageContainer>
  );
}
