import type {
  NewWebhook,
  UpdateWebhook,
  Webhook,
  WebhookDelivery,
  WebhookDeliverySummary,
} from "../types";
import { baseApi } from "./base";

export const webhooksApi = baseApi
  .injectEndpoints({
    endpoints: (builder) => ({
      createWebhook: builder.mutation<
        Webhook,
        { newWebhook: NewWebhook; workspaceId: string }
      >({
        query: ({ newWebhook, workspaceId }) => ({
          url: `/workspaces/${workspaceId}/webhooks`,
          method: "POST",
          body: newWebhook,
        }),
      }),
      deleteWebhook: builder.mutation<
        null,
        { webhookId: string; workspaceId: string }
      >({
        query: ({ webhookId, workspaceId }) => ({
          url: `/workspaces/${workspaceId}/webhooks/${webhookId}`,
          method: "DELETE",
        }),
      }),
      getWebhookDelivery: builder.query<
        WebhookDelivery,
        { deliveryId: string; webhookId: string; workspaceId: string }
      >({
        query: ({ deliveryId, webhookId, workspaceId }) =>
          `/workspaces/${workspaceId}/webhooks/${webhookId}/deliveries/${deliveryId}`,
      }),
      listDeliveries: builder.query<
        Array<WebhookDeliverySummary>,
        { webhookId: string; workspaceId: string }
      >({
        query: ({ webhookId, workspaceId }) =>
          `/workspaces/${workspaceId}/webhooks/${webhookId}/deliveries`,
      }),
      listWebhooks: builder.query<Array<Webhook>, { workspaceId: string }>({
        query: ({ workspaceId }) => `/workspaces/${workspaceId}/webhooks`,
      }),
      updateWebhook: builder.mutation<
        Webhook,
        { updateWebhook: UpdateWebhook; webhookId: string; workspaceId: string }
      >({
        query: ({ updateWebhook, webhookId, workspaceId }) => ({
          url: `/workspaces/${workspaceId}/webhooks/${webhookId}`,
          method: "PATCH",
          body: updateWebhook,
        }),
      }),
    }),
    overrideExisting: false,
  })
  .enhanceEndpoints({
    addTagTypes: ["Webhook", "Delivery"],
    endpoints: {
      createWebhook: {
        invalidatesTags: [{ type: "Webhook", id: "LIST" }],
      },
      deleteWebhook: {
        invalidatesTags: [
          { type: "Webhook", id: "LIST" },
          { type: "Delivery", id: "LIST" },
        ],
      },
      listDeliveries: {
        providesTags: [{ type: "Delivery", id: "LIST" }],
      },
      listWebhooks: {
        providesTags: [{ type: "Webhook", id: "LIST" }],
      },
      updateWebhook: {
        invalidatesTags: [{ type: "Webhook", id: "LIST" }],
      },
    },
  });

export const {
  useCreateWebhookMutation,
  useDeleteWebhookMutation,
  useGetWebhookDeliveryQuery,
  useListDeliveriesQuery,
  useListWebhooksQuery,
  useUpdateWebhookMutation,
} = webhooksApi;
