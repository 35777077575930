import { isMac } from ".";

export function convertShortcutKey(value: string): string {
  switch (value.toLowerCase()) {
    case "ctrl":
    case "⌘":
    case "cmd": {
      if (isMac) {
        return "⌘";
      }

      return "Ctrl";
    }
    case "alt":
    case "option":
    case "⎇":
    case "⌥": {
      if (isMac) {
        return "⌥";
      }

      return "Alt";
    }
  }

  return value;
}
