import { Button } from "@fiberplane/ui";
import { css, styled } from "styled-components";

export const SettingsPageActionButton = styled(Button).attrs({
  buttonStyle: "tertiary-color",
})`
  position: absolute;
  /* Offset own padding */
  top: -6px;
  right: -2px;
`;

export const SettingsPageErrorMessage = styled.div(
  ({ theme }) => css`
    margin: auto;
    background-color: ${theme.colorDanger100};
    color: ${theme.colorDanger700};
    border-radius: ${theme.borderRadius600};
    padding: 4px 8px;
  `,
);
