import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { useHandler } from "@fiberplane/hooks";
import { Icon } from "@fiberplane/ui";
import { FRONT_MATTER_CELL_ID } from "../../../../../constants";
import { makeIsFocusedFieldSelector } from "../../../../../selectors";
import { Sentry } from "../../../../../services";
import type { FrontMatterNumberValue } from "../../../../../types";
import type { FilterMenuItem } from "../../../../UI";
import { useFrontMatterValue } from "../../hooks";
import { isNumber } from "../../util";
import { OptionSelectorSingle } from "../shared";

type Props = Readonly<{
  id: string;
  options: Array<FrontMatterNumberValue>;
  onChange: (
    value: number | undefined,
    options?: { newValue?: FrontMatterNumberValue },
  ) => void;
  readOnly?: boolean;
  suffix?: string;
  prefix?: string;
  allowExtraValues?: boolean;
}>;

export function FieldNumberList({
  id,
  options,
  onChange,
  readOnly,
  suffix,
  prefix,
  allowExtraValues = false,
}: Props) {
  const stringOptions = useMemo(
    () => options.map((option) => option.toString()),
    [options],
  );

  const value = useFrontMatterValue(id, isNumber);
  const stringValue = useMemo(() => value?.toString(), [value]);

  const selectFocus = useMemo(
    () => makeIsFocusedFieldSelector(FRONT_MATTER_CELL_ID, id),
    [id],
  );
  const focused = useSelector(selectFocus);

  const onChangeValue = useCallback(
    (newValue: string | undefined) => {
      if (newValue === undefined) {
        onChange(undefined);
        return;
      }

      const numberValue = Number(newValue);
      if (Number.isNaN(numberValue)) {
        Sentry.captureError(
          `Could not convert ${newValue} to number in FieldNumberList`,
        );
        return;
      }

      onChange(numberValue);
    },
    [onChange],
  );

  const onAddAndActivate = useHandler((value: number) => {
    onChange(value, { newValue: value });
  });

  const createExtraMenuItem = useCallback(
    (options: { filterText: string; exactMatchFound: boolean }):
      | FilterMenuItem
      | undefined => {
      if (options.exactMatchFound || options.filterText.trim() === "") {
        return;
      }

      const numberValue = Number(options.filterText);
      if (Number.isNaN(numberValue)) {
        return;
      }

      return {
        type: "item_with_icons",
        id: "add-result",
        title: `"${options.filterText}"`,
        renderTitle: () => (
          <Container>
            <span>Add new option:</span>
            <em>{numberValue}</em>
          </Container>
        ),
        onActivate: () => {
          onAddAndActivate(numberValue);
        },
        iconRight: {
          icon: <Icon iconType="key_return" />,
          showOnlyOnSelection: true,
        },
      };
    },
    [onAddAndActivate],
  );

  return (
    <Container>
      {prefix && <span>{prefix}</span>}
      <OptionSelectorSingle
        focused={focused}
        readOnly={readOnly === true}
        currentValue={stringValue}
        options={stringOptions}
        onChange={onChangeValue}
        createExtraMenuItem={allowExtraValues ? createExtraMenuItem : undefined}
      />
      {suffix && <span>{suffix}</span>}
    </Container>
  );
}

const Container = styled.div`
  grid-area: value;
  display: flex;
  align-items: center;
  gap: 8px;
`;
