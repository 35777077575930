import { memo } from "react";

import { useHandler } from "@fiberplane/hooks";
import { dispatch } from "../../../../store";
import {
  addAndSelectSchemaOption,
  deleteFrontMatterKey,
  updateFrontMatterValue,
} from "../../../../thunks";
import type {
  FrontMatterNumberValue,
  FrontMatterStringValue,
  FrontMatterValueSchema,
  NotebookFocus,
} from "../../../../types";
import {
  FieldDateTime,
  FieldGitHubPullRequest,
  FieldNumber,
  FieldNumberList,
  FieldPagerDutyIncident,
  FieldString,
  FieldStringList,
  FieldStringListMultiple,
  FieldUser,
  FieldUserMultiple,
  FrontMatterFieldWithLabel,
} from "../Field";
import { getSchemaIconType } from "../util";

type Props = {
  schema: FrontMatterValueSchema;
  fieldKey: string;
  readOnly?: boolean;
};

export const FrontMatterField = memo((props: Props) => {
  const { fieldKey, schema, readOnly = false } = props;
  const onDelete = useHandler(() => handleDelete(fieldKey));
  const onChange = useHandler(
    (
      value,
      options?: {
        focus?: NotebookFocus;
        newValue: FrontMatterStringValue | FrontMatterNumberValue;
      },
    ) => {
      if (options?.newValue) {
        dispatch(addAndSelectSchemaOption(fieldKey, options.newValue));
      } else {
        dispatch(updateFrontMatterValue(fieldKey, value, options?.focus));
      }
    },
  );

  const result = getFieldComponentAndType({
    fieldKey,
    schema,
    readOnly,
    onChange,
  });

  return (
    <FrontMatterFieldWithLabel
      field={fieldKey}
      iconType={getSchemaIconType(schema)}
      name={schema.displayName}
      onDelete={onDelete}
      readOnly={readOnly}
      cellType={result.type}
    >
      {result.content}
    </FrontMatterFieldWithLabel>
  );
});

const handleDelete = (fieldKey: string) => {
  dispatch(deleteFrontMatterKey(fieldKey));
};

function getFieldComponentAndType(
  props: Pick<Props, "fieldKey" | "schema" | "readOnly"> & {
    onChange: (value: unknown) => void;
  },
): { content: JSX.Element; type: string } {
  const { schema, fieldKey, readOnly, onChange } = props;
  const schemaType = schema.type;
  switch (schemaType) {
    case "string": {
      if (schema.options) {
        if (schema.multiple) {
          return {
            content: (
              <FieldStringListMultiple
                id={fieldKey}
                options={schema.options}
                readOnly={readOnly}
                onChange={onChange}
                allowExtraValues={schema.allowExtraValues}
              />
            ),
            type: "front-matter-user-multiple",
          };
        }

        return {
          content: (
            <FieldStringList
              id={fieldKey}
              options={schema.options}
              onChange={onChange}
              readOnly={readOnly}
              allowExtraValues={schema.allowExtraValues}
            />
          ),
          type: "front-matter-string-list",
        };
      }

      return {
        content: (
          <FieldString id={fieldKey} onChange={onChange} readOnly={readOnly} />
        ),
        type: "front-matter-string",
      };
    }
    case "number": {
      if (schema.options) {
        return {
          content: (
            <FieldNumberList
              id={fieldKey}
              options={schema.options}
              onChange={onChange}
              readOnly={readOnly}
              prefix={schema.prefix}
              suffix={schema.suffix}
              allowExtraValues={schema.allowExtraValues}
            />
          ),
          type: "front-matter-number-list",
        };
      }

      return {
        content: (
          <FieldNumber
            id={fieldKey}
            onChange={onChange}
            readOnly={readOnly}
            prefix={schema.prefix}
            suffix={schema.suffix}
          />
        ),
        type: "front-matter-number",
      };
    }
    case "user": {
      if (schema.multiple) {
        return {
          content: (
            <FieldUserMultiple
              id={fieldKey}
              name={schema.displayName}
              onChange={onChange}
              readOnly={readOnly}
            />
          ),
          type: "front-matter-user-multiple",
        };
      }

      return {
        content: (
          <FieldUser id={fieldKey} onChange={onChange} readOnly={readOnly} />
        ),
        type: "front-matter-user",
      };
    }

    case "date_time":
      return {
        content: (
          <FieldDateTime
            id={fieldKey}
            name={schema.displayName}
            onChange={onChange}
          />
        ),
        type: "front-matter-date-time",
      };

    case "pagerduty_incident":
      return {
        content: <FieldPagerDutyIncident id={fieldKey} />,
        type: "front-matter-pager-duty",
      };

    case "github_pull_request":
      return {
        content: <FieldGitHubPullRequest id={fieldKey} />,
        type: "front-matter-git-hub",
      };

    default: {
      const exhaustiveCheck: never = schemaType;
      throw new Error(`Unhandled schema type: ${exhaustiveCheck}`);
    }
  }
}
