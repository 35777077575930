import type {
  Cell,
  Label,
  NewNotebook,
  Notebook,
  NotebookSearch,
  NotebookSortFields,
  NotebookSummary,
  RelativeTime,
  SortDirection,
  UpdateNotebook,
} from "../../types";
import { baseApi } from "../base";
import { binaryResponseHandler, withQueryArgs } from "../utils";

export const notebooksApi = baseApi
  .injectEndpoints({
    endpoints: (builder) => ({
      appendCellToNotebook: builder.mutation<
        void,
        { notebookId: string; cells: Array<Cell> }
      >({
        query: ({ notebookId, cells }) => ({
          url: `/api/notebooks/${encodeURIComponent(notebookId)}/cells`,
          method: "POST",
          body: cells,
        }),
      }),
      createNotebook: builder.mutation<
        Uint8Array,
        { workspaceId: string; newNotebook: NewNotebook }
      >({
        query: ({ workspaceId, newNotebook }) => ({
          url: `/workspaces/${workspaceId}/notebooks`,
          method: "POST",
          body: newNotebook,
          responseHandler: binaryResponseHandler,
        }),
      }),
      deleteNotebook: builder.mutation<void, { notebookId: string }>({
        query: ({ notebookId }) => ({
          url: `/notebooks/${notebookId}`,
          method: "DELETE",
        }),
      }),
      duplicateNotebook: builder.mutation<
        void,
        { workspaceId: string; notebookId: string; title: string }
      >({
        query: ({ workspaceId, notebookId, title }) => ({
          url: `/notebooks/${notebookId}/duplicate`,
          method: "POST",
          body: {
            workspaceId,
            title,
          },
        }),
      }),
      forkNotebook: builder.mutation<
        Notebook,
        { workspaceId: string; newNotebook: NewNotebook }
      >({
        query: ({ workspaceId, newNotebook }) => ({
          url: `/workspaces/${workspaceId}/notebooks`,
          method: "POST",
          body: newNotebook,
        }),
      }),
      getNotebook: builder.query<Uint8Array, { notebookId: string }>({
        query: ({ notebookId }) => ({
          url: `/notebooks/${notebookId}`,
          responseHandler: binaryResponseHandler,
        }),
      }),
      listNotebooks: builder.query<
        Array<NotebookSummary>,
        { workspaceId: string }
      >({
        query: ({ workspaceId }) => `/workspaces/${workspaceId}/notebooks`,
      }),
      listPinnedNotebooks: builder.query<
        Array<NotebookSummary>,
        { workspaceId: string }
      >({
        query: ({ workspaceId }) =>
          `/workspaces/${workspaceId}/pinnednotebooks`,
      }),
      patchNotebook: builder.mutation<
        void,
        { notebookId: string; updateNotebook: UpdateNotebook }
      >({
        query: ({ notebookId, updateNotebook }) => ({
          url: `/notebooks/${notebookId}`,
          method: "PATCH",
          body: updateNotebook,
        }),
      }),
      pinNotebook: builder.mutation<void, { notebookId: string }>({
        query: ({ notebookId }) => ({
          url: "/pinnednotebooks",
          method: "POST",
          body: { notebookId },
        }),
      }),
      searchNotebooks: builder.query<
        Array<NotebookSummary>,
        {
          labels?: ReadonlyArray<Label>;
          relativeTime?: RelativeTime;
          sortBy?: NotebookSortFields;
          sortDirection?: SortDirection;
          viewName?: string;
          workspaceId: string;
        }
      >({
        query: ({
          labels,
          relativeTime,
          sortBy,
          sortDirection,
          viewName,
          workspaceId,
        }) => {
          const body: NotebookSearch = {
            labels: labels
              ? Object.fromEntries(labels.map(({ key, value }) => [key, value]))
              : undefined,
            relativeTime,
            view: viewName,
          };
          return {
            url: withQueryArgs(`/workspaces/${workspaceId}/search/notebooks`, {
              // biome-ignore lint/style/useNamingConvention: API field name is correct
              sort_by: sortBy,
              // biome-ignore lint/style/useNamingConvention: API field name is correct
              sort_direction: sortDirection,
            }),
            method: "POST",
            body,
          };
        },
      }),
      unpinNotebook: builder.mutation<void, { notebookId: string }>({
        query: ({ notebookId }) => ({
          url: `/pinnednotebooks/${notebookId}`,
          method: "DELETE",
        }),
      }),
      addGithubPullRequestToNotebook: builder.mutation<
        void,
        {
          notebookId: string;
          url: string;
        }
      >({
        query: ({ notebookId, url }) => ({
          url: `/notebooks/${notebookId}/integrations/github/pull_request`,
          method: "POST",
          body: { url },
        }),
        // }),
        // } = (
        //   { signal,
        //     notebookId,
        //     url,
        //   }: {
        //     signal?: AbortSignal;
        //     notebookId: string;
        //     url: string;
        //   }
        // ) => postJsonRequest(
        //   `/api/notebooks/${notebookId}/integrations/github/pull_request`,
        //   { url },
        //   parseJsonResponse,
        //   { signal },
        // )
      }),
    }),
    overrideExisting: false,
  })
  .enhanceEndpoints({
    addTagTypes: [
      "Notebook",
      "Notebooks",
      "PinnedNotebooks",
      "SearchedNotebooks",
    ],
    endpoints: {
      createNotebook: {
        invalidatesTags: [
          { id: "LIST", type: "Notebooks" },
          { id: "LIST", type: "PinnedNotebooks" },
        ],
      },
      deleteNotebook: {
        invalidatesTags: [
          { id: "LIST", type: "Notebooks" },
          { id: "LIST", type: "PinnedNotebooks" },
          { id: "LIST", type: "SearchedNotebooks" },
        ],
      },
      duplicateNotebook: {
        invalidatesTags: [{ id: "LIST", type: "Notebooks" }],
      },
      forkNotebook: {
        invalidatesTags: [
          { id: "LIST", type: "Notebooks" },
          { id: "LIST", type: "PinnedNotebooks" },
        ],
      },
      getNotebook: {
        providesTags: (_result, _error, { notebookId }) => [
          { id: notebookId, type: "Notebook" },
        ],
      },
      listNotebooks: {
        providesTags: [{ id: "LIST", type: "Notebooks" }],
      },
      listPinnedNotebooks: {
        providesTags: [{ id: "LIST", type: "PinnedNotebooks" }],
      },
      patchNotebook: {
        invalidatesTags: [
          { id: "LIST", type: "Notebooks" },
          { id: "LIST", type: "PinnedNotebooks" },
        ],
      },
      pinNotebook: {
        invalidatesTags: [{ id: "LIST", type: "PinnedNotebooks" }],
      },
      searchNotebooks: {
        providesTags: [{ id: "LIST", type: "SearchedNotebooks" }],
      },
      unpinNotebook: {
        invalidatesTags: [{ id: "LIST", type: "PinnedNotebooks" }],
      },
    },
  });

export const {
  useDeleteNotebookMutation,
  useDuplicateNotebookMutation,
  useListNotebooksQuery,
  useListPinnedNotebooksQuery,
  usePinNotebookMutation,
  useSearchNotebooksQuery,
  useUnpinNotebookMutation,
  useAddGithubPullRequestToNotebookMutation,
} = notebooksApi;
