import type { ReactNode } from "react";
import type {
  ControllerFieldState,
  FieldPath,
  FieldValues,
} from "react-hook-form";
import {
  FieldContainer,
  FieldErrorMessage,
  FieldErrorMessageIcon,
  FieldLabel,
} from "../styled";

/**
 * A component for wrapping and rendering a form field using Zod schema validation.
 * @param {Object} props - Props for the FiberField component.
 * @param {ReactNode} props.children - The child components.
 * @param {ControllerFieldState} props.fieldState - The field state object.
 * @param {string} [props.label] - The label for the form field.
 * @param {FieldName} props.name - The name of the form field.
 * @returns {JSX.Element} The FiberField component.
 * @template Values - The type of form field values.
 * @template FieldName - The type of field name.
 */
export function FiberFormFieldContainer<
  Values extends FieldValues,
  FieldName extends FieldPath<Values>,
>({
  children,
  fieldState,
  label,
  name,
}: {
  children: ReactNode;
  fieldState: ControllerFieldState;
  label?: string;
  name: FieldName;
}): JSX.Element {
  return (
    <FieldContainer>
      <FieldLabel htmlFor={name}>{label ?? name}</FieldLabel>
      {children}
      {fieldState.error && (
        <FieldErrorMessage>
          <FieldErrorMessageIcon iconType="warning_octagon" />
          {fieldState.error.message}
        </FieldErrorMessage>
      )}
    </FieldContainer>
  );
}
