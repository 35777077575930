import { useHandler } from "@fiberplane/hooks";
import { useEffect, useState } from "react";
import { throttle } from "throttle-debounce";

import { cancelEvent } from "@fiberplane/ui";
import {
  endActiveFormatting,
  setFocus,
  showError,
  withActiveNotebook,
} from "../../../../actions";
import { CELLS_MIME_TYPE, RICH_TEXT_MIME_TYPE } from "../../../../constants";
import {
  makeCellDraftsSelector,
  makeThreadItemsSelector,
  selectCell,
  selectCellIds,
  selectCellOrSurrogate,
  selectCellText,
  selectNotebookFocus,
} from "../../../../selectors";
import type { RootState } from "../../../../state";
import {
  dispatch,
  getState,
  useActiveNotebookDispatch,
} from "../../../../store";
import {
  focusCell,
  openProviderLink,
  selectCellFieldContent,
  updateCellText,
} from "../../../../thunks";
import type {
  ActiveFormatting,
  Cell,
  FocusPosition,
  RichText,
} from "../../../../types";
import {
  cellFocusesAreEqual,
  getCellFocus,
  getClosestAttribute,
  getEndPosition,
  getStartPosition,
  isMac,
  toNotebookFocus,
} from "../../../../utils";
import { replaceSelection, replaceSelectionWithCells } from "../../thunks";
import type { RichTextInputProps } from "../RichTextInput";
import { ZERO_WIDTH_SPACE } from "../constants";
import {
  copySelection,
  cutSelection,
  getCellFocusForRange,
  getContainerElForCellField,
  getOffsetForCoordinates,
} from "../utils";
import { useLinkEditor } from "./useLinkEditor";

export const useRichTextEventHandlers = (
  {
    cellId,
    field,
    focus,
    onPaste: onPasteFn,
    disableFormatting,
    value,
  }: RichTextInputProps,
  activeFormatting: ActiveFormatting,
  containerEl: HTMLElement | null,
  contentRef: React.RefObject<HTMLElement>,
) => {
  const dispatch = useActiveNotebookDispatch();

  // The "selectionchange" listener is bound to a specific focus position.
  // Once we receive a "selectionchange" event for a different focus position,
  // we unbind the listener.
  const [selectionListenerPosition, setSelectionListenerPosition] = useState<
    number | null
  >(null);

  const onUrlEditorRequested = useLinkEditor(value, focus, activeFormatting);

  const onClick = (event: React.MouseEvent) => {
    // Most clicks are suppressed by calling `preventDefault()` inside
    // `onMouseDown()`, but for those that get through, we may need to
    // check which ones we still want:
    if (event.target instanceof Element && event.target.nodeName === "A") {
      const href = event.target.getAttribute("href");
      const target = event.target.getAttribute("target");
      if (href && target === "_blank") {
        // We need to explicitly call `window.open()` because the browser
        // doesn't open links in contenteditable elements by default.
        window.open(href, target);
      }

      if (href?.startsWith("provider:")) {
        dispatch(openProviderLink(href, { cellId }));
      }
    }

    cancelEvent(event);
  };

  const onCut = (event: React.ClipboardEvent) => {
    cancelEvent(event);
    cutSelection(event);
  };

  const onCopy = (event: React.ClipboardEvent) => {
    cancelEvent(event);
    copySelection(event);
  };

  const onInput = (event: React.SyntheticEvent) => {
    switch ((event.nativeEvent as InputEvent).inputType) {
      case "insertLineBreak":
      case "insertReplacementText":
        // We don't want to replace text here, since the same event will be dealt with
        // in the 'onBeforeInput' of the notebook event handlers. Otherwise we will try
        // replacing the same text twice, and then the second time around retrieving the
        // 'old text' from the cell will actually return the _new_ text, breaking things
        // in the process.
        break;

      default: {
        const textContent = contentRef.current?.textContent?.replaceAll(
          ZERO_WIDTH_SPACE,
          "",
        );

        if (textContent != null && textContent !== value) {
          dispatch(updateCellText(cellId, textContent));
        }
      }
    }
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (
      focus.type === "selection" &&
      disableFormatting !== true &&
      (isMac ? event.metaKey : event.ctrlKey) &&
      event.key.toLowerCase() === "k"
    ) {
      onUrlEditorRequested(event);
    } else if (event.key === "Escape") {
      dispatch(endActiveFormatting());
    }
  };

  const onMouseDown = (event: React.MouseEvent) => {
    const state = getState();
    const cell = selectCellOrSurrogate(state, cellId);

    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
    if (!cell || !containerEl) {
      return;
    }

    const focusOffset = getOffsetForEvent(
      state,
      cell,
      field,
      containerEl,
      event,
    );

    if (event.button === 2) {
      // Right-click typically opens the context menu, which allows the
      // user to select spelling suggestions or use Select All. In the case
      // of Chrome, the browser also selects the word that is being
      // right-clicked.
      // As these selection changes happen outside our control, we install
      // a temporary "selectionchange" listener that gets unregistered on
      // the first selection change for a different position. This seems to
      // work reliably for the spelling-checker, though Select All still has
      // issues sometimes in Firefox :(
      setSelectionListenerPosition(focusOffset);
      return;
    }

    if (event.button !== 0) {
      return; // We only care about primary (left) clicks from here on...
    }

    if (event.target instanceof Element && event.target.nodeName === "A") {
      return; // Clicks on links are handled in `onClick()`.
    }

    // Don't completely stop event propagation, since any popups want to be
    // notified about this event as well.
    event.preventDefault();

    // See: https://developer.mozilla.org/en-US/docs/Web/API/UIEvent/detail
    const isDoubleClick = event.detail === 2;
    const isTripleClick = event.detail === 3;

    dispatch(
      isTripleClick
        ? selectCellFieldContent(cell, field)
        : focusCell({
            cellId,
            field,
            offset: focusOffset,
            extendSelection: event.shiftKey,
            selectionUnit: isDoubleClick ? "word" : "grapheme_cluster",
          }),
    );

    installMouseListeners();
  };

  const onPaste = (event: React.ClipboardEvent) => {
    const focus = selectNotebookFocus(getState());
    if (focus.type === "none") {
      return;
    }

    const cellsJson = event.clipboardData.getData(`${CELLS_MIME_TYPE}+json`);
    if (cellsJson) {
      try {
        const cells: Array<Cell> = JSON.parse(cellsJson);
        dispatch(replaceSelectionWithCells(focus, cells));
        cancelEvent(event);
        return;
      } catch (error) {
        dispatch(
          showError({
            type: "other",
            message: `Cannot parse cells: ${error}`,
          }),
        );
      }
    }

    const cellRichText = event.clipboardData.getData(
      `${RICH_TEXT_MIME_TYPE}+json`,
    );
    if (cellRichText) {
      try {
        const { text, formatting }: RichText = JSON.parse(cellRichText);
        dispatch(replaceSelection(focus, text, formatting));
        cancelEvent(event);
        return;
      } catch (error) {
        dispatch(
          showError({
            type: "other",
            message: `Cannot parse rich text: ${error}`,
          }),
        );
      }
    }

    const text = event.clipboardData.getData("text");
    if (text) {
      dispatch(replaceSelection(focus, text));
      cancelEvent(event);
      return;
    }

    onPasteFn?.(event);
  };

  const onSelectionChange = useHandler(() => {
    const selection = window.getSelection();
    const range = selection?.getRangeAt(0);
    if (range && containerEl?.contains(range.commonAncestorContainer)) {
      const newFocus = getCellFocusForRange(containerEl, range);
      if (
        newFocus.type !== "collapsed" ||
        newFocus.offset !== selectionListenerPosition
      ) {
        setSelectionListenerPosition(null);
      }

      const state = getState();
      const cell = selectCell(state, cellId);
      if (!cell) {
        return;
      }

      const cellIds = selectCellIds(state);
      const notebookFocus = selectNotebookFocus(state);
      const drafts = makeCellDraftsSelector(cellId)(state);
      const threadItems = makeThreadItemsSelector(cellId)(state);
      const oldFocus = getCellFocus(
        cellIds,
        cell,
        drafts,
        threadItems,
        notebookFocus,
      );
      if (!cellFocusesAreEqual(newFocus, oldFocus)) {
        dispatch(setFocus(toNotebookFocus(newFocus, cellId)));
      }
    }
  });

  useEffect(() => {
    if (selectionListenerPosition) {
      document.addEventListener("selectionchange", onSelectionChange);
      return () => {
        document.removeEventListener("selectionchange", onSelectionChange);
      };
    }
  }, [onSelectionChange, selectionListenerPosition]);

  return {
    onClick,
    onCut,
    onCopy,
    onDoubleClick: cancelEvent,
    onInput,
    onKeyDown,
    onMouseDown,
    onPaste,
    onUrlEditorRequested,
  };
};

function getOffsetForEvent(
  state: RootState,
  cell: Cell,
  field: string | undefined,
  containerEl: HTMLElement,
  event: MouseEvent | React.MouseEvent,
): number {
  const containerRect = containerEl.getBoundingClientRect();
  const coordinates = {
    x: event.clientX - containerRect.left,
    y: event.clientY - containerRect.top,
  };
  const text = selectCellText(state, cell, field);
  return getOffsetForCoordinates(containerEl, text, coordinates);
}

/**
 * Installs mouse listeners that keep extending the selection until the primary
 * mouse button is released.
 */
function installMouseListeners() {
  // FP-1419: If the user double-clicked to make an initial selection, the
  //          entire selection should serve as the anchor, rather than only a
  //          single focus position. This way, when the selection is extended,
  //          we can maintain this entire "anchor focus".
  const state = getState();
  const cellIds = selectCellIds(state);
  const anchorFocus = selectNotebookFocus(state);
  const anchorStartPosition = getStartPosition(cellIds, anchorFocus);
  const anchorEndPosition = getEndPosition(cellIds, anchorFocus);
  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
  if (!anchorStartPosition || !anchorEndPosition) {
    return;
  }

  const throttledMouseMove = throttle(33, (event: MouseEvent) =>
    onMouseMove(
      event,
      anchorStartPosition,
      anchorEndPosition,
      uninstallListeners,
    ),
  );

  const onMouseUp = (event: MouseEvent) => {
    if (event.button === 0) {
      uninstallListeners();
    }
  };

  function uninstallListeners() {
    window.removeEventListener("mousemove", throttledMouseMove);
    window.removeEventListener("mouseup", onMouseUp);
  }

  window.addEventListener("mousemove", throttledMouseMove);
  window.addEventListener("mouseup", onMouseUp);
}

function onMouseMove(
  event: MouseEvent,
  anchorStartPosition: FocusPosition,
  anchorEndPosition: FocusPosition,
  uninstallListeners: () => void,
) {
  cancelEvent(event);

  if ((event.buttons & 1) === 0) {
    // User let go of the button.
    uninstallListeners();
    return;
  }

  const cellId = getClosestAttribute(event.target as Node, "data-cell-id");
  if (!cellId) {
    return; // We're not hovering over a cell.
  }

  const state = getState();
  const cell = selectCellOrSurrogate(state, cellId);
  if (!cell) {
    // This ain't right.
    uninstallListeners();
    return;
  }

  // Note: We only care about the initial `field` value. This is because if the
  // user started dragging in a field, we may not extend the selection outside
  // of it. And if the user didn't start dragging in a field, but is hovering
  // over a field later, we may still extend the selection, but must ignore the
  // field so it doesn't become a part of it.
  const {
    cellId: anchorStartCellId,
    field,
    offset: anchorStartOffset = 0,
  } = anchorStartPosition;
  const { cellId: anchorEndCellId, offset: anchorEndOffset = 0 } =
    anchorEndPosition;

  const containerEl = getContainerElForCellField(cellId, field);
  const offset = containerEl
    ? getOffsetForEvent(state, cell, field, containerEl, event)
    : undefined;

  const cellIds = selectCellIds(state);
  const newCellIndex = cellIds.indexOf(cellId);

  const startCellIndex = cellIds.indexOf(anchorStartCellId);
  const extendsBeforeAnchor =
    newCellIndex < startCellIndex ||
    (cellId === anchorStartCellId && (!offset || offset < anchorStartOffset));
  if (extendsBeforeAnchor) {
    dispatch(
      withActiveNotebook(
        setFocus({
          type: "selection",
          anchor: { cellId: anchorEndCellId, field, offset: anchorEndOffset },
          focus: { cellId, field, offset },
        }),
      ),
    );
    return;
  }

  const endCellIndex = cellIds.indexOf(anchorEndCellId);
  const extendsAfterAnchor =
    newCellIndex > endCellIndex ||
    (cellId === anchorEndCellId && (!offset || offset > anchorEndOffset));
  if (extendsAfterAnchor) {
    dispatch(
      withActiveNotebook(
        setFocus({
          type: "selection",
          anchor: {
            cellId: anchorStartCellId,
            field,
            offset: anchorStartOffset,
          },
          focus: { cellId, field, offset },
        }),
      ),
    );
  }
}
