import { Input } from "@fiberplane/ui";
import { useCallback } from "react";
import { css, styled } from "styled-components";

import { useQueryFieldValidationError } from "./QueryError";

type Props = {
  cellId: string;
  readOnly?: boolean;
  isLive: boolean;
  setLive: (live: boolean) => void;
};

export function LiveButton({ readOnly, isLive, setLive, cellId }: Props) {
  const toggleLive = useCallback(() => {
    if (!readOnly) {
      setLive(!isLive);
    }
  }, [isLive, readOnly, setLive]);

  const { ref, errorOutput } = useQueryFieldValidationError<HTMLDivElement>(
    cellId,
    "live",
  );

  return (
    <>
      <div ref={ref}>
        <BoltSwitch $isDisabled={readOnly} $isChecked={isLive}>
          Live
          <Input
            type="lightswitch"
            disabled={readOnly}
            checked={isLive}
            onChange={toggleLive}
            aria-label={isLive ? "Live" : "Not live"}
            data-testid="live"
          />
          <svg
            width="44"
            height="23"
            viewBox="0 0 44 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M36.1157 11.0406C36.1018 10.9802 36.0731 10.9241 36.0322 10.8775C35.9913 10.8309 35.9394 10.7951 35.8813 10.7734L33.1813 9.76094L33.8657 6.325C33.8814 6.24541 33.8713 6.16287 33.8367 6.08948C33.8021 6.01609 33.7449 5.95571 33.6735 5.91719C33.6014 5.87883 33.5186 5.86539 33.4381 5.87895C33.3576 5.89252 33.2838 5.93233 33.2282 5.99219L27.9782 11.6172C27.9352 11.6618 27.9041 11.7165 27.8877 11.7763C27.8713 11.8361 27.8702 11.899 27.8844 11.9594C27.8991 12.0195 27.9281 12.0752 27.9689 12.1217C28.0097 12.1682 28.0611 12.2042 28.1188 12.2266L30.8188 13.2391L30.1344 16.675C30.1187 16.7546 30.1289 16.8371 30.1635 16.9105C30.198 16.9839 30.2552 17.0443 30.3266 17.0828C30.3805 17.11 30.4398 17.1244 30.5001 17.125C30.551 17.1252 30.6014 17.1149 30.6482 17.0948C30.695 17.0746 30.7371 17.045 30.7719 17.0078L36.0219 11.3828C36.065 11.3382 36.0961 11.2835 36.1125 11.2237C36.1288 11.1639 36.13 11.101 36.1157 11.0406Z"
              fill="currentColor"
            />
          </svg>
        </BoltSwitch>
      </div>
      {errorOutput}
    </>
  );
}

const BoltSwitch = styled.label<{ $isChecked: boolean; $isDisabled?: boolean }>(
  ({ $isChecked, $isDisabled, theme }) => css`
    display: grid;
    gap: 8px;
    grid-auto-flow: column;
    position: relative;
    align-items: center;
    cursor: pointer;
    
    svg {
      position: absolute;
      right: -2px;
      top: 1px;
      margin: auto 0;
      transition: color 0.1s ease-in-out;
      color: ${theme.color.fg.muted};

      ${
        $isChecked &&
        css`
          color: ${theme.color.fg.primary};
        `
      }
    }

    ${
      !$isDisabled &&
      css`
        &:hover svg {
          color: ${theme.color.fg.primary};
        }
      `
    }
  `,
);
