import {
  ADD_BUTTON_FIELD_ID,
  FRONT_MATTER_CELL_ID,
  isSurrogateId,
} from "../constants";
import {
  getActiveQuerySchema,
  selectCellOrSurrogate,
  selectNotebookFrontMatterSchema,
  selectRelativeCellOrSurrogate,
} from "../selectors";
import type { RootState } from "../state";
import type { Cell } from "../types";
import { parseIntent } from "../utils";

export type RelativeField = {
  cellId: string;
  field: string | undefined;
};

export function selectRelativeField(
  state: RootState,
  cellId: string,
  field: string | undefined,
  delta: 1 | -1,
): RelativeField | undefined {
  const cell = selectCellOrSurrogate(state, cellId);
  const fieldNames = (cell && getFieldNamesForCell(state, cell)) || [];

  const schemaFieldIndex = field ? fieldNames.indexOf(field) : 0;
  const relativeFieldIndex = schemaFieldIndex + delta;

  if (relativeFieldIndex >= 0 && relativeFieldIndex < fieldNames.length) {
    return {
      cellId,
      field: fieldNames[relativeFieldIndex],
    };
  }

  const otherCell = selectRelativeCellOrSurrogate(state, cellId, delta);
  if (otherCell) {
    const fields = getFieldNamesForCell(state, otherCell);
    const field = delta === 1 ? fields[0] : fields[fields.length - 1];

    if (field) {
      return {
        cellId: otherCell.id,
        field,
      };
    }
  }
}

function getFieldNamesForCell(state: RootState, cell: Cell): Array<string> {
  if (cell.type === "provider") {
    return getNavigableQueryFields(state, cell);
  }

  if (isSurrogateId(cell.id)) {
    return getNavigableFieldsForSurrogateCell(state, cell);
  }

  return [];
}

function getNavigableFieldsForSurrogateCell(state: RootState, cell: Cell) {
  if (cell.id === FRONT_MATTER_CELL_ID) {
    const schema = selectNotebookFrontMatterSchema(state);
    return [...schema.map((field) => field.key), ADD_BUTTON_FIELD_ID];
  }

  return [];
}

function getNavigableQueryFields(
  state: RootState,
  cell: Cell | undefined,
): Array<string> {
  if (cell?.type !== "provider") {
    return [];
  }

  const fullSchema =
    getActiveQuerySchema(state, parseIntent(cell.intent)) || [];

  // Filter out any fields that we don't want to navigate to via keyboard
  const schema = fullSchema.filter((f) => {
    return f.name !== "live";
  });

  return schema.map((field) => field.name);
}
