import { createContext } from "react";

import { noop } from "../../../utils";

export const ModalContext = createContext<{
  modalType: string;
  requestClose: () => void;
  afterClose?: () => void;
}>({
  modalType: "",
  requestClose: noop,
  afterClose: noop,
});
