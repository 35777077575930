import { useHandler } from "@fiberplane/hooks";
import type { HTMLMotionProps, Variants } from "framer-motion";
import { motion, useAnimationControls } from "framer-motion";
import { useEffect } from "react";

type LazilyLoadedImageProps = {
  src: string;
} & HTMLMotionProps<"img">;

export function LazilyLoadedImage({
  src,
  ...attributes
}: LazilyLoadedImageProps) {
  const controls = useAnimationControls();

  const setImageIsLoaded = useHandler(() => {
    controls.start("animate");
  });

  useEffect(() => {
    const imageElement = new Image();
    imageElement.src = src;
    imageElement.addEventListener("load", setImageIsLoaded);

    return () => {
      imageElement.removeEventListener("load", setImageIsLoaded);
    };
  }, [setImageIsLoaded, src]);

  return (
    <motion.img
      {...attributes}
      src={src}
      variants={variants}
      initial="initial"
      animate={controls}
    />
  );
}

const variants: Variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};
