import { useHandler } from "@fiberplane/hooks";
import { useEffect, useRef, useState } from "react";

export function useNotebookContainerWidth() {
  const [containerWidth, setContainerWidth] = useState<number>();

  const observerCallback = useHandler((entries: Array<ResizeObserverEntry>) => {
    const firstBoxSize = entries[0]?.borderBoxSize[0];
    if (firstBoxSize) {
      setContainerWidth(firstBoxSize.inlineSize);
    }
  });

  const resizeObserver = useRef(new ResizeObserver(observerCallback));

  useEffect(() => {
    const element = document.querySelector(
      '[data-function="notebook-container"]',
    );
    const observer = resizeObserver.current;

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }

      observer.disconnect();
    };
  }, []);

  return containerWidth;
}
