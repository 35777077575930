import type { WorkspaceFrontMatterSchemas } from "../types";
import { baseApi } from "./base";

export const frontMatterSchemasApi = baseApi
  .injectEndpoints({
    endpoints: (builder) => ({
      listFrontMatterSchemas: builder.query<
        WorkspaceFrontMatterSchemas,
        {
          workspaceId: string;
        }
      >({
        query: ({ workspaceId }) =>
          `/workspaces/${workspaceId}/front_matter_schemas`,
      }),
    }),
    overrideExisting: false,
  })
  .enhanceEndpoints({
    addTagTypes: ["FrontMatterSchemas"],
    endpoints: {
      listFrontMatterSchemas: {
        providesTags: ["FrontMatterSchemas"],
      },
    },
  });

export const { useListFrontMatterSchemasQuery } = frontMatterSchemasApi;
