import { IconButton } from "@fiberplane/ui";
import { css, styled } from "styled-components";

import { useSelector } from "react-redux";
import { useOnLocationChange } from "../../../hooks";
import { selectActiveWorkspaceId } from "../../../selectors";
import { dispatch } from "../../../store";
import {
  type MainSettingsParams,
  type PersonalIntegrationSettingsParams,
  type WorkspaceIntegrationSettingsParams,
  hideSettingsModal,
  showSettingsModal,
} from "../../../thunks";
import { BaseModal } from "../../UI";
import {
  MenuFeatureListItem,
  MenuListItem,
  MenuSignOutListItem,
} from "./MenuListItem";
import { SettingsContentBlock } from "./SettingsContentBlock";
import { WorkspaceSwitch } from "./WorkspaceSwitch";
import { CATEGORIZED_SETTINGS, SETTINGS } from "./content";

export type SettingsProps =
  | MainSettingsParams
  | PersonalIntegrationSettingsParams
  | WorkspaceIntegrationSettingsParams;

export function Settings(props: SettingsProps) {
  const { section: activeSection = "profile" } = props;
  const integrationId =
    (props.section === "personalIntegrations" && props.integrationId) ||
    (props.section === "workspaceIntegrations" && props.integrationId) ||
    undefined;
  const content = SETTINGS.find(({ section }) => section === activeSection);

  useOnLocationChange(() => {
    dispatch(hideSettingsModal());
  });

  const workspaceId = useSelector(selectActiveWorkspaceId);

  if (!workspaceId) {
    return (
      <Loading>
        Waiting for a workspace id{" "}
        <CloseButton
          onClick={() => dispatch(hideSettingsModal())}
          buttonStyle="tertiary-grey"
          iconType="close"
          data-testid="settings-close-button"
        />
      </Loading>
    );
  }

  return (
    <SettingsModal data-testid="settings-modal">
      <SettingsPageContainer>
        <CloseButton
          onClick={() => dispatch(hideSettingsModal())}
          buttonStyle="tertiary-grey"
          iconType="close"
          data-testid="settings-close-button"
        />
        <SettingsTitle>Settings</SettingsTitle>

        <SettingsContent>
          <SettingsMenuContainer>
            {CATEGORIZED_SETTINGS.map(({ category, settings }) => (
              <MenuCategorySection key={category}>
                <CategoryTitle>{category}</CategoryTitle>

                {category === "workspace" && <WorkspaceSwitch />}

                <SettingsSideMenu>
                  {settings.map((setting) => {
                    const onClick = () =>
                      dispatch(showSettingsModal({ section: setting.section }));
                    const isActive = activeSection === setting.section;

                    if (setting.feature) {
                      return (
                        <MenuFeatureListItem
                          feature={setting.feature}
                          isActive={isActive}
                          key={setting.section}
                          onClick={onClick}
                          setting={setting}
                        />
                      );
                    }

                    return (
                      <MenuListItem
                        isActive={isActive}
                        key={setting.section}
                        onClick={onClick}
                        setting={setting}
                      />
                    );
                  })}
                </SettingsSideMenu>
              </MenuCategorySection>
            ))}

            <MenuSignOutListItem />
          </SettingsMenuContainer>

          {content && (
            <SettingsContentBlock
              integrationId={integrationId}
              description={content.description}
              section={content.section}
              title={content.title}
            />
          )}
        </SettingsContent>
      </SettingsPageContainer>
    </SettingsModal>
  );
}

const Loading = styled.div`
  animation: fadeIn 2.5s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    80% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

// Copy from Explorer; contender for UI lib
const PageContainer = styled.div(
  ({ theme: { box } }) => css`
    width: min(100% - calc(${box.spacing.page.inline} * 2), 1120px);
    margin-inline: auto;
    padding-block: ${box.spacing.page.block};
    display: grid;
    gap: ${box.spacing.page.gap};
  `,
);

const SettingsMenuContainer = styled.div`
  display: grid;
  align-content: start;
  gap: 8px;
`;

const MenuCategorySection = styled.section`
  display: grid;
  gap: 8px;
`;

const CategoryTitle = styled.h6`
  margin: 0 0 0 8px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.color.fg.muted};
`;

const SettingsModal = styled(BaseModal)`
  height: 100vh; /* use proper uom */
  width: 100vw;
  max-height: unset;
  max-width: unset;
  padding: 0;
  margin: 0;
  background-color: ${({ theme }) => theme.color.bg.default};
  border-radius: 0;
`;

const SettingsPageContainer = styled(PageContainer)(
  ({ theme: { box } }) => css`
    position: relative;
    padding-block: ${box.spacing.page.block};
  `,
);

const SettingsTitle = styled.h1(
  ({ theme }) => css`
    font: ${theme.font.headings.h1};
    font-size: 20px;
    line-height: 1.2;
    margin: 0;
  `,
);

const SettingsContent = styled.div`
  display: grid;
  grid-template-columns: minmax(max-content, 224px) 1fr;
  gap: 0 60px;
`;

const SettingsSideMenu = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0;
  margin: 0;
`;

const CloseButton = styled(IconButton)(
  ({ theme }) => css`
    position: absolute;
    top: 34px;
    right: 0;
    background-color: ${theme.color.fg.subtle};
    color: ${theme.color.fg.default};
    border-radius: ${theme.radius.full};
  `,
);
