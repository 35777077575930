import { Icon, IconButton } from "@fiberplane/ui";
import { useSelector } from "react-redux";

import { useNotebookIsPinnedQuery } from "../../api";
import {
  selectActiveNotebookId,
  selectActiveWorkspaceIdOrThrow,
} from "../../selectors";
import { dispatch } from "../../store";
import {
  forkActiveNotebook,
  pinNotebook,
  showNotebookDeleteConfirmation,
  unpinNotebook,
} from "../../thunks";
import { MenuDivider, MenuItem, ToggleMenu } from "../UI";
import { OFFSET } from "./constants";

export function NotebookActionsToggle() {
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const notebookId = useSelector(selectActiveNotebookId);

  const { data: isPinned } = useNotebookIsPinnedQuery(notebookId);

  return (
    <ToggleMenu
      offset={OFFSET}
      toggleElement={() => (
        <IconButton
          tabIndex={0}
          aria-label="More..."
          iconType="ellipsis"
          buttonStyle="secondary"
        />
      )}
    >
      <MenuItem
        id="pin"
        title={isPinned ? "Unpin notebook" : "Pin notebook"}
        onActivate={() =>
          dispatch(
            isPinned ? unpinNotebook(notebookId) : pinNotebook(notebookId),
          )
        }
        iconLeft={<Icon iconType={isPinned ? "push_pin_slash" : "push_pin"} />}
      />
      <MenuItem
        id="duplicate"
        title="Duplicate notebook"
        onActivate={() => dispatch(forkActiveNotebook())}
        iconLeft={<Icon iconType="copy" />}
      />
      <MenuDivider />
      <MenuItem
        id="delete-notebook"
        title="Delete notebook"
        onActivate={() =>
          dispatch(
            showNotebookDeleteConfirmation(notebookId, `/${workspaceId}`),
          )
        }
        iconLeft={<Icon iconType="trash" />}
      />
    </ToggleMenu>
  );
}
