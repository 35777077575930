import { styled } from "styled-components";

type Props = {
  error?: Error;
};

export function ChartPlaceholder({ error }: Props) {
  return (
    <Container>
      {error
        ? `Error loading chart: ${error.message || error.toString()}`
        : "Loading chart..."}
    </Container>
  );
}

const Container = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  background-color: ${({ theme }) => theme.colorBase200};
  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.borderRadius500};
  font: ${({ theme }) => theme.fontStudioStrongRegularShortHand};
`;
