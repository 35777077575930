import { css, styled } from "styled-components";

export const Key = styled.div(
  ({ theme }) => css`
    border: 1px solid ${theme.color.border.muted};
    background-color: ${theme.color.bg.elevated.default};
    border-radius: ${theme.borderRadius400};
    padding: 3px;
    min-width: 25px;
    color: ${theme.color.fg.muted};
    font: ${theme.fontStudioStrongRegularShortHand};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    height: 24px;
  `,
);
