import { useHandler, useLocalStorage } from "@fiberplane/hooks";
import { Input } from "@fiberplane/ui";
import { forwardRef, useMemo, useRef, useState } from "react";
import { css, styled } from "styled-components";

import {
  LOG_CELL_SUGGESTED_FIELDS_KEY,
  SUGGESTED_FIELDS_LIMIT,
} from "../../../constants";
import { useFuse } from "../../../hooks";
import { dispatch } from "../../../store";
import { toggleCellDisplayField } from "../../../thunks";
import type { ProviderEvent } from "../../../types";
import { uniq } from "../../../utils";
import { FilterContentItem } from "./FilterContentItem";
import { formatField } from "./utils";

type Props = {
  cellId: string;
  records: Array<ProviderEvent>;
  displayFields: Array<string>;
};

export const FilterContent = forwardRef(function FilterContent(
  { cellId, displayFields, records }: Props,
  ref: React.Ref<HTMLDivElement>,
): JSX.Element {
  const displayRef = useRef(displayFields);

  const unfilteredProperties = useMemo(() => {
    const set = new Set<string>();
    set.add("timestamp");
    set.add("document");
    set.add("body");

    for (const record of records) {
      for (const key in record.attributes) {
        set.add(`attributes.${key}`);
      }
    }

    return [...set].sort(createFieldSort(displayRef.current));
  }, [records]);

  const [query, setQuery] = useState("");

  const filteredProperties = useFuse(unfilteredProperties || [], query, {
    shouldFilter: true,
    shouldSort: true,
    includeScore: true,
    includeMatches: true,
    minMatchCharLength: Math.min(query.length, 3),
    findAllMatches: true,
    threshold: 0.3,
  });

  const { suggestedFieldIds, addSuggestedField } = useSuggestedFields();

  const onChange = useHandler((value: string) => {
    dispatch(toggleCellDisplayField(cellId, value));

    addSuggestedField(value);
  });

  const existingSuggestedFieldIds = useMemo(() => {
    if (!filteredProperties || suggestedFieldIds === null) {
      return [];
    }

    return suggestedFieldIds.filter((id) =>
      filteredProperties.find(({ item }) => item === id),
    );
  }, [suggestedFieldIds, filteredProperties]);

  const suggestedFields = useMemo(() => {
    return existingSuggestedFieldIds.map((id) => {
      const checked = displayFields.includes(id);
      return (
        <FilterContentItem
          checked={checked}
          key={id}
          value={id}
          onChange={onChange}
        >
          {formatField(id)}
        </FilterContentItem>
      );
    });
  }, [existingSuggestedFieldIds, onChange, displayFields]);

  const fields = useMemo(() => {
    const value = [];
    for (const result of filteredProperties) {
      const checked = displayFields.includes(result.item);
      value.push(
        <FilterContentItem
          checked={checked}
          key={result.item}
          value={result.item}
          onChange={onChange}
        >
          {formatField(result.item)}
        </FilterContentItem>,
      );
    }

    return value;
  }, [filteredProperties, displayFields, onChange]);

  return (
    <Container ref={ref} data-testid="fields-filter">
      <StyledTextInput
        data-prevent-rte
        onChange={(event) => setQuery(event.target.value)}
        placeholder="Search fields..."
      />

      <List>
        <ListTitle>Suggested</ListTitle>
        <ListContent>
          {suggestedFields.length > 0 ? (
            suggestedFields.slice(0, 6)
          ) : (
            <span>No results</span>
          )}
        </ListContent>
      </List>

      <List>
        <ListTitle>All</ListTitle>
        <ListContent>
          {fields.length > 0 ? fields : <span>No results</span>}
        </ListContent>
      </List>
    </Container>
  );
});

function useSuggestedFields() {
  const [suggestedFieldIds, setSuggestedFieldIds] = useLocalStorage<
    Array<string>
  >(LOG_CELL_SUGGESTED_FIELDS_KEY, []);

  const addSuggestedField = (field: string) => {
    const updatedFieldIds = uniq([field, ...suggestedFieldIds]).slice(
      0,
      SUGGESTED_FIELDS_LIMIT,
    );
    setSuggestedFieldIds(updatedFieldIds);
  };

  return { suggestedFieldIds, addSuggestedField };
}

function createFieldSort(displayedFields: Array<string>) {
  return (a: string, b: string) => {
    const indexA = displayedFields.indexOf(a);
    const indexB = displayedFields.indexOf(b);

    if (indexA !== -1 && indexB === -1) {
      return -1;
    }

    if (indexA === -1 && indexB !== -1) {
      return 1;
    }

    if (indexA !== -1 && indexB !== -1) {
      if (indexA > indexB) {
        return 1;
      }

      return -1;
    }

    // The values stored in our data model is different from what we display
    // So we're formatting the key value
    const displayedA = formatField(a);
    const displayedB = formatField(b);
    if (displayedA === displayedB) {
      return 0;
    }

    if (displayedA < displayedB) {
      return -1;
    }

    return 1;
  };
}

const StyledTextInput = styled(Input).attrs({ type: "text" })`
  max-width: 100%;
`;

const Container = styled.div(
  ({ theme }) => css`
    max-height: min(50vh, 360px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
    position: relative;
    width: 240px;
    background-color: ${theme.color.bg.elevated.default};
    border: 1px solid ${theme.color.border.default};
    box-shadow: ${theme.effect.shadow.m};
    border-radius: ${theme.radius.rounded};
  `,
);

const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

const ListTitle = styled.h2(
  ({ theme }) => css`
    font: ${theme.font.body.sm.medium};
    color: ${theme.color.fg.muted};
    margin: 0;
    padding: 0;
  `,
);

const ListContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;
