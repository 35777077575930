import {
  CLOSE_NOTEBOOK,
  OPEN_NOTEBOOK,
  showError,
  withActiveNotebook,
} from "../actions";
import { notebooksApi } from "../api";
import { NotFoundError, normalizeException } from "../errors";
import {
  selectActiveWorkspaceIdOrThrow,
  selectCanCreateNotebook,
  selectCurrentUser,
  selectNotebook,
} from "../selectors";
import type { Thunk } from "../store";
import { uuid64 } from "../utils";
import { unsubscribeFromNotebook } from "./courierThunks";
import { addNotification } from "./notificationsThunks";

export const closeNotebook =
  (
    notebookId: string,
    { newActiveNotebookId }: { newActiveNotebookId: string | undefined },
  ): Thunk =>
  (dispatch, getState) => {
    const notebook = selectNotebook(getState(), notebookId);
    if (notebook?.id) {
      dispatch(unsubscribeFromNotebook(notebookId));
    }

    dispatch({
      type: CLOSE_NOTEBOOK,
      payload: { notebookId, newActiveNotebookId },
    });
  };

export const duplicateNotebook =
  (notebookId: string): Thunk<Promise<void>> =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const workspaceId = selectActiveWorkspaceIdOrThrow(state);
      const canCreateNotebook = selectCanCreateNotebook(state);

      if (!canCreateNotebook) {
        dispatch(
          addNotification({
            type: "danger",
            title:
              "You don't have permission to create notebooks in this workspace",
          }),
        );

        return;
      }

      const user = selectCurrentUser(state);

      const notebooks = await dispatch(
        notebooksApi.endpoints.listNotebooks.initiate({ workspaceId }),
      ).unwrap();

      const notebook = notebooks.find(({ id }) => id === notebookId);

      if (!notebook) {
        dispatch(
          addNotification({
            type: "warning",
            title: "Unable to duplicate notebook",
            description: "Target notebook does not exist",
          }),
        );

        return;
      }

      const title = `Copy of ${notebook.title} by ${user?.name ?? "Anonymous"}`;

      await dispatch(
        notebooksApi.endpoints.duplicateNotebook.initiate({
          workspaceId,
          title,
          notebookId,
        }),
      );
    } catch (error) {
      dispatch(
        addNotification({
          type: "danger",
          title: "Failed to duplicate notebook",
          description: normalizeException(error).message,
        }),
      );
    }
  };

export const openNotebook = (): Thunk<string> => (dispatch) => {
  const notebookId = uuid64();
  dispatch({ type: OPEN_NOTEBOOK, payload: notebookId });
  return notebookId;
};

export const pinNotebook =
  (notebookId: string): Thunk<Promise<void>> =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const workspaceId = selectActiveWorkspaceIdOrThrow(state);
      let notebooks = notebooksApi.endpoints.listNotebooks.select({
        workspaceId,
        // Unfortunately splitting the API causes typing issues on selectors
        // https://github.com/reduxjs/redux-toolkit/issues/2484
        // @ts-expect-error
      })(state).data;

      if (!notebooks) {
        notebooks = await dispatch(
          notebooksApi.endpoints.listNotebooks.initiate({ workspaceId }),
        ).unwrap();

        if (!notebooks) {
          // if there's still not a list of notebooks,
          // we don't pin anything
          throw new Error("Unable to pin notebooks");
        }
      }

      const notebook = notebooks.find((notebook) => {
        return notebook.id === notebookId;
      });

      if (!notebook) {
        return;
      }

      await dispatch(
        notebooksApi.endpoints.pinNotebook.initiate({ notebookId }),
      );

      dispatch(addNotification({ title: "Notebook has been pinned" }));
    } catch {
      dispatch(
        addNotification({
          title: "Failed to pin the notebook",
          type: "danger",
        }),
      );
    }
  };

export const removeNotebook =
  (id: string): Thunk =>
  async (dispatch) => {
    try {
      await dispatch(
        notebooksApi.endpoints.deleteNotebook.initiate({ notebookId: id }),
      );
    } catch (error) {
      if (error instanceof NotFoundError) {
        // If it's already removed, we treat that as a success.
        return;
      }

      dispatch(
        withActiveNotebook(
          showError({
            type: "other",
            message: `Cannot remove recent notebook. Reason: ${error}`,
          }),
        ),
      );
    }
  };

export const unpinNotebook =
  (notebookId: string): Thunk<Promise<void>> =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const workspaceId = selectActiveWorkspaceIdOrThrow(state);
      const { data: notebooks } = notebooksApi.endpoints.listNotebooks.select({
        workspaceId,
        // Unfortunately splitting the API causes typing issues on selectors
        // https://github.com/reduxjs/redux-toolkit/issues/2484
        // @ts-expect-error
      })(state);

      if (!notebooks) {
        return;
      }

      const notebook = notebooks.find((notebook) => notebook.id === notebookId);
      if (!notebook) {
        return;
      }

      await dispatch(
        notebooksApi.endpoints.unpinNotebook.initiate({ notebookId }),
      );

      dispatch(addNotification({ title: "Notebook is no longer pinned" }));
    } catch {
      dispatch(
        addNotification({
          title: "Failed to unpin the notebook",
          type: "danger",
        }),
      );
    }
  };
