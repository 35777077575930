import { css, styled } from "styled-components";

import { useCellFocus, useFilePaste } from "../../hooks";
import type { HeadingCell } from "../../types";
import { RichTextInput, type RichTextInputProps } from "./RTE";

export type HeadingCellContentProps = {
  cell: { type: "heading" } & HeadingCell;
  readOnly: boolean;
};

export function HeadingCellContent({
  cell,
  readOnly,
}: HeadingCellContentProps) {
  const focus = useCellFocus(cell.id);
  const onPaste = useFilePaste(cell.id);

  return (
    <StyledRichTextInput
      cellId={cell.id}
      focus={focus}
      formatting={cell.formatting}
      onPaste={onPaste}
      readOnly={readOnly}
      tagName={cell.headingType}
      value={cell.content}
    />
  );
}

function getFontCss(tagName: RichTextInputProps["tagName"]) {
  switch (tagName) {
    case "h1":
      return css`
        font: ${({ theme }) => theme.font.headings.h1};
      `;

    case "h2":
      return css`
        font: ${({ theme }) => theme.font.headings.h2};
      `;

    case "h3":
      return css`
        font: ${({ theme }) => theme.font.headings.h3};
      `;
  }
}

const StyledRichTextInput = styled(RichTextInput)<{ title?: boolean }>`
  ${({ tagName }) => getFontCss(tagName)}
  margin: 0;
`;
