import { selectSelectedTextInCell } from "../../selectors";
import type { RootState } from "../../state";
import type { Cell } from "../../types";
import { getAllRichTextFromTableCell, only } from "../../utils";
import type { ExportRange } from "./types";

/**
 * Exports the given cells as plain text.
 */
export function exportCellsAsPlainText(
  state: RootState,
  cells: Array<Cell>,
  range: ExportRange,
): string {
  // If only one cell is selected, we don't want cell-level annotations.
  const onlyCell = only(cells);
  if (onlyCell) {
    return selectSelectedTextInCell(state, onlyCell, range) ?? "";
  }

  let text = "";
  let previousCell: Cell | undefined;
  for (const cell of cells) {
    const cellText = getCellAsPlainText(state, cell, range);

    const separator = getSeparator(cell, previousCell);
    text += `${separator}${cellText}`;

    previousCell = cell;
  }

  return text;
}

function getCellAsPlainText(
  state: RootState,
  cell: Cell,
  range: ExportRange,
): string {
  const text = selectSelectedTextInCell(state, cell, range) ?? "";

  switch (cell.type) {
    case "checkbox":
      return `[${cell.checked ? "x" : " "}] ${text}`;

    case "code":
    case "heading":
    case "text":
      return text;

    // TODO (Oscar):
    // https://linear.app/fiberplane/project/discussion-cell-3b9038a7df70/FP
    case "discussion":
    case "graph":
    case "image":
    case "log":
    case "timeline":
      return "";

    case "divider":
      return "---";

    case "list_item":
      return `${
        cell.listType === "ordered" ? `${cell.startNumber ?? "1"}.` : "-"
      } ${text}`;

    case "provider":
      return cell.output
        ? exportCellsAsPlainText(state, cell.output, { type: "all" })
        : "";

    case "table":
      return getAllRichTextFromTableCell(cell).text;
  }
}

function getSeparator(cell: Cell, previousCell?: Cell): string {
  if (!previousCell) {
    return "";
  }

  if (cell.type === "checkbox" && previousCell.type === "checkbox") {
    return "\n";
  } else if (cell.type === "list_item" && previousCell.type === "list_item") {
    return "\n";
  } else {
    return "\n\n";
  }
}
