import { useSelector } from "react-redux";
import { css, styled } from "styled-components";

import { selectCanCreateNotebook } from "../../selectors";
import { useAppDispatch } from "../../store";
import { createNotebookAndRedirect } from "../../thunks";
import {
  TemplateContainer,
  TemplateDetails,
  TemplateIcon,
  TemplateIconContainer,
  TemplateItems,
  TemplateTitle,
} from "../Templates";

export function GettingStartedTemplatesContent() {
  const canCreateNotebook = useSelector(selectCanCreateNotebook);
  const dispatch = useAppDispatch();

  const handleCreateNotebook = () => dispatch(createNotebookAndRedirect());

  return (
    <>
      <TemplateItems />

      {canCreateNotebook && (
        <TemplateContainer onClick={handleCreateNotebook}>
          <BlankNotebookIconContainer>
            <TemplateIcon iconType="plus" />
          </BlankNotebookIconContainer>

          <TemplateTitle>Blank notebook</TemplateTitle>
          <TemplateDetails>Start a notebook from scratch</TemplateDetails>
        </TemplateContainer>
      )}
    </>
  );
}

const BlankNotebookIconContainer = styled(TemplateIconContainer)(
  ({ theme }) => css`
    background-color: ${theme.color.bg.default};
    border: 1px solid ${theme.color.border.default};
  `,
);
