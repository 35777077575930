import { Icon } from "@fiberplane/ui";
import { css, styled } from "styled-components";

import type { UserSummary } from "../../../types";
import { ProfileAvatar } from "../../Avatar";
import { StatusButtonPlaceholder } from "./DiscussionStatus";
import { TypingIndicator } from "./TypingIndicator";

type PlaceholderProps = {
  createdBy: UserSummary;
};

/**
 * Component that renders when a discussion is opened by another user and no
 * initial comment is submitted.
 */
export function Placeholder({ createdBy }: PlaceholderProps) {
  return (
    <>
      <StatusContainer forwardedAs="span">
        <Icon iconType="chats_circle" /> Discussion being opened...
      </StatusContainer>

      <CreatedByContainer>
        <ProfileAvatar name={createdBy.name} userId={createdBy.id} />
        <TypingIndicator />
      </CreatedByContainer>
    </>
  );
}

const StatusContainer = styled(StatusButtonPlaceholder)(
  ({ theme }) => css`
    &, &:hover {
      background-color: ${theme.color.bg.emphasis.neutral};
      color: ${theme.color.fg.default};
    }
  `,
);

const CreatedByContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 32px;
`;
