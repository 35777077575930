import { ONE_DAY, ONE_HOUR, ONE_MINUTE } from "../constants";
import { formatDate } from "./formatDate";

const relativeTimeFormat = new Intl.RelativeTimeFormat("en", {
  numeric: "auto",
});

export function formatRelativeDate(timestamp: Date): string {
  const now = new Date();
  const distance = (timestamp.getTime() - now.getTime()) / 1000;
  if (distance < -7 * ONE_DAY) {
    return formatDate(timestamp);
  } else if (distance < -ONE_DAY) {
    return relativeTimeFormat.format(Math.floor(distance / ONE_DAY), "days");
  } else if (distance < -ONE_HOUR) {
    return relativeTimeFormat.format(Math.floor(distance / ONE_HOUR), "hours");
  } else if (distance < -ONE_MINUTE) {
    return relativeTimeFormat.format(
      Math.floor(distance / ONE_MINUTE),
      "minute",
    );
  } else {
    return "just now";
  }
}
