import { styled } from "styled-components";

import { ResultHeader, ResultIcon, ResultTitle } from "./styled";

export function NoResults() {
  return (
    <Empty>
      <ResultIcon iconType="robot" />
      <ResultHeader>
        <ResultTitle>no matches found</ResultTitle>
      </ResultHeader>
    </Empty>
  );
}

const Empty = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-auto-rows: auto;
  grid-column-gap: 16px;
  padding: 12px 24px;
  align-items: center;
  cursor: inherit;
`;
