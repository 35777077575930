import { useHandler } from "@fiberplane/hooks";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { createStructuredSelector } from "reselect";

import { useGetViewByNameQuery, useUpdateViewMutation } from "../../api";
import { normalizeException } from "../../errors";
import {
  selectActiveWorkspaceIdOrThrow,
  selectCanUpdateView,
} from "../../selectors";
import { dispatch } from "../../store";
import { addNotification } from "../../thunks";
import type { UpdateView } from "../../types";
import { View } from "./View";

export function ViewWithName() {
  const { canUpdateView, workspaceId } = useSelector(selectViewWithNameState);
  const { viewName = "" } = useParams();
  const { data, isLoading: isViewLoading } = useGetViewByNameQuery(
    { workspaceId, viewName },
    { refetchOnMountOrArgChange: true, skip: !viewName },
  );

  const [updateView, { isLoading, isSuccess, reset }] = useUpdateViewMutation();

  const onUpdate = useHandler(async (changes: UpdateView) => {
    if (!canUpdateView) {
      return dispatch(
        addNotification({
          type: "danger",
          title: "You don't have permissions to update views in this workspace",
        }),
      );
    }

    if (!data) {
      return;
    }

    const result = await updateView({
      workspaceId,
      viewName: data.name,
      updateView: changes,
    });

    if ("error" in result) {
      return dispatch(
        addNotification({
          type: "danger",
          title: normalizeException(result.error).message,
        }),
      );
    }
  });

  if (!data || isViewLoading) {
    return null;
  }

  return (
    <View
      key={data.name}
      isLoading={isLoading}
      isSuccess={isSuccess}
      onUpdate={onUpdate}
      resetMutation={reset}
      view={data}
    />
  );
}

const selectViewWithNameState = createStructuredSelector({
  canUpdateView: selectCanUpdateView,
  workspaceId: selectActiveWorkspaceIdOrThrow,
});
