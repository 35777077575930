export * from "./RichTextInput";
export * from "./constants";
export * from "./utils";

export {
  SupportedSyntax,
  getSupportedSyntax,
  useHighlighter,
  type SupportedSyntaxName,
} from "./hooks";
export { processFormatting } from "./processFormatting";
export type { ExtendedFormatting } from "./types";
