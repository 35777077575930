// biome-ignore lint/style/noNamespaceImport: This is the recommended way to import sentry
import * as Sentry from "@sentry/react";

import { ErrorScreen } from "./ErrorScreen";

type Props = {
  children: React.ReactNode;
};

export function ErrorBoundary(props: Props): JSX.Element {
  return (
    <Sentry.ErrorBoundary fallback={ErrorScreen}>
      {props.children}
    </Sentry.ErrorBoundary>
  );
}
