import { workspacesApi } from "../api";
import type { RootState } from "../state";
import { selectCurrentUser } from "./authenticationSelectors";

export const selectActiveWorkspace = (state: RootState) =>
  selectAvailableWorkspaces(state).find(
    ({ name }) => name === selectActiveWorkspaceName(state),
  );

export const selectActiveWorkspaceDisplayName = (state: RootState) =>
  selectActiveWorkspace(state)?.displayName;

/**
 * As of writing, we should only lack an active workspace in initial stages of the app
 * e.g. when the user is not signed in or when they first boot studio,
 * and the app still needs to redirect to the last active or default workspace.
 */
export const selectActiveWorkspaceOrThrow = (state: RootState) => {
  const activeWorkspaceId = selectActiveWorkspaceIdOrThrow(state);

  const workspace = selectAvailableWorkspaces(state).find(
    ({ id }) => id === activeWorkspaceId,
  );

  if (!workspace) {
    throw new Error("Active workspace is not available");
  }

  return workspace;
};

export const selectActiveWorkspaceId = (state: RootState) =>
  selectActiveWorkspace(state)?.id;

/**
 * As of writing, we should only lack an active workspace in initial stages of the app
 * e.g. when the user is not signed in or when they first boot studio,
 * and the app still needs to redirect to the last active or default workspace.
 */
export function selectActiveWorkspaceIdOrThrow(state: RootState) {
  const workspaceId = selectActiveWorkspaceId(state);
  if (!workspaceId) {
    throw new Error("No active workspace");
  }

  return workspaceId;
}

export const selectActiveWorkspaceName = (state: RootState) =>
  state.workspaces.activeWorkspaceName;

/**
 * As of writing, we should only lack an active workspace in initial stages of the app
 * e.g. when the user is not signed in or when they first boot studio,
 * and the app still needs to redirect to the last active or default workspace.
 */
export const selectActiveWorkspaceNameOrThrow = (state: RootState) => {
  const workspaceName = selectActiveWorkspaceName(state);
  if (!workspaceName) {
    throw new Error("No active workspace");
  }

  return workspaceName;
};

export const selectActiveWorkspaceOwnerId = (state: RootState) =>
  selectActiveWorkspace(state)?.ownerId;

export const selectActiveWorkspaceRole = (state: RootState) => {
  const workspaceId = selectActiveWorkspaceId(state);
  return workspaceId ? selectCurrentUser(state)?.roles[workspaceId] : undefined;
};

export const selectActiveWorkspaceType = (state: RootState) =>
  selectActiveWorkspace(state)?.type;

export const selectAvailableWorkspaces = (state: RootState) =>
  // Unfortunately splitting the API causes typing issues on selectors
  // https://github.com/reduxjs/redux-toolkit/issues/2484
  // @ts-expect-error
  workspacesApi.endpoints.listWorkspaces.select()(state).data ?? [];

export const selectPersonalWorkspace = (state: RootState) =>
  selectAvailableWorkspaces(state).find(({ type }) => type === "personal");
