import { Icon } from "@fiberplane/ui";
import { motion } from "framer-motion";
import { styled } from "styled-components";

import {
  ButtonFooter,
  IllustrationContainer,
  ModalBodyRegularText,
  ModalClose,
  ModalHeading,
  StyledModal,
  StyledModalContent,
} from "./common";

export function ErrorScreen() {
  return (
    <StyledModal>
      <StyledModalContent>
        <IllustrationContainer>
          <ModalClose />
          <ErrorIconContainer>
            <Icon width="70" iconType="fiberplane_logo_cracked" />
          </ErrorIconContainer>
        </IllustrationContainer>
        <ModalHeading>Uh Oh</ModalHeading>
        <ModalBodyRegularText>
          Something went wrong setting up your FPD token. Try again in a few
          seconds.
        </ModalBodyRegularText>
      </StyledModalContent>
      <ButtonFooter />
    </StyledModal>
  );
}

const ErrorIconContainer = styled(motion.span).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})``;
