import type { NotebookAction } from "../actions";
import { FiberKit, Sentry } from "../services";
import { type NotebookState, initialNotebookState } from "../state";
import type {
  CellWithMetadata,
  CellsUpdate,
  EditorStateUpdate,
  NotebookReducerResult,
  NotebookStateUpdate,
  PendingOperationsUpdate,
  SideEffectDescriptor,
  StateWithSideEffects,
} from "../types";
import { shallowEqualArrays } from "../utils";

const providerDataNopUpdateTypes = new Set([
  "replace_text",
  "delete_from_cursor",
]);

export function notebookReducer(
  state = initialNotebookState,
  action: NotebookAction,
): StateWithSideEffects<NotebookState> {
  let sideEffects: Array<SideEffectDescriptor> = [];
  try {
    let result: NotebookReducerResult;
    if (action.type === "notebook_js_error") {
      result = FiberKit.notebookReducer(state.id, {
        type: "notebook_error",
        payload: { ...action.payload, error: action.payload.error.toString() },
      });
      state = {
        ...stateUpdateReducer(state, result.stateUpdate, action),
        error: action.payload.error,
      };
    } else {
      const start = Date.now();

      result = FiberKit.notebookReducer(state.id, action);
      state = stateUpdateReducer(state, result.stateUpdate, action);

      const timeSpent = Date.now() - start;
      if (timeSpent > 100) {
        Sentry.captureWarning(`Notebook reducer took ${timeSpent}ms`, {
          action,
          state: Sentry.getSensitiveNotebookInfo(state.id),
        });
      }
    }

    sideEffects = result.sideEffects;
  } catch (error) {
    Sentry.captureError("FiberKit error while processing notebook action", {
      action,
      error,
      state: Sentry.getSensitiveNotebookInfo(state.id),
    });
  }

  return { state, sideEffects };
}

// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: it's never going to be easy...
function stateUpdateReducer(
  state: NotebookState,
  stateUpdate: NotebookStateUpdate,
  originalAction: NotebookAction,
): NotebookState {
  const updateEntries = Object.entries(stateUpdate);
  if (updateEntries.length === 0) {
    return state;
  }

  const newState = { ...state };
  for (const [key, value] of updateEntries) {
    switch (key) {
      case "cells": {
        const { cellIds, changedCells } = value as CellsUpdate;
        const cellsById = {} as Record<string, CellWithMetadata>;
        for (const id of cellIds) {
          const newCell = changedCells.find(({ cell }) => cell.id === id);
          const oldCell = state.cellsById[id];
          if (
            providerDataNopUpdateTypes.has(originalAction.type) &&
            newCell?.cell?.type === "provider" &&
            oldCell?.cell?.type === "provider" &&
            newCell.cell.response?.data === oldCell.cell.response?.data
          ) {
            // Special handling for provider cells - we don't want to update them
            // when response is the same as it was
            newCell.cell.response = oldCell.cell.response;
            newCell.cell.output = oldCell.cell.output;
          }

          const cell = newCell ?? oldCell;
          if (cell) {
            cellsById[id] = cell;
          }
        }

        if (!shallowEqualArrays(cellIds, newState.cellIds)) {
          newState.cellIds = cellIds;
        }

        newState.cellsById = cellsById;
        break;
      }

      case "editor": {
        const { contextMenu: oldContextMenu, ...oldEditor } = state.editor;
        const { contextMenu, ...newEditor } = value as EditorStateUpdate;
        const newContextMenu =
          contextMenu === null ? undefined : contextMenu ?? oldContextMenu;
        newState.editor = {
          ...oldEditor,
          contextMenu: newContextMenu,
          ...newEditor,
        };
        break;
      }

      case "pendingOperations": {
        const { numOperations, numUnsentOperations } =
          value as PendingOperationsUpdate;
        newState.numPendingOperations = numOperations;
        newState.numUnsentPendingOperations = numUnsentOperations;
        break;
      }

      default:
        if (value === null) {
          delete newState[key as keyof NotebookState];
        } else {
          // This looks hideous... I think the root issue is that TypeScript
          // doesn't track the types after `Object.entries()`, but I don't know
          // a better solution here...
          // biome-ignore lint/suspicious/noExplicitAny: see comment
          (newState[key as keyof NotebookState] as any) = value;
        }
    }
  }

  return newState;
}
