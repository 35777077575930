import type { Profile } from "../types";
import { baseApi } from "./base";

export const userApi = baseApi
  .injectEndpoints({
    endpoints: (builder) => ({
      getOwnProfile: builder.query<Profile, void>({
        query: () => "/profile",
      }),
      logout: builder.mutation<void, void>({
        query: () => ({ url: "/logout", method: "POST" }),
      }),
      updateProfilePicture: builder.mutation<void, { picture: File }>({
        query: ({ picture }) => {
          const body = new FormData();
          body.append("picture", picture);

          return {
            url: "/profile/picture",
            method: "POST",
            body,
          };
        },
      }),
      deleteAccount: builder.mutation<void, void>({
        query: () => {
          return {
            url: "/profile",
            method: "DELETE",
          };
        },
      }),
    }),
    overrideExisting: false,
  })
  .enhanceEndpoints({
    addTagTypes: ["Profile"],
    endpoints: {
      getOwnProfile: {
        providesTags: ["Profile"],
      },
      logout: {
        invalidatesTags: ["Profile"],
      },
      deleteAccount: {
        invalidatesTags: ["Profile"],
      },
    },
  });

export const { useDeleteAccountMutation } = userApi;
