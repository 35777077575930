import { CellById } from "../../../../CellById";
import { addCellAtIndex, withActiveNotebook } from "../../../../actions";
import { COMMENT_INITIAL } from "../../../../constants";
import {
  makeDataSourceSelector,
  selectActiveNotebook,
  selectActiveNotebookId,
  selectCell,
  selectContextMenu,
  selectFocusedCell,
} from "../../../../selectors";
import type { Thunk } from "../../../../store";
import {
  addCellWithDivider,
  changeCellType,
  createThread,
  getProviderForIntent,
  shouldChangeCurrentCell,
  showModal,
} from "../../../../thunks";
import {
  type Cell,
  type CellTypeProperties,
  type NotebookContextMenuInfo,
  type NotebookFocus,
  type QueryField,
  isContentCell,
} from "../../../../types";
import {
  type Intent,
  charCount,
  charSlice,
  encodeQueryData,
  getQueryField,
  makeCell,
  track,
  uuid64,
} from "../../../../utils";

type AddCellBelowParams = {
  contextMenu: NotebookContextMenuInfo;
  focusField?: string;
  properties: CellTypeProperties;
  source: SourceType;
};

type ChangeIntoOrAddProviderCellParams = {
  providerType: string;
  queryType: string;
  source: SourceType;
};

export type SourceType = "slash command" | "menu | turn into";

/**
 * Adds a cell below the one from which the slash command menu was opened.
 */
const addCellBelow =
  ({
    contextMenu,
    focusField,
    properties,
    source,
  }: AddCellBelowParams): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const notebook = selectActiveNotebook(state);

    track("notebook | add cell", {
      cellType: properties.type,
      notebookId: notebook.id,
      position: "after",
      source,
    });

    const relatedId = contextMenu.cellId;

    if (properties.type === "divider") {
      return addCellWithDivider({ relatedId });
    }

    const cell = makeCell(uuid64(), properties);
    const focus: NotebookFocus = {
      type: "collapsed",
      cellId: cell.id,
      field: focusField,
      offset: isContentCell(cell) ? 0 : undefined,
    };
    const index =
      selectActiveNotebook(getState()).cellIds.indexOf(relatedId) + 1;

    dispatch(
      withActiveNotebook(
        addCellAtIndex({ cell, index, closeMenu: contextMenu, focus }),
      ),
    );
  };

/**
 * Changes the type of the cell from which the slash command menu was opened.
 */
const changeCurrentCellType =
  ({
    contextMenu,
    focusField,
    properties,
    source,
  }: AddCellBelowParams): Thunk =>
  (dispatch, getState) => {
    const { cellId, typeAheadOffset } = contextMenu;

    const state = getState();
    track("notebook | change cell", {
      cellType: properties.type,
      notebookId: selectActiveNotebookId(state),
      originalCellType: selectCell(state, cellId)?.type,
      position: "after",
      source,
    });

    dispatch(
      changeCellType(cellId, properties, {
        closeMenu: contextMenu,
        focus: {
          type: "collapsed",
          cellId,
          field: focusField,
          offset: (typeAheadOffset || 1) - 1,
        },
      }),
    );
  };

/**
 * Changes the cell from which the slash command menu was opened into a
 * discussion cell, or adds the discussion cell below.
 */
export const changeIntoOrAddDiscussionCell =
  (source: SourceType): Thunk =>
  async (dispatch, getState) => {
    const state = getState();
    const contextMenu = selectContextMenu(state);
    if (!contextMenu) {
      throw new Error("No context menu opened");
    }

    const cell = selectCell(state, contextMenu.cellId);
    if (cell?.readOnly) {
      CellById.get(cell.id)?.shake();
      return;
    }

    const threadId = await dispatch(createThread());
    dispatch(
      changeOrAddCellType({
        contextMenu,
        focusField: COMMENT_INITIAL,
        properties: { type: "discussion", threadId },
        source,
      }),
    );
  };

/**
 * Changes the cell from which the slash command menu was opened into a provider
 * cell, or adds the provider cell below.
 *
 * Will open a data source configuration modal if no data source of the given
 * provider type is configured yet.
 */
export const changeIntoOrAddProviderCell =
  ({
    providerType,
    queryType,
    source,
  }: ChangeIntoOrAddProviderCellParams): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const contextMenu = selectContextMenu(state);
    if (!contextMenu) {
      throw new Error("No context menu opened");
    }

    const changeIntoOrAddCell = async () => {
      const state = getState();
      const focusedCell = selectFocusedCell(state);
      const focusField = await dispatch(
        getFocusFieldForProviderCell({
          providerType,
          queryType,
        }),
      );

      const queryData =
        focusField &&
        focusedCell &&
        dispatch(shouldChangeCurrentCell(contextMenu))
          ? getInitialQueryDataForFocusField(
              focusField,
              focusedCell,
              contextMenu,
            )
          : undefined;

      dispatch(
        changeOrAddCellType({
          contextMenu,
          focusField: focusField?.name,
          properties: {
            type: "provider",
            intent: `${providerType},${queryType}`,
            queryData,
          },
          source,
        }),
      );
    };

    const dataSource = makeDataSourceSelector(providerType)(state);
    if (dataSource) {
      changeIntoOrAddCell();
    } else {
      dispatch(
        showModal({
          type: "directAccess",
          dataSource,
          providerType,
        }),
      );
    }
  };

/**
 * Changes the type of the cell from which the slash command menu was opened,
 * or adds a cell of that type below it.
 */
export const changeOrAddCellType =
  (params: AddCellBelowParams): Thunk =>
  (dispatch) => {
    if (dispatch(shouldChangeCurrentCell(params.contextMenu))) {
      dispatch(changeCurrentCellType(params));
    } else {
      dispatch(addCellBelow(params));
    }
  };

/**
 * Returns the first (non-checkbox) field from the provider schema as the field
 * to initially set focus to.
 */
const getFocusFieldForProviderCell =
  (intent: Intent): Thunk<Promise<QueryField | undefined>> =>
  async (dispatch) => {
    const { schema } = await dispatch(getProviderForIntent(intent));
    if (!schema) {
      return;
    }

    return schema.find((field) => field.type !== "checkbox");
  };

/**
 * Returns a provider cell's initial query data by initializing the value of the
 * focus field with the content from the given cell.
 */
function getInitialQueryDataForFocusField(
  focusField: QueryField,
  cell: Cell,
  contextMenu: NotebookContextMenuInfo,
): string | undefined {
  const fieldName = focusField.name;

  if (cell.type === "provider") {
    // If the cell we are converting from is also a provider cell, we can carry
    // over from the field with the same name, assuming it will be a semantic
    // match.
    // TODO: Should we just carry over all fields that are present in the new
    //       cell's schema?
    const value = getQueryField(cell.queryData, fieldName);
    return value ? encodeQueryData({ [fieldName]: value }) : undefined;
  }

  if (isContentCell(cell)) {
    const { content } = cell;
    return content
      ? encodeQueryData({
          [fieldName]: stripTypeAheadText(content, contextMenu),
        })
      : undefined;
  }
}

function stripTypeAheadText(
  content: string,
  menu: NotebookContextMenuInfo,
): string {
  const { typeAheadOffset, menuType, typeAheadText } = menu;

  // With slash command we want to strip out some text, for the other menu types
  // typeAheadOffset isn't used (context menu) or we don't want to consider the
  // content that is entered while the menu is open to be "invalid" or to be
  // ignored in any way.
  if (
    menuType !== "slash_command" ||
    !typeAheadOffset ||
    typeAheadText === undefined
  ) {
    return content;
  }

  return (
    charSlice(content, 0, typeAheadOffset - 1) +
    charSlice(content, typeAheadOffset + charCount(typeAheadText))
  );
}
