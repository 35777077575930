import { createGlobalStyle, css } from "styled-components";

export const GlobalStyle = createGlobalStyle(
  ({ theme }) => css`
    * {
      box-sizing: border-box;
    }

    body[data-switching="true"] {
      &,
      * {
        transition: background 0.1s ease-in, border 0.1s ease-in,
          color 0.1s ease-in;
      }
    }

    html,
    body {
      font: ${theme.font.body.md.regular};
      color: ${theme.color.fg.default};
      background-color: ${theme.color.bg.default};
    }

    html,
    body,
    #root {
      height: 100%;
      width: 100%;
    }

    h1 {
      font: ${theme.font.headings.h1};
    }

    h2 {
      font: ${theme.font.headings.h2};
    }

    h3 {
      font: ${theme.font.headings.h4};
    }

    h4 {
      font: ${theme.font.headings.h4};
    }

    h5 {
      font: ${theme.font.headings.h5};
    }

    h6 {
      font: ${theme.font.headings.h6};
    }

    ul {
      list-style-type: none;
    }

    ol {
      list-style-type: decimal;
    }

    ol,
    ul,
    li {
      margin: 0;
      padding: 0;
    }

    ol ol {
      list-style-type: lower-alpha;
    }

    ol ol,
    ul ul {
      margin: 0;
    }

    a {
      ${LinkStyle}
    }

    pre {
      padding: 6px 16px;
      background: ${theme.color.bg.subtle};
      color: ${theme.color.fg.default};
      font: ${theme.font.code.regular};
    }

    /* FIXME (Oscar): Temporary for as long the theme switcher is hidden behind
    a feature flag */
    @media (prefers-color-scheme: dark) {
      :root {
        --color-bg-accent-1: var(--color-accent-magenta-900);
        --color-bg-accent-2: var(--color-accent-lime-700);
        --color-bg-accent-3: var(--color-accent-rust-700);
        --color-bg-danger: var(--color-support-danger-alpha-50);
        --color-bg-default: var(--color-neutral-900);
        --color-bg-disabled-overlay: var(--color-neutral-alpha-dark-300);
        --color-bg-disabled: var(--color-neutral-700);
        --color-bg-elevated-default: var(--color-neutral-800);
        --color-bg-elevated-hover: var(--color-neutral-700);
        --color-bg-emphasis-neutral-subtle: var(
          --color-neutral-alpha-light-100
        );
        --color-bg-emphasis-neutral: var(--color-neutral-800);
        --color-bg-emphasis-primary-subtle: var(
          --color-primary-brand-alpha-200
        );
        --color-bg-emphasis-primary: var(--color-primary-brand-500);
        --color-bg-hover-primary: var(--color-primary-brand-alpha-200);
        --color-bg-hover: var(--color-neutral-700);
        --color-bg-subtle: var(--color-neutral-800);
        --color-bg-success: var(--color-support-success-alpha-50);
        --color-bg-warning: var(--color-support-warning-alpha-50);
        --color-border-danger: var(--color-support-danger-500);
        --color-border-default: var(--color-neutral-600);
        --color-border-focus-error: var(--color-support-danger-alpha-200);
        --color-border-focus-primary: var(--color-primary-brand-alpha-200);
        --color-border-muted: var(--color-neutral-700);
        --color-border-primary: var(--color-primary-brand-500);
        --color-border-success: var(--color-support-success-500);
        --color-border-warning: var(--color-support-warning-500);
        --color-button-primary-bg-default: var(--color-primary-brand-500);
        --color-button-primary-bg-disabled: var(--color-primary-brand-700);
        --color-button-primary-bg-hover: var(--color-primary-brand-400);
        --color-button-primary-fg-default: var(--color-neutral-white);
        --color-button-primary-fg-disabled: var(
          --color-neutral-alpha-light-200
        );
        --color-button-secondary-bg: var(--color-neutral-800);
        --color-button-secondary-border: var(--color-neutral-600);
        --color-button-secondary-fg: var(--color-neutral-white);
        --color-fg-accent-1: var(--color-accent-magenta-500);
        --color-fg-accent-2: var(--color-accent-lime-500);
        --color-fg-accent-3: var(--color-accent-rust-500);
        --color-fg-danger: var(--color-support-danger-400);
        --color-fg-default: var(--color-neutral-white);
        --color-fg-muted: var(--color-neutral-300);
        --color-fg-onemphasis-default: var(--color-neutral-white);
        --color-fg-onemphasis-subtle: var(--color-neutral-alpha-light-300);
        --color-fg-primary: var(--color-primary-brand-400);
        --color-fg-subtle: var(--color-neutral-500);
        --color-fg-success: var(--color-support-success-400);
        --color-fg-warning: var(--color-support-warning-400);
        --color-input-bg: var(--color-neutral-700);
        --color-input-border-default: var(--color-border-default);
        --color-input-border-hover: var(--color-neutral-500);
        --color-input-fg-input: var(--color-fg-default);
        --color-input-fg-placeholder: var(--color-fg-subtle);
        --color-nav-bar-bg-selected: var(--color-neutral-700);
        --color-notebook-cell-bg-cell-selected: var(
          --color-primary-brand-alpha-100
        );
        --color-notebook-cell-bg-default: var(--color-neutral-800);
        --color-notebook-cell-bg-emphasis-neutral: var(--color-neutral-600);
        --color-notebook-cell-bg-query: var(--color-neutral-700);
        --color-notebook-cell-bg-text-highlighted: var(
          --color-support-warning-alpha-200
        );
        --color-notebook-cell-bg-text-selected: var(
          --color-primary-brand-alpha-200
        );
        --color-tab-bg-default: var(--color-neutral-alpha-light-50);
        --color-tab-bg-selected: var(--color-neutral-700);
        --color-tab-border-selected: var(--color-neutral-600);
        --color-tab-text-default: var(--color-fg-muted);
        --color-tab-text-selected: var(--color-fg-default);
        --color-tag-bg: var(--color-primary-brand-alpha-300);
        --color-tag-fg-default: var(--color-primary-brand-100);
        --color-tag-fg-placeholder: var(--color-primary-brand-400);
      }

      body[data-theme="light"] {
        --color-bg-default: var(--color-neutral-white);
        --color-bg-subtle: var(--color-neutral-50);
        --color-bg-hover: var(--color-neutral-100);
        --color-bg-hover-primary: var(--color-primary-brand-alpha-100);
        --color-bg-disabled: var(--color-neutral-100);
        --color-bg-disabled-overlay: var(--color-neutral-alpha-light-300);
        --color-bg-accent-1: var(--color-accent-magenta-100);
        --color-bg-accent-2: var(--color-accent-lime-100);
        --color-bg-accent-3: var(--color-accent-rust-100);
        --color-bg-elevated-default: var(--color-bg-default);
        --color-bg-elevated-hover: var(--color-bg-hover);
        --color-bg-emphasis-neutral: var(--color-neutral-800);
        --color-bg-emphasis-neutral-subtle: var(--color-neutral-alpha-dark-50);
        --color-bg-emphasis-primary: var(--color-primary-brand-500);
        --color-bg-emphasis-primary-subtle: var(
          --color-primary-brand-alpha-100
        );
        --color-bg-danger: var(--color-support-danger-50);
        --color-bg-success: var(--color-support-success-50);
        --color-bg-warning: var(--color-support-warning-50);
        --color-fg-default: var(--color-neutral-black);
        --color-fg-muted: var(--color-neutral-500);
        --color-fg-subtle: var(--color-neutral-300);
        --color-fg-primary: var(--color-primary-brand-500);
        --color-fg-accent-1: var(--color-accent-magenta-500);
        --color-fg-accent-2: var(--color-accent-lime-500);
        --color-fg-accent-3: var(--color-accent-rust-500);
        --color-fg-onemphasis-default: var(--color-neutral-white);
        --color-fg-onemphasis-subtle: var(--color-neutral-alpha-light-300);
        --color-fg-danger: var(--color-support-danger-600);
        --color-fg-success: var(--color-support-success-600);
        --color-fg-warning: var(--color-support-warning-600);
        --color-border-default: var(--color-neutral-200);
        --color-border-muted: var(--color-neutral-100);
        --color-button-primary-bg-default: var(--color-primary-brand-500);
        --color-button-primary-bg-hover: var(--color-primary-brand-600);
        --color-button-primary-bg-disabled: var(--color-primary-brand-200);
        --color-border-primary: var(--color-primary-brand-500);
        --color-button-primary-fg-default: var(--color-neutral-white);
        --color-button-primary-fg-disabled: var(
          --color-button-primary-fg-default
        );
        --color-button-secondary-bg: var(--color-neutral-white);
        --color-button-secondary-fg: var(--color-neutral-900);
        --color-button-secondary-border: var(--color-neutral-200);
        --color-notebook-cell-bg-default: var(--color-neutral-white);
        --color-input-bg: var(--color-neutral-white);
        --color-input-fg-input: var(--color-fg-default);
        --color-input-fg-placeholder: var(--color-fg-subtle);
        --color-input-border-default: var(--color-border-default);
        --color-tag-fg-default: var(--color-fg-primary);
        --color-tag-fg-placeholder: var(--color-primary-brand-alpha-300);
        --color-tag-bg: var(--color-primary-brand-alpha-50);
        --color-border-danger: var(--color-support-danger-500);
        --color-border-success: var(--color-support-success-500);
        --color-border-warning: var(--color-support-warning-500);
        --color-notebook-cell-bg-query: var(--color-neutral-50);
        --color-notebook-cell-bg-emphasis-neutral: var(--color-neutral-100);
        --color-notebook-cell-bg-text-selected: var(
          --color-primary-brand-alpha-100
        );
        --color-notebook-cell-bg-cell-selected: var(
          --color-primary-brand-alpha-50
        );
        --color-notebook-cell-bg-text-highlighted: var(
          --color-support-warning-100
        );
        --color-tab-bg-default: var(--color-neutral-alpha-dark-25);
        --color-nav-bar-bg-selected: var(--color-neutral-white);
        --color-tab-bg-selected: var(--color-neutral-white);
        --color-tab-text-selected: var(--color-fg-default);
        --color-tab-text-default: var(--color-fg-muted);
        --color-tab-border-selected: var(--color-neutral-100);
        --color-border-focus-primary: var(--color-primary-brand-alpha-100);
        --color-input-border-hover: var(--color-neutral-300);
        --color-border-focus-error: var(--color-support-danger-alpha-100);
      }
    }
  `,
);

export const LinkStyle = css`
  color: ${({ theme }) => theme.color.fg.primary};
  cursor: pointer;
  text-decoration: underline;
`;
