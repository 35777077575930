import { IconButton } from "@fiberplane/ui";
import { css, styled } from "styled-components";

import { useCopyToClipboard } from "../../hooks";
import { codePreStyling, preStyling } from "./codeStyling";

type Props = {
  code: string;
  onCopy?: () => void;
};

/**
 * Component for rending a code sample in the UI.
 * Uses similar styles to the CodeCell in the editor.
 *
 * @param [onCopy=Function] - Function to execute after copying text (e.g., to fire tracking event)
 */
export function CodeSample({ code, onCopy }: Props) {
  const { isCopied, handleCopy: copyToClipboard } = useCopyToClipboard(code);

  const handleCopy = () => {
    // If we recently copied the text, don't do anything
    if (isCopied) {
      return;
    }

    copyToClipboard();

    if (onCopy) {
      onCopy();
    }
  };

  return (
    <Container>
      <CodeActionBar>
        <CopyCodeButton
          iconType={isCopied ? "check" : "copy"}
          aria-label={isCopied ? undefined : "Click to copy code block"}
          data-no-tooltip="true"
          disabled={isCopied}
          onClick={handleCopy}
        />
      </CodeActionBar>
      <CodeContainer>
        <pre>
          <code>{code}</code>
        </pre>
      </CodeContainer>
    </Container>
  );
}

const Container = styled.div(
  ({ theme }) => css`
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    position: relative;
    background: ${theme.colorBase100};
    border: 1px solid ${theme.colorBase300};
    border-radius: ${theme.borderRadius400};
    padding: ${theme.spacingParagraph};
    margin: ${theme.spacingParagraph};
  `,
);

const CodeContainer = styled.div`
  ${/* sc-declaration */ preStyling}
  ${/* sc-declaration */ codePreStyling}
  overflow-x: scroll;
`;

const CodeActionBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
`;

// NOTE - Uses a transparent background on hover to accommodate styling (looks weird otherwise)
const CopyCodeButton = styled(IconButton).attrs({
  buttonSize: "small",
})`
  z-index: 1;

  &:hover {
    background-color: transparent;
    cursor: pointer;
  }
`;
