import { INVISIBLE_CHARACTER } from "../../constants";

/**
 * Cleans up the value by removing the invisible character and the trailing newline.
 */
export function sanitizeText(value: string) {
  const endText = "\n";
  let updatedValue = value.endsWith(endText)
    ? value.slice(0, -endText.length)
    : value;
  updatedValue = updatedValue.replace(INVISIBLE_CHARACTER, "");
  return updatedValue;
}

export function updateBrowserCursorPosition(
  node: Node,
  start: number | undefined,
  end: number,
) {
  const selection = document.getSelection() || new Selection();
  selection.setBaseAndExtent(node, start ?? end, node, end);
}
