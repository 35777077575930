import styled, { css } from "styled-components";

export const BetaBadge = styled.span.attrs({ children: "BETA" })(
  ({ theme }) => css`
    border-radius: ${theme.radius.rounded};
    background-color: ${theme.color.tag.bg};
    color: ${theme.color.tag.fg};
    font: ${theme.font.headings.h6};
    font-weight: 600;
    padding: 2px 6px;
  `,
);
