import { LABEL_SPLIT_CHARACTER } from "../constants";
import type { ApiEvent, ClientLabelValidationError, Label } from "../types";
import { compact } from "./compact";

export const REPLACE_LABEL_WARNING_MODAL_KEY =
  "labels/replaceInsteadOfUpdateWarning";

const prefixRegex =
  /^[\da-z]([\da-z-]*[\da-z])?(\.[\da-z]([\da-z-]*[\da-z])?)*$/;

const valueRegex = /^[\dA-Za-z]([\w.-]*[\dA-Za-z])?$/;

function validateKey(key: string): Array<ClientLabelValidationError> {
  const errors: Array<ClientLabelValidationError> = [];

  if (key.length === 0) {
    return ["empty_key"];
  }

  let prefix = "";
  let name = key;
  // If the key contains a prefix character, split it into prefix and name
  const splitIndex = key.indexOf("/");
  if (splitIndex > -1) {
    prefix = key.slice(0, splitIndex);
    name = key.slice(splitIndex + 1);
  }

  // Prefix is not required but has length and character validation
  if (prefix.length > 0) {
    if (prefix.length > 253) {
      errors.push("prefix_too_long");
    }

    if (!prefixRegex.test(prefix)) {
      errors.push("prefix_invalid_characters");
    }
  } else if (splitIndex > -1) {
    // Prefix character exists but prefix is empty
    errors.push("empty_prefix");
  }

  // Name is required and has a max length
  if (name.length === 0) {
    errors.push("empty_name");
  } else if (name.length > 63) {
    errors.push("name_too_long");
  }

  // Only verify characters if the name has a value
  if (name !== "" && !valueRegex.test(name)) {
    errors.push("name_invalid_characters");
  }

  return errors;
}

function validateValue(value: string): Array<ClientLabelValidationError> {
  const errors: Array<ClientLabelValidationError> = [];

  // Value is not required
  if (value.length === 0) {
    return [];
  }

  if (value.length > 63) {
    errors.push("value_too_long");
  }

  if (!valueRegex.test(value)) {
    errors.push("value_invalid_characters");
  }

  return errors;
}

// We're using k8's validation rules for labels, see:
// https://kubernetes.io/docs/concepts/overview/working-with-objects/labels/#syntax-and-character-set
/**
 * Validates a label and returns an array of errors.
 */
export function validateLabel(
  label: Label,
  existingKeys: ReadonlyArray<string> = [],
): Array<ClientLabelValidationError> {
  return compact([
    existingKeys.includes(label.key) ? "duplicate_key" : null,
    ...validateKey(label.key),
    ...(label.value ? validateValue(label.value) : []),
  ]);
}

export function formatLabel(label: Label): string {
  const { key, value } = label;
  return `${key}${value ? `${LABEL_SPLIT_CHARACTER}${value}` : ""}`;
}

export function parseLabel(content: string): Label {
  let key = content;
  let value = "";

  const splitIndex = content.indexOf(LABEL_SPLIT_CHARACTER);
  if (splitIndex > -1) {
    key = content.slice(0, splitIndex);
    value = content.slice(splitIndex + 1);
  }

  return {
    key,
    value,
  };
}

/**
 * Parses a comma-separated string of labels into an object.
 */
export function parseLabels(labels: string): { [key: string]: string } {
  if (labels === "") {
    return {};
  }

  return labels
    .split(" ")
    .map(parseLabel)
    .reduce(
      (labels, { key, value }) => {
        labels[key] = value;
        return labels;
      },
      {} as { [key: string]: string },
    );
}

/**
 * Converts labels from the API format to the format that LabelsEditor expects
 */
export function convertApiLabelToClientLabel(
  labels: Readonly<ApiEvent["labels"]>,
): Array<Label> {
  return Object.entries(labels).map(([key, value]) => ({
    key,
    value: value ?? "",
  }));
}
