const PROMQL_WRITER_HOST =
  "https://whecczaqormdgfn4ysdkd4clby0ycylq.lambda-url.us-west-2.on.aws";

export function promqlWriter(prompt: string) {
  return fetch(`${PROMQL_WRITER_HOST}/api/generate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    body: JSON.stringify({ prompt }),
  });
}

export async function processStreamingResponse(
  response: Response,
  {
    onChunk,
    onDone,
  }: {
    onChunk: (chunk: string) => void;
    onDone: () => void;
  },
) {
  // Response Body is a ReadableStream. See https://developer.mozilla.org/en-US/docs/Web/API/ReadableStream
  const reader = response.body?.getReader();
  const decoder = new TextDecoder();

  // Process the chunks from the stream
  while (true) {
    if (!reader) {
      break;
    }
    const { done, value } = await reader.read();
    if (done) {
      onDone();
      break;
    }
    const text = decoder.decode(value);
    onChunk(text);
  }
}
