import type {
  Blob,
  Cell,
  ConfigSchema,
  DataSource,
  Provider,
  ProviderError,
  ProviderRequest,
  Result,
  SupportedQueryType,
} from "../../types";
import { unwrap } from "../../utils";
import { createRuntime } from "./providerRuntime";
// biome-ignore lint/nursery/useImportRestrictions: generated file
import { imports } from "./providerRuntime/imports";

export function createProviderWithUrl(providerUrl: string): Provider {
  let provider: Promise<ProviderImpl> | null = null;

  return {
    async getConfigSchema(): Promise<ConfigSchema> {
      provider ??= loadProvider(providerUrl);

      const loadedProvider = await provider;
      const { getConfigSchema } = loadedProvider;
      if (!getConfigSchema) {
        throw new Error("Provider did not implement `get_config_schema()`");
      }

      return getConfigSchema();
    },

    async getSupportedQueryTypes(
      dataSource: DataSource,
    ): Promise<Array<SupportedQueryType>> {
      provider ??= loadProvider(providerUrl);

      const loadedProvider = await provider;
      return loadedProvider.getSupportedQueryTypes(dataSource);
    },

    async invoke(
      request: ProviderRequest,
    ): Promise<Result<Blob, ProviderError>> {
      provider ??= loadProvider(providerUrl);

      const loadedProvider = await provider;
      return loadedProvider.invoke2(request);
    },

    async createCells(queryType: string, response: Blob): Promise<Array<Cell>> {
      provider ??= loadProvider(providerUrl);

      const loadedProvider = await provider;
      const { createCells } = loadedProvider;
      if (!createCells) {
        throw new Error("Provider did not implement `create_cells()`");
      }

      return unwrap(createCells(queryType, response));
    },

    async extractData(
      response: Blob,
      mimeType: string,
      query: string | null,
    ): Promise<Result<Blob, ProviderError>> {
      provider ??= loadProvider(providerUrl);

      const loadedProvider = await provider;
      const { extractData } = loadedProvider;
      if (!extractData) {
        // biome-ignore lint/style/useNamingConvention: the Result concept comes from rust and follows that naming convention
        return { Err: { type: "unsupported_request" } };
      }

      return extractData(response, mimeType, query);
    },
  };
}

type ProviderImpl = Awaited<ReturnType<typeof loadProvider>>;

async function loadProvider(url: string) {
  const runtime = await createRuntime(fetch(url), imports);

  const {
    createCells,
    getConfigSchema,
    getSupportedQueryTypes,
    invoke2,
    invoke2Raw,
    extractData,
    extractDataRaw,
  } = runtime;
  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version (which is less readable)
  if (!getSupportedQueryTypes || !invoke2 || !invoke2Raw) {
    throw new Error("Provider did not provide expected exports");
  }

  return {
    createCells,
    getConfigSchema,
    getSupportedQueryTypes,
    invoke2,
    invoke2Raw,
    extractData,
    extractDataRaw,
  };
}
