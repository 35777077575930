// biome-ignore lint/correctness/noUnusedImports: Required for jest
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { css, styled } from "styled-components";

import {
  selectCurrentUser,
  selectNotebookSubscriberUserIds,
} from "../../../../selectors";
import { ProfileAvatar } from "../../../Avatar";
import type { ExtendedAnnotation } from "../types";
import type { EditorNode, KeyedEditorNode } from "./EditorNode";
import { EntityEditorNode, ICON_SIZE } from "./EntityEditorNode";
import type { RenderableAnnotation } from "./RenderableAnnotation";

type Props = EditorNode & {
  userId: string;
  name: string;
};

export const MentionEditorNode = memo(function MentionEditorNode({
  startOffset,
  endOffset,
  userId,
  name,
}: Props) {
  const currentUser = useSelector(selectCurrentUser);
  const subscriberUserIds = useSelector(selectNotebookSubscriberUserIds);

  const isCurrentUser = currentUser?.id === userId;

  return (
    <MentionContainer
      data-start-offset={startOffset}
      data-end-offset={endOffset}
      data-is-online={isCurrentUser || subscriberUserIds.includes(userId)}
      data-is-current-user={isCurrentUser}
      data-user-id={userId}
      icon={
        <ProfileAvatar
          name={name}
          userId={userId}
          size={ICON_SIZE}
          showTooltip={false}
        />
      }
    >
      {name}
    </MentionContainer>
  );
});

type MentionAnnotation = ExtendedAnnotation & { type: "mention" };

export const RENDERABLE_MENTION: RenderableAnnotation<MentionAnnotation> = {
  supports: (annotation: ExtendedAnnotation) => annotation.type === "mention",
  getPlainText: (annotation: MentionAnnotation) => {
    return `@${annotation.name}`;
  },
  getElement: (annotation: MentionAnnotation, props: KeyedEditorNode) => {
    const { userId, name } = annotation;

    return <MentionEditorNode {...props} name={name} userId={userId} />;
  },
};

const MentionContainer = styled(EntityEditorNode)(
  ({ theme }) => css`
    transition: color 0.125s;

    &[data-is-online="false"] {
      color: ${theme.colorPrimaryAlpha300};
    }

    &[data-is-current-user="true"] {
      background: ${theme.colorSecondarySecondary100};
      color: ${theme.colorSecondary500};
    }
  `,
);
