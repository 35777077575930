import equal from "fast-deep-equal";

import {
  type CourierAction,
  incomingMessage,
  setConnectionStatus,
  withNotebook,
} from "../actions";
import {
  selectAllNotebooks,
  selectAuthentication,
  selectIsAuthenticated,
  selectNotebook,
} from "../selectors";
import type { Thunk } from "../store";
import type { ClientRealtimeMessage, NotebookFocus, Operation } from "../types";
import { only } from "../utils";
import { fixScrollPosition } from "./scrollPositionThunks";

const MAX_DISCONNECT_DELAY = 5000; // ms

let broadcastChannel: BroadcastChannel | null = null;

const authOpId = "a0";
let opCounter = 1;

let reconnectTimer: ReturnType<typeof setTimeout> | null = null;

let socket: WebSocket | null = null;

export const authenticateCourier = (): Thunk => (dispatch) => {
  if (socket?.readyState === WebSocket.OPEN) {
    dispatch(sendAuthenticate());
  } else {
    dispatch(connect());
  }
};

/**
 * Connects to the WS server and either subscribes to related notebook or workspace
 */
const connect = (): Thunk => (dispatch) => {
  if (reconnectTimer) {
    clearTimeout(reconnectTimer);
    reconnectTimer = null;
  }

  switch (socket?.readyState) {
    case WebSocket.CONNECTING:
    case WebSocket.OPEN:
      return; // We're already connecting or connected.
  }

  let newSocket: WebSocket;
  try {
    const { location } = document;
    const wsUrl = `${location.protocol.replace("http", "ws")}//${
      location.host
    }/api/ws`;

    newSocket = new WebSocket(wsUrl);
  } catch (error) {
    // biome-ignore lint/suspicious/noConsoleLog: seems appropriate here
    console.log("Exception trying to open WebSocket", error);
    dispatch(setConnectionStatus("disconnected"));
    return;
  }

  const onClose = (event: CloseEvent) => dispatch(handleClose(event));
  const onMessage = (event: MessageEvent<string>) => {
    const { data } = event;

    let action: CourierAction | Thunk = incomingMessage(data);
    // Don't attempt to fix our scroll position if we can quickly (and cheaply)
    // determine the message is for an action that won't affect our scroll
    // position anyway:
    if (
      // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version (which is less readable)
      !data.startsWith('{"type":"ack"') &&
      !data.startsWith('{"type":"subscriber_')
    ) {
      action = fixScrollPosition(action);
    }

    dispatch(action);
  };
  const onOpen = () => dispatch(handleOpen());

  newSocket.addEventListener("open", onOpen);
  newSocket.addEventListener("message", onMessage);
  newSocket.addEventListener("error", onError);
  newSocket.addEventListener("close", onClose);
  removeEventListeners = () => {
    newSocket.removeEventListener("open", onOpen);
    newSocket.removeEventListener("message", onMessage);
    newSocket.removeEventListener("error", onError);
    newSocket.removeEventListener("close", onClose);
  };

  socket = newSocket;

  dispatch(setConnectionStatus("connecting"));
};

/**
 * Disconnects from the WS server.
 */
export const disconnect = (): Thunk => (dispatch, getState) => {
  const disconnectedSocket = socket;
  if (!disconnectedSocket) {
    return;
  }

  const removeEventListenersFromDisconnectedSocket = removeEventListeners;
  removeEventListeners = undefined;

  // Immediately nullify the socket, so we cannot use it anymore
  // to send new operations:
  socket = null;

  const isIdle = !selectIsAuthenticated(getState());
  dispatch(setConnectionStatus(isIdle ? "idle" : "disconnected"));

  // Delay the actual closing of the socket until the pending operations are
  // processed:
  let waitTime = 0;
  const waitForPendingOperationOrDisconnect = () => {
    if (
      waitTime < MAX_DISCONNECT_DELAY &&
      selectAllNotebooks(getState()).some(
        (notebook) => notebook.numPendingOperations > 0,
      )
    ) {
      const increment = 100;
      setTimeout(() => {
        waitTime += increment;
        waitForPendingOperationOrDisconnect();
      }, increment);
    } else {
      removeEventListenersFromDisconnectedSocket?.();
      disconnectedSocket.close();
    }
  };

  waitForPendingOperationOrDisconnect();
};

const handleClose =
  (event: CloseEvent): Thunk =>
  (dispatch, getState) => {
    // biome-ignore lint/suspicious/noConsoleLog: seems appropriate here
    console.log(`Socket closed (reason: ${event.reason}, code: ${event.code})`);

    removeEventListeners?.();
    socket = null;

    const isIdle = !selectIsAuthenticated(getState());
    dispatch(setConnectionStatus(isIdle ? "idle" : "disconnected"));
  };

const handleOpen = (): Thunk => (dispatch, getState) => {
  dispatch(sendAuthenticate());

  const state = getState();
  for (const { id, confirmedRevision, loading } of selectAllNotebooks(state)) {
    if (!loading) {
      sendNotebookSubscribe(id, confirmedRevision);
    }
  }
};

export const initBroadcastChannel = (): Thunk => (dispatch) => {
  // We need feature detection because of Safari, which doesn't support these yet:
  broadcastChannel = window.BroadcastChannel
    ? new BroadcastChannel("studio")
    : null;
  if (broadcastChannel) {
    broadcastChannel.addEventListener("message", (event) => {
      dispatch(incomingMessage(event.data));
    });
  }
};

const onError = () => {
  // biome-ignore lint/suspicious/noConsoleLog: seems appropriate here
  console.log("Unexpected socket error");
};

/**
 * Triggers an immediate reconnect if we're currently disconnected.
 */
export const reconnect = (): Thunk => (dispatch) => {
  if (!socket) {
    dispatch(connect());
  }
};

/**
 * Initiates a reconnect while respecting the backoff rules.
 */
export const reconnectWithBackoff =
  (backoff: number): Thunk =>
  (dispatch) => {
    if (reconnectTimer) {
      clearTimeout(reconnectTimer);
    }

    reconnectTimer = setTimeout(
      () => dispatch(reconnect()),
      // Randomize the exact delay to prevent waves of clients trying to
      // reconnect at the same time:
      (Math.random() + 0.5) * backoff,
    );
  };

let removeEventListeners: (() => void) | undefined;

const sendAuthenticate = (): Thunk => (_dispatch, getState) => {
  const { token } = selectAuthentication(getState());
  if (token) {
    sendMessage({ type: "authenticate", token, opId: authOpId });
  }
};

let previousFocusInfo: [string, NotebookFocus] | null = null;

export const sendFocusInfo =
  (notebookId: string, focus: NotebookFocus): Thunk =>
  () => {
    if (socket?.readyState !== WebSocket.OPEN) {
      return;
    }

    if (
      previousFocusInfo &&
      previousFocusInfo[0] === notebookId &&
      equal(previousFocusInfo[1], focus)
    ) {
      // No need to send the same info again.
      return;
    }

    sendMessage({
      type: "focus_info",
      focus,
      notebookId,
      opId: `f${opCounter}`,
    });

    opCounter++;
    previousFocusInfo = [notebookId, focus];
  };

const sendMessage = (message: ClientRealtimeMessage) => {
  if (socket?.readyState !== WebSocket.OPEN) {
    throw new Error("WebSocket not open");
  }

  socket.send(JSON.stringify(message));
};

/**
 * Sends an operation over whatever connection we have available.
 */
export const sendOperations =
  (notebookId: string, revision: number, operations: Array<Operation>): Thunk =>
  (dispatch) => {
    const opId = `op${opCounter}`;
    opCounter++;

    const onlyOperation = only(operations);
    const message: ClientRealtimeMessage = onlyOperation
      ? {
          type: "apply_operation",
          notebookId,
          operation: onlyOperation,
          revision,
          opId,
        }
      : {
          type: "apply_operation_batch",
          notebookId,
          operations,
          revision,
          opId,
        };

    if (socket?.readyState === WebSocket.OPEN) {
      sendMessage(message);

      dispatch(
        withNotebook(notebookId, {
          type: "mark_pending_operations_sent",
          payload: { opId, revisions: operations.map((_, i) => revision + i) },
        }),
      );
    } else {
      broadcastChannel?.postMessage(JSON.stringify(message));
    }
  };

function sendNotebookSubscribe(notebookId: string, revision?: number) {
  sendMessage({
    type: "subscribe",
    notebookId,
    revision,
    opId: `s${opCounter}`,
  });
  opCounter++;
}

/**
 * Subscribes to a notebook.
 */
export const subscribeToNotebook =
  (notebookId: string): Thunk =>
  (dispatch, getState) => {
    if (socket?.readyState === WebSocket.OPEN) {
      sendNotebookSubscribe(
        notebookId,
        selectNotebook(getState(), notebookId).confirmedRevision,
      );
    } else {
      dispatch(connect());
    }
  };

function sendWorkspaceSubscribe(workspaceId: string) {
  sendMessage({
    type: "subscribe_workspace",
    workspaceId,
    opId: `s${opCounter}`,
  });
  opCounter++;
}

export const subscribeToWorkspace =
  (workspaceId: string): Thunk =>
  (dispatch) => {
    if (socket?.readyState === WebSocket.OPEN) {
      sendWorkspaceSubscribe(workspaceId);
    } else {
      dispatch(connect());
    }
  };

export const unsubscribeFromNotebook =
  (notebookId: string): Thunk =>
  () => {
    if (socket?.readyState === WebSocket.OPEN) {
      sendMessage({ type: "unsubscribe", notebookId });
    }
  };

export const unsubscribeFromWorkspace =
  (workspaceId: string): Thunk =>
  () => {
    if (socket?.readyState === WebSocket.OPEN) {
      sendMessage({ type: "unsubscribe_workspace", workspaceId });
    }
  };

export const sendUserTyping =
  (notebookId: string, threadId: string): Thunk =>
  () => {
    sendMessage({ type: "user_typing_comment", notebookId, threadId });
  };
