import Prism from "prismjs";

import { Pre } from "./Pre";

export function Headers({ headers }: { headers: string }) {
  const grammar = Prism.languages.yaml;

  if (!grammar) {
    return <Pre>{headers}</Pre>;
  }

  const __html = Prism.highlight(headers, grammar, "yaml");

  // biome-ignore lint/security/noDangerouslySetInnerHtml: In Prism we trust
  return <Pre dangerouslySetInnerHTML={{ __html }} />;
}
