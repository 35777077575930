import { useContext } from "react";

import { setCommandMenuVariant } from "../../../../actions";
import { dispatch } from "../../../../store";
import { CommandMenuContext } from "../CommandMenu";
import { BreadcrumbIconButton, BreadcrumbLabel } from "./BreadcrumbLabel";

type VariantBreadcrumbProps = {
  children: React.ReactNode;
};

export function VariantBreadcrumb({ children }: VariantBreadcrumbProps) {
  const { focusInput } = useContext(CommandMenuContext);

  return (
    <BreadcrumbLabel>
      <BreadcrumbIconButton
        iconType="caret_left"
        onClick={() => {
          dispatch(setCommandMenuVariant({ type: "none" }));
          focusInput();
        }}
        aria-label="View all commands"
        data-tooltip-placement="left"
      />
      {children}
    </BreadcrumbLabel>
  );
}
