import {
  ModalErrorHint,
  ModalLabel,
  ModalLabelText,
  ModalSelectInput,
} from "../../../UI";
import {
  type ValidationStatus,
  ValidationStatusIcon,
} from "../ValidationStatusIcon";

type Props = {
  autoFocus?: boolean;
  label?: string;
  name: string;
  onChange: (value: string) => void;
  options: Array<string>;
  placeholder?: string;
  required: boolean;
  validationStatus: ValidationStatus;
  value?: unknown;
};

export function SelectField({
  autoFocus,
  label,
  name,
  onChange,
  options,
  placeholder,
  required,
  validationStatus,
  value,
}: Props): JSX.Element {
  const hasValidValue = typeof value === "string" && options.includes(value);
  const showValidationError =
    required && validationStatus === "invalid" && !hasValidValue;

  return (
    <ModalLabel>
      {label && <ModalLabelText>{label}</ModalLabelText>}
      <ModalSelectInput
        aria-invalid={showValidationError}
        autoFocus={autoFocus}
        data-config-field={name}
        dropdownPlacement="bottom-end"
        name={`config-field-${name}`}
        onChange={onChange}
        options={options.map((option) => ({ children: option, value: option }))}
        placeholder={placeholder}
        trailingIcon={
          showValidationError
            ? () => (
                <ValidationStatusIcon
                  containerStyle={validationStatusIconContainerStyle}
                  status={validationStatus}
                />
              )
            : undefined
        }
        value={hasValidValue ? value : undefined}
      />
      {showValidationError && (
        <ModalErrorHint>This is a required field</ModalErrorHint>
      )}
    </ModalLabel>
  );
}

const validationStatusIconContainerStyle: React.CSSProperties = {
  position: "relative",
  right: 0,
};
