import styled, { css } from "styled-components";

const activeMenuStyles = css(
  ({ theme }) => css`
    color: ${theme.color.fg.default};
    box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 8%);

    &&,
    &:hover {
      background-color: ${theme.color.nav.bar.bg.selected};
    }
  `,
);

/**
 * Component that represents standard styles for a menu item. Its active state
 * can be set with either the `.active` class or the `$isActive` prop.
 */
export const NavElement = styled.div<{ $isActive?: boolean }>(
  ({ $isActive, theme }) => css`
    /* Reset styles for cases where the component has the as="button" prop */
    &:is(button) {
      cursor: pointer;
      background: unset;
      outline: unset;
      border: unset;
      padding: 0;
    }

    font: ${theme.font.headings.h5};
    color: ${theme.color.fg.muted};
    border-radius: ${theme.radius.default};
    /* From Button; add to theme */
    transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out,
      box-shadow 0.1s ease-in-out 0.05s;
    cursor: pointer;

    &:hover {
      color: ${theme.color.fg.default};
      background-color: ${theme.color.bg.hover};
    }

    ${$isActive && activeMenuStyles}

    &.active {
      ${activeMenuStyles}
    }
  `,
);

const navButtonActiveStyles = css`
  &:before {
    opacity: 1;
    height: ${({ theme }) => theme.height.small};
  }
`;

export const NavButton = styled(NavElement)(
  ({ $isActive, theme }) => css`
    display: grid;
    place-content: center;
    aspect-ratio: 1;
    height: 36px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      translate: 0 -50%;
      left: -16px;
      width: 4px;
      height: 8px;
      opacity: 0;
      background-color: ${theme.color.fg.primary};
      border-radius: 0 ${theme.radius.lowest} ${theme.radius.lowest} 0;
      transition: opacity 0.2s ease-in-out, height 0.2s ease-in-out;
    }

    &.active {
      ${navButtonActiveStyles}
    }

    ${
      !$isActive &&
      css`
        &:not(&.active):hover:before {
          opacity: 1;
          height: 10px;
        }
      `
    }

    ${$isActive && navButtonActiveStyles}
  `,
);
