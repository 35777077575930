import { useCallback } from "react";
import { isValidOption } from "../../../../../../selectors";
import type {
  FrontMatterStringValue,
  SafeFrontMatterValue,
} from "../../../../../../types";
import { isArrayOfType } from "../../../../../../utils";
import { useFrontMatterValue } from "../../../hooks";

export function useStringValuesFromStore(
  id: string,
  options: Array<FrontMatterStringValue>,
) {
  type ValidValues = (typeof options)[number];
  const validator = useCallback(
    (values: SafeFrontMatterValue): values is Array<ValidValues> =>
      isArrayOfType(values, (value): value is ValidValues =>
        isValidOption(value, options),
      ),
    [options],
  );
  const values = useFrontMatterValue<Array<string>>(id, validator) ?? [];
  return values;
}
