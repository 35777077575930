import type { Trigger } from "../state";

export const FETCH_TRIGGERS_PENDING = "triggers/fetch/PENDING";
export const FETCH_TRIGGERS_FULFILLED = "triggers/fetch/FULFILLED";
export const FETCH_TRIGGERS_REJECTED = "triggers/fetch/REJECTED";

export type FetchTriggersPendingAction = {
  type: typeof FETCH_TRIGGERS_PENDING;
};

export type FetchTriggersFulFilledAction = {
  type: typeof FETCH_TRIGGERS_FULFILLED;
  payload: Array<Trigger>;
};

export type FetchTriggersRejectedAction = {
  type: typeof FETCH_TRIGGERS_REJECTED;
  payload: Error;
};

export type TriggerAction =
  | FetchTriggersPendingAction
  | FetchTriggersFulFilledAction
  | FetchTriggersRejectedAction;
