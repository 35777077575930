import { css, styled } from "styled-components";

import { formatProviderType } from "../../../../utils";

type Props = { type: string } & React.HTMLAttributes<HTMLDivElement>;

export function DataSourceSelectorGroupTitle({
  type,
  children,
  ...props
}: Props): JSX.Element {
  return (
    <GroupTitleContainer {...props}>
      <GroupTitleText>{formatProviderType(type)}</GroupTitleText>
      {children}
    </GroupTitleContainer>
  );
}

const GroupTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  justify-content: stretch;
  align-items: center;
  margin: 0 12px;
`;

const GroupTitleText = styled.span(
  ({ theme }) => css`
    flex: 1;
    color: ${theme.color.fg.muted};
    font: ${theme.font.headings.h5};
  `,
);
