import { useEffect, useRef, useState } from "react";
import { css, styled } from "styled-components";

import { stopPropagation } from "@fiberplane/ui";
import { formatTableRowValueId } from "../../../utils";
import { TableColumnMenu } from "./TableColumnMenu";
import { headingRowMarker } from "./constants";
import { focusField } from "./utils";

type Props = {
  cellId: string;
  children: React.ReactNode;
  columnId: string;
  firstColumn: boolean;
  hovered: boolean;
  onHover: () => void;
  selected: boolean;
};

export function TableColumnHeading({
  cellId,
  children,
  columnId,
  firstColumn,
  hovered,
  onHover,
  selected,
}: Props): JSX.Element {
  const ref = useRef<HTMLDivElement | null>(null);
  const [menuOpened, setMenuOpened] = useState(false);

  useEffect(() => setMenuOpened(selected), [selected]);

  const onClick = () => {
    if (selected) {
      setMenuOpened(!menuOpened);
    } else {
      focusField(cellId, formatTableRowValueId(headingRowMarker, columnId));
    }
  };

  return (
    <StyledTableColumnHeading
      firstColumn={firstColumn}
      hovered={hovered}
      onClick={onClick}
      onMouseDown={stopPropagation}
      onMouseEnter={onHover}
      ref={ref}
      selected={selected}
    >
      {children}
      {menuOpened && ref.current && (
        <TableColumnMenu
          cellId={cellId}
          columnId={columnId}
          element={ref.current}
          onClose={() => setMenuOpened(false)}
        />
      )}
    </StyledTableColumnHeading>
  );
}

const StyledTableColumnHeading = styled.div<{
  firstColumn: boolean;
  hovered: boolean;
  selected: boolean;
}>`
  ${({ firstColumn, hovered, selected, theme }) => css`
    grid-column: auto / span 1;
    color: ${selected ? theme.colorBackground : theme.colorBase800};
    cursor: pointer;
    font: ${theme.fontStudioStrongRegularShortHand};
    letter-spacing ${theme.fontStudioStrongRegularLetterSpacing};
    background: ${
      selected
        ? theme.colorPrimary400
        : hovered
          ? theme.colorBase200
          : theme.colorBase100
    };
    border-bottom: 2px solid ${theme.colorBase300};
    padding: 8px 16px 8px 16px;
    ${firstColumn ? "" : `border-left: 1px solid ${theme.colorBase300};`}
  `}

  &:empty {
    padding: 0;
  }
`;
