export const CLIPPED_NOTEBOOK_ICON_ID = "clipped_notebook_icon";

export function ClippedNotebookIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="84"
      height="96"
      viewBox="0 0 84 96"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <clipPath
          id={CLIPPED_NOTEBOOK_ICON_ID}
          clipPathUnits="objectBoundingBox"
        >
          <path
            d="M0,0.063 C0,0.028,0.032,0,0.071,0 H0.214 V1 H0.071 C0.032,1,0,0.972,0,0.938 V0.063 M0.857,0 C0.936,0,1,0.056,1,0.125 V0.875 C1,0.944,0.936,1,0.857,1 H0.321 V0 H0.857"
            fill="currentColor"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
