import { createContext } from "react";
import { noop } from "../../../utils";

type ItemType = Pick<DataTransferItem, "kind" | "type">;

export type FileDropContextType = {
  files: Array<ItemType>;
  setFiles(files: Array<ItemType>): void;
};

export const FileDropContext = createContext<FileDropContextType>({
  files: [],
  setFiles: noop,
});
