import { cancelEvent } from "@fiberplane/ui";
import { FRONT_MATTER_CELL_ID } from "../../../constants";
import {
  selectActiveLabelsEditorType,
  selectFocusedCellOrSurrogate,
  selectLabelsState,
  selectModalsMap,
} from "../../../selectors";
import { getState } from "../../../store";
import { getLabelInputElement, isMac } from "../../../utils";
import { LinkMenuItem } from "./LinkMenuItem";
import type { MenuProps } from "./Menu";
import { MenuItem } from "./MenuItem";
import { MenuItemWithPopup } from "./MenuItemWithPopup";
import { MenuItemWithSubMenu } from "./MenuItemWithSubMenu";
import type { Selection } from "./types";

export type MenuKeyboardListenerParams = Omit<MenuProps, "children"> & {
  items: Array<React.ReactElement>;
  menuRef: React.RefObject<HTMLElement | HTMLDivElement>;
  selection: Selection;
  setSelection: (selection: Selection) => void;
};

export const createKeyboardListener =
  (params: MenuKeyboardListenerParams) => (event: KeyboardEvent) => {
    const hasModal = selectModalsMap(getState()).size > 0;
    const { activeEditorType } = selectLabelsState(getState());
    if (hasModal && activeEditorType !== "event_modal") {
      return;
    }

    const { items, isSubmenu, onClose: close, selection } = params;

    const selectedItem = items.find(isSelected(selection));
    if (selection.autoOpen && canOpen(selectedItem?.type)) {
      return;
    }

    switch (event.code) {
      case "ArrowLeft": {
        if (isSubmenu) {
          cancelEvent(event);
          close({ reason: "close_requested" });
        }

        break;
      }
      case "ArrowRight": {
        handleArrowRight(params, event);
        break;
      }
      case "ArrowUp": {
        cancelEvent(event);
        moveSelection(params, -1);
        break;
      }
      case "ArrowDown": {
        cancelEvent(event);
        moveSelection(params, 1);
        break;
      }

      case "Backspace": {
        if (!isInInputElement(event)) {
          event.preventDefault();
          close({ reason: "close_requested" });
        }

        break;
      }
      case "Space": {
        handleSpace(params, event);
        break;
      }
      case "Tab":
      case "Enter": {
        handleConfirmItem(params, event, selectedItem);
        break;
      }
    }
  };

function canOpen(
  itemType: string | React.JSXElementConstructor<unknown> | undefined,
): boolean {
  return itemType === MenuItemWithSubMenu || itemType === MenuItemWithPopup;
}

function handleArrowRight(
  { grabsFocus = true, selection, setSelection }: MenuKeyboardListenerParams,
  event: KeyboardEvent,
) {
  if (isInInputElement(event)) {
    return; // Allow native behavior for right arrow when in input element
  }

  cancelEvent(event);

  setSelection({ ...selection, grabsFocus, autoOpen: true });
}

function handleConfirmItem(
  { menuRef, onClose: close }: MenuKeyboardListenerParams,
  event: KeyboardEvent,
  selectedItem: React.ReactElement | undefined,
) {
  const withModifier = isMac ? event.metaKey : event.ctrlKey;
  if (!selectedItem || withModifier) {
    return;
  }

  // HACK: Compensate for the lack of `onActivate()` on LinkMenuItem:
  const { type, props: itemProps } = selectedItem;
  if (type === LinkMenuItem) {
    cancelEvent(event);

    const el = menuRef.current?.querySelector(
      `[data-menu-item-id="${itemProps.id}"] a`,
    );
    if (el instanceof HTMLAnchorElement) {
      el.click();
      close({ reason: "item_activated" });
    }
  } else if ("onActivate" in itemProps) {
    cancelEvent(event);
    if (!itemProps.disabled) {
      itemProps.onActivate();
    }

    if (type === MenuItem && itemProps.disableCloseOnActivate !== true) {
      close({ reason: "item_activated" });
    }
  }
}

function handleSpace(params: MenuKeyboardListenerParams, event: KeyboardEvent) {
  if (params.items.length === 0 && !isInInputElement(event)) {
    params.onClose({ reason: "close_requested" });
  }
}

function isInInputElement(event: KeyboardEvent) {
  return (
    event.target instanceof HTMLInputElement ||
    isInLabelEditor() ||
    isInFrontMatterCell()
  );
}

function isInFrontMatterCell() {
  const activeCell = selectFocusedCellOrSurrogate(getState());
  return activeCell && activeCell.id === FRONT_MATTER_CELL_ID;
}

function isInLabelEditor() {
  return selectActiveLabelsEditorType(getState()) !== "none";
}

const isSelected = (selection: Selection) => (item: React.ReactElement) =>
  "id" in item.props &&
  item.props.id === selection.itemId &&
  !item.props.disabled;

function moveSelection(params: MenuKeyboardListenerParams, delta: 1 | -1) {
  const { grabsFocus = true, items, selection, setSelection } = params;

  const index = selection.itemId
    ? items.findIndex((item) => item.props.id === selection.itemId)
    : -1;

  const newIndex = index + delta;

  // In case the menu is rendered for label suggestions, get the input
  // element, focus on it and remove current selection.
  if (isInLabelEditor() && (newIndex < 0 || newIndex >= items.length)) {
    getLabelInputElement()?.focus();

    setSelection({ itemId: undefined, selectedBy: "key" });
  } else {
    const selectableItems = items.filter((item) => !item.props.disabled);
    const selectableIndex =
      index >= 0
        ? selectableItems.findIndex(
            (item) => item.props.id === selection.itemId,
          )
        : -1;
    const newSelectableIndex = selectableIndex + delta;

    const newItem =
      selectableItems[
        (newSelectableIndex + selectableItems.length) % selectableItems.length
      ];

    setSelection({ itemId: newItem?.props.id, selectedBy: "key", grabsFocus });
  }
}
