import { parseJsonResponse, postMultipartFormRequest } from "./utils";

type FileSummary = { fileId: string };

export const uploadFile = (notebookId: string, file: File) =>
  postMultipartFormRequest<FileSummary>(
    `/api/notebooks/${encodeURIComponent(notebookId)}/files`,
    { file },
    parseJsonResponse,
  );
