import { Button, Icon } from "@fiberplane/ui";
import { type Control, useController } from "react-hook-form";
import { useSelector } from "react-redux";
import { css, keyframes, styled } from "styled-components";

import { useUpdateWebhookMutation } from "../../../../api";
import { useThrottledLoadingState } from "../../../../hooks";
import { selectActiveWorkspaceIdOrThrow } from "../../../../selectors";
import { dispatch } from "../../../../store";
import { showConfirmationDialog, showModal } from "../../../../thunks";
import type { Webhook } from "../../../../types";
import { ModalDescription, ModalLabel } from "../../../UI";

const ANIMATION_DURATION_MS = 1000;

type SecretFieldProps = {
  control: Control<Webhook>;
};

export function SecretField({ control }: SecretFieldProps) {
  const {
    field: { value: webhookId },
  } = useController({ control, name: "id" });
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const [updateMutation, { isLoading }] = useUpdateWebhookMutation();
  const isLoadingThrottled = useThrottledLoadingState(
    !!isLoading,
    ANIMATION_DURATION_MS,
  );

  const regenerateSecret = () => {
    dispatch(
      showConfirmationDialog("confirmRegenerateSecret", {
        children:
          "Are you sure you want to regenerate the shared secret? This action cannot be undone and the clients using the existing secret will stop working immediately.",
        iconVisual: "warning",
        title: "Regenerate secret",
        onConfirm: async () => {
          const { sharedSecret } = await updateMutation({
            updateWebhook: { regenerateSharedSecret: true },
            webhookId,
            workspaceId,
          }).unwrap();

          if (sharedSecret) {
            dispatch(
              showModal({ type: "webhookSecret", secret: sharedSecret }),
            );
          }
        },
      }),
    );
  };

  return (
    <Container>
      <ModalLabel>Signing secret</ModalLabel>

      <RegenerateButton
        buttonStyle="secondary"
        disabled={isLoadingThrottled}
        onClick={regenerateSecret}
        type="button"
      >
        <LoadingIcon
          $loading={isLoadingThrottled}
          iconType="arrows_clockwise"
        />
        Regenerate
      </RegenerateButton>

      <ModalDescription>
        A secret token used to sign the webhook payload
      </ModalDescription>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid: "label button" auto "description description" auto / 1fr auto;
  gap: 8px;
`;

const RegenerateButton = styled(Button)`
  grid-area: button;
`;

const iconAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingIcon = styled(Icon)<{ $loading: boolean }>(
  ({ $loading }) => css`
    width: 16px;
    animation: ${
      $loading
        ? css`
          ${iconAnimation} ${ANIMATION_DURATION_MS}ms infinite
        `
        : "none"
    };
  `,
);
