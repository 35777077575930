import { styled } from "styled-components";

import GithubLogoSvg from "./GithubLogo.svg";
import GoogleLogoSvg from "./GoogleLogoColor.svg";

export const GoogleLogoIcon = styled(GoogleLogoSvg)`
  height: 20px;
`;

export const GithubLogoIcon = styled(GithubLogoSvg)`
  height: 20px;
`;
