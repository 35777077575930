import {
  type AppAction,
  CLEAR_AUTHENTICATION,
  SET_ACTIVE_WORKSPACE_NAME,
} from "../actions";
import type { WorkspaceState } from "../state";

export const initialState: WorkspaceState = {
  activeWorkspaceName: undefined,
};

export function workspaceReducer(
  state = initialState,
  action: AppAction,
): WorkspaceState {
  switch (action.type) {
    case CLEAR_AUTHENTICATION:
      return initialState;

    case SET_ACTIVE_WORKSPACE_NAME:
      return {
        ...state,
        activeWorkspaceName: action.payload,
      };

    default:
      return state;
  }
}
