import { getClosest } from "./getClosest";

/**
 * Returns the value of an attribute from the closest ancestor `Element` that
 * contains that attribute.
 *
 * Returns `undefined` if no ancestor element with that attribute can be found.
 */
export const getClosestAttribute = (
  node: Node | null,
  attributeName: string,
): string | undefined => {
  const el = getClosest(node, `[${attributeName}]`);
  return el ? el.getAttribute(attributeName) ?? undefined : undefined;
};
