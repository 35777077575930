import { useCellFocus, useFilePaste } from "../../hooks";
import type { TextCell } from "../../types";
import { RichTextInput } from "./RTE";

export type TextCellContentProps = {
  cell: { type: "text" } & TextCell;
  readOnly: boolean;
};

export function TextCellContent({ cell, readOnly }: TextCellContentProps) {
  const focus = useCellFocus(cell.id);
  const onPaste = useFilePaste(cell.id);

  return (
    <RichTextInput
      cellId={cell.id}
      focus={focus}
      formatting={cell.formatting}
      onPaste={onPaste}
      readOnly={readOnly}
      value={cell.content}
    />
  );
}
