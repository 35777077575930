import type { CellFocus, NotebookFocus } from "../../../../types";
import { getClosestAttribute } from "../../../../utils";
import { getContainerElForCellField, getOffsetForNodeAndIndex } from "./nodes";

export function getCellFocusForRange(
  containerEl: HTMLElement,
  range: StaticRange,
): CellFocus {
  const start = {
    offset: getOffsetForNodeAndIndex(
      containerEl,
      range.startContainer,
      range.startOffset,
    ),
  };

  if (range.collapsed) {
    return { type: "collapsed", ...start };
  }

  const end = {
    offset: getOffsetForNodeAndIndex(
      containerEl,
      range.endContainer,
      range.endOffset,
    ),
  };
  return { type: "selection", start, end, focus: end };
}

export function getNotebookFocusForRange(range: StaticRange): NotebookFocus {
  const startCellId = getClosestAttribute(range.startContainer, "data-cell-id");
  const endCellId = getClosestAttribute(range.endContainer, "data-cell-id");
  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
  if (!startCellId || !endCellId) {
    return { type: "none" };
  }

  const field = getClosestAttribute(range.startContainer, "data-field");
  const endField = getClosestAttribute(range.endContainer, "data-field");

  const startOffset = getOffsetForRangePosition(
    startCellId,
    field,
    range.startContainer,
    range.startOffset,
  );

  // We do not support ranges that cross fields...
  const collapseRangeAcrossFields =
    field !== endField || (field && startCellId !== endCellId);

  if (range.collapsed || collapseRangeAcrossFields) {
    return {
      type: "collapsed",
      cellId: startCellId,
      field,
      offset: startOffset,
    };
  }

  const endOffset = getOffsetForRangePosition(
    endCellId,
    endField,
    range.endContainer,
    range.endOffset,
  );
  return {
    type: "selection",
    anchor: { cellId: startCellId, field, offset: startOffset },
    focus: { cellId: endCellId, field, offset: endOffset },
  };
}

function getOffsetForRangePosition(
  cellId: string,
  field: string | undefined,
  node: Node,
  offset: number,
) {
  const container = getContainerElForCellField(cellId, field);
  return container ? getOffsetForNodeAndIndex(container, node, offset) : 0;
}
