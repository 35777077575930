import { AttachedPopup, Button } from "@fiberplane/ui";
import { useRef } from "react";
import { css, styled } from "styled-components";

import { useOpen } from "@fiberplane/hooks";
import type { ProviderEvent } from "../../../types";
import { FilterContent } from "./FilterContent";

type FilterProps = {
  cellId: string;
  records: Array<ProviderEvent>;
  displayFields: Array<string>;
};

export function Filter(props: FilterProps) {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { opened, setOpened, modalRef } = useOpen(anchorRef);

  return (
    <>
      <StyledButton
        data-menu
        ref={anchorRef}
        onClick={(event) => {
          event.preventDefault();
          setOpened(!opened);
        }}
        buttonStyle="tertiary-grey"
      >
        + Add columns
      </StyledButton>

      {opened && (
        <AttachedPopup
          element={anchorRef.current}
          placement="bottom-end"
          offset={[0, 4]}
        >
          <FilterContent ref={modalRef} {...props} />
        </AttachedPopup>
      )}
    </>
  );
}

const StyledButton = styled(Button)(
  ({ theme }) => css`
    min-height: unset;
    height: ${theme.height.x.small};
    border-radius: ${theme.radius.minimal};
  `,
);
