import type React from "react";
import { styled } from "styled-components";

import { LINE_HEIGHT } from "../constants";
import { resetCss } from "./TextEditorNode";

type Props = {
  icon?: JSX.Element;
  children: React.ReactNode;
  className?: string;
  "data-start-offset": number;
  "data-end-offset": number;
};

const PADDING = 4;

export const ICON_SIZE = LINE_HEIGHT - PADDING * 2;

export const EntityEditorNode = ({ icon, children, ...props }: Props) => (
  <EntityContainer {...props}>
    {icon}
    {children}
  </EntityContainer>
);

const EntityContainer = styled.span`
  ${resetCss}

  /*
   * Chrome needs vertical-align set to top so it centers vertically...
   */
  vertical-align: top;
  display: inline-flex;
  align-items: center;
  gap: ${PADDING}px;

  padding: 0 ${PADDING * 2}px;
  background: ${({ theme }) => theme.colorPrimaryAlpha50};
  color: ${({ theme }) => theme.colorPrimary500};
  line-height: ${LINE_HEIGHT}px;
  min-height: ${LINE_HEIGHT}px;
  max-height: ${LINE_HEIGHT}px;
  border-radius: ${({ theme }) => theme.borderRadius600};

  /*
   * TODO:
   * Interactive entities are not supported yet, but these are the styling implementations
   */

  /*
  &:hover {
    background: ${({ theme }) => theme.colorPrimaryAlpha100};
  }

  &:active {
    background: ${({ theme }) => theme.colorPrimary500};
    color: ${({ theme }) => theme.colorBackground};
  }

  &:focus {
    outline: ${({ theme }) => theme.effectFocusOutline};
  }
  */
`;
