import { styled } from "styled-components";

import { FeatureCard, type FeatureProps } from "./FeatureCard";

const FEATURES: Array<FeatureProps> = [
  {
    feature: "theme-switcher",
    title: "Theme switcher",
    description: (
      <>
        Switch between light and dark mode. Take note this is actively under
        development; enabling this feature may introduce breaking UI elements.
      </>
    ),
  },
  {
    feature: "nav-new-notebook",
    title: "New notebook button",
    description: (
      <>
        Open a new notebook directly from the vertical navigation without the
        need to open its menu first.
      </>
    ),
  },
  {
    feature: "notebook-table-of-contents",
    title: "Table of contents",
    description: "Navigate through your notebook with a table of contents.",
  },
];

export function ExperimentalFeatures() {
  return (
    <ExperimentalFeaturesContainer>
      {FEATURES.map(({ feature, description, title }) => (
        <FeatureCard
          key={feature}
          feature={feature}
          description={description}
          title={title}
        />
      ))}
    </ExperimentalFeaturesContainer>
  );
}

const ExperimentalFeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
  justify-content: start;
  gap: 20px;
`;
