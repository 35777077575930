import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectModalsMap, selectOverlayValue } from "../../../selectors";
import type { ModalState } from "../../../state";
import { dispatch } from "../../../store";
import { hideSettingsModal } from "../../../thunks";
import type { RoutedModalType } from "../../../types";

function getModalTypePropsFromOverlay(overlay: string): RoutedModalType | null {
  if (!overlay) {
    return null;
  }
  return {
    type: "routedModal",
    uri: overlay,
  };
}

export function useOverlayModal():
  | (RoutedModalType & { type: "routedModal" })
  | null {
  const overlay = useSelector(selectOverlayValue);
  return useMemo(
    () => (overlay ? getModalTypePropsFromOverlay(overlay) : null),
    [overlay],
  );
}

/**
 * Modals can be stored either in the store or as part of the URL (query params).
 *
 * Right now, url based modals are shown first and then the store based modals are shown.
 */
export function useModals() {
  // store based modals
  const storedModals = useSelector(selectModalsMap);
  const overlayProps = useOverlayModal();

  return useMemo(() => {
    const onRequestClose = () => {
      dispatch(hideSettingsModal());
    };

    const modalEntries = [...storedModals.entries()].map(
      ([key, modalState]: [key: string, modalState: ModalState]) => ({
        key,
        modalState,
      }),
    );

    // If there's an overlay props combine modal entries with the overlay modal
    return overlayProps
      ? [
          {
            key: "url-overlay",
            modalState: {
              onRequestClose,
              modal: overlayProps,
            },
          },
          ...modalEntries,
        ]
      : // Otherwise return the list as is
        modalEntries;
  }, [overlayProps, storedModals]);
}
