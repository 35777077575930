import { useHandler } from "@fiberplane/hooks";
import { useRef } from "react";

/**
 * Returns a callback that will only ever be called once.
 * This was a quickfix for multiple calls to `track("sign_up")` in App.tsx
 */
// biome-ignore lint/suspicious/noExplicitAny: I don't think TS has a better solution.
export function useHandlerOnce<Handler extends (...args: Array<any>) => any>(
  handler: Handler,
): (...args: Parameters<Handler>) => void {
  const hasBeenCalled = useRef(false);
  const handlerRef = useRef(handler);
  handlerRef.current = handler;

  return useHandler((...args) => {
    if (!hasBeenCalled.current) {
      handlerRef.current(...args);
      hasBeenCalled.current = true;
    }
  });
}
