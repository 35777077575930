import { setCommandMenuVariant } from "../actions";
import { LABEL_SPLIT_CHARACTER } from "../constants";
import {
  selectCommandMenuLabels,
  selectCommandMenuVariant,
} from "../selectors";
import type { CommandMenuVariant } from "../state";
import type { Thunk } from "../store";
import { parseLabel } from "../utils";

export const addCommandMenuLabelKeySuggestion =
  (key: string): Thunk =>
  (dispatch, getState) => {
    const labels = selectCommandMenuLabels(getState());
    const label = labels.find((label) => label.key === key);

    const variant: CommandMenuVariant = {
      type: "search",
      labels,
      labelsEditor: {
        original: label ?? { key, value: "" },
        draft: `${key}${LABEL_SPLIT_CHARACTER}`,
        suggestions: { loading: false },
        suggestionsKey: "",
      },
    };

    dispatch(setCommandMenuVariant(variant));
  };

export const addCommandMenuLabelValueSuggestion =
  (value: string): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const variant = selectCommandMenuVariant(state);

    if (variant.type === "search" && variant.labelsEditor?.draft) {
      const currentLabels = selectCommandMenuLabels(state);

      const { key } = parseLabel(variant.labelsEditor.draft);
      const label = { key, value };

      const labels = currentLabels.some((label) => label.key === key)
        ? currentLabels.filter((label) => label.key === key)
        : [...currentLabels, label];

      dispatch(
        setCommandMenuVariant({
          type: "search",
          labels,
          labelsEditor: undefined,
        }),
      );
    }
  };
