import Preview from "url:./image.png";
import { Button } from "@fiberplane/ui";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { push } from "redux-first-history";
import { css, styled } from "styled-components";

import { useGetTemplateByNameQuery } from "../../../api";
import { normalizeException } from "../../../errors";
import {
  selectActiveWorkspaceIdOrThrow,
  selectActiveWorkspaceNameOrThrow,
} from "../../../selectors";
import { dispatch } from "../../../store";
import {
  Modal,
  ModalButtonBar,
  ModalContent,
  ModalContext,
  ModalDescription,
  ModalLabel,
  ModalLabelText,
  RelativeTimestamp,
} from "../../UI";
import { Form } from "./Form";
import { Paginator } from "./Paginator";
import { PaginatorControls } from "./PaginatorControls";
import { formatTemplateUrl } from "./util";

type Props = {
  templateName: string;
};

export function TemplateDetail({ templateName }: Props): JSX.Element {
  const { requestClose } = useContext(ModalContext);
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const workspaceName = useSelector(selectActiveWorkspaceNameOrThrow);

  const { isFetching, error, data, refetch } = useGetTemplateByNameQuery({
    workspaceId,
    templateName,
  });

  const [value, setValue] = useState<Record<string, string>>();
  const onSubmit = () => {
    dispatch(push(formatTemplateUrl(workspaceName, templateName, value)));
    requestClose();
  };

  const [page, setPage] = useState(0);

  if (isFetching) {
    return <Modal title="Loading template" />;
  }

  if (error) {
    return (
      <Modal
        title="An error occured"
        description={normalizeException(error).message}
      />
    );
  }

  if (!data) {
    return (
      <Modal title="Template not found">
        <ModalButtonBar>
          <Button onClick={refetch}>Retry</Button>
        </ModalButtonBar>
      </Modal>
    );
  }

  const hasMultiplePages = data.parameters.length > 0;

  return (
    <Modal title={`New notebook from template ${data.name}`}>
      <Paginator
        page={page}
        renderControls={(page: number) => (
          <PaginatorControls
            hasMultiplePages={hasMultiplePages}
            onSubmit={onSubmit}
            page={page}
            setPage={setPage}
          />
        )}
      >
        <PageContainer>
          <StyledPreview>
            <StyledImage src={Preview} alt="" />
          </StyledPreview>

          <ModalContent>
            <ModalLabel>
              <ModalLabelText>Created on</ModalLabelText>
              <ModalDescription
                as={RelativeTimestamp}
                timestamp={data.createdAt}
              />
            </ModalLabel>

            <ModalLabel>
              <ModalLabelText as="span">Description</ModalLabelText>
              <ModalDescription as="span">{data.description}</ModalDescription>
            </ModalLabel>
          </ModalContent>
        </PageContainer>

        {hasMultiplePages && data.parameters.length > 0 && (
          <ModalContent>
            <ModalDescription>Template parameters</ModalDescription>
            <Form
              parameters={data.parameters}
              onChange={setValue}
              onSubmit={onSubmit}
              disabled={page !== 1}
            />
          </ModalContent>
        )}
      </Paginator>
    </Modal>
  );
}

const PageContainer = styled.section`
  align-items: stretch;
  width: 100%;
`;

const StyledPreview = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    max-width: 100%;
    box-sizing: border-box;
    background-color: ${theme.colorBase200};
    padding: 16px 30px 0;

    @media (min-width: 449px) {
      padding: 16px 40px 0;
    }

    @media (min-width: 449px) {
      padding: 16px 60px 0;
    }

    @media (min-width: 768px) {
      padding: 24px 100px 0;
    }
  `,
);

const StyledImage = styled.img`
  max-width: 100%;
`;
