import "prismjs/themes/prism.css";

import Prism from "prismjs";
import "prismjs/components/prism-bash"; // NOTE - same as "sh" and "shell" syntax highlighters
import "prismjs/components/prism-json";
import "prismjs/components/prism-markup";
import "prismjs/components/prism-promql";
import "prismjs/components/prism-rust";
import "prismjs/components/prism-sql";
import "prismjs/components/prism-yaml";
import { useMemo } from "react";
import { type DefaultTheme, useTheme } from "styled-components";

import { sortBy } from "../../../../utils";
import { promQlFunctions } from "../promQlFunctions";
import type { ExtendedFormatting } from "../types";

// Extend shell syntax highlighting to include fp and fpx commands
const FP_COMMAND_TOKEN = "fp-command";
Prism.languages.shell = Prism.languages.extend("shell", {
  [FP_COMMAND_TOKEN]: {
    lookbehind: true,
    pattern: /(^|[\s&;|]|[<>]\()(?:fp|fpx)(?=$|[\s&);|])/,
  },
});

// Simplistic lucene syntax highlighting, could be improved
// Borrows string, number, and property highlighting from JSON definition
Prism.languages.lucene = {
  property: {
    pattern: /(^|[^\\])"(?:\\.|[^\n\r"\\])*"(?=\s*:)/,
    lookbehind: true,
    greedy: true,
  },
  string: {
    pattern: /(^|[^\\])"(?:\\.|[^\n\r"\\])*"(?!\s*:)/,
    lookbehind: true,
    greedy: true,
  },
  number: /-?\b\d+(?:\.\d+)?(?:e[+-]?\d+)?\b/i,
  punctuation: /[():[\]{}]/,
};

// Extend the PromQL syntax highlighting to include PromQL functions
const { promql } = Prism.languages;
if (!promql) {
  throw new Error("PromQL syntax not defined");
}

promql.function = new RegExp(
  `\\b(?:${promQlFunctions.join("|")})(?=\\s*\\()`,
  "i",
);

export const SupportedSyntax = {
  clike: "clike",
  docker: "docker",
  js: "js",
  json: "json",
  lucene: "lucene",
  plaintext: "plaintext",
  promql: "promql",
  rust: "rust",
  shell: "shell",
  sql: "sql",
  xml: "xml",
  yaml: "yaml",
  // "splunk-spl": "splunk-spl",
  // css: "css",
  // markdown: "markdown",
  // nginx: "nginx",
  // typescript: "typescript",
} as const;

export type SupportedSyntaxName = keyof typeof SupportedSyntax;

export const useHighlighter = (
  syntax: SupportedSyntaxName,
  content: string,
) => {
  const theme = useTheme();

  return useMemo(() => {
    const formatting: ExtendedFormatting = [];
    const grammar = Prism.languages[syntax];
    if (!grammar) {
      return formatting;
    }

    const tokens = Prism.tokenize(content, grammar);

    let start = 0;
    const processToken = (token: string | Prism.Token) => {
      const length = getLength(token);
      const end = start + length;

      if (typeof token !== "string") {
        processNestedToken(token, end);
      }

      start = end;
    };

    const processNestedToken = (token: Prism.Token, end: number) => {
      if (Array.isArray(token.content)) {
        for (const inner of token.content) {
          processToken(inner);
        }
      } else {
        const color = colorForTokenType(token.type, theme);
        if (color) {
          formatting.push(
            { type: "start_color", color, offset: start },
            { type: "end_color", offset: end },
          );
        }
      }
    };

    for (const token of tokens) {
      processToken(token);
    }

    return sortBy(formatting, (annotation) => annotation.offset);
  }, [content, syntax, theme]);
};

function colorForTokenType(type: string, theme: DefaultTheme) {
  switch (type) {
    case "comment":
      return theme.color.fg.subtle;

    case "operator":
    case "url":
      return theme.color.fg.default;

    case "keyword":
      return theme.color.fg.primary;

    case "variable":
    case "regex":
      return theme.color.fg.accent[3];

    case "number":
    case "boolean":
    case "tag":
    case "constant":
    case "symbol":
    case "attr-name":
    case "selector":
    case "range-duration": // PromQL specific
      return theme.color.fg.danger;

    case "punctuation":
      return theme.color.fg.muted;

    case "string":
    case "char":
    case "attr-value":
    case "label-value": // PromQL specific
      return theme.color.fg.accent[2];

    case "function":
    case "class-name":
    case FP_COMMAND_TOKEN: // shell specific (our custom extension)
      return theme.color.fg.accent[1];
  }
}

function getLength(token: string | Prism.Token): number {
  if (typeof token === "string") {
    return token.length;
  } else if (typeof token.content === "string") {
    return token.content.length;
  } else if (Array.isArray(token.content)) {
    return token.content.reduce((l, t) => l + getLength(t), 0);
  } else {
    return getLength(token.content);
  }
}

export const isSupportedSyntax = (
  syntax?: string,
): syntax is SupportedSyntaxName => {
  if (syntax === undefined) {
    return false;
  }

  return syntax in SupportedSyntax;
};

export const getSupportedSyntax = (
  rawSyntax?: string,
  fallback: SupportedSyntaxName = SupportedSyntax.plaintext,
) => {
  if (isSupportedSyntax(rawSyntax)) {
    return rawSyntax;
  }

  return fallback;
};
