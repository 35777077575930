import { Input } from "@fiberplane/ui";
import type { ReactNode } from "react";
import { css, styled } from "styled-components";

type FilterContentItemProps = {
  value: string;
  children: ReactNode;
  checked?: boolean;
  onChange(value: string, checked: boolean): void;
};

export function FilterContentItem({
  children,
  checked = false,
  onChange,
  value,
}: FilterContentItemProps) {
  return (
    <Container>
      <CheckBox checked={checked} onChange={() => onChange(value, !checked)} />
      <TextContent>{children}</TextContent>
    </Container>
  );
}

const Container = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 12px;
  max-width: 100%;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  cursor: pointer;
`;

const CheckBox = styled(Input).attrs({ type: "checkbox" })`
  & > div {
    border-radius: ${({ theme }) => theme.radius.lowest};
  }
`;

const TextContent = styled.div(
  ({ theme }) => css`
    font: ${theme.font.body.sm.regular};
    min-height: 20px;
    overflow-wrap: anywhere;
    max-width: calc(100% - 16px);
    display: flex;
    align-items: center;
    flex: none;
    order: 1;
    flex-grow: 1;
  `,
);
