import { Icon } from "@fiberplane/ui";
import { styled } from "styled-components";

export const ConsoleHeaderIcon = styled(Icon)`
  cursor: pointer;
  display: inline-flex;
  width: 12px;
  height: 12px;
  color: currentColor;
`;
