import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { generatePath, matchPath } from "react-router-dom";
import { useTitle } from "react-use";
import { push } from "redux-first-history";
import { createSelector } from "reselect";
import { styled } from "styled-components";

import { useListSnippetsQuery, useListTemplatesQuery } from "../../api";
import { ROUTES } from "../../constants";
import {
  selectActiveWorkspaceIdOrThrow,
  selectActiveWorkspaceNameOrThrow,
  selectPathname,
} from "../../selectors";
import { dispatch } from "../../store";
import {
  CONTAINER_PADDING,
  PAGE_MAX_WIDTH,
  PageContainer,
  PageContent,
  PageHeader,
  PageSubTitle,
  PageTitle,
  PillBox,
  PillItem,
} from "../UI";
import { SnippetItems } from "./SnippetItems";
import { TemplateItems } from "./TemplateItems";
import { TemplatesAnchor } from "./template";

const TEMPLATES = "templates" as const;
const SNIPPETS = "snippets" as const;
const LISTS = [TEMPLATES, SNIPPETS] as const;

export function TemplatesPage(): JSX.Element {
  const { activePage, workspaceId, workspaceName } = useSelector(
    selectTemplatesPageState,
  );
  const renderTemplates = activePage === TEMPLATES;

  const { isFetching: isFetchingTemplates, error: templatesError } =
    useListTemplatesQuery(
      { workspaceId },
      { skip: !renderTemplates, refetchOnMountOrArgChange: true },
    );

  const { isFetching: isFetchingSnippets, error: snippetsError } =
    useListSnippetsQuery(
      { workspaceId },
      { skip: renderTemplates, refetchOnMountOrArgChange: true },
    );

  const isFetching = renderTemplates ? isFetchingTemplates : isFetchingSnippets;

  useTitle(renderTemplates ? "Templates" : "Snippets");

  if (templatesError || snippetsError) {
    return <Message>Error!</Message>;
  }

  return (
    <TemplatesPageContainer
      data-testid="templates-page"
      data-fetching={isFetching}
    >
      <TemplatesPageHeader>
        <PageTitle $noMargin loading={isFetching}>
          Templates
        </PageTitle>
        <PageSubTitle>
          Templates help make your workflows more efficient. Find out how to{" "}
          <TemplatesAnchor
            href="https://docs.fiberplane.com/docs/templates"
            target="_blank"
            rel="noopener"
          >
            create them
          </TemplatesAnchor>
          .
        </PageSubTitle>
      </TemplatesPageHeader>

      <PillBoxRow data-testid="page-switch-pill-box">
        <PillBox>
          {LISTS.map((list) => {
            const capitalizedName =
              list.charAt(0).toUpperCase() + list.slice(1);

            const route = getListItemRoute(list);
            const path = generatePath(route, { workspaceName });

            return (
              <PillItem
                key={list}
                isActive={activePage === list}
                onClick={() => dispatch(push(path))}
              >
                {capitalizedName}
              </PillItem>
            );
          })}
        </PillBox>
      </PillBoxRow>

      <StyledPageContent>
        {renderTemplates ? <TemplateItems showLearnMore /> : <SnippetItems />}
      </StyledPageContent>
    </TemplatesPageContainer>
  );
}

const selectTemplatesPageState = createSelector(
  [
    selectPathname,
    selectActiveWorkspaceIdOrThrow,
    selectActiveWorkspaceNameOrThrow,
  ],
  (pathname, workspaceId, workspaceName) => {
    const activePage =
      pathname && matchPath(ROUTES.Snippets, pathname) ? SNIPPETS : TEMPLATES;

    return {
      activePage,
      workspaceId,
      workspaceName,
    };
  },
);

function getListItemRoute(list: (typeof LISTS)[number]) {
  switch (list) {
    case TEMPLATES:
      return ROUTES.Templates;
    case SNIPPETS:
      return ROUTES.Snippets;
  }
}

const TemplatesPageContainer = styled(PageContainer)`
  grid: "header" auto "switch" auto "content" 1fr / 1fr;
`;

const Message = styled(motion.span)`
  grid-area: content;
  align-self: center;
`;

const TemplatesPageHeader = styled(PageHeader)`
  margin-top: 40px;
  grid: "title" auto "subtitle" auto / 1fr;
`;

const PillBoxRow = styled.div`
  grid-area: switch;
  padding: 0 ${CONTAINER_PADDING};
  margin: 0 auto;
  width: 100%;
  max-width: calc(${PAGE_MAX_WIDTH} + 2 * ${CONTAINER_PADDING});
`;

const StyledPageContent = styled(PageContent)`
  /* For now, we put some top padding and a top border instead of a search box above the Template container  */
  padding-top: 16px; 
  border-top: solid 1px ${({ theme }) => theme.color.border.muted};
`;
