/**
 * Maps all values in an object from one type to another.
 */
export function mapValues<V, W>(
  object: { [key: string]: V },
  callback: (value: V) => W,
): { [key: string]: W } {
  return Object.keys(object).reduce(
    (accumulator, key) => {
      accumulator[key] = callback(object[key] as V);
      return accumulator;
    },
    {} as { [key: string]: W },
  );
}
