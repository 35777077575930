import { styled } from "styled-components";

import { MEDIUM_SIZE_QUERY } from "../../../constants";
import { LabelsEditor } from "../../LabelsEditor";

export const GridAreaMeta = styled.div`
  grid-area: meta;
`;

export const GridAreaLabels = styled.div`
  grid-area: labels;
  display: flex;
  justify-content: start;
`;

export const StyledLabelEditor = styled(LabelsEditor)`
  flex-direction: row;
`;

export const GridAreaActions = styled.div`
  grid-area: actions;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EventGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto 28px;
  grid-template-rows: auto auto;
  grid-template-areas:
    "meta meta actions"
    "labels labels actions";

  input {
    border: none;
    outline: none;
    background: transparent;
  }

  & > * + * {
    margin-left: 10px;
  }

  @media ${MEDIUM_SIZE_QUERY} {
    grid-template-areas:
      "meta labels actions"
      "meta labels actions";

    ${GridAreaLabels} {
      justify-content: end;
    }

    ${StyledLabelEditor} {
      flex-direction: row-reverse;
    }
  }
`;
