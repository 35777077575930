import {
  type AppAction,
  CLEAR_AUTHENTICATION,
  UPDATE_AVATAR_IMAGE,
} from "../actions";
import type { AvatarState } from "../state";
import { createImmerReducer } from "../utils";

export const initialState: AvatarState = {
  profile: new Map(),
  workspace: new Map(),
};

export const avatarsReducer = createImmerReducer<AppAction, AvatarState>(
  initialState,
  {
    [CLEAR_AUTHENTICATION]: () => initialState,
    [UPDATE_AVATAR_IMAGE]: (state, action) => {
      const { avatarType, id } = action.payload;

      const data = state[avatarType].get(id);
      const currentLastUpdated = data ? data.lastUpdated : Date.now();

      state[avatarType].set(id, {
        ...data,
        ...action.payload.data,
        lastUpdated: action.payload.data.lastUpdated || currentLastUpdated,
      });
    },
  },
);
