import { type IconType, isIconType } from "@fiberplane/ui";

import type { FrontMatterUser, FrontMatterValueSchema } from "../../../types";

export function getSchemaIconType(schema: FrontMatterValueSchema): IconType {
  const { iconName, type: schemaType } = schema;
  if (isIconType(iconName)) {
    return iconName;
  }

  switch (schemaType) {
    case "string": {
      const { options } = schema;
      if (options) {
        return "list_bullets";
      }

      return "text_align_left";
    }

    case "user": {
      if (schema.multiple) {
        return "list_bullets";
      }

      return "user_circle";
    }
    case "number":
      return "hash";
    case "date_time":
      return "time_duotone";
    case "pagerduty_incident":
      return "pager_duty_color";
    case "github_pull_request":
      return "github_logo_alt";
    default: {
      const exhaustiveCheck: never = schemaType;
      throw new Error(`Unhandled schema icon type: ${exhaustiveCheck}`);
    }
  }
}

export function isString(value: unknown): value is string {
  return typeof value === "string";
}

export function isNumber(value: unknown): value is number {
  return typeof value === "number";
}

export function isUser(value: unknown): value is FrontMatterUser {
  return (
    typeof value === "object" &&
    value !== null &&
    "id" in value &&
    typeof value.id === "string" &&
    "name" in value &&
    typeof value.name === "string"
  );
}
