import { useHandler } from "@fiberplane/hooks";
import { Button, Icon } from "@fiberplane/ui";
import type React from "react";
import { useEffect, useRef, useState } from "react";
import { css, styled } from "styled-components";

import { setZeroTimeout } from "../../utils";

type Options = {
  /**
   * Default height (assumed to be in pixels).
   */
  defaultHeight: number;
};

type Result<T> = {
  /**
   * Component you should include in your output to allow the user to toggle
   * the expanded state, if relevant.
   */
  expandButton?: JSX.Element;

  /**
   * Component you may need to include in your output to display the gradient
   * to indicate the collapsed state, if relevant.
   */
  gradient?: JSX.Element;

  /**
   * Whether the expandable container is currently expanded.
   */
  isExpanded: boolean;

  /**
   * Scroll event listener to attach to the container.
   */
  onScroll: (event: React.UIEvent<T, UIEvent>) => void;

  /**
   * Ref to attach to the container.
   */
  ref: React.RefCallback<T>;
};

/**
 * Implements all the logic needed to create an expandable container.
 */
export function useExpandable<T extends HTMLElement = HTMLDivElement>({
  defaultHeight,
}: Options): Result<T> {
  const ref = useRef<T | null>(null);

  const [showExpandButton, setShowExpandButton] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showGradient, setShowGradient] = useState(false);

  const update = useHandler((element: Element) => {
    const { scrollTop, scrollHeight, clientHeight } = element;

    if (scrollHeight <= defaultHeight) {
      setShowExpandButton(false);
      setShowGradient(false);
    } else {
      setShowExpandButton(true);
      setShowGradient(scrollHeight - scrollTop >= clientHeight);
    }
  });

  // This calls update function with a tiny delay. This fixes
  // errors with the ResizeObserver loop taking too long
  const asyncUpdate = useHandler((element: Element) => {
    setZeroTimeout(() => {
      if (ref.current !== element) {
        return;
      }

      update(element);
    });
  });

  useEffect(() => {
    return () => {
      if (ref.current) {
        unsubscribeFromNode(ref.current, asyncUpdate);
        ref.current = null;
      }
    };
  }, [asyncUpdate]);

  const setRef = useHandler((node: T | null) => {
    if (ref.current === node) {
      return;
    }

    if (ref.current) {
      unsubscribeFromNode(ref.current, asyncUpdate);
    }

    if (node) {
      subscribeToNode(node, asyncUpdate);
      update(node);
    }

    ref.current = node;
  });

  const onClickExpand = useHandler(() => {
    setIsExpanded(!isExpanded);
  });

  const onScroll = useHandler((event: React.UIEvent<T, UIEvent>) => {
    asyncUpdate(event.currentTarget);
  });

  return {
    expandButton: showExpandButton ? (
      <ExpandButton buttonStyle="tertiary-color" onClick={onClickExpand}>
        {isExpanded ? "Hide" : "Show more"}
        <Icon iconType={isExpanded ? "caret_up" : "caret_down"} />
      </ExpandButton>
    ) : undefined,
    gradient: showGradient ? (
      <GradientContainer>
        <Gradient />
      </GradientContainer>
    ) : undefined,
    isExpanded: isExpanded || !showExpandButton,
    onScroll,
    ref: setRef,
  };
}

type Listener = (node: Element) => void;

const listenerMap: WeakMap<Element, Set<Listener>> = new WeakMap();

let observer: ResizeObserver | undefined;

function observerCallback(entries: Array<ResizeObserverEntry>) {
  for (const entry of entries) {
    const listeners = listenerMap.get(entry.target);
    if (listeners) {
      for (const listener of listeners) {
        listener(entry.target);
      }
    }
  }
}

function subscribeToNode(node: Element, listener: Listener) {
  const listeners = listenerMap.get(node);
  if (listeners) {
    listeners.add(listener);
  } else {
    listenerMap.set(node, new Set([listener]));

    if (!observer) {
      observer = new ResizeObserver(observerCallback);
    }

    observer.observe(node);
  }
}

function unsubscribeFromNode(node: Element, listener: Listener) {
  const listeners = listenerMap.get(node);

  if (listeners) {
    listeners.delete(listener);

    if (listeners.size === 0) {
      listenerMap.delete(node);

      observer?.unobserve(node);
    }
  }
}

const ExpandButton = styled(Button)(
  ({ theme }) => css`
    min-height: unset;
    height: ${theme.height.x.small};
    padding: 4px 2px;
    border-radius: ${theme.radius.minimal};
  `,
);

const Gradient = styled.div`
  position: absolute;
  bottom: 0;
  height: 32px;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    transparent,
    ${({ theme }) => theme.color.bg["disabled-overlay"]} 50%
  );
  pointer-events: none;
`;

// The container is sticky, but zero height to prevent the gradient itself
// from reserving any space.
const GradientContainer = styled.div`
  bottom: 0;
  height: 0;
  position: sticky;
  width: 100%;
`;
