import { useEffect, useRef, useState } from "react";

import { ItemSelector, type ItemSelectorHandle } from "..";
import type { FilterMenuItem } from "../../../../../UI";
import { SelectionFrontMatterMenu } from "./SelectionFrontMatterMenu";

type Props = {
  currentValue: string | undefined; // NOTE - undefined implies there is no current value
  options: Array<string>;
  onChange: (newValue: string | undefined) => void;
  focused?: boolean;
  readOnly?: boolean;
  /**
   * Function that creates a custom menu item. Useful for adding a "Create new" item
   * or perhaps an interactive No results item.
   */
  createExtraMenuItem?: (options: {
    filterText: string;
    exactMatchFound: boolean;
  }) => FilterMenuItem | undefined;
};

/**
 * Render single element from a list of options
 *
 * Note: this component should only be used in cases where the user can only select a single option
 */
export const OptionSelectorSingle = ({
  currentValue,
  options,
  onChange,
  focused = false,
  readOnly = false,
  createExtraMenuItem,
}: Props) => {
  const [initialValue, setInitialValue] = useState<string>();
  useEffect(() => {
    if (initialValue === undefined) {
      forwardRef.current?.focus();
    }
  }, [initialValue]);

  const selectorProps = {
    getId: getValue,
    getValue,
    openContextMenu: setInitialValue,
    colorKey: currentValue,
    focused: focused,
    placeholder: focused ? "Select an option" : "Empty",
    selection: currentValue,
    onClear: () => onChange(undefined),
    hideClearButton: focused !== true || currentValue === undefined,
    renderValue: getValue,
    singleColumn: true,
    readOnly,
  };

  const forwardRef = useRef<ItemSelectorHandle>(null);
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div ref={ref}>
      <ItemSelector
        {...selectorProps}
        ref={forwardRef}
        // Key is used to prevent the animation delay that we have by default on the
        // ItemSelector component.
        key={currentValue || "__new__"}
      />
      {initialValue !== undefined && (
        <SelectionFrontMatterMenu
          options={options}
          element={ref.current}
          filterText={initialValue}
          onActivate={(newValue) => {
            onChange(newValue);
            setInitialValue(undefined);
          }}
          onClose={() => {
            setInitialValue(undefined);
          }}
          createExtraMenuItem={createExtraMenuItem}
        />
      )}
    </div>
  );
};

function getValue(option: string) {
  return option;
}
