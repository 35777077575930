import { useTheme } from "styled-components";

import type { View } from "../types";

export type ViewColorSet = {
  default: string;
  highlight: string;
};

export function useViewColor(color: View["color"]): {
  colorSet: ViewColorSet;
  total: number;
} {
  const theme = useTheme();

  const colors = [
    {
      default: theme.colorViews1100,
      highlight: theme.colorViews1200,
    },
    {
      default: theme.colorViews2100,
      highlight: theme.colorViews2200,
    },
    {
      default: theme.colorViews3100,
      highlight: theme.colorViews3200,
    },
    {
      default: theme.colorViews4100,
      highlight: theme.colorViews4200,
    },
    {
      default: theme.colorViews5100,
      highlight: theme.colorViews5200,
    },
    {
      default: theme.colorViews6100,
      highlight: theme.colorViews6200,
    },
    {
      default: theme.colorViews7100,
      highlight: theme.colorViews7200,
    },
    {
      default: theme.colorViews8100,
      highlight: theme.colorViews8200,
    },
    {
      default: theme.colorViews9100,
      highlight: theme.colorViews9200,
    },
  ];

  const total = colors.length;
  const colorSet = colors[color % total] as ViewColorSet;

  return {
    colorSet,
    total,
  };
}
