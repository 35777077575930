import type {
  ActionReducerMapBuilder,
  Draft,
  createAsyncThunk,
} from "@reduxjs/toolkit";

// import type { WritableDraft } from "immer/dist/internal";
import type { LoadableData, LoadableMutation } from "../types";

export function getInitialLoadableDataState<T>(): LoadableData<T> {
  return {
    loading: false,
  };
}

export function addLoadableDataReducerCases<T, ThunkPayload, ThunkParams>(
  builder: ActionReducerMapBuilder<T>,
  asyncThunk: ReturnType<typeof createAsyncThunk<ThunkPayload, ThunkParams>>,
  getLoadableDataFromSlice: (
    state: Draft<T>,
  ) => Draft<LoadableData<ThunkPayload>>,
) {
  builder.addCase(asyncThunk.pending, (state, _action) => {
    const loadableData = getLoadableDataFromSlice(state);
    loadableData.loading = true;
  });

  builder.addCase(asyncThunk.rejected, (state, action) => {
    const loadableData = getLoadableDataFromSlice(state);
    loadableData.error = action.error.message;
    loadableData.loading = false;
    loadableData.consecutiveErrorCount =
      (loadableData.consecutiveErrorCount ?? -1) + 1;
  });

  builder.addCase(asyncThunk.fulfilled, (state, action) => {
    const loadableData = getLoadableDataFromSlice(state);
    loadableData.data = action.payload as Draft<ThunkPayload>;
    loadableData.error = undefined;
    loadableData.loading = false;
  });
}

export function getInitialLoadableMutationState<
  T = void,
>(): LoadableMutation<T> {
  return {
    loading: false,
  };
}

export function addLoadableMutationReducerCases<T, ThunkPayload, ThunkParams>(
  builder: ActionReducerMapBuilder<T>,
  asyncThunk: ReturnType<typeof createAsyncThunk<ThunkPayload, ThunkParams>>,
  getLoadableMutationStateFromSlice: (
    state: Draft<T>,
  ) => Draft<LoadableMutation<ThunkPayload>>,
) {
  builder.addCase(asyncThunk.pending, (state, _action) => {
    const mutation = getLoadableMutationStateFromSlice(state);
    mutation.loading = true;
  });

  builder.addCase(asyncThunk.rejected, (state, action) => {
    const mutation = getLoadableMutationStateFromSlice(state);
    mutation.consecutiveErrorCount = (mutation.consecutiveErrorCount ?? -1) + 1;
    mutation.error = action.error.message;
    mutation.loading = false;
    mutation.success = false;
  });

  builder.addCase(asyncThunk.fulfilled, (state, action) => {
    const mutation = getLoadableMutationStateFromSlice(state);
    mutation.data = action.payload as Draft<ThunkPayload>;
    mutation.error = undefined;
    mutation.loading = false;
    mutation.success = true;
  });
}
