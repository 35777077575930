import { getRequest, textResponse } from "./utils";

export const slackOauth = (
  code: string | undefined,
  error: string | undefined,
  state: string | undefined,
) =>
  getRequest(
    `/api/slack/oauth?code=${code}&state=${state}&error=${error}`,
    textResponse,
  );
