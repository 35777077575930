import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";

import { COMMENT_INITIAL, COMMENT_REPLY } from "../../../constants";
import {
  makeAppThreadSelector,
  makeNotebookThreadSelector,
  selectCurrentUser,
  selectNotebookFetchThreadsStatus,
} from "../../../selectors";
import type { DiscussionCell } from "../../../types";
import { CommentInput } from "./CommentInput";
import { DiscussionCellContainer } from "./DiscussionCellContainer";
import { DiscussionNotFound } from "./DiscussionNotFound";
import { Thread } from "./Thread";

export type DiscussionCellContentProps = {
  cell: { type: "discussion" } & DiscussionCell;
  readOnly: boolean;
};

export function DiscussionCellContent({
  cell: { id: cellId, threadId },
  readOnly,
}: DiscussionCellContentProps) {
  const selectCellState = useMemo(
    () =>
      createStructuredSelector({
        thread: makeNotebookThreadSelector(threadId),
        threadState: makeAppThreadSelector(threadId),
        user: selectCurrentUser,
        fetchThreadsStatus: selectNotebookFetchThreadsStatus,
      }),
    [threadId],
  );
  const { thread, threadState, user, fetchThreadsStatus } =
    useSelector(selectCellState);
  if (!user || fetchThreadsStatus !== "success") {
    return null;
  }

  if (!thread) {
    return <DiscussionNotFound threadState={threadState} cellId={cellId} />;
  }

  const isDiscussionOwner = thread.createdBy.id === user.id;
  const hasThreadItems = thread.items.length > 0;

  return (
    <DiscussionCellContainer
      cellId={cellId}
      renderPlaceholder={
        // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
        !isDiscussionOwner && !hasThreadItems
      }
      showThreadDeletionPrompt={threadState?.showThreadDeletionPrompt}
      thread={thread}
      user={user}
    >
      {hasThreadItems && (
        <Thread
          cellId={cellId}
          showReplies={threadState?.showReplies ?? false}
          thread={thread}
          user={user}
        />
      )}

      {thread.status === "open" &&
        (threadState?.showReplies || !hasThreadItems) && (
          <CommentInput
            cellId={cellId}
            field={
              !hasThreadItems && isDiscussionOwner
                ? COMMENT_INITIAL
                : COMMENT_REPLY
            }
            firstComment={!hasThreadItems}
            readOnly={readOnly}
            threadId={threadId}
            user={user}
          />
        )}
    </DiscussionCellContainer>
  );
}
