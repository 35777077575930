import { Icon, IconButton, stopPropagation } from "@fiberplane/ui";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { css, styled } from "styled-components";

import { makeProxySelector } from "../../../../../selectors";
import type { Api } from "../../../../../services";
import { dispatch } from "../../../../../store";
import { loadProxy } from "../../../../../thunks";
import { sortBy } from "../../../../../utils";
import { MenuItem, ProviderTypeIcon, ToggleMenu } from "../../../../UI";
import { LargeStatusIndicator, StyledTableContent, StyledTd } from "../shared";
import { formatDataSourceError } from "../utils";

const StyledFiller = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  line-height: 24px;
`;

const ExpandIconButton = styled(IconButton)<{ $isExpanded: boolean }>(
  ({ $isExpanded }) => {
    if ($isExpanded) {
      return css`
        svg {
          transform: rotate(180deg);
        }
      `;
    }
  },
);

const EmptyStyledTableContent = styled(StyledTableContent)`
  justify-content: center;
`;

type Props = {
  proxy: Api.ProxySummary;
  showDeleteModal: (name: string) => void;
};

export function ProxyListItem({ proxy: proxySummary, showDeleteModal }: Props) {
  const { name, status } = proxySummary;
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (expanded) {
      dispatch(loadProxy(name));
    }
  }, [expanded, name]);

  const selectProxyDetails = useMemo(() => makeProxySelector(name), [name]);
  const proxyDetail = useSelector(selectProxyDetails);
  const { data: proxy, loading, error } = proxyDetail ?? {};

  const sortedDataSources = useMemo(
    () => sortBy(proxy ? [...proxy.dataSources] : [], (source) => source.name),
    [proxy],
  );

  return (
    <>
      <tr>
        <StyledTd>
          <StyledTableContent>
            <Icon iconType="proxy" /> {name}
          </StyledTableContent>
        </StyledTd>
        <StyledTd colSpan={2}>
          <StyledFiller>
            <StyledTableContent>
              <LargeStatusIndicator status={status} />
            </StyledTableContent>
            <StyledButtonContainer>
              <ToggleMenu
                offset={[0, 6]}
                onClick={stopPropagation}
                placement="bottom-end"
                toggleElement={({ anchorRef, opened }) => (
                  <div ref={anchorRef}>
                    <IconButton
                      iconType="ellipsis"
                      isActive={opened}
                      data-menu
                    />
                  </div>
                )}
              >
                <MenuItem
                  id="delete"
                  onActivate={() => showDeleteModal(name)}
                  title="Delete FPD"
                  iconLeft={<Icon iconType="trash" width="16" height="16" />}
                />
              </ToggleMenu>
              <ExpandIconButton
                iconType="caret_down"
                onClick={() => setExpanded(!expanded)}
                $isExpanded={expanded}
              />
            </StyledButtonContainer>
          </StyledFiller>
        </StyledTd>
      </tr>
      {error && (
        <tr>
          <StyledTd>Error loading FPD list: {error}</StyledTd>
        </tr>
      )}
      {expanded &&
        (loading && !sortedDataSources ? (
          <tr>
            <StyledTd colSpan={2}>
              <EmptyStyledTableContent>
                <span>Loading</span>
              </EmptyStyledTableContent>
            </StyledTd>
          </tr>
        ) : (
          <>
            {sortedDataSources.map((dataSource) => (
              <tr key={dataSource.name}>
                <StyledTd indent>
                  <StyledTableContent>
                    <ProviderTypeIcon type={dataSource.providerType} />
                    <StyledTableContent>{dataSource.name}</StyledTableContent>
                  </StyledTableContent>
                </StyledTd>
                <StyledTd colSpan={2}>
                  <StyledTableContent>
                    <LargeStatusIndicator
                      status={dataSource.status ?? "connected"}
                      error={
                        dataSource.error &&
                        formatDataSourceError(dataSource.error)
                      }
                    />
                    {dataSource.status !== "connected" && (
                      <IconButton iconType="question" />
                    )}
                  </StyledTableContent>
                </StyledTd>
              </tr>
            ))}
            {sortedDataSources.length === 0 && (
              <tr>
                <StyledTd colSpan={3}>
                  <EmptyStyledTableContent>
                    <span>No data sources defined for this instance</span>
                  </EmptyStyledTableContent>
                </StyledTd>
              </tr>
            )}
          </>
        ))}
    </>
  );
}
