import { Icon } from "@fiberplane/ui";
import { shallowEqual, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { styled } from "styled-components";

import { useFeature } from "../../../hooks";
import {
  selectNotebookError,
  selectNotebookLoading,
  selectNotebookReadOnly,
  selectNotebookTitle,
  selectNotebookUnrecoverable,
  selectNotebookVisibility,
} from "../../../selectors";
import { DataSourceToggle } from "../DataSourceToggle";
import { NotebookActionsToggle } from "../NotebookActionsToggle";
import { CopilotToggle } from "./CopilotToggle";
import { Presence } from "./Presence";
import { ShareToggle } from "./ShareToggle";

export function NotebookToolbar() {
  const [isCopilotEnabled] = useFeature("ai-copilot");

  const { error, readOnly, unrecoverable, showPublicIndicator, isLoading } =
    useSelector(selectComponentState);

  return (
    <Utils>
      {unrecoverable && <PlugsIcon iconType="plugs" />}
      {showPublicIndicator && (
        <PublicIndicator>
          <Icon iconType="globe" />
          Public
        </PublicIndicator>
      )}

      <Presence />

      {
        // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
        !readOnly && !error && !isLoading && (
          <>
            <ShareToggle />
            {isCopilotEnabled && <CopilotToggle />}
            <DataSourceToggle />
            <NotebookActionsToggle />
          </>
        )
      }
    </Utils>
  );
}

const selectComponentState = createSelector(
  [
    selectNotebookError,
    selectNotebookReadOnly,
    selectNotebookUnrecoverable,
    selectNotebookVisibility,
    selectNotebookLoading,
    selectNotebookTitle,
  ],
  (error, readOnly, unrecoverable, visibility, isLoading, title) => ({
    error,
    readOnly,
    unrecoverable,
    showPublicIndicator: visibility === "public" && !readOnly,
    isLoading,
    title,
  }),
  { memoizeOptions: { resultEqualityCheck: shallowEqual } },
);

const PublicIndicator = styled.span`
  color: ${(props) => props.theme.colorSecondary400};
  font: ${(props) => props.theme.fontStudioHeadingsH5ShortHand};
  display: flex;
  align-items: center;
  gap: 4px;
`;

const PlugsIcon = styled(Icon)`
  color: ${(props) => props.theme.colorBase600};
  height: 14px;
  width: 14px;
  margin-right: 4px;
`;

const Utils = styled.div`
  margin-left: auto;
  align-items: center;
  justify-self: flex-end;
  display: flex;
  gap: 8px;
  flex: 0 0 1;
  width: min-content;
`;
