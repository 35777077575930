import { useEffect, useState } from "react";

import { setZeroTimeout } from "../../../../utils";
import { getCursorHeight } from "../utils";

export function useCursorHeight(
  container: HTMLElement,
  value: string,
  offset?: number,
) {
  const [cursorHeight, setCursorHeight] = useState(0);
  useEffect(() => {
    setZeroTimeout(() => {
      const height = getCursorHeight(container, value, offset ?? 0);
      if (height) {
        setCursorHeight(height);
      }
    });
  }, [container, value, offset]);

  return cursorHeight;
}
