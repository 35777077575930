import { styled } from "styled-components";

import { GridContainer } from "../Container";
import { PAGE_GAP } from "./constants";

export const PageContainer = styled(GridContainer)`
  position: relative;
  grid:
    "header" auto
    "content" 1fr
    / 1fr;
  row-gap: ${PAGE_GAP};
  width: 100%;
`;
