import { DEFAULT_FIELDS } from "../../../constants";
import type { LogCell, ProviderEvent } from "../../../types";

const ATTRIBUTES_PREFIX = "attributes.";

export function getDisplayFields(cell: LogCell): Array<string> {
  return cell.displayFields || DEFAULT_FIELDS;
}

export function formatField(key: string) {
  if (key === "document") {
    return "Document";
  }

  if (key === "timestamp") {
    return "Timestamp";
  }

  if (isAttributeKey(key)) {
    return stripAttributePrefix(key);
  }

  return key;
}

export function isAttributeKey(key: string) {
  return key.startsWith(ATTRIBUTES_PREFIX);
}

export function formatAttributeKey(key: string) {
  return `${ATTRIBUTES_PREFIX}${key}`;
}

export function stripAttributePrefix(key: string) {
  return key.slice(ATTRIBUTES_PREFIX.length);
}

export function containsExportedLogs(cell: LogCell): boolean {
  if (!cell.dataLinks) {
    return false;
  }

  return cell.dataLinks.some((link) => link.startsWith("data:"));
}

/**
 * Returns the keys of all attributes that have the same value across all the
 * given provider events.
 *
 * The title of events is compared as well, and is returned as `"body"` if it's
 * the same for all events.
 */
export function getKeysWithSameValue(
  events: Array<ProviderEvent>,
): Array<string> {
  const firstEvent = events[0];
  if (!firstEvent) {
    return [];
  }

  const keyValueMap: Record<string, string> = {};
  keyValueMap.body = firstEvent.title;
  for (const [key, value] of Object.entries(firstEvent.attributes)) {
    keyValueMap[formatAttributeKey(key)] = value;
  }

  for (const record of events) {
    for (const [key, value] of Object.entries(keyValueMap)) {
      const shouldDelete = isAttributeKey(key)
        ? record.attributes[stripAttributePrefix(key)] !== value
        : key === "body" && record.title !== value;
      if (shouldDelete) {
        delete keyValueMap[key];
      }
    }
  }

  return Object.keys(keyValueMap);
}
