import type { TagDescription } from "@reduxjs/toolkit/dist/query";

import type {
  Cell,
  NewSnippet,
  Snippet,
  SnippetSummary,
  UpdateSnippet,
} from "../types";
import { baseApi } from "./base";
import { jsonWithTextErrorResponseHandler, withQueryArgs } from "./utils";

export const snippetsApi = baseApi
  .injectEndpoints({
    endpoints: (builder) => ({
      createSnippet: builder.mutation<
        Snippet,
        { workspaceId: string; newSnippet: NewSnippet }
      >({
        query: ({ workspaceId, newSnippet }) => ({
          url: `/workspaces/${workspaceId}/snippets`,
          method: "POST",
          body: newSnippet,
          responseHandler: jsonWithTextErrorResponseHandler,
        }),
      }),
      deleteSnippet: builder.mutation<
        void,
        { workspaceId: string; snippetName: string }
      >({
        query: ({ workspaceId, snippetName }) => ({
          url: `/workspaces/${workspaceId}/snippets/${snippetName}`,
          method: "DELETE",
        }),
      }),
      expandSnippet: builder.query<
        Array<Cell>,
        { workspaceId: string; snippetName: string }
      >({
        query: ({ workspaceId, snippetName }) =>
          `/workspaces/${workspaceId}/snippets/${snippetName}/expand`,
      }),
      getSnippetBody: builder.query<
        string,
        { startCellId: string; endCellId: string; notebookId: string }
      >({
        query: ({ startCellId, endCellId, notebookId }) => ({
          url: withQueryArgs(`/notebooks/${notebookId}/snippet.jsonnet`, {
            // biome-ignore lint/style/useNamingConvention: API field name is correct
            start_cell_id: startCellId,
            // biome-ignore lint/style/useNamingConvention: API field name is correct
            end_cell_id: endCellId,
          }),
          responseHandler: "text",
        }),
      }),
      getSnippetByName: builder.query<
        Snippet,
        { workspaceId: string; snippetName: string }
      >({
        query: ({ workspaceId, snippetName }) =>
          `/workspaces/${workspaceId}/snippets/${snippetName}`,
      }),
      listSnippets: builder.query<
        Array<SnippetSummary>,
        { workspaceId: string }
      >({
        query: ({ workspaceId }) => `/workspaces/${workspaceId}/snippets`,
      }),
      updateSnippet: builder.mutation<
        Snippet,
        {
          workspaceId: string;
          snippetName: string;
          updateSnippet: UpdateSnippet;
        }
      >({
        query: ({ workspaceId, snippetName, updateSnippet }) => ({
          url: `/workspaces/${workspaceId}/snippets/${snippetName}`,
          method: "PATCH",
          body: updateSnippet,
          responseHandler: jsonWithTextErrorResponseHandler,
        }),
      }),
    }),
  })
  .enhanceEndpoints({
    addTagTypes: ["Snippet", "SnippetSummary"],
    endpoints: {
      createSnippet: {
        invalidatesTags: [{ type: "SnippetSummary", id: "LIST" }],
      },
      deleteSnippet: {
        invalidatesTags: (_data, _error, { snippetName }) => [
          { type: "Snippet", id: snippetName },
          { type: "SnippetSummary", id: snippetName },
          { type: "SnippetSummary", id: "LIST" },
        ],
      },
      getSnippetByName: {
        providesTags: (_data, _error, { snippetName }) => [
          { type: "Snippet", id: snippetName },
        ],
      },
      listSnippets: {
        providesTags: (snippetSummaries) => {
          const summaryTags =
            snippetSummaries?.map(
              ({ name }): TagDescription<"SnippetSummary"> => ({
                type: "SnippetSummary",
                id: name,
              }),
            ) ?? [];

          return [{ type: "SnippetSummary", id: "LIST" }, ...summaryTags];
        },
      },
      updateSnippet: {
        invalidatesTags: (_data, _error, { snippetName }) => [
          { type: "Snippet", id: snippetName },
          { type: "SnippetSummary", id: snippetName },
        ],
      },
    },
  });

export const {
  useCreateSnippetMutation,
  useGetSnippetBodyQuery,
  useGetSnippetByNameQuery,
  useListSnippetsQuery,
  useUpdateSnippetMutation,
} = snippetsApi;
