import { useHandler } from "@fiberplane/hooks";
import { useState } from "react";
import { css, styled } from "styled-components";

import { Button } from "@fiberplane/ui";
import { IMAGE_MIME_TYPES } from "../../../constants";
import { useFileInput } from "../../../hooks";
import { readFile } from "../../../utils";
import type { DropZoneState } from "../../UI";

type Props = {
  setImage(url: string): void;
  hint?: boolean;
  state: DropZoneState;
};

export function ImageSelector(props: Props): JSX.Element {
  const { setImage, hint = false, state } = props;
  const [container, setContainer] = useState<HTMLElement | null>(null);
  const handler = useHandler((changedState) => {
    // Is something still in progress? if so, cancel it
    if (changedState.state === "selected") {
      readFile(changedState.file).then((result) => setImage(result));
    }
  });

  const browse = useFileInput({
    id: "avatar-updater",
    acceptFileTypes: IMAGE_MIME_TYPES.join(", "),
    handler,
    parentNode: container,
  });

  return (
    <DropContainer $state={state} onClick={browse} ref={setContainer}>
      {state === "hover" || state === "valid" ? (
        "Drop image here..."
      ) : (
        <>
          {hint && "Please select an image"}
          <Button
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              browse();
            }}
            onKeyDown={(event: React.KeyboardEvent) => {
              if (event.key === "Enter" || event.key === " ") {
                browse();
              }
            }}
          >
            Browse Image
          </Button>
        </>
      )}
    </DropContainer>
  );
}

const DropContainer = styled.div<{ $state: DropZoneState }>(
  ({ $state, theme }) => css`
    box-sizing: border-box;
    border-radius: ${theme.radius.default};
    background-color: ${$state === "hover" ? "#fff" : "#e3efff"};
    color: #4797ff;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    border-width: 1px;
    border-style: dashed;
    position: relative;
    border-color: ${
      /* sc-custom "ignore this" */
      $state === "hover" || $state === "valid" ? "#4797ff" : "#e3efff"
    };
  `,
);
