import { generatePath } from "react-router";

import { ROUTES } from "../constants";

/**
 * Creates a relative link to a notebook, based on its ID and title.
 */
export function createNotebookLink(
  workspaceName: string,
  id: string,
  title: string,
): string {
  const escapedTitle = title
    .replace(/'/g, "")
    .replace(/\W/g, "-")
    .replace(/-{2,}/g, "-");

  return generatePath(ROUTES.Notebook, {
    workspaceName,
    notebookId: `${escapedTitle}-${id}`,
  });
}
