/* stylelint-disable scale-unlimited/declaration-strict-value */
import { styled } from "styled-components";

export const OutputContainer = styled.div`
  border: 1px solid #f6f6f6;
  border-top: none;
  border-bottom-right-radius: 6px;
  display: flex;
  flex: 1;
  margin-bottom: 10px; /* temp spacing fix. */
`;
