import { useHandler } from "@fiberplane/hooks";

import { cancelEvent } from "@fiberplane/ui";
import { CellById } from "../../../../CellById";
import {
  closeContextMenu,
  deleteFromCursor,
  moveCursor,
  openContextMenu,
  removeCell,
  replaceText,
  setNotebookFocused,
  splitCell,
  toggleFormatting,
} from "../../../../actions";
import {
  CELL_TYPE_SHORTCUTS,
  FRONT_MATTER_CELL_ID,
  GLOBAL_TIME_RANGE_ID,
  MAX_LIST_ITEM_LEVEL,
  THREAD_DELETE_CONFIRM,
  isSurrogateId,
} from "../../../../constants";
import { useKeyboardHandlers } from "../../../../hooks";
import {
  selectActiveEditor,
  selectActiveNotebookId,
  selectAppThreads,
  selectCell,
  selectCellOrSurrogate,
  selectCellText,
  selectContextMenu,
  selectFocusedCell,
  selectFocusedCellOrSurrogate,
  selectNotebookFocus,
  selectNotebookFocused,
  selectRelativeCell,
  selectRelativeCellOrSurrogate,
  selectRelativeField,
} from "../../../../selectors";
import type { RootState } from "../../../../state";
import {
  dispatch,
  getState,
  wrapDispatchWithActiveNotebook,
} from "../../../../store";
import {
  addCell,
  changeCellType,
  deleteCellAndThread,
  focusCell,
  handleBlockingCells,
  invokeProviderCell,
  mergeCells,
  moveCell,
  replaceCells,
  selectCellFieldContent,
  setThreadDeletionPrompt,
  toggleLockFocusedCells,
  updateCell,
} from "../../../../thunks";
import {
  type Cell,
  type CellTypeProperties,
  type ContentCell,
  type NotebookFocus,
  isCellTypeWithTextField,
  isContentCell,
  supportsEntityFormatting,
} from "../../../../types";
import {
  charCount,
  charSlice,
  formatTableRowValueId,
  getField,
  getFocusCellId,
  getFocusOffset,
  getNewCellProperties,
  isMac,
  makeCell,
  noop,
  parseTableRowValueId,
  track,
  uuid64,
} from "../../../../utils";
import {
  CommentInputById,
  getContainerElForCellField,
  getCoordinatesForOffset,
  getLineHeightForContainer,
  getNotebookFocusForRange,
  getOffsetForCoordinates,
  replaceSelection,
} from "../../../Cell";
import { getFieldSupportsSuggestions } from "../../getFieldSupportsSuggestions";
import { findWordStart } from "./utils";

type EventHandlers = {
  onBeforeInput?: (event: InputEvent) => void;
  onBlur: React.FocusEventHandler;
  onFocus: React.FocusEventHandler;
  onKeyDown: React.KeyboardEventHandler;
  onKeyUp: React.KeyboardEventHandler;
};

/**
 * Returns and attaches handlers for handling events at the notebook level.
 */
export function useNotebookEventHandlers(
  containerRef: React.MutableRefObject<HTMLElement | undefined>,
  readOnly: boolean,
): EventHandlers {
  return {
    ...useKeyboardHandlers(readOnly ? noop : handleKeyDown),
    onBeforeInput: readOnly ? undefined : handleBeforeInput,
    onBlur: useHandler((event) => handleBlur(containerRef, event)),
    onFocus: handleFocus,
  };
}

function handleBeforeInput(event: InputEvent) {
  if (
    eventTargetIsLabelsEditorInput(event) ||
    eventTargetHasPreventRte(event)
  ) {
    return;
  }

  switch (event.inputType) {
    case "insertLineBreak": {
      if (handleLinebreak()) {
        cancelEvent(event);
      }
      break;
    }

    case "insertText": {
      if (handleOtherInput(event.data ?? "")) {
        cancelEvent(event);
      }

      break;
    }

    case "insertReplacementText": {
      event.dataTransfer?.items[0]?.getAsString((input) => {
        if (handleOtherInput(input, event.getTargetRanges()[0])) {
          cancelEvent(event);
        }
      });
      break;
    }

    case "historyUndo":
    case "deleteContentForward":
    case "deleteContentBackward":
    case "deleteSoftLineForward":
    case "deleteSoftLineBackward": {
      // Don't deal with these for now
      cancelEvent(event);
      break;
    }

    default:
      console.debug(`Ignored unknown input type: ${event.inputType}`);
  }
}

function handleBlur(
  containerRef: React.MutableRefObject<HTMLElement | undefined>,
  event: React.FocusEvent,
) {
  if (!selectNotebookFocused(getState())) {
    return;
  }

  const container = containerRef.current;
  const shouldStayFocused =
    container && event.relatedTarget
      ? container.contains(event.relatedTarget)
      : /* We don't know where the focus went so must assume it's gone */ false;
  if (!shouldStayFocused) {
    dispatch(setNotebookFocused(false));
  }
}

function handleFocus() {
  if (!selectNotebookFocused(getState())) {
    dispatch(setNotebookFocused(true));
  }
}

function handleKeyDown(event: React.KeyboardEvent) {
  if (handleKey(event)) {
    cancelEvent(event);
  }
}

function handleKey(event: React.KeyboardEvent): boolean | undefined {
  // TODO: Handle the event listeners conditionally based on focus
  if (
    eventTargetIsLabelsEditorInput(event) ||
    eventTargetHasDisabledNotebookKeyboardHandlers(event)
  ) {
    return;
  }

  switch (event.key) {
    case "ArrowDown":
      return handleArrowDown(event);
    case "ArrowLeft":
      return handleArrowLeft(event);
    case "ArrowRight":
      return handleArrowRight(event);
    case "ArrowUp":
      return handleArrowUp(event);
    case "Backspace":
      return handleBackspace(event);
    case "Delete":
      return handleDelete(event);
    case "End":
      return handleEnd(event);
    case "Enter":
      return handleEnter(event);
    case "Escape":
      return handleEscape();
    case "Home":
      return handleHome(event);
    case "Tab":
      return handleTab(event);
    case " ":
      return handleSpace(event);
    case "`":
      return handleBacktick(event);
    case "-":
      return handleDash(event);
  }

  if (isMac ? event.metaKey : event.ctrlKey) {
    if (event.shiftKey) {
      switch (event.code) {
        case "Digit0":
          return handleRevertContentCellType();
        case "KeyL":
          return handleReadonlyToggle();
      }
    } else {
      return handleFormattingShortcuts(event);
    }
  }
}

function handleArrowDown(event: React.KeyboardEvent) {
  const state = getState();
  const focusedCell = selectFocusedCellOrSurrogate(state);
  if (!focusedCell) {
    return;
  }

  if (event.altKey) {
    return handleSwapCell(focusedCell, 1);
  }

  const extendSelection = event.shiftKey;

  const focus = selectNotebookFocus(state);
  const field = getField(focus);

  const containerEl = getContainerElForCellField(focusedCell.id, field);

  if (containerEl && focus.type !== "none") {
    const options = {
      containerEl,
      cell: focusedCell,
      delta: 1,
      extendSelection,
    } as const;
    if (
      handleMoveCursorUpOrDownWithinField(options) ||
      handleMoveCursorUpOrDownBetweenFields(options)
    ) {
      return true;
    }
  }

  const targetField = selectRelativeField(state, focusedCell.id, field, 1);
  if (targetField) {
    dispatch(
      focusCell({
        cellId: targetField.cellId,
        field: targetField.field,
        offset: 0,
        extendSelection: false,
      }),
    );
    return true;
  }

  const targetCell = selectRelativeCellOrSurrogate(state, focusedCell.id, 1);
  if (!targetCell) {
    return handleEnd(event);
  }

  if (isContentCell(targetCell)) {
    handleMoveCursorToCell({
      containerEl,
      delta: 1,
      extendSelection,
      focusedCell,
      targetCell,
    });
  } else {
    // Move to a cell without cursor position:
    dispatch(focusCell({ cellId: targetCell.id, extendSelection }));
  }

  return true;
}

function handleArrowLeft(event: React.KeyboardEvent) {
  const state = getState();
  const { focus, contextMenu } = selectActiveEditor(state);

  if (focus.type === "none") {
    return;
  }

  if (
    contextMenu?.typeAheadOffset !== undefined &&
    focus.type === "collapsed" &&
    focus.cellId === contextMenu.cellId &&
    focus.offset !== undefined &&
    focus.offset <= contextMenu.typeAheadOffset
  ) {
    notebookDispatch(closeContextMenu());
  }

  if (isMac && event.metaKey) {
    return handleHome(event);
  }

  const modifierPressed = isMac ? event.altKey : event.ctrlKey;
  notebookDispatch(
    moveCursor({
      delta: -1,
      extendSelection: event.shiftKey,
      unit: modifierPressed ? "word" : "grapheme_cluster",
    }),
  );

  return true;
}

function handleArrowRight(event: React.KeyboardEvent) {
  const { contextMenu, focus } = selectActiveEditor(getState());
  if (focus.type === "none") {
    return;
  }

  if (
    contextMenu?.typeAheadOffset !== undefined &&
    focus.type === "collapsed" &&
    focus.cellId === contextMenu.cellId &&
    focus.offset !== undefined &&
    focus.offset >=
      contextMenu.typeAheadOffset + charCount(contextMenu.typeAheadText ?? "")
  ) {
    notebookDispatch(closeContextMenu());
  }

  if (isMac && event.metaKey) {
    return handleEnd(event);
  }

  const modifierPressed = isMac ? event.altKey : event.ctrlKey;
  notebookDispatch(
    moveCursor({
      delta: 1,
      extendSelection: event.shiftKey,
      unit: modifierPressed ? "word" : "grapheme_cluster",
    }),
  );
  return true;
}

function handleArrowUp(event: React.KeyboardEvent) {
  const state = getState();
  const focusedCell = selectFocusedCellOrSurrogate(state);
  if (!focusedCell) {
    return;
  }

  if (event.altKey) {
    return handleSwapCell(focusedCell, -1);
  }

  const extendSelection = event.shiftKey;

  const focus = selectNotebookFocus(state);
  const field = getField(focus);

  const containerEl = getContainerElForCellField(focusedCell.id, field);

  if (containerEl && focus.type !== "none") {
    const options = {
      containerEl,
      cell: focusedCell,
      delta: -1,
      extendSelection,
    } as const;
    if (
      handleMoveCursorUpOrDownWithinField(options) ||
      handleMoveCursorUpOrDownBetweenFields(options)
    ) {
      return true;
    }
  }

  const targetField = selectRelativeField(state, focusedCell.id, field, -1);
  if (targetField) {
    const cell = selectCellOrSurrogate(state, targetField.cellId);
    dispatch(
      focusCell({
        cellId: targetField.cellId,
        field: targetField.field,
        offset:
          cell && charCount(selectCellText(state, cell, targetField.field)),
        extendSelection: false,
      }),
    );
    return true;
  }

  const targetCell = selectRelativeCellOrSurrogate(state, focusedCell.id, -1);
  if (!targetCell) {
    return handleHome(event);
  }

  if (isContentCell(targetCell)) {
    handleMoveCursorToCell({
      containerEl,
      delta: -1,
      extendSelection,
      focusedCell,
      targetCell,
    });
  } else {
    // Move to a cell without cursor position:
    dispatch(focusCell({ cellId: targetCell.id, extendSelection }));
  }

  return true;
}

type MoveCursorUpOrDownParams = {
  containerEl: HTMLElement;
  cell: Cell;
  delta: -1 | 1;
  extendSelection: boolean;
};

function handleMoveCursorUpOrDownWithinField({
  containerEl,
  cell,
  delta,
  extendSelection,
}: MoveCursorUpOrDownParams): boolean {
  const state = getState();
  const focus = selectNotebookFocus(state);
  const field = getField(focus);

  const text = selectCellText(state, cell, field);
  const coordinates = getCoordinatesForOffset(
    containerEl,
    text,
    getFocusOffset(focus),
  );
  if (!coordinates) {
    return false;
  }

  const lineHeight = getLineHeightForContainer(containerEl);
  if (delta === 1) {
    const containerRect = containerEl?.getBoundingClientRect();
    if (coordinates.y + lineHeight >= containerRect.height) {
      return false;
    }
  } else if (coordinates.y - lineHeight <= 0) {
    return false;
  }

  // Move the cursor within the cell:
  const offset = getOffsetForCoordinates(containerEl, text, {
    x: coordinates.x,
    y: coordinates.y + delta * lineHeight,
  });
  dispatch(focusCell({ cellId: cell.id, field, offset, extendSelection }));
  return true;
}

/**
 * Moves the cursor up or down between fields within a cell, while attempting
 * to maintain the horizontal cursor position.
 */
function handleMoveCursorUpOrDownBetweenFields({
  containerEl,
  cell,
  delta,
}: MoveCursorUpOrDownParams): boolean {
  if (cell.type !== "table") {
    return false; // We only support this for tables so far.
  }

  const state = getState();
  const focus = selectNotebookFocus(state);
  const focusedField = getField(focus);
  if (!focusedField) {
    return false;
  }

  const [focusedRowId, columnId] = parseTableRowValueId(focusedField);
  const focusedRowIndex = cell.rows.findIndex((row) => row.id === focusedRowId);
  const targetRowIndex = focusedRowIndex + delta;
  const targetRowId = cell.rows[targetRowIndex]?.id;
  if (!targetRowId) {
    return false;
  }

  const focusedText = selectCellText(state, cell, focusedField);
  const coordinates = getCoordinatesForOffset(
    containerEl,
    focusedText,
    getFocusOffset(focus),
  );
  if (!coordinates) {
    return false;
  }

  const targetField = formatTableRowValueId(targetRowId, columnId);
  const targetContainerEl = getContainerElForCellField(cell.id, targetField);
  if (!targetContainerEl) {
    return false;
  }

  const lineHeight = getLineHeightForContainer(targetContainerEl);
  const targetContainerRect = targetContainerEl.getBoundingClientRect();
  const targetText = selectCellText(state, cell, targetField);
  const offset = getOffsetForCoordinates(targetContainerEl, targetText, {
    x: coordinates.x,
    y:
      delta === 1
        ? lineHeight / 2
        : targetContainerRect.height - lineHeight / 2,
  });

  dispatch(focusCell({ cellId: cell.id, field: targetField, offset }));
  return true;
}

type MoveCursorToCellParams = {
  containerEl: HTMLElement | null;
  delta: -1 | 1;
  extendSelection: boolean;
  focusedCell: Cell;
  targetCell: ContentCell;
};

/**
 * Moves the cursor up or down between cells, while attempting to maintain the
 * horizontal cursor position.
 */
function handleMoveCursorToCell({
  containerEl,
  delta,
  extendSelection,
  focusedCell,
  targetCell,
}: MoveCursorToCellParams) {
  const field = undefined;
  const targetContainerEl = getContainerElForCellField(targetCell.id, field);
  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
  if (!containerEl || !targetContainerEl) {
    dispatch(focusCell({ cellId: targetCell.id, offset: 0, extendSelection }));
    return;
  }

  const state = getState();
  const focus = selectNotebookFocus(state);

  const containerRect = containerEl.getBoundingClientRect();
  const targetContainerRect = targetContainerEl.getBoundingClientRect();
  const deltaX = containerRect.left - targetContainerRect.left;

  const text = selectCellText(state, focusedCell, field);
  const coordinates = getCoordinatesForOffset(
    containerEl,
    text,
    getFocusOffset(focus),
  );

  const lineHeight = getLineHeightForContainer(containerEl);
  const offset = coordinates
    ? getOffsetForCoordinates(targetContainerEl, targetCell.content, {
        x: coordinates.x + deltaX,
        y:
          delta === 1
            ? lineHeight / 2
            : targetContainerRect.height - lineHeight / 2,
      })
    : 0;

  dispatch(focusCell({ cellId: targetCell.id, offset, extendSelection }));
}

function handleSwapCell(cell: Cell, delta: -1 | 1): boolean {
  if (cell.readOnly) {
    CellById.get(cell.id)?.shake();
    return false;
  }

  const targetCell = selectRelativeCellOrSurrogate(getState(), cell.id, delta);
  if (!targetCell) {
    return false;
  }

  if (isSurrogateId(targetCell.id)) {
    // TODO: Should we nudge?
  } else {
    // Swap cells with Alt modifier:
    dispatch(
      moveCell({
        subjectCellId: cell.id,
        position: delta === 1 ? "after" : "before",
        targetCellId: targetCell.id,
      }),
    );
  }

  return true;
}

function handleBackspace(event: React.KeyboardEvent) {
  const state = getState();
  const focusedCell = selectFocusedCellOrSurrogate(state);
  const hasBlockingCells = dispatch(handleBlockingCells());

  if (
    !focusedCell ||
    focusedCell.id === GLOBAL_TIME_RANGE_ID ||
    focusedCell.id === FRONT_MATTER_CELL_ID ||
    hasBlockingCells
  ) {
    return;
  }

  const cellAbove = selectRelativeCell(state, focusedCell?.id, -1);
  if (
    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
    !isContentCell(focusedCell) &&
    !isCellTypeWithTextField(focusedCell.type)
  ) {
    const notebookId = selectActiveNotebookId(getState());
    track("notebook | remove cell", {
      source: "backspace",
      notebookId,
      cellType: focusedCell.type,
    });

    notebookDispatch(
      removeCell(focusedCell.id, {
        cursorDirection: "backward",
        focusCell: cellAbove,
      }),
    );
    return true;
  }

  const focus = selectNotebookFocus(state);
  if (focus.type === "none") {
    return;
  }

  const field = getField(focus);
  if (getFocusOffset(focus) === 0 && focus.type !== "selection") {
    return handleBackspaceAtStart({
      state,
      focusedCell,
      cellAbove,
      field,
    });
  }

  const containerEl = getContainerElForCellField(focusedCell.id, field);
  if (isMac && event.metaKey && containerEl) {
    const focusOffset = getFocusOffset(focus);
    const text = selectCellText(state, focusedCell, field);
    dispatch(
      replaceSelection(
        {
          type: "selection",
          anchor: {
            cellId: focusedCell.id,
            field,
            offset: getStartOfLineOffset(containerEl, text, focusOffset),
          },
          focus: { cellId: focusedCell.id, field, offset: focusOffset },
        },
        "",
      ),
    );
  } else {
    const modifierPressed = isMac ? event.altKey : event.ctrlKey;
    notebookDispatch(
      deleteFromCursor({
        cursorDirection: "backward",
        unit: modifierPressed ? "word" : "grapheme_cluster",
      }),
    );
  }

  return true;
}

function handleBackspaceAtStart({
  state,
  focusedCell,
  cellAbove,
  field,
}: {
  state: RootState;
  focusedCell: Cell;
  cellAbove: Cell | undefined;
  field?: string;
}) {
  // Prevent deletion or merging of entire cell when the focus is set to a field:
  if (field) {
    return;
  }

  // We're not passing `field` to selectCellText because we shouldn't have treat
  // backspacing within a `field` like we do for a "top-level" cell
  if (selectCellText(state, focusedCell) === "") {
    const canRevertToText =
      focusedCell.type === "checkbox" || focusedCell.type === "list_item";
    if (canRevertToText) {
      const notebookId = selectActiveNotebookId(getState());
      track("notebook | change cell type", {
        notebookId,
        cellType: "text",
        source: "backspace at start",
      });

      dispatch(changeCellType(focusedCell.id, { type: "text" }));
    } else {
      const notebookId = selectActiveNotebookId(getState());
      track("notebook | remove cell", {
        source: "backspace",
        notebookId,
        cellType: focusedCell.type,
      });

      notebookDispatch(
        removeCell(focusedCell.id, {
          cursorDirection: "backward",
          focusCell: cellAbove,
        }),
      );
    }

    return true;
  }

  if (cellAbove) {
    const cellAboveIsBlocking = dispatch(handleBlockingCells([cellAbove]));
    if (cellAboveIsBlocking) {
      return;
    }

    if (isContentCell(cellAbove)) {
      const notebookId = selectActiveNotebookId(getState());
      track("notebook | merge cells", {
        notebookId,
        cellType: cellAbove.type,
      });

      dispatch(
        mergeCells(
          { sourceId: focusedCell.id, targetId: cellAbove.id },
          { cursorDirection: "backward", focusCell: cellAbove },
        ),
      );
    } else {
      const notebookId = selectActiveNotebookId(getState());
      track("notebook | remove cell", {
        source: "backspace",
        notebookId,
        cellType: focusedCell.type,
      });

      notebookDispatch(removeCell(cellAbove.id));
    }

    return true;
  }
}

function handleBacktick(event: React.KeyboardEvent) {
  const state = getState();
  const focusedCell = selectFocusedCell(state);
  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
  if (!focusedCell || !isContentCell(focusedCell)) {
    return handleOther(event);
  }

  const focus = selectNotebookFocus(state);
  if (
    focus.type === "collapsed" &&
    focus.offset === 2 &&
    focusedCell.content.startsWith("``")
  ) {
    const notebookId = selectActiveNotebookId(state);
    track("notebook | change cell type", {
      notebookId,
      cellType: "code",
      source: "backtick",
    });

    dispatch(
      changeCellType(
        focusedCell.id,
        { type: "code" },
        {
          content: focusedCell.content.slice(3),
          focus: { type: "collapsed", cellId: focusedCell.id, offset: 0 },
        },
      ),
    );
    return true;
  }

  return handleOther(event);
}

function handleDash(event: React.KeyboardEvent) {
  const state = getState();
  const focusedCell = selectFocusedCell(state);
  const notebookId = selectActiveNotebookId(state);

  const modifierKeyPressed = isMac ? event.metaKey : event.ctrlKey;
  if (modifierKeyPressed) {
    return false;
  }

  if (focusedCell?.type !== "text") {
    return handleOther(event);
  }

  // When adding three dashes to an empty cell: replace current cell with
  // Divider & add new cell below
  const { content, id } = focusedCell;
  if (content === "--") {
    track("notebook | add cell", {
      cellType: "text",
      notebookId,
      position: "after",
      source: "double dash",
    });

    dispatch(
      replaceCells({
        oldCellIds: [id],
        newCells: [
          { id, type: "divider" },
          makeCell(uuid64(), { type: "text" }),
        ],
      }),
    );
    return true;
  }

  const focus = selectNotebookFocus(state);
  if (focus.type !== "collapsed" || focus.offset === undefined) {
    return handleOther(event);
  }

  // When adding three dashes in the beginning of the content: add Divider cell above
  const focusOffset = focus.offset;
  if (focusOffset === 2 && content.startsWith("--")) {
    track("notebook | add cell", {
      cellType: "divider",
      notebookId,
      position: "before",
      source: "double dash",
    });

    dispatch(
      replaceCells({
        oldCellIds: [id],
        newCells: [
          { id: uuid64(), type: "divider" },
          makeCell(id, { type: "text" }),
        ],
        mergeOffset: 2,
      }),
    );
    return true;
  }

  // When adding three dashes at the end of an input cell: remove the two
  // dashes from the input and insert Divider below
  const contentLength = charCount(content);
  if (focusOffset === contentLength && content.endsWith("--")) {
    dispatch(
      replaceCells({
        oldCellIds: [id],
        newCells: [
          makeCell(id, { type: "text" }),
          { id: uuid64(), type: "divider" },
          makeCell(uuid64(), { type: "text" }),
        ],
        splitOffset: contentLength - 2,
      }),
    );
    return true;
  }

  // When adding three dashes in the middle of content: split cell & remove the
  // two dashes from input
  if (
    focusOffset >= 2 &&
    focusOffset !== contentLength &&
    charSlice(content, focusOffset - 2, focusOffset) === "--"
  ) {
    dispatch(
      replaceCells({
        oldCellIds: [id],
        newCells: [
          makeCell(id, { type: "text" }),
          makeCell(uuid64(), { type: "divider" }),
          makeCell(uuid64(), { type: "text" }),
        ],
        splitOffset: focusOffset - 2,
        mergeOffset: focusOffset,
      }),
    );
    return true;
  }

  return handleOther(event);
}

function handleDelete(event: React.KeyboardEvent) {
  const state = getState();
  const focusedCell = selectFocusedCellOrSurrogate(state);
  const cellId = focusedCell?.id;
  if (
    !cellId ||
    cellId === GLOBAL_TIME_RANGE_ID ||
    !focusedCell ||
    dispatch(handleBlockingCells())
  ) {
    return;
  }

  const focus = selectNotebookFocus(state);
  const cellBelow = selectRelativeCell(state, cellId, 1);
  const text = selectCellText(state, focusedCell, getField(focus));
  if (
    focus.type === "collapsed" &&
    focus.offset === charCount(text) &&
    cellBelow
  ) {
    const cellBelowIsBlocking = dispatch(handleBlockingCells([cellBelow]));

    if (text === "") {
      const notebookId = selectActiveNotebookId(getState());
      track("notebook | remove cell", {
        source: "delete",
        notebookId,
        cellType: focusedCell.type,
      });

      notebookDispatch(
        removeCell(focusedCell.id, {
          cursorDirection: "forward",
          focusCell: cellBelow,
        }),
      );
    } else if (cellBelowIsBlocking) {
      return;
    } else if (isContentCell(cellBelow)) {
      const notebookId = selectActiveNotebookId(getState());
      track("notebook | merge cells", {
        notebookId,
        cellType: focusedCell.type,
      });

      dispatch(
        mergeCells({ sourceId: cellBelow.id, targetId: focusedCell.id }),
      );
    } else {
      const notebookId = selectActiveNotebookId(getState());
      track("notebook | remove cell", {
        source: "delete",
        notebookId,
        cellType: focusedCell.type,
      });

      notebookDispatch(removeCell(cellBelow.id));
    }

    return true;
  }

  const modifierPressed = isMac ? event.altKey : event.ctrlKey;
  notebookDispatch(
    deleteFromCursor({
      cursorDirection: "forward",
      unit: modifierPressed ? "word" : "grapheme_cluster",
    }),
  );
  return true;
}

function handleEnd(event: React.KeyboardEvent) {
  const state = getState();
  const focusedCell = selectFocusedCellOrSurrogate(getState());
  const focus = selectNotebookFocus(state);
  if (
    !focusedCell ||
    focusedCell.id === GLOBAL_TIME_RANGE_ID ||
    focus.type === "none"
  ) {
    return;
  }

  const field = getField(focus);
  const text = selectCellText(state, focusedCell, field);

  let offset: number | undefined;
  const modifierPressed = isMac
    ? event.key === "End" && event.metaKey
    : event.ctrlKey;
  const containerEl = getContainerElForCellField(focusedCell.id, field);
  if (modifierPressed || !containerEl) {
    offset = charCount(text);
  } else {
    const coordinates = getCoordinatesForOffset(
      containerEl,
      text,
      getFocusOffset(focus),
    );
    if (coordinates) {
      offset = getOffsetForCoordinates(containerEl, text, {
        x: containerEl.getBoundingClientRect().width,
        y: coordinates.y,
      });
    }
  }

  dispatch(
    focusCell({
      cellId: focusedCell.id,
      field,
      offset,
      extendSelection: event.shiftKey,
    }),
  );

  return true;
}

function handleEnter(event: React.KeyboardEvent) {
  const state = getState();
  const focusedCell = selectFocusedCellOrSurrogate(state);
  const notebookId = selectActiveNotebookId(state);
  if (!focusedCell || focusedCell.id === GLOBAL_TIME_RANGE_ID) {
    return;
  }

  if (focusedCell.type === "discussion") {
    return handleDiscussionCellEnter(event, focusedCell);
  }

  if (event.ctrlKey || event.metaKey) {
    return handleRunCell(event, notebookId, focusedCell);
  }

  if (event.shiftKey) {
    return handleLinebreak();
  }

  if (!isContentCell(focusedCell)) {
    track("notebook | add cell", {
      cellType: focusedCell.type,
      notebookId,
      position: "after",
      source: "enter",
    });

    dispatch(addCell({ relatedId: focusedCell.id, position: "after" }));
    return true;
  }

  if (!handleInsertCellOrChangeTypeOnEnter(notebookId, focusedCell)) {
    track("notebook | split cell", {
      notebookId,
      cellType: focusedCell.type,
      source: "enter",
    });

    const focus = selectNotebookFocus(state);
    notebookDispatch(splitCell({ focus }));
  }

  return true;
}

function handleLinebreak() {
  const focus = selectNotebookFocus(getState());
  dispatch(replaceSelection(focus, "\n"));

  return true;
}

function handleRunCell(
  event: React.KeyboardEvent,
  notebookId: string,
  cell: Cell,
) {
  track("notebook | run cell", {
    cellType: cell.type,
    notebookId,
    source: "keyboard shortcut",
  });

  if (cell.type !== "provider") {
    return false;
  }

  const modifierPressed = isMac ? event.metaKey : event.ctrlKey;
  if (!modifierPressed) {
    return false;
  }

  dispatch(invokeProviderCell(cell.id));

  if (selectActiveEditor(getState()).contextMenu) {
    notebookDispatch(closeContextMenu());
  }

  return true;
}

function handleInsertCellOrChangeTypeOnEnter(
  notebookId: string,
  cell: ContentCell,
): boolean {
  const state = getState();
  const focus = selectNotebookFocus(state);
  if (focus.type !== "collapsed") {
    return false;
  }

  const text = selectCellText(state, cell, getField(focus));
  if (focus.offset !== charCount(text)) {
    return false;
  }

  const isList = cell.type === "checkbox" || cell.type === "list_item";
  if (isList && text === "") {
    track("notebook | change cell type", {
      notebookId,
      cellType: "text",
      source: "list item empty",
    });

    // Typing Enter in an empty list item or checkbox acts like a Backspace:
    dispatch(changeCellType(cell.id, { type: "text" }));
  } else {
    const properties = getNewCellProperties(cell);

    track("notebook | add cell", {
      cellType: properties?.type || "text",
      notebookId,
      position: "before",
      source: "enter",
    });

    dispatch(
      addCell({
        relatedId: cell.id,
        position: "after",
        properties,
      }),
    );
  }

  return true;
}

function handleEscape() {
  const focusedCell = selectFocusedCellOrSurrogate(getState());

  if (focusedCell?.type === "discussion") {
    const threads = selectAppThreads(getState());
    const showThreadDeletionPrompt = threads.get(
      focusedCell.threadId,
    )?.showThreadDeletionPrompt;

    if (showThreadDeletionPrompt) {
      dispatch(
        setThreadDeletionPrompt({
          showThreadDeletionPrompt: false,
          threadId: focusedCell.threadId,
        }),
      );
    }
  }

  const editor = selectActiveEditor(getState());
  if (editor.contextMenu) {
    notebookDispatch(closeContextMenu());
    return true;
  }
}

function handleFormattingShortcuts(event: React.KeyboardEvent) {
  const state = getState();
  const focusedCell = selectFocusedCellOrSurrogate(state);
  if (
    !focusedCell ||
    focusedCell.id === GLOBAL_TIME_RANGE_ID ||
    focusedCell.id === FRONT_MATTER_CELL_ID
  ) {
    return;
  }

  switch (event.key.toLowerCase()) {
    case "a": {
      const field = getField(selectNotebookFocus(state));
      dispatch(selectCellFieldContent(focusedCell, field));
      return true;
    }

    case "b": {
      if (focusedCell.type !== "heading") {
        if (focusedCell.readOnly) {
          CellById.get(focusedCell.id)?.shake();
        } else {
          notebookDispatch(toggleFormatting({ type: "start_bold" }));
        }

        return true;
      }

      break;
    }

    case "i": {
      if (focusedCell.readOnly) {
        CellById.get(focusedCell.id)?.shake();
      } else {
        notebookDispatch(toggleFormatting({ type: "start_italics" }));
      }

      return true;
    }

    case "u": {
      if (focusedCell.readOnly) {
        CellById.get(focusedCell.id)?.shake();
      } else {
        notebookDispatch(toggleFormatting({ type: "start_underline" }));
      }

      return true;
    }
  }
}

function handleHome(event: React.KeyboardEvent) {
  const state = getState();
  const focus = selectNotebookFocus(state);
  const focusedCell = selectFocusedCellOrSurrogate(state);
  if (
    !focusedCell ||
    focusedCell.id === GLOBAL_TIME_RANGE_ID ||
    focus.type === "none"
  ) {
    return;
  }

  const field = getField(focus);
  const text = selectCellText(state, focusedCell, field);

  const modifierPressed = isMac
    ? event.key === "Home" && event.metaKey
    : event.ctrlKey;
  const containerEl = getContainerElForCellField(focusedCell.id, field);
  const offset =
    containerEl && !modifierPressed
      ? getStartOfLineOffset(containerEl, text, getFocusOffset(focus))
      : 0;

  const extendSelection = event.shiftKey;
  dispatch(
    focusCell({ cellId: focusedCell.id, field, offset, extendSelection }),
  );

  return true;
}

function handleListItemShortcut(
  cell: ContentCell,
  event: React.KeyboardEvent,
  focus: NotebookFocus,
) {
  const periodOffset = getFocusOffset(focus);
  const listItemNumber = Number.parseInt(cell.content, 10);
  if (!listItemNumber) {
    return handleOther(event);
  }

  const isPeriodAfterNumber =
    periodOffset === charCount(listItemNumber.toString()) + 1;
  if (!isPeriodAfterNumber) {
    return handleOther(event);
  }

  const notebookId = selectActiveNotebookId(getState());
  track("notebook | change cell type", {
    notebookId,
    cellType: "list_item",
    source: "list item shortcut",
  });

  dispatch(
    changeCellType(
      cell.id,
      { type: "list_item", listType: "ordered", startNumber: listItemNumber },
      {
        content: charSlice(cell.content, periodOffset),
        focus: { type: "collapsed", cellId: cell.id, offset: 0 },
      },
    ),
  );
  return true;
}

function handleOther(event: React.KeyboardEvent) {
  return handleOtherInput(event.key);
}

function handleOtherInput(input: string, range?: StaticRange) {
  const focus = range
    ? getNotebookFocusForRange(range)
    : selectNotebookFocus(getState());
  const focusCellId = getFocusCellId(focus);
  if (
    !focusCellId ||
    focusCellId === GLOBAL_TIME_RANGE_ID ||
    focusCellId === FRONT_MATTER_CELL_ID
  ) {
    return;
  }

  dispatch(replaceSelection(focus, input));

  handlePostInputActions(input);

  return true;
}

function handlePostInputActions(input: string) {
  const state = getState();
  const focus = selectNotebookFocus(state);
  if (focus.type !== "collapsed") {
    return;
  }

  const { cellId, field, offset } = focus;
  const cell = selectCell(state, cellId);
  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
  if (!cell || !offset) {
    return;
  }

  if (input === "/" && field === undefined && cell.type !== "code") {
    handleOpenSlashMenu(cell, offset);
  } else if (input === "@") {
    handleOpenAtMenu(cell, field, offset);
  } else if (
    field &&
    cell.type === "provider" &&
    getFieldSupportsSuggestions(state, cell, field)
  ) {
    const contextMenu = selectContextMenu(state);

    if (contextMenu?.menuType !== "auto_suggest") {
      const cell = selectCellOrSurrogate(state, cellId);
      const content =
        (cell && charSlice(selectCellText(state, cell, field), 0, offset)) ||
        input;

      const typeAheadOffset = findWordStart(content);
      const typeAheadText = charSlice(content, typeAheadOffset, offset);
      notebookDispatch(
        openContextMenu({
          cellId,
          field,
          menuType: "auto_suggest",
          typeAheadOffset,
          typeAheadText,
        }),
      );
    }
  }
}

function handleOpenAtMenu(
  cell: Cell,
  field: string | undefined,
  offset: number,
) {
  if (supportsEntityFormatting(cell)) {
    const state = getState();
    const previousChar = charSlice(
      selectCellText(state, cell, field),
      offset - 2,
      offset - 1,
    );
    if (
      offset === 1 ||
      previousChar === " " ||
      previousChar === TAB_CHAR ||
      previousChar === "\n" // Fixes FP-2872 however this long condition suggests we should have a helper function to evaluate if the previousChar is "at-mentionable"
    ) {
      notebookDispatch(
        openContextMenu({
          cellId: cell.id,
          field,
          menuType: "at_menu",
          typeAheadOffset: offset,
        }),
      );
    }
  }
}

function handleOpenSlashMenu(cell: Cell, offset: number) {
  const state = getState();

  const lookbackOffset = 5;
  const content = selectCellText(state, cell);
  const prefix =
    offset >= lookbackOffset
      ? charSlice(content, offset - lookbackOffset, offset)
      : undefined;

  if (
    !(
      prefix &&
      ["http:", "https:", "http:/", "https:/"].some((ignoredPrefix) =>
        ignoredPrefix.endsWith(prefix),
      )
    )
  ) {
    notebookDispatch(
      openContextMenu({
        cellId: cell.id,
        menuType: "slash_command",
        typeAheadOffset: offset,
        typeAheadText: "",
      }),
    );
  }
}

function handleSpace(event: React.KeyboardEvent) {
  const state = getState();
  const focus = selectNotebookFocus(state);
  if (focus.type !== "collapsed") {
    return handleOther(event);
  }

  const { cellId, field, offset } = focus;
  const cell = selectCell(state, cellId);
  if (!cell) {
    return;
  }

  if (isMac ? event.metaKey : event.ctrlKey) {
    if (
      field &&
      cell.type === "provider" &&
      getFieldSupportsSuggestions(state, cell, field) &&
      offset !== undefined
    ) {
      notebookDispatch(
        openContextMenu({
          cellId,
          field,
          menuType: "auto_suggest",
          typeAheadOffset: offset,
        }),
      );

      return true;
    }

    return handleOther(event);
  }

  if (!isContentCell(cell)) {
    return handleOther(event);
  }

  const { content, type } = cell;
  if (type !== "code") {
    for (const [shortcut, cellTypeProperties] of CELL_TYPE_SHORTCUTS) {
      if (
        handleCellTypeShortcut(
          cellId,
          cellTypeProperties,
          content,
          offset,
          shortcut,
        )
      ) {
        return true;
      }
    }
  }

  return handleListItemShortcut(cell, event, focus);
}

function handleCellTypeShortcut(
  cellId: string,
  cellTypeProperties: CellTypeProperties,
  content: string,
  offset: number | undefined,
  shortcut: string,
) {
  const numChars = shortcut.length;

  if (offset === charCount(content, numChars) && content.startsWith(shortcut)) {
    const notebookId = selectActiveNotebookId(getState());
    track("notebook | change cell type", {
      notebookId,
      cellType: cellTypeProperties.type,
      source: "list item shortcut",
    });

    dispatch(
      changeCellType(cellId, cellTypeProperties, {
        content: charSlice(content, numChars),
        focus: { type: "collapsed", cellId, offset: 0 },
      }),
    );
    return true;
  }
}

const TAB_CHAR = "\t";

function handleTab(event: React.KeyboardEvent) {
  const state = getState();
  const focusedCell = selectFocusedCell(state);
  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
  if (!focusedCell || !isContentCell(focusedCell)) {
    return;
  }

  if (focusedCell.readOnly) {
    CellById.get(focusedCell.id)?.shake();
    return true;
  }

  if (focusedCell.type === "list_item") {
    const { id, level = 0 } = focusedCell;
    notebookDispatch(
      updateCell(id, {
        level: event.shiftKey
          ? Math.max(level - 1, 0)
          : Math.min(level + 1, MAX_LIST_ITEM_LEVEL),
      }),
    );

    return true;
  }

  const focus = selectNotebookFocus(state);
  const offset = getFocusOffset(focus);

  if (event.shiftKey && offset >= 1) {
    const previousChar = charSlice(focusedCell.content, offset - 1, offset);
    if (previousChar === TAB_CHAR) {
      notebookDispatch(
        replaceText({
          cellId: focusedCell.id,
          offset: offset - 1,
          oldText: TAB_CHAR,
          newText: "",
        }),
      );
    }
  } else if (!event.ctrlKey) {
    notebookDispatch(
      replaceText({
        cellId: focusedCell.id,
        offset,
        oldText: "",
        newText: TAB_CHAR,
      }),
    );
  }

  return true;
}

function handleReadonlyToggle() {
  const focusedCell = selectFocusedCell(getState());

  if (focusedCell) {
    dispatch(toggleLockFocusedCells(focusedCell.id));
    return true;
  }
}

function handleRevertContentCellType() {
  const state = getState();
  const focusedCell = selectFocusedCell(state);

  if (
    focusedCell &&
    isContentCell(focusedCell) &&
    focusedCell.type !== "text"
  ) {
    const focus = selectNotebookFocus(state);
    dispatch(changeCellType(focusedCell.id, { type: "text" }, { focus }));
    return true;
  }
}

function getStartOfLineOffset(
  containerEl: HTMLElement,
  text: string,
  offset: number,
): number {
  const coordinates = getCoordinatesForOffset(containerEl, text, offset);
  return coordinates
    ? getOffsetForCoordinates(containerEl, text, { x: 0, y: coordinates.y })
    : 0;
}

const notebookDispatch = wrapDispatchWithActiveNotebook(dispatch);

function eventTargetHasDisabledNotebookKeyboardHandlers(
  event: InputEvent | React.KeyboardEvent,
) {
  return (
    event.target instanceof HTMLElement &&
    event.target.dataset.disableNotebookKeyboardHandlers
  );
}

function eventTargetIsLabelsEditorInput(
  event: InputEvent | React.KeyboardEvent,
) {
  return event.target instanceof HTMLElement && event.target.dataset.labelInput;
}

function eventTargetHasPreventRte(event: InputEvent | React.KeyboardEvent) {
  return event.target instanceof HTMLElement && event.target.dataset.preventRte;
}

/**
 * Handle all discussion cell enter key events.
 */
function handleDiscussionCellEnter(
  event: React.KeyboardEvent,
  focusedCell: Cell & { type: "discussion" },
) {
  const state = getState();
  const threads = selectAppThreads(state);
  const thread = threads.get(focusedCell.threadId);
  const showThreadDeletionPrompt = thread?.showThreadDeletionPrompt;

  if (showThreadDeletionPrompt) {
    const shouldDeleteThread =
      event.target instanceof HTMLButtonElement &&
      event.target.attributes.getNamedItem("type")?.value ===
        THREAD_DELETE_CONFIRM;

    dispatch(
      shouldDeleteThread
        ? deleteCellAndThread(focusedCell.id)
        : setThreadDeletionPrompt({
            showThreadDeletionPrompt: false,
            threadId: focusedCell.threadId,
          }),
    );

    return true;
  }

  const focus = selectNotebookFocus(state);
  const text = selectCellText(state, focusedCell, getField(focus));

  if (getField(focus) && text.length > 0) {
    if (event.shiftKey) {
      dispatch(replaceSelection(focus, "\n"));
      return true;
    }

    CommentInputById.get(focusedCell.id)?.submitComment();
    return true;
  }

  return true;
}
