import { useSelector } from "react-redux";
import { styled } from "styled-components";

import { selectUniqueNotebookSubscriberSessions } from "../../../selectors";
import type { SubscriberSession } from "../../../types";
import { sortBy } from "../../../utils";
import { PresenceContainer } from "../../UI";

export function Presence() {
  const sessions = useSelector(selectUniqueNotebookSubscriberSessions);
  const sortedSessions = sortBy([...sessions], getName);

  if (sortedSessions.length === 0) {
    return null;
  }

  return <StyledPresenceContainer sessions={sortedSessions} maxDisplayed={6} />;
}

function getName(session: SubscriberSession): string {
  return session.user.name;
}

export const StyledPresenceContainer = styled(PresenceContainer)`
  &:not(:empty) {
    padding: 0 12px;
    height: 100%;
  }
`;
