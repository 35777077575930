import { memo } from "react";
import { styled } from "styled-components";

import type { ViewCommand } from "../../../../types";
import { ViewIcon } from "../../../UI";
import { DEFAULT_ICON, MAX_LABELS, TITLE_INDEX_KEY } from "../constants";
import { HighlightedText } from "./HighlightedText";
import type { SwitchedResultProps } from "./Result";
import { ResultContainer } from "./ResultContainer";
import {
  ResultHeader,
  ResultIcon,
  ResultLabels,
  ResultTitle,
  StyledResultLabelList,
} from "./styled";

type ViewResultProps = SwitchedResultProps<ViewCommand>;

export const ViewResult = memo(function ViewResult({
  command,
  index,
  matches,
}: ViewResultProps) {
  const labels = command.view?.labels ?? [];

  return (
    <ResultContainer command={command} index={index}>
      {command.view ? (
        <StyledViewIcon color={command.view.color} iconSize={15} />
      ) : (
        <ResultIcon iconType={command.icon ?? DEFAULT_ICON} />
      )}
      <ResultHeader>
        <ResultTitle>
          <HighlightedText indexKey={TITLE_INDEX_KEY} matches={matches}>
            {command.title}
          </HighlightedText>
        </ResultTitle>
        {labels.length > 0 && (
          <ResultLabels>
            <StyledResultLabelList labels={labels} max={MAX_LABELS} />
          </ResultLabels>
        )}
      </ResultHeader>
    </ResultContainer>
  );
});

const StyledViewIcon = styled(ViewIcon)`
  grid-row: 1 / 3;
  width: 20px;
  height: 20px;
`;
