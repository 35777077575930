import { useRef } from "react";
import useMeasure from "react-use/lib/useMeasure";
import { styled } from "styled-components";

import type { QuerySchema } from "../../../../types";
import { mergeRefs } from "../../../../utils";
import { useQueryFormError } from "./QueryError";
import { QueryField } from "./QueryField";

type Props = {
  cellId: string;
  providerType: string;
  queryData?: string;
  readOnly?: boolean;
  schema: QuerySchema;
  setLive: (live: boolean) => void;
};

export function QueryForm({
  cellId,
  providerType,
  queryData,
  readOnly,
  schema,
  setLive,
}: Props): JSX.Element {
  const { update, errorOutput, ref: containerRef } = useQueryFormError(cellId);
  const [measureRef, { width }] = useMeasure();
  const ref = mergeRefs([measureRef, containerRef]);

  const lastWidth = useRef(width);
  if (width !== lastWidth.current && width) {
    update();
    lastWidth.current = width;
  }

  return (
    <Container ref={ref}>
      {schema.map((field) => (
        <QueryField
          key={field.name}
          cellId={cellId}
          field={field}
          providerType={providerType}
          queryData={queryData}
          readOnly={readOnly}
          schema={schema}
          setLive={setLive}
        />
      ))}
      {errorOutput}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 0;
`;
