import { useHandler } from "@fiberplane/hooks";

import { cancelEvent } from "@fiberplane/ui";
import {
  deleteLinkAtCursor,
  toggleFormatting,
  updateLinkAtCursor,
  withActiveNotebook,
} from "../../../../actions";
import { dispatch } from "../../../../store";
import { hideModal, showModal } from "../../../../thunks";
import type { ActiveFormatting, CellFocus } from "../../../../types";
import {
  charSlice,
  getCellFocusEnd,
  getCellFocusStart,
} from "../../../../utils";

export function useLinkEditor(
  value: string,
  focus: CellFocus,
  activeFormatting: ActiveFormatting,
) {
  return useHandler((event: React.SyntheticEvent) => {
    cancelEvent(event);

    let url = activeFormatting.link;
    const isNewLink = !url;

    if (!url) {
      const selectedText = charSlice(
        value,
        getCellFocusStart(focus) ?? 0,
        getCellFocusEnd(focus),
      );

      url =
        selectedText.startsWith("http://") ||
        selectedText.startsWith("https://")
          ? selectedText
          : "";
    }

    const requestClose = () => {
      dispatch(hideModal("editLink"));
    };

    const onConfirm = (url: string) => {
      dispatch(
        withActiveNotebook(
          isNewLink
            ? toggleFormatting({ type: "start_link", url })
            : updateLinkAtCursor(url),
        ),
      );
      requestClose();
    };

    const onDelete = () => {
      dispatch(withActiveNotebook(deleteLinkAtCursor()));
      requestClose();
    };

    dispatch(
      showModal({
        type: "editLink",
        onConfirm,
        onDelete,
        initialUrl: url,
        isNewLink,
      }),
    );
  });
}
