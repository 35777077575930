import { Icon } from "@fiberplane/ui";
import { styled } from "styled-components";

import {
  selectActiveNotebook,
  selectActiveWorkspaceName,
  selectCellIds,
  selectNotebookFocus,
} from "../../../selectors";
import { getState, useActiveNotebookDispatch } from "../../../store";
import {
  addNotification,
  deleteFocusedCells,
  duplicateFocusedCells,
  showCreateSnippetModal,
} from "../../../thunks";
import type { NotebookContextMenuInfo } from "../../../types";
import { createNotebookLink, getStartPosition } from "../../../utils";
import {
  type FilterMenuItem,
  PositionedFilterMenu,
  type PositionedFilterMenuProps,
} from "../../UI";
import { useSlashCommandItems } from "./useSlashCommandItems";

type Props = {
  contextMenu: NotebookContextMenuInfo;
} & Omit<PositionedFilterMenuProps, "items">;

export function CellActionsMenu({
  contextMenu,
  ...menuProps
}: Props): JSX.Element | null {
  const dispatch = useActiveNotebookDispatch();

  const slashCommandMenuItems = useSlashCommandItems({
    contextMenu,
    source: "menu | turn into",
  });

  const copyLink = () => {
    const state = getState();
    const { id, title } = selectActiveNotebook(state);
    const workspaceName = selectActiveWorkspaceName(state);

    const cellIds = selectCellIds(state);
    const focus = selectNotebookFocus(state);
    const cellId = getStartPosition(cellIds, focus)?.cellId;

    const { location } = document;
    const link = workspaceName
      ? createNotebookLink(workspaceName, id, title)
      : location.pathname;
    navigator.clipboard.writeText(`${location.origin}${link}#${cellId}`);
    dispatch(addNotification({ title: "Link copied to your clipboard" }));
  };

  const items: Array<FilterMenuItem> = [
    {
      type: "item_with_icons",
      id: "delete",
      title: "Delete",
      onActivate: () => dispatch(deleteFocusedCells()),
      iconLeft: <Icon iconType="trash" />,
    },
    {
      type: "item_with_icons",
      id: "duplicate",
      title: "Duplicate",
      onActivate: () => dispatch(duplicateFocusedCells()),
      iconLeft: <Icon iconType="copy" />,
    },
    {
      type: "item_with_submenu",
      id: "turn-into",
      title: "Turn into",
      iconLeft: <Icon iconType="arrows_left_right" />,
      items: slashCommandMenuItems,
    },
    {
      type: "item_with_icons",
      id: "copy-link",
      title: "Copy link",
      onActivate: copyLink,
      iconLeft: <Icon iconType="link" />,
    },
    {
      type: "item_with_icons",
      id: "save-snippet",
      title: "Save as snippet",
      onActivate: () => dispatch(showCreateSnippetModal()),
      iconLeft: <Icon iconType="scissors" />,
    },
  ];

  return (
    <StyledPositionedMenu
      {...menuProps}
      items={items}
      noResultsText="No results"
      placement="bottom-start"
    />
  );
}

const StyledPositionedMenu = styled(PositionedFilterMenu)`
  max-height: 300px;
  width: 185px;
`;
