export const SET_ACTIVE_WORKSPACE_NAME = "workspaces/setActiveWorkspaceName";

export type WorkspaceAction = SetActiveWorkspaceIdAction;

type SetActiveWorkspaceIdAction = {
  type: typeof SET_ACTIVE_WORKSPACE_NAME;
  payload: string;
};

export function setActiveWorkspaceName(workspaceName: string): WorkspaceAction {
  return { type: SET_ACTIVE_WORKSPACE_NAME, payload: workspaceName };
}
