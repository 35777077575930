import { Icon } from "@fiberplane/ui";
import { memo } from "react";

import { formatDateTime } from "../../../../utils";
import type { ExtendedAnnotation } from "../types";
import type { EditorNode, KeyedEditorNode } from "./EditorNode";
import { EntityEditorNode } from "./EntityEditorNode";
import type { RenderableAnnotation } from "./RenderableAnnotation";

type Props = EditorNode & {
  timestamp: string;
};

export const TimestampEditorNode = memo(function TimestampEditorNode({
  startOffset,
  endOffset,
  timestamp,
}: Props) {
  return (
    <EntityEditorNode
      data-start-offset={startOffset}
      data-end-offset={endOffset}
      icon={<Icon iconType="timer" />}
    >
      {formatDateTime(new Date(timestamp))}
    </EntityEditorNode>
  );
});

type TimestampAnnotation = ExtendedAnnotation & { type: "timestamp" };

export const RENDERABLE_TIMESTAMP: RenderableAnnotation<TimestampAnnotation> = {
  supports: (annotation: ExtendedAnnotation) => annotation.type === "timestamp",
  getPlainText: (annotation: TimestampAnnotation) => annotation.timestamp,
  getElement: (annotation: TimestampAnnotation, props: KeyedEditorNode) => {
    const { timestamp } = annotation;

    return <TimestampEditorNode {...props} timestamp={timestamp} />;
  },
};
