import { IconButton } from "@fiberplane/ui";
import { type Control, useWatch } from "react-hook-form";
import { css, styled } from "styled-components";

import { useCopyToClipboard } from "../../../hooks";
import type { Snippet } from "../../../types";

type Props = {
  control: Control<Snippet>;
};

export function SnippetsPreview({ control }: Props) {
  const body = useWatch({ control, name: "body" });

  const { isCopied, handleCopy } = useCopyToClipboard(body);

  return (
    <CodeContainer data-testid="snippet-preview">
      <CopyCodeButton
        iconType={isCopied ? "check" : "copy"}
        buttonSize="small"
        aria-label={isCopied ? undefined : "Click to copy code block"}
        disabled={isCopied}
        onClick={isCopied ? undefined : handleCopy}
      />

      {body && <Code>{body.trim()}</Code>}
    </CodeContainer>
  );
}

const CodeContainer = styled.div(
  ({ theme }) => css`
    max-width: 100%;
    display: flex;
    align-items: stretch;
    background: ${theme.colorBase100};
    border: 1px solid ${theme.colorBase300};
    border-radius: ${theme.borderRadius500};
    position: relative;
    margin-bottom: 16px;
    overflow: hidden;

    &:hover {
      ${/* sc-selector */ CopyCodeButton} {
        opacity: 1;
      }
    }
  `,
);

const Code = styled.pre`
  overflow-x: auto;
  flex: 1;
  padding-top: 16px;
  font-size: ${({ theme }) => theme.fontNotebooksCodeFontSize};
  margin: 0;
`;

const CopyCodeButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.1s;

  &:hover {
    cursor: pointer;
  }
`;
