import { Button, Icon } from "@fiberplane/ui";
import { useEffect } from "react";
import styled from "styled-components";
import { css } from "styled-components";
import { MEDIUM_SIZE_QUERY } from "../../../../../constants";
import { useCopyToClipboard } from "../../../../../hooks";
import { dispatch } from "../../../../../store";
import { addNotification } from "../../../../../thunks";
import type { FrontMatterGitHubPullRequest } from "../../../../../types";

import { AvatarIcon } from "../../../../Avatar";
import { RelativeTimestamp } from "../../../../UI";
import { PrStatusPill } from "./PrStatusPill";
import { mergePrStatus } from "./utils";

type Props = FrontMatterGitHubPullRequest & {
  requestClose: () => void;
};

export function GitHubPullRequestMetaData({ requestClose, ...value }: Props) {
  const { handleCopy: handleCopyNumber, isCopied: numberIsCopied } =
    useCopyToClipboard(value.number.toString());
  const { handleCopy: handleCopyBranch, isCopied: branchIsCopied } =
    useCopyToClipboard(value.branch.toString());
  const isCopied = numberIsCopied || branchIsCopied;
  useEffect(() => {
    if (isCopied) {
      dispatch(
        addNotification({
          title: "Copied to clipboard",
        }),
      );
    }
  }, [isCopied]);

  const mergedStatus = mergePrStatus(value);
  return (
    <PopupContent>
      <Controls>
        <PopupHeading>
          <IconContainer>
            <Icon iconType="github_logo_alt" width="40" height="40" />
          </IconContainer>
          <Text>
            <Category>App</Category>
            <Name>GitHub</Name>
          </Text>
        </PopupHeading>
        <SmallButton
          buttonStyle="tertiary-grey"
          onClick={() => requestClose()}
          aria-label="Close details"
        >
          <Icon iconType="close" width="16" height="16" />
        </SmallButton>
      </Controls>
      <PopupList>
        <Term>Repository</Term>
        <Definition>
          <Link
            href={`https://github.com/${value.repoOwner}/${value.repoName}`}
          >
            {value.repoOwner}/{value.repoName}
          </Link>
        </Definition>
        <Term>Title</Term>
        <Definition>
          {value.htmlUrl ? (
            <Link
              href={value.htmlUrl}
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Go to GitHub"
            >
              <LinkText>{value.title}</LinkText>
              <LinkIcon iconType="arrow_up_right" />
            </Link>
          ) : (
            "value.title"
          )}
        </Definition>
        <Term>Id</Term>
        <Definition>
          <Overflow>#{value.number}</Overflow>
          <ButtonContainer>
            <SmallButton buttonStyle="tertiary-grey" onClick={handleCopyNumber}>
              <Icon
                iconType={numberIsCopied ? "check" : "copy"}
                width="16"
                height="16"
                aria-label={numberIsCopied ? undefined : "Copy to clipboard"}
              />
            </SmallButton>
          </ButtonContainer>
        </Definition>
        <Term>Branch</Term>
        <Definition>
          <Overflow arial-label={value.branch}>{value.branch}</Overflow>
          <ButtonContainer>
            <SmallButton buttonStyle="tertiary-grey" onClick={handleCopyBranch}>
              <Icon
                iconType={branchIsCopied ? "check" : "copy"}
                width="16"
                height="16"
                aria-label={branchIsCopied ? undefined : "Copy to clipboard"}
              />
            </SmallButton>
          </ButtonContainer>
        </Definition>
        <Term>Status</Term>
        <Definition>
          <div>
            <PrStatusPill status={mergedStatus} />
          </div>
        </Definition>
        <Term>Number of commits</Term>
        <Definition>{value.commits}</Definition>
        <Term>Created by</Term>
        <Definition>
          <CreationInfo>
            <RelativeTimestamp timestamp={value.createdAt} /> <span>by </span>
            <AvatarIcon name={value.author} src={value.authorAvatarUrl} />
            <span> {value.author}</span>
          </CreationInfo>
        </Definition>
        <Term>Updated at</Term>
        <Definition>
          <RelativeTimestamp timestamp={value.updatedAt} />
        </Definition>
      </PopupList>
    </PopupContent>
  );
}

const PopupContent = styled.div(
  ({ theme }) => css`
    display: grid;
    border: 1px solid ${theme.color.border.muted};
    border-radius: ${theme.radius.default};
    background: ${theme.color.bg.elevated.default};
    padding: ${theme.height.xx.small};
    max-width: min(90vw, 418px);
    gap: 4px;
    box-shadow: ${theme.effect.shadow.s};
    
    @media ${MEDIUM_SIZE_QUERY} {
      gap: ${theme.height.xx.small};
    }
`,
);

const Controls = styled.div`
  display: grid;
  grid-template-columns: auto 36px;
  /* Align close button with the rest of the content */
  margin-right: -6px;
`;

const PopupHeading = styled.div`
  display: grid;
  grid-template: "icon type" auto / 40px auto;
  gap: 16px;
`;

const IconContainer = styled.div(
  ({ theme }) => css`
  width: 40px;
  height: 40px;
  grid-area: icon;
  border-radius: ${theme.radius.default};
  border: 1px solid ${theme.color.border.default};
`,
);

const Text = styled.div`
  display: grid;
`;

const Category = styled.div(
  ({ theme }) => css`
  color: ${theme.color.fg.muted};
  font: ${theme.font.headings.h6};
`,
);

const Name = styled.div(
  ({ theme }) => css`
    font: ${theme.font.headings.h4};
    color: ${theme.color.fg.default};
  `,
);

const PopupList = styled.dl(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr auto;

    column-gap: 12px;
    row-gap: 4px;
    margin: 0;
    padding: 0;

    @media ${MEDIUM_SIZE_QUERY} {
      column-gap: 32px;
      row-gap: 12px;
    }

    color: ${theme.color.fg.muted};
  `,
);

const Term = styled.dt`
  margin: 0;
  padding: 0;
  white-space: nowrap
`;

const Definition = styled.dd`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  min-height: 26px;
  /* set min-width so content doesn't push the content outside of the container */
  min-width: 0;
`;

const Link = styled.a(
  ({ theme }) => css`
  position: relative;
  display: inline-flex;
  gap: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  color: ${theme.color.fg.default};

  &:last-child {
    /* make the last child icon align with the other icons */
    margin-right: 6px;
  }

  &::before {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    border-bottom: 1px solid ${theme.color.border.default};
  }

  &:hover {
    &::before {
      border-color: ${theme.color.fg.default};
    }

    ${LinkIcon} {
      color: ${theme.color.fg.default};
    }
  }
`,
);

const LinkText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;

`;

const LinkIcon = styled(Icon)(
  ({ theme }) => css`
  color: ${theme.color.fg.muted};
  align-self: center;
  min-width: 14px;
  min-height: 14px;
`,
);

const Overflow = styled.span`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ButtonContainer = styled.div`
  display: grid;
  align-items: center;
`;

// TODO: (JF) Move this to a shared "small" button component
// Design already has these buttons but the haven't been fully
// implemented yet
const SmallButton = styled(Button)`
  padding: 6px;
  margin-top: -8px;
  margin-bottom: -8px;
`;

const CreationInfo = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;
