import { Icon } from "@fiberplane/ui";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { generatePath, useParams } from "react-router";
import { push } from "redux-first-history";
import { keyframes, styled } from "styled-components";

import { useAcceptInviteMutation } from "../../api";
import { ROUTES } from "../../constants";
import { selectIsAuthenticated } from "../../selectors";
import { dispatch } from "../../store";
import { addNotification } from "../../thunks";

export function WorkspaceInvitation() {
  const { invitationId, invitationSecret } = useParams();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [accept, { error }] = useAcceptInviteMutation();

  useEffect(() => {
    (async () => {
      if (invitationId && invitationSecret) {
        if (!isAuthenticated) {
          return dispatch(
            push(
              `${ROUTES.SignIn}?redirect=${generatePath(ROUTES.Invitation, {
                invitationId,
                invitationSecret,
              })}`,
            ),
          );
        }

        const result = await accept({
          invitationId,
          invitationSecret,
        });

        if ("error" in result) {
          return;
        }

        const { data: workspace } = result;

        dispatch(
          addNotification({
            title: `You are now a member of workspace ${workspace.displayName}`,
          }),
        );

        dispatch(
          push(
            generatePath(ROUTES.GettingStarted, {
              workspaceName: workspace.name,
            }),
          ),
        );
      }
    })();
  }, [accept, invitationId, isAuthenticated, invitationSecret]);

  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
  if (!invitationId || !invitationSecret) {
    return (
      <Container>
        Unable to accept workspace invite, some parameters are missing.
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        An error occurred while trying to accept your invitation.
      </Container>
    );
  }

  return (
    <Container>
      <LoadIcon iconType="arrows_clockwise" />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoadIcon = styled(Icon)`
  animation: ${spinAnimation} 0.6s ease-in-out forwards infinite;
`;
