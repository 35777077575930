import { AttachedPopup, Icon } from "@fiberplane/ui";
import { useRef, useState } from "react";
import { type Control, useController } from "react-hook-form";
import { css, styled } from "styled-components";

import { type ViewColorSet, useViewColor } from "../../hooks";
import type { View } from "../../types";
import { Menu, MenuItemGroup } from "../UI";

type Props = {
  control: Control<View>;
};

export function ColorPicker({ control }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const onClose = () => setShowColorPicker(false);

  const {
    field: { value, onChange },
  } = useController({ control, name: "color" });
  const { colorSet, total } = useViewColor(value);

  return (
    <Container ref={ref}>
      <ViewIcon
        iconType="views"
        $colorSet={colorSet}
        width={26}
        height={26}
        onClick={() => setShowColorPicker((current) => !current)}
      />
      {showColorPicker && (
        <AttachedPopup element={ref.current} placement="bottom-end">
          <StyledMenu onClose={onClose}>
            <MenuItemGroup title="Select color">
              <ColorList>
                {Array.from({ length: total })
                  .fill(0)
                  .map((_, index) => (
                    <ColorOption
                      key={index}
                      color={index}
                      selected={index === value}
                      onClick={() => {
                        onChange(index);
                        onClose();
                      }}
                    />
                  ))}
              </ColorList>
            </MenuItemGroup>
          </StyledMenu>
        </AttachedPopup>
      )}
    </Container>
  );
}

type ColorOptionProps = {
  color: View["color"];
  selected: boolean;
  onClick: () => void;
};

function ColorOption({ color, selected, onClick }: ColorOptionProps) {
  const { colorSet } = useViewColor(color);

  return (
    <ColorCircle colorSet={colorSet} selected={selected} onClick={onClick} />
  );
}

const Container = styled.div`
  grid-area: viewIcon;
  display: flex;
  align-items: center;
  /* Make sure the icon is vertically aligned to the center of the h2 styled input box on it's right */
  height: ${({ theme }) => theme.fontStudioHeadingsH2LineHeight};
`;

const ViewIcon = styled(Icon)<{ $colorSet: ViewColorSet }>`
  cursor: pointer;
  transition: 200ms ease-out;
  color: ${({ $colorSet: colorSet }) => colorSet.default};
  width: 20px;
  justify-self: center;

  @media (min-width: 768px) {
    width: 26px;
  }
`;

const ColorList = styled.div`
  display: flex;
  gap: 12px;
  margin: 0 12px;
`;

const ColorCircle = styled.div<{
  colorSet: ViewColorSet;
  selected: boolean;
}>(
  ({ colorSet: viewColorSet, theme, selected }) => css`
    border-radius: ${theme.borderRadiusRound};
    width: 24px;
    height: 24px;
    background: ${viewColorSet.default};
    border: 1.5px solid ${selected ? theme.colorForeground : "transparent"};

    &:hover {
      border-color: ${theme.colorBase600};
      cursor: pointer;
    }
  `,
);

const StyledMenu = styled(Menu)`
  width: max-content;
`;
