const dateTimeFormat = new Intl.DateTimeFormat("en");

export function formatDate(timestamp: Date): string {
  return dateTimeFormat.format(timestamp);
}

const dateTimeLongFormat = new Intl.DateTimeFormat("en", {
  dateStyle: "long",
});

export function formatLongDate(timestamp: Date): string {
  return dateTimeLongFormat.format(timestamp);
}
