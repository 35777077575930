import { Icon } from "@fiberplane/ui";
import type { Variants } from "framer-motion";
import { motion } from "framer-motion";
import { useCallback } from "react";
import { css, styled } from "styled-components";

import type { Notification, NotificationType } from "../../state";
import { useAppDispatch } from "../../store";
import { removeNotification } from "../../thunks";

const variants: Variants = {
  show: {
    opacity: 1,
    y: 0,
  },
  hide: {
    opacity: 0,
    y: -10,
  },
};

const Container = styled(motion.div)(
  ({ theme }) => css`
  border-radius: ${theme.radius.default};
  background: ${theme.color.bg.elevated.default};
  box-shadow: ${theme.effect.shadow.m};
`,
);

const ActionButton = styled(Container).attrs({ as: "button" })(
  ({ theme }) => css`
  border: none;
  padding: 0;
  margin: 0;
  appearance: none;
  text-align: initial;
  align-items: flex-start;

  &:hover {
    cursor: pointer;

    --iconColor: ${theme.color.fg.primary};
  }
`,
);

Container.defaultProps = {
  variants,
  initial: "hide",
  animate: "show",
  exit: "hide",
  layout: true,
};

const Content = styled.div`
  width: max-content;
  display: grid;
  grid-auto-columns: auto;
  grid-auto-rows: auto;
  grid-template-areas: "icon title close" "icon description close";
  align-items: flex-start;
  grid-gap: 4px 6px;
  padding: 8px;
`;

const NotificationIcon = styled(Icon)<{ $notificationType?: NotificationType }>`
  grid-area: icon;
  width: 14px;
  color: var(
    --iconColor,
    ${
      /* sc-custom "ignore this" */ ({ $notificationType, theme }) => {
        switch ($notificationType) {
          case "warning":
            return theme.color.fg.warning;
          case "danger":
            return theme.color.fg.danger;
          default:
            return "inherit";
        }
      }
    }
  );
`;

const Title = styled.strong(
  ({ theme }) => css`
  grid-area: title;
  font: ${theme.font.body.sm.medium};
  color: ${theme.color.fg.default};
`,
);

const Description = styled.p(
  ({ theme }) => css`
  grid-area: description;
  font: ${theme.font.body.sm.regular};
  color: ${theme.color.fg.muted};
  margin: 0;
`,
);

const CloseIcon = styled(Icon)`
  grid-area: close;
  cursor: pointer;
  width: 14px;
`;

type Props = Notification & {
  notificationKey: string;
};

export function Toast({
  notificationKey,
  type,
  title,
  description,
  hideCloseButton,
  action,
}: Props) {
  const dispatch = useAppDispatch();

  const handleAction = useCallback(() => {
    if (!action) {
      return;
    }

    dispatch(removeNotification(notificationKey));
    action(dispatch);
  }, [action, dispatch, notificationKey]);

  const content = (
    <Content>
      <NotificationIcon iconType="warning_circle" $notificationType={type} />
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
      {!hideCloseButton && (
        <CloseIcon
          iconType="close"
          data-testid="close"
          onClick={() => dispatch(removeNotification(notificationKey))}
        />
      )}
    </Content>
  );

  if (action) {
    return (
      <ActionButton data-testid="notification" onClick={handleAction}>
        {content}
      </ActionButton>
    );
  }

  return <Container data-testid="notification">{content}</Container>;
}
