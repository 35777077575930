import { Icon } from "@fiberplane/ui";
import { type ComponentProps, type ForwardedRef, forwardRef } from "react";
import { styled } from "styled-components";

import { Nudge } from "../../../../UI";

type NudgeProps = ComponentProps<typeof Nudge>;

type Props = Pick<NudgeProps, "element" | "onClose" | "children">;

export const QueryError = forwardRef(function QueryError(
  props: Props,
  ref: ForwardedRef<{
    update: () => Promise<void>;
  }>,
): JSX.Element {
  return (
    <StyledNudge
      ref={ref}
      placement="top"
      element={props.element}
      onClose={props.onClose}
    >
      <ErrorContent>
        <IconContainer>
          <ErrorIcon iconType="warning_circle" />
        </IconContainer>
        <div>{props.children}</div>
      </ErrorContent>
    </StyledNudge>
  );
});

const StyledNudge = styled(Nudge)`
  max-width: 95%;
`;

const ErrorContent = styled.div`
  display: grid;
  grid-template-columns: 28px 1fr;
  gap: 12px;
  font: ${({ theme }) => theme.fontNotebooksCodeShortHand};
  letter-spacing: ${({ theme }) => theme.fontNotebooksCodeLetterSpacing};
  white-space: pre;
  overflow-wrap: anywhere;
  overflow: auto;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 8px;
`;

const ErrorIcon = styled(Icon)`
  color: ${({ theme }) => theme.colorDanger400};
`;
