import { generatePath } from "react-router";
import { push } from "redux-first-history";

import { setActiveWorkspaceName } from "../actions";
import { workspacesApi } from "../api";
import { ROUTES } from "../constants";
import {
  selectActiveWorkspaceId,
  selectActiveWorkspaceIdOrThrow,
  selectAvailableWorkspaces,
  selectLocation,
} from "../selectors";
import type { Thunk } from "../store";
import type { Membership } from "../types";
import {
  containsMultipleParams,
  containsWorkspaceName,
  findMatchingRoute,
  hasFeature,
} from "../utils";
import {
  subscribeToWorkspace,
  unsubscribeFromWorkspace,
} from "./courierThunks";

export const transferWorkspaceOwnership =
  (member: Membership): Thunk =>
  async (dispatch, getState) => {
    const workspaceId = selectActiveWorkspaceIdOrThrow(getState());
    await dispatch(
      workspacesApi.endpoints.updateWorkspace.initiate({
        workspaceId,
        updateWorkspace: {
          owner: member.id,
        },
      }),
    );
  };

export const switchActiveWorkspace =
  (workspaceName: string): Thunk =>
  // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: FIXME
  (dispatch, getState) => {
    const state = getState();
    const location = selectLocation(state);
    const pathname = location?.pathname ?? "";
    const search = location?.search ?? "";
    const match = findMatchingRoute(pathname);

    if (hasFeature("courier-workspaces")) {
      const activeWorkspaceId = selectActiveWorkspaceId(state);
      if (activeWorkspaceId) {
        dispatch(unsubscribeFromWorkspace(activeWorkspaceId));
      }

      const workspaceId = selectAvailableWorkspaces(state).find(
        ({ name }) => name === workspaceName,
      )?.id;
      if (workspaceId) {
        dispatch(subscribeToWorkspace(workspaceId));
      }
    }

    if (match && containsWorkspaceName(match.pattern.path)) {
      if (
        // If a URL contains multiple arguments the second argument
        // will likely be unique to the workspace so we redirect
        // to the getting started page instead. Though we may want
        // to refine this to redirect to the closest alternative page
        // i.e. go from a specific view to the views listing page
        containsMultipleParams(match.pattern.path)
      ) {
        dispatch(
          push(
            generatePath(ROUTES.GettingStarted, {
              workspaceName,
            }),
          ),
        );
      } else {
        dispatch(
          push(
            `${generatePath(match.pattern.path, {
              ...match.params,
              workspaceName,
            })}${search}`,
          ),
        );
      }
    }

    dispatch(setActiveWorkspaceName(workspaceName));
  };
