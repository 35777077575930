import { Input } from "@fiberplane/ui";
import { styled } from "styled-components";

import { useCellFocus, useFilePaste } from "../../hooks";
import { useActiveNotebookDispatch } from "../../store";
import { updateCell } from "../../thunks";
import type { CheckboxCell } from "../../types";
import { noop } from "../../utils";
import { Container } from "../UI";
import { RichTextInput } from "./RTE";

const StyledRichTextInput = styled(RichTextInput)`
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  position: relative;
`;

export type CheckboxCellContentProps = {
  cell: { type: "checkbox" } & CheckboxCell;
  readOnly: boolean;
};

export function CheckboxCellContent({
  cell,
  readOnly,
}: CheckboxCellContentProps) {
  const dispatch = useActiveNotebookDispatch();
  const focus = useCellFocus(cell.id);
  const onPaste = useFilePaste(cell.id);

  const toggleChecked = () =>
    dispatch(updateCell(cell.id, { checked: !cell.checked }));

  return (
    <CheckboxCellContainer onPaste={onPaste}>
      <StyledInput
        type="checkbox"
        checked={cell.checked}
        onChange={noop}
        onClick={readOnly ? noop : toggleChecked}
      />
      <StyledRichTextInput
        cellId={cell.id}
        focus={focus}
        formatting={cell.formatting}
        readOnly={readOnly}
        value={cell.content}
      />
    </CheckboxCellContainer>
  );
}

/*
  FIXME (Oscar): The radius tokens need to be updated, until then we use this as
  a temporary fix
*/
const StyledInput = styled(Input)`
  & > div {
    border-radius: ${({ theme }) => theme.radius.lowest};
  }
`;

const CheckboxCellContainer = styled(Container)`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
`;
