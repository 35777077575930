import { useHandler } from "@fiberplane/hooks";
import { Button, Input } from "@fiberplane/ui";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { css, styled } from "styled-components";

import {
  useDeleteAccountMutation,
  useUpdateWorkspaceMutation,
} from "../../../../api";
import { useFeature } from "../../../../hooks";
import {
  selectActiveWorkspaceOrThrow,
  selectCanDeleteWorkspace,
  selectCanUpdateWorkspace,
} from "../../../../selectors";
import { dispatch } from "../../../../store";
import {
  addNotification,
  showWorkspaceDeleteConfirmation,
  signOut,
} from "../../../../thunks";
import type { UpdateWorkspace } from "../../../../types";
import {
  WORKSPACE_DISPLAY_NAME_MAX_CHARACTERS,
  validateMaxLength,
  validateRequired,
} from "../../../../utils";
import { AvatarUpdater } from "../../../AvatarUpdater";
import { Nudge } from "../../../UI";

export function Workspace(): JSX.Element {
  const workspace = useSelector(selectActiveWorkspaceOrThrow);
  const canDeleteWorkspace = useSelector(selectCanDeleteWorkspace);
  const canUpdateWorkspace = useSelector(selectCanUpdateWorkspace);

  const [canDeleteAccount] = useFeature("delete-account");

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<UpdateWorkspace>({
    defaultValues: workspace,
  });

  const [mutate, { isLoading }] = useUpdateWorkspaceMutation();

  const onSubmit = useHandler(async (updateWorkspace: UpdateWorkspace) => {
    if (
      !canUpdateWorkspace ||
      workspace.displayName === updateWorkspace.displayName
    ) {
      return;
    }

    await mutate({
      workspaceId: workspace.id,
      updateWorkspace,
    });

    dispatch(addNotification({ title: "Workspace name has been updated" }));
  });

  const [deleteAccount, { isLoading: isDeletingAccount }] =
    useDeleteAccountMutation();

  return (
    <Page>
      <Section>
        <Heading>Logo</Heading>
        <AvatarUpdater
          id={workspace.id}
          name={workspace.displayName}
          avatarType="workspace"
          readOnly={!canUpdateWorkspace}
        />
      </Section>
      <Section>
        <Heading>Display name</Heading>
        <Description>Workspace display name</Description>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            type="text"
            readOnly={!canUpdateWorkspace}
            data-testid="workspace-display-name"
            aria-invalid={errors.displayName !== undefined}
            {...register("displayName", {
              validate: {
                required: (value) => validateRequired(value, "display name"),
                maxLength: (value) =>
                  validateMaxLength(
                    value,
                    WORKSPACE_DISPLAY_NAME_MAX_CHARACTERS,
                    "display name",
                  ),
              },
            })}
          />
          {errors.displayName && (
            <Nudge element={errors.displayName.ref as HTMLElement}>
              {errors.displayName.message}
            </Nudge>
          )}
          {canUpdateWorkspace && (
            <StyledButton
              type="submit"
              data-testid="update-workspace-display-name"
              disabled={isLoading}
            >
              Update display name
            </StyledButton>
          )}
        </form>
      </Section>
      {canDeleteWorkspace && (
        <Section>
          <Heading>Delete workspace</Heading>
          <Description>
            If you want to permanently delete this workspace and all of its
            data, including but not limited to users, issues and comments, you
            can do so below.
          </Description>
          <StyledButton
            buttonStyle="danger"
            onClick={() =>
              dispatch(showWorkspaceDeleteConfirmation(workspace.id))
            }
            data-testid="delete-workspace"
          >
            Delete workspace
          </StyledButton>
        </Section>
      )}
      {canDeleteAccount && (
        <Section>
          <Heading>Delete account</Heading>
          <Description>
            If you want to permanently delete your account, click this.
          </Description>
          <StyledButton
            buttonStyle="danger"
            onClick={async () => {
              const result = await deleteAccount();
              const hadError = "error" in result;
              if (hadError) {
                dispatch(
                  addNotification({
                    title: "Failed to delete account",
                    description:
                      "You must first leave all collaborative workspaces",
                  }),
                );
              } else {
                dispatch(signOut());
              }
            }}
            disabled={isDeletingAccount}
            data-testid="delete-account"
          >
            Delete account
          </StyledButton>
        </Section>
      )}
    </Page>
  );
}

const Page = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Heading = styled.h4(
  ({ theme }) => css`
    margin: 0;
    font: ${theme.fontStudioHeadingsH4ShortHand};
    letter-spacing: ${theme.fontStudioHeadingsH4LetterSpacing};
  `,
);

const Description = styled.p(
  ({ theme }) => css`
    margin: 0;
    color: ${theme.colorBase600};
    font: ${theme.fontStudioStrongSmallShortHand};
    letter-spacing: ${theme.fontStudioStrongSmallLetterSpacing};
  `,
);

const StyledButton = styled(Button)`
  width: max-content;
  margin-top: 8px;
`;
