import type { Transition, Variants } from "framer-motion";
import { AnimatePresence, motion } from "framer-motion";
import { memo } from "react";
import { styled } from "styled-components";

import type { ThreadDetails, User } from "../../../types";
import { ThreadItemContainer } from "./ThreadItemContainer";
import { TypingIndicator } from "./TypingIndicator";
import { UserTyping } from "./UserTyping";

type ThreadProps = {
  cellId: string;
  showReplies: boolean;
  thread: ThreadDetails;
  user: User;
};

export const Thread = memo(function Thread({
  cellId,
  showReplies,
  thread,
  user,
}: ThreadProps) {
  const { id, items, usersTyping } = thread;

  const typingUsers = Object.values(usersTyping);
  typingUsers.sort((a, b) => (a.startedAt > b.startedAt ? 1 : -1));

  return (
    <ThreadContainer>
      {items.map((item, index) => (
        <ThreadItemContainer
          cellId={cellId}
          key={item.id}
          index={index}
          item={item}
          numItems={items.length}
          showReplies={showReplies}
          thread={thread}
          user={user}
        />
      ))}

      <AnimatePresence>
        {showReplies && typingUsers.length > 0 && (
          <TypingIndicatorContainer
            key="users-typing"
            variants={usersTypingVariants}
            transition={usersTypingTransitions}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            <TypingIndicatorContent>
              {typingUsers.map(({ updatedAt, user }) => (
                <UserTyping
                  key={user.id}
                  updatedAt={updatedAt}
                  user={user}
                  threadId={id}
                />
              ))}
              <TypingIndicator />
            </TypingIndicatorContent>

            <TypingIndicatorSpacerContainer>
              <TypingIndicatorVerticalLine />
            </TypingIndicatorSpacerContainer>
          </TypingIndicatorContainer>
        )}
      </AnimatePresence>
    </ThreadContainer>
  );
});

const usersTypingVariants: Variants = {
  initial: {
    height: 0,
    opacity: 0,
  },
  enter: {
    height: "unset",
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
  exit: {
    // Immediately hide the content when collapsing the element. This prevents
    // the element looking messy for a split second.
    visibility: "hidden",
    height: 0,
    transition: {
      duration: 0.1,
    },
  },
};

const usersTypingTransitions: Transition = {
  type: "just",
};

const ThreadContainer = styled.div`
  margin-top: 32px;
  color: ${({ theme }) => theme.color.fg.muted};
`;

const TypingIndicatorContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 2px;
`;

const TypingIndicatorContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const TypingIndicatorSpacerContainer = styled.div`
  display: flex;
  height: 20px;
`;

const TypingIndicatorVerticalLine = styled.div`
  flex-basis: 24px;
  position: relative;

  &::before {
    content: "";
    display: block;
    height: 100%;
    width: 1px;
    position: absolute;
    left: 50%;
    background: ${({ theme }) => theme.color.bg.subtle};
  }
`;
