import { Icon } from "@fiberplane/ui";
import { useState } from "react";
import { styled } from "styled-components";

import type { AvatarType } from "../../state";
import { dispatch } from "../../store";
import { showAvatarUploader } from "../../thunks";
import { noop } from "../../utils";
import { ProfileAvatar, WorkspaceAvatar } from "../Avatar";
import { Nudge, useShake } from "../UI";

type Props = {
  id: string;
  name: string;
  avatarType: AvatarType;
  readOnly?: boolean;
};

export function AvatarUpdater({ id, avatarType, name, readOnly }: Props) {
  const [progress, setProgress] = useState<number>();
  const { shake, shakeClassName } = useShake();

  const [ref, setRef] = useState<HTMLElement | null>(null);

  const showUploader = () =>
    dispatch(
      showAvatarUploader({
        id,
        avatarType,
        setProgress,
        onError: shake,
      }),
    );

  const props = {
    className: shakeClassName,
    size: 100,
    name,
    "data-testid": "large-icon",
    showTooltip: false,
  };

  return (
    <ImageContainer
      ref={setRef}
      onClick={readOnly ? noop : showUploader}
      data-readonly={readOnly ? true : undefined}
    >
      <AvatarContainer>
        {avatarType === "profile" ? (
          <ProfileAvatar {...props} userId={id} />
        ) : (
          <WorkspaceAvatar {...props} workspaceId={id} />
        )}
      </AvatarContainer>
      {!readOnly && (
        <EditButton
          aria-label={
            avatarType === "profile" ? "Update profile image" : "Update logo"
          }
        >
          <EditIcon iconType="pencil_simple" />
        </EditButton>
      )}
      {progress !== undefined && (
        <Nudge
          element={ref}
          placement="right"
          onClose={() => setProgress(undefined)}
        >
          <Icon iconType="upload" />
          Uploading - {progress.toFixed(0)}%
        </Nudge>
      )}
    </ImageContainer>
  );
}

const EditButton = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  cursor: pointer;
  opacity: 0;
`;

const EditIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.bg.default};
`;

const AvatarContainer = styled.div``;

const ImageContainer = styled.div`
  position: relative;
  width: max-content;
  border-radius: ${({ theme }) => theme.radius.full};
  overflow: hidden;

  &:not([data-readonly]) {
    &:hover ${AvatarContainer} {
      opacity: 0.5;
    }

    &:hover ${EditButton} {
      opacity: 1;
    }
  }
`;
