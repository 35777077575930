import { useHandler } from "@fiberplane/hooks";

import { stopPropagation } from "@fiberplane/ui";
import { Sentry } from "../../../../../../../services";
import type { CellFocus } from "../../../../../../../types";
import { charCount, getCursorOffset, isMac } from "../../../../../../../utils";
import { isCursorAtTopOrBottom } from "../../utils";
import type { SendCurrentCellFocus } from "./types";

type KeyDownParams = {
  field: string;
  getCellFocus(): CellFocus;
  value: string;
  singleLine: boolean;
  sendCurrentCellFocus: SendCurrentCellFocus;
};

export function useKeyDownHandler(params: KeyDownParams) {
  return useHandler((event: React.KeyboardEvent<HTMLElement>) =>
    handleKeyDown(event, params),
  );
}

/**
 * Handles key down events on the editor.
 */
function handleKeyDown(
  event: React.KeyboardEvent<HTMLElement>,
  params: KeyDownParams,
) {
  const { field, getCellFocus, value, sendCurrentCellFocus, singleLine } =
    params;

  const cellFocus = getCellFocus();
  if (cellFocus.type === "none") {
    event.preventDefault();
    return;
  }

  const node = event.currentTarget;
  const editorNode = node?.firstChild;
  if (!editorNode) {
    Sentry.captureWarning("Editor node seems to be missing", { node, event });
    return;
  }

  sendCurrentCellFocus({ node, field });

  const {
    atBottom,
    atTop,
    cellFocus: currentCellFocus,
  } = isCursorAtTopOrBottom(node, field);

  switch (event.key) {
    case "Enter": {
      if (!handleEnter(event, singleLine)) {
        return;
      }
      break;
    }

    case "ArrowDown": {
      if (!handleArrowDown(event, atBottom)) {
        return;
      }
      break;
    }

    case "ArrowUp": {
      if (!handleArrowUp(event, atTop)) {
        return;
      }
      break;
    }

    case "ArrowLeft": {
      if (!handleArrowLeft(event, currentCellFocus, field)) {
        return;
      }
      break;
    }

    case "ArrowRight": {
      if (!handleArrowRight(event, currentCellFocus, field, value)) {
        return;
      }
      break;
    }
  }

  stopPropagation(event);
}

function handleEnter(event: React.KeyboardEvent, singleLine: boolean): boolean {
  if (singleLine) {
    event.preventDefault();
  }

  if (!event.shiftKey) {
    return false;
  }

  return true;
}

function handleArrowDown(
  event: React.KeyboardEvent,
  atBottom: boolean,
): boolean {
  if (atBottom && !event.shiftKey) {
    return false;
  }

  return true;
}

function handleArrowUp(event: React.KeyboardEvent, atTop: boolean): boolean {
  if (atTop && !event.shiftKey) {
    return false;
  }

  return true;
}

function handleArrowLeft(
  event: React.KeyboardEvent,
  cellFocus: CellFocus | undefined,
  id: string,
): boolean {
  const offset = cellFocus && getCursorOffset(cellFocus, id);
  if (offset === 0 && isMac ? !event.metaKey : !event.ctrlKey) {
    return false;
  }

  return true;
}

function handleArrowRight(
  event: React.KeyboardEvent,
  cellFocus: CellFocus | undefined,
  id: string,
  value: string,
): boolean {
  const offset = cellFocus && getCursorOffset(cellFocus, id);
  if (
    offset !== undefined && offset >= charCount(value) && isMac
      ? !event.metaKey
      : !event.ctrlKey
  ) {
    return false;
  }

  return true;
}
