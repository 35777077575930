import { Icon } from "@fiberplane/ui";
import { styled } from "styled-components";

type Props = {
  onClick: () => void;
  visible?: boolean;
};

/**
 * A very thin, blue, button that stretches along the bottom of a table to
 * append rows.
 */
export function AppendRowButton({ onClick }: Props): JSX.Element {
  return (
    <StyledBottomButton onClick={onClick}>
      <Icon iconType="plus" width={16} height={16} />
    </StyledBottomButton>
  );
}

const StyledBottomButton = styled.button`
  width: 100%;
  height: 16px;
  margin: 6px 0 0;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colorPrimary50};
  color: ${({ theme }) => theme.colorPrimary500};
  outline: none;
  padding: 0;
  border: none;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colorPrimary100};
  }
`;

/**
 * A very thin, blue, button that stretches along the right side of a table to
 * append columns.
 */
export function AppendColumnButton({ onClick, visible }: Props): JSX.Element {
  return (
    <StyledRightSideButton onClick={onClick} visible={visible}>
      <Icon iconType="plus" width={16} height={16} />
    </StyledRightSideButton>
  );
}

const StyledRightSideButton = styled.button<{ visible?: boolean }>`
  position: absolute;
  right: -22px;
  top: 0px;
  width: 16px;
  height: calc(100% - 30px);
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colorPrimary50};
  color: ${({ theme }) => theme.colorPrimary500};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.1s ease-in-out;
  outline: none;
  padding: 0 0;
  border: none;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colorPrimary100};
    opacity: 1;
  }
`;
