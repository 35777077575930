import { useMemo } from "react";
import { useSelector } from "react-redux";

import { makeModalActiveSelector } from "../selectors";
import type { ModalType } from "../types";

export function useIsModalActive(key: ModalType | string) {
  const selectIsModalActive = useMemo(
    () => makeModalActiveSelector(key),
    [key],
  );
  return useSelector(selectIsModalActive);
}
