import { styled } from "styled-components";

export const FieldContentContainer = styled.div`
  display: flex;
  flex: 0 1 100%;
  gap: 8px;
  flex-wrap: wrap;
  border-radius: 6px;
  padding: 0 5px 0 0px;
`;
