import type { Draft } from "immer";

import { CellById } from "../CellById";
import {
  type RemoveCellOptions,
  addCellAtIndex,
  applyOwnOperation,
  focusForRemoveCellOptions,
  removeCell,
  removeCells,
  replaceCells as replaceCellsAction,
  replaceText,
  resolveCellErrors,
  showError,
  withActiveNotebook,
  withNotebook,
} from "../actions";
import { DEFAULT_FIELDS, EVENTS_MIME_TYPE, TITLE_CELL_ID } from "../constants";
import type { EventWithIndex } from "../hooks";
import {
  selectActiveNotebook,
  selectCell,
  selectCellIds,
  selectCellOrSurrogate,
  selectCellText,
  selectCellsInSelection,
  selectContextMenu,
  selectNotebookCellIds,
  selectNotebookFocus,
  selectNthCellId,
} from "../selectors";
import { Api } from "../services";
import type { RootState } from "../state";
import type { Thunk } from "../store";
import type {
  Cell,
  CellTypeProperties,
  LogRecordIndex,
  NotebookContextMenuInfo,
  NotebookFocus,
  ProviderEvent,
  ReplaceCellsPayload,
} from "../types";
import { hasFormatting, isContentCell } from "../types";
import {
  charCount,
  getField,
  getNewCellProperties,
  getParentCellId,
  getSelectedCellIds,
  getSelection,
  getStartPosition,
  last,
  makeCell,
  omit,
  parseImage,
  recordIndexMatches,
  setQueryField,
  uuid64,
  withNewId,
} from "../utils";
import { setThreadDeletionPrompt } from "./discussionsThunks";
import { focusCell } from "./editorThunks";
import { showDiscussionCellDeleteConfirmation } from "./modalThunks";
import { addNotification } from "./notificationsThunks";
import { invokeProviderCell } from "./providerThunks";

type AddCellOptions = {
  relatedId: string;
  position: "before" | "after";
  closeMenu?: NotebookContextMenuInfo;
  properties?: CellTypeProperties;
};

export const addCell =
  ({
    relatedId,
    closeMenu,
    properties = { type: "text" },
    position = "after",
  }: AddCellOptions): Thunk<string> =>
  (dispatch, getState) => {
    const cell = makeCell(uuid64(), properties);
    const index =
      selectActiveNotebook(getState()).cellIds.indexOf(relatedId) +
      (position === "after" ? 1 : 0);

    dispatch(withActiveNotebook(addCellAtIndex({ cell, index, closeMenu })));
    return cell.id;
  };

type AddCellWithDividerOptions = {
  relatedId: string;
};

export const addCellWithDivider =
  ({ relatedId }: AddCellWithDividerOptions): Thunk =>
  (dispatch, getState) => {
    const dividerIndex =
      selectActiveNotebook(getState()).cellIds.indexOf(relatedId) + 1;
    const textCellId = uuid64();
    const newCells = [
      { cell: makeCell(uuid64(), { type: "divider" }), index: dividerIndex },
      { cell: makeCell(textCellId, { type: "text" }), index: dividerIndex + 1 },
    ];

    dispatch(
      withActiveNotebook(
        replaceCellsAction({
          newCells,
          focus: { type: "collapsed", cellId: textCellId, offset: 0 },
        }),
      ),
    );
  };

type AddImageCellOptions = AddCellOptions & {
  file: File;
  error?: string;
};

/**
 * This thunk creates an Image Cell and in case of an error dispatches an error
 * message. If no error is passed in the image upload is triggered automatically
 * for drag & drop feature.
 */
export const addImageCell =
  ({
    relatedId,
    closeMenu,
    position = "after",
    file,
    error,
  }: AddImageCellOptions): Thunk =>
  (dispatch, getState) => {
    const newCellId = uuid64();
    const newCell = makeCell(newCellId, { type: "image" });

    let index = selectActiveNotebook(getState()).cellIds.indexOf(relatedId);
    if (position === "after" || index === -1) {
      index++;
    }

    dispatch(
      withActiveNotebook(addCellAtIndex({ cell: newCell, index, closeMenu })),
    );

    if (error) {
      dispatch(
        withActiveNotebook(
          showError(
            {
              type: "other",
              message: error.toString(),
            },
            { cellId: newCellId },
          ),
        ),
      );
      return;
    }

    dispatch(uploadImage(newCellId, file));
  };

export const addAndInvokeProviderCell =
  ({ intent, queryData }: { intent: string; queryData: string }): Thunk =>
  (dispatch, getState) => {
    const cellId = uuid64();
    const cell = makeCell(cellId, {
      type: "provider",
      intent,
      queryData,
    });

    dispatch(
      withActiveNotebook(
        addCellAtIndex({
          cell,
          index: selectActiveNotebook(getState()).cellIds.length,
        }),
      ),
    );
    dispatch(invokeProviderCell(cellId));
    dispatch(focusCell({ cellId, field: "query" }));
  };

export const addImageCellAtEnd =
  (options: Omit<AddImageCellOptions, "relatedId" | "position">): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const notebook = selectActiveNotebook(state);
    const cellCount = notebook.cellIds.length;
    if (cellCount === 0) {
      return dispatch(
        addImageCell({ ...options, relatedId: "", position: "after" }),
      );
    }

    const lastCellId = selectNthCellId(state, cellCount - 1);
    if (!lastCellId) {
      throw new Error("Unexpected error: can't find the last cell");
    }

    return dispatch(
      addImageCell({
        ...options,
        relatedId: lastCellId,
        position: "after",
      }),
    );
  };

type ChangeCellTypeOptions = {
  /**
   * Specify the context menu info if you want to close it atomically.
   */
  closeMenu?: NotebookContextMenuInfo;

  /**
   * Specify the updated content if you want to change it automatically.
   */
  content?: string;

  file?: File;

  fileError?: string;

  /**
   * New focus to set after changing the cell type.
   */
  focus?: NotebookFocus;

  /**
   * Specify the updated title if you want to change it automatically
   */
  title?: string;
};

export const changeCellType =
  (
    cellId: string,
    properties: CellTypeProperties,
    options?: ChangeCellTypeOptions,
  ): Thunk =>
  // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: FIXME
  (dispatch, getState) => {
    const cell = selectCell(getState(), cellId);
    if (!cell) {
      throw new Error("Cell not found");
    }

    const updatedCell = makeCell(cellId, properties) as Draft<Cell>;

    // Transfer content, if possible:
    if (isContentCell(cell) && isContentCell(updatedCell)) {
      updatedCell.content = options?.content ?? cell.content;
    }

    if (hasFormatting(updatedCell)) {
      updatedCell.formatting = (hasFormatting(cell) && cell.formatting) || [];
    }

    const newCells = [updatedCell];
    if (properties.type === "divider") {
      newCells.push(makeCell(uuid64(), { type: "text" }));
    }

    dispatch(
      replaceCells({
        oldCellIds: [cell.id],
        newCells,
        closeMenu: options?.closeMenu,
        focus: options?.focus,
      }),
    );

    // Handle image upload specific parameters here
    if (options?.fileError) {
      dispatch(
        withActiveNotebook(
          showError(
            {
              type: "other",
              message: options.fileError,
            },
            { cellId },
          ),
        ),
      );
      return;
    }

    if (options?.file) {
      dispatch(uploadImage(cellId, options.file));
    }
  };

export const duplicateFocusedCells = (): Thunk => (dispatch, getState) => {
  const state = getState();
  const focus = selectNotebookFocus(state);
  const contextMenu = selectContextMenu(state);

  if (focus.type === "none" || !contextMenu) {
    return;
  }

  if (focus.type === "collapsed") {
    const cell = selectCell(state, focus.cellId);
    if (!cell) {
      return;
    }

    const cellId = uuid64();

    dispatch(
      withActiveNotebook(
        addCellAtIndex({
          cell: withNewId(cell, cellId),
          index: selectActiveNotebook(state).cellIds.indexOf(cell.id) + 1,
          closeMenu: contextMenu,
          focus: {
            ...focus,
            cellId,
          },
        }),
      ),
    );
    return;
  }

  if (focus.type === "selection") {
    const cellIds = selectCellIds(state);
    const selection = getSelection(cellIds, focus);
    const cellsInSelection = selectCellsInSelection(state, selection);

    const cellIdsInSelection = getSelectedCellIds(cellIds, focus);
    const anchorIndex = cellIdsInSelection.indexOf(focus.anchor.cellId);
    const focusedIndex = cellIdsInSelection.indexOf(focus.focus.cellId);

    const lastCellIndex = selectActiveNotebook(state).cellIds.indexOf(
      selection.end.cellId,
    );
    const newCells = cellsInSelection.map((cell, index) => ({
      cell: withNewId(cell, uuid64()),
      index: lastCellIndex + index + 1,
    }));

    dispatch(
      withActiveNotebook(
        replaceCellsAction({
          newCells,
          closeMenu: contextMenu,
          focus: {
            ...focus,
            anchor: {
              ...focus.anchor,
              cellId: newCells[anchorIndex]?.cell.id ?? focus.anchor.cellId,
            },
            focus: {
              ...focus.focus,
              cellId: newCells[focusedIndex]?.cell.id ?? focus.focus.cellId,
            },
          },
        }),
      ),
    );
  }
};

export const deleteFocusedCells = (): Thunk => (dispatch, getState) => {
  const state = getState();
  const focus = selectNotebookFocus(state);
  const contextMenu = selectContextMenu(state);
  const hasBlockingCells = dispatch(handleBlockingCells());

  if (focus.type === "none" || !contextMenu || hasBlockingCells) {
    return;
  }

  if (focus.type === "collapsed") {
    const cell = selectCell(state, focus.cellId);
    if (!cell) {
      return;
    }

    dispatch(withActiveNotebook(removeCell(focus.cellId)));
    return;
  }

  if (focus.type === "selection") {
    const cellIds = selectCellIds(state);
    const selection = getSelection(cellIds, focus);
    const cellsInSelection = selectCellsInSelection(state, selection);
    const cellIdsInSelection = cellsInSelection.map((cell) => cell.id);

    dispatch(withActiveNotebook(removeCells(cellIdsInSelection)));
  }
};

/**
 * Helper thunk that handles cells that are blocking immediate deletion: cells
 * that are read-only or discussion cells. Returns true if the deletion should
 * be blocked.
 * @param specificCells If specified, only these cells will be checked for if
 * they're blocking. Otherwise, the currently focused cells will be checked.
 * @returns true if the focus contains blocking cell(s), false otherwise.
 */
export const handleBlockingCells =
  (specificCells?: Array<Cell>): Thunk<boolean> =>
  // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: FIXME
  (dispatch, getState) => {
    const state = getState();

    const cells = specificCells ?? getCellsFromFocus(state);

    const [cell] = cells;
    if (!cell) {
      return false;
    }

    // If there is only one cell selected and it is a discussion cell, we show
    // the thread deletion prompt in the cell itself. Otherwise we'll show the
    // confirmation dialog. In case the user has a field focused within the
    // discussion cell, we'll not show the prompt.
    if (cells.length === 1 && cell.type === "discussion") {
      const focus = selectNotebookFocus(state);
      const hasFieldFocus = getField(focus);
      if (hasFieldFocus) {
        return false;
      }

      dispatch(
        setThreadDeletionPrompt({
          threadId: cell.threadId,
          showThreadDeletionPrompt: true,
        }),
      );

      return true;
    }

    const readOnlyCells = cells.filter(({ readOnly }) => readOnly);
    if (readOnlyCells.length > 0) {
      for (const cell of readOnlyCells) {
        CellById.get(cell.id)?.shake();
      }

      dispatch(
        addNotification({
          type: "warning",
          title: "Cannot delete read-only cells",
          description:
            "If you want to delete read-only cells you have to unlock them first.",
        }),
      );

      return true;
    }

    const containsDiscussionCells = cells.some(
      ({ type }) => type === "discussion",
    );
    if (containsDiscussionCells) {
      dispatch(showDiscussionCellDeleteConfirmation(cells));

      return true;
    }

    return false;
  };

type MergeCellsArguments = { sourceId: string; targetId: string };

export const mergeCells =
  (
    { sourceId, targetId }: MergeCellsArguments,
    options?: RemoveCellOptions,
  ): Thunk =>
  (dispatch, getState) => {
    const notebook = selectActiveNotebook(getState());
    const sourceCell = notebook.cellsById[sourceId]?.cell;
    const targetCell = notebook.cellsById[targetId]?.cell;
    if (
      // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version (which is less readable)
      !sourceCell ||
      !isContentCell(sourceCell) ||
      !targetCell ||
      !isContentCell(targetCell)
    ) {
      throw new Error("Invalid cell to merge");
    }

    dispatch(
      replaceCells({
        oldCellIds: [targetCell.id, sourceCell.id],
        newCells: [makeCell(targetCell.id, targetCell)],
        splitOffset: charCount(targetCell.content),
        mergeOffset: 0,
        focus: focusForRemoveCellOptions(options),
      }),
    );
  };

type ReplaceCellsOptions = {
  oldCellIds: ReadonlyArray<string>;
  newCells: ReadonlyArray<Cell>;
} & Omit<ReplaceCellsPayload, "oldCellIds" | "newCells">;

export const replaceCells =
  ({ oldCellIds, newCells, ...options }: ReplaceCellsOptions): Thunk =>
  (dispatch, getState) => {
    if (!Array.isArray(oldCellIds) || oldCellIds.length === 0) {
      return;
    }

    const cellIds = selectCellIds(getState());
    const index = cellIds.indexOf(oldCellIds[0]);

    dispatch(
      withActiveNotebook(
        replaceCellsAction({
          oldCellIds,
          newCells: newCells.map((cell, i) => ({ cell, index: index + i })),
          ...options,
        }),
      ),
    );
  };

type SplitCellWithImageOptions = {
  file: File;
  focus: NotebookFocus;
  error?: string;
};

export const splitCellWithImage =
  ({ file, focus, error }: SplitCellWithImageOptions): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const cellIds = selectCellIds(state);
    const startPosition = getStartPosition(cellIds, focus);
    const cellId = startPosition?.cellId;
    const cell = cellId && selectCell(state, cellId);

    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version (which is less readable)
    if (!cell || !isContentCell(cell)) {
      throw new Error("No focus cell to split at");
    }

    const imageCellId = uuid64();

    dispatch(
      replaceCells({
        oldCellIds: [cellId],
        newCells: [
          makeCell(cellId, getNewCellProperties(cell)),
          makeCell(imageCellId, { type: "image" }),
          makeCell(uuid64(), getNewCellProperties(cell)),
        ],
        splitOffset: startPosition.offset,
        mergeOffset: startPosition.offset,
      }),
    );

    if (error) {
      dispatch(
        withActiveNotebook(
          showError(
            { type: "other", message: error.toString() },
            { cellId: imageCellId },
          ),
        ),
      );
      return;
    }

    dispatch(uploadImage(imageCellId, file));
  };

type MoveCellOptions = {
  subjectCellId: string;
  position: "before" | "after";
  targetCellId: string;
};

export const moveCell =
  ({ subjectCellId, position, targetCellId }: MoveCellOptions): Thunk =>
  (dispatch, getState) => {
    const { cellIds } = selectActiveNotebook(getState());
    const fromIndex = cellIds.indexOf(subjectCellId);
    const toIndex =
      cellIds.indexOf(targetCellId) + (position === "after" ? 1 : 0);

    dispatch(
      withActiveNotebook(
        applyOwnOperation({
          type: "move_cells",
          cellIds: [subjectCellId],
          fromIndex,
          // Make sure we compensate for the fact the subject won't be counted
          // towards the target index anymore if it moves down.
          toIndex: fromIndex < toIndex ? toIndex - 1 : toIndex,
        }),
      ),
    );
  };

export const moveCellToEnd =
  (cellId: string): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const cellIds = selectNotebookCellIds(state);

    // Cell is already the last one in the notebook
    if (cellId === last(cellIds)) {
      return;
    }

    dispatch(
      withActiveNotebook(
        applyOwnOperation({
          type: "move_cells",
          cellIds: [cellId],
          fromIndex: cellIds.indexOf(cellId),
          toIndex: cellIds.length - 1,
        }),
      ),
    );
  };

type UpdateCellOptions = { focus?: NotebookFocus };

export const updateCell =
  (id: string, properties: Partial<Cell>, options?: UpdateCellOptions): Thunk =>
  (dispatch, getState) => {
    const cell = selectCell(getState(), id);
    if (!cell) {
      throw new Error("Cell not found");
    }

    let newCell = { ...cell, ...omit(properties, "id", "type") };

    // TODO Arend: We might want to introduce a specialized operation for
    //             updating nested cells. `ReplaceCells` works, but it is
    //             unnecessarily crude and causes quite some data overhead...
    const parentCellId = getParentCellId(id);
    if (parentCellId) {
      const parentCell = selectCell(getState(), parentCellId);
      if (parentCell?.type !== "provider") {
        throw new Error("Parent cell not found or has incorrect type");
      }

      newCell = {
        ...parentCell,
        output: parentCell.output?.map((cell) =>
          cell.id === id ? newCell : cell,
        ),
      };
    }

    // Did the new cell become read-only? Then disable live mode, if needed.
    if (newCell.readOnly && newCell.type === "provider") {
      newCell.queryData = setQueryField(newCell.queryData, "live", "");
    }

    dispatch(
      replaceCells({
        oldCellIds: [newCell.id],
        newCells: [newCell],
        focus: options?.focus,
      }),
    );
  };

export const toggleLockFocusedCells =
  (cellId: string): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const focus = selectNotebookFocus(state);
    const cell = selectCell(state, cellId);

    if (!cell) {
      throw new Error(
        "Unable to toggle read-only state as there is no cell found",
      );
    }

    if (focus.type === "selection") {
      const cellIds = selectCellIds(state);
      const selection = getSelection(cellIds, focus);
      const cellsInSelection = selectCellsInSelection(state, selection);
      const cellIdsInSelection = cellsInSelection.map((cell) => cell.id);
      const shouldLock = cellsInSelection.some((cell) => !cell.readOnly);
      const newCells = cellsInSelection.map((cell) => ({
        ...cell,
        readOnly: shouldLock,
      }));

      dispatch(
        replaceCells({
          oldCellIds: cellIdsInSelection,
          newCells,
          focus,
        }),
      );

      return;
    }

    dispatch(
      updateCell(cell.id, {
        readOnly: !cell.readOnly,
      }),
    );
  };

export const updateCellText =
  (cellId: string, newText: string): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const focus = selectNotebookFocus(state);
    const field = getField(focus);
    const cell = selectCellOrSurrogate(state, cellId);
    const oldText = cell ? selectCellText(state, cell, field) : undefined;
    if (oldText === undefined) {
      throw new Error("Cell not found or has incorrect type");
    }

    if (newText === oldText) {
      return; // Nothing to do.
    }

    const { offset, oldTextSlice, newTextSlice } = findDifferenceStartEnd(
      oldText,
      newText,
    );

    dispatch(
      withActiveNotebook(
        replaceText({
          cellId,
          field,
          offset,
          oldText: oldTextSlice,
          newText: newTextSlice,
        }),
      ),
    );
  };

export const findDifferenceStartEnd = (oldText: string, newText: string) => {
  // Find common prefix:
  let start = 0;
  const oldLen = oldText.length;
  const newLen = newText.length;
  for (; start < oldLen && start < newLen; start++) {
    if (oldText.charCodeAt(start) !== newText.charCodeAt(start)) {
      break;
    }
  }

  // Find common suffix:
  let oldEnd = oldLen;
  let newEnd = newLen;
  for (; oldEnd > start && newEnd > start; oldEnd-- && newEnd--) {
    if (oldText.charCodeAt(oldEnd - 1) !== newText.charCodeAt(newEnd - 1)) {
      break;
    }
  }

  return {
    offset: charCount(oldText, start),
    oldTextSlice: oldText.slice(start, oldEnd),
    newTextSlice: newText.slice(start, newEnd),
  };
};

export const toggleCellDisplayField =
  (id: string, displayField: string): Thunk =>
  (dispatch, getState) => {
    const cell = selectCell(getState(), id);
    if (cell?.type !== "log") {
      throw new Error("Cell not found or has incorrect type");
    }

    const { displayFields = DEFAULT_FIELDS } = cell;

    const checked = displayFields.includes(displayField);
    const newDisplayFields = checked
      ? displayFields.filter((item) => item !== displayField)
      : [...displayFields, displayField];
    dispatch(
      updateCell(id, {
        displayFields: newDisplayFields,
      }),
    );
  };

export const toggleSelectAllRows =
  (id: string, records: Array<EventWithIndex>): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const cell = selectCell(state, id);
    if (cell?.type !== "log") {
      throw new Error("Cell not found or has incorrect type");
    }

    const { selectedIndices = [] } = cell;
    const selected = selectedIndices.length > 0;

    const newSelectedIndices: Array<LogRecordIndex> = selected
      ? []
      : records.map((event) => event.recordIndex);

    dispatch(updateCell(id, { selectedIndices: newSelectedIndices }));
  };

export const updateCellDisplayOrder =
  (id: string, oldIndex: number, newIndex: number): Thunk =>
  (dispatch, getState) => {
    const cell = selectCell(getState(), id);
    if (cell?.type !== "log") {
      throw new Error("Cell not found or has incorrect type");
    }

    // Check if there's nothing to move
    if (oldIndex === newIndex) {
      return;
    }

    const displayFields = [...(cell.displayFields || DEFAULT_FIELDS)];
    const [element] = displayFields.splice(oldIndex, 1);

    if (!element) {
      throw new Error(
        `unable to find element ${oldIndex} new index: ${newIndex}`,
      );
    }

    displayFields.splice(
      newIndex > oldIndex ? newIndex - 1 : newIndex,
      0,
      element,
    );

    dispatch(updateCell(id, { displayFields }));
  };

export const highlightSelectedRows =
  (id: string): Thunk =>
  (dispatch, getState) => {
    const cell = selectCell(getState(), id);
    if (cell?.type !== "log") {
      throw new Error("Cell not found or has incorrect type");
    }

    const { selectedIndices = [] } = cell;
    // Check if there's nothing to move
    if (selectedIndices.length === 0) {
      return;
    }

    const highlightedIndices = selectedIndices.map((element) => ({
      ...element,
    }));

    dispatch(updateCell(id, { highlightedIndices }));
  };

export const exportSelectedLogRecords =
  (id: string, records: Array<EventWithIndex>): Thunk =>
  // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: FIXME
  (dispatch, getState) => {
    const state = getState();
    const providerCellId = getParentCellId(id);
    if (!providerCellId) {
      throw new Error(
        "Can only export records from a log cell embedded in a provider cell",
      );
    }

    const logCell = selectCell(state, id);
    const providerCell = providerCellId
      ? selectCell(state, providerCellId)
      : null;
    if (logCell?.type !== "log" || providerCell?.type !== "provider") {
      throw new Error("Cell not found or has incorrect type");
    }

    const { displayFields = DEFAULT_FIELDS, selectedIndices = [] } = logCell;
    if (selectedIndices.length === 0) {
      return; // Nothing to export.
    }

    const exportedRecords: Array<ProviderEvent> = [];
    for (const { linkIndex, recordIndex } of selectedIndices) {
      for (const event of records) {
        if (
          recordIndex === event.recordIndex.recordIndex &&
          linkIndex === event.recordIndex.linkIndex
        ) {
          exportedRecords.push(event);
          break;
        }
      }
    }

    const newId = uuid64();

    const exportedCell: Cell = {
      type: "provider",
      id: newId,
      intent: providerCell.intent,
      output: [
        {
          type: "log",
          id: `${newId}/log`,
          dataLinks: [
            `data:${EVENTS_MIME_TYPE}+json,${JSON.stringify(exportedRecords)}`,
          ],
          displayFields,
        },
      ],
      queryData: providerCell.queryData,
      readOnly: true,
    };

    const cellIds = selectCellIds(state);
    dispatch(
      withActiveNotebook(
        replaceCellsAction({
          newCells: [
            { cell: exportedCell, index: cellIds.indexOf(providerCellId) + 1 },
          ],
        }),
      ),
    );
  };

/**
 * Returns whether we should apply the cell action to the current cell, as
 * opposed to adding a new below.
 */
export const shouldChangeCurrentCell =
  ({
    cellId,
    menuType,
    typeAheadText,
  }: NotebookContextMenuInfo): Thunk<boolean> =>
  (_, getState) => {
    if (cellId === TITLE_CELL_ID) {
      return false;
    }

    const cell = selectCell(getState(), cellId);
    if (!cell) {
      throw new Error("Context menu is not opened for a valid cell");
    }

    return (
      menuType === "cell_actions" ||
      (isContentCell(cell) && cell.content === `/${typeAheadText}`)
    );
  };

export const toggleExpandIndex =
  (id: string, recordIndex: LogRecordIndex): Thunk =>
  (dispatch, getState) => {
    const cell = selectCell(getState(), id);
    if (cell?.type !== "log") {
      throw new Error("Cell not found or has incorrect type");
    }

    const { expandedIndices = [] } = cell;

    const matchesRecordIndex = recordIndexMatches(recordIndex);
    const newExpandedIndexes = expandedIndices.some(matchesRecordIndex)
      ? expandedIndices.filter((index) => !matchesRecordIndex(index))
      : [...expandedIndices, recordIndex];

    dispatch(updateCell(id, { expandedIndices: newExpandedIndexes }));
  };

export const toggleSelectedIndex =
  (id: string, recordIndex: LogRecordIndex): Thunk =>
  (dispatch, getState) => {
    const cell = selectCell(getState(), id);
    if (cell?.type !== "log") {
      throw new Error("Cell not found or has incorrect type");
    }

    const { selectedIndices = [] } = cell;

    const matchesRecordIndex = recordIndexMatches(recordIndex);
    const newSelectedIndices = selectedIndices.some(matchesRecordIndex)
      ? selectedIndices.filter((index) => !matchesRecordIndex(index))
      : [...selectedIndices, recordIndex];

    dispatch(updateCell(id, { selectedIndices: newSelectedIndices }));
  };

export const uploadImage =
  (cellId: string, file: File): Thunk =>
  async (dispatch, getState) => {
    let progress = 5;
    dispatch(withActiveNotebook(resolveCellErrors([cellId])));
    dispatch(updateCell(cellId, { progress }));

    const state = getState();
    // We need to be careful to use `withNotebook()` rather than
    // `withActiveNotebook()` in this thunk, because the active notebook might
    // change during an `await`, which would otherwise result in dispatching
    // results to the wrong notebook.
    const { id: notebookId } = selectActiveNotebook(state);

    let intervalId: ReturnType<typeof setInterval> | undefined;
    try {
      const increaseProgress = () => {
        const stepSize = 0.05;
        progress += stepSize * (100 - progress);
        dispatch(updateCell(cellId, { progress }));
      };

      intervalId = setInterval(increaseProgress, 1000);

      const result = await parseImage(file);

      const fileInfo = await Api.uploadFile(notebookId, file);
      clearTimeout(intervalId);

      if (fileInfo?.fileId) {
        dispatch(
          updateCell(cellId, {
            fileId: fileInfo.fileId,
            progress: undefined,
            width: result.width,
            height: result.height,
            preview: result.preview,
          }),
        );
      }
    } catch (error: unknown) {
      // Clear fake progress indicator
      if (intervalId) {
        clearInterval(intervalId);
      }

      // Reset progress
      dispatch(
        updateCell(cellId, {
          progress: undefined,
        }),
      );

      // Set cell to failed state for local user
      dispatch(
        withNotebook(
          notebookId,
          showError(
            {
              type: "other",
              message: error?.toString() || "Unexpected error",
            },
            { cellId },
          ),
        ),
      );
    }
  };

function getCellsFromFocus(state: RootState): Array<Cell> {
  const focus = selectNotebookFocus(state);

  switch (focus.type) {
    case "none":
      return [];

    case "collapsed": {
      const cell = selectCell(state, focus.cellId);
      return cell ? [cell] : [];
    }

    case "selection": {
      const cellIds = selectCellIds(state);
      const selection = getSelection(cellIds, focus);
      return selectCellsInSelection(state, selection);
    }
  }
}
