import { IconButton } from "@fiberplane/ui";
import { type Target, type TargetAndTransition, motion } from "framer-motion";
import { css, styled } from "styled-components";

export const BreadcrumbLabel = styled(motion.span)(
  ({ theme }) => css`
    background: ${theme.color.bg.subtle};
    color: ${theme.color.fg.muted};
    font: ${theme.font.body.md.regular};
    border-radius: ${theme.radius.default};
    max-width: max-content;
    display: inline-flex;
    align-items: center;
    padding: 0 8px;
    gap: 8px;
    min-height: 32px;
  `,
);

export const BreadcrumbIconButton = styled(IconButton).attrs({
  buttonStyle: "tertiary-grey",
})`
  margin-left: -8px;
`;

const initial: Target = {
  opacity: 0,
  translateX: -16,
};

const animate: TargetAndTransition = {
  opacity: 1,
  translateX: 0,
};

const exit: TargetAndTransition = {
  ...initial,
};

BreadcrumbLabel.defaultProps = {
  initial,
  animate,
  exit,
  layout: true,
};
