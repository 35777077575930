import { AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";

import { hideContextMenu } from "../../actions";
import { selectActiveContextMenu } from "../../selectors";
import type { ContextMenuInfo, ContextMenuType } from "../../state";
import { dispatch } from "../../store";
import { LabelSuggestionsMenu } from "./LabelSuggestionsMenu";

const MENU_OFFSET: [number, number] = [0, 3];

export function ContextMenu(): JSX.Element {
  const contextMenu = useSelector(selectActiveContextMenu);

  return (
    <AnimatePresence>
      {contextMenu && <PositionedContextMenu {...contextMenu} />}
    </AnimatePresence>
  );
}

function PositionedContextMenu(contextMenu: ContextMenuInfo): JSX.Element {
  const { element, menuType } = contextMenu;

  const MenuComponent = getMenuComponent(menuType);

  return (
    <MenuComponent
      contextMenu={contextMenu}
      element={element}
      offset={MENU_OFFSET}
      onClose={closeMenu}
    />
  );
}

function closeMenu() {
  dispatch(hideContextMenu());
}

function getMenuComponent(menuType: ContextMenuType) {
  switch (menuType) {
    case "auto_suggest_labels":
      return LabelSuggestionsMenu;
  }
}
