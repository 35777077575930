import { useHandler } from "@fiberplane/hooks";
import { AttachedPopup, IconButton } from "@fiberplane/ui";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

import { useClosePopupHandlers, useIsModalActive } from "../../../hooks";
import { selectDataSources } from "../../../selectors";
import { dispatch } from "../../../store";
import { setSelectedDataSource, showModal } from "../../../thunks";
import { setZeroTimeout } from "../../../utils";
import { MenuContainer } from "../../UI";
import { OFFSET } from "../constants";
import { DataSourceSelector } from "./DataSourceSelector";
import { SetupTeaser } from "./SetupTeaser";

export function DataSourceToggle(): JSX.Element {
  const [buttonElement, setButtonElement] = useState<HTMLElement | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isPopupVisible, setPopupVisible] = useState(false);

  const isModalOpen = useIsModalActive("directAccess");
  const onClose = useHandler(() => {
    setZeroTimeout(() => setPopupVisible(false));
  });

  useClosePopupHandlers({
    isActive: isPopupVisible,
    onClose,
    popupRef: contentRef,
  });

  const dataSources = useSelector(selectDataSources);

  const hasDataSources = dataSources.length > 0;
  const [teaserVisible, setTeaserVisible] = useState(false);

  useEffect(() => {
    if (hasDataSources) {
      setTeaserVisible(false);
    } else {
      const id = setTimeout(() => setTeaserVisible(true), 1500);
      return () => clearTimeout(id);
    }
  }, [hasDataSources]);

  return (
    <>
      <IconButton
        ref={setButtonElement}
        onClick={() => setPopupVisible(!isPopupVisible)}
        data-testid="manage-data-source-toggle"
        aria-label="Manage Data Sources"
        iconType="source"
        buttonStyle="secondary"
      />

      {teaserVisible &&
        !isModalOpen &&
        !isPopupVisible &&
        !hasDataSources &&
        buttonElement && (
          <SetupTeaser
            element={buttonElement}
            onClose={() => setTeaserVisible(false)}
          />
        )}
      {isPopupVisible && !isModalOpen && (
        <AttachedPopup
          element={buttonElement}
          placement="bottom-end"
          offset={OFFSET}
        >
          <DataSourceDropdown
            ref={contentRef}
            data-testid="manage-data-sources"
          >
            <DataSourceSelector
              onConfigureDirectAccess={(params) => {
                dispatch(showModal({ type: "directAccess", ...params }));
                setPopupVisible(false);
              }}
              onSelect={(dataSource) => {
                dispatch(setSelectedDataSource(dataSource));
                setPopupVisible(false);
              }}
            />
          </DataSourceDropdown>
        </AttachedPopup>
      )}
    </>
  );
}

const DataSourceDropdown = styled(MenuContainer)`
  width: min(90vw, 440px);
  max-height: 90vh;
  padding: 20px 12px;
  border-radius: ${({ theme }) => theme.radius.rounded};
`;
