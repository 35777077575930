import type { Timestamp } from "../../../types";
import type { TimeRangePickerProps } from "./TimeRangePicker";
import type { TimestampPickerProps } from "./TimestampPicker";

export function isTimeRangePickerComponent(
  props: TimeRangePickerProps | TimestampPickerProps,
): props is TimeRangePickerProps {
  return props.value !== undefined && typeof props.value !== "string";
}

export type DateParts = {
  month: number;
  date: number;
  year: number;
  timestamp: Timestamp;
};

export function getDateParts(date: Date): DateParts {
  return {
    month: date.getMonth(),
    date: date.getDate(),
    year: date.getFullYear(),
    timestamp: date.toISOString(),
  };
}
