import { useMemo } from "react";
import styled from "styled-components";

type MarkdownTooltipProps = {
  text: string;
};

export function MarkdownishTooltip({ text }: MarkdownTooltipProps) {
  const children = useMemo(() => parseMarkdownish(text), [text]);
  return <StyledMarkdownTooltip>{children}</StyledMarkdownTooltip>;
}

/**
 * Function that turns markdown-like text into a component tree that can be rendered in tooltip
 * 
 * @NOTE Only supports bold text (via asterisks) and escaped newlines (via `\\n`)
 * 
 * Example: 
 *   Executing `parseMarkdownish("*Drag* to move \\n *Click* to open menu");`
 *   would result in a component :
     <>
       <StyledMarkdownTooltipLine>
         <StyledMarkdownTooltipBold>
          Drag
         </StyledMarkdownTooltipBold>
         to move
       </StyledMarkdownTooltipLine>
       <StyledMarkdownTooltipLine>
         <StyledMarkdownTooltipBold>
          Click
         </StyledMarkdownTooltipBold>
         to open menu
       </StyledMarkdownTooltipLine>
     </>
 */
function parseMarkdownish(text: string) {
  const lines = text
    // Split by "newlines", which are literal `\n` in a string surrounded by spaces
    .split(/\s+\\n\s+/)
    // Text by surrounding with asterisks should be in bold
    .map((line) => line.split(/(\*[^*]+\*)/g));

  return (
    <>
      {lines.map((line, lineIndex) => {
        return (
          <StyledMarkdownTooltipLine key={lineIndex}>
            {line.map((part, partIndex) => {
              if (part.startsWith("*") && part.endsWith("*")) {
                // Remove asterisks and wrap with proper tag
                return (
                  <StyledMarkdownTooltipBold key={partIndex}>
                    {part.slice(1, -1)}
                  </StyledMarkdownTooltipBold>
                );
              }

              return part;
            })}
          </StyledMarkdownTooltipLine>
        );
      })}
    </>
  );
}

const StyledMarkdownTooltip = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 0;
`;

const StyledMarkdownTooltipLine = styled.span`
  line-height: 20px;
`;

const StyledMarkdownTooltipBold = styled.b`
  color: ${({ theme }) => theme.color.primary};
  font: ${({ theme }) => theme.font.body.md.medium};
  font-weight: bold;
  line-height: 20px;
`;
