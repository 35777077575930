import { compact } from "./compact";

const QUERY_DATA_MIMETYPE_PREFIX = "application/x-www-form-urlencoded,";

export type Intent = {
  providerType: string;
  dataSourceKey?: string;
  queryType: string;
  queryData?: string;
};

export class InvalidIntentError extends Error {}

/**
 * Parses an [Intent](https://www.notion.so/fiberplane/RFC-45-Provider-Protocol-2-0-Revised-4ec85a0233924b2db0010d8cdae75e16#c8ed5dfbfd764e6bbd5c5b79333f9d6e).
 */
export function parseIntent(intent: string): Intent {
  const commaIndex = intent.indexOf(",");
  if (commaIndex === -1) {
    throw new InvalidIntentError();
  }

  let providerType = intent.slice(0, commaIndex);
  let queryType = intent.slice(commaIndex + 1);

  // Extract the data source name, if there is any:
  let dataSourceKey: string | undefined;
  const semicolonIndex = providerType.indexOf(";");
  if (semicolonIndex > -1) {
    dataSourceKey = providerType.slice(semicolonIndex + 1);
    providerType = providerType.slice(0, semicolonIndex);
  }

  // Extract the query data, if there is any:
  let queryData: string | undefined;
  const questionMarkIndex = queryType.indexOf("?");
  if (questionMarkIndex > -1) {
    queryData = `${QUERY_DATA_MIMETYPE_PREFIX}${queryType.slice(
      questionMarkIndex + 1,
    )}`;
    queryType = queryType.slice(0, questionMarkIndex);
  }

  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version (which is less readable)
  if (!providerType || !queryType) {
    throw new InvalidIntentError();
  }

  return {
    providerType,
    dataSourceKey,
    queryType,
    queryData,
  };
}

/**
 * Stringifies an [Intent](https://www.notion.so/fiberplane/RFC-45-Provider-Protocol-2-0-Revised-4ec85a0233924b2db0010d8cdae75e16#c8ed5dfbfd764e6bbd5c5b79333f9d6e).
 */
export function stringifyIntent(intent: Intent): string {
  const { providerType, dataSourceKey, queryType, queryData } = intent;

  return compact([
    providerType,
    dataSourceKey && `;${dataSourceKey}`,
    ",",
    queryType,
    queryData?.startsWith(QUERY_DATA_MIMETYPE_PREFIX) &&
      `?${queryData.slice(QUERY_DATA_MIMETYPE_PREFIX.length)}`,
  ]).join("");
}
