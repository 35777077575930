import { Button, Icon } from "@fiberplane/ui";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

import { makeProxySelector } from "../../../../../selectors";
import type { DataSource } from "../../../../../types";
import { ProviderTypeIcon, StatusBadge } from "../../../../UI";
import { StatusIndicator, StyledTableContent, StyledTd } from "../shared";
import { formatDataSourceError } from "../utils";

const StyledEllipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 1 0;
`;

const ProxyIcon = styled(Icon)`
  display: none;
  width: 24px;
  height: 24px;

  @media (min-width: 768px) {
    display: inline-block;
  }
`;

type Props = {
  dataSource: DataSource;
};

export function DataSourceItem({ dataSource }: Props) {
  const { name, providerType, proxyName, status, error } = dataSource;

  const proxySelector = useMemo(
    () => (proxyName ? makeProxySelector(proxyName) : () => undefined),
    [proxyName],
  );
  const proxy = useSelector(proxySelector)?.data;

  const errorMessage = error && formatDataSourceError(error);

  return (
    <tr>
      <StyledTd>
        <StyledTableContent>
          <ProviderTypeIcon type={providerType} />
        </StyledTableContent>
      </StyledTd>
      <StyledTd>
        <StyledTableContent>
          <StyledEllipsis title={name}>{name}</StyledEllipsis>
        </StyledTableContent>
      </StyledTd>
      <StyledTd>
        <StyledTableContent>
          <StatusBadge
            $status={!status || status === "connected" ? "success" : "danger"}
            aria-label={errorMessage}
          >
            {!status || status === "connected" ? "online" : "offline"}
          </StatusBadge>

          {status === "error" && (
            <Button
              // @ts-expect-error FIXME: This is rendered correctly in the DOM, but the type definition is wrong.
              as="a"
              href="https://docs.fiberplane.com/docs/configuration-help-faq"
              target="_blank"
              rel="noopener noreferrer"
              buttonStyle="tertiary-grey"
            >
              <Icon iconType="question" />
            </Button>
          )}
        </StyledTableContent>
      </StyledTd>
      <StyledTd colSpan={2}>
        <StyledTableContent>
          {proxyName && (
            <>
              <StatusIndicator status={proxy?.status ?? "connected"} />
              <ProxyIcon iconType="proxy" />
              <StyledEllipsis title={proxyName}>{proxyName}</StyledEllipsis>
            </>
          )}
        </StyledTableContent>
      </StyledTd>
    </tr>
  );
}
