import { useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import styled, { css } from "styled-components";

import { Input } from "@fiberplane/ui";
import { useSelector } from "react-redux";
import { selectIntegrations } from "../../../../../selectors";
import {
  type Integration,
  IntegrationType,
  loadIntegrations,
  unloadIntegrationList,
} from "../../../../../slices";
import { dispatch } from "../../../../../store";
import { showSettingsModal } from "../../../../../thunks";
import type {
  PersonalIntegrationSummary,
  WorkspaceIntegrationSummary,
} from "../../../../../types";
import { EmptyMessage } from "../shared";
import { IntegrationCard } from "./IntegrationCard";

/**
 * Adding ids to this array will make sure studio doesn't render them in the list
 */
const HIDDEN_INTEGRATIONS: Array<Integration["id"]> = [];

type IntegrationListProps = {
  type: IntegrationType;
};

export function IntegrationsList({ type }: IntegrationListProps) {
  const { control, register } = useForm<{ query: string }>({
    defaultValues: { query: "" },
  });
  const query = useWatch({ control, name: "query" });

  const { data: integrations, loading } = useSelector(selectIntegrations);

  const { available, connected } = useMemo(() => {
    const available: Array<
      PersonalIntegrationSummary | WorkspaceIntegrationSummary
    > = [];
    const connected: Array<
      PersonalIntegrationSummary | WorkspaceIntegrationSummary
    > = [];

    const visibleIntegrations = integrations
      ? integrations.filter(({ id }) => !HIDDEN_INTEGRATIONS.includes(id))
      : [];

    const filtered = query
      ? visibleIntegrations.filter(({ id }) => id.includes(query))
      : visibleIntegrations;

    for (const summary of filtered) {
      if (summary.status.type === "disconnected") {
        available.push(summary);
        continue;
      }

      connected.push(summary);
    }

    return {
      available,
      connected,
    };
  }, [integrations, query]);

  useEffect(() => {
    const promise = dispatch(loadIntegrations({ type }));

    return () => {
      promise.abort();
      dispatch(unloadIntegrationList());
    };
  }, [type]);

  const section =
    type === IntegrationType.Personal
      ? "personalIntegrations"
      : "workspaceIntegrations";

  return (
    <Container>
      {integrations && integrations.length > 0 && (
        <Input
          type="text"
          placeholder="Search integrations"
          {...register("query")}
        />
      )}
      {connected.length === 0 && available.length === 0 ? (
        loading ? null : (
          <EmptyMessage
            iconType="plugs"
            title={
              query
                ? "No integrations match your query"
                : "No integrations available"
            }
            description={undefined}
            action={undefined}
          />
        )
      ) : (
        <>
          {connected.length > 0 && (
            <Section>
              <strong>Connected</strong>
              {connected.map((integrationProfile) => {
                return (
                  <IntegrationCard
                    key={integrationProfile.id}
                    type={type}
                    summary={integrationProfile}
                    onClick={() =>
                      dispatch(
                        showSettingsModal({
                          section,
                          integrationId: integrationProfile.id,
                        }),
                      )
                    }
                  />
                );
              })}
            </Section>
          )}
          {available.length > 0 && (
            <Section>
              <strong>Available</strong>
              {available.map((integrationProfile) => {
                return (
                  <IntegrationCard
                    type={type}
                    key={integrationProfile.id}
                    summary={integrationProfile}
                    onClick={() =>
                      dispatch(
                        showSettingsModal({
                          section,
                          integrationId: integrationProfile.id,
                        }),
                      )
                    }
                  />
                );
              })}
            </Section>
          )}
        </>
      )}
    </Container>
  );
}

const Container = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,
);

const Section = styled.section(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    > strong {
      grid-column: 1 / 5;
      color: ${theme.color.fg.default};
      font: ${theme.font.headings.h4};
    }
  `,
);
