// Allows for safe indexing of collections & returns a union type of possible
// indices.
type SafeIndices<
  N extends number,
  U extends Array<number> = [],
> = U["length"] extends N ? U[number] : SafeIndices<N, [...U, U["length"] | 0]>;

/**
 * Helper function that returns an index that is safe to use as an index for a
 * collection.
 * @param index
 * @param length length of the collection
 */
export function getSafeIndex<const I extends number>(index: number, length: I) {
  return ((index < 0 ? 0 : index) % length) as SafeIndices<I>;
}
