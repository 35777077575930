import type { ConnectionStatus, CourierAction } from "../types";

export type { CourierAction } from "../types";

export const incomingMessage = (data: string): CourierAction => ({
  type: "courier/incoming_message",
  payload: data,
});

export const setConnectionStatus = (
  connectionStatus: ConnectionStatus,
): CourierAction => ({
  type: "courier/set_connection_status",
  payload: connectionStatus,
});
