import { Icon, IconButton, stopPropagation } from "@fiberplane/ui";
import { useSelector } from "react-redux";
import { css, styled } from "styled-components";

import { useListSnippetsQuery } from "../../api";
import { selectActiveWorkspaceIdOrThrow } from "../../selectors";
import { dispatch } from "../../store";
import { showModal, showSnippetDeleteConfirmation } from "../../thunks";
import type { SnippetSummary } from "../../types";
import {
  Box,
  MenuItem,
  PageContentItem,
  PageContentPlaceholder,
  PageContentPlaceholderDescription,
  PageContentPlaceholderText,
  RelativeTimestamp,
  Text,
  ToggleMenu,
} from "../UI";

const EMPTY_SNIPPETS_LIST: Array<SnippetSummary> = [];

export function SnippetItems() {
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const { data: snippets = EMPTY_SNIPPETS_LIST } = useListSnippetsQuery(
    { workspaceId },
    { refetchOnMountOrArgChange: true },
  );

  if (!snippets || snippets.length === 0) {
    return (
      <PageContentPlaceholder icon="scissors">
        <PageContentPlaceholderText>
          You don&rsquo;t have any snippets saved
        </PageContentPlaceholderText>

        <PageContentPlaceholderDescription>
          Save snippets by selecting one or multiple cells and selecting the
          &lsquo;Save as snippet&rsquo; option from the menu.
        </PageContentPlaceholderDescription>
      </PageContentPlaceholder>
    );
  }

  return (
    <>
      {snippets.map(({ createdAt, description, id, name, updatedAt }) => (
        <SnippetsContentItem
          onClick={() =>
            dispatch(showModal({ type: "editSnippet", snippetName: name }))
          }
          key={id}
          data-testid="snippet-link"
          data-snippet-name={name}
        >
          <IconContainer>
            <Icon iconType="scissors" width={24} />
          </IconContainer>

          <SnippetTitle>{name}</SnippetTitle>
          <SnippetDetails>
            {createdAt !== updatedAt ? (
              <>
                Updated <RelativeTimestamp timestamp={updatedAt} />
              </>
            ) : (
              <>
                Created <RelativeTimestamp timestamp={createdAt} />
              </>
            )}
            {description && <> &bull; {description}</>}
          </SnippetDetails>

          <MenuHandleContainer>
            <ToggleMenu
              data-notebook-menu
              offset={[0, 6]}
              onClick={stopPropagation}
              placement="bottom-start"
              toggleElement={({ anchorRef, opened }) => (
                <div ref={anchorRef}>
                  <IconButton iconType="ellipsis" isActive={opened} data-menu />
                </div>
              )}
            >
              <MenuItem
                key="snippet-edit"
                id="snippet-edit"
                iconLeft={<Icon iconType="pencil_simple" />}
                onActivate={() =>
                  dispatch(
                    showModal({ type: "editSnippet", snippetName: name }),
                  )
                }
                title="Edit"
              />
              <MenuItem
                key="snippet-delete"
                id="snippet-delete"
                iconLeft={<Icon iconType="trash" />}
                onActivate={() =>
                  dispatch(showSnippetDeleteConfirmation({ snippetName: name }))
                }
                title="Delete"
              />
            </ToggleMenu>
          </MenuHandleContainer>
        </SnippetsContentItem>
      ))}
    </>
  );
}

const SnippetsContentItem = styled(PageContentItem)`
  /* HACK - Override PageContentItem styles */
  border: none;
  margin: 0;

  /* Style the snippet container */
  grid:
    "icon title menu" 1fr
    "icon details menu" 1fr
    / 48px 1fr auto;
  gap: 0 20px;
  padding: ${({ theme }) => theme.height.xx.small};
  border-radius: ${({ theme }) => theme.radius.default};
`;

const IconContainer = styled.div(
  ({ theme }) => css`
    grid-area: icon;
    background-color: ${theme.color.bg.emphasis.neutral};
    color: ${theme.color.fg.muted};
    border-radius: ${theme.radius.default};
    display: grid;
    place-content: center;
    width: 100%;
    aspect-ratio: 1;
  `,
);

const MenuHandleContainer = styled(Box)`
  grid-area: menu;
  align-self: center;
  padding: 4px;
  border-radius: ${({ theme }) => theme.radius.default};
`;

const SnippetTitle = styled.h4(
  ({ theme }) => css`
    grid-area: title;
    margin: 0;
    font: ${theme.font.headings.h4};
    line-height: 20px;
  `,
);

const SnippetDetails = styled(Text)(
  ({ theme }) => css`
    grid-area: details;
    font: ${theme.font.body.md.regular};
    color: ${theme.color.fg.muted};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
);
