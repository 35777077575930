import { createSelector } from "reselect";

import type { LabelsEditorState, RootState } from "../state";
import type { ActiveLabelsEditorType, Label, QueryField } from "../types";
import {
  convertApiLabelToClientLabel,
  getFocusPosition,
  getQueryField,
  parseIntent,
  parseLabel,
} from "../utils";
import {
  selectCommandMenuLabels,
  selectCommandMenuLabelsEditor,
  selectCommandMenuVariant,
} from "./commandMenuSelectors";
import {
  getActiveQuerySchema,
  selectFocusedProviderCellQueryData,
} from "./dataSourcesSelectors";
import { selectNotebookFocus } from "./editorSelectors";
import { selectLabelsEditor, selectLabelsState } from "./labelsSelectors";
import {
  selectFocusedProviderCell,
  selectNotebookLabels,
} from "./notebookSelectors";
import { selectActiveView } from "./viewsSelectors";

const emptyArray = [] as const;

/**
 * Returns the focused query field in the currently focused provider cell.
 */
export const selectFocusedProviderField = (
  state: RootState,
): QueryField | undefined => {
  const cell = selectFocusedProviderCell(state);
  if (!cell) {
    return;
  }

  const notebookFocus = selectNotebookFocus(state);
  const focus = getFocusPosition(notebookFocus);
  if (!focus?.field) {
    return;
  }

  const schema = getActiveQuerySchema(state, parseIntent(cell.intent)) || [];
  return schema.find((field) => field.name === focus.field);
};

/**
 * Determines the type of labels editor that is currently active.
 *
 * This is used in `selectLabelsEditorState()` to select the relevant state
 * slice.
 */
export const selectActiveLabelsEditorType = (
  state: RootState,
): ActiveLabelsEditorType => {
  if (selectCommandMenuVariant(state).type !== "none") {
    return "command_menu";
  }

  return selectLabelsState(state).activeEditorType;
};

export const selectActiveProviderFieldLabels = createSelector(
  [selectFocusedProviderField, selectFocusedProviderCellQueryData],
  (field, queryData) =>
    field?.type === "label"
      ? getQueryField(queryData, field.name).split(" ").map(parseLabel)
      : emptyArray,
);

export const selectViewLabels = createSelector([selectActiveView], (view) => {
  return view?.labels ?? [];
});

export const selectEventModalMenuLabels = (state: RootState): Array<Label> =>
  convertApiLabelToClientLabel(state.events.activeEvent?.labels || {});

/**
 * Selects the labels the labels editor is operating on, depending on which
 * type of editor is currently active.
 */
export const selectLabelsEditorLabels = (
  state: RootState,
): ReadonlyArray<Label> => {
  switch (selectActiveLabelsEditorType(state)) {
    case "command_menu":
      return selectCommandMenuLabels(state);

    case "event_modal":
      return selectEventModalMenuLabels(state);

    case "none":
      return emptyArray;

    case "notebook":
      return selectNotebookLabels(state);

    case "provider_field":
      return selectActiveProviderFieldLabels(state);

    case "view":
      return selectViewLabels(state);
  }
};

/**
 * Selects the relevant state slice for the labels editor, depending on which
 * type of editor is currently active.
 */
export const selectLabelsEditorState = (
  state: RootState,
): LabelsEditorState | undefined => {
  switch (selectActiveLabelsEditorType(state)) {
    case "command_menu":
      return selectCommandMenuLabelsEditor(state);

    case "none":
      return undefined;

    case "event_modal":
    case "notebook":
    case "provider_field":
    case "view":
      return selectLabelsEditor(state);
  }
};

export const selectLabelsEditorExistingLabelKeys = createSelector(
  [selectLabelsEditorLabels],
  (labels): ReadonlyArray<string> => labels.map(({ key }) => key),
);

export const selectLabelSuggestions = createSelector(
  [selectLabelsEditorState],
  (editorState) => editorState?.suggestions,
);

export const selectLabelSuggestionsKey = createSelector(
  [selectLabelsEditorState],
  (editorState) => editorState?.suggestionsKey,
);

export const selectLabelKeySuggestions = createSelector(
  [selectLabelSuggestions],
  (suggestions) => suggestions?.data?.keys ?? emptyArray,
);

export const selectLabelValueSuggestions = createSelector(
  [selectLabelSuggestions],
  (suggestions) => suggestions?.data?.values ?? emptyArray,
);
