import { styled } from "styled-components";

import type { ProviderEvent } from "../../../types";
import { sortBy } from "../../../utils";
import { RowControls } from "./RowControls";
import {
  formatAttributeKey,
  isAttributeKey,
  stripAttributePrefix,
} from "./utils";

type Props = {
  displayFields: Array<string>;
  expanded: boolean;
  record: ProviderEvent;
  similarValuedKeys: Array<string>;
  selected: boolean;
  toggleExpand: () => void;
  toggleSelected?: () => void;
};

export function formatRow({
  displayFields,
  expanded,
  record,
  selected,
  similarValuedKeys,
  toggleExpand,
  toggleSelected,
}: Props): Array<React.ReactNode> {
  return [
    <RowControls
      key="row-controls"
      expanded={expanded}
      selected={selected}
      toggleExpand={toggleExpand}
      toggleSelected={toggleSelected}
    />,
    ...displayFields.map((field) =>
      formatRowContent(field, record, similarValuedKeys),
    ),
  ];
}

export function formatRowContent(
  field: string,
  record: ProviderEvent,
  similarValuedKeys: Array<string> = [],
) {
  switch (field) {
    case "timestamp":
      return record.time;

    case "body":
      return record.title;

    case "document":
      return formatDocument(record, similarValuedKeys);

    default: {
      if (isAttributeKey(field)) {
        const key = stripAttributePrefix(field);
        if (key in record.attributes) {
          return record.attributes[key];
        }
      }

      return "Not set";
    }
  }
}

function formatDocument(
  record: ProviderEvent,
  similarValuedKeys: Array<string>,
): React.ReactNode {
  const allAttributeEntries = Object.entries(record.attributes);
  const attributeEntries =
    similarValuedKeys.length > 0
      ? allAttributeEntries.filter(
          ([key]) =>
            similarValuedKeys.includes(formatAttributeKey(key)) === false,
        )
      : allAttributeEntries;

  return (
    <Container>
      {similarValuedKeys.includes("body") === false && (
        <StyledKeyValuePair key="body">
          <StyledKey>body</StyledKey>:<StyledValue>{record.title}</StyledValue>
        </StyledKeyValuePair>
      )}
      {sortBy(attributeEntries, ([key]) => key).map(([key, value]) => (
        <StyledKeyValuePair key={key}>
          <StyledKey>{key}</StyledKey>:<StyledValue>{value}</StyledValue>
        </StyledKeyValuePair>
      ))}
    </Container>
  );
}

const Container = styled.div`
  height: min-content;
`;

const StyledKeyValuePair = styled.span`
  display: inline-block;
  margin: 0 2px;
  font: ${({ theme }) => theme.fontNotebooksCodeShortHand};
  letter-spacing: ${({ theme }) => theme.fontNotebooksCodeLetterSpacing};
`;

const StyledKey = styled.span`
  background-color: ${({ theme }) => theme.colorPrimaryAlpha50};
  padding: 1px 0;
  border-radius: 3px;
`;

const StyledValue = styled.span`
  display: inline-block;
  margin: 0 0 0 4px;
`;
