import { Button, Icon } from "@fiberplane/ui";
import { Fragment, useEffect } from "react";
import styled from "styled-components";
import { css } from "styled-components";
import { MEDIUM_SIZE_QUERY } from "../../../../../constants";
import { useCopyToClipboard } from "../../../../../hooks";
import { dispatch } from "../../../../../store";
import { addNotification } from "../../../../../thunks";
import type { FrontMatterPagerDutyIncident } from "../../../../../types";
import { StatusPill } from "./StatusPill";

import { RelativeTimestamp } from "../../../../UI";
import PagerDutyLogo from "./PagerDutyLogo.svg";

type Props = FrontMatterPagerDutyIncident & {
  requestClose: () => void;
};

export function PagerDutyMetaData(props: Props) {
  const { handleCopy, isCopied } = useCopyToClipboard(props.number.toString());
  useEffect(() => {
    if (isCopied) {
      dispatch(
        addNotification({
          title: "Copied to clipboard",
        }),
      );
    }
  }, [isCopied]);
  return (
    <PopupContent>
      <Controls>
        <PopupHeading>
          <IconContainer>
            <PagerDutyLogo width="30" height="30" />
          </IconContainer>
          <Text>
            <Category>App</Category>
            <Name>PagerDuty</Name>
          </Text>
        </PopupHeading>
        <SmallButton
          buttonStyle="tertiary-grey"
          onClick={() => props.requestClose()}
          aria-label="Close incident details"
        >
          <Icon iconType="close" width="16" height="16" />
        </SmallButton>
      </Controls>
      <PopupList>
        <Term>Title</Term>
        <Definition>
          {props.htmlUrl ? (
            <Link
              href={props.htmlUrl}
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Open in PagerDuty"
            >
              <LinkText>{props.title}</LinkText>
              <LinkIcon iconType="arrow_up_right" />
            </Link>
          ) : (
            props.title
          )}
        </Definition>
        <Term>Incident </Term>
        <Definition>
          <Overflow>{props.number}</Overflow>
          <ButtonContainer>
            <SmallButton buttonStyle="tertiary-grey" onClick={handleCopy}>
              <Icon
                iconType={isCopied ? "check" : "copy"}
                width="16"
                height="16"
                aria-label={isCopied ? undefined : "Copy to clipboard"}
              />
            </SmallButton>
          </ButtonContainer>
        </Definition>
        <Term>Status</Term>
        <Definition>
          <div>
            <StatusPill
              status={props.status}
              reason={props.resolveReason ?? undefined}
            />
          </div>
        </Definition>
        <Term>Urgency</Term>
        <Definition>{props.urgency}</Definition>
        {props.priority && (
          <Fragment key="priority">
            <Term>Status</Term>
            <Definition>
              <Link
                href={props.priority.htmlUrl}
                target="_blank"
                rel="noreferrer noopener"
              >
                {props.priority.summary}
              </Link>
            </Definition>
          </Fragment>
        )}
        {props.service && (
          <Fragment key="service">
            <Term>Service</Term>
            <Definition>
              <Link
                href={props.service.htmlUrl}
                target="_blank"
                rel="noreferrer noopener"
              >
                {props.service.summary}
              </Link>
            </Definition>
          </Fragment>
        )}
        {props.escalationPolicy && (
          <Fragment key="escalationPolicy">
            <Term>Escalation Policy</Term>
            <Definition>
              <Link
                href={props.escalationPolicy.htmlUrl}
                target="_blank"
                rel="noreferrer noopener"
              >
                {props.escalationPolicy.summary}
              </Link>
            </Definition>
          </Fragment>
        )}
        <Term>Created</Term>
        <Definition>
          <div>
            <RelativeTimestamp timestamp={props.createdAt} />
          </div>
        </Definition>
      </PopupList>
    </PopupContent>
  );
}

const PopupContent = styled.div(
  ({ theme }) => css`
    display: grid;
    border: 1px solid ${theme.color.border.muted};
    border-radius: ${theme.radius.default};
    background: ${theme.color.bg.elevated.default};
    padding: ${theme.height.xx.small};
    max-width: min(90vw, 418px);
    gap: 4px;
    box-shadow: ${theme.effect.shadow.s};
    
    @media ${MEDIUM_SIZE_QUERY} {
      gap: ${theme.height.xx.small};
    }
`,
);

const Controls = styled.div`
  display: grid;
  grid-template-columns: auto 36px;
  /* Align close button with the rest of the content */
  margin-right: -6px;
`;

const PopupHeading = styled.div`
  display: grid;
  grid-template: "icon type" auto / 40px auto;
  gap: 16px;
`;

const IconContainer = styled.div(
  ({ theme }) => css`
  width: 40px;
  height: 40px;
  grid-area: icon;
  border-radius: ${theme.radius.default};
  border: 1px solid ${theme.color.border.default};
  display: flex;
  justify-content: center;
  align-items: center;
`,
);

const Text = styled.div`
  display: grid;
`;

const Category = styled.div(
  ({ theme }) => css`
  color: ${theme.color.fg.muted};
  font: ${theme.font.headings.h6};
`,
);

const Name = styled.div(
  ({ theme }) => css`
    font: ${theme.font.headings.h4};
    color: ${theme.color.fg.default};
  `,
);

const PopupList = styled.dl(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr auto;

    column-gap: 12px;
    row-gap: 4px;
    margin: 0;
    padding: 0;

    @media ${MEDIUM_SIZE_QUERY} {
      column-gap: 32px;
      row-gap: 12px;
    }

    color: ${theme.color.fg.muted};
  `,
);

const Term = styled.dt`
  margin: 0;
  padding: 0;
  white-space: nowrap
`;

const Definition = styled.dd`
  margin: 0;
  padding: 0;
  display: flex;
  gap: 4px;
  min-height: 26px;
  /* set min-width so content doesn't push the content outside of the container */
  min-width: 0;
`;

const Link = styled.a(
  ({ theme }) => css`
  position: relative;
  display: inline-flex;
  gap: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  color: ${theme.color.fg.default};

  &:last-child {
    margin-right: 6px;
  }

  &::before {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    border-bottom: 1px solid ${theme.color.border.default};
  }

  &:hover {
    &::before {
      border-color: ${theme.color.fg.default};
    }

    ${LinkIcon} {
      color: ${theme.color.fg.default};
    }
  }
`,
);

const LinkText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
`;

const LinkIcon = styled(Icon)(
  ({ theme }) => css`
  color: ${theme.color.fg.muted};
  align-self: center;
  min-width: 14px;
  min-height: 14px;
`,
);

const Overflow = styled.span`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ButtonContainer = styled.div`
  display: grid;
  align-items: center;
`;

// TODO: (JF) Move this to a shared "small" button component
// Design already has these buttons but the haven't been fully
// implemented yet
const SmallButton = styled(Button)`
  padding: 6px;
  margin-top: -8px;
  margin-bottom: -8px;
`;
