import { useMemo } from "react";

import { useProviderData } from ".";
import { EVENTS_MIME_TYPE } from "../constants";
import type { LogCell, LogRecordIndex, ProviderEvent } from "../types";
import { getParentCellId, parseEventsBlob } from "../utils";

export type EventWithIndex = ProviderEvent & {
  recordIndex: LogRecordIndex;
};

export function useLogRecordsData({
  id,
  dataLinks,
}: LogCell): Array<EventWithIndex> {
  const data = useProviderData(
    getParentCellId(id),
    dataLinks ?? [],
    EVENTS_MIME_TYPE,
    parseEventsBlob,
  );

  return useMemo(
    () =>
      data.flatMap(
        (events, linkIndex) =>
          events?.map((event, recordIndex) => ({
            ...event,
            recordIndex: { linkIndex, recordIndex },
          })) ?? [],
      ),
    [data],
  );
}
