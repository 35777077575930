import { IconButton, stopPropagation } from "@fiberplane/ui";
import cx from "classnames";
import type React from "react";
import { forwardRef } from "react";
import { css, styled } from "styled-components";

import { getAccentColors } from "../../../../../utils";
import type { FilterMenuItem } from "../../../../UI";

export const FieldSelectionElement = forwardRef(function FieldSelectionElement(
  props: {
    onClear?(): void;
    hideClearButton?: boolean;
    colorKey?: string;
    focused?: boolean;
    singleColumn?: boolean;
    /**
     * Function that creates a custom menu item. Useful for adding a "Create new" item
     * or perhaps an interactive No results item.
     */
    createExtraMenuItem?: (options: {
      filterText: string;
      exactMatchFound: boolean;
    }) => FilterMenuItem | undefined;
  } & React.HTMLAttributes<HTMLDivElement>,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const {
    children,
    onClear,
    hideClearButton = false,
    focused = false,
    colorKey,
    singleColumn = false,
    ...divProps
  } = props;
  return (
    <Container
      ref={ref}
      $colorKey={colorKey}
      $singleColumn={singleColumn}
      {...divProps}
      className={cx(props.className, { focused })}
      onKeyUp={
        onClear
          ? (event) => {
              event.key === "Backspace" && onClear();
            }
          : undefined
      }
    >
      <div>{children}</div>
      {onClear && hideClearButton !== true && (
        <SmallIconButton
          iconType="close"
          onClick={(event) => {
            stopPropagation(event);
            onClear();
          }}
          buttonStyle="tertiary-grey"
        />
      )}
    </Container>
  );
});

const Container = styled.div<{
  $colorKey?: string;
  $singleColumn?: boolean;
}>(
  ({ $colorKey, $singleColumn, theme }) => css`
    display: grid;
    align-items: center;
    grid-template-columns: ${$singleColumn ? "auto" : "auto 1fr"};
    gap: 4px;
    padding-left: 4px;
    padding-right: 4px;
    min-height: 24px;
    border-radius: ${theme.borderRadius600};
    border: 1px solid transparent;
    color: ${theme.color.fg.muted};
    ${$colorKey && getAccentColors($colorKey)}
    transition: 0.2s color 0.5s ease-in-out;
    transition-property: background-color, color;

    &:not([aria-disabled="true"]) {
      cursor: pointer;

      &.focused {
        outline: none;

        ${
          $colorKey
            ? css`
          border-color: currentColor;
        `
            : css`
          color:${theme.color.fg.default};
          border-color: ${theme.color.border.default};
        `
        }
      }

      ${
        $colorKey === undefined &&
        css`
          &:hover {
            background-color: ${theme.color.bg.hover};
          }
        `
      }
    }
`,
);

const SmallIconButton = styled(IconButton)`
  width: 16px;
  height: 16px;
  min-height: auto;
  overflow: hidden;
  color: inherit;
`;
