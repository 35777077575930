import { hasFeature } from "../../utils";
import { registerProviderFactory } from "./dataSourceRegistry";
import { createProviderWithUrl } from "./providerLoader";
import {
  awsCloudwatchProviderUrl,
  createAutometricsProvider,
  createFiberplaneProvider,
  elasticsearchProviderUrl,
  httpsProviderUrl,
  lokiProviderUrl,
  prometheusProviderUrl,
  sentryProviderUrl,
} from "./providers";

export function registerProviders() {
  if (hasFeature("autometrics-provider")) {
    registerFauxProvider("autometrics", createAutometricsProvider);
  }

  registerFauxProvider("fiberplane", createFiberplaneProvider);

  registerProviderByUrl("cloudwatch", awsCloudwatchProviderUrl);
  registerProviderByUrl("elasticsearch", elasticsearchProviderUrl);
  registerProviderByUrl("https", httpsProviderUrl);
  registerProviderByUrl("loki", lokiProviderUrl);
  registerProviderByUrl("prometheus", prometheusProviderUrl);
  registerProviderByUrl("sentry", sentryProviderUrl);
}

// Faux providers are really nothing but TypeScript factories that implement
// the same protocol as the WASM modules.
//
// But to be a little bit more precise: Our `Provider` type is implemented by
// the Provider loader (`createProviderWithUrl()`), which injects a bit of glue
// code so the provider will be loaded on-demand when any of the `Provider`
// functions are invoked. Faux providers implement this interface directly
// without any need for such glue code.
//
// See also: https://www.notion.so/fiberplane/RFC-45-Provider-Protocol-2-0-Revised-4ec85a0233924b2db0010d8cdae75e16#2410e153d8624a17a2c5f10523d08169
const registerFauxProvider = registerProviderFactory;

function registerProviderByUrl(type: string, url: string) {
  registerProviderFactory(type, () => createProviderWithUrl(url));
}
