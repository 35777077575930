import { css, styled } from "styled-components";

export const ButtonGroup = styled.span(
  ({ theme }) => css`
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 4px 8px;
    background: ${theme.color.bg.elevated.hover};
    border-radius: ${theme.radius.default};
  `,
);
