import { useHandler } from "@fiberplane/hooks";
import { IconButton, Select } from "@fiberplane/ui";
import { css, styled } from "styled-components";

import { useCellFocus, useCopyToClipboard, useFilePaste } from "../../hooks";
import { selectCellText } from "../../selectors";
import { getState, useActiveNotebookDispatch } from "../../store";
import { updateCell } from "../../thunks";
import type { CodeCell } from "../../types";
import type { SupportedSyntaxName } from "./RTE";
import {
  RichTextInput,
  SupportedSyntax,
  getSupportedSyntax,
  useHighlighter,
} from "./RTE";
import { codePreStyling, preStyling } from "./codeStyling";

export type CodeCellContentProps = {
  cell: { type: "code" } & CodeCell;
  readOnly: boolean;
};

export function CodeCellContent({ cell, readOnly }: CodeCellContentProps) {
  const { content: cellContent, id: cellId } = cell;
  // NOTE - If `syntax` is undefined or not supported, we will fall back to plaintext
  const syntax = getSupportedSyntax(cell.syntax, SupportedSyntax.plaintext);

  const dispatch = useActiveNotebookDispatch();
  const focus = useCellFocus(cellId);

  // TODO - FP-2879: Try to detect syntax on paste
  const onPaste = useFilePaste(cellId);

  const getCodeCellContent = useHandler(() => selectCellText(getState(), cell));
  const { isCopied, handleCopy: handleCopyCodeContent } =
    useCopyToClipboard(getCodeCellContent);

  const formatting = useHighlighter(syntax, cellContent);

  const handleNewSyntax = (newSyntax: string) => {
    // Update the syntax highlighting property tied to the cell.
    // This results in a "replace_cells" OT operation.
    dispatch(updateCell(cellId, { syntax: newSyntax }));
  };

  return (
    <Container>
      <ActionBar>
        <SelectSyntax syntax={syntax} handleNewSyntax={handleNewSyntax} />
        <CopyCodeButton
          iconType={isCopied ? "check" : "copy"}
          buttonStyle="tertiary-grey"
          aria-label={isCopied ? undefined : "Copy code block"}
          disabled={isCopied}
          onClick={isCopied ? undefined : handleCopyCodeContent}
        />
      </ActionBar>
      <Code>
        <StyledRichTextInput
          cellId={cellId}
          focus={focus}
          onPaste={onPaste}
          placeholder="/* Paste code */"
          readOnly={readOnly}
          disableFormatting
          disableSpellCheck
          tagName="pre"
          value={cellContent}
          formatting={formatting}
          browserSelectionDependency={syntax}
        />
      </Code>
    </Container>
  );
}

const SYNTAXES_ALPHABETICAL = Object.values(SupportedSyntax).sort();

const SelectSyntax = ({
  syntax,
  handleNewSyntax,
}: {
  syntax: SupportedSyntaxName;
  handleNewSyntax: (newSyntax: string) => void;
}) => {
  return (
    <StyledSelectContainer>
      <Select
        dropdownPlacement="top-start"
        name="code-cell-syntax"
        onChange={handleNewSyntax}
        options={SYNTAXES_ALPHABETICAL.map((syntaxName) => ({
          children: getHumanReadableSyntaxName(syntaxName),
          value: syntaxName,
        }))}
        value={syntax}
      />
    </StyledSelectContainer>
  );
};

const StyledSelectContainer = styled.div`
  /* HACK - to remove border from selection container */
  [role="combobox"] {
    border: none;
    border-radius: 0;

    background-color: transparent;
  }
`;

const getHumanReadableSyntaxName = (syntax: SupportedSyntaxName): string => {
  switch (syntax) {
    case SupportedSyntax.clike:
      return "C-like";
    case SupportedSyntax.docker:
      return "Docker";
    case SupportedSyntax.js:
      return "JavaScript";
    case SupportedSyntax.json:
      return "JSON";
    case SupportedSyntax.lucene:
      return "Lucene";
    case SupportedSyntax.plaintext:
      return "Plain Text";
    case SupportedSyntax.promql:
      return "PromQL";
    case SupportedSyntax.rust:
      return "Rust";
    case SupportedSyntax.sql:
      return "SQL";
    case SupportedSyntax.xml:
      return "XML";
    case SupportedSyntax.yaml:
      return "YAML";
    default:
      return syntax;
  }
};

const ActionBar = styled.div(
  () => css`
    flex-basis: 100%;
    display: flex;
    position: relative;
  `,
);

const Container = styled.div(
  ({ theme }) => css`
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    background: ${theme.color.bg.subtle};
    border: 1px solid ${theme.color.border.default};
    border-radius: ${theme.radius.default};
    position: relative;

    &:hover {
      ${/* sc-selector */ CopyCodeButton} {
        opacity: 1;
      }
    }
  `,
);

const CopyCodeButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.1s;

  /* NOTE - uses same border radius as cell */
  border-radius: ${({ theme }) => theme.radius.default};

  &:hover {
    cursor: pointer;
  }
`;

const Code = styled.div`
  overflow-x: auto;
  flex: 1;
  /* TODO - Revisit this padding... seems off to my eyes */
  padding: 15px 0 15px 29px;
  width: 100%;
`;

const StyledRichTextInput = styled(RichTextInput)`
  ${/* sc-declaration */ preStyling}
  ${/* sc-declaration */ codePreStyling}
`;
