import type { ConsoleMessage, SideEffectDescriptor } from ".";
// biome-ignore lint/nursery/useImportRestrictions: generated types
import type { NotebookAction as FiberKitNotebookAction } from "../services/FiberKit/ts-bindings/types";

export type {
  ConfigField,
  ConfigSchema,
  Error as ProviderError,
  ProviderConfig,
  ProviderRequest,
  QueryField,
  QuerySchema,
  Result,
  SupportedQueryType,
  // biome-ignore lint/nursery/useImportRestrictions: generated types
} from "../services/DataSources/providerRuntime/types";

// biome-ignore lint/nursery/useImportRestrictions: generated types
export * from "../services/FiberKit/ts-bindings/types";
export * from "./apiTypes";
export * from "./avatarTypes";
export * from "./buttonStyleTypes";
export * from "./CellDrafts";
export * from "./CellFocus";
export * from "./cellTypes";
export * from "./commandMenuTypes";
export * from "./dataSourceTypes";
export * from "./discussionThreadTypes";
export * from "./features";
export * from "./iconVisualTypes";
export * from "./labelsEditorTypes";
export * from "./legacyProviderProtocol";
export * from "./modalTypes";
export * from "./notebookSelections";
export * from "./stateTypes";
export * from "./theme";
export * from "./tooltipTypes";

// Work-around to deal with JS errors that cannot survive serialization:
export type NotebookAction =
  | FiberKitNotebookAction
  | { type: "notebook_js_error"; payload: { id?: string; error: Error } };

export type ResponsesBySourceId = {
  [sourceId: string]: Uint8Array;
};

export type Severity = ConsoleMessage["severity"];

export type StateWithSideEffects<T> = {
  state: T;
  sideEffects?: Array<SideEffectDescriptor>;
};

export type OidcProvider = "google" | "github";
