export const WebhooksIntro = (
  <>
    Webhooks allow your web application to receive real-time notifications of
    changes to notebooks by sending POST requests to a specified endpoint URL.
    Learn more about webhooks in our{" "}
    <a
      href="https://docs.fiberplane.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      documentation
    </a>
    .
  </>
);
