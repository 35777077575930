import { useHandler } from "@fiberplane/hooks";
import { Button } from "@fiberplane/ui";
import { useContext, useState } from "react";
import type { FieldErrors } from "react-hook-form";
import { updateReceiver } from "../../../slices";
import { dispatch } from "../../../store";
import type { UpdatePagerDutyReceiver } from "../../../types";
import { FiberForm, FiberFormTextInputField } from "../../FiberForm";
import { ButtonBar, Modal, ModalContext } from "../../UI";
import { TemplateField } from "./TemplateField";
import { UpdateReceiverFormState } from "./schemas";

export type UpdateReceiverFormProps = {
  name: string;
  receiver: UpdatePagerDutyReceiver;
};

export function UpdatePagerDutyReceiverModal({
  name,
  receiver: { incidentCreatedTemplateName },
}: UpdateReceiverFormProps) {
  const { requestClose } = useContext(ModalContext);

  const [errors, setErrors] = useState<FieldErrors<UpdateReceiverFormState>>();

  const onSubmit = useHandler(
    async (updatePagerDutyReceiver: UpdateReceiverFormState) => {
      const result = await dispatch(
        updateReceiver({ updatePagerDutyReceiver, name }),
      );

      if ("error" in result || result instanceof Error) {
        return setErrors((current) => ({
          ...current,
          root: {
            type: "error",
            message: "An unexpected error occured",
          },
        }));
      }

      requestClose();
    },
  );

  return (
    <Modal title="Update PagerDuty Receiver">
      <FiberForm
        defaultValues={{
          // Nullish coalescer is used here because using null as a default value on react-hook-form fields changes
          // the input from uncontrolled to controlled when the value is updated
          incidentCreatedTemplateName: incidentCreatedTemplateName ?? undefined,
        }}
        errors={errors}
        schema={UpdateReceiverFormState}
        submitErrorHandler={setErrors}
        submitHandler={onSubmit}
      >
        {(control) => (
          <>
            <TemplateField
              control={control}
              name="incidentCreatedTemplateName"
            />
            <FiberFormTextInputField
              label="Secret"
              description="Enter the secret key provided by PagerDuty"
              control={control}
              name="secret"
            />
            <ButtonBar>
              <Button
                type="button"
                buttonStyle="secondary"
                onClick={requestClose}
              >
                Cancel
              </Button>
              <Button type="submit">Update</Button>
            </ButtonBar>
          </>
        )}
      </FiberForm>
    </Modal>
  );
}
