import { CellById } from "../../../CellById";
import {
  replaceCells as replaceCellsAction,
  replaceText,
  withActiveNotebook,
} from "../../../actions";
import { TITLE_CELL_ID } from "../../../constants";
import {
  selectCell,
  selectCellIds,
  selectCellOrSurrogate,
  selectSelectedTextInCell,
} from "../../../selectors";
import type { RootState } from "../../../state";
import type { Thunk } from "../../../store";
import { replaceCells } from "../../../thunks";
import {
  type Cell,
  type Formatting,
  type NotebookFocus,
  isContentCell,
} from "../../../types";
import {
  getEndOffset,
  getField,
  getSelectedCellIds,
  getStartOffset,
  getStartPosition,
  makeCell,
  only,
  uuid64,
  withNewId,
} from "../../../utils";

/**
 * Removes the content inside the selection of the given `focus` and replaces
 * it with the given `replacement`. If the selection is collapsed, this simply
 * inserts the replacement.
 */
export const replaceSelection =
  (focus: NotebookFocus, replacement: string, formatting?: Formatting): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const cellIds = selectCellIds(state);
    const selectedCellIds = getSelectedCellIds(cellIds, focus);

    if (!validateCellsBeforeReplacement(state, selectedCellIds)) {
      return;
    }

    const onlySelectedCellId = only(selectedCellIds);
    if (onlySelectedCellId) {
      // If the selection doesn't extend past a single cell, we can issue a
      // `ReplaceText` operation.
      const cell = selectCellOrSurrogate(state, onlySelectedCellId);
      if (cell) {
        dispatch(
          withActiveNotebook(
            replaceText({
              cellId: onlySelectedCellId,
              field: getField(focus),
              offset: getStartOffset(cellIds, focus),
              oldText: selectSelectedTextInCell(state, cell, focus),
              newText: replacement,
              newFormatting: formatting,
            }),
          ),
        );
        return;
      }
    }

    // Otherwise we need a `ReplaceCells` operation.
    const [firstCellId] = selectedCellIds;
    if (!firstCellId) {
      return;
    }

    const firstCell = selectCell(state, firstCellId);
    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
    if (!firstCell || !isContentCell(firstCell)) {
      return;
    }

    const newCell = {
      ...makeCell(firstCellId, firstCell),
      content: replacement,
    };

    dispatch(
      replaceCells({
        oldCellIds: selectedCellIds,
        newCells: [newCell],
        splitOffset: getStartOffset(cellIds, focus),
        mergeOffset: getEndOffset(cellIds, focus),
      }),
    );
  };

type ReplaceSelectionWithCellsOptions = {
  generateId?: () => string;
};

/**
 * Same as `replaceSelection()`, but inserts a list of cells instead of plain
 * text.
 */
export const replaceSelectionWithCells =
  (
    focus: NotebookFocus,
    replacement: Array<Cell>,
    { generateId = uuid64 }: ReplaceSelectionWithCellsOptions = {},
  ): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const cellIds = selectCellIds(state);
    const selectedCellIds = getSelectedCellIds(cellIds, focus);

    if (!validateCellsBeforeReplacement(state, selectedCellIds)) {
      return;
    }

    const startPosition = getStartPosition(cellIds, focus);
    const index = startPosition ? cellIds.indexOf(startPosition.cellId) : 0;

    dispatch(
      withActiveNotebook(
        replaceCellsAction({
          oldCellIds: selectedCellIds,
          newCells: replacement.map((cell, i) => ({
            cell: withNewId(cell, generateId()),
            index: index + i,
          })),
          splitOffset: startPosition?.offset ?? 0,
          mergeOffset: getEndOffset(cellIds, focus),
        }),
      ),
    );
  };

function validateCellsBeforeReplacement(
  state: RootState,
  cellIds: Array<string>,
): boolean {
  // Make sure we do not attempt to replace any surrogate or locked cells:
  let cellsAreValid = true;
  for (const cellId of cellIds) {
    if (cellId === TITLE_CELL_ID) {
      continue;
    }

    const cell = selectCell(state, cellId);
    if (!cell || cell.readOnly) {
      CellById.get(cellId)?.shake();
      cellsAreValid = false;
    }
  }

  return cellsAreValid;
}
