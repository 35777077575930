import { useEffect } from "react";

import { selectNotebookSyncState } from "../../../selectors";
import { getState } from "../../../store";

export function useCloseTabProtection() {
  useEffect(() => {
    const listener = (event: Event) => {
      const hasPendingChanges = selectNotebookSyncState(getState()) !== "idle";
      if (hasPendingChanges) {
        event.preventDefault();
        // Neither Firefox nor Chrome seem to actually use the following
        // message, but at least the messages they present instead both warn
        // of potential data loss.
        return (
          "Are you sure you want to close Fiberplane Studio? " +
          "Changes to your notebook may be lost."
        );
      }
    };

    window.addEventListener("beforeunload", listener);
    return () => window.removeEventListener("beforeunload", listener);
  }, []);
}
