import type { Label } from "../types";
import type { LabelsEditorState } from "./labelsEditorState";

export type CommandMenuVariant =
  | { type: "none" }
  | {
      type: "search";
      labels?: ReadonlyArray<Label>;
      labelsEditor?: LabelsEditorState;
    }
  | { type: "selectTemplate" };

export type CommandMenuState = {
  readonly selectedIndex: number;
  readonly variant: CommandMenuVariant;
  readonly query: string;
};
