import { IconButton } from "@fiberplane/ui";
import { useRef, useState } from "react";

import type { LogVisibilityFilter } from "../../../types";
import { MenuItemWithCheckbox, PositionedMenu } from "../../UI";

type VisibilityMenuProps = {
  visibilityFilter: LogVisibilityFilter;
  setLogVisibilityFilter: (visibilityFilter: LogVisibilityFilter) => void;
};

export function VisibilityMenu({
  visibilityFilter,
  setLogVisibilityFilter,
}: VisibilityMenuProps) {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton
        ref={anchorRef}
        iconType="eye"
        onClick={() => setOpen(!open)}
        buttonStyle="tertiary-grey"
      />

      {open && (
        <PositionedMenu
          onClose={() => setOpen(false)}
          element={anchorRef.current}
          placement="bottom-start"
          offset={[0, 4]}
        >
          <MenuItemWithCheckbox
            id="all"
            onChange={() => setLogVisibilityFilter("all")}
            title="Show all"
            checked={visibilityFilter === "all"}
          />
          <MenuItemWithCheckbox
            id="selected"
            onChange={() => setLogVisibilityFilter("selected")}
            title="Selected only"
            checked={visibilityFilter === "selected"}
          />
          <MenuItemWithCheckbox
            id="highlighted"
            onChange={() => setLogVisibilityFilter("highlighted")}
            title="Highlighted only"
            checked={visibilityFilter === "highlighted"}
          />
        </PositionedMenu>
      )}
    </>
  );
}
