import type { RootState, TriggerState } from "../state";

export const selectTriggerState = (state: RootState): TriggerState =>
  state.triggers;

export const selectIsLoadingTriggers = (state: RootState): boolean =>
  !!selectTriggerState(state).loading;

export const selectTriggerError = (state: RootState): TriggerState["error"] =>
  selectTriggerState(state).error;
