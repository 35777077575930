import { AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

import { type TemplateSummary, useListTemplatesQuery } from "../../api";
import { selectActiveWorkspaceIdOrThrow } from "../../selectors";
import {
  TemplateContainer,
  TemplateDetails,
  TemplateIcon,
  TemplateIconContainer,
  TemplateListItem,
  TemplateTitle,
  TemplatesAnchor,
} from "./template";

const EMPTY_TEMPLATES_LIST: Array<TemplateSummary> = [];

type TemplateItemsProps = {
  showLearnMore?: boolean;
};

export function TemplateItems({ showLearnMore = false }: TemplateItemsProps) {
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const { data: templates = EMPTY_TEMPLATES_LIST } = useListTemplatesQuery(
    { workspaceId },
    { refetchOnMountOrArgChange: true },
  );

  return (
    <AnimatePresence>
      {templates.map((template) => (
        <TemplateListItem key={template.name} {...template} />
      ))}
      {showLearnMore && (
        <StyledTemplateContainer key="create-template">
          <StyledTemplateIconContainer>
            <TemplateIcon iconType="plus" />
          </StyledTemplateIconContainer>

          <TemplateTitle>
            Templates help make your workflows more efficient
          </TemplateTitle>

          <TemplateDetails>
            Find out how to{" "}
            <TemplatesAnchor
              href="https://docs.fiberplane.com/docs/templates"
              target="_blank"
              rel="noopener"
            >
              create them
            </TemplatesAnchor>
          </TemplateDetails>
        </StyledTemplateContainer>
      )}
      {/* TODO: Discussed with Bart, hiding this for now until we have the new empty state, which includes create notebook interaction */}
      {/*!loading && templates.length === 0 && (
        <PageContentPlaceholder  icon="templates">
          <PageContentPlaceholderText>
            You don&apos;t have any templates yet!
          </PageContentPlaceholderText>
        </PageContentPlaceholder>
      )*/}
    </AnimatePresence>
  );
}

const StyledTemplateContainer = styled(TemplateContainer)`
  cursor: initial;

  &:hover {
    border-color: transparent;
    background: transparent;
  }
`;

const StyledTemplateIconContainer = styled(TemplateIconContainer)`
  background: ${({ theme }) => theme.color.bg.default};
  border: 1px solid ${({ theme }) => theme.color.border.default};
`;
