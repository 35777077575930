import type { Notification } from "../state";

export const ADD_NOTIFICATION = "notifications/addNotification";
export const REMOVE_NOTIFICATION = "notifications/removeNotification";
export const UPDATE_NOTIFICATION = "notifications/updateNotification";

export type NotificationsAction =
  | AddNotificationAction
  | RemoveNotificationAction
  | UpdateNotificationAction;

type AddNotificationAction = {
  type: typeof ADD_NOTIFICATION;
  payload: {
    key: string;
    notification: Notification;
  };
};

type RemoveNotificationAction = {
  type: typeof REMOVE_NOTIFICATION;
  payload: string;
};

type UpdateNotificationAction = {
  type: typeof UPDATE_NOTIFICATION;
  payload: {
    key: string;
    notification: Notification;
  };
};
