import type { ActiveLabelsEditorType, Label } from "../types";

export const LABELS_EDITOR_CLEAR_ORIGINAL = "labelsEditor/clearOriginal";
export const LABELS_EDITOR_DELETE = "labelsEditor/delete";
export const LABELS_EDITOR_DRAFT_CREATE = "labelsEditor/draft/create";
export const LABELS_EDITOR_DRAFT_UPDATE = "labelsEditor/draft/update";
export const LABELS_EDITOR_EDIT = "labelsEditor/edit";
export const LABELS_EDITOR_LOAD_SUGGESTIONS = "labelsEditor/loadSuggestions";
export const LABELS_EDITOR_RESET = "labelsEditor/reset";
export const LABELS_EDITOR_SET_SUGGESTIONS = "labelsEditor/setSuggestions";
export const LABELS_EDITOR_SET_SUGGESTIONS_ERROR =
  "labelsEditor/setSuggestionsError";

export type LabelsEditorAction =
  | ClearOriginalLabelAction
  | CreateLabelDraftAction
  | DeleteLabelAction
  | EditLabelAction
  | LoadLabelSuggestionsAction
  | ResetLabelsEditorAction
  | SetLabelSuggestionsAction
  | SetLabelSuggestionsErrorAction
  | UpdateLabelDraftAction;

export type CreateLabelDraftAction = {
  type: typeof LABELS_EDITOR_DRAFT_CREATE;
  payload: { target: ActiveLabelsEditorType };
};

export type ClearOriginalLabelAction = {
  type: typeof LABELS_EDITOR_CLEAR_ORIGINAL;
  payload: { target: ActiveLabelsEditorType };
};

export type DeleteLabelAction = {
  type: typeof LABELS_EDITOR_DELETE;
  payload: { key: string; target: ActiveLabelsEditorType };
};

export type EditLabelAction = {
  type: typeof LABELS_EDITOR_EDIT;
  payload: { label: Label; target: ActiveLabelsEditorType };
};

export type LoadLabelSuggestionsAction = {
  type: typeof LABELS_EDITOR_LOAD_SUGGESTIONS;
  payload: { key: string; target: ActiveLabelsEditorType };
};

export type ResetLabelsEditorAction = {
  type: typeof LABELS_EDITOR_RESET;
  payload: { target: ActiveLabelsEditorType };
};

export type SetLabelSuggestionsAction = {
  type: typeof LABELS_EDITOR_SET_SUGGESTIONS;
  payload: {
    target: ActiveLabelsEditorType;
    keys: Array<string>;
    values: Array<string>;
  };
};

export type SetLabelSuggestionsErrorAction = {
  type: typeof LABELS_EDITOR_SET_SUGGESTIONS_ERROR;
  payload: {
    error: string;
    target: ActiveLabelsEditorType;
  };
};

export type UpdateLabelDraftAction = {
  type: typeof LABELS_EDITOR_DRAFT_UPDATE;
  payload: { draft: string; target: ActiveLabelsEditorType };
};

/**
 * Clears the original of the label we're editing, effectively turning the
 * current "editing" action into a "create" action.
 *
 * This is used when the label that is currently being edited is removed by
 * another user.
 */
export const clearOriginalLabel = (
  target: ActiveLabelsEditorType,
): LabelsEditorAction => ({
  type: LABELS_EDITOR_CLEAR_ORIGINAL,
  payload: { target },
});

/**
 * Creates a draft for creating a new label.
 */
export const createLabelDraft = (
  target: ActiveLabelsEditorType,
): LabelsEditorAction => ({
  type: LABELS_EDITOR_DRAFT_CREATE,
  payload: { target },
});

/**
 * Deletes the label with the given key.
 */
export const deleteLabel = (
  target: ActiveLabelsEditorType,
  key: string,
): LabelsEditorAction => ({
  type: LABELS_EDITOR_DELETE,
  payload: { key, target },
});

/**
 * Starts editing an existing label.
 */
export const editLabel = (
  target: ActiveLabelsEditorType,
  label: Label,
): LabelsEditorAction => ({
  type: LABELS_EDITOR_EDIT,
  payload: { label, target },
});

/**
 * Indicates we start loading suggestions for the given label key.
 */
export const loadLabelSuggestions = (
  target: ActiveLabelsEditorType,
  key: string,
): LabelsEditorAction => ({
  type: LABELS_EDITOR_LOAD_SUGGESTIONS,
  payload: { key, target },
});

/**
 * Resets the label editor if it is active.
 */
export const resetLabelsEditor = (
  target: ActiveLabelsEditorType,
): LabelsEditorAction => ({
  type: LABELS_EDITOR_RESET,
  payload: { target },
});

/**
 * Sets or updates the loaded suggestions.
 */
export const setLabelSuggestions = (
  target: ActiveLabelsEditorType,
  keys: Array<string>,
  values: Array<string>,
): LabelsEditorAction => ({
  type: LABELS_EDITOR_SET_SUGGESTIONS,
  payload: { keys, target, values },
});

/**
 * Sets an error that might have occurred while loading suggestions.
 */
export const setLabelSuggestionsError = (
  target: ActiveLabelsEditorType,
  error: string,
): LabelsEditorAction => ({
  type: LABELS_EDITOR_SET_SUGGESTIONS_ERROR,
  payload: { error, target },
});

/**
 * Updates the draft of the label we're currently editing or creating.
 */
export const updateLabelDraft = (
  target: ActiveLabelsEditorType,
  draft: string,
): LabelsEditorAction => ({
  type: LABELS_EDITOR_DRAFT_UPDATE,
  payload: { draft, target },
});
