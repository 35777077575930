/* stylelint-disable scale-unlimited/declaration-strict-value */
import type React from "react";
import { useRef, useState } from "react";
import { css, styled } from "styled-components";

type Props = {
  headers: Array<React.ReactNode>;
  stickyFirstCell?: boolean;
  onChangeColumnOrder?: (oldIndex: number, newIndex: number) => void;
};

export function TableHead(props: Props): JSX.Element {
  const { onChangeColumnOrder, headers, stickyFirstCell = false } = props;
  const [dragIndex, setDragIndex] = useState<null | number>(null);
  const [dragTargetIndex, setDragTargetIndex] = useState<number | null>(null);
  const [dragCursor, setDragCursor] = useState(false);
  const [side, setSide] = useState<"left" | "right">("left");
  const selectionRef = useRef<Element | null>(null);

  return (
    <TableHeadElement>
      <Row
        onMouseOut={() => setTimeout(() => setDragCursor(false), 10)}
        onDragEnter={(event) => {
          // If the index is set, this event should not bubble any further
          if (dragIndex !== null) {
            event.stopPropagation();
          }
        }}
      >
        {headers.map((heading, index) => (
          <Head
            key={index}
            tabIndex={0}
            $sticky={stickyFirstCell && index === 0}
            aria-label={index > 0 ? "Click and hold to drag column" : undefined}
            data-tooltip-placement="top"
            data-dragging={index === dragIndex}
            data-drag-hover={dragTargetIndex === index && side}
            data-drag-cursor={dragCursor}
            draggable={index > 0 && !!onChangeColumnOrder}
            onMouseDown={() => {
              setDragCursor(true);
            }}
            onMouseUp={() => {
              setDragCursor(false);
              setDragIndex(null);
            }}
            onDragStart={(event) => {
              setDragIndex(index);
              selectionRef.current = event.currentTarget;
              event.dataTransfer.effectAllowed = "move";
            }}
            onDragOver={(event) => {
              event.stopPropagation();
              if (selectionRef.current && index !== dragIndex && index > 0) {
                event.preventDefault();

                const { x, width } =
                  event.currentTarget.getBoundingClientRect();
                const layerX = event.clientX - x;
                const isLeft = layerX < width * 0.5;
                setSide(isLeft ? "left" : "right");
                setDragTargetIndex(index);
              }
            }}
            onDrop={() => {
              setDragCursor(false);

              if (dragIndex && dragTargetIndex && onChangeColumnOrder) {
                const oldIndex = dragIndex - 1;
                const newIndex =
                  dragTargetIndex - 1 + (side === "left" ? 0 : 1);
                onChangeColumnOrder(oldIndex, newIndex);
              }

              setDragIndex(null);
              setDragTargetIndex(null);
            }}
          >
            {heading}
          </Head>
        ))}
      </Row>
    </TableHeadElement>
  );
}

const TableHeadElement = styled.thead`
  height: 36px;
  background-color: ${({ theme }) =>
    theme.color.notebook.cell.bg.emphasis.neutral};
`;

const Row = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.color.border.default};
`;

const Head = styled.th<{
  "data-dragging"?: boolean;
  $sticky?: boolean;
}>(
  ({ $sticky, theme }) => css`
    padding: 4px 12px;
    font: ${theme.font.headings.h5};
    transition: all 0.1s ease-in-out;

    @media (min-width: 568px) {
      position: ${$sticky ? "sticky" : "relative"};
      left: 0;
      z-index: ${$sticky ? 1 : 0};
    }

    &::before {
      border-right: 3px solid ${theme.color.fg.primary};
      transition: opacity 0.1s ease-in-out;
      content: "";
      position: absolute;
      z-index: 1;
      height: 100%;
      top: 0;
      left: -3px;
      opacity: 0;
    }

    &[data-drag-cursor="true"] {
      cursor: grab;
    }

    &[data-dragging="true"] {
      opacity: 0.5;
      cursor: grabbing;
    }

    &[data-drag-hover="left"] {
      transform: translateX(3px);
    }

    &[data-drag-hover="left"]::before {
      opacity: 1;
    }

    &[data-drag-hover="right"] {
      transform: translateX(-3px);
    }

    &[data-drag-hover="right"]::before {
      opacity: 1;
      left: unset;
      right: -3px;
    }
  `,
);
