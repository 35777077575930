import { IconButton } from "@fiberplane/ui";
import { useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import { push } from "redux-first-history";
import { css, styled } from "styled-components";

import { ROUTES } from "../../constants";
import { type ViewColorSet, useViewColor } from "../../hooks";
import { selectActiveWorkspaceNameOrThrow } from "../../selectors";
import { dispatch } from "../../store";
import type { View } from "../../types";
import {
  PageContentItem,
  PageContentList,
  ViewIcon,
  ViewIconContainer,
} from "../UI";
import { ViewToggleMenu } from "./ViewToggleMenu";

type Props = {
  views: Array<View>;
};

export function ViewList({ views }: Props) {
  return (
    <PageContentList>
      {views.map((view) => (
        <ViewItem key={view.id} view={view} />
      ))}
    </PageContentList>
  );
}

type ViewItemProps = {
  view: View;
};

function ViewItem({ view }: ViewItemProps) {
  const workspaceName = useSelector(selectActiveWorkspaceNameOrThrow);
  const { colorSet } = useViewColor(view.color);

  return (
    <ViewPageContentItem
      key={view.id}
      onClick={() =>
        dispatch(
          push(
            generatePath(ROUTES.View, {
              workspaceName,
              viewName: view.name,
            }),
          ),
        )
      }
      colorSet={colorSet}
    >
      <StyledViewIcon color={view.color} iconSize={20} />
      <DisplayName data-testid="view-displayName">
        {view.displayName}
      </DisplayName>
      <Description>
        {view.description === "" ? "No description provided" : view.description}
      </Description>
      <StyledViewToggleMenu
        view={view}
        toggleElement={({ anchorRef, opened }) => (
          <div ref={anchorRef}>
            <IconButton
              iconType="dots_three"
              buttonSize="small"
              isActive={opened}
            />
          </div>
        )}
      />
    </ViewPageContentItem>
  );
}

const ViewPageContentItem = styled(PageContentItem)<{
  colorSet: ViewColorSet;
}>(
  ({ colorSet }) => css`
    grid:
      "icon title menu" 20px
      "icon description menu" auto
      / 40px 1fr 32px;
    gap: 4px 20px;

    &:hover ${/* sc-selector */ ViewIconContainer} {
      background: ${colorSet.highlight};
    }
  `,
);

const StyledViewIcon = styled(ViewIcon)(
  ({ theme }) => css`
    grid-area: icon;
    width: 40px;
    height: 40px;
    border-radius: ${theme.borderRadius700};
  `,
);

const DisplayName = styled.strong(
  ({ theme }) => css`
    grid-area: title;
    font: ${theme.fontStudioHeadingsH1ShortHand};
    letter-spacing: ${theme.fontStudioHeadingsH1LetterSpacing};
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    font-size: 16px;
    line-height: 20px;
  `,
);

const Description = styled.p(
  ({ theme }) => css`
    grid-area: description;
    margin: 0;
    font: ${theme.fontStudioBodyCopyExtraSmallShortHand};
    letter-spacing: ${theme.fontStudioBodyCopyExtraSmallLetterSpacing};
    color: ${theme.colorBase600};
  `,
);

const StyledViewToggleMenu = styled(ViewToggleMenu)`
  grid-area: menu;
`;
