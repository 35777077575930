import type { Label } from "../../../types";
import {
  Toolbar,
  ToolbarButton,
  ToolbarIcon,
  type ToolbarProps,
} from "./Toolbar";

type Props = {
  element: ToolbarProps["element"];
  label: Label;
  onDelete?: (event: React.SyntheticEvent, label: Label) => void;
  onEdit?: (event: React.SyntheticEvent, label: Label) => void;
};

export function HoverToolbar({
  element,
  label,
  onDelete,
  onEdit,
}: Props): JSX.Element {
  return (
    <Toolbar element={element}>
      {onEdit && (
        <ToolbarButton onClick={(event) => onEdit(event, label)}>
          <ToolbarIcon iconType="pencil_simple" />
          Edit
        </ToolbarButton>
      )}
      {onDelete && (
        <ToolbarButton onClick={(event) => onDelete(event, label)}>
          <ToolbarIcon iconType="trash" />
          Delete
        </ToolbarButton>
      )}
    </Toolbar>
  );
}
