import type { Formatting, Thread } from "../../types";
import {
  binaryResponse,
  deleteRequest,
  getRequest,
  parseJsonResponse,
  patchJsonRequest,
  postJsonRequestDeprecated,
  postRawRequest,
} from "./utils";

type NewComment = {
  id?: string;
  content: string;
  formatting?: Formatting;
};

type NewThread = {
  id?: string;
  comment?: NewComment;
};

type UpdateComment = {
  content?: string;
  formatting?: Formatting;
};

export const createComment = ({
  newComment,
  threadId,
}: {
  newComment: NewComment;
  threadId: string;
}) =>
  postJsonRequestDeprecated(
    `/api/threads/${encodeURIComponent(threadId)}/comments`,
    newComment,
    binaryResponse,
  );

export const createThread = (notebookId: string, newThread: NewThread) =>
  postJsonRequestDeprecated<Thread>(
    `/api/notebooks/${encodeURIComponent(notebookId)}/threads`,
    newThread,
    parseJsonResponse,
  );

export const deleteComment = (commentId: string) =>
  deleteRequest(`/api/comments/${encodeURIComponent(commentId)}`);

export const updateComment = (
  commentId: string,
  updatedComment: UpdateComment,
): Promise<Uint8Array> =>
  patchJsonRequest(
    `/api/comments/${encodeURIComponent(commentId)}`,
    updatedComment,
    binaryResponse,
  );

export const deleteThread = (threadId: string) =>
  deleteRequest(`/api/threads/${encodeURIComponent(threadId)}`);

export const getThread = (threadId: string): Promise<Uint8Array> =>
  getRequest(`/api/threads/${encodeURIComponent(threadId)}`, binaryResponse);

export const getThreads = (notebookId: string): Promise<Uint8Array> =>
  getRequest(
    `/api/notebooks/${encodeURIComponent(notebookId)}/threads`,
    binaryResponse,
  );

export const resolveThread = (threadId: string) =>
  postRawRequest({
    url: `/api/threads/${encodeURIComponent(threadId)}/resolve`,
    responseHandler: binaryResponse,
  });

export const reopenThread = (threadId: string) =>
  postRawRequest({
    url: `/api/threads/${encodeURIComponent(threadId)}/reopen`,
    responseHandler: binaryResponse,
  });
