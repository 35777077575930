import FocusTrap from "focus-trap-react";
import { AnimatePresence } from "framer-motion";
import { createPortal } from "react-dom";
import useLockBodyScroll from "react-use/lib/useLockBodyScroll";

import { ModalContext, useModals } from "../UI";
import { ModalContent } from "./ModalContent";
import { Overlay } from "./Overlay";

export function Modals() {
  const modals = useModals();
  const components = modals?.map(
    ({ key, modalState: { onRequestClose, onAfterClose, modal } }) => (
      <ModalContext.Provider
        key={key}
        value={{
          modalType: modal.type,
          requestClose: onRequestClose,
          afterClose: onAfterClose,
        }}
      >
        <FocusTrap>
          <Overlay data-testid="modal-overlay">
            <ModalContent modal={modal} />
          </Overlay>
        </FocusTrap>
      </ModalContext.Provider>
    ),
  );

  useLockBodyScroll(!!components);

  if (!components) {
    return null;
  }

  return createPortal(
    <AnimatePresence>{components}</AnimatePresence>,
    document.body,
  );
}
