import { Icon } from "@fiberplane/ui";
import { forwardRef, useEffect } from "react";
import { css, styled } from "styled-components";

import type { Workspace } from "../../../../types";
import { track } from "../../../../utils";
import { WorkspaceAvatar } from "../../../Avatar";

export const SwitchContainer = forwardRef(function SwitchContainer(
  {
    opened,
    workspace,
  }: {
    opened: boolean;
    workspace: Workspace;
  },
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  useEffect(() => {
    if (!opened) {
      return;
    }

    track("Graph to notebook | Workspaces menu | opened");
    return () => {
      track("Graph to notebook | Workspaces menu | closed");
    };
  }, [opened]);

  return (
    <Container
      ref={ref}
      tabIndex={0}
      role="button"
      aria-pressed={opened ? "true" : "false"}
      data-testid="workspace-toggle"
    >
      <StyledWorkspaceAvatar
        workspaceId={workspace.id}
        name={workspace.displayName}
      />
      <WorkspaceName data-testid="active-workspace-display-name">
        {workspace.displayName}
      </WorkspaceName>
      <DoubleArrowIcon iconType="double_arrow" />
    </Container>
  );
});

SwitchContainer.displayName = "SwitchContainer";

const Container = styled.div`
  display: grid;
  grid: "avatar name switch" 1fr / auto 1fr auto;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
`;

const StyledWorkspaceAvatar = styled(WorkspaceAvatar)`
  grid-area: avatar;
`;

const WorkspaceName = styled.h1(
  ({ theme }) => css`
    margin: 0;
    grid-area: name;
    font: ${theme.fontStudioStrongRegularShortHand};
    letter-spacing: ${theme.fontStudioStrongRegularLetterSpacing};
    color: ${theme.colorBase600};
    line-height: 32px;
    user-select: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
  `,
);

const DoubleArrowIcon = styled(Icon)`
  grid-area: switch;
  flex: 0 0 64px;
`;
