import { IconButton } from "@fiberplane/ui";
import { type ComponentProps, useEffect } from "react";

import type { CellFocus } from "../../types";
import { noCellFocus } from "../../utils";

type Props = {
  active: boolean;
  onFocusChange: (focus: CellFocus) => void;
} & Omit<ComponentProps<typeof IconButton>, "iconType">;

export function TimeRangeMenuButton({
  active,
  onFocusChange,
  className,
  "aria-label": ariaLabel,
}: Props): JSX.Element {
  useEffect(() => {
    // Temporarily remove focus from the cell while the dropdown is open.
    if (active) {
      onFocusChange(noCellFocus);
    }
  }, [active, onFocusChange]);

  return (
    <IconButton
      tabIndex={0}
      className={className}
      aria-label={ariaLabel}
      iconType="triangle_down"
      buttonStyle="secondary"
    />
  );
}
