import type { IconType } from "@fiberplane/ui";

import github from "url:./images/github.png";
import pagerdutywebhook from "url:./images/pagerduty.png";
import type { Integration } from "../../../../../slices";
import type {
  PersonalIntegrationSummary,
  WorkspaceIntegrationSummary,
} from "../../../../../types";

export type IntegrationProfile = (
  | PersonalIntegrationSummary
  | WorkspaceIntegrationSummary
) & {
  description?: string;
  gettingStarted?: string;
  iconType: IconType;
  name: string;
  coverImage: string;
};

export const getIntegrationProfile = (
  integration: PersonalIntegrationSummary | WorkspaceIntegrationSummary,
): IntegrationProfile => {
  const iconType = getIntegrationIconType(integration.id);

  switch (integration.id) {
    case "github":
    case "githubapp":
      return {
        ...integration,
        description: "Show your GitHub Pull Request status in the frontmatter",
        iconType,
        name: "Github",
        coverImage: github,
      };
    case "pagerdutywebhook":
      return {
        ...integration,
        description:
          "Create notebooks automatically any time a new PagerDuty incident is created",
        iconType,
        name: "PagerDuty Webhook",
        coverImage: pagerdutywebhook,
      };
  }
};

export const getIntegrationIconType = (
  integrationId: Integration["id"],
): IconType => {
  switch (integrationId) {
    case "github":
    case "githubapp":
      return "github_logo_alt";
    case "pagerdutywebhook":
      return "bug";
  }
};
