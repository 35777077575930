import { useHandler } from "@fiberplane/hooks";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";

import { useCreateViewMutation } from "../api";
import { normalizeException } from "../errors";
import {
  selectActiveWorkspaceIdOrThrow,
  selectCanCreateView,
} from "../selectors";
import { dispatch } from "../store";
import { addNotification } from "../thunks";
import type { NewView as NewViewModel, View as ViewModel } from "../types";
import { View } from "./View";

const NEW_VIEW: ViewModel = {
  id: "",
  name: "",
  displayName: "",
  description: "",
  color: 0,
  labels: [],
  createdAt: "",
  updatedAt: "",
};

export function NewView() {
  const { canCreateView, workspaceId } = useSelector(selectNewViewState);

  const [createView, { isLoading, isSuccess, reset }] = useCreateViewMutation();

  const onCreate = useHandler(async (newView: NewViewModel) => {
    if (!canCreateView) {
      return dispatch(
        addNotification({
          type: "danger",
          title:
            "You don't have permissions to create a new view in this workspace",
        }),
      );
    }

    const result = await createView({
      workspaceId,
      newView,
    });

    if ("error" in result) {
      return dispatch(
        addNotification({
          type: "danger",
          title: normalizeException(result.error).message,
        }),
      );
    }
  });

  return (
    <View
      isLoading={isLoading}
      isSuccess={isSuccess}
      onCreate={onCreate}
      resetMutation={reset}
      view={NEW_VIEW}
    />
  );
}

const selectNewViewState = createStructuredSelector({
  canCreateView: selectCanCreateView,
  workspaceId: selectActiveWorkspaceIdOrThrow,
});
