import type { ActiveLabelsEditorType } from "../types";
import type { LabelsEditorState } from "./labelsEditorState";

export type LabelsState = {
  /**
   * The type of labels editor that is currently active. Note this excludes
   * the command menu type, because the state for that editor is stored in the
   * state slice for the command menu, rather than here.
   */
  readonly activeEditorType: Exclude<ActiveLabelsEditorType, "command_menu">;

  /**
   * Labels editor state.
   *
   * `undefined` if no editor is active.
   */
  readonly editor?: LabelsEditorState;
};
