import { useHandler } from "@fiberplane/hooks";
import { Button, IconButton, Input, cancelEvent } from "@fiberplane/ui";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { css, styled } from "styled-components";

import { useCreateInviteMutation } from "../../api";
import { ROLES } from "../../constants";
import { normalizeException } from "../../errors";
import { selectActiveWorkspaceIdOrThrow } from "../../selectors";
import { dispatch } from "../../store";
import { addNotification } from "../../thunks";
import type { NewWorkspaceInvite } from "../../types";
import { formatRole, noop, validateRequired } from "../../utils";
import {
  ButtonBar,
  Label,
  Modal,
  ModalContext,
  ModalDescription,
  ModalForm,
  ModalLabel,
  ModalLabelText,
  ModalRadioGroup,
  Nudge,
} from "../UI";

export function InviteWorkspaceMember() {
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const { requestClose } = useContext(ModalContext);

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setError,
    trigger,
    watch,
  } = useForm<NewWorkspaceInvite>({
    defaultValues: {
      role: "read",
    },
    mode: "onChange",
  });

  const [invite, { data: invitation, error, isLoading }] =
    useCreateInviteMutation();

  useEffect(() => {
    if (error) {
      setError("email", {
        type: "string",
        message: normalizeException(error).message,
      });
    }
  }, [error, setError]);

  const onSubmit = useHandler(
    async (newWorkspaceInvite: NewWorkspaceInvite) => {
      const isValid = await trigger();
      if (isValid) {
        await invite({
          workspaceId,
          newWorkspaceInvite,
        });
      }
    },
  );

  const handleClickCopy = useHandler(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      cancelEvent(event);

      if (invitation) {
        navigator.clipboard.writeText(invitation.url);
        dispatch(
          addNotification({ title: "Invitation url copied to your clipboard" }),
        );
      }
    },
  );

  if (invitation) {
    return (
      <Modal title="Workspace invitation sent">
        <ModalDescription>
          An email with the following invitation url has been sent to{" "}
          <Email>{getValues("email")}</Email>
        </ModalDescription>

        <ModalForm onSubmit={noop}>
          <ModalLabel>
            <ModalLabelText>Invitation url:</ModalLabelText>
            <InputContainer>
              <Input type="text" value={invitation.url} readOnly />
              <CopyIconButton
                iconType="copy"
                onClick={handleClickCopy}
                buttonStyle="tertiary-grey"
              />
            </InputContainer>
          </ModalLabel>
        </ModalForm>

        <ButtonBar>
          <Button onClick={requestClose}>Close</Button>
        </ButtonBar>
      </Modal>
    );
  }

  return (
    <Modal title="Invite member to workspace">
      <ModalForm onSubmit={handleSubmit(onSubmit)}>
        <ModalLabel>
          <ModalLabelText>Email</ModalLabelText>
          <Input
            type="text"
            autoFocus
            placeholder="email@example.com"
            readOnly={isLoading}
            aria-invalid={errors.email !== undefined}
            {...register("email", {
              validate: (value) => validateRequired(value, "email"),
            })}
          />
          {errors.email && (
            <Nudge element={errors.email.ref as HTMLElement} placement="bottom">
              {errors.email.message}
            </Nudge>
          )}
        </ModalLabel>

        <ModalLabel>
          <ModalLabelText>Role</ModalLabelText>
          <ModalRadioGroup>
            {ROLES.map((role) => (
              <Label key={role}>
                <Input
                  type="radio"
                  key={role}
                  disabled={isLoading}
                  value={role}
                  checked={watch("role") === role}
                  {...register("role")}
                />
                {formatRole(role)}
              </Label>
            ))}
          </ModalRadioGroup>
        </ModalLabel>
      </ModalForm>

      <ButtonBar>
        <Button type="button" buttonStyle="secondary" onClick={requestClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          // Outside of the form so we have to submit values manually
          onClick={() => onSubmit(getValues())}
          disabled={isLoading}
        >
          Send invite
        </Button>
      </ButtonBar>
    </Modal>
  );
}

const InputContainer = styled.div`
  display: grid;
  position: relative;
`;

const CopyIconButton = styled(IconButton)`
  position: absolute;
  /* Offset the input's border */
  right: 1px;
  top: 1px;
`;

const Email = styled.span(
  ({ theme }) => css`
    font: ${theme.font.body.md.medium};
  `,
);
