// Import React explicitly for Jest
import { memo } from "react";
import { styled } from "styled-components";

import type { EditorNode } from "./EditorNode";

type Props = Omit<EditorNode, "activeFormatting"> & {
  text: string;
};

export const PlaceholderEditorNode = memo(function PlaceholderEditorNode({
  text,
  startOffset,
  endOffset,
}: Props) {
  const props = {
    "data-start-offset": startOffset,
    "data-end-offset": endOffset,
  };

  return <Container {...props}>{text}</Container>;
});

const Container = styled.span`
  color: ${({ theme }) => theme.colorBase600};
  /* Make sure the placeholder is the same height as the
   * content span to prevent resizing
   */
  line-height: inherit;
`;
