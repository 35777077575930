/* stylelint-disable scale-unlimited/declaration-strict-value */
.initial [data-function="cell-content"]::before {
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  pointer-events: none;
  content: "";
  position: absolute;
  top: -10px;
  height: 4px;
  width: 100%;
  border-radius: 2px;
  background-color: #e3efff;
}

.hoverTop {
  position: relative;
}

.hoverTop::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.hoverTop [data-function="cell-content"]::before {
  opacity: 1;
}

.hoverBottom {
  position: relative;
}

.hoverBottom::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}
