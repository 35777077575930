import { getRequest, parseJsonResponse } from "./utils";

export const listLabelKeys = (
  signal: AbortSignal,
  workspaceId: string,
  prefix: string | undefined,
) =>
  getRequest<Array<string>>(
    `/api/workspaces/${workspaceId}/labels/keys${
      prefix ? `?prefix=${encodeURIComponent(prefix)}` : ""
    }`,
    parseJsonResponse,
    { signal },
  );

export const listLabelValues = (
  signal: AbortSignal,
  workspaceId: string,
  key: string | undefined,
) =>
  getRequest<Array<string>>(
    `/api/workspaces/${workspaceId}/labels/values/${key ?? ""}`,
    parseJsonResponse,
    {
      signal,
    },
  );
