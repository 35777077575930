/**
 * Creates an object from all the input object's properties the given predicate
 * returns `true` for.
 */
export function pickBy<T extends Record<string, unknown>>(
  object: T,
  predicate: (value: T[keyof T], key: keyof T) => boolean,
): Partial<T> {
  return Object.fromEntries(
    Object.entries(object).filter(([key, value]) =>
      predicate(value as T[keyof T], key as keyof T),
    ),
  ) as T;
}
