import {
  AttachedPopup,
  Button,
  Icon,
  IconButton,
  cancelEvent,
} from "@fiberplane/ui";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { css, styled } from "styled-components";

import {
  createLabelDraft,
  editLabel,
  resetLabelsEditor,
  setActiveEvent,
  setActiveEventValue,
} from "../../../actions";
import { selectLabelsEditor } from "../../../selectors";
import type { RootState } from "../../../state";
import { useAppDispatch } from "../../../store";
import { saveLabelsEditorDraft, upsertEvent } from "../../../thunks";
import type { Timestamp } from "../../../types";
import {
  convertApiLabelToClientLabel,
  formatLongDate,
  formatTime,
  isPartialEvent,
} from "../../../utils";
import { LabelsEditor } from "../../LabelsEditor";
import {
  BaseModal,
  MenuContainer,
  ModalContext,
  TimestampPicker,
} from "../../UI";

export const TimelineEdit = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const event = useSelector((state: RootState) => state.events.activeEvent);
  const editor = useSelector(selectLabelsEditor);
  const { requestClose: closeModal } = useContext(ModalContext);

  const onSubmit = () => {
    if (isPartialEvent(event)) {
      return;
    }

    dispatch(upsertEvent(event));
    closeModal();
    dispatch(setActiveEvent(null));
  };

  useEffect(() => {
    inputRef.current?.focus();
    return () => {
      dispatch(resetLabelsEditor("event_modal"));
    };
  }, [dispatch]);

  return (
    <BaseModal>
      <Contents>
        <TextInputContainer>
          <TextInput
            type="text"
            placeholder="Untitled event"
            value={event.title ?? ""}
            onChange={(event) =>
              dispatch(setActiveEventValue("title", event.currentTarget.value))
            }
            ref={inputRef}
          />
          <IconButton iconType="close" onClick={() => closeModal()} />
        </TextInputContainer>
        <DateButton
          value={event.occurrenceTime}
          onChange={(range) =>
            dispatch(setActiveEventValue("occurrenceTime", range))
          }
        />
        <StyledLabelsEditor
          type="event_modal"
          editor={editor}
          labels={convertApiLabelToClientLabel(event?.labels || {})}
          onCreate={(event) => {
            cancelEvent(event);
            dispatch(createLabelDraft("event_modal"));
          }}
          onBlur={(event) => {
            // switching to input means we are still editing
            if (event.target.tagName === "INPUT") {
              return;
            }

            dispatch(resetLabelsEditor("event_modal"));
          }}
          onEdit={(_, label) => {
            dispatch(editLabel("event_modal", label));
          }}
          onKeyDown={(event) => {
            if (event.key === "Escape") {
              dispatch(resetLabelsEditor("event_modal"));
            }

            if (event.key === "Enter") {
              dispatch(saveLabelsEditorDraft());
            }
          }}
          newLabelButtonLabel="Add label"
          withDeleteIcon
          withContextMenu
        />
      </Contents>

      <ButtonContainer>
        <Button disabled={isPartialEvent(event)} onClick={onSubmit}>
          Save event
        </Button>
      </ButtonContainer>
    </BaseModal>
  );
};

const DateButton = ({
  value,
  onChange,
}: {
  value?: Timestamp;
  onChange: (value: Timestamp) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const formattedValue = useMemo(() => {
    if (!value) {
      return "Add date and time";
    }

    const date = new Date(value);
    return `${formatLongDate(date)} at ${formatTime(date)}`;
  }, [value]);
  const ref = useRef<HTMLButtonElement>(null);
  return (
    <>
      <PillButton ref={ref} onClick={() => setIsOpen(true)} isActive={isOpen}>
        <StyledIcon width={12} height={12} iconType="clock" />
        {formattedValue}
      </PillButton>
      {isOpen && (
        <AttachedPopup element={ref.current} offset={[0, 4]} placement="right">
          <MenuContainer>
            <TimestampPicker
              value={value}
              onChange={(newValue: Timestamp) => {
                onChange(newValue);
                setIsOpen(false);
              }}
            />
          </MenuContainer>
        </AttachedPopup>
      )}
    </>
  );
};

const StyledLabelsEditor = styled(LabelsEditor)``;

const TextInputContainer = styled.div`
  display: flex;
  width: 100%;
`;

const TextInput = styled.input`
  font: ${(p) => p.theme.fontStudioHeadingsH5ShortHand};
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
`;

const PillButton = styled.button<{ isActive: boolean }>`
  background: transparent;
  border: 0;
  font: ${(p) => p.theme.fontStudioStrongSmallShortHand};
  letter-spacing: ${(p) => p.theme.fontStudioStrongRegularLetterSpacing};
  color: ${(p) => p.theme.colorBase500};
  border-radius: 32px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  transition: color 0.1s ease-out;
  transition-property: color, background;
  cursor: pointer;
  ${(p) =>
    p.isActive
      ? css`
          background: ${p.theme.colorBase600};
          color: ${p.theme.colorBackground};
        `
      : css`
          :hover {
            color: ${p.theme.colorBase500};
            background: ${p.theme.colorBase300};
          }
        `}
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 16px;
  padding-left: 8px;

  & > {
    ${TextInputContainer} {
      margin-left: 8px;
    }

    ${PillButton} {
      margin-top: 5px;
    }

    ${StyledLabelsEditor} {
      margin-top: 12px;
      margin-left: 8px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px 16px;
  border-top: 1px solid ${(p) => p.theme.colorBase200};
`;

const StyledIcon = styled(Icon)`
  margin-right: 4px;
`;
