import { useMemo } from "react";
import { useSelector } from "react-redux";

import { makeCellFocusSelector } from "../selectors";
import type { CellFocus } from "../types";

/**
 * Returns the cell focus for a given cell, with an optional field.
 *
 * If a field is provided and the cell focus isn't on the given field, a focus
 * of type `"none"` is returned.
 */
export function useCellFocus(cellId: string, field?: string): CellFocus {
  const selectCellFocus = useMemo(
    () => makeCellFocusSelector(cellId, field),
    [cellId, field],
  );
  return useSelector(selectCellFocus);
}
