import { Icon } from "@fiberplane/ui";
import { useSelector } from "react-redux";

import { selectActiveViewDisplayName } from "../../../../selectors";
import { BreadcrumbLabel } from "./BreadcrumbLabel";

export function ActiveViewBreadcrumb() {
  const viewDisplayName = useSelector(selectActiveViewDisplayName);

  return (
    <BreadcrumbLabel>
      <Icon iconType="views" />
      View - {viewDisplayName}
    </BreadcrumbLabel>
  );
}
