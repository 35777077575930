import {
  ADD_VIEW_EDITOR_LABEL,
  UPDATE_VIEW_EDITOR_LABEL,
  addEventEditorLabel,
  addNotebookLabel,
  hideContextMenu,
  replaceNotebookLabel,
  resetLabelsEditor,
  setCommandMenuVariant,
  updateLabelDraft,
  withActiveNotebook,
} from "../actions";
import { LABEL_SPLIT_CHARACTER } from "../constants";
import {
  selectActiveLabelsEditorType,
  selectCommandMenuLabels,
  selectFocusedProviderCell,
  selectLabelsEditorExistingLabelKeys,
  selectLabelsEditorState,
  selectNotebookFocus,
} from "../selectors";
import type { Thunk } from "../store";
import type { Label } from "../types";
import {
  formatLabel,
  getFocusPosition,
  getQueryField,
  parseLabel,
  setQueryField,
  validateLabel,
} from "../utils";
import { replaceCells } from "./notebookCellThunks";

export const addLabelKeySuggestion =
  (key: string): Thunk =>
  (dispatch, getState) => {
    const target = selectActiveLabelsEditorType(getState());
    dispatch(updateLabelDraft(target, `${key}${LABEL_SPLIT_CHARACTER}`));
  };

export const addLabelValueSuggestion =
  (value: string): Thunk =>
  (dispatch, getState) => {
    const editorState = selectLabelsEditorState(getState());
    if (editorState) {
      const parsedLabel = parseLabel(editorState.draft);
      const label = { ...parsedLabel, value };
      const target = selectActiveLabelsEditorType(getState());
      dispatch(updateLabelDraft(target, formatLabel(label)));

      dispatch(saveLabelsEditorDraft());
    }
  };

export const resetActiveLabelsEditor = (): Thunk => (dispatch, getState) => {
  const target = selectActiveLabelsEditorType(getState());
  dispatch(resetLabelsEditor(target));
};

export const saveLabelsEditorDraft = (): Thunk => (dispatch, getState) => {
  const state = getState();
  const editorState = selectLabelsEditorState(state);
  if (!editorState) {
    return;
  }

  const { original, draft } = editorState;
  const existingKeys = selectLabelsEditorExistingLabelKeys(state).filter(
    (key) => (original ? key !== original.key : true),
  );

  const label = parseLabel(draft);
  const errors = validateLabel(label, existingKeys);
  if (errors.length > 0) {
    return;
  }

  const target = selectActiveLabelsEditorType(getState());
  switch (target) {
    case "command_menu": {
      dispatch(saveLabelInCommandMenu(label));
      return;
    }

    case "notebook": {
      dispatch(saveLabelInLabelsCell(label, original));
      return;
    }

    case "provider_field": {
      dispatch(saveLabelInProviderField(label, original));
      return;
    }

    case "view": {
      dispatch(saveLabelInView(label, original));
      return;
    }

    case "event_modal": {
      dispatch(saveLabelInEvent(label));
      return;
    }
  }
};

export const saveLabelInLabelsCell =
  (label: Label, original: Label | undefined): Thunk =>
  (dispatch) => {
    if (original) {
      dispatch(withActiveNotebook(replaceNotebookLabel(original, label)));
    } else {
      dispatch(withActiveNotebook(addNotebookLabel(label)));
    }

    dispatch(resetLabelsEditor("notebook"));
    dispatch(hideContextMenu());
  };

export const saveLabelInCommandMenu =
  (label: Label): Thunk =>
  (dispatch, getState) => {
    const currentLabels = selectCommandMenuLabels(getState());
    const labels = currentLabels.some(({ key }) => key === label.key)
      ? currentLabels.map(({ key, value }) => ({
          key,
          value: key === label.key ? label.value : value,
        }))
      : [...currentLabels, label];

    dispatch(
      setCommandMenuVariant({
        type: "search",
        labels,
        labelsEditor: undefined,
      }),
    );
  };

export const saveLabelInProviderField =
  (label: Label, original: Label | undefined): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const focus = selectNotebookFocus(state);
    const fieldName = getFocusPosition(focus)?.field;
    const providerCell = selectFocusedProviderCell(state);
    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version (which is less readable)
    if (!providerCell || !fieldName) {
      return;
    }

    const { id, queryData } = providerCell;

    const labels = getQueryField(queryData, fieldName)
      .split(" ")
      .filter((label) => label.length > 0)
      .map(parseLabel);
    const newLabels = original
      ? labels.map((existing) =>
          existing.key === original.key ? label : existing,
        )
      : [...labels, label];
    const newQueryData = setQueryField(
      queryData,
      fieldName,
      newLabels.map(formatLabel).join(" "),
    );

    dispatch(
      replaceCells({
        oldCellIds: [id],
        newCells: [{ ...providerCell, queryData: newQueryData }],
      }),
    );

    dispatch(resetLabelsEditor("provider_field"));
    dispatch(hideContextMenu());
  };

export const saveLabelInView =
  (label: Label, original: Label | undefined): Thunk =>
  (dispatch) => {
    if (original) {
      dispatch({
        type: UPDATE_VIEW_EDITOR_LABEL,
        payload: {
          original,
          updated: label,
        },
      });
    } else {
      dispatch({ type: ADD_VIEW_EDITOR_LABEL, payload: label });
    }

    dispatch(resetLabelsEditor("view"));
    dispatch(hideContextMenu());
  };

export const saveLabelInEvent =
  (label: Label): Thunk =>
  (dispatch) => {
    dispatch(addEventEditorLabel(label));
    dispatch(resetLabelsEditor("event_modal"));
  };

export const updateLabelsEditorDraft =
  (draft: string): Thunk =>
  (dispatch, getState) => {
    const target = selectActiveLabelsEditorType(getState());
    dispatch(updateLabelDraft(target, draft));
  };
