import { formatSize } from ".";
import { IMAGE_MIME_TYPES, MAX_FILE_UPLOAD_SIZE } from "../constants";

/**
 * Validates the given `file` and returns the optional error message.
 *
 * Returns `undefined` when everything checks out fine.
 */
export function validateImageFileSize(file: File): string | undefined {
  if (file.size > MAX_FILE_UPLOAD_SIZE) {
    return `File is too big (max ${formatSize(MAX_FILE_UPLOAD_SIZE)})`;
  }
}

export function validateImageFileType(
  items:
    | void
    | Array<Pick<DataTransferItem, "type" | "kind">>
    | DataTransferItemList,
): boolean {
  if (!items || items.length === 0 || items.length > 1) {
    return false;
  }

  const item = items[0];
  return item?.kind === "file" && IMAGE_MIME_TYPES.includes(item.type);
}
