import { Button } from "@fiberplane/ui";
import { css, styled } from "styled-components";

import {
  codePreStyling as uiCodePreStyling,
  preStyling as uiPreStyling,
} from "../UI";
import { RichTextInput } from "./RTE";

/**
 * Styling to use with `<RichTextInput tagName="pre" />`.
 */
export const preStyling = uiPreStyling;

/**
 * Additional styling for use with Code cells.
 */
export const codePreStyling = uiCodePreStyling;

/*
  Below is the styling for code/query field as used by
  provider 2.0 query cells
*/

// Generic container for "fields" like query cell
export const FieldContainer = styled.div`
  align-items: stretch;
  border-radius: ${({ theme }) => theme.radius.default};
  display: flex;
  max-width: 100%;
`;

// Make styling more specific
export const QueryFieldContainer = styled(FieldContainer)<{ $error?: boolean }>(
  ({ $error, theme }) => css`
    background: ${theme.color.notebook.cell.bg.query};
    border: 1px solid ${theme.color.border.default};
    padding: 8px;
    /* Min-width is needed so the container won't grow beyond the parent's size */
    min-width: 0;

    ${
      $error &&
      css`
        background-color: ${theme.color.bg.danger};
        border-color: ${theme.color.border.danger};
      `
    }
  `,
);

// Container for the icon. It also adds a line if cell is very tall/contains a
// multi line query
export const QueryIconContainer = styled.div(
  ({ theme }) => css`
    display: grid;
    place-items: center;
    height: ${theme.height.small};
    aspect-ratio: 1;
    border-radius: ${theme.radius.minimal};
    background-color: ${theme.color.bg.default};
    border: 1px solid ${theme.color.border.muted};
  `,
);

export const QueryInput = styled(RichTextInput).attrs({
  disableFormatting: true,
  tagName: "pre",
})`
  ${preStyling}
  /* set min-width so the element won't overflow thw parent */
  min-width:0;
`;

export const QueryButton = styled(Button)(
  ({ theme }) => css`
    min-height: unset;
    height: ${theme.height.small};
    font: ${theme.font.buttons.sm};
    border-radius: ${theme.radius.minimal};
    top: 8px;
    right: 8px;
  `,
);
