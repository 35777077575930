import { selectCellOrSurrogate, selectCellText } from "../../../../selectors";
import { getState } from "../../../../store";
import type { Rect } from "../../../../types";
import { CURSOR_WIDTH } from "../constants";
import { getCoordinatesForOffset } from "./coordinates";
import { getContainerElForCellField, getNodeAndIndexForOffset } from "./nodes";

export function getCursorHeight(
  containerEl: HTMLElement,
  text: string,
  offset: number,
): number {
  const nodeAndIndex = getNodeAndIndexForOffset(containerEl, text, offset);
  if (!nodeAndIndex) {
    return 0;
  }

  const range = document.createRange();
  range.setStart(...nodeAndIndex);
  range.setEnd(...nodeAndIndex);

  const { height } = range.getBoundingClientRect();
  return height;
}

export function getCursorRectForCellFieldAtOffset(
  cellId: string,
  field: string | undefined,
  offset: number,
): Rect | null {
  const state = getState();
  const cell = selectCellOrSurrogate(state, cellId);
  if (!cell) {
    return null;
  }

  const text = selectCellText(state, cell, field);
  const containerEl = getContainerElForCellField(cellId, field);
  const coordinates =
    containerEl && getCoordinatesForOffset(containerEl, text, offset);
  const cursorHeight =
    containerEl && getCursorHeight(containerEl, text, offset);

  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
  if (!coordinates || !cursorHeight) {
    return null;
  }

  const rect = containerEl.getBoundingClientRect();
  return {
    x: coordinates.x - CURSOR_WIDTH / 2 + rect.left,
    y: coordinates.y - cursorHeight / 2 + rect.top,
    width: CURSOR_WIDTH,
    height: cursorHeight,
  };
}
