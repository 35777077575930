import { css } from "styled-components";

export const preStyling = css(
  ({ theme }) => css`
    background: unset;
    color: ${theme.color.fg.default};
    cursor: text;
    padding: 0;
    margin: 4px 12px;
    position: relative;
    font: ${theme.font.code.regular};
  `,
);

export const codePreStyling = css`
  background-color: unset;
  padding: 0;
  margin: 0;
`;
