import { shallowEqual } from "react-redux";
import { createSelector } from "reselect";

import type { RootState } from "../state";
import type {
  ActiveFormatting,
  CellFocus,
  EditorState,
  NotebookContextMenuInfo,
  NotebookContextMenuType,
  NotebookFocus,
} from "../types";
import {
  getCellFocus,
  getField,
  getFocusCellId,
  getFocusField,
  noCellFocus,
} from "../utils";
import { makeThreadItemsSelector } from "./discussionsSelectors";
import {
  makeCellDraftsSelector,
  makeCellOrSurrogateSelector,
} from "./notebookSelectors";
import { selectActiveNotebook, selectNotebooks } from "./notebooksSelectors";

export const selectActiveEditor = (state: RootState): EditorState =>
  selectNotebooks(state).activeNotebook.editor;

export const selectActiveFormatting = (state: RootState): ActiveFormatting =>
  selectActiveEditor(state).activeFormatting;

export const selectContextMenu = (state: RootState) =>
  selectActiveEditor(state).contextMenu;

export const selectNotebookCellIds = (
  state: RootState,
): ReadonlyArray<string> => selectActiveNotebook(state).cellIds;

export const selectNotebookFocus = (state: RootState): NotebookFocus =>
  selectActiveEditor(state).focus;

export const makeCellFocusSelector = (cellId: string, field?: string) =>
  createSelector(
    [
      makeCellOrSurrogateSelector(cellId),
      makeCellDraftsSelector(cellId),
      makeThreadItemsSelector(cellId),
      selectNotebookCellIds,
      selectNotebookFocus,
    ],
    (cell, drafts, threadItems, cellIds, focus): CellFocus => {
      const cellFocus = cell
        ? getCellFocus(cellIds, cell, drafts, threadItems, focus)
        : noCellFocus;

      if (!field) {
        return cellFocus;
      }

      const fieldMatches = field === getField(cellFocus);
      return fieldMatches ? cellFocus : noCellFocus;
    },
    { memoizeOptions: { resultEqualityCheck: shallowEqual } },
  );

export const makeNotebookContextMenuSelector = (cellId: string) =>
  createSelector(
    [selectActiveEditor],
    (editor: EditorState): NotebookContextMenuInfo | null =>
      cellId === editor.contextMenu?.cellId ? editor.contextMenu : null,
  );

export const makeNotebookContextMenuOfTypeIsOpenSelector = (
  cellId: string,
  menuType: NotebookContextMenuType,
) =>
  createSelector(
    [selectActiveEditor],
    (editor: EditorState): boolean =>
      cellId === editor.contextMenu?.cellId &&
      editor.contextMenu.menuType === menuType,
  );

export const makeIsFocusedSelector = (cellId: string) =>
  createSelector(
    [selectNotebookFocus],
    (focus): boolean => getFocusCellId(focus) === cellId,
  );

export const makeIsFocusedFieldSelector = (cellId: string, field?: string) =>
  createSelector(
    [selectNotebookFocus],
    (focus): boolean =>
      getFocusCellId(focus) === cellId && getFocusField(focus) === field,
  );

export const makeHasFocusedFieldSelector = (cellId: string) =>
  createSelector(
    [selectNotebookFocus],
    (focus): boolean =>
      getFocusCellId(focus) === cellId && getFocusField(focus) === undefined,
  );

export const makeIsHighlightedSelector = (cellId: string) => {
  const selectIsFocused = makeIsFocusedSelector(cellId);
  return createSelector(
    [selectActiveEditor, selectIsFocused],
    (editor, isFocused): boolean => editor.highlightFocus && isFocused,
  );
};
