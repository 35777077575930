import useMedia from "react-use/lib/useMedia";
import { styled } from "styled-components";

import type { SubscriberSession } from "../../../types";
import { Overflow } from "./Overflow";
import { PresenceIcon } from "./PresenceIcon";
import { PresenceProfileAvatar } from "./PresenceProfileAvatar";
import { useNewlySortedFirst } from "./useNewlySortedFirst";

export type PresenceContainerProps = {
  sessions: Array<SubscriberSession>;
  maxDisplayed?: number;
  className?: string;
};

export function PresenceContainer({
  sessions,
  maxDisplayed = 6,
  className,
}: PresenceContainerProps) {
  const mediumSizeAndUp = useMedia("(min-width: 768px)");
  const largeSizeAndUp = useMedia("(min-width: 1024px)");

  const sortedSessions = useNewlySortedFirst(sessions);

  const maxRendered = largeSizeAndUp ? maxDisplayed : mediumSizeAndUp ? 4 : 1;
  const totalItems =
    maxRendered >= sortedSessions.length ? sortedSessions.length : maxRendered;

  const children =
    totalItems === sortedSessions.length
      ? sortedSessions.map((session) => (
          <PresenceIcon key={session.sessionId} session={session} />
        ))
      : [
          ...sortedSessions
            .slice(0, maxRendered - 1)
            .map((session) => (
              <PresenceIcon key={session.sessionId} session={session} />
            )),
          <Overflow
            key="overflow"
            count={sortedSessions.length}
            popupChildren={sortedSessions.map((session) => (
              <PresenceIcon key={session.sessionId} session={session} />
            ))}
          />,
        ];

  return (
    <Container data-testid="presence-container" className={className}>
      {children}
    </Container>
  );
}

const Container = styled.div`
  display: flex;

  > ${PresenceProfileAvatar}:not(:first-child) {
    margin-left: -2px;
  }
`;
