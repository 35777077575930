import { useEffect } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import scrollIntoView from "scroll-into-view-if-needed";

import {
  selectLastUserAction,
  selectNotebookFocused,
} from "../../../../selectors";
import type { CellFocus } from "../../../../types";
import { hasFocusPosition } from "../../../../utils";
import type { CursorCoordinates } from "../utils";

/**
 * Used to focus the viewport on the cursor position.
 *
 * See `useFocus()` for a similar hook that is used for cells that do not have
 * input fields.
 */
export function useFocusPosition(
  cellId: string,
  contentRef: React.RefObject<HTMLElement>,
  cursorCoordinates: CursorCoordinates | null,
  cursorRef: React.RefObject<HTMLElement>,
  focus: CellFocus,
  field: string | undefined,
) {
  const hasFocus = hasFocusPosition(focus, field);
  const shouldFocus = useSelector(selectShouldFocus) && hasFocus;

  // biome-ignore lint/correctness/useExhaustiveDependencies: intentional
  useEffect(() => {
    const contentEl = contentRef.current;
    const cursorEl = cursorRef.current;

    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
    if (!contentEl || !shouldFocus) {
      return;
    }

    const alreadyHasFocus = document.activeElement === contentEl;
    if (!alreadyHasFocus) {
      // Prevent the element from scrolling into view when we focus it, since
      // we'll handle scrolling separately (with scrollIntoView).
      contentEl.focus({ preventScroll: true });

      // NOTE - If the cursor element is defined, we'll be scrolling it into
      //        view below, which is why we don't want to scroll the content
      //        element into view here.
      //
      //        In practice, the cursor element is not defined when someone is
      //        just clicking around into other text cells.
      if (!cursorEl) {
        scrollIntoView(contentEl, {
          scrollMode: "if-needed",
          block: "nearest",
        });
      }
    }

    if (cursorEl) {
      scrollIntoView(cursorEl, { scrollMode: "if-needed", block: "nearest" });
    }
  }, [cellId, contentRef, cursorCoordinates, cursorRef, shouldFocus]);
}

const selectShouldFocus = createSelector(
  [selectLastUserAction, selectNotebookFocused],
  (lastUserAction, isNotebookFocused) =>
    lastUserAction === "typing" && isNotebookFocused,
);
