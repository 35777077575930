import { memo, useEffect } from "react";
import { useSelector } from "react-redux";

import { selectCurrentUser } from "../../../../../selectors";
import { page } from "../../../../../utils";
import { ProfileInfo } from "./ProfileInfo";

export const Profile = memo(function Settings() {
  const user = useSelector(selectCurrentUser);

  useEffect(() => {
    page();
  }, []);

  if (!user) {
    return null;
  }

  return <ProfileInfo {...user} />;
});
