import {
  replaceText,
  showError,
  withActiveNotebook,
  withNotebook,
} from "../actions";
import { SUGGESTIONS_MIME_TYPE, SUGGESTIONS_QUERY_TYPE } from "../constants";
import { isFiberplaneError } from "../errors";
import {
  selectActiveNotebookId,
  selectCell,
  selectCellText,
} from "../selectors";
import { DataSources } from "../services";
import type { Thunk } from "../store";
import { getProviderForIntent } from "../thunks";
import type {
  AutoSuggestRequest,
  FocusPosition,
  NotebookContextMenuInfo,
  ProviderError,
  ProviderRequest,
  Suggestion,
} from "../types";
import {
  type Intent,
  charCount,
  charSlice,
  decodeQueryData,
  encodeQueryData,
  matchesMimeTypeWithEncoding,
  omit,
  parseBlob,
  parseIntent,
  toQueryDataBlob,
  unwrap,
} from "../utils";

type ApplySuggestionOptions = {
  contextMenu: NotebookContextMenuInfo;
  text: string;
  from?: number;
};

export const applySuggestion =
  ({ contextMenu, text, from }: ApplySuggestionOptions): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const cell = selectCell(state, contextMenu.cellId);
    if (cell?.type !== "provider") {
      throw new Error("Cell not found or has incorrect type");
    }

    const { field, typeAheadOffset, typeAheadText } = contextMenu;
    if (typeAheadOffset !== undefined) {
      dispatch(
        withActiveNotebook(
          replaceText({
            cellId: cell.id,
            closeMenu: contextMenu,
            field,
            offset: from ?? typeAheadOffset,
            newText: text,
            oldText: charSlice(
              selectCellText(state, cell, field),
              from ?? typeAheadOffset,
              typeAheadOffset + charCount(typeAheadText ?? ""),
            ),
          }),
        ),
      );
    }
  };

/**
 * Fetches auto-suggestions for a query at the given focus position.
 *
 * Returns an empty list of suggestions in case of errors. Errors are logged
 * to the feedback console.
 */
export const fetchSuggestionsForFocusPosition =
  (focus: FocusPosition): Thunk<Promise<Array<Suggestion>>> =>
  // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: FIXME
  async (dispatch, getState) => {
    const state = getState();
    const { cellId, field, offset } = focus;
    if (!field) {
      return [];
    }

    const notebookId = selectActiveNotebookId(state);

    try {
      const cell = selectCell(state, cellId);
      if (cell?.type !== "provider") {
        throw new Error("Not a provider cell");
      }

      const intent = parseIntent(cell.intent);
      const query = charSlice(selectCellText(state, cell, field), 0, offset);
      if (!query) {
        return [];
      }

      const otherFields = omit(decodeQueryData(cell.queryData ?? ""), field);

      return await dispatch(
        fetchSuggestions(intent, query, field, encodeQueryData(otherFields)),
      );
    } catch (error) {
      if (error instanceof DataSources.UnsupportedQueryTypeError) {
        // That's fine. Implementing the "suggestions" query type is optional.
      } else {
        const fpError: ProviderError = isFiberplaneError(error)
          ? error
          : {
              type: "other",
              message: `Error fetching auto suggestions, ${JSON.stringify(
                error,
              )}`,
            };

        dispatch(withNotebook(notebookId, showError(fpError, { cellId })));
      }

      return [];
    }
  };

/**
 * Fetches auto-suggestions for the given intent, based on the given query.
 *
 * Throws in case of an error.
 */
export const fetchSuggestions =
  (
    intent: Intent,
    query: string,
    field: string,
    otherFieldData?: string,
  ): Thunk<Promise<Array<Suggestion>>> =>
  async (dispatch) => {
    const { provider, dataSource, mimeTypes } = await dispatch(
      getProviderForIntent({
        ...intent,
        queryType: SUGGESTIONS_QUERY_TYPE,
      }),
    );

    if (dataSource.status === "error") {
      throw dataSource.error ?? new Error("Data source has an error");
    }

    if (!supportsSuggestionsMimeType(mimeTypes)) {
      throw new Error(
        `No matching MIME type (provider supported: ${mimeTypes.join(", ")})`,
      );
    }

    const queryData: AutoSuggestRequest = {
      // biome-ignore lint/style/useNamingConvention: naming convention is correct
      query_type: intent.queryType,
      query,
      field,
      // biome-ignore lint/style/useNamingConvention: naming convention is correct
      other_field_data: otherFieldData,
    };

    const request: ProviderRequest = {
      config: dataSource.config,
      queryType: SUGGESTIONS_QUERY_TYPE,
      queryData: toQueryDataBlob(queryData),
    };

    const response = await provider.invoke(request, dataSource);

    return parseBlob(unwrap(response)) as Array<Suggestion>;
  };

function supportsSuggestionsMimeType(mimeTypes: Array<string>): boolean {
  return mimeTypes.some(
    (mimeType) =>
      mimeType === SUGGESTIONS_MIME_TYPE ||
      matchesMimeTypeWithEncoding(mimeType, SUGGESTIONS_MIME_TYPE),
  );
}
