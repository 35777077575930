import { type Variants, motion } from "framer-motion";
import { css, styled } from "styled-components";

import { type ClippedIconType, useClippedIcon } from "../ClippedIcon";
import {
  CONTENT_ITEM_BORDER_SIZE,
  CONTENT_PLACEHOLDER_GAP,
  CONTENT_PLACEHOLDER_MARGIN,
} from "./constants";

type StudioPageContentPlaceholderProps = {
  children: React.ReactNode;
  icon: ClippedIconType;
};

export function PageContentPlaceholder({
  children,
  icon,
}: StudioPageContentPlaceholderProps) {
  const { ClippedIcon, iconId } = useClippedIcon(icon);

  return (
    <PlaceholderContainer
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
      data-testid="page-placeholder"
    >
      <NoResultsIconWrapper>
        <ClippedIconContainer $iconId={iconId}>
          <ClippedIcon />
        </ClippedIconContainer>
      </NoResultsIconWrapper>
      {children}
    </PlaceholderContainer>
  );
}

const variants: Variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      delay: 0.3,
    },
  },
  exit: { opacity: 0 },
};

const NoResultsIconWrapper = styled.div`
  height: 96px;
  width: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlaceholderContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  margin-top: ${CONTENT_ITEM_BORDER_SIZE};
  gap: ${CONTENT_PLACEHOLDER_GAP};
`;

const ClippedIconContainer = styled.div<{ $iconId: string }>(
  ({ $iconId, theme }) => css`
    background: linear-gradient(
      180deg,
      ${theme.color.primary.brand[500]} 0%,
      ${theme.color.primary.brand[300]} 100%
    );
    clip-path: url("#${$iconId}");
    display: flex;
  `,
);

export const PageContentPlaceholderText = styled.h3``;

export const PageContentPlaceholderDescription = styled.p(
  ({ theme }) => css`
    font: ${theme.font.body.md.regular};
    color: ${theme.color.fg.muted};
    margin: 0;
  `,
);

// biome-ignore lint/style/useNamingConvention: CTA is an abbreviation for Call To Action
export const PageContentPlaceholderCTA = styled.nav`
  margin-top: calc(${CONTENT_PLACEHOLDER_MARGIN} - ${CONTENT_PLACEHOLDER_GAP});
`;
