import type { ThunkAction } from "@reduxjs/toolkit";

import type { AppAction, NotebookAction } from "../actions";
import type { RootState } from "../state";
import type { RTKAction } from "./rtkTypes";

export type AppActionOrThunk = AppAction | RTKAction | Thunk<unknown>;

export type AppDispatch = <T extends AppActionOrThunk>(
  actionOrThunk: T,
) => T extends Thunk<infer Returned> ? Returned : void;

export type NotebookActionOrThunk = NotebookAction | Thunk<unknown>;

export type NotebookDispatch = <T extends NotebookActionOrThunk>(
  actionOrThunk: T,
) => T extends Thunk<infer Returned> ? Returned : void;

export type Thunk<Returned = void> = ThunkAction<
  Returned,
  RootState,
  unknown,
  AppAction | RTKAction
>;
