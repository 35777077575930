import {
  ModalErrorHint,
  ModalLabel,
  ModalLabelText,
  ModalTextInput,
} from "../../../UI";
import {
  type ValidationStatus,
  ValidationStatusIcon,
} from "../ValidationStatusIcon";

type Props = {
  autoFocus?: boolean;
  label?: string;
  max?: number;
  min?: number;
  name: string;
  onChange: (value: number) => void;
  onKeyDown: (event: React.KeyboardEvent) => void;
  required: boolean;
  step?: number;
  validationStatus: ValidationStatus;
  value?: unknown;
};

export function IntegerField({
  autoFocus,
  label,
  max,
  min,
  name,
  onChange,
  onKeyDown,
  required,
  step,
  validationStatus,
  value,
}: Props): JSX.Element {
  const showValidationError =
    required && validationStatus === "invalid" && value == null;

  return (
    <ModalLabel>
      {label && <ModalLabelText>{label}</ModalLabelText>}
      <ModalTextInput
        autoFocus={autoFocus}
        data-config-field={name}
        max={max}
        min={min}
        onChange={(event) => onChange(Number.parseInt(event.target.value, 10))}
        aria-invalid={showValidationError}
        trailingIcon={
          showValidationError
            ? () => <ValidationStatusIcon status={validationStatus} />
            : undefined
        }
        type="number"
        onKeyDown={onKeyDown}
        step={step}
        value={typeof value === "number" ? value : ""}
      />
      {showValidationError && (
        <ModalErrorHint>This is a required field</ModalErrorHint>
      )}
    </ModalLabel>
  );
}
