import { Icon } from "@fiberplane/ui";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

import {
  selectEventLabels,
  selectLabelSuggestions,
  selectLabelsState,
  selectNotebookLabels,
} from "../../selectors";
import type { ContextMenuInfo } from "../../state";
import { dispatch } from "../../store";
import { addLabelKeySuggestion, addLabelValueSuggestion } from "../../thunks";
import type { NotebookContextMenuInfo } from "../../types";
import { sortBy } from "../../utils";
import {
  type FilterMenuItem,
  PositionedFilterMenu,
  type PositionedFilterMenuProps,
} from "../UI";
import { getPriority } from "./getPriority";

type Props = {
  contextMenu: ContextMenuInfo | NotebookContextMenuInfo;
} & Omit<PositionedFilterMenuProps, "items">;

export function LabelSuggestionsMenu({
  filterText = "",
  contextMenu: _contextMenu,
  ...menuProps
}: Props): JSX.Element | null {
  const notebookLabels = useSelector(selectNotebookLabels);
  const eventLabels = useSelector(selectEventLabels);
  const suggestionsLabels = useSelector(selectLabelSuggestions);
  const { activeEditorType } = useSelector(selectLabelsState);

  const labelKeysInUse = useMemo(() => {
    const labels =
      activeEditorType === "event_modal" ? eventLabels : notebookLabels;

    return labels.map((label) => label.key);
  }, [activeEditorType, eventLabels, notebookLabels]);

  const items = useMemo(() => {
    if (!suggestionsLabels?.data) {
      return [];
    }

    const filteredKeySuggestions = suggestionsLabels.data.keys.filter(
      (suggestion) => {
        return !labelKeysInUse.includes(suggestion);
      },
    );

    const fetchedLabelKeySuggestions = labelSuggestions(
      filteredKeySuggestions,
      false,
      filterText,
      (suggestion) => dispatch(addLabelKeySuggestion(suggestion)),
    );

    const fetchedLabelValueSuggestions = labelSuggestions(
      suggestionsLabels.data.values,
      true,
      filterText,
      (suggestion) => dispatch(addLabelValueSuggestion(suggestion)),
    );

    return [...fetchedLabelKeySuggestions, ...fetchedLabelValueSuggestions];
  }, [filterText, labelKeysInUse, suggestionsLabels]);

  return (
    <StyledPositionedMenu
      {...menuProps}
      filterText={filterText}
      items={items}
      noResultsText="No results"
      placement="bottom-start"
      preventDefaultSelection={true}
    />
  );
}

function labelSuggestions(
  labelSuggestions: ReadonlyArray<string>,
  isValueSuggestion: boolean,
  filterText: string,
  onActivate: (labelSuggestion: string) => void,
): Array<FilterMenuItem> {
  const items: Array<FilterMenuItem> = labelSuggestions.map((title) => ({
    type: "item_with_icons",
    id: title,
    title,
    iconRight: {
      icon: <Icon iconType="key_return" />,
      showOnlyOnSelection: true,
    },
    onActivate: () => onActivate(title),
  }));
  if (items.length === 0) {
    return [];
  }

  return [
    {
      type: "item_group",
      id: "label-suggestions",
      title: isValueSuggestion
        ? "Suggested label values"
        : "Suggested label keys",
      items: sortBy(items, getPriority(filterText)),
    },
  ];
}

const StyledPositionedMenu = styled(PositionedFilterMenu)`
  max-height: 300px;
  width: 192px;
`;
