import { styled } from "styled-components";

import { RequiredValidationError, ValidationError } from "../../../errors";

const ErrorHintEmphasis = styled.em`
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  font-weight: bold;
`;

const HTTPS_ONLY_MESSAGE = "Only HTTPS supported";

export function baseUrlValidate(url: string): Promise<string> {
  if (!url) {
    return Promise.reject(new RequiredValidationError("url"));
  }

  try {
    const parsedUrl = new URL(url);

    if (
      window.location.protocol === "https:" &&
      parsedUrl.protocol !== "https:" &&
      parsedUrl.hostname !== "localhost" &&
      parsedUrl.hostname !== "127.0.0.1"
    ) {
      throw new ValidationError(HTTPS_ONLY_MESSAGE, "url");
    }
  } catch (error) {
    return Promise.reject(error);
  }

  return Promise.resolve(url);
}

export function formatUrlError(error: undefined | Error): JSX.Element {
  if (error instanceof ValidationError && error.reason === HTTPS_ONLY_MESSAGE) {
    return (
      <span>Due to browser security restrictions only HTTPS is supported.</span>
    );
  }

  if (error instanceof RequiredValidationError && error.property === "url") {
    return (
      <>
        This is a required field.
        <br />
        Please enter the full URL.
      </>
    );
  }

  return (
    <>
      <ErrorHintEmphasis>Failed to connect.</ErrorHintEmphasis>
      <br /> Please make sure you enter the full URL without a trailing slash
    </>
  );
}
