import styled, { css } from "styled-components";

export const PillShape = styled.div(
  ({ theme }) => css`
  display: flex;
  align-items: center;
  gap: 6px;
  flex: fit-content;
  padding: 1px 7px;
  border: 1px solid ${theme.color.border.default};
  border-radius: ${theme.radius.full};
  font: ${theme.font.body.md.medium};
  line-height: 20px;
`,
);
