import { removeNotebookLabel, withActiveNotebook } from "../actions";
import { selectNotebookLabels } from "../selectors";
import type { Thunk } from "../store";

export const deleteNotebookLabel =
  (key: string): Thunk =>
  (dispatch, getState) => {
    const labels = selectNotebookLabels(getState());
    const label = labels.find((label) => label.key === key);
    if (label) {
      dispatch(withActiveNotebook(removeNotebookLabel(label)));
    }
  };
