import { useHandler } from "@fiberplane/hooks";
import { useRef } from "react";

import type { NotebookFocus } from "../../../../../../../types";
import { useCursorPosition } from "./useCursorPosition";
import { useEditorValue } from "./useEditorValue";
import { useErrorState } from "./useErrorState";
import { useKeyDownHandler } from "./useKeyDownHandler";
import { useKeyUpHandler } from "./useKeyUpHandler";
import { usePasteHandler } from "./usePasteHandler";
import { sanitizeText } from "./utils";

export function useFrontMatterEditor(options: {
  id: string;
  value: string;
  onChange: (value: string, focus: NotebookFocus) => void;
  validator?: (value: string) => boolean;
  singleLine: boolean;
}) {
  const { id, value, onChange, validator, singleLine } = options;
  const editorRef = useEditorValue(value);
  const [errorState, setErrorState] = useErrorState(value, editorRef);

  // A ref to the content-editable element. Used to get information like the cursor position
  const ref = useRef<HTMLDivElement>(null);

  const { getCellFocus, sendCurrentCellFocus, clearLocalCursorPosition } =
    useCursorPosition(id, ref, errorState);

  const setValue = useHandler((text: string, focus: NotebookFocus) => {
    const sanitizedText = sanitizeText(text);

    // If there's a validator, check if the text is valid
    if (validator && !validator(sanitizedText)) {
      // If not valid, set the error state
      // and set the editor value to the sanitized text
      setErrorState(true);
      editorRef.current = sanitizedText;

      const node = ref.current;
      if (node) {
        // console.log('sending cursor position')
        sendCurrentCellFocus({ node, field: id });
      }
      return;
    }

    // Everything is fine, so reset the error state if need be
    if (errorState) {
      setErrorState(false);
    }

    // Clear any local cursor position
    clearLocalCursorPosition();

    onChange(sanitizedText, focus);
  });

  const onKeyDown = useKeyDownHandler({
    field: id,
    getCellFocus,
    value,
    sendCurrentCellFocus,
    singleLine,
  });

  const onKeyUp = useKeyUpHandler({
    field: id,
    getCellFocus,
    value,
    sendCurrentCellFocus,
    setValue,
    ref,
  });

  const onMouseUp = useHandler(() => {
    const node = ref.current;
    if (node) {
      sendCurrentCellFocus({ node, field: id, ignoreFocus: true });
    }
  });

  const onBlur = useHandler(() => {
    clearLocalCursorPosition();

    const selection = document.getSelection();
    if (selection?.anchorNode === ref.current?.firstChild) {
      selection?.collapse(document.activeElement);
    }
  });

  const onPaste = usePasteHandler({
    ref,
    value,
    id,
    setValue,
  });

  return {
    ref,
    focused: getCellFocus().type !== "none",
    onKeyDown,
    onMouseUp,
    onKeyUp,
    onBlur,
    onPaste,
    editorValue: editorRef.current,
    error: errorState,
  };
}
