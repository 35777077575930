import { useHandler, useThemeSelect } from "@fiberplane/hooks";
import { Input } from "@fiberplane/ui";
import { useEffect } from "react";
import { styled } from "styled-components";

import { useFeature, useFeatures } from "../../../../../hooks";
import { ExperimentalFeatures } from "./ExperimentalFeatures";

export function ExperimentalContentBlock() {
  const [hasFeaturesEnabled, setHasFeaturesEnabled] = useFeatures();

  // Set to system theme if the theme switcher is not enabled. This is a
  // temporary solution until dark mode isn't hidden behind a feature flag.
  const [hasThemeSwitcherEnabled] = useFeature("theme-switcher");
  const { setToSystemTheme } = useThemeSelect();
  useEffect(() => {
    if (!hasThemeSwitcherEnabled) {
      setToSystemTheme();
    }
  }, [hasThemeSwitcherEnabled, setToSystemTheme]);

  const handleLightSwitchOnChange = useHandler(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setHasFeaturesEnabled(event.target.checked),
  );

  return (
    <ExperimentalContent>
      <LightSwitchContainer>
        <LightSwitchLabel>
          <Input
            type="lightswitch"
            checked={hasFeaturesEnabled}
            onChange={handleLightSwitchOnChange}
          />
          Enable experimental features
        </LightSwitchLabel>
      </LightSwitchContainer>

      {hasFeaturesEnabled && <ExperimentalFeatures />}
    </ExperimentalContent>
  );
}

const ExperimentalContent = styled.div`
  display: grid;
  gap: 16px;
`;

const LightSwitchContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const LightSwitchLabel = styled.label`
  margin: 0;
  font: ${({ theme }) => theme.font.headings.h4};
  display: flex;
  gap: 8px;
  cursor: pointer;
`;
