import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import {
  type TemplateSummary,
  useListTemplatesQuery,
} from "../../../../../api";
import {
  selectActiveWorkspaceIdOrThrow,
  selectCommandMenuVariantType,
} from "../../../../../selectors";
import { dispatch } from "../../../../../store";
import { showTemplateDetailModal } from "../../../../../thunks";
import type { SelectTemplateCommand } from "../../../../../types";

const EMPTY_TEMPLATE_LIST: Array<TemplateSummary> = [];

export function useTemplateCommands(): Array<SelectTemplateCommand> {
  const { variantType, workspaceId } = useSelector(selectTemplateCommandState);
  const { data: templates = EMPTY_TEMPLATE_LIST } = useListTemplatesQuery(
    { workspaceId },
    { skip: !workspaceId },
  );

  return useMemo(() => {
    if (variantType === "none" || variantType === "selectTemplate") {
      return templates.map(
        ({ name, description }): SelectTemplateCommand => ({
          type: "templates",
          key: `notebooks/fromTemplate/${name}`,
          title: name,
          description,
          icon: "templates",
          onActivate: () => dispatch(showTemplateDetailModal(name)),
        }),
      );
    }

    return [];
  }, [variantType, templates]);
}

const selectTemplateCommandState = createSelector(
  [selectActiveWorkspaceIdOrThrow, selectCommandMenuVariantType],
  (workspaceId, variantType) => ({
    variantType,
    workspaceId,
  }),
);
