import { useHandler } from "@fiberplane/hooks";
import {
  type MutableRefObject,
  type RefObject,
  useEffect,
  useRef,
} from "react";

import { setFocus, withActiveNotebook } from "../../../../../../../actions";
import { FRONT_MATTER_CELL_ID } from "../../../../../../../constants";
import { useCellFocus } from "../../../../../../../hooks";
import { dispatch } from "../../../../../../../store";
import type { CellFocus, NotebookFocus } from "../../../../../../../types";
import {
  getCellFocusEnd,
  getCellFocusStart,
  toNotebookFocus,
} from "../../../../../../../utils";
import { createCellFocus } from "../../utils";
import type { SendCurrentCellFocus } from "./types";
import { updateBrowserCursorPosition } from "./utils";

export function useCursorPosition(
  field: string,
  ref: RefObject<HTMLElement>,
  errorState: boolean,
) {
  // Get the focus information
  const cellFocusFromStore = useCellFocus(FRONT_MATTER_CELL_ID, field);

  // This is used to keep track of the last position that was sent to the store
  // It is possible that the user already moved on to a new position and we don't
  // want to overwrite that with a position that is slightly behind
  const lastSentCellFocus = useRef<CellFocus | undefined>();

  // Store timeout id that we use for delaying cursor position updates
  // so we can cancel it if we want
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const cellFocus = lastSentCellFocus.current ?? cellFocusFromStore;
  const { start: storedStartPosition, end: storedEndPosition } =
    getStartAndEndFromFocus(cellFocus);

  const clearLocalCursorPosition = useHandler(() => {
    lastSentCellFocus.current = undefined;
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  });

  // Function so other handlers can access the current cell focus value
  const getCellFocus = useHandler(
    () => lastSentCellFocus.current ?? cellFocusFromStore,
  );

  // Update the browser focus && cursor position on focus (information) change
  // biome-ignore lint/correctness/useExhaustiveDependencies: It looks intentional...
  useEffect(() => {
    // Check if we should bring the focus to the editor
    if (
      cellFocusFromStore.type !== "none" &&
      ref.current &&
      document.activeElement !== ref.current
    ) {
      ref.current.focus();
      lastSentCellFocus.current = undefined;
    }

    // Now for updating the cursor position
    // First do a base check: see if there's an end position & if there
    // are dom elements to bring focus to
    if (ref.current?.firstChild && cellFocusFromStore.type !== "none") {
      const { start, end } = getStartAndEndFromFocus(
        lastSentCellFocus.current ?? cellFocusFromStore,
      );

      // If there's at least an end position, update the cursor position
      if (end !== undefined) {
        updateBrowserCursorPosition(ref.current.firstChild, start, end);
      }
    }

    // If there's a lastSentCellFocus and the focus is none,
    // reset the lastSentCellFocus & timeout for sending the cursor position
    if (lastSentCellFocus.current && cellFocusFromStore.type === "none") {
      clearLocalCursorPosition();
    }
  }, [
    ref,
    cellFocusFromStore,
    storedEndPosition,
    storedStartPosition,
    clearLocalCursorPosition,
    errorState,
  ]);

  const sendCurrentCellFocusHandler = useHandler<SendCurrentCellFocus>(
    (params) =>
      sendCurrentCellFocus({
        ...params,
        errorState,
        timeoutRef,
        lastSentCellFocus,
      }),
  );

  return {
    sendCurrentCellFocus: sendCurrentCellFocusHandler,
    getCellFocus,
    clearLocalCursorPosition,
  };
}

function sendCurrentCellFocus(params: {
  node: HTMLElement;
  field: string;
  ignoreFocus?: boolean;
  errorState: boolean;
  timeoutRef: MutableRefObject<ReturnType<typeof setTimeout> | undefined>;
  lastSentCellFocus: MutableRefObject<CellFocus | undefined>;
}) {
  const {
    node,
    field,
    ignoreFocus = false,
    timeoutRef,
    lastSentCellFocus,
    errorState,
  } = params;

  // Clear the timeout & lastSentCellFocus
  if (timeoutRef.current) {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = undefined;
  }

  // Check if the editor is focused, unless we should ignore the focus
  if (document.activeElement !== node && ignoreFocus === false) {
    lastSentCellFocus.current = undefined;
    return;
  }

  // Update the lastSentCellFocus to the future position
  lastSentCellFocus.current = createCellFocus(node, field);

  // If there's an error, don't send the position
  if (errorState) {
    return;
  }

  timeoutRef.current = setTimeout(() => {
    timeoutRef.current = undefined;
    if (!lastSentCellFocus.current) {
      return;
    }

    const focus: NotebookFocus = toNotebookFocus(
      lastSentCellFocus.current,
      FRONT_MATTER_CELL_ID,
    );
    dispatch(withActiveNotebook(setFocus(focus)));
  }, 1000);
}

function getStartAndEndFromFocus(cellFocus: CellFocus) {
  return {
    start: getCellFocusStart(cellFocus),
    end: getCellFocusEnd(cellFocus),
  };
}
