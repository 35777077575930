import type { ParamParseKey, Params } from "react-router";
import {
  MAIN_SECTION_PATH,
  PERSONAL_INTEGRATION_PATH,
  WORKSPACE_INTEGRATION_PATH,
} from "../../../thunks";
import { SETTINGS, Settings } from "../Settings";

type RouteObject<Path extends string, Props = Params<ParamParseKey<Path>>> = {
  path: Path | `${Path}?`;
  component: React.FunctionComponent<Props>;
};

export const MainSettingsRoute: RouteObject<typeof MAIN_SECTION_PATH> = {
  path: MAIN_SECTION_PATH,

  component: (props) => {
    const activeSection =
      SETTINGS.find(({ section: s }) => s === props.section)?.section ||
      "profile";
    return <Settings section={activeSection} />;
  },
};

export const PersonalIntegrationSettingsRoute: RouteObject<
  typeof PERSONAL_INTEGRATION_PATH
> = {
  path: PERSONAL_INTEGRATION_PATH,
  component: (props) => {
    return (
      <Settings
        section="personalIntegrations"
        integrationId={props.integrationId}
      />
    );
  },
};

export const WorkspaceIntegrationSettingsRoute: RouteObject<
  typeof WORKSPACE_INTEGRATION_PATH
> = {
  path: WORKSPACE_INTEGRATION_PATH,
  component: (props) => {
    return (
      <Settings
        section="workspaceIntegrations"
        integrationId={props.integrationId}
      />
    );
  },
};

export const routes = [
  MainSettingsRoute,
  PersonalIntegrationSettingsRoute,
] as const;
