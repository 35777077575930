import { memo } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

import { Icon, cancelEvent } from "@fiberplane/ui";
import { createLabelDraft, editLabel } from "../../../actions";
import { LABELS_CELL_ID } from "../../../constants";
import {
  makeIsFocusedSelector,
  selectLabelsEditor,
  selectNotebookLabels,
} from "../../../selectors";
import { dispatch } from "../../../store";
import { deleteNotebookLabel, focusCell } from "../../../thunks";
import type { Label } from "../../../types";
import { LabelsEditor } from "../../LabelsEditor";
import { useCellHighlight } from "../../highlight";
import {
  CellContainer,
  CellLeftContext,
  CellMain,
  CellRightContext,
} from "../CellContainer";
import { CellFocusIndicatorWithButtons } from "../CellFocusIndicatorWithButtons";
import { CellPresence } from "../CellPresence";
import { ContextMenu } from "../ContextMenu";
import {
  KeyValueCellContentKey,
  KeyValueCellContentRow,
  KeyValueCellContentValueContainer,
  KeyValueCellContentValueContent,
} from "../KeyValueCellContent";
import { onLabelsEditorBlur, onLabelsEditorKeyDown } from "./thunks";

type Props = {
  readOnly: boolean;
};

export const LabelsCell = memo(function LabelsCell({ readOnly }: Props) {
  const editor = useSelector(selectLabelsEditor);
  const focused = useSelector(selectFocus);
  const highlighted = useCellHighlight(id);
  const labels = useSelector(selectNotebookLabels);

  const onClick = () => {
    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
    if (!focused && !readOnly) {
      dispatch(focusCell({ cellId: id }));
    }
  };

  return (
    <LabelsCellContainer
      data-cell-id={id}
      data-cell-type="labels"
      onClick={onClick}
      focused={focused}
    >
      <ContextMenu cellId={id} />
      <CellLeftContext>
        <CellFocusIndicatorWithButtons cellId={id} focused={focused} />
      </CellLeftContext>
      <CellMain
        tabIndex={focused ? 0 : -1}
        data-function="cell-content"
        $highlight={highlighted}
      >
        <KeyValueCellContentRow>
          <KeyValueCellContentKey>
            <Icon iconType="tag" />
            Labels
          </KeyValueCellContentKey>
          <StyledKeyValueCellContentValueContainer>
            <KeyValueCellContentValueContent>
              <LabelsEditor
                editor={focused ? editor : undefined}
                labels={labels}
                onBlur={onBlur}
                onCreate={onCreate}
                onDelete={onDelete}
                onEdit={onEdit}
                onKeyDown={onKeyDown}
                readOnly={readOnly}
                type="notebook"
                withContextMenu
                withToolbar
              />
            </KeyValueCellContentValueContent>
          </StyledKeyValueCellContentValueContainer>
        </KeyValueCellContentRow>
      </CellMain>
      <CellRightContext>
        <CellPresence cellId={id} />
      </CellRightContext>
    </LabelsCellContainer>
  );
});

const id = LABELS_CELL_ID;

const selectFocus = makeIsFocusedSelector(LABELS_CELL_ID);

function onBlur(event: React.FocusEvent) {
  dispatch(onLabelsEditorBlur(event));
}

function onCreate(event: React.SyntheticEvent) {
  cancelEvent(event);
  dispatch(focusCell({ cellId: id }));
  dispatch(createLabelDraft("notebook"));
}

function onDelete(event: React.SyntheticEvent, label: Label) {
  cancelEvent(event);
  dispatch(deleteNotebookLabel(label.key));
}

function onEdit(event: React.SyntheticEvent, label: Label) {
  cancelEvent(event);
  dispatch(focusCell({ cellId: id }));
  dispatch(editLabel("notebook", label));
}

function onKeyDown(event: React.KeyboardEvent) {
  dispatch(onLabelsEditorKeyDown(event));
}

const LabelsCellContainer = styled(CellContainer)`
margin: ${({ theme }) => theme.spacingParagraph};
margin-bottom: 0;
margin-top: 4px;
`;

const StyledKeyValueCellContentValueContainer = styled(
  KeyValueCellContentValueContainer,
)`
  height: 100%;
`;
