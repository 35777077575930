import { useHandler } from "@fiberplane/hooks";
import { Button, IconButton } from "@fiberplane/ui";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { generatePath } from "react-router";
import { useTitle } from "react-use";
import useMedia from "react-use/lib/useMedia";
import { replace } from "redux-first-history";
import { createStructuredSelector } from "reselect";
import { css, styled } from "styled-components";

import { CLEAR_VIEW_EDITOR } from "../../actions";
import { ROUTES } from "../../constants";
import { useCopyToClipboard } from "../../hooks";
import {
  selectActiveWorkspaceNameOrThrow,
  selectCanCreateView,
  selectCanUpdateView,
} from "../../selectors";
import { dispatch } from "../../store";
import type { NewView, UpdateView, View as ViewModel } from "../../types";
import {
  formatName,
  validateRequired,
  validateResourceName,
} from "../../utils";
import { Nudge, PageContainer, PageHeader, SuccessButton } from "../UI";
import { ViewToggleMenu } from "../Views";
import { ColorPicker } from "./ColorPicker";
import { NotebookList } from "./NotebookList";
import { TimeRangeToggleMenu } from "./TimeRangeToggleMenu";
import { ViewInput } from "./ViewInput";
import { ViewLabelsEditor } from "./ViewLabelsEditor";

type MutationProps = {
  isLoading: boolean;
  isSuccess: boolean;
  resetMutation: () => void;
  view: ViewModel;
};

type NewViewProps = MutationProps & {
  onCreate: (newView: NewView) => Promise<string | undefined>;
};

type UpdateViewProps = MutationProps & {
  onUpdate: (updateView: UpdateView) => Promise<string | undefined>;
};

type Props = NewViewProps | UpdateViewProps;

function isNewViewProps(props: Props): props is NewViewProps {
  return "onCreate" in props;
}

const FORM_SUCCESS_RESET_TIMEOUT_MS = 1500;

export function View(props: Props) {
  const { canCreateView, canUpdateView, workspaceName } =
    useSelector(selectViewState);

  const { resetMutation, isSuccess, isLoading, view } = props;
  const isNew = isNewViewProps(props);
  const canMutate = isNew ? canCreateView : canUpdateView;

  const {
    control,
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setFocus,
    setValue,
    trigger,
    watch,
  } = useForm<ViewModel>({
    defaultValues: {
      relativeTime: undefined,
      sortBy: "updated_at",
      sortDirection: "descending",
      ...view,
    },
    mode: "onChange",
  });

  const displayName = watch("displayName");
  useEffect(() => {
    if (isNew && displayName) {
      const formattedName = formatName(displayName);
      if (formattedName) {
        setValue("name", formattedName);
        trigger("name");
      }
    }
  }, [displayName, isNew, setValue, trigger]);

  const name = watch("name");
  useTitle(name);

  const isPreview = isNew || isDirty;

  const mediumSizeAndUp = useMedia("(min-width: 768px)");
  const smallNamePrefix = `workspaces/${workspaceName}/views/`;
  const namePrefix = `studio.fiberplane.com/${smallNamePrefix}`;
  const { isCopied, handleCopy } = useCopyToClipboard(
    `${namePrefix}${view?.name}`,
  );

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_VIEW_EDITOR });
    };
  }, []);

  const onSave = useHandler(async (data: ViewModel) => {
    const isValid = await trigger();

    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
    if (!canMutate && !isValid) {
      return;
    }

    await (isNew ? props.onCreate(data) : props.onUpdate(data));
  });

  const onCancel = useHandler(() => {
    dispatch({ type: CLEAR_VIEW_EDITOR });
    reset();
  });

  const handleFormReset = useHandler(() => {
    // Reset loading states
    resetMutation();

    dispatch({ type: CLEAR_VIEW_EDITOR });

    if (isNew) {
      dispatch(
        replace(
          generatePath(ROUTES.View, {
            workspaceName,
            viewName: name,
          }),
        ),
      );
    } else {
      // Reset with current values to unset dirty state
      reset(getValues());
    }
  });

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;

    if (isSuccess) {
      timeoutId = setTimeout(handleFormReset, FORM_SUCCESS_RESET_TIMEOUT_MS);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [handleFormReset, isSuccess]);

  return (
    <PageContainer>
      <StyledPageHeader>
        <Form onSubmit={handleSubmit(onSave)}>
          <ColorPicker control={control} />
          <DisplayNameInput
            autoFocus={isNew}
            aria-invalid={!!errors.displayName}
            placeholder={isNew ? "New view" : "View"}
            disabled={!canMutate}
            tabIndex={0}
            {...register("displayName", {
              validate: (value) => validateRequired(value, "display name"),
            })}
            data-testid="view-displayName-input"
          />
          {errors.displayName && (
            <Nudge
              element={errors.displayName.ref as HTMLElement}
              placement="bottom"
            >
              {errors.displayName.message}
            </Nudge>
          )}
          <NameInputContainer>
            <NamePrefix onClick={() => setFocus("name")}>
              {mediumSizeAndUp ? namePrefix : smallNamePrefix}
            </NamePrefix>
            {isNew ? (
              <>
                <NameInput
                  aria-invalid={!!errors.name}
                  disabled={!canMutate}
                  tabIndex={0}
                  {...register("name", {
                    validate: {
                      resourceName: (value) =>
                        validateResourceName(value, "name"),
                    },
                  })}
                  data-testid="view-name-input"
                />
                {errors.name && (
                  <Nudge
                    element={errors.name.ref as HTMLElement}
                    placement="bottom"
                  >
                    {errors.name.message}
                  </Nudge>
                )}
              </>
            ) : (
              <>
                <Name>{view.name}</Name>
                <CopyUrlIconButton
                  iconType={isCopied ? "check" : "copy"}
                  aria-label={isCopied ? undefined : "Click to copy url"}
                  disabled={isCopied}
                  onClick={isCopied ? undefined : handleCopy}
                  buttonSize="small"
                  type="button"
                />
              </>
            )}
          </NameInputContainer>
          <DescriptionInput
            placeholder={
              canMutate
                ? "Add a description (optional)..."
                : "No description provided"
            }
            tabIndex={0}
            {...register("description")}
            data-testid="view-description-input"
          />
          <ViewLabelsEditor canMutate={canMutate} control={control} />
          <TimeRangeToggleMenu canMutate={canMutate} control={control} />
          <AnimatePresence mode="sync">
            {(isDirty || isSuccess) && (
              <Actions key="actions">
                {
                  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
                  !isNew && !isSuccess && (
                    <CancelButton
                      onClick={onCancel}
                      buttonStyle="secondary"
                      buttonSize="small"
                      disabled={isLoading}
                      type="button"
                      data-testid="view-mutation-cancel"
                    >
                      Cancel
                    </CancelButton>
                  )
                }
                <SuccessButton
                  buttonSize="small"
                  isSuccessful={isSuccess}
                  type="submit"
                  successIconType="check"
                  disabled={isLoading}
                  data-testid="view-mutation-submit"
                >
                  {isNew
                    ? isSuccess
                      ? "View created"
                      : "Create view"
                    : isSuccess
                      ? "View updated"
                      : "Update view"}
                </SuccessButton>
              </Actions>
            )}
            {!isNew && (
              <ViewToggleContainer>
                <ViewToggleMenu
                  view={view}
                  toggleElement={({ anchorRef, opened }) => (
                    <div ref={anchorRef}>
                      <IconButton
                        iconType="dots_three"
                        isActive={opened}
                        type="button"
                      />
                    </div>
                  )}
                />
              </ViewToggleContainer>
            )}
          </AnimatePresence>
        </Form>
      </StyledPageHeader>
      <NotebookList
        canMutate={canMutate}
        control={control}
        isPreview={isPreview}
      />
    </PageContainer>
  );
}

const selectViewState = createStructuredSelector({
  canCreateView: selectCanCreateView,
  canUpdateView: selectCanUpdateView,
  workspaceName: selectActiveWorkspaceNameOrThrow,
});

const StyledPageHeader = styled(PageHeader)`
  padding-bottom: 20px;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  border-bottom: 1px solid ${({ theme }) => theme.colorBase200};

  @media (min-width: 768px) {
    gap: 12px 20px;
  }
`;

const Form = styled.form`
  display: grid;
  grid:
    "viewIcon displayName menu" auto
    "name name name" auto
    "description description description" auto
    "labels labels labels" auto
    "timerange timerange actions" auto
    / auto 1fr auto;
  gap: 12px 18px;
  align-items: center;
`;

const CopyUrlIconButton = styled(IconButton)`
  grid-area: nameIcon;
  margin-left: 4px;
`;

const DisplayNameInput = styled(ViewInput)(
  ({ theme }) => css`
    grid-area: displayName;
    font: ${theme.fontStudioHeadingsH2ShortHand};
    letter-spacing: ${theme.fontStudioHeadingsH2LetterSpacing};
    flex: 1;
    width: 100%;

    @media (min-width: 768px) {
      font: ${theme.fontStudioHeadingsH1ShortHand};
      letter-spacing: ${theme.fontStudioHeadingsH1LetterSpacing};
    }
  `,
);

const NameInputContainer = styled.div`
  grid-area: name;
  display: flex;
  align-items: center;
  height: 28px;
  width: 100%;
`;

const NamePrefix = styled.strong(
  ({ theme }) => css`
    font: ${theme.fontStudioStrongSmallShortHand};
    letter-spacing: ${theme.fontStudioStrongSmallLetterSpacing};
    color: ${theme.colorBase400};
    width: max-content;
  `,
);

const NameInput = styled(ViewInput)(
  ({ theme }) => css`
    flex: 1;
    font: ${theme.fontStudioStrongSmallShortHand};
    letter-spacing: ${theme.fontStudioStrongSmallLetterSpacing};
    color: ${theme.colorBase600};
    width: max-content;
  `,
);

const Name = styled(NameInput).attrs({ as: "span" })`
  flex: 0 0 max-content;
`;

const DescriptionInput = styled(ViewInput)(
  ({ theme }) =>
    css`
      grid-area: description;
      font: ${theme.fontStudioBodyCopyLargeShortHand};
      letter-spacing: ${theme.fontStudioBodyCopyLargeLetterSpacing};
      color: ${theme.colorBase600};
    `,
);

const ViewToggleContainer = styled.div`
  grid-area: menu;
  justify-self: flex-end;
  width: max-content;
`;

const Actions = styled(motion.nav)`
  width: max-content;
  grid-area: actions;
  gap: 10px;
  display: flex;
  align-self: flex-end;
`;

Actions.defaultProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const CancelButton = styled(Button)`
  &:not(:hover) {
    background: transparent;
    border-color: transparent;
  }
`;

const SuccessIconContainer = styled(motion.div)`
  margin-right: 10px;

  & svg {
    height: 10px;
    width: 10px;
    transform: scale(1.5);
  }
`;

SuccessIconContainer.defaultProps = {
  initial: { scale: 0 },
  animate: { scale: 1 },
  exit: { scale: 0 },
};
