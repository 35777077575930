import { memo } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

import { useDropTargetClassNames } from "../../hooks";
import { selectActiveDrag, selectActiveNotebook } from "../../selectors";
import type { RootState } from "../../state";
import { dispatch, getState } from "../../store";
import { addCell, focusCell } from "../../thunks";
import { last, track } from "../../utils";

export const EmptyCellArea = memo(function EmptyCellArea() {
  const dropTargetClassNames = useDropTargetClassNames({
    topHovered: useSelector(isDropTargetSelector),
  });

  return (
    <Area data-testid="empty-cell-area" className={dropTargetClassNames}>
      <Button onClick={handleClick} />
      <HoverContainer data-function="cell-content" />
    </Area>
  );
});

function handleClick() {
  const { cellIds, cellsById, id } = selectActiveNotebook(getState());

  const lastCellId = last(cellIds);
  if (lastCellId) {
    const lastCell = cellsById[lastCellId]?.cell;
    if (lastCell && lastCell.type === "text" && lastCell.content.length === 0) {
      return dispatch(focusCell({ cellId: lastCellId, highlight: true }));
    }
  }

  track("notebook | add cell", {
    notebookId: id,
    cellType: "text",
    source: "empty cell area",
  });

  dispatch(addCell({ relatedId: lastCellId || "", position: "after" }));
}

const isDropTargetSelector = (state: RootState) =>
  selectActiveDrag(state)?.target.type === "end_of_notebook";

const Area = styled.div`
  flex: 1 1 auto;
  min-height: 50px;
  position: relative;
  margin: 4px 0;
`;

const HoverContainer = styled.div`
  pointer-events: none;
  height: 100%;
  width: calc(100% - 188px);
  margin: 0 auto;
  display: flex;

  &&::before {
    position: relative;
    content: "";
    max-width: calc(100vw - 112px);
    display: block;
  }
`;

const Button = styled.button`
  position: absolute;
  display: block;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  cursor: text;
  appearance: none;
  background: none;
  border: none;
`;

Button.defaultProps = {
  tabIndex: -1,
};
