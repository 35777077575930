import type { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";

import { showError, withNotebook } from "../actions";
import { notebooksApi } from "../api";
import { SEND_UPDATE_THROTTLE_TIMEOUT } from "../constants";
import {
  selectActiveNotebook,
  selectActiveWorkspaceId,
  selectAllNotebooks,
  selectNotebook,
} from "../selectors";
import { FiberKit, Sentry } from "../services";
import type { Thunk } from "../store";
import type { Change, NotebookSummary } from "../types";
import { formatLabel } from "../utils";
import { sendFocusInfo, sendOperations } from "./courierThunks";

export const processChanges =
  (notebookId: string, changes: Array<Change>): Thunk =>
  (dispatch) => {
    try {
      for (const change of changes) {
        switch (change.type) {
          case "add_label": {
            dispatch(processAddLabelChange(notebookId, change));
            break;
          }
          case "remove_label": {
            dispatch(processRemoveLabelChange(notebookId, change));
            break;
          }
          case "replace_label": {
            dispatch(processReplaceLabelChange(notebookId, change));
            break;
          }
          case "update_notebook_title": {
            dispatch(processNotebookTitleChange(notebookId, change));
            break;
          }
          default: {
            dispatch(processNotebookChange(notebookId));
            break;
          }
        }
      }
    } catch (error) {
      const message = `Cannot persist changes. Data loss may occur! Reason: ${error}`;
      dispatch(withNotebook(notebookId, showError({ type: "other", message })));
    }
  };

/**
 * Adds a label and updates the `updatedAt` property on the notebook in the cache
 */
const processAddLabelChange =
  (notebookId: string, change: Extract<Change, { type: "add_label" }>): Thunk =>
  (dispatch) => {
    const updateDraft = (
      draftNotebooks?: MaybeDrafted<Array<NotebookSummary>>,
    ) => {
      if (!draftNotebooks) {
        return;
      }

      for (const notebook of draftNotebooks) {
        if (notebook.id === notebookId) {
          notebook.labels.push(change.label);
          notebook.updatedAt = new Date().toISOString();
          break;
        }
      }
    };

    dispatch(updateNotebookCache(updateDraft));
  };

/**
 * Updates the `updatedAt` property on the notebook in the cache
 */
const processNotebookChange =
  (notebookId: string): Thunk =>
  (dispatch) => {
    dispatch(
      updateNotebookCache((draftNotebooks) => {
        if (!draftNotebooks) {
          return;
        }

        for (const notebook of draftNotebooks) {
          if (notebook.id === notebookId) {
            notebook.updatedAt = new Date().toISOString();
            break;
          }
        }
      }),
    );
  };

/**
 * Updates the `title` and `updatedAt` properties on the notebook in the cache
 */
const processNotebookTitleChange =
  (
    notebookId: string,
    change: Extract<Change, { type: "update_notebook_title" }>,
  ): Thunk =>
  (dispatch) => {
    const updateDraft = (
      draftNotebooks?: MaybeDrafted<Array<NotebookSummary>>,
    ) => {
      if (!draftNotebooks) {
        return;
      }

      for (const notebook of draftNotebooks) {
        if (notebook.id === notebookId) {
          notebook.title = change.title;
          notebook.updatedAt = new Date().toISOString();
          break;
        }
      }
    };

    dispatch(updateNotebookCache(updateDraft));
  };

/**
 * Removes a label and updates the `updatedAt` property on the notebook in the cache
 */
const processRemoveLabelChange =
  (
    notebookId: string,
    change: Extract<Change, { type: "remove_label" }>,
  ): Thunk =>
  (dispatch) => {
    const updateDraft = (
      draftNotebooks?: MaybeDrafted<Array<NotebookSummary>>,
    ) => {
      if (!draftNotebooks) {
        return;
      }

      for (const notebook of draftNotebooks) {
        if (notebook.id === notebookId) {
          notebook.labels = notebook.labels.filter(
            (label) => formatLabel(label) !== formatLabel(change.label),
          );
          notebook.updatedAt = new Date().toISOString();
          break;
        }
      }
    };

    dispatch(updateNotebookCache(updateDraft));
  };

/**
 * Replaces a label and updates the `updatedAt` property on the notebook in the cache
 */
const processReplaceLabelChange =
  (
    notebookId: string,
    change: Extract<Change, { type: "replace_label" }>,
  ): Thunk =>
  (dispatch) => {
    const updateDraft = (
      draftNotebooks?: MaybeDrafted<Array<NotebookSummary>>,
    ) => {
      if (!draftNotebooks) {
        return;
      }

      for (const notebook of draftNotebooks) {
        if (notebook.id !== notebookId) {
          continue;
        }

        notebook.labels = notebook.labels.map((label) => {
          if (label.key === change.key) {
            return change.label;
          }

          return label;
        });
        notebook.updatedAt = new Date().toISOString();

        break;
      }
    };

    dispatch(updateNotebookCache(updateDraft));
  };

/**
 * Timer that tracks sending of focus updates and pending operations.
 *
 * Because we don't want the sending of focus updates and operations to go out
 * of sync (that could lead to other users seeing cursors at places *before*
 * you type anything there) they both use the same timer, while the `type`
 * property tracks what needs to be send.
 */
type UpdateTimer = {
  notebookId: string;
  timer: ReturnType<typeof setTimeout>;
  type: UpdateType;
};

type UpdateType = "focus" | "operations_and_focus";

let updateTimer: UpdateTimer | null = null;

export const queueSendFocusUpdate =
  (notebookId: string): Thunk =>
  (dispatch) => {
    if (updateTimer?.notebookId === notebookId) {
      return; // Timer is already set and includes focus in any case.
    }

    if (updateTimer) {
      // Timer is already set for other notebook. Send that one without waiting
      // for the timeout, and schedule a new one.
      clearTimeout(updateTimer.timer);
      dispatch(sendUpdates(updateTimer));
    }

    updateTimer = {
      notebookId,
      timer: setTimeout(() => {
        if (updateTimer) {
          dispatch(sendUpdates(updateTimer));
          updateTimer = null;
        }
      }, SEND_UPDATE_THROTTLE_TIMEOUT),
      type: "focus",
    };
  };

export const queueSendPendingOperations =
  (notebookId: string): Thunk =>
  (dispatch) => {
    if (updateTimer?.notebookId === notebookId) {
      updateTimer.type = "operations_and_focus";
      return;
    }

    if (updateTimer) {
      // Timer is already set for other notebook. Send that one without waiting
      // for the timeout, and schedule a new one.
      clearTimeout(updateTimer.timer);
      dispatch(sendUpdates(updateTimer));
    }

    updateTimer = {
      notebookId,
      timer: setTimeout(() => {
        if (updateTimer) {
          dispatch(sendUpdates(updateTimer));
          updateTimer = null;
        }
      }, SEND_UPDATE_THROTTLE_TIMEOUT),
      type: "operations_and_focus",
    };
  };

export const sendAllPendingOperations = (): Thunk => (dispatch, getState) => {
  const state = getState();
  for (const notebook of selectAllNotebooks(state)) {
    if (notebook.numUnsentPendingOperations > 0) {
      dispatch(sendPendingOperations(notebook.id));
    }
  }

  const activeNotebookId = selectActiveNotebook(state).id;
  if (activeNotebookId) {
    dispatch(sendFocusUpdate(activeNotebookId));
  }
};

const sendFocusUpdate =
  (notebookId: string): Thunk =>
  (dispatch, getState) => {
    const { focus } = selectNotebook(getState(), notebookId).editor;
    dispatch(sendFocusInfo(notebookId, focus));
  };

const sendUpdates =
  (updateTimer: UpdateTimer): Thunk =>
  (dispatch) => {
    const { notebookId, type } = updateTimer;
    if (type === "operations_and_focus") {
      dispatch(sendPendingOperations(notebookId));
    }

    dispatch(sendFocusUpdate(notebookId));
  };

export const sendPendingOperations =
  (notebookId: string): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const { numPendingOperations, numUnsentPendingOperations, remoteRevision } =
      selectNotebook(state, notebookId);
    if (numUnsentPendingOperations === 0) {
      return; // Nothing to do.
    }

    if (numUnsentPendingOperations !== numPendingOperations) {
      // Some operations have already been sent, but are still waiting for
      // confirmation from the server. We will wait sending out new operations
      // until we have received the confirmation, so there can be no accidental
      // accepting of a revision that we applied after another revision which
      // ends up getting rejected.
      return;
    }

    try {
      const pendingOperations =
        FiberKit.getUnsentPendingOperationsForNotebook(notebookId);
      const firstPendingOperation = pendingOperations?.[0];
      if (!firstPendingOperation) {
        throw new Error("Unsent pending operations were expected");
      }

      const { revision } = firstPendingOperation;
      if (remoteRevision && remoteRevision >= revision) {
        // There is no point in sending our own operations if we haven't
        // caught up yet with the remote revision.
        return;
      }

      const operations = pendingOperations.map(({ operation }) => operation);
      dispatch(sendOperations(notebookId, revision, operations));
    } catch (error) {
      // It appears that in extreme cases, `getUnsentPendingOperationsForNotebook()`
      // may panic due to `fp-bindgen`'s memory limit (32MB) being exceeded.
      // We'll add additional debugging here to catch what might be going on.
      Sentry.captureError("Error sending pending operations", {
        error,
        notebookId,
        numUnsentPendingOperations,
        remoteRevision,
      });
    }
  };

const updateNotebookCache =
  (
    updateDraftCallback: (
      draftNotebooks?: MaybeDrafted<Array<NotebookSummary>>,
    ) => void,
  ): Thunk =>
  (dispatch, getState) => {
    const workspaceId = selectActiveWorkspaceId(getState());

    if (!workspaceId) {
      return;
    }

    dispatch(
      notebooksApi.util.updateQueryData(
        "listNotebooks",
        { workspaceId },
        updateDraftCallback,
      ),
    );

    dispatch(
      notebooksApi.util.updateQueryData(
        "listPinnedNotebooks",
        { workspaceId },
        updateDraftCallback,
      ),
    );
  };
