import type { NotebookSelection } from "../../types";

export function isCellInSelection(
  cellIds: ReadonlyArray<string>,
  cellId: string,
  { start, end }: NotebookSelection,
): boolean {
  if (cellId === start.cellId || cellId === end.cellId) {
    return true;
  }

  const cellIndex = cellIds.indexOf(cellId);
  return cellIndex > start.cellIndex && cellIndex < end.cellIndex;
}
