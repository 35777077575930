import { replace } from "redux-first-history";

import { templatesApi } from "../api";
import { normalizeException } from "../errors";
import {
  selectActiveWorkspaceIdOrThrow,
  selectActiveWorkspaceNameOrThrow,
  selectNotebookTitle,
} from "../selectors";
import type { Thunk } from "../store";
import type { Notebook } from "../types";
import { createNotebookLink, downloadFile } from "../utils";
import { addNotification } from "./notificationsThunks";

export function convertNotebookToTemplate(
  notebookId: string,
): Thunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      const { error, data } = await dispatch(
        templatesApi.endpoints.convertNotebookToTemplate.initiate({
          notebookId,
        }),
      );

      if (error) {
        throw normalizeException(error);
      }

      if (!data) {
        throw new Error("Template is empty");
      }

      const basename = selectNotebookTitle(getState())
        .replace(/'/g, "")
        .replace(/\W/g, "_")
        .replace(/_{2,}/g, "_")
        .toLowerCase();

      downloadFile(`${basename}.jsonnet`, "text/jsonnet", data);
      dispatch(addNotification({ title: "Template downloaded" }));
    } catch {
      const title = "Failed to download template";
      dispatch(addNotification({ title, type: "danger" }));
    }
  };
}

export function expandTemplate(
  templateName: string,
  parameters: Record<string, string>,
): Thunk<Promise<Notebook>> {
  return async (dispatch, getState) => {
    const workspaceId = selectActiveWorkspaceIdOrThrow(getState());

    const result = await dispatch(
      templatesApi.endpoints.expandTemplate.initiate({
        workspaceId,
        templateName,
        parameters,
      }),
    );

    if ("error" in result) {
      throw normalizeException(result.error);
    }

    const { data: notebook } = result;

    return notebook;
  };
}

export function expandTemplateAndRedirect(
  templateName: string,
  parameters: Record<string, string>,
): Thunk<Promise<Notebook>> {
  return async (dispatch, getState): Promise<Notebook> => {
    const workspaceName = selectActiveWorkspaceNameOrThrow(getState());

    const notebook = await dispatch(expandTemplate(templateName, parameters));

    dispatch(
      replace(createNotebookLink(workspaceName, notebook.id, notebook.title)),
    );
    return notebook;
  };
}
