export const CLIPPED_TEMPLATES_ICON_ID = "clipped_templates_icon";

export function ClippedTemplatesIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <clipPath
          id={CLIPPED_TEMPLATES_ICON_ID}
          clipPathUnits="objectBoundingBox"
        >
          <path d="M0.917,0.333 C0.939,0.333,0.96,0.325,0.976,0.309 C0.991,0.293,1,0.272,1,0.25 V0.083 C1,0.061,0.991,0.04,0.976,0.024 C0.96,0.009,0.939,0,0.917,0 H0.75 C0.728,0,0.707,0.009,0.691,0.024 C0.675,0.04,0.667,0.061,0.667,0.083 V0.125 H0.333 V0.083 C0.333,0.061,0.325,0.04,0.309,0.024 C0.293,0.009,0.272,0,0.25,0 H0.083 C0.061,0,0.04,0.009,0.024,0.024 C0.009,0.04,0,0.061,0,0.083 V0.25 C0,0.272,0.009,0.293,0.024,0.309 C0.04,0.325,0.061,0.333,0.083,0.333 H0.125 V0.667 H0.083 C0.061,0.667,0.04,0.675,0.024,0.691 C0.009,0.707,0,0.728,0,0.75 V0.917 C0,0.939,0.009,0.96,0.024,0.976 C0.04,0.991,0.061,1,0.083,1 H0.25 C0.272,1,0.293,0.991,0.309,0.976 C0.325,0.96,0.333,0.939,0.333,0.917 V0.875 H0.667 V0.917 C0.667,0.939,0.675,0.96,0.691,0.976 C0.707,0.991,0.728,1,0.75,1 H0.917 C0.939,1,0.96,0.991,0.976,0.976 C0.991,0.96,1,0.939,1,0.917 V0.75 C1,0.728,0.991,0.707,0.976,0.691 C0.96,0.675,0.939,0.667,0.917,0.667 H0.875 V0.333 H0.917 M0.792,0.667 H0.75 C0.728,0.667,0.707,0.675,0.691,0.691 C0.675,0.707,0.667,0.728,0.667,0.75 V0.792 H0.333 V0.75 C0.333,0.728,0.325,0.707,0.309,0.691 C0.293,0.675,0.272,0.667,0.25,0.667 H0.208 V0.333 H0.25 C0.272,0.333,0.293,0.325,0.309,0.309 C0.325,0.293,0.333,0.272,0.333,0.25 V0.208 H0.667 V0.25 C0.667,0.272,0.675,0.293,0.691,0.309 C0.707,0.325,0.728,0.333,0.75,0.333 H0.792 V0.667" />
        </clipPath>
      </defs>
    </svg>
  );
}
