import type { ChartTheme } from "@fiberplane/charts";
import { useMemo } from "react";
import { useTheme } from "styled-components";

export function useChartTheme(): ChartTheme {
  const theme = useTheme();

  const chartTheme: ChartTheme = useMemo(
    () => ({
      axisColor: theme.color.fg.muted,
      axisFontFamily: theme.font.body.xs.regular,
      buttonActiveBackgroundColor: theme.color.bg.emphasis["neutral-subtle"],
      buttonActiveColor: theme.color.fg.default,
      buttonBackgroundColor: "transparent",
      buttonBorderRadius: theme.radius.default,
      buttonColor: theme.color.fg.muted,
      buttonDisabledBackgroundColor: theme.color.button.primary.bg.disabled,
      buttonDisabledColor: theme.color.button.primary.fg.disabled,
      buttonFocusBackgroundColor: theme.color.bg.default,
      buttonFocusBorderColor: theme.color.fg.primary,
      buttonFocusOutline: theme.effect.focus.primary,
      buttonFont: theme.font.headings.h5,
      buttonGroupBackgroundColor: "transparent",
      buttonHoverBackgroundColor: theme.colorBase300,
      buttonHoverColor: theme.color.button.primary.bg.hover,
      expandableGradientColor: theme.color.notebook.cell.bg.default,
      gridStrokeColor: theme.color.border.muted,
      legendItemBorderRadius: theme.radius.minimal,
      legendItemCheckboxBorderRadius: theme.radius.lowest,
      legendItemCheckboxColor: theme.color.fg.onemphasis.default,
      legendItemColor: theme.color.fg.default,
      legendItemEmphasisBackgroundColor:
        theme.color.bg.emphasis["neutral-subtle"],
      legendItemEmphasisBorderRadius: theme.radius.lowest,
      legendItemEmphasisColor: theme.color.fg.default,
      legendItemEmphasisFont: theme.font.body.xs.medium,
      legendItemFont: theme.font.body.xs.regular,
      legendItemOnHoverBackgroundColor: theme.color.bg["hover-primary"],
      shapeListColors: [
        theme.color.accent.aqua["500"],
        theme.color.accent.aubergine["500"],
        theme.color.accent.coral["500"],
        theme.color.accent.earth["500"],
        theme.color.accent.lime["500"],
        theme.color.accent.magenta["500"],
        theme.color.accent.rust["500"],
        theme.color.accent.sea["500"],
        theme.color.accent.sky["500"],
      ],
    }),
    [theme],
  );

  return chartTheme;
}
