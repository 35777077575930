import { memo } from "react";

import type { ExtendedAnnotation } from "../types";
import type { EditorNode, KeyedEditorNode } from "./EditorNode";
import { EntityEditorNode } from "./EntityEditorNode";
import type { RenderableAnnotation } from "./RenderableAnnotation";

type Props = EditorNode & { typeAheadText: string };

export const EntityInputEditorNode = memo(function EntityInputEditorNode({
  typeAheadText,
  startOffset,
  endOffset,
}: Props) {
  return (
    <EntityEditorNode
      data-start-offset={startOffset}
      data-end-offset={endOffset}
    >
      {typeAheadText}
    </EntityEditorNode>
  );
});

type EntityInputAnnotation = ExtendedAnnotation & {
  type: "entity_input";
};

export const RENDERABLE_ENTITY_INPUT: RenderableAnnotation<EntityInputAnnotation> =
  {
    supports: (annotation: ExtendedAnnotation) =>
      annotation.type === "entity_input",
    getPlainText: (annotation: EntityInputAnnotation) => {
      return `@${annotation.typeAheadText}`;
    },
    getElement: (annotation: EntityInputAnnotation, props: KeyedEditorNode) => {
      return (
        <EntityInputEditorNode
          {...props}
          typeAheadText={RENDERABLE_ENTITY_INPUT.getPlainText(annotation)}
        />
      );
    },
  };
