import { Icon } from "@fiberplane/ui";
import styled, { css } from "styled-components";

import type { Integration } from "../../../../../slices";
import { getIntegrationIconType } from "./utils";

type IntegrationIconProps = {
  className?: string;
  integrationId: Integration["id"];
};

export function IntegrationIcon({
  className,
  integrationId,
}: IntegrationIconProps) {
  return (
    <Container className={className}>
      <StyledIcon iconType={getIntegrationIconType(integrationId)} />
    </Container>
  );
}

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    width: 40px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: ${theme.radius.default};
    border: 1px solid ${theme.color.border.muted};
  `,
);

const StyledIcon = styled(Icon)(
  () => `
    width: 25px;
    height: 25px;
  `,
);
