import { useClickAway, useHandler, useKeyPressEvent } from "@fiberplane/hooks";
import { Icon, IconButton, type IconType } from "@fiberplane/ui";
import { type HTMLAttributes, useContext, useEffect, useRef } from "react";
import { css, styled, useTheme } from "styled-components";

import { last } from "../../../utils";
import { ButtonBar } from "../buttons";
import { ModalContext } from "./ModalContext";
import { useModals } from "./hooks";

const MAX_WIDTH = "600px";

type BaseModalProps = {
  "data-testid"?: string;
} & HTMLAttributes<HTMLDivElement>;

/**
 * Modal to be used when composing custom modals.
 */
export function BaseModal({
  "data-testid": testId = "modal",
  children,
  ...attributes
}: BaseModalProps) {
  const modals = useModals() || [];

  const { requestClose, afterClose, modalType } = useContext(ModalContext);
  const ref = useRef<HTMLDivElement>(null);

  const onCloseModal = useHandler((event: Event) => {
    // To prevent closing modals underneath another modal, we check if the
    // current modal is the top modal. If it's covered by another one, we
    // prevent the click away from closing the it.
    const topModal = last(modals);
    const topModalType = topModal?.modalState.modal.type;
    const isCoveredByModal = topModalType !== modalType;

    // Popper can be displayed inside the modal, so we need to check if the
    // click was on a popper before closing the modal
    const isPopperTarget =
      event.target instanceof HTMLElement &&
      event.target.offsetParent?.getAttribute("role") === "tooltip";

    if (isPopperTarget || isCoveredByModal) {
      return;
    }

    requestClose();
  });

  useEffect(() => {
    return () => afterClose?.();
  }, [afterClose]);

  useClickAway(ref, onCloseModal);
  useKeyPressEvent("Escape", onCloseModal);

  return (
    <BaseModalContainer
      data-testid={testId}
      ref={ref}
      aria-modal="true"
      aria-labelledby="modal-title"
      {...attributes}
    >
      {children}
    </BaseModalContainer>
  );
}

type ModalProps = {
  description?: React.ReactNode;
  icon?: IconType;
  title?: string;
} & BaseModalProps;

/**
 * Modal container that accepts a title, description, icon type and renders it
 * in a consistent way with spacing & standard styles.
 */
export function Modal({
  "data-testid": testId = "modal",
  children,
  description,
  icon,
  title,
  ...attributes
}: ModalProps): JSX.Element {
  const { requestClose } = useContext(ModalContext);
  const { colorBackground } = useTheme();

  return (
    <ModalContainer data-testid={testId} {...attributes}>
      <ModalIconButton
        onClick={requestClose}
        iconType="close"
        buttonStyle="tertiary-grey"
      />

      {icon && (
        <IconContainer>
          <Icon iconType={icon} color={colorBackground} />
        </IconContainer>
      )}

      {title && <Title>{title}</Title>}

      {description && <Description>{description}</Description>}

      <Content>{children}</Content>
    </ModalContainer>
  );
}

const BaseModalContainer = styled.div(
  ({ theme }) => css`
    max-width: ${MAX_WIDTH};
    min-width: min-content;
    max-height: 90vh;
    width: min(calc(100vh - 40px), calc(100vw - 40px));
    box-shadow: ${theme.effect.shadow.xl};
    border-radius: ${theme.radius.rounded};
    overflow-y: auto;
    background-color: ${theme.color.bg.elevated.default};

    ${theme.media.md`
      width: min(calc(100vh - 80px), calc(100vw - 80px));
    `}
  `,
);

const ModalContainer = styled(BaseModal)(
  ({ theme }) => css`
    --modal-spacing: 16px;

    display: grid;
    position: relative;
    padding: var(--modal-spacing);
    gap: var(--modal-spacing);

    ${theme.media.sm`
      --modal-spacing: 24px;
    `}
  `,
);

const ModalIconButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const Content = styled.div`
  /* Compensate for cut off elements due to overflow container */
  --modal-spacing-negative: calc(var(--modal-spacing) * -1);

  margin-block: 0 var(--modal-spacing-negative);
  margin-inline: var(--modal-spacing-negative);
  padding-block: 0 var(--modal-spacing);
  padding-inline: var(--modal-spacing);
  overflow-x: auto;

  ${ButtonBar} {
    margin-top: 24px;
  }
`;

const Description = styled.p(
  ({ theme }) => css`
    color: ${theme.color.fg.muted};
    margin: 0;
  `,
);

const Title = styled.h3`
  margin: 0;
`;

const IconContainer = styled.div(
  ({ theme }) => css`
    width: 44px;
    aspect-ratio: 1;
    background: linear-gradient(
      225deg,
      ${theme.color.primary.brand[500]} 0%,
      ${theme.color.primary.brand[300]} 100%
    );
    box-shadow: ${theme.effect.shadow.xxs};
    border-radius: ${theme.radius.default};
    display: grid;
    place-items: center;
    margin-bottom: 20px;
  `,
);
