import { Select } from "@fiberplane/ui";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  selectActiveWorkspaceName,
  selectAvailableWorkspaces,
} from "../../../selectors";
import { useAppDispatch } from "../../../store";
import { switchActiveWorkspace } from "../../../thunks";
import { WorkspaceIcon } from "../../UI";

export function WorkspaceSwitch() {
  const { activeWorkspaceName, availableWorkspaces } = useSelector(
    selectWorkspaceSwitchState,
  );

  const dispatch = useAppDispatch();

  if (availableWorkspaces.length <= 1) {
    return null;
  }

  return (
    <Select
      name="activeWorkspaceName"
      onChange={(name) => dispatch(switchActiveWorkspace(name))}
      value={activeWorkspaceName}
      options={availableWorkspaces.map((workspace) => ({
        children: (
          <>
            <WorkspaceIcon name={workspace.name} />
            {workspace.displayName}
          </>
        ),
        value: workspace.name,
      }))}
    />
  );
}

const selectWorkspaceSwitchState = createSelector(
  [selectActiveWorkspaceName, selectAvailableWorkspaces],
  (activeWorkspaceName, availableWorkspaces) => ({
    activeWorkspaceName,
    availableWorkspaces,
  }),
);
