import { useEffect } from "react";

import { dispatch } from "../store";
import { redo, undo } from "../thunks";
import { isMac } from "../utils";

export function useUndoRedoKeyboardHandlers(readOnly: boolean) {
  useEffect(() => {
    if (!readOnly) {
      document.addEventListener("keydown", handleUndoRedo);

      return () => document.removeEventListener("keydown", handleUndoRedo);
    }
  }, [readOnly]);
}

function handleUndoRedo(event: KeyboardEvent) {
  if (
    (isMac ? event.metaKey : event.ctrlKey) &&
    event.key.toLowerCase() === "z"
  ) {
    const handled = dispatch(event.shiftKey ? redo() : undo());
    if (handled) {
      event.preventDefault();
    }
  }
}
