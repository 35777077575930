import { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectProxyList } from "../../../../../selectors";
import { dispatch } from "../../../../../store";
import { loadProxyList } from "../../../../../thunks";
import { RefreshButton, useShake } from "../../../../UI";
import {
  StyledTableHeadings,
  StyledTh,
  TableHeaderItem,
  useSortingParams,
} from "../shared";

export function ProxyListHeader(): JSX.Element {
  const { sortDirection, sortProperty, updateSorting } = useSortingParams();
  const { loading, error } = useSelector(selectProxyList);
  const { shake, shakeClassName } = useShake();

  useEffect(() => {
    if (error) {
      shake();
    }
  }, [shake, error]);

  return (
    <StyledTableHeadings>
      <StyledTh>
        <TableHeaderItem
          active={sortProperty === "name"}
          reverse={sortProperty === "name" && sortDirection === "descending"}
          onClick={() =>
            updateSorting(
              "name",
              sortProperty === "name" && sortDirection === "ascending"
                ? "descending"
                : "ascending",
            )
          }
        >
          Name
        </TableHeaderItem>
      </StyledTh>
      <StyledTh>
        <TableHeaderItem
          active={sortProperty === "status"}
          reverse={sortProperty === "status" && sortDirection === "descending"}
          onClick={() =>
            updateSorting(
              "status",
              sortProperty === "status" && sortDirection === "ascending"
                ? "descending"
                : "ascending",
            )
          }
        >
          Status
        </TableHeaderItem>
      </StyledTh>
      <StyledTh size="24px">
        <RefreshButton
          busy={loading}
          className={shakeClassName}
          onClick={() => dispatch(loadProxyList())}
        />
      </StyledTh>
    </StyledTableHeadings>
  );
}
