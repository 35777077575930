import {
  ModalErrorHint,
  ModalLabel,
  ModalLabelText,
  ModalTextInput,
} from "../../../UI";
import {
  type ValidationStatus,
  ValidationStatusIcon,
} from "../ValidationStatusIcon";

type Props = {
  autoFocus?: boolean;
  label?: string;
  name: string;
  onChange: (value: string) => void;
  onKeyDown: (event: React.KeyboardEvent) => void;
  placeholder?: string;
  required: boolean;
  validationStatus: ValidationStatus;
  value?: unknown;
};

export function TextField({
  autoFocus,
  label,
  name,
  onChange,
  onKeyDown,
  placeholder,
  required,
  validationStatus,
  value,
}: Props): JSX.Element {
  const stringValue = value == null ? "" : `${value}`;
  const showValidationError =
    required && validationStatus === "invalid" && !stringValue;

  return (
    <ModalLabel>
      {label && <ModalLabelText>{label}</ModalLabelText>}
      <ModalTextInput
        autoFocus={autoFocus}
        data-config-field={name}
        onChange={(event) => onChange(event.target.value)}
        aria-invalid={showValidationError}
        trailingIcon={
          showValidationError
            ? () => <ValidationStatusIcon status={validationStatus} />
            : undefined
        }
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        value={stringValue}
      />
      {showValidationError && (
        <ModalErrorHint>This is a required field</ModalErrorHint>
      )}
    </ModalLabel>
  );
}
