import { charIndex } from "../../../../utils";
import {
  DASH_CHAR_CODE,
  NEWLINE_CHAR_CODE,
  SPACE_CHAR_CODE,
} from "../constants";
import { getOffsetForCoordinatesInternal } from "./getOffsetForCoordinatesInternal";
import { getNodeAndIndexForOffset } from "./nodes";

export type CursorCoordinates = { x: number; y: number };

/**
 * Returns the center coordinates of a cursor rendered in the given element at
 * the given offset.
 */
export function getCoordinatesForOffset(
  containerEl: HTMLElement,
  text: string,
  offset: number,
): CursorCoordinates | null {
  let index: number;
  let lineHeight: number;
  try {
    index = charIndex(text, offset);
    lineHeight = getLineHeightForContainer(containerEl);
  } catch (error) {
    console.warn(
      `Cannot determine index for offset ${offset} in text "${text}": ${error}`,
    );
    return null;
  }

  const previousCharCode = index > 0 && text.charCodeAt(index - 1);

  // Firefox has an issue (at least on Linux) that causes it to render the
  // cursor after a newline at the end of the previous line, instead of on the
  // new line.
  if (previousCharCode === NEWLINE_CHAR_CODE) {
    const coordinates = getCoordinatesForOffset(containerEl, text, offset - 1);

    const { paddingLeft } = getComputedStyle(containerEl);
    const x = Number.parseInt(paddingLeft.split("px")[0] ?? "0", 10);

    return coordinates && { x, y: coordinates.y + lineHeight };
  }

  const nodeAndIndex = getNodeAndIndexForOffset(containerEl, text, offset);
  if (!nodeAndIndex) {
    return null;
  }

  const { top: containerTop, left: containerLeft } =
    containerEl.getBoundingClientRect();

  let coordinates: CursorCoordinates;
  const [node, indexWithinNode] = nodeAndIndex;
  if (isElement(node)) {
    const { top, left, bottom } = node.getBoundingClientRect();

    coordinates = {
      x: left - containerLeft,
      y: (top + bottom) / 2 - containerTop,
    };
  } else {
    const range = document.createRange();
    range.setStart(node, indexWithinNode);
    range.setEnd(node, indexWithinNode);

    const { top, left, bottom, right } = range.getBoundingClientRect();

    coordinates = {
      x: (left + right) / 2 - containerLeft,
      y: (top + bottom) / 2 - containerTop,
    };
  }

  // Browsers often want to render cursors on the end of the line in the
  // presence of a line wrap, even though we prefer it to render at the start
  // of the new line.
  if (
    (previousCharCode === SPACE_CHAR_CODE ||
      previousCharCode === DASH_CHAR_CODE) &&
    index < text.length &&
    text.charCodeAt(index) !== NEWLINE_CHAR_CODE
  ) {
    const coordinatesForNextOffset = getCoordinatesForOffset(
      containerEl,
      text,
      offset + 1,
    );
    const lineWrapped =
      coordinatesForNextOffset &&
      coordinatesForNextOffset.y > coordinates.y + lineHeight / 2;
    if (lineWrapped) {
      // FIXME: with ah x value of 0, it is assumed that the containerEl doesn't have a padding
      return { x: 0, y: coordinatesForNextOffset.y };
    }
  }

  return coordinates;
}

/**
 * Returns the active line height for the given container.
 *
 * Throws if the line height cannot be determined.
 */
export function getLineHeightForContainer(containerEl: HTMLElement): number {
  const { lineHeight } = getComputedStyle(containerEl);
  if (!lineHeight.endsWith("px")) {
    throw new Error(
      `Can only determine line height defined in pixels (was: ${lineHeight})`,
    );
  }

  return Number.parseInt(lineHeight.slice(0, -2), 10);
}

/**
 * Returns the offset in the text for the given coordinates.
 *
 * See [Measuring coordinates](../../../../../docs/EDITOR.md).
 */
export function getOffsetForCoordinates(
  containerEl: HTMLElement,
  text: string,
  coords: CursorCoordinates,
): number {
  return getOffsetForCoordinatesInternal(
    containerEl,
    text,
    coords,
    getCoordinatesForOffset,
  );
}

function isElement(node: Node): node is HTMLElement {
  return node.nodeType === Node.ELEMENT_NODE;
}
