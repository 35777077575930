import type { WorkspaceFrontMatterSchemas } from "../../../../types";

const SEVERITY_VALUES = ["critical", "error", "warning", "info"];

// This is the hardcoded default front matter schema
// The commented out code represents possible schema for a post mortem
// For demoing purposes (for the client that is very much interested in front-matter).
export const DEFAULT_FRONT_MATTER_SCHEMAS: WorkspaceFrontMatterSchemas = {
  default: [
    // "Post mortem": [
    {
      key: "status",
      schema: {
        iconName: "spinner",
        type: "string",
        displayName: "Status",
        allowExtraValues: false,
        multiple: false,
        options: ["started", "ongoing", "resolved"],
      },
    },
    //   {
    //     key: "startedAt",
    //     schema: {
    //       type: "date_time",
    //       displayName: "Started at",
    //     },
    //   },
    //   {
    //     key: "endedAt",
    //     schema: {
    //       type: "date_time",
    //       displayName: "Ended at",
    //     },
    //   },
    //   {
    //     key: "GmvLoss",
    //     schema: {
    //       type: "number",
    //       displayName: "GMV loss",
    //       allowExtraValues: false,
    //       suffix: "EUR",
    //     },
    //   },
    //   {
    //     key: "EbitLoss",
    //     schema: {
    //       type: "number",
    //       displayName: "EBIT loss",
    //       allowExtraValues: false,
    //       suffix: "EUR",
    //     },
    //   },
    //   {
    //     key: "affectedSLOs",
    //     schema: {
    //       type: "string",
    //       displayName: "Affected SLO's",
    //       allowExtraValues: false,
    //       multiple: true,
    //       options: ["availability", "latency", "throughput"],
    //     },
    //   },
    //   {
    //     key: "affectedCustomers",
    //     schema: {
    //       type: "number",
    //       displayName: "Affected customers",
    //       allowExtraValues: false,
    //       suffix: "%",
    //     },
    //   },
    //   {
    //     key: "affectedEmployees",
    //     schema: {
    //       type: "number",
    //       displayName: "Affected employees",
    //       allowExtraValues: false,
    //       suffix: "%",
    //     },
    //   },
    //   {
    //     key: "businessImpact",
    //     schema: {
    //       type: "string",
    //       displayName: "Business impact",
    //       allowExtraValues: false,
    //     },
    //   },
    // ],
    // default: [
    {
      key: "commander",
      schema: {
        type: "user",
        displayName: "Commander",
      },
    },
    {
      key: "severity",
      schema: {
        type: "string",
        options: SEVERITY_VALUES,
        displayName: "Severity",
        allowExtraValues: false,
        iconName: "fire",
      },
    },
    {
      key: "github_pull_request",
      schema: {
        type: "github_pull_request",
        displayName: "GitHub pull request",
      },
    },
    // {
    //   // A front-matter field that allows multiple users to be selected
    //   key: "participants",
    //   schema: {
    //     type: "user",
    //     displayName: "Participants",
    //     multiple: true,
    //   },
    // },
  ],
};
