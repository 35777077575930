import {
  AnimatePresence,
  LayoutGroup,
  type TargetAndTransition,
  motion,
} from "framer-motion";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { styled } from "styled-components";

import {
  selectCommandMenuVariantType,
  selectHasActiveNotebook,
  selectHasActiveView,
  selectIsAuthenticated,
} from "../../../../selectors";
import type { CommandMenuVariant } from "../../../../state";
import { CommandMenuContext } from "../CommandMenu";
import { ActiveNotebookBreadcrumb } from "./ActiveNotebookBreadcrumb";
import { ActiveViewBreadcrumb } from "./ActiveViewBreadcrumb";
import { VariantBreadcrumb } from "./VariantBreadcrumb";

type BreadcrumbType =
  | null
  | CommandMenuVariant["type"]
  | "activeNotebook"
  | "activeView"
  | "unauthenticated";

export function Breadcrumb() {
  const type = useSelector(selectBreadcrumbType);
  const { focusInput } = useContext(CommandMenuContext);

  return (
    <LayoutGroup>
      <Container
        onClick={focusInput}
        animate={type ? "visible" : "hidden"}
        layout
      >
        <Padding>
          <AnimatePresence mode="wait">
            <SwitchedBreadcrumb type={type} />
          </AnimatePresence>
        </Padding>
      </Container>
    </LayoutGroup>
  );
}

type SwitchedBreadcrumbProps = {
  type: BreadcrumbType;
};

function SwitchedBreadcrumb({ type }: SwitchedBreadcrumbProps) {
  switch (type) {
    case "search":
      return <VariantBreadcrumb>Search notebooks</VariantBreadcrumb>;
    case "selectTemplate":
      return <VariantBreadcrumb>Notebook from template</VariantBreadcrumb>;
    case "activeNotebook":
      return <ActiveNotebookBreadcrumb />;
    case "activeView":
      return <ActiveViewBreadcrumb />;
    default:
      return null;
  }
}

const selectBreadcrumbType = createSelector(
  [
    selectCommandMenuVariantType,
    selectHasActiveNotebook,
    selectHasActiveView,
    selectIsAuthenticated,
  ],
  (
    variantType,
    hasActiveNotebook,
    hasActiveView,
    isAuthenticated,
  ): BreadcrumbType => {
    if (!isAuthenticated) {
      return "unauthenticated";
    }

    if (variantType !== "none") {
      return variantType;
    }

    if (hasActiveNotebook) {
      return "activeNotebook";
    }

    if (hasActiveView) {
      return "activeView";
    }

    return null;
  },
);

const Container = styled(motion.div)`
  grid-area: breadcrumb;
`;

const hidden: TargetAndTransition = {
  height: 0,
  transition: {
    when: "afterChildren",
  },
};

const visible: TargetAndTransition = {
  height: "auto",
  transition: {
    when: "beforeChildren",
  },
};

Container.defaultProps = {
  variants: {
    hidden,
    visible,
  },
};

const Padding = styled.div`
  padding: 24px 34px 0;
`;
