import { IconButton } from "@fiberplane/ui";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { hideSideMenu, showSideMenu } from "../../../../actions";
import type { RootState } from "../../../../state";
import { useAppDispatch } from "../../../../store";

export const CopilotToggle = () => {
  const isSideMenuOpen = useSelector(
    (state: RootState) => state.ui.sideMenuIsOpen,
  );
  const dispatch = useAppDispatch();
  return (
    <StyledIconButton
      data-testid="copilot-toggle"
      aria-label="Open Copilot"
      iconType="sparkle_gradient"
      buttonStyle="secondary"
      onClick={() => dispatch(isSideMenuOpen ? hideSideMenu() : showSideMenu())}
      $isActive={isSideMenuOpen}
    />
  );
};

const StyledIconButton = styled(IconButton)<{ $isActive: boolean }>`
  &:hover {
    background: linear-gradient(45deg, #4661EB 0%, #BB58ED 71.22%, #E352BE 100%);
    svg path {
      fill: ${({ theme }) => theme.color.bg.default};
    }
  }
  ${({ $isActive, theme }) =>
    $isActive
      ? css`
          background: ${theme.color.bg.hover};
      `
      : css`
          svg path {
            fill: currentColor;
          }
  `}
`;
