import { Button, Icon } from "@fiberplane/ui";
import {
  type Control,
  type FieldErrors,
  type UseFormHandleSubmit,
  useWatch,
} from "react-hook-form";
import { css, styled } from "styled-components";

import { useContext } from "react";
import type { Webhook } from "../../../../types";
import { ButtonBar, ModalContext } from "../../../UI";
import { EnabledField } from "./EnabledField";
import { EndpointField } from "./EndpointField";
import { EventsField } from "./EventsField";
import { SecretField } from "./SecretField";

type WebhookFormProps = {
  control: Control<Webhook>;
  errors: FieldErrors<Webhook>;
  onSubmit: ReturnType<UseFormHandleSubmit<Webhook>>;
};

export function WebhookForm({ control, errors, onSubmit }: WebhookFormProps) {
  const { requestClose } = useContext(ModalContext);
  const id = useWatch({ control, name: "id" });
  const isNew = !id;

  return (
    <>
      <StyledForm onSubmit={onSubmit}>
        {errors.root && (
          <FormErrorHint>
            <FormErrorIcon iconType="warning" />
            <FormErrorMessage>{errors.root.message}</FormErrorMessage>
          </FormErrorHint>
        )}

        <EnabledField control={control} />
        <EndpointField control={control} />

        {!isNew && <SecretField control={control} />}

        <EventsField
          options={[
            {
              value: "front_matter",
              label: "Frontmatter",
              description: "Changes, addition or removal of frontmatter",
            },
          ]}
          control={control}
        />
      </StyledForm>

      <ButtonBar>
        <Button type="button" buttonStyle="secondary" onClick={requestClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          buttonStyle="primary"
          onClick={() => {
            onSubmit()
              .then(() => {
                requestClose();
              })
              // Swallow error
              .catch(() => undefined);
          }}
        >
          {isNew ? "Add webhook" : "Save changes"}
        </Button>
      </ButtonBar>
    </>
  );
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FormErrorHint = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: 18px;
    padding: 8px 12px;
    color: ${theme.color.fg.default};
    border: 1px solid ${theme.colorDanger500};
    border-radius: ${theme.radius.default};
  `,
);

const FormErrorMessage = styled.span(
  ({ theme }) =>
    css`
      font: ${theme.fontStudioStrongRegularShortHand};
      letter-spacing: ${theme.fontStudioStrongRegularLetterSpacing};
    `,
);

const FormErrorIcon = styled(Icon)(
  ({ theme }) => css`
    color: ${theme.colorDanger500};
  `,
);
