import { z } from "zod";

const incidentCreatedTemplateName = z.string().min(1, "A template is required");

export const CreateReceiverFormState = z.object({
  incidentCreatedTemplateName,
  pagerDutyReceiverName: z
    .string()
    .min(1, "A name is required")
    .max(256)
    .regex(/^[a-z0-9]+$/, "Name needs to be lowercase alphanumeric"),
  // Secret on create is nullabale since it can be a value or null for not set
  secret: z.string().nullable(),
});

export type CreateReceiverFormState = z.infer<typeof CreateReceiverFormState>;

export const UpdateReceiverFormState = z.object({
  incidentCreatedTemplateName,
  // Secret on update is nullish since it can be null for not set, undefined if not updated and a value if updated
  secret: z.string().nullish(),
});

export type UpdateReceiverFormState = z.infer<typeof UpdateReceiverFormState>;
