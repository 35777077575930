import { AnimatePresence } from "framer-motion";
import type React from "react";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { css, styled } from "styled-components";

import type { MenuProps } from "./Menu";
import type { CloseReason } from "./MenuContext";
import { PositionedMenu, type PositionedMenuProps } from "./PositionedMenu";

export type ToggleMenuMethods = {
  open: () => void;
  close: () => void;
};

export type ToggleMenuProps = Omit<MenuProps, "onClose"> & {
  /**
   * The element that is used for opening and closing the menu.
   *
   * It also acts as anchor for the positioning of the menu.
   */
  toggleElement: (options: {
    anchorRef: React.RefObject<HTMLDivElement>;
    opened: boolean;
    disabled?: boolean;
  }) => React.ReactNode;

  offset?: PositionedMenuProps["offset"];

  onClose?: (info: { reason: CloseReason }) => void;

  placement?: PositionedMenuProps["placement"];

  absolutePositioned?: boolean;
  disabled?: boolean;
  "data-testid"?: string;
};

/**
 * A menu that is attached to a toggle element.
 *
 * The toggle is placed inside a container with event listeners, so you don't
 * need to implement the toggle logic anymore.
 */
export const ToggleMenu = forwardRef(function ToggleMenu(
  props: ToggleMenuProps,
  ref: React.Ref<ToggleMenuMethods>,
) {
  const {
    toggleElement,
    absolutePositioned,
    disabled = false,
    "data-testid": testId,
    ...menuProps
  } = props;

  const anchorRef = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState(false);

  const open = () => setOpen(true);
  const close = () => setOpen(false);
  useImperativeHandle(ref, () => ({ open, close }));

  function onClose(info: { reason: CloseReason }) {
    if (info.reason === "item_activated" || info.reason === "close_requested") {
      anchorRef.current?.focus();
    }

    props.onClose?.(info);

    // If the anchor doesn't exist we've probably already unmounted
    if (anchorRef.current) {
      close();
    }
  }

  function onClick(event: React.MouseEvent) {
    event.stopPropagation();
    setOpen(!isOpen);
  }

  function onKeyDown(event: React.KeyboardEvent) {
    if (event.key === " " || event.key === "Enter") {
      event.stopPropagation();
      event.preventDefault();
      setOpen(!isOpen);
    }
  }

  return (
    <>
      <ToggleContainer
        onClick={disabled ? undefined : onClick}
        onKeyDown={disabled ? undefined : onKeyDown}
        ref={anchorRef}
        absolutePositioned={absolutePositioned}
        data-testid={testId}
      >
        {toggleElement({ anchorRef, opened: isOpen, disabled })}
      </ToggleContainer>

      <AnimatePresence>
        {isOpen && (
          <PositionedMenu
            {...menuProps}
            element={anchorRef.current}
            onClose={onClose}
          />
        )}
      </AnimatePresence>
    </>
  );
});

const ToggleContainer = styled.div<{ absolutePositioned?: boolean }>`
  display: inline-flex;
  align-items: center;
  min-width: 0;
  ${(props) => props.absolutePositioned && toggleContainerStyling}
`;

const toggleContainerStyling = css`
  position: absolute;
  bottom: 3px;
  right: 6px;

  @media (min-width: 1024px) {
    position: unset;
    display: inline;
  }
`;
