import { useHandler } from "@fiberplane/hooks";
import {
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { styled } from "styled-components";

import { cancelEvent } from "@fiberplane/ui";
import {
  clearCommandMenuQuery,
  setCommandMenuSelectedIndex,
  setCommandMenuVariant,
} from "../../../actions";
import { useKeyboardHandlers } from "../../../hooks";
import {
  selectCommandMenuSelectedIndex,
  selectCommandMenuVariantType,
} from "../../../selectors";
import { dispatch } from "../../../store";
import type { Command } from "../../../types";
import { isMac, noop, track } from "../../../utils";
import { BaseModal, ModalContext, useShake } from "../../UI";
import { Breadcrumb } from "./Breadcrumb";
import { ResultList } from "./ResultList";
import { SearchBar } from "./SearchBar";
import { useSearch } from "./hooks";

type CommandMenuContextValue = {
  activateCommand: (command: Command) => void;
  focusInput: () => void;
};

export const CommandMenuContext = createContext<CommandMenuContextValue>({
  activateCommand: noop,
  focusInput: noop,
});

export const CommandMenu = memo(function CommandMenu() {
  const { requestClose } = useContext(ModalContext);

  useEffect(() => {
    track("CommandMenu", { action: "open" });

    return () => {
      track("CommandMenu", { action: "close" });
    };
  }, []);

  const inputRef = useRef<HTMLInputElement>(null);
  const { shake, shakeClassName } = useShake();

  const { selectedIndex, variantType } = useSelector(selectCommandMenuState);
  const { groupedResults, total } = useSearch();

  const selectedResult = useMemo(() => {
    const result = groupedResults.flatMap(([, results]) => results);
    const flattened = result.flat();
    return flattened[selectedIndex];
  }, [groupedResults, selectedIndex]);

  const activateCommand = (command: Command) => {
    command.onActivate();

    if (!command.keepOpenAfterActivation) {
      requestClose();
    }

    if (command.focusInputAfterActivation) {
      focusInput();
    }

    if (!command.keepQueryAfterActivation) {
      dispatch(clearCommandMenuQuery());
    }

    dispatch(setCommandMenuSelectedIndex(0));
  };

  const focusInput = useHandler(() => {
    if (document.activeElement !== inputRef.current) {
      inputRef.current?.focus();
    }
  });

  const handleArrowDown = (event: React.KeyboardEvent) => {
    const lastIndex = total - 1;
    const nextIndex = selectedIndex + 1;

    if (nextIndex > lastIndex) {
      shake();
      return true;
    }

    if (isMac ? event.metaKey : event.ctrlKey) {
      dispatch(setCommandMenuSelectedIndex(lastIndex));
    } else {
      dispatch(setCommandMenuSelectedIndex(nextIndex));
    }

    return true;
  };

  const handleArrowUp = (event: React.KeyboardEvent) => {
    if (selectedIndex === 0) {
      shake();
      return true;
    }

    if (isMac ? event.metaKey : event.ctrlKey) {
      dispatch(setCommandMenuSelectedIndex(0));
    } else {
      dispatch(setCommandMenuSelectedIndex(selectedIndex - 1));
    }

    return true;
  };

  const handleEnter = () => {
    if (selectedResult) {
      activateCommand(selectedResult.item);
    }

    return true;
  };

  const handleEscape = () => {
    if (variantType !== "none") {
      dispatch(setCommandMenuVariant({ type: "none" }));
      dispatch(setCommandMenuSelectedIndex(0));
      return true;
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case "ArrowDown":
        return handleArrowDown(event);
      case "ArrowUp":
        return handleArrowUp(event);
      case "Enter":
        return handleEnter();
      case "Escape":
        return handleEscape();
    }
  };

  const { onKeyDown, onKeyUp } = useKeyboardHandlers({
    onKeyDown(event: React.KeyboardEvent) {
      if (handleKeyDown(event)) {
        cancelEvent(event);
      }
    },
    onKeyUp: noop,
  });

  return (
    <StyledModal
      data-testid="command-menu"
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      className={shakeClassName}
    >
      <CommandMenuContext.Provider value={{ activateCommand, focusInput }}>
        <Breadcrumb />
        <SearchBar
          onFocusRequested={focusInput}
          ref={inputRef}
          selectedResult={selectedResult}
        />
        <ResultList groupedResults={groupedResults} total={total} />
      </CommandMenuContext.Provider>
    </StyledModal>
  );
});

CommandMenu.whyDidYouRender = true;

const selectCommandMenuState = createSelector(
  [selectCommandMenuSelectedIndex, selectCommandMenuVariantType],
  (selectedIndex, variantType) => ({ selectedIndex, variantType }),
);

const StyledModal = styled(BaseModal)`
  width: 100%;
  max-width: 728px;
  max-height: 568px;
  overflow-y: hidden;
  display: grid;
  grid:
    "breadcrumb" auto
    "header" auto
    "results" 1fr
    / 1fr;

  /*
   * Make sure the modal has a fixed position. The CommandMenu grows and shrinks
   * quite often, so this prevents jumping.
   */
  margin-top: 20vh;
  align-self: flex-start;
`;
