import { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { type DefaultTheme, css, styled } from "styled-components";

import { selectConsoleMessages } from "../../selectors";
import type { Severity } from "../../types";

type ColorSet = {
  color: string;
  background: string;
};

const getColorSetForSeverity = (
  severity: Severity,
  theme: DefaultTheme,
): ColorSet => {
  switch (severity) {
    case "notice":
      return {
        color: theme.color.fg.success,
        background: theme.color.bg.success,
      };

    case "warning":
      return {
        color: theme.color.fg.warning,
        background: theme.color.bg.warning,
      };

    case "error":
      return {
        color: theme.color.fg.danger,
        background: theme.color.bg.danger,
      };
  }
};

const Dot = styled.div<{ severity: Severity }>`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ severity, theme }) => {
    const colorSet = getColorSetForSeverity(severity, theme);

    return css`
      font: ${theme.font.body.xs.medium};
      color: ${colorSet.color};
      background: ${colorSet.background};
      border-radius: ${theme.radius.full};
    `;
  }}
`;

type FeedbackMessageCounterProps = {
  severity: Severity;
};

export const FeedbackMessageCounter = memo(function FeedbackMessageCounter({
  severity,
}: FeedbackMessageCounterProps): JSX.Element {
  const selectMessageCount = useMemo(
    () =>
      createSelector(
        [selectConsoleMessages],
        (messages) =>
          messages.filter((message) => message.severity === severity).length,
      ),
    [severity],
  );
  const numMessages = useSelector(selectMessageCount);

  return (
    <Dot severity={severity} data-testid={`${severity}-counter`}>
      {numMessages}
    </Dot>
  );
});
