import { AnimatePresence } from "framer-motion";

import type { TooltipState } from "../../../state";
import type { ShowTooltipFn } from "../../../types";
import { Tooltip } from "./Tooltip";
import { TooltipPortal } from "./TooltipPortal";

type Props = {
  showTooltip: ShowTooltipFn;
  tip: TooltipState | null;
};

export function TooltipContainer({ showTooltip, tip }: Props): JSX.Element {
  return (
    <TooltipPortal>
      <AnimatePresence>
        {tip && <Tooltip showTooltip={showTooltip} tip={tip} />}
      </AnimatePresence>
    </TooltipPortal>
  );
}
