import { styled } from "styled-components";

type Props = {
  children: React.ReactNode;
  hideFromScreenReader?: boolean;
  name?: string;
};

/**
 * Helper componenent for showing emoji in the UI
 *
 * @param hideFromScreenReader - If the emoji is purely decorative, use this to tell screen readers to ignore it (see: https://www.upyoura11y.com/images-icons-and-emojis/)
 */
export const Emoji = ({
  children,
  hideFromScreenReader = true,
  name,
}: Props) => {
  const ariaHidden = hideFromScreenReader ? "true" : undefined;
  const role = hideFromScreenReader ? undefined : "img";
  const label = hideFromScreenReader ? undefined : name;

  return (
    <EmojiWrapper aria-hidden={ariaHidden} role={role} aria-label={label}>
      {children}
    </EmojiWrapper>
  );
};

// Font-family declaration inspired by https://nolanlawson.com/2022/04/08/the-struggle-of-using-native-emoji-on-the-web/
const EmojiWrapper = styled.span`
  /* stylelint-disable-next-line */
  font-family:
    "Twemoji Mozilla",
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji",
    "EmojiOne Color",
    "Android Emoji",
    sans-serif;
`;
