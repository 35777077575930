import {
  CLIPPED_NOTEBOOK_ICON_ID,
  CLIPPED_ROCKET_ICON_ID,
  CLIPPED_SCISSORS_ICON_ID,
  CLIPPED_VIEWS_ICON_ID,
  ClippedNotebookIcon,
  ClippedRocketIcon,
  ClippedScissorsIcon,
  ClippedViewsIcon,
} from "./clippedIcons";

export const CLIPPED_ICON_MAP = {
  notebook: {
    component: ClippedNotebookIcon,
    iconId: CLIPPED_NOTEBOOK_ICON_ID,
  },
  rocket: {
    component: ClippedRocketIcon,
    iconId: CLIPPED_ROCKET_ICON_ID,
  },
  scissors: {
    component: ClippedScissorsIcon,
    iconId: CLIPPED_SCISSORS_ICON_ID,
  },
  templates: {
    component: ClippedScissorsIcon,
    iconId: CLIPPED_SCISSORS_ICON_ID,
  },
  views: {
    component: ClippedViewsIcon,
    iconId: CLIPPED_VIEWS_ICON_ID,
  },
};
