import {
  type StackingType,
  type TickFormattersFactory,
  getFormatterForAxis,
} from "@fiberplane/charts";
import { useMemo } from "react";

export function useTickFormatters(stackingType: StackingType) {
  return useMemo(
    (): TickFormattersFactory => (xAxis, yAxis) => ({
      xFormatter: getFormatterForAxis(xAxis, "time"),
      yFormatter:
        stackingType === "percentage"
          ? getFormatterForAxis(yAxis, "percentage")
          : getFormatterForAxis(yAxis, "scientific"),
    }),
    [stackingType],
  );
}

/**
 * A version of `useTickFormatters` that forces the x-axis to display `HH:MM` instead of `HH:MM:SS`
 */
export function useTimeTickFormatterForceMinutesHack(
  stackingType: StackingType,
) {
  return useMemo(
    (): TickFormattersFactory => (_, yAxis) => ({
      xFormatter: (value: number) => {
        const date = new Date(value * 1000);
        if (Number.isNaN(date.getTime())) {
          return "-";
        }
        return `${date.getUTCHours()}:${date
          .getUTCMinutes()
          .toString()
          .padStart(2, "0")}`;
      },
      yFormatter:
        stackingType === "percentage"
          ? getFormatterForAxis(yAxis, "percentage")
          : getFormatterForAxis(yAxis, "scientific"),
    }),
    [stackingType],
  );
}
