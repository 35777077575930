import { forwardRef } from "react";
import { ItemSelector, type ItemSelectorHandle } from "../../shared";

type Props = {
  placeholder: string;
  readOnly?: boolean;
  focused: boolean;
  colorKey?: string;
  hideClearButton?: boolean;
  openContextMenu: (value: string | undefined) => void;
  onFocus?: () => void;
  onClear?: () => void;
  selection?: string;
};

export type StringSelectorItemHandle = ItemSelectorHandle;

export const StringSelectorItem = forwardRef<StringSelectorItemHandle, Props>(
  function StringSelectorItem(props: Props, ref) {
    const {
      focused,
      openContextMenu,
      placeholder,
      readOnly,
      onClear,
      onFocus,
      colorKey,
      selection,
      hideClearButton,
    } = props;
    return (
      <ItemSelector<string>
        getId={getValue}
        getValue={getValue}
        renderValue={getValue}
        placeholder={placeholder}
        colorKey={colorKey}
        ref={ref}
        focused={focused}
        readOnly={readOnly}
        hideClearButton={hideClearButton}
        openContextMenu={openContextMenu}
        onFocus={onFocus}
        onClear={onClear}
        selection={selection}
      />
    );
  },
);

function getValue(value: string) {
  return value;
}
