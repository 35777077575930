import {
  type AppAction,
  CLEAR_AUTHENTICATION,
  STORE_AUTH_TOKEN,
} from "../actions";
import type { AuthenticationState } from "../state";

export const initialState: AuthenticationState = {
  token: "",
};

export function authenticationReducer(
  state = initialState,
  action: AppAction,
): AuthenticationState {
  switch (action.type) {
    case CLEAR_AUTHENTICATION:
      return initialState;

    case STORE_AUTH_TOKEN:
      return { ...state, token: action.payload };

    default:
      return state;
  }
}
