import { useHandler } from "@fiberplane/hooks";
import { styled } from "styled-components";

import type { NotebookFocus } from "../../../../../types";
import { isNumberAsString } from "../../../../../utils";
import { useFrontMatterValue } from "../../hooks";
import { FrontMatterEditor } from "../FrontMatterEditor";

type Props = Readonly<{
  id: string;
  onChange: (
    value: number | undefined,
    options: { focus: NotebookFocus },
  ) => void;
  readOnly?: boolean;
  prefix?: string;
  suffix?: string;
}>;

export function FieldNumber({ id, onChange, readOnly, prefix, suffix }: Props) {
  const value = useFrontMatterValue(id, isNumberAsString);
  const handleChange = useHandler((value: string, focus: NotebookFocus) => {
    if (value === "") {
      onChange(undefined, { focus });
      return;
    }

    // Convert to number
    const number = Number(value);
    if (Number.isNaN(number)) {
      return;
    }

    onChange(number, { focus });
  });

  return (
    <Container>
      {prefix && <span>{prefix}</span>}
      <NumberEditor
        onChange={handleChange}
        placeholder={value ? undefined : "Empty"}
        id={id}
        readOnly={readOnly}
        value={value?.toString() ?? ""}
        validator={isEmptyOrNumberAsString}
        singleLine
      />
      {suffix && <span>{suffix}</span>}
    </Container>
  );
}

function isEmptyOrNumberAsString(value: string) {
  return value === "" || isNumberAsString(value);
}

const Container = styled.div`
  grid-area: value;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 100%;
`;

const NumberEditor = styled(FrontMatterEditor)`
  width: fit-content;
  flex: 0 1 auto;

  &:focus {
    flex: 1;
  }
`;
