import type { DataSource, SupportedQueryType } from "../types";

export const ADD_DATA_SOURCE_ACTION = "dataSources/list/add";
export const FAIL_LOADING_DATA_SOURCE_LIST = "dataSources/list/failLoading";
export const FINISH_LOADING_DATA_SOURCE_LIST = "dataSources/list/finishLoading";
export const SET_SUPPORTED_QUERY_TYPES =
  "dataSources/supportedQueryTypes/update";
export const START_LOADING_DATA_SOURCE_LIST = "dataSources/list/startLoading";

export type DataSourcesAction =
  | AddDataSourceAction
  | FailLoadingDataSourcesAction
  | FinishLoadingDataSourcesAction
  | SetSupportedQueryTypesAction
  | StartLoadingDataSourcesAction;

type AddDataSourceAction = {
  type: typeof ADD_DATA_SOURCE_ACTION;
  payload: DataSource;
};

type FailLoadingDataSourcesAction = {
  type: typeof FAIL_LOADING_DATA_SOURCE_LIST;
  payload: string;
};

type FinishLoadingDataSourcesAction = {
  type: typeof FINISH_LOADING_DATA_SOURCE_LIST;
  payload: Array<DataSource>;
};

type SetSupportedQueryTypesAction = {
  type: typeof SET_SUPPORTED_QUERY_TYPES;
  payload: {
    dataSourceKey: string;
    queryTypes: ReadonlyArray<SupportedQueryType>;
  };
};

type StartLoadingDataSourcesAction = {
  type: typeof START_LOADING_DATA_SOURCE_LIST;
};

export function addDataSource(dataSource: DataSource): DataSourcesAction {
  return {
    type: ADD_DATA_SOURCE_ACTION,
    payload: dataSource,
  };
}

export function setSupportedQueryTypes(
  dataSourceKey: string,
  queryTypes: ReadonlyArray<SupportedQueryType>,
): DataSourcesAction {
  return {
    type: SET_SUPPORTED_QUERY_TYPES,
    payload: { dataSourceKey, queryTypes },
  };
}
