import { Icon } from "@fiberplane/ui";
import { useSelector } from "react-redux";

import { useListSnippetsQuery } from "../../../../api";
import { selectActiveWorkspaceIdOrThrow } from "../../../../selectors";
import { dispatch } from "../../../../store";
import { expandSnippetToNotebook } from "../../../../thunks";
import type { FilterMenuItem } from "../../../UI";
import { IconContainer } from "./common";

export function useSlashCommandSnippetItems(): FilterMenuItem | null {
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const { data } = useListSnippetsQuery(
    { workspaceId },
    { refetchOnMountOrArgChange: true },
  );

  if (data && data.length > 0) {
    return {
      type: "item_group",
      id: "snippets",
      title: "Snippets",
      items: [
        {
          type: "item_with_submenu",
          id: "insert-snippet",
          title: "Insert snippet",
          iconLeft: (
            <IconContainer>
              <Icon iconType="star" />
            </IconContainer>
          ),
          items: data.map(({ name }) => ({
            type: "item_with_icons",
            id: name,
            title: name,
            onActivate: () => dispatch(expandSnippetToNotebook(name)),
          })),
        },
      ],
    };
  }

  return null;
}
