import { useHandler } from "@fiberplane/hooks";
import { Icon } from "@fiberplane/ui";
import { type Control, useController } from "react-hook-form";
import { css, styled } from "styled-components";

import type { NotebookSortFields, SortDirection, View } from "../../../types";
import { MenuItem, ToggleMenu } from "../../UI";

const SORT_OPTIONS: Array<SortOption> = [
  {
    selectedLabel: "Order by date edited",
    menuItemLabel: "Date edited",
    field: "updated_at",
    direction: "descending",
  },
  {
    selectedLabel: "Order from newest to oldest",
    menuItemLabel: "Newest to oldest",
    field: "created_at",
    direction: "descending",
  },
  {
    selectedLabel: "Order from oldest to newest",
    menuItemLabel: "Oldest to newest",
    field: "created_at",
    direction: "ascending",
  },
  {
    selectedLabel: "Order from A-Z",
    menuItemLabel: "A-Z",
    field: "title",
    direction: "ascending",
  },
  {
    selectedLabel: "Order from Z-A",
    menuItemLabel: "Z-A",
    field: "title",
    direction: "descending",
  },
];

type SortOption = {
  selectedLabel: string;
  menuItemLabel: string;
  field: NotebookSortFields;
  direction: SortDirection;
};

type Props = {
  canMutate: boolean;
  control: Control<View>;
};

export function SortToggleMenu({ canMutate, control }: Props) {
  const {
    field: { value: sortBy, onChange: onSortByChange },
  } = useController({ control, name: "sortBy" });
  const {
    field: { value: sortDirection, onChange: onSortDirectionChange },
  } = useController({ control, name: "sortDirection" });

  const onActivate = useHandler(
    (field: NotebookSortFields, direction: SortDirection) => {
      onSortByChange(field);
      onSortDirectionChange(direction);
    },
  );

  if (!canMutate) {
    return (
      <NotebookSortLabel canMutate={canMutate}>
        {formatSortLabel(sortBy, sortDirection)}
      </NotebookSortLabel>
    );
  }

  return (
    <ToggleMenu
      placement="bottom-start"
      offset={[0, 8]}
      toggleElement={() => (
        <NotebookSortLabel canMutate={canMutate}>
          {formatSortLabel(sortBy, sortDirection)}
          <NotebookSortIcon iconType="caret_down" />
        </NotebookSortLabel>
      )}
    >
      {SORT_OPTIONS.map(({ menuItemLabel: label, field, direction }) => {
        const key = `${field}:${direction}`;
        const isActive = field === sortBy && direction === sortDirection;

        return (
          <MenuItem
            key={key}
            id={key}
            title={label}
            onActivate={() => onActivate(field, direction)}
            iconRight={{
              icon: isActive ? <Icon iconType="check" /> : undefined,
            }}
          />
        );
      })}
    </ToggleMenu>
  );
}

const formatSortLabel = (
  field: NotebookSortFields | undefined = "updated_at",
  direction: SortDirection | undefined = "descending",
) => {
  const option =
    SORT_OPTIONS.find(
      (option) => option.field === field && option.direction === direction,
    ) ?? SORT_OPTIONS[0];

  return option?.selectedLabel;
};

const NotebookSortLabel = styled.span<{ canMutate: boolean }>(
  ({ theme, canMutate }) => css`
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${theme.colorBase500};
    font: ${theme.fontStudioStrongSmallShortHand};
    letter-spacing: ${theme.fontStudioStrongSmallLetterSpacing};

    ${
      canMutate &&
      css`
      &:hover {
        cursor: pointer;
      }
    `
    }
  `,
);

const NotebookSortIcon = styled(Icon)`
  width: 20px;
  height: 20px;
`;
