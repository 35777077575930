import { css, styled } from "styled-components";

import type { DataSourceConnectionStatus } from "../../../types";

type Props = {
  status: DataSourceConnectionStatus;
} & React.HTMLAttributes<HTMLDivElement>;

export function ConnectionStatusIcon({ status, ...props }: Props): JSX.Element {
  return (
    <StyledContainer {...props}>
      <StyledStatus status={status} />
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  flex-grow: 0;
  padding: 0;
  width: 6px;
  height: 6px;
`;

const StyledStatus = styled.div<Pick<Props, "status">>(
  ({ theme, status }) => css`
    flex-grow: 0;
    background-color: ${
      /* sc-custom "ignore this" */ status === "connected"
        ? theme.color.fg.success
        : theme.color.fg.danger
    };
    border-radius: ${theme.radius.full};
    width: 100%;
    height: 100%;
  `,
);
