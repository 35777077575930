import decamelize from "decamelize";
import { memo } from "react";
import { useSelector } from "react-redux";
import { css, styled } from "styled-components";

import { selectActiveView, selectNotebookTitle } from "../../../selectors";
import type { Command } from "../../../types";
import { NoResults, Result, StyledResultContainer } from "./Result";
import type { SearchResult } from "./hooks";

export const ResultList = memo(function ResultList({
  groupedResults,
  total,
}: SearchResult) {
  let index = -1;

  return (
    <Container data-function="command-menu-results">
      {total === 0 ? (
        <NoResults key="no-results" />
      ) : (
        groupedResults.map(([commandType, results]) => (
          <ResultGroup key={commandType}>
            <CategoryLabel categoryKey={commandType} />
            {results.map((result) => {
              index++;

              return (
                <Result key={result.item.key} index={index} result={result} />
              );
            })}
          </ResultGroup>
        ))
      )}
    </Container>
  );
});

ResultList.whyDidYouRender = true;

function CategoryLabel({ categoryKey }: { categoryKey: Command["type"] }) {
  const notebookTitle = useSelector(selectNotebookTitle);
  const view = useSelector(selectActiveView);

  switch (categoryKey) {
    case "activeNotebook":
      return (
        <ResultCategory>
          Notebook -{" "}
          <ResultCategoryResource>{notebookTitle}</ResultCategoryResource>
        </ResultCategory>
      );
    case "activeView":
      return (
        <ResultCategory>
          View
          {view && (
            <>
              {" - "}
              <ResultCategoryResource>
                {view.displayName}
              </ResultCategoryResource>
            </>
          )}
        </ResultCategory>
      );

    default:
      return (
        <ResultCategory>
          {categoryKey.slice(0, 1).toUpperCase() +
            decamelize(categoryKey.slice(1, categoryKey.length), " ")}
        </ResultCategory>
      );
  }
}

const Container = styled.nav`
  grid-area: results;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 16px 8px;

  ${StyledResultContainer} {
    padding: 4px 0;
  }
`;

const ResultGroup = styled.section`
  display: contents;
`;

const ResultCategory = styled.strong(
  ({ theme }) => css`
    padding: 4px 24px;

    font: ${theme.font.headings.h6};
    color: ${theme.color.fg.muted};
  `,
);

const ResultCategoryResource = styled.span(
  ({ theme }) =>
    css`
      color: ${theme.colorBase600};
      font-weight: ${theme.fontStudioHeadingsH5FontWeight};
    `,
);
