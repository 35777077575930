import { forwardRef, useLayoutEffect, useState } from "react";
import { styled } from "styled-components";

import { StyledLabel } from "./NotebookLabel";

type Props = React.HTMLAttributes<HTMLInputElement> & {
  value: string;
};

export const LabelsSearchInput = forwardRef(function LabelsSearchInput(
  { value, ...htmlAttributes }: Props,
  inputRef: React.Ref<HTMLInputElement>,
) {
  const [width, setWidth] = useState(0);

  const onClick = () => {
    if (isRefObject(inputRef)) {
      inputRef.current?.focus();
    }
  };

  useLayoutEffect(() => {
    const input = isRefObject(inputRef) && inputRef.current;
    if (input) {
      // Create an element to calculate text width with
      const element = document.createElement("canvas");
      const context = element.getContext("2d");

      if (context) {
        context.font = getComputedStyle(input).font;
        setWidth(context.measureText(value).width);
      }
    }
  }, [inputRef, value]);

  return (
    <Container onClick={onClick}>
      <Input
        data-label-input
        ref={inputRef}
        style={{ width }}
        value={value}
        {...htmlAttributes}
      />
    </Container>
  );
});

const isRefObject = (
  ref: React.Ref<HTMLInputElement | undefined>,
): ref is React.RefObject<HTMLInputElement> => {
  return typeof ref === "object";
};

const Container = styled(StyledLabel)`
  display: inline-flex;
  cursor: text;
`;

const Input = styled.input`
  border: none;
  background: transparent;
  outline: none;
  /* stylelint-disable scale-unlimited/declaration-strict-value */
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  /* stylelint-enable scale-unlimited/declaration-strict-value */
  flex: 1 0 auto;
  margin: 0;
  padding: 0;
  height: 16px; /* Make sure the label height doesn't change when editing */
  min-width: 16px;
  display: inline-flex;
`;
