import { css, styled } from "styled-components";

import type { Severity } from "../../../types";
import { Box } from "../Box";

export const ConsoleMessage = styled(Box)<{ severity?: Severity }>(
  ({ theme, severity }) => css`
  font: ${theme.font.code.regular};
  padding: 16px 20px;
  overflow: hidden;
  word-break: break-all;

  color: ${() => {
    switch (severity) {
      case "notice":
        return theme.color.fg.primary;
      case "warning":
        return theme.color.fg.warning;
      case "error":
        return theme.color.fg.danger;
      default:
        return "inherit";
    }
  }};
`,
);
