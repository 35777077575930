import { useEffect } from "react";

/**
 * Calls the given callback (if any) after the specified timeout.
 *
 * This effect can be used in conjunction with `useCallback()`. Changes to
 * the identity of the callback function will reset the timer, effectively
 * making this behave like a debounce if the identity keeps changing.
 *
 * Invocation of the callback may be canceled entirely when the component
 * unmounts.
 */
export function useTimeout(callback: (() => void) | undefined, delay: number) {
  useEffect(() => {
    if (!callback) {
      return;
    }

    const timeoutId = setTimeout(callback, delay);
    return () => clearTimeout(timeoutId);
  }, [delay, callback]);
}
