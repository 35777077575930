import {
  type Cell,
  type CellDrafts,
  type Formatting,
  type RichText,
  type ThreadItem,
  hasFormatting,
  isContentCell,
} from "../../types";
import { getQueryField } from "../queryData";
import {
  getAllRichTextFromTableCell,
  getRichTextFromTableCellField,
} from "../tables";

const noFormatting: Formatting = [];

/**
 * Returns the text of the cell or one of its fields, either from the `cell`
 * instance directly, or an external, injectable resource such as drafts or
 * thread items.
 *
 * **NOTE:** Please keep this function in sync with
 * {@link getRichCellTextUsingExternalSources()} below.
 */
export function getCellTextUsingExternalSources(
  cell: Cell,
  drafts: CellDrafts,
  threadItems: Array<ThreadItem>,
  field?: string,
): string | undefined {
  if (field) {
    switch (cell.type) {
      case "discussion": {
        const item = threadItems.find((item) => item.id === field);
        if (item?.type === "comment") {
          return item.content;
        } else {
          return drafts[field]?.text;
        }
      }

      case "provider":
        return getQueryField(cell.queryData, field);

      case "table":
        return getRichTextFromTableCellField(cell, field)?.text;
    }
  }

  if (cell.type === "table") {
    return getAllRichTextFromTableCell(cell)?.text;
  }

  return isContentCell(cell) ? cell.content : undefined;
}

/**
 * Returns the text and formatting of the cell or one of its fields, either from
 * the `cell` instance directly, or an external, injectable resource such as
 * drafts or thread items.
 *
 * **NOTE:** Please keep this function in sync with
 * {@link getCellTextUsingExternalSources()} above.
 */
export function getRichCellTextUsingExternalSources(
  cell: Cell,
  drafts: CellDrafts,
  threadItems: Array<ThreadItem>,
  field?: string,
): RichText | undefined {
  if (field) {
    switch (cell.type) {
      case "discussion": {
        const item = threadItems.find((item) => item.id === field);
        if (item?.type === "comment") {
          return { text: item.content, formatting: item.formatting };
        } else {
          const draft = drafts[field];
          return (
            draft && {
              text: draft.text,
              formatting: draft.formatting ?? noFormatting,
            }
          );
        }
      }

      case "provider":
        return {
          text: getQueryField(cell.queryData, field),
          formatting: noFormatting,
        };

      case "table":
        return getRichTextFromTableCellField(cell, field);
    }
  }

  if (cell.type === "table") {
    return getAllRichTextFromTableCell(cell);
  }

  return {
    text: isContentCell(cell) ? cell.content : "",
    formatting: (hasFormatting(cell) && cell.formatting) || noFormatting,
  };
}
