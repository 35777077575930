import { BadDataError, normalizeException } from "../../errors";
import { createAuthHeaders, informSessionExpiredListeners } from "./auth";

/**
 * Type representing raw request parameters.
 * @template RequestData - The type of request data.
 * @template ResponseData - The type of response data.
 */
type RawRequestParams<RequestData, ResponseData> = {
  /**
   * The request body.
   */
  body?: RequestData;
  /**
   * Custom request headers.
   */
  headers?: Record<string, string>;
  /**
   * Additional fetch options.
   */
  options?: RequestInit;
  /**
   * Response handler function.
   * @param {Response} response - The response object.
   * @returns {ResponseData | Promise<ResponseData>} The response data.
   */
  responseHandler: (response: Response) => ResponseData | Promise<ResponseData>;
  /**
   * The URL to send the request to.
   */
  url: string;
};

/**
 * Function for making a POST request with JSON data.
 * @param {RawRequestParams<RequestData, ResponseData, ResponseError>} params - The raw request parameters.
 * @returns {Promise<ResponseData>} A promise that resolves with the response data.
 * @template RequestData - The type of request data.
 * @template ResponseData - The type of response data.
 * @template ResponseError - The type of response error.
 */
export async function postJsonRequest<
  RequestData,
  ResponseData,
  ResponseError,
>({
  body,
  headers,
  options,
  responseHandler,
  url,
}: RawRequestParams<RequestData, ResponseData>): Promise<
  ResponseData | ResponseError
> {
  try {
    const response = await fetch(url, {
      ...options,
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        ...createAuthHeaders(),
        ...headers,
      },
      method: "POST",
    });

    if (response.ok) {
      return responseHandler(response);
    }

    throw response;
  } catch (exception) {
    return await handleException<ResponseError>(exception);
  }
}

/**
 * Handles request exceptions, normalizes them and handles session expiry.
 * @param {unknown} exception - The exception to handle.
 * @returns {Promise<ResponseError>} A promise that resolves with the response error.
 * @template ResponseError - The type of response error.
 */
async function handleException<ResponseError>(
  exception: unknown,
): Promise<ResponseError> {
  const error = normalizeException(exception);

  informSessionExpiredListeners(error);

  // Check if API returned any known error types and parse them as JSON
  if (error instanceof BadDataError) {
    if (error.response) {
      return await error.response.json();
    }
  }

  throw error;
}
