import { makeModalActiveSelector } from "../../selectors";
import type { Thunk } from "../../store";
import { hideModal, showModal } from "./showModalThunks";

export function showCommandMenu(): Thunk {
  return (dispatch) => {
    dispatch(showModal({ type: "commandMenu" }));
  };
}

export function hideCommandMenu(): Thunk {
  return (dispatch) => {
    dispatch(hideModal("commandMenu"));
  };
}

export function toggleCommandMenu(): Thunk {
  return (dispatch, getState) => {
    const commandMenuIsOpenSelector = makeModalActiveSelector("commandMenu");
    const commandMenuIsOpen = commandMenuIsOpenSelector(getState());

    dispatch(commandMenuIsOpen ? hideCommandMenu() : showCommandMenu());
  };
}
