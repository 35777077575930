import { useEffect, useRef } from "react";
import { css, styled } from "styled-components";

import { useOpen } from "@fiberplane/hooks";
import { AttachedPopup } from "@fiberplane/ui";
import { isTimestamp, timestampToString } from "../../../../../utils";
import { MenuContainer, TimestampPicker } from "../../../../UI";
import { useFocused, useFrontMatterValue } from "../../hooks";

type Props = Readonly<{
  id: string;
  onChange: (value: string) => void;
  name: string;
}>;

export function FieldDateTime(props: Props) {
  const { id, onChange } = props;
  const value = useFrontMatterValue(id, isTimestamp);
  const focused = useFocused(id);

  const anchorRef = useRef<HTMLButtonElement>(null);
  const { modalRef, opened, setOpened } = useOpen(anchorRef);

  useEffect(() => {
    if (opened && !focused) {
      setOpened(false);
    }

    if (focused && anchorRef.current) {
      anchorRef.current.focus();
    }
  }, [opened, setOpened, focused]);
  return (
    <>
      <DatePickerButton
        ref={anchorRef}
        className={opened ? "active" : ""}
        onClick={(event) => {
          event.preventDefault();
          setOpened(!opened);
        }}
        onKeyDown={(event) => {
          if (event.key === " ") {
            event.preventDefault();
            setOpened(true);
          }
        }}
      >
        {value ? (
          <span>{value}</span>
        ) : (
          <Placeholder>Select a date and time</Placeholder>
        )}
      </DatePickerButton>
      {opened && (
        <AttachedPopup element={anchorRef.current ?? null} placement="bottom">
          <MenuContainer ref={modalRef} data-testid="timestamp-picker">
            <TimestampPicker
              onChange={(newValue) => {
                // Reformat the value to ISO string
                // TimestampPicker.handleApply formatted the value to RFC3339 already
                const formattedValue = timestampToString(newValue);
                // When taking the value out from the store it's validated to match the ISO format
                // otherwise it won't be used as a value
                onChange(formattedValue);
                setOpened(false);
              }}
              value={value}
            />
          </MenuContainer>
        </AttachedPopup>
      )}
    </>
  );
}

/// Styles

const DatePickerButton = styled.button(
  ({ theme }) => `
  display: inline-block;
  margin-left: -6px;
  margin-right: 6px;
  padding: 8px 6px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: ${theme.borderRadius600};
  cursor: pointer;
  color: ${theme.color.fg.default};


  &.active,
  &:hover {
    background-color: ${theme.color.bg.hover};
    color: ${theme.color.fg.default};
  }

  &:focus {
    outline: none;
    border-color: ${theme.color.input.border.default};
  }
`,
);

const Placeholder = styled.span(
  ({ theme }) => css`
  color: ${theme.color.fg.subtle};
  font: ${theme.font.body.md.regular};
`,
);
