import { useHandler } from "@fiberplane/hooks";
import { Button, IconButton, Input, cancelEvent } from "@fiberplane/ui";
import { type FormEvent, useContext, useEffect, useState } from "react";
import useAsync from "react-use/lib/useAsync";
import { styled } from "styled-components";

import { BadDataError } from "../../errors";
import { dispatch } from "../../store";
import { addNotification, createProxyAndRefreshList } from "../../thunks";
import {
  ButtonBar,
  Modal,
  ModalContext,
  ModalErrorHint,
  ModalForm,
  ModalLabel,
  ModalLabelText,
  useShake,
} from "../UI";

export function CreateProxyModal(): JSX.Element {
  const { requestClose } = useContext(ModalContext);
  const [proxyName, setProxyName] = useState("");
  const [proxyNameError, setProxyNameError] = useState(false);
  const [submitName, setSubmitName] = useState("");
  const { shake, shakeClassName } = useShake();

  const validateForSubmit = () => {
    const trimmedName = proxyName.trim();
    if (trimmedName.length > 0) {
      setSubmitName(trimmedName);
    } else {
      setProxyNameError(true);
      setSubmitName("");
    }
  };

  const {
    value: proxy,
    error,
    loading: submitting,
  } = useAsync(
    () =>
      submitName
        ? dispatch(createProxyAndRefreshList({ name: submitName })).catch(
            async (error: unknown) => {
              throw error instanceof BadDataError && error.response
                ? await error.response.text()
                : (error as Error).message;
            },
          )
        : Promise.resolve(),
    [submitName],
  );

  const handleClickCopy = () => {
    navigator.clipboard.writeText(proxy?.token ?? "");
    dispatch(addNotification({ title: "Token copied to your clipboard" }));
  };

  const onSubmit = useHandler((event: FormEvent) => {
    cancelEvent(event);
    validateForSubmit();
  });

  useEffect(() => {
    if (error) {
      shake();
    }
  }, [error, shake]);

  return (
    <Modal
      title="Register FPD"
      description="Registering an FPD instance will create a token that your instance must use to authenticate with Fiberplane."
    >
      {proxy ? (
        <>
          <ModalForm onSubmit={onSubmit}>
            <InputContainer>
              <Input type="text" value={proxy.token} readOnly />
              <CopyIconButton
                iconType="copy"
                buttonStyle="tertiary-grey"
                onClick={handleClickCopy}
              />
            </InputContainer>
          </ModalForm>

          <ButtonBar>
            <Button onClick={requestClose} buttonStyle="secondary">
              Close
            </Button>
          </ButtonBar>
        </>
      ) : (
        <>
          <ModalForm onSubmit={onSubmit}>
            <ModalLabel>
              <ModalLabelText>Enter instance name</ModalLabelText>
              <Input
                type="text"
                autoFocus
                aria-invalid={proxyNameError ? true : undefined}
                data-source-name-input
                onChange={(event) => {
                  setProxyName(event.target.value);
                  setSubmitName("");
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter" && !submitting) {
                    validateForSubmit();
                  }
                }}
                placeholder="i.e. production or us-region-1"
                readOnly={submitting}
                value={proxyName}
              />
              {proxyNameError && (
                <ModalErrorHint>
                  Instance name is a required field
                </ModalErrorHint>
              )}
              {error && <ModalErrorHint>{error.toString()}</ModalErrorHint>}
            </ModalLabel>
          </ModalForm>

          <ButtonBar>
            <Button onClick={requestClose} buttonStyle="secondary">
              Cancel
            </Button>
            <Button
              className={shakeClassName}
              disabled={submitting}
              onClick={onSubmit}
            >
              Register FPD instance
            </Button>
          </ButtonBar>
        </>
      )}
    </Modal>
  );
}

const InputContainer = styled.div`
  display: grid;
  position: relative;
`;

const CopyIconButton = styled(IconButton)`
  position: absolute;
  /* Offset the input's border */
  right: 1px;
  top: 1px;
`;
