import {
  replaceCells as replaceCellsAction,
  withActiveNotebook,
} from "../actions";
import { snippetsApi } from "../api";
import { getApiErrorMessage } from "../errors";
import {
  selectActiveNotebook,
  selectActiveWorkspaceId,
  selectContextMenu,
  selectFocusedCell,
} from "../selectors";
import type { Thunk } from "../store";
import { uuid64 } from "../utils";
import { replaceCells, shouldChangeCurrentCell } from "./notebookCellThunks";
import { addNotification } from "./notificationsThunks";

export function expandSnippetToNotebook(snippetName: string): Thunk {
  return async (dispatch, getState) => {
    const state = getState();
    const workspaceId = selectActiveWorkspaceId(state);
    const activeCell = selectFocusedCell(state);
    const contextMenu = selectContextMenu(state);

    const defaultErrorMessage =
      "An error occurred when expanding these snippet(s). Please try again later.";

    if (!(workspaceId && activeCell && contextMenu)) {
      dispatch(
        addNotification({
          title: defaultErrorMessage,
          type: "danger",
        }),
      );

      return;
    }

    // Determine if the snippet expansion should replace the active cell or
    // insert the snippet after the active cell.
    const shouldChange = dispatch(shouldChangeCurrentCell(contextMenu));

    try {
      const cellsToInsert = await dispatch(
        snippetsApi.endpoints.expandSnippet.initiate({
          workspaceId,
          snippetName,
        }),
      ).unwrap();

      const cellsWithId = cellsToInsert.map((cell) => ({
        ...cell,
        id: uuid64(),
      }));

      if (shouldChange) {
        dispatch(
          replaceCells({
            newCells: cellsWithId,
            oldCellIds: [activeCell.id],
            closeMenu: contextMenu,
          }),
        );
        return;
      }

      const currentIndex =
        selectActiveNotebook(state).cellIds.indexOf(activeCell.id) + 1;

      dispatch(
        withActiveNotebook(
          replaceCellsAction({
            closeMenu: contextMenu,
            newCells: cellsWithId.map((cell, i) => ({
              cell,
              index: currentIndex + i,
            })),
          }),
        ),
      );
    } catch (error) {
      const errorMessage = await getApiErrorMessage(error, defaultErrorMessage);
      console.error(errorMessage);
    }
  };
}

export function deleteSnippet(snippetName: string): Thunk {
  return async (dispatch, getState) => {
    const workspaceId = selectActiveWorkspaceId(getState());

    const defaultErrorMessage =
      "An error occurred deleting your snippet. Please try again later.";

    if (!workspaceId) {
      dispatch(
        addNotification({
          title: defaultErrorMessage,
          type: "danger",
        }),
      );

      return;
    }

    try {
      await dispatch(
        snippetsApi.endpoints.deleteSnippet.initiate({
          snippetName,
          workspaceId,
        }),
      );
      // dispatch(fetchSnippets());
    } catch (error) {
      const errorMessage = await getApiErrorMessage(error, defaultErrorMessage);
      console.error(errorMessage);
    }
  };
}
