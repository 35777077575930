import { createSelector } from "reselect";

import { selectCell } from "../selectors";
import type { RootState } from "../state";
import type { ProviderCell } from "../types";
import {
  matchesMimeTypeWithEncoding,
  pick,
  shallowEqualArraysOfObjects,
} from "../utils";

/**
 * Creates a selector for all the data referenced by the given links that
 * might conform to the given MIME types.
 */
export function makeProviderCellDataSelector(
  providerCellId: string | null,
  links: Array<string>,
  mimeType: string,
): (
  state: RootState,
) => Array<Pick<ProviderCell, "intent" | "response"> | undefined> {
  const { cellLinks } = splitLinksByType(links, mimeType);

  const cellSelector = (state: RootState) =>
    cellLinks.map((url) => {
      const cellId = getCellId(url);
      return selectCell(
        state,
        providerCellId && cellId === "self" ? providerCellId : cellId,
      );
    });

  return createSelector(
    [cellSelector],
    (cells) =>
      cells.map((cell) =>
        cell?.type === "provider"
          ? pick(cell, "intent", "response")
          : undefined,
      ),
    { memoizeOptions: { resultEqualityCheck: shallowEqualArraysOfObjects } },
  );
}

/**
 * Returns the cell ID from the given `cell-data:` URL.
 *
 * Throws if the URL is not a `cell-data:` URL.
 */
function getCellId(url: string): string {
  if (!url.startsWith("cell-data:")) {
    throw new TypeError(`Expected cell-data URL, received: ${url}`);
  }

  const commaIndex = url.indexOf(",", 10);
  if (commaIndex === -1) {
    throw new TypeError(`Invalid cell-data URL, received: ${url}`);
  }

  return url.slice(commaIndex + 1);
}

/**
 * Returns `true` if the given URL is a `cell-data:` URL suitable for the given
 * MIME types.
 *
 * Returns `false` otherwise.
 */
function isCellLinkForMimeType(url: string, mimeType: string): boolean {
  if (!url.startsWith("cell-data:")) {
    return false;
  }

  const commaIndex = url.indexOf(",", 10);
  if (commaIndex === -1) {
    console.warn(`Invalid cell-data URL: ${url}`);
    return false;
  }

  return url.slice(10, commaIndex) === mimeType;
}

/**
 * Returns `true` if the given URL is a `data:` URL suitable for the given
 * MIME type.
 *
 * Returns `false` otherwise.
 */
function isDataLinkForMimeType(url: string, mimeType: string): boolean {
  if (!url.startsWith("data:")) {
    return false;
  }

  const commaIndex = url.indexOf(",", 5);
  if (commaIndex === -1) {
    console.warn(`Invalid data URL: ${url}`);
    return false;
  }

  let urlMimeType = url.slice(5, commaIndex);
  if (urlMimeType.endsWith(";base64")) {
    urlMimeType = urlMimeType.slice(0, -7);
  }

  return matchesMimeTypeWithEncoding(urlMimeType, mimeType);
}

type LinkOrder = Array<[LinkType, number]>;

type LinkType = "cell" | "link" | "null";

/**
 * Divide the given links into two sets:
 * - A list of applicable links to (provider) cells.
 * - A list of applicable links with embedded data segments.
 */
function splitLinksByType(links: Array<string>, mimeType: string) {
  const cellLinks = [];
  const dataLinks = [];

  // We keep track of the link order to be able to return the result array
  // in the same order as the input links.
  const linkOrder: LinkOrder = [];

  for (const url of links) {
    if (isCellLinkForMimeType(url, mimeType)) {
      linkOrder.push(["cell", cellLinks.length]);
      cellLinks.push(url);
    } else if (isDataLinkForMimeType(url, mimeType)) {
      linkOrder.push(["link", cellLinks.length]);
      dataLinks.push(url);
    } else {
      console.warn(`Ignoring non-matching URL from dataLinks: ${url}`);
      linkOrder.push(["null", 0]);
    }
  }

  return { cellLinks, dataLinks, linkOrder };
}
