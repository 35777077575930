import type { CropPosition } from "./types";

type UpdatePositionAction = {
  type: "UPDATE_POSITION";
  payload: Omit<CropPosition, "scale">;
};

type UpdateAction = {
  type: "UPDATE";
  payload: CropPosition;
};

type Actions = UpdateAction | UpdatePositionAction;

const INITIAL_STATE = {
  top: 0,
  left: 0,
  scale: 1,
};

export function cropPositionReducer(
  // biome-ignore lint/style/useDefaultParameterLast: this is a useful pattern for reducers
  state: CropPosition = INITIAL_STATE,
  action: Actions,
): CropPosition {
  switch (action.type) {
    case "UPDATE":
      return action.payload;

    case "UPDATE_POSITION":
      return {
        scale: state.scale,
        ...action.payload,
      };
  }
}
