import { useRef } from "react";

export function useNewlySortedFirst<T>(list: Array<T>): Array<T> {
  const latestList = useRef(list);

  // Get all newly added ids
  const newList = list.filter(
    (id) => latestList.current.includes(id) === false,
  );

  // Remove ids that no longer exist in the prop's ids list
  latestList.current = latestList.current.filter((id) => list.includes(id));

  // Add new ids to the front
  latestList.current = [...newList, ...latestList.current];

  return latestList.current;
}
