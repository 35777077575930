import type React from "react";
import { memo } from "react";

import { Icon, type IconType } from "@fiberplane/ui";
import {
  KeyValueCellContentKey,
  KeyValueCellContentRow,
  KeyValueCellContentValueContainer,
  KeyValueCellContentValueContent,
} from "../../../KeyValueCellContent";
import { useFocused } from "../../hooks";
import {
  FrontMatterFieldBase,
  FrontMatterRemoveButton,
} from "./FrontMatterFieldBase";

type Props = {
  field: string;
  cellType?: string;
  readOnly: boolean;
  handleClick?: () => void;
  onDelete: () => void;
  children: React.ReactNode;
  iconType: IconType;
  name: string;
};

/**
 * Cell/field containing front-matter for the component
 */
export const FrontMatterFieldWithLabel = memo(
  function FrontMatterFieldWithLabel({
    field,
    cellType = "front-matter", // NOTE - `front-matter` is not a recognizable cell type as of writing
    handleClick,
    readOnly,
    children,
    iconType,
    name,
    onDelete,
  }: Props) {
    const focused = useFocused(field);

    return (
      <FrontMatterFieldBase
        field={field}
        cellType={cellType}
        readOnly={readOnly}
        handleClick={handleClick}
      >
        <KeyValueCellContentRow>
          <KeyValueCellContentKey>
            <Icon iconType={iconType} />
            {name}
          </KeyValueCellContentKey>
          <KeyValueCellContentValueContainer>
            <KeyValueCellContentValueContent>
              {children}
            </KeyValueCellContentValueContent>
            <FrontMatterRemoveButton
              onClick={onDelete}
              hidden={!focused || readOnly === true}
            />
          </KeyValueCellContentValueContainer>
        </KeyValueCellContentRow>
      </FrontMatterFieldBase>
    );
  },
);
