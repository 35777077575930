import { hideContextMenu, resetLabelsEditor } from "../../../../actions";
import {
  makeModalActiveSelector,
  selectContextMenu,
  selectLabelsEditorExistingLabelKeys,
  selectLabelsEditorState,
} from "../../../../selectors";
import type { Thunk } from "../../../../store";
import { saveLabelsEditorDraft } from "../../../../thunks";
import {
  REPLACE_LABEL_WARNING_MODAL_KEY,
  validateLabelsEditor,
} from "../../../../utils";

export const onLabelsEditorBlur =
  (event: React.FocusEvent): Thunk =>
  (dispatch, getState) => {
    const state = getState();

    const { relatedTarget } = event;
    const isContextMenuItemOrToolbarButton =
      relatedTarget instanceof HTMLElement &&
      (relatedTarget.dataset.contextMenu ||
        relatedTarget.dataset.toolbarButton);
    const isModalActive = makeModalActiveSelector(
      REPLACE_LABEL_WARNING_MODAL_KEY,
    )(state);

    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
    if (!isContextMenuItemOrToolbarButton && !isModalActive) {
      dispatch(resetLabelsEditor("notebook"));

      const contextMenuInfo = selectContextMenu(state);
      if (contextMenuInfo?.menuType === "auto_suggest_labels") {
        dispatch(hideContextMenu());
      }
    }
  };

export const onLabelsEditorKeyDown =
  (event: React.KeyboardEvent): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const editorState = selectLabelsEditorState(state);
    if (!editorState) {
      return;
    }

    switch (event.key) {
      case "Enter": {
        const existingKeys = selectLabelsEditorExistingLabelKeys(state);
        const errors = validateLabelsEditor(editorState, existingKeys);
        if (errors.length === 0) {
          dispatch(saveLabelsEditorDraft());
          dispatch(hideContextMenu());
        }

        break;
      }

      case "Escape": {
        dispatch(resetLabelsEditor("notebook"));
        break;
      }
    }
  };
