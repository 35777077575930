import { useLocalStorage } from "@fiberplane/hooks";
import { type SyntheticEvent, useCallback, useMemo } from "react";
import type { ResizeCallbackData } from "react-resizable";

export const RESIZABLE_COPILOT_DIMENSIONS = {
  default: 500,
  min: 200,
  max: 800,
};

/**
 * Hook for setting the width of the copilot sidebar,
 * intended for use with react-resizable.
 *
 * @NOTE - Persists the selected width in localStorage
 * @HACK - Relies on "broadcasting" the change in width,
 *       which is picked up by the parent notebook container
 */
export function useCopilotContainerWidth({
  default: default_,
  min,
  max,
}: {
  default: number;
  min: number;
  max: number;
}) {
  const [storedWidth, setStoredWidth] = useLocalStorage<number | null>(
    "studio.copilotSidebarWidth",
    null,
  );

  const getClampedWidth = useCallback(
    (newWidth: number) => {
      return Math.min(max, Math.max(newWidth, min));
    },
    [min, max],
  );

  const width = useMemo<number>(() => {
    if (storedWidth) {
      return getClampedWidth(storedWidth);
    }

    return getClampedWidth(default_);
  }, [storedWidth, getClampedWidth, default_]);

  // NOTE - It could be good to debounce calls to localstorage in this handler,
  //        but I didn't notice any lag while testing.
  const handleResize = useCallback(
    (_event: SyntheticEvent, { size }: ResizeCallbackData) => {
      setStoredWidth(getClampedWidth(size.width));
    },
    [setStoredWidth, getClampedWidth],
  );

  return { width, handleResize };
}
