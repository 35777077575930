import { styled } from "styled-components";

import { dispatch } from "../../../../store";
import { updateQueryField } from "../../../../thunks";
import type { CellFocus } from "../../../../types";
import { FieldContainer } from "../../codeStyling";
import { useQueryFieldValidationError } from "./QueryError";

type Props = {
  cellId: string;
  field: string;
  focus: CellFocus;
  max?: number;
  min?: number;
  placeholder?: string;
  readOnly?: boolean;
  step?: number;
  value: string;
};

export function IntegerField({
  cellId,
  field,
  focus,
  max,
  min,
  placeholder,
  readOnly,
  step,
  value,
}: Props): JSX.Element {
  const { ref, errorOutput } = useQueryFieldValidationError<HTMLDivElement>(
    cellId,
    field,
  );

  return (
    <>
      <Container ref={ref}>
        <Input
          autoFocus={!!focus}
          type="number"
          max={max}
          min={min}
          onChange={(event) => {
            dispatch(updateQueryField(cellId, field, event.target.value));
          }}
          placeholder={placeholder}
          readOnly={readOnly}
          step={step}
          value={value}
        />
      </Container>
      {errorOutput}
    </>
  );
}

const Container = styled(FieldContainer)`
  background: #f3f3f3;
`;

const Input = styled.input`
  flex: 1;
`;
