import { Input } from "@fiberplane/ui";
import { css, styled } from "styled-components";

import { useFeature } from "../../../../../hooks";
import { type Feature, isFeature } from "../../../../../types";

export type FeatureProps = {
  feature: Feature;
  description: React.ReactNode;
  title: string;
};

export function FeatureCard({ feature, description, title }: FeatureProps) {
  const [hasFeature, setHasFeature] = useFeature(feature);

  const handleInputChange = ({
    target: { checked, id: feature },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const isValidFeature = isFeature(feature);
    if (!isValidFeature) {
      return;
    }

    setHasFeature(checked);
  };

  return (
    <CardContainer $isSelected={hasFeature}>
      <label htmlFor={feature}>{title}</label>
      <StyledInput
        type="checkbox"
        id={feature}
        checked={hasFeature}
        onChange={handleInputChange}
      />

      <p>{description}</p>
    </CardContainer>
  );
}

/*
  FIXME (Oscar): The radius tokens need to be updated, until then we use this as
  a temporary fix
*/
const StyledInput = styled(Input)(
  ({ theme: { radius } }) => css`
    grid-area: checkbox;

    & > div {
      border-radius: ${radius.lowest};
    }
  `,
);

const CardContainer = styled.div<{ $isSelected: boolean }>(
  ({ $isSelected, theme }) => css`
    display: grid;
    grid: "checkbox title" "checkbox description" 1fr / min-content 1fr;
    gap: 4px 16px;
    padding: 16px;
    border-radius: ${theme.radius.rounded};
    background-color: ${theme.color.bg.default};
    border: 1px solid ${theme.color.border.default};

    transition: border-color 0.2s ease-in-out;

    & > label {
      grid-area: title;
      align-self: center;
      cursor: pointer;
      line-height: 1;
      font: ${theme.font.headings.h5};
    }

    & > p {
      grid-area: description;
      font: ${theme.font.body.sm.regular};
      color: ${theme.color.fg.muted};
      margin: 0;
    }

    ${
      $isSelected &&
      css`
      border-color: ${theme.color.border.primary};
    `
    }
  `,
);
