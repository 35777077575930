import type { SyntheticEvent } from "react";

export function mergeHandlers<E extends SyntheticEvent>(
  handlers: Array<(event: E) => void>,
) {
  return (event: E) => {
    for (const handler of handlers) {
      handler(event);
    }
  };
}
