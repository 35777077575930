import { useMemo } from "react";
import { styled } from "styled-components";

import { Select } from "@fiberplane/ui";
import { dispatch } from "../../../../store";
import { updateQueryField } from "../../../../thunks";
import type { CellFocus } from "../../../../types";
import { Container, Label } from "../../../UI";

type Props = {
  cellId: string;
  field: string;
  focus: CellFocus;
  label?: string;
  options: Array<string>;
  placeholder?: string;
  readOnly?: boolean;
  value: string;
};

export function SelectField({
  cellId,
  field,
  label,
  options,
  placeholder,
  readOnly,
  value,
}: Props): JSX.Element {
  const selectOptions = useMemo(
    () => options.map((option) => ({ children: option, value: option })),
    [options],
  );

  return (
    <Container>
      {label ? <StyledLabel>{label}</StyledLabel> : undefined}
      <Select
        disabled={readOnly}
        dropdownPlacement="bottom-end"
        name={field ? `${cellId}__${field}` : cellId}
        onChange={(newValue) => {
          dispatch(updateQueryField(cellId, field, newValue));
        }}
        options={selectOptions}
        placeholder={placeholder}
        value={value}
      />
    </Container>
  );
}

const StyledLabel = styled(Label)`
  margin-right: 12px;
`;
