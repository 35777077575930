import { Button, Icon, Input } from "@fiberplane/ui";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTitle } from "react-use";
import { styled, useTheme } from "styled-components";

import { useListRecentNotebooksQuery } from "../../api";
import {
  selectActiveWorkspaceIdOrThrow,
  selectCanCreateNotebook,
} from "../../selectors";
import { dispatch } from "../../store";
import { createNotebookAndRedirect } from "../../thunks";
import { formatLabel, track } from "../../utils";
import {
  PageContainer,
  PageContentList,
  PageContentPlaceholder,
  PageContentPlaceholderCTA,
  PageContentPlaceholderDescription,
  PageContentPlaceholderText,
  PageHeader,
  PageTitle,
} from "../UI";
import { NotebookPageItem } from "./NotebookPageItem";

export function Notebooks() {
  useTitle("Notebooks");

  const shouldCallOutOnboarding = useShouldCallOutOnboarding();
  const [filterText, setFilterText] = useState(
    shouldCallOutOnboarding ? "onboarding" : "",
  );

  const canCreateNotebook = useSelector(selectCanCreateNotebook);
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const { data: recentNotebooks, isFetching } =
    useListRecentNotebooksQuery(workspaceId);

  const theme = useTheme();
  const textInputStyle: React.CSSProperties = {
    borderRadius: theme.borderRadius500,
  };

  const loweredFilterText = filterText.toLocaleLowerCase();
  const filtering = filterText !== "";

  const handleEscape = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape") {
      setFilterText("");
    }
  };

  useEffect(() => {
    if (filtering) {
      track("dashboard | filter recent notebooks");
    }
  }, [filtering]);

  const hasNotebooks = recentNotebooks && recentNotebooks.length > 0;

  return (
    <NotebooksPageContainer
      data-testid="notebooks-page"
      data-fetching={isFetching}
    >
      <NotebooksPageHeader>
        <PageTitle $noMargin loading={isFetching}>
          Notebooks
        </PageTitle>
        <NotebookButtons>
          {canCreateNotebook && (
            <Button onClick={() => dispatch(createNotebookAndRedirect())}>
              <Icon width="20" iconType="plus" />
              New Notebook
            </Button>
          )}
        </NotebookButtons>
        {hasNotebooks && (
          <NotebooksSearch>
            <StyledIcon width="20" iconType="search_duotone" />
            <StyledInput
              type="text"
              autoFocus={shouldCallOutOnboarding}
              onChange={(event) => setFilterText(event.target.value)}
              placeholder="Search Notebooks"
              value={filterText}
              onKeyDown={handleEscape}
              style={textInputStyle}
            />
            {shouldCallOutOnboarding && (
              <NotebooksOnboardingCallout>
                Check out the onboarding notebooks below!
              </NotebooksOnboardingCallout>
            )}
          </NotebooksSearch>
        )}
      </NotebooksPageHeader>
      <NotebookPageContentList>
        <AnimatePresence>
          {recentNotebooks
            ?.filter(
              ({ title, labels }) =>
                title.toLocaleLowerCase().includes(loweredFilterText) ||
                labels?.some((label) =>
                  formatLabel(label)
                    .toLocaleLowerCase()
                    .includes(loweredFilterText),
                ),
            )
            .map(({ id, ...props }) => (
              <NotebookPageItem key={id} id={id} {...props} />
            ))}

          {!(isFetching || hasNotebooks) && (
            <PageContentPlaceholder icon="notebook">
              <PageContentPlaceholderText>
                You currently have no Notebooks
              </PageContentPlaceholderText>
              {canCreateNotebook && (
                <>
                  <PageContentPlaceholderDescription>
                    Be the first one to create a notebook.
                  </PageContentPlaceholderDescription>
                  <PageContentPlaceholderCTA>
                    <Button
                      onClick={() => dispatch(createNotebookAndRedirect())}
                    >
                      New notebook
                    </Button>
                  </PageContentPlaceholderCTA>
                </>
              )}
            </PageContentPlaceholder>
          )}
        </AnimatePresence>
      </NotebookPageContentList>
    </NotebooksPageContainer>
  );
}

/**
 * Helper that consumes the `fromOnboarding` location state from the history
 *
 * We expect the link to this page from the onboarding Getting Started card to
 * set this state
 */
function useShouldCallOutOnboarding() {
  const location = useLocation();
  return location.state?.fromOnboarding === true;
}

const NotebooksPageHeader = styled(PageHeader)`
  margin-top: 40px;
  gap: 40px;
  grid:
    "title buttons" 1fr
    "search search" auto
    / 1fr;
`;

const StyledInput = styled(Input)`
  border: 0;
  border-radius: 0 !important;
  background: transparent;
  padding: 0;
  font: ${({ theme }) => theme.font.body.md.regular};

  &:focus, &:focus-visible {
    box-shadow: none;
    border: none;
  }


`;

const NotebooksSearch = styled.div`  
  grid-area: search;

  display: grid;
  grid-template-columns: 20px auto;
  align-items: center;
  grid-gap: 8px;

  border-bottom: 2px solid ${({ theme }) => theme.color.border.muted};;


  ${StyledInput} {
    width: 100%;
  }
`;

// TODO - FP-2855: Style this like a callout, and use it when `shouldCallOutOnboarding` is true
//        https://fiberplane.slack.com/archives/C04CRHP3NUQ/p1672750777795219
const NotebooksOnboardingCallout = styled.div`
  display: none;
`;

const NotebooksPageContainer = styled(PageContainer)`
  /* grid: "header" auto "switch" auto "content" 1fr / 1fr; */
`;

const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.color.fg.muted};
`;

const NotebookButtons = styled.div`
  grid-area: buttons;
  align-self: end;
`;

const NotebookPageContentList = styled(PageContentList)`
  /* HACK - Override margin hacks and row gap */
  margin: 0;
  row-gap: 0;
  `;
