import type { AppAction, CourierAction } from "../actions";
import { FiberKit, Sentry } from "../services";
import type { CourierState } from "../state";
import type { StateWithSideEffects } from "../types";

export const initialState: CourierState = {
  backoffStep: 0,
  connectionStatus: "idle",
};

export function courierReducer(
  state = initialState,
  action: AppAction,
): StateWithSideEffects<CourierState> {
  if (action.type.startsWith("courier/")) {
    try {
      const start = Date.now();

      const result = FiberKit.courierReducer(action as CourierAction);

      const timeSpent = Date.now() - start;
      if (timeSpent > 100) {
        Sentry.captureWarning(
          `Courier reducer took ${timeSpent}ms`,
          Sentry.hasSensitiveDataCollection() ? { action } : undefined,
        );
      }

      return result;
    } catch (error) {
      Sentry.captureError("FiberKit error while processing courier action", {
        action: Sentry.hasSensitiveDataCollection() ? action : undefined,
        error,
      });
    }
  }

  return { state };
}
