import { useHandler } from "@fiberplane/hooks";
import { useEffect } from "react";
import { type RegisterOptions, useForm } from "react-hook-form";

import { useCreateWorkspaceMutation } from "../api";
import { getApiErrorMessage, normalizeException } from "../errors";
import type { NewWorkspace, Workspace } from "../types";
import {
  WORKSPACE_DISPLAY_NAME_MAX_CHARACTERS,
  formatName,
  validateMaxLength,
  validateRequired,
  validateResourceName,
} from "../utils";

export function useCreateWorkspaceForm(
  onSuccess?: (workspace: Workspace) => void,
) {
  const [createWorkspace, { isLoading }] = useCreateWorkspaceMutation();

  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
    setValue,
    trigger,
    watch,
  } = useForm<NewWorkspace>({
    mode: "onChange",
  });

  const displayName = watch("displayName");
  useEffect(() => {
    if (displayName) {
      setValue("name", formatName(displayName));
      trigger("name");
    }
  }, [displayName, setValue, trigger]);

  const onSubmit = useHandler(async (newWorkspace: NewWorkspace) => {
    const result = await createWorkspace(newWorkspace);

    if ("error" in result) {
      const defaultErrorMessage =
        "An error has occurred while trying to create your workspace, please try again later.";
      const error = normalizeException(result.error);
      const message = await getApiErrorMessage(error, defaultErrorMessage);

      setError("root", { message });

      return;
    }

    onSuccess?.(result.data);
  });

  const displayNameOptions: RegisterOptions<NewWorkspace, "displayName"> = {
    validate: {
      required: (value) => validateRequired(value, "display name"),
      maxLength: (value) =>
        validateMaxLength(
          value,
          WORKSPACE_DISPLAY_NAME_MAX_CHARACTERS,
          "display name",
        ),
    },
  };

  const nameOptions: RegisterOptions<NewWorkspace, "name"> = {
    validate: {
      validateResourceName: (value) => validateResourceName(value, "name"),
      maxLength: (value) =>
        validateMaxLength(value, WORKSPACE_DISPLAY_NAME_MAX_CHARACTERS, "name"),
    },
  };

  return {
    displayNameOptions,
    errors,
    isLoading,
    nameOptions,
    onSubmit: handleSubmit(onSubmit),
    register,
  };
}
