import {
  type ForwardedRef,
  type ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { css, styled } from "styled-components";

import { cancelEvent } from "@fiberplane/ui";
import { FieldSelectionElement } from "../shared";

export type ItemSelectorProps<T> = Readonly<{
  readOnly?: boolean;
  focused?: boolean;
  /**
   * Placeholder text to show when the cell is not focused
   */
  placeholder: string;
  /**
   * Called to render the value of the option in options the list
   */
  renderValue: (value: T) => ReactNode;
  /**
   * get the value of the option to be used for filtering
   */
  getValue: (option: T) => string;

  /**
   * Return the value of the option to be used to uniquely identify the option
   * @returns
   */
  getId: (option: T) => string;
  colorKey?: string;
  /**
   * The currently selected value
   */
  selection: T | undefined;
  onFocus?: () => void;
  openContextMenu(value: string | undefined): void;
  onClear?: () => void;
  hideClearButton?: boolean;
}>;

export type ItemSelectorHandle = {
  focus: () => void;
};

/**
 * This is a generic component that can be used to show the selected element of a list of options.
 *
 * It has a forwarded ref that can be used to focus the component.
 */
function InnerItemSelector<T>(
  props: ItemSelectorProps<T>,
  forwardRef: ForwardedRef<ItemSelectorHandle>,
) {
  const {
    readOnly,
    focused = false,
    selection,
    openContextMenu,
    colorKey,
    getValue,
    renderValue,
    onFocus,
    placeholder,
    hideClearButton = false,
    onClear,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  useImperativeHandle(
    forwardRef,
    () => {
      return {
        focus: () => ref.current?.focus(),
      };
    },
    [],
  );

  useEffect(() => {
    if (focused) {
      ref.current?.focus();
    }
  }, [focused]);

  const onClick = () => {
    if (!focused) {
      onFocus?.();
    }

    openContextMenu(selection ? getValue(selection) : "");
  };

  const onKeyDown = readOnly
    ? undefined
    : (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === " " || event.key === "/") {
          openContextMenu(selection ? getValue(selection) : "");
          cancelEvent(event);
        }
      };

  return (
    <Container data-testid="option-selector">
      <FieldSelectionElement
        aria-disabled={readOnly}
        hideClearButton={hideClearButton}
        singleColumn={!selection}
        tabIndex={focused && !readOnly ? 0 : undefined}
        ref={ref}
        focused={focused}
        onClick={readOnly ? undefined : onClick}
        onKeyDown={readOnly ? undefined : onKeyDown}
        colorKey={colorKey}
        onClear={
          selection || onClear
            ? () => {
                if (onClear) {
                  onClear();
                }

                openContextMenu(undefined);
              }
            : undefined
        }
      >
        {selection ? (
          renderValue(selection)
        ) : (
          <Placeholder $focused={focused}>{placeholder}</Placeholder>
        )}
      </FieldSelectionElement>
    </Container>
  );
}

/**
 * This is a (forwarded) generic component that can be used to show the selected element of
 * a list of options.
 *
 * The forwarded API allows the parent component to call the focus method on the component.
 */
export const ItemSelector = forwardRef(InnerItemSelector) as <T>(
  props: ItemSelectorProps<T> & { ref?: ForwardedRef<ItemSelectorHandle> },
) => ReturnType<typeof InnerItemSelector>;

const Container = styled.div`
  display: flex;
`;

const Placeholder = styled.div<{ $focused: boolean }>(
  ({ theme, $focused }) => css`
  ${
    $focused
      ? css`
  color: inherit;
  `
      : css`
  color: ${theme.color.fg.subtle};
  padding-left: 0;
  `
  }
`,
);
