import { useHandler } from "@fiberplane/hooks";
import { css, styled } from "styled-components";

import { useMemo } from "react";
import { dispatch } from "../../../store";
import { focusCell } from "../../../thunks";
import type { SubscriberSession } from "../../../types";
import { getFocusCellId } from "../../../utils";
import type { ProfileAvatar } from "../../Avatar";
import { PresenceProfileAvatar } from "./PresenceProfileAvatar";

type PresenceIconProps = {
  session: SubscriberSession;
} & Omit<React.ComponentProps<typeof ProfileAvatar>, "userId">;

export function PresenceIcon({ session, ...restProps }: PresenceIconProps) {
  const iconState = useMemo(() => {
    const { focus, user } = session;

    return { hasFocus: focus && focus.type !== "none", user };
  }, [session]);

  const onClickHandler = useHandler(() => {
    const focusedCellId = getFocusCellId(session.focus);
    if (!focusedCellId) {
      return;
    }

    dispatch(focusCell({ cellId: focusedCellId, highlight: true }));
  });

  if (!iconState) {
    return null;
  }

  const { hasFocus, user } = iconState;

  return (
    <StyledPresenceIcon
      $hasFocus={hasFocus}
      name={user.name}
      userId={user.id}
      onClick={onClickHandler}
      {...restProps}
    />
  );
}

export const StyledPresenceIcon = styled(PresenceProfileAvatar)<{
  $hasFocus?: boolean;
}>(
  ({ $hasFocus }) =>
    $hasFocus &&
    css`
      transition: scale 0.1s ease-in-out;

      &:hover {
        scale: 1.1;
      }
    `,
);
