import { useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { push, replace } from "redux-first-history";

import { DEFAULT_NOTEBOOK_TITLE, ROUTES } from "../../constants";
import {
  selectActiveWorkspaceName,
  selectIsAuthenticated,
} from "../../selectors";
import { dispatch } from "../../store";
import { createNotebook } from "../../thunks";
import { createNotebookLink } from "../../utils";

export function NewNotebook() {
  const workspaceName = useSelector(selectActiveWorkspaceName);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(push(`${ROUTES.SignIn}?redirect=${ROUTES.NewNotebook}`));
    }
  }, [isAuthenticated]);

  useLayoutEffect(() => {
    async function createAndOpenNotebook() {
      if (workspaceName) {
        const id = await dispatch(createNotebook());
        dispatch(
          replace(
            createNotebookLink(workspaceName, id, DEFAULT_NOTEBOOK_TITLE),
          ),
        );
      }
    }

    createAndOpenNotebook();
  }, [workspaceName]);

  return null;
}
