import type { LabelsEditorState } from "../state";
import type { ClientLabelValidationError } from "../types";
import { formatLabel, parseLabel, validateLabel } from "./labels";

export const getLabelInputElement = () =>
  document.querySelector<HTMLInputElement>('[data-label-input="true"]');

export function isPristine(editorState?: LabelsEditorState): boolean {
  if (!editorState) {
    return false;
  }

  const { original, draft } = editorState;
  if (original) {
    return formatLabel(original) === draft;
  } else {
    const label = parseLabel(draft);
    return label.key === "";
  }
}

export const validateLabelsEditor = (
  editorState: LabelsEditorState | undefined,
  existingKeys: ReadonlyArray<string>,
): Array<ClientLabelValidationError> => {
  if (!editorState) {
    return ["empty_key"];
  }

  const filteredExistingKeys = existingKeys.filter((key) =>
    editorState.original ? key !== editorState.original.key : true,
  );

  return validateLabel(parseLabel(editorState.draft), filteredExistingKeys);
};
