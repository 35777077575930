import { Icon, cancelEvent } from "@fiberplane/ui";
import { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";

import { ADD_BUTTON_FIELD_ID } from "../../../../constants";
import { noop } from "../../../../utils";
import { FrontMatterFieldBase } from "../Field";
import { useFocused } from "../hooks";
import { AddButton } from "./AddButton";
import { FrontMatterMenu } from "./FrontMatterMenu";

type Props = {
  readOnly?: boolean;
};

const field = ADD_BUTTON_FIELD_ID;

export function AddFrontMatter({ readOnly = false }: Props) {
  const focused = useFocused(field);
  const containerRef = useRef<HTMLButtonElement | null>(null);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (focused && !opened && containerRef.current) {
      containerRef.current.focus();
    }
  }, [focused, opened]);

  return (
    <FrontMatterFieldBase
      cellType="front-matter-add"
      field={field}
      readOnly={readOnly}
    >
      <Root>
        <Container
          disabled={readOnly}
          onKeyDown={(event) => {
            if (event.key === "Enter" || event.key === " ") {
              cancelEvent(event);
              if (readOnly) {
                return;
              } else {
                setOpened(!opened);
              }
            }
          }}
          role="combobox"
          tabIndex={0}
        >
          <ButtonContainer>
            <AddButton
              active={opened}
              focused={focused}
              ref={containerRef}
              disabled={readOnly}
              data-testid="add-front-matter"
              onClick={readOnly ? noop : () => setOpened(!opened)}
              tabIndex={0}
            >
              <StyledIcon iconType="plus" />
              Add front-matter
            </AddButton>
          </ButtonContainer>
        </Container>
        <div role="listbox" style={{ position: "absolute" }}>
          {opened && (
            <FrontMatterMenu
              element={containerRef.current}
              placement="bottom-start"
              onClose={() => setOpened(false)}
            />
          )}
        </div>
      </Root>
    </FrontMatterFieldBase>
  );
}

// STYLES
const Root = styled.div`
  height: min-content;
  margin-top: 8px;
`;

const Container = styled.div<{ disabled?: boolean }>`
  align-content: center;
  align-items: center;
  color: ${(props) => (props.disabled ? "#1f2023" : "rgb(31, 32, 35)")};
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  height: 20px;
  width: 20px;
`;
