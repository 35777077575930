import {
  ADD_EVENT_EDITOR_LABEL,
  type AppAction,
  EVENTS_SET_ACTIVE_EVENT,
  EVENTS_SET_ACTIVE_EVENT_VALUE,
} from "../actions";
import type { EventsState } from "../state";

const draftEvent = {
  labels: {},
};

const initialState: EventsState = {
  activeEvent: { ...draftEvent },
};

export function eventsReducer(
  state: EventsState = initialState,
  action: AppAction,
): EventsState {
  const { activeEvent } = state;

  switch (action.type) {
    case EVENTS_SET_ACTIVE_EVENT:
      return {
        ...state,
        activeEvent: action.payload.event || { ...draftEvent },
      };

    case EVENTS_SET_ACTIVE_EVENT_VALUE:
      return {
        ...state,
        activeEvent: {
          ...activeEvent,
          [action.payload.key]: action.payload.value,
        },
      };

    case ADD_EVENT_EDITOR_LABEL:
      return {
        ...state,
        activeEvent: {
          ...activeEvent,
          labels: {
            ...activeEvent.labels,
            [action.payload.key]: action.payload.value,
          },
        },
      };

    default:
      return state;
  }
}
