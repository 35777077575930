import { Button } from "@fiberplane/ui";
import { useContext, useEffect } from "react";

import { track } from "../../utils";
import {
  CodeSample,
  GradientModal,
  GradientModalBodyRegularText,
  GradientModalHeading,
  ModalButtonBar,
  ModalContext,
} from "../UI";

const INSTALL_INSTRUCTIONS =
  "curl --proto '=https' --tlsv1.2 -sSf https://fp.dev/install.sh | sh";

export function InstallCliModal() {
  const { requestClose } = useContext(ModalContext);

  useEffect(() => {
    track("onboarding | cli instructions", { action: "open" });

    return () => {
      track("onboarding | cli instructions", { action: "close" });
    };
  }, []);

  return (
    <GradientModal
      illustration="fiberplane_logo"
      footer={
        <ModalButtonBar>
          <Button onClick={requestClose} buttonStyle="secondary">
            Close
          </Button>
        </ModalButtonBar>
      }
    >
      <GradientModalHeading>Install the Fiberplane CLI</GradientModalHeading>
      <GradientModalBodyRegularText>
        Fiberplane CLI enables you to create templates, notebooks, and even
        stream output&mdash;all from your shell. To install, execute the
        following command:
      </GradientModalBodyRegularText>
      <CodeSample
        code={INSTALL_INSTRUCTIONS}
        // Fire a segment event when the user copies the CLI installation instructions
        onCopy={() => track("onboarding | copy cli instructions")}
      />

      <GradientModalBodyRegularText>
        Read more about what you can do with the CLI{" "}
        <a
          href="https://docs.fiberplane.com/docs/cli"
          target="_blank"
          rel="noopener noreferrer"
        >
          in our docs
        </a>
        .
      </GradientModalBodyRegularText>
    </GradientModal>
  );
}
