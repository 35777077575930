import type { Timestamp } from "@fiberplane/prometheus-query";

import type {
  FrontMatterGitHubPullRequest,
  FrontMatterPagerDutyIncident,
  FrontMatterUser,
} from "../types";

export function isString(value: unknown): value is string {
  return typeof value === "string";
}

export function isNumber(value: unknown): value is number {
  return typeof value === "number";
}

export function isArrayOfType<T>(
  value: unknown,
  typeGuard: (value: unknown) => value is T,
): value is Array<T> {
  return Array.isArray(value) && value.every(typeGuard);
}

export function isFrontMatterUser(value: unknown): value is FrontMatterUser {
  return (
    typeof value === "object" &&
    value !== null &&
    "id" in value &&
    typeof value.id === "string" &&
    "name" in value &&
    typeof value.name === "string"
  );
}

export function isPagerDutyIncident(
  value: unknown,
): value is FrontMatterPagerDutyIncident {
  return !!value && typeof value === "object";
}

export function isGithubPullRequest(
  value: unknown,
): value is FrontMatterGitHubPullRequest {
  return !!value && value !== null && typeof value === "object";
}

export function isNumberAsString(value: unknown): value is string {
  return !Number.isNaN(Number(value));
}

export function isTimestamp(value: unknown): value is Timestamp {
  try {
    if (!isString(value)) {
      return false;
    }

    const parsedDate = new Date(value).getTime();
    return typeof parsedDate === "number" && !Number.isNaN(parsedDate);
  } catch {
    return false;
  }
}
