import { useHandler } from "@fiberplane/hooks";
import { Icon } from "@fiberplane/ui";
import { useEffect } from "react";
import styled, { css } from "styled-components";

import { removeCell, withActiveNotebook } from "../../../actions";
import { selectRelativeCell } from "../../../selectors";
import type { ThreadState } from "../../../state";
import { type Thunk, dispatch } from "../../../store";

type Props = {
  cellId: string;
  threadState?: ThreadState;
};

const deleteCell =
  (cellId: string): Thunk =>
  (dispatch, getState) => {
    const focusCell = selectRelativeCell(getState(), cellId, -1);
    dispatch(
      withActiveNotebook(
        removeCell(
          cellId,
          focusCell ? { cursorDirection: "backward", focusCell } : undefined,
        ),
      ),
    );
  };

export function DiscussionNotFound(props: Props) {
  const { threadState, cellId } = props;
  const shouldDelete = threadState?.showThreadDeletionPrompt;

  const handleDeleteThread = useHandler(() => {
    dispatch(deleteCell(cellId));
  });

  useEffect(() => {
    if (shouldDelete) {
      handleDeleteThread();
    }
  }, [shouldDelete, handleDeleteThread]);
  return (
    <Container>
      <Heading>
        <Icon iconType="bell" />
        Discussion thread not found
      </Heading>
      <p>This discussion was probably deleted and no longer exists</p>
    </Container>
  );
}

const Container = styled.div(
  ({ theme }) => css`
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid ${theme.color.border.warning};
  border-radius: ${theme.radius.default};
  background-color: ${theme.color.bg.warning};
  color: ${theme.color.fg.warning};
`,
);

const Heading = styled.h5`
  display: flex;
  align-items: center;
`;
