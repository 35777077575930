import { Autometrics } from "@fiberplane/prometheus-query";

import {
  TIMESERIES_MIME_TYPE,
  TIMESERIES_QUERY_TYPE,
} from "../../../constants";
import type {
  Blob,
  Cell,
  ConfigField,
  DataSource,
  Provider,
  ProviderError,
  ProviderRequest,
  Result,
  SupportedQueryType,
} from "../../../types";
import { getQueryField, toQueryData, toQueryDataBlob } from "../../../utils";
import { getProviderForDataSource } from "../dataSourceRegistry";
import { UnsupportedQueryTypeError } from "../errors";

const AUTOMETRICS_FUNCTION_QUERY_TYPE = "x-autometrics-function";

const FUNCTION_FIELD_NAME = "function";
const METRIC_TYPE_FIELD_NAME = "metric_type";
const TIME_RANGE_FIELD_NAME = "time_range";

export function createAutometricsProvider(): Provider {
  return {
    createCells,
    getConfigSchema,
    getSupportedQueryTypes,
    invoke,
    extractData,
  };
}

function createCells(queryType: string): Promise<Array<Cell>> {
  switch (queryType) {
    case AUTOMETRICS_FUNCTION_QUERY_TYPE: {
      const graphCell: Cell = {
        id: "graph",
        dataLinks: [`cell-data:${TIMESERIES_MIME_TYPE},self`],
        graphType: "line",
        readOnly: false,
        stackingType: "none",
        type: "graph",
      };

      return Promise.resolve([graphCell]);
    }

    default:
      return Promise.reject(new UnsupportedQueryTypeError(queryType));
  }
}

function getConfigSchema(): Promise<Array<ConfigField>> {
  return Promise.resolve([
    {
      type: "text",
      name: "url",
      label: "Prometheus URL",
      required: true,
      multiline: false,
      multiple: false,
      placeholder: "",
      prerequisites: [],
      supportsSuggestions: false,
    },
  ]);
}

function getSupportedQueryTypes(): Promise<Array<SupportedQueryType>> {
  const placeholder: Autometrics.MetricType = "request_rate";

  const functionQueryType: SupportedQueryType = {
    label: "Autometrics function",
    queryType: AUTOMETRICS_FUNCTION_QUERY_TYPE,
    schema: [
      {
        name: FUNCTION_FIELD_NAME,
        label: "Select a function to render",
        placeholder: "",
        required: true,
        type: "text",
        multiline: false,
        multiple: false,
        prerequisites: [],
        supportsSuggestions: true,
      },
      {
        name: METRIC_TYPE_FIELD_NAME,
        label: "Select the type of metric to retrieve",
        placeholder,
        required: true,
        type: "select",
        multiple: false,
        options: [...Autometrics.metricTypes],
        prerequisites: [],
        supportsSuggestions: false,
      },
      {
        name: TIME_RANGE_FIELD_NAME,
        label: "Select the time range",
        type: "date_time_range",
        placeholder: "",
        required: true,
      },
    ],
    mimeTypes: [`${TIMESERIES_MIME_TYPE}+json`],
  };

  return Promise.resolve([functionQueryType]);
}

async function invoke(
  { queryType, queryData, config }: ProviderRequest,
  dataSource: DataSource,
): Promise<Result<Blob, ProviderError>> {
  if (queryType !== AUTOMETRICS_FUNCTION_QUERY_TYPE) {
    throw new UnsupportedQueryTypeError(queryType);
  }

  const queryDataString = toQueryData(queryData);

  const functionName = getQueryField(queryDataString, FUNCTION_FIELD_NAME);
  if (!functionName) {
    return validationError(FUNCTION_FIELD_NAME, "No function selected");
  }

  const metricType = getQueryField(queryDataString, METRIC_TYPE_FIELD_NAME);
  if (!Autometrics.isMetricType(metricType)) {
    return validationError(
      METRIC_TYPE_FIELD_NAME,
      `Not a valid metric type: ${metricType}`,
    );
  }

  const timeRange = getQueryField(queryDataString, TIME_RANGE_FIELD_NAME);
  if (!timeRange) {
    return validationError(TIME_RANGE_FIELD_NAME, "Missing time range");
  }

  const prometheusProvider = getProviderForDataSource({
    ...dataSource,
    name: `${dataSource.name}__prometheus`,
    providerType: "prometheus",
  });

  return await prometheusProvider.invoke(
    {
      queryType: TIMESERIES_QUERY_TYPE,
      queryData: toQueryDataBlob({
        query: Autometrics.createFunctionQuery(metricType, { functionName }),
        // biome-ignore lint/style/useNamingConvention: field name is correct
        time_range: timeRange,
      }),
      config,
    },
    dataSource,
  );
}

function extractData(): Promise<Result<Blob, ProviderError>> {
  // biome-ignore lint/style/useNamingConvention: the Result concept comes from rust and follows that naming convention
  return Promise.reject({ Err: { type: "unsupported_request" } });
}

function validationError(
  fieldName: string,
  message: string,
  // biome-ignore lint/style/useNamingConvention: the Result concept comes from rust and follows that naming convention
): { Err: ProviderError } {
  return {
    // biome-ignore lint/style/useNamingConvention: the Result concept comes from rust and follows that naming convention
    Err: { type: "validation_error", errors: [{ fieldName, message }] },
  };
}
