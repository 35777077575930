import { useEffect, useState } from "react";

import type { LabelsEditorState } from "../../../state";
import { dispatch } from "../../../store";
import { showLabelDeletedWarning } from "../../../thunks";
import type { Label, LabelsEditorType } from "../../../types";

export const useLabelDeletedWarning = (
  inputRef: React.MutableRefObject<HTMLInputElement | null>,
  editorState: LabelsEditorState,
  labels: ReadonlyArray<Label>,
  type: LabelsEditorType,
) => {
  const existingKeys = labels.map(({ key }) => key);
  const [modalShown, setModalShown] = useState(false);

  useEffect(() => {
    if (modalShown) {
      return;
    }

    const { draft, original } = editorState;
    // Show the warning if we're editing, but the label no longer exists:
    const shouldShowWarning =
      type !== "command_menu" &&
      original &&
      !existingKeys.includes(original.key);

    if (shouldShowWarning) {
      dispatch(
        showLabelDeletedWarning({
          draft,
          onContinueEditing: () => inputRef.current?.focus(),
        }),
      );
      setModalShown(true);
    }
  }, [editorState, existingKeys, inputRef, modalShown, type]);
};
