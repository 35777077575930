import { Button, Icon } from "@fiberplane/ui";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { useListWebhooksQuery } from "../../../../../api";
import { selectActiveWorkspaceIdOrThrow } from "../../../../../selectors";
import { dispatch } from "../../../../../store";
import { createWebhook, hideSettingsModal } from "../../../../../thunks";
import type { Webhook } from "../../../../../types";
import { sortBy } from "../../../../../utils";
import { EmptyMessage, SettingsPageActionButton } from "../shared";
import { WebhookList } from "./WebhookList";

const EMPTY_WEBHOOK_LIST: Array<Webhook> = [];

export function Webhooks() {
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const { data = EMPTY_WEBHOOK_LIST } = useListWebhooksQuery({ workspaceId });

  const webhooks = useMemo(
    () => sortBy([...data], ({ createdAt }) => createdAt, true),
    [data],
  );

  return (
    <>
      <SettingsPageActionButton
        onClick={() => {
          dispatch(createWebhook());
          dispatch(hideSettingsModal());
        }}
      >
        <Icon iconType="plus" /> New
      </SettingsPageActionButton>

      {webhooks && webhooks.length > 0 ? (
        <WebhookList webhooks={webhooks} />
      ) : (
        <EmptyMessage
          iconType="webhook"
          title="No webhooks"
          description={
            <>
              Add your first webhook or learn more about webhooks{" "}
              <a
                href="https://docs.fiberplane.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </>
          }
          action={
            <Button onClick={() => dispatch(createWebhook())}>
              <Icon iconType="plus" />
              New webhook
            </Button>
          }
        />
      )}
    </>
  );
}
