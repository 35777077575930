import { motion, useMotionValue, useTransform } from "framer-motion";
import { useEffect, useState } from "react";

const checkmarkVariants = {
  checked: { pathLength: 1 },
  unchecked: { pathLength: 0 },
};

type Props = { height: string; width: string; delayMs?: number };

/**
 * Checkmark icon that animates shortly after mounting
 * The SVG here is a modified copy-paste of the `CheckIcon`
 *
 * The original `CheckIcon` used `fill="currentColor"`, but to make this animation work
 *    we need to use a combination of the svg stroke + pathLength properties.
 *    So, this component uses `stroke="currentColor"` and `strokeWidth="2"`
 *
 */
export function AnimatedCheck({ height, width, delayMs = 250 }: Props) {
  const [isChecked, setIsChecked] = useState(false);
  const pathLength = useMotionValue(0);
  const opacity = useTransform(pathLength, [0.05, 0.15], [0, 1]);

  // Animate checkmark on mount
  useEffect(() => {
    const timeout = setTimeout(() => setIsChecked(true), delayMs);
    return () => clearTimeout(timeout);
  });

  return (
    <motion.svg
      initial={false}
      animate={isChecked ? "checked" : "unchecked"}
      viewBox="0 0 20 20"
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M8.1251 15C7.95944 14.9977 7.80061 14.9336 7.67979 14.8203L3.30479 10.4453C3.20512 10.3239 3.15419 10.1697 3.1619 10.0128C3.1696 9.85587 3.2354 9.70744 3.34648 9.59636C3.45756 9.48527 3.606 9.41948 3.7629 9.41177C3.91981 9.40407 4.07398 9.455 4.19541 9.55466L8.1251 13.4922L16.4298 5.17966C16.5512 5.08 16.7054 5.02907 16.8623 5.03677C17.0192 5.04448 17.1676 5.11027 17.2787 5.22136C17.3898 5.33244 17.4556 5.48087 17.4633 5.63778C17.471 5.79469 17.4201 5.94885 17.3204 6.07029L8.57041 14.8203C8.44958 14.9336 8.29075 14.9977 8.1251 15Z"
        fill="transparent"
        stroke="currentColor"
        strokeWidth="2"
        style={{ pathLength, opacity }}
        variants={checkmarkVariants}
      />
    </motion.svg>
  );
}
