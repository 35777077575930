import {
  loadLabelSuggestions,
  setLabelSuggestions,
  setLabelSuggestionsError,
  showError,
  withNotebook,
} from "../../../actions";
import {
  selectActiveLabelsEditorType,
  selectActiveWorkspaceIdOrThrow,
  selectLabelSuggestions,
  selectLabelSuggestionsKey,
  selectNotebookId,
} from "../../../selectors";
import { Api } from "../../../services";
import type { Thunk } from "../../../store";
import { parseLabel } from "../../../utils";

export const fetchLabelSuggestions =
  (draft: string, abortController: AbortController): Thunk<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const notebookId = selectNotebookId(state);
    const workspaceId = selectActiveWorkspaceIdOrThrow(state);
    const labelsEditorType = selectActiveLabelsEditorType(state);

    try {
      if (selectLabelSuggestions(state)?.loading) {
        if (selectLabelSuggestionsKey(state) === draft) {
          return;
        }

        abortController.abort();
      }

      const { key, value } = parseLabel(draft);

      dispatch(loadLabelSuggestions(labelsEditorType, key));

      const { signal } = abortController;
      if (draft.includes("=") || value.length > 0) {
        const values = await Api.listLabelValues(signal, workspaceId, key);

        const filteredValues = values.filter((current) => current !== value);

        dispatch(setLabelSuggestions(labelsEditorType, [], filteredValues));
      } else {
        const keys = await Api.listLabelKeys(signal, workspaceId, key);

        const filteredKeys = keys.filter((current) => current !== key);

        dispatch(setLabelSuggestions(labelsEditorType, filteredKeys, []));
      }
    } catch (error) {
      const isAbortError =
        error instanceof DOMException && error.name === "AbortError";

      if (!isAbortError) {
        const message = "Unable to fetch labels keys";
        dispatch(setLabelSuggestionsError(labelsEditorType, message));
        dispatch(
          withNotebook(
            notebookId,
            showError({
              type: "other",
              message,
            }),
          ),
        );
      }
    }
  };
