import { createSelector } from "reselect";

import type { RootState } from "../state";
import type { ModalType } from "../types";

export const makeModalActiveSelector = (name: ModalType | string) =>
  createSelector([selectModalsMap], (modalMap) => modalMap.has(name));

export const selectActiveContextMenu = (state: RootState) =>
  selectUiState(state).activeContextMenu;

export const selectActiveDrag = (state: RootState) =>
  selectUiState(state).activeDrag;

export const selectActiveDragFormatSupported = (state: RootState) =>
  selectActiveDrag(state)?.isFormatSupported;

export const selectActiveDropTarget = (state: RootState) =>
  selectActiveDrag(state)?.target;

export const selectActiveTooltip = (state: RootState) =>
  selectUiState(state).activeTooltip;

export const selectEngageIsOpen = (state: RootState) =>
  selectUiState(state).engageMenuIsOpen;

export const selectHasModalOpen = (state: RootState) =>
  selectModalsMap(state).size > 0;

export const selectLastUserAction = (state: RootState) =>
  selectUiState(state).lastUserAction;

export const selectModalsMap = (state: RootState) =>
  selectUiState(state).modals;

export const selectNotebookFocused = (state: RootState) =>
  selectUiState(state).isNotebookFocused;

export const selectSideMenuIsOpen = (state: RootState) =>
  selectUiState(state).sideMenuIsOpen;

export const selectShowOwnCursor = (state: RootState): boolean => {
  const uiState = selectUiState(state);
  return uiState.isNotebookFocused && uiState.isWindowFocused;
};

export const selectUiState = (state: RootState) => state.ui;

export const selectViewsDisplayType = (state: RootState) =>
  selectUiState(state).viewsDisplayType;

export const selectWindowFocused = (state: RootState) =>
  selectUiState(state).isWindowFocused;
