import {
  type AppAction,
  HIDE_ACTIVE_CONTEXT_MENU,
  HIDE_ACTIVE_TOOLTIP,
  HIDE_ALL_MODALS,
  HIDE_ENGAGE_MENU,
  HIDE_MODAL,
  HIDE_SIDE_MENU,
  type HideModalAction,
  SET_ACTIVE_CONTEXT_MENU,
  SET_ACTIVE_TOOLTIP,
  SET_LAST_USER_ACTION,
  SET_NOTEBOOK_FOCUSED,
  SET_VIEWS_DISPLAY_TYPE,
  SET_WINDOW_FOCUSED,
  SHOW_ENGAGE_MENU,
  SHOW_MODAL,
  SHOW_SIDE_MENU,
  START_DRAG,
  STOP_DRAG,
  type ShowModalAction,
  UPDATE_DROP_TARGET,
  UPDATE_MODAL,
  type UpdateModalAction,
  type WrappedActiveNotebookAction,
  isActiveNotebookAction,
} from "../actions";
import { selectHasActiveNotebook } from "../selectors";
import type { RootState, UiState } from "../state";

export const initialState: UiState = {
  activeContextMenu: null,
  activeDrag: null,
  activeTooltip: null,
  engageMenuIsOpen: false,
  isNotebookFocused: false,
  isWindowFocused: true,
  lastUserAction: "typing",
  modals: new Map(),
  sideMenuIsOpen: false,
  viewsDisplayType: "grid",
};

export function uiReducer(
  rootState: RootState | undefined,
  action: AppAction,
): UiState {
  const state = rootState?.ui ?? initialState;

  switch (action.type) {
    case HIDE_ACTIVE_CONTEXT_MENU:
      return { ...state, activeContextMenu: null };

    case HIDE_ACTIVE_TOOLTIP:
      return { ...state, activeTooltip: null };

    case HIDE_ENGAGE_MENU:
      return { ...state, engageMenuIsOpen: false };

    case HIDE_MODAL:
      return handleHideModal(rootState, state, action);

    case HIDE_ALL_MODALS:
      return { ...state, modals: new Map() };

    case HIDE_SIDE_MENU:
      return { ...state, sideMenuIsOpen: false };

    case SET_ACTIVE_CONTEXT_MENU:
      return { ...state, activeContextMenu: action.payload };

    case SET_ACTIVE_TOOLTIP: {
      const { anchor, content, options } = action.payload;
      return {
        ...state,
        activeTooltip: {
          anchor: () => anchor,
          content: () => content,
          options,
        },
      };
    }

    case SET_LAST_USER_ACTION:
      return { ...state, lastUserAction: action.payload };

    case SET_NOTEBOOK_FOCUSED:
      return { ...state, isNotebookFocused: action.payload };

    case SET_VIEWS_DISPLAY_TYPE:
      return { ...state, viewsDisplayType: action.payload };

    case SET_WINDOW_FOCUSED:
      return { ...state, isWindowFocused: action.payload };

    case SHOW_ENGAGE_MENU:
      return { ...state, engageMenuIsOpen: true };

    case SHOW_MODAL:
      return handleShowModal(state, action);

    case SHOW_SIDE_MENU:
      return { ...state, sideMenuIsOpen: true };

    case START_DRAG:
      return { ...state, activeDrag: action.payload };

    case STOP_DRAG:
      return { ...state, activeDrag: null };

    case UPDATE_DROP_TARGET: {
      const { activeDrag } = state;
      return activeDrag
        ? { ...state, activeDrag: { ...activeDrag, target: action.payload } }
        : state;
    }

    case UPDATE_MODAL:
      return handleUpdateModal(state, action);

    default: {
      if (isActiveNotebookAction(action)) {
        return handleNotebookAction(state, action);
      }

      return state;
    }
  }
}

function handleHideModal(
  rootState: RootState | undefined,
  state: UiState,
  action: HideModalAction,
): UiState {
  const modals = new Map(state.modals);
  modals.delete(action.payload.key);

  // Return focus to the notebook after the last modal is closed, assuming
  // a notebook is currently opened.
  const isNotebookFocused =
    modals.size === 0 &&
    (rootState ? selectHasActiveNotebook(rootState) : false);

  return { ...state, isNotebookFocused, modals };
}

function handleShowModal(state: UiState, action: ShowModalAction): UiState {
  const { key, modal } = action.payload;

  // Any modal that gets opened should implicitly remove the focus from the
  // notebook, otherwise you could continue typing underneath the modal.
  const isNotebookFocused = false;

  const modals = state.modals.has(key)
    ? state.modals
    : new Map(state.modals).set(key, modal);

  return { ...state, isNotebookFocused, modals };
}

function handleUpdateModal(state: UiState, action: UpdateModalAction): UiState {
  const { key, modal } = action.payload;

  if (!state.modals.has(key)) {
    console.warn("Attempting to update a modal that doesn't exist!");
  }

  const modals = new Map(state.modals).set(key, modal);

  return { ...state, modals };
}

function handleNotebookAction(
  state: UiState,
  action: WrappedActiveNotebookAction,
): UiState {
  switch (action.payload.type) {
    case "focus_cell":
    case "replace_text":
    case "set_focus":
      // Any action we dispatch that affects the focus within the
      // notebook should also mark the notebook as focused.
      if (!state.isNotebookFocused || state.lastUserAction !== "typing") {
        return {
          ...state,
          isNotebookFocused: true,
          lastUserAction: "typing",
        };
      }
  }

  return state;
}
