import { applyOwnOperation, withActiveNotebook } from "../actions";
import { FRONT_MATTER_CELL_ID } from "../constants";
import {
  selectDangerousNotebookFrontMatter,
  selectNotebookFocus,
  selectNotebookFrontMatterSchema,
  selectRelativeField,
  selectStudioSafeFrontMatter,
} from "../selectors";
import type { Thunk } from "../store";
import type {
  FrontMatterNumberList,
  FrontMatterNumberValue,
  FrontMatterStringList,
  FrontMatterStringValue,
  FrontMatterValue,
  FrontMatterValueSchema,
  NotebookFocus,
  Operation,
  SafeFrontMatterValue,
} from "../types";
import { getFocusCellId, getFocusField, isNumber, isString } from "../utils";
import { focusCell } from "./editorThunks";

type AllowExtraValueEnums =
  | FrontMatterNumberValue
  | FrontMatterStringValue
  | FrontMatterNumberList
  | FrontMatterStringList;

export const addAndSelectSchemaOption =
  (key: string, value: AllowExtraValueEnums): Thunk =>
  (dispatch, getState) => {
    // Get the raw value as it is stored in the state
    const oldFrontMatter = selectDangerousNotebookFrontMatter(getState());

    // Get the schema entries
    const schemaEntries = selectNotebookFrontMatterSchema(getState());

    // Find the schema entry for the given key
    const entry = schemaEntries.find((entry) => entry.key === key);
    // Value of the soon to be old schema
    const oldSchema = entry?.schema;
    // Value stored in the state
    const oldValue = oldFrontMatter[key];

    let newSchema: FrontMatterValueSchema | undefined;
    let newValue: unknown;

    if (oldSchema?.type === "string" && isString(value)) {
      // Get the same value but from the safe/constrained front-matter
      const currentValue = selectStudioSafeFrontMatter(getState())[key];

      newValue = getNewValue(currentValue, value, oldSchema.multiple ?? false);

      // Add the value to the options
      newSchema = {
        ...oldSchema,
        options: [...(oldSchema.options || []), value],
      };
    } else if (oldSchema?.type === "number" && isNumber(value)) {
      // Construct the new selection value
      // if the schema can contain multiple strings, then we need to append the value to the array or
      // set it to an array with the value
      // otherwise use the value as is
      newValue = value;

      // Add the value to the options
      newSchema = {
        ...oldSchema,
        options: [...(oldSchema.options || []), value],
      };
    }

    if (oldSchema && newSchema) {
      return dispatch(
        withActiveNotebook(
          applyOwnOperation({
            type: "update_front_matter_schema",
            key,
            oldValue,
            newValue: (newValue as FrontMatterValue) ?? null,
            oldSchema,
            newSchema,
          }),
        ),
      );
    }
  };

function getNewValue(
  currentValue: SafeFrontMatterValue,
  optionValue: string,
  multiple: boolean,
) {
  if (multiple) {
    return Array.isArray(currentValue)
      ? [...currentValue, optionValue]
      : [optionValue];
  }

  return optionValue;
}

export const updateFrontMatterValue =
  (key: string, value: unknown, focus?: NotebookFocus): Thunk =>
  (dispatch, getState) => {
    const oldFrontMatter = selectDangerousNotebookFrontMatter(getState());
    const schemaEntries = selectNotebookFrontMatterSchema(getState());
    const entry = schemaEntries.find((entry) => entry.key === key);
    if (!entry) {
      // No matching schema found, so we can't update the value.
      return;
    }

    // The cast here is done because the API enforces that the current values of front matter
    // have valid values
    const oldValue = oldFrontMatter[key] as FrontMatterValue;

    // The cast here is done because the FieldTYPE components have typed onChange handlers that
    // fall into the FrontMatterValue case
    const newValue = value as FrontMatterValue;

    return dispatch(
      withActiveNotebook(
        applyOwnOperation(
          {
            type: "update_front_matter_schema",
            key,
            oldValue,
            newValue,
            oldSchema: entry.schema,
            deleteValue: oldValue !== undefined && value === undefined,
          },
          {
            focus,
          },
        ),
      ),
    );
  };

export const deleteFrontMatterKey =
  (key: string): Thunk =>
  (dispatch, getState) => {
    const state = getState();

    // Determine the next cell/field to focus on before removing content
    const focus = selectNotebookFocus(state);
    const targetField =
      getFocusCellId(focus) === FRONT_MATTER_CELL_ID &&
      getFocusField(focus) === key
        ? selectRelativeField(state, FRONT_MATTER_CELL_ID, key, 1)
        : null;
    const frontMatterData = selectDangerousNotebookFrontMatter(state);
    if (targetField) {
      dispatch(
        focusCell({ cellId: targetField.cellId, field: targetField.field }),
      );
    }

    const schemaEntries = selectNotebookFrontMatterSchema(state);
    const schemaIndex = schemaEntries.findIndex((entry) => entry.key === key);
    const entry = schemaEntries[schemaIndex];
    if (!entry) {
      // No matching schema/entity found, so we can't remove the value.
      return;
    }

    const keyOfEntryBeforeDeletionRange = schemaEntries[schemaIndex - 1]?.key;
    const keyOfEntryAfterDeletionRange = schemaEntries[schemaIndex + 1]?.key;

    const value = frontMatterData[key];
    const operation: Operation = {
      type: "remove_front_matter_schema",
      fromIndex: schemaIndex,
      deletions: [
        {
          key,
          value,
          schema: entry.schema,
        },
      ],
      keyOfEntryAfterDeletionRange,
      keyOfEntryBeforeDeletionRange,
    };

    // Remove the schema
    dispatch(withActiveNotebook(applyOwnOperation(operation)));
  };

export const appendFrontMatterSchema =
  (
    key: string,
    schema: FrontMatterValueSchema,
    options?: { focus: NotebookFocus },
  ): Thunk =>
  (dispatch, getState) => {
    const schemas = selectNotebookFrontMatterSchema(getState()) || [];
    const keyOfEntryBeforeInsertionLocation = schemas[schemas.length - 1]?.key;
    const operation: Operation = {
      type: "insert_front_matter_schema",
      keyOfEntryBeforeInsertionLocation,
      insertions: [{ key, schema }],
      toIndex: schemas.length,
    };
    dispatch(withActiveNotebook(applyOwnOperation(operation, options)));
  };
