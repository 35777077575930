/* stylelint-disable scale-unlimited/declaration-strict-value */
import type { CSSProperties, ReactNode } from "react";
import { css, styled } from "styled-components";

import { useExpandable } from "../useExpandable";
import { TableHead } from "./TableHead";

type TableProps = {
  headers: Array<ReactNode>;
  children?: ReactNode;
  rows: Array<{ children: Array<ReactNode>; highlighted?: boolean }>;
  totalRows?: number;
  displayNumRows?: number;
  defaultHeight: number;
  expandedHeight: Exclude<CSSProperties["height"], undefined>;
  hasMore?: boolean;
  onChangeColumnOrder?: (oldIndex: number, newIndex: number) => void;
  stickyFirstCell?: boolean;
};

export function Table({
  children,
  headers,
  rows,
  totalRows,
  displayNumRows,
  defaultHeight,
  expandedHeight,
  hasMore = false,
  stickyFirstCell = false,
  onChangeColumnOrder,
}: TableProps) {
  const { expandButton, gradient, onScroll, ref, isExpanded } = useExpandable({
    defaultHeight,
  });
  const maxHeight = isExpanded ? expandedHeight : `${defaultHeight}px`;

  const columnCount = headers.length;
  const contentRows = rows.map(({ children, highlighted = false }, i) => {
    const useColspan = children.length < columnCount;
    return (
      <Tr key={i} $hideBorder={useColspan} $highlighted={highlighted}>
        {children.map((col, j) => {
          const colSpan: number | undefined =
            useColspan && children.length === j + 1
              ? columnCount - children.length + 1
              : undefined;
          return (
            <TableData
              key={j}
              colSpan={colSpan}
              $sticky={stickyFirstCell && j === 0}
            >
              {col}
            </TableData>
          );
        })}
      </Tr>
    );
  });

  const numResults = displayNumRows ?? rows.length;
  const resultsText = formatResults({
    numResults,
    totalRows,
    hasMore,
  });

  return (
    <Container>
      {children}

      <TableContainer ref={ref} $maxHeight={maxHeight} onScroll={onScroll}>
        <T>
          {headers && (
            <TableHead
              headers={headers}
              stickyFirstCell={stickyFirstCell}
              onChangeColumnOrder={onChangeColumnOrder}
            />
          )}
          <Tbody>{contentRows}</Tbody>
        </T>

        {gradient}
      </TableContainer>

      <Footer>
        <Results>{resultsText}</Results>
        {expandButton}
      </Footer>
    </Container>
  );
}

function pluralizeResult(count = 0) {
  return `result${count === 1 ? "" : "s"}`;
}

function formatResults(options: {
  numResults?: number;
  totalRows?: number;
  hasMore?: boolean;
}) {
  const { totalRows, numResults, hasMore } = options;

  if (totalRows !== undefined && numResults !== undefined) {
    return `Showing ${numResults} of ${totalRows} ${pluralizeResult(
      totalRows,
    )}`;
  }

  return `${hasMore ? "First " : ""}${numResults} ${pluralizeResult(
    numResults,
  )}`;
}

const TableData = styled.td<{ $sticky?: boolean }>(
  ({ $sticky, theme }) => css`
    font: ${theme.font.code.regular};
    font-size: 14px;
    padding: 0 12px;

    @media (min-width: 568px) {
      position: ${$sticky ? "sticky" : "static"};
      left: 0;
    }
  `,
);

const Tbody = styled.tbody`
  counter-reset: elements;
`;

const Container = styled.div(
  ({ theme }) => css`
    width: 100%;
    border-radius: ${theme.radius.rounded};
    border: 1px solid ${theme.color.border.default};
    background-color: ${theme.color.notebook.cell.bg.query};
    user-select: text;
  `,
);

const TableContainer = styled.div<{
  $maxHeight: Exclude<CSSProperties["height"], undefined>;
}>(
  ({ $maxHeight, theme }) => css`
    box-sizing: border-box;
    position: relative;
    width: 100%;
    max-height: ${$maxHeight};
    overflow: auto;
    border: 1px solid ${theme.color.border.default};
    border-left: none;
    border-right: none;
  `,
);

const T = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
`;

const Tr = styled.tr<{ $hideBorder: boolean; $highlighted: boolean }>(
  ({ $hideBorder, $highlighted, theme }) => css`
    min-height: 36px;

    ${
      !$hideBorder &&
      css`
      &:not(:first-child) {
        border-top: 1px solid ${theme.color.border.default};
      }
    `
    }

    ${TableData} {
      background-color: ${
        $highlighted ? theme.color.notebook.cell.bg.default : "unset"
      };
    }
  `,
);

const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 12px;
`;

const Results = styled.span(
  ({ theme }) => css`
    font: ${theme.font.headings.h5};
    color: ${theme.color.fg.muted};
  `,
);
