import type React from "react";
import { memo } from "react";

import { IconButton } from "@fiberplane/ui";
import styled, { css } from "styled-components";
import { FRONT_MATTER_CELL_ID } from "../../../../../constants";
import { dispatch } from "../../../../../store";
import { focusCell } from "../../../../../thunks";
import {
  CellContainer,
  CellLeftContext,
  CellMain,
  CellRightContext,
} from "../../../CellContainer";
import { CellFocusIndicatorWithButtons } from "../../../CellFocusIndicatorWithButtons";
import { CellPresence } from "../../../CellPresence";
import { ContextMenu } from "../../../ContextMenu";
import { useFocused } from "../../hooks";

type Props = {
  field: string;
  cellType?: string;
  readOnly: boolean;
  handleClick?: () => void;
  children: React.ReactNode;
};

const cellId = FRONT_MATTER_CELL_ID;

/**
 * Cell/field containing front-matter for the component
 */
export const FrontMatterFieldBase = memo(function FrontMatterFieldBase({
  field,
  cellType = "front-matter", // NOTE - `front-matter` is not a recognizable cell type as of writing
  handleClick,
  readOnly,
  children,
}: Props) {
  const focused = useFocused(field);

  const highlighted = false;
  // Focus the cell when clicked, and execute optional `handleClick` callback
  const onClick = (event: React.MouseEvent) => {
    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
    if (!focused && !readOnly) {
      event.stopPropagation();
      dispatch(focusCell({ cellId, field }));
      if (handleClick) {
        handleClick();
      }
    }
  };

  return (
    <CellContainer
      data-cell-id={cellId}
      data-cell-type={cellType}
      onClick={onClick}
      focused={focused}
    >
      <ContextMenu cellId={cellId} />
      <CellLeftContext>
        <CellFocusIndicatorWithButtons cellId={cellId} focused={focused} />
      </CellLeftContext>
      <CellMain
        tabIndex={focused ? 0 : -1}
        data-function="cell-content"
        $highlight={highlighted}
      >
        {children}
      </CellMain>
      <CellRightContext>
        <CellPresence cellId={cellId} field={field} />
      </CellRightContext>
    </CellContainer>
  );
});

export const FrontMatterRemoveButton = memo(
  ({
    hidden,
    onClick,
  }: {
    hidden: boolean;
    onClick: () => void;
  }) => (
    <RemoveIconButton
      onClick={onClick}
      data-testid="front-matter-remove-button"
      hidden={hidden}
      iconType="trash"
      buttonStyle="tertiary-grey"
    />
  ),
);

FrontMatterRemoveButton.displayName = "FrontMatterRemoveButton";

const RemoveIconButton = styled(IconButton)<{ hidden: boolean }>(
  ({ theme, hidden }) => css`
  grid-area: remove;
  visibility: ${hidden ? "hidden" : "visible"};

  &:focus {
      box-shadow: none;
      border-color: ${theme.colorPrimary500};
      outline: ${theme.effectFocusOutline};
      color: ${theme.colorBase500};
    }
`,
);
