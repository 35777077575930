import type { Thunk } from "../store";
import type { SideEffectDescriptor } from "../types";
import { reconnectWithBackoff } from "./courierThunks";
import { revalidateEvents } from "./eventThunks";
import { showMentionNotification } from "./mentionsThunks";
import { addErrorNotification } from "./notificationsThunks";
import {
  processChanges,
  queueSendFocusUpdate,
  queueSendPendingOperations,
  sendAllPendingOperations,
  sendPendingOperations,
} from "./operationThunks";
import { captureSentryErrorWithNotebookState } from "./sentryThunks";

/**
 * Decides which thunk to dispatch based on a side effect descriptor.
 *
 * Side effect descriptors are returned by select reducers and stored in the
 * `sideEffects` slice of our root state. From there, they get dispatched by
 * a special event listener on our store, which uses this function to map the
 * descriptors to actual thunks to dispatch.
 */
export function thunkForDescriptor(
  descriptor: SideEffectDescriptor,
): Thunk<void> {
  switch (descriptor.type) {
    case "apply_pending_changes":
      return processChanges(descriptor.notebookId, descriptor.changes);

    case "queue_send_focus_update":
      return queueSendFocusUpdate(descriptor.notebookId);

    case "queue_send_pending_notebook_operations":
      return queueSendPendingOperations(descriptor.notebookId);

    case "reconnect_with_backoff":
      return reconnectWithBackoff(descriptor.backoff);

    case "register_sentry_error":
      return captureSentryErrorWithNotebookState(
        descriptor.notebookId,
        descriptor.message,
      );

    case "send_all_pending_operations":
      return sendAllPendingOperations();

    case "send_pending_notebook_operations":
      return sendPendingOperations(descriptor.notebookId);

    case "show_mention_notification":
      return showMentionNotification(descriptor);

    case "show_error":
      return addErrorNotification(descriptor.message);

    case "revalidate_events":
      return revalidateEvents();
  }
}
