import { memo, useMemo } from "react";
import { useSelector } from "react-redux";

import { makeCellSubscribersSelector } from "../../selectors";
import { shallowEqualArrays } from "../../utils";
import { PresenceContainer } from "../UI";

function CellPresenceComponent({
  cellId,
  field,
}: { cellId: string; field?: string }): JSX.Element {
  const subscribersSelector = useMemo(
    () => makeCellSubscribersSelector(cellId, field),
    [cellId, field],
  );
  const sessions = useSelector(subscribersSelector, shallowEqualArrays);

  return <PresenceContainer sessions={sessions} />;
}

export const CellPresence = memo(CellPresenceComponent);
