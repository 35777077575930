import { useHandler } from "@fiberplane/hooks";
import {
  Icon,
  IconButton,
  Input,
  cancelEvent,
  stopPropagation,
} from "@fiberplane/ui";
import { css, styled } from "styled-components";

import type { DataSourceConnectionStatus } from "../../../../types";
import { ConnectionStatusIcon, ProviderTypeIcon, useShake } from "../../../UI";

type Props = {
  name: string;
  providerType: string;
  metaInfo?: React.ReactNode;
  selected: boolean;
  connectionStatus?: DataSourceConnectionStatus;
  onEdit?: () => void;
  onSelect: () => void;
};

export function DataSourceItem({
  name,
  providerType,
  metaInfo,
  selected,
  connectionStatus,
  onEdit,
  onSelect,
}: Props): JSX.Element {
  const disabled = connectionStatus === "error";
  const { shake, shakeClassName } = useShake();

  const select = useHandler(() => {
    if (disabled) {
      shake();
    } else {
      onSelect();
    }
  });

  return (
    <Container
      $withMetaData={!!metaInfo}
      data-testid="data-source-item"
      data-name={name}
      data-type={providerType}
      onClick={select}
      aria-selected={selected}
      aria-disabled={disabled}
      role="option"
      className={shakeClassName}
    >
      <IconContainer>
        <StyledProviderTypeIcon
          // Use a separate transient prop to prevent passing `offline` as an
          // attribute to the DOM element
          $offline={disabled}
          height="32"
          offline={disabled}
          type={providerType}
          width="32"
        />
        <StatusContainer>
          {connectionStatus ? (
            <StyledConnectionStatus status={connectionStatus} />
          ) : (
            <LightningIcon iconType="lightning" />
          )}
        </StatusContainer>
      </IconContainer>

      <DataSourceNameContainer>
        <DataSourceName $disabled={disabled}>{name}</DataSourceName>
        {onEdit && (
          <IconButton
            iconType="pencil_simple"
            buttonStyle="tertiary-grey"
            aria-label="Edit"
            onClick={(event) => {
              cancelEvent(event);
              onEdit();
            }}
          />
        )}
      </DataSourceNameContainer>

      {metaInfo && (
        <MetaInfo $disabled={disabled}>
          <Icon iconType="proxy" />
          <StyledTextProxyName>{metaInfo}</StyledTextProxyName>
        </MetaInfo>
      )}
      <InputContainer>
        <Input
          type="radio"
          checked={selected}
          onClick={stopPropagation}
          onChange={onSelect}
          value={name}
          disabled={disabled}
        />
      </InputContainer>
    </Container>
  );
}

const Container = styled.div<{ $withMetaData: boolean }>`
  ${({ theme, $withMetaData }) => css`
    display: flex;
    flex-direction: row;
    gap: 2px 12px;
    display: grid;
    width: 100%;
    grid-template-areas:
      "icon name action"
      ${$withMetaData && `"icon metadata action"`};
    grid-template-columns: 32px auto 52px;
    grid-template-rows: auto;

    align-items: center;
    padding: 5px 12px;
    border-radius: ${theme.radius.default};
    cursor: pointer;

    &:hover {
      background: ${theme.color.bg.elevated.hover};
    }

    &:active {
      background: ${theme.colorBase600};
      color: ${theme.colorBackground};
    }

    &[aria-disabled="true"] {
      cursor: default;
    }

    &[aria-disabled="true"]:hover {
      background: none;
    }
  `}
`;

const IconContainer = styled.div`
  flex-grow: 0;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  grid-area: icon;
`;

const DataSourceNameContainer = styled.div`
  display: flex;
  grid-area: "name";
  flex-direction: row;
  gap: 2px;
  align-items: center;
`;

const DataSourceName = styled.span<{ $disabled: boolean }>(
  ({ $disabled, theme }) => css`
    flex-grow: 0;
    font: ${theme.font.headings.h4};
    color: ${$disabled ? theme.color.fg.muted : theme.color.fg.default};
    text-decoration: ${$disabled ? "line-through" : "none"};

    ${Container}:active & {
      color: ${theme.color.bg.default};
    }
  `,
);

const MetaInfo = styled.div<{ $disabled?: boolean }>(
  ({ $disabled, theme }) => css`
    display: flex;
    grid-area: metadata;
    flex-direction: row;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 0;
    color: ${$disabled ? theme.color.fg.muted : theme.color.fg.default};

    ${Container}:active & {
      color: ${theme.color.bg.default};
    }
  `,
);

const StyledTextProxyName = styled.span`
  ${Container}:active & {
    color: ${({ theme }) => theme.color.bg.default};
  }
`;

const InputContainer = styled.div`
  grid-area: action;
  display: grid;
  place-content: center end;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
`;

const StyledConnectionStatus = styled(ConnectionStatusIcon)(
  ({ theme }) => css`
    border: 1px solid ${theme.color.bg.default};
    border-radius: ${theme.radius.full};
    width: 10px;
    height: 10px;
  `,
);

const StyledProviderTypeIcon = styled(ProviderTypeIcon)<{ $offline: boolean }>(
  ({ $offline, theme }) => css`
  opacity: ${$offline ? 0.5 : 1};
  filter: ${theme.effect.shadow.xxs};
  background: ${theme.color.bg.default};
  border-radius: ${theme.radius.full};
`,
);

const LightningIcon = styled(Icon)(
  ({ theme }) => css`
    width: 14px;
    height: 15px;
    color: ${theme.color.fg.default};
    /* Hacky way of adding a 1px outline on the svg */
    filter: drop-shadow(1px 1px 0 ${theme.color.bg.default})
      drop-shadow(-1px -1px 0 ${theme.color.bg.default})
      drop-shadow(-1px 1px 0 ${theme.color.bg.default})
      drop-shadow(1px -1px 0 ${theme.color.bg.default});
  `,
);
