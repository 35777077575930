import { useHandler } from "@fiberplane/hooks";

import { FRONT_MATTER_CELL_ID } from "../../../../../../../constants";
import type { CellFocus, NotebookFocus } from "../../../../../../../types";
import {
  charCount,
  getCellFocusEnd,
  getCellFocusStart,
  toNotebookFocus,
} from "../../../../../../../utils";
import { createCellFocus } from "../../utils";

export function usePasteHandler(parameters: {
  ref: React.RefObject<HTMLElement>;
  value: string;
  id: string;
  setValue: (value: string, focus: NotebookFocus) => void;
}) {
  const { ref, id, value, setValue } = parameters;
  return useHandler((event: React.ClipboardEvent<HTMLElement>) => {
    event.preventDefault();

    const text = event.clipboardData.getData("text/plain");
    if (!ref.current) {
      return;
    }

    pasteHandler(text, ref.current, id, value, setValue);
  });
}

function pasteHandler(
  text: string,
  node: HTMLElement,
  id: string,
  value: string,
  setValue: (value: string, focus: NotebookFocus) => void,
) {
  const cellFocus = createCellFocus(node, id);
  const start = getCellFocusStart(cellFocus) ?? 0;
  const end = getCellFocusEnd(cellFocus) ?? 0;

  const before = value.substring(0, start);
  const after = value.substring(end);
  const newCellFocus: CellFocus = {
    type: "collapsed",
    field: id,
    offset: start + charCount(text),
  };

  const focus: NotebookFocus = toNotebookFocus(
    newCellFocus,
    FRONT_MATTER_CELL_ID,
  );
  setValue(`${before}${text}${after}`, focus);
}
