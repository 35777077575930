import { useLayoutEffect, useState } from "react";

import type { ExtendedFormatting } from "../types";
import { type CursorCoordinates, getCoordinatesForOffset } from "../utils";

export function useCursorCoordinates(
  container: HTMLElement,
  text: string,
  offset: number | undefined,
  formatting: ExtendedFormatting | undefined,
  fontLoading: boolean | undefined,
  notebookContainerWidth: number | undefined,
): CursorCoordinates | null {
  const [cursorCoordinates, setCursorCoordinates] =
    useState<CursorCoordinates | null>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: intentional
  useLayoutEffect(() => {
    if (offset === undefined) {
      setCursorCoordinates(null);
      return;
    }

    const newCoordinates = getCoordinatesForOffset(container, text, offset);

    if (
      cursorCoordinates?.x !== newCoordinates?.x ||
      cursorCoordinates?.y !== newCoordinates?.y
    ) {
      setCursorCoordinates(newCoordinates);
    }
    // Formatting is in the dependencies to force the cursor to re-render,
    // as some formatting options influence the cursors position, like bold.
  }, [
    container,
    notebookContainerWidth,
    cursorCoordinates,
    fontLoading,
    formatting,
    offset,
    text,
  ]);

  return cursorCoordinates;
}
