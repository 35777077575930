import { Button, Icon } from "@fiberplane/ui";
import classNames from "classnames";
import { css, styled } from "styled-components";

import { ModalContent } from "../../UI";

type Props = {
  hasMultiplePages: boolean;
  onSubmit: () => void;
  page: number;
  setPage: (page: number) => void;
};

export function PaginatorControls({
  hasMultiplePages,
  onSubmit,
  page,
  setPage,
}: Props) {
  return (
    <StyledNav>
      {hasMultiplePages && (
        <>
          {page > 0 && (
            <StyledNavButton onClick={() => setPage(0)} buttonStyle="secondary">
              <StyledIcon iconType="caret_left" /> Back
            </StyledNavButton>
          )}

          <StyledNavToggle>
            <Ellipsis
              className={classNames(page === 0 && "active")}
              onClick={() => setPage(0)}
            />
            <Ellipsis
              className={classNames(page === 1 && "active")}
              onClick={() => setPage(1)}
            />
          </StyledNavToggle>

          {page === 0 && (
            <StyledNavButton onClick={() => setPage(1)} autoFocus tabIndex={0}>
              New notebook
              <StyledIcon iconType="caret_right" />
            </StyledNavButton>
          )}
        </>
      )}
      {(hasMultiplePages === false || page === 1) && (
        <StyledNavButton onClick={onSubmit}>
          Create Notebook <StyledIcon iconType="plus" />
        </StyledNavButton>
      )}
    </StyledNav>
  );
}

const StyledNav = styled(ModalContent)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

const StyledNavToggle = styled.div`
  margin: 0 auto;
  grid-column: 2 / 3;
`;

const StyledNavButton = styled(Button)`
  width: max-content;

  &:first-child {
    grid-column: 1 / 2;
    margin-right: auto;
  }

  &:last-child {
    grid-column: 3 / 4;
    margin-left: auto;
  }
`;

const StyledIcon = styled(Icon)`
  ${/* sc-selector */ StyledNavButton}:first-child & {
    margin-right: 4px;
  }

  ${/* sc-selector */ StyledNavButton}:last-child & {
    margin-left: 4px;
  }
`;

const Ellipsis = styled.button(
  ({ theme }) => css`
    all: unset;
    flex-grow: 0;
    width: 12px;
    position: relative;
    height: 100%;
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      top: 50%;
      left: 50%;
      margin-top: -4px;
      margin-left: -4px;
      border-radius: ${theme.borderRadiusRound};
      background-color: ${theme.colorBase300};
      transition: background-color 0.2s ease-in-out;
      border: 1px solid ${theme.colorBase300};
    }

    &:focus::after {
      border-color: rgb(96, 98, 102);
    }

    &.active::after,
    &:hover::after {
      background-color: rgb(96, 98, 102);
    }
  `,
);
