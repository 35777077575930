// biome-ignore lint/style/noNamespaceImport: This is the recommended way to import sentry
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import "./wdyr";

import "./index.css";
// this import is used to create a permalink to our logo for use in emails
import "url:../public/email/logo.png";

import { App, ErrorBoundary, ErrorScreen, SignIn } from "./components";
import { ROUTES, VERSION, getEnvironment } from "./constants";
import { Auth, DataSources, FiberKit } from "./services";
import { dispatch, init } from "./store";
import { ThemeProvider } from "./theme";
import { setAuthToken } from "./thunks";

const root = createRoot(document.querySelector("#root") as HTMLElement);

const environment = getEnvironment();
const sampleRate: number = (() => {
  switch (environment) {
    case "production":
      return 0.1; // Sample 10% of real users.
    case "demo":
    case "dev":
      return 1; // Sample 100% on `dev` and `demo`. These are best for stress tests.
    case "local":
      return 0;
  }
})();

Sentry.init({
  dsn: "https://6a9e6dc77e904fe88db4483aacaa8c65@o1302973.ingest.sentry.io/6541337",
  integrations: [new BrowserTracing(), new Sentry.Replay()],
  release: VERSION,
  environment,
  tracesSampleRate: sampleRate,
  enabled: environment !== "local",

  replaysSessionSampleRate: sampleRate,
  replaysOnErrorSampleRate: 1, // Always start a replay after hitting an error.
});

function start() {
  const { store, history } = init();
  Promise.all([Auth.getApiToken(), FiberKit.init(), Auth.initServiceWorker()])
    .then(([token]) => {
      DataSources.registerProviders();

      // If an api token is found, initialize the store with it:
      if (token?.value) {
        dispatch(setAuthToken(token.value));
      }

      root.render(
        <Provider store={store}>
          <ThemeProvider>
            <ErrorBoundary>
              <Router history={history}>
                <Routes>
                  <Route path={ROUTES.SignIn} element={<SignIn />} />
                  <Route path="*" element={<App />} />
                </Routes>
              </Router>
            </ErrorBoundary>
          </ThemeProvider>
        </Provider>,
      );
    })
    .catch((error) => {
      root.render(
        <ThemeProvider>
          <ErrorScreen
            error={error}
            componentStack={null}
            description={error.message}
          />
        </ThemeProvider>,
      );
    });
}

start();
