import { CLIPPED_ICON_MAP } from "./clippedIconMap";

export type ClippedIconType = keyof typeof CLIPPED_ICON_MAP;

export function useClippedIcon(type: ClippedIconType) {
  const icon = CLIPPED_ICON_MAP[type];

  return {
    // biome-ignore lint/style/useNamingConvention: returning a component, it's ok to use PascalCase
    ClippedIcon: icon.component,
    iconId: icon.iconId,
  };
}
