export class InvalidMimeTypeError extends Error {
  constructor(mimeType: string) {
    super(`Invalid MIME type: "${mimeType}"`);
  }
}

export class NoDataSourceError extends Error {
  constructor(providerType: string, dataSourceKey?: string) {
    super(
      `No data source configured for type "${providerType}"${
        dataSourceKey ? ` with key "${dataSourceKey}"` : ""
      }`,
    );
  }
}

export class NoProviderError extends Error {
  constructor(providerType: string) {
    super(`No provider registered for type "${providerType}"`);
  }
}

export class UnsupportedQueryTypeError extends Error {
  constructor(queryType: string) {
    super(`Unsupported query type: "${queryType}"`);
  }
}
