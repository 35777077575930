import { useMemo } from "react";
import { useSelector } from "react-redux";
import { generatePath } from "react-router";
import { push } from "redux-first-history";
import { createSelector } from "reselect";

import { ROUTES } from "../../../../../constants";
import {
  selectActiveWorkspaceName,
  selectCommandMenuVariantType,
} from "../../../../../selectors";
import { dispatch } from "../../../../../store";
import type { Command } from "../../../../../types";

export function useRouteCommands(): Array<Command> {
  const { variantType, workspaceName } = useSelector(selectRouteCommandsState);

  return useMemo(() => {
    if (variantType === "none") {
      const commands: Array<Command> = [];

      if (workspaceName) {
        commands.push(
          {
            key: "getting-started",
            type: "navigation",
            title: "Open Getting started",
            keywords: ["dashboard", "visit"],
            icon: "rocket",
            onActivate: () =>
              dispatch(
                push(
                  generatePath(ROUTES.GettingStarted, {
                    workspaceName,
                  }),
                ),
              ),
          },
          {
            key: "notebooks",
            type: "navigation",
            title: "Open Notebooks",
            keywords: ["visit"],
            description: "List all recent notebooks",
            icon: "notebook",
            onActivate: () =>
              dispatch(push(generatePath(ROUTES.Notebooks, { workspaceName }))),
          },
          {
            key: "templates",
            type: "navigation",
            title: "Open Templates",
            keywords: ["visit"],
            description: "List all available templates",
            icon: "templates",
            onActivate: () =>
              dispatch(push(generatePath(ROUTES.Templates, { workspaceName }))),
          },
          {
            type: "navigation",
            key: "views",
            title: "Open Views",
            keywords: ["visit"],
            description: "List all available views",
            onActivate: () =>
              dispatch(push(generatePath(ROUTES.Views, { workspaceName }))),
            icon: "views",
          },
          {
            key: "snippets",
            type: "navigation",
            title: "Open Snippets",
            keywords: ["visit"],
            description: "List all available snippets",
            icon: "scissors",
            onActivate: () =>
              dispatch(push(generatePath(ROUTES.Snippets, { workspaceName }))),
          },
        );
      }

      return commands;
    }

    return [];
  }, [variantType, workspaceName]);
}

const selectRouteCommandsState = createSelector(
  [selectActiveWorkspaceName, selectCommandMenuVariantType],
  (workspaceName, variantType) => ({
    variantType,
    workspaceName,
  }),
);
