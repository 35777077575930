import { css, styled } from "styled-components";

import { TextInput } from "../UI";

export const ViewInput = styled(TextInput)(
  ({ theme }) => css`
    --placeholderColor: ${theme.colorBase400};
    color: ${theme.colorBase800};
    background: transparent;
    border-color: transparent;
    padding: 0;

    &:hover {
      background: transparent;
      border-color: transparent;
    }

    &:active,
    &:focus {
      outline: none;
      box-sizing: none;
      border-color: transparent;
    }

    &:disabled {
      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    &[aria-invalid="true"],
    &[aria-invalid="true"]:hover {
      background: transparent;
      color: ${theme.colorBase800};
      border-color: transparent;
      border-bottom-color: ${theme.colorDanger400};
    }
  `,
);
