import type {
  Control,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";

/**
 * Props for a FiberFormField component.
 * @template Values - The type of form field values.
 * @template FieldName - The type of field name.
 */
export type FiberFormFieldProps<
  Values extends FieldValues,
  FieldName extends FieldPath<Values>,
> = {
  /**
   * The form control object.
   */
  control: Control<Values>;
  /**
   * Description for the form field.
   */
  description?: string;
  /**
   * Label for the form field, uses the name if none is provided.
   */
  label?: string;
  /**
   * Name of the form field.
   */
  name: FieldName;
  /**
   * Validation rules for the form field.
   */
  rules?: RegisterOptions<Values, FieldName>;
};
