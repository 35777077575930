import { Button } from "@fiberplane/ui";
import { styled } from "styled-components";

import type { Api } from "../../../../../services";
import {
  linkupProvider,
  listConnections,
  unlinkProvider,
} from "../../../../../slices";
import { useAppDispatch } from "../../../../../store";
import { addNotification } from "../../../../../thunks";
import { GithubLogoIcon, GoogleLogoIcon } from "../../../../SignIn";

export const ProviderConnection = ({
  provider,
  linked,
  hasMultipleConnections,
}: Api.Connection & { hasMultipleConnections: boolean }) => {
  const dispatch = useAppDispatch();
  const providerName = { github: "GitHub", google: "Google" }[provider];
  const Icon = { github: GithubLogoIcon, google: GoogleLogoIcon }[provider];

  return linked ? (
    <StyledButton
      buttonStyle="secondary"
      disabled={!hasMultipleConnections}
      onClick={() => {
        dispatch(unlinkProvider({ provider }))
          .unwrap()
          .then(() => {
            dispatch(
              addNotification({
                type: "info",
                title: `Disconnected from ${providerName}`,
              }),
            );
          })
          .catch(() => {
            dispatch(
              addNotification({
                type: "danger",
                title: `Failed to disconnect from ${providerName}`,
              }),
            );
          })
          .finally(() => dispatch(listConnections()));
      }}
    >
      <Icon />
      Disconnect from {providerName}
    </StyledButton>
  ) : (
    <StyledButton
      buttonStyle="secondary"
      onClick={() => {
        dispatch(
          linkupProvider({
            provider,
            redirect: `${window.location.pathname}?overlay=/settings/profile`,
          }),
        )
          .unwrap()
          .then(({ location }) => {
            window.location.href = decodeURIComponent(location);
          })
          .catch(() => {
            dispatch(
              addNotification({
                type: "danger",
                title: `Failed to link ${providerName}`,
              }),
            );
          })
          .finally(() => dispatch(listConnections()));
      }}
    >
      <Icon />
      Connect to {providerName}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  /* FIXME: This override can be undone once Studio's theme extends the UI lib
    theme provider  */
  font: ${({ theme }) => theme.fontStudioButtonsButtonCopyRegularShortHand};
`;
