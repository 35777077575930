// biome-ignore lint/suspicious/noShadowRestrictedNames: we may have to...
const { hasOwnProperty } = Object.prototype;

/**
 * Returns whether the given object has the specified property.
 */
export function has(
  object: object | null | undefined,
  propertyName: string,
): boolean {
  return hasOwnProperty.call(object, propertyName);
}
