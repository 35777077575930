import { Icon } from "@fiberplane/ui";
import { forwardRef, useContext, useEffect } from "react";
import { css, styled } from "styled-components";

import { useListWorkspacesQuery } from "../../api";
import { dispatch } from "../../store";
import {
  showModal,
  showSettingsModal,
  switchActiveWorkspace,
} from "../../thunks";
import type { Workspace } from "../../types";
import { track } from "../../utils";
import { WorkspaceAvatar } from "../Avatar";
import { MenuContext, MenuItem, MenuItemGroup, ToggleMenu } from "../UI";
import { NavButton } from "./NavElement";
import { MENU_OFFSET } from "./common";

const EMPTY_WORKSPACE_LIST: Array<Workspace> = [];

type WorkspaceToggleElement = {
  opened: boolean;
  workspace: Workspace;
};

const WorkspaceToggleElement = forwardRef<
  HTMLDivElement,
  WorkspaceToggleElement
>(function WorkspaceToggleElement({ opened, workspace }, ref) {
  useEffect(() => {
    if (!opened) {
      return;
    }

    track("Workspaces menu | opened");
    return () => {
      track("Workspaces menu | closed");
    };
  }, [opened]);

  return (
    <div ref={ref}>
      <StyledNavButton
        as="button"
        $isActive={opened}
        aria-label={opened ? undefined : "Workspaces"}
        data-tooltip-placement="right"
      >
        <WorkspaceAvatar
          size={36}
          workspaceId={workspace.id}
          name={workspace.displayName}
        />
      </StyledNavButton>
    </div>
  );
});

const StyledNavButton = styled(NavButton)`
  box-shadow: none;
`;

type WorkspaceToggleProps = {
  activeWorkspace: Workspace;
};

export function WorkspaceToggle({ activeWorkspace }: WorkspaceToggleProps) {
  const { data: availableWorkspaces = EMPTY_WORKSPACE_LIST } =
    useListWorkspacesQuery();

  return (
    <StyledToggleMenu
      toggleElement={({ anchorRef, opened }) => (
        <WorkspaceToggleElement
          opened={opened}
          workspace={activeWorkspace}
          ref={anchorRef}
        />
      )}
      offset={MENU_OFFSET}
      placement="right-start"
    >
      <MenuItemGroup
        key="workspaces"
        title="Your workspaces"
        iconRight={<WorkspaceSettingsIcon />}
      >
        {availableWorkspaces.map((workspace) => (
          <MenuItem
            id={workspace.id}
            key={workspace.id}
            title={workspace.displayName}
            iconLeft={
              <StyledWorkspaceAvatar
                size={24}
                workspaceId={workspace.id}
                name={workspace.displayName}
              />
            }
            iconRight={{
              icon:
                activeWorkspace.id === workspace.id ? (
                  <Icon
                    iconType="check"
                    data-testid={`active-workspace-${workspace.id}`}
                  />
                ) : undefined,
            }}
            onActivate={() => dispatch(switchActiveWorkspace(workspace.name))}
          />
        ))}
      </MenuItemGroup>

      <MenuItem
        id="new"
        key="new"
        title="Create workspace"
        iconLeft={
          <PlusIconContainer>
            <Icon iconType="plus" height={20} width={20} />
          </PlusIconContainer>
        }
        onActivate={() => dispatch(showModal({ type: "createWorkspace" }))}
      />
    </StyledToggleMenu>
  );
}

const WorkspaceSettingsIcon = () => {
  const { close } = useContext(MenuContext);

  return (
    <StyledSettingsIcon
      iconType="settings_duotone"
      onClick={() => {
        if (close) {
          close({ reason: "close_requested" });
        }
        dispatch(showSettingsModal({ section: "workspace" }));
      }}
    />
  );
};

const StyledToggleMenu = styled(ToggleMenu)`
  width: 300px;
`;

const StyledWorkspaceAvatar = styled(WorkspaceAvatar)`
  border-radius: ${({ theme }) => theme.radius.minimal};
`;

const StyledSettingsIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.fg.muted};
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const PlusIconContainer = styled.div(
  ({ theme }) => css`
    width: 24px;
    height: 24px;
    display: grid;
    align-items: center;
    justify-content: center;
    color: ${theme.color.fg.muted};
  `,
);
