import { useCallback, useEffect, useRef } from "react";

type StateChange =
  | { state: "browse" }
  | { state: "canceled" }
  | { state: "selected"; file: File };

export type FileInputStateHandler = (change: StateChange) => void;

type Options = {
  id: string;
  acceptFileTypes: string;
  handler: FileInputStateHandler;
  // Inputs are typically added to document body, but you can override this
  parentNode?: HTMLElement | null;
};

export function useFileInput({
  id,
  handler,
  acceptFileTypes,
  parentNode,
}: Options) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const browse = useCallback(() => {
    if (!fileInputRef.current) {
      return;
    }

    fileInputRef.current.click();
    handler({ state: "browse" });
  }, [handler]);

  // Add hidden file input directly to dom
  useEffect(() => {
    const input = document.createElement("input");
    input.id = id;
    input.setAttribute("type", "file");
    input.setAttribute("accept", acceptFileTypes);
    input.style.display = "none";
    input.dataset.testid = "file-upload";
    input.dataset.inputCellId = id;

    (parentNode || document.body).append(input);

    fileInputRef.current = input;

    const handleChange = () => {
      const file = input.files?.[0];
      if (!file) {
        handler({ state: "canceled" });
        return;
      }

      handler({
        state: "selected",
        file,
      });
    };

    input.addEventListener("change", handleChange);

    return () => {
      input.removeEventListener("change", handleChange);
      input.remove();

      fileInputRef.current = null;
    };
  }, [id, acceptFileTypes, handler, parentNode]);

  return browse;
}
