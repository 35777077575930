import { useKeyPressEvent } from "@fiberplane/hooks";
import { Button, Icon } from "@fiberplane/ui";
import { useEffect, useState } from "react";
import { css, keyframes, styled } from "styled-components";

import { MEDIUM_SIZE_QUERY } from "../constants";
import { useAnalyticsEnabled } from "../hooks";

export function CookieBanner(
  props: React.HTMLAttributes<HTMLDivElement>,
): JSX.Element | null {
  const [enabled, setEnabled] = useAnalyticsEnabled();
  const [shown, setShown] = useState(enabled === undefined);

  useEffect(() => {
    setShown(enabled === undefined);
  }, [enabled]);

  function accept() {
    setEnabled(true);
    setShown(false);
  }

  function reject() {
    setEnabled(false);
    setShown(false);
  }

  function handleAcceptKeyDown(event: React.KeyboardEvent<HTMLButtonElement>) {
    const { key } = event;

    if (key === "Enter" || key === " ") {
      event.preventDefault();
      accept();
    }
  }

  function handleRejectKeydown(event: React.KeyboardEvent<HTMLButtonElement>) {
    const { key } = event;

    if (key === "Enter" || key === " ") {
      event.preventDefault();
      reject();
    }
  }

  function hide() {
    setShown(false);
  }

  useKeyPressEvent("Escape", hide);

  if (!shown) {
    return null;
  }

  return (
    <StyledOverlay>
      <StyledPrivacyPopup {...props}>
        <StyledContent>
          <StyledFrame1858>
            <StyledIconPlusBg>
              <StyledIcon iconType="shield_check" />
            </StyledIconPlusBg>

            <StyledText>
              <StyledTextTitle>Privacy notice</StyledTextTitle>

              <StyledTextCopy>
                We collect data for analytical purposes in order to improve user
                experience. By clicking “Agree”, you consent to our{" "}
                <StyledLink href="https://fiberplane.com/privacy">
                  Privacy Policy
                </StyledLink>
              </StyledTextCopy>
            </StyledText>
          </StyledFrame1858>

          <ButtonContainer>
            <Button
              onClick={reject}
              onKeyDown={handleRejectKeydown}
              buttonStyle="secondary"
            >
              Reject
            </Button>
            <Button
              buttonStyle="primary"
              onClick={accept}
              onKeyDown={handleAcceptKeyDown}
            >
              Agree
            </Button>
          </ButtonContainer>
        </StyledContent>
      </StyledPrivacyPopup>
    </StyledOverlay>
  );
}

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const StyledOverlay = styled.div`
  position: fixed;
  z-index: 1010;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  box-sizing: border-box;
  padding: 40px 16px;
  animation-delay: 0s;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
  animation-name: ${fadeInAnimation};
  animation-timing-function: cubic-bezier(0.87, 0, 0.13, 1);

  &::before {
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    background: #00000060;
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
  }

  @media ${MEDIUM_SIZE_QUERY} {
    padding: 40px;
  }
`;

const fadeInContentAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const StyledPrivacyPopup = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex-grow: 0;
  max-width: 1200px;
  background-color: ${({ theme }) => theme.colorBackground};
  border-radius: ${({ theme }) => theme.borderRadius600};
  padding: 16px 32px;
  opacity: 0;

  animation-delay: 0.25s;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
  animation-name: ${fadeInContentAnimation};
  animation-timing-function: cubic-bezier(0.87, 0, 0.13, 1);
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  flex-grow: 0;
  padding: 0;

  @media ${MEDIUM_SIZE_QUERY} {
    flex-direction: row;
  }
`;

const StyledFrame1858 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  padding: 0;
`;

const StyledIconPlusBg = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-grow: 0;
    background: ${theme.colorSecondary600};
    border-radius: ${theme.borderRadius600};
    padding: 6px;
  `,
);

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colorBackground};
`;

const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  flex-grow: 1;
  padding: 0;
`;

const StyledTextTitle = styled.span(
  ({ theme }) => css`
    flex-grow: 0;
    text-align: center;
    color: ${theme.colorForeground};
    font: ${theme.fontNotebooksBaseShortHand};
    letter-spacing: ${theme.fontNotebooksBaseLetterSpacing};
  `,
);

const StyledTextCopy = styled.span(
  ({ theme }) => css`
    flex-grow: 0;
    font: ${theme.fontNotebooksSmallShortHand};
    letter-spacing: ${theme.fontNotebooksSmallLetterSpacing};
    color: ${theme.colorForeground};
  `,
);

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colorForeground};
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  align-self: flex-end;

  @media ${MEDIUM_SIZE_QUERY} {
    align-self: center;
    gap: 16px;
  }
`;
