import { Icon } from "@fiberplane/ui";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

import { changeContextMenuType } from "../../../actions";
import { useListMembersQuery } from "../../../api";
import {
  selectActiveWorkspaceIdOrThrow,
  selectUniqueNotebookSubscriberSessions,
} from "../../../selectors";
import { useActiveNotebookDispatch } from "../../../store";
import { activateMention } from "../../../thunks";
import type { Membership, SubscriberSession, User } from "../../../types";
import { sortBy } from "../../../utils";
import { ProfileAvatar } from "../../Avatar";
import {
  type FilterMenuItem,
  PositionedFilterMenu,
  type PositionedFilterMenuProps,
} from "../../UI";
import { getPriority } from "./getPriority";

const AT_MENU_OFFSET: [number, number] = [0, 4];
const EMPTY_MEMBERS_LIST: Array<Membership> = [];

type Props = Omit<PositionedFilterMenuProps, "items">;

export function AtMenu({
  filterText = "",
  ...menuProps
}: Props): JSX.Element | null {
  const dispatch = useActiveNotebookDispatch();
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const { data: mentionsSuggestions = EMPTY_MEMBERS_LIST } =
    useListMembersQuery({ workspaceId }, { refetchOnMountOrArgChange: true });
  const subscriberSessions = useSelector(
    selectUniqueNotebookSubscriberSessions,
  );

  const suggestions = useMemo(() => {
    const timestamps = timestampSuggestion(() =>
      dispatch(changeContextMenuType("date_picker")),
    );

    const onActivateMention = (user: User) => dispatch(activateMention(user));

    const suggestions = mentionSubscriberSuggestions(
      subscriberSessions,
      filterText,
      onActivateMention,
    );

    const fetchedSuggestions = mentionUserSuggestions(
      mentionsSuggestions.filter(
        ({ id }: User) =>
          !subscriberSessions.some(({ user }) => id === user.id),
      ),
      filterText,
      onActivateMention,
    );

    return [...timestamps, ...suggestions, ...fetchedSuggestions];
  }, [dispatch, filterText, subscriberSessions, mentionsSuggestions]);

  return (
    <StyledPositionedMenu
      {...menuProps}
      filterText={filterText}
      grabsFocus={false}
      items={suggestions}
      noResultsText="No users"
      offset={AT_MENU_OFFSET}
      placement="bottom-start"
    />
  );
}

function mentionSubscriberSuggestions(
  sessions: ReadonlyArray<SubscriberSession>,
  filterText: string,
  onActivate: (user: User) => void,
): Array<FilterMenuItem> {
  const items: Array<FilterMenuItem> = sessions.map(({ user }) =>
    itemForUser(user, onActivate),
  );
  if (items.length === 0) {
    return [];
  }

  return [
    {
      type: "item_group",
      id: "in-notebook",
      title: "In this Notebook",
      items: sortBy(items, getPriority(filterText)),
    },
  ];
}

function mentionUserSuggestions(
  users: Array<User>,
  filterText: string,
  onActivate: (user: User) => void,
): Array<FilterMenuItem> {
  const items: Array<FilterMenuItem> = users.map((user) =>
    itemForUser(user, onActivate),
  );
  if (items.length === 0) {
    return [];
  }

  return [
    {
      type: "item_group",
      id: "from-workspace",
      title: "From your workspace",
      items: sortBy(items, getPriority(filterText)),
    },
  ];
}

function itemForUser(
  user: User,
  onActivate: (user: User) => void,
): FilterMenuItem {
  return {
    type: "item_with_icons",
    id: user.id,
    title: user.name,
    iconLeft: (
      <ProfileAvatar userId={user.id} name={user.name} showTooltip={false} />
    ),
    iconRight: {
      icon: <Icon iconType="key_return" />,
      showOnlyOnSelection: true,
    },
    onActivate: () => onActivate(user),
  };
}

function timestampSuggestion(onActivate: () => void): Array<FilterMenuItem> {
  return [
    {
      type: "item_group",
      id: "timestamp",
      title: "Add timestamps to the notebook",
      items: [
        {
          type: "item_with_icons",
          id: "timestamp",
          title: "Timestamp",
          iconLeft: <Icon iconType="timer" />,
          iconRight: {
            icon: <Icon iconType="key_return" />,
            showOnlyOnSelection: true,
          },
          disableCloseOnActivate: true,
          onActivate,
        },
      ],
    },
  ];
}

const StyledPositionedMenu = styled(PositionedFilterMenu)`
  max-height: 300px;
  width: 236px;
`;
