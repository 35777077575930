import { useHandler } from "@fiberplane/hooks";
import { useEffect, useRef, useState } from "react";
import useLatest from "react-use/lib/useLatest";

import type { CropPosition } from "../types";

function isSvgRect(target: EventTarget): target is SVGRectElement {
  return target instanceof SVGRectElement;
}

type DragContext = {
  startX: number; // Exact position where was clicked (in pixels)
  startY: number; // Exact position where was clicked (in pixels)
  // initial values are the values that what we're cropping the image to
  // this is slightly different to the start values, because the user is
  // probably not clicking exactly in the 0,0 corner of the controlling UI element
  initialX: number; // in pixels
  initialY: number; // in pixels

  size: number; // in pixels
  width: number;
  height: number;
  updatePosition: (position: Omit<CropPosition, "scale">) => void;
};

function drag(event: MouseEvent, dragContext: DragContext | null) {
  if (!dragContext) {
    return;
  }

  const { clientX, clientY } = event;
  const {
    size,
    startX,
    initialX,
    startY,
    initialY,
    width,
    height,
    updatePosition,
  } = dragContext;

  const left = Math.min(Math.max(clientX - startX + initialX, 0), width - size);
  const top = Math.min(Math.max(clientY - startY + initialY, 0), height - size);

  updatePosition({
    left: left / width,
    top: top / height,
  });
}

export function useDragCrop<
  // Container refers to the type for the containerRef
  Container = SVGSVGElement,
>(
  width: number,
  height: number,
  updatePosition: (position: Omit<CropPosition, "scale">) => void,
) {
  const [dragContext, setDragContext] = useState<DragContext | null>(null);
  const containerRef = useRef<Container | null>(null);
  const latestDragContext = useLatest(dragContext);

  const dragStart = (event: React.MouseEvent<SVGRectElement>) => {
    const { target, clientX: startX, clientY: startY } = event;

    if (isSvgRect(target) && target.dataset.drag) {
      const xPercentage = Number.parseFloat(target.getAttribute("x") || "0");
      const yPercentage = Number.parseFloat(target.getAttribute("y") || "0");
      const initialX = (xPercentage * width) / 100;
      const initialY = (yPercentage * height) / 100;
      const sizePercentage = Number.parseFloat(
        target.getAttribute("width") || "0",
      );

      const size = (sizePercentage * width) / 100;

      setDragContext({
        startX,
        startY,
        initialX,
        initialY,
        size,
        width,
        height,
        updatePosition,
      });
    }
  };

  const handleDrag = useHandler((event: MouseEvent) => {
    const context = latestDragContext.current;
    if (!context) {
      return;
    }

    if (!containerRef.current) {
      return;
    }

    // This is an edge case but we should verify if updatePosition hasn't changed in the mean time
    if (context.updatePosition !== updatePosition) {
      return;
    }

    drag(event, context);
  });

  useEffect(() => {
    if (!dragContext) {
      return;
    }

    // On mouse up: reset the context
    const onMouseUp = () => setDragContext(null);

    window.addEventListener("mousemove", handleDrag);
    window.addEventListener("mouseup", onMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleDrag);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [handleDrag, dragContext]);

  return {
    dragStart,
    containerRef,
  };
}
