import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CLEAR_AUTHENTICATION } from "../actions";
import { normalizeException } from "../errors";
import { Api } from "../services";
import type { LoadableData, LoadableMutation } from "../types";
import {
  addLoadableDataReducerCases,
  addLoadableMutationReducerCases,
  getInitialLoadableDataState,
  getInitialLoadableMutationState,
} from "./utils";

export interface ProfileState {
  connections: LoadableData<Array<Api.Connection>>;
  linkup: LoadableMutation<{ location: string }>;
  // TODO: Hack... createAsyncThunk types void as undefined...?
  unlink: LoadableMutation<undefined>;
}

const initialState: ProfileState = {
  connections: getInitialLoadableDataState(),
  linkup: getInitialLoadableMutationState(),
  unlink: getInitialLoadableMutationState(),
};

export const listConnections = createAsyncThunk(
  "profile/getConnections",
  async (_, { rejectWithValue, signal }) => {
    try {
      return await Api.listConnections({ signal });
    } catch (exception) {
      const error = normalizeException(exception);
      return rejectWithValue(error);
    }
  },
);

export const linkupProvider = createAsyncThunk(
  "profile/linkupProvider",
  async (
    { provider, redirect }: Parameters<typeof Api.linkupProvider>[0],
    { rejectWithValue, signal },
  ) => {
    try {
      return await Api.linkupProvider({ provider, redirect, signal });
    } catch (exception) {
      const error = normalizeException(exception);

      return rejectWithValue(error);
    }
  },
);

export const unlinkProvider = createAsyncThunk(
  "profile/unlinkProvider",
  async (
    { provider }: Parameters<typeof Api.unlinkProvider>[0],
    { rejectWithValue, signal },
  ) => {
    try {
      await Api.unlinkProvider({ provider, signal });
      return;
    } catch (exception) {
      const error = normalizeException(exception);

      return rejectWithValue(error);
    }
  },
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(CLEAR_AUTHENTICATION, () => ({ ...initialState }));

    addLoadableDataReducerCases(
      builder,
      listConnections,
      (state) => state.connections,
    );

    addLoadableMutationReducerCases(
      builder,
      linkupProvider,
      (state) => state.linkup,
    );

    addLoadableMutationReducerCases(
      builder,
      unlinkProvider,
      (state) => state.unlink,
    );
  },
});
