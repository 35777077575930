import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useThrottledLoadingState } from "../../../hooks";
import { selectActiveWorkspaceIdOrThrow } from "../../../selectors";
import { track } from "../../../utils";
import { ErrorScreen } from "./ErrorScreen";
import { ProxyCreated } from "./ProxyCreated";
import { ProxyDeployInstructions } from "./ProxyDeployInstructions";
import { ProxyExplainer } from "./ProxyExplainer";
import {
  isOnCreatedScreen,
  isOnDeployScreen,
  useAddProxyWizard,
} from "./useAddProxyWizard";

export function AddProxyModal() {
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);

  const {
    currentScreen,
    wizardState,
    requestCreateProxy,
    toProxyCreated,
    toDeployInstructions,
  } = useAddProxyWizard({ workspaceId });

  useEffect(() => {
    track("onboarding | connect data modal", { action: "open" });

    return () => {
      track("onboarding | connect data modal", { action: "close" });
    };
  }, []);

  // Show loading indicator for a minimum of 1,5s before transitioning to next screen
  const isLoading = useThrottledLoadingState(wizardState.isLoading, 1500);

  // NOTE - since `isLoading` is a throttled state, independent of `wizardState`,
  //        we short circuit here to prevent rendering multiple modal screens simultaneously
  if (isLoading) {
    return (
      <AnimatePresence>
        <ProxyExplainer next={requestCreateProxy} isLoading={isLoading} />
      </AnimatePresence>
    );
  }

  return (
    <>
      {currentScreen === "initial" && (
        <AnimatePresence>
          <ProxyExplainer next={requestCreateProxy} isLoading={isLoading} />
        </AnimatePresence>
      )}
      {currentScreen === "error" && <ErrorScreen />}
      {isOnCreatedScreen(wizardState) && (
        <AnimatePresence>
          <ProxyCreated
            next={toDeployInstructions}
            name={wizardState.proxy.name}
            token={wizardState.proxy.token}
          />
        </AnimatePresence>
      )}
      {isOnDeployScreen(wizardState) && (
        <ProxyDeployInstructions
          back={toProxyCreated}
          token={wizardState.proxy.token}
        />
      )}
    </>
  );
}
