import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import {
  selectActiveWorkspaceName,
  selectCanCreateWebhook,
} from "../../../../../selectors";
import { dispatch } from "../../../../../store";
import { createWebhook } from "../../../../../thunks";
import type { WebhookCommand } from "../../../../../types";

export function useWebhookCommands(): Array<WebhookCommand> {
  const { workspaceName, canCreateWebhook } = useSelector(
    selectWebhookCommandsState,
  );

  return useMemo((): Array<WebhookCommand> => {
    if (!workspaceName) {
      return [];
    }

    const commands: Array<WebhookCommand> = [];

    if (canCreateWebhook) {
      commands.push({
        key: "createWebhook",
        keywords: ["webhook", "add", "insert"],
        type: "webhooks",
        title: "Create a new webhook",
        icon: "plus",
        onActivate: () => dispatch(createWebhook()),
      });
    }

    return commands;
  }, [canCreateWebhook, workspaceName]);
}

const selectWebhookCommandsState = createSelector(
  [selectActiveWorkspaceName, selectCanCreateWebhook],
  (workspaceName, canCreateWebhook) => ({ workspaceName, canCreateWebhook }),
);
