import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
/* stylelint-disable scale-unlimited/declaration-strict-value */
import { styled } from "styled-components";

import { useCellFocus, useFilePaste } from "../../../hooks";
import { makeSelectOrderedListItemCounter } from "../../../selectors";
import { useActiveNotebookDispatch } from "../../../store";
import { updateCell } from "../../../thunks";
import type { ListItemCell } from "../../../types";
import { RichTextInput } from "../RTE";
import { formatBullet, formatCounter } from "./utils";

const OL_MARGIN = 23; // px
const UL_MARGIN = 18; // px

const ListItemContainer = styled.li`
  display: flex;
  font-weight: 500;
`;

const OrderedListStyledWrapper = styled.span<{ itemPreface: string }>`
  /*
    This wrapper renders the ordered list item preface with a monospaced font
    of which the visibility is set to hidden. The ::before renders the actual
    displayed number in the same font as the rest of the content, resulting in
    consistent preface widths regardless of what the preface number is.
  */
  font-family: "DM Mono", monospace;
  visibility: hidden;
  position: relative;

  &::before {
    position: absolute;
    font-family: Inter, sans-serif;
    visibility: visible;
    content: ${({ itemPreface }) => `"${itemPreface}."`};
  }
`;

const UnorderedListItemPreface = styled.span<{ level?: number }>`
  display: inline-block;
  margin-left: ${({ level = 0 }) => UL_MARGIN * level}px;
  flex: 0 0 ${UL_MARGIN}px;
`;

export type ListItemCellContentProps = {
  cell: { type: "list_item" } & ListItemCell;
  readOnly: boolean;
};

export function ListItemCellContent({
  cell,
  readOnly,
}: ListItemCellContentProps) {
  const focus = useCellFocus(cell.id);
  const onPaste = useFilePaste(cell.id);

  return (
    <ListItemContainer onPaste={onPaste}>
      {cell.listType === "ordered" ? (
        <OrderedListItemPreface cell={cell} />
      ) : (
        <UnorderedListItemPreface level={cell.level}>
          {formatBullet(cell.level)}
        </UnorderedListItemPreface>
      )}
      <RichTextInput
        cellId={cell.id}
        focus={focus}
        formatting={cell.formatting}
        onPaste={onPaste}
        readOnly={readOnly}
        value={cell.content}
      />
    </ListItemContainer>
  );
}

type OrderedListItemPrefaceProps = {
  cell: ListItemCell;
};

function OrderedListItemPreface({
  cell,
}: OrderedListItemPrefaceProps): JSX.Element {
  const dispatch = useActiveNotebookDispatch();

  const selectOrderedListItemCounter = useMemo(
    () => makeSelectOrderedListItemCounter(cell),
    [cell],
  );

  const {
    level0Preface,
    level1Preface,
    level2Preface,
    removeLevel1StartNumber,
  } = useSelector(selectOrderedListItemCounter);

  const counter = useMemo(
    () =>
      formatCounter(
        {
          level0Preface,
          level1Preface,
          level2Preface,
        },
        cell.level ?? 0,
      ),
    [level0Preface, level1Preface, level2Preface, cell.level],
  );

  useEffect(() => {
    if (removeLevel1StartNumber) {
      dispatch(updateCell(cell.id, { startNumber: undefined }));
    }
  }, [cell.id, dispatch, removeLevel1StartNumber]);

  return (
    <OrderedListStyledWrapper
      style={cell.level ? { marginLeft: OL_MARGIN * cell.level } : undefined}
      itemPreface={counter}
    >
      {counter}.
    </OrderedListStyledWrapper>
  );
}
