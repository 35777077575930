import { useHandler } from "@fiberplane/hooks";
import { Icon } from "@fiberplane/ui";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

import { useUpdateMemberMutation } from "../../../../../api";
import { ROLES } from "../../../../../constants";
import {
  selectActiveWorkspaceIdOrThrow,
  selectActiveWorkspaceOwnerId,
  selectActiveWorkspaceRole,
  selectActiveWorkspaceType,
  selectCurrentUser,
} from "../../../../../selectors";
import { Sentry } from "../../../../../services";
import { dispatch } from "../../../../../store";
import {
  addNotification,
  showWorkspaceLeaveConfirmation,
  showWorkspaceRemoveMemberConfirmation,
  showWorkspaceTransferOwnershipConfirmation,
} from "../../../../../thunks";
import type { AuthRole, Membership } from "../../../../../types";
import { formatRole } from "../../../../../utils";
import { MenuDivider, MenuItem, ToggleMenu } from "../../../../UI";
import { Role } from "./Role";

type RoleToggleProps = {
  member: Membership;
};

export function RoleToggle({ member }: RoleToggleProps) {
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const ownerId = useSelector(selectActiveWorkspaceOwnerId);
  const type = useSelector(selectActiveWorkspaceType);
  const role = useSelector(selectActiveWorkspaceRole);
  const userRole = ownerId === member.id ? "Owner" : formatRole(member.role);
  const currentUser = useSelector(selectCurrentUser);

  const [updateMember] = useUpdateMemberMutation();

  const updateRole = useHandler(async (role: AuthRole) => {
    try {
      await updateMember({
        workspaceId,
        userId: member.id,
        updateWorkspaceUser: { role },
      });
      dispatch(
        addNotification({
          title: `The role of ${member.name} has been updated to ${formatRole(
            role,
          )}`,
        }),
      );
    } catch (error) {
      Sentry.captureError("Error updating user role", { error });
      dispatch(
        addNotification({
          type: "danger",
          title: "Something went wrong updating the user's role",
        }),
      );
    }
  });

  const menuItems = useMemo(() => {
    if (
      type !== "personal" &&
      ownerId &&
      currentUser &&
      ownerId !== member.id && // Don't show the toggle if the user is the owner
      (ownerId === currentUser.id || role === "admin") // Show toggle if you are the owner or an admin
    ) {
      const items = ROLES.map((role) => (
        <MenuItem
          id={role}
          key={role}
          title={formatRole(role)}
          onActivate={() => updateRole(role)}
          iconRight={
            member.role === role
              ? { icon: <Icon iconType="check" /> }
              : undefined
          }
        />
      ));

      if (currentUser.id === member.id) {
        items.push(
          <MenuDivider />,
          <MenuItem
            id="leave"
            key="leave"
            title="Leave team"
            onActivate={() => dispatch(showWorkspaceLeaveConfirmation())}
          />,
        );
      } else {
        items.push(
          <MenuDivider />,
          <MenuItem
            id="remove"
            key="remove"
            title="Remove from team"
            onActivate={() =>
              dispatch(showWorkspaceRemoveMemberConfirmation(member))
            }
          />,
        );

        if (currentUser.id === ownerId) {
          items.push(
            <MenuItem
              id="transfer"
              key="transfer"
              title="Transfer ownership"
              onActivate={() =>
                dispatch(showWorkspaceTransferOwnershipConfirmation(member))
              }
            />,
          );
        }
      }

      return items;
    }

    return [];
  }, [currentUser, ownerId, role, type, updateRole, member]);

  if (menuItems.length === 0) {
    return <Role key="userRole">{userRole}</Role>;
  }

  return (
    <ToggleContainer>
      <ToggleMenu
        toggleElement={({ anchorRef, opened }) => (
          <Role ref={anchorRef} active={opened}>
            {userRole}
            <Icon iconType="caret_down" />
          </Role>
        )}
        placement="bottom-end"
        offset={[0, 6]}
      >
        {menuItems}
      </ToggleMenu>
    </ToggleContainer>
  );
}

const ToggleContainer = styled.span`
  grid-area: role;
  cursor: pointer;
`;
