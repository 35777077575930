import { Button, Icon } from "@fiberplane/ui";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import {
  selectActiveWorkspaceId,
  selectDataSourcesList,
} from "../../../../../selectors";
import { dispatch } from "../../../../../store";
import {
  loadDataSources,
  loadProxyList,
  showModal,
  showSettingsModal,
} from "../../../../../thunks";
import { formatDataSourceKey, sortBy } from "../../../../../utils";
import { ButtonBar, RefreshButton } from "../../../../UI";
import {
  EmptyMessage,
  SettingsPageActionButton,
  SettingsPageErrorMessage,
  StyledTable,
  StyledTableHeadings,
  StyledTh,
  TableHeaderItem,
  useSortingParams,
} from "../shared";
import { DataSourceItem } from "./DataSourceItem";

export function DataSourcesList(): JSX.Element {
  const {
    data: dataSources,
    loading,
    error,
  } = useSelector(selectDataSourcesList);
  const { sortProperty, sortDirection, updateSorting } = useSortingParams();
  const workspaceId = useSelector(selectActiveWorkspaceId);

  useEffect(() => {
    if (workspaceId) {
      dispatch(loadDataSources(workspaceId));
      dispatch(loadProxyList());
    }
  }, [workspaceId]);

  const sortedDataSources = useMemo(
    () =>
      dataSources &&
      sortBy(
        [...dataSources],
        (dataSource) => {
          switch (sortProperty) {
            case "type":
              return dataSource.providerType;
            case "status":
              return dataSource.status ?? "";
            case "proxy":
              return dataSource.proxyName ?? "";
            default:
              return dataSource.name;
          }
        },
        sortDirection === "descending",
      ),
    [dataSources, sortDirection, sortProperty],
  );

  return (
    <>
      <SettingsPageActionButton
        onClick={() => dispatch(showModal({ type: "directAccess" }))}
        data-testid="new-data-source"
      >
        <Icon iconType="plus" /> New
      </SettingsPageActionButton>

      {error && <SettingsPageErrorMessage>{error}</SettingsPageErrorMessage>}

      {dataSources?.length === 0 ? (
        <EmptyMessage
          action={
            <ButtonBar>
              <Button
                onClick={() => dispatch(showSettingsModal({ section: "fpd" }))}
              >
                Manage FPD instances
              </Button>
              <Button
                buttonStyle="secondary"
                // @ts-expect-error FIXME: This is rendered correctly in the DOM, but the type definition is wrong.
                as="a"
                href="https://docs.fiberplane.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </Button>
            </ButtonBar>
          }
          description={
            <>
              Start by connecting a data source through FPD or via{" "}
              <a
                // biome-ignore lint/a11y/useValidAnchor: <explanation>
                onClick={() => dispatch(showModal({ type: "directAccess" }))}
                data-testid="setup-direct-access"
              >
                direct access
              </a>
            </>
          }
          iconType="proxy"
          title="No data sources yet"
        />
      ) : (
        <StyledTable cellPadding={0} cellSpacing={0}>
          <thead>
            <StyledTableHeadings>
              <StyledTh size="50px">
                <TableHeaderItem
                  active={sortProperty === "type"}
                  reverse={sortDirection === "ascending"}
                  onClick={() =>
                    updateSorting(
                      "type",
                      sortProperty === "type" && sortDirection === "ascending"
                        ? "descending"
                        : "ascending",
                    )
                  }
                >
                  Type
                </TableHeaderItem>
              </StyledTh>
              <StyledTh size="33%">
                <TableHeaderItem
                  active={sortProperty === "name"}
                  reverse={sortDirection === "ascending"}
                  onClick={() =>
                    updateSorting(
                      "name",
                      sortProperty === "name" && sortDirection === "ascending"
                        ? "descending"
                        : "ascending",
                    )
                  }
                >
                  Name
                </TableHeaderItem>
              </StyledTh>
              <StyledTh size="33%">
                <TableHeaderItem
                  active={sortProperty === "status"}
                  reverse={sortDirection === "ascending"}
                  onClick={() =>
                    updateSorting(
                      "status",
                      sortProperty === "status" && sortDirection === "ascending"
                        ? "descending"
                        : "ascending",
                    )
                  }
                >
                  Status
                </TableHeaderItem>
              </StyledTh>
              <StyledTh size="33%">
                <TableHeaderItem
                  active={sortProperty === "proxy"}
                  reverse={sortDirection === "ascending"}
                  onClick={() =>
                    updateSorting(
                      "proxy",
                      sortProperty === "proxy" && sortDirection === "ascending"
                        ? "descending"
                        : "ascending",
                    )
                  }
                >
                  FPD
                </TableHeaderItem>
              </StyledTh>
              <StyledTh size="24px">
                {workspaceId && (
                  <RefreshButton
                    busy={loading}
                    onClick={() => dispatch(loadDataSources(workspaceId))}
                  />
                )}
              </StyledTh>
            </StyledTableHeadings>
          </thead>

          <tbody>
            {sortedDataSources?.map((dataSource) => (
              <DataSourceItem
                dataSource={dataSource}
                key={formatDataSourceKey(dataSource)}
              />
            ))}
          </tbody>
        </StyledTable>
      )}
    </>
  );
}
