import { Icon } from "@fiberplane/ui";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { useListPinnedViewsQuery } from "../../api";
import {
  selectActiveViewName,
  selectActiveWorkspaceIdOrThrow,
  selectActiveWorkspaceNameOrThrow,
  selectCanDeleteView,
} from "../../selectors";
import { dispatch } from "../../store";
import { pinView, showDeleteViewConfirmation, unpinView } from "../../thunks";
import type { View } from "../../types";
import { MenuItem, ToggleMenu, type ToggleMenuProps } from "../UI";

export const ViewToggleMenu = ({
  className,
  toggleElement,
  view,
}: {
  className?: string;
  toggleElement: ToggleMenuProps["toggleElement"];
  view: View;
}) => {
  const { workspaceId } = useSelector(selectViewToggleState);

  const { isPinned } = useListPinnedViewsQuery(
    { workspaceId },
    {
      selectFromResult: ({ data, ...rest }) => ({
        isPinned: data?.some(({ name }) => name === view.name) ?? false,
        ...rest,
      }),
    },
  );

  return (
    <ToggleMenu
      placement="bottom-end"
      toggleElement={toggleElement}
      className={className}
      data-testid="view-toggle"
    >
      <MenuItem
        id="delete"
        title="Delete view"
        onActivate={() => dispatch(showDeleteViewConfirmation(view.name))}
        iconLeft={<Icon iconType="trash" />}
      />
      {isPinned ? (
        <MenuItem
          id="unpin"
          title="Unpin view"
          onActivate={() => dispatch(unpinView(view.name))}
          iconLeft={<Icon iconType="push_pin_slash" />}
        />
      ) : (
        <MenuItem
          id="pin"
          title="Pin view"
          onActivate={() => dispatch(pinView(view.name))}
          iconLeft={<Icon iconType="push_pin" />}
        />
      )}
    </ToggleMenu>
  );
};

const selectViewToggleState = createSelector(
  [
    selectActiveViewName,
    selectCanDeleteView,
    selectActiveWorkspaceIdOrThrow,
    selectActiveWorkspaceNameOrThrow,
  ],
  (activeViewName, canDeleteView, workspaceId, workspaceName) => ({
    activeViewName,
    canDeleteView,
    workspaceId,
    workspaceName,
  }),
);
