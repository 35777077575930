import { createSelector } from "reselect";
import type { RootState } from "../state";

export function makePresenceSummaryByNotebookIdSelector(notebookId: string) {
  return createSelector(
    [(state: RootState) => state.presence.notebookPresence[notebookId]],
    (sessions) => {
      return sessions;
    },
  );
}
