import { MetricsChart } from "@fiberplane/charts";
import { Icon, cancelEvent } from "@fiberplane/ui";
import { motion, useReducedMotion } from "framer-motion";
import Prism from "prismjs";
import "prismjs/plugins/line-numbers/prism-line-numbers.js";
import {
  type ReactElement,
  type ReactNode,
  type RefObject,
  isValidElement,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import { css, keyframes, styled } from "styled-components";
import { MiniButton } from ".";
import {
  useChartTheme,
  useTimeTickFormatterForceMinutesHack,
} from "../../../hooks";
import {
  selectCurrentUser,
  selectLastCell,
  selectNotebookTimeRange,
} from "../../../selectors";
import { useAppDispatch } from "../../../store";
import { addAndInvokeProviderCell, addCell, focusCell } from "../../../thunks";
import type { Formatting } from "../../../types";
import { encodeQueryData } from "../../../utils";
import { ProfileAvatar } from "../../Avatar";
import {
  ProviderContainer,
  QueryFieldContainer,
  replaceSelection,
} from "../../Cell";
import { ErrorBoundary } from "../../ErrorBoundary";
import { codePreStyling } from "../../UI";
import { usePrometheusQuery } from "../hooks";
import type { CopilotAction, Message } from "./types";
import { usePrismLineNumbersHack } from "./usePrismLineNumbers";
import type { UserReferenceProps } from "./zhuzh";

export const ChatMessage = ({
  message,
  showActions,
  handleActionSubmit,
}: {
  message: Message;
  showActions: boolean;
  handleActionSubmit?: (a: CopilotAction) => void;
  // this complex function is for demo
}) => {
  const shouldReduceMotion = useReducedMotion();
  const dispatch = useAppDispatch();
  const lastCellId = useSelector(selectLastCell)?.id ?? "";
  const user = useSelector(selectCurrentUser);
  const userId = user?.id ?? "";
  const userName = user?.name ?? "Anon";
  const actions = message?.actions;
  const hasActions = typeof actions?.length === "number" && actions.length > 0;
  return (
    <motion.div
      initial={{ opacity: 0, y: 16 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -16 }}
      transition={{
        duration: shouldReduceMotion ? 0 : 0.2,
        ease: "easeOut",
      }}
    >
      <MessageHeader>
        <ChatAvatarContainer>
          {message.isFromUser ? (
            <ProfileAvatar name={userName} userId={userId} size={16} />
          ) : (
            <StyledCopilotAvatar iconType="sparkle_gradient" />
          )}
        </ChatAvatarContainer>
        {message.isFromUser ? "You" : "Copilot"}
      </MessageHeader>
      {message.type === "summary" ? (
        <StyledProviderContainer>
          <StyledQueryFieldContainer>
            <QueryHeader>
              Summary{" "}
              <MiniButton
                buttonStyle="secondary"
                disabled={!showActions || message.isLoading}
                onClick={(e) => {
                  cancelEvent(e);
                  const newCellId = dispatch(
                    addCell({
                      relatedId: lastCellId,
                      position: "after",
                      properties: {
                        type: "heading",
                        headingType: "h3",
                      },
                    }),
                  );

                  dispatch(focusCell({ cellId: newCellId }));

                  dispatch(
                    replaceSelection(
                      {
                        cellId: newCellId,
                        offset: 0,
                        type: "collapsed",
                      },
                      "Summary",
                    ),
                  );

                  const newNewCellId = dispatch(
                    addCell({
                      relatedId: newCellId,
                      position: "after",
                    }),
                  );

                  dispatch(focusCell({ cellId: newNewCellId }));

                  const fancyMessage = messageContentToFormattedText(
                    message.content,
                  );

                  dispatch(
                    replaceSelection(
                      {
                        cellId: newNewCellId,
                        offset: 0,
                        type: "collapsed",
                      },
                      fancyMessage.content,
                      fancyMessage.formatting,
                    ),
                  );

                  handleActionSubmit?.({
                    type: "ADD_SUMMARY_TO_NOTEBOOK",
                    label: "Add summary to notebook",
                  });
                }}
              >
                Insert
              </MiniButton>
            </QueryHeader>
          </StyledQueryFieldContainer>
          <SummaryContent>
            {message.content}
            {message.isLoading && (
              <CopilotCursorContainer>
                {!!message.content && " "}
                <CopilotCursor />
                {!message.content && <Thinking>Writing response…</Thinking>}
              </CopilotCursorContainer>
            )}
          </SummaryContent>
        </StyledProviderContainer>
      ) : message.isLoading || message.type === "text" ? (
        <MessageContentContainer>
          <MessageContent>
            {message.content}
            {!hasActions && message.isLoading && (
              <CopilotCursorContainer>
                {!!message.content && " "}
                <CopilotCursor />
                {!message.content && <Thinking>Writing response…</Thinking>}
              </CopilotCursorContainer>
            )}
          </MessageContent>
          {showActions && hasActions && (
            <MessageActions>
              {actions.map((action) => (
                <MiniButton
                  key={action.type}
                  buttonStyle="secondary"
                  onClick={(e) => {
                    cancelEvent(e);
                    handleActionSubmit?.(action);
                  }}
                >
                  {action.label}
                </MiniButton>
              ))}
            </MessageActions>
          )}
        </MessageContentContainer>
      ) : message.type === "query" ? (
        <StyledProviderContainer>
          <StyledQueryFieldContainer>
            <QueryHeader>
              PromQL query{" "}
              <MiniButton
                buttonStyle="secondary"
                onClick={(e) => {
                  cancelEvent(e);
                  dispatch(
                    addAndInvokeProviderCell({
                      intent: "prometheus,timeseries",
                      queryData: encodeQueryData({
                        query: message.content.toString(),
                      }),
                    }),
                  );
                }}
              >
                Insert
              </MiniButton>
            </QueryHeader>
            <PromQlQuery query={message.content.toString()} />
          </StyledQueryFieldContainer>
          <ErrorBoundary>
            <QueryResponse query={message.content} />
          </ErrorBoundary>
        </StyledProviderContainer>
      ) : (
        <>Not yet implemented</>
      )}
    </motion.div>
  );
};

const QueryResponse = ({ query }: { query: string | React.ReactNode }) => {
  const { data, loading, error } = usePrometheusQuery(query?.toString() ?? "");
  const globalTimeRange = useSelector(selectNotebookTimeRange);
  const chartTheme = useChartTheme();
  const tickFormattersFactory = useTimeTickFormatterForceMinutesHack("none");
  if (error) {
    return <MessageContent>{String(error)}</MessageContent>;
  }
  return (
    <ChartContainer>
      <MetricsChart
        chartTheme={chartTheme}
        graphType="line"
        readOnly
        gridRowsShown={false}
        gridColumnsShown={false}
        axisLinesShown={false}
        stackingType="none"
        tickFormatters={tickFormattersFactory}
        timeseriesData={loading ? [] : data ?? []}
        timeRange={globalTimeRange}
        legendShown={false}
        stackingControlsShown={false}
        numXTicks={7}
        numYTicks={4}
      />
    </ChartContainer>
  );
};

const PromQlQuery = ({ query }: { query: string }) => {
  const grammar = Prism.languages.promql ?? {};
  const __html = Prism.highlight(query, grammar, "promql");
  const preRef = useRef<HTMLPreElement>(null);
  const codeRef = useRef<HTMLDivElement>(null);

  usePrismLineNumbersHack({
    preRef,
    codeRef,
    grammar,
    query,
  });

  return (
    <PromQlPre className="line-numbers" ref={preRef}>
      {/* biome-ignore lint/security/noDangerouslySetInnerHtml: necessary for how we use Prism */}
      <code dangerouslySetInnerHTML={{ __html }} ref={codeRef} />
    </PromQlPre>
  );
};

const QueryHeader = styled.div`
  font: ${({ theme }) => theme.font.headings.h5};
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.color.notebook.cell.bg.emphasis.neutral};
  padding: 4px 8px;
`;

const StyledProviderContainer = styled(ProviderContainer)`
  margin: 16px 0 0;
  gap: 12px;
  padding: 0;
  border: none;
  background: transparent;
`;

const ChartContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.color.border.muted};
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: 12px;
`;

const MessageHeader = styled.header`
  font: ${({ theme }) => theme.font.body.md.medium};
  display: flex;
  gap: 8px;
  align-items: center;
`;

const StyledCopilotAvatar = styled(Icon).attrs({ height: 16 })`
  color: ${({ theme }) => theme.color.fg.muted};
`;

const ChatAvatarContainer = styled.div`
  display: flex;
  align-items: center;
  height: 16px;
  width: 16px;
  margin-left: -24px;
`;

const MessageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`;

const MessageContent = styled.div`
  font: ${({ theme }) => theme.font.body.md.regular};
  gap: 4px;
`;

const MessageActions = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const SummaryContent = styled.div`
  padding-left: 8px;
`;

const cursorKeyframes = keyframes`
  0%, 100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
`;

const CopilotCursorContainer = styled.span`
  display: inline-flex;
  gap: 4px;
  align-items: center;
`;

const CopilotCursor = styled.div`
  width: 6px;
  height: 16px;
  background: ${({ theme }) => theme.color.accent.aubergine[300]};
  animation: ${cursorKeyframes} 800ms infinite;
  transform: translateY(2px);
`;

const Thinking = styled.span`
  color: ${({ theme }) => theme.color.fg.subtle};
  transform: translateY(2px);
`;

const PromQlPre: React.FC<{
  className: string;
  children: React.ReactNode;
  ref: RefObject<HTMLPreElement>;
}> = styled.pre(
  ({ theme }) => css`
  font: ${theme.font.code.regular};
  ${codePreStyling}
  font-size: 14px;
  padding: 16px 12px;
  overflow-x: scroll; /* NOTE - This can cause overflowing of the pre outside the container */

  background-color: inherit;
  text-shadow: 0 1px ${theme.color.bg.default};

  transition: background-color 0.2s ease-in-out;

  color: ${theme.color.fg.default};

  /* Handle common cases */
  .token.comment {
    color: ${theme.color.fg.subtle};
  }

  .token.operator,
  .token.url {
    background: transparent;
    color: ${theme.color.fg.default};
  }

  .token.keyword {
    color: ${theme.color.fg.primary};
  }

  .token.variable,
  .token.regex {
    color: ${theme.color.fg.accent[3]};
  }

  .token.number,
  .token.boolean,
  .token.tag,
  .token.constant,
  .token.symbol,
  /* .token.attr-name, */
  .token.selector,
  .token.range-duration { /* PromQL specific */
    color: ${theme.color.fg.danger};
  }

  /* HACK - because our weird promql syntax with regex matching on __name__ is not playing nice with prismjs */
  .token.attr-name {
    color: ${theme.color.fg.default};

  }

  .token.punctuation {
    color: ${theme.color.fg.muted};
  }

  .token.string,
  .token.char,
  .token.attr-value,
  .token.label-value { /* PromQL specific */
    color: ${theme.color.fg.accent[2]};
  }

  .token.function,
  .token.class-name {
    color: ${theme.color.fg.accent[1]};
  }
`,
);

const StyledQueryFieldContainer = styled(QueryFieldContainer)`
  flex-direction: column;
  padding: 0;
  overflow: hidden;

  pre.line-numbers {
    position: relative;
    padding-left: 3.4em;
    counter-reset: linenumber;
  }

  pre.line-numbers > code {
    position: relative;
    white-space: inherit;
  }

  .line-numbers .line-numbers-rows {
    position: absolute;
    pointer-events: none;
    top: 0;
    font-size: 100%;
    left: -3.4em;
    width: 2.4em; /* works for line-numbers below 1000 lines */
    letter-spacing: 0;
    border-right: 0px solid ${(props) => props.theme.color.fg.subtle};

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

  }

    .line-numbers-rows > span {
      display: block;
      counter-increment: linenumber;
    }

      .line-numbers-rows > span:before {
        content: counter(linenumber);
        color: ${(props) => props.theme.color.fg.subtle};
        display: block;
        text-align: right;
      }

`;

// const testNodes = [
//   "Hello, ",
//   <NotebookReference to="/hi">notebook</NotebookReference>,
//   "!",
//   <div key="div">This is a div</div>,
// ];

// console.log(messageContentToFormattedText(testNodes));

function messageContentToFormattedText(content: Message["content"]) {
  if (typeof content === "string") {
    return { content };
  }

  let result = "";
  const formatting: Formatting = [];

  for (const node of content) {
    if (typeof node === "string") {
      result += node;
    } else if (isValidElement(node) && isLink(node)) {
      const { to, children } = node.props;

      const filteredChildren = children
        ?.filter((child: unknown) => typeof child === "string")
        .join(" ");

      formatting.push({
        type: "start_link",
        url: to,
        offset: result.length,
      });

      result += filteredChildren;

      formatting.push({
        type: "end_link",
        offset: result.length,
      });
    } else if (isValidElement(node) && isUser(node)) {
      const { user } = node.props;

      formatting.push({
        type: "mention",
        name: user.name,
        userId: user.id,
        offset: result.length,
      });

      result += `@${user.name}`;
    } else {
      // NOT IN USE -- but could be useful? idk
      // const { children } = node.props;
      // if (Array.isArray(children)) {
      //   // NOTE - recursion
      //   // NOTE - does not use formatting of children
      //   result += messageContentToFormattedText(children).content;
      // } else {
      //   result += children;
      // }
    }
  }

  return {
    content: result,
    formatting,
  };
}

function isLink(node: ReactNode) {
  return isValidElement(node) && typeof node?.props?.to === "string";
}

function isUser(node: ReactNode): node is ReactElement<UserReferenceProps> {
  return (
    isValidElement(node) &&
    typeof node?.props?.user?.id === "string" &&
    typeof node?.props?.user?.name === "string"
  );
}
