import { IconButton } from "@fiberplane/ui";
import { css, styled } from "styled-components";

export const cellIconStyling = css`
  margin-left: 1px;

  &:first-child {
    margin-left: 0;
  }
`;

export const CellIconButton = styled(IconButton).attrs({
  buttonStyle: "tertiary-grey",
})<{
  $isAlwaysVisible?: boolean;
}>`
  ${cellIconStyling}

  opacity: 0;

  ${({ $isAlwaysVisible }) => css`
    opacity: ${$isAlwaysVisible ? 1 : 0};
  `}
`;
