import { type ReactElement, memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

import { setFocus, withActiveNotebook } from "../../../../../actions";
import { selectNotebookTimeRange } from "../../../../../selectors";
import { dispatch } from "../../../../../store";
import {
  updateNotebookTimeRange,
  updateQueryField,
} from "../../../../../thunks";
import type { CellFocus } from "../../../../../types";
import {
  cellFocusesAreEqual,
  formatTimeRange,
  isSameTimeRange,
  parseTimeRange,
} from "../../../../../utils";
import { FieldContainer } from "../../../codeStyling";
import { useQueryFieldValidationError } from "../QueryError";
import { TimeRangeFieldContent } from "./TimeRangeFieldContent";

type Props = {
  cellId: string;
  field: string;
  focus: CellFocus;
  readOnly?: boolean;
  value: string;
  liveButton?: ReactElement;
};

export const TimeRangeField = memo(function TimeRangeField({
  cellId,
  field,
  focus,
  readOnly,
  value,
  liveButton,
}: Props): JSX.Element {
  const cellTimeRange = parseTimeRange(value);
  const globalTimeRange = useSelector(selectNotebookTimeRange);
  const timeRange = cellTimeRange || globalTimeRange;
  const isFollowingGlobalTime = !cellTimeRange;

  const resetToGlobalTimeRange = () => {
    dispatch(updateQueryField(cellId, field, null, { autoSubmit: true }));
  };

  const useGlobally =
    isFollowingGlobalTime === false
      ? () => {
          dispatch(updateNotebookTimeRange(timeRange));
          // NOTE - This clears the custom time range for this cell,
          //        which means now we'll inherit the time from the notebook
          resetToGlobalTimeRange();
        }
      : undefined;

  const { errorOutput, ref } = useQueryFieldValidationError<HTMLDivElement>(
    cellId,
    field,
  );

  const [internalFocus, setInternalFocus] = useState<CellFocus>();
  useEffect(() => {
    if (focus.type === "none") {
      setInternalFocus(undefined);
    }
  }, [focus.type]);

  return (
    <>
      <Container ref={ref}>
        <TimeRangeFieldContent
          focus={internalFocus || focus}
          from={timeRange.from}
          to={timeRange.to}
          readOnly={!!readOnly}
          resetToGlobalTimeRange={resetToGlobalTimeRange}
          useGlobally={useGlobally}
          onFocusChange={(newFocus) => {
            const equalFocus =
              internalFocus && cellFocusesAreEqual(internalFocus, newFocus);

            if (!equalFocus) {
              setInternalFocus(newFocus);
            }

            if (focus.type === "none") {
              dispatch(
                withActiveNotebook(
                  setFocus({
                    cellId,
                    type: "collapsed",
                    field,
                    offset:
                      (newFocus.type === "collapsed" && newFocus.offset) || 0,
                  }),
                ),
              );
            }
          }}
          enableLocalTimeRange={() => {
            dispatch(
              updateQueryField(cellId, field, formatTimeRange(timeRange), {
                autoSubmit: false,
              }),
            );
          }}
          onChange={(newTimeRange) => {
            if (isSameTimeRange(timeRange, newTimeRange)) {
              return;
            }

            dispatch(
              updateQueryField(cellId, field, formatTimeRange(newTimeRange), {
                autoSubmit: true,
              }),
            );
          }}
        />
        {liveButton}
      </Container>
      {errorOutput}
    </>
  );
});

const Container = styled(FieldContainer)`
  display: flex;
  position: relative;
  justify-content: space-between;
`;
