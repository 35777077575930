export type TriggerType = "Prometheus";

export interface Trigger {
  id: string;
  title: string;
  templateId: string;
  secretKey?: string;
  // biome-ignore lint/suspicious/noExplicitAny: FIXME
  defaultArguments?: Record<string, any>;
  createdAt: string;
  updatedAt: string;
}

export type TriggerState = {
  error?: string | Error;
  loading: boolean;
  triggers: Array<Trigger>;
};
