import { useHandler } from "@fiberplane/hooks";
import { useEffect, useRef, useState } from "react";

import type { Anchor, CropPosition } from "../types";
import { anchorToDirection } from "../utils";

type DragContext = {
  startX: number;
  startY: number;
  initialX: number;
  initialY: number;
  scale: number;
  width: number;
  height: number;
  anchor: Anchor;
  setState: (position: CropPosition) => void;
};

function getAnchor(element: HTMLElement): Anchor {
  switch (element.dataset.anchor) {
    case "ne":
      return "ne";

    case "nw":
      return "nw";

    case "sw":
      return "sw";

    default:
      return "se";
  }
}

function drag(event: MouseEvent, dragContext: DragContext) {
  const {
    anchor,
    startX,
    startY,
    scale: startScale,
    initialX,
    initialY,
    width,
    height,
    setState,
  } = dragContext;
  const minDimension = Math.min(width, height);

  const { clientX, clientY } = event;

  const diffX =
    anchor === "sw" || anchor === "ne" ? startX - clientX : clientX - startX;
  const diffY = clientY - startY;

  const diffValue = Math.abs(diffX) < Math.abs(diffY) ? diffX : diffY;
  switch (anchor) {
    case "nw": {
      const preferredScale = Math.max(startScale - diffValue / minDimension, 0);
      const scale = Math.min(Math.max(preferredScale, 0), 1);
      const left =
        (initialX + diffValue - (preferredScale - scale) * minDimension) /
        width;
      const top =
        (initialY + diffValue - (preferredScale - scale) * minDimension) /
        height;

      setState({
        left,
        top,
        scale,
      });
      return;
    }

    case "ne": {
      const scale = startScale - diffValue / minDimension;

      if (scale < 0) {
        const left = (initialX - startScale * minDimension) / width;
        const top = (initialY + startScale * minDimension) / height;
        setState({
          left,
          top,
          scale: 0,
        });
        return;
      }

      const left = (initialX - startScale * minDimension) / width;
      const top = (initialY + diffValue) / height;

      setState({
        left,
        top,
        scale,
      });
      return;
    }

    case "se": {
      const scale = startScale + diffValue / minDimension;
      if (scale < 0) {
        const left = (initialX - startScale * minDimension) / width;
        const top = (initialY - startScale * minDimension) / height;
        setState({
          left,
          top,
          scale: 0,
        });
        return;
      }

      // initialX/Y is in the bottom/right corner of the rect
      // so we need to deduct the size of the rect to get the original
      // top/left
      const left = (initialX - startScale * minDimension) / width;
      const top = (initialY - startScale * minDimension) / height;

      setState({
        left,
        top,
        scale,
      });

      return;
    }

    case "sw": {
      const scale = startScale + diffValue / minDimension;
      if (scale < 0) {
        const left = (initialX + startScale * minDimension) / width;
        const top = (initialY - startScale * minDimension) / height;

        setState({
          left,
          top,
          scale: 0,
        });
        return;
      }

      const left = (initialX - diffValue) / width;
      const top = (initialY - startScale * minDimension) / height;

      setState({
        left,
        top,
        scale,
      });

      return;
    }
  }
}

export function useResizeDrag<
  // Container refers to the type for the containerRef
  Container extends HTMLElement,
  // TriggerElement refer sto the element type for the button/resize trigger element
  TriggerElement extends HTMLElement,
>(width: number, height: number, setState: (newState: CropPosition) => void) {
  const [dragContext, setDragContext] = useState<DragContext | null>(null);
  const containerRef = useRef<Container | null>(null);

  // Event handler that begins the drag behaviour.
  // Should be used on "mousedown"
  const dragStart = (event: React.MouseEvent<TriggerElement>) => {
    const { target, clientX: startX, clientY: startY } = event;

    if (target instanceof HTMLButtonElement) {
      const anchor = getAnchor(target);

      const xPercentage = Number.parseFloat(target.dataset.x || "0");
      const yPercentage = Number.parseFloat(target.dataset.y || "0");
      const initialX = (xPercentage * width) / 100;
      const initialY = (yPercentage * height) / 100;
      const scale = Number.parseFloat(target.dataset.scale || "0");
      setDragContext({
        startX,
        startY,
        initialX,
        initialY,
        scale,
        width,
        height,
        anchor,
        setState,
      });
    }
  };

  const onMouseMove = useHandler((event: MouseEvent) => {
    if (dragContext === null) {
      return;
    }

    if (!containerRef.current) {
      return;
    }

    if (setState !== dragContext.setState) {
      // setState got updated while we're dragging
      // this should be an edge case
      setDragContext({
        ...dragContext,
        setState,
      });
      return;
    }

    if (
      event.target &&
      event.target instanceof Node &&
      containerRef.current.contains(event.target as Node) === false
    ) {
      return;
    }

    drag(event, dragContext);
  });

  useEffect(() => {
    if (!dragContext) {
      return;
    }

    // On mouseup reset context
    const onMouseUp = () => setDragContext(null);

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [dragContext, onMouseMove]);

  return {
    dragStart,
    containerRef,
    direction: dragContext?.anchor
      ? anchorToDirection(dragContext.anchor)
      : undefined,
  };
}
