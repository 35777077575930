import { css, styled } from "styled-components";

export const Role = styled.span<{ active?: boolean }>(
  ({ theme, active }) => css`
    grid-area: role;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 8px;
    font: ${theme.font.body.md.regular};
    border-radius: ${theme.radius.lowest};
    background: ${
      active ? theme.color.bg.elevated.hover : theme.color.bg.elevated.default
    };
    color: ${theme.color.fg.default};
    user-select: none;
  `,
);
