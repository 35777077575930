import { Icon } from "@fiberplane/ui";
import { useSelector } from "react-redux";

import { forwardRef } from "react";
import { selectCurrentUser } from "../../selectors";
import { dispatch } from "../../store";
import { showSettingsModal, showSignOutConfirmation } from "../../thunks";
import type { Profile } from "../../types";
import { ProfileAvatar } from "../Avatar";
import { MenuItem, MenuItemGroup, ToggleMenu } from "../UI";
import { NavButton } from "./NavElement";
import { MENU_OFFSET } from "./common";

const CurrentUserToggleElement = forwardRef<
  HTMLDivElement,
  { opened: boolean; user?: Profile }
>(function CurrentUserToggleElement({ opened, user }, ref) {
  return (
    <div ref={ref}>
      <NavButton
        as="button"
        $isActive={opened}
        aria-label="Profile"
        data-tooltip-placement="right"
        aria-pressed={opened}
      >
        <ProfileAvatar
          name={user?.name ?? "Guest"}
          userId={user?.id}
          showTooltip={false}
        />
      </NavButton>
    </div>
  );
});

export function CurrentUser() {
  const user = useSelector(selectCurrentUser);

  return (
    <ToggleMenu
      offset={MENU_OFFSET}
      placement="right-end"
      toggleElement={({ anchorRef, opened }) => (
        <CurrentUserToggleElement opened={opened} ref={anchorRef} user={user} />
      )}
      data-testid="current-user-menu"
    >
      {user && (
        <MenuItemGroup
          iconLeft={<ProfileAvatar name={user.name} userId={user.id} />}
          title={user.name}
        >
          <MenuItem
            title="Profile settings"
            id="profile-settings"
            iconLeft={<Icon iconType="settings_duotone" />}
            onActivate={() =>
              dispatch(showSettingsModal({ section: "profile" }))
            }
          />
          <MenuItem
            title="Sign out"
            id="sign-out"
            iconLeft={<Icon iconType="sign_out" />}
            onActivate={() => dispatch(showSignOutConfirmation())}
          />
        </MenuItemGroup>
      )}
    </ToggleMenu>
  );
}
