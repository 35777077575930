import { generatePath } from "react-router";
import { push } from "redux-first-history";

import {
  HIDE_MODAL,
  SHOW_MODAL,
  removeCells,
  withActiveNotebook,
} from "../../actions";
import { viewsApi, workspacesApi } from "../../api";
import { ROUTES } from "../../constants";
import { NotFoundError, normalizeException } from "../../errors";
import {
  selectActiveViewName,
  selectActiveWorkspaceIdOrThrow,
  selectActiveWorkspaceName,
  selectActiveWorkspaceNameOrThrow,
  selectActiveWorkspaceOrThrow,
  selectCanDeleteView,
  selectPersonalWorkspace,
} from "../../selectors";
import { Api } from "../../services";
import { deleteReceiver } from "../../slices";
import type { Thunk } from "../../store";
import {
  addNotification,
  deleteEvent,
  deleteSnippet,
  deleteView,
  deleteWebhook,
  removeNotebook,
  setNotebookVisibility,
  signOut,
  transferWorkspaceOwnership,
} from "../../thunks";
import type {
  Cell,
  ConfirmationDialogModalProps,
  ConfirmationDialogType,
  DeleteProxyModalProps,
  Membership,
} from "../../types";
import { track } from "../../utils";

export const showConfirmationDialog =
  (key: string, modalProps: ConfirmationDialogModalProps): Thunk =>
  (dispatch) => {
    const onRequestClose = () => {
      dispatch(hideConfirmationDialog(key));
    };

    const modal: ConfirmationDialogType = {
      ...modalProps,
      type: "confirmationDialog",
    };

    dispatch({
      type: SHOW_MODAL,
      payload: {
        key,
        modal: { onRequestClose, modal },
      },
    });
  };

export const hideConfirmationDialog =
  (key: string): Thunk =>
  (dispatch) => {
    dispatch({ type: HIDE_MODAL, payload: { key } });
  };

export const showEventDeleteConfirmation =
  ({ eventId, eventTitle }: { eventId: string; eventTitle: string }): Thunk =>
  (dispatch) => {
    const onConfirm = () => dispatch(deleteEvent(eventId));

    dispatch(
      showConfirmationDialog("deleteSnippetConfirmation", {
        children: `Are you sure you want to delete "${eventTitle}"?`,
        onConfirm,
        title: "Delete event",
        onConfirmButtonStyle: "danger",
        iconVisual: "danger",
      }),
    );
  };

export const showNotebookVisibilityConfirmation =
  (notebookId: string): Thunk =>
  (dispatch) =>
    dispatch(
      showConfirmationDialog("visibilityConfirmation", {
        children:
          "Are you sure you want to make this notebook public? Anyone with the link will be able to view (but not edit) the notebook.",
        onConfirm: () => dispatch(setNotebookVisibility(notebookId, "public")),
        onConfirmLabel: "Make public",
        title: "Set to public",
        iconVisual: "warning",
      }),
    );

export const showNotebookDeleteConfirmation =
  (notebookId: string, redirectPath?: string): Thunk =>
  (dispatch) => {
    const onConfirm = () => {
      dispatch(removeNotebook(notebookId));

      if (redirectPath) {
        dispatch(push(redirectPath));
      }
    };

    return dispatch(
      showConfirmationDialog("deleteNotebookConfirmation", {
        children: "Are you sure you want to delete this notebook?",
        onConfirm,
        onConfirmButtonStyle: "danger",
        onConfirmLabel: "Delete notebook",
        title: "Delete notebook",
        iconVisual: "danger",
      }),
    );
  };

export const showPagerDutyReceiverDeleteConfirmation =
  ({ name }: { name: string }): Thunk =>
  (dispatch) => {
    // TODO: Fix createAsyncThunk types
    // @ts-ignore
    const onConfirm = () => dispatch(deleteReceiver(name));

    dispatch(
      showConfirmationDialog("deleteSnippetConfirmation", {
        children: "Are you sure you want to delete this receiver?",
        onConfirm,
        title: "Delete PagerDuty Receiver",
        onConfirmButtonStyle: "danger",
        iconVisual: "danger",
      }),
    );
  };

export const showSnippetDeleteConfirmation =
  ({ snippetName }: { snippetName: string }): Thunk =>
  (dispatch) => {
    const onConfirm = () => dispatch(deleteSnippet(snippetName));

    dispatch(
      showConfirmationDialog("deleteSnippetConfirmation", {
        children: "Are you sure you want to delete this snippet?",
        onConfirm,
        title: "Delete snippet",
        onConfirmButtonStyle: "danger",
        iconVisual: "danger",
      }),
    );
  };

/**
 * If the user is confirms the deletion, the discussion's thread will be
 * deleted. Then all cells will be deleted from the notebook.
 * @param cellIds Array of all cell ids to delete.
 */
export const showDiscussionCellDeleteConfirmation =
  (cells: Array<Cell>): Thunk =>
  (dispatch) => {
    const onConfirm = () => {
      const cellIds = cells.map((cell) => cell.id);

      for (const cell of cells) {
        if (cell.type === "discussion") {
          Api.deleteThread(cell.threadId);
        }
      }

      dispatch(withActiveNotebook(removeCells(cellIds)));
    };

    const message =
      cells.length > 1
        ? "Your selection contains discussions. Are you sure you want to delete them? This action cannot be undone!"
        : "Your selection contains a discussion. Are you sure you want to delete it? This action cannot be undone!";

    const title = cells.length > 1 ? "Delete discussions" : "Delete discussion";

    dispatch(
      showConfirmationDialog("deleteDiscussionCellConfirmation", {
        children: message,
        onConfirm,
        title,
        iconVisual: "danger",
        onConfirmButtonStyle: "danger",
      }),
    );
  };

export const showSignOutConfirmation = (): Thunk => (dispatch) =>
  dispatch(
    showConfirmationDialog("signOutConfirmation", {
      title: "Sign out",
      onConfirmLabel: "Sign out",
      onConfirm: () => {
        dispatch(signOut());
        track("logout");
      },
      children: "Are you sure you want to sign out?",
      iconVisual: "warning",
    }),
  );

export const showWorkspaceDeleteConfirmation =
  (workspaceId: string): Thunk =>
  (dispatch, getState) => {
    const onConfirm = async () => {
      await dispatch(
        workspacesApi.endpoints.deleteWorkspace.initiate({ workspaceId }),
      );
      dispatch(addNotification({ title: "Workspace has been deleted" }));

      const personalWorkspace = selectPersonalWorkspace(getState());
      if (!personalWorkspace) {
        // This should not happen, as personal workspaces can't be deleted
        // If this is thrown the state is incorrect
        throw new Error("No personal workspace");
      }

      dispatch(
        push(
          generatePath(ROUTES.GettingStarted, {
            workspaceName: personalWorkspace.name,
          }),
        ),
      );
    };

    dispatch(
      showConfirmationDialog("workspaceDeleteConfirmation", {
        children: "Are you sure you want to delete the workspace?",
        title: "Delete workspace",
        onConfirmLabel: "Delete workspace",
        onConfirm,
        onConfirmButtonStyle: "danger",
        iconVisual: "danger",
      }),
    );
  };

export const showWorkspaceRemoveMemberConfirmation =
  (member: Membership): Thunk =>
  (dispatch, getState) => {
    const workspace = selectActiveWorkspaceOrThrow(getState());

    const onConfirm = () => {
      try {
        dispatch(
          workspacesApi.endpoints.deleteMember.initiate({
            workspaceId: workspace.id,
            userId: member.id,
          }),
        );
        dispatch(
          addNotification({
            title: `${member.name} has been removed from the workspace.`,
          }),
        );
      } catch (error) {
        console.warn(error);
        dispatch(
          addNotification({
            type: "danger",
            title: "Something went wrong removing the user",
          }),
        );
      }
    };

    dispatch(
      showConfirmationDialog("workspaceRemoveMemberConfirmation", {
        children: (
          <>
            Are you sure you want to remove <strong>{member.name}</strong> from
            workspace <strong>{workspace.displayName}</strong>?
          </>
        ),
        title: "Remove workspace member",
        onConfirmLabel: "Remove member",
        onConfirm,
        onConfirmButtonStyle: "danger",
        iconVisual: "danger",
      }),
    );
  };

export const showWorkspaceLeaveConfirmation =
  (): Thunk => (dispatch, getState) => {
    const workspace = selectActiveWorkspaceOrThrow(getState());

    const onConfirm = async () => {
      await dispatch(
        workspacesApi.endpoints.leaveWorkspace.initiate({
          workspaceId: workspace.id,
        }),
      );

      const personalWorkspace = selectPersonalWorkspace(getState());
      dispatch(
        push(
          generatePath(ROUTES.GettingStarted, {
            workspaceName: personalWorkspace?.name ?? "Unknown workspace",
          }),
        ),
      );
    };

    dispatch(
      showConfirmationDialog("workspaceLeaveConfirmation", {
        title: "Leave workspace",
        onConfirmLabel: "Leave workspace",
        onConfirm,
        onConfirmButtonStyle: "danger",
        children: (
          <>
            Are you sure you want to leave workspace{" "}
            <strong>{workspace.displayName}</strong>? You can’t undo this action
            and you will lose access to all private notebooks in this workspace.
          </>
        ),
        iconVisual: "danger",
      }),
    );
  };

const WORKSPACE_TRANSFER_OWNERSHIP_DIALOG_KEY =
  "workspaceTransferOwnershipConfirmation";

export const showWorkspaceTransferOwnershipConfirmation =
  (member: Membership): Thunk =>
  (dispatch, getState) => {
    const workspace = selectActiveWorkspaceOrThrow(getState());

    const hide = () =>
      dispatch(hideConfirmationDialog(WORKSPACE_TRANSFER_OWNERSHIP_DIALOG_KEY));

    const onConfirm = () => {
      dispatch(transferWorkspaceOwnership(member));
      hide();
    };

    dispatch(
      showConfirmationDialog(WORKSPACE_TRANSFER_OWNERSHIP_DIALOG_KEY, {
        title: "Transfer ownership",
        onConfirmLabel: "Transfer ownership",
        onConfirm,
        onConfirmButtonStyle: "danger",
        children: (
          <>
            Are you sure you want to make <strong>{member.name}</strong> the
            only owner of workspace <strong>{workspace.displayName}</strong>?
            You can’t undo this action.
          </>
        ),
        iconVisual: "danger",
      }),
    );
  };

export const showDeleteInviteConfirmation =
  (invitationId: string): Thunk =>
  async (dispatch, getState) => {
    const workspaceId = selectActiveWorkspaceIdOrThrow(getState());

    const { data = [] } = await dispatch(
      workspacesApi.endpoints.listInvites.initiate({
        workspaceId,
      }),
    );

    const invitation = data.find(({ id }) => id === invitationId);

    if (!invitation) {
      throw new Error(`Unable to find matching invite (${invitationId})`);
    }

    const onConfirm = async () => {
      await dispatch(
        workspacesApi.endpoints.deleteInvite.initiate({
          invitationId,
        }),
      );

      dispatch(
        addNotification({ title: "Invite has been deleted", type: "warning" }),
      );
    };

    dispatch(
      showConfirmationDialog("workspaceTransferOwnershipConfirmation", {
        title: "Delete workspace invite",
        onConfirmLabel: "Delete",
        onConfirm,
        onConfirmButtonStyle: "danger",
        children: (
          <>
            Are you sure you want to delete the invite for{" "}
            <strong>{invitation.receiver}</strong>?
          </>
        ),
        iconVisual: "danger",
      }),
    );
  };

export const showSnippetSavedSuccessfullyDialog =
  (): Thunk => (dispatch, getState) => {
    const workspaceName = selectActiveWorkspaceName(getState());

    const onCancel = () => {
      if (workspaceName) {
        dispatch(push(generatePath(ROUTES.Snippets, { workspaceName })));
      }
    };

    dispatch(
      showConfirmationDialog("snippetSavedSuccessfully", {
        title: "Snippet saved!",
        children:
          "Your snipped was saved successfully. You can insert the snippet in any notebook using the ‘/’-menu.",
        iconVisual: "success",
        onConfirmLabel: "Continue in notebook",
        onCancel,
        onCancelLabel: workspaceName ? "Go to snippets overview" : "Close",
      }),
    );
  };

export const showDeleteProxyConfirmationDialog =
  ({ name, onConfirm }: DeleteProxyModalProps): Thunk =>
  (dispatch) => {
    dispatch(
      showConfirmationDialog("deleteProxyConfirmation", {
        title: "Delete FPD",
        children: (
          <>
            By deleting an FPD instance, all related data sources will be
            removed as well. Are you sure you want to delete FPD{" "}
            <strong>{name}</strong>?
          </>
        ),
        iconVisual: "danger",
        onConfirmLabel: "Delete",
        onConfirm,
        onConfirmButtonStyle: "danger",
      }),
    );
  };

export const showDeleteViewConfirmation =
  (viewName: string): Thunk =>
  async (dispatch, getState) => {
    const state = getState();
    const workspaceId = selectActiveWorkspaceIdOrThrow(state);

    if (!selectCanDeleteView(state)) {
      dispatch(
        addNotification({
          type: "danger",
          title: "You don't have permission to delete views in this workspace",
        }),
      );

      return;
    }

    try {
      const view = await dispatch(
        viewsApi.endpoints.getViewByName.initiate({ viewName, workspaceId }),
      ).unwrap();

      dispatch(
        showConfirmationDialog("deleteView", {
          children: `Are you sure you want to delete view ${view.displayName}?`,
          onConfirm: () => dispatch(deleteView(viewName)),
          onConfirmButtonStyle: "danger",
          onConfirmLabel: "Delete view",
          title: "Delete view",
          iconVisual: "danger",
        }),
      );
    } catch (error) {
      const normalizedError = normalizeException(error);
      if (normalizedError instanceof NotFoundError) {
        dispatch(
          viewsApi.util.invalidateTags([
            { type: "PinnedView", id: viewName },
            { type: "View", id: viewName },
          ]),
        );
      } else {
        dispatch(
          addNotification({
            type: "danger",
            title: "An error ocurred while trying to delete the view",
          }),
        );
      }

      const activeViewName = selectActiveViewName(state);
      if (activeViewName === viewName) {
        const workspaceName = selectActiveWorkspaceNameOrThrow(state);
        dispatch(push(generatePath(ROUTES.Views, { workspaceName })));
      }
    }
  };

export const showWebhookDeleteConfirmation =
  (webhookId: string): Thunk =>
  (dispatch) => {
    const onConfirm = () => {
      dispatch(deleteWebhook(webhookId));
    };

    return dispatch(
      showConfirmationDialog("deleteWebhookConfirmation", {
        children: "Are you sure you want to delete this webhook?",
        onConfirm,
        onConfirmButtonStyle: "danger",
        onConfirmLabel: "Delete webhook",
        title: "Delete webhook",
        iconVisual: "danger",
      }),
    );
  };
