import type {
  Notebook,
  SortDirection,
  Template,
  TemplateListSortFields,
} from "../types";
import { baseApi } from "./base";
import { withQueryArgs } from "./utils";

export type NewTemplate = Pick<Template, "body" | "description" | "name">;

export type TemplateSummary = Pick<
  Template,
  "createdAt" | "description" | "name" | "updatedAt"
>;

export const templatesApi = baseApi
  .injectEndpoints({
    endpoints: (builder) => ({
      convertNotebookToTemplate: builder.query<string, { notebookId: string }>({
        query: ({ notebookId }) => ({
          url: `/notebooks/${notebookId}/template.jsonnet`,
          responseHandler: "text",
        }),
      }),
      createTemplate: builder.mutation<
        Template,
        { workspaceId: string; newTemplate: NewTemplate }
      >({
        query: ({ workspaceId, newTemplate }) => ({
          url: `/workspaces/${workspaceId}/templates`,
          method: "POST",
          body: newTemplate,
        }),
      }),
      expandTemplate: builder.mutation<
        Notebook,
        { workspaceId: string; templateName: string; parameters: object }
      >({
        query: ({ workspaceId, templateName, parameters: body }) => ({
          url: `/workspaces/${workspaceId}/templates/${templateName}/expand`,
          method: "POST",
          body,
        }),
      }),
      getTemplateByName: builder.query<
        Template,
        { workspaceId: string; templateName: string }
      >({
        query: ({ workspaceId, templateName }) =>
          `/workspaces/${workspaceId}/templates/${templateName}`,
      }),
      listTemplates: builder.query<
        Array<TemplateSummary>,
        {
          workspaceId: string;
          sortBy?: TemplateListSortFields;
          sortDirection?: SortDirection;
        }
      >({
        query: ({
          workspaceId,
          sortBy = "updated_at",
          sortDirection = "descending",
        }) =>
          withQueryArgs(`/workspaces/${workspaceId}/templates`, {
            // biome-ignore lint/style/useNamingConvention: API field name is correct
            sort_by: sortBy,
            // biome-ignore lint/style/useNamingConvention: API field name is correct
            sort_direction: sortDirection,
          }),
      }),
    }),
    overrideExisting: false,
  })
  .enhanceEndpoints({
    addTagTypes: ["Notebooks", "Templates"],
    endpoints: {
      createTemplate: {
        invalidatesTags: [{ id: "LIST", type: "Templates" }],
      },
      expandTemplate: {
        invalidatesTags: [{ id: "LIST", type: "Notebooks" }],
      },
      getTemplateByName: {
        providesTags: (_result, _error, { templateName }) => [
          { id: templateName, type: "Templates" },
        ],
      },
      listTemplates: {
        providesTags: [{ id: "LIST", type: "Templates" }],
      },
    },
  });

export const {
  useConvertNotebookToTemplateQuery,
  useCreateTemplateMutation,
  useExpandTemplateMutation,
  useGetTemplateByNameQuery,
  useListTemplatesQuery,
} = templatesApi;
