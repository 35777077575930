import { type ReactElement, useMemo } from "react";

import type { CellFocus, Profile } from "../../../../types";
import { getField, sortBy } from "../../../../utils";
import { processFormatting } from "../processFormatting";
import type { ExtendedFormatting } from "../types";
import type { CellSubscriber } from "./useCellSubscribers";
import { useEntityInputFormatting } from "./useEntityInputFormatting";
import { noFormatting, useSelectionFormatting } from "./useSelectionFormatting";

export function useContentWithFormatting(
  cellId: string,
  focus: CellFocus,
  field: string | undefined,
  text: string,
  formatting: ExtendedFormatting = noFormatting,
  placeholder?: string,
  currentUser?: Profile,
  cellSubscribers?: Array<CellSubscriber>,
): Array<ReactElement> {
  const selectionFormatting = useSelectionFormatting(
    focus,
    field,
    currentUser,
    cellSubscribers,
  );

  const entityInputFormatting = useEntityInputFormatting(
    cellId,
    getField(focus),
  );

  const allFormatting = useMemo(
    () =>
      sortBy(
        [...formatting, ...selectionFormatting, ...entityInputFormatting],
        ({ offset }) => offset,
      ),
    [entityInputFormatting, formatting, selectionFormatting],
  );

  return useMemo(
    () =>
      processFormatting({
        text: text,
        placeholder,
        formatting: allFormatting,
      }),
    [allFormatting, placeholder, text],
  );
}
