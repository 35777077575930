import { userApi } from "../api";
import type { AuthenticationState, RootState } from "../state";

export const selectAuthentication = (state: RootState): AuthenticationState =>
  state.authentication;

export const selectIsAuthenticated = (state: RootState): boolean =>
  !!selectAuthentication(state).token;

export const selectCurrentUser = (state: RootState) =>
  // Unfortunately splitting the API causes typing issues on selectors
  // https://github.com/reduxjs/redux-toolkit/issues/2484
  // @ts-expect-error
  userApi.endpoints.getOwnProfile.select()(state)?.data;

export const selectDefaultWorkspaceName = (state: RootState) =>
  selectCurrentUser(state)?.defaultWorkspaceName;
