import { useHandler } from "@fiberplane/hooks";
import { Button } from "@fiberplane/ui";
import { useState } from "react";
import type { FieldErrors } from "react-hook-form";
import type { z } from "zod";

import { updateReceiver } from "../../../slices";
import { dispatch } from "../../../store";
import { FiberForm, FiberFormTextInputField } from "../../FiberForm";
import { ButtonBar } from "../../UI";
import { CreateReceiverFormState } from "./schemas";

type UpdateSecretFormProps = {
  name: string;
  onCancel: () => void;
  onSuccess: () => void;
};

const FormState = CreateReceiverFormState.pick({ secret: true });

type FormState = z.infer<typeof FormState>;

export function InitialSecretForm({
  name,
  onCancel,
  onSuccess,
}: UpdateSecretFormProps) {
  const [errors, setErrors] = useState<FieldErrors<FormState>>();

  const onSubmit = useHandler(async (payload: FormState) => {
    await dispatch(updateReceiver({ updatePagerDutyReceiver: payload, name }));
    onSuccess();
  });

  return (
    <FiberForm
      errors={errors}
      schema={FormState}
      submitErrorHandler={setErrors}
      submitHandler={onSubmit}
    >
      {(control) => (
        <>
          <FiberFormTextInputField
            label="Secret"
            description="Enter the secret key provided by PagerDuty"
            control={control}
            name="secret"
          />
          <ButtonBar>
            <Button type="button" buttonStyle="secondary" onClick={onCancel}>
              Skip for now
            </Button>
            <Button type="submit">Save secret key</Button>
          </ButtonBar>
        </>
      )}
    </FiberForm>
  );
}
