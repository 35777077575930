import { Icon } from "@fiberplane/ui";
import { useSelector } from "react-redux";
import { NavLink, generatePath, useSearchParams } from "react-router-dom";
import { css, styled } from "styled-components";

import { ROUTES } from "../../constants";
import { selectActiveWorkspace, selectModalsMap } from "../../selectors";
import { dispatch } from "../../store";
import { showCommandMenu, showSettingsModal } from "../../thunks";
import { isMac } from "../../utils";
import { CurrentUser } from "./CurrentUser";
import { Engage } from "./Engage";
import { NavButton } from "./NavElement";
import { NewNotebook } from "./NewNotebook";
import { WorkspaceToggle } from "./WorkspaceToggle";

export function VerticalNav() {
  const activeWorkspace = useSelector(selectActiveWorkspace);
  const workspaceName = activeWorkspace?.name;

  const modals = useSelector(selectModalsMap);
  const [params] = useSearchParams();

  // Track opened state so we can set the active state of the button
  const isCommandMenuOpen = modals.has("commandMenu");
  const isSettingsOpen = params.get("overlay")?.includes("settings");

  return (
    <VerticalMenuContainer data-testid="vertical-nav">
      <Figure>
        <Icon iconType="fiberplane_logo_inverted" />
      </Figure>

      <MenuItemList>
        <WorkspaceToggleItem data-testid="workspace-toggle">
          {activeWorkspace && (
            <WorkspaceToggle activeWorkspace={activeWorkspace} />
          )}
        </WorkspaceToggleItem>

        <li>
          <NavButton
            as="button"
            role="button"
            onClick={() => dispatch(showCommandMenu())}
            $isActive={isCommandMenuOpen}
            aria-label="Search"
            data-tooltip-placement="right"
            data-shortcut={`${isMac ? "cmd" : "ctrl"}+K`}
            data-testid="quick-access"
          >
            <Icon iconType="search_duotone" />
          </NavButton>
        </li>

        {workspaceName && (
          <>
            <li>
              <NavButton
                as={NavLink}
                to={generatePath(ROUTES.Notebooks, { workspaceName })}
                aria-label="Notebooks"
                data-tooltip-placement="right"
                data-testid="notebooks"
              >
                <Icon iconType="notebook" />
              </NavButton>
            </li>

            <li>
              <NavButton
                as={NavLink}
                to={generatePath(ROUTES.Templates, { workspaceName })}
                aria-label="Templates"
                data-tooltip-placement="right"
                data-testid="templates"
              >
                <Icon iconType="templates" />
              </NavButton>
            </li>

            <li>
              <NavButton
                as={NavLink}
                to={generatePath(ROUTES.Views, { workspaceName })}
                aria-label="Views"
                data-tooltip-placement="right"
                data-testid="views"
              >
                <Icon iconType="views" />
              </NavButton>
            </li>

            <li>
              <NavButton
                as={NavLink}
                to={generatePath(ROUTES.GettingStarted, { workspaceName })}
                end
                aria-label="Getting started"
                data-tooltip-placement="right"
              >
                <Icon iconType="rocket_duotone" />
              </NavButton>
            </li>

            <li>
              <NewNotebook />
            </li>
          </>
        )}
      </MenuItemList>

      <MenuItemList>
        <li>
          <Engage />
        </li>

        <li>
          <NavButton
            as="button"
            role="button"
            onClick={() => dispatch(showSettingsModal())}
            $isActive={isSettingsOpen}
            aria-label="Settings"
            data-tooltip-placement="right"
            data-testid="settings"
          >
            <Icon iconType="settings_duotone" />
          </NavButton>
        </li>

        <li>
          <CurrentUser />
        </li>
      </MenuItemList>
    </VerticalMenuContainer>
  );
}

const VerticalMenuContainer = styled.nav(
  ({ theme }) => css`
    grid-area: nav-vertical;
    display: grid;
    grid-template-rows: var(--nav-width) 1fr auto;
    gap: 4px;
    padding: 0 16px 16px;
    background-color: ${theme.color.bg.subtle};
    border-right: 1px solid ${theme.color.border.muted};
    position: relative;
  `,
);

const Figure = styled.figure`
  display: grid;
  place-items: center;
  margin: 0;
  aspect-ratio: 1;
  align-self: center;
`;

const WorkspaceToggleItem = styled.li`
  && {
    margin-bottom: 4px;
    aspect-ratio: 1;
  }
`;

const MenuItemList = styled.ul`
  display: grid;
  gap: 16px;
  align-content: start;

  /*
    In GlobalStyle; can be removed once the new design feature flag is removed
  */
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin: 0;
    padding: 0;
  }
`;
