import { Button } from "@fiberplane/ui";
import { useSelector } from "react-redux";
import { useTitle } from "react-use";
import useMeasure from "react-use/lib/useMeasure";
import { css, styled } from "styled-components";

import { useListMembersQuery } from "../../api";
import { MEDIUM_SIZE_QUERY } from "../../constants";
import {
  selectActiveWorkspaceOrThrow,
  selectCanInviteWorkspaceMember,
} from "../../selectors";
import { dispatch } from "../../store";
import { showModal, showSettingsModal } from "../../thunks";
import type { Membership } from "../../types";
import { PAGE_MAX_WIDTH, PresenceProfileAvatar } from "../UI";
import { GettingStartedCardContent } from "./GettingStartedCardContent";
import { GettingStartedTemplatesContent } from "./GettingStartedTemplatesContent";

const AVATAR_OFFSET = 16;
const AVATAR_SIZE = 44;
const CONTAINER_PADDING_SM = "24px";
const CONTAINER_PADDING_LG = "48px";
const EMPTY_MEMBERS_LIST: Array<Membership> = [];

export function GettingStarted() {
  useTitle("Getting started");

  const workspace = useSelector(selectActiveWorkspaceOrThrow);
  const canInviteWorkspaceMembers = useSelector(selectCanInviteWorkspaceMember);
  const {
    data: members = EMPTY_MEMBERS_LIST,
    isLoading,
    isFetching,
  } = useListMembersQuery(
    {
      workspaceId: workspace.id,
    },
    { refetchOnMountOrArgChange: true },
  );

  // TODO:
  // We should turn the MemberList into a PresenceContainer once we have workspace events,
  // so we can drop this logic
  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const visibleAvatarCount = Math.floor(
    width / (AVATAR_SIZE - AVATAR_OFFSET / 2),
  );

  return (
    <GettingStartedPageWrapper>
      <SectionContainer>
        <SectionTitle>Getting Started</SectionTitle>
        <SectionSubTitle>
          Get the most out of Fiberplane Notebooks by completing your setup
        </SectionSubTitle>
        <SectionContent>
          <GettingStartedCardContent />
        </SectionContent>
      </SectionContainer>

      <SectionContainer>
        <SectionTitle>Create a Notebook</SectionTitle>
        <SectionSubTitle>
          Use a template to discover how notebooks can work for you
        </SectionSubTitle>
        <SectionContent>
          <GettingStartedTemplatesContent />
        </SectionContent>
      </SectionContainer>

      <SectionContainer>
        <SectionTitle>Fiberplane is better together</SectionTitle>
        {workspace.type === "personal" ? (
          <>
            <SectionSubTitle>
              Unlock collaborative debugging by creating a workspace for your
              team
            </SectionSubTitle>
            <SectionContent>
              <GettingStartedCollaborativeContentContainer>
                <Button
                  buttonStyle="secondary"
                  onClick={() =>
                    dispatch(showModal({ type: "createWorkspace" }))
                  }
                >
                  Create a collaborative workspace
                </Button>
              </GettingStartedCollaborativeContentContainer>
            </SectionContent>
          </>
        ) : canInviteWorkspaceMembers &&
          isLoading === false &&
          members.length === 1 ? (
          <>
            <SectionSubTitle>
              Unlock collaborative debugging by inviting your team members
            </SectionSubTitle>
            <SectionContent>
              <GettingStartedCollaborativeContentContainer>
                <Button
                  buttonStyle="secondary"
                  onClick={() =>
                    dispatch(showModal({ type: "inviteWorkspaceMember" }))
                  }
                >
                  Invite team members
                </Button>
              </GettingStartedCollaborativeContentContainer>
            </SectionContent>
          </>
        ) : (
          <>
            <SectionSubTitle>
              You&apos;re all set up for collaborative debugging
            </SectionSubTitle>
            <SectionContent>
              <GettingStartedCollaborativeContentContainer ref={ref}>
                {!isFetching && (
                  <MemberList>
                    {members?.slice(0, visibleAvatarCount).map((member) => (
                      <Member key={member.id}>
                        <PresenceProfileAvatar
                          userId={member.id}
                          name={member.name}
                          size={AVATAR_SIZE}
                        />
                      </Member>
                    ))}
                  </MemberList>
                )}
                <Button
                  buttonStyle="secondary"
                  onClick={() =>
                    dispatch(showSettingsModal({ section: "members" }))
                  }
                >
                  View your team
                </Button>
              </GettingStartedCollaborativeContentContainer>
            </SectionContent>
          </>
        )}
      </SectionContainer>
    </GettingStartedPageWrapper>
  );
}

const GettingStartedPageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0 ${CONTAINER_PADDING_SM} ${CONTAINER_PADDING_LG};

  @media ${MEDIUM_SIZE_QUERY} {
    padding: 0 ${CONTAINER_PADDING_LG} ${CONTAINER_PADDING_LG};
  }
`;

const SectionContainer = styled.section`
  display: grid;
  grid:
    "title rightContext" auto
    "subtitle subtitle" auto
    "content content" auto / 1fr auto;
  grid-row-gap: 12px;
  margin: 0 auto;
  width: 100%;
  max-width: ${PAGE_MAX_WIDTH};
`;

const SectionTitle = styled.h2`
  grid-area: title;
  margin-bottom: 0;
`;

const SectionSubTitle = styled.p(
  ({ theme }) => css`
    grid-area: subtitle;
    font: ${theme.font.body.md.medium};
    color: ${theme.color.fg.muted};
    margin: 0;
  `,
);

const SectionContent = styled.div`
  grid-area: content;
`;

const GettingStartedCollaborativeContentContainer = styled.div`
  margin-top: 20px;
  display: grid;
  gap: 20px;
  justify-content: start;
`;

const MemberList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: start;
  list-style: none;
  gap: 4px;
  margin-bottom: 20px;
`;

const Member = styled.li`
  &:not(:first-child) {
    margin-left: -${AVATAR_OFFSET}px;
  }
`;
