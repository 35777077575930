import { usePrevious } from "@fiberplane/hooks";
import { useRef } from "react";

export function useEditorValue(value: string) {
  const previousValue = usePrevious(value);
  const editorRef = useRef(value);
  if (previousValue !== value) {
    editorRef.current = value;
  }

  return editorRef;
}
