import { useHandler } from "@fiberplane/hooks";

import { FRONT_MATTER_CELL_ID } from "../../../../../../../constants";
import type { CellFocus, NotebookFocus } from "../../../../../../../types";
import {
  getCellFocusEnd,
  getCellFocusStart,
  noCellFocus,
  toNotebookFocus,
} from "../../../../../../../utils";
import { createCellFocus, isCursorAtTopOrBottom } from "../../utils";
import type { SendCurrentCellFocus } from "./types";
import { sanitizeText, updateBrowserCursorPosition } from "./utils";

type KeyUpParams = {
  field: string;
  getCellFocus(): CellFocus;
  value: string;
  sendCurrentCellFocus: SendCurrentCellFocus;
  setValue: (value: string, focus: NotebookFocus) => void;
  ref: React.RefObject<HTMLElement>;
};

export function useKeyUpHandler(params: KeyUpParams) {
  return useHandler((event: React.KeyboardEvent<HTMLElement>) => {
    handleKeyUp(event, params);
  });
}

function handleKeyUp(
  event: React.KeyboardEvent<HTMLElement>,
  params: KeyUpParams,
) {
  const { field, getCellFocus, value, sendCurrentCellFocus, ref, setValue } =
    params;
  const node = ref.current;
  if (!node) {
    return;
  }

  const cellFocus = getCellFocus();

  // Get the new cell focus. However if the user is pressing shift + enter
  // the value of the cell is not updated correctly (i.e. cursor is set to 0)
  // So we rely on the previous cell focus in that case
  const currentCellFocus =
    event.key === "Enter" && event.shiftKey
      ? createCellFocusForShiftEnter(cellFocus)
      : createCellFocus(node, field);

  if (!handleArrowKeys(event, currentCellFocus, node, field)) {
    return;
  }

  const text = event.currentTarget.textContent;
  if (text === null || sanitizeText(text) === value) {
    sendCurrentCellFocus({ node, field });
  } else {
    setValue(text, toNotebookFocus(currentCellFocus, FRONT_MATTER_CELL_ID));
    event.stopPropagation();
  }
}

function handleArrowKeys(
  event: React.KeyboardEvent,
  cellFocus: CellFocus,
  node: HTMLElement,
  field: string,
): boolean {
  if (
    (event.key === "ArrowLeft" || event.key === "ArrowRight") &&
    !event.shiftKey &&
    cellFocus.type === "selection"
  ) {
    const start = getCellFocusStart(cellFocus);
    const end = getCellFocusEnd(cellFocus);
    const newPosition = event.key === "ArrowLeft" ? start : end;
    const { firstChild } = node;
    if (newPosition !== undefined && firstChild) {
      updateBrowserCursorPosition(firstChild, newPosition, newPosition);
    }
  }

  if (event.key === "ArrowUp" || event.key === "ArrowDown") {
    const { atBottom, atTop } = isCursorAtTopOrBottom(node, field, cellFocus);
    if (
      (event.key === "ArrowUp" && atTop) ||
      (event.key === "ArrowDown" && atBottom)
    ) {
      return false;
    }
  }

  return true;
}

/**
 * Create a new focus position based on the cell focus position
 */
function createCellFocusForShiftEnter(cellFocus: CellFocus): CellFocus {
  switch (cellFocus.type) {
    case "collapsed":
      return {
        ...cellFocus,
        offset: cellFocus.offset === undefined ? 1 : cellFocus.offset + 1,
      };

    case "selection": {
      const { start } = cellFocus;
      return {
        type: "collapsed",
        field: start.field,
        offset: start.offset === undefined ? 1 : start.offset + 1,
      };
    }

    case "none":
      return noCellFocus;
  }
}
