import type { DefaultTheme } from "styled-components";

import type { IconVisualType } from "../../../types";

export function getFrontElementBackgroundColor(
  type: IconVisualType,
  theme: DefaultTheme,
) {
  switch (type) {
    case "danger":
      return theme.color.support.danger.alpha[300];
    case "default":
      return theme.color.neutral.alpha.dark[300];
    case "info":
      return theme.color.primary.brand.alpha[300];
    case "success":
      return theme.color.support.success.alpha[300];
    case "warning":
      return theme.color.support.warning.alpha[300];
  }
}

/**
 * Returns the background color for the back element gradient based on the type
 * of icon visual
 * @param type
 * @returns Left to right gradient color values
 */
export function getBackElementBackground(
  type: IconVisualType,
  theme: DefaultTheme,
) {
  switch (type) {
    case "danger":
      return [
        theme.color.support.danger.alpha[200],
        theme.color.support.danger.alpha[400],
      ];
    case "default":
      return [
        theme.color.neutral.alpha.dark[200],
        theme.color.neutral.alpha.dark[400],
      ];
    case "info":
      return [
        theme.color.primary.brand.alpha[200],
        theme.color.primary.brand.alpha[400],
      ];
    case "success":
      return [
        theme.color.support.success.alpha[200],
        theme.color.support.success.alpha[400],
      ];
    case "warning":
      return [
        theme.color.support.warning.alpha[200],
        theme.color.support.warning.alpha[400],
      ];
  }
}
