import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useMatch } from "react-router";
import { createSelector } from "reselect";

import { push } from "redux-first-history";
import { setActiveWorkspaceName } from "../../actions";
import { useListWorkspacesQuery } from "../../api";
import { ROUTES } from "../../constants";
import {
  selectActiveWorkspaceName,
  selectIsAuthenticated,
  selectPathname,
} from "../../selectors";
import { dispatch } from "../../store";
import { showModal } from "../../thunks";
import type { Workspace } from "../../types";
import {
  getCurrentPathnameUriComponent,
  getWorkspaceNameParameter,
  setLastActiveWorkspaceName,
} from "../../utils";
import { GettingStarted } from "../GettingStarted";
import { NewView } from "../NewView";
import { NotebookWithId } from "../Notebook";
import { Notebooks } from "../Notebooks";
import { ExpandTemplate, NewTemplate, TemplateWithName } from "../Template";
import { TemplatesPage } from "../Templates";
import { ViewWithName } from "../View";
import { Views } from "../Views";
import { WorkspaceError } from "./WorkspaceError";
import { useShouldAddGraphToNotebook } from "./hooks";

const EMPTY_WORKSPACE_LIST: Array<Workspace> = [];

export function Workspaces() {
  const { data: availableWorkspaces = EMPTY_WORKSPACE_LIST } =
    useListWorkspacesQuery();
  const { workspaceNameParameter, activeWorkspaceName } =
    useSelector(selectWorkspaceState);

  useEffect(() => {
    if (
      workspaceNameParameter !== undefined &&
      workspaceNameParameter !== activeWorkspaceName
    ) {
      // We only want to set the activeWorkspaceName if the user is actually a member of the workspace.
      // Public notebooks in another workspace should not override the activeWorkspaceName without membership.
      const workspace = availableWorkspaces.find(
        ({ name }) => name === workspaceNameParameter,
      );

      if (workspace) {
        dispatch(setActiveWorkspaceName(workspace.name));
        setLastActiveWorkspaceName(workspace.name);
      }
    }
  }, [activeWorkspaceName, availableWorkspaces, workspaceNameParameter]);

  // Allow Notebooks to be rendered outside of activeWorkspaceName to support
  // public notebooks in non user workspaces
  const notebookRouteMatch = useMatch(ROUTES.Notebook);
  const isNotebookRoute = notebookRouteMatch?.params.notebookId;

  const shouldAddGraph = useShouldAddGraphToNotebook();
  const workspacesLoaded = availableWorkspaces !== undefined;
  useEffect(() => {
    if (workspacesLoaded && shouldAddGraph) {
      dispatch(showModal({ type: "addGraphToNotebook" }));
    }
  }, [shouldAddGraph, workspacesLoaded]);

  // Should redirect to login page
  const authenticated = useSelector(selectIsAuthenticated);
  const shouldRedirect =
    activeWorkspaceName !== workspaceNameParameter &&
    !isNotebookRoute &&
    !authenticated;

  useEffect(() => {
    if (shouldRedirect) {
      const redirect = getCurrentPathnameUriComponent();
      dispatch(push(`${ROUTES.SignIn}?redirect=${redirect}`));
    }
  }, [shouldRedirect]);

  // No active workspace (auth is fetching)
  if (!(activeWorkspaceName || isNotebookRoute)) {
    return null;
  }

  if (activeWorkspaceName !== workspaceNameParameter && !isNotebookRoute) {
    return <WorkspaceError />;
  }

  return (
    <Routes>
      <Route index element={<GettingStarted />} />

      <Route path="notebooks">
        <Route index element={<Notebooks />} />
        <Route path=":notebookId" element={<NotebookWithId />} />
      </Route>

      <Route path="templates">
        <Route index element={<TemplatesPage />} />
        <Route path="new" element={<NewTemplate />} />

        <Route path=":templateName">
          <Route index element={<TemplateWithName />} />
          <Route path="expand" element={<ExpandTemplate />} />
        </Route>
      </Route>
      <Route path="snippets">
        <Route index element={<TemplatesPage />} />
      </Route>

      <Route path="views">
        <Route index element={<Views />} />
        <Route path="new" element={<NewView />} />
        <Route path=":viewName" element={<ViewWithName />} />
      </Route>
    </Routes>
  );
}

const selectWorkspaceState = createSelector(
  [selectPathname, selectActiveWorkspaceName],
  (pathname, activeWorkspaceName) => ({
    workspaceNameParameter: pathname && getWorkspaceNameParameter(pathname),
    activeWorkspaceName,
  }),
);
