import { css, styled } from "styled-components";

export const Container = styled.div(
  ({ theme }) => css`
    position: relative;
    border-radius: ${theme.borderRadius500};
    border: 1px solid ${theme.colorBase300};
    display: grid;
    grid: minmax(40px, auto) / 1fr auto;
    padding: 12px 12px 12px 24px;
  `,
);

export const Info = styled.p`
  flex: 1;
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 0;
  font: ${({ theme }) => theme.fontNotebooksSmallShortHand};
`;
