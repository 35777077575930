import { css } from "styled-components";
import { stringToNumber } from "../stringToNumber";
import { getSafeIndex } from "./getSafeIndex";

const accentColors = [
  css`
    color: ${({ theme }) => theme.color.accent.aqua[500]};
    background-color: ${({ theme }) => theme.color.accent.aqua.alpha[100]};
  `,
  css`
    color: ${({ theme }) => theme.color.accent.coral[500]};
    background-color: ${({ theme }) => theme.color.accent.coral.alpha[100]};
  `,
  css`
    color: ${({ theme }) => theme.color.accent.earth[500]};
    background-color: ${({ theme }) => theme.color.accent.earth.alpha[100]};
  `,
  css`
    color: ${({ theme }) => theme.color.accent.lime[500]};
    background-color: ${({ theme }) => theme.color.accent.lime.alpha[100]};
  `,
  css`
    color: ${({ theme }) => theme.color.accent.magenta[500]};
    background-color: ${({ theme }) => theme.color.accent.magenta.alpha[100]};
  `,
  css`
    color: ${({ theme }) => theme.color.accent.rust[500]};
    background-color: ${({ theme }) => theme.color.accent.rust.alpha[100]};
  `,
  css`
    color: ${({ theme }) => theme.color.accent.sea[500]};
    background-color: ${({ theme }) => theme.color.accent.sea.alpha[100]};
  `,
  css`
    color: ${({ theme }) => theme.color.accent.sky[500]};
    background-color: ${({ theme }) => theme.color.accent.sky.alpha[100]};
  `,
] as const;

/**
 * Returns color & background color based on string identifier
 */
export function getAccentColors(key: string) {
  const safeIndex = getSafeIndex(stringToNumber(key), accentColors.length);
  return accentColors[safeIndex];
}
