/**
 * A specific position within a {@link NotebookSelection}.
 */
export type NotebookSelectionPosition = {
  cellId: string;
  cellIndex: number;
  field?: string;
  offset?: number;
};

/**
 * A selection that starts and ends at a (surrogate) cell in the notebook.
 *
 * The `focus` of the selection has the same identity as either the `start` or
 * `end` of the selection.
 */
export type NotebookSelection = {
  start: NotebookSelectionPosition;
  end: NotebookSelectionPosition;
  focus: NotebookSelectionPosition;
};
