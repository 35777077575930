import { useHandler } from "@fiberplane/hooks";

import {
  COOKIE_CONSENT_NAME,
  COOKIE_CONSENT_VALUE,
  COOKIE_REJECT_VALUE,
} from "../constants";
import { useCookie } from "./useCookie";

export function useAnalyticsEnabled(): [
  enabled: boolean | undefined,
  setEnabled: (enabled: boolean) => void,
] {
  const [value, setValue] = useCookie(COOKIE_CONSENT_NAME);
  const setEnabled = useHandler((enabled) => {
    setValue(enabled ? COOKIE_CONSENT_VALUE : COOKIE_REJECT_VALUE);
  });

  if (typeof value !== "string" || value === "") {
    return [undefined, setEnabled];
  }

  return [value === COOKIE_CONSENT_VALUE, setEnabled];
}
