import { useHandler } from "@fiberplane/hooks";
import { memo, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import useNetworkState from "react-use/lib/useNetworkState";

import { selectConnectionStatus } from "../selectors";
import type { Notification } from "../state";
import { dispatch } from "../store";
import {
  addNotification,
  removeNotification,
  updateNotification,
} from "../thunks";
import type { ConnectionStatus } from "../types";

export const ConnectionMonitor = memo(function ConnectionMonitor() {
  const notificationKey = useRef<string>();
  const { online } = useNetworkState();
  const courierConnectionStatus = useSelector(selectConnectionStatus);

  const show = useHandler((status: ConnectionStatus) => {
    if (notificationKey.current) {
      dispatch(
        updateNotification(
          notificationKey.current,
          statusToNotification(status),
        ),
      );
    } else {
      notificationKey.current = dispatch(
        addNotification(statusToNotification(status)),
      );
    }
  });

  const hide = useHandler(() => {
    if (notificationKey.current) {
      dispatch(removeNotification(notificationKey.current));
      notificationKey.current = undefined;
    }
  });

  useEffect(() => {
    const status =
      !!online === false ? "disconnected" : courierConnectionStatus;

    switch (status) {
      case "disconnected": {
        const id = setTimeout(() => show(status), 300);
        return () => clearTimeout(id);
      }

      case "connecting": {
        // If there's a notification already, update it
        // immediately
        if (notificationKey.current) {
          show(status);
          return;
        }

        const id = setTimeout(() => show(status), 300);
        return () => clearTimeout(id);
      }

      case "connected": {
        // If there's a notification already, update it
        // immediately
        if (notificationKey.current) {
          show(status);

          const id = setTimeout(() => hide(), 1000);
          return () => clearTimeout(id);
        }

        return;
      }

      default:
        if (notificationKey.current) {
          const id = setTimeout(() => hide(), 500);
          return () => clearTimeout(id);
        }
    }
  }, [courierConnectionStatus, online, show, hide]);

  return null;
});

function statusToNotification(status: ConnectionStatus): Notification {
  switch (status) {
    case "idle":
    case "connecting":
      return {
        title: "Connecting",
        type: "info",
        hideCloseButton: true,
        disableAutoHide: true,
      };

    case "disconnected":
      return {
        title: "You are offline",
        type: "warning",
        description: "Please check your connection",
        hideCloseButton: true,
        disableAutoHide: true,
      };

    default:
      return {
        title: "Connected",
        type: "info",
        hideCloseButton: true,
        disableAutoHide: true,
      };
  }
}
