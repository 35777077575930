import { pickBy } from "./pickBy";

/**
 * Creates an object from another, with one or more properties removed.
 */
export function omit<
  T extends Record<string, unknown>,
  K extends Array<keyof T>,
>(object: T, ...propNames: K): Omit<T, K[number]> {
  return pickBy(object, (_value, key) => !propNames.includes(key)) as Omit<
    T,
    K[number]
  >;
}
