import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CLEAR_AUTHENTICATION } from "../../actions";
import { normalizeException } from "../../errors";
import { selectActiveWorkspaceIdOrThrow } from "../../selectors";
import { Api } from "../../services";
import { dispatch, getState } from "../../store";
import { addNotification, showModal } from "../../thunks";
import type {
  LoadableData,
  LoadableMutation,
  PersonalIntegrationSummary,
  WorkspaceIntegrationSummary,
} from "../../types";
import { linkupProvider, unlinkProvider } from "../profile";
import {
  addLoadableDataReducerCases,
  getInitialLoadableDataState,
  getInitialLoadableMutationState,
} from "../utils";

export enum IntegrationType {
  Personal = "personal",
  Workspace = "workspace",
}

export type Integration =
  | ({ type: IntegrationType.Personal } & PersonalIntegrationSummary)
  | ({ type: IntegrationType.Workspace } & WorkspaceIntegrationSummary);

export interface IntegrationsState {
  activeIntegration: LoadableData<
    PersonalIntegrationSummary | WorkspaceIntegrationSummary
  >;
  connect: LoadableMutation;
  disconnect: LoadableMutation;
  integrationList: LoadableData<
    Array<PersonalIntegrationSummary> | Array<PersonalIntegrationSummary>
  >;
}

const initialState: IntegrationsState = {
  activeIntegration: getInitialLoadableDataState(),
  connect: getInitialLoadableMutationState(),
  disconnect: getInitialLoadableMutationState(),
  integrationList: getInitialLoadableDataState(),
};

export const connectIntegration = createAsyncThunk(
  "integrations/connect",
  async (
    { type, id }: Pick<Integration, "id" | "type">,
    { rejectWithValue, signal },
  ) => {
    try {
      switch (type) {
        case IntegrationType.Personal: {
          switch (id) {
            case "github": {
              const redirect = `${window.location.pathname}?overlay=/settings/personalIntegrations/github`;

              const { location } = await dispatch(
                linkupProvider({
                  provider: id,
                  redirect,
                  signal,
                }),
              ).unwrap();

              window.location.href = decodeURIComponent(location);
              return;
            }
            default:
              throw Error("Integration not implemented yet");
          }
        }
        case IntegrationType.Workspace: {
          switch (id) {
            case "pagerdutywebhook": {
              dispatch(showModal({ type: "createPagerDutyReceiver" }));
              return;
            }
            case "githubapp": {
              const workspaceId = selectActiveWorkspaceIdOrThrow(getState());
              const { location } = await Api.getGithubAppInstallUrl({
                workspaceId,
              });
              window.location.href = decodeURIComponent(location);
              break;
            }
            default:
              throw Error("Integration not implemented yet");
          }
        }
      }
    } catch (exception) {
      const error = normalizeException(exception);
      dispatch(
        addNotification({
          type: "danger",
          title: `Failed to connect ${type} integration ${id}`,
          description: error.message,
        }),
      );

      return rejectWithValue(error);
    }
  },
);

export const disconnectIntegration = createAsyncThunk(
  "integrations/disconnect",
  async (
    { type, id }: Pick<Integration, "id" | "type">,
    { rejectWithValue, signal },
  ) => {
    try {
      switch (type) {
        case IntegrationType.Personal:
          switch (id) {
            case "github": {
              await dispatch(unlinkProvider({ provider: id, signal })).unwrap();
            }
          }
      }
    } catch (exception) {
      const error = normalizeException(exception);
      dispatch(
        addNotification({
          type: "danger",
          title: `Failed to disconnect ${type} integration ${id}`,
          description: error.message,
        }),
      );

      return rejectWithValue(error);
    }
  },
);

export const loadIntegrations = createAsyncThunk(
  "integrations/loadIntegrations",
  async ({ type }: Pick<Integration, "type">, { rejectWithValue, signal }) => {
    try {
      switch (type) {
        case IntegrationType.Personal:
          return await Api.listPersonalIntegrations({ signal });
        case IntegrationType.Workspace: {
          const workspaceId = selectActiveWorkspaceIdOrThrow(getState());
          return await Api.listWorkspaceIntegrations({ workspaceId, signal });
        }
      }
    } catch (exception) {
      const error = normalizeException(exception);

      dispatch(
        addNotification({
          type: "danger",
          title: "Failed to load integrations",
          description: error.message,
        }),
      );

      return rejectWithValue(error);
    }
  },
);

export const loadIntegrationById = createAsyncThunk(
  "integrations/loadIntegrationById",
  async (
    { type, id }: Pick<Integration, "id" | "type">,
    { rejectWithValue },
  ) => {
    try {
      const integrations = await dispatch(loadIntegrations({ type })).unwrap();
      return integrations.find((integration) => id === integration.id);
    } catch (exception) {
      const error = normalizeException(exception);

      dispatch(
        addNotification({
          type: "danger",
          title: "Failed to load integration",
          description: error.message,
        }),
      );

      return rejectWithValue(error);
    }
  },
);

export const integrationsSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    unloadIntegration(state) {
      state.activeIntegration = getInitialLoadableDataState();
    },
    unloadIntegrationList(state) {
      state.integrationList = getInitialLoadableDataState();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CLEAR_AUTHENTICATION, () => ({ ...initialState }));

    addLoadableDataReducerCases(
      builder,
      loadIntegrations,
      (state) => state.integrationList,
    );

    addLoadableDataReducerCases(
      builder,
      loadIntegrationById,
      (state) => state.activeIntegration,
    );
  },
});

export const { unloadIntegration, unloadIntegrationList } =
  integrationsSlice.actions;
