import { css, styled } from "styled-components";

import type { ProviderEvent } from "../../../types";
import { formatRowContent } from "./formatRow";
import { formatAttributeKey, formatField } from "./utils";

type Props = {
  record: ProviderEvent;
};

export function Details({ record }: Props): JSX.Element {
  return (
    <Table>
      <thead>
        <TableRow>
          <TableHead>Field</TableHead>
          <TableHead>Value</TableHead>
        </TableRow>
      </thead>
      <tbody>
        <TableRow>
          <TableData>body</TableData>
          <TableData>${formatRowContent("body", record)}</TableData>
        </TableRow>
        {Object.keys(record.attributes)
          .sort()
          .map((key) => {
            const formattedKey = formatAttributeKey(key);
            return (
              <TableRow key={formattedKey}>
                <TableData>{formatField(formattedKey)}</TableData>
                <TableData>{formatRowContent(formattedKey, record)}</TableData>
              </TableRow>
            );
          })}
      </tbody>
    </Table>
  );
}

const Table = styled.table`
  width: calc(100% - 16px);
  margin: 8px;
  border-collapse: collapse;
`;

const TableHead = styled.th`
  border-bottom: 1px solid ${({ theme }) => theme.color.border.default};
  padding: 4px 32px;

  &:first-child {
    width: 25%;
  }
`;

const TableData = styled.td(
  ({ theme }) => css`
    padding: 4px 32px;
    font: ${theme.font.code.regular};
    font-size: 14px;
    vertical-align: top;
    word-wrap: anywhere;
  `,
);

const TableRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.color.border.muted};

  &:last-child {
    border-bottom: none;
  }
`;
