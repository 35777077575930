import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { push } from "redux-first-history";

import { AUTHENTICATE_URL, ROUTES } from "../../constants";
import {
  ApiUnavailableError,
  ForbiddenError,
  NotFoundError,
  UnauthenticatedError,
} from "../../errors";
import { useAppDispatch } from "../../store";
import { type PageContainer, PageContent, PageHeader, PageTitle } from "../UI";

type Props = {
  error: Error;
};

export function NotebookError({ error }: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();
  useEffect(() => {
    if (error instanceof UnauthenticatedError) {
      const redirect = search ? `${pathname}?${search}` : pathname;
      dispatch(
        push(`${ROUTES.SignIn}?redirect=${encodeURIComponent(redirect)}`),
      );
    }
  }, [error, dispatch, pathname, search]);

  if (error instanceof ApiUnavailableError) {
    return (
      <ErrorPageContainer>
        <PageHeader>
          <PageTitle>Fiberplane is currently unavailable</PageTitle>
        </PageHeader>
        <PageContent>
          <p>We apologize, please try again shortly.</p>
        </PageContent>
      </ErrorPageContainer>
    );
  }

  if (error instanceof ForbiddenError) {
    return (
      <ErrorPageContainer>
        <PageHeader>
          <PageTitle>You do not have access to this notebook</PageTitle>
        </PageHeader>
        <PageContent>
          <p>Please ask the owner of this notebook for access.</p>
          <p>
            If you are a member of multiple organizations, you may want to
            verify you are signed in with the correct one.
          </p>
        </PageContent>
      </ErrorPageContainer>
    );
  }

  if (error instanceof NotFoundError) {
    return (
      <ErrorPageContainer>
        <PageHeader>
          <PageTitle>This notebook does not exist</PageTitle>
        </PageHeader>
        <PageContent>
          <p>Please verify the URL for typo&apos;s.</p>
        </PageContent>
      </ErrorPageContainer>
    );
  }

  if (error instanceof UnauthenticatedError) {
    return (
      <ErrorPageContainer>
        <PageHeader>
          <PageTitle>You are not signed in</PageTitle>
        </PageHeader>
        <PageContent>
          <p>You cannot access this notebook without valid credentials.</p>
          <p>
            Please <a href={AUTHENTICATE_URL}>sign in</a> first.
          </p>
        </PageContent>
      </ErrorPageContainer>
    );
  }

  return (
    <ErrorPageContainer>
      <PageHeader>
        <PageTitle>An unexpected error occurred</PageTitle>
      </PageHeader>
      <PageContent>
        <p>We apologize, this should not have happened.</p>
        <p>
          <b>Reason:</b> {error.message}
        </p>
      </PageContent>
    </ErrorPageContainer>
  );
}

function ErrorPageContainer(props: React.ComponentProps<typeof PageContainer>) {
  return <PageContent {...props} data-testid="notebook-error-page" />;
}
