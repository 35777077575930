export function shallowEqualArrays<T>(
  a: ReadonlyArray<T>,
  b: ReadonlyArray<T>,
): boolean {
  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version (which is less readable)
  if (!Array.isArray(a) || !Array.isArray(b) || a.length !== b.length) {
    return false;
  }

  for (let i = 0, len = a.length; i < len; i++) {
    if (!Object.is(a[i], b[i])) {
      return false;
    }
  }

  return true;
}
