import { useHandler } from "@fiberplane/hooks";
import format from "date-fns/format";
import { useEffect, useState } from "react";

import { Button, Input, cancelEvent } from "@fiberplane/ui";
// biome-ignore lint/style/useNamingConvention: use the name of the package
import parseISO from "date-fns/parseISO";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import type { Timestamp } from "../../../types";
import { MonthTable } from "./MonthTable";
import {
  Container,
  Form,
  FormContent,
  FormErrorMessage,
  FormHeader,
} from "./styled";

export type TimestampPickerProps = {
  value?: Timestamp;
  onChange: (value: Timestamp) => void;
};

export function TimestampPicker({ value, onChange }: TimestampPickerProps) {
  const [currentDate, setCurrentDate] = useState<Date>(
    value ? parseISO(value) : new Date(),
  );

  // State for the time input
  const [timeIsValid, setTimeIsValid] = useState(true);
  const [timeInputValue, setTimeInputValue] = useState<string>(
    format(currentDate, "HH:mm"),
  );

  const updateDate = useHandler((next: string) => {
    // Create a date from the new timestamp
    const nextDate = new Date(next);

    // Set the time to the current timestamp's time
    nextDate.setHours(currentDate.getHours());
    nextDate.setMinutes(currentDate.getMinutes());

    setCurrentDate(nextDate);
  });

  const updateTime = useHandler((value: string) => {
    // Update the input state
    setTimeInputValue(value);

    // Test for a 24h time format
    const isValid = /^([01]\d|2[0-3]):?[0-5]\d$/.test(value);

    if (isValid) {
      const [h = "00", m = "00"] = value.split(":");

      // Update the time of the currentDate
      let nextDate = setHours(currentDate, Number.parseInt(h));
      nextDate = setMinutes(nextDate, Number.parseInt(m));

      setCurrentDate(nextDate);
    }

    setTimeIsValid(isValid);
  });

  const handleApply = useHandler((event) => {
    cancelEvent(event);
    if (timeIsValid) {
      onChange(currentDate.toISOString());
    }
  });

  const onKeyDown = useHandler((event) => {
    if (event.key === "Enter") {
      handleApply(event);
    }
  });

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, true);
    return () => {
      document.removeEventListener("keydown", onKeyDown, true);
    };
  }, [onKeyDown]);

  return (
    <Container>
      <MonthTable
        startTime={currentDate.toISOString()}
        setStartTime={updateDate}
      />
      <Form>
        <FormContent>
          <FormHeader>Time</FormHeader>
          <Input
            type="text"
            value={timeInputValue}
            onChange={(event) => updateTime(event.target.value)}
            data-disable-notebook-keyboard-handlers
            data-prevent-rte
          />
          {!timeIsValid && <FormErrorMessage>Time is invalid</FormErrorMessage>}
          <Button onClick={handleApply} buttonStyle="primary">
            Apply
          </Button>
        </FormContent>
      </Form>
    </Container>
  );
}
