import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { css, styled } from "styled-components";

import {
  selectActiveWorkspace,
  selectCommandMenuLabels,
  selectCommandMenuVariant,
} from "../../../../selectors";
import { shallowEqualArrays } from "../../../../utils";

const selectCommandMenuSuggestions = createSelector(
  [selectActiveWorkspace, selectCommandMenuVariant, selectCommandMenuLabels],
  (workspace, variant, labels) => {
    if (!workspace) {
      return [];
    }

    const isSearch = variant.type === "search";
    const isTemplateSelector = variant.type === "selectTemplate";
    const isEditingLabel = isSearch && !!variant.labelsEditor;

    const suggestions: Array<[key: string, label: string]> = [];
    if (!isTemplateSelector) {
      // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
      if (!isSearch && !isEditingLabel) {
        suggestions.push(["/", "to search for notebooks"]);
      }

      if (!isEditingLabel) {
        suggestions.push(["#", "to search for labels"]);

        if (labels && labels.length > 0) {
          suggestions.push([
            "backspace",
            `to delete ${labels.length > 1 ? "the last " : " "}label`,
          ]);
        }
      }
    }

    if (variant.type !== "none") {
      suggestions.push(["escape", "to return"]);
    }

    return suggestions;
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqualArrays } },
);

export function QuerySuggestions() {
  const suggestions = useSelector(selectCommandMenuSuggestions);

  if (suggestions.length === 0) {
    return null;
  }

  return (
    <Container>
      {suggestions.map(([key, label]) => (
        <Suggestion key={key}>
          <SuggestionKey>{key}</SuggestionKey>
          {label}
        </Suggestion>
      ))}
    </Container>
  );
}

QuerySuggestions.whyDidYouRender = true;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
  padding: 12px 32px;

  ${({ theme }) => css`
    background: ${theme.color.bg.subtle};
    color: ${theme.color.fg.muted};
    border-top: 1px solid ${theme.color.border.muted};
    font: ${theme.font.headings.h6};
  `}
`;

const Suggestion = styled.span`
  display: contents;
`;

const SuggestionKey = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  padding: 0 4px;

  ${({ theme }) => css`
    background: ${theme.color.bg.default};
    border-radius: ${theme.radius.minimal};
    border: 1px solid ${theme.color.border.muted};
  `}
`;
