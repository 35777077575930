import React from "react";

import { LinkMenuItem } from "./LinkMenuItem";
import { MenuItem } from "./MenuItem";
import { MenuItemGroup } from "./MenuItemGroup";
import { MenuItemWithDescription } from "./MenuItemWithDescription";
import { MenuItemWithPopup } from "./MenuItemWithPopup";
import { MenuItemWithSubMenu } from "./MenuItemWithSubMenu";

export const MENU_CHILD_TYPES = new Set<React.ReactElement["type"]>([
  LinkMenuItem,
  MenuItem,
  MenuItemGroup,
  MenuItemWithPopup,
  MenuItemWithSubMenu,
  MenuItemWithDescription,
]);

/**
 * Returns the React elements representing menu items. This may be the children
 * themselves, or nested items, such as in the case of `MenuItemGroup`.
 */
export function flattenItems(
  children: React.ReactNode,
): Array<React.ReactElement> {
  return React.Children.toArray(children).flatMap((child) => {
    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version (which is less readable)
    if (!React.isValidElement(child) || !MENU_CHILD_TYPES.has(child.type)) {
      return [];
    }

    if (child.type === MenuItemGroup) {
      return flattenItems(child.props.children);
    }

    return [child];
  });
}
