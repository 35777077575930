import type { DataSources } from "../../../../services";

export function formatDataSourceError(error: DataSources.DataSourceError) {
  switch (error.type) {
    case "proxy_disconnected":
      return "Your FPD instance is not connected";
    case "unsupported_request":
      return "The provider received an unsupported request";
    case "validation_error":
      return `Validation error:
${error.errors.join("\n * ")}`;
    case "http": {
      const reason = getHttpErrorReason(error.error);
      return reason
        ? `Failure:
${reason}`
        : `HTTP error.
type: ${error.error.type}`;
    }

    case "data":
      return error.message;
    case "not_found":
      return "Data source not found";
    case "invocation":
      return `Invocation error (${error.message})`;
    case "other":
      return `Unexpected error occurred (${error.message})`;
  }
}

function getHttpErrorReason(error: DataSources.HttpRequestError) {
  switch (error.type) {
    case "connection_refused":
    case "no_route":
    case "offline":
    case "response_too_big":
    case "timeout":
      return;
    case "other":
      return error.reason;
    case "server_error":
      return `Server error with status code: ${error.statusCode}
response: ${error.response}`;
  }
}
