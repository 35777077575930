import {
  type ForwardedRef,
  forwardRef,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import { styled } from "styled-components";

import { cancelEvent } from "@fiberplane/ui";
import { compact, mergeRefs } from "../../../utils";
import { MenuContext } from "./MenuContext";
import { MenuItemContainer } from "./MenuItemContainer";
import { useScrollIntoView } from "./useScrollIntoView";

export type MenuItemProps = {
  /**
   * ID of the menu item.
   */
  id: string;

  title: string;

  /**
   * In case you want to control the rendering of the title, you can specify this function
   * that will receive the title (text) and return the rendered title.
   */
  renderTitle?: (title: string) => React.ReactNode;

  iconLeft?: React.ReactNode;
  iconRight?: { icon: React.ReactNode; showOnlyOnSelection?: boolean };

  /**
   * Called when the menu item is activated (clicked or Enter pressed).
   */
  onActivate: () => void;

  disableCloseOnActivate?: boolean;

  disabled?: boolean;

  textStyle?: "danger";
};

export const MenuItem = forwardRef(function MenuItem(
  {
    id,
    iconLeft,
    iconRight,
    onActivate,
    disableCloseOnActivate = false,
    title,
    renderTitle,
    disabled,
    textStyle,
  }: MenuItemProps,
  forwardedRef: ForwardedRef<HTMLDivElement>,
): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);
  const { close, selection, setSelection } = useContext(MenuContext);
  const { grabsFocus, selectedBy, itemId } = selection;
  const isSelected = itemId === id;
  const shouldScroll = selectedBy === "key";

  useScrollIntoView(containerRef, isSelected && shouldScroll);
  // biome-ignore lint/correctness/useExhaustiveDependencies: regrab focus when `id` changes
  useLayoutEffect(() => {
    if (isSelected && grabsFocus && containerRef.current) {
      containerRef.current.focus();
    }
  }, [isSelected, grabsFocus, id]);

  const hasIconRight = !!iconRight?.icon;
  const showIconRight =
    hasIconRight &&
    (!iconRight.showOnlyOnSelection ||
      (iconRight.showOnlyOnSelection && isSelected));

  return (
    <MenuItemContainer
      data-context-menu
      data-menu-item-id={id}
      onFocus={() => {
        if (isSelected) {
          return;
        }

        setSelection({ grabsFocus: false, itemId: id, selectedBy: "key" });
      }}
      onClick={(event) => {
        cancelEvent(event);
        onActivate();
        if (disableCloseOnActivate !== true) {
          close({ reason: "item_activated" });
        }
      }}
      onMouseMove={() => {
        setSelection({
          itemId: id,
          selectedBy: "mouse",
          autoOpen: true,
        });
      }}
      ref={mergeRefs(compact([containerRef, forwardedRef]))}
      isSelected={isSelected}
      tabIndex={disabled ? -1 : 0}
      onKeyDown={(event) => {
        if (event.key === "Enter" || event.key === "Tab" || event.key === " ") {
          onActivate();
          if (disableCloseOnActivate !== true) {
            close({ reason: "item_activated" });
          }
        }
      }}
      disabled={disabled}
      textStyle={textStyle}
    >
      {iconLeft}

      <ItemText>{renderTitle ? renderTitle(title) : title}</ItemText>

      <IconRightContainer $visible={hasIconRight && !disabled}>
        {showIconRight && iconRight.icon}
      </IconRightContainer>
    </MenuItemContainer>
  );
});

const ItemText = styled.span`
  flex: 1;
`;

export const IconRightContainer = styled.span<{ $visible: boolean }>`
  width: 20px;
  display: flex;
  align-items: center;
  visibility: ${({ $visible }) => ($visible ? "visible" : "hidden")};
`;
