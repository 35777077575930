import type { SerializedError } from "@reduxjs/toolkit";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

// These come from rtk-query but aren't directly exposed.
// They are used to differentiate from http status errors and rtk-query errors.
const FETCH_BASE_QUERY_WRAPPED_STATUS = new Set([
  "FETCH_ERROR",
  "PARSING_ERROR",
  "TIMEOUT_ERROR",
  "CUSTOM_ERROR",
]);

type FetchBaseQueryHttpError = Extract<FetchBaseQueryError, { status: number }>;

type FetchBaseQueryWrappedError = Exclude<
  FetchBaseQueryError,
  FetchBaseQueryHttpError
>;

export function isFetchBaseQueryHttpError(
  error: object,
): error is FetchBaseQueryHttpError {
  return "status" in error && typeof error.status === "number";
}

export function isFetchBaseQueryWrappedError(
  error: object,
): error is FetchBaseQueryWrappedError {
  return (
    "status" in error &&
    typeof error.status === "string" &&
    FETCH_BASE_QUERY_WRAPPED_STATUS.has(error.status)
  );
}

export function isSerializedError(error: object): error is SerializedError {
  return "stack" in error;
}

/**
 * Determines if an exception if an error generated by rtk-query
 */
export function isRtkApiError(
  exception: unknown,
): exception is FetchBaseQueryError | SerializedError {
  return (
    typeof exception === "object" &&
    exception != null &&
    (isFetchBaseQueryHttpError(exception) ||
      isFetchBaseQueryWrappedError(exception) ||
      isSerializedError(exception))
  );
}

/**
 * Response handler which turns text error responses into JSON
 *
 * HACK: rtk query tries to always parse JSON responses because, which makes sense from a resource
 * perspective. However, the API sometimes returns text errors.
 */
export async function jsonWithTextErrorResponseHandler(response: Response) {
  const contentType = response.headers.get("Content-Type")?.toLowerCase();
  const isJson = contentType?.includes("application/json");

  if (!isJson && response.status !== 200) {
    const message = await response.text();
    return { message };
  }

  return await response.json();
}

/**
 * Parses the response into a Uint8Array buffer unless the status is 200.
 * This is to make sure the `normalizeException` logic can parse the returned response.
 */
export async function binaryResponseHandler(response: Response) {
  if (response.status !== 200) {
    return await response.text();
  }

  const buffer = await response.arrayBuffer();
  return new Uint8Array(buffer);
}

export function withQueryArgs(url: string, args: object): string {
  const params: Array<string> = [];
  for (const [key, value] of Object.entries(args)) {
    if (!value) {
      continue;
    }

    params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
  }

  return `${url}?${params.join("&")}`;
}
