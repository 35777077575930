import { motion, useReducedMotion } from "framer-motion";
import { forwardRef } from "react";
import { styled } from "styled-components";

export const Overlay = forwardRef<HTMLDivElement, React.PropsWithChildren>(
  function Overlay({ children }, ref) {
    const shouldReduceMotion = useReducedMotion();
    const duration = shouldReduceMotion ? 0 : 0.1;

    return (
      <Background
        ref={ref}
        data-testid="modal-overlay"
        initial="initial"
        animate="visible"
        exit="initial"
        variants={{
          initial: {
            opacity: 0,
          },
          visible: {
            opacity: 1,
          },
        }}
        transition={{ duration }}
      >
        <CenterContainer
          initial="initial"
          animate="visible"
          exit="exit"
          variants={{
            initial: {
              opacity: 0,
              transition: {
                duration,
                when: "afterChildren",
              },
            },
            visible: {
              opacity: 1,
              transition: {
                duration,
                when: "beforeChildren",
              },
            },
            exit: {
              scale: 0.95,
              opacity: 0,
            },
          }}
          transition={{
            duration,
            translateY: {
              type: "linear",
            },
          }}
        >
          {children}
        </CenterContainer>
      </Background>
    );
  },
);

const Background = styled(motion.div)`
  position: fixed;
  width: 100%;
  max-height: 100%;
  inset: 0;
  background-color: ${({ theme }) => theme.color.bg["disabled-overlay"]};
  backdrop-filter: blur(4px);
  z-index: 1020;
`;

const CenterContainer = styled(motion.div)`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
