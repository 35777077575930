import { IconButton } from "@fiberplane/ui";
import { styled } from "styled-components";

import { ControlsSet, ControlsSetLabel } from "@fiberplane/charts";
import type { CellFocus, TimeRange, Timestamp } from "../../../../../types";
import { cellFocusesAreEqual, withField } from "../../../../../utils";
import {
  TimeRangeMenuButton,
  TimeRangeSelectorMenu,
} from "../../../../TimeRangeSelectorMenu";
import { ButtonGroup } from "../../../../UI";
import { TimeRangeForm, getTimeRangeFocusField } from "../../../TimeRangeForm";
import { ControlsGroup } from "../../../baseControls";

type Props = {
  from: Timestamp;
  to: Timestamp;
  focus: CellFocus;
  readOnly: boolean;
  onChange(timeRange: TimeRange): void;
  onFocusChange(focus: CellFocus): void;
  resetToGlobalTimeRange: () => void;
  /**
   * This prop is Used to detect if the cell has its own time range (i.e. is not following the notebooks time range)
   *
   * So passing a value/function for this prop indicates that a local time range is used
   */
  useGlobally?: () => void;
  enableLocalTimeRange?: () => void;
};

export function TimeRangeFieldContent(props: Props) {
  const {
    focus,
    from,
    to,
    readOnly,
    onChange,
    onFocusChange,
    resetToGlobalTimeRange,
    useGlobally,
    enableLocalTimeRange,
  } = props;

  const field = getTimeRangeFocusField(focus);

  const updateFocus = (newFocus: CellFocus) => {
    // Make sure the new focus is really different, before
    // calling `onFocusChange`:
    const currentFocus = withField(focus, field ?? "from");

    // FIXME: support for offset / fields for global time range cells
    // For now we assume that if the focus type is collapsed that
    // one of the inputs has focus
    if (currentFocus.type === "collapsed") {
      // We're assuming that you're at the first field (if no field is specified already)
      currentFocus.field ??= "from";
      // We're assuming that you're at position 0 if no position is specified
      currentFocus.offset ??= 0;
    }

    const focusWithField = withField(newFocus, field ?? "from");
    if (!cellFocusesAreEqual(focusWithField, currentFocus)) {
      onFocusChange(focusWithField);
    }
  };

  return (
    <Container>
      <TimeRangeForm
        from={from}
        to={to}
        focus={focus}
        onChange={onChange}
        onFocusChange={onFocusChange}
        readOnly={readOnly}
      />
      {!readOnly && enableLocalTimeRange && (
        <ControlsGroup>
          <ControlsSet>
            <ControlsSetLabel>Link</ControlsSetLabel>
            <StyledButtonGroup>
              <StyledIconButton
                aria-label="Inherit notebook time range"
                iconType="link"
                onClick={resetToGlobalTimeRange}
                buttonStyle={useGlobally ? "tertiary-grey" : "secondary"}
              />
              <StyledIconButton
                aria-label="Customize cell specific time range"
                disabled={!useGlobally}
                iconType="link_break"
                onClick={enableLocalTimeRange}
                buttonStyle={useGlobally ? "secondary" : "tertiary-grey"}
              />
            </StyledButtonGroup>
          </ControlsSet>
          <TimeRangeSelectorMenu
            onChange={(timeRange) => {
              onChange(timeRange);
            }}
            useGlobally={useGlobally}
            resetToGlobal={resetToGlobalTimeRange}
            disabled={readOnly}
            value={{ from, to }}
          >
            {({ opened }) => (
              <StyledTimeRangeMenuButton
                aria-label="Customize time range"
                active={opened}
                onFocusChange={updateFocus}
              />
            )}
          </TimeRangeSelectorMenu>
        </ControlsGroup>
      )}
    </Container>
  );
}

const StyledButtonGroup = styled(ButtonGroup)`
  padding: 4px;
  gap: 4px;
`;

const StyledIconButton = styled(IconButton)`
  min-height: 30px;
  height: 30px;
  width: 30px;

  svg {
    height: 18px;
    width: 18px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`;

const StyledTimeRangeMenuButton = styled(TimeRangeMenuButton)`
  border-width: 0;
`;
