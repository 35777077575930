import { useThemeSelect } from "@fiberplane/hooks";
import { Input } from "@fiberplane/ui";
import { css, styled } from "styled-components";

import { isValidTheme } from "../../../../../types";
import ThemeDark from "./ThemeDark.svg";
import ThemeLight from "./ThemeLight.svg";

export function ThemeSwitcher() {
  const {
    currentTheme,
    isSystemPreference,
    setThemePreference,
    setToSystemTheme,
  } = useThemeSelect();

  const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const themePreference = event.target.value;
    const validTheme = isValidTheme(themePreference);

    if (validTheme) {
      setThemePreference(themePreference);
      return;
    }

    setToSystemTheme();
  };

  const isLightActive = !isSystemPreference && currentTheme === "light";
  const isDarkActive = !isSystemPreference && currentTheme === "dark";

  return (
    <CardsContainer>
      <ThemeCard $isActive={isSystemPreference}>
        <ThemeImageContainer>
          <SystemThemeVisual onClick={setToSystemTheme}>
            <HalfIcon>
              <ThemeLightSvg />
            </HalfIcon>
            <ThemeDarkSvg />
          </SystemThemeVisual>
        </ThemeImageContainer>
        <ThemeCardTitle as="label">
          <Input
            type="radio"
            value="system"
            checked={isSystemPreference}
            onChange={onRadioChange}
            id="system"
            name="theme"
          />
          System setting
        </ThemeCardTitle>
      </ThemeCard>

      <ThemeCard $isActive={isLightActive}>
        <ThemeImageContainer>
          <ThemeLightSvg onClick={() => setThemePreference("light")} />
        </ThemeImageContainer>

        <ThemeCardTitle as="label" htmlFor="light">
          <Input
            type="radio"
            id="light"
            value="light"
            name="theme"
            onChange={onRadioChange}
            checked={isLightActive}
          />
          Light
        </ThemeCardTitle>
      </ThemeCard>

      <ThemeCard $isActive={isDarkActive}>
        <ThemeImageContainer>
          <ThemeDarkSvg onClick={() => setThemePreference("dark")} />
        </ThemeImageContainer>
        <ThemeCardTitle as="label" htmlFor="dark">
          <Input
            type="radio"
            id="dark"
            value="dark"
            name="theme"
            onChange={onRadioChange}
            checked={isDarkActive}
          />
          Dark
        </ThemeCardTitle>
      </ThemeCard>
    </CardsContainer>
  );
}

const CardsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 20px;
  justify-content: start;
`;

const ThemeImageContainer = styled.div`
  transition: 0.2s transform ease-in-out;
`;

const SystemThemeVisual = styled.div`
  position: relative;
  margin-bottom: -8px; /* offset invisible overflow from SVG */
`;

// Wrap the SVGs in styled component so we can use selectors to style them
const ThemeLightSvg = styled(ThemeLight)``;
const ThemeDarkSvg = styled(ThemeDark)``;

const ThemeCard = styled.div<{ $isActive: boolean }>(
  ({ $isActive, theme }) => css`
    display: grid;
    row-gap: 8px;
    cursor: pointer;
    
    ${ThemeLightSvg}, ${ThemeDarkSvg} {
      color: ${theme.color.border.default};
      border-radius: 6px;

      ${
        $isActive &&
        css`
          color: ${theme.color.border.primary};
        `
      } 
    }

    ${
      $isActive &&
      css`
        :not(${HalfIcon}) > ${ThemeLightSvg}, ${ThemeDarkSvg} {
          border-radius: 6px;
          box-shadow: ${theme.effect.focus.primary};
          color: ${theme.color.border.primary};
        }
      `
    }

    &:hover ${ThemeImageContainer} {
      transform: scale(1.02);
    }
  `,
);

const ThemeCardTitle = styled.h5`
  grid-area: unset;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  line-height: 1;
`;

const HalfIcon = styled.div`
  position: absolute;
  clip-path: polygon(0 0, 85% 0, 0 110%);
`;
