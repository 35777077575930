import { nanoid } from "@reduxjs/toolkit";
import { useCallback, useEffect, useState } from "react";

import { useHandler } from "@fiberplane/hooks";
import { isString } from "../../../utils";
import { useFrontMatterValue } from "../../Cell";
import type { Message } from "./types";

type CopilotChatState = ReturnType<typeof useCopilotChat>;

export const useCopilotChat = () => {
  const [{ ids, messages }, setMessages] = useState<{
    ids: Array<string>;
    messages: Record<string, Message>;
  }>({ ids: [], messages: {} });
  const [prompt, setPrompt] = useState("");
  const [isLocked, setIsLocked] = useState(false);
  const addMessage = useCallback((message: Message) => {
    const id = message.id;
    setMessages(({ ids, messages }) => ({
      ids: [...ids, id],
      messages: {
        ...messages,
        [id]: message,
      },
    }));
    return id;
  }, []);
  const updateMessage = useCallback(
    (id: string, updater: (message: Message) => Message) => {
      setMessages(({ ids, messages }) => {
        const message = messages[id];
        if (!message) {
          console.debug("[copilot] chat message not found!");
          return { ids, messages };
        }
        return {
          ids,
          messages: {
            ...messages,
            [id]: updater(message),
          },
        };
      });
    },
    [],
  );

  return {
    ids,
    messages,
    prompt,
    isLocked,
    setPrompt,
    setIsLocked,
    addMessage,
    updateMessage,
  };
};

const RESOLVED_ACTIONS = [
  { type: "SUMMARIZE", label: "Summarize this notebook" },
];

/**
 * Helper hook for setting initial message and actions in copilot sidebar
 */
export function useInitializeCopilot({
  isSideMenuOpen,
  ids,
  addMessage,
  updateMessage,
}: {
  isSideMenuOpen: boolean;
  ids: CopilotChatState["ids"];
  addMessage: CopilotChatState["addMessage"];
  updateMessage: CopilotChatState["updateMessage"];
}) {
  const frontMatterStatus = useFrontMatterValue("status", isString);
  const messagesCount = ids.length;
  const firstMessage = ids[0];

  // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: demo code
  useEffect(() => {
    const hasStatus = !!frontMatterStatus;
    const isResolved = frontMatterStatus?.toLowerCase() === "resolved";

    const initialMessageContent =
      hasStatus && isResolved
        ? "Greetings! Looks like we're dealing with a resolved issue. How can I help?"
        : hasStatus
          ? "Greetings! Looks like we're dealing with an ongoing issue. Describe what you would like to do, or select an action below."
          : "Hello! How can I help you today?";

    const actions = isResolved
      ? RESOLVED_ACTIONS
      : hasStatus
        ? [
            {
              type: "FIND_SIMILAR",
              label: "Look for similar issues",
            },
            // {
            //   type: "PRINT_LOADS",
            //   label: "Print loads of text",
            // },
          ]
        : undefined;

    if (!isSideMenuOpen || messagesCount > 1) {
      return;
    }

    // If we only have the initial message, then update it to reflect frontmatter
    if (messagesCount === 1) {
      const greetingMessageId = firstMessage ?? "";
      updateMessage(greetingMessageId, (m) => ({
        ...m,
        content: initialMessageContent,
        actions,
      }));
      return;
    }

    const timer = setTimeout(() => {
      addMessage({
        id: nanoid(),
        isFromUser: false,
        isLoading: false,
        type: "text",
        content: initialMessageContent,
        actions,
      });
    }, 200);

    return () => clearTimeout(timer);
  }, [
    addMessage,
    updateMessage,
    isSideMenuOpen,
    messagesCount,
    firstMessage,
    frontMatterStatus,
  ]);
}

const RESOLVED_MESSAGE =
  "This notebook has been marked as resolved. Is there anything I can do to help follow-up?";

/**
 * HACK This is an effect that *adds* a message when frontmatter status changes to resolved,
 * and there's already an ongoing conversation in the notebook.
 *
 * - Ignores "Greeting" (first) message, leaves the logic that updates the greeting to the initialization hook
 * - Will not add the message if a current message is generating
 */
export function useCopilotChatFrontmatterResolvedEffect({
  isLocked,
  ids,
  messages,
  addMessage,
}: {
  isLocked: boolean;
  ids: CopilotChatState["ids"];
  addMessage: CopilotChatState["addMessage"];
  messages: Record<string, Message>;
}) {
  const frontMatterStatus = useFrontMatterValue("status", isString);
  const messagesCount = ids.length;
  const hasSeenResolvedMessage = Object.values(messages).some(
    (m) => m.content === RESOLVED_MESSAGE,
  );

  const addResolvedMessage = useHandler((status: string) => {
    if (messagesCount < 2) {
      return;
    }
    if (status?.toLowerCase() !== "resolved") {
      return;
    }

    if (isLocked) {
      return;
    }

    if (hasSeenResolvedMessage) {
      return;
    }

    addMessage({
      id: nanoid(),
      type: "text",
      content: RESOLVED_MESSAGE,
      isFromUser: false,
      isLoading: false,
      actions: RESOLVED_ACTIONS,
    });
  });

  useEffect(() => {
    addResolvedMessage(frontMatterStatus ?? "");
  }, [frontMatterStatus, addResolvedMessage]);
}
