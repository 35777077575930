import { Icon } from "@fiberplane/ui";
import { css, styled } from "styled-components";

import { type ViewColorSet, useViewColor } from "../../hooks";
import type { View } from "../../types";

type Props = {
  className?: string;
  color: View["color"];
  iconSize?: number;
};

export function ViewIcon({ className, color, iconSize }: Props) {
  const { colorSet } = useViewColor(color);

  return (
    <ViewIconContainer colorSet={colorSet} className={className}>
      <StyledViewIcon iconType="views" width={iconSize} height={iconSize} />
    </ViewIconContainer>
  );
}

export const ViewIconContainer = styled.div<{ colorSet: ViewColorSet }>(
  ({ theme, colorSet }) => css`
    transition: background 200ms ease-out;
    border-radius: ${theme.borderRadius400};
    background: ${colorSet.default};
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
  `,
);

const StyledViewIcon = styled(Icon)`
  color: ${({ theme }) => theme.colorBackground};
  background: none;
`;
