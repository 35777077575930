import { styled } from "styled-components";

import { Checkbox, ModalLabel } from "../../../UI";

type Props = {
  checked: boolean;
  label?: string;
  name: string;
  onChange: (value: boolean) => void;
  onKeyDown: (event: React.KeyboardEvent) => void;
  value?: unknown;
};

export function CheckboxField({
  checked: defaultValue,
  label,
  name,
  onChange,
  onKeyDown,
  value,
}: Props): JSX.Element {
  const checked = value == null ? defaultValue : !!value;
  const id = `config-field:${name}`;

  return (
    <ModalCheckboxLabel htmlFor={id}>
      <Checkbox
        checked={checked}
        id={id}
        onChange={(event) =>
          onChange((event.target as HTMLInputElement).checked)
        }
        onKeyDown={onKeyDown}
      />
      {label}
    </ModalCheckboxLabel>
  );
}

const ModalCheckboxLabel = styled(ModalLabel)`
  align-items: center;
  flex-direction: row;
`;
