import { memo } from "react";

import type { NotebooksCommand } from "../../../../types";
import { getCreatedByLabel } from "../../../../utils";
import {
  CREATED_BY_INDEX_KEY,
  MAX_LABELS,
  TITLE_INDEX_KEY,
} from "../constants";
import { HighlightedText } from "./HighlightedText";
import type { SwitchedResultProps } from "./Result";
import { ResultContainer } from "./ResultContainer";
import {
  ResultHeader,
  ResultIcon,
  ResultLabels,
  ResultSubTitle,
  ResultTitle,
  StyledResultLabelList,
} from "./styled";

type NotebookResultProps = SwitchedResultProps<NotebooksCommand>;

export const NotebookResult = memo(function NotebookResult({
  command,
  index,
  matches,
}: NotebookResultProps) {
  const labels = command.notebook?.labels ?? [];

  return (
    <ResultContainer command={command} index={index}>
      <ResultIcon iconType="notebook" />
      <ResultHeader>
        <ResultTitle>
          <HighlightedText indexKey={TITLE_INDEX_KEY} matches={matches}>
            {command.title}
          </HighlightedText>
        </ResultTitle>
        {command.notebook?.createdBy && (
          <ResultSubTitle>
            <HighlightedText indexKey={CREATED_BY_INDEX_KEY} matches={matches}>
              {getCreatedByLabel(command.notebook.createdBy)}
            </HighlightedText>
          </ResultSubTitle>
        )}
        {labels.length > 0 && (
          <ResultLabels>
            <StyledResultLabelList labels={labels} max={MAX_LABELS} />
          </ResultLabels>
        )}
      </ResultHeader>
    </ResultContainer>
  );
});
