import { Icon } from "@fiberplane/ui";
import type { Placement, VirtualElement } from "@popperjs/core";
import type React from "react";
import { type ForwardedRef, forwardRef } from "react";
import { type DefaultTheme, styled } from "styled-components";

import { AttachedPopup } from "@fiberplane/ui";
import { Text } from "../Text";

type NudgeStyle = "highlight" | "default" | "warning";

type Props = {
  element: HTMLElement | VirtualElement | null;
  onClose?: () => void;
  children: React.ReactNode;
  nudgeStyle?: NudgeStyle;
  placement?: Placement;
  className?: string;
};

export const Nudge = forwardRef(function Nudge(
  { className, element, placement = "right", ...props }: Props,
  forwardedRef: ForwardedRef<{
    update: () => Promise<void>;
  }>,
): JSX.Element {
  return (
    <StyledAttachedPopup
      className={className}
      ref={forwardedRef}
      element={element}
      placement={placement}
    >
      <NudgeContent {...props} />
    </StyledAttachedPopup>
  );
});

function NudgeContent({
  onClose,
  children,
  nudgeStyle = "default",
}: Omit<Props, "element" | "placement">): JSX.Element {
  return (
    <Root>
      <Container>
        <Content $nudgeStyle={nudgeStyle}>
          {children}
          {onClose && <CloseIcon iconType="close" onClick={onClose} />}
        </Content>
      </Container>
    </Root>
  );
}

function nudgeStyleToBackground(theme: DefaultTheme, nudgeStyle: NudgeStyle) {
  switch (nudgeStyle) {
    case "highlight":
      return theme.color.bg.emphasis.neutral;
    case "warning":
      return theme.color.bg.warning;
    default:
      return theme.color.bg.default;
  }
}

function nudgeStyleToForeground(theme: DefaultTheme, nudgeStyle: NudgeStyle) {
  switch (nudgeStyle) {
    case "highlight":
      return theme.color.fg.onemphasis.default;
    case "warning":
      return theme.color.fg.warning;
    default:
      return theme.color.fg.default;
  }
}

const StyledAttachedPopup = styled(AttachedPopup)`
  z-index: 1000;
`;

const Root = styled.div`
  position: relative;
  filter: ${(props) => props.theme.effectShadowMainFilter};
`;

const Container = styled.div`
  [data-popper-placement="top"] & {
    margin-bottom: 12px;
  }

  [data-popper-placement="right"] & {
    margin-left: 12px;
  }

  [data-popper-placement="bottom"] & {
    margin-top: 12px;
  }

  [data-popper-placement="left"] & {
    margin-right: 12px;
  }
`;

const Content = styled(Text)<{ $nudgeStyle: NudgeStyle }>`
  display: grid;
  grid-template-columns: 1fr 20px;
  align-items: center;
  gap: 6px;
  background-color: ${
    /* sc-custom "ignore this" */ (props) =>
      nudgeStyleToBackground(props.theme, props.$nudgeStyle)
  };

  border-radius: ${(props) => props.theme.borderRadius500};
  color: ${
    /* sc-custom "ignore this" */ (props) =>
      nudgeStyleToForeground(props.theme, props.$nudgeStyle)
  };
  font: ${(props) => props.theme.fontStudioStrongSmallShortHand};
  padding: 8px;
  overflow: hidden;
`;

const CloseIcon = styled(Icon)`
  cursor: pointer;
  height: 12px;
  width: 12px;
`;
