import type {
  CellTypeProperties,
  Formatting,
  RichText,
  TableCell,
} from "../types";
import { range } from "./range";
import { charCount } from "./unicode";

const TABLE_ROW_VALUE_ID_SEPARATOR = ";";

/**
 * Formats the ID used to identify a single value inside a table row.
 *
 * See the documentation of {@link TableCell} for more information.
 */
export function formatTableRowValueId(rowId: string, columnId: string): string {
  return `${rowId}${TABLE_ROW_VALUE_ID_SEPARATOR}${columnId}`;
}

/**
 * Parses the ID used to identify a single value inside a table row into a
 * separate row ID and column ID.
 *
 * See the documentation of {@link TableCell} for more information.
 */
export function parseTableRowValueId(
  valueId: string,
): [rowId: string, columnId: string] {
  const [rowId, columnId] = valueId.split(TABLE_ROW_VALUE_ID_SEPARATOR);

  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version (which is less readable)
  if (!rowId || !columnId) {
    throw new Error(`Invalid table row value ID: ${valueId}`);
  }

  return [rowId, columnId];
}

/**
 * Returns all the rich text in a table cell.
 */
export function getAllRichTextFromTableCell(cell: TableCell): RichText {
  const separator = " | ";

  let text = "";
  let offset = 0;
  const formatting: Formatting = [];

  for (const { title } of cell.columnDefs) {
    if (text.length > 0) {
      text += separator;
      offset += separator.length;
    }

    const titleLen = charCount(title);

    text += title;
    formatting.push(
      { offset, type: "start_bold" },
      { offset: offset + titleLen, type: "end_bold" },
    );

    offset += titleLen;
  }

  for (const row of cell.rows) {
    text += "\n";
    offset += 1;

    let columnIndex = 0;
    for (const value of row.values) {
      if (columnIndex > 0) {
        text += separator;
        offset += separator.length;
      }

      const valueTextLen = charCount(value.text);

      text += value.text;
      formatting.push(
        ...value.formatting.map((annotation) => ({
          ...annotation,
          offset: annotation.offset + offset,
        })),
      );

      offset += valueTextLen;
      columnIndex += 1;
    }
  }

  return { text, formatting };
}

/**
 * Returns the rich text in a table cell's row value.
 *
 * Note the `field` equals the table row value ID.
 */
export function getRichTextFromTableCellField(
  cell: TableCell,
  field: string,
): RichText | undefined {
  const [rowId, columnId] = parseTableRowValueId(field);

  const columnIndex = cell.columnDefs.findIndex(
    (columnDef) => columnDef.id === columnId,
  );
  if (columnIndex === -1) {
    return;
  }

  return cell.rows.find((row) => row.id === rowId)?.values[columnIndex];
}

type GenerateEmptyTableOptions = {
  numColumns: number;
  numRows: number;
};

/**
 * Generates a new empty table cell, sans ID.
 */
export function generateEmptyTable(
  options: GenerateEmptyTableOptions,
): CellTypeProperties & { type: "table" } {
  const table: CellTypeProperties & { type: "table" } = {
    type: "table",
    columnDefs: [],
    rows: [],
  };

  for (let i = 0; i < options.numColumns; i++) {
    table.columnDefs.push({
      id: getUniqueColumnId(table),
      title: `Column ${String.fromCharCode("A".charCodeAt(0) + i)}`,
    });
  }

  for (let i = 0; i < options.numRows; i++) {
    table.rows.push({
      id: getUniqueRowId(table),
      values: range(0, options.numColumns).map(() => ({
        type: "text",
        text: "",
        formatting: [],
      })),
    });
  }

  return table;
}

/**
 * Returns a new column ID that is guaranteed not to exist in the given table
 * cell.
 */
export function getUniqueColumnId(
  cell: CellTypeProperties & { type: "table" },
): string {
  let id = getRandomId();
  while (cell.columnDefs.some((columnDef) => columnDef.id === id)) {
    id += getRandomIdChar();
  }

  return id;
}

/**
 * Returns a new row ID that is guaranteed not to exist in the given table cell.
 */
export function getUniqueRowId(
  cell: CellTypeProperties & { type: "table" },
): string {
  let id = getRandomId();
  while (cell.rows.some((row) => row.id === id)) {
    id += getRandomIdChar();
  }

  return id;
}

function getRandomId(): string {
  return Math.random().toString(36).slice(2, 8);
}

function getRandomIdChar(): string {
  return Math.random().toString(36).slice(2, 3);
}
