const units = [
  "byte",
  "kilobyte",
  "megabyte",
  "gigabyte",
  "terabyte",
  "petabyte",
];

export function formatSize(size: number): string {
  let unitIndex = 0;
  let currentSize = size;
  while (currentSize > 1024 && unitIndex < units.length - 1) {
    currentSize /= 1024;
    unitIndex += 1;
  }

  const options: Intl.NumberFormatOptions = {
    maximumFractionDigits: 2,
    style: "unit",
    unit: units[unitIndex],
  };

  return Intl.NumberFormat("en-US", options).format(currentSize);
}
