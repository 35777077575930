import type { TimeRange } from "../../../types";
import { getLatencyQuery, getSimilarNotebookDetails } from "./config";
import type { CopilotAction } from "./types";

/**
 * Smoke and mirrors fetch function for a copilot service
 */
export function fakeCopilotService(
  body: {
    prompt: string;
    action: CopilotAction;
  },
  notebookTimeRange?: TimeRange,
) {
  const action = body.action;
  const fakeDataChunks = getFakeDataChunks(action, notebookTimeRange);

  // We want to be able to stream the response
  const stream = new ReadableStream({
    async start(controller) {
      for (const chunk of fakeDataChunks) {
        // encoding our string chunk to Uint8Array for the stream
        controller.enqueue(new TextEncoder().encode(chunk));
        // dramatic pause between chunks
        await new Promise((resolve) => setTimeout(resolve, randomChunkDelay()));
      }
      controller.close();
    },
  });

  // Simulate a fetch response with a ReadableStream
  return Promise.resolve(
    new Response(stream, {
      headers: { "Content-Type": "application/json" },
    }),
  );
}

function randomChunkDelay() {
  // return randomDelayInRange(1700, 10000);
  return randomDelayInRange(170, 1000);
}

function randomDelayInRange(x: number, y: number) {
  return Math.random() * (y - x) + x;
}

function getFakeDataChunks(
  action: CopilotAction,
  notebookTimeRange?: TimeRange,
) {
  const similarNotebook = getSimilarNotebookDetails();
  const notebookId = similarNotebook.id;
  const notebookTitle = similarNotebook.title;
  const commanderName = similarNotebook.commander.name;
  const commanderFirstName = commanderName.split(" ")[0];
  const commanderId = similarNotebook.commander.id;

  switch (action.type) {
    case "FIND_SIMILAR":
      return [
        "", // HACK - The first message is usually dropped due to artificial timeout
        "Searching recent incidents... ",
        "Found one!",
        `%USER | ${commanderId} | ${commanderName}`,
        "was the commander for a similar incident",
        `%NOTEBOOK | ${notebookId} | ${notebookTitle}`,
        `%ACTION | ASSIGN_COMMANDER | Assign ${commanderFirstName} as Commander`,
        `%ACTION | EXECUTE_RUNBOOK | Check ${commanderFirstName}'s Notebook`,
      ];
    case "ASSIGN_COMMANDER":
      return [
        "",
        "Done! ",
        `We can also proceed to use ${commanderFirstName}'s investigation strategy.`,
        `%ACTION | EXECUTE_RUNBOOK | Use a query from ${commanderFirstName}'s Notebook`,
      ];
    case "EXECUTE_RUNBOOK": {
      const query = createHighLatencyQuery(notebookTimeRange);
      return [
        "",
        "Checking summary from similar notebook... ",
        `${commanderFirstName} pinpointed the issue in the previous notebook with a query for high latency api handlers. `,
        "From there, we can check if any handlers are showing abnormally high latency. ",
        `%QUERY | ${query}`,
      ];
    }
    case "PRINT_LOADS":
      return getLoadsOfChunks();
    case "SUMMARIZE":
      return [
        "",
        "This notebook was created after an alert fired. ",
        "The alert indicated a sudden increase in latency for a backend api. ",
        `%USER | ${commanderId} | ${commanderName}`,
        " was assigned incident commander, ",
        "and a Prometheus query uncovered a single api handler that had a spike in its response time. ",
        "This is a repeat incident of ",
        `%NOTEBOOK | ${notebookId} | ${notebookTitle}`,
        ", and it warrants further investigation and follow-up.",
      ];
    case "ADD_SUMMARY_TO_NOTEBOOK":
      return [
        "",
        "The summary is now in your notebook. Let me know if there is anything else you want to do. ",
      ];
    default:
      return [
        "I'm not sure how to respond. ",
        "Could you please rephrase your query?",
      ];
  }
}

export function parseFakeActionChunk(chunk: string): CopilotAction {
  return {
    type: chunk.split("|")[1]?.trim() || "UNKNOWN",
    label: chunk.split("|")[2]?.trim() || "Error Parsing Action",
  };
}

export function parseFakeNotebookChunk(chunk: string) {
  return {
    title: chunk.split("|")[2]?.trim() || "Notebook",
    id: chunk.split("|")[1]?.trim() || "zzz",
  };
}

export function parseFakeUserChunk(chunk: string) {
  return {
    name: chunk.split("|")[2]?.trim() || "Ted Lasso",
    id: chunk.split("|")[1]?.trim() || "zzz",
  };
}

/**
 * Utility to create a query with a proper smoothing window based on the notebook time range
 */
function createHighLatencyQuery(timeRange?: TimeRange) {
  const query = getLatencyQuery();

  if (!timeRange) {
    return query.replace("%SMOOTHING_WINDOW", "5m");
  }

  const from = +new Date(timeRange.from) / 1000;
  const to = +new Date(timeRange.to) / 1000;

  const durationInSeconds = to - from;

  // Calculate duration in hours for easier thresholding
  const durationInHours = durationInSeconds / 3600;

  let smoothingWindow: string;
  if (durationInHours <= 1) {
    // less than or equal to 1 hour
    smoothingWindow = "1m";
  } else if (durationInHours <= 24) {
    // less than or equal to 24 hours
    smoothingWindow = "5m";
  } else if (durationInHours <= 168) {
    // less than or equal to one week
    smoothingWindow = "15m";
  } else if (durationInHours <= 720) {
    // less than or equal to one month
    smoothingWindow = "1h";
  } else {
    // more than one month
    smoothingWindow = "2h";
  }

  return query.replace("%SMOOTHING_WINDOW", smoothingWindow);
}

/**
 * Test function for keep scroll position in place as lots of text is getting printed
 */
function getLoadsOfChunks() {
  return [
    "Looks like we need to print a lot of text...\n",
    "Yes. More text!\n",
    "Mwahahahahhahahahahahahahahahhahahahaha\n",
    ...Array.from({ length: 15 }).map(() => getLongEvilLaugh()),
    "Yes. More text!\n",
    "Yes. More text!\n",
    "Yes. More text!\n",
    "Yes. More text!\n",
    "Yes. More text!\n",
    "Yes. More text!\n",
    "Yes. More text!\n",
    "Yes. More text!\n",
    "Yes. More text!\n",
  ];

  function getLongEvilLaugh() {
    const evilLaugh = Array.from({ length: 8 })
      .map(() => "Mwahahahahhahahahahahahahahahhahahahaha")
      .join();
    return `Yes. More text! ${evilLaugh}\n`;
  }
}
