import { useHandler } from "@fiberplane/hooks";
import { Button, Icon } from "@fiberplane/ui";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { selectProxyList } from "../../../../../selectors";
import { dispatch } from "../../../../../store";
import {
  deleteProxyAndRefreshList,
  loadProxyList,
  showDeleteProxyConfirmationDialog,
  showModal,
} from "../../../../../thunks";
import { sortBy } from "../../../../../utils";
import { ButtonBar } from "../../../../UI";
import {
  EmptyMessage,
  SettingsPageActionButton,
  SettingsPageErrorMessage,
  StyledTable,
  useSortingParams,
} from "../shared";
import { ProxyListHeader } from "./ProxyListHeader";
import { ProxyListItem } from "./ProxyListItem";

export function ProxyList(): JSX.Element {
  const { data: proxies, error } = useSelector(selectProxyList);

  useEffect(() => {
    dispatch(loadProxyList());
  }, []);

  const showCreateModal = useHandler(() => {
    dispatch(showModal({ type: "createProxy" }));
  });

  const showDeleteModal = useHandler((name: string) => {
    const onConfirm = () => {
      dispatch(deleteProxyAndRefreshList(name));
    };

    dispatch(showDeleteProxyConfirmationDialog({ name, onConfirm }));
  });

  const { sortDirection, sortProperty } = useSortingParams();
  const proxyList = useMemo(
    () =>
      proxies &&
      sortBy(
        [...proxies],
        (item) => (sortProperty === "status" ? item.status : item.name),
        sortDirection === "descending",
      ),
    [proxies, sortDirection, sortProperty],
  );

  return (
    <>
      <SettingsPageActionButton onClick={() => showCreateModal()}>
        <Icon iconType="plus" /> New
      </SettingsPageActionButton>

      {error && <SettingsPageErrorMessage>{error}</SettingsPageErrorMessage>}

      {proxyList?.length === 0 ? (
        <EmptyMessage
          action={
            <ButtonBar>
              <Button onClick={showCreateModal}>
                <Icon iconType="plus" /> New FPD
              </Button>

              <Button
                buttonStyle="secondary"
                // @ts-expect-error FIXME: This is rendered correctly in the DOM, but the type definition is wrong.
                as="a"
                href="https://docs.fiberplane.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </Button>
            </ButtonBar>
          }
          description="Connect your first FPD to start resolving incidents"
          iconType="proxy"
          title="No FPD instances yet"
        />
      ) : (
        <StyledTable cellSpacing={0}>
          <thead>
            <ProxyListHeader />
          </thead>
          <tbody>
            {proxyList?.map((proxy) => (
              <ProxyListItem
                key={proxy.id}
                proxy={proxy}
                showDeleteModal={showDeleteModal}
              />
            ))}
          </tbody>
        </StyledTable>
      )}
    </>
  );
}
