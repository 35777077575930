import { Button, Icon } from "@fiberplane/ui";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

import {
  selectDataSources,
  selectSelectedDataSources,
} from "../../../../selectors";
import type {
  ConfigureDirectAccessModalProps,
  DataSource,
} from "../../../../types";
import { formatDataSourceKey, isSameDataSource } from "../../../../utils";
import { DataSourceItem } from "./DataSourceItem";
import { DataSourceSelectorGroupTitle } from "./DataSourceSelectorGroupTitle";

type Props = {
  providerType: string;
  onConfigureDirectAccess: (params?: ConfigureDirectAccessModalProps) => void;
  onSelect: (dataSource: DataSource) => void;
};

export function DataSourceSelectorGroup({
  providerType,
  onConfigureDirectAccess,
  onSelect,
}: Props): JSX.Element {
  const allDataSources = useSelector(selectDataSources);

  const dataSources = useMemo(() => {
    const dataSources = allDataSources.filter(
      (source) => source.providerType === providerType,
    );
    return dataSources.sort((a, b) => {
      // If the statuses is equal, sort by formatted names, which are prefixed
      // with the proxy name, if there is any:
      if (a.status === b.status) {
        return formatDataSourceKey(a) > formatDataSourceKey(b) ? 1 : -1;
      }

      // First direct data sources, then connected sources, and finally the
      // ones with errors:
      switch (a.status) {
        case undefined:
          return -1;
        case "connected":
          return b.status === undefined ? 1 : -1;
        case "error":
          return 1;
      }
    });
  }, [allDataSources, providerType]);

  const selectedDataSources = useSelector(selectSelectedDataSources);
  const selectedDataSource = selectedDataSources[providerType];

  return (
    <div data-testid={providerType}>
      <DataSourceSelectorGroupTitle type={providerType}>
        <Button
          onClick={() => onConfigureDirectAccess({ providerType })}
          aria-label="Add direct access"
          buttonStyle="tertiary-grey"
        >
          <Icon iconType="lightning" width="16" height="16" />
          <Icon iconType="plus" width="16" height="16" />
        </Button>
      </DataSourceSelectorGroupTitle>
      <StyledList role="listbox">
        {dataSources.length === 0 && (
          <Empty>No data source configured yet</Empty>
        )}
        {dataSources.map((dataSource) => {
          const selected = isSameDataSource(dataSource, selectedDataSource);
          return (
            <DataSourceItem
              key={formatDataSourceKey(dataSource)}
              name={dataSource.name}
              providerType={dataSource.providerType}
              connectionStatus={dataSource.status}
              metaInfo={dataSource.proxyName}
              selected={selected}
              onEdit={
                dataSource.proxyName
                  ? undefined
                  : () => onConfigureDirectAccess({ dataSource })
              }
              onSelect={() => {
                if (!selected) {
                  onSelect(dataSource);
                }
              }}
            />
          );
        })}
      </StyledList>
    </div>
  );
}

const StyledList = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

const Empty = styled.p`
  padding: 0 12px;
  margin: 0;
  font: ${({ theme }) => theme.fontStudioHeadingsH6ShortHand};
`;
