import { Icon } from "@fiberplane/ui";
import { useSelector } from "react-redux";

import { useEffect } from "react";
import { useListFrontMatterSchemasQuery } from "../../../../api";
import { FRONT_MATTER_CELL_ID } from "../../../../constants";
import {
  selectActiveWorkspaceIdOrThrow,
  selectIntegrations,
  selectNotebookFrontMatterSchema,
} from "../../../../selectors";
import { IntegrationType, loadIntegrations } from "../../../../slices";
import { dispatch } from "../../../../store";
import { appendFrontMatterSchema, showModal } from "../../../../thunks";
import type { FrontMatterSchemaEntry } from "../../../../types";
import { noop } from "../../../../utils";
import {
  MenuItem,
  MenuItemGroup,
  PositionedMenu,
  type PositionedMenuProps,
} from "../../../UI";
import { getSchemaIconType } from "../util";
import { DEFAULT_FRONT_MATTER_SCHEMAS } from "./constants";

type Props = Pick<PositionedMenuProps, "element" | "onClose" | "placement">;

export function FrontMatterMenu(props: Props) {
  const { element, onClose, placement } = props;
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const {
    isFetching,
    isLoading,
    data: apiSchemas,
    error,
  } = useListFrontMatterSchemasQuery({
    workspaceId,
  });

  const schemas =
    apiSchemas && Object.keys(apiSchemas).length > 0
      ? apiSchemas
      : DEFAULT_FRONT_MATTER_SCHEMAS;

  const loading = isFetching || isLoading;
  const { data } = useSelector(selectIntegrations);
  useEffect(() => {
    dispatch(loadIntegrations({ type: IntegrationType.Personal }));
  }, []);

  const githubIntegrationInstalled = !!data?.find(
    (item) => item.id === "github",
  );

  const currentSchema = useSelector(selectNotebookFrontMatterSchema) || [];
  const names = new Set(currentSchema.map((field) => field.key));

  if (loading) {
    return (
      <MenuItem onActivate={noop} id="loading" title="Loading..." disabled />
    );
  }

  if (error) {
    return (
      <PositionedMenu
        key="loading"
        element={element}
        placement={placement}
        onClose={onClose}
      >
        <MenuItem
          onActivate={noop}
          id="error"
          title="Unable to load schema data. Retry?"
          disabled
        />
      </PositionedMenu>
    );
  }

  if (!schemas) {
    return (
      <PositionedMenu
        key="no-schemas"
        element={element}
        placement={placement}
        onClose={onClose}
      >
        <MenuItem
          onActivate={noop}
          id="no-schemas"
          title="No schemas"
          disabled
        />
      </PositionedMenu>
    );
  }

  return (
    <PositionedMenu
      key="schemas"
      element={element}
      placement={placement}
      onClose={onClose}
    >
      {Object.entries(schemas).map(([name, schema]) => (
        <MenuItemGroup key={name} title={name}>
          {schema
            .filter(
              (field) =>
                githubIntegrationInstalled ||
                field.schema.type !== "github_pull_request",
            )
            .map((field) =>
              names.has(field.key) ? null : (
                <MenuItem
                  id={field.key}
                  key={field.key}
                  title={field.schema.displayName || field.key}
                  onActivate={
                    field.schema.type === "github_pull_request"
                      ? () =>
                          dispatch(
                            showModal({ type: "createGitHubPullRequest" }),
                          )
                      : () => addField(field)
                  }
                  iconLeft={<Icon iconType={getSchemaIconType(field.schema)} />}
                  iconRight={{
                    icon: <Icon iconType="key_return" />,
                    showOnlyOnSelection: true,
                  }}
                />
              ),
            )}
        </MenuItemGroup>
      ))}
    </PositionedMenu>
  );
}

/**
 * Create a new front-matter field and move the focus to it.
 */
const addField = (field: FrontMatterSchemaEntry) => {
  // Add the field to the notebook's front-matter schema
  dispatch(
    appendFrontMatterSchema(field.key, field.schema, {
      focus: {
        type: "collapsed",
        cellId: FRONT_MATTER_CELL_ID,
        field: field.key,
      },
    }),
  );
};
