import { setFocus, withActiveNotebook } from "../../../actions";
import { dispatch } from "../../../store";

type FocusFieldOptions = {
  offset?: number;
};

export function focusField(
  cellId: string,
  field: string,
  { offset }: FocusFieldOptions = {},
) {
  dispatch(
    withActiveNotebook(setFocus({ type: "collapsed", cellId, field, offset })),
  );
}
