import cx from "classnames";
import { css, styled } from "styled-components";

import { memo } from "react";
import type { NotebookFocus } from "../../../../../types";
import { INVISIBLE_CHARACTER } from "./constants";
import { useFrontMatterEditor } from "./hooks";

export const FrontMatterEditor = memo(function FrontMatterEditor(props: {
  id: string;
  value: string;
  onChange(value: string, focus?: NotebookFocus): void;
  readOnly?: boolean;
  placeholder?: string;
  validator?: (value: string) => boolean;
  className?: string;
  singleLine?: boolean;
}) {
  const {
    id,
    value,
    onChange,
    readOnly = false,
    placeholder,
    validator,
    className,
    singleLine = false,
  } = props;

  const {
    focused,
    ref,
    onBlur,
    onKeyDown,
    onKeyUp,
    onMouseUp,
    onPaste,
    error,
    editorValue,
  } = useFrontMatterEditor({
    id,
    value,
    onChange,
    validator,
    singleLine,
  });

  const shouldRenderValue = focused || !!value;
  return (
    <Editor
      data-placeholder={placeholder}
      ref={readOnly === true ? undefined : ref}
      contentEditable={readOnly === true ? undefined : true}
      tabIndex={0}
      aria-disabled={readOnly}
      suppressContentEditableWarning
      className={cx({ error }, className)}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onMouseUp={onMouseUp}
      onPaste={onPaste}
    >
      {
        // If the editor isn't focused, the invisible character is not shown.
        // The invisible character however is needed to make sure the editor
        // can correctly determine whether the cursor is at bottom line
        // when otherwise the last character is a newline
        shouldRenderValue && `${editorValue}${INVISIBLE_CHARACTER}`
      }
    </Editor>
  );
});

export const Editor = styled.div.attrs((props) => ({
  "data-prevent-rte": "true",
  ...props,
}))(
  ({ theme }) => css`
  white-space: pre-wrap;
  position: relative;
  z-index: 0;
  border: 1px solid transparent;
  border-radius: ${theme.borderRadius600};
  margin-left: -6px;
  padding-left: 6px;
  padding-right: 6px;

  &[aria-disabled="true"],
  &[aria-disabled="true"]:hover {
    cursor: default;
    background-color: inherit;
  }

  &[data-placeholder] {
    &:empty::before
    {
      content: attr(data-placeholder);
      color: ${theme.color.fg.subtle};
      margin-left: -6px;
      padding: 2px 0 2px 6px;
      border-radius: ${theme.borderRadius600};
    }
  }

  &:hover {
    background-color: ${theme.color.bg.hover};
  }

  &:focus {
    outline: none;
    border-color: ${theme.color.input.border.default};
    background-color: inherit;
  }

  &.error {
    border-color: ${theme.colorDanger500};
  }
`,
);
