import {
  type AppAction,
  type CreateLabelDraftAction,
  type DeleteLabelAction,
  LABELS_EDITOR_DELETE,
  LABELS_EDITOR_DRAFT_CREATE,
  LABELS_EDITOR_RESET,
  type ResetLabelsEditorAction,
  SET_COMMAND_MENU_QUERY,
  SET_COMMAND_MENU_SELECTED_INDEX,
  SET_COMMAND_MENU_VARIANT,
} from "../actions";
import type { CommandMenuState } from "../state";
import {
  isLabelsEditorActionForTarget,
  labelsEditorReducer,
} from "./labelsEditorReducer";

export const initialState: CommandMenuState = {
  selectedIndex: 0,
  variant: { type: "none" },
  query: "",
};

export function commandMenuReducer(
  state = initialState,
  action: AppAction,
): CommandMenuState {
  switch (action.type) {
    case LABELS_EDITOR_DELETE:
      return handleDeleteLabel(state, action.payload);

    case LABELS_EDITOR_DRAFT_CREATE:
      return handleCreateLabelDraft(state, action.payload);

    case LABELS_EDITOR_RESET:
      return handleResetLabelsEditor(state, action.payload);

    case SET_COMMAND_MENU_SELECTED_INDEX:
      return { ...state, selectedIndex: action.payload };

    case SET_COMMAND_MENU_QUERY:
      return { ...state, query: action.payload };

    case SET_COMMAND_MENU_VARIANT:
      return { ...state, selectedIndex: 0, variant: action.payload };

    default: {
      const { variant } = state;
      if (
        variant.type === "search" &&
        isLabelsEditorActionForTarget(action, "command_menu")
      ) {
        return {
          ...state,
          variant: {
            ...variant,
            labelsEditor: labelsEditorReducer(variant.labelsEditor, action),
          },
        };
      }

      return state;
    }
  }
}

function handleCreateLabelDraft(
  state: CommandMenuState,
  { target }: CreateLabelDraftAction["payload"],
): CommandMenuState {
  if (target !== "command_menu") {
    return state;
  }

  const { variant } = state;
  return {
    ...state,
    variant: {
      type: "search",
      labels: variant.type === "search" ? variant.labels : undefined,
      labelsEditor: {
        draft: "",
        suggestions: { loading: false },
        suggestionsKey: "",
      },
    },
  };
}

function handleDeleteLabel(
  state: CommandMenuState,
  { key, target }: DeleteLabelAction["payload"],
): CommandMenuState {
  const { variant } = state;
  if (target !== "command_menu" || variant.type !== "search") {
    return state;
  }

  const labels = ((variant.type === "search" && variant.labels) || []).filter(
    (label) => label.key !== key,
  );

  return {
    ...state,
    variant: {
      type: "search",
      labels: labels.length > 0 ? labels : undefined,
      labelsEditor: labels.length > 0 ? variant.labelsEditor : undefined,
    },
  };
}

function handleResetLabelsEditor(
  state: CommandMenuState,
  { target }: ResetLabelsEditorAction["payload"],
): CommandMenuState {
  if (target !== "command_menu") {
    return state;
  }

  const { variant } = state;
  return {
    ...state,
    variant: {
      type: "search",
      labels: variant.type === "search" ? variant.labels : undefined,
    },
  };
}
