import { memo, useEffect, useRef, useState } from "react";
import { styled } from "styled-components";

import { useHandler } from "@fiberplane/hooks";
import {
  AttachedPopup,
  type AttachedPopupProps,
  Input,
  cancelEvent,
  stopPropagation,
} from "@fiberplane/ui";
import { css } from "styled-components";
import { setZeroTimeout } from "../../../utils";
import { MenuContainer } from "../Menu";
import type { CloseReason } from "../Menu";
import { FilterMenu, type FilterMenuProps } from "./FilterMenu";
import type { FilterMenuItem } from "./types";

export type PositionedFilterMenuProps = FilterMenuProps &
  Pick<AttachedPopupProps, "element" | "offset" | "placement"> & {
    placeholder?: string;
    keepFocusOnInput?: boolean;
    /**
     * Function that creates a custom menu item. Useful for adding a "Create new" item
     * or perhaps an interactive No results item.
     */
    createExtraMenuItem?: (options: {
      filterText: string;
      exactMatchFound: boolean;
    }) => FilterMenuItem | undefined;
  };

/**
 * A filter menu that is positioned relative to a reference element.
 *
 * This menu also contains a search input field.
 *
 */
export const PositionedEmbeddedSearchMenu = memo(function PositionedFilterMenu({
  element,
  offset = [0, 0],
  placement = "bottom",
  filterText = "",
  placeholder = "Search",
  onClose,
  keepFocusOnInput = false,
  ...menuProps
}: PositionedFilterMenuProps) {
  const [currentFilterText, setCurrentFilterText] = useState(filterText);
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    setCurrentFilterText(filterText);
    if (ref.current) {
      ref.current.focus();
      ref.current.setSelectionRange(0, filterText.length);
    }
  }, [filterText]);

  const closeHandler = useHandler((info: { reason: CloseReason }) => {
    onClose(info);

    if (keepFocusOnInput && info.reason === "item_activated") {
      // Add a bit of delay before attempting to grab focus
      setZeroTimeout(() => {
        ref.current?.focus();
      });
    }
  });
  const overridePopupRef = useRef<HTMLDivElement>(null);

  return (
    <AttachedPopup element={element} offset={offset} placement={placement}>
      <StyledMenuContainer
        onClick={(event) => stopPropagation(event)}
        ref={overridePopupRef}
        data-testid="positioned-filter-menu"
      >
        <Input
          type="text"
          autoFocus
          ref={ref}
          placeholder={placeholder}
          value={currentFilterText}
          data-prevent-rte // This is necessary to trigger the onChange event when you type
          onKeyDown={(event) => {
            // Prevent arrow keys from surfacing to the notebook level
            if (["ArrowLeft", "ArrowRight"].includes(event.key)) {
              stopPropagation(event);
            }
          }}
          onKeyUp={(event) => {
            // Prevent arrow keys from surfacing to parent components
            if (["ArrowLeft", "ArrowRight"].includes(event.key)) {
              stopPropagation(event);
            }
            // Prevent the user from deleting the currently selected user
            // as this input is only shown when editing the user selection.
            if (event.key === "Backspace") {
              stopPropagation(event);
            }
          }}
          onChange={(event) => {
            cancelEvent(event);
            setCurrentFilterText(event.target.value);
          }}
        />
        <StyledFilterMenu
          {...menuProps}
          overridePopupRef={overridePopupRef}
          onClose={closeHandler}
          filterText={currentFilterText}
          grabsFocus={false}
        />
      </StyledMenuContainer>
    </AttachedPopup>
  );
});

const StyledFilterMenu = styled(FilterMenu)`
  border: 0;
  outline: none;
  box-shadow: none;
  padding: 8px 0 0;
  `;

const StyledMenuContainer = styled(MenuContainer)(
  ({ theme }) => css`
  border: 1px solid ${theme.color.border.default};
`,
);
