import Prism from "prismjs";

import { Pre } from "./Pre";

const DEFAULT_SYNTAX: SupportedSyntaxName = "json";

const SupportedSyntax = {
  html: "html",
  json: "json",
  xml: "xml",
};

type SupportedSyntaxName = keyof typeof SupportedSyntax;

export function Body({ body, headers }: { body: string; headers: string }) {
  const contentType = parseContentType(headers);

  const grammar = Prism.languages[contentType];

  if (!grammar) {
    return <Pre>{body}</Pre>;
  }

  const __html = Prism.highlight(body, grammar, contentType);

  // biome-ignore lint/security/noDangerouslySetInnerHtml: In Prism we trust.
  return <Pre dangerouslySetInnerHTML={{ __html }} />;
}

const parseContentType = (headers: string): SupportedSyntaxName => {
  const contentTypeHeader = headers
    .split("\n")
    .map((line) => line.split(":"))
    .find(([key]) => key === "content-type");

  if (contentTypeHeader) {
    const [, contentTypeValue] = contentTypeHeader;

    if (!contentTypeValue) {
      return DEFAULT_SYNTAX;
    }

    if (contentTypeValue.includes("html")) {
      return "html";
    }

    if (contentTypeValue.includes("xml")) {
      return "xml";
    }
  }

  return DEFAULT_SYNTAX;
};
