import { useMemo } from "react";
import { useSelector } from "react-redux";
import { generatePath } from "react-router";
import { push } from "redux-first-history";
import { createSelector } from "reselect";

import { useListWorkspacesQuery } from "../../../../../api";
import { ROUTES } from "../../../../../constants";
import {
  selectActiveWorkspace,
  selectActiveWorkspaceRole,
  selectCommandMenuVariantType,
} from "../../../../../selectors";
import { dispatch } from "../../../../../store";
import { showModal } from "../../../../../thunks";
import type { Workspace, WorkspaceCommand } from "../../../../../types";

const EMPTY_WORKSPACE_LIST: Array<Workspace> = [];

export function useWorkspaceCommands(): Array<WorkspaceCommand> {
  const { activeWorkspace, variantType, role } = useSelector(
    selectWorkspaceCommandsState,
  );
  const { data: workspaces = EMPTY_WORKSPACE_LIST } = useListWorkspacesQuery();

  return useMemo(() => {
    if (variantType === "none") {
      const commands: Array<WorkspaceCommand> = workspaces
        .filter(
          (workspace) => activeWorkspace && activeWorkspace.id !== workspace.id,
        )
        .map((workspace) => ({
          key: `workspaces/${workspace.id}`,
          type: "workspaces",
          title: workspace.displayName,
          icon: "folder",
          description: `Switch to workspace ${workspace.displayName}`,
          workspace,
          onActivate: () =>
            dispatch(
              push(
                generatePath(ROUTES.GettingStarted, {
                  workspaceName: workspace.name,
                }),
              ),
            ),
        }));

      commands.push({
        key: "createWorkspace",
        keywords: ["workspace", "add", "insert"],
        type: "workspaces",
        title: "Create a new workspace",
        icon: "folder_plus",
        onActivate: () => dispatch(showModal({ type: "createWorkspace" })),
      });

      if (
        activeWorkspace &&
        activeWorkspace.type === "organization" &&
        role === "admin"
      ) {
        commands.push({
          type: "workspaces",
          key: "workspace/invite",
          title: "Invite member",
          keywords: ["users"],
          icon: "user_circle_plus",
          description: "Invite new members to your workspace ",
          onActivate: () =>
            dispatch(showModal({ type: "inviteWorkspaceMember" })),
        });
      }

      if (commands.length > 0) {
        return commands;
      }
    }

    return [];
  }, [activeWorkspace, variantType, role, workspaces]);
}

const selectWorkspaceCommandsState = createSelector(
  [
    selectActiveWorkspace,
    selectActiveWorkspaceRole,
    selectCommandMenuVariantType,
  ],
  (activeWorkspace, role, variantType) => ({
    activeWorkspace,
    variantType,
    role,
  }),
);
