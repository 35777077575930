export const CLIPPED_VIEWS_ICON_ID = "clipped_views_icon";

export function ClippedViewsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <clipPath id={CLIPPED_VIEWS_ICON_ID} clipPathUnits="objectBoundingBox">
          <path
            d="M0.449,0.022 C0.487,0,0.533,0,0.571,0.022 L0.977,0.254 C1,0.279,1,0.343,0.977,0.368 L0.571,0.6 C0.533,0.622,0.487,0.622,0.449,0.6 L0.043,0.368 C0,0.343,0,0.279,0.043,0.254 L0.449,0.022 M0.977,0.757 L0.571,0.989 C0.533,1,0.487,1,0.449,0.989 L0.043,0.757 C0.004,0.734,0,0.68,0.032,0.651 C0.035,0.654,0.039,0.657,0.043,0.659 L0.449,0.892 C0.487,0.914,0.533,0.914,0.571,0.892 L0.977,0.659 C0.981,0.657,0.985,0.654,0.988,0.651 C1,0.68,1,0.734,0.977,0.757 M0.979,0.562 L0.578,0.794 C0.54,0.816,0.494,0.817,0.456,0.795 L0.045,0.562 C0.005,0.54,0.002,0.485,0.033,0.457 C0.037,0.46,0.04,0.463,0.045,0.465 L0.456,0.698 C0.494,0.719,0.54,0.719,0.578,0.697 L0.979,0.465 C0.983,0.462,0.986,0.46,0.99,0.457 C1,0.485,1,0.539,0.979,0.562"
            fill="currentColor"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
