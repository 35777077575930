import { Icon } from "@fiberplane/ui";
import styled, { css } from "styled-components";

export const FieldContainer = styled.div(
  ({ theme }) => css`
    font: ${theme.font.body.md.regular};
    color: ${theme.color.fg.default};
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
);

export const FieldLabel = styled.label(
  ({ theme }) => css`
    font: ${theme.font.body.md.regular};
    color: ${theme.color.fg.default};
  `,
);

export const FieldDescription = styled.div(
  ({ theme }) => css`
    font: ${theme.font.body.md.regular};
    color: ${theme.color.fg.subtle};
    font-size: 12px;
  `,
);

export const FieldErrorMessage = styled.span(
  ({ theme }) => `
    color: ${theme.color.fg.danger};
    font: ${theme.font.body.sm.medium};
    font-size: 10px;
    display: flex;
    gap: 4px;
    align-items: center;
  `,
);

export const FieldErrorMessageIcon = styled(Icon)`
  width: 12px;
  height: 12px;
`;
