import type { IconType } from "@fiberplane/ui";

export const DEFAULT_ICON: IconType = "terminal";
export const MAX_LABELS = 4;

export const CATEGORY_INDEX_KEY = "category" as const;
export const TITLE_INDEX_KEY = "title" as const;
export const KEYWORDS_INDEX_KEY = "keywords" as const;
export const CREATED_BY_INDEX_KEY = "createdBy" as const;
export const LABELS_INDEX_KEY = "labels" as const;
export const DESCRIPTION_INDEX_KEY = "description" as const;

export type SearchIndexKey =
  | typeof CATEGORY_INDEX_KEY
  | typeof TITLE_INDEX_KEY
  | typeof KEYWORDS_INDEX_KEY
  | typeof CREATED_BY_INDEX_KEY
  | typeof LABELS_INDEX_KEY
  | typeof DESCRIPTION_INDEX_KEY;
