import { Icon } from "@fiberplane/ui";
import styled, { css } from "styled-components";

export function PrStatusPill(props: { status: string }) {
  const { status } = props;
  return (
    <Container status={status}>
      <Icon
        iconType={status === "closed" ? "close" : "git_branch"}
        width={14}
        height={14}
      />
      <span>{status}</span>
    </Container>
  );
}

const Container = styled.div<{ status: string }>(
  ({ theme, status }) => css`
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 1px 7px;
  border: 1px solid ${theme.color.border.default};
  border-radius: ${theme.radius.full};
  font: ${theme.font.body.md.medium};
  line-height: 20px;
  color: ${() => {
    switch (status) {
      case "open":
        return theme.color.support.success[500];
      case "merged":
        return theme.color.accent.aubergine[500];
      case "draft":
        return theme.color.fg.muted;
      default:
        return theme.color.support.danger[500];
    }
  }};
  border-color: currentColor;
  flex: fit-content;
`,
);
