import { memo } from "react";
import { useSelector } from "react-redux";

import {
  selectActiveWorkspace,
  selectConnectionStatus,
  selectNotebookSyncState,
} from "../../selectors";

function useIndicatorText(
  syncState: ReturnType<typeof selectNotebookSyncState>,
  connectionStatus: ReturnType<typeof selectConnectionStatus>,
  activeWorkspace: ReturnType<typeof selectActiveWorkspace>,
): string | null {
  if (!activeWorkspace) {
    return null;
  }
  if (syncState === "saving") {
    return "Saving changes...";
  }

  if (syncState === "syncing") {
    return connectionStatus === "connected"
      ? "Syncing changes..."
      : "Local changes.";
  }

  if (syncState === "waiting") {
    return "Waiting for changes...";
  }

  return syncState === "unrecoverable"
    ? "Could not sync changes."
    : "Changes saved.";
}

export const NotebookSyncIndicator = memo(
  function NotebookSyncIndicator(): JSX.Element | null {
    const connectionStatus = useSelector(selectConnectionStatus);
    const syncState = useSelector(selectNotebookSyncState);
    const activeWorkspace = useSelector(selectActiveWorkspace);

    const text = useIndicatorText(syncState, connectionStatus, activeWorkspace);

    return <div data-sync-indicator>{text}</div>;
  },
);
