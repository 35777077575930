import { type RefObject, useEffect } from "react";

/**
 * Hook that adds line numbers to syntax highlighted query
 * Because of the way we use prism, we have to re-invent the line-number plugin a little
 *
 * This is not robust to content changes. Do not use in an element whose contents (query) could update.
 */
export function usePrismLineNumbersHack({
  preRef,
  codeRef,
  query,
  grammar,
}: {
  preRef: RefObject<HTMLPreElement>;
  codeRef: RefObject<HTMLDivElement>;
  query: string;
  grammar: Prism.Grammar;
}) {
  useEffect(() => {
    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
    if (!preRef.current || !codeRef.current) {
      return;
    }

    const env = {
      code: query,
      grammar,
      language: "promql",
    };
    const code = codeRef.current;
    const codeParent = code.parentNode;

    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
    if (!codeParent || !/pre/i.test(codeParent.nodeName)) {
      // works only for <code> wrapped inside <pre> (not inline)
      return;
    }

    if (code.querySelector(".line-numbers-rows")) {
      // Abort if line numbers already exists
      return;
    }

    if (!preRef.current.classList.contains("line-numbers")) {
      // only add line numbers if <pre> has the `line-numbers` class
      return;
    }

    const match = env.code.match(/\n(?!$)/g);
    const linesNum = match ? match.length + 1 : 1;

    const lines = new Array(linesNum + 1).join("<span></span>");

    const lineNumbersWrapper = document.createElement("span");
    lineNumbersWrapper.setAttribute("aria-hidden", "true");
    lineNumbersWrapper.className = "line-numbers-rows";
    lineNumbersWrapper.innerHTML = lines;

    code.appendChild(lineNumbersWrapper);
  }, [preRef.current, codeRef.current, grammar, query]);
}
