import type { FC } from "react";
import { styled } from "styled-components";

import { NotebookLabel } from ".";
import type { Label as LabelModel } from "../../types";
import { StyledLabel } from "./NotebookLabel";

type Props = {
  labels: Array<LabelModel>;
  max?: number;
  className?: string;
};

export const LabelList: FC<Props> = ({ labels, max, className }) => {
  const shouldSlice = typeof max === "number";
  const slicedLabels = shouldSlice ? labels.slice(0, max) : labels;

  return (
    <List className={className}>
      {slicedLabels.map((label) => (
        <NotebookLabel key={label.key} label={label} />
      ))}
      {shouldSlice && labels.length > max && (
        <StyledLabel>+{labels.length - max}</StyledLabel>
      )}
    </List>
  );
};

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;
