import decamelize from "decamelize";
import type React from "react";
import { type ChangeEvent, useEffect, useMemo, useState } from "react";
import { css, styled } from "styled-components";

import type { TemplateParameter } from "../../../types";
import { ModalForm, ModalLabel, ModalLabelText, StyledInput } from "../../UI";

type Props = {
  parameters: Array<TemplateParameter>;
  onChange(data: Record<string, string>): void;
  onSubmit(): void;
  disabled?: boolean;
};

function InputElement(props: {
  name: string;
  label: React.ReactNode;
  value: string;
  help?: React.ReactNode;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  type?: "text" | "number" | "checkbox";
  disabled?: boolean;
}): JSX.Element {
  const { name, label, onChange, type = "text", value, help, disabled } = props;

  return (
    <ModalLabel>
      <ModalLabelText id={`id-${name}`}>{label}</ModalLabelText>
      <StyledInput
        type={type}
        onChange={onChange}
        name={name}
        id={`id-${name}`}
        value={value}
        disabled={disabled}
      />
      {help && <StyledHelp>{help}</StyledHelp>}
    </ModalLabel>
  );
}

export function Form(props: Props): JSX.Element {
  const { parameters, onChange, onSubmit, disabled } = props;

  const initialValue = useMemo(() => {
    return parameters.reduce(
      (value, parameter) => {
        value[parameter.name] =
          (parameter.type === "string" && parameter.defaultValue) || "";
        return value;
      },
      {} as Record<string, string>,
    );
  }, [parameters]);

  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    onChange(value);
  }, [value, onChange]);
  return (
    <ModalForm
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
    >
      {parameters.map((parameter) => {
        switch (parameter.type) {
          case "number":
            return (
              <InputElement
                label={decamelize(parameter.name, " ")}
                key={parameter.name}
                name={parameter.name}
                value={value[parameter.name] || "0"}
                type="number"
                disabled={disabled}
                onChange={(event) => {
                  setValue({
                    ...value,
                    [parameter.name]: event.target.value,
                  });
                }}
              />
            );

          case "boolean":
            return (
              <InputElement
                label={decamelize(parameter.name, " ")}
                key={parameter.name}
                name={parameter.name}
                value={value[parameter.name] || ""}
                type="checkbox"
                disabled={disabled}
                onChange={(event) => {
                  setValue({
                    ...value,
                    [parameter.name]: event.target.value,
                  });
                }}
              />
            );

          case "object":
            return (
              <InputElement
                label={decamelize(parameter.name, " ")}
                key={parameter.name}
                name={parameter.name}
                value={value[parameter.name] || ""}
                type="text"
                help={`Objects should be formatted like JSON, i.e. {"a": "b"}`}
                disabled={disabled}
                onChange={(event) => {
                  setValue({
                    ...value,
                    [parameter.name]: event.target.value,
                  });
                }}
              />
            );

          case "array":
            return (
              <InputElement
                label={decamelize(parameter.name, " ")}
                key={parameter.name}
                name={parameter.name}
                value={value[parameter.name] || ""}
                type="text"
                help="Arrays should be entered like JSON, i.e. [1,2,3]"
                disabled={disabled}
                onChange={(event) => {
                  setValue({
                    ...value,
                    [parameter.name]: event.target.value,
                  });
                }}
              />
            );

          default:
            return (
              <InputElement
                label={decamelize(parameter.name, " ")}
                key={parameter.name}
                name={parameter.name}
                value={value[parameter.name] || ""}
                disabled={disabled}
                onChange={(event) => {
                  setValue({
                    ...value,
                    [parameter.name]: event.target.value,
                  });
                }}
              />
            );
        }
      })}
      <input type="submit" hidden />
    </ModalForm>
  );
}

const StyledHelp = styled.div(
  ({ theme }) => css`
    font-size: ${theme.fontNotebooksSmallerFontSize};
    color: ${theme.colorBase600};
  `,
);
