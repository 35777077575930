export const FETCH_THREADS_PENDING = "discussions/fetchThreads/PENDING";
export const FETCH_THREADS_FULFILLED = "discussions/fetchThreads/FULFILLED";
export const FETCH_THREADS_REJECTED = "discussions/fetchThreads/REJECTED";

export const SUBMIT_COMMENT_PENDING = "discussions/submitComment/PENDING";
export const SUBMIT_COMMENT_FULFILLED = "discussions/submitComment/FULFILLED";
export const SUBMIT_COMMENT_REJECTED = "discussions/submitComment/REJECTED";

export const TOGGLE_THREAD_SHOW_REPLIES = "discussions/thread/toggleReplies";
export const SET_THREAD_DELETION_PROMPT = "discussions/thread/deletionPrompt";
export const DELETE_THREAD = "discussions/thread/delete";

export type DiscussionsAction =
  | DeleteThreadAction
  | FetchThreadsPendingAction
  | FetchThreadsFulfilledAction
  | FetchThreadsRejectedAction
  | SubmitCommentFulfilledAction
  | SubmitCommentPendingAction
  | SubmitCommentRejectedAction
  | ThreadSetDeletionPromptAction
  | ToggleThreadRepliesAction;

type FetchThreadsPendingAction = {
  type: typeof FETCH_THREADS_PENDING;
};

type FetchThreadsFulfilledAction = {
  type: typeof FETCH_THREADS_FULFILLED;
};

type FetchThreadsRejectedAction = {
  type: typeof FETCH_THREADS_REJECTED;
};

type SubmitCommentPendingAction = {
  type: typeof SUBMIT_COMMENT_PENDING;
  payload: string;
};

type SubmitCommentFulfilledAction = {
  type: typeof SUBMIT_COMMENT_FULFILLED;
  payload: string;
};

type SubmitCommentRejectedAction = {
  type: typeof SUBMIT_COMMENT_REJECTED;
  payload: string;
};

type ThreadSetDeletionPromptAction = {
  type: typeof SET_THREAD_DELETION_PROMPT;
  payload: {
    threadId: string;
    showThreadDeletionPrompt: boolean;
  };
};

type ToggleThreadRepliesAction = {
  type: typeof TOGGLE_THREAD_SHOW_REPLIES;
  payload: string;
};

type DeleteThreadAction = {
  type: typeof DELETE_THREAD;
  payload: string;
};
