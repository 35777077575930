import { Button, Icon } from "@fiberplane/ui";
import { Link } from "react-router-dom";
import { styled } from "styled-components";

import { ROUTES } from "../../constants";

type Props = {
  error: Error;
  componentStack?: string | null;
  resetError?: () => void;
  description?: string;
};

const isProduction = process.env.NODE_ENV === "production";

export function ErrorScreen({
  error,
  componentStack,
  resetError,
  description = "An unexpected error occurred",
}: Props) {
  return (
    <Container>
      <Icon iconType="fiberplane_logo_cracked" />
      <Title>An error has occurred</Title>
      <Description>
        {description}. <a href="mailto:support@fiberplane.com">Contact us</a> if
        you need assistance.
      </Description>
      {resetError && (
        <LinkButton as={Link} to={ROUTES.Root} onClick={resetError}>
          Back to home
        </LinkButton>
      )}
      {!isProduction && (
        <StackTrace>
          {error && (
            <>
              <h2>Exception</h2>
              <Pre>{error.message.trim()}</Pre>
            </>
          )}
          {componentStack && (
            <>
              <h2>Call Stack</h2>
              <Pre>{componentStack.trim()}</Pre>
            </>
          )}
        </StackTrace>
      )}
    </Container>
  );
}

const LinkButton = styled(Button)``;

const Container = styled.div`
  padding: 16px;
  gap: 16px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Title = styled.h1`
  display: inline-block;
  font-weight: ${({ theme }) => theme.fontNotebooksH1FontWeight};
  font-size: ${({ theme }) => theme.fontNotebooksH1FontSize};
  line-height: 38px;
  margin: 0;
  text-align: center;
`;

const Description = styled.p`
  text-align: center;
  font-weight: ${({ theme }) => theme.fontNotebooksSmallestFontWeight};
  font-size: ${({ theme }) => theme.fontNotebooksBaseFontSize};
  line-height: 28px;
  margin: 0;
  max-width: 728px;
`;

const StackTrace = styled.section`
  margin-top: 32px;
`;

const Pre = styled.pre`
  overflow-x: scroll;
  width: 100%;
  max-width: 80vw;
`;
