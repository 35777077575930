import { usePrevious } from "@fiberplane/hooks";
import { forwardRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { css, styled } from "styled-components";

import {
  deleteLabel,
  resetLabelsEditor,
  setCommandMenuQuery,
} from "../../../../actions";
import {
  selectCommandMenuLabels,
  selectCommandMenuLabelsEditor,
  selectCommandMenuQuery,
  selectCommandMenuVariant,
} from "../../../../selectors";
import type { CommandMenuVariant } from "../../../../state";
import { dispatch } from "../../../../store";
import type { Label } from "../../../../types";
import { LabelsEditor } from "../../../LabelsEditor";
import { onLabelsEditorKeyDown, onSearchInputKeyDown } from "../thunks";
import type { Result } from "../types";
import { QuerySuggestions } from "./QuerySuggestions";

type Props = {
  onFocusRequested: () => void;
  selectedResult: Result | undefined;
};

export const SearchBar = forwardRef(function SearchBar(
  { onFocusRequested, selectedResult }: Props,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const query = useSelector(selectCommandMenuQuery);
  const labels = useSelector(selectCommandMenuLabels);
  const variant = useSelector(selectCommandMenuVariant);
  const editor = useSelector(selectCommandMenuLabelsEditor);
  const previousVariant = usePrevious(variant);

  const didLabelEditorClose =
    previousVariant?.type === "search" &&
    previousVariant.labelsEditor &&
    (variant.type !== "search" || !variant.labelsEditor);

  useEffect(() => {
    if (didLabelEditorClose) {
      onFocusRequested();
    }
  }, [didLabelEditorClose, onFocusRequested]);

  return (
    <Container onClick={onFocusRequested}>
      <SearchInputContainer>
        {variant.type === "search" &&
          (variant.labels || variant.labelsEditor) && (
            <StyledLabelsEditor
              editor={editor}
              labels={labels}
              onBlur={onBlurLabelsEditor}
              onDelete={onDeleteLabel}
              onKeyDown={(event) =>
                dispatch(onLabelsEditorKeyDown(event, selectedResult))
              }
              type="command_menu"
            />
          )}
        <SearchInput
          ref={ref}
          autoFocus
          placeholder={getPlaceholder(variant)}
          value={query}
          onChange={(event) => {
            dispatch(setCommandMenuQuery(event.target.value));
          }}
          data-testid="command-menu-input"
          onKeyDown={(event) => dispatch(onSearchInputKeyDown(event))}
        />
      </SearchInputContainer>
      <QuerySuggestions />
    </Container>
  );
});

SearchBar.whyDidYouRender = true;

function getPlaceholder(variant: CommandMenuVariant): string {
  switch (variant.type) {
    case "search":
      return "Search for notebooks";
    case "selectTemplate":
      return "Search for templates";
    default:
      return "Type a command";
  }
}

function onBlurLabelsEditor(event: React.FocusEvent) {
  const { relatedTarget } = event;
  if (relatedTarget instanceof Element) {
    const resultsEl = document.querySelector(
      '[data-function="command-menu-results"]',
    );

    if (resultsEl?.contains(relatedTarget)) {
      return;
    }
  }

  dispatch(resetLabelsEditor("command_menu"));
}

function onDeleteLabel(_event: React.SyntheticEvent, label: Label) {
  dispatch(deleteLabel("command_menu", label.key));
}

const StyledLabelsEditor = styled(LabelsEditor)`
  display: contents;
`;

const Container = styled.header`
  grid-area: header;
  display: flex;
  flex-direction: column;
`;

const SearchInputContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  padding: 24px 24px 16px 34px;
`;

const SearchInput = styled.input`
  ${({ theme }) => css`
    font: ${theme.font.body.md.regular};
    color: ${theme.color.fg.default};
  `}

  flex: 1 0 auto;
  background: inherit;
  color: inherit;
  border: none;
  min-height: 32px;

  &:focus {
    outline: none;
  }
`;
