import { viewsApi } from "../api";
import type { RootState } from "../state";
import { selectActiveViewName } from "./routerSelectors";
import { selectActiveWorkspaceId } from "./workspaceSelectors";

export const selectActiveView = (state: RootState) => {
  const workspaceId = selectActiveWorkspaceId(state);
  if (!workspaceId) {
    return;
  }

  const viewName = selectActiveViewName(state);
  if (viewName) {
    return viewsApi.endpoints.getViewByName.select({
      viewName,
      workspaceId,
      // Unfortunately splitting the API causes typing issues on selectors
      // https://github.com/reduxjs/redux-toolkit/issues/2484
      // @ts-expect-error
    })(state).data;
  }
};

export const selectActiveViewDisplayName = (state: RootState) =>
  selectActiveView(state)?.displayName;

export const selectHasActiveView = (state: RootState) =>
  !!selectActiveView(state);

export const selectViewEditor = (state: RootState) => state.views.editor;
