import { createGlobalStyle, css } from "styled-components";

export const GlobalStyle = createGlobalStyle(
  ({ theme }) => css`
    * {
      box-sizing: border-box;
    }

    html,
    body {
      font: ${theme.fontNotebooksBaseShortHand};
      color: ${theme.colorForeground};
      background-color: ${theme.colorBackground};
      font-stretch: ${theme.fontNotebooksBaseFontStretch};
      text-decoration: ${theme.fontNotebooksBaseTextDecoration};
      letter-spacing: ${theme.fontNotebooksBaseLetterSpacing};
    }

    html,
    body,
    #root {
      height: 100%;
      width: 100%;
    }

    h1 {
      font: ${theme.fontNotebooksH1ShortHand};
      font-stretch: ${theme.fontNotebooksH1FontStretch};
      text-decoration: ${theme.fontNotebooksH1TextDecoration};
      letter-spacing: ${theme.fontNotebooksH1LetterSpacing};
      margin: ${theme.spacingHeadingH1};
    }

    h2 {
      font: ${theme.fontNotebooksH2ShortHand};
      font-stretch: ${theme.fontNotebooksH2FontStretch};
      text-decoration: ${theme.fontNotebooksH2TextDecoration};
      letter-spacing: ${theme.fontNotebooksH2LetterSpacing};
      margin: ${theme.spacingHeadingH2};
    }

    h3 {
      font: ${theme.fontNotebooksH3ShortHand};
      font-stretch: ${theme.fontNotebooksH3FontStretch};
      text-decoration: ${theme.fontNotebooksH3TextDecoration};
      letter-spacing: ${theme.fontNotebooksH3LetterSpacing};
      margin: ${theme.spacingHeadingH3};
    }

    p {
      margin: ${theme.spacingParagraph};
    }

    ol {
      list-style-type: decimal;
    }

    ol,
    ul {
      margin: 16px 0 18px;
      padding: 0 0 0 18px;
      /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
      font-weight: 500;
      line-height: 32px;
    }

    ol ol {
      list-style-type: lower-alpha;
    }

    ol ol,
    ul ul {
      margin: 0;
    }

    li {
      margin: ${theme.spacingListItem};
    }

    a {
      ${LinkStyle}
    }

    pre {
      margin: ${theme.spacingParagraph};
      padding: 6px 16px;
      background: ${theme.colorBase100};
      color: ${theme.colorBase600};
      font: ${theme.fontNotebooksCodeShortHand};
    }
  `,
);

export const LinkStyle = css`
  color: ${({ theme }) => theme.colorPrimary400};
  cursor: pointer;
  text-decoration: underline;
`;
