import { useHandler } from "@fiberplane/hooks";
import { Button, Icon, stopPropagation } from "@fiberplane/ui";
import { css, styled } from "styled-components";

import { useEffect, useRef } from "react";
import { COMMENT_REPLY } from "../../../constants";
import { useShouldFocus } from "../../../hooks";
import { useActiveNotebookDispatch } from "../../../store";
import {
  fetchThread,
  focusCell,
  toggleThreadShowReplies,
} from "../../../thunks";
import type { ThreadItem as Item, ThreadDetails, User } from "../../../types";
import { ThreadItem } from "./ThreadItem";

type ThreadItemContainerProps = {
  cellId: string;
  index: number;
  item: Item;
  numItems: number;
  showReplies: boolean;
  thread: ThreadDetails;
  user: User;
};

export function ThreadItemContainer({
  cellId,
  index,
  item,
  numItems,
  showReplies,
  thread,
  user,
}: ThreadItemContainerProps) {
  const dispatch = useActiveNotebookDispatch();

  const handleShowAllReplies = useHandler(() => {
    dispatch(fetchThread(thread.id));
  });

  const toggleRepliesVisibility = () => {
    dispatch(toggleThreadShowReplies(thread.id));
  };

  const firstComment = index === 0;

  const shouldFocus = useShouldFocus(cellId);
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (shouldFocus && ref.current) {
      ref.current.focus();
    }
  }, [shouldFocus]);

  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
  if (!showReplies && !firstComment) {
    return null;
  }

  return (
    <>
      <ThreadItem
        cellId={cellId}
        item={item}
        threadStatus={thread.status}
        threadId={thread.id}
        user={user}
        firstComment={firstComment}
      />
      {firstComment && (
        <ThreadActionsContainer $hasMarginBottom={!!showReplies}>
          {showReplies ? (
            <>
              {thread.itemCount > numItems && (
                <ThreadActionButton
                  buttonStyle="tertiary-grey"
                  onClick={handleShowAllReplies}
                >
                  View {thread.itemCount - numItems} older repl
                  {thread.itemCount - numItems === 1 ? "y" : "ies"}
                </ThreadActionButton>
              )}

              <ThreadActionButton
                buttonStyle="tertiary-grey"
                onClick={toggleRepliesVisibility}
              >
                Hide replies
              </ThreadActionButton>
            </>
          ) : (
            <ThreadActionButton
              buttonStyle="tertiary-grey"
              onClick={toggleRepliesVisibility}
              onKeyDown={(event) => {
                if (event.key === " ") {
                  stopPropagation(event);
                  toggleRepliesVisibility();
                  // Move focus to reply field
                  dispatch(
                    focusCell({
                      cellId,
                      field: COMMENT_REPLY,
                    }),
                  );
                }
              }}
              ref={ref}
            >
              <Icon iconType="chat_tear_drop" height={16} width={16} />
              {thread.itemCount === 1
                ? "Reply"
                : `${thread.itemCount - 1} Repl${
                    thread.itemCount === 2 ? "y" : "ies"
                  }`}
            </ThreadActionButton>
          )}
        </ThreadActionsContainer>
      )}
    </>
  );
}

const ThreadActionButton = styled(Button).attrs({
  buttonStyle: "tertiary-grey",
})`
  margin-inline-start: -2px;
`;

const ThreadActionsContainer = styled.div<{ $hasMarginBottom: boolean }>(
  ({ $hasMarginBottom }) => css`
    display: flex;
    gap: 24px;
    margin: 28px 0 ${$hasMarginBottom ? "" : 0};
  `,
);
