import { IconButton, cancelEvent, stopPropagation } from "@fiberplane/ui";
import { styled } from "styled-components";

import { setFocus } from "../../../actions";
import { DEFAULT_FIELDS, LOGS_PAGE_SIZE } from "../../../constants";
import { useFilePaste, useLogRecordsData } from "../../../hooks";
import { useActiveNotebookDispatch } from "../../../store";
import {
  exportSelectedLogRecords,
  highlightSelectedRows,
  updateCell,
  updateCellDisplayOrder,
} from "../../../thunks";
import type { LogCell, LogVisibilityFilter } from "../../../types";
import { Table } from "../../UI";
import { ExportedLabel } from "./ExportedLabel";
import { Filter } from "./Filter";
import { VisibilityMenu } from "./VisibilityMenu";
import { useHeaders, useRows } from "./hooks";
import { containsExportedLogs } from "./utils";

const DEFAULT_HEIGHT = 200;
const EXPANDED_HEIGHT = "auto";

type Props = {
  cell: LogCell;
  readOnly?: boolean;
};

export function LogCellContent({ cell }: Props) {
  const dispatch = useActiveNotebookDispatch();

  const isExportedLogs = containsExportedLogs(cell);
  const { id, displayFields = DEFAULT_FIELDS, visibilityFilter = "all" } = cell;

  const records = useLogRecordsData(cell);

  const onPaste = useFilePaste(id);

  const { rows, numRows, totalRows } = useRows(cell, records);
  const headers = useHeaders(cell);

  // When the table is clicked we want to prevent event propagation and remove focus,
  // because otherwise this will focus the entire cell (thereby focusing the title RTE
  // which will then scroll into view after any change). Since the table may be quite
  // large it can be very distracting if the scroll resets.
  const onTableClick = (event: React.MouseEvent) => {
    stopPropagation(event);
    dispatch(setFocus({ type: "none" }));
  };

  const setLogVisibilityFilter = (visibilityFilter: LogVisibilityFilter) => {
    dispatch(updateCell(id, { visibilityFilter }));
  };

  // FIXME: (JF) We should get some data back containing the total number of results.
  // For now we're assuming that if we get LOGS_PAGE_SIZE number of results back that
  // there might be more
  const maxedOutResults = numRows >= LOGS_PAGE_SIZE;

  const onChangeColumnOrder = (oldIndex: number, newIndex: number) => {
    dispatch(updateCellDisplayOrder(id, oldIndex, newIndex));
  };

  const exportSelection = (event: React.MouseEvent) => {
    cancelEvent(event);
    dispatch(exportSelectedLogRecords(id, records));
  };

  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: FIXME
    <div onClick={onTableClick} onPaste={onPaste}>
      <Table
        defaultHeight={DEFAULT_HEIGHT}
        expandedHeight={EXPANDED_HEIGHT}
        hasMore={maxedOutResults}
        rows={rows}
        displayNumRows={numRows}
        headers={headers}
        totalRows={visibilityFilter === "all" ? undefined : totalRows}
        stickyFirstCell
        onChangeColumnOrder={onChangeColumnOrder}
      >
        {isExportedLogs ? (
          <ExportedLabel />
        ) : (
          <ControlsContainer>
            <ButtonGroup>
              <IconButton
                iconType="highlighter_circle"
                onClick={() => dispatch(highlightSelectedRows(id))}
                buttonStyle="tertiary-grey"
              />
              <IconButton
                aria-label="Export to new cell"
                onClick={exportSelection}
                iconType="arrow_square_in"
                buttonStyle="tertiary-grey"
              />
              <VisibilityMenu
                visibilityFilter={visibilityFilter}
                setLogVisibilityFilter={setLogVisibilityFilter}
              />
            </ButtonGroup>

            <Filter
              displayFields={displayFields}
              records={records}
              cellId={id}
            />
          </ControlsContainer>
        )}
      </Table>
    </div>
  );
}

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 4px;
  padding-inline: 4px 12px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 4px;
`;
