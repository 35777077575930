import { Icon } from "@fiberplane/ui";
import { styled } from "styled-components";

export const IconContainer = styled.div`
  display: flex;
  padding: 6px;

  background: ${({ theme }) => theme.color.bg.default};
  border-radius: ${({ theme }) => theme.radius.minimal};
`;

export const iconRight: {
  icon: React.ReactNode;
  showOnlyOnSelection?: boolean;
} = {
  icon: <Icon iconType="key_return" />,
  showOnlyOnSelection: true,
};
