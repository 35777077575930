import styled, { css } from "styled-components";

type Status = "neutral" | "danger" | "success";

type StatusBadgeProps = {
  $status?: Status;
};

export const StatusBadge = styled.span<StatusBadgeProps>(
  ({ $status = "neutral", theme }) => {
    const colorSet = getColorSetForStatus($status);

    return css`
      grid-area: status;
      border-radius: ${theme.radius.full};
      font: ${theme.font.body.sm.regular};
      padding-inline: 6px;
      border: 1px solid;
      cursor: default;

      ${colorSet};
    `;
  },
);

const getColorSetForStatus = (status: Status) => {
  switch (status) {
    case "danger":
      return css(
        ({ theme }) => css`
          color: ${theme.color.fg.danger};
          border-color: ${theme.color.border.danger};
        `,
      );

    case "success":
      return css(
        ({ theme }) => css`
          color: ${theme.color.fg.success};
          border-color: ${theme.color.border.success};
        `,
      );

    default:
      return css(
        ({ theme }) => css`
          color: ${theme.color.fg.primary};
          border-color: ${theme.color.border.primary};
        `,
      );
  }
};
