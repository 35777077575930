import { Nudge } from "../../UI";

type Props = {
  onClose: () => void;
  element: null | HTMLElement;
};

export function SetupTeaser({ onClose, element }: Props): JSX.Element {
  return (
    <Nudge
      element={element}
      onClose={onClose}
      nudgeStyle="highlight"
      placement="bottom"
    >
      Set up your data source
    </Nudge>
  );
}
