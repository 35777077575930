import React, { useCallback, useImperativeHandle, useState } from "react";
import { styled } from "styled-components";

import {
  ModalErrorHint,
  ModalLabel,
  ModalLabelText,
  ModalTextInput,
} from "../../UI";
import type { ConfigEditorHandle, ConfigEditorProps } from "./ConfigEditor";
import { ValidationStatusIcon } from "./ValidationStatusIcon";

type SentryConfig = {
  token: string;
  organizationSlug: string;
  projectSlug: string;
};

const StyledBrandName = styled.span`
  text-transform: capitalize;
`;

function SentryProviderConfigEditorComponent(
  {
    config,
    onSubmit,
    onValidationStatusChange,
    validationStatus,
  }: ConfigEditorProps,
  ref: React.Ref<ConfigEditorHandle>,
): JSX.Element {
  const sentryConfig = isSentryConfig(config) ? config : undefined;

  const [token, setToken] = useState(sentryConfig?.token || "");
  const [organizationSlug, setOrganizationSlug] = useState(
    sentryConfig?.organizationSlug || "",
  );
  const [projectSlug, setProjectSlug] = useState(
    sentryConfig?.projectSlug || "",
  );

  const validateAndSubmit = useCallback(() => {
    if (token && organizationSlug && projectSlug) {
      const config: SentryConfig = { token, organizationSlug, projectSlug };
      onSubmit(config);
    } else {
      onValidationStatusChange("invalid");
    }
  }, [
    onSubmit,
    onValidationStatusChange,
    organizationSlug,
    projectSlug,
    token,
  ]);
  useImperativeHandle(ref, () => ({ validateAndSubmit }));

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      validateAndSubmit();
    }
  };

  return (
    <>
      <ModalLabel>
        <ModalLabelText>
          Enter your <StyledBrandName>Sentry</StyledBrandName> token
        </ModalLabelText>
        <ModalTextInput
          autoFocus={true}
          data-source-token-input
          onChange={(event) => setToken(event.target.value)}
          name="token"
          aria-invalid={
            validationStatus === "invalid" && !token ? true : undefined
          }
          trailingIcon={() => (
            <ValidationStatusIcon
              status={token ? "initial" : validationStatus}
            />
          )}
          onKeyDown={onKeyDown}
          placeholder="Your token"
          value={token}
        />
        {validationStatus === "invalid" && !token && (
          <ModalErrorHint>This is a required field</ModalErrorHint>
        )}
      </ModalLabel>
      <ModalLabel>
        Organization slug
        <ModalTextInput
          data-source-organization-slug-input
          onChange={(event) => setOrganizationSlug(event.target.value)}
          name="organization"
          aria-invalid={
            validationStatus === "invalid" && !organizationSlug
              ? true
              : undefined
          }
          trailingIcon={() => (
            <ValidationStatusIcon
              status={organizationSlug ? "initial" : validationStatus}
            />
          )}
          onKeyDown={onKeyDown}
          placeholder="my_organization"
          value={organizationSlug}
        />
        {validationStatus === "invalid" && !organizationSlug && (
          <ModalErrorHint>This is a required field</ModalErrorHint>
        )}
      </ModalLabel>
      <ModalLabel>
        Project slug
        <ModalTextInput
          data-source-project-slug-input
          onChange={(event) => setProjectSlug(event.target.value)}
          name="project"
          aria-invalid={
            validationStatus === "invalid" && !projectSlug ? true : undefined
          }
          trailingIcon={() => (
            <ValidationStatusIcon
              status={projectSlug ? "initial" : validationStatus}
            />
          )}
          onKeyDown={onKeyDown}
          placeholder="my_project"
          value={projectSlug}
        />
        {validationStatus === "invalid" && !projectSlug && (
          <ModalErrorHint>This is a required field</ModalErrorHint>
        )}
      </ModalLabel>
    </>
  );
}

export const SentryProviderConfigEditor = React.forwardRef(
  SentryProviderConfigEditorComponent,
);

function isSentryConfig(config?: object): config is SentryConfig {
  if (!config) {
    return false;
  }

  const sentryConfig = config as SentryConfig;
  return (
    typeof sentryConfig.token === "string" &&
    typeof sentryConfig.organizationSlug === "string" &&
    typeof sentryConfig.projectSlug === "string"
  );
}
