import { useHandler } from "@fiberplane/hooks";
import { useEffect } from "react";

import type { CellSubscriber } from ".";
import { dispatch } from "../../../../store";
import { showTooltip } from "../../../../thunks";
import { compact } from "../../../../utils";
import { Text } from "../../../UI";

export function useSelectionTooltips(
  contentRef: React.RefObject<HTMLElement>,
  subscribers: Array<CellSubscriber>,
) {
  const mouseOver = useHandler((event: MouseEvent) => {
    const { target } = event;
    if (target instanceof HTMLElement && target.dataset.userIds) {
      // Grab the userIds and match them to the notebookSubscribers
      const ids = target.dataset.userIds.split(",");
      const users = compact(
        ids.map((id) => subscribers.find(({ user }) => user.id === id)?.user),
      );

      // Join the names together into a string
      const content = <Text>{users.map(({ name }) => name).join(", ")}</Text>;

      dispatch(
        showTooltip(target, content, { hideOnHover: false, placement: "top" }),
      );
    }
  });

  useEffect(() => {
    const contentEl = contentRef.current;
    if (contentEl) {
      contentEl.addEventListener("mouseover", mouseOver);

      return () => {
        contentEl.removeEventListener("mouseover", mouseOver);
      };
    }
  }, [contentRef, mouseOver]);
}
