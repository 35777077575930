import { Icon } from "@fiberplane/ui";
import styled, { css } from "styled-components";
import { PillShape } from "../shared";

export function StatusPill(props: { status: string; reason?: string }) {
  const { status, reason } = props;
  return (
    <Container status={status} aria-label={reason}>
      <Icon
        iconType={
          status === "resolved" || status === "open"
            ? "check_square"
            : "warning_octagon"
        }
        width={14}
        height={14}
      />
      <span>{status}</span>
    </Container>
  );
}

const Container = styled(PillShape)<{ status: string }>(
  ({ theme, status }) => css`
    border-color: currentColor;

    color: ${() => {
      switch (status) {
        case "resolved":
        case "open":
          return theme.color.support.success[500];
        case "acknowledged":
          return theme.color.fg.warning[500];
        default:
          return theme.color.support.danger[500];
      }
    }};
`,
);
