/**
 * Returns an array with elements in the given range, from `min` inclusive,
 * up to `max` exclusive.
 */
export function range(min: number, max: number): Array<number> {
  const range = [];
  for (let i = min; i < max; i++) {
    range.push(i);
  }

  return range;
}
