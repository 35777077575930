import { generatePath } from "react-router";
import { push } from "redux-first-history";

import { insertMention, withActiveNotebook } from "../actions";
import { ROUTES } from "../constants";
import {
  makeCellFocusSelector,
  selectActiveNotebookId,
  selectActiveWorkspaceNameOrThrow,
  selectCell,
  selectCellText,
  selectConnectionStatus,
  selectContextMenu,
} from "../selectors";
import type { Thunk } from "../store";
import {
  type MentionMessage,
  type User,
  supportsEntityFormatting,
} from "../types";
import { getCursorOffset } from "../utils";
import { focusCell } from "./editorThunks";
import { addNotification } from "./notificationsThunks";

export const activateMention =
  (user: User): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const contextMenu = selectContextMenu(state);
    const initialFocusOffset = contextMenu?.typeAheadOffset;
    if (!contextMenu || initialFocusOffset === undefined) {
      return;
    }

    const { cellId, field } = contextMenu;

    const cell = selectCell(state, cellId);
    if (!(cell && supportsEntityFormatting(cell))) {
      return;
    }

    const currentFocus = makeCellFocusSelector(cellId, field)(state);
    const currentFocusOffset = getCursorOffset(currentFocus, field);
    if (!currentFocusOffset) {
      return;
    }

    const content = selectCellText(state, cell, field);

    dispatch(
      withActiveNotebook(
        insertMention({
          cell,
          content,
          field,
          user,
          initialFocusOffset,
          currentFocusOffset,
        }),
      ),
    );

    const connectionStatus = selectConnectionStatus(state);
    if (connectionStatus !== "connected") {
      dispatch(
        addNotification({
          title: "Your mention will be sent after syncing",
          disableAutoHide: true,
          type: "warning",
        }),
      );
    }
  };

export const openMention =
  (mention: MentionMessage): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const workspaceName = selectActiveWorkspaceNameOrThrow(state);

    if (selectActiveNotebookId(state) === mention.notebookId) {
      dispatch(focusCell({ cellId: mention.cellId, highlight: true }));
    } else {
      dispatch(
        push(
          `${generatePath(ROUTES.Notebook, {
            workspaceName,
            notebookId: mention.notebookId,
          })}#${encodeURIComponent(mention.cellId)}`,
        ),
      );
    }
  };

export const showMentionNotification =
  (mention: MentionMessage): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const cellType = selectCell(state, mention.cellId)?.type;

    const description = `You have been mentioned by ${
      mention.mentionedBy.name
    }${cellType === "discussion" ? " in a discussion" : ""}`;

    dispatch(
      addNotification({
        title: "New mention",
        description,
        disableAutoHide: true,
        action: (dispatch) => dispatch(openMention(mention)),
      }),
    );
  };
