// biome-ignore lint/correctness/noUnusedImports: Required for jest
import React from "react";

import type { ComponentProps, ReactElement } from "react";

import { charCount, charIndex } from "../../../utils";
import {
  type EditorNode,
  PlaceholderEditorNode,
  RENDERABLE_ANNOTATIONS,
  TextEditorNode,
} from "./EditorNode";
import {
  NEWLINE_CHAR_CODE,
  ZERO_WIDTH_SPACE,
  defaultFormatting,
} from "./constants";
import type {
  ActiveExtendedFormatting,
  ExtendedAnnotation,
  ExtendedFormatting,
} from "./types";

type TextEditorNodeProps = ComponentProps<typeof TextEditorNode>;

const emptyEditorNode: ReactElement = (
  <TextEditorNode
    text={ZERO_WIDTH_SPACE}
    activeFormatting={defaultFormatting}
    key="empty"
    startOffset={0}
    endOffset={0}
  />
);

export type ProcessFormattingOptions = {
  text: string;
  placeholder?: string;
  formatting?: ExtendedFormatting;
};

/**
 * Applies the given `formatting` to the `text` and returns an array of
 * React elements.
 */
export function processFormatting({
  text,
  placeholder,
  formatting,
}: ProcessFormattingOptions): Array<ReactElement> {
  if (text.length === 0) {
    if (placeholder) {
      return [
        <PlaceholderEditorNode
          text={placeholder}
          key={0}
          startOffset={0}
          endOffset={0}
        />,
      ];
    }

    return [emptyEditorNode];
  }

  const editorNodeList: Array<ReactElement> =
    formatting && formatting.length > 0
      ? createEditorNodeList(text, formatting)
      : [
          <TextEditorNode
            text={text}
            activeFormatting={defaultFormatting}
            key={0}
            startOffset={0}
            endOffset={charCount(text)}
          />,
        ];

  // Add a span with a zero-width space if the content ends with a
  // newline, to prevent "collapsing" of the cell:
  if (text.charCodeAt(text.length - 1) === NEWLINE_CHAR_CODE) {
    editorNodeList.push(emptyEditorNode);
  }

  return editorNodeList;
}

/**
 * A list of either:
 *
 * - `TextEditorNodeProps` with a key.
 * - A React element for a renderable annotation.
 */
type FirstPassNodeList = Array<
  (TextEditorNodeProps & { key: number }) | ReactElement
>;

/**
 * We use a two-pass algorithm to create the editor node list.
 *
 * The first pass creates an array containing
 * - the props for all text nodes that are not renderable annotations
 * - the ReactElement for all renderable annotations
 *
 * The second pass maps the props for text nodes from above to their actual
 * ReactElements, adding `mergeLeft` and `mergeRight` props as needed.
 *
 * We could likely merge the two passes into one, but the code would be more
 * complex. If we start seeing performance issues, we can revisit this.
 */
function createEditorNodeList(
  text: string,
  formatting: ExtendedFormatting,
): Array<ReactElement> {
  const firstPassNodeList: FirstPassNodeList = [];
  const formattingByOffset = getFormattingByOffset(formatting);

  let currentOffset = 0;
  let currentIndex = 0;

  // First pass:
  // - Get props for all text nodes that are not renderable annotations
  // - Get ReactElements for all renderable annotations
  const activeFormatting = { ...defaultFormatting };
  for (const [offset, annotations] of formattingByOffset.entries()) {
    try {
      // Have we reached the annotations' offset yet?
      if (offset > currentOffset) {
        // No, so let's first insert a text node up until the point of the
        // annotations.
        const index = charIndex(text, offset - currentOffset, currentIndex);
        const innerText = text.slice(currentIndex, index);

        const textEditorNodeProps = {
          key: currentOffset,
          startOffset: currentOffset,
          endOffset: offset,
          text: innerText,
          activeFormatting: { ...activeFormatting },
        };
        firstPassNodeList.push(textEditorNodeProps);
        currentIndex = index;
        currentOffset = offset;
      }

      // currentOffset is now _at_ the annotations.
      for (const annotation of annotations) {
        updateFormatting(activeFormatting, annotation);
      }

      // The list of annotations may contain (exactly) one annotation that
      // renders a non-text node. If so, render it here.
      for (const annotation of annotations) {
        const renderable = RENDERABLE_ANNOTATIONS.find((ra) =>
          ra.supports(annotation),
        );
        if (!renderable) {
          continue;
        }

        // Make sure the text in the plain text version really matches what
        // the annotation expects. Otherwise, we drop the annotation and
        // pretend it's not there.
        const plainText = renderable.getPlainText(annotation);
        if (!verifyPlainText(text, currentIndex, plainText)) {
          const actualPlainText = text.slice(
            currentIndex,
            currentIndex + plainText.length,
          );
          console.warn(
            `Plain text (${actualPlainText}) doesn't match what was expected ` +
              `by annotation of type "${annotation.type}" at offset ${offset}`,
          );
          continue;
        }

        const endOffset = currentOffset + charCount(plainText);
        const nodeProps = {
          key: currentOffset,
          startOffset: currentOffset,
          endOffset,
          activeFormatting: { ...activeFormatting },
        };
        const editorNode = renderable.getElement(annotation, nodeProps);
        firstPassNodeList.push(editorNode);
        currentIndex += plainText.length;
        currentOffset = endOffset;
        break;
      }
    } catch (error) {
      console.warn(
        `Exception at annotation offset ${offset} in text ${text}: ${error}`,
      );
      break;
    }
  }

  // No annotations left, so if necessary, add the remaining text as a text node.
  if (currentIndex < text.length) {
    const remainingText = text.slice(currentIndex);
    const nodeProps = {
      text: remainingText,
      activeFormatting: { ...activeFormatting },
      key: currentOffset,
      startOffset: currentOffset,
      endOffset: currentOffset + charCount(remainingText),
    };
    firstPassNodeList.push(nodeProps);
  }

  return mergeEditorNodeList(firstPassNodeList);
}

/**
 * Returns a map that groups all formatting annotations by offset.
 *
 * If the incoming `formatting` array is sorted by offset, the resulting map can
 * be iterated in order of offset as well.
 */
function getFormattingByOffset(
  formatting: ExtendedFormatting,
): Map<number, ExtendedFormatting> {
  const formattingByOffset = new Map<number, ExtendedFormatting>();
  for (const annotation of formatting) {
    const { offset } = annotation;
    const annotations = formattingByOffset.get(offset);
    if (annotations) {
      annotations.push(annotation); // Mutate the existing value in the map.
    } else {
      formattingByOffset.set(offset, [annotation]);
    }
  }

  return formattingByOffset;
}

/**
 * Second pass: Determine which text nodes need to be visually merged with their
 * siblings.
 *
 * Note that we never merge text nodes that are renderable annotations. An
 * explanation follows:
 *
 *   Merging renderable annotations with nodes that have "bordered" formatting
 *   (highlight, code) definitely does not work with mentions and timestamps,
 *   since they are chunkier (have more padding, i.e. more height) so their
 *   borders cannot be cleanly merged.
 *
 *   I have not yet checked with other renderable annotations (Labels and
 *   Entities), but I suspect they will have the same problem, and do not need
 *   to be merged (BB - 2022-02-09).
 */
function mergeEditorNodeList(
  firstPassNodeList: FirstPassNodeList,
): Array<ReactElement> {
  return firstPassNodeList.map((propsOrNode, index) => {
    // If we're dealing with a ReactElement, just return it
    if (propsOrNode && !isEditorNode(propsOrNode)) {
      return propsOrNode;
    }

    // If we're dealing with props, we check to see if we need to merge borders
    // between siblings
    const props = propsOrNode;
    let mergeLeftBorder = false;
    let mergeRightBorder = false;
    const prevSiblingProps = firstPassNodeList[index - 1];
    if (isEditorNode(prevSiblingProps)) {
      mergeLeftBorder = canMergeBorders(
        prevSiblingProps.activeFormatting,
        props.activeFormatting,
      );
    }

    const nextSiblingProps = firstPassNodeList[index + 1];
    if (isEditorNode(nextSiblingProps)) {
      mergeRightBorder = canMergeBorders(
        props.activeFormatting,
        nextSiblingProps.activeFormatting,
      );
    }

    return (
      <TextEditorNode
        {...props}
        key={props.key}
        mergeLeftBorder={mergeLeftBorder}
        mergeRightBorder={mergeRightBorder}
      />
    );
  });
}

/**
 * **Mutates** the `activeFormatting` based on the `annotation` passed.
 *
 * Make sure to copy the activeFormatting in the `EditorNode` component, as the
 * loop will mutate it.
 *
 * @param activeFormatting The formatting object that will be **mutated**.
 * @param annotation The annotation that should be applied.
 */
function updateFormatting(
  activeFormatting: ActiveExtendedFormatting,
  annotation: ExtendedAnnotation,
) {
  switch (annotation.type) {
    case "start_bold": {
      activeFormatting.bold = true;
      break;
    }
    case "end_bold": {
      activeFormatting.bold = false;
      break;
    }
    case "start_code": {
      activeFormatting.code = true;
      break;
    }
    case "end_code": {
      activeFormatting.code = false;
      break;
    }
    case "start_color": {
      activeFormatting.color = annotation.color;
      break;
    }
    case "end_color": {
      activeFormatting.color = undefined;
      break;
    }
    case "start_highlight": {
      activeFormatting.highlight = true;
      break;
    }
    case "end_highlight": {
      activeFormatting.highlight = false;
      break;
    }
    case "start_italics": {
      activeFormatting.italics = true;
      break;
    }
    case "end_italics": {
      activeFormatting.italics = false;
      break;
    }
    case "start_link": {
      activeFormatting.link = annotation.url;
      break;
    }
    case "end_link": {
      activeFormatting.link = undefined;
      break;
    }
    case "start_strikethrough": {
      activeFormatting.strikethrough = true;
      break;
    }
    case "end_strikethrough": {
      activeFormatting.strikethrough = false;
      break;
    }
    case "start_underline": {
      activeFormatting.underline = true;
      break;
    }
    case "end_underline": {
      activeFormatting.underline = false;
      break;
    }
    case "start_selection": {
      activeFormatting.selection = [
        ...activeFormatting.selection,
        annotation.userId,
      ];
      break;
    }
    case "end_selection": {
      activeFormatting.selection = activeFormatting.selection.filter(
        (userId) => userId !== annotation.userId,
      );
      break;
    }
  }
}

/**
 * Verifies the underlying plain text is as expected by a renderable annotation.
 */
function verifyPlainText(
  text: string,
  currentIndex: number,
  expectedPlainText: string,
): boolean {
  for (let i = 0, len = expectedPlainText.length; i < len; i++) {
    const actualCharCode = text.charCodeAt(currentIndex + i);
    const expectedCharCode = expectedPlainText.charCodeAt(i);
    if (actualCharCode !== expectedCharCode) {
      return false;
    }
  }

  return true;
}

/**
 * Helper that determines if two formatting objects contain formatting that will
 * render visible boxes around the text in the editor. If they do, then we will
 * want to merge them visually, by suppressing things like borders and border
 * radiuses along their common edge.
 */
function canMergeBorders(
  leftNodeActiveFormatting: ActiveExtendedFormatting,
  rightActiveNodeFormatting: ActiveExtendedFormatting,
) {
  return (
    hasVisibleBorderStyles(leftNodeActiveFormatting) &&
    hasVisibleBorderStyles(rightActiveNodeFormatting)
  );
}

/**
 * Helper that's aware of when formatting will render visible borders around the
 * text in the editor. Typically, this happens because the text node's `<span>`
 * has a background color.
 *
 * NOTE - One issue with this logic is that a nested selection (from another
 *        collaborator) will no longer have a border radius, which is kind of a
 *        stylistic degradation.
 */
function hasVisibleBorderStyles(activeFormatting: ActiveExtendedFormatting) {
  return (
    activeFormatting.code ||
    activeFormatting.highlight ||
    activeFormatting.selection.length > 0
  );
}

function isEditorNode(object: unknown): object is EditorNode {
  return (
    typeof object === "object" && object != null && "activeFormatting" in object
  );
}
