import type { NotebookFocus, NotebookSelection } from "../../types";

/**
 * Extracts the selection from a `NotebookFocus` instance of type `"selection"`,
 * including the start and end indices.
 */
export function getSelection(
  cellIds: ReadonlyArray<string>,
  focus: NotebookFocus & { type: "selection" },
): NotebookSelection {
  const anchorIndex = cellIds.indexOf(focus.anchor.cellId);
  const focusIndex = cellIds.indexOf(focus.focus.cellId);

  const anchorPosition = { ...focus.anchor, cellIndex: anchorIndex };
  const focusPosition = { ...focus.focus, cellIndex: focusIndex };

  if (anchorIndex < focusIndex) {
    return { start: anchorPosition, end: focusPosition, focus: focusPosition };
  }

  if (anchorIndex > focusIndex) {
    return { start: focusPosition, end: anchorPosition, focus: focusPosition };
  }

  const anchorOffset = anchorPosition.offset;
  const focusOffset = focusPosition.offset;
  if (
    anchorOffset !== undefined &&
    focusOffset !== undefined &&
    anchorOffset > focusOffset
  ) {
    return { start: focusPosition, end: anchorPosition, focus: focusPosition };
  }

  return { start: anchorPosition, end: focusPosition, focus: focusPosition };
}
