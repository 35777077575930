import { zodResolver } from "@hookform/resolvers/zod";
import type { ReactNode } from "react";
import {
  type Control,
  type DefaultValues,
  type FieldErrors,
  type FieldValues,
  type SubmitErrorHandler,
  type SubmitHandler,
  useForm,
} from "react-hook-form";
import styled from "styled-components";
import type { ZodSchema } from "zod";
import { FieldErrorMessage, FieldErrorMessageIcon } from "./styled";

type FiberFormProps<Values extends FieldValues> = {
  /**
   * A function that renders the form fields and receives the control object.
   * @param {Control<Values>} control - The form control object.
   * @returns {ReactNode} The rendered form fields.
   */
  children: (control: Control<Values>) => ReactNode;
  /**
   * Default values for the form fields.
   */
  defaultValues?: DefaultValues<Values>;
  /**
   * Errors associated with the form fields.
   */
  errors?: FieldErrors<Values>;
  /**
   * Zod schema used for form validation.
   */
  schema: ZodSchema<Values>;
  /**
   * Handler for form submission errors.
   */
  submitErrorHandler: SubmitErrorHandler<Values>;
  /**
   * Handler for form submission.
   */
  submitHandler: SubmitHandler<Values>;
};

/**
 * A form component that integrates with Zod schema validation and react-hook-form.
 * @param {FiberFormProps<Values>} props - Props for the FiberForm component.
 * @returns {JSX.Element} The FiberForm component.
 * @template Values - The type of form field values.
 *
 * @example
 *
 * const MyFormState = z.object({
 *   email: z.string().email(),
 * });
 *
 * type MyFormState = z.infer<typeof MyFormState>;
 *
 * const MyForm = () => {
 *   const [errors, setErrors] = useState<FieldErrors<MyFormState>>();
 *
 *   const onSubmit = useHandler(async (payload: MyFormState) => {
 *     try {
 *       await doSomething(payload);
 *     } catch (exception) {
 *       // Field errors:
 *       setErrors((current) => ({
 *         ...current,
 *         email: {
 *           type: "error",
 *           message: "This email already exists"
 *         },
 *       });
 *
 *       // Form root level errors:
 *       setErrors((current) => ({
 *         ...current,
 *         root: {
 *           type: "error",
 *           message: "An unexpected error occured",
 *         },
 *       }))
 *     }
 *   });
 *
 *   return (
 *     <FiberForm
 *       errors={errors}
 *       schema={MyFormState}
 *       submitErrorHandler={setErrors}
 *       submitHandler={onSubmit}
 *     >
 *       (control) => (
 *         <>
 *           <FiberForm.TextInput control={control} name="email" />
 *           <Button type="submit">Submit</Button>
 *         </>
 *       )
 *     </FiberForm>
 *   );
 * }
 */
export function FiberForm<Values extends FieldValues>({
  children,
  defaultValues,
  errors,
  schema,
  submitErrorHandler,
  submitHandler,
}: FiberFormProps<Values>): JSX.Element {
  const { control, handleSubmit, formState } = useForm<Values>({
    defaultValues,
    errors,
    resolver: zodResolver(schema),
  });

  return (
    <Container onSubmit={handleSubmit(submitHandler, submitErrorHandler)}>
      {formState.errors.root && (
        <FormErrorMessage>
          <FieldErrorMessageIcon iconType="warning_octagon" />
          {formState.errors.root.message}
        </FormErrorMessage>
      )}
      {children(control)}
    </Container>
  );
}

const Container = styled.form`
  display: grid;
  gap: 16px;
`;

const FormErrorMessage = styled(FieldErrorMessage)`
  font-size: 12px;
`;
