import { css, styled } from "styled-components";

const WIDTH = 30;

export const ConsoleResizeHandle = styled.div<{ isActive: boolean }>(
  ({ isActive, theme }) => css`
    background-color: ${
      isActive ? theme.color.border.default : theme.color.border.muted
    };
    border-radius: ${theme.radius.lowest};
    cursor: ${isActive ? "grabbing" : "grab"};
    left: calc(50% - ${WIDTH / 2}px);
    position: absolute;
    top: 10px;
    height: 2px;
    width: ${WIDTH}px;
  `,
);
