import { useSelector } from "react-redux";
import { Route, Routes } from "react-router";
import { css, styled } from "styled-components";

import { ROUTES } from "../../constants";
import { selectActiveWorkspaceId } from "../../selectors";
import { Breadcrumb } from "./Breadcrumb";
import { NotebookToolbar } from "./NotebookToolbar";

export function TopMenu() {
  const activeWorkspaceId = useSelector(selectActiveWorkspaceId);

  if (!activeWorkspaceId) {
    return null;
  }

  return (
    <TopMenuContainer id="topmenu" data-testid="topmenu">
      <Breadcrumb />
      <Routes>
        <Route path={ROUTES.Notebook} element={<NotebookToolbar />} />
      </Routes>
    </TopMenuContainer>
  );
}

const TopMenuContainer = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    height: 100%;
    border-bottom: 1px solid ${theme.color.border.muted};
    padding-inline: 24px;
  `,
);
