import favIconUnseen from "url:./images/favicon-update.raw.svg";
import favIcon from "url:./images/favicon.raw.svg";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { shallowEqual, useSelector } from "react-redux";
import useFavicon from "react-use/lib/useFavicon";
import { createSelector } from "reselect";
import { styled } from "styled-components";

import { selectNotifications, selectWindowFocused } from "../../selectors";
import { Toast } from "./Toast";

export function Notifications() {
  const { hasUnseen, notifications } = useSelector(
    selectNotificationsState,
    shallowEqual,
  );

  useFavicon(hasUnseen ? favIconUnseen : favIcon);

  return (
    <LayoutGroup>
      <Stack layout>
        <AnimatePresence>
          {[...notifications].map(([key, notification]) => (
            <Toast key={key} notificationKey={key} {...notification} />
          ))}
        </AnimatePresence>
      </Stack>
    </LayoutGroup>
  );
}

const selectNotificationsState = createSelector(
  [selectNotifications, selectWindowFocused],
  (notifications, isWindowFocused) => ({
    hasUnseen: notifications.size > 0 && !isWindowFocused,
    notifications,
  }),
);

const Stack = styled(motion.div)`
  z-index: 1030;
  position: fixed;
  top: 64px;
  right: 49%;
  left: 49%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;
