import { FEATURES_KEY } from "../constants";
import type { Feature } from "../types";

type Features = {
  /**
   * Indicates whether (beta) features are enabled or not. Regardless of
   * individual feature flags' enabled state, this property determines whether
   * the app should enable features or not.
   */
  enabled: boolean;
  features: Array<{
    name: Feature;
    enabled: boolean;
  }>;
};

/**
 * Util that checks if a feature is enabled or not. Should be used when React
 * hooks can't be used.
 */
export function hasFeature(feature: Feature) {
  const data = localStorage.getItem(FEATURES_KEY);
  if (!data) {
    return false;
  }

  const storedFeatures: Features = JSON.parse(data);
  if (!storedFeatures.enabled) {
    return false;
  }

  const currentFeature = storedFeatures.features.find(
    ({ name }) => name === feature,
  );

  return !!currentFeature?.enabled;
}

/**
 * Util that returns an array of enabled feature names. Should be used when
 * React hooks can't be used.
 * @returns An array of enabled feature names
 */
export function getFeatures() {
  const data = localStorage.getItem(FEATURES_KEY);
  if (!data) {
    return [];
  }

  const storedFeatures: Features = JSON.parse(data);
  if (!storedFeatures.enabled) {
    return [];
  }

  return storedFeatures.features.reduce<Array<Feature>>(
    (accumulator, feature) => {
      if (feature.enabled) {
        accumulator.push(feature.name);
      }

      return accumulator;
    },
    [],
  );
}
