import { useParams } from "react-router-dom";
import useAsync from "react-use/lib/useAsync";

import { BadDataError } from "../../errors";
import { useQuery } from "../../hooks";
import { useAppDispatch } from "../../store";
import { expandTemplateAndRedirect } from "../../thunks";

type Params = {
  templateName: string;
};

export function ExpandTemplate() {
  const { templateName = "" } = useParams<Params>();
  const params = useQuery();
  const dispatch = useAppDispatch();

  const { loading, error } = useAsync(async () => {
    const parameters = Object.fromEntries(params.entries());

    try {
      await dispatch(expandTemplateAndRedirect(templateName, parameters));
    } catch (error) {
      if (error instanceof BadDataError && error.response?.text) {
        const errorText = await error.response.text();
        throw new Error(errorText);
      }

      throw error;
    }
  }, [templateName, params]);

  if (loading) {
    return <div>Loading</div>;
  }

  if (error) {
    if (error.message) {
      return <div>{error.message}</div>;
    }

    return <div>An unexpected error occurred</div>;
  }

  return <div>Notebook created</div>;
}
