import { Icon } from "@fiberplane/ui";
import { type Control, useController } from "react-hook-form";
import { css, styled } from "styled-components";

import type { RelativeTime, View } from "../../types";
import { MenuDivider, MenuItem, StyledLabel, ToggleMenu } from "../UI";

const RANGE_OPTIONS: Array<RangeOption> = [
  { title: "Today", value: { unit: "days", value: 1 } },
  { title: "Last 2 days", value: { unit: "days", value: 2 } },
  { title: "Last 3 days", value: { unit: "days", value: 3 } },
  { title: "Last 4 days", value: { unit: "days", value: 4 } },
  { title: "Last 5 days", value: { unit: "days", value: 5 } },
  { title: "Last 6 days", value: { unit: "days", value: 6 } },
  { title: "Last week", value: { unit: "days", value: 7 } },
  { title: "Last 2 weeks", value: { unit: "days", value: 14 } },
  { title: "Last month", value: { unit: "days", value: 30 } },
];

type RangeOption = {
  title: string;
  value: RelativeTime;
};

type Props = {
  canMutate: boolean;
  control: Control<View>;
};

export function TimeRangeToggleMenu({ canMutate, control }: Props) {
  const {
    field: { value, onChange },
  } = useController({ control, name: "relativeTime" });

  const selectedOption = value
    ? RANGE_OPTIONS.find(
        ({ value: option }) =>
          option.unit === value.unit && option.value === value.value,
      )
    : undefined;

  if (!canMutate) {
    return (
      <ToggleContainer>
        <ToggleComponent
          active={false}
          canMutate={canMutate}
          selected={selectedOption}
        />
      </ToggleContainer>
    );
  }

  return (
    <ToggleContainer>
      <ToggleMenu
        placement="bottom-start"
        offset={[0, 8]}
        toggleElement={({ opened }) => (
          <ToggleComponent
            active={opened}
            canMutate={canMutate}
            selected={selectedOption}
          />
        )}
      >
        {RANGE_OPTIONS.map((option) => {
          const isSelected =
            selectedOption &&
            option.value.unit === selectedOption.value.unit &&
            option.value.value === selectedOption.value.value;

          return (
            <MenuItem
              key={option.title}
              id={option.title}
              title={option.title}
              iconRight={{
                icon: isSelected ? <Icon iconType="check" /> : undefined,
              }}
              onActivate={() => onChange(option.value)}
            />
          );
        })}
        <MenuDivider />
        <MenuItem
          id="reset"
          title="Reset filter"
          onActivate={() => onChange(null)}
          textStyle="danger"
        />
      </ToggleMenu>
    </ToggleContainer>
  );
}

function ToggleComponent({
  active,
  selected,
  canMutate,
}: {
  active: boolean;
  selected?: RangeOption;
  canMutate: boolean;
}) {
  return (
    <StyledLabelButton $active={active} $canMutate={canMutate}>
      <StyledIcon iconType="clock" /> {selected?.title ?? "Filter on time"}
    </StyledLabelButton>
  );
}

const ToggleContainer = styled.div`
  grid-area: timerange;
`;

const StyledLabelButton = styled(StyledLabel)<{
  $active: boolean;
  $canMutate: boolean;
}>(
  ({ theme, $active, $canMutate }) => css`
    cursor: ${$canMutate ? "pointer" : "initial"};
    background-color: ${$active ? theme.colorBase600 : theme.colorBase200};
    color: ${$active ? theme.colorBackground : theme.colorBase600};
  `,
);

const StyledIcon = styled(Icon)`
  cursor: pointer;
  width: 1em;
  height: 1em;
`;
