import { AnimatePresence } from "framer-motion";
import { createElement, memo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { css, styled } from "styled-components";

import { CellById } from "../../../CellById";
import { selectActiveFormatting, selectCurrentUser } from "../../../selectors";
import type { CellFocus } from "../../../types";
import { Cursors } from "./Cursors";
import { resetCss } from "./EditorNode";
import { Toolbar } from "./Toolbar";
import {
  useBrowserSelection,
  useCellSubscribers,
  useContentWithFormatting,
  useRichTextEventHandlers,
  useSelectionTooltips,
} from "./hooks";
import type { ExtendedFormatting } from "./types";

const ALLOWED_READONLY_HANDLERS = new Set([
  "onClick",
  "onDoubleClick",
  "onMouseDown",
]);

export type RichTextInputProps = {
  cellId: string;

  className?: string;

  /**
   * Optional field name in case Cell contains more than one Rich Text inputs.
   */
  field?: string;

  focus: CellFocus;

  /**
   * Optional rich-text formatting to display.
   */
  formatting?: ExtendedFormatting;

  /**
   * Custom event handler that is called when content is pasted that cannot be
   * handled by the RTE itself.
   */
  onPaste?: React.ClipboardEventHandler;

  /**
   * Optional placeholder to display in case of an empty value.
   */
  placeholder?: string;

  readOnly?: boolean;

  /**
   * Set to `true` to disable formatting options.
   */
  disableFormatting?: boolean;

  /**
   * Set to `true` to disable spellcheck on the container element.
   * This is useful if the cell will contain content with non-standard words, like code.
   */
  disableSpellCheck?: boolean;

  /**
   * The top-level element type
   */
  tagName?: string;

  /**
   * The plain-text value of the input.
   */
  value: string;

  /**
   * Optional value that can be used to re-trigger the useBrowserSelection hook
   * Useful if the cell has other components in the DOM that could steal focus
   * (like a select input)
   */
  browserSelectionDependency?: unknown;
};

export const RichTextInput = memo(function RichTextInput(
  props: RichTextInputProps,
): JSX.Element {
  const {
    cellId,
    className,
    field,
    focus,
    formatting,
    placeholder,
    readOnly,
    disableFormatting = false,
    disableSpellCheck = false,
    tagName = "div",
    value,
    browserSelectionDependency,
  } = props;

  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLSpanElement>(null);

  const activeFormatting = useSelector(selectActiveFormatting);

  const attributes: React.HTMLAttributes<HTMLElement> = {
    className,
    // @ts-ignore
    "data-field": field,
    "data-rte-container": "",
    $readOnly: readOnly,
    ref: setContainer,
    as: tagName,
  };

  const { onUrlEditorRequested, ...eventHandlers } = useRichTextEventHandlers(
    props,
    activeFormatting,
    container,
    contentRef,
  );

  for (const [eventName, handler] of Object.entries(eventHandlers)) {
    if (!readOnly || (readOnly && ALLOWED_READONLY_HANDLERS.has(eventName))) {
      // @ts-ignore
      attributes[eventName] = handler;
    } else {
      // In case the handler isn't allowed for read-only cells, replace them
      // with a handler that shakes the lock icon of the cell. We're replacing
      // the onKeyDown handler with the onBeforeInput handler so we don't have
      // to catch input that doesn't produce a character, allowing the user to
      // navigate their cursor and select text - without the lock shaking.
      // @ts-ignore
      attributes[eventName === "onKeyDown" ? "onBeforeInput" : eventName] =
        () => CellById.get(cellId)?.shake();
    }
  }

  const cellSubscribers = useCellSubscribers(cellId);
  const currentUser = useSelector(selectCurrentUser);

  useBrowserSelection(
    container,
    focus,
    field,
    value,
    browserSelectionDependency,
  );
  useSelectionTooltips(contentRef, cellSubscribers);

  const editorNodes = useContentWithFormatting(
    cellId,
    focus,
    field,
    value,
    formatting,
    placeholder,
    currentUser,
    cellSubscribers,
  );

  const showToolbar =
    disableFormatting !== true &&
    (focus.type === "selection" ||
      (focus.type === "collapsed" && !!activeFormatting.link));

  const children = (
    <>
      <NodesContainer
        suppressContentEditableWarning
        contentEditable={!readOnly}
        ref={contentRef}
        tabIndex={0}
        spellCheck={!disableSpellCheck}
      >
        {editorNodes}
      </NodesContainer>
      <AnimatePresence>
        {!readOnly && showToolbar && container && (
          <Toolbar
            cellId={cellId}
            activeFormatting={activeFormatting}
            container={container}
            focus={focus}
            field={field}
            formatting={formatting}
            text={value}
            onUrlEditorRequested={onUrlEditorRequested}
          />
        )}
      </AnimatePresence>
      {container && (
        <Cursors
          cellId={cellId}
          cellSubscribers={cellSubscribers}
          container={container}
          contentRef={contentRef}
          focus={focus}
          field={field}
          formatting={formatting}
          value={value}
          isEditable={!readOnly}
        />
      )}
    </>
  );

  return createElement(RteContainer, attributes, children);
});

const NodesContainer = styled.span`
  ${resetCss}
`;

const RteContainer = styled.div<{ $readOnly?: boolean }>(
  ({ $readOnly, theme }) => css`
    position: relative;
    width: 100%;
    font: ${theme.font.body.l.regular};
    cursor: ${$readOnly ? "default" : "text"};
  `,
);
