import { createSelector } from "reselect";

import type { ProxiesState, RootState } from "../state";

export const selectProxiesState = (state: RootState): ProxiesState =>
  state.proxies;

export const selectProxyList = (state: RootState) =>
  selectProxiesState(state).proxyList;

export const selectProxyMap = (state: RootState) =>
  selectProxiesState(state).proxies;

export const makeProxySelector = (name: string) =>
  createSelector([selectProxyMap], (proxyMap) => proxyMap.get(name));

export const makeProxyDataSourcesSelector = (name: string) => {
  const proxySelector = makeProxySelector(name);
  return createSelector([proxySelector], (proxy) => proxy?.data?.dataSources);
};
