import { Button } from "@fiberplane/ui";
import { styled } from "styled-components";

import { dispatch } from "../../../../store";
import { showSettingsModal } from "../../../../thunks";
import { ProviderTypeIcon } from "../../../UI";

type Props = {
  loading: boolean;
  onConfigureDirectAccess: () => void;
};

export function DataSourceDropdownEmpty({
  loading,
  onConfigureDirectAccess,
}: Props): JSX.Element {
  return (
    <Container data-loading={loading}>
      <Logos>
        <IconContainer>
          <ProviderTypeIcon type="loki" width="44" />
        </IconContainer>
        <IconContainer>
          <ProviderTypeIcon type="prometheus" width="56" />
        </IconContainer>
        <IconContainer>
          <ProviderTypeIcon type="elasticsearch" width="48" />
        </IconContainer>
      </Logos>

      <Content>
        <Heading>No data sources yet</Heading>
        <Text>
          Start by connecting a data source through FPD or via{" "}
          <Link
            onClick={() => onConfigureDirectAccess()}
            data-testid="setup-direct-access"
          >
            direct access
          </Link>
        </Text>
      </Content>

      <CTAS>
        <Button onClick={() => dispatch(showSettingsModal({ section: "fpd" }))}>
          Manage FPD instances
        </Button>
        <CtaAlternative>
          Or{" "}
          <Link
            href="https://docs.fiberplane.com/docs/setting-up-providers"
            rel="noopener"
            target="_blank"
          >
            learn more
          </Link>
        </CtaAlternative>
      </CTAS>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 12px 0;
`;

const Logos = styled.div`
  display: flex;
  flex-direction: row;
`;

const IconContainer = styled.div`
  flex-grow: 0;
  display: flex;
  width: 64px;
  height: 64px;
  align-items: center;
  justify-content: center;
  margin-left: -20px;
  border-radius: ${({ theme }) => theme.borderRadiusRound};
  background: ${({ theme }) => theme.colorBackground};
  filter: ${({ theme }) => theme.effectShadowMainFilter};

  &:first-child {
    margin-left: 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: center;
`;

const Heading = styled.span`
  font: ${({ theme }) => theme.fontStudioHeadingsH4ShortHand};
  letter-spacing: ${({ theme }) => theme.fontStudioHeadingsH4LetterSpacing};
`;

const Text = styled.div`
  font: ${({ theme }) => theme.fontStudioStrongSmallShortHand};
  color: ${({ theme }) => theme.colorBase500};
`;

const CTAS = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;

  color: ${({ theme }) => theme.colorBase500};
  font: ${({ theme }) => theme.fontStudioStrongSmallShortHand};
  letter-spacing: ${({ theme }) => theme.fontStudioStrongSmallLetterSpacing};
`;

const CtaAlternative = styled.div`
  text-align: center;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colorBase500};
`;
