import useMeasure from "react-use/lib/useMeasure";
import { styled } from "styled-components";

import { Modal } from "../../UI";
import { Shortcut } from "./Shortcut";

export function Shortcuts(): JSX.Element {
  const [ref, { width = 200 }] = useMeasure<HTMLDivElement>();
  const size = width < 400 ? "narrow" : "wide";
  return (
    <Modal title="Keyboard shortcuts" icon="list">
      <StyledModalContent ref={ref} size={size}>
        <StyledColumn size={size}>
          <StyledSectionTitle>General</StyledSectionTitle>
          <StyledShortcutGroup>
            <Shortcut shortcut="ctrl+K">Command menu</Shortcut>
            <Shortcut shortcut="ctrl+shift+\">Side menu</Shortcut>
            <Shortcut shortcut="ctrl+C">Copy</Shortcut>
            <Shortcut shortcut="ctrl+v">Paste</Shortcut>
            <Shortcut shortcut="ctrl+x">Cut</Shortcut>
            <Shortcut shortcut="ctrl+z">Undo</Shortcut>
            <Shortcut shortcut="ctrl+shift+z">Redo</Shortcut>
            <Shortcut shortcut="Esc">Close dialog</Shortcut>
          </StyledShortcutGroup>
          <StyledSectionTitle>Text styling</StyledSectionTitle>
          <StyledShortcutGroup>
            <Shortcut shortcut="ctrl+b">Bold text</Shortcut>
            <Shortcut shortcut="ctrl+i">Italic text</Shortcut>
            <Shortcut shortcut="ctrl+u">Underline text</Shortcut>
            <Shortcut shortcut="ctrl+shift+k">Create/edit link</Shortcut>
          </StyledShortcutGroup>
        </StyledColumn>

        <StyledColumn size={size}>
          <StyledSectionTitle>Cell</StyledSectionTitle>

          <StyledShortcutGroup>
            <Shortcut shortcut="ctrl+shift+L">Lock cell</Shortcut>
            <Shortcut shortcut="ctrl+enter">Run query cell</Shortcut>
            <Shortcut shortcut="alt+↑">Move cell up</Shortcut>
            <Shortcut shortcut="alt+↓">Move cell down</Shortcut>
            <Shortcut shortcut="/">Show context menu</Shortcut>
          </StyledShortcutGroup>
        </StyledColumn>
      </StyledModalContent>
    </Modal>
  );
}

const StyledModalContent = styled.section<{
  size: "narrow" | "wide";
}>`
  min-width: fit-content;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: ${({ size = "narrow" }) =>
    size === "narrow" ? "column" : "row"};
  gap: 24px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 0;
`;

const StyledColumn = styled.div<{
  size: "narrow" | "wide";
}>`
  display: flex;
  flex: 1 1 ${({ size = "narrow" }) => (size === "narrow" ? "1" : "50%")};
  width: ${({ size = "narrow" }) => (size === "narrow" ? "100%" : "unset")};
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledSectionTitle = styled.span`
  display: block;
  flex: 1;
`;

const StyledShortcutGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;
