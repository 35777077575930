import type React from "react";
import { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { styled } from "styled-components";

import { cancelEvent } from "@fiberplane/ui";
import {
  closeContextMenu,
  createLabelDraft,
  editLabel,
  resetLabelsEditor,
  withActiveNotebook,
} from "../../../../actions";
import {
  makeIsFocusedFieldSelector,
  makeIsRunningSelector,
  selectCell,
  selectLabelsEditor,
  selectLabelsEditorExistingLabelKeys,
  selectLabelsEditorState,
} from "../../../../selectors";
import { type Thunk, dispatch } from "../../../../store";
import {
  focusCell,
  invokeProviderCell,
  replaceCells,
  saveLabelsEditorDraft,
} from "../../../../thunks";
import type { Label } from "../../../../types";
import {
  formatLabel,
  getQueryField,
  isMac,
  parseLabel,
  setQueryField,
  validateLabelsEditor,
} from "../../../../utils";
import { LabelsEditor } from "../../../LabelsEditor";
import { noOutlineStyling } from "../../CellContainer";
import {
  QueryButton,
  QueryFieldContainer,
  QueryIconContainer,
} from "../../codeStyling";
import { useQueryFieldValidationError } from "./QueryError";

type Props = {
  cellId: string;
  field: string;
  icon?: React.ReactNode;
  multiple: boolean;
  readOnly?: boolean;
  showSearchCta?: boolean;
  value: string;
};

export function LabelField({
  cellId,
  field,
  icon,
  multiple,
  readOnly,
  showSearchCta,
  value,
}: Props): JSX.Element {
  const selectFieldState = useMemo(
    () =>
      createStructuredSelector({
        editor: selectLabelsEditor,
        focused: makeIsFocusedFieldSelector(cellId, field),
        isRunning: makeIsRunningSelector(cellId),
      }),
    [cellId, field],
  );
  const { editor, focused, isRunning } = useSelector(selectFieldState);

  const labels = useMemo(
    () => (value ? value.split(" ").map(parseLabel) : []),
    [value],
  );

  const canCreate = multiple ? true : labels.length === 0;
  const onCreate = (event: React.SyntheticEvent) => {
    cancelEvent(event);
    dispatch(focusCell({ cellId, field }));
    dispatch(createLabelDraft("provider_field"));
  };

  const onDelete = (event: React.SyntheticEvent, label: Label) => {
    cancelEvent(event);
    dispatch(deleteLabel(cellId, field, label));
  };

  const onEdit = (event: React.SyntheticEvent, label: Label) => {
    cancelEvent(event);
    dispatch(focusCell({ cellId, field }));
    dispatch(editLabel("provider_field", label));
  };

  const onInvoke = (event: React.SyntheticEvent) => {
    cancelEvent(event);
    dispatch(invokeProviderCell(cellId));
  };

  const fieldRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    if (focused && fieldRef.current) {
      fieldRef.current.focus();
    }
  }, [focused]);

  const { ref, errorOutput } = useQueryFieldValidationError<HTMLDivElement>(
    cellId,
    field,
  );

  return (
    <>
      <QueryFieldContainer ref={ref} data-field={field}>
        {icon && <QueryIconContainer>{icon}</QueryIconContainer>}
        <StyledLabelsEditor
          tabIndex={-1}
          ref={fieldRef}
          compact
          editor={focused ? editor : undefined}
          labels={labels}
          newLabelButtonLabel={
            labels.length === 0
              ? "Add label or leave empty for all"
              : "Add label"
          }
          onBlur={onBlur}
          onCreate={canCreate ? onCreate : undefined}
          onDelete={onDelete}
          onEdit={onEdit}
          onKeyDown={(event) => dispatch(onKeyDown(event))}
          readOnly={readOnly}
          type="provider_field"
          withDeleteIcon
          withToolbar
        />
        {showSearchCta && !readOnly && (
          <QueryButton
            aria-label="Search"
            data-run-cell
            data-shortcut={`${isMac ? "cmd" : "ctrl"}+enter`}
            disabled={isRunning}
            onClick={onInvoke}
          >
            Search
          </QueryButton>
        )}
      </QueryFieldContainer>
      {errorOutput}
    </>
  );
}

const deleteLabel =
  (cellId: string, field: string, label: Label): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const providerCell = selectCell(state, cellId);
    if (providerCell?.type !== "provider") {
      throw new Error("Cell not found or has incorrect type");
    }

    const { id, queryData } = providerCell;

    const labels = getQueryField(queryData, field).split(" ").map(parseLabel);
    const newLabels = labels.filter((existing) => existing.key !== label.key);
    const newQueryData = setQueryField(
      queryData,
      field,
      newLabels.map(formatLabel).join(" "),
    );

    dispatch(
      replaceCells({
        oldCellIds: [id],
        newCells: [{ ...providerCell, queryData: newQueryData }],
      }),
    );
  };

const onKeyDown =
  (event: React.KeyboardEvent): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const editorState = selectLabelsEditorState(state);
    if (!editorState) {
      return;
    }

    switch (event.key) {
      case "Enter": {
        cancelEvent(event);

        const existingKeys = selectLabelsEditorExistingLabelKeys(state);
        const errors = validateLabelsEditor(editorState, existingKeys);
        if (errors.length === 0) {
          dispatch(saveLabelsEditorDraft());
          dispatch(withActiveNotebook(closeContextMenu()));
        }

        break;
      }

      case "Escape": {
        cancelEvent(event);
        dispatch(resetLabelsEditor("provider_field"));
        break;
      }
    }
  };

function onBlur() {
  dispatch(resetLabelsEditor("provider_field"));
}

const StyledLabelsEditor = styled(LabelsEditor)`
  ${noOutlineStyling}
  flex: 1;
  margin: 0 11px;
`;
