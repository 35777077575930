/**
 * Returns whether an object is empty.
 *
 * This returns whether `length` is greater than `0` for arrays,
 * and returns whether the object has any keys for other objects.
 */
export function isEmpty<T extends Record<string, unknown> | Array<unknown>>(
  object: T | null | undefined,
): object is null | undefined {
  if (object == null) {
    return true;
  }

  if (Array.isArray(object)) {
    return object.length === 0;
  }

  for (const _ in object) {
    return false;
  }

  return true;
}
