import type React from "react";
import {
  type CSSProperties,
  forwardRef,
  memo,
  useEffect,
  useState,
} from "react";
import { css, keyframes, styled } from "styled-components";

import type { User } from "../../../types";
import { getSupportColorSetForUser } from "../../../utils";
import { CURSOR_WIDTH } from "./constants";
import type { CursorCoordinates } from "./utils";

type Props = {
  cursorHeight: number;
  cursorCoordinates?: CursorCoordinates;
  isEditable?: boolean;
  user?: User;
};

interface PositionStyle extends CSSProperties {
  "--top": string;
  "--left": string;
}

function MemoizedCursorWithRef(
  { cursorHeight, cursorCoordinates, user, isEditable }: Props,
  ref: React.Ref<HTMLElement>,
) {
  const [isIdling, setIsIdling] = useState(false);
  const shouldBlink = !user && isEditable;
  const colorSet = user && getSupportColorSetForUser(user.id);
  const color = colorSet && `var(--${colorSet[400]})`;

  // biome-ignore lint/correctness/useExhaustiveDependencies: intentional
  useEffect(() => {
    if (shouldBlink) {
      const timeout = setTimeout(() => setIsIdling(true), 400);

      return () => {
        clearTimeout(timeout);
        setIsIdling(false);
      };
    }
  }, [shouldBlink, cursorCoordinates]);

  if (cursorHeight > 0 && cursorCoordinates) {
    const position: PositionStyle = {
      "--top": `${cursorCoordinates.y - cursorHeight / 2}px`,
      "--left": `${cursorCoordinates.x - CURSOR_WIDTH / 2}px`,
    };

    return (
      <CursorSpanElement
        data-testid="cursor"
        cursorHeight={cursorHeight}
        color={color}
        isIdling={isIdling}
        ref={ref}
        style={position}
      />
    );
  }

  return null;
}

export const Cursor = memo(forwardRef(MemoizedCursorWithRef));

const CursorSpanElement = styled.span<{
  cursorHeight: Props["cursorHeight"];
  color?: string;
  isIdling: boolean;
}>(
  ({ color, cursorHeight, isIdling }) => css`
    display: block;
    position: absolute;
    background: ${({ theme }) => color ?? theme.colorPrimary300};
    border-radius: 2px;
    width: ${CURSOR_WIDTH}px;
    height: ${cursorHeight}px;
    top: var(--top);
    left: var(--left);
    ${isIdling && animationStyling}
  `,
);

const blinkAnimation = keyframes`
  from {
    visibility: hidden;
  }
`;

const animationStyling = css`
  animation: ${blinkAnimation} 1.2s steps(2) infinite;
`;
