import { generatePath } from "react-router";

import { ROUTES } from "../../../constants";

export function formatTemplateUrl(
  workspaceName: string,
  templateName: string,
  parameters?: Record<string, string>,
): string {
  const path = generatePath(ROUTES.ExpandTemplate, {
    workspaceName,
    templateName,
  });

  const querystring =
    parameters && Object.keys(parameters).length > 0
      ? `?${new URLSearchParams(parameters)}`
      : "";

  return `${path}${querystring}`;
}
