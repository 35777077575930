import { cancelEvent } from "@fiberplane/ui";
import {
  createLabelDraft,
  setCommandMenuSelectedIndex,
  setCommandMenuVariant,
} from "../../../../actions";
import {
  selectCommandMenuQuery,
  selectCommandMenuSelectedIndex,
  selectCommandMenuVariant,
  selectIsAuthenticated,
} from "../../../../selectors";
import type { Thunk } from "../../../../store";

export const onSearchInputKeyDown =
  (event: React.KeyboardEvent): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const isAuthenticated = selectIsAuthenticated(state);
    if (!isAuthenticated) {
      return;
    }

    switch (event.key) {
      case "/": {
        dispatch(handleSlash(event));
        break;
      }

      case "#": {
        dispatch(handleSharp(event));
        break;
      }

      case "Backspace": {
        if (handleBackspace(event)) {
          return;
        }
        break;
      }
    }

    // Reset the index to 0
    if (
      selectCommandMenuSelectedIndex(state) > 0 &&
      event.key !== "ArrowDown" &&
      event.key !== "ArrowUp"
    ) {
      dispatch(setCommandMenuSelectedIndex(0));
    }
  };

const handleBackspace =
  (event: React.KeyboardEvent): Thunk<boolean> =>
  (dispatch, getState) => {
    // Only handle backspace when there's a query
    const state = getState();
    if (selectCommandMenuQuery(state).length > 0) {
      return true;
    }

    // Only handle the event if we're in a variant already
    const variant = selectCommandMenuVariant(state);
    if (variant.type === "none") {
      return true;
    }

    if (variant.type === "search") {
      const { labels } = variant;
      if (labels) {
        cancelEvent(event);

        dispatch(
          setCommandMenuVariant(
            labels.length > 1
              ? { ...variant, labels: labels.slice(0, -1) }
              : { type: "search" },
          ),
        );
        return true;
      }
    }

    dispatch(setCommandMenuVariant({ type: "none" }));
    return false;
  };

const handleSlash =
  (event: React.KeyboardEvent): Thunk =>
  (dispatch, getState) => {
    // Only handle the event if we're not already searching
    const variant = selectCommandMenuVariant(getState());
    if (variant.type !== "search") {
      cancelEvent(event);
      dispatch(setCommandMenuVariant({ type: "search" }));
    }
  };

const handleSharp =
  (event: React.KeyboardEvent): Thunk =>
  (dispatch, getState) => {
    cancelEvent(event);

    const variant = selectCommandMenuVariant(getState());
    if (variant.type !== "search") {
      dispatch(setCommandMenuVariant({ type: "search" }));
    }

    dispatch(createLabelDraft("command_menu"));
  };
