import { styled } from "styled-components";

import type { FrontMatterUser } from "../../../../../types";
import { ProfileAvatar } from "../../../../Avatar";

export function getValue(value: FrontMatterUser) {
  return value.name;
}

export function getId(value: FrontMatterUser) {
  return value.id;
}

export function renderValue(value: FrontMatterUser) {
  return (
    <FieldUserInfo>
      <ProfileAvatar
        userId={value.id}
        name={value.name}
        size={18}
        showTooltip={false}
      />
      <span>{value.name}</span>
    </FieldUserInfo>
  );
}

/// Styles

const FieldUserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
`;
