import type { Label } from "../types";

export type ViewEditor = {
  // Since LabelsEditor is still dependent on redux state instead of react-hook-form,
  // we have to keep this around
  labels: { value: Array<Label> };
};

export type ViewsState = {
  readonly editor: ViewEditor | undefined;
};
