import { AnimatePresence } from "framer-motion";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { selectActiveContextMenu } from "../../selectors";
import type { LabelsEditorState } from "../../state";
import { dispatch } from "../../store";
import { showContextMenu } from "../../thunks";
import type { Label, LabelsEditorType } from "../../types";
import { isPristine } from "../../utils";
import { LabelItemEditor } from "./LabelItemEditor";
import { EditingToolbar } from "./Toolbars";
import { ValidationNudge } from "./ValidationNudge";
import { useValidation } from "./hooks";
import { StyledListItem } from "./styled";
import type { LabelItemOptions } from "./types";

type Props = LabelItemOptions & {
  editor: LabelsEditorState;
  labels: ReadonlyArray<Label>;
  popperKey: string;
  onBlur: (event: React.FocusEvent) => void;
  type: LabelsEditorType;
};

export function EditedLabelItem({
  editor,
  labels,
  onBlur,
  popperKey,
  type,
  withContextMenu,
  withToolbar,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const ref = useRef<HTMLLIElement>(null);
  const el = ref.current;

  const hasEditor = !!editor;
  useEffect(() => {
    if (hasEditor) {
      inputRef.current?.focus();
    }
  }, [hasEditor]);

  return (
    <StyledListItem ref={ref}>
      <AnimatePresence>
        {withToolbar && (
          <EditingToolbar
            editor={editor}
            element={el}
            key={`toolbar:${popperKey}`}
          />
        )}
      </AnimatePresence>
      <LabelItemEditor
        editor={editor}
        inputRef={inputRef}
        labels={labels}
        onBlur={onBlur}
        type={type}
      />
      <ValidationNudge
        editor={editor}
        element={el}
        key={`nudge:${popperKey}`}
        type={type}
      />
      {withContextMenu && <ContextMenuRequester editor={editor} element={el} />}
    </StyledListItem>
  );
}

type ContextMenuRequesterProps = {
  editor: LabelsEditorState;
  element: HTMLElement | null;
};

function ContextMenuRequester({
  editor,
  element,
}: ContextMenuRequesterProps): JSX.Element | null {
  const contextMenu = useSelector(selectActiveContextMenu);
  const errors = useValidation(editor);
  const valid = errors.length === 0;

  useEffect(() => {
    const changed = !isPristine(editor);
    // Only open context menu when none is rendered already:
    if (
      element &&
      !contextMenu &&
      (editor.draft === "" || (changed && valid))
    ) {
      dispatch(showContextMenu(element, "auto_suggest_labels"));
    }
  }, [contextMenu, editor, element, valid]);

  return null;
}
