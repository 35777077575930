import type { LabelsEditorState } from "../../../state";
import { dispatch } from "../../../store";
import {
  resetActiveLabelsEditor,
  saveLabelsEditorDraft,
} from "../../../thunks";
import { parseLabel } from "../../../utils";
import { useValidation } from "../hooks";
import {
  Toolbar,
  ToolbarButton,
  ToolbarIcon,
  type ToolbarProps,
} from "./Toolbar";

type Props = {
  element: ToolbarProps["element"];
  editor: LabelsEditorState;
};

export function EditingToolbar({ element, editor }: Props): JSX.Element | null {
  const errors = useValidation(editor);

  const { draft } = editor;
  if (!draft) {
    return null;
  }

  const label = parseLabel(draft);

  return (
    <Toolbar element={element}>
      {label.key && (
        <ToolbarButton onClick={() => dispatch(resetActiveLabelsEditor())}>
          <ToolbarIcon iconType="close" />
          Cancel
        </ToolbarButton>
      )}
      <ToolbarButton
        disabled={!draft || errors.length > 0}
        onClick={() => dispatch(saveLabelsEditorDraft())}
      >
        <ToolbarIcon iconType="key_return" />
        Enter
      </ToolbarButton>
    </Toolbar>
  );
}
