import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { ProfileAvatar } from "../../Avatar";

export const NotebookReference = styled(Link)`
  font: ${({ theme }) => theme.font.body.md.medium};
  color: ${({ theme }) => theme.color.fg.primary};

  padding: 0 2px;
  margin-left: 4px;
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  /* HACK - To get everything to align with surrounding text */
  transform: translateY(2px);
  transition: color ease-in-out .2s;

  &:hover {
    text-decoration: underline;
  }
`;

export type UserReferenceProps = {
  user: { name: string; id: string };
};

export const UserReference = ({ user }: UserReferenceProps) => {
  return (
    <UserReferenceContainer>
      <StyledProfileAvatar name={user.name} userId={user.id} />
      {user.name}
    </UserReferenceContainer>
  );
};

const UserReferenceContainer = styled.span`
  font: ${({ theme }) => theme.font.body.md.medium};
  margin: 0 4px;

  display: inline-flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.radius.rounded};
  border: solid 1px ${({ theme }) => theme.color.border.muted};
  padding: 0 4px;
`;

const StyledProfileAvatar = styled(ProfileAvatar).attrs({ showTooltip: false })`
  display: inline-grid;
  margin-right: 4px;
  width: 18px;
`;
