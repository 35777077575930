import { Icon } from "@fiberplane/ui";
import type React from "react";
import { useEffect } from "react";
import { css, keyframes, styled } from "styled-components";

import { useHandler } from "@fiberplane/hooks";
import { useSelector } from "react-redux";
import { createSearchParams } from "react-router-dom";
import { selectOverlayQueryParams } from "../../../../../selectors";
import { dispatch } from "../../../../../store";
import { updateRoutedModalQueryParams } from "../../../../../thunks";

type Props = {
  children: React.ReactNode;
  size?: string;
  active?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  reverse?: boolean;
};

const TriangleIcon = styled(Icon)<{ reverse?: "true" | "false" }>`
  transform: ${(props) =>
    props.reverse === "true" ? "rotate(-90deg)" : "rotate(90deg)"};
`;

export function TableHeaderItem({
  reverse = false,
  ...props
}: Props): JSX.Element {
  return (
    <StyledHeader
      {...props}
      tabIndex={props.onClick ? 1 : undefined}
      role={props.onClick ? "button" : undefined}
    >
      <StyledText>{props.children}</StyledText>
      <TriangleIcon
        iconType="triangle_right"
        reverse={props.active && !reverse ? "false" : "true"}
      />
    </StyledHeader>
  );
}

export function useSortingParams(
  defaultProperty = "name",
  defaultDirection = "ascending",
): {
  sortProperty: string;
  sortDirection: string;
  updateSorting: (
    property: string,
    direction: "ascending" | "descending",
  ) => void;
} {
  const params = useSelector(selectOverlayQueryParams);
  const sortProperty = params.get("sortBy") || defaultProperty;
  const sortDirection = params.get("sortDirection") || defaultDirection;

  const updateSorting = useHandler((property: string, direction: string) => {
    dispatch(
      updateRoutedModalQueryParams((previous: URLSearchParams) => {
        const newParams = createSearchParams(previous);
        if (property) {
          newParams.set("sortBy", property);
        }
        newParams.set("sortDirection", direction);
        return newParams;
      }),
    );
  });

  const hasSortParams =
    sortProperty !== defaultProperty && sortDirection !== defaultDirection;

  useEffect(() => {
    if (!hasSortParams) {
      return;
    }

    return () => {
      dispatch(
        updateRoutedModalQueryParams((previous: URLSearchParams) => {
          const newParams = createSearchParams(previous);
          newParams.delete("sortBy");
          newParams.delete("sortDirection");
          return newParams;
        }),
      );
    };
  }, [hasSortParams]);

  return { sortProperty, sortDirection, updateSorting };
}

export const StyledTd = styled.td<{ indent?: boolean }>(
  ({ theme, indent }) => css`
    border-bottom: 1px solid ${theme.colorBase300};
    padding: 12px 6px;
    padding-left: ${indent ? "40px" : "6px"};

    @media (min-width: 500px) {
      padding: 12px;
      padding-left: ${indent ? "40px" : "12px"};
    }
  `,
);

export const StyledTableContent = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 6px;
    font: ${theme.fontStudioBodyCopySmallShortHand};
    text-overflow: ellipsis;
    overflow: hidden;

    @media (min-width: 500px) {
      gap: 10px;
    }
  `,
);

export const StyledTable = styled.table`
  table-layout: fixed;
  max-width: 100%;
`;

export const StyledTableHeadings = styled.tr`
  min-height: 48px;
`;

export const StyledTh = styled.th<{ size?: string }>(
  ({ theme, size = "50%" }) => css`
    border-bottom: 1px solid ${theme.colorBase300};
    padding: 12px 6px;
    width: ${size};

    @media (min-width: 500px) {
      padding: 12px;
    }
  `,
);

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const StyledLoading = styled(StyledTd)`
  animation: ${fadeInAnimation} ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

export const StyledCenterContent = styled(StyledTableContent)`
  justify-content: center;
`;

const StyledHeader = styled.div<Props>(
  ({ theme, active }) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    border-radius: ${theme.borderRadiusNone};
    padding: 0;
    color: ${active ? theme.colorBase600 : theme.colorBase500};
    cursor: pointer;

    &:hover {
      color: rgb(96, 98, 102);
    }
  `,
);

const StyledText = styled.span(
  ({ theme }) => css`
    font: ${theme.fontStudioBodyCopySmallShortHand};
    letter-spacing: ${theme.fontStudioBodyCopySmallLetterSpacing};
  `,
);

export const EmptyMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-grow: 0;
  border-radius: ${({ theme }) => theme.borderRadiusNone};
  padding: 40px 0;
  margin: 0;
`;

export const EmptyMessageIconContainer = styled.div(
  ({ theme }) => css`
    flex-grow: 0;
    display: flex;
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    border-radius: 14px;
    padding: 3px;
    width: 45px;
    height: 45px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    background: ${theme.colorForeground};
    color: ${theme.colorBackground};
    border: 1.7428px solid ${theme.colorBackground};
    box-shadow: ${theme.effect.shadow.m};
    margin-left: -7px;

    &:last-child {
      margin-left: 0;
    }
  `,
);

export const EmptyMessageContent = styled.div`
  text-align: center;
  margin: 8px 0;
`;

export const EmptyMessageTitle = styled.strong(
  ({ theme }) => css`
    margin: 0;
    flex-grow: 0;
    text-align: center;
    color: ${theme.colorBase700};
    font: ${theme.fontStudioStrongRegularShortHand};
    letter-spacing: ${theme.fontStudioStrongRegularLetterSpacing};
  `,
);

export const EmptyMessageDescription = styled.p(
  ({ theme }) => css`
    margin: 0;
    flex-grow: 0;
    text-align: center;
    color: ${theme.colorBase700};
    font: ${theme.fontStudioBodyCopyRegularShortHand};
    letter-spacing: ${theme.fontStudioBodyCopyRegularLetterSpacing};
  `,
);
