import { AttachedPopup, Button } from "@fiberplane/ui";
import { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

import { useClosePopupHandlers } from "../../../../hooks";
import { selectNotebookId } from "../../../../selectors";
import { useAppDispatch } from "../../../../store";
import { addNotification, convertNotebookToTemplate } from "../../../../thunks";
import { setZeroTimeout } from "../../../../utils";
import { MenuContainer } from "../../../UI";
import { OFFSET } from "../../constants";
import { PublicToggle } from "./PublicToggle";

export function ShareToggle() {
  const dispatch = useAppDispatch();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const id = useSelector(selectNotebookId);
  const url = document.location.toString();

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleClickCopy = () => {
    navigator.clipboard.writeText(url);
    setMenuIsOpen(false);
    dispatch(addNotification({ title: "Link copied to your clipboard" }));
  };

  const [downloading, setDownloading] = useState(false);

  const handleDownloadTemplate = async () => {
    setDownloading(true);
    await dispatch(convertNotebookToTemplate(id));
    setDownloading(false);
  };

  const onClose = useCallback(() => {
    setZeroTimeout(() => setMenuIsOpen(false));
  }, []);

  useClosePopupHandlers({
    isActive: menuIsOpen,
    onClose,
    popupRef: dropdownRef,
  });

  return (
    <>
      {menuIsOpen && (
        <AttachedPopup
          element={anchorRef.current}
          placement="bottom"
          offset={OFFSET}
        >
          <StyledContainer data-testid="share-popup" ref={dropdownRef}>
            <Text>Share current notebook</Text>
            <PublicToggle id={id} />
            <div>
              <StyledActionButton onClick={handleClickCopy}>
                Copy link
              </StyledActionButton>
              <StyledActionButton
                disabled={downloading}
                onClick={handleDownloadTemplate}
              >
                Download as template
              </StyledActionButton>
            </div>
          </StyledContainer>
        </AttachedPopup>
      )}

      <Button
        data-testid="share"
        onClick={() => setMenuIsOpen(!menuIsOpen)}
        ref={anchorRef}
      >
        Share
      </Button>
    </>
  );
}

const StyledContainer = styled(MenuContainer)`
  width: 340px;
  max-width: 90vw;
  padding: 20px;
  gap: 24px;
  overflow: hidden;
`;

const Text = styled.h4`
  margin: 0;
  padding: 0;
`;

const StyledActionButton = styled(Button)`
  width: 100%;

  &:last-of-type {
    margin-top: 10px;
  }
`;
