import { motion } from "framer-motion";
import type { HTMLAttributes } from "react";
import { css, styled } from "styled-components";

type PillItemProps = {
  isActive: boolean;
} & HTMLAttributes<HTMLButtonElement>;

/**
 * A pill item that should be used as a direct child of \<PillBox \/\>
 * @param isActive: boolean
 * @param buttonAttributes: HTMLAttributes\<HTMLButtonElement\>
 * @returns Pill item with animated background when active.
 * @example
 * <PillBox>
 *   <PillItem
 *     isActive={activeTab === "templates"}
 *     onClick={() => setParams({ tab: "templates" })}
 *   >
 *     Templates
 *   </PillItem>
 *   <PillItem
 *     isActive={activeTab === "snippets"}
 *     onClick={() => setParams({ tab: "snippets" })}
 *   >
 *     Snippets
 *   </PillItem>
 * </PillBox>
 */
export function PillItem({ isActive, ...buttonAttributes }: PillItemProps) {
  return (
    <ButtonContainer>
      {isActive && <ActiveButtonBackground layoutId="pillBox" />}
      <Button {...buttonAttributes}>{buttonAttributes.children}</Button>
    </ButtonContainer>
  );
}

type PillBoxProps = {
  children:
    | Array<React.ReactElement<PillItemProps>>
    | React.ReactElement<PillItemProps>;
};

/** Pill container that should have multiple <PillItem /> as direct children */
export const PillBox = styled.div<PillBoxProps>(
  ({ theme }) => css`
    display: inline-flex;
    line-height: 1;
    background-color: ${theme.color.bg.subtle};
    border-radius: ${theme.radius.full};
    padding: 4px;
  `,
);

const ButtonContainer = styled.span`
  position: relative;
  border-radius: ${({ theme }) => theme.radius.full};
  height: 100%;
  width: 100%;
  display: inline-flex;
`;

const ActiveButtonBackground = styled(motion.div)`
  background-color: ${({ theme }) => theme.color.bg.emphasis.neutral};
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: ${({ theme }) => theme.borderRadiusRound};
  z-index: 0;
`;

const Button = styled.button(
  ({ theme }) => css`
    z-index: 1;
    cursor: pointer;
    margin: 0;
    padding: 8px 12px;
    border-radius: ${theme.radius.full};
    border: none;
    outline: none;
    font: ${theme.font.buttons.md};
    line-height: 1;
    background: none;
    color: ${theme.color.fg.onemphasis.default};
    mix-blend-mode: difference;
  `,
);
