import { createSelector } from "reselect";

import {
  DEFAULT_NOTEBOOK_TITLE,
  FRONT_MATTER_CELL_ID,
  GLOBAL_TIME_RANGE_ID,
  TITLE_CELL_ID,
} from "../constants";
import type { NotebookState, RootState } from "../state";
import type {
  Cell,
  CellDrafts,
  Formatting,
  ListItemCell,
  NotebookFocus,
  NotebookSelection,
  RichText,
  SubscriberSession,
  ThreadItem,
  TimeRange,
} from "../types";
import {
  charCount,
  charSlice,
  compact,
  emptyDraft,
  emptyDrafts,
  getCellFocus,
  getCellTextUsingExternalSources,
  getField,
  getFocusCellId,
  getFocusField,
  getRichCellTextUsingExternalSources,
  noCellFocus,
  sortBy,
  uniqBy,
} from "../utils";
import {
  makeThreadItemsSelector,
  selectNotebookThreads,
} from "./discussionsSelectors";
import { selectNotebookCellIds, selectNotebookFocus } from "./editorSelectors";
import { selectStudioSafeFrontMatter } from "./frontMatterSelectors";
import { selectActiveNotebook } from "./notebooksSelectors";

const emptyThreadItems: Array<ThreadItem> = [];
const noFormatting: Formatting = [];

export const selectNotebookId = (state: RootState): string =>
  selectActiveNotebook(state).id;

export const selectNotebookRevision = (state: RootState): number =>
  selectActiveNotebook(state).revision;

export const selectNotebookCreatedAt = (state: RootState): string =>
  selectActiveNotebook(state).createdAt;

export const selectNotebookError = (
  state: RootState,
): Error | string | undefined => selectActiveNotebook(state).error;

export const selectNotebookReadOnly = (state: RootState) =>
  selectActiveNotebook(state).readOnly;

export const selectNotebookUnrecoverable = (state: RootState) =>
  selectActiveNotebook(state).unrecoverable;

export const selectNotebookLoading = (state: RootState): boolean =>
  !!selectActiveNotebook(state).loading;

export const selectNotebookTitle = (state: RootState): string =>
  selectActiveNotebook(state).title || DEFAULT_NOTEBOOK_TITLE;

export const selectNotebookVisibility = (state: RootState) =>
  selectActiveNotebook(state).visibility;

export const selectNotebookSubscriberSessions = (
  state: RootState,
): ReadonlyArray<SubscriberSession> =>
  selectActiveNotebook(state).subscriberSessions;

export const selectUniqueNotebookSubscriberSessions = createSelector(
  selectNotebookSubscriberSessions,
  (subscriberSessions) =>
    uniqBy(
      sortBy([...subscriberSessions], ({ updatedAt }) => updatedAt, true),
      ({ user }) => user.id,
    ),
);

export const selectNotebookSubscriberUserIds = createSelector(
  selectActiveNotebook,
  (state) => state.subscriberSessions.map(({ user: { id } }) => id),
);

export const selectNotebookWorkspaceId = (state: RootState): string =>
  selectActiveNotebook(state).workspaceId;

export const makeCellSubscribersSelector = (cellId: string, field?: string) =>
  createSelector([selectUniqueNotebookSubscriberSessions], (sessions) =>
    sessions.filter(
      (session) =>
        getFocusCellId(session.focus) === cellId &&
        (getFocusField(session.focus) === field || field === undefined),
    ),
  );

export const makeSubscriberSelector = (sessionId: string) =>
  createSelector(selectNotebookSubscriberSessions, (sessions) =>
    sessions.find((session) => session.sessionId === sessionId),
  );

export const makeSubscriberUserSelector = (sessionId: string) => {
  const selectSubscriber = makeSubscriberSelector(sessionId);
  return createSelector(selectSubscriber, (subscriber) => subscriber?.user);
};

export const selectNotebookTimeRange = (state: RootState): TimeRange => {
  const notebook = selectActiveNotebook(state);
  return notebook.timeRange;
};

export const selectCellIds = createSelector(
  selectActiveNotebook,
  (notebook) => notebook.cellIds,
);

export const selectFocusedCell = createSelector(
  selectActiveNotebook,
  (notebook) => {
    const focusedCellId = getFocusCellId(notebook.editor.focus);
    return focusedCellId ? notebook.cellsById[focusedCellId]?.cell : undefined;
  },
);

export const selectFocusedProviderCell = createSelector(
  selectFocusedCell,
  (focusedCell) => (focusedCell?.type === "provider" ? focusedCell : undefined),
);

export const selectFocusedCellOrSurrogate = createSelector(
  selectActiveNotebook,
  (notebook): Cell | undefined => {
    const focusedCellId = getFocusCellId(notebook.editor.focus);
    return focusedCellId
      ? selectCellOrSurrogateLocal(notebook, focusedCellId)
      : undefined;
  },
);

export const selectLastCell = createSelector(
  selectActiveNotebook,
  (notebook): Cell | undefined => {
    const cellId = notebook.cellIds[notebook.cellIds.length - 1];
    return cellId ? selectCellOrSurrogateLocal(notebook, cellId) : undefined;
  },
);

export const makeCellErrorSelector = (cellId: string) =>
  createSelector(
    selectActiveNotebook,
    (notebook) => notebook.cellsById[cellId]?.error,
  );

export const makeCellQueryFieldErrorSelector = (
  cellId: string,
  fieldName: string,
) =>
  createSelector(selectActiveNotebook, (notebook) => {
    const error = notebook.cellsById[cellId]?.error;

    if (!error || error.type !== "validation_error") {
      return;
    }

    return error.errors.find(
      (validationError) => validationError.fieldName === fieldName,
    );
  });

export const makeCellSelector = (id: string) =>
  createSelector(
    selectActiveNotebook,
    (notebook) => notebook.cellsById[id]?.cell,
  );

export const makeCellTypeSelector = (id: string) => {
  const cellSelector = makeCellSelector(id);
  return createSelector(cellSelector, (cell) => cell?.type);
};

export const makeCellOrSurrogateSelector = (id: string) =>
  createSelector(selectActiveNotebook, (notebook) =>
    selectCellOrSurrogateLocal(notebook, id),
  );

export const makeIsRunningSelector = (cellId: string) =>
  createSelector(
    selectActiveNotebook,
    (notebook) => !!notebook.cellsById[cellId]?.isRunning,
  );

export const makeIsSelectedSelector = (cellId: string) => {
  const selectCell = makeCellOrSurrogateSelector(cellId);
  return createSelector(
    [
      selectCell,
      selectNotebookCellIds,
      makeThreadItemsSelector(cellId),
      selectNotebookFocus,
    ],
    (cell, cellIds, threadItems, focus): boolean => {
      if (focus.type === "selection") {
        const cellFocus = cell
          ? getCellFocus(cellIds, cell, emptyDrafts, threadItems, focus)
          : noCellFocus;
        return cellFocus.type !== "none";
      }

      return false;
    },
  );
};

export const makeSelectOrderedListItemCounter = (cell: ListItemCell) =>
  // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: FIXME
  createSelector(selectActiveNotebook, (notebook) => {
    let level0Counter = 1;
    let level0Preface = cell.startNumber ?? level0Counter;

    let level1Counter = 1;
    let level1Preface = 1;

    let level2Counter = 1;
    let level2Preface = 1;

    let removeLevel1StartNumber = false;

    const index = notebook.cellIds.indexOf(cell.id);

    for (let i = index - 1; i >= 0; i--) {
      const previousCell = notebook.cellsById[notebook.cellIds[i] ?? ""]?.cell;

      if (
        previousCell?.type !== "list_item" ||
        previousCell.listType !== cell.listType
      ) {
        break;
      }

      if (previousCell && cell.startNumber) {
        removeLevel1StartNumber = true;
      }

      level0Preface = previousCell.startNumber
        ? previousCell.startNumber + level0Counter
        : level0Counter + 1;

      // Iterate over nested cells above determining where the level 0 parent
      // is. Once found the counter is reset.
      if (cell.level === 1) {
        for (let i = index - 1; i >= 0; i--) {
          if (previousCell.level === 1) {
            break;
          }

          if (!previousCell.level) {
            level1Counter = 0;
          }
        }
      }

      // Reset counter for level 2 nested elements
      if (cell.level === 2 && previousCell.level !== 2) {
        level2Counter = 0;
      }

      if (previousCell.level === 1) {
        level1Preface += level1Counter;
      } else if (previousCell.level === 2) {
        level2Preface += level2Counter;
      } else {
        level0Counter++;
      }
    }

    return {
      level0Preface,
      level1Preface,
      level2Preface,
      removeLevel1StartNumber,
    };
  });

export const selectCell = (state: RootState, cellId: string) =>
  selectCellLocal(selectActiveNotebook(state), cellId);

export const selectCellDrafts = (state: RootState, cellId: string) =>
  selectCellDraftsLocal(selectActiveNotebook(state), cellId);

export const selectThreadItems = (state: RootState, threadId: string) =>
  selectNotebookThreads(state)[threadId]?.items ?? emptyThreadItems;

export const selectCellDraftsLocal = (
  notebook: NotebookState,
  cellId: string,
): CellDrafts => notebook.cellsById[cellId]?.drafts ?? emptyDrafts;

export const makeCellDraftsSelector = (cellId: string) =>
  createSelector(selectActiveNotebook, (notebook) =>
    selectCellDraftsLocal(notebook, cellId),
  );

export const makeCellDraftSelector = (cellId: string, field: string) =>
  createSelector(
    makeCellDraftsSelector(cellId),
    (drafts) => drafts[field] ?? emptyDraft,
  );

// TODO: FP-2945 requires us to support surrogate cells (like title cell)
export const selectCellLocal = (
  notebook: NotebookState,
  cellId: string,
): Cell | undefined => {
  const cellWithMetadata = notebook.cellsById[cellId];
  if (cellWithMetadata) {
    return cellWithMetadata.cell;
  } else {
    const slashIndex = cellId.indexOf("/");
    if (slashIndex > -1) {
      const cell = selectCellLocal(notebook, cellId.slice(0, slashIndex));
      if (cell?.type === "provider" && cell.output) {
        return selectNestedCell(cell.output, cellId);
      }
    }
  }
};

export const selectCellIndex = (state: RootState, cellId: string) =>
  selectCellIndexLocal(selectActiveNotebook(state), cellId);

export const selectCellIndexLocal = (
  notebook: NotebookState,
  cellId: string,
): number => {
  const index = notebook.cellIds.indexOf(cellId);
  if (index > -1) {
    return index;
  }

  const slashIndex = cellId.indexOf("/");
  return slashIndex > -1
    ? notebook.cellIds.indexOf(cellId.slice(0, slashIndex))
    : -1;
};

function selectNestedCell(
  cells: Array<Cell>,
  cellId: string,
): Cell | undefined {
  for (const cell of cells) {
    if (cell.id === cellId) {
      return cell;
    }

    if (
      cell.type === "provider" &&
      cellId.startsWith(`${cell.id}/`) &&
      cell.output
    ) {
      const nestedCell = selectNestedCell(cell.output, cellId);
      if (nestedCell) {
        return nestedCell;
      }
    }
  }
}

export const selectCellOrSurrogate = (state: RootState, cellId: string) =>
  selectCellOrSurrogateLocal(selectActiveNotebook(state), cellId);

export const selectCellOrSurrogateLocal = (
  notebook: NotebookState,
  cellId: string,
): Cell | undefined => {
  switch (cellId) {
    case TITLE_CELL_ID:
      return {
        id: TITLE_CELL_ID,
        content: notebook.title,
        formatting: [],
        headingType: "h1",
        type: "heading",
      };
    case FRONT_MATTER_CELL_ID:
      return {
        id: FRONT_MATTER_CELL_ID,
        content: "",
        formatting: [],
        type: "text",
      };

    case GLOBAL_TIME_RANGE_ID:
      return {
        id: GLOBAL_TIME_RANGE_ID,
        content: `${notebook.timeRange.from} ${notebook.timeRange.to}`,
        formatting: [],
        type: "text",
      };

    default:
      return selectCellLocal(notebook, cellId);
  }
};

/**
 * Returns all the cells that are part of the given selection.
 */
export function selectCellsInSelection(
  state: RootState,
  { start, end }: NotebookSelection,
): Array<Cell> {
  // Title is the only surrogate cell that can be selected,
  // so we add it explicitly and shift the indices by 1.
  const cellIds = [TITLE_CELL_ID, ...selectCellIds(state)].slice(
    start.cellIndex + 1,
    end.cellIndex + 2,
  );

  return compact(cellIds.map((cellId) => selectCellOrSurrogate(state, cellId)));
}

/**
 * **NOTE:** Please keep this function in sync with {@link selectCellRichText()}
 * below.
 */
export function selectCellText(
  state: RootState,
  cell: Cell,
  field?: string,
): string {
  if (cell.id === FRONT_MATTER_CELL_ID && field) {
    const value = selectStudioSafeFrontMatter(state)[field];
    return value !== undefined && ["string", "number"].includes(typeof value)
      ? value.toString()
      : "";
  }

  return (
    getCellTextUsingExternalSources(
      cell,
      selectCellDrafts(state, cell.id),
      cell.type === "discussion"
        ? selectThreadItems(state, cell.threadId)
        : emptyThreadItems,
      field,
    ) ?? ""
  );
}

/**
 * **NOTE:** Please keep this function in sync with {@link selectCellText()}
 * above.
 */
export function selectCellRichText(
  state: RootState,
  cell: Cell,
  field?: string,
): RichText | undefined {
  if (cell.id === FRONT_MATTER_CELL_ID && field) {
    const value = selectStudioSafeFrontMatter(state)[field];
    return value !== undefined && ["string", "number"].includes(typeof value)
      ? { text: value.toString(), formatting: noFormatting }
      : undefined;
  }

  return getRichCellTextUsingExternalSources(
    cell,
    selectCellDrafts(state, cell.id),
    cell.type === "discussion"
      ? selectThreadItems(state, cell.threadId)
      : emptyThreadItems,
    field,
  );
}

export const selectNthCellId = (state: RootState, index: number) =>
  selectActiveNotebook(state).cellIds[index];

export type NotebookSyncState =
  | "saving"
  | "syncing"
  | "waiting"
  | "idle"
  | "unrecoverable";

export const selectNotebookSyncState = createSelector(
  selectActiveNotebook,
  (notebook): NotebookSyncState =>
    notebook.unrecoverable
      ? "unrecoverable"
      : notebook.numPendingOperations > 0
        ? "syncing"
        : notebook.numPendingIncomingOperations > 0
          ? "waiting"
          : notebook.unrecoverable
            ? "unrecoverable"
            : "idle",
);

/**
 * Returns the text inside the given `cell` that is selected according to the
 * given `notebookFocus`.
 */
export function selectSelectedTextInCell(
  state: RootState,
  cell: Cell,
  notebookFocus: NotebookFocus | { type: "all" },
): string | undefined {
  return selectSelectedRichTextInCell(state, cell, notebookFocus)?.text;
}

/**
 * Returns the rich text inside the given `cell` that is selected according to
 * the given `notebookFocus`.
 */
export function selectSelectedRichTextInCell(
  state: RootState,
  cell: Cell,
  notebookFocus: NotebookFocus | { type: "all" },
): RichText | undefined {
  switch (notebookFocus.type) {
    case "all":
      return selectCellRichText(state, cell);

    case "collapsed":
    case "none":
      return;

    case "selection": {
      const drafts = makeCellDraftsSelector(cell.id)(state);
      const threadItems = makeThreadItemsSelector(cell.id)(state);
      const cellFocus = getCellFocus(
        selectCellIds(state),
        cell,
        drafts,
        threadItems,
        notebookFocus,
      );
      if (cellFocus.type === "none" || cellFocus.type === "collapsed") {
        return;
      }

      const richText = selectCellRichText(state, cell, getField(notebookFocus));
      if (!richText) {
        return;
      }

      const startOffset = cellFocus.start.offset ?? 0;
      const optionalEndOffset = cellFocus.end.offset;

      const text = charSlice(richText.text, startOffset, optionalEndOffset);

      const endOffset = optionalEndOffset ?? startOffset + charCount(text);

      const formatting = richText.formatting
        .filter(
          (annotation) =>
            annotation.offset >= startOffset && annotation.offset <= endOffset,
        )
        .map((annotation) => ({
          ...annotation,
          offset: annotation.offset - startOffset,
        }));

      return { text, formatting };
    }
  }
}

export function selectRelativeCell(
  state: RootState,
  referenceId: string | undefined,
  delta: 1 | -1,
) {
  const notebook = selectActiveNotebook(state);
  const index = referenceId ? selectCellIndexLocal(notebook, referenceId) : -1;
  const cellId = index > -1 ? notebook.cellIds[index + delta] : undefined;
  return cellId ? selectCellLocal(notebook, cellId) : undefined;
}

export function selectRelativeCellOrSurrogate(
  state: RootState,
  referenceId: string | undefined,
  delta: 1 | -1,
) {
  const notebook = selectActiveNotebook(state);
  const index = referenceId
    ? indexOfCellOrSurrogateId(notebook, referenceId)
    : undefined;
  return index === undefined
    ? index
    : cellOrSurrogateIdAtRelativeIndex(notebook, index, delta);
}

function cellOrSurrogateIdAtRelativeIndex(
  notebook: NotebookState,
  baseIndex: number,
  delta: 1 | -1,
): Cell | undefined {
  switch (baseIndex) {
    case -3:
      return delta > 0
        ? selectCellOrSurrogateLocal(notebook, GLOBAL_TIME_RANGE_ID)
        : undefined;
    case -2:
      return delta < 0
        ? selectCellOrSurrogateLocal(notebook, TITLE_CELL_ID)
        : selectCellOrSurrogateLocal(notebook, FRONT_MATTER_CELL_ID);

    case -1:
      if (delta < 0) {
        return selectCellOrSurrogateLocal(notebook, GLOBAL_TIME_RANGE_ID);
      } else {
        const cellId = notebook.cellIds[baseIndex + delta];
        return cellId ? selectCellLocal(notebook, cellId) : undefined;
      }

    default: {
      const index = baseIndex + delta;
      if (index < 0) {
        return selectCellOrSurrogateLocal(notebook, FRONT_MATTER_CELL_ID);
      } else {
        const cellId = notebook.cellIds[index];
        return cellId ? selectCellLocal(notebook, cellId) : undefined;
      }
    }
  }
}

function indexOfCellOrSurrogateId(
  notebook: NotebookState,
  cellId: string,
): number | undefined {
  switch (cellId) {
    case TITLE_CELL_ID:
      return -3;

    case GLOBAL_TIME_RANGE_ID:
      return -2;

    case FRONT_MATTER_CELL_ID:
      return -1;

    default: {
      const index = selectCellIndexLocal(notebook, cellId);
      return index === -1 ? undefined : index;
    }
  }
}

export const selectNotebookLabels = (state: RootState) =>
  state.notebooks.activeNotebook.labels;

export const selectNotebookFrontMatterSchema = (state: RootState) =>
  selectActiveNotebook(state).frontMatterSchema;
