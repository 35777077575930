import { encode } from "blurhash";

type ParserResult = {
  width: number;
  height: number;
  preview: string;
};

function getLoadFileError(_event: Event, source: LoadEventSource) {
  return source instanceof FileReader && source.error
    ? source.error
    : new Error("Unexpected error");
}

export function readFile(file: File): Promise<string> {
  const reader = new FileReader();

  function getResult(): string {
    if (typeof reader.result === "string") {
      return reader.result;
    }

    throw new Error("Loading of file failed: unexpected result");
  }

  const result = loadPromise(reader, getResult, getLoadFileError);
  reader.readAsDataURL(file);
  return result;
}

interface LoadEventMap {
  load: () => void;
  error: (event: Event) => void;
}

type LoadEventSource = {
  addEventListener<E extends keyof LoadEventMap>(
    eventName: E,
    handler: LoadEventMap[E],
  ): void;
  removeEventListener<E extends keyof LoadEventMap>(
    eventName: E,
    handler: LoadEventMap[E],
  ): void;
};

function loadPromise<S extends LoadEventSource, R>(
  eventSource: S,
  getResult: (source: S) => R,
  getError: (event: Event, source: S) => Error | DOMException,
): Promise<R> {
  return new Promise((resolve, reject) => {
    function handleLoad() {
      cleanup();
      resolve(getResult(eventSource));
    }

    function handleError(event: Event) {
      cleanup();
      reject(getError(event, eventSource));
    }

    function cleanup() {
      eventSource.removeEventListener("error", handleError);
      eventSource.removeEventListener("load", handleLoad);
    }

    eventSource.addEventListener("load", handleLoad);
    eventSource.addEventListener("error", handleError);
  });
}

function getImageError(event: Event) {
  return event instanceof ErrorEvent
    ? event.error
    : new Error("Unexpected error occurred while loading an image");
}

export function getImage(imgSource: string) {
  const img = new Image();

  function getResult() {
    return img;
  }

  const result = loadPromise(img, getResult, getImageError);

  img.src = imgSource;

  return result;
}

export function blurhashFactor(width: number, height: number) {
  const maxSize = Math.max(width, height);
  // This is the maximum size of the biggest dimension of the image
  // So, if set to 50 the max the blurhash would be calculated for on
  // an image of max 50x50 pixels
  const maxTargetSize = 50;
  return maxTargetSize / maxSize;
}
export function blurhashSize(width: number, height: number) {
  const factor = blurhashFactor(width, height);
  return {
    width: Math.round(width * factor),
    height: Math.round(height * factor),
  };
}

function getImageData(image: HTMLImageElement) {
  const canvas = document.createElement("canvas");
  const { width, height } = blurhashSize(
    image.naturalWidth,
    image.naturalHeight,
  );

  canvas.width = width;
  canvas.height = height;

  const context = canvas.getContext("2d");
  if (!context) {
    throw new Error("No canvas context");
  }

  context.drawImage(image, 0, 0, width, height);
  return context.getImageData(0, 0, width, height);
}

export async function parseImage(file: File): Promise<ParserResult> {
  const result = await readFile(file);
  const img = await getImage(result);

  const width = img.naturalWidth;
  const height = img.naturalHeight;

  const { data } = getImageData(img);
  const hashSize = blurhashSize(img.naturalWidth, img.naturalHeight);

  // ComponentX/ComponentY are special parameters for blurhash
  const componentX = 4;
  const componentY = 4;

  const preview = encode(
    data,
    hashSize.width,
    hashSize.height,
    componentX,
    componentY,
  );

  return {
    width,
    height,
    preview,
  };
}
