import { Button } from "@fiberplane/ui";
import { css, styled } from "styled-components";

const StyledButton = styled(Button)``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Form = styled.form`
  display: contents;
`;

export const FormHeader = styled.div(
  ({ theme }) => css`
    font: ${theme.fontStudioHeadingsH6ShortHand};
    letter-spacing: ${theme.fontStudioHeadingsH6LetterSpacing};
    color: ${theme.colorBase600};
    padding: 0 6px;
  `,
);

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 8px;

  ${/* sc-selector */ StyledButton}:last-child {
    margin-top: 12px;
  }
`;

export const FormErrorMessage = styled(FormHeader)(
  ({ theme }) => css`
    color: ${theme.colorDanger400};
  `,
);
