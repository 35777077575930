import { type MutableRefObject, useEffect, useState } from "react";

export function useErrorState(
  editorValue: string,
  editorRef: MutableRefObject<string>,
) {
  const [errorState, setErrorState] = useState(false);
  // Reset the error state when the value gets updated
  useEffect(() => {
    if (errorState && editorRef.current === editorValue) {
      setErrorState(false);
    }
  }, [editorValue, errorState, editorRef]);

  return [errorState, setErrorState] as const;
}
