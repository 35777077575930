import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import useAsync from "react-use/lib/useAsync";
import { styled } from "styled-components";

import { formatProviderType } from "../../../utils";
import {
  ModalErrorHint,
  ModalLabel,
  ModalLabelText,
  ModalTextInput,
} from "../../UI";
import type { ConfigEditorHandle, ConfigEditorProps } from "./ConfigEditor";
import { ValidationStatusIcon } from "./ValidationStatusIcon";
import { baseUrlValidate, formatUrlError } from "./validationUtils";

type LegacyConfig = { url: string };

const StyledBrandName = styled.span`
  text-transform: capitalize;
`;

function LegacyProviderConfigEditorComponent(
  {
    providerType,
    config,
    onSubmit,
    onValidationStatusChange,
    validationStatus,
  }: ConfigEditorProps,
  ref: React.Ref<ConfigEditorHandle>,
): JSX.Element {
  const [url, setUrl] = useState(isLegacyConfig(config) ? config.url : "");

  const validateAndSubmit = useCallback(() => {
    // Submission is a multi-step process: By setting the validation status to
    // "pending", the `useAsync()` hook will initiate validation. Dependending
    // on the outcome, we either submit or notify of the validation failure in
    // the `useEffect()` below.
    onValidationStatusChange("pending");
  }, [onValidationStatusChange]);
  useImperativeHandle(ref, () => ({ validateAndSubmit }));

  const { error, value: validatedUrl } = useAsync(
    () =>
      validationStatus === "pending"
        ? baseUrlValidate(url)
        : Promise.resolve(""),
    [url, validationStatus],
  );

  // When the validation has finished (for the current URL) and has not
  // produced any error, we will automatically continue submitting:
  const shouldSubmit = url && url === validatedUrl && !error;
  useEffect(() => {
    if (validationStatus !== "pending") {
      return;
    }

    if (shouldSubmit) {
      onSubmit(
        providerType === "elasticsearch"
          ? {
              url,
              // TODO: (JF) We may want to make this configurable
              // as well for direct access
              bodyFieldNames: [],
              timestampFieldNames: [],
            }
          : { url },
      );
    } else if (error) {
      onValidationStatusChange("invalid");
    }
  }, [
    providerType,
    error,
    onSubmit,
    onValidationStatusChange,
    shouldSubmit,
    url,
    validationStatus,
  ]);

  return (
    <>
      <ModalLabel>
        <ModalLabelText>
          Enter the{" "}
          <StyledBrandName>{formatProviderType(providerType)}</StyledBrandName>{" "}
          URL
        </ModalLabelText>
        <ModalTextInput
          data-source-url-input
          onChange={(event) => setUrl(event.target.value)}
          aria-invalid={validationStatus === "invalid" ? true : undefined}
          trailingIcon={() => (
            <ValidationStatusIcon status={validationStatus} />
          )}
          onKeyDown={(event) => {
            if (event.key === "Enter" && validationStatus !== "pending") {
              validateAndSubmit();
            }
          }}
          placeholder="i.e. https://your-data-source.example.com"
          value={url}
        />
      </ModalLabel>

      {validationStatus === "invalid" && (
        <ModalErrorHint>{formatUrlError(error)}</ModalErrorHint>
      )}
    </>
  );
}

export const LegacyProviderConfigEditor = React.forwardRef(
  LegacyProviderConfigEditorComponent,
);

function isLegacyConfig(config?: object): config is LegacyConfig {
  return !!config && "url" in config && typeof config.url === "string";
}
