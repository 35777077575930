import { Icon } from "@fiberplane/ui";
import { forwardRef } from "react";
import { css, styled } from "styled-components";

import { Container, Info } from "./shared";

type ProgressContainerProps = {
  /**
   * In percentage.
   */
  value: number;
};

export const Progress = forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof ProgressContainer>
>(function ProgressComponent(props, ref): JSX.Element {
  return (
    <ProgressContainer {...props} ref={ref}>
      <ProgressInfo>
        <Icon iconType="image" />
        Uploading Image
      </ProgressInfo>
    </ProgressContainer>
  );
});

const ProgressContainer = styled(Container)<ProgressContainerProps>(
  ({ theme, value }) => css`
    z-index: 1;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      width: ${`${value}%`};
      height: 100%;
      background-color: ${theme.colorSecondary100};
      transition: width cubic-bezier(0.075, 0.82, 0.165, 1) 0.2s;
      top: 0;
      left: 0;
      z-index: 0;
    }
  `,
);

const ProgressInfo = styled(Info)`
  z-index: 2;
`;
