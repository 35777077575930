import type { DataSource } from "../../types";
import { pick } from "../../utils";
import {
  deleteRequest,
  getRequest,
  parseJsonResponse,
  patchJsonRequest,
  postJsonRequestDeprecated,
} from "./utils";

export type NewDataSource = Pick<
  DataSource,
  "name" | "protocolVersion" | "providerType" | "config" | "description"
>;

export const createDataSource = (
  workspaceId: string,
  dataSource: NewDataSource,
) =>
  postJsonRequestDeprecated<DataSource>(
    `/api/workspaces/${workspaceId}/data_sources`,
    dataSource,
    parseJsonResponse,
  );

export const deleteDataSource = (workspaceId: string, dataSourceName: string) =>
  deleteRequest(
    `/api/workspaces/${workspaceId}/data_sources/${dataSourceName}`,
  );

export const listDataSources = (workspaceId: string) =>
  getRequest<Array<DataSource>>(
    `/api/workspaces/${workspaceId}/data_sources`,
    parseJsonResponse,
  );

export const updateDataSource = (workspaceId: string, dataSource: DataSource) =>
  patchJsonRequest<DataSource>(
    `/api/workspaces/${workspaceId}/data_sources/${dataSource.name}`,
    pick(dataSource, "config", "description"),
    parseJsonResponse,
  );
