import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { setCommandMenuVariant } from "../../../../../actions";
import { useListTemplatesQuery } from "../../../../../api";
import {
  selectActiveWorkspaceIdOrThrow,
  selectCanCreateNotebook,
  selectCommandMenuVariantType,
} from "../../../../../selectors";
import { dispatch } from "../../../../../store";
import {
  createNotebookAndRedirect,
  showModal,
  showSignOutConfirmation,
} from "../../../../../thunks";
import type { Command } from "../../../../../types";

export function useDefaultCommands(): Array<Command> {
  const { canCreateNotebook, variantType, workspaceId } = useSelector(
    selectDefaultCommandsState,
  );

  const { data: hasTemplates } = useListTemplatesQuery(
    { workspaceId },
    {
      selectFromResult: ({ data, ...rest }) => ({
        data: data ? data.length > 0 : false,
        ...rest,
      }),
      skip: !workspaceId,
    },
  );
  return useMemo(() => {
    if (variantType === "none" && workspaceId) {
      const commands: Array<Command> = [];

      if (canCreateNotebook) {
        commands.push({
          key: "notebooks/new",
          type: "commands",
          title: "Create a new blank Notebook",
          keywords: ["notebook", "add", "insert"],
          icon: "file_plus",
          onActivate: async () => {
            await dispatch(createNotebookAndRedirect());
          },
        });

        if (hasTemplates) {
          commands.push({
            key: "notebooks/newFromTemplate",
            type: "commands",
            title: "Create a new Notebook from a Template",
            keywords: ["notebook", "template", "add", "insert"],
            icon: "file_plus",
            onActivate: () =>
              dispatch(setCommandMenuVariant({ type: "selectTemplate" })),
            keepOpenAfterActivation: true,
          });
        }
      }

      commands.push(
        {
          key: "shortcuts",
          type: "commands",
          title: "Shortcuts",
          keywords: ["keyboards"],
          icon: "lightning",
          description: "List all available shortcuts",
          onActivate: () => dispatch(showModal({ type: "shortcuts" })),
        },
        {
          key: "signOut",
          type: "commands",
          title: "Sign out",
          icon: "sign_out",
          onActivate: () => dispatch(showSignOutConfirmation()),
        },
        {
          key: "search",
          type: "commands",
          title: "Search notebooks",
          description: "Search for notebooks",
          icon: "magnifying_glass",
          onActivate: () => dispatch(setCommandMenuVariant({ type: "search" })),
          keepOpenAfterActivation: true,
          keepQueryAfterActivation: false,
          focusInputAfterActivation: true,
          keyboardShortcut: "/",
        },
      );

      return commands;
    }

    return [];
  }, [canCreateNotebook, variantType, hasTemplates, workspaceId]);
}

const selectDefaultCommandsState = createSelector(
  [
    selectActiveWorkspaceIdOrThrow,
    selectCanCreateNotebook,
    selectCommandMenuVariantType,
  ],
  (workspaceId, canCreateNotebook, variantType) => ({
    canCreateNotebook,
    variantType,
    workspaceId,
  }),
);
