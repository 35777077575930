import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import scrollIntoView from "scroll-into-view-if-needed";

import {
  makeCellFocusSelector,
  selectLastUserAction,
  selectNotebookFocused,
} from "../selectors";
import { hasFocusPosition } from "../utils";

/**
 * Used to focus the viewport for cells that do not have input fields.
 *
 * For cells that have fields using the RTE, see `useFocusPosition()`.
 */
export function useFocus(
  cellId: string,
  contentRef: React.RefObject<HTMLElement>,
) {
  const shouldFocus = useShouldFocus(cellId);

  useEffect(() => {
    const contentEl = contentRef.current;

    // biome-ignore lint/complexity/useSimplifiedLogicExpression: prefer this more explicit logic
    if (!contentEl || !shouldFocus) {
      return;
    }

    const alreadyHasFocus = document.activeElement === contentEl;
    if (alreadyHasFocus) {
      return;
    }

    // NOTE - Calling `focus()` directly will scroll the element into view by default.
    //        We do not want this behavior, since we're also using `scrollIntoView()` below,
    //        and we want to avoid scrolling the element twice.
    //        Furthermore, the default scroll of `.focus` will always scroll to the center of the element,
    //        which is not ideal when the cell is taller than the viewport!
    //
    contentEl.focus({ preventScroll: true });

    // TODO - Handle the case where the cell is already partially visible to some acceptable percentage
    //        (e.g. 90% of the cell is visible).
    //        We should do this to avoid "jumping" to the cell when a use clicks on its
    //        bounding box in the notebook.
    //
    //        This will likely require usage of an intersection observer, or a modified `scrollIntoView` function that
    //        can calculate the percentage of the element that is visible.
    //
    scrollIntoView(contentEl, { scrollMode: "if-needed", block: "nearest" });
  }, [contentRef, shouldFocus]);
}

export function useShouldFocus(cellId: string) {
  const selectShouldFocus = useMemo(
    () => makeShouldFocusSelector(cellId),
    [cellId],
  );
  return useSelector(selectShouldFocus);
}

const makeShouldFocusSelector = (cellId: string) => {
  const selectCellFocus = makeCellFocusSelector(cellId);
  return createSelector(
    [selectCellFocus, selectLastUserAction, selectNotebookFocused],
    (cellFocus, lastUserAction, isNotebookFocused) =>
      hasFocusPosition(cellFocus) &&
      lastUserAction === "typing" &&
      isNotebookFocused,
  );
};
