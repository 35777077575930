import { useEffect } from "react";

import { Sentry } from "../../../../services";
import type { CellFocus } from "../../../../types";
import { getField } from "../../../../utils";
import { getNodeAndIndexForOffset } from "../utils";

/**
 * Sets the selection using the browser's Selection API, so that browser
 * functions like copying from the context menu, or possibly some extensions
 * will work as expected.
 */
export function useBrowserSelection<T>(
  containerEl: HTMLElement | null,
  focus: CellFocus,
  field: string | undefined,
  value: string,
  selectionDependency?: T,
) {
  /* biome-ignore lint/correctness/useExhaustiveDependencies: intentional to let
     the caller specify extra dependencies through `selectionDependency` */
  useEffect(
    () => updateBrowserSelection(containerEl, focus, field, value),
    [containerEl, focus, field, value, selectionDependency],
  );
}

function updateBrowserSelection(
  containerEl: HTMLElement | null,
  focus: CellFocus,
  field: string | undefined,
  value: string,
) {
  const selection = window.getSelection();
  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
  if (!containerEl || !selection || getField(focus) !== field) {
    return;
  }

  try {
    if (focus.type === "collapsed") {
      const nodeAndIndex = getNodeAndIndexForOffset(
        containerEl,
        value,
        focus.offset ?? 0,
      );
      if (nodeAndIndex) {
        selection.setPosition(nodeAndIndex[0], nodeAndIndex[1]);
      }
    } else if (focus.type === "selection") {
      const start = getNodeAndIndexForOffset(
        containerEl,
        value,
        focus.start.offset ?? 0,
      );
      const end = getNodeAndIndexForOffset(
        containerEl,
        value,
        focus.end.offset ?? 0,
      );
      if (start && end) {
        selection.setBaseAndExtent(start[0], start[1], end[0], end[1]);
      }
    }
  } catch (error) {
    Sentry.captureError("Exception while trying to set selection", { error });
  }
}
