import { memo } from "react";

import { AttachedPopup, type AttachedPopupProps } from "@fiberplane/ui";
import { Menu, type MenuProps } from "./Menu";

export type PositionedMenuProps = MenuProps &
  Pick<AttachedPopupProps, "element" | "offset" | "placement">;

/**
 * A menu that is positioned relative to a reference element.
 *
 * The reference element can be either a DOM element, or a virtual element.
 * See also: https://popper.js.org/docs/v2/virtual-elements/
 */
export const PositionedMenu = memo(function PositionedMenu({
  element,
  offset = [0, 0],
  placement = "bottom",
  ...menuProps
}: PositionedMenuProps) {
  return (
    <AttachedPopup element={element} offset={offset} placement={placement}>
      <Menu {...menuProps} />
    </AttachedPopup>
  );
});
