import { IconButton } from "@fiberplane/ui";
import { useState } from "react";
import { css, styled } from "styled-components";

import type { WebhookDeliverySummary } from "../../../../../types";
import { StatusBadge } from "../../../../UI";
import { DeliveryDetails } from "./DeliveryDetails";

type DeliveryProps = {
  delivery: WebhookDeliverySummary;
  webhookId: string;
};

export function Delivery({ delivery, webhookId }: DeliveryProps) {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded((current) => !current);

  return (
    <Container>
      <Toggle
        iconType={expanded ? "caret_down" : "caret_right"}
        buttonSize="small"
        onClick={toggleExpanded}
      />
      <ListItem key={delivery.id}>
        <StatusBadge
          onClick={toggleExpanded}
          $status={delivery.successful ? "success" : "danger"}
        >
          {delivery.successful ? "Delivered" : "Not delivered"}
        </StatusBadge>
        <Identifier onClick={toggleExpanded}>{delivery.id}</Identifier>
        <Event onClick={toggleExpanded}>{delivery.event}</Event>
        <Timestamp onClick={toggleExpanded}>{delivery.timestamp}</Timestamp>
        {expanded && (
          <DeliveryDetails deliveryId={delivery.id} webhookId={webhookId} />
        )}
      </ListItem>
    </Container>
  );
}

const Container = styled.li(
  ({ theme }) => css`
    display: flex;
    gap: 8px;
    border-bottom: 1px solid ${theme.colorBase300};

    &:last-child {
      border-bottom-color: transparent;
    }
  `,
);

const ListItem = styled.div`
  display: grid;
  grid:
    "status" auto
    "id" auto
    "event" auto
    "timestamp" auto
    "details" auto
    / auto 1fr;
  gap: 8px;
  margin: 0;
  padding: 0;

  @media (min-width: 768px) {
    flex: 1;
    grid: "status id event timestamp" auto "details details details details" auto / auto auto auto auto;
    align-items: center;
    justify-content: space-between;
  }
`;

const Toggle = styled(IconButton)`
  margin-top: -4px;
`;

const InfoLabel = styled.div(
  ({ theme }) =>
    css`
      cursor: pointer;
      color: ${theme.colorBase800};
      font: ${theme.fontNotebooksCodeShortHand};
      letter-spacing: ${theme.fontNotebooksCodeLetterSpacing};
    `,
);

const Identifier = styled(InfoLabel)`
  grid-area: id;
`;

const Event = styled(InfoLabel)`
  grid-area: event;
`;

const Timestamp = styled(InfoLabel)`
  grid-area: timestamp;
`;
