import { forwardRef } from "react";

import type { FrontMatterUser } from "../../../../../types";
import { ItemSelector } from "../shared";
import { renderValue } from "./shared";

type Props = Readonly<{
  readOnly?: boolean;
  focused?: boolean;
  /**
   * List of user ids that should not be shown in the suggestions
   */
  selectedList?: Array<string>;
  /**
   * Placeholder text to show when the cell is not focused
   */
  placeholder: string;
  user?: FrontMatterUser;
  onFocus?: () => void;
  openContextMenu(value: string | undefined): void;
  onClear?: () => void;
  hideClearButton?: boolean;
}>;

export type UserSelectorHandle = {
  focus: () => void;
};

export const UserSelector = forwardRef<UserSelectorHandle, Props>(
  function UserSelector(props: Props, forwardRef) {
    const {
      readOnly,
      focused,
      user,
      openContextMenu,
      onFocus,
      placeholder,
      hideClearButton = false,
      onClear,
    } = props;

    const selectorProps = {
      getId: (option: FrontMatterUser) => option.id,
      getValue: (option: FrontMatterUser) => option.name,
      openContextMenu: openContextMenu,
      onFocus: onFocus,
      focused: focused,
      readOnly: readOnly,
      placeholder: placeholder,
      selection: user,
      onClear: onClear,
      hideClearButton: hideClearButton,
      renderValue: renderValue,
    };

    return <ItemSelector {...selectorProps} ref={forwardRef} />;
  },
);
