import { useHandler } from "@fiberplane/hooks";
import { Icon } from "@fiberplane/ui";

import { MenuItem } from "..";

export type MenuItemWithCheckboxProps = {
  checked: boolean;

  /**
   * ID of the menu item.
   */
  id: string;

  title: string;

  /**
   * Called when the checkbox is toggled.
   */
  onChange: (checked: boolean) => void;
};

export function MenuItemWithCheckbox({
  checked,
  id,
  onChange,
  title,
}: MenuItemWithCheckboxProps) {
  const onActivate = useHandler(() => {
    onChange(!checked);
  });
  return (
    <MenuItem
      id={id}
      title={title}
      onActivate={onActivate}
      iconRight={
        checked
          ? {
              icon: <Icon iconType="check" />,
              showOnlyOnSelection: false,
            }
          : undefined
      }
    />
  );
}
