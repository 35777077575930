import { css, styled } from "styled-components";

import { ModalButtonBar } from "./ModalButtonBar";

export const ModalDescription = styled.p(
  ({ theme }) => css`
    font: ${theme.font.body.md.regular};
    color: ${theme.color.fg.muted};
    margin: 0;
  `,
);

export const ModalContent = styled.section`
  min-width: fit-content;
  position: relative;
  padding: 24px;

  & + ${ModalButtonBar} {
    padding-top: 0;
  }
`;
