import { getTokenFromLocationHash } from "../../utils";
import { type Token, getToken as dbGetToken, setToken } from "./store";

export { getToken, setToken, clearToken } from "./store";

export async function getApiToken(): Promise<Token | undefined> {
  const token = getTokenFromLocationHash();
  if (token) {
    await setToken("api", token);
    return { type: "api", value: token };
  }

  return dbGetToken("api");
}

export async function initServiceWorker(): Promise<void> {
  if (!("serviceWorker" in navigator)) {
    throw new Error(
      // this error message doesn't include articles like "the" for the purposes of readability
      "Service workers are required for Fiberplane Studio to work. Make sure they're enabled in your browser, you are using latest version of Safari, Firefox, or Chrome and you're not in private mode on Firefox",
    );
  }

  const registration = await navigator.serviceWorker.register(
    new URL("worker/sw.ts", import.meta.url),
    {
      scope: "/",
      type: "module",
    },
  );

  // If there's an active worker, assume it's the right one
  if (registration.active) {
    return;
  }

  const currentWorker =
    registration.active || registration.waiting || registration.installing;
  if (!currentWorker) {
    throw new Error("No possible worker found");
  }

  return new Promise((resolve) => {
    currentWorker.addEventListener("statechange", (event: Event) => {
      const target = event.target as ServiceWorker;
      if (target.state === "activated") {
        resolve();
      }
    });
  });
}
