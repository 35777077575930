import {
  DELETE_THREAD,
  FETCH_THREADS_FULFILLED,
  FETCH_THREADS_PENDING,
  FETCH_THREADS_REJECTED,
  SET_THREAD_DELETION_PROMPT,
  SUBMIT_COMMENT_FULFILLED,
  SUBMIT_COMMENT_PENDING,
  SUBMIT_COMMENT_REJECTED,
  TOGGLE_THREAD_SHOW_REPLIES,
  removeCell,
  showError,
  withActiveNotebook,
} from "../actions";
import { normalizeException } from "../errors";
import {
  selectActiveNotebookId,
  selectCell,
  selectRelativeCell,
} from "../selectors";
import { Api } from "../services";
import type { Thunk } from "../store";
import type {
  AddCommentPayload,
  CommentThreadItem,
  Formatting,
  ThreadStatus,
  User,
} from "../types";

type AddCommentArguments = {
  cellId: string;
  formatting?: Formatting;
  text: string;
  threadId: string;
};

export function addComment({
  cellId,
  formatting,
  text,
  threadId,
}: AddCommentArguments): Thunk {
  return async (dispatch) => {
    const newComment = {
      content: text,
      formatting,
    };

    dispatch({ type: SUBMIT_COMMENT_PENDING, payload: threadId });

    try {
      const commentResponse = await Api.createComment({
        newComment,
        threadId,
      });

      const payload: AddCommentPayload = {
        cellId,
        commentResponse,
      };

      dispatch(withActiveNotebook({ type: "add_comment", payload }));

      dispatch({
        type: SUBMIT_COMMENT_FULFILLED,
        payload: threadId,
      });
    } catch (error) {
      const normalizedException = normalizeException(error);

      dispatch(
        withActiveNotebook(
          showError({
            type: "other",
            message: "Unable to submit comment",
          }),
        ),
      );
      console.error(normalizedException);

      dispatch({
        type: SUBMIT_COMMENT_REJECTED,
        payload: threadId,
      });
    }
  };
}

export const createThread =
  (): Thunk<Promise<string>> => async (dispatch, getState) => {
    const notebookId = selectActiveNotebookId(getState());
    const thread = await Api.createThread(notebookId, {});

    dispatch(withActiveNotebook({ type: "add_thread", payload: { thread } }));

    return thread.id;
  };

type DeleteCommentArguments = {
  comment: CommentThreadItem;
  threadId: string;
};

export function deleteComment({
  comment,
  threadId,
}: DeleteCommentArguments): Thunk {
  return async (dispatch) => {
    await Api.deleteComment(comment.id);

    const deletedAt = new Date(Date.now()).toISOString();

    dispatch(
      withActiveNotebook({
        type: "delete_comment",
        payload: {
          commentDelete: {
            createdAt: comment.createdAt,
            createdBy: comment.createdBy,
            deletedAt,
            id: comment.id,
          },
          threadId,
        },
      }),
    );
  };
}

export function updateComment({
  commentId,
  content,
  formatting,
  threadId,
}: {
  commentId: string;
  content?: string;
  formatting?: Formatting;
  threadId: string;
}): Thunk {
  return async (dispatch) => {
    const updateCommentResponse = await Api.updateComment(commentId, {
      content,
      formatting,
    });

    dispatch(
      withActiveNotebook({
        type: "edit_comment",
        payload: {
          commentResponse: updateCommentResponse,
          threadId,
        },
      }),
    );
  };
}

type ChangeDiscussionStatus = {
  newStatus: ThreadStatus;
  threadId: string;
  user: User;
};

export function changeDiscussionStatus({
  newStatus,
  threadId,
}: ChangeDiscussionStatus): Thunk {
  return async (dispatch) => {
    const threadResponse = await (newStatus === "resolved"
      ? Api.resolveThread(threadId)
      : Api.reopenThread(threadId));

    dispatch(
      withActiveNotebook({
        type: "change_thread_status",
        payload: {
          threadId,
          threadResponse,
        },
      }),
    );
  };
}

export function fetchThread(threadId: string): Thunk {
  return async (dispatch) => {
    const threadResponse = await Api.getThread(threadId);

    dispatch(
      withActiveNotebook({
        type: "expand_thread",
        payload: {
          threadResponse,
          threadId,
        },
      }),
    );
  };
}

export function fetchThreads(): Thunk {
  return async (dispatch, getState) => {
    const notebookId = selectActiveNotebookId(getState());

    dispatch({ type: FETCH_THREADS_PENDING });

    try {
      const threads = await Api.getThreads(notebookId);

      dispatch(
        withActiveNotebook({
          type: "load_threads",
          payload: { threadsResponse: threads },
        }),
      );

      dispatch({ type: FETCH_THREADS_FULFILLED });
    } catch (error) {
      const normalizedException = normalizeException(error);

      dispatch(
        withActiveNotebook(
          showError({
            type: "other",
            message: `Unable to fetch discussion threads (${normalizedException.toString()})`,
          }),
        ),
      );
      console.error(normalizedException);

      dispatch({ type: FETCH_THREADS_REJECTED });
    }
  };
}

export function deleteCellAndThread(cellId: string): Thunk {
  return async (dispatch, getState) => {
    const cell = selectCell(getState(), cellId);

    if (cell?.type === "discussion") {
      await Api.deleteThread(cell.threadId);

      dispatch({
        type: DELETE_THREAD,
        payload: cell.threadId,
      });

      const focusCell = selectRelativeCell(getState(), cellId, -1);
      dispatch(
        withActiveNotebook(
          removeCell(
            cellId,
            focusCell ? { cursorDirection: "backward", focusCell } : undefined,
          ),
        ),
      );
    }
  };
}

export function toggleThreadShowReplies(threadId: string): Thunk {
  return (dispatch) => {
    dispatch({ type: TOGGLE_THREAD_SHOW_REPLIES, payload: threadId });
  };
}

export function setThreadDeletionPrompt({
  threadId,
  showThreadDeletionPrompt,
}: {
  threadId: string;
  showThreadDeletionPrompt: boolean;
}): Thunk {
  return (dispatch) => {
    dispatch({
      type: SET_THREAD_DELETION_PROMPT,
      payload: {
        threadId,
        showThreadDeletionPrompt,
      },
    });
  };
}
