// Define a regex for boundaries, excluding Unicode letters (\p{L}) and numbers (\p{N})
// The 'u' flag enables Unicode mode, allowing for the use of Unicode property escapes
const boundaryRegex = /[^\p{L}\p{N}_]/u;

/**
 * Find the beginning of a word in a string. If no offset is specified, the end of the string is used
 */
export function findWordStart(s: string, offset?: number): number {
  // If there is an empty string, return 0
  if (!s) {
    return 0;
  }

  const chars = Array.from(s);
  const end = chars.length - 1;

  let index = offset !== undefined ? Math.min(offset, end) : end;
  while (index > 0) {
    const char = chars[index];
    if (char === undefined || boundaryRegex.test(char)) {
      index += 1;
      break;
    }
    index -= 1;
  }
  return index;
}
