import { useSelector } from "react-redux";
import { styled } from "styled-components";

import { useListDeliveriesQuery } from "../../../../../api";
import { selectActiveWorkspaceIdOrThrow } from "../../../../../selectors";
import { Delivery } from "./Delivery";

export function DeliveryList({ webhookId }: { webhookId: string }) {
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const { data: deliveries } = useListDeliveriesQuery(
    {
      webhookId,
      workspaceId,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !webhookId,
    },
  );

  if (!deliveries || deliveries.length === 0) {
    return null;
  }

  return (
    <List>
      {deliveries.map((delivery) => (
        <Delivery key={delivery.id} delivery={delivery} webhookId={webhookId} />
      ))}
    </List>
  );
}

const List = styled.ul`
  display: grid;
  gap: 12px;
  margin: 0;
  padding: 0;
`;
