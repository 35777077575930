import { createSelector } from "reselect";

import type { CommandMenuState, RootState } from "../state";
import type { Label } from "../types";

const emptyLabels: ReadonlyArray<Label> = [] as const;

export const selectCommandMenu = (state: RootState): CommandMenuState =>
  state.commandMenu;

export const selectCommandMenuSelectedIndex = (state: RootState) =>
  selectCommandMenu(state).selectedIndex;

export const selectCommandMenuQuery = (state: RootState): string =>
  selectCommandMenu(state).query;

export const selectCommandMenuVariant = (state: RootState) =>
  selectCommandMenu(state).variant;

export const selectCommandMenuVariantType = (state: RootState) =>
  selectCommandMenuVariant(state).type;

export const selectCommandMenuLabels = createSelector(
  [selectCommandMenuVariant],
  (variant) => (variant.type === "search" && variant.labels) || emptyLabels,
);

export const selectCommandMenuLabelsEditor = createSelector(
  [selectCommandMenuVariant],
  (variant) => (variant.type === "search" ? variant.labelsEditor : undefined),
);
