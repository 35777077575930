import type { TagDescription } from "@reduxjs/toolkit/dist/query";

import type {
  NewView,
  SortDirection,
  UpdateView,
  View,
  ViewSortFields,
} from "../types";
import { baseApi } from "./base";
import { jsonWithTextErrorResponseHandler, withQueryArgs } from "./utils";

export const viewsApi = baseApi
  .injectEndpoints({
    endpoints: (builder) => ({
      createView: builder.mutation<
        View,
        { workspaceId: string; newView: NewView }
      >({
        query: ({ workspaceId, newView }) => ({
          url: `/workspaces/${workspaceId}/views`,
          method: "POST",
          body: newView,
        }),
      }),
      deleteView: builder.mutation<
        void,
        { workspaceId: string; viewName: string }
      >({
        query: ({ workspaceId, viewName }) => ({
          url: `/workspaces/${workspaceId}/views/${viewName}`,
          method: "DELETE",
          responseHandler: jsonWithTextErrorResponseHandler,
        }),
      }),
      getViewByName: builder.query<
        View,
        { workspaceId: string; viewName: string }
      >({
        query: ({ workspaceId, viewName }) =>
          `/workspaces/${workspaceId}/views/${viewName}`,
      }),
      listPinnedViews: builder.query<Array<View>, { workspaceId: string }>({
        query: ({ workspaceId }) => `/workspaces/${workspaceId}/pinned_views`,
      }),
      listViews: builder.query<
        Array<View>,
        {
          workspaceId: string;
          page?: number;
          limit?: number;
          sortBy?: ViewSortFields;
          sortDirection?: SortDirection;
        }
      >({
        query: ({ workspaceId, page, limit, sortBy, sortDirection }) =>
          withQueryArgs(`/workspaces/${workspaceId}/views`, {
            page,
            limit,
            sortBy,
            sortDirection,
          }),
      }),
      pinView: builder.mutation<
        void,
        { workspaceId: string; viewName: string }
      >({
        query: ({ workspaceId, viewName }) => ({
          url: `/workspaces/${workspaceId}/pinned_views`,
          method: "POST",
          body: {
            name: viewName,
          },
        }),
      }),
      unpinView: builder.mutation<
        void,
        { workspaceId: string; viewName: string }
      >({
        query: ({ workspaceId, viewName }) => ({
          url: `/workspaces/${workspaceId}/pinned_views/${viewName}`,
          method: "DELETE",
        }),
      }),
      updateView: builder.mutation<
        View,
        { workspaceId: string; viewName: string; updateView: UpdateView }
      >({
        query: ({ workspaceId, viewName, updateView }) => ({
          url: `/workspaces/${workspaceId}/views/${viewName}`,
          method: "PATCH",
          body: updateView,
        }),
      }),
    }),
    overrideExisting: false,
  })
  .enhanceEndpoints({
    addTagTypes: ["PinnedView", "SearchedNotebooks", "View"],
    endpoints: {
      createView: {
        invalidatesTags: [{ type: "View", id: "LIST" }],
      },
      deleteView: {
        invalidatesTags: (_result, _error, { viewName }) => [
          { type: "View", id: viewName },
          { type: "PinnedView", id: viewName },
        ],
      },
      getViewByName: {
        providesTags: (_result, _error, { viewName }) => [
          { type: "View", id: viewName },
        ],
      },
      listPinnedViews: {
        providesTags: (pinnedViews) => {
          const pinnedViewTags =
            pinnedViews?.map(
              ({ name }): TagDescription<"PinnedView"> => ({
                type: "PinnedView",
                id: name,
              }),
            ) ?? [];

          return [{ type: "PinnedView", id: "LIST" }, ...pinnedViewTags];
        },
      },
      listViews: {
        providesTags: (views) => {
          const viewTags =
            views?.map(
              ({ name }): TagDescription<"View"> => ({
                type: "View",
                id: name,
              }),
            ) ?? [];

          return [{ type: "View", id: "LIST" }, ...viewTags];
        },
      },
      unpinView: {
        invalidatesTags: (_data, _error, { viewName }) => [
          { type: "PinnedView", id: viewName },
        ],
      },
      pinView: {
        invalidatesTags: [{ type: "PinnedView", id: "LIST" }],
      },
      updateView: {
        invalidatesTags: (_result, _error, { viewName }) => [
          { type: "View", id: viewName },
          { type: "PinnedView", id: viewName },
          { type: "SearchedNotebooks" },
        ],
      },
    },
  });

export const {
  useCreateViewMutation,
  useDeleteViewMutation,
  useGetViewByNameQuery,
  useListPinnedViewsQuery,
  useListViewsQuery,
  usePinViewMutation,
  useUnpinViewMutation,
  useUpdateViewMutation,
} = viewsApi;
