import type { AuthRole } from "../types";

const KEY = "lastActiveWorkspaceName";

export function formatRole(role: AuthRole) {
  switch (role) {
    case "admin":
      return "Admin";

    case "write":
      return "Editor";

    case "read":
      return "Viewer";
  }
}

/**
 * Returns the `activeWorkspaceName` from localStorage
 **/
export const getLastActiveWorkspaceName = () => localStorage.getItem(KEY);

/**
 * Sets the `activeWorkspaceName` in localStorage
 **/
export const setLastActiveWorkspaceName = (name: string) =>
  localStorage.setItem(KEY, name);
