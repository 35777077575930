import { IconButton } from "@fiberplane/ui";
import { styled } from "styled-components";

import { useThrottledLoadingState } from "../../../hooks";

type RefreshButtonProps = {
  busy: boolean;
} & Pick<React.HTMLAttributes<HTMLButtonElement>, "onClick" | "className">;

export function RefreshButton({
  busy,
  className,
  onClick,
}: RefreshButtonProps) {
  const throttledBusy = useThrottledLoadingState(busy, 800);

  return (
    <StyledIconButton
      aria-disabled={throttledBusy}
      aria-label="Refresh"
      buttonStyle="tertiary-grey"
      className={className}
      data-testid="refresh"
      disabled={throttledBusy}
      iconType="arrows_clockwise"
      onClick={onClick}
    />
  );
}

const StyledIconButton = styled(IconButton)`
  @keyframes spin {
    to {
      rotate: 360deg;
    }
  }

  &[aria-disabled="true"] {
    cursor: default;

    & > svg {
      animation: spin 0.8s ease-in-out forwards infinite;
    }
  }
`;
