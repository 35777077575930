import { memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { replace } from "redux-first-history";
import { createSelector } from "reselect";

import { notebooksApi } from "../../api";
import {
  selectAvailableWorkspaces,
  selectNotebookId,
  selectNotebookTitle,
  selectNotebookWorkspaceId,
  selectPathname,
} from "../../selectors";
import { dispatch } from "../../store";
import { closeNotebook, loadNotebookById } from "../../thunks";
import {
  createNotebookLink,
  extractNotebookIdFromPath,
  page,
} from "../../utils";
import { Notebook } from "./Notebook";
import { useTrackOnboardingNotebookOpened } from "./hooks";

type Params = {
  workspaceName: string;
  notebookId: string;
};

export const NotebookWithId = memo(function NotebookWithId() {
  const { notebookId: notebookIdParameter } = useParams<Params>();
  const notebookId =
    notebookIdParameter && extractNotebookIdFromPath(notebookIdParameter);

  const notebookWithIdSelector = useMemo(
    () => makeNotebookWithIdSelector(notebookId),
    [notebookId],
  );

  const { activeNotebookId, updatedNotebookPath } = useSelector(
    notebookWithIdSelector,
  );

  const [didLoad, setLoaded] = useState(false);

  useEffect(() => {
    page({ notebookId });
  }, [notebookId]);

  useTrackOnboardingNotebookOpened({ didLoad });

  useEffect(() => {
    if (notebookId) {
      if (notebookId === activeNotebookId) {
        setLoaded(true);
      } else if (!didLoad) {
        dispatch(loadNotebookById(notebookId));
      }
    }
  }, [activeNotebookId, didLoad, notebookId]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (updatedNotebookPath) {
      // The notebook hasn't fully unloaded yet and we can't assume that the active notebook
      // is part of the active workspace, so we need to prevent the url from replacing redirects.
      // Unfortunately a setZeroTimeout is too fast, and will force path updating, as the notebook
      // hasn't unloaded properly yet.
      timeoutId = setTimeout(() => dispatch(replace(updatedNotebookPath)), 10);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [updatedNotebookPath]);

  useEffect(() => {
    const cleanup = () => {
      if (notebookId) {
        dispatch(
          notebooksApi.util.invalidateTags([
            { type: "Notebook", id: notebookId },
          ]),
        );
        dispatch(closeNotebook(notebookId, { newActiveNotebookId: undefined }));
        setLoaded(false);
      }
    };

    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, [notebookId]);

  return notebookId === activeNotebookId ? <Notebook /> : null;
});

/**
 * Creates a selector that returns the `activeNotebookId` and an `updatedPath`,
 * which is used to replace the `pathname` into a single canonical path using `createNotebookLink`
 */
const makeNotebookWithIdSelector = (notebookIdParameter?: string) =>
  createSelector(
    [
      selectPathname,
      selectNotebookId,
      selectNotebookTitle,
      selectNotebookWorkspaceId,
      selectAvailableWorkspaces,
    ],
    (
      pathname,
      activeNotebookId,
      activeNotebookTitle,
      activeNotebookWorkspaceId,
      availableWorkspaces,
    ) => {
      // Check if we need to return a corrected pathname
      if (
        activeNotebookId &&
        activeNotebookWorkspaceId &&
        // Only trigger updatedNotebookPath if where the activeNotebookId matches the route param
        // Fixes FP-2444
        // https://linear.app/fiberplane/issue/FP-2444/clicking-on-recent-notebooks-does-not-work
        notebookIdParameter === activeNotebookId
      ) {
        const workspaceName = availableWorkspaces.find(
          ({ id }) => id === activeNotebookWorkspaceId,
        )?.name;
        if (workspaceName) {
          const activeNotbookPath = createNotebookLink(
            workspaceName,
            activeNotebookId,
            activeNotebookTitle,
          );

          if (pathname !== activeNotbookPath) {
            return {
              activeNotebookId,
              updatedNotebookPath: activeNotbookPath,
            };
          }
        }
      }

      return {
        activeNotebookId,
      };
    },
  );
