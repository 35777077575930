import { styled } from "styled-components";

const maxHeight = 4 * 295;
const maxWidth = 4 * 400;
const ratio = (maxHeight / maxWidth) * 100;

const SizeConstraint = styled.div`
  max-width: min(100%, ${maxWidth}px);
  max-height: min(100%, ${maxHeight}px);
`;

const RatioContainer = styled.div`
  height: 0;
  padding-top: ${ratio}%;
  position: relative;
  overflow: hidden;
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
`;

export function ResizeConstraint(props: {
  children: React.ReactNode;
}): JSX.Element {
  return (
    <SizeConstraint>
      <RatioContainer>
        <Content>{props.children}</Content>
      </RatioContainer>
    </SizeConstraint>
  );
}
