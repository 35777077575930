import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import useAsync from "react-use/lib/useAsync";
import { styled } from "styled-components";

import { RequiredValidationError } from "../../../errors";
import { dispatch } from "../../../store";
import { getProviderForDataSourceWithQueryType } from "../../../thunks";
import type {
  AutoSuggestRequest,
  DataSource,
  ProviderRequest,
} from "../../../types";
import { toQueryDataBlob, unwrap } from "../../../utils";
import {
  ModalErrorHint,
  ModalLabel,
  ModalLabelText,
  ModalTextInput,
} from "../../UI";
import type { ConfigEditorHandle, ConfigEditorProps } from "./ConfigEditor";
import { ValidationStatusIcon } from "./ValidationStatusIcon";
import { baseUrlValidate, formatUrlError } from "./validationUtils";

type PrometheusConfig = { url: string };

const StyledBrandName = styled.span`
  text-transform: capitalize;
`;

function PrometheusProviderConfigEditorComponent(
  {
    config,
    onSubmit,
    onValidationStatusChange,
    validationStatus,
  }: ConfigEditorProps,
  ref: React.Ref<ConfigEditorHandle>,
): JSX.Element {
  const [url, setUrl] = useState(isPrometheusConfig(config) ? config.url : "");

  const prometheusValidate = useCallback(
    (url: string) => baseUrlValidate(url).then(validatePrometheusUrl),
    [],
  );

  const validateAndSubmit = useCallback(() => {
    // Submission is a multi-step process: By setting the validation status to
    // "pending", the `useAsync()` hook will initiate validation. Dependending
    // on the outcome, we either submit or notify of the validation failure in
    // the `useEffect()` below.
    onValidationStatusChange("pending");
  }, [onValidationStatusChange]);
  useImperativeHandle(ref, () => ({ validateAndSubmit }));

  const { error, value: validatedUrl } = useAsync(
    () =>
      validationStatus === "pending"
        ? prometheusValidate(url)
        : Promise.resolve(""),
    [url, validationStatus],
  );

  // When the validation has finished (for the current URL) and has not
  // produced any error, we will automatically continue submitting:
  const shouldSubmit = url && url === validatedUrl && !error;
  useEffect(() => {
    if (validationStatus !== "pending") {
      return;
    }

    if (shouldSubmit) {
      onSubmit({ url });
    } else if (error) {
      onValidationStatusChange("invalid");
    }
  }, [
    error,
    onSubmit,
    onValidationStatusChange,
    shouldSubmit,
    url,
    validationStatus,
  ]);

  return (
    <>
      <ModalLabel>
        <ModalLabelText>
          Enter the <StyledBrandName>Prometheus</StyledBrandName> URL
        </ModalLabelText>
        <ModalTextInput
          data-source-url-input
          onChange={(event) => setUrl(event.target.value)}
          aria-invalid={validationStatus === "invalid" ? true : undefined}
          trailingIcon={() => (
            <ValidationStatusIcon status={validationStatus} />
          )}
          onKeyDown={(event) => {
            if (event.key === "Enter" && validationStatus !== "pending") {
              validateAndSubmit();
            }
          }}
          placeholder="i.e. https://prometheus.example.com"
          value={url}
        />
        {validationStatus === "invalid" && (
          <ModalErrorHint>{formatUrlError(error)}</ModalErrorHint>
        )}
      </ModalLabel>
    </>
  );
}

export const PrometheusProviderConfigEditor = React.forwardRef(
  PrometheusProviderConfigEditorComponent,
);

function isPrometheusConfig(config?: object): config is PrometheusConfig {
  if (!config) {
    return false;
  }

  return typeof (config as PrometheusConfig).url === "string";
}

async function validatePrometheusUrl(url: string): Promise<string> {
  if (!url) {
    throw new RequiredValidationError("url");
  }

  const dataSource: DataSource = {
    id: "dummy",
    name: "dummy",
    protocolVersion: 2,
    providerType: "prometheus",
    config: { url },
  };

  const { provider } = await dispatch(
    getProviderForDataSourceWithQueryType(dataSource, "suggestions"),
  );

  const queryData: AutoSuggestRequest = {
    // biome-ignore lint/style/useNamingConvention: naming convention is correct
    query_type: "timeseries",
    query: "",
    field: "query",
  };

  const request: ProviderRequest = {
    config: dataSource.config,
    queryType: "suggestions",
    queryData: toQueryDataBlob(queryData),
  };

  const response = await provider.invoke(request, dataSource);
  unwrap(response); // Throws in case of failure.

  return url;
}
