import { Fragment, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { throttle } from "throttle-debounce";

import { setHeight, toggleExpanded } from "../../actions";
import { ROUTES, VERSION } from "../../constants";
import { formatFiberplaneError } from "../../errors";
import { selectConsole, selectConsoleMessages } from "../../selectors";
import { useActiveNotebookDispatch } from "../../store";
import { getFeatures } from "../../utils";
import {
  Console,
  ConsoleHeader,
  ConsoleHeaderGroup,
  ConsoleHeaderIcon,
  ConsoleMessage,
  ConsoleMessages,
  ConsoleResizeHandle,
  Spacer,
} from "../UI";
import { ConnectionStatusIndicator } from "./ConnectionStatusIndicator";
import { FeedbackMessageCounter } from "./FeedbackMessageCounter";
import { NotebookSyncIndicator } from "./NotebookSyncIndicator";

export function FeedbackConsole(): JSX.Element {
  const dispatch = useActiveNotebookDispatch();
  const { expanded, height } = useSelector(selectConsole);
  const messages = useSelector(selectConsoleMessages);

  const actualHeight = expanded ? height : 0;

  const [isResizing, setResizing] = useState(false);
  const startResize = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();

      const initialY = event.pageY;
      const resize = throttle(33, (event: MouseEvent) => {
        const delta = event.pageY - initialY;
        dispatch(setHeight(Math.max(0, actualHeight - delta)));
      });
      const stopResizing = () => {
        document.removeEventListener("mousemove", resize);
        document.removeEventListener("mouseup", stopResizing, true);
        setResizing(false);
      };

      document.addEventListener("mousemove", resize);
      document.addEventListener("mouseup", stopResizing, true);
      setResizing(true);
    },
    [actualHeight, dispatch],
  );

  const toggle = useCallback(() => dispatch(toggleExpanded()), [dispatch]);
  const features = getFeatures();

  return (
    <Console>
      <ConsoleHeader isResizing={isResizing}>
        <ConnectionStatusIndicator />
        <Routes>
          <Route path={ROUTES.Notebook} element={<NotebookSyncIndicator />} />
        </Routes>
        <Spacer />
        <ConsoleResizeHandle isActive={isResizing} onMouseDown={startResize} />
        <Spacer />
        <ConsoleHeaderGroup>
          <FeedbackMessageCounter severity="notice" />
          <FeedbackMessageCounter severity="warning" />
          <FeedbackMessageCounter severity="error" />
        </ConsoleHeaderGroup>
        <ConsoleHeaderIcon
          onClick={toggle}
          data-testid="console-toggle"
          iconType={expanded ? "caret_down" : "caret_up"}
        />
      </ConsoleHeader>
      <ConsoleMessages style={{ height: actualHeight }}>
        <ConsoleMessage key={VERSION}>
          --------------------- <br />- Fiberplane studio - <br />
          ---------------------
          <br /> build: <strong>#{VERSION.substr(0, 8)}</strong>
          <br /> experimental features:
          {features.length > 0 ? (
            features.map((feature) => (
              <Fragment key={feature}>
                <br />* <em>{feature}</em>
              </Fragment>
            ))
          ) : (
            <>
              <br />
              <em>none</em>
            </>
          )}
          <br />
          ---------------------
        </ConsoleMessage>
        {messages.map((message, i) => (
          <ConsoleMessage key={i} severity={message.severity}>
            {message.severity === "error"
              ? formatFiberplaneError(message.error)
              : message.message}
          </ConsoleMessage>
        ))}
      </ConsoleMessages>
    </Console>
  );
}
