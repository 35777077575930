import { Icon } from "@fiberplane/ui";
import { css, styled } from "styled-components";

import {
  ButtonFooter,
  IllustrationContainer,
  ModalBodyRegularText,
  ModalClose,
  ModalHeading,
  NextButton,
  PageIndicator,
  PageIndicatorList,
  StyledModal,
  StyledModalContent,
} from "./common";

type Props = { isLoading: boolean; next: () => void };

export function ProxyExplainer({ isLoading, next }: Props) {
  return (
    <StyledModal>
      <StyledModalContent>
        <IllustrationContainer>
          <ModalClose />
          <ProxyIconWrapper>
            <Icon iconType="proxy" height="70" width="70" />
          </ProxyIconWrapper>
        </IllustrationContainer>
        <ModalHeading>Connect Data with FPD</ModalHeading>
        <LeftAlign>
          <ModalBodyRegularText>
            To connect data sources like Prometheus to notebooks, Studio uses
            the Fiberplane Daemon. We can set up FPD in two steps:
          </ModalBodyRegularText>
          <ProxySetupSteps>
            <ProxySetupStep>
              <ModalBodyRegularText>
                <b>Create a token</b>&mdash;FPD needs an API token to relay
                requests from Fiberplane Studio to data sources.
              </ModalBodyRegularText>
            </ProxySetupStep>
            <ProxySetupStep>
              <ModalBodyRegularText>
                <b>Deploy your FPD instance</b>&mdash;Using Docker or
                Kubernetes, configure an instance of Fiberplane Daemon with your
                token.
              </ModalBodyRegularText>
            </ProxySetupStep>
          </ProxySetupSteps>
        </LeftAlign>
      </StyledModalContent>
      <ButtonFooter>
        {/* This is a little funky, but we also bounce the page indicators to indicate a loading state :D */}
        <PageIndicatorList
          initial="initial"
          animate={isLoading ? "animate" : false}
        >
          <PageIndicator $active />
          <PageIndicator />
          <PageIndicator />
        </PageIndicatorList>
        <NextButton disabled={isLoading} onClick={next}>
          {isLoading ? "Creating Token" : "Create a Token"}
        </NextButton>
      </ButtonFooter>
    </StyledModal>
  );
}

const LeftAlign = styled.div`
  text-align: left;
`;

const ProxySetupSteps = styled.ol``;
const ProxySetupStep = styled.li(
  ({ theme }) => css`
    color: ${theme.colorBase600};
    font: ${theme.fontStudioBodyCopyRegularShortHand};
    letter-spacing: ${theme.fontStudioBodyCopyRegularLetterSpacing};

    margin-top: 1rem;
  `,
);

const ProxyIconWrapper = styled.div(
  () => css`
    background: rgba(255, 255, 255, 80%);
    border-radius: 50%;
    height: 120px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
);
