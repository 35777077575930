import { useHandler } from "@fiberplane/hooks";
import { useEffect, useMemo } from "react";
import useUpdate from "react-use/lib/useUpdate";

import type { Timestamp } from "../types";

type PreferredNotebook = {
  updatedAt: Timestamp;
  notebookId: string;
  workspaceName: string;
};

const PREFERRED_ID_KEY = "preferredNotebookId";

type ReturnValue = {
  updatePreference(workspaceName: string, notebookId: string): void;
  resetPreference(): void;
  preference?: Pick<PreferredNotebook, "notebookId" | "workspaceName">;
};

/**
 * This hook should only be used once per tab. If it's used multiple times in the same tab,
 * then the preference value can get out of sync with what's in storage (this is because storage
 * events don't get triggered by changes to storage from code that's running in the current tab)
 */
export function useNotebookPreference(): ReturnValue {
  const update = useUpdate();
  const updatePreference = useHandler(
    (workspaceName: string, notebookId: string) => {
      const date = new Date();
      localStorage.setItem(
        PREFERRED_ID_KEY,
        JSON.stringify({
          updatedAt: date.toISOString(),
          notebookId,
          workspaceName,
        }),
      );
    },
  );

  const resetPreference = useHandler(() => {
    localStorage.removeItem(PREFERRED_ID_KEY);
    update();
  });

  let workspaceName: string | undefined;
  let notebookId: string | undefined;

  try {
    const data = localStorage.getItem(PREFERRED_ID_KEY);
    const preference = data && JSON.parse(data);

    if (
      isPreferredNotebook(preference) &&
      preference.updatedAt > new Date(Date.now() - 30 * 60 * 1000).toISOString()
    ) {
      workspaceName = preference.workspaceName;
      notebookId = preference.notebookId;
    }
  } catch (error: unknown) {
    console.warn(
      "Error handling local storage information for preferred notebook id",
      error,
    );
  }

  const handleStorageEvent = useHandler((event: StorageEvent) => {
    if (event.key === PREFERRED_ID_KEY) {
      update();
    }
  });

  useEffect(() => {
    window.addEventListener("storage", handleStorageEvent);

    return () => window.removeEventListener("storage", handleStorageEvent);
  }, [handleStorageEvent]);

  const preference = useMemo(() => {
    if (notebookId && workspaceName) {
      return {
        notebookId,
        workspaceName,
      };
    }
  }, [notebookId, workspaceName]);

  return {
    updatePreference,
    resetPreference,
    preference,
  };
}

function isPreferredNotebook(data: unknown): data is PreferredNotebook {
  return (
    data !== null &&
    typeof data === "object" &&
    "updatedAt" in data &&
    typeof data.updatedAt === "string" &&
    "notebookId" in data &&
    typeof data.notebookId === "string" &&
    "workspaceName" in data &&
    typeof data.workspaceName === "string"
  );
}
