import { useEffect, useState } from "react";

import { getQueryField } from "../../../../utils";

export type UseQueryDataMonitorProps = {
  queryData: string | undefined;
  fields: Array<string>;
  onChanged: () => void;
};

export function useQueryDataMonitor({
  queryData,
  fields,
  onChanged,
}: UseQueryDataMonitorProps) {
  const [currentData, setCurrentData] = useState(queryData);

  useEffect(() => {
    const hasChanges = fields.some((field) => {
      return (
        getQueryField(queryData, field) !== getQueryField(currentData, field)
      );
    });

    if (hasChanges) {
      setCurrentData(queryData);
      onChanged();
    }
  }, [onChanged, currentData, fields, queryData]);
}
