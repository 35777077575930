import { css, styled } from "styled-components";

import { StatusIndicator, type StatusIndicatorProps } from "./StatusIndicator";

type Props = StatusIndicatorProps & {
  error?: string;
};

export function LargeStatusIndicator(props: Props): JSX.Element {
  return (
    <StyledLabel aria-label={props.error}>
      <StatusIndicator status={props.status} />
      <StyledTextOnline>
        {props.status === "connected" ? "online" : "offline"}
      </StyledTextOnline>
    </StyledLabel>
  );
}

const StyledLabel = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    background: ${theme.color.bg.elevated.default};
    border-radius: ${theme.radius.minimal};
    height: 22px;
    padding: 0 10px;
  `,
);

const StyledTextOnline = styled.span(
  ({ theme }) => css`
    flex-grow: 0;
    color: ${theme.color.fg.default};
    font: ${theme.font.body.sm.regular};
    display: none;

    @media (min-width: 500px) {
      display: inline;
    }
  `,
);
