import { CodeSample } from "../../UI";
import { ModalBodyRegularText } from "./common";

type InstructionProps = {
  token: string;
};

// Local
// https://docs.fiberplane.com/docs/run-locally
export const LOCAL_DATA_SOURCES_YAML = `
# data_sources.yaml
#
# Replace the following line with the name of the data source
# The data source name will appear in the Fiberplane Studio
- name: prometheus-prod
  description: Prometheus (Production)
  providerType: prometheus
  config:
    # Replace the following line with your Prometheus URL
    url: http://prometheus
# Consult the docs to see how to use other data sources
# https://docs.fiberplane.com/docs/setting-up-providers 
`.trim();

export const LOCAL_FPD_RUN = "./fpd --token <token>";

export function LocalInstructions({ token }: InstructionProps) {
  return (
    <>
      <ModalBodyRegularText>
        Download the Fiberplane Daemon and Provider binaries as explained in{" "}
        <a
          href="https://docs.fiberplane.com/docs/run-locally"
          target="_blank"
          rel="noopener noreferrer"
        >
          our docs
        </a>
        , and unzip them in a directory. In that same directory, create a
        data_sources.yaml file with the following format:
      </ModalBodyRegularText>
      <CodeSample code={LOCAL_DATA_SOURCES_YAML} />

      <ModalBodyRegularText>Run the following command:</ModalBodyRegularText>
      <CodeSample code={LOCAL_FPD_RUN.replace("<token>", token)} />

      <ModalBodyRegularText>
        Once you complete your FPD setup, your data sources linked in the
        configuration should be recognized by the Studio. You can verify this by
        going to the Data Sources section of Settings.
      </ModalBodyRegularText>
    </>
  );
}

// Docker
// https://docs.fiberplane.com/docs/deploy-to-docker
export const DOCKER_DATA_SOURCES_YAML = `
# data_sources.yaml
#
# Replace the following line with the name of the data source
# The data source name will appear in the Fiberplane Studio
- name: prometheus-prod
  description: Prometheus (Production)
  providerType: prometheus
  config:
    # Replace the following line with your Prometheus URL
    url: http://prometheus
# Consult the docs to see how to use other data sources
# https://docs.fiberplane.com/docs/setting-up-providers  
`.trim();

export const DOCKER_RUN = `docker run \\
  -v "$PWD/data_sources.yaml:/app/data_sources.yaml" \\
  fiberplane/fpd:v2 \\
  --token=<token>`;

export function DockerInstructions({ token }: InstructionProps) {
  return (
    <>
      <ModalBodyRegularText>
        Create a data_sources.yaml file in the current directory with the
        following format:
      </ModalBodyRegularText>
      <CodeSample code={DOCKER_DATA_SOURCES_YAML} />

      <ModalBodyRegularText>Run the following command:</ModalBodyRegularText>
      <CodeSample code={DOCKER_RUN.replace("<token>", token)} />

      <ModalBodyRegularText>
        Once you complete your FPD setup, your data sources linked in the
        configuration should be recognized by the Studio. You can verify this by
        going to the Data Sources section of Settings.
      </ModalBodyRegularText>
    </>
  );
}

// Kubernetes
// https://docs.fiberplane.com/docs/deploy-to-kubernetes
export const K8S_CONFIGMAP = `
# configmap.yaml

---
apiVersion: v1
kind: ConfigMap
metadata:
  name: fiberplane-daemon
data:
  data_sources.yaml: |
    # Change this file to point to your actual data source(s)

    # You can add data sources using this format:
    # Data Source Name:
    #   type: prometheus
    #   options:
    #     url: your prometheus URL

    # Consult the docs to see how to use other data sources
    # https://docs.fiberplane.com/docs/setting-up-providers

    # The data source name will appear in the Fiberplane Studio
   - name: prometheus-prod
		 description: Prometheus (Production)
		 providerType: prometheus
		 config:
	     # Replace the following line with your Prometheus URL
	     url: http://prometheus
`.trim();

export const K8S_DEPLOYMENT = `
# deployment.yaml

---
apiVersion: apps/v1
kind: Deployment
metadata:
  name: fiberplane-daemon
  labels:
    app: fiberplane-daemon
spec:
  replicas: 1
  selector:
    matchLabels:
      app: fiberplane-daemon
  template:
    metadata:
      labels:
        app: fiberplane-daemon
    spec:
      containers:
      - name: fiberplane-daemon
        image: "fiberplane/fpd:v2"
        env:
          - name: TOKEN
            value: "<token>" # <-- This is your FPD token we just created
          - name: DATA_SOURCES_PATH
            value: /app/config/data_sources.yaml
          - name: RUST_LOG
            value: fpd=debug
        volumeMounts:
          - name: data-sources
            mountPath: /app/config/data_sources.yaml
            subPath: data_sources.yaml
      volumes:
        - name: data-sources
          configMap:
            # Provide the name of the ConfigMap containing the files you want
            # to add to the container
            name: fiberplane-daemon
            items:
              - key: data_sources.yaml
                path: data_sources.yaml
`.trim();

export const K8S_KUBE_CTL = `
kubectl apply -f configmap.yml
kubectl apply -f deployment.yml
`.trim();

export function KubernetesInstructions({ token }: InstructionProps) {
  return (
    <>
      <ModalBodyRegularText>
        Create a configmap.yaml Kubernetes configuration file and change the
        Prometheus URL below to point to the Prometheus instance(s) inside your
        cluster
      </ModalBodyRegularText>
      <CodeSample code={K8S_CONFIGMAP} />
      <ModalBodyRegularText>
        Create the Kubernetes deployment file. We&rsquo;ve added the FPD API
        Token for you:
      </ModalBodyRegularText>
      <CodeSample code={K8S_DEPLOYMENT.replace("<token>", token)} />
      <ModalBodyRegularText>
        Apply the changes to your Kubernetes cluster by running the following
        commands:
      </ModalBodyRegularText>
      <CodeSample code={K8S_KUBE_CTL} />
      <ModalBodyRegularText>
        Once you complete your FPD setup, your data sources linked in the
        configuration should be recognized by the Studio. You can verify this by
        going to the Data Sources section of Settings.
      </ModalBodyRegularText>
    </>
  );
}
