import { decode, encode } from "@msgpack/msgpack";

import {
  EVENTS_MIME_TYPE,
  EVENTS_QUERY_TYPE,
  LOGS_PAGE_SIZE,
} from "../../constants";
import type {
  Blob,
  Cell,
  LegacyProviderRequest,
  LegacyProviderResponse,
  ProviderError,
  ProviderEvent,
  ProviderRequest,
  Result,
  SupportedQueryType,
} from "../../types";
import {
  formatProviderType,
  getQueryField,
  parseTimeRange,
  secondsToTimestamp,
  timestampToSeconds,
  toQueryData,
} from "../../utils";

const LIVE_PARAM_NAME = "live";
const QUERY_PARAM_NAME = "query";
const TIME_RANGE_PARAM_NAME = "time_range";

export function getSupportedQueryTypesForLegacyLogProvider(
  providerType: string,
): Array<SupportedQueryType> {
  const providerName = formatProviderType(providerType);

  return [
    {
      label: `${providerName} query`,
      queryType: EVENTS_QUERY_TYPE,
      schema: [
        {
          type: "text",
          name: QUERY_PARAM_NAME,
          label: `Enter your ${providerName} query`,
          multiline: false,
          placeholder: "",
          prerequisites: [],
          required: true,
          supportsSuggestions: false,
          multiple: false,
        },
        {
          type: "date_time_range",
          name: TIME_RANGE_PARAM_NAME,
          label: "Specify a time range",
          placeholder: "",
          required: true,
        },
        {
          type: "checkbox",
          name: LIVE_PARAM_NAME,
          label: "Enable live mode",
          checked: false,
          required: false,
          value: "true",
        },
      ],
      mimeTypes: [EVENTS_MIME_TYPE],
    },
  ];
}

export function createLogOutputCells(): Array<Cell> {
  return [
    {
      type: "log",
      id: "log",
      dataLinks: [`cell-data:${EVENTS_MIME_TYPE},self`],
    },
  ];
}

export function createLegacyLogRequest(
  providerRequest: ProviderRequest,
): LegacyProviderRequest {
  const queryData = toQueryData(providerRequest.queryData);
  const timeRange = parseTimeRange(
    getQueryField(queryData, TIME_RANGE_PARAM_NAME),
  );
  if (!timeRange) {
    throw new Error("No time range in query data");
  }

  return {
    type: "logs",
    query: getQueryField(queryData, QUERY_PARAM_NAME),
    timeRange: {
      from: timestampToSeconds(timeRange.from),
      to: timestampToSeconds(timeRange.to),
    },
    limit: LOGS_PAGE_SIZE,
  };
}

export function interpretLegacyLogResponse(
  response: Uint8Array,
): Result<Blob, ProviderError> {
  const legacyResponse = decode(response) as LegacyProviderResponse;
  if (legacyResponse.type !== "log_records") {
    return {
      // biome-ignore lint/style/useNamingConvention: the Result concept comes from rust and follows that naming convention
      Err: {
        type: "deserialization",
        message: `Unrecognized response type: ${legacyResponse.type}`,
      },
    };
  }

  const emptyObject = {};

  const data: Array<ProviderEvent> = legacyResponse.logRecords.map(
    (record) => ({
      time: secondsToTimestamp(record.timestamp),
      title: record.body,
      attributes: record.attributes,
      labels: emptyObject,
      resource: record.resource,
      spanId: record.spanId,
      traceId: record.traceId,
    }),
  );

  return {
    // biome-ignore lint/style/useNamingConvention: the Result concept comes from rust and follows that naming convention
    Ok: { data: encode(data), mimeType: `${EVENTS_MIME_TYPE}+msgpack` },
  };
}
