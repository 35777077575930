import { Button, Icon, Tab, TabList } from "@fiberplane/ui";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  selectActiveIntegration,
  selectActiveWorkspaceNameOrThrow,
  selectCurrentUser,
} from "../../../../../selectors";
import {
  type Integration,
  IntegrationType,
  connectIntegration,
  loadIntegrationById,
  unloadIntegration,
} from "../../../../../slices";
import { dispatch } from "../../../../../store";
import { createSettingsSectionUri } from "../../../../../thunks";
import { noop } from "../../../../../utils";
import { IntegrationIcon } from "./IntegrationIcon";
import { ReceiverList } from "./PagerDuty";
import { getIntegrationProfile } from "./utils";

type IntegrationDetailsProps = Pick<Integration, "id" | "type">;

function updateOverlayQueryParams(params: URLSearchParams, overlay?: string) {
  const newParams = new URLSearchParams(params);
  if (overlay !== undefined) {
    newParams.set("overlay", overlay);
  } else {
    newParams.delete("overlay");
  }

  return newParams;
}

export function IntegrationDetails({ id, type }: IntegrationDetailsProps) {
  const [activeTab, setActiveTab] = useState<"overview" | "config">("overview");

  const user = useSelector(selectCurrentUser);
  const workspaceName = useSelector(selectActiveWorkspaceNameOrThrow);

  const { loading, data } = useSelector(selectActiveIntegration);

  useEffect(() => {
    const promise = dispatch(loadIntegrationById({ id, type }));

    return () => {
      promise.abort();

      dispatch(unloadIntegration());
    };
  }, [id, type]);

  // TODO: Decided to disable disconnect for now
  // We only support Github atm and it's linked to your personal account,
  // disconnecting can introduce issues related to gh only accounts that need another
  // method of auth etc
  // const onDisconnectRequested = useHandler(() => {
  //   const onConfirm = async () => {
  //     await dispatch(disconnectIntegration(selectedIntegrationId));
  //     await dispatch(loadIntegrationById(selectedIntegrationId));
  //   };
  //
  //   dispatch(
  //     showConfirmationDialog("disconnect", {
  //       children:
  //         "Integration functionality will stop and related settings will be deleted.",
  //       iconVisual: "danger",
  //       title: "Are you sure you want to disconnect?",
  //       onConfirmLabel: "Disconnect",
  //       onConfirm,
  //     }),
  //   );
  // });

  const [params] = useSearchParams();

  if (!data) {
    return <div>404</div>;
  }

  const profile = getIntegrationProfile(data);
  const isEnabled = data.status.type !== "disconnected";

  return (
    <Container>
      <BackButton
        to={`?${updateOverlayQueryParams(
          params,
          createSettingsSectionUri(
            type === IntegrationType.Personal
              ? "personalIntegrations"
              : "workspaceIntegrations",
          ),
        ).toString()}`}
        replace
      >
        <Icon iconType="caret_left" />
        <span>Back</span>
      </BackButton>
      <Header>
        <StyledIntegrationIcon integrationId={data.id} />
        <IntegrationName>{profile.name}</IntegrationName>
        {isEnabled && user ? (
          data.status.type === "attention_required" ? (
            <ActionButton buttonStyle="secondary" onClick={noop}>
              <Icon iconType="warning_octagon" />
              Action needed
            </ActionButton>
          ) : (
            <>
              {!loading && (
                <>
                  <IntegrationStatus>
                    Connected as{" "}
                    {type === "personal" ? user.name : workspaceName}
                  </IntegrationStatus>
                  {/*
                  <ActionButton
                    buttonStyle="secondary"
                    onClick={onDisconnectRequested}
                  >
                    Disconnect
                  </ActionButton>
                  */}
                </>
              )}
            </>
          )
        ) : (
          <ActionButton
            buttonStyle="secondary"
            onClick={() => dispatch(connectIntegration({ type, id: data.id }))}
          >
            {data.id === "pagerdutywebhook" ? "Create receiver" : "Connect"}
          </ActionButton>
        )}
      </Header>
      <TabList layoutId="integrationDetailTabList">
        <Tab
          alias="overview"
          isActive={activeTab === "overview"}
          onClick={() => setActiveTab("overview")}
        >
          Overview
        </Tab>
        {profile.id === "pagerdutywebhook" && (
          <Tab
            alias="config"
            isActive={activeTab === "config"}
            onClick={() => setActiveTab("config")}
          >
            Configure
          </Tab>
        )}
      </TabList>
      {activeTab === "overview" && (
        <>
          <Section>
            <SectionDescription>{profile.description}</SectionDescription>
          </Section>
          <CoverImageContainer>
            <CoverImage src={profile.coverImage} />
          </CoverImageContainer>
          {profile.gettingStarted && (
            <Section>
              <SectionName>Getting started</SectionName>
              <SectionDescription>{profile.gettingStarted}</SectionDescription>
            </Section>
          )}
        </>
      )}
      {activeTab === "config" && profile.id === "pagerdutywebhook" && (
        <ReceiverList />
      )}
    </Container>
  );
}

const Container = styled.div(
  () => css`
    display: grid;
    gap: 20px;
  `,
);

const BackButton = styled(Link)(
  ({ theme }) => css`
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    color: ${theme.color.fg.muted};
    font: ${theme.font.headings.h5};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  `,
);

const IntegrationStatus = styled.span(
  ({ theme }) => css`
    grid-area: status;
    color: ${theme.color.fg.muted};
    font: ${theme.font.body.sm.regular};
  `,
);

const Header = styled.header(
  () => css`
    display: grid;
    grid: "icon name action" auto / auto 1fr auto;
    gap: 0 16px;
    align-items: center;

    &:has(${IntegrationStatus}) {
      grid:
        "icon name action" auto
        "icon status action" auto
        / auto 1fr auto;
    }
  `,
);

const StyledIntegrationIcon = styled(IntegrationIcon)(
  () => css`
    grid-area: icon;
  `,
);

const IntegrationName = styled.strong(
  ({ theme }) => css`
    grid-area: name;
    color: ${theme.color.fg.default};
    font: ${theme.font.body.xl.regular};
    font-weight: 700;
    line-height: 24px; /* 120% */
  `,
);

const ActionButton = styled(Button)(
  () => css`
    grid-area: action;
  `,
);

const Section = styled.section`
  gap: 8px;
`;

const SectionName = styled.strong(
  ({ theme }) => css`
    color: ${theme.color.fg.default};
    font: ${theme.font.headings.h5};
  `,
);

const SectionDescription = styled.p(
  ({ theme }) => css`
    margin: 0;

    color: ${theme.color.fg.muted};
    font: ${theme.font.body.md.regular};
  `,
);

const CoverImageContainer = styled.div(
  ({ theme }) => css`
    max-width: 730px;
    aspect-ratio: 73 / 43;
    border-radius: ${theme.radius.rounded};
    background: ${theme.color.primary.brand[100]};
    content: " ";
  `,
);
const CoverImage = styled.img`
  max-width: 100%;
`;
