import { useEffect, useMemo, useRef } from "react";
import { css, styled, useTheme } from "styled-components";

import { Sentry } from "../../../services";
import { NoiseCanvas } from "./NoiseCanvas";
import fragment from "./shader.frag";
import vertex from "./shader.vert";

type PlasmaBackgroundProps = {
  position?: "top" | "bottom";
};

/**
 * Renders a plasma effect background with a selection of theme colors. It's
 * absolute positioned, fully covers the parent container it's placed in and has
 * a z-index of 0, so take this into consideration when adding the background
 * into a container element.
 *
 * @param position either "top" or "bottom" determining the location from where
 * the plasma effect takes place.
 */
export function PlasmaBackground({ position }: PlasmaBackgroundProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const startTime = useMemo(() => Date.now(), []);
  const theme = useTheme();

  // This color ramp is used to map the calculated plasma values (that go from
  // 0..1) to a nice color ramp. The last value of the ramp should be identical
  // to the first one to prevent any discontinuities.
  const colorRamp: Array<[number, number, number]> = useMemo(
    () => [
      parseColor(theme.colorSupport1400),
      parseColor(theme.colorPrimary500),
      parseColor(theme.colorSupport1400),
      parseColor(theme.colorPrimary600),
    ],
    [theme.colorPrimary500, theme.colorPrimary600, theme.colorSupport1400],
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    const gl = canvas?.getContext("webgl2");

    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
    if (!canvas || !gl) {
      return;
    }

    // When calculating the plasma pixel values, make sure we keep the aspect
    // ratio of the screen in mind
    const aspect = canvas.width / canvas.height;

    // This is how many pixels we're actually rendering, that will get stretched
    // out across the canvas container
    canvas.width = 256 * aspect;
    canvas.height = 256;

    gl.viewport(0, 0, canvas.width, canvas.height);

    const program = gl.createProgram();
    if (!program) {
      Sentry.captureError("Couldn't create WebGL program");
      throw new Error("Couldn't create WebGL program");
    }

    gl.attachShader(program, createShader(gl, vertex, gl.VERTEX_SHADER));
    gl.attachShader(program, createShader(gl, fragment, gl.FRAGMENT_SHADER));
    gl.linkProgram(program);

    const timeUniform = gl.getUniformLocation(program, "time");
    const sizeUniform = gl.getUniformLocation(program, "size");

    gl.useProgram(program);

    const texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.REPEAT);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.REPEAT);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.bindTexture(gl.TEXTURE_2D, texture);

    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGB,
      colorRamp.length,
      1,
      0,
      gl.RGB,
      gl.UNSIGNED_BYTE,
      new Uint8Array(colorRamp.flat()),
    );
    gl.uniform2f(sizeUniform, canvas.width, canvas.height);

    let raf: number | undefined;
    const update = () => {
      // Adjust speed of animation here
      gl.uniform1f(timeUniform, (Date.now() - startTime) * 0.01);
      gl.drawArrays(gl.TRIANGLE_FAN, 0, 3);

      raf = window.requestAnimationFrame(() => {
        update();
      });
    };

    update();

    return () => {
      if (raf) {
        window.cancelAnimationFrame(raf);
      }
    };
  }, [colorRamp, startTime]);

  return (
    <>
      <BackgroundCanvas ref={canvasRef} position={position} />
      <NoiseCanvas />
    </>
  );
}

const BackgroundCanvas = styled.canvas<PlasmaBackgroundProps>`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  inset: 0;

  ${({ position }) =>
    position === "bottom" &&
    css`
      transform: rotate(180deg);
    `}
`;

function parseColor(hex: string): [number, number, number] {
  const result = /^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i.exec(hex);
  return [
    Number.parseInt(result?.[1] ?? "00", 16),
    Number.parseInt(result?.[2] ?? "00", 16),
    Number.parseInt(result?.[3] ?? "00", 16),
  ];
}

function createShader(
  gl: WebGL2RenderingContext,
  source: string,
  type: GLenum,
) {
  const shader = gl.createShader(type);
  if (!shader) {
    Sentry.captureError("Couldn't create WebGL shader");
    throw new Error("Couldn't create WebGL shader");
  }

  gl.shaderSource(shader, source);
  gl.compileShader(shader);
  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    throw new Error(
      gl.getShaderInfoLog(shader) ??
        "Unknown error when compiling WebGL shader",
    );
  }
  return shader;
}
