import { useState } from "react";
import { styled } from "styled-components";

import { cancelEvent } from "@fiberplane/ui";
import { applyOwnOperation, withActiveNotebook } from "../../../actions";
import { useFilePaste } from "../../../hooks";
import {
  selectCell,
  selectNotebookFocus,
  selectRelativeCellOrSurrogate,
} from "../../../selectors";
import { dispatch, getState } from "../../../store";
import { focusCell } from "../../../thunks";
import { type TableCell, isContentCell } from "../../../types";
import {
  formatTableRowValueId,
  getFocusPosition,
  getUniqueColumnId,
  getUniqueRowId,
  last,
  parseTableRowValueId,
} from "../../../utils";
import { TableCellRow } from "./TableCellRow";
import { TableColumnHeading } from "./TableColumnHeading";
import { AppendColumnButton, AppendRowButton } from "./appendButtons";
import { headingRowMarker, noFormatting, noHover } from "./constants";
import { useSelectedColumnId } from "./hooks";
import { focusField } from "./utils";

type Props = {
  cell: { type: "table" } & TableCell;
  readOnly?: boolean;
};

export function TableCellContent({ cell }: Props): JSX.Element {
  const { id: cellId, columnDefs, readOnly = false, rows } = cell;

  const [hoveredField, setHoveredField] = useState("");
  const [hoveredRowId, hoveredColumnId] = hoveredField
    ? parseTableRowValueId(hoveredField)
    : noHover;

  const onPaste = useFilePaste(cellId);
  const selectedColumnId = useSelectedColumnId(cellId);

  const table = (
    <Table
      onKeyDown={handleKeyDown}
      onMouseLeave={() => setHoveredField("")}
      onPaste={onPaste}
    >
      <TableContent columnCount={columnDefs.length}>
        {columnDefs.map(({ id: columnId, title }, index) => (
          <TableColumnHeading
            cellId={cellId}
            columnId={columnId}
            firstColumn={index === 0}
            hovered={
              columnId === hoveredColumnId && hoveredRowId === headingRowMarker
            }
            key={columnId}
            onHover={() =>
              setHoveredField(formatTableRowValueId(headingRowMarker, columnId))
            }
            selected={columnId === selectedColumnId}
          >
            {title}
          </TableColumnHeading>
        ))}
        {rows.map((row, index) => (
          <TableCellRow
            cellId={cellId}
            columnDefs={columnDefs}
            first={index === 0}
            hoveredColumnId={hoveredColumnId}
            hoveredRowId={hoveredRowId}
            key={row.id}
            onHover={setHoveredField}
            readOnly={readOnly}
            row={row}
          />
        ))}
      </TableContent>
    </Table>
  );

  if (readOnly) {
    return table;
  }

  return (
    <TableContainer>
      {table}
      <AppendRowButton onClick={() => appendRow(cellId)} />
      <AppendColumnButton
        onClick={() => appendColumn(cellId)}
        visible={hoveredColumnId === last(columnDefs)?.id}
      />
    </TableContainer>
  );
}

function appendRow(cellId: string) {
  const cell = selectCell(getState(), cellId);
  if (cell?.type !== "table") {
    return;
  }

  dispatch(
    withActiveNotebook(
      applyOwnOperation({
        type: "insert_table_row",
        cellId,
        index: cell.rows.length,
        row: {
          id: getUniqueRowId(cell),
          values: cell.columnDefs.map(() => ({
            type: "text",
            text: "",
            formatting: noFormatting,
          })),
        },
      }),
    ),
  );
}

function appendColumn(cellId: string) {
  const cell = selectCell(getState(), cellId);
  if (cell?.type !== "table") {
    return;
  }

  const index = cell.columnDefs.length;

  dispatch(
    withActiveNotebook(
      applyOwnOperation({
        type: "insert_table_column",
        cellId,
        index,
        columnDef: {
          id: getUniqueColumnId(cell),
          title: `Column ${String.fromCharCode(65 + index)}`,
        },
        values: cell.rows.map(() => ({
          type: "text",
          text: "",
          formatting: noFormatting,
        })),
      }),
    ),
  );
}

function handleKeyDown(event: React.KeyboardEvent) {
  switch (event.key) {
    case "Enter": {
      if (!event.shiftKey) {
        cancelEvent(event);
        return handleTab(1);
      }
      break;
    }

    case "Tab": {
      cancelEvent(event);
      return handleTab(event.shiftKey ? -1 : 1);
    }
  }
}

function handleTab(delta: 1 | -1) {
  const state = getState();
  const focusPosition = getFocusPosition(selectNotebookFocus(state));
  if (!focusPosition) {
    return;
  }

  const { cellId, field } = focusPosition;
  const cell = selectCell(getState(), cellId);
  if (cell?.type !== "table") {
    return;
  }

  if (!field) {
    return handleTabOnUnknownField(cell, delta);
  }

  const [rowId, columnId] = parseTableRowValueId(field);
  const rowIndex = cell.rows.findIndex((row) => row.id === rowId);
  const columnIndex = cell.columnDefs.findIndex(
    (columnDef) => columnDef.id === columnId,
  );
  if (rowIndex === -1 || columnIndex === -1) {
    return handleTabOnUnknownField(cell, delta);
  }

  const newColumn = cell.columnDefs[columnIndex + delta];
  if (newColumn) {
    return focusField(cellId, formatTableRowValueId(rowId, newColumn.id), {
      offset: 0,
    });
  }

  const newRow = cell.rows[rowIndex + delta];
  if (newRow) {
    const newColumn = delta === 1 ? cell.columnDefs[0] : last(cell.columnDefs);
    if (!newColumn) {
      return handleTabOnUnknownField(cell, delta);
    }

    return focusField(cellId, formatTableRowValueId(newRow.id, newColumn.id), {
      offset: 0,
    });
  }

  const newCell = selectRelativeCellOrSurrogate(state, cellId, delta);
  if (!newCell) {
    return; // Don't know where to go anymore...
  }

  dispatch(
    focusCell({
      cellId: newCell.id,
      offset: isContentCell(newCell) ? 0 : undefined,
    }),
  );
}

function handleTabOnUnknownField(cell: TableCell, delta: 1 | -1) {
  const row = delta === 1 ? cell.rows[0] : last(cell.rows);
  const columnDef = delta === 1 ? cell.columnDefs[0] : last(cell.columnDefs);
  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
  if (!row || !columnDef) {
    return; // No field for us to move the focus to.
  }

  focusField(cell.id, formatTableRowValueId(row.id, columnDef.id), {
    offset: 0,
  });
}

const TableContainer = styled.div`
  width: 100%;
`;

const Table = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colorBase300};
  border-radius: 6px;
  margin: 0;
  overflow: auto visible;
  position: relative;
`;

const TableContent = styled.div<{ columnCount: number }>`
  grid-template-columns: repeat(${({ columnCount }) =>
    `${columnCount}, minmax(max-content, 50%)`});
  display: grid;
  margin: 0;
  font: ${({ theme }) => theme.fontNotebooksSmallerShortHand};
  min-width: fit-content;
  flex: 1;
  border-collapse: collapse;
`;
