import { useHandler } from "@fiberplane/hooks";
import { Icon } from "@fiberplane/ui";
import type React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { push } from "redux-first-history";
import { css, styled } from "styled-components";

import { ROUTES } from "../../constants";
import { useQuery } from "../../hooks";
import { selectIsAuthenticated } from "../../selectors";
import { useAppDispatch } from "../../store";
import { authenticate } from "../../thunks";
import { page } from "../../utils";
import { CookieBanner } from "../CookieBanner";
import { glassBackground } from "../UI";
import { FeatureList } from "./FeatureList";
import "./bodyOverride.css";
import { GithubLogoIcon, GoogleLogoIcon } from "./icons";

export function SignIn(): JSX.Element {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const dispatch = useAppDispatch();

  const params = useQuery();
  const returnUrl = params.get("redirect") || undefined;
  const cliRedirectPort = params.get("cli_redirect_port") || undefined;

  useEffect(() => {
    page();
  }, []);

  useEffect(() => {
    // cliRedirectPort is used to auth fp CLI, in this case we still need to
    // allow user to pick which provider to use for that
    if (isAuthenticated && !cliRedirectPort) {
      dispatch(push(ROUTES.Root));
    }
  });

  const handleKeyDownGoogle = useHandler(
    (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (event.key === "Enter" || event.key === " ") {
        event.preventDefault();
        dispatch(authenticate("google", { returnUrl, cliRedirectPort }));
      }
    },
  );

  const handleKeyDownGithub = useHandler(
    (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (event.key === "Enter" || event.key === " ") {
        event.preventDefault();
        dispatch(authenticate("google", { returnUrl, cliRedirectPort }));
      }
    },
  );

  return (
    <SignInWrapper>
      <SignInContent>
        <Icon iconType="fiberplane_logo_type" width="100%" height={50} />

        <SignInTitle>
          {cliRedirectPort ? "Redirecting to FP" : "Get started for free"}
        </SignInTitle>

        <SignInButton
          onClick={() =>
            dispatch(authenticate("google", { returnUrl, cliRedirectPort }))
          }
          onKeyDown={handleKeyDownGoogle}
        >
          <GoogleLogoIcon />
          Continue with Google
        </SignInButton>

        <SignInButton
          onClick={() =>
            dispatch(authenticate("github", { returnUrl, cliRedirectPort }))
          }
          onKeyDown={handleKeyDownGithub}
        >
          <GithubLogoIcon />
          Continue with GitHub
        </SignInButton>
      </SignInContent>

      <FeatureList />

      <Footer>
        <Anchor
          href="https://fiberplane.com/privacy"
          target="_blank"
          rel="noopener"
        >
          Privacy
        </Anchor>
        Fiberplane B.V. &copy; 2024
      </Footer>

      <CookieBanner />
    </SignInWrapper>
  );
}

const SignInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 1024px) {
    display: grid;
    grid: 1fr auto / 1fr 1fr;
    height: 100%;
  }
`;

const SignInTitle = styled.h1(
  ({ theme }) => css`
    margin: 0;
    text-align: center;
    font: ${theme.fontStudioHeadingsH2ShortHand};
    letter-spacing: ${theme.fontStudioHeadingsH1LetterSpacing};
  `,
);

/* stylelint-disable scale-unlimited/declaration-strict-value */
const SignInButton = styled.button(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    height: 40px;
    border: 1px solid ${theme.colorBase300};
    border-radius: ${theme.borderRadius500};
    outline: none;
    box-shadow: 0 2px 4px 1px rgb(0 0 0 / 5%);
    font: normal 500 14px / 1 Roboto, sans-serif;
    background-color: ${theme.colorBackground};
    color: ${theme.colorForeground};
    transition: background-color 0.1s ease-in-out;

    &:focus,
    &:hover,
    &:active,
    .active {
      cursor: pointer;
      background-color: ${theme.colorBase100};
    }

    &:focus {
      outline: rgba(66, 133, 244, 50%) 2px solid;
    }
  `,
);
/* stylelint-enable scale-unlimited/declaration-strict-value */

const SignInContent = styled.div`
  display: grid;
  grid-row-gap: 24px;
  width: 100%;
  max-width: 330px;
  margin: 140px 16px;
  place-self: center;

  ${/* sc-selector */ SignInButton}:first-of-type {
    margin-top: 24px;
  }
`;

const Footer = styled.footer(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    padding: 8px 0;
    width: 100%;
    font: ${theme.fontStudioBodyCopySmallShortHand};
    letter-spacing: ${theme.fontStudioBodyCopySmallLetterSpacing};
    gap: 20px;
    opacity: 0.6;
    color: ${theme.colorBase700};

    ${glassBackground};

    @media (min-width: 1024px) {
      position: fixed;
      bottom: 0;
      z-index: 2;
    }
  `,
);

const Anchor = styled.a(
  ({ theme }) => css`
    text-decoration: none;
    color: ${theme.colorForeground};

    &:hover {
      color: ${theme.colorBase700};
    }
  `,
);
