import type { Label, LoadableData } from "../types";

export type LabelsEditorState = {
  /**
   * The original of the label we're editing.
   *
   * Remains `undefined` in the case we are adding a label.
   */
  readonly original?: Readonly<Label>;

  /**
   * Label that is being added or edited, as a plain string.
   */
  readonly draft: string;

  /**
   * Suggestions to display while adding/editing a label.
   */
  readonly suggestions: LoadableData<{
    readonly keys: ReadonlyArray<string>;
    readonly values: ReadonlyArray<string>;
  }>;

  /**
   * The label key we're fetching suggestions for.
   */
  readonly suggestionsKey: string;
};
