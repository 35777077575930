import { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { focusable } from "tabbable";

type Props = { children: React.ReactNode; active: boolean };

export function PageElement({ active, children }: Props) {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const focusDelay = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (ref && active) {
      const [element] = focusable(ref);
      if (element) {
        focusDelay.current = setTimeout(() => {
          element.focus();
          focusDelay.current = undefined;
        }, 200);
      }
    }
  }, [active, ref]);

  useEffect(() => {
    return () => {
      clearTimeout(focusDelay.current);
    };
  }, []);

  return (
    <StyledContainer
      ref={setRef}
      active={active}
      tabIndex={active ? undefined : -1}
    >
      {children}
    </StyledContainer>
  );
}

const StyledContainer = styled.div<Pick<Props, "active">>`
  flex: 0 0 100%;
  pointer-events: ${({ active }) => (active ? "auto" : "none")};
`;
