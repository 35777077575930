import { styled } from "styled-components";

export const ErrorContainer = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  background: ${({ theme }) => theme.colorBase200};
  border-radius: ${({ theme }) => theme.borderRadius500};
  font: ${({ theme }) => theme.fontStudioBodyCopyRegularShortHand};
`;
