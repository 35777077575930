import { Button, Icon } from "@fiberplane/ui";
import { useContext, useState } from "react";
import { css, styled } from "styled-components";

import { ModalContext } from "../../UI";
import {
  BackButton,
  ButtonFooter,
  IllustrationContainer,
  ModalClose,
  ModalHeading,
  NextButton,
  PageIndicator,
  PageIndicatorList,
  StyledModal,
  StyledModalContent,
} from "./common";
import {
  DockerInstructions,
  KubernetesInstructions,
  LocalInstructions,
} from "./instructions";

type Props = { token: string; back: () => void };

export function ProxyDeployInstructions({ token, back }: Props) {
  const { requestClose } = useContext(ModalContext);

  const {
    isKubernetesVisible,
    showKubernetes,
    isDockerVisible,
    showDocker,
    isLocalVisible,
    showLocal,
  } = useInstallationMethods();

  return (
    <StyledModal>
      <StyledModalContent gradient="deploy">
        <IllustrationContainer>
          <ModalClose />
          <CheckIconWrapper>
            <Icon iconType="proxy" height="70" width="70" />
          </CheckIconWrapper>
        </IllustrationContainer>
        <ModalHeading>Deploy Your FPD instance</ModalHeading>
        <InstallationMethodTabs>
          <InstallationMethodTab
            buttonStyle={isKubernetesVisible ? "primary" : "secondary"}
            onClick={showKubernetes}
          >
            Kubernetes
          </InstallationMethodTab>
          <InstallationMethodTab
            buttonStyle={isDockerVisible ? "primary" : "secondary"}
            onClick={showDocker}
          >
            Docker
          </InstallationMethodTab>{" "}
          <InstallationMethodTab
            buttonStyle={isLocalVisible ? "primary" : "secondary"}
            onClick={showLocal}
          >
            Local
          </InstallationMethodTab>
        </InstallationMethodTabs>
        <InstallationMethodBody>
          {isKubernetesVisible && <KubernetesInstructions token={token} />}
          {isDockerVisible && <DockerInstructions token={token} />}{" "}
          {isLocalVisible && <LocalInstructions token={token} />}
        </InstallationMethodBody>
      </StyledModalContent>
      <ButtonFooter>
        <BackButton onClick={back} buttonStyle="secondary">
          Back
        </BackButton>
        <PageIndicatorList>
          <PageIndicator />
          <PageIndicator />
          <PageIndicator $active />
        </PageIndicatorList>
        <NextButton onClick={requestClose}>Done</NextButton>
      </ButtonFooter>
    </StyledModal>
  );
}

function useInstallationMethods() {
  const k8s = "kubernetes";
  const docker = "docker";
  const local = "local";

  const [selected, setSelected] = useState(k8s);

  return {
    isKubernetesVisible: selected === k8s,
    showKubernetes: () => setSelected(k8s),
    isDockerVisible: selected === docker,
    showDocker: () => setSelected(docker),
    isLocalVisible: selected === local,
    showLocal: () => setSelected(local),
  };
}

const InstallationMethodTabs = styled.div`
  background: ${({ theme }) => theme.colorBase300};
  display: grid;
  grid: 1fr / 1fr 1fr 1fr;
  margin: 0 4rem;
  border-radius: 100px;
`;

const InstallationMethodTab = styled(Button)`
  border-radius: 100px;
`;

// HACK - without the max-width here, the modal will expand horizontally! even though the modal container has max-width:600px
//        (i set the max-width to match that of the modal)
const InstallationMethodBody = styled.div`
  max-width: 600px;
  padding: 24px;
  text-align: left;
`;

const CheckIconWrapper = styled.div(
  ({ theme }) => css`
    background: ${theme.colorBackground};
    border-radius: 32px;
    height: 120px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
);
