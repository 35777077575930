import { Button, Icon } from "@fiberplane/ui";
import { css, styled } from "styled-components";

import { useFeature } from "../../../hooks";
import { dispatch } from "../../../store";
import { showSignOutConfirmation } from "../../../thunks";
import type { Feature, Setting } from "../../../types";
import { BetaBadge } from "../../UI";

type MenuListItemProps = React.PropsWithChildren<{
  setting: Setting;
  isActive: boolean;
  onClick: () => void;
}>;

export function MenuListItem({
  children,
  isActive,
  onClick,
  setting,
}: MenuListItemProps) {
  return (
    <ListItem>
      <MenuButton
        $isActive={isActive}
        buttonStyle="tertiary-grey"
        onClick={onClick}
        data-menu-item-id={setting.section}
      >
        <Icon iconType={setting.iconType} />
        {setting.title}
        {children}
      </MenuButton>
    </ListItem>
  );
}

type MenuFeatureListItemProps = MenuListItemProps & {
  feature: Feature;
};

/**
 * Same as MenuListItem, but only renders if the feature is enabled. As we can't
 * call hooks conditionally, we need to use a separate component.
 */
export function MenuFeatureListItem({
  feature,
  ...restProps
}: MenuFeatureListItemProps) {
  const [hasFeatureEnabled] = useFeature(feature);
  if (!hasFeatureEnabled) {
    return null;
  }

  return (
    <MenuListItem {...restProps}>
      <BetaBadge />
    </MenuListItem>
  );
}

export function MenuSignOutListItem() {
  return (
    <SignOut>
      <MenuButton
        buttonStyle="tertiary-grey"
        onClick={() => {
          dispatch(showSignOutConfirmation());
        }}
        data-menu-item-id="signout"
      >
        <Icon iconType="sign_out" />
        Log out
      </MenuButton>
    </SignOut>
  );
}

const ListItem = styled.li`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const SignOut = styled(ListItem)`
  padding-top: 8px;
  border-top: 1px solid ${({ theme }) => theme.color.border.default};
`;

/**
 * These buttons should be links, but we're using buttons now because
 * @fiberplane/ui buttons doesn't support links...
 */
const MenuButton = styled(Button)<{ $isActive?: boolean }>(
  ({ $isActive, theme }) => css`
    padding: 4px 8px;
    width: 100%;
    min-height: auto;
    justify-content: flex-start;

    ${
      $isActive &&
      css`
        color: ${theme.color.fg.default};
        background-color: ${theme.color.bg.hover};
      `
    }
  `,
);
