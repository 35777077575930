import type { VirtualElement } from "@popperjs/core";
import { AnimatePresence } from "framer-motion";
import { useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { closeContextMenu, withActiveNotebook } from "../../../actions";
import { makeNotebookContextMenuSelector } from "../../../selectors";
import { dispatch } from "../../../store";
import type {
  NotebookContextMenuInfo,
  NotebookContextMenuType,
  Rect,
} from "../../../types";
import { LabelSuggestionsMenu } from "../../ContextMenu";
import { getCursorRectForCellFieldAtOffset } from "../RTE";
import { AtMenu } from "./AtMenu";
import { AutoSuggestionsMenu } from "./AutoSuggestionsMenu";
import { CellActionsMenu } from "./CellActionsMenu";
import { DatePickerMenu } from "./DatePickerMenu";
import { SlashCommandMenu } from "./SlashCommandMenu";

const MENU_OFFSET: [number, number] = [0, 3];

type Props = {
  cellId: string;
};

export function ContextMenu({ cellId }: Props): JSX.Element {
  const selectContextMenu = useMemo(
    () => makeNotebookContextMenuSelector(cellId),
    [cellId],
  );
  const contextMenu = useSelector(selectContextMenu);

  return (
    <AnimatePresence>
      {contextMenu && <PositionedContextMenu {...contextMenu} />}
    </AnimatePresence>
  );
}

function PositionedContextMenu(
  contextMenu: NotebookContextMenuInfo,
): JSX.Element {
  const {
    cellId,
    cursorRect,
    field,
    menuType,
    typeAheadOffset,
    typeAheadText,
  } = contextMenu;

  // Tracks the virtual element that serves as anchor to position the menu:
  const [element, setElement] = useState<VirtualElement | null>(null);
  useLayoutEffect(() => {
    setElement(getPositionElement(cellId, field, typeAheadOffset, cursorRect));
  }, [cellId, cursorRect, field, typeAheadOffset]);

  const MenuComponent = getMenuComponent(menuType);
  return (
    <MenuComponent
      contextMenu={contextMenu}
      element={element}
      filterText={typeAheadText}
      offset={MENU_OFFSET}
      onClose={closeMenu}
    />
  );
}

function closeMenu() {
  dispatch(withActiveNotebook(closeContextMenu()));
}

function getMenuComponent(menuType: NotebookContextMenuType) {
  switch (menuType) {
    case "at_menu":
      return AtMenu;
    case "auto_suggest":
      return AutoSuggestionsMenu;
    case "auto_suggest_labels":
      return LabelSuggestionsMenu;
    case "cell_actions":
      return CellActionsMenu;
    case "date_picker":
      return DatePickerMenu;
    case "slash_command":
      return SlashCommandMenu;
  }
}

function getPositionElement(
  cellId: string,
  field: string | undefined,
  offset: number | undefined,
  cursorRect: Rect | undefined,
): VirtualElement | null {
  if (cursorRect) {
    return {
      getBoundingClientRect() {
        return new DOMRect(
          cursorRect.x,
          cursorRect.y,
          cursorRect.width,
          cursorRect.height,
        );
      },
    };
  }

  if (offset === undefined) {
    return null;
  }

  const rect = getCursorRectForCellFieldAtOffset(cellId, field, offset);
  return (
    rect && {
      getBoundingClientRect: () =>
        new DOMRect(rect.x, rect.y, rect.width, rect.height),
    }
  );
}
