import {
  MetricsChart,
  type SeriesSource,
  type ToggleTimeseriesEvent,
} from "@fiberplane/charts";
import { useHandler } from "@fiberplane/hooks";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  toggleTimeseriesVisibility,
  withActiveNotebook,
} from "../../../actions";
import {
  useChartTheme,
  useFilePaste,
  useProvider,
  useTickFormatters,
} from "../../../hooks";
import { makeCellSelector, selectNotebookTimeRange } from "../../../selectors";
import { dispatch } from "../../../store";
import {
  showTooltip as showTooltipAction,
  updateCell,
  updateNotebookTimeRange,
  updateQueryField,
} from "../../../thunks";
import type {
  GraphCell,
  GraphType,
  Metric,
  ProviderCell,
  ProviderEvent,
  QuerySchema,
  StackingType,
  TimeRange,
  TooltipAnchor,
} from "../../../types";
import {
  formatTimeRange,
  getParentCellId,
  getQueryField,
  getTimeRangeFieldName,
  noop,
  parseIntent,
  parseTimeRange,
} from "../../../utils";
import { ChartPlaceholder } from "./ChartPlaceholder";
import { EventTooltipContent, TimeseriesTooltipContent } from "./Tooltip";
import { useTimeseriesData } from "./useTimeseriesData";

type Props = {
  cell: GraphCell;
  readOnly?: boolean;
};

export function GraphCellContent({ cell, readOnly }: Props) {
  const providerCellId = getParentCellId(cell.id);
  const providerCellSelector = useMemo(
    () => (providerCellId ? makeCellSelector(providerCellId) : () => undefined),
    [providerCellId],
  );
  const providerCell = useSelector(providerCellSelector);

  // If the graph cell is embedded in a provider cell, we use it to extract
  // the time range from it. Otherwise, we directly render
  // `<GraphCellContentWithTimeRange />` with an undefined time range.
  return providerCell?.type === "provider" ? (
    <GraphCellContentWithProviderCell
      cell={cell}
      providerCell={providerCell}
      readOnly={readOnly}
    />
  ) : (
    <GraphCellContentWithTimeRange
      cell={cell}
      readOnly={readOnly}
      timeRange={undefined}
    />
  );
}

function GraphCellContentWithProviderCell({
  cell,
  providerCell,
  readOnly,
}: Props & { providerCell: ProviderCell }) {
  const intent = useMemo(
    () => parseIntent(providerCell.intent),
    [providerCell],
  );

  const providerResult = useProvider(intent);
  if (!providerResult || providerResult instanceof Error) {
    return <ChartPlaceholder error={providerResult} />;
  }

  return (
    <GraphCellContentWithProviderCellAndSchema
      cell={cell}
      providerCell={providerCell}
      readOnly={readOnly}
      schema={providerResult.schema}
    />
  );
}

function GraphCellContentWithProviderCellAndSchema({
  cell,
  providerCell,
  readOnly,
  schema,
}: Props & { providerCell: ProviderCell; schema: QuerySchema }) {
  const timeRangeFieldName = getTimeRangeFieldName(schema);
  const timeRangeString = useMemo(
    () =>
      timeRangeFieldName
        ? getQueryField(providerCell.queryData, timeRangeFieldName)
        : undefined,
    [providerCell, timeRangeFieldName],
  );
  const timeRange = useMemo(
    () => (timeRangeString ? parseTimeRange(timeRangeString) : undefined),
    [timeRangeString],
  );

  return (
    <GraphCellContentWithTimeRange
      cell={cell}
      providerCell={providerCell}
      readOnly={readOnly}
      timeRange={timeRange}
      timeRangeFieldName={timeRangeFieldName}
    />
  );
}

function GraphCellContentWithTimeRange({
  cell,
  providerCell,
  readOnly,
  timeRange,
  timeRangeFieldName,
}: Props & {
  providerCell?: ProviderCell;
  timeRange: TimeRange | undefined;
  timeRangeFieldName?: string;
}) {
  const cellId = cell.id;
  const globalTimeRange = useSelector(selectNotebookTimeRange);
  const chartTheme = useChartTheme();

  const onPaste = useFilePaste(cellId);

  const timeseriesData = useTimeseriesData(cell);

  const onChangeTimeRange = useHandler((newTimeRange: TimeRange | null) => {
    const useGlobalTime = !timeRange;
    if (providerCell && timeRangeFieldName && !useGlobalTime) {
      dispatch(
        updateQueryField(
          providerCell.id,
          timeRangeFieldName,
          newTimeRange ? formatTimeRange(newTimeRange) : null,
          { autoSubmit: true },
        ),
      );
    } else if (newTimeRange) {
      dispatch(updateNotebookTimeRange(newTimeRange));
    }
  });

  const onChangeGraphType = useHandler((graphType: GraphType) => {
    dispatch(
      updateCell(
        cellId,
        { graphType },
        { focus: { type: "collapsed", cellId } },
      ),
    );
  });

  const onChangeStackingType = useHandler((stackingType: StackingType) => {
    dispatch(
      updateCell(
        cellId,
        { stackingType },
        { focus: { type: "collapsed", cellId } },
      ),
    );
  });

  const onToggleTimeseriesVisibility = useHandler(
    (event: ToggleTimeseriesEvent) => {
      dispatch(
        withActiveNotebook(
          toggleTimeseriesVisibility({
            cellId: getParentCellId(cellId) ?? cellId,
            seriesLabels: event.timeseries.labels,
            seriesName: event.timeseries.name,
            toggleOthers: event.toggleOthers,
          }),
        ),
      );
    },
  );

  const hasMultipleSeries = timeseriesData.length > 1;

  const tickFormattersFactory = useTickFormatters(cell.stackingType);

  const chart = (
    <MetricsChart
      chartTheme={chartTheme}
      graphType={cell.graphType}
      onChangeGraphType={readOnly ? undefined : onChangeGraphType}
      onChangeStackingType={readOnly ? undefined : onChangeStackingType}
      onChangeTimeRange={readOnly ? undefined : onChangeTimeRange}
      onToggleTimeseriesVisibility={
        readOnly ? undefined : onToggleTimeseriesVisibility
      }
      readOnly={readOnly}
      showTooltip={showTooltip}
      stackingType={cell.stackingType}
      tickFormatters={tickFormattersFactory}
      timeseriesData={timeseriesData}
      timeRange={timeRange || globalTimeRange}
      legendShown
      stackingControlsShown={hasMultipleSeries}
    />
  );

  return providerCell ? chart : <div onPaste={onPaste}>{chart}</div>;
}

function showTooltip(
  anchor: TooltipAnchor,
  [series, point]: [SeriesSource, Metric | ProviderEvent | null],
) {
  if (series.type === "target_latency") {
    return noop;
  }

  const content =
    series.type === "events" ? (
      <EventTooltipContent event={point as ProviderEvent} />
    ) : (
      <TimeseriesTooltipContent timeseries={series} metric={point as Metric} />
    );

  return dispatch(showTooltipAction(anchor, content));
}
