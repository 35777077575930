import cardImage1 from "url:./images/1.png";
import cardImage2 from "url:./images/2.png";
import cardImage3 from "url:./images/3.png";
import { useSelector } from "react-redux";
import useMedia from "react-use/lib/useMedia";
import { css, styled } from "styled-components";

import { MEDIUM_SIZE_QUERY } from "../../constants";
import { selectSideMenuIsOpen } from "../../selectors";
import { dispatch } from "../../store";
import { showModal } from "../../thunks";
import { LazilyLoadedImage } from "../UI";

export function GettingStartedCardContent() {
  const mediumSize = useMedia(MEDIUM_SIZE_QUERY);
  const isMenuOpen = useSelector(selectSideMenuIsOpen) ?? mediumSize;

  return (
    <GettingStartedCardContentContainer isMenuOpen={isMenuOpen}>
      <ClickableCard
        onClick={() => dispatch(showModal({ type: "installCli" }))}
      >
        <LazilyLoadedCardImage src={cardImage1} />
        <CardTitle>Install the Fiberplane CLI</CardTitle>
        <CardStepIndicatorContainer>
          <CardStepIndicator>{1}</CardStepIndicator>
        </CardStepIndicatorContainer>
      </ClickableCard>
      <ClickableCard onClick={() => dispatch(showModal({ type: "addProxy" }))}>
        <LazilyLoadedCardImage src={cardImage2} />
        <CardTitle>Connect your Data</CardTitle>
        <CardStepIndicatorContainer>
          <CardStepIndicator>{2}</CardStepIndicator>
        </CardStepIndicatorContainer>
      </ClickableCard>
      <ClickableCard
        onClick={() => dispatch(showModal({ type: "exploreNotebooks" }))}
      >
        <LazilyLoadedCardImage src={cardImage3} />
        <CardTitle>Explore Notebooks</CardTitle>
        <CardStepIndicatorContainer>
          <CardStepIndicator>{3}</CardStepIndicator>
        </CardStepIndicatorContainer>
      </ClickableCard>
    </GettingStartedCardContentContainer>
  );
}

const GettingStartedCardContentContainer = styled.div<{ isMenuOpen: boolean }>(
  ({ isMenuOpen }) => css`
    display: grid;
    grid-auto-rows: minmax(144px, auto);
    gap: 12px;

    @media (min-width: 428px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media ${MEDIUM_SIZE_QUERY} {
      grid-template-columns: repeat(${isMenuOpen ? 1 : 2}, 1fr);
    }

    @media (min-width: 768px) {
      grid-template-columns: repeat(${isMenuOpen ? 2 : 3}, 1fr);
    }

    @media (min-width: 926px) {
      grid-template-columns: repeat(3, 1fr);
    }
  `,
);

const ClickableCard = styled.button(
  ({ theme }) => css`
    border: none;
    position: relative;
    overflow: hidden;
    border-radius: ${({ theme }) => theme.radius.default};
    z-index: 0;
    display: grid;
    align-content: flex-end;
    padding: 32px 20px 20px;
    box-shadow: ${theme.effect.shadow.xxs};
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.2s;
    text-align: left;
    text-decoration: none;

    &:hover {
      transform: scale(1.03);
    }
  `,
);

const CardTitle = styled.h3(
  ({ theme }) => css`
    color: ${theme.color.fg.onemphasis.default};
    font-size: 20px;
    line-height: 1.4;
    margin: 0;
  `,
);

const CardStepIndicatorContainer = styled.div(
  ({ theme }) => css`
    aspect-ratio: 1;
    background-color: ${theme.color.bg.emphasis.neutral};
    border-radius: ${theme.radius.full};
    position: absolute;
    top: 12px;
    right: 12px;
    height: 20px;
    width: 20px;
    display: grid;
    justify-content: center;
    align-items: center;
  `,
);

const CardStepIndicator = styled.p(
  ({ theme }) => css`
    font: ${theme.font.body.sm.medium};
    color: ${theme.color.fg.onemphasis.default};
    margin: 0;
  `,
);

const LazilyLoadedCardImage = styled(LazilyLoadedImage)`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
  z-index: -1;
`;
