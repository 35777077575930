import { AttachedPopup, Icon } from "@fiberplane/ui";
import type { VirtualElement } from "@popperjs/core";
/* stylelint-disable scale-unlimited/declaration-strict-value */
import { css, styled } from "styled-components";

export type ToolbarProps = {
  element: HTMLElement | VirtualElement | null;
  children: React.ReactNode;
};

export function Toolbar({ element, children }: ToolbarProps) {
  return (
    <AttachedPopup element={element} placement="top-start">
      <Offset>
        <ToolbarComponent>{children}</ToolbarComponent>
      </Offset>
    </AttachedPopup>
  );
}

export const ToolbarButton = styled.button.attrs<{
  "data-toolbar-button"?: boolean;
}>({
  "data-toolbar-button": true,
})(
  ({ theme }) => css`
  appearance: none;
  border: none;
  margin: 0;

  display: flex;
  align-items: center;
  gap: 4px;

  border-radius: ${theme.radius.lowest};
  padding: 2px;
  padding-right: 8px;
  background: ${theme.color.bg.elevated.default};
  color: ${theme.color.fg.default};

  &:hover {
    cursor: pointer;
    background: ${theme.color.bg.elevated.hover};
  }

  &:active {
    background: ${theme.color.bg.emphasis.neutral};
  }

  &:disabled {
    background: inherit;
    color: ${theme.color.fg.muted};

    &:hover,
    &:active {
      background: inherit;
      color: ${theme.color.fg.muted};
      cursor: not-allowed;
    }
  }
`,
);

export const ToolbarIcon = styled(Icon)`
  color: inherit;
  width: 20px;
`;

const ToolbarComponent = styled.nav(
  ({ theme }) => css`
  display: flex;
  align-items: center;
  padding: 5px 14px;
  color: ${theme.color.fg.default};
  background: ${theme.color.bg.elevated.default};
  border: 1px solid ${theme.color.border.muted};
  box-sizing: border-box;
  box-shadow: ${theme.effect.shadow.s};
  border-radius: ${theme.radius.lowest};
`,
);

const Offset = styled.div`
  padding-bottom: 6px;
`;
