import { IconButton } from "@fiberplane/ui";
import type { MouseEventHandler } from "react";

import { shakeClassName } from "../useShake";

type CellErrorIconProps = {
  onClick: MouseEventHandler<HTMLElement>;
  title?: string;
};

export function CellErrorIcon(props: CellErrorIconProps) {
  const { onClick, title } = props;

  return (
    <IconButton
      iconType="warning_circle"
      className={shakeClassName}
      onClick={onClick}
      buttonStyle="danger"
      title={title}
    />
  );
}
