import { keyframes, styled } from "styled-components";

import { ResizeHandler } from "./ResizeHandler";
import { useDragCrop, useResizeDrag } from "./hooks";
import type { CropPosition, Direction } from "./types";

const Container = styled.div<{
  width: number;
  height: number;
  direction: Direction | undefined;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: ${({ width }) => `${-0.5 * width}px`};
  margin-top: ${({ height }) => `${-0.5 * height}px`};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  cursor: ${({ direction }) => (direction ? `${direction}-resize` : "unset")};
`;

const dashAnimation = keyframes`
  0% {
    stroke-dashoffset: 8px;
  }

  100% {
    stroke-dashoffset: 0;
  }
`;

const DragRect = styled.rect<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? "unset" : "move")};
  stroke-dasharray: 4px;
  stroke-dashoffset: 4px;
  animation: ${dashAnimation} 0.5s linear forwards infinite;
`;

/**
 * OVerlay is the component to be on top of the original image. It provides the UI for both resizing
 * and scaling of the avatar cut out.
 * @param props
 */
export function Overlay(props: {
  width: number;
  height: number;
  left: number;
  top: number;
  scale: number;
  updatePosition(position: Omit<CropPosition, "scale">): void;
  setState(position: CropPosition): void;
}): JSX.Element {
  const { width, height, left, top, scale, updatePosition, setState } = props;

  const minDimension = Math.min(width, height);
  const percentageWidth = (minDimension / width) * scale;
  const percentageHeight = (minDimension / height) * scale;

  const x = left * 100;
  const y = top * 100;

  const currentWidth = percentageWidth * 100;
  const currentHeight = percentageHeight * 100;

  const { dragStart, containerRef } = useDragCrop<SVGSVGElement>(
    width,
    height,
    updatePosition,
  );

  const resize = useResizeDrag<HTMLDivElement, HTMLButtonElement>(
    width,
    height,
    setState,
  );

  return (
    <Container
      width={width}
      height={height}
      ref={resize.containerRef}
      direction={resize.direction}
    >
      <ResizeHandler
        left={x}
        top={y}
        scale={scale}
        anchor="nw"
        onMouseDown={resize.dragStart}
      />
      <ResizeHandler
        left={x + currentWidth}
        top={y}
        scale={scale}
        anchor="ne"
        onMouseDown={resize.dragStart}
      />
      <ResizeHandler
        anchor="se"
        left={x + currentWidth}
        top={y + currentHeight}
        scale={scale}
        onMouseDown={resize.dragStart}
      />
      <ResizeHandler
        left={x}
        top={y + currentHeight}
        scale={scale}
        anchor="sw"
        onMouseDown={resize.dragStart}
      />
      <svg width={width} height={height} ref={containerRef}>
        <title>Image cropper</title>
        <defs>
          <rect
            id="inner"
            x={`${x}%`}
            y={`${y}%`}
            rx={scale * minDimension * 0.43}
            ry={scale * minDimension * 0.43}
            width={`${currentWidth}%`}
            height={`${currentHeight}%`}
          />
        </defs>
        <mask id="clip">
          <rect x="0" y="0" width="100%" height="100%" fill="White" />
          <use xlinkHref="#inner" fill="Black" />
        </mask>
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          fill="#000000A0"
          mask="url(#clip)"
        />
        <DragRect
          x={`${x}%`}
          y={`${y}%`}
          disabled={!!resize.direction}
          width={`${currentWidth}%`}
          height={`${currentHeight}%`}
          fill="transparent"
          stroke="white"
          data-drag="true"
          onMouseDown={dragStart}
        />
      </svg>
    </Container>
  );
}
