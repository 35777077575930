import { Icon } from "@fiberplane/ui";
import { AnimatePresence, motion } from "framer-motion";
import { css, keyframes, styled } from "styled-components";

import { useThrottledLoadingState } from "../../../hooks";

type PageTitleProps = {
  children: React.ReactNode;
  className?: string;
  loading?: boolean;
  $noMargin?: boolean;
};

export function PageTitle({ children, loading, ...props }: PageTitleProps) {
  const isLoadingThrottled = useThrottledLoadingState(!!loading, 1500);

  return (
    <PageTitleContainer {...props}>
      {children}
      <AnimatePresence>
        {isLoadingThrottled && (
          <LoadingIconContainer>
            <LoadingIcon iconType="arrow_clockwise" />
          </LoadingIconContainer>
        )}
      </AnimatePresence>
    </PageTitleContainer>
  );
}

const iconAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const PageTitleContainer = styled.h1<{ $noMargin?: boolean }>`
  grid-area: title;
  margin-bottom: 0;
  ${({ $noMargin }) => $noMargin && "margin-top: 0;"}
  display: flex;
  align-items: flex-end;
`;

export const PageSubTitle = styled.h3(
  ({ theme }) => css`
    grid-area: subtitle;
    color: ${theme.color.fg.muted};
    font: ${theme.font.body.md.regular};
    margin: 0;
  `,
);

const LoadingIconContainer = styled(motion.span).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})`
  display: inline-flex;
  margin-bottom: 6px;
`;

const LoadingIcon = styled(Icon)`
  animation: ${iconAnimation} 1s infinite;
  margin-left: 0.25em;
`;
