import { Input } from "@fiberplane/ui";
import {
  type ForwardedRef,
  type InputHTMLAttributes,
  forwardRef,
  useState,
} from "react";
import { css, styled } from "styled-components";
import { omit } from "../../../utils";

const ICON_SIZE = 16;
const LINE_HEIGHT = 16;
const PADDING_Y = 8;
const PADDING_X = 12;
const PREFIX_PADDING = 7;

const iconStyle: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  marginTop: ICON_SIZE / -2,
};

export type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  leadingIcon?: React.ElementType<React.SVGProps<SVGSVGElement>>;
  trailingIcon?: React.ElementType<React.SVGProps<SVGSVGElement>>;
};

export const TextInput = forwardRef(function TextInput(
  props: TextInputProps,
  ref: ForwardedRef<HTMLInputElement>,
): JSX.Element {
  const {
    prefix,
    className,
    leadingIcon: LeadingIcon,
    trailingIcon: TrailingIcon,
    style,
    ...rest
  } = props;

  const [prefixRef, setPrefixRef] = useState<HTMLSpanElement | null>(null);

  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
  if (!prefix && !LeadingIcon && !TrailingIcon) {
    return (
      <StyledInput {...rest} className={className} ref={ref} style={style} />
    );
  }

  const innerStyle = style && omit(style as Record<string, unknown>, "margin");

  const prefixStyle = {
    ...iconStyle,
    left: PREFIX_PADDING,
  };

  let paddingLeft = LeadingIcon ? ICON_SIZE + PADDING_X * 2 : PADDING_X;
  if (prefix && prefixRef) {
    paddingLeft += prefixRef.getBoundingClientRect().width - PREFIX_PADDING;
  }

  return (
    <StyledContainer className={className}>
      {LeadingIcon && (
        <LeadingIcon
          height={ICON_SIZE}
          width={ICON_SIZE}
          style={{ ...iconStyle, left: iconStyle.left ?? PADDING_X }}
        />
      )}
      {prefix && (
        <Prefix ref={setPrefixRef} style={prefixStyle}>
          {prefix}
        </Prefix>
      )}
      <StyledInput
        type="text"
        {...rest}
        ref={ref}
        style={{
          ...innerStyle,
          paddingLeft,
          paddingRight: TrailingIcon ? ICON_SIZE + PADDING_X * 2 : PADDING_X,
          verticalAlign: "bottom",
          flex: 1,
        }}
      />
      {TrailingIcon && (
        <TrailingIcon
          height={ICON_SIZE}
          width={ICON_SIZE}
          style={{ ...iconStyle, right: iconStyle.right ?? PADDING_X }}
        />
      )}
    </StyledContainer>
  );
});

const Prefix = styled.span(
  ({ theme }) => css`
    font: ${theme.fontNotebooksSmallShortHand};
    line-height: ${LINE_HEIGHT}px;
    padding: ${PADDING_Y} 0;
    user-select: none;
  `,
);

const StyledContainer = styled.div`
  --placeholderColor: ${({ theme }) => theme.color.input.fg.placeholder};

  position: relative;
  flex: 1;
  display: flex;
  color: var(--placeholderColor);
`;

export const StyledInput = styled(Input).attrs({ type: "text" })``;
