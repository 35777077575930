import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { replace } from "redux-first-history";

import { dispatch } from "../../../store";
import { focusCell } from "../../../thunks";

/**
 * Moves the focus to a cell when it detects the URL ends with a hash that
 * references a cell ID.
 */
export function useCellDeepLinking(cellIds: ReadonlyArray<string>) {
  const location = useLocation();

  useEffect(() => {
    if (location.hash.startsWith("#")) {
      const cellId = location.hash.slice(1);
      if (cellIds.includes(cellId)) {
        dispatch(replace(location.pathname));
        dispatch(focusCell({ cellId, highlight: true }));
      }
    }
  }, [cellIds, location]);
}
