import { EDIT_VIEW } from "../../../actions";
import { selectActiveView, selectViewEditor } from "../../../selectors";
import type { Thunk } from "../../../store";
import type { View } from "../../../types";

const NEW_VIEW: View = {
  id: "",
  name: "",
  displayName: "",
  description: "",
  color: 0,
  labels: [],
  createdAt: "",
  updatedAt: "",
};

export const startEditingIfNeeded = (): Thunk => (dispatch, getState) => {
  const state = getState();

  if (!selectViewEditor(state)) {
    dispatch({
      type: EDIT_VIEW,
      payload: selectActiveView(state) ?? NEW_VIEW,
    });
  }
};
