import type { Label, View } from "../types";

export const ADD_VIEW_EDITOR_LABEL = "views/editor/addLabel";
export const CLEAR_VIEW_EDITOR = "views/editor/clear";
export const EDIT_VIEW = "views/editView";
export const REMOVE_VIEW_EDITOR_LABEL = "views/editor/removeLabel";
export const UPDATE_VIEW_EDITOR_LABEL = "views/editor/updateLabel";

export type ViewsAction =
  | AddViewEditorLabelAction
  | ClearViewEditor
  | EditViewAction
  | RemoveViewEditorLabelAction
  | UpdateViewEditorLabelAction;

type AddViewEditorLabelAction = {
  type: typeof ADD_VIEW_EDITOR_LABEL;
  payload: Label;
};

type ClearViewEditor = { type: typeof CLEAR_VIEW_EDITOR };

type EditViewAction = { type: typeof EDIT_VIEW; payload?: View };

type RemoveViewEditorLabelAction = {
  type: typeof REMOVE_VIEW_EDITOR_LABEL;
  payload: Label;
};

type UpdateViewEditorLabelAction = {
  type: typeof UPDATE_VIEW_EDITOR_LABEL;
  payload: {
    original: Label;
    updated: Label;
  };
};
