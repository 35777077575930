import { useHandler } from "@fiberplane/hooks";
import { Icon } from "@fiberplane/ui";
import { forwardRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

import { useAnalyticsEnabled } from "../../hooks";
import { selectCurrentUser, selectEngageIsOpen } from "../../selectors";
import { dispatch } from "../../store";
import { showModal } from "../../thunks";
import { identify, track } from "../../utils";
import { MenuItem, MenuItemGroup, ToggleMenu } from "../UI";
import { NavButton } from "./NavElement";
import { MENU_OFFSET } from "./common";

const ToggleElement = forwardRef<HTMLDivElement, { opened: boolean }>(
  function ToggleElement({ opened }, ref) {
    useEffect(() => {
      if (!opened) {
        return;
      }

      track("Help menu | opened");
      return () => {
        track("Help menu | closed");
      };
    }, [opened]);

    return (
      <div ref={ref}>
        <NavButton
          as="button"
          $isActive={opened}
          aria-label="Help"
          data-tooltip-placement="right"
          aria-pressed={opened}
        >
          <Icon iconType="question" />
        </NavButton>
      </div>
    );
  },
);

export function Engage() {
  const user = useSelector(selectCurrentUser);
  const thirdPartyAllowed = useAnalyticsEnabled();

  useEffect(() => {
    if (user && thirdPartyAllowed) {
      identify(user.id, {
        // We need to pass in some data otherwise segment wont forward the
        // information to june
        userId: user.id,
      });
    }
  }, [user, thirdPartyAllowed]);

  const shown = useSelector(selectEngageIsOpen);

  useEffect(() => {
    if (!shown) {
      return;
    }

    track("Engage | contact support | open");
    return () => {
      track("Engage | contact support | closed");
    };
  }, [shown]);

  const toggleElement = useHandler(({ anchorRef, opened }) => (
    <ToggleElement opened={opened || shown} ref={anchorRef} />
  ));

  return (
    <StyledToggleMenu
      placement="right-end"
      offset={MENU_OFFSET}
      toggleElement={toggleElement}
    >
      <MenuItemGroup title="Help">
        <MenuItem
          iconLeft={<Icon iconType="book_open" width="16" />}
          id="docs"
          onActivate={async () => {
            await track("Help menu | docs");
            window.open(
              "https://docs.fiberplane.com/",
              "_blank",
              "noopener noreferrer",
            );
          }}
          iconRight={{
            icon: <Icon iconType="arrow_out" width="16" height="16" />,
          }}
          title="Documentation"
        />
        <MenuItem
          iconLeft={<Icon iconType="book_open" width="16" />}
          id="keyboard"
          onActivate={() => {
            track("Help menu | shortcuts");
            dispatch(showModal({ type: "shortcuts" }));
          }}
          title="Keyboard shortcuts"
        />
        <MenuItem
          key="help"
          iconLeft={<Icon iconType="chat_circle" width="16" height="16" />}
          id="help"
          onActivate={async () => {
            await track("Help menu | support");
            window.open(
              "mailto:support@fiberplane.com?subject=Support%20request",
              "_blank",
              "noopener noreferrer",
            );
          }}
          title="Contact support"
        />
        <MenuItem
          id="discord"
          iconLeft={<Icon iconType="discord_logo" width="16" height="16" />}
          onActivate={async () => {
            await track("Help menu | discord");
            window.open(
              "https://discord.gg/MJr7pYzZQ4",
              "_blank",
              "noopener noreferrer",
            );
          }}
          title="Ask on discord"
          iconRight={{
            icon: <Icon iconType="arrow_out" width="16" height="16" />,
          }}
        />
      </MenuItemGroup>
      <MenuItem
        id="terms"
        onActivate={async () => {
          await track("Help menu | terms");
          window.open(
            "https://fiberplane.com/privacy/",
            "_blank",
            "noopener noreferrer",
          );
        }}
        title="Terms & Conditions"
        iconRight={{
          icon: <Icon iconType="arrow_out" width="16" height="16" />,
        }}
      />
    </StyledToggleMenu>
  );
}

const StyledToggleMenu = styled(ToggleMenu)`
  max-height: 400px;
`;
