import { useHandler } from "@fiberplane/hooks";
import { Icon } from "@fiberplane/ui";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { FrontMatterStringValue } from "../../../../../../types";
import { compact, setZeroTimeout } from "../../../../../../utils";
import type { FilterMenuItem } from "../../../../../UI";
import { useFocused } from "../../../hooks";
import {
  FieldContentContainer,
  type ItemSelectorHandle,
  SelectionFrontMatterMenu,
  renderTitle,
} from "../../shared";
import { StringSelectorItem } from "./StringSelectorItem";
import { useStringValuesFromStore } from "./hooks";

type Props = Readonly<{
  id: string;
  onChange: (
    value: Array<string> | undefined,
    options?: { newValue?: FrontMatterStringValue },
  ) => void;
  options: Array<FrontMatterStringValue>;
  readOnly?: boolean;
  allowExtraValues?: boolean;
}>;

/**
 * Front-matter cell that is used to select a string (like for instance a list of affected SLO's)
 * - When in edit mode, uses an input to filter the options
 */
export function FieldStringListMultiple({
  id,
  onChange,
  options,
  readOnly,
  allowExtraValues,
}: Props) {
  const values = useStringValuesFromStore(id, options);
  const focused = useFocused(id);

  const [rawFocusIndex, setFocusIndex] = useState(values.length);
  const focusedIndex = Math.min(rawFocusIndex, values.length);

  const addOrUpdateValue = (index: number, value: string | undefined) => {
    const newValues: Array<string | undefined> = [...values];
    if (index < newValues.length) {
      newValues[index] = value;
    } else {
      newValues.push(value);
    }

    return onChange(compact(newValues));
  };

  const toggleValue = (value: string) => {
    const foundIndex = values.indexOf(value);

    if (foundIndex === -1) {
      addOrUpdateValue(values.length, value);
      if (foundIndex === values.length) {
        setFocusIndex((prev) => prev + 1);
      }
    } else {
      addOrUpdateValue(foundIndex, foundIndex === -1 ? value : undefined);

      if (foundIndex < focusedIndex) {
        setFocusIndex((prev) => Math.max(prev - 1, 0));
      }
    }
  };

  useEffect(() => {
    if (!focused) {
      setFocusIndex(values.length);
    }
  }, [focused, values.length]);

  // Holds a reference to the currently selected user selector
  const selectorRef = useRef<ItemSelectorHandle>(null);

  // This value is used to set the initial filter text
  // for the dropdown. Clicking on an item will set this value
  // to the value of the item clicked.
  const [initialFilterText, setInitialFilterText] = useState<
    string | undefined
  >();

  // biome-ignore lint/correctness/useExhaustiveDependencies: grab focus when the index changed && filterText is undefined
  useEffect(() => {
    // Grab focus back
    if (initialFilterText === undefined && selectorRef.current) {
      selectorRef.current.focus();
    }
  }, [focusedIndex, initialFilterText]);

  // This is a ref is used to determine the position of the dropdown
  const ref = useRef<HTMLDivElement>(null);

  //
  const optionValues = useMemo(() => options, [options]);

  const openContextMenu = (value: string | undefined) => {
    // Use a tiny amount of delay before setting the initial filter text
    // This is useful to avoid the context menu to be closed right after
    // the user clicked on a different user selector
    setZeroTimeout(() => {
      setInitialFilterText(value);
    });
  };

  const onAddAndActivate = useHandler((value: string) => {
    onChange([...values, value], { newValue: value });
  });

  const createExtraMenuItem = useCallback(
    (options: { filterText: string; exactMatchFound: boolean }):
      | FilterMenuItem
      | undefined => {
      if (options.exactMatchFound || options.filterText.trim() === "") {
        return;
      }

      return {
        type: "item_with_icons",
        id: "add-result",
        title: `"${options.filterText}"`,
        renderTitle: () => (
          <>Add new option" {renderTitle(options.filterText)}</>
        ),
        onActivate: () => {
          onAddAndActivate(options.filterText);
        },
        iconRight: {
          icon: <Icon iconType="key_return" />,
          showOnlyOnSelection: true,
        },
      };
    },
    [onAddAndActivate],
  );

  return (
    <FieldContentContainer
      ref={ref}
      onKeyUp={(event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "ArrowLeft") {
          setFocusIndex((prev) => Math.max(prev - 1, 0));
        } else if (event.key === "ArrowRight") {
          setFocusIndex((prev) => Math.min(prev + 1, values.length));
        }
      }}
    >
      {values.map((value, index) => {
        return (
          <StringSelectorItem
            key={value}
            ref={index === focusedIndex ? selectorRef : undefined}
            colorKey={value}
            focused={focused && focusedIndex === index}
            readOnly={readOnly}
            placeholder="Select a user"
            selection={value}
            hideClearButton={!focused}
            openContextMenu={openContextMenu}
            onClear={() => toggleValue(value)}
            onFocus={() => {
              setFocusIndex(index);
            }}
          />
        );
      })}
      {(values.length === 0 || focused) && (
        <StringSelectorItem
          key={values.length}
          placeholder={
            values.length === 0 && !focused ? "Empty" : "Add a value"
          }
          ref={focusedIndex === values.length ? selectorRef : undefined}
          focused={focused && focusedIndex === values.length}
          readOnly={readOnly}
          hideClearButton
          openContextMenu={openContextMenu}
          onClear={() => {
            const lastValue = values[values.length - 1];
            if (lastValue !== undefined) {
              toggleValue(lastValue);
            }
          }}
          onFocus={() => {
            setFocusIndex(values.length);
          }}
        />
      )}
      {initialFilterText !== undefined && (
        <SelectionFrontMatterMenu
          options={optionValues}
          selectedList={values}
          element={ref.current}
          filterText={initialFilterText}
          onActivate={toggleValue}
          onClose={(info) => {
            if (info.reason === "item_activated") {
              return;
            }

            setInitialFilterText(undefined);
          }}
          createExtraMenuItem={
            allowExtraValues ? createExtraMenuItem : undefined
          }
        />
      )}
    </FieldContentContainer>
  );
}

/// Styles
