import { useHandler } from "@fiberplane/hooks";
import { Button, Icon } from "@fiberplane/ui";
import { useSelector } from "react-redux";
import { generatePath } from "react-router";
import { useTitle } from "react-use";
import { push } from "redux-first-history";
import { styled } from "styled-components";

import { useListViewsQuery } from "../../api";
import { ROUTES } from "../../constants";
import { normalizeException } from "../../errors";
import {
  selectActiveWorkspaceIdOrThrow,
  selectActiveWorkspaceNameOrThrow,
  selectCanCreateView,
  selectViewsDisplayType,
} from "../../selectors";
import { dispatch } from "../../store";
import { addNotification } from "../../thunks";
import type { View } from "../../types";
import {
  PageContainer,
  PageContentPlaceholder,
  PageContentPlaceholderCTA,
  PageContentPlaceholderDescription,
  PageContentPlaceholderText,
  PageHeader,
  PageSubTitle,
  PageTitle,
} from "../UI";
import { DisplayTypeSwitch } from "./DisplayTypeSwitch";
import { ViewGrid } from "./ViewGrid";
import { ViewList } from "./ViewList";

const EMPTY_VIEW_LIST: Array<View> = [];

export function Views() {
  useTitle("Views");

  const displayType = useSelector(selectViewsDisplayType);
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const {
    data: views = EMPTY_VIEW_LIST,
    error,
    isFetching,
  } = useListViewsQuery(
    { workspaceId },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const hasViews = views.length > 0;

  const pageProps = {
    "data-testid": "views-page",
    "data-fetching": isFetching,
  };

  if (error) {
    return (
      <PageContainer {...pageProps}>
        <PageContentPlaceholder icon="views">
          <PageContentPlaceholderText>
            {normalizeException(error).message}
          </PageContentPlaceholderText>
        </PageContentPlaceholder>
      </PageContainer>
    );
  }

  return (
    <PageContainer {...pageProps}>
      <ViewsPageHeader>
        <PageTitle loading={isFetching}>Notebook views</PageTitle>
        <PageSubTitle>
          Views are smart collections of notebooks based on notebook labels
        </PageSubTitle>
        {hasViews && (
          <Controls>
            <DisplayTypeSwitch />
            <NewViewButton />
          </Controls>
        )}
      </ViewsPageHeader>
      {
        // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version (which is less readable)
        !isFetching && !hasViews ? (
          <PageContentPlaceholder icon="views">
            <PageContentPlaceholderText>
              You don’t have any notebook views yet
            </PageContentPlaceholderText>
            <PageContentPlaceholderDescription>
              Be the first one to create a view.
            </PageContentPlaceholderDescription>
            <PageContentPlaceholderCTA>
              <NewViewButton />
            </PageContentPlaceholderCTA>
          </PageContentPlaceholder>
        ) : (
          views &&
          (displayType === "grid" ? (
            <ViewGrid views={views} />
          ) : (
            <ViewList views={views} />
          ))
        )
      }
    </PageContainer>
  );
}

function NewViewButton() {
  const workspaceName = useSelector(selectActiveWorkspaceNameOrThrow);
  const canCreateView = useSelector(selectCanCreateView);

  const createAndOpenNewView = useHandler(() => {
    if (!canCreateView) {
      return dispatch(
        addNotification({
          type: "danger",
          title: "You don't have permission to create views in this workspace",
        }),
      );
    }

    dispatch(push(generatePath(ROUTES.NewView, { workspaceName })));
  });

  return (
    <Button onClick={createAndOpenNewView} data-testid="create-view-button">
      <Icon iconType="plus" />
      New view
    </Button>
  );
}

const ViewsPageHeader = styled(PageHeader)`
  grid:
    "title title" auto
    "subtitle controls" auto
    / 1fr auto;
  gap: 12px 20px;

  @media (min-width: 768px) {
    grid:
      "title controls" auto
      "subtitle controls" auto
      / 1fr auto;
  }
`;

const Controls = styled.nav`
  grid-area: controls;
  display: flex;
  align-self: flex-end;
  gap: 10px;
  align-items: center;
`;
