import type {
  NewPagerDutyReceiver,
  PagerDutyReceiver,
  PagerDutyReceiverCreateError,
  PersonalIntegrationSummary,
  UpdatePagerDutyReceiver,
  WorkspaceIntegrationSummary,
} from "../../types";
import { postJsonRequest } from "./client";
import {
  deleteRequest,
  getRequest,
  parseJsonResponse,
  patchJsonRequest,
} from "./utils";

export const listPersonalIntegrations = ({
  signal,
}: {
  signal?: AbortSignal;
}) =>
  getRequest<Array<PersonalIntegrationSummary>>(
    "/api/profile/integrations",
    parseJsonResponse,
    { signal },
  );

export const listWorkspaceIntegrations = ({
  signal,
  workspaceId,
}: {
  signal?: AbortSignal;
  workspaceId: string;
}) =>
  getRequest<Array<WorkspaceIntegrationSummary>>(
    `/api/workspaces/${workspaceId}/integrations`,
    parseJsonResponse,
    { signal },
  );

export const listPagerDutyReceivers = ({
  signal,
  workspaceId,
}: {
  signal?: AbortSignal;
  workspaceId: string;
}) =>
  getRequest<Array<PagerDutyReceiver>>(
    `/api/workspaces/${workspaceId}/pagerduty/receivers`,
    parseJsonResponse,
    { signal },
  );

export const deletePagerDutyReceiver = ({
  name,
  signal,
  workspaceId,
}: {
  name: string;
  signal?: AbortSignal;
  workspaceId: string;
}) =>
  deleteRequest(`/api/workspaces/${workspaceId}/pagerduty/receivers/${name}`, {
    signal,
  });

export const createPagerDutyReceiver = ({
  signal,
  workspaceId,
  newPagerDutyReceiver,
  pagerDutyReceiverName,
}: {
  signal?: AbortSignal;
  workspaceId: string;
  newPagerDutyReceiver: NewPagerDutyReceiver;
  pagerDutyReceiverName: string;
}) =>
  postJsonRequest<
    NewPagerDutyReceiver,
    PagerDutyReceiver,
    PagerDutyReceiverCreateError
  >({
    url: `/api/workspaces/${workspaceId}/pagerduty/receivers/${pagerDutyReceiverName}`,
    body: newPagerDutyReceiver,
    responseHandler: parseJsonResponse,
    options: { signal },
  });

export const updatePagerDutyReceiver = ({
  signal,
  workspaceId,
  name,
  updatePagerDutyReceiver,
}: {
  signal?: AbortSignal;
  workspaceId: string;
  name: PagerDutyReceiver["name"];
  updatePagerDutyReceiver: UpdatePagerDutyReceiver;
}) =>
  patchJsonRequest<PagerDutyReceiver>(
    `/api/workspaces/${workspaceId}/pagerduty/receivers/${name}`,
    updatePagerDutyReceiver,
    parseJsonResponse,
    { signal },
  );

  export const getGithubAppInstallUrl = ({
    workspaceId,
    signal,
  }: {
    workspaceId: string;
    signal?: AbortSignal;
  }) =>
    // TODO - use `SoftRedirect` model
    getRequest<{ location: string }>(
      `/api/workspaces/${workspaceId}/integrations/github/install`,
      parseJsonResponse,
      { signal }
    );