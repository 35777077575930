export const CLEAR_AUTHENTICATION = "authentication/clearAuthentication";
export const STORE_AUTH_TOKEN = "authentication/storeAuthToken";

export type AuthenticationAction =
  | ClearAuthenticationAction
  | StoreAuthTokenAction;

type ClearAuthenticationAction = {
  type: typeof CLEAR_AUTHENTICATION;
};

type StoreAuthTokenAction = {
  payload: string;
  type: typeof STORE_AUTH_TOKEN;
};
