import { type DataAttributes, css, styled } from "styled-components";

import { CellAddIconButton, CellIconButton } from "../UI";
import { CellFocusWithButtonsContainer } from "./CellFocusIndicatorWithButtons";

export const CellGridContainer = styled.div`
  display: grid;
  grid-template-areas: "leftContext main rightContext";
  grid-template-columns: 72px 1fr 72px;
  grid-gap: 20px;
`;

type CellContainerProps = {
  focused: boolean;
  selection?: "start" | "end" | "middle";
};

// As we're using CellContainer for both surrogate and general notebook cells we
// can set the `data-has-focus` attribute here, instead of separately defining
// it on every instance we're using it.
export const CellContainer = styled(CellGridContainer).attrs<
  CellContainerProps & DataAttributes
>(({ focused }) => ({
  "data-has-focus": focused,
}))<CellContainerProps>(
  ({ focused, selection, theme }) => css`
    position: relative;
    transition: background-color 0.1s;

    ${CellIconButton},
    ${CellAddIconButton} {
      transition: opacity 0.1s ease-in-out;
    }

    &:hover {
      ${CellIconButton},
      ${CellAddIconButton} {
        opacity: 1;
        pointer-events: auto;
      }

      ${
        !focused &&
        css`
          ${CellFocusWithButtonsContainer} {
            background-color: ${theme.color.bg.disabled};
          }
        `
      }
    }

    ${
      selection &&
      css`
        background-color: ${theme.color.primary.brand.alpha[50]};

        ${
          selection !== "middle" &&
          css`
          border-radius: ${selection === "end" && "0 0"}
          ${theme.radius.default} ${theme.radius.default}
          ${selection === "start" && "0 0"}};
        `
        }
      `
    }
  `,
);

export const CellLeftContext = styled.div`
  grid-area: leftContext;
  display: flex;
  justify-content: flex-end;
`;

export const CellMain = styled.div<{ $highlight: boolean }>(
  ({ $highlight, theme }) => css`
    grid-area: main;
    width: auto;
    position: relative;
    /* Needs there so we can control overflow in the children */
    min-width: 0;
    align-self: center;

    &:focus {
      outline: none;
    }

    &::after {
      content: "";
      pointer-events: none;
      display: block;
      position: absolute;
      inset: -8px -12px;
      background-color: transparent;
      border-radius: ${theme.radius.default};
      transition: background-color 0.7s ease-in-out;

      ${
        $highlight &&
        css`
          transition: background-color ease-in-out;
          background-color: ${theme.color.border.focus.primary};
        `
      }
    }
  `,
);

export const CellRightContext = styled.div`
  grid-area: rightContext;
  position: relative;
  text-align: left;
`;

export const CellRightContent = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  height: 100%;
`;

export const noOutlineStyling = css`
  outline: none;
`;
