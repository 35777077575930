/* stylelint-disable scale-unlimited/declaration-strict-value */
import { styled } from "styled-components";

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100vw;
  max-width: 460px;
`;

export const InfoLabel = styled.label`
  padding: 10px 0;
  font-weight: bold;
  font-size: 12px;
`;

// TODO: (JF) Refactor form styling/make it generic
export const InfoInput = styled.input`
  display: block;
  padding: 4px 12px;
  box-sizing: border-box;

  color: #383b40;
  border: 1px solid #d2d2d3;
  border-radius: 6px;
  background: #ededed;
  line-height: inherit;

  &::placeholder {
    color: #606266;
  }

  &[type="text"],
  textarea& {
    width: 100%;
    max-width: 100%;
  }

  &:focus {
    border-color: #a4a4a4;
  }

  &:invalid,
  &.error {
    border-color: #ff5a47;
  }
`;

export const StyledFormContainer = styled.div`
  display: flex;
  margin: 32px;
  align-content: center;
  justify-content: center;
  max-width: 100%;
`;

export const StyledForm = styled.form`
  max-width: 100%;
`;
