import { setupListeners } from "@reduxjs/toolkit/query";
import { enableMapSet } from "immer";
import { unstable_batchedUpdates } from "react-dom";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import { api } from "../api";
import {
  consumeScheduledSideEffects,
  createReduxHistory,
  rootReducer,
  routerMiddleware,
} from "../reducers";
import { thunkForDescriptor } from "../thunks";
import { setZeroTimeout } from "../utils";
import { apiAuthMiddleware } from "./middleware";
import type { AppDispatch } from "./types";

// Change `trace` to `true` to enable action specific stack traces on the Redux Dev Tools
const trace = false;
const traceLimit = 25;

// Allow ES6 `Map` and `Set` types to be used in our store:
enableMapSet();

const isProduction = process.env.NODE_ENV === "production";

const composeEnhancers =
  // @ts-ignore
  (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === "function" &&
    !isProduction &&
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace,
      traceLimit,
      serialize: {
        options: { map: true, undefined: true },
        replacer: (_key: string, value: unknown) => {
          // We permit storing mutable values in our UI slice, which includes
          // DOM nodes in some cases. DOM nodes make the Redux DevTools horribly
          // slow however, so we replace them with a constant string here...
          if (value instanceof HTMLElement) {
            return "(HTML element)";
          }

          // Byte arrays would also make serialization too slow.
          if (value instanceof Uint8Array) {
            return "(byte array)";
          }

          if (value instanceof Function) {
            return value.toString();
          }

          return value;
        },
      },
    })) ||
  compose;

let _store: ReturnType<typeof initStore> | undefined;

function initStore() {
  const store = createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware,
        thunk,
        api.middleware,
        apiAuthMiddleware,
      ),
    ),
  );

  // Set up the first event handler for "consuming" side effects
  store.subscribe(() => {
    const sideEffects = consumeScheduledSideEffects();
    setZeroTimeout(() => {
      unstable_batchedUpdates(() => {
        for (const descriptor of sideEffects) {
          dispatch(thunkForDescriptor(descriptor));
        }
      });
    });
  });

  // Required for refetchOnFocus / refetchOnReconnect behaviors
  setupListeners(store.dispatch);
  _store = store;
  return store;
}

export * from "./types";

export { useAppDispatch } from "./dispatch";
export {
  useActiveNotebookDispatch,
  wrapDispatchWithActiveNotebook,
} from "./notebookDispatch";

type StoreType = ReturnType<typeof initStore>;
type DispatchType = StoreType["dispatch"];

const _dispatch = (...args: Parameters<DispatchType>) => {
  if (!_store) {
    return initStore().dispatch(...args);
  }

  return _store.dispatch(...args);
};

export const dispatch = _dispatch as AppDispatch;

export const getState = () => {
  if (!_store) {
    return initStore().getState();
  }

  return _store.getState();
};

export function init() {
  const store = initStore();
  const history = createReduxHistory(store);

  return {
    store,
    history,
  };
}
