import { AttachedPopup } from "@fiberplane/ui";
import { closeContextMenu, replaceText } from "../../../actions";
import { useActiveNotebookDispatch } from "../../../store";
import type { NotebookContextMenuInfo, Timestamp } from "../../../types";
import {
  MenuContainer,
  type PositionedMenuProps,
  TimestampPicker,
} from "../../UI";

type Props = {
  contextMenu: NotebookContextMenuInfo;
} & Omit<PositionedMenuProps, "children">;

export function DatePickerMenu({
  contextMenu,
  element,
  offset,
  placement = "bottom-start",
}: Props): JSX.Element | null {
  const { cellId, field, typeAheadOffset, typeAheadText } = contextMenu;
  const dispatch = useActiveNotebookDispatch();

  const onInsertTimestamp = (timestamp: Timestamp) => {
    const offset = typeAheadOffset ? typeAheadOffset - 1 : 0;

    dispatch(
      replaceText({
        cellId,
        field,
        offset,
        oldText: `@${typeAheadText ?? ""}`,
        newText: `${timestamp} `,
        newFormatting: [{ offset: 0, type: "timestamp", timestamp }],
      }),
    );

    dispatch(closeContextMenu());
  };

  return (
    <AttachedPopup element={element} offset={offset} placement={placement}>
      <MenuContainer>
        <TimestampPicker onChange={onInsertTimestamp} />
      </MenuContainer>
    </AttachedPopup>
  );
}
