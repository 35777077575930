import {
  ADD_NOTIFICATION,
  type AppAction,
  REMOVE_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from "../actions";
import type { Notification, NotificationsState } from "../state";

export const initialState: NotificationsState = new Map<string, Notification>();

export function notificationsReducer(
  state = initialState,
  action: AppAction,
): NotificationsState {
  switch (action.type) {
    case UPDATE_NOTIFICATION:
    case ADD_NOTIFICATION:
      return new Map(state).set(
        action.payload.key,
        action.payload.notification,
      );

    case REMOVE_NOTIFICATION: {
      const next = new Map(state);
      next.delete(action.payload);
      return next;
    }

    default:
      return state;
  }
}
