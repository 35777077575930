import { useMemo, useRef } from "react";
import { useSelector } from "react-redux";

import { makeCellTypeSelector, selectShowOwnCursor } from "../../../selectors";
import type { CellFocus } from "../../../types";
import { getCursorOffset } from "../../../utils";
import { Cursor } from "./Cursor";
import {
  type CellSubscriber,
  useCursorCoordinates,
  useCursorHeight,
  useFocusPosition,
  useFontLoadedState,
  useNotebookContainerWidth,
  usePresenceCursorCoordinates,
} from "./hooks";
import type { ExtendedFormatting } from "./types";

type Props = {
  cellId: string;
  cellSubscribers: Array<CellSubscriber>;
  container: HTMLElement;
  contentRef: React.RefObject<HTMLElement>;
  focus: CellFocus;
  field?: string;
  formatting?: ExtendedFormatting;
  isEditable?: boolean;
  value: string;
};

export function Cursors({
  cellId,
  cellSubscribers,
  container,
  contentRef,
  focus,
  field,
  formatting,
  isEditable,
  value,
}: Props): JSX.Element {
  const cursorRef = useRef<HTMLElement>(null);
  const fontLoading = useFontLoadedState(container);

  const focusOffset = getCursorOffset(focus, field);
  const cursorHeight = useCursorHeight(container, value, focusOffset);

  const cellTypeSelector = useMemo(
    () => makeCellTypeSelector(cellId),
    [cellId],
  );
  const cellType = useSelector(cellTypeSelector);

  const notebookContainerWidth = useNotebookContainerWidth();

  const cursorCoordinates = useCursorCoordinates(
    container,
    value,
    focusOffset,
    formatting,
    fontLoading,
    notebookContainerWidth,
  );

  useFocusPosition(
    cellId,
    contentRef,
    cursorCoordinates,
    cursorRef,
    focus,
    field,
  );

  const presenceCursorCoordinates = usePresenceCursorCoordinates(
    container,
    field,
    cellSubscribers,
    value,
    fontLoading,
    notebookContainerWidth,
  );

  const showOwnCursor = useSelector(selectShowOwnCursor);

  return (
    <>
      {cellType !== "discussion" &&
        presenceCursorCoordinates.map(
          (coordinates, i) =>
            coordinates && (
              <Cursor
                key={i}
                cursorCoordinates={coordinates}
                cursorHeight={cursorHeight}
                user={coordinates.user}
                isEditable={isEditable}
              />
            ),
        )}
      {showOwnCursor && cursorCoordinates && (
        <Cursor
          cursorCoordinates={cursorCoordinates}
          cursorHeight={cursorHeight}
          ref={cursorRef}
          isEditable={isEditable}
        />
      )}
    </>
  );
}
