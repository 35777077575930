import { UPDATE_AVATAR_IMAGE } from "../../actions";
import { userApi, workspacesApi } from "../../api";
import type { AvatarType } from "../../state";
import type { Thunk } from "../../store";
import { addNotification, hideModal, showModal } from "../../thunks";
import type { AvatarUploaderDone } from "../../types";

type AvatarUploaderOptions = {
  id: string;
  avatarType: AvatarType;
  setProgress: (progress: number | undefined) => void;
  onError?: () => void;
};

export const showAvatarUploader =
  ({ id, avatarType, setProgress, onError }: AvatarUploaderOptions): Thunk =>
  (dispatch) => {
    const hide = () => dispatch(hideModal("avatarUploader"));

    const done: AvatarUploaderDone = async (blob) => {
      let lastProgress = 5;
      setProgress(lastProgress);

      const progressInterval = setInterval(() => {
        const stepSize = 0.05;
        lastProgress += stepSize * (100 - lastProgress);
        setProgress(lastProgress);
      }, 100);

      try {
        const picture = new File([blob], "profile.jpg", { type: "image/jpeg" });

        await dispatch(
          avatarType === "profile"
            ? userApi.endpoints.updateProfilePicture.initiate({ picture })
            : workspacesApi.endpoints.updateWorkspacePicture.initiate({
                workspaceId: id,
                picture,
              }),
        );

        clearInterval(progressInterval);
        setProgress(100);
        dispatch({
          type: UPDATE_AVATAR_IMAGE,
          payload: {
            avatarType,
            id,
            data: {
              hasImage: true,
              lastUpdated: Date.now(),
              forceUpdate: true,
            },
          },
        });
        setTimeout(() => setProgress(undefined), 2000);
      } catch (error) {
        console.warn(error);

        dispatch(
          addNotification({
            title: "Updating avatar image failed",
            type: "danger",
            disableAutoHide: true,
          }),
        );

        clearInterval(progressInterval);
        setProgress(undefined);

        if (onError) {
          onError();
        }
      }

      hide();
    };

    dispatch(
      showModal({
        type: "avatarUploader",
        done,
      }),
    );

    return hide;
  };
