import { useContext, useLayoutEffect, useRef } from "react";
import { styled } from "styled-components";

import { MenuContext } from "./MenuContext";
import { IconRightContainer } from "./MenuItem";
import { MenuItemContainer as Container } from "./MenuItemContainer";
import { useScrollIntoView } from "./useScrollIntoView";

const Link = styled(Container)`
  text-decoration: none;
`;

export type LinkMenuItemProps = {
  href: string;

  /**
   * ID of the menu item.
   */
  id: string;

  title: string;

  iconLeft?: React.ReactNode;
  iconRight?: { icon: React.ReactNode; showOnlyOnSelection?: boolean };
};

export function LinkMenuItem({
  id,
  href,
  title,
  iconLeft,
  iconRight,
}: LinkMenuItemProps) {
  const containerRef = useRef<HTMLAnchorElement>(null);
  const { close, selection, setSelection } = useContext(MenuContext);
  const { grabsFocus, selectedBy, itemId } = selection;
  const isSelected = itemId === id;
  const shouldScroll = selectedBy === "key";

  useLayoutEffect(() => {
    if (isSelected && grabsFocus && containerRef.current) {
      containerRef.current.focus();
    }
  }, [isSelected, grabsFocus]);

  useScrollIntoView(containerRef, isSelected && shouldScroll);

  const hasIconRight = !!iconRight?.icon;
  const showIconRight =
    hasIconRight &&
    (!iconRight.showOnlyOnSelection ||
      (iconRight.showOnlyOnSelection && isSelected));

  return (
    <Link
      as="a"
      data-menu-item-id={id}
      onClick={() => close({ reason: "item_activated" })}
      onMouseMove={() => setSelection({ itemId: id, selectedBy: "mouse" })}
      onKeyDown={(event: React.KeyboardEvent<HTMLAnchorElement>) => {
        if (event.key === "Enter" || event.key === " ") {
          event.currentTarget.click();
        }
      }}
      isSelected={isSelected}
      ref={containerRef}
      href={href}
      target="_blank"
    >
      {iconLeft}

      {title}

      <IconRightContainer $visible={hasIconRight}>
        {showIconRight && iconRight.icon}
      </IconRightContainer>
    </Link>
  );
}
