import { useHandler } from "@fiberplane/hooks";
import { Button } from "@fiberplane/ui";
import { useContext, useMemo } from "react";
import {
  type Control,
  type UseFormHandleSubmit,
  type UseFormSetError,
  useController,
} from "react-hook-form";
import { useSelector } from "react-redux";
import { css, styled } from "styled-components";

import {
  useCreateSnippetMutation,
  useUpdateSnippetMutation,
} from "../../../api";
import { getApiErrorMessage } from "../../../errors";
import { selectActiveWorkspaceIdOrThrow } from "../../../selectors";
import { dispatch } from "../../../store";
import {
  addNotification,
  showSnippetSavedSuccessfullyDialog,
} from "../../../thunks";
import type { Snippet } from "../../../types";
import { validateResourceName } from "../../../utils";
import { Container, ModalContext, SuccessButton, TextInput } from "../../UI";

type SnippetsFormProps = {
  control: Control<Snippet>;
  handleSubmit: UseFormHandleSubmit<Snippet>;
  isNewSnippet: boolean;
  setError: UseFormSetError<Snippet>;
};

export function SnippetsForm({
  control,
  handleSubmit,
  isNewSnippet,
  setError,
}: SnippetsFormProps) {
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const { requestClose } = useContext(ModalContext);

  const nameController = useController({
    control,
    name: "name",
    rules: {
      validate: {
        resourceName: (value) => validateResourceName(value, "name"),
      },
    },
  });

  const descriptionController = useController({
    control,
    name: "description",
  });

  const [
    createMutation,
    { isLoading: isCreateLoading, isSuccess: isCreateSuccess },
  ] = useCreateSnippetMutation();
  const [
    updateMutation,
    { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess },
  ] = useUpdateSnippetMutation();

  const isLoading = isNewSnippet ? isCreateLoading : isUpdateLoading;
  const isSuccess = isNewSnippet ? isCreateSuccess : isUpdateSuccess;

  const successButtonText = useMemo(() => {
    if (isSuccess) {
      return isNewSnippet ? "Snippet saved!" : "Snippet updated!";
    }

    return isNewSnippet ? "Save snippet" : "Save changes";
  }, [isNewSnippet, isSuccess]);

  const isSaveButtonDisabled = useMemo(() => {
    // If we're viewing an existing snippet we check if the description has
    // changed
    if (!isNewSnippet) {
      return (
        descriptionController.fieldState.invalid ||
        !descriptionController.fieldState.isDirty
      );
    }

    return (
      nameController.fieldState.invalid || !nameController.fieldState.isDirty
    );
  }, [
    descriptionController.fieldState.invalid,
    descriptionController.fieldState.isDirty,
    isNewSnippet,
    nameController.fieldState.invalid,
    nameController.fieldState.isDirty,
  ]);

  const onSubmit = useHandler(async (changes: Snippet) => {
    try {
      if (isNewSnippet) {
        await createMutation({ workspaceId, newSnippet: changes }).unwrap();
      } else {
        const { name: snippetName, ...updateSnippet } = changes;
        await updateMutation({
          workspaceId,
          snippetName,
          updateSnippet,
        }).unwrap();
      }

      if (isNewSnippet) {
        dispatch(showSnippetSavedSuccessfullyDialog());
      } else {
        dispatch(addNotification({ title: "Snippet updated" }));
      }

      setTimeout(requestClose, 2000);
    } catch (error) {
      const defaultErrorMessage =
        "An error occurred while saving your snippet. Please try again later.";

      const message = await getApiErrorMessage(error, defaultErrorMessage);
      setError("root", { type: "custom", message });
    }
  });

  return (
    <SnippetsFormContainer onSubmit={handleSubmit(onSubmit)}>
      <SnippetsFormLabel as="label" htmlFor="name">
        Name
        <SnippetsTextInput
          autoFocus={isNewSnippet}
          id="name"
          placeholder="Can only contain ASCII characters with dashes, e.g. kubernetes-cpu-query"
          disabled={!isNewSnippet || isLoading || isSuccess}
          {...nameController.field}
        />
        {nameController.fieldState.error && (
          <SnippetsTextInputError>
            {nameController.fieldState.error.message}
          </SnippetsTextInputError>
        )}
      </SnippetsFormLabel>

      <SnippetsFormLabel as="label" htmlFor="description">
        Description
        <SnippetsTextInput
          autoFocus={!isNewSnippet}
          id="description"
          placeholder="Add a description..."
          disabled={isLoading || isSuccess}
          {...descriptionController.field}
        />
        {descriptionController.fieldState.error && (
          <SnippetsTextInputError>
            {descriptionController.fieldState.error.message}
          </SnippetsTextInputError>
        )}
      </SnippetsFormLabel>

      <ButtonsContainer>
        <Button
          type="button"
          buttonStyle="secondary"
          onClick={requestClose}
          disabled={isLoading || isSuccess}
        >
          Cancel
        </Button>
        <SuccessButton
          isSuccessful={isSuccess}
          successIconType="check_circle"
          type="submit"
          disabled={isLoading || (!isSuccess && isSaveButtonDisabled)}
        >
          {successButtonText}
        </SuccessButton>
      </ButtonsContainer>
    </SnippetsFormContainer>
  );
}

const SnippetsFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SnippetsLabel = styled.span(
  ({ theme }) => css`
    font: ${theme.fontStudioStrongSmallShortHand};
    letter-spacing: ${theme.fontStudioStrongSmallLetterSpacing};
    color: ${theme.colorBase600};
  `,
);

const SnippetsFormLabel = styled(SnippetsLabel)`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  gap: 8px;
`;

const SnippetsTextInput = styled(TextInput)`
  border-radius: ${({ theme }) => theme.borderRadius500};
`;

const SnippetsTextInputError = styled(SnippetsLabel)`
  color: ${({ theme }) => theme.colorDanger500};
  position: absolute;
  top: 100%;
`;

const ButtonsContainer = styled(Container)`
  margin-top: 12px;
  justify-content: flex-end;
  column-gap: 8px;
`;
