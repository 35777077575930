import { Icon } from "@fiberplane/ui";
import { type RefObject, useCallback } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

import {
  selectActiveWorkspace,
  selectAvailableWorkspaces,
} from "../../../../selectors";
import { dispatch } from "../../../../store";
import { switchActiveWorkspace } from "../../../../thunks";
import { WorkspaceAvatar } from "../../../Avatar";
import { MenuItem, MenuItemGroup, ToggleMenu } from "../../../UI";
import { SwitchContainer } from "./SwitchContainer";

export function WorkspaceToggle() {
  const availableWorkspaces = useSelector(selectAvailableWorkspaces);
  const activeWorkspace = useSelector(selectActiveWorkspace);

  const toggleElement = useCallback(
    ({
      anchorRef,
      opened,
    }: {
      anchorRef: RefObject<HTMLDivElement>;
      opened: boolean;
      disabled?: boolean | undefined;
    }) => {
      if (!activeWorkspace) {
        return null;
      }

      return (
        <SwitchContainer
          ref={anchorRef}
          workspace={activeWorkspace}
          opened={opened}
        />
      );
    },
    [activeWorkspace],
  );

  if (!activeWorkspace) {
    return <TopNavigation />;
  }

  return (
    <TopNavigation>
      <StyledToggleMenu toggleElement={toggleElement}>
        <MenuItemGroup key="workspaces" title="Select workspace">
          {availableWorkspaces.map((workspace) => (
            <MenuItem
              id={workspace.id}
              key={workspace.id}
              title={workspace.displayName}
              iconLeft={
                <WorkspaceAvatar
                  workspaceId={workspace.id}
                  name={workspace.displayName}
                />
              }
              iconRight={{
                icon:
                  activeWorkspace.id === workspace.id ? (
                    <Icon iconType="check" />
                  ) : undefined,
              }}
              onActivate={() => dispatch(switchActiveWorkspace(workspace.name))}
            />
          ))}
        </MenuItemGroup>
      </StyledToggleMenu>
    </TopNavigation>
  );
}

const TopNavigation = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 8px;
  width: 100%;
  min-height: 36px;
  background: ${({ theme }) => theme.colorBase200};
`;

const StyledToggleMenu = styled(ToggleMenu)`
  width: 100%;
`;
