import { Icon, IconButton } from "@fiberplane/ui";

import { Container, Info } from "./shared";

type Props = {
  reason: string;
  onSelectFile(): void;
  readOnly: boolean;
};

export function Failed({ reason, onSelectFile, readOnly }: Props): JSX.Element {
  return (
    <Container>
      <Info>
        <Icon iconType="image" />
        Image upload error - {reason}
      </Info>
      {!readOnly && (
        <IconButton
          iconType="folder"
          buttonStyle="secondary"
          aria-label="Browse"
          onClick={onSelectFile}
          onKeyDown={(event) => {
            if (event.key === "Enter" || event.key === " ") {
              onSelectFile();
            }
          }}
        />
      )}
    </Container>
  );
}
