import { createSelector } from "reselect";

import type { RootState } from "../state";
import type { DataSource, QuerySchema, SelectedDataSources } from "../types";
import {
  type Intent,
  formatDataSourceKey,
  parseIntent,
  parseSelectedDataSource,
} from "../utils";
import { selectFocusedProviderCell } from "./notebookSelectors";
import { selectActiveNotebook } from "./notebooksSelectors";

export const FIBERPLANE_DATASOURCE_ID = "$fiberplane";

const emptyList: Array<DataSource> = [];

// The Fiberplane data source doesn't need any configuration, so we inject a
// fake DataSource object.
const fiberplaneDataSource: DataSource = {
  id: FIBERPLANE_DATASOURCE_ID,
  name: FIBERPLANE_DATASOURCE_ID,
  protocolVersion: 2,
  providerType: "fiberplane",
};

/**
 * For the specified intent, find the query schema that is currently in use
 * based on the selected data source for the given intent.
 *
 * Warning: This function assumes the provider for the data source is already
 *          loaded. If it is not, this function may return `undefined`. If you
 *          do not know whether the provider is loaded in advance, you may wish
 *          to use the `getProviderForIntent()` thunk instead. That will return
 *          the schema alongside the loaded provider.
 */
export function getActiveQuerySchema(
  state: RootState,
  intent: Intent,
): QuerySchema | undefined {
  const selectedDataSources = selectSelectedDataSources(state);
  let selectedDataSource = intent.dataSourceKey
    ? parseSelectedDataSource(intent.dataSourceKey)
    : selectedDataSources[intent.providerType];

  // If no data source was explicitly set and we are using the faux Fiberplane
  // provider, then it will be using the faux Fiberplane Datasource.
  if (!selectedDataSource) {
    if (intent.providerType === "fiberplane") {
      selectedDataSource = { name: FIBERPLANE_DATASOURCE_ID };
    } else {
      return;
    }
  }

  const supportedQueryTypes = selectSupportedQueryTypes(state);

  const dataSourceKey = formatDataSourceKey(selectedDataSource);

  return supportedQueryTypes[dataSourceKey]?.find(
    (supportedQueryTypes) => supportedQueryTypes.queryType === intent.queryType,
  )?.schema;
}

/**
 * Creates a selector for selecting the data source to be used for the given
 * provider type and optional data source name.
 */
export function makeDataSourceSelector(
  providerType: string,
  dataSourceKey?: string,
) {
  return createSelector(
    [selectDataSources, selectSelectedDataSources],
    (dataSources, selectedDataSources) =>
      selectDataSource(
        dataSources,
        selectedDataSources,
        providerType,
        dataSourceKey,
      ),
  );
}

/**
 * Creates a selector for all query types supported by the given data source.
 */
export const makeDataSourceSupportedQueryTypesSelector = (
  dataSourceKey: string,
) =>
  createSelector(
    [selectDataSourcesState],
    (dataSources) => dataSources.supportedQueryTypes[dataSourceKey],
  );

/**
 * Creates a selector for a query type, if supported by the given data source.
 */
export const makeDataSourceQueryTypeSelector = (
  dataSourceKey: string,
  queryType: string,
) => {
  const selectedSupportedQueryTypes =
    makeDataSourceSupportedQueryTypesSelector(dataSourceKey);
  return createSelector([selectedSupportedQueryTypes], (supportedQueryTypes) =>
    supportedQueryTypes?.find(
      (supportedQueryTypes) => supportedQueryTypes.queryType === queryType,
    ),
  );
};

/**
 * Selects the data source to be used for the given provider type and optional
 * selected data source.
 */
export function selectDataSource(
  dataSources: ReadonlyArray<DataSource>,
  selectedDataSources: SelectedDataSources,
  providerType: string,
  dataSourceKey?: string,
): DataSource | undefined {
  if (providerType === "fiberplane") {
    return fiberplaneDataSource;
  }

  let key = dataSourceKey;
  if (!key) {
    const selectedDataSource = selectedDataSources[providerType];
    if (!selectedDataSource) {
      return undefined;
    }

    key = formatDataSourceKey(selectedDataSource);
  }

  for (const dataSource of dataSources) {
    if (dataSource.providerType !== providerType) {
      continue;
    }

    if (formatDataSourceKey(dataSource) === key) {
      return dataSource;
    }
  }
}

/**
 * Selects the data sources configured within the workspace.
 */
export const selectDataSources = (state: RootState) =>
  selectDataSourcesList(state).data ?? emptyList;

/**
 * Selects the data sources state slice.
 */
export const selectDataSourcesList = (state: RootState) =>
  selectDataSourcesState(state).dataSources;

export const selectDataSourcesListLoading = (state: RootState) =>
  selectDataSourcesList(state).loading;

/**
 * Selects the data sources state slice.
 */
export const selectDataSourcesState = (state: RootState) => state.dataSources;

/**
 * Helper to prevent `selectFocusedProviderCellIntent()` from parsing more often
 * than necessary.
 */
export const selectRawFocusedProviderCellIntent = createSelector(
  [selectFocusedProviderCell],
  (cell) => cell?.intent,
);

/**
 * Returns the parsed intent for the currently focused provider cell.
 *
 * Returns `undefined` if no provider cell is focused, or if there is an error
 * parsing the intent.
 */
export const selectFocusedProviderCellIntent = createSelector(
  [selectRawFocusedProviderCellIntent],
  (intent) => {
    try {
      return intent && parseIntent(intent);
    } catch {
      return; // Swallow errors...
    }
  },
);

/**
 * Returns the query data for the currently focused provider cell.
 *
 * Returns `undefined` if no provider cell is focused, or if there is no query
 * data.
 */
export const selectFocusedProviderCellQueryData = createSelector(
  [selectFocusedProviderCell],
  (cell) => cell?.queryData,
);

/**
 * Selects the data sources that are selected for the active notebook.
 */
export const selectSelectedDataSources = createSelector(
  [selectActiveNotebook],
  (notebook) => notebook.selectedDataSources,
);

/**
 * Selects the map of supported query types by data source name.
 */
export const selectSupportedQueryTypes = (state: RootState) =>
  selectDataSourcesState(state).supportedQueryTypes;
