import { cancelEvent } from "@fiberplane/ui";
import {
  REMOVE_VIEW_EDITOR_LABEL,
  createLabelDraft,
  editLabel,
  hideContextMenu,
  resetLabelsEditor,
  updateLabelDraft,
} from "../../../actions";
import {
  selectActiveContextMenu,
  selectCanUpdateView,
} from "../../../selectors";
import type { Thunk } from "../../../store";
import { saveLabelsEditorDraft } from "../../../thunks";
import type { Label } from "../../../types";
import { startEditingIfNeeded } from "./viewEditorThunks";

export const onLabelsEditorBlur =
  (event: React.FocusEvent): Thunk =>
  (dispatch, getState) => {
    const state = getState();

    const { relatedTarget } = event;
    const isContextMenuItemOrToolbarButton =
      relatedTarget instanceof HTMLElement &&
      (relatedTarget.dataset.contextMenu ||
        relatedTarget.dataset.toolbarButton);

    if (!isContextMenuItemOrToolbarButton) {
      dispatch(resetLabelsEditor("view"));

      const contextMenuInfo = selectActiveContextMenu(state);
      if (contextMenuInfo?.menuType === "auto_suggest_labels") {
        dispatch(hideContextMenu());
      }
    }
  };

export const onCreateLabel =
  (event: React.SyntheticEvent): Thunk =>
  (dispatch, getState) => {
    if (!selectCanUpdateView(getState())) {
      return;
    }

    cancelEvent(event);
    dispatch(startEditingIfNeeded());
    dispatch(createLabelDraft("view"));
  };

export const onDeleteLabel =
  (event: React.SyntheticEvent, label: Label): Thunk =>
  (dispatch, getState) => {
    if (!selectCanUpdateView(getState())) {
      return;
    }

    cancelEvent(event);
    dispatch(startEditingIfNeeded());
    dispatch({ type: REMOVE_VIEW_EDITOR_LABEL, payload: label });
  };

export const onEditLabel =
  (event: React.SyntheticEvent, label: Label): Thunk =>
  (dispatch, getState) => {
    if (!selectCanUpdateView(getState())) {
      return;
    }

    cancelEvent(event);
    dispatch(startEditingIfNeeded());
    dispatch(editLabel("view", label));
  };

export const onLabelsEditorKeyDown =
  (event: React.KeyboardEvent): Thunk =>
  (dispatch) => {
    switch (event.key) {
      case "Enter": {
        dispatch(saveLabelsEditorDraft());
        dispatch(updateLabelDraft("view", ""));
        break;
      }
      case "Escape": {
        dispatch(resetLabelsEditor("view"));
        break;
      }
    }
  };
