/**
 * Returns the closest ancestor `Element` of the given `node` that matches the
 * given `selector` for any `node`.
 */
export const getClosest = (
  node: Node | null,
  selector: string,
): HTMLElement | null => {
  if (isElement(node)) {
    return node.closest(selector);
  } else if (isText(node)) {
    return getClosest(node.parentNode, selector);
  } else {
    return null;
  }
};

const isElement = (node: Node | null): node is Element => node?.nodeType === 1;

const isText = (node: Node | null): node is Text => node?.nodeType === 3;
