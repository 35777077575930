import { memo } from "react";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { styled } from "styled-components";

import { TITLE_CELL_ID } from "../../constants";
import { useCellFocus, useFilePaste } from "../../hooks";
import { makeIsFocusedSelector, selectActiveNotebook } from "../../selectors";
import type { RootState } from "../../state";
import { dispatch } from "../../store";
import { focusCell } from "../../thunks";
import { useCellHighlight } from "../highlight";
import {
  CellContainer,
  CellLeftContext,
  CellMain,
  CellRightContext,
} from "./CellContainer";
import { CellFocusIndicatorWithButtons } from "./CellFocusIndicatorWithButtons";
import { CellPresence } from "./CellPresence";
import { ContextMenu } from "./ContextMenu";
import { RichTextInput } from "./RTE";

type Props = {
  readOnly: boolean;
};

export const TitleCell = memo(function TitleCell({ readOnly }: Props) {
  const { focused, title } = useSelector(selectTitleCellState);

  const highlighted = useCellHighlight(id);
  const focus = useCellFocus(id);
  const onPaste = useFilePaste(id);

  const onClick = () => {
    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
    if (!focused && !readOnly) {
      dispatch(focusCell({ cellId: id }));
    }
  };

  return (
    <TitleCellContainer
      data-cell-id={id}
      data-cell-type="heading"
      onClick={onClick}
      focused={focused}
    >
      <ContextMenu cellId={id} />

      <CellLeftContext>
        <CellFocusIndicatorWithButtons cellId={id} focused={focused} />
      </CellLeftContext>

      <CellMain
        tabIndex={focused ? 0 : -1}
        data-function="cell-content"
        $highlight={highlighted}
      >
        <TitleCellContent
          cellId={id}
          focus={focus}
          onPaste={onPaste}
          readOnly={readOnly}
          disableFormatting={true}
          tagName={"h1"}
          value={title}
        />
      </CellMain>

      <CellRightContext>
        <CellPresence cellId={id} />
      </CellRightContext>
    </TitleCellContainer>
  );
});

const id = TITLE_CELL_ID;

const selectTitleCellState = createStructuredSelector({
  focused: makeIsFocusedSelector(id),
  // Don't use `selectNotebookTitle()` because we don't want the default title
  // inserted in this cell:
  title: (state: RootState) => selectActiveNotebook(state).title,
});

const TitleCellContainer = styled(CellContainer)`
  margin: ${({ theme }) => theme.spacingHeadingH1};
  margin-bottom: 8px;
`;

const TitleCellContent = styled(RichTextInput)`
  margin: 0;
  font: ${({ theme }) => theme.font.headings.h1};
`;
