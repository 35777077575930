import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { makeCellFocusSelector } from "../../../selectors";
import { getField, parseTableRowValueId } from "../../../utils";
import { headingRowMarker, rowHandleMarker } from "./constants";

/**
 * Returns whether the given field inside the given cell is selected.
 *
 * Selected is not the same as having focus. Having focus means the cursor is
 * inside the field, while fields show a selected state when the row or column
 * they are in is selected.
 */
export function useFieldIsSelected(cellId: string, field: string): boolean {
  const selectCellFocusField = useMemo(
    () => makeCellFocusFieldSelector(cellId),
    [cellId],
  );
  const selectFieldIsSelected = useMemo(
    () =>
      createSelector(selectCellFocusField, (focusField) => {
        const [fieldRowId, fieldColumnId] = parseTableRowValueId(field);
        const selectedColumnId = getSelectedColumnId(focusField);
        const selectedRowId = getSelectedRowId(focusField);
        return (
          fieldRowId === selectedRowId || fieldColumnId === selectedColumnId
        );
      }),
    [field, selectCellFocusField],
  );
  return useSelector(selectFieldIsSelected);
}

export function useSelectedColumnId(cellId: string): string | undefined {
  const selectCellFocusField = useMemo(
    () => makeCellFocusFieldSelector(cellId),
    [cellId],
  );
  const selectSelectedColumnId = useMemo(
    () => createSelector(selectCellFocusField, getSelectedColumnId),
    [selectCellFocusField],
  );
  return useSelector(selectSelectedColumnId);
}

export function useSelectedRowId(cellId: string): string | undefined {
  const selectCellFocusField = useMemo(
    () => makeCellFocusFieldSelector(cellId),
    [cellId],
  );
  const selectSelectedColumnId = useMemo(
    () => createSelector(selectCellFocusField, getSelectedRowId),
    [selectCellFocusField],
  );
  return useSelector(selectSelectedColumnId);
}

function getSelectedColumnId(
  focusField: string | undefined,
): string | undefined {
  return focusField?.startsWith(headingRowMarker)
    ? parseTableRowValueId(focusField)[1]
    : undefined;
}

function getSelectedRowId(focusField: string | undefined): string | undefined {
  return focusField?.endsWith(rowHandleMarker)
    ? parseTableRowValueId(focusField)[0]
    : undefined;
}

/**
 * Creates a selector that selects the `field` inside `CellFocus`.
 *
 * The selector returns `undefined` if the cell does not have focus.
 */
function makeCellFocusFieldSelector(cellId: string) {
  return createSelector(makeCellFocusSelector(cellId), getField);
}
