import { useHandler } from "@fiberplane/hooks";
import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { shallowEqual, useSelector } from "react-redux";
import { createSelector, createStructuredSelector } from "reselect";
import scrollIntoView from "scroll-into-view-if-needed";

import { CellById, type CellHandle } from "../../CellById";
import { expandConsole } from "../../actions";
import { formatFiberplaneError } from "../../errors";
import {
  type DropTargetHoverOptions,
  useDropTargetClassNames,
  useSelectionCellPosition,
} from "../../hooks";
import {
  makeCellErrorSelector,
  makeCellSelector,
  makeIsFocusedSelector,
  makeIsSelectedSelector,
  selectActiveDropTarget,
} from "../../selectors";
import { useActiveNotebookDispatch } from "../../store";
import { focusCellIfNotFocused } from "../../thunks";
import type { Cell as CellType, HeadingType } from "../../types";
import { CellErrorIcon, useShake } from "../UI";
import { useCellHighlight } from "../highlight";
import {
  CellContainer,
  CellLeftContext,
  CellMain,
  CellRightContent,
  CellRightContext,
} from "./CellContainer";
import { CellContent } from "./CellContent";
import { CellControls } from "./CellControls";
import { CellPresence } from "./CellPresence";
import { ContextMenu } from "./ContextMenu";

export type CellProps = {
  id: string;
  readOnly: boolean;
};

export const Cell = memo(forwardRef(MemoizedCellWithRef));

function MemoizedCellWithRef(props: CellProps, ref: React.Ref<CellHandle>) {
  const { id, readOnly: notebookReadOnly } = props;

  const selectCellState = useMemo(
    () =>
      createStructuredSelector({
        cell: makeCellSelector(id),
        error: makeCellErrorSelector(id),
        focused: makeIsFocusedSelector(id),
        selected: makeIsSelectedSelector(id),
      }),
    [id],
  );
  const { cell, error, focused, selected } = useSelector(selectCellState);

  const dispatch = useActiveNotebookDispatch();
  const highlight = useCellHighlight(id);
  const selectionPosition = useSelectionCellPosition(id);
  const { shake, shakeClassName } = useShake();

  const containerRef = useRef<HTMLDivElement>(null);
  const handleRef = useRef<CellHandle>({
    scrollIntoView: useHandler(() => {
      const container = containerRef.current;
      if (container) {
        scrollIntoView(container, { scrollMode: "if-needed" });
      }
    }),
    shake,
  });

  useImperativeHandle(ref, () => handleRef.current);
  useEffect(() => {
    CellById.set(id, handleRef.current);
    return () => {
      CellById.delete(id);
    };
  }, [id]);

  const selectDropTargetHoverOptions = useMemo(
    () => makeDropTargetHoverOptionsSelector(id),
    [id],
  );
  const dropTargetHoverOptions = useSelector(selectDropTargetHoverOptions);
  const dropTargetClassNames = useDropTargetClassNames(dropTargetHoverOptions);

  const cellContainerStyles: React.CSSProperties = useMemo(
    () => ({
      padding: cell ? getCellPadding(cell) : 0,
      margin: cell?.type === "list_item" ? 0 : "4px 0",
    }),
    [cell],
  );

  if (!cell) {
    return null;
  }

  const cellReadOnly = cell.readOnly ?? false;
  const readOnly = notebookReadOnly || cellReadOnly;

  const onClick = () => {
    dispatch(focusCellIfNotFocused({ cellId: id }));
  };

  return (
    <CellContainer
      ref={containerRef}
      className={dropTargetClassNames}
      data-cell-id={id}
      data-cell-type={cell.type}
      onClick={onClick}
      style={cellContainerStyles}
      focused={focused}
      selection={selectionPosition}
    >
      <ContextMenu cellId={id} />

      {!notebookReadOnly && (
        <CellLeftContext>
          <CellControls
            cell={cell}
            focused={focused}
            lockIconClassName={shakeClassName}
            selected={selected}
          />
        </CellLeftContext>
      )}

      <CellMain
        tabIndex={focused ? 0 : -1}
        data-function="cell-content"
        $highlight={highlight}
      >
        <CellContent cell={cell} readOnly={readOnly} />
      </CellMain>

      <CellRightContext>
        <CellRightContent>
          {error && (
            <CellErrorIcon
              key={error.type}
              onClick={() => dispatch(expandConsole())}
              title={formatFiberplaneError(error)}
            />
          )}
          <CellPresence cellId={id} />
        </CellRightContent>
      </CellRightContext>
    </CellContainer>
  );
}

function getCellPadding(cell: CellType) {
  switch (cell.type) {
    case "checkbox":
    case "list_item":
      return 0;

    case "heading":
      return headingDimensions(cell.headingType);

    default:
      return "6px 0";
  }
}

function headingDimensions(headingType: HeadingType): string {
  switch (headingType) {
    case "h1":
      return "24px 0 10px";

    case "h2":
      return "14px 0 10px";

    case "h3":
      return "12px 0 6px";
  }
}

const noDropTarget: DropTargetHoverOptions = {};

function makeDropTargetHoverOptionsSelector(cellId: string) {
  return createSelector(
    selectActiveDropTarget,
    (dropTarget): DropTargetHoverOptions => {
      switch (dropTarget?.type) {
        case "after_cell":
          return dropTarget.cellId === cellId
            ? { bottomHovered: true }
            : noDropTarget;
        case "before_cell":
          return dropTarget.cellId === cellId
            ? { topHovered: true }
            : noDropTarget;
        default:
          return noDropTarget;
      }
    },
    { memoizeOptions: { resultEqualityCheck: shallowEqual } },
  );
}
