import { Button } from "@fiberplane/ui";
import { useContext } from "react";
import { styled } from "styled-components";

import type { ReplaceLabelKeyWarningModalProps } from "../../types";
import { parseLabel } from "../../utils";
import {
  Modal,
  ModalButtonBar,
  ModalContent,
  ModalContext,
  ModalHeader,
  NotebookLabel,
} from "../UI";

// TODO (Oscar): replace with confirmation modal
export function ReplaceLabelKeyWarningModal({
  draft,
  onConfirm,
}: ReplaceLabelKeyWarningModalProps) {
  const { requestClose } = useContext(ModalContext);

  return (
    <Modal>
      <ModalHeader>Label deleted by other user</ModalHeader>
      <ModalContent>
        Do you want to keep editing the label{" "}
        <StyledLabel label={parseLabel(draft)} />? When you save the edited
        label, it will be re-added instead of replaced.
      </ModalContent>
      <ModalButtonBar>
        <Button onClick={requestClose} buttonStyle="secondary">
          Discard
        </Button>
        <Button onClick={onConfirm}>Keep editing</Button>
      </ModalButtonBar>
    </Modal>
  );
}

const StyledLabel = styled(NotebookLabel)`
  display: inline-flex !important;
  cursor: text;
`;
