import type { Event, Label } from "../types";

export const EVENTS_SET_ACTIVE_EVENT = "events/setActiveEvent";
export const EVENTS_SET_ACTIVE_EVENT_VALUE = "events/setActiveEventValue";
export const ADD_EVENT_EDITOR_LABEL = "events/editor/addLabel";

export type EventsAction =
  | SetActiveEventAction
  | AddEventEditorLabelAction
  | SetActiveEventValueAction;

export type SetActiveEventAction = {
  type: typeof EVENTS_SET_ACTIVE_EVENT;
  payload: { event: Event | null };
};

export type SetActiveEventValueAction = {
  type: typeof EVENTS_SET_ACTIVE_EVENT_VALUE;
  payload: { key: keyof Event; value: Event[keyof Event] };
};

export type AddEventEditorLabelAction = {
  type: typeof ADD_EVENT_EDITOR_LABEL;
  payload: Label;
};

export const setActiveEvent = (event: Event | null): SetActiveEventAction => ({
  type: EVENTS_SET_ACTIVE_EVENT,
  payload: { event },
});

export const setActiveEventValue: <Key extends keyof Event>(
  key: Key,
  value: Event[Key],
) => SetActiveEventValueAction = (key, value) => ({
  type: EVENTS_SET_ACTIVE_EVENT_VALUE,
  payload: { key, value },
});

export const addEventEditorLabel = (
  label: Label,
): AddEventEditorLabelAction => ({
  type: ADD_EVENT_EDITOR_LABEL,
  payload: label,
});
