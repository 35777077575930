import type { Api } from "../services";
import type { LoadableData } from "../types";

export interface ProxiesState {
  readonly proxyList: LoadableProxyList;
  readonly proxies: ReadonlyMap<string, LoadableProxy>;
}

export type LoadableProxy = LoadableData<Api.Proxy>;
export type LoadableProxyList = LoadableData<Array<Api.ProxySummary>>;
