import { useHandler } from "@fiberplane/hooks";
import { useEffect } from "react";

import { useActiveNotebookDispatch } from "../../../store";
import type { UserTyping as UserTypingType } from "../../../types";
import { ProfileAvatar } from "../../Avatar";

type UserTypingProps = Omit<UserTypingType, "startedAt"> & {
  threadId: string;
};

export function UserTyping({ threadId, updatedAt, user }: UserTypingProps) {
  const dispatch = useActiveNotebookDispatch();

  const setUserStoppedTyping = useHandler(() => {
    dispatch({
      type: "stop_user_typing",
      payload: { threadId, userId: user.id },
    });
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: intentional
  useEffect(() => {
    const countdown = setTimeout(setUserStoppedTyping, 3000);

    return () => {
      clearTimeout(countdown);
    };
  }, [setUserStoppedTyping, updatedAt]);

  return <ProfileAvatar key={user.id} name={user.name} userId={user.id} />;
}
