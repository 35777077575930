import type { FuseResultMatch, RangeTuple } from "fuse.js";
import { Fragment, useMemo } from "react";
import { css, styled } from "styled-components";

import type { SearchIndexKey } from "../constants";

type HighlightedTextProps = {
  children: string;
  indexKey: SearchIndexKey;
  matches: ReadonlyArray<FuseResultMatch> | undefined;
};

type TextNode = {
  highlighted?: boolean;
  text: string;
};

export function HighlightedText({
  children,
  indexKey,
  matches,
}: HighlightedTextProps) {
  const textNodes = useMemo(() => {
    const rangeTuples = indicesByKey(indexKey, matches);
    return createHighlightedTextNodes(children, rangeTuples);
  }, [children, indexKey, matches]);

  return (
    <>
      {textNodes.map(({ text, highlighted }, index) =>
        highlighted ? (
          <StyledHighlight key={index}>{text}</StyledHighlight>
        ) : (
          <Fragment key={index}>{text}</Fragment>
        ),
      )}
    </>
  );
}

const createHighlightedTextNodes = (
  text: string,
  rangeTuples: ReadonlyArray<RangeTuple>,
): Array<TextNode> => {
  if (!rangeTuples) {
    return [{ text }];
  }

  let offset = 0;
  const nodes = [];
  for (const [, [start, end]] of rangeTuples.entries()) {
    if (offset < start) {
      nodes.push({ text: text.slice(offset, start) });
    }

    nodes.push({
      highlighted: true,
      text: text.slice(start, end + 1),
    });

    offset = end + 1;
  }

  if (offset < text.length) {
    nodes.push({ text: text.slice(offset, text.length) });
  }

  return nodes;
};

const indicesByKey = (
  key: SearchIndexKey,
  matches: ReadonlyArray<FuseResultMatch> | undefined,
) => matches?.find((match) => match.key === key)?.indices ?? [];

const StyledHighlight = styled.span(
  ({ theme }) => css`
    box-shadow: inset 0 -2em 0 ${theme.colorPrimary100};
  `,
);
