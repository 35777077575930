import { useHandler } from "@fiberplane/hooks";
import { Button } from "@fiberplane/ui";
import type { ReactElement } from "react";
import { css, styled } from "styled-components";

import { TIMELINE_MODAL_KEY } from ".";
import { setActiveEvent } from "../../../actions";
import { dispatch } from "../../../store";
import { showModal } from "../../../thunks";
import type { ApiEvent, TimelineCell } from "../../../types";
import { formatLongDate, isEmpty } from "../../../utils";
import { EventRow } from "./EventRow";
import { useTimelineData } from "./hooks";

export type TimelineCellContentProps = {
  cell: { type: "timeline" } & TimelineCell;
  readOnly: boolean;
};

export function TimelineCellContent({
  cell,
  readOnly,
}: TimelineCellContentProps) {
  const data = useTimelineData(cell);

  const openModal = useHandler((event: ApiEvent | null) => {
    dispatch(setActiveEvent(event));
    dispatch(
      showModal({
        type: TIMELINE_MODAL_KEY,
      }),
    );
  });

  return (
    <TimelineContainer>
      {data.map(({ days, eventsByDay }) => {
        if (isEmpty(days)) {
          return (
            <Banner key="no-events">
              <header>No events on your timeline</header>
              <div>You can easily add an event manually to your timeline</div>

              {!readOnly && (
                <Button
                  onClick={() => {
                    openModal(null);
                  }}
                >
                  New event
                </Button>
              )}
            </Banner>
          );
        }

        return days.reduce<Array<ReactElement>>((accumulator, day) => {
          const dayEvents = eventsByDay[day];
          if (!isEmpty(dayEvents)) {
            accumulator.push(
              <DateIndicator key={`${day}-header`}>
                {formatLongDate(new Date(day))}
              </DateIndicator>,
              ...dayEvents.map((event) => (
                <EventRow
                  key={`${day}-event-${event.id}`}
                  event={event}
                  readOnly={readOnly}
                  onClick={() => {
                    openModal(event);
                  }}
                />
              )),
            );
            if (!readOnly) {
              accumulator.push(
                <Actions key={`${day}-actions`}>
                  <Button disabled={readOnly} onClick={() => openModal(null)}>
                    New event
                  </Button>
                </Actions>,
              );
            }
          }

          return accumulator;
        }, []);
      })}
    </TimelineContainer>
  );
}

const DateIndicator = styled.div(
  ({ theme }) => css`
    background: ${theme.colorBase100};
    color: ${theme.colorBase600};
    padding: 8px 16px;
    font: ${theme.fontStudioStrongRegularShortHand};
    letter-spacing: ${theme.fontStudioStrongRegularLetterSpacing};
  `,
);

const Actions = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.colorBase200};
  padding: 16px;
`;

const Banner = styled.div(
  ({ theme }) => css`
    background: ${theme.color.bg.subtle};
    border: 1px solid ${theme.color.border.default};
    border-radius: 8px;
    height: 182px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    header {
      font: ${theme.font.headings.h3};
    }

    & > div {
      font: ${theme.fontStudioBodyCopyRegularShortHand};
      letter-spacing: ${theme.fontStudioStrongRegularLetterSpacing};
      margin: 8px 0 16px;
    }
  `,
);

export const TimelineContainer = styled.div`
  overflow: hidden;

  div:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  ${/* sc-selector */ DateIndicator}:not(:first-child) {
    margin-top: 24px;
  }
`;
