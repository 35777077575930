import { memo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { setFocus } from "../../../actions";
import { GLOBAL_TIME_RANGE_ID } from "../../../constants";
import {
  makeCellFocusSelector,
  makeIsFocusedSelector,
  selectNotebookTimeRange,
} from "../../../selectors";
import { useActiveNotebookDispatch } from "../../../store";
import { updateNotebookTimeRange } from "../../../thunks";
import { toNotebookFocus } from "../../../utils";
import { useCellHighlight } from "../../highlight";
import {
  CellContainer,
  CellLeftContext,
  CellMain,
  CellRightContext,
} from "../CellContainer";
import { CellFocusIndicatorWithButtons } from "../CellFocusIndicatorWithButtons";
import { CellPresence } from "../CellPresence";
import { TimeRangeSelectorCellContent } from "./TimeRangeSelectorCellContent";

const id = GLOBAL_TIME_RANGE_ID;
const selectComponentState = createSelector(
  [
    makeCellFocusSelector(id),
    makeIsFocusedSelector(id),
    selectNotebookTimeRange,
  ],
  (focus, focused, timeRange) => ({ focus, focused, timeRange }),
);

type Props = {
  readOnly: boolean;
};

/**
 * Special cell for showing & modifying the global time range.
 */
export const GlobalTimeSelectorCell = memo(function GlobalTimeSelectorCell({
  readOnly,
}: Props) {
  const dispatch = useActiveNotebookDispatch();
  const { focus, focused, timeRange } = useSelector(selectComponentState);
  const highlight = useCellHighlight(id);

  return (
    <CellContainer
      data-cell-id={id}
      data-cell-type="global-time-range"
      focused={focused}
    >
      <CellLeftContext>
        <CellFocusIndicatorWithButtons cellId={id} focused={focused} />
      </CellLeftContext>
      <CellMain
        tabIndex={focused ? 0 : -1}
        data-function="cell-content"
        $highlight={highlight}
      >
        <TimeRangeSelectorCellContent
          label="Time range"
          focus={focus}
          from={timeRange.from}
          readOnly={readOnly}
          to={timeRange.to}
          onChange={(timeRange) => dispatch(updateNotebookTimeRange(timeRange))}
          onFocusChange={(focus) =>
            dispatch(setFocus(toNotebookFocus(focus, id)))
          }
        />
      </CellMain>
      <CellRightContext>
        <CellPresence cellId={id} />
      </CellRightContext>
    </CellContainer>
  );
});
