import cx from "classnames";

// biome-ignore lint/style/noNamespaceImport: This is the recommended way to import css modules
import * as styles from "./styles.module.css";

export type DropTargetHoverOptions = {
  topHovered?: boolean;
  bottomHovered?: boolean;
};

export function useDropTargetClassNames(
  options: DropTargetHoverOptions,
): string {
  return cx(
    styles.initial,
    options.topHovered && styles.hoverTop,
    options.bottomHovered && styles.hoverBottom,
  );
}
