import { useHandler } from "@fiberplane/hooks";
import { Icon } from "@fiberplane/ui";
import { useSelector } from "react-redux";
import { css, styled } from "styled-components";

import type { TemplateSummary } from "../../api";
import { selectActiveWorkspaceRole } from "../../selectors";
import { dispatch } from "../../store";
import { addNotification, showTemplateDetailModal } from "../../thunks";
import { getAccentColors } from "../../utils";
import { PageContentItem, RelativeTimestamp, Text } from "../UI";

type Props = TemplateSummary;

export function TemplateListItem({
  createdAt,
  name,
  description,
}: Props): JSX.Element {
  const role = useSelector(selectActiveWorkspaceRole);

  const onClick = useHandler(() => {
    if (role === "read") {
      return dispatch(
        addNotification({
          type: "danger",
          title:
            "You don't have permission to create notebooks in this workspace",
        }),
      );
    }

    dispatch(showTemplateDetailModal(name));
  });

  return (
    <TemplateContainer tabIndex={0} onClick={onClick}>
      <TemplateIconContainer $name={name}>
        <TemplateIcon iconType="templates" />
      </TemplateIconContainer>

      <TemplateTitle>{name}</TemplateTitle>

      <TemplateDetails>
        Created <RelativeTimestamp timestamp={createdAt} /> &bull;{" "}
        {getTemplateDescription(description)}
      </TemplateDetails>
    </TemplateContainer>
  );
}

const getTemplateDescription = (description: string) => {
  if (/\S/.test(description)) {
    return <>{description}</>;
  }

  return <>No description provided</>;
};

export const TemplateContainer = styled(PageContentItem)`
  /* HACK - Override PageContentItem styles */
  border: none;
  margin: 0;

  /* Style the template container */
  grid:
    "icon title" 1fr
    "icon details" 1fr
    / 48px 1fr;
  gap: 0 20px;
  padding: ${({ theme }) => theme.height.xx.small};
  border-radius: ${({ theme }) => theme.radius.default};
`;

export const TemplateIconContainer = styled.div<{ $name?: string }>(
  ({ $name, theme }) => {
    const colors = $name && getAccentColors($name);

    return css`
      grid-area: icon;
      background-color: ${theme.color.bg.emphasis.neutral};
      border-radius: ${theme.radius.default};
      display: grid;
      place-content: center;
      width: 100%;
      aspect-ratio: 1;

      ${colors}
    `;
  },
);

export const TemplateIcon = styled(Icon)`
  height: ${({ theme }) => theme.height.x.small};
`;

export const TemplateTitle = styled(Text)(
  ({ theme }) => css`
    grid-area: title;
    text-decoration: none;
    font: ${theme.font.headings.h4};
    display: flex;
    align-items: center;
    position: relative;
    line-height: 20px;
  `,
);

export const TemplateDetails = styled(Text)(
  ({ theme }) => css`
    grid-area: details;
    font: ${theme.font.body.md.regular};
    color: ${theme.color.fg.muted};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
);

export const TemplatesAnchor = styled.a`
  color: ${({ theme }) => theme.color.fg.default};
`;
