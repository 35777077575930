import { matchPath } from "react-router-dom";

import { createSelector } from "reselect";
import { ROUTES } from "../constants";
import type { RootState } from "../state";

export const selectLocation = (state: RootState) => state.router.location;

export const selectActiveViewName = (state: RootState) => {
  const pathname = selectPathname(state);

  if (pathname) {
    const match = matchPath(ROUTES.View, pathname);
    if (match) {
      return match.params.viewName;
    }
  }
};

export const selectPathname = (state: RootState) =>
  selectLocation(state)?.pathname;

export const selectSearch = (state: RootState) => selectLocation(state)?.search;

/**
 * Selects the value of the overlay query parameter
 *
 * The value of overlay should be a 'uri' that can be used to open a modal
 */
export const selectOverlayValue = createSelector([selectSearch], (search) => {
  const params = new URLSearchParams(search);
  return params.get("overlay");
});

export const selectOverlayQueryParams = createSelector(
  [selectOverlayValue],
  (uri) => {
    if (!uri) {
      return new URLSearchParams();
    }

    try {
      const url = new URL(uri, window.location.origin);
      return url.searchParams;
    } catch {
      return new URLSearchParams();
    }
  },
);
