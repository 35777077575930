import {
  EVENTS_MIME_TYPE,
  TIMELINE_MIME_TYPE,
  TIMESERIES_MIME_TYPE,
} from "../constants";
import type { Blob, ProviderEvent, Timeline, Timeseries } from "../types";
import { parseBlob } from "./blobs";

const EVENTS_MIME_TYPES = new Set([
  `${EVENTS_MIME_TYPE}+json`,
  `${EVENTS_MIME_TYPE}+msgpack`,
]);

const TIMESERIES_MIME_TYPES = new Set([
  `${TIMESERIES_MIME_TYPE}+json`,
  `${TIMESERIES_MIME_TYPE}+msgpack`,
]);

const TIMELINE_MIME_TYPES = new Set([
  `${TIMELINE_MIME_TYPE}+json`,
  `${TIMELINE_MIME_TYPE}+msgpack`,
]);

export function parseEventsBlob(data: Blob): Array<ProviderEvent> {
  if (!EVENTS_MIME_TYPES.has(data.mimeType)) {
    throw new TypeError(
      `Expected events MIME type, received: ${data.mimeType}`,
    );
  }

  const parsedData = parseBlob(data);
  if (!Array.isArray(parsedData)) {
    throw new TypeError(
      `Array expected in blob with MIME type ${data.mimeType}`,
    );
  }

  return parsedData as Array<ProviderEvent>;
}

export function parseTimeseriesBlob(data: Blob): Array<Timeseries> {
  if (!TIMESERIES_MIME_TYPES.has(data.mimeType)) {
    throw new TypeError(
      `Expected timeseries MIME type, received: ${data.mimeType}`,
    );
  }

  const parsedData = parseBlob(data);
  if (!Array.isArray(parsedData)) {
    throw new TypeError(
      `Array expected in blob with MIME type ${data.mimeType}`,
    );
  }

  return parsedData as Array<Timeseries>;
}

export function parseTimelineBlob(data: Blob): Array<Timeline> {
  if (!TIMELINE_MIME_TYPES.has(data.mimeType)) {
    throw new TypeError(
      `Expected timeline MIME type, received: ${data.mimeType}`,
    );
  }

  const parsedData = parseBlob(data);
  if (!Array.isArray(parsedData)) {
    throw new TypeError(
      `Array expected in blob with MIME type ${data.mimeType}`,
    );
  }

  return parsedData as Array<Timeline>;
}
