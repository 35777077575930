/**
 * Returns the last element of any iterable structure.
 */
export function last<T>(iterable: ArrayLike<T> | Iterable<T>): T | undefined {
  if ("length" in iterable) {
    return iterable[iterable.length - 1];
  }

  let last = undefined;
  for (const item of iterable) {
    last = item;
  }
  return last;
}
