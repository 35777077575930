import { Button } from "@fiberplane/ui";
import { css, styled } from "styled-components";

import { useCopyToClipboard } from "../../../hooks";
import { track } from "../../../utils";
import { TextInput } from "../../UI";
import { AnimatedCheck } from "./AnimatedCheck";
import {
  ButtonFooter,
  IllustrationContainer,
  ModalBodyRegularText,
  ModalClose,
  ModalHeading,
  NextButton,
  PageIndicator,
  PageIndicatorList,
  StyledModal,
  StyledModalContent,
} from "./common";

type Props = {
  token: string;
  name: string;
  next: () => void;
};

export function ProxyCreated({ name, token, next }: Props) {
  const { isCopied, handleCopy } = useCopyToClipboard(token);

  // Copies to clipboard and fires a segment event
  const handleAndTrackCopy = () => {
    handleCopy();
    track("onboarding | copy proxy token");
  };

  return (
    <StyledModal>
      <StyledModalContent>
        <IllustrationContainer>
          <ModalClose />
          <IconWrapper>
            <AnimatedCheck height="70" width="70" />
          </IconWrapper>
        </IllustrationContainer>
        <ModalHeading>Created FPD Token</ModalHeading>
        <ModalBodyRegularText>
          Save this token somewhere secure. Next, we&rsquo;ll deploy the FPD
          instance itself.
        </ModalBodyRegularText>
        <CopyTokenContainer>
          <ProxyToken name="proxyToken" type="text" value={token} readOnly />
          <CopyTokenButton
            onClick={handleAndTrackCopy}
            buttonStyle="secondary"
            disabled={isCopied}
          >
            {isCopied ? "Copied" : "Copy"}
          </CopyTokenButton>
        </CopyTokenContainer>
        <ModalBodyRegularText>
          By the way, you can now find this FPD instance on the Settings page,
          under the name <ProxyName>{name}</ProxyName>
        </ModalBodyRegularText>
      </StyledModalContent>
      <ButtonFooter>
        <PageIndicatorList>
          <PageIndicator />
          <PageIndicator $active />
          <PageIndicator />
        </PageIndicatorList>
        <NextButton onClick={next}>Let&rsquo;s Deploy</NextButton>
      </ButtonFooter>
    </StyledModal>
  );
}

const IconWrapper = styled.div(
  ({ theme }) => css`
    background: ${theme.colorBackground};
    border-radius: 32px;
    color: ${theme.colorSecondary600};
    height: 120px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
);

const CopyTokenContainer = styled.div`
  font: ${({ theme }) => theme.fontStudioStrongRegularShortHand};
  letter-spacing: ${({ theme }) => theme.fontStudioStrongRegularLetterSpacing};
  padding: 1rem;
`;

const ProxyToken = styled(TextInput)(
  ({ theme }) => css`
    min-width: 300px;

    &:disabled,
    &[readonly] {
      background: ${theme.colorSecondary100};
      color: ${theme.colorSecondary500};
    }
  `,
);

const CopyTokenButton = styled(Button).attrs({ buttonSize: "small" })`
  display: inline-block;
  margin-left: 1rem;
  /* HACK: Magic number - Prevents width from jumping when text changes to "Copied!" */
  min-width: 70px;
`;

const ProxyName = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colorSecondary500};
`;
