import { getActiveQuerySchema } from "../../selectors";
import type { RootState } from "../../state";
import type { ProviderCell } from "../../types";
import { parseIntent } from "../../utils";

/**
 * For the specified cell and field, return whether the field supports
 * data source-provided suggestions
 *
 * Warning: This function assumes the provider for the data source is already
 *          loaded. If it is not, this function will return false.
 */
export function getFieldSupportsSuggestions(
  state: RootState,
  cell: ProviderCell,
  field: string,
): boolean {
  const schema = getActiveQuerySchema(state, parseIntent(cell.intent));
  const fieldSchema = schema?.find(
    (candidateField) => candidateField.name === field,
  );

  if (!fieldSchema) {
    return false;
  }

  if (fieldSchema.type === "text" || fieldSchema.type === "select") {
    return fieldSchema.supportsSuggestions;
  }

  return false;
}
