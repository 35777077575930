import { Button, IconButton } from "@fiberplane/ui";
import { useState } from "react";
import { useSelector } from "react-redux";
import { type DefaultTheme, css, styled } from "styled-components";

import { useGetWebhookDeliveryQuery } from "../../../../../../api";
import { selectActiveWorkspaceIdOrThrow } from "../../../../../../selectors";
import { HorizontalRule } from "../../../../../UI";
import { Body } from "./Body";
import { Headers } from "./Headers";

type DeliveryDetailsProps = {
  deliveryId: string;
  webhookId: string;
};

type Tab = "request" | "response";

export function DeliveryDetails({
  deliveryId,
  webhookId,
}: DeliveryDetailsProps) {
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const { data, isLoading } = useGetWebhookDeliveryQuery({
    deliveryId,
    webhookId,
    workspaceId,
  });

  const [activeTab, setActiveTab] = useState<Tab>("request");
  const [showHeaders, setShowHeaders] = useState(false);
  const toggleHeaders = () => setShowHeaders((current) => !current);
  const [showPayload, setShowPayload] = useState(false);
  const togglePayload = () => setShowPayload((current) => !current);

  if (isLoading || !data) {
    return null;
  }

  const body = data[activeTab === "request" ? "requestBody" : "responseBody"];

  const headers =
    data[activeTab === "request" ? "requestHeaders" : "responseHeaders"];

  return (
    <Container>
      <Header>
        <HeaderButton
          active={activeTab === "request"}
          onClick={() => setActiveTab("request")}
        >
          Request
        </HeaderButton>
        <HeaderButton
          active={activeTab === "response"}
          onClick={() => setActiveTab("response")}
        >
          Response
        </HeaderButton>
        <StatusCode statusCode={data.statusCode}>{data.statusCode}</StatusCode>
      </Header>
      <Section>
        <Toggle
          iconType={showHeaders ? "caret_down" : "caret_right"}
          buttonSize="small"
          onClick={toggleHeaders}
        />
        <SectionName onClick={toggleHeaders}>Headers</SectionName>
        {showHeaders && headers && <Headers headers={headers} />}
        <Line />
      </Section>
      <Section>
        <Toggle
          iconType={showPayload ? "caret_down" : "caret_right"}
          buttonSize="small"
          onClick={togglePayload}
        />
        <SectionName onClick={togglePayload}>Payload</SectionName>
        {showPayload && body && headers && (
          <Body body={body} headers={headers} />
        )}
      </Section>
    </Container>
  );
}

const Container = styled.div(
  ({ theme }) => css`
    grid-area: details;
    background: ${theme.colorBase100};
    border: 1px solid ${theme.colorBase300};
    border-radius: ${theme.borderRadius500};
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
);

const Header = styled.header(
  ({ theme }) =>
    css`
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 8px;
      background: ${theme.colorBase200};
      border-bottom: 1px solid ${theme.colorBase300};
    `,
);

const HeaderButton = styled(Button)<{ active: boolean }>(
  ({ active, theme }) => css`
    background: ${active ? theme.colorBase600 : "transparent"};
    color: ${active ? theme.colorBackground : theme.colorForeground};
    border: none;
  `,
);

const StatusCode = styled.span<{ statusCode?: number }>(
  ({ statusCode, theme }) => `
  font: ${theme.fontStudioButtonsButtonCopyRegularShortHand};
  letter-spacing: ${theme.fontStudioButtonsButtonCopyRegularLetterSpacing};
  color: ${getStatusColor(statusCode, theme)};
`,
);

const getStatusColor = (
  statusCode: number | undefined,
  theme: DefaultTheme,
) => {
  if (statusCode && statusCode >= 200 && statusCode <= 299) {
    return theme.colorSuccess500;
  }

  return theme.colorDanger500;
};

const Section = styled.div`
  display: grid;
  grid:
    "toggle name" auto
    "toggle content" auto
    "toggle line" auto
    / auto 1fr;
  gap: 8px;
  padding: 0 8px;
`;

const Toggle = styled(IconButton)`
  grid-area: toggle;
  margin-top: -4px;
`;

const SectionName = styled.span(
  ({ theme }) => css`
    grid-area: name;
    cursor: pointer;
    font: ${theme.fontNotebooksCodeShortHand};
    letter-spacing: ${theme.fontNotebooksCodeLetterSpacing};
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    font-weight: 600;
    color: ${theme.colorForeground};
  `,
);

const Line = styled(HorizontalRule)(
  ({ theme }) => css`
    grid-area: line;
    margin: 0;
    width: 100%;
    border-color: ${theme.colorBase300};
  `,
);
