import type {
  MenuItemGroupProps,
  MenuItemProps,
  MenuItemWithDescriptionProps,
  MenuItemWithSubMenuProps,
} from "../Menu";

export type FilterMenuItem =
  | { type: "divider"; id: string }
  | ({ type: "item_group"; id: string; items: Array<FilterMenuItem> } & Omit<
      MenuItemGroupProps,
      "children"
    >)
  | ({ type: "item_with_description" } & MenuItemWithDescriptionProps)
  | ({ type: "item_with_icons" } & MenuItemProps)
  | ({ type: "item_with_submenu"; items: Array<FilterMenuItem> } & Omit<
      MenuItemWithSubMenuProps,
      "children"
    >);
