import type {
  ActiveFormatting,
  CellWithMetadata,
  NotebookState as FiberKitNotebookState,
} from "../types";

export type NotebookState = Omit<
  FiberKitNotebookState,
  | "cells"
  | "error"
  | "pendingChanges"
  | "pendingIncomingOperations"
  | "pendingOperations"
  | "undoRedo"
> & {
  // Cells are represented the way our old notebook state looked to minimize
  // impact on the rest of our codebase.
  readonly cellIds: ReadonlyArray<string>;
  readonly cellsById: Readonly<{ [id: string]: CellWithMetadata }>;

  readonly error?: Error | string;

  // Pending operations are kept outside of the JS state to avoid serialization
  // overhead, and they're not needed except for these simple counters:
  readonly numPendingIncomingOperations: number;
  readonly numPendingOperations: number;
  readonly numUnsentPendingOperations: number;
};

const epoch = new Date(1970, 0, 1, 0, 0, 0, 0).toISOString();

export const initialNotebookState: NotebookState = {
  cellIds: [],
  cellsById: {},
  confirmedRevision: 1,
  console: {
    expanded: false,
    height: 0,
    messages: [],
  },
  createdAt: "",
  createdBy: { type: "unknown" },
  editor: {
    activeFormatting: {} as ActiveFormatting,
    focus: { type: "none" },
    highlightFocus: false,
  },
  frontMatter: {},
  frontMatterSchema: [],
  id: "",
  labels: [],
  loading: false,
  numPendingIncomingOperations: 0,
  numPendingOperations: 0,
  numUnsentPendingOperations: 0,
  readOnly: false,
  remoteRevision: undefined,
  revision: 1,
  selectedDataSources: {},
  subscriberSessions: [],
  threads: {},
  timeRange: { from: epoch, to: epoch },
  title: "",
  unrecoverable: false,
  updatedAt: "",
  visibility: "private",
  workspaceId: "",
};
