import { useCallback, useState } from "react";

import { useTimeout } from "../../../hooks";
// biome-ignore lint/style/noNamespaceImport: This is the recommended way to import css modules
import * as styles from "./styles.module.css";

const { shake: shakeClassName } = styles;

type Result = {
  /**
   * Triggers a shake.
   */
  shake: () => void;
  /**
   * Class name to apply to show the shake when it is active.
   */
  shakeClassName?: string;
};

export { shakeClassName };

export function useShake(): Result {
  const [shakeVisible, setShakeVisible] = useState(false);
  const timeoutCallback = shakeVisible
    ? () => setShakeVisible(false)
    : undefined;
  useTimeout(timeoutCallback, 820);
  return {
    shake: useCallback(() => setShakeVisible(true), []),
    shakeClassName: shakeVisible ? shakeClassName : undefined,
  };
}
