import {
  getActiveQuerySchema,
  selectNotebookTimeRange,
  selectSelectedRichTextInCell,
} from "../../selectors";
import type { RootState } from "../../state";
import { type Cell, hasFormatting, isContentCell } from "../../types";
import {
  formatTimeRange,
  getQueryField,
  getTimeRangeFieldName,
  parseIntent,
  setQueryField,
} from "../../utils";
import type { ExportRange } from "./types";

/**
 * Exports the given cells as JSON.
 *
 * As part of the export, the time range of the notebook gets hardcoded into any
 * time-range-supporting cells that did not specify their own. This is to avoid
 * the situation where graphs don't show any data because the time range of the
 * data doesn't align with the time range of the notebook it is pasted in.
 */
export function exportCellsJson(
  state: RootState,
  cells: Array<Cell>,
  range: ExportRange,
): Array<Cell> {
  return cells.map((cell) =>
    withSelectedText(state, withTimeRange(state, cell), range),
  );
}

function withTimeRange(state: RootState, cell: Cell): Cell {
  if (cell.type === "provider") {
    try {
      const intent = parseIntent(cell.intent);
      const schema = getActiveQuerySchema(state, intent);
      if (schema) {
        const timeRangeFieldName = getTimeRangeFieldName(schema);
        if (timeRangeFieldName) {
          const timeRange = getQueryField(cell.queryData, timeRangeFieldName);
          if (!timeRange) {
            const notebookTimeRange = selectNotebookTimeRange(state);
            const queryData = setQueryField(
              cell.queryData,
              timeRangeFieldName,
              formatTimeRange(notebookTimeRange),
            );

            return { ...cell, queryData };
          }
        }
      }
    } catch (error) {
      console.warn(
        `Failed to include time range in cell with intent "${cell.intent}": ${error}`,
      );
    }
  }

  return cell;
}

function withSelectedText(
  state: RootState,
  cell: Cell,
  range: ExportRange,
): Cell {
  if (range.type === "all" || !isContentCell(cell)) {
    return cell;
  }

  const richText = selectSelectedRichTextInCell(state, cell, range);
  if (!richText) {
    return cell;
  }

  return hasFormatting(cell)
    ? { ...cell, content: richText.text, formatting: richText.formatting }
    : { ...cell, content: richText.text };
}
