import { capitalize } from "./capitalize";

/**
 * Returns a user-friendly display name for the provider type.
 *
 * By default, provider types are simply capitalized, but some provider types
 * may be treated specially.
 */
export function formatProviderType(providerType: string): string {
  switch (providerType) {
    case "https":
      return "HTTPS";
    default:
      return capitalize(providerType);
  }
}
