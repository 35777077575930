import type { Api } from "../services";

export const START_LOADING_PROXY_LIST = "proxies/list/startLoading";
export const FINISH_LOADING_PROXY_LIST = "proxies/list/finishLoading";
export const FAIL_LOADING_PROXY_LIST = "proxies/list/failLoading";

export const START_LOADING_PROXY = "proxies/proxy/startLoading";
export const FINISH_LOADING_PROXY = "proxies/proxy/finishLoading";
export const FAIL_LOADING_PROXY = "proxies/proxy/failLoading";

export type ProxiesAction =
  | StartLoadingProxyList
  | FinishLoadingProxyList
  | FailLoadingProxyList
  | StartLoadingProxy
  | FinishLoadingProxy
  | FailLoadingProxy;

type StartLoadingProxyList = {
  type: typeof START_LOADING_PROXY_LIST;
};

type FinishLoadingProxyList = {
  type: typeof FINISH_LOADING_PROXY_LIST;
  payload: Array<Api.ProxySummary>;
};

type FailLoadingProxyList = {
  type: typeof FAIL_LOADING_PROXY_LIST;
  payload: string;
};

type StartLoadingProxy = {
  type: typeof START_LOADING_PROXY;
  payload: { name: string };
};

type FinishLoadingProxy = {
  type: typeof FINISH_LOADING_PROXY;
  payload: Api.Proxy;
};

type FailLoadingProxy = {
  type: typeof FAIL_LOADING_PROXY;
  payload: {
    error: string;
    name: string;
  };
};
