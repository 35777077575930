import { useHandler } from "@fiberplane/hooks";
import { IconButton } from "@fiberplane/ui";
import { useSelector } from "react-redux";
import { generatePath } from "react-router";
import { push } from "redux-first-history";
import { css, styled } from "styled-components";

import { ROUTES } from "../../constants";
import { type ViewColorSet, useViewColor } from "../../hooks";
import { selectActiveWorkspaceNameOrThrow } from "../../selectors";
import { dispatch } from "../../store";
import type { View } from "../../types";
import { noop, parseColor } from "../../utils";
import { LabelsEditor } from "../LabelsEditor";
import { PageContent, StyledLabel } from "../UI";
import { ViewToggleMenu } from "./ViewToggleMenu";

type Props = {
  views: Array<View>;
};

export function ViewGrid({ views }: Props) {
  return (
    <PageContent>
      <Grid>
        {views.map((view) => (
          <GridItem key={view.id} view={view} />
        ))}
      </Grid>
    </PageContent>
  );
}

const GridItem = ({ view }: { view: View }) => {
  const workspaceName = useSelector(selectActiveWorkspaceNameOrThrow);
  const { colorSet } = useViewColor(view.color);

  const onClick = useHandler(() => {
    dispatch(
      push(generatePath(ROUTES.View, { workspaceName, viewName: view.name })),
    );
  });

  return (
    <RotatingContainer>
      <ViewCard onClick={onClick} colorSet={colorSet}>
        <IconContainer>
          <IconButton name={view.name} iconType="views" />
          <ViewToggleMenu
            view={view}
            toggleElement={({ anchorRef, opened }) => (
              <div ref={anchorRef}>
                <ToggleIconButton iconType="dots_three" $isActive={opened} />
              </div>
            )}
          />
        </IconContainer>
        <div>
          <DisplayName data-testid="view-displayName">
            {view.displayName}
          </DisplayName>
          <Description>{view.description}</Description>
        </div>
        <StyledLabelsEditor
          labels={view.labels}
          onBlur={noop}
          editor={undefined}
          type="view"
        />
      </ViewCard>
    </RotatingContainer>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 16px;

  @media (min-width: 768px) {
    gap: 32px;
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
`;

const DisplayName = styled.strong`
  font: ${({ theme }) => theme.fontStudioHeadingsH3ShortHand};
  letter-spacing: ${({ theme }) => theme.fontStudioHeadingsH3LetterSpacing};
`;

const Description = styled.p`
  font: ${({ theme }) => theme.fontNotebooksSmallestShortHand};
  letter-spacing: ${({ theme }) => theme.fontNotebooksSmallestLetterSpacing};
  margin: 0;
`;

const StyledLabelsEditor = styled(LabelsEditor)`
  gap: 4px;

  ${StyledLabel} {
    pointer-events: none;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;

  & > button:first-of-type {
    background: rgba(255, 255, 255, 20%);
  }
`;

const ViewCard = styled.div<{
  colorSet: ViewColorSet;
}>(({ theme, colorSet }) => {
  const [r, g, b] = parseColor(colorSet.default);
  const shadowColor = `rgba(${r}, ${g}, ${b}, 50%)`;

  return css`
    transition: background 200ms ease-out;
    cursor: pointer;
    background: ${colorSet.default};
    color: ${theme.colorBackground};
    padding: 20px;
    box-shadow: 0 4px 20px -2px ${shadowColor};
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    border-radius: 20px;
    height: 192px;

    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 12px;

    ${StyledLabel} {
      padding: 4px;
      background: rgba(255, 255, 255, 20%);
      color: ${theme.colorBackground};
      font: ${theme.fontStudioStrongExtraSmallShortHand};
    }

    &:hover {
      background: ${colorSet.highlight};
    }
  `;
});

const RotatingContainer = styled.div`
  position: relative;

  &::before,
  &::after {
    transform-origin: top left;
    transition: transform 100ms ease-out;
    content: "";
    position: absolute;
    top: 2px;
    left: 24px;
    height: 164px;
    background: ${({ theme }) => theme.colorBackground};
    box-shadow: 0 0 8px -2px rgba(0, 0, 0, 40%);

    border-radius: ${({ theme }) => theme.borderRadius600};
    z-index: -1;
    pointer-events: none;
  }

  &::before {
    width: calc(100% - 48px);
  }

  &::after {
    width: calc(100% - 36px);
  }

  &:hover {
    &::before {
      transform: rotate(-4deg);
      transform: matrix(0.99, -0.14, 0.14, 0.99, 0, 0);
    }

    &::after {
      transform: rotate(-1deg);
      transform: matrix(1, -0.07, 0.07, 1, 0, 0);
    }

    &:active {
      ${/* sc-selector */ ViewCard},
      &::before,
      &::after {
        transform-origin: center;
        transition: 200ms ease-out;
        transform: rotate(-2deg) scale(1.1);
        opacity: 0.3;
      }
    }
  }
`;

const ToggleIconButton = styled(IconButton)<{ $isActive: boolean }>(
  ({ $isActive }) => css`
    background: ${$isActive ? "rgba(255, 255, 255, 20%)" : "transparent"};

    &:hover {
      background: rgba(255, 255, 255, 20%);
    }
  `,
);
