import type { LogRecordIndex } from "../types";

/**
 * Returns whether two record indices match.
 *
 * This function can use partial application. If you don't supply `other`, it
 * will return a matcher function.
 */
export function recordIndexMatches(
  index: LogRecordIndex,
  other: LogRecordIndex,
): boolean;
export function recordIndexMatches(
  index: LogRecordIndex,
): (other: LogRecordIndex) => boolean;
export function recordIndexMatches(
  index: LogRecordIndex,
  other?: LogRecordIndex,
): boolean | ((other: LogRecordIndex) => boolean) {
  if (!other) {
    return (other) => recordIndexMatches(index, other);
  }

  return (
    index.linkIndex === other.linkIndex &&
    index.recordIndex === other.recordIndex
  );
}
