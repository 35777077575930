import { useMemo } from "react";
import { type Control, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";

import { useSearchNotebooksQuery } from "../../../api";
import { selectActiveWorkspaceIdOrThrow } from "../../../selectors";
import type { NotebookSummary, View } from "../../../types";
import { NotebookPageItem } from "../../Notebooks";
import {
  PageContentList,
  PageContentPlaceholder,
  PageContentPlaceholderDescription,
  PageContentPlaceholderText,
} from "../../UI";
import { SortToggleMenu } from "./SortToggleMenu";

const EMPTY_NOTEBOOKS_LIST: Array<NotebookSummary> = [];

type Props = {
  canMutate: boolean;
  control: Control<View>;
  isPreview: boolean;
};

export function NotebookList({ canMutate, control, isPreview }: Props) {
  const labels = useWatch({ control, name: "labels" });
  const relativeTime = useWatch({ control, name: "relativeTime" });
  const sortBy = useWatch({ control, name: "sortBy" });
  const sortDirection = useWatch({ control, name: "sortDirection" });
  const viewName = useWatch({ control, name: "name" });

  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);

  const searchParameters = useMemo(
    () =>
      isPreview
        ? {
            labels,
            relativeTime,
            sortBy,
            sortDirection,
            workspaceId,
          }
        : {
            viewName,
            workspaceId,
          },

    [
      isPreview,
      labels,
      relativeTime,
      sortBy,
      sortDirection,
      viewName,
      workspaceId,
    ],
  );

  const { data: notebooks = EMPTY_NOTEBOOKS_LIST } = useSearchNotebooksQuery(
    searchParameters,
    {
      skip: labels.length === 0,
      refetchOnMountOrArgChange: true,
    },
  );

  return labels.length > 0 && notebooks.length > 0 ? (
    <PageContentList>
      <SortToggleMenu canMutate={canMutate} control={control} />
      {notebooks.map((notebook) => (
        <NotebookPageItem key={notebook.id} {...notebook} />
      ))}
    </PageContentList>
  ) : (
    <PageContentPlaceholder icon="notebook">
      <PageContentPlaceholderText>
        No notebooks in this view
      </PageContentPlaceholderText>
      <PageContentPlaceholderDescription>
        {labels.length === 0
          ? "Add labels to set up a smart collection of notebooks"
          : "No notebooks match the labels you provided"}
      </PageContentPlaceholderDescription>
    </PageContentPlaceholder>
  );
}
