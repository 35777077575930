import { dispatch, getState } from "../../../store";
import type {
  HttpRequest,
  HttpRequestError,
  HttpResponse,
  Result,
} from "./types";
import type { Imports } from ".";
import { showNotice, withActiveNotebook } from "../../../actions";

const RELAY_ENDPOINT_REGEX =
  /^\/api\/proxies\/([\w.~!$&'()*+,;=-]|%[0-9a-fA-F]{2})+\/relay([/?]|^)/;

export const imports: Imports = {
  log,
  makeHttpRequest,
  now,
  random,
};

function log(message: string) {
  dispatch(withActiveNotebook(showNotice("Provider log: " + message)));
  // biome-ignore lint/suspicious/noConsoleLog: seems appropriate here
  console.info("Provider log: " + message);
}

async function makeHttpRequest(
  request: HttpRequest,
): Promise<Result<HttpResponse, HttpRequestError>> {
  try {
    const headers = new Headers();
    if (request.headers) {
      for (const [key, value] of Object.entries(request.headers)) {
        headers.append(key, value);
      }
    }

    // This is a bit of a work-around to allow the Proxy provider to submit
    // requests to the relay endpoint using our own access token:
    if (RELAY_ENDPOINT_REGEX.test(request.url)) {
      headers.append(
        "Authorization",
        `Bearer ${getState().authentication.token}`,
      );
    }

    const response = await fetch(request.url, {
      body: request.body,
      headers,
      method: request.method,
    });

    if (response.ok) {
      const headers: Record<string, string> = {};
      for (const [key, value] of response.headers) {
        headers[key] = value;
      }

      return ok({
        body: new Uint8Array(await response.arrayBuffer()),
        statusCode: response.status,
        headers,
      });
    } else {
      return err({
        type: "server_error",
        statusCode: response.status,
        response: new Uint8Array(await response.arrayBuffer()),
      });
    }
  } catch (error: any) {
    return err({
      type: "other",
      reason: error.toString() || "no error details",
    });
  }
}

function now(): string {
  return new Date().toISOString();
}

function random(length: number): Array<number> {
  const array = [];
  for (let i = 0; i < length; i++) {
    array.push(Math.ceil(Math.random() * 256));
  }

  return array;
}

function ok<T, E>(result: T): Result<T, E> {
  return { Ok: result };
}

function err<T, E>(error: E): Result<T, E> {
  return { Err: error };
}
