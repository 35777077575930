type DiscussionsLoadingState = "none" | "pending" | "success" | "error";

export type ThreadState = {
  showReplies: boolean;
  showThreadDeletionPrompt: boolean;
  submitCommentStatus: DiscussionsLoadingState;
};

export type DiscussionsState = {
  fetchThreadsStatus: DiscussionsLoadingState;
  threads: Map<string, ThreadState>;
};
