import { shallowEqual, useSelector } from "react-redux";
import { css, keyframes, styled } from "styled-components";

import { cancelEvent } from "@fiberplane/ui";
import { useRef } from "react";
import { createPortal } from "react-dom";
import { startDrag, stopDrag, updateDropTarget } from "../../actions";
import {
  selectActiveDrag,
  selectActiveDragFormatSupported,
} from "../../selectors";
import type { DropTarget } from "../../state";
import { dispatch, getState } from "../../store";
import {
  addImageCell,
  addImageCellAtEnd,
  moveCell,
  moveCellToEnd,
} from "../../thunks";
import { validateImageFileSize, validateImageFileType } from "../../utils";
import DndFileError from "./DndFileError.svg";

// Margin around cells that we still consider a valid drop area.
const CELL_DROP_MARGIN = 2;

type Props = {
  children: React.ReactNode;
};

export function DragAndDropContainer({ children }: Props) {
  const ref = useRef<HTMLDivElement>(null);

  const isActiveDragFormatSupported = useSelector(
    selectActiveDragFormatSupported,
  );

  const onDragLeave = (event: React.DragEvent) => {
    if (ref.current === event.target) {
      cancelEvent(event);

      if (selectActiveDrag(getState())) {
        dispatch(stopDrag());
      }
    }
  };

  return (
    <Component
      data-drag-and-drop-container
      onDragEnter={onDragEnter}
      onDragEnd={onDragLeave}
      onDragOver={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      ref={ref}
      unsupportedFormat={isActiveDragFormatSupported === false}
    >
      {isActiveDragFormatSupported === false &&
        createPortal(
          <DragEventError>
            <DragEventErrorContainer>
              <StyledDndFileError />

              <ErrorText>File type not compatible</ErrorText>
              <ErrorText>We only support JPEG, PNG and Gif</ErrorText>
            </DragEventErrorContainer>
          </DragEventError>,
          document.body,
        )}

      {children}
    </Component>
  );
}

function onDragEnter(event: React.DragEvent) {
  cancelEvent(event);

  const target = getDropTarget(event);
  if (!target || event.dataTransfer.types.includes("text/plain")) {
    return;
  }

  const activeDrag = selectActiveDrag(getState());
  if (activeDrag) {
    if (!shallowEqual(activeDrag.target, target)) {
      dispatch(updateDropTarget(target));
    }
  } else if (event.dataTransfer.items.length > 0) {
    const isImage = validateImageFileType(event.dataTransfer.items);
    dispatch(startDrag({ isFormatSupported: isImage, target }));
  }
}

function onDrop(event: React.DragEvent) {
  cancelEvent(event);

  const activeDrag = selectActiveDrag(getState());
  if (!activeDrag) {
    return;
  }

  // Handle file drops:
  const file = event.dataTransfer.files[0];
  if (file) {
    const isImage = validateImageFileType(event.dataTransfer.items);
    if (isImage) {
      const fileError = validateImageFileSize(file);

      const { target } = activeDrag;
      if (target.type === "end_of_notebook") {
        dispatch(addImageCellAtEnd({ file, error: fileError }));
      } else {
        dispatch(
          addImageCell({
            relatedId: target.cellId,
            position: target.type === "after_cell" ? "after" : "before",
            file,
            error: fileError,
          }),
        );
      }
    }
    dispatch(stopDrag());
    return;
  }

  // Handle cell drags:
  const { draggedCellId } = activeDrag;
  if (draggedCellId) {
    const { target } = activeDrag;
    if (target.type === "end_of_notebook") {
      dispatch(moveCellToEnd(draggedCellId));
    } else if (draggedCellId !== target.cellId) {
      dispatch(
        moveCell({
          subjectCellId: draggedCellId,
          position: target.type === "after_cell" ? "after" : "before",
          targetCellId: target.cellId,
        }),
      );
    }
  }

  dispatch(stopDrag());
}

function getDropTarget(event: React.DragEvent): DropTarget | null {
  const { target, clientY } = event;
  if (!(target instanceof Element)) {
    return null;
  }

  const containerEl = target.closest("[data-drag-and-drop-container]");
  if (!(containerEl instanceof HTMLElement)) {
    return null;
  }

  // We iterate over potential drop targets to make sure we can take drop
  // margins into account.
  let dropTarget: DropTarget = { type: "end_of_notebook" };
  for (const cellEl of containerEl.querySelectorAll("[data-cell-id]")) {
    const clientRect = cellEl.getBoundingClientRect();
    if (clientRect.top > clientY + CELL_DROP_MARGIN) {
      break; // Current cell is below where we're dragging, don't look further.
    }

    if (clientRect.bottom < clientY - CELL_DROP_MARGIN) {
      continue; // Current cell is above where we're dragging, continue.
    }

    const cellId = (cellEl as HTMLElement).dataset.cellId;
    if (!cellId) {
      throw new Error("Cell element without cell ID :shrug:");
    }

    dropTarget = {
      type:
        Math.abs(clientRect.top - clientY) <
        Math.abs(clientRect.bottom - clientY)
          ? "before_cell"
          : "after_cell",
      cellId,
    };
  }

  return dropTarget;
}

const Component = styled.div<{ unsupportedFormat: boolean }>`
  display: flex;
  flex: 1;
  ${({ unsupportedFormat }) => unsupportedFormat && fileErrorHoverStyling}
`;

const fileErrorHoverStyling = css`
  &::after {
    display: block;
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const DragEventError = styled.div`
  pointer-events: none;
  height: 100%;
  width: 100%;
  background: color-mix(in srgb, ${({ theme }) => theme.color.bg.default}, transparent 25%)
    radial-gradient(circle at center, ${({ theme }) => theme.color.bg.default} 10%, transparent 90%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-duration: 0.25s;
  animation-name: ${fadeInAnimation};
`;

const DragEventErrorContainer = styled.div`
  text-align: center;
  overflow: visible;
`;

const ErrorText = styled.h2`
  margin: 0;
`;

const StyledDndFileError = styled(DndFileError)`
  width: 200px;
  height: 140px;
`;
