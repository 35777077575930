import { IconButton, type IconType } from "@fiberplane/ui";
import type { MouseEvent } from "react";
import styled, { css } from "styled-components";

type ToolbarButtonProps = {
  active: boolean;
  iconType: IconType;
  onMouseDown: (event: MouseEvent<HTMLButtonElement>) => void;
  shortcut?: string;
  tooltipText: string;
};

export function ToolbarButton({
  active,
  iconType,
  onMouseDown,
  shortcut,
  tooltipText,
}: ToolbarButtonProps) {
  return (
    <StyledIconButton
      $active={active}
      aria-label={tooltipText}
      buttonStyle="tertiary-grey"
      data-shortcut={shortcut}
      data-tooltip-placement="top"
      iconType={iconType}
      onMouseDown={onMouseDown}
    />
  );
}

const StyledIconButton = styled(IconButton)<{ $active: boolean }>(
  ({ $active, theme }) => css`
  ${
    $active &&
    css`
    background-color: ${theme.color.bg.elevated.hover};
    color: ${theme.color.fg.default}
  `
  }
`,
);
