import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from "../actions";
import { selectNotifications } from "../selectors";
import type { Notification } from "../state";
import type { Thunk } from "../store";
import { uuid64 } from "../utils";

export function addNotification(notification: Notification): Thunk<string> {
  return (dispatch) => {
    const key = uuid64();
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        key,
        notification,
      },
    });

    if (!notification.disableAutoHide) {
      setTimeout(
        () =>
          dispatch({
            type: REMOVE_NOTIFICATION,
            payload: key,
          }),
        4000,
      );
    }

    return key;
  };
}

export function addErrorNotification(errorMessage: string): Thunk {
  return addNotification({
    title: errorMessage,
    type: "danger",
    disableAutoHide: true,
  });
}

export function updateNotification(
  key: string,
  notification: Notification,
): Thunk<string> {
  return (dispatch, getState) => {
    const originalNotification = selectNotifications(getState()).get(key);
    if (!originalNotification) {
      console.warn(
        "No notification exists for this given key. Changing the behaviour to 'add notification' instead",
      );

      return dispatch(addNotification(notification));
    }

    if (
      notification.disableAutoHide === true &&
      originalNotification.disableAutoHide !== true
    ) {
      console.warn("Auto hide on notifications cannot be disabled on update");
    }

    if (
      notification.disableAutoHide !== true &&
      originalNotification.disableAutoHide === true
    ) {
      setTimeout(
        () =>
          dispatch({
            type: REMOVE_NOTIFICATION,
            payload: key,
          }),
        4000,
      );
    }

    dispatch({
      type: UPDATE_NOTIFICATION,
      payload: {
        key,
        notification,
      },
    });

    return key;
  };
}

export function removeNotification(key: string): Thunk {
  return (dispatch) =>
    dispatch({
      type: REMOVE_NOTIFICATION,
      payload: key,
    });
}
