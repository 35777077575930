import type { CommandMenuVariant } from "../state";

export const SET_COMMAND_MENU_SELECTED_INDEX = "commandMenu/setSelectedIndex";
export const SET_COMMAND_MENU_QUERY = "commandMenu/setQuery";
export const SET_COMMAND_MENU_VARIANT = "commandMenu/setVariant";

export const SET_COMMAND_MENU_LABEL_SUGGESTIONS =
  "commandMenu/label/setSuggestions";

export type CommandMenuAction =
  | SetCommandMenuSelectedIndex
  | SetCommandMenuQuery
  | SetCommandMenuVariantAction
  | SetCommandMenuLabelSuggestions;

type SetCommandMenuSelectedIndex = {
  type: typeof SET_COMMAND_MENU_SELECTED_INDEX;
  payload: number;
};

type SetCommandMenuQuery = {
  type: typeof SET_COMMAND_MENU_QUERY;
  payload: string;
};

type SetCommandMenuVariantAction = {
  type: typeof SET_COMMAND_MENU_VARIANT;
  payload: CommandMenuVariant;
};

type SetCommandMenuLabelSuggestions = {
  type: typeof SET_COMMAND_MENU_LABEL_SUGGESTIONS;
  payload: {
    keys: Array<string>;
    values: Array<string>;
  };
};

export const setCommandMenuSelectedIndex = (
  index: number,
): CommandMenuAction => ({
  type: SET_COMMAND_MENU_SELECTED_INDEX,
  payload: index,
});

export const setCommandMenuQuery = (query: string): CommandMenuAction => ({
  type: SET_COMMAND_MENU_QUERY,
  payload: query,
});

export const clearCommandMenuQuery = (): CommandMenuAction => ({
  type: SET_COMMAND_MENU_QUERY,
  payload: "",
});

export const setCommandMenuVariant = (
  variant: CommandMenuVariant,
): CommandMenuAction => ({
  type: SET_COMMAND_MENU_VARIANT,
  payload: variant,
});
