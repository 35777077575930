import {
  type Cell,
  type CellDrafts,
  type CellFocus,
  type CellFocusPosition,
  type NotebookFocus,
  type ThreadItem,
  hasTextField,
  isContentCell,
} from "../types";
import { getCellTextUsingExternalSources } from "./cellUtils";
import { getEndOffset, getStartOffset } from "./notebookFocus";
import { getSelection, isCellInSelection } from "./selections";
import { charCount } from "./unicode";

const noFocus: CellFocus & NotebookFocus = { type: "none" };

export const noCellFocus: CellFocus = noFocus;
export const noNotebookFocus: NotebookFocus = noFocus;

export function cellFocusesAreEqual(
  focus: CellFocus,
  other: CellFocus,
): boolean {
  switch (focus.type) {
    case "none":
      return other.type === "none";

    case "collapsed":
      return other.type === "collapsed"
        ? cellFocusPositionsAreEqual(focus, other)
        : false;

    case "selection":
      return other.type === "selection"
        ? cellSelectionsAreEqual(focus, other)
        : false;
  }
}

export function cellFocusPositionsAreEqual(
  position: CellFocusPosition,
  other: CellFocusPosition,
): boolean {
  return position.field === other.field && position.offset === other.offset;
}

export function cellSelectionsAreEqual(
  focus: CellFocus & { type: "selection" },
  other: CellFocus & { type: "selection" },
): boolean {
  if (
    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version (which is less readable)
    !cellFocusPositionsAreEqual(focus.start, other.start) ||
    !cellFocusPositionsAreEqual(focus.end, other.end)
  ) {
    return false;
  }

  return focus.focus && other.focus
    ? cellFocusPositionsAreEqual(focus.focus, other.focus)
    : // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version (which is less readable)
      !focus.focus && !other.focus;
}

/**
 * Returns the focus scoped to a single cell.
 *
 * The returned focus will ignore the direction of the notebook focus, meaning
 * the anchor of the cell focus corresponds to the start offset of the notebook
 * focus, while the focus position corresponds to the end offset.
 *
 * @param cellIds Ordered list of all the cell IDs in the notebook.
 * @param cell The cell to which to scope the focus.
 * @param drafts The input drafts for inputs with fields.
 * @param focus The focus as applied to the entire notebook.
 */
export function getCellFocus(
  cellIds: ReadonlyArray<string>,
  cell: Cell,
  drafts: CellDrafts,
  threadItems: Array<ThreadItem>,
  focus: NotebookFocus,
): CellFocus {
  switch (focus.type) {
    case "none":
      return noCellFocus;

    case "collapsed":
      return focus.cellId === cell.id
        ? { type: "collapsed", field: focus.field, offset: focus.offset }
        : noCellFocus;

    case "selection":
      return getCellFocusWithSelection(
        cellIds,
        cell,
        drafts,
        threadItems,
        focus,
      );
  }
}

export function getCellFocusEnd(focus: CellFocus): number | undefined {
  switch (focus.type) {
    case "collapsed":
      return focus.offset;

    case "selection":
      return focus.end.offset;
  }
}

export function getCellFocusStart(focus: CellFocus): number | undefined {
  switch (focus.type) {
    case "collapsed":
      return focus.offset;

    case "selection":
      return focus.start.offset;
  }
}

/**
 * Returns the offset where the focus position (the blinking cursor) should be.
 */
export function getCursorOffset(
  focus: CellFocus,
  field?: string,
): number | undefined {
  switch (focus.type) {
    case "collapsed": {
      if (focus?.field === field ?? true) {
        return focus.offset;
      }

      break;
    }

    case "selection": {
      if (focus.focus?.field === field ?? true) {
        return focus.focus?.offset;
      }

      break;
    }
  }
}

function getCellFocusWithSelection(
  cellIds: ReadonlyArray<string>,
  cell: Cell,
  drafts: CellDrafts,
  threadItems: Array<ThreadItem>,
  focus: NotebookFocus & { type: "selection" },
): CellFocus {
  const cellId = cell.id;
  const selection = getSelection(cellIds, focus);

  if (!isCellInSelection(cellIds, cellId, selection)) {
    return noCellFocus;
  }

  const { start, end } = selection;
  const startField = cellId === start.cellId ? start.field : undefined;
  const endField = cellId === end.cellId ? end.field : undefined;

  let startOffset: number | undefined;
  let endOffset: number | undefined;
  if (isContentCell(cell) || hasTextField(cell)) {
    startOffset = cellId === start.cellId ? getStartOffset(cellIds, focus) : 0;

    endOffset =
      cellId === end.cellId
        ? getEndOffset(cellIds, focus)
        : charCount(
            getCellTextUsingExternalSources(
              cell,
              drafts,
              threadItems,
              getField(focus),
            ) ?? "",
          );
  }

  if (
    start.cellId === end.cellId &&
    startOffset === endOffset &&
    startField === endField
  ) {
    return {
      type: "collapsed",
      field: startField,
      offset: startOffset,
    };
  }

  const selectionStart = { field: startField, offset: startOffset };
  const selectionEnd = { field: endField, offset: endOffset };
  let selectionFocus: CellFocusPosition | undefined;
  if (selection.focus.cellId === cellId) {
    selectionFocus = selection.focus === start ? selectionStart : selectionEnd;
  }

  return {
    type: "selection",
    start: selectionStart,
    end: selectionEnd,
    focus: selectionFocus,
  };
}

/**
 * Returns whether the given cell focus is in the given field.
 */
export function hasFocusPosition(focus: CellFocus, field?: string): boolean {
  switch (focus.type) {
    case "none":
      return false;

    case "collapsed":
      return focus.field === field;

    case "selection":
      return (
        focus.focus !== undefined &&
        (focus.start.field === field || focus.end.field === field)
      );
  }
}

export function toNotebookFocus(
  focus: CellFocus,
  cellId: string,
): NotebookFocus {
  switch (focus.type) {
    case "none":
      return noNotebookFocus;

    case "collapsed":
      return { ...focus, cellId };

    case "selection": {
      const start = { ...focus.start, cellId };
      const end = { ...focus.end, cellId };
      const anchor = focus.start === focus.focus ? end : start;
      const focus_ = focus.start === focus.focus ? start : end;
      return { type: "selection", anchor, focus: focus_ };
    }
  }
}

/**
 * Returns a copy of the cell focus with the `field` property set to the given
 * field.
 */
export function withField(focus: CellFocus, field: string): CellFocus {
  switch (focus.type) {
    case "none":
      return focus;

    case "collapsed":
      return { ...focus, field };

    case "selection": {
      const start = { ...focus.start, field };
      const end = { ...focus.end, field };

      let newFocus: CellFocusPosition | undefined;
      if (focus.focus) {
        newFocus = focus.focus === focus.start ? start : end;
      }

      return { type: "selection", start, end, focus: newFocus };
    }
  }
}

export function getField(focus: NotebookFocus | CellFocus): string | undefined {
  switch (focus.type) {
    case "none":
      return;

    case "collapsed":
      return focus.field;

    case "selection":
      return focus.focus?.field;
  }
}
