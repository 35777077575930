import type { ReactElement } from "react";

import type { ExtendedAnnotation } from "../types";
import type { KeyedEditorNode } from "./EditorNode";
import { RENDERABLE_ENTITY_INPUT } from "./EntityInputEditorNode";
import { RENDERABLE_LABEL } from "./LabelEditorNode";
import { RENDERABLE_MENTION } from "./MentionEditorNode";
import { RENDERABLE_TIMESTAMP } from "./TimestampEditorNode";

// An annotation that renders itself as a React element, instead of merely updating formatting.
export type RenderableAnnotation<A extends ExtendedAnnotation> = {
  // Does this renderable annotation support 'annotation'?
  supports: (annotation: ExtendedAnnotation) => boolean;
  // Returns the plain text representation as can be found in the original text
  getPlainText: (annotation: A) => string;
  // Returns the React element to be rendered
  getElement: (annotation: A, props: KeyedEditorNode) => ReactElement;
};

// biome-ignore lint/suspicious/noExplicitAny: TS really doesn't like this otherwise...
export const RENDERABLE_ANNOTATIONS: Array<RenderableAnnotation<any>> = [
  RENDERABLE_MENTION,
  RENDERABLE_TIMESTAMP,
  RENDERABLE_LABEL,
  RENDERABLE_ENTITY_INPUT,
];
