/**
 * Creates an object from the specified input object's properties.
 */
export function pick<
  T extends Record<string, unknown>,
  K extends Array<keyof T>,
>(object: T, ...propNames: K): Pick<T, K[number]> {
  return Object.fromEntries(
    Object.entries(object).filter(([key]) => propNames.includes(key)),
  ) as Pick<T, K[number]>;
}
