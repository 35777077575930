import type { Setting } from "../../../types";

export const SETTINGS: Array<Setting> = [
  {
    iconType: "user_circle",
    section: "profile",
    title: "Profile",
    category: "preferences",
  },
  {
    iconType: "bolt_duotone",
    section: "experimental",
    title: "Experimental features",
    description: "Be the first to try out new features.",
    category: "preferences",
  },
  {
    category: "preferences",
    description: (
      <>
        Enhance your Fiberplane experience with a wide variety of add-ons and
        integrations.
      </>
    ),
    iconType: "plugs",
    section: "personalIntegrations",
    title: "Integrations",
  },
  {
    iconType: "moon_duotone",
    section: "theme",
    title: "Theme Switcher",
    description: (
      <>
        Switch between light and dark mode.
        <br />
        <em>Note:</em> this feature is in active development and may cause
        breaking UI changes.
      </>
    ),
    category: "preferences",
    feature: "theme-switcher",
  },
  {
    description: "Workspace settings",
    iconType: "settings_duotone",
    section: "workspace",
    title: "Workspace",
    category: "workspace",
  },
  {
    category: "workspace",
    description: "Manage members",
    iconType: "users_three",
    section: "members",
    title: "Members",
  },
  {
    category: "workspace",
    description: (
      <>
        Data sources are the way you configure access to services like
        Prometheus. It can be done either directly, which means your browser
        needs to be able to access it directly or through an FPD instance.
      </>
    ),
    iconType: "database",
    section: "data-sources",
    title: "Data sources",
  },
  {
    category: "workspace",
    description: (
      <>
        FPD instances allow you to make calls to data sources such as Prometheus
        without needing to make them accessible from the internet. You can add
        an FPD instance by clicking New+ or by using our CLI. For more
        information see our quickstart guide{" "}
        <a
          href="https://docs.fiberplane.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </>
    ),
    iconType: "proxy",
    section: "fpd",
    title: "FPD",
  },
  {
    category: "workspace",
    description: (
      <>
        Webhooks allow your web application to receive real-time notifications
        of changes to notebooks by sending POST requests to a specified endpoint
        URL. Learn more about webhooks in our{" "}
        <a
          href="https://docs.fiberplane.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          documentation
        </a>
        .
      </>
    ),
    iconType: "webhook",
    section: "webhooks",
    title: "Webhooks",
  },
  {
    category: "workspace",
    description: (
      <>
        Enhance your Fiberplane experience with a wide variety of add-ons and
        integrations.
      </>
    ),
    iconType: "plugs",
    section: "workspaceIntegrations",
    title: "Integrations",
  },
];

type CategorizedSetting = Array<{
  category: Setting["category"];
  settings: Array<Setting>;
}>;

export const CATEGORIZED_SETTINGS = SETTINGS.reduce<CategorizedSetting>(
  (accumulator, setting) => {
    const { category } = setting;

    const categorySettings = accumulator.find((c) => c.category === category);
    if (categorySettings) {
      categorySettings.settings.push(setting);
      return accumulator;
    }

    accumulator.push({
      category,
      settings: [setting],
    });

    return accumulator;
  },
  [],
);
