import { memo } from "react";
import { useSelector } from "react-redux";
import { css, styled } from "styled-components";

import { selectNotebookFrontMatterSchema } from "../../../../selectors";
import { AddFrontMatter } from "../AddFrontMatter";
import { FrontMatterField } from "./FrontMatterField";

/**
 * Container for all front-matter cells for the notebook
 * As of writing, passes each cell the front-matter interface that's returned by `useFrontMatter`
 */
export const FrontMatterCells = memo(function FrontMatterCells({
  readOnly = true,
}: { readOnly?: boolean }) {
  const schema = useSelector(selectNotebookFrontMatterSchema);
  return (
    <FrontMatterCellsContainer>
      <PropsContainer>
        {schema.map((field) => {
          const { key: fieldKey, schema } = field;
          return (
            <FrontMatterField
              key={fieldKey}
              fieldKey={fieldKey}
              schema={schema}
              readOnly={readOnly}
            />
          );
        })}
      </PropsContainer>
      <AddFrontMatter readOnly={readOnly} />
    </FrontMatterCellsContainer>
  );
});

// STYLES

const PropsContainer = styled.div(
  ({ theme }) => css`
    font: ${theme.fontStudioBodyCopySmallShortHand};
    letter-spacing: ${theme.fontStudioBodyCopySmallLetterSpacing};
    display: gap;
    gap: 4px;
    margin: 0;
  `,
);

const FrontMatterCellsContainer = styled.div`
  margin: ${({ theme }) => theme.spacingParagraph};
  margin-top: 0;
  margin-bottom: 0;
`;
