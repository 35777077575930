import type { Cell } from "../../types";

/**
 * A mapping from old IDs to their replacement IDs.
 */
type Replacements = {
  [oldId: string]: string;
};

/**
 * Returns the given `cell` with its ID updated to `newId`.
 */
export function withNewId(cell: Cell, newId: string): Cell {
  if (cell.type === "provider") {
    const { id, output, ...rest } = cell;
    return {
      id: newId,
      output: output?.map((cell) =>
        withUpdatedReferences(cell, { [id]: newId }),
      ),
      ...rest,
    };
  }

  return { ...cell, id: newId };
}

/**
 * Returns the given `cell` with any references to other cell IDs updated.
 *
 * `references` is given as a map from old IDs to their replacement IDs.
 */
function withUpdatedReferences(cell: Cell, replacements: Replacements): Cell {
  switch (cell.type) {
    case "graph":
    case "log": {
      const { id, dataLinks, ...rest } = cell;
      return {
        id: getIdWithUpdatedReferences(id, replacements),
        dataLinks: (dataLinks ?? []).map((link) =>
          getDataLinkWithUpdatedReferences(link, replacements),
        ),
        ...rest,
      };
    }

    case "provider": {
      const { id, output, ...rest } = cell;
      const newId = getIdWithUpdatedReferences(id, replacements);
      return {
        id: newId,
        output: output?.map((cell) =>
          withUpdatedReferences(cell, { ...replacements, [id]: newId }),
        ),
        ...rest,
      };
    }

    default: {
      const { id, ...rest } = cell;
      return { id: getIdWithUpdatedReferences(id, replacements), ...rest };
    }
  }
}

function getDataLinkWithUpdatedReferences(
  link: string,
  replacements: Replacements,
): string {
  if (link.startsWith("cell-data:")) {
    const commaIndex = link.lastIndexOf(",");
    if (commaIndex > -1) {
      const cellId = link.slice(commaIndex + 1);
      return `${link.slice(0, commaIndex)},${getIdWithUpdatedReferences(
        cellId,
        replacements,
      )}`;
    }
  }

  return link;
}

function getIdWithUpdatedReferences(
  id: string,
  replacements: Replacements,
): string {
  for (const [oldId, newId] of Object.entries(replacements)) {
    if (oldId === id) {
      return newId;
    }

    if (id.startsWith(`${oldId}/`)) {
      return `${newId}/${id.slice(oldId.length + 1)}`;
    }
  }

  return id;
}
