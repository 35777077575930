import { createPortal } from "react-dom";
import { styled } from "styled-components";

export function TooltipPortal({ children }: React.PropsWithChildren) {
  return createPortal(
    <TooltipRootElement>{children}</TooltipRootElement>,
    document.body,
  );
}

const TooltipRootElement = styled.div`
  pointer-events: none;
  position: relative;
  z-index: 10000;
`;
