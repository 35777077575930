import { useHandler } from "@fiberplane/hooks";
import { useState, useSyncExternalStore } from "react";

import {
  getCookieValue,
  setCookieValue,
  subscribeToCookieValue,
} from "../utils";

export function useCookie(
  name: string,
): [value: string | undefined, setValue: (value: string) => void] {
  const [value, setValue] = useState(getCookieValue(name));
  const subscribe = useHandler(() => subscribeToCookieValue(name, setValue));

  useSyncExternalStore(subscribe, () => value);

  return [
    value,
    useHandler((value) => {
      setCookieValue(name, value);
      setValue(value);
    }),
  ];
}
