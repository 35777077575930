import {
  type Cell,
  type Label,
  type NotebookAction,
  type NotebookContextMenuInfo,
  type NotebookFocus,
  type Operation,
  type ToggleTimeseriesVisibilityPayload,
  isContentCell,
} from "../types";
import { charCount } from "../utils";
import { replaceCells } from "./editorActions";

type AddCellAtIndexOptions = {
  cell: Cell;
  focus?: NotebookFocus;
  index: number;
  closeMenu?: NotebookContextMenuInfo;
};

export const addCellAtIndex = ({
  cell,
  closeMenu,
  focus,
  index,
}: AddCellAtIndexOptions) =>
  replaceCells({
    newCells: [{ cell, index }],
    closeMenu,
    focus: focus ?? {
      type: "collapsed",
      cellId: cell.id,
      offset: isContentCell(cell) ? 0 : undefined,
    },
  });

export const addNotebookLabel = (label: Label) =>
  applyOwnOperation({ type: "add_label", label });

export type ApplyOwnOperationOptions = {
  /**
   * The context menu that should be closed as a result of applying these
   * operations.
   */
  closeMenu?: NotebookContextMenuInfo;

  /**
   * New focus to set after applying the operations.
   *
   * If omitted, FiberKit will attempt to determine a sensible new focus based
   * on the operations.
   */
  focus?: NotebookFocus;
};

/**
 * Applies an operation that was created by ourselves and sends it to the API.
 */
export const applyOwnOperation = (
  operation: Operation,
  options?: ApplyOwnOperationOptions,
): NotebookAction => ({
  type: "apply_own_operation",
  payload: {
    operation,
    closeMenu: options?.closeMenu,
    focus: options?.focus,
    undoOptions: {
      canUndo: true,
      isUndo: false,
      popFromPendingOperations: false,
    },
  },
});

export type RemoveCellOptions = {
  cursorDirection?: "forward" | "backward";
  focusCell?: Cell;
};

export function removeCell(id: string, options?: RemoveCellOptions) {
  return removeCells([id], options);
}

export function removeCells(
  cellIds: Array<string>,
  options?: RemoveCellOptions,
) {
  return replaceCells({
    oldCellIds: cellIds,
    focus: focusForRemoveCellOptions(options),
  });
}

export function focusForRemoveCellOptions(
  options?: RemoveCellOptions,
): NotebookFocus | undefined {
  if (!options) {
    return;
  }

  const { cursorDirection, focusCell } = options;
  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version (which is less readable)
  if (!cursorDirection || !focusCell) {
    return;
  }

  let offset: number | undefined;
  if (isContentCell(focusCell)) {
    offset = cursorDirection === "forward" ? 0 : charCount(focusCell.content);
  }

  return { type: "collapsed", cellId: focusCell.id, offset };
}

export const removeNotebookLabel = (label: Label) =>
  applyOwnOperation({ type: "remove_label", label });

export const replaceNotebookLabel = (oldLabel: Label, newLabel: Label) =>
  applyOwnOperation({ type: "replace_label", oldLabel, newLabel });

export const resolveCellErrors = (cellIds: Array<string>): NotebookAction => ({
  type: "resolve_cell_errors",
  payload: { cellIds },
});

export const startForking = (forkedAt: number): NotebookAction => ({
  type: "start_forking",
  payload: { forkedAt },
});

export const toggleTimeseriesVisibility = (
  payload: ToggleTimeseriesVisibilityPayload,
): NotebookAction => ({
  type: "toggle_timeseries_visibility",
  payload,
});
