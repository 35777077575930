import { TIMELINE_MIME_TYPE, TIMESERIES_MIME_TYPE } from "../../../constants";
import { selectActiveWorkspaceIdOrThrow } from "../../../selectors";
import { getState } from "../../../store";
import type {
  ApiEvent,
  Blob,
  Cell,
  Metric,
  Provider,
  ProviderError,
  ProviderRequest,
  Result,
  SupportedQueryType,
  TimeRange,
  Timeline,
  Timeseries,
} from "../../../types";
import {
  getQueryField,
  parseLabels,
  parseTimeRange,
  range,
  secondsToTimestamp,
  timestampToSeconds,
  toQueryData,
} from "../../../utils";
import { listEvents } from "../../Api";
import { UnsupportedQueryTypeError } from "../errors";

const EVENT_TIMESERIES_QUERY_TYPE = "event-timeseries";
const EVENT_TIMELINE_QUERY_TYPE = "event-timeline";
const SUPPORTED_QUERY_TYPES = new Set([
  EVENT_TIMESERIES_QUERY_TYPE,
  EVENT_TIMELINE_QUERY_TYPE,
]);

const LABELS_FIELD_NAME = "labels";
const TIME_RANGE_FIELD_NAME = "time_range";

export function createFiberplaneProvider(): Provider {
  return {
    createCells,
    getConfigSchema,
    getSupportedQueryTypes,
    invoke,
    extractData,
  };
}

function createCells(queryType: string): Promise<Array<Cell>> {
  switch (queryType) {
    case EVENT_TIMESERIES_QUERY_TYPE: {
      const graphCell: Cell = {
        id: "graph",
        dataLinks: [`cell-data:${TIMESERIES_MIME_TYPE},self`],
        graphType: "bar",
        readOnly: false,
        stackingType: "none",
        type: "graph",
      };

      return Promise.resolve([graphCell]);
    }

    case EVENT_TIMELINE_QUERY_TYPE: {
      const timelineCell: Cell = {
        id: "timeline",
        dataLinks: [`cell-data:${TIMELINE_MIME_TYPE},self`],
        type: "timeline",
      };
      return Promise.resolve([timelineCell]);
    }

    default:
      return Promise.reject(new UnsupportedQueryTypeError(queryType));
  }
}

function getConfigSchema(): never {
  throw new Error("Cannot configure Fiberplane provider locally");
}

function getSupportedQueryTypes(): Promise<Array<SupportedQueryType>> {
  const timeseriesQueryType: SupportedQueryType = {
    label: "Events timeseries",
    queryType: EVENT_TIMESERIES_QUERY_TYPE,
    schema: [
      {
        name: LABELS_FIELD_NAME,
        label: "Choose one or more labels to select events",
        multiple: true,
        placeholder: "",
        required: true,
        type: "label",
      },
      {
        name: TIME_RANGE_FIELD_NAME,
        label: "Specify a time range",
        placeholder: "",
        required: true,
        type: "date_time_range",
      },
    ],
    mimeTypes: [`${TIMESERIES_MIME_TYPE}+json`],
  };

  const timelineQueryType: SupportedQueryType = {
    label: "Events timeline",
    queryType: EVENT_TIMELINE_QUERY_TYPE,
    schema: [
      {
        name: LABELS_FIELD_NAME,
        label: "Choose one or more labels to select events",
        multiple: true,
        placeholder: "",
        required: true,
        type: "label",
      },
      {
        name: TIME_RANGE_FIELD_NAME,
        label: "Specify a time range",
        placeholder: "",
        required: true,
        type: "date_time_range",
      },
    ],
    mimeTypes: [`${TIMELINE_MIME_TYPE}+json`],
  };

  return Promise.resolve([timeseriesQueryType, timelineQueryType]);
}

async function invoke({
  queryType,
  queryData,
}: ProviderRequest): Promise<Result<Blob, ProviderError>> {
  if (!SUPPORTED_QUERY_TYPES.has(queryType)) {
    throw new UnsupportedQueryTypeError(queryType);
  }

  const queryDataString = toQueryData(queryData);

  const timeRange = parseTimeRange(
    getQueryField(queryDataString, TIME_RANGE_FIELD_NAME),
  );
  if (!timeRange) {
    const error = {
      fieldName: TIME_RANGE_FIELD_NAME,
      message: "No time range given",
    };
    // biome-ignore lint/style/useNamingConvention: the Result concept comes from rust and follows that naming convention
    return { Err: { type: "validation_error", errors: [error] } };
  }

  const labelsString = getQueryField(queryDataString, LABELS_FIELD_NAME);
  const labels = parseLabels(labelsString);

  const workspaceId = selectActiveWorkspaceIdOrThrow(getState());
  const events = await listEvents(workspaceId, timeRange, labels);

  const { data, mimeType } = getDataForQuery(
    queryType,
    events,
    timeRange,
    labels,
  );

  const response: Blob = {
    data: new TextEncoder().encode(JSON.stringify(data)),
    mimeType,
  };

  // biome-ignore lint/style/useNamingConvention: the Result concept comes from rust and follows that naming convention
  return { Ok: response };
}

function getDataForQuery(
  queryType: string,
  events: Array<ApiEvent>,
  timeRange: TimeRange,
  labels: Record<string, string>,
) {
  switch (queryType) {
    case EVENT_TIMESERIES_QUERY_TYPE: {
      const timeseries: Timeseries = {
        name: "Events with labels",
        metrics: eventsToMetrics(events, timeRange),
        labels,
        attributes: emptyObject,
        resource: emptyObject,
        visible: true,
      };
      return {
        mimeType: `${TIMESERIES_MIME_TYPE}+json`,
        data: [timeseries],
      };
    }

    case EVENT_TIMELINE_QUERY_TYPE: {
      const { days, eventsByDay } = events.reduce<{
        days: Set<string>;
        eventsByDay: Timeline["eventsByDay"];
      }>(
        ({ days, eventsByDay }, item) => {
          const date = item.occurrenceTime.substring(0, 10);
          const bucket = eventsByDay[date] || [];
          days.add(date);
          bucket.push(item);
          eventsByDay[date] = bucket;
          return { days, eventsByDay };
        },
        { days: new Set(), eventsByDay: {} },
      );
      const timeline: Timeline = {
        days: [...days],
        eventsByDay,
        attributes: emptyObject,
        resource: emptyObject,
      };

      return {
        mimeType: `${TIMELINE_MIME_TYPE}+json`,
        data: [timeline],
      };
    }

    default:
      throw new UnsupportedQueryTypeError(queryType);
  }
}

/**
 * Converts an array of events to an array of metrics to be used as part of a
 * `Timeseries`.
 *
 * The given time range is divided into "buckets" and each metric represents
 * the amount of events that occurred in each such bucket.
 */
function eventsToMetrics(
  events: Array<ApiEvent>,
  timeRange: TimeRange,
): Array<Metric> {
  const from = timestampToSeconds(timeRange.from);
  const to = timestampToSeconds(timeRange.to);
  const numBuckets = 30;
  const bucketSize = (to - from) / numBuckets;

  return range(0, numBuckets)
    .map((bucketIndex) => from + bucketIndex * bucketSize)
    .map((bucketStart) => {
      const bucketEnd = bucketStart + bucketSize;
      const numEventsInBucket = events.filter((event) => {
        const time = timestampToSeconds(event.occurrenceTime);
        return time >= bucketStart && time < bucketEnd;
      }).length;

      return {
        time: secondsToTimestamp(bucketStart),
        value: numEventsInBucket,
        attributes: emptyObject,
        resource: emptyObject,
      };
    });
}

const emptyObject = {};

function extractData(): Promise<Result<Blob, ProviderError>> {
  // biome-ignore lint/style/useNamingConvention: the Result concept comes from rust and follows that naming convention
  return Promise.resolve({ Err: { type: "unsupported_request" } });
}
