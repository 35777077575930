import { stringToNumber } from "../stringToNumber";

const SUPPORT_COLOR_COUNT = 11;

export type SupportColorSet = {
  100: string;
  200: string;
  300: string;
  400: string;
};

export function getSupportColorSetForUser(userId: string): SupportColorSet {
  const num = (stringToNumber(userId) % (SUPPORT_COLOR_COUNT - 1)) + 1;

  return {
    100: `colorSupport${num}100`,
    200: `colorSupport${num}200`,
    300: `colorSupport${num}300`,
    400: `colorSupport${num}400`,
  };
}
