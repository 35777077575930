import { css, styled } from "styled-components";

import { Box } from "../Box";

export const ConsoleHeader = styled(Box)<{ isResizing?: boolean }>(
  ({ isResizing, theme }) => css`
    display: flex;
    align-items: center;
    font: ${theme.fontStudioStrongSmallShortHand};
    position: relative;
    padding: 10px 20px;
    gap: 10px;
    color: ${theme.color.fg.muted};
    cursor: ${isResizing ? "grabbing" : "inherit"};
  `,
);
