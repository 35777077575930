import { useHandler } from "@fiberplane/hooks";
import { Button } from "@fiberplane/ui";
import { useEffect, useRef, useState } from "react";
import { useAddGithubPullRequestToNotebookMutation } from "../../../api";
import { normalizeException } from "../../../errors";
import { selectActiveNotebookId } from "../../../selectors";
import { type Thunk, useAppDispatch } from "../../../store";
import { addNotification, hideModal } from "../../../thunks";
import {
  ButtonBar,
  Modal,
  ModalErrorHint,
  ModalForm,
  ModalLabel,
  ModalLabelText,
  ModalTextInput,
} from "../../UI";

export function CreateGitHubPullRequestModal() {
  const [url, setUrl] = useState("");

  const [addGithubPullRequestNotebook, result] =
    useAddGithubPullRequestToNotebookMutation();
  const dispatch = useAppDispatch();

  // TODO: (JF) Move this to a separate thunk
  const addField =
    (url: string): Thunk<Promise<void>> =>
    async (_dispatch, getState) => {
      const id = selectActiveNotebookId(getState());
      const result = await addGithubPullRequestNotebook({
        url,
        notebookId: id,
      });

      //.Check if there's no error
      if ("error" in result === false) {
        dispatch(hideModal("createGitHubPullRequest"));
      }
    };

  const [submitting, setSubmitting] = useState(false);
  const [nameValidationError, setNameValidationError] = useState<string | null>(
    null,
  );

  const onSubmit = useHandler(() => {
    if (submitting) {
      return;
    }

    let hasErrors = false;
    const regex =
      /https:\/\/github\.com\/(?<owner>[^\/]+)\/(?<repoName>[^\/]+)\/pull\/(?<prId>\d+)/;
    const match = url.match(regex);

    if (!url) {
      setNameValidationError("URL is required");
      hasErrors = true;
    } else if (match) {
      setNameValidationError(null);
    } else {
      setNameValidationError("Invalid URL");
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    setSubmitting(true);
    dispatch(addField(url)).then(() => {
      setSubmitting(false);
    });
  });

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (result.error) {
      const normalized = normalizeException(result.error);
      dispatch(
        addNotification({
          title: normalized.message,
          type: "danger",
        }),
      );
    }
  }, [result.error, dispatch]);

  return (
    <Modal
      data-testid="create-github-pull-request-modal"
      title="Add GitHub Pull Request"
      description="Add a reference to a GitHub pull request to your notebook front-matter."
    >
      <ModalForm
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit();
        }}
      >
        <ModalLabel>
          <ModalLabelText>Url</ModalLabelText>
          <ModalTextInput
            ref={inputRef}
            autoFocus
            data-testid="url"
            aria-invalid={!!nameValidationError || undefined}
            onChange={(event) => setUrl(event.target.value)}
            placeholder="Enter a github url"
          />
          {nameValidationError && (
            <ModalErrorHint>{nameValidationError}</ModalErrorHint>
          )}
        </ModalLabel>
        <ButtonBar>
          <Button disabled={submitting} onClick={onSubmit} type="submit">
            Add
          </Button>
        </ButtonBar>
      </ModalForm>
    </Modal>
  );
}

export function extractInfo(url: string):
  | undefined
  | {
      owner: string;
      repoName: string;
      prId: string;
    } {
  const regex =
    /https:\/\/github\.com\/(?<owner>[^\/]+)\/(?<repoName>[^\/]+)\/pull\/(?<prId>\d+)/;
  const match = url.match(regex);
  const { owner, repoName, prId } = match?.groups || {};
  if (owner && repoName && prId) {
    return {
      owner,
      repoName,
      prId,
    };
  }
}
