import { useRef, useState } from "react";

import type { LabelsEditorState } from "../../state";
import type { Label } from "../../types";
import { HoverToolbar } from "./Toolbars";
import { FadedNotebookLabel, StyledListItem } from "./styled";
import type { LabelItemOptions } from "./types";

type Props = LabelItemOptions & {
  editor?: LabelsEditorState;
  faded: boolean;
  label: Label;
  popperKey: string;
  readOnly?: boolean;
};

export function LabelItem({
  faded,
  label,
  onDelete,
  onEdit,
  popperKey,
  readOnly,
  withToolbar,
}: Props) {
  const ref = useRef<HTMLLIElement>(null);
  const [hovered, setHovered] = useState(false);

  return (
    <StyledListItem
      ref={ref}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {withToolbar && !readOnly && hovered && (
        <HoverToolbar
          element={ref.current}
          key={popperKey}
          label={label}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      )}
      <FadedNotebookLabel
        label={label}
        faded={faded}
        onClick={
          onEdit && !readOnly ? (event) => onEdit(event, label) : undefined
        }
        onDeleteRequested={
          onDelete && !readOnly ? (event) => onDelete(event, label) : undefined
        }
      />
    </StyledListItem>
  );
}
