import { useHandler } from "@fiberplane/hooks";
import { memo } from "react";
import { useSelector } from "react-redux";
import { css, styled } from "styled-components";

import { cancelEvent } from "@fiberplane/ui";
import { isSurrogateId } from "../../constants";
import { selectActiveNotebookId } from "../../selectors";
import { dispatch } from "../../store";
import { addCell } from "../../thunks";
import { track } from "../../utils";
import { CellAddIcon } from "../UI";

type Props = {
  cellId: string;
  focused: boolean;
  selected?: boolean;
};

export const CellFocusIndicatorWithButtons = memo(
  function CellFocusIndicatorWithButtons({ cellId, focused, selected }: Props) {
    const notebookId = useSelector(selectActiveNotebookId);
    const isSurrogateCell = isSurrogateId(cellId);

    const cellAdd = useHandler((position: "before" | "after") => {
      if (cellId) {
        track("notebook | add cell", {
          source: "add icon",
          position,
          notebookId,
          cellType: "text",
        });

        dispatch(addCell({ relatedId: cellId, position }));
      }
    });

    return (
      <CellFocusWithButtonsContainer
        focused={focused}
        selected={selected}
        data-testid={focused ? "active-focus-indicator" : ""}
      >
        {!isSurrogateCell && (
          <>
            <CellAddIcon
              onClick={(event) => {
                cancelEvent(event);
                cellAdd("before");
              }}
              aria-label="Add a cell above"
              data-no-tooltip
              data-insert-before
            />
            <CellAddIcon
              onClick={(event) => {
                cancelEvent(event);
                cellAdd("after");
              }}
              aria-label="Add a cell below"
              data-no-tooltip
              data-insert-after
              insertAfter
            />
          </>
        )}
      </CellFocusWithButtonsContainer>
    );
  },
);

export const CellFocusWithButtonsContainer = styled.div<{
  focused: Props["focused"];
  selected?: Props["selected"];
}>(
  ({ focused, selected, theme }) => css`
    position: relative;
    width: 1px;
    border-radius: ${theme.radius.full};

    ${
      focused &&
      css`
        background-color: ${theme.color.bg["hover-primary"]};
    `
    }

    ${
      selected &&
      !focused &&
      css`
        background-color: ${theme.color.bg.emphasis.neutral};
      `
    };
  `,
);
