import { useEffect, useMemo, useRef, useState } from "react";
import type { FrontMatterUser } from "../../../../../types";
import {
  compact,
  isArrayOfType,
  isFrontMatterUser,
  setZeroTimeout,
} from "../../../../../utils";
import { useFocused, useFrontMatterValue } from "../../hooks";
import { FieldContentContainer } from "../shared";
import { UserSelector, type UserSelectorHandle } from "./UserSelector";
import { UserSuggestionsMenu } from "./UserSuggestionsMenu";

type Props = Readonly<{
  onChange: (value: Array<FrontMatterUser> | undefined) => void;
  name: string;
  readOnly?: boolean;
  id: string;
}>;

/**
 * Front-matter cell that is used to select a user (like for instance an incident commander)
 * - When in edit mode, uses an input to filter user suggestions
 */
export function FieldUserMultiple({ id, onChange, readOnly }: Props) {
  const users = useFrontMatterValue(id, validateUsers) ?? [];
  const focused = useFocused(id);

  const [focusedUserIndex, setFocusedUserIndex] = useState(users.length);
  const focusedIndex = Math.min(focusedUserIndex, users.length);

  const addOrUpdateUser = (
    index: number,
    user: FrontMatterUser | undefined,
  ) => {
    const newUsers: Array<FrontMatterUser | undefined> = [...users];
    if (index < newUsers.length) {
      newUsers[index] = user;
    }

    if (user && index === newUsers.length) {
      newUsers.push(user);
    }

    return onChange(compact(newUsers));
  };

  useEffect(() => {
    if (!focused) {
      setFocusedUserIndex(users.length);
    }
  }, [focused, users.length]);

  // Holds a reference to the currently selected user selector
  const selectorRef = useRef<UserSelectorHandle>(null);

  const [initialFilterText, setInitialFilterText] = useState<
    string | undefined
  >();

  // biome-ignore lint/correctness/useExhaustiveDependencies: grab focus when the index changed && filterText is undefined
  useEffect(() => {
    if (initialFilterText === undefined && selectorRef.current) {
      selectorRef.current.focus();
    }
  }, [focusedIndex, initialFilterText]);

  // This list contains ids for users that are already selected
  // and should not be shown in the suggestions menu
  const selectedList = useMemo(() => users.map((user) => user.id), [users]);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <FieldContentContainer
      ref={ref}
      onKeyUp={(event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "ArrowLeft") {
          setFocusedUserIndex((prev) => Math.max(prev - 1, 0));
        } else if (event.key === "ArrowRight") {
          setFocusedUserIndex((prev) => Math.min(prev + 1, users.length));
        }
      }}
    >
      {users.map((user, index) => {
        return (
          <UserSelector
            key={user.id}
            ref={index === focusedIndex ? selectorRef : undefined}
            focused={focused && focusedIndex === index}
            readOnly={readOnly}
            placeholder="Select a user"
            user={user}
            hideClearButton={!focused}
            openContextMenu={(value: string | undefined) => {
              // Use a tiny amount of delay before setting the initial filter text
              // This is useful to avoid the context menu to be closed right after
              // the user clicked on a different user selector
              setZeroTimeout(() => {
                setInitialFilterText(value);
              });
            }}
            onClear={() => {
              addOrUpdateUser(users.length - 1, undefined);
              setFocusedUserIndex((prev) => Math.max(prev - 1, 0));
            }}
            onFocus={() => {
              setFocusedUserIndex(index);
            }}
          />
        );
      })}
      {(users.length === 0 || focused) && (
        <UserSelector
          key={users.length}
          placeholder={
            (users.length === 0 && !focused) || readOnly
              ? "Empty"
              : "Add a user"
          }
          ref={focusedIndex === users.length ? selectorRef : undefined}
          focused={focused && focusedIndex === users.length}
          readOnly={readOnly}
          hideClearButton
          openContextMenu={(value) => {
            // Use a tiny amount of delay before setting the initial filter text
            // This is useful to avoid the context menu to be closed right after
            // the user clicked on a different user selector
            setZeroTimeout(() => setInitialFilterText(value));
          }}
          onFocus={() => {
            setFocusedUserIndex(users.length);
          }}
          onClear={() => {
            if (users.length > 0) {
              addOrUpdateUser(users.length - 1, undefined);
              setFocusedUserIndex((prev) => Math.max(prev - 1, 0));
            }
          }}
          selectedList={selectedList}
        />
      )}
      {initialFilterText !== undefined && (
        <UserSuggestionsMenu
          selectedList={selectedList}
          filterText={initialFilterText}
          element={ref.current}
          keepFocusOnInput
          onActivate={(user) => {
            const foundIndex = users.findIndex((u) => u.id === user.id);

            if (foundIndex === -1) {
              addOrUpdateUser(users.length, user);
              if (foundIndex === users.length) {
                setFocusedUserIndex((prev) => prev + 1);
              }
            } else {
              addOrUpdateUser(foundIndex, foundIndex === -1 ? user : undefined);

              if (foundIndex < focusedIndex) {
                setFocusedUserIndex((prev) => Math.max(prev - 1, 0));
              }
            }
          }}
          onClose={(info) => {
            if (info.reason === "item_activated") {
              return;
            }

            setInitialFilterText(undefined);
          }}
        />
      )}
    </FieldContentContainer>
  );
}

function validateUsers(value: unknown): value is Array<FrontMatterUser> {
  return isArrayOfType(value, isFrontMatterUser);
}
