import { selectSelectedTextInCell } from "../../selectors";
import type { RootState } from "../../state";
import type { Cell } from "../../types";
import type { HeadingCell } from "../../types";
import {
  capitalize,
  formatProviderType,
  getAllRichTextFromTableCell,
  only,
  parseIntent,
} from "../../utils";
import type { ExportRange } from "./types";

/**
 * Exports the given cells as Markdown.
 *
 * TODO: This does not yet apply formatting annotations.
 */
export function exportCellsAsMarkdown(
  state: RootState,
  cells: Array<Cell>,
  range: ExportRange,
): string {
  // If only one cell is selected, we don't want cell-level annotations.
  const onlyCell = only(cells);
  if (onlyCell) {
    return selectSelectedTextInCell(state, onlyCell, range) ?? "";
  }

  let text = "";
  let previousCell: Cell | undefined;
  for (const cell of cells) {
    const cellText = getCellAsMarkdown(state, cell, range);

    const separator = getSeparator(cell, previousCell);
    text += `${separator}${cellText}`;

    previousCell = cell;
  }

  return text;
}

function getCellAsMarkdown(
  state: RootState,
  cell: Cell,
  range: ExportRange,
): string {
  const text = selectSelectedTextInCell(state, cell, range) ?? "";

  switch (cell.type) {
    case "checkbox":
      return `[${cell.checked ? "x" : " "}] ${text}`;

    case "code":
      // biome-ignore lint/style/useTemplate: escaping the backticks is a mess here
      return "```\n" + text + "\n```";

    case "divider":
      return "---";

    // TODO (Oscar):
    // https://linear.app/fiberplane/project/discussion-cell-3b9038a7df70/FP
    case "discussion":
      return "";

    case "text":
      return text;

    case "graph":
    case "image":
    case "log":
    case "timeline": {
      const type = capitalize(cell.type);
      return `[${type}]`;
    }

    case "heading":
      return `${getHeadingPrefix(cell)} ${text}`;

    case "list_item":
      return `${
        cell.listType === "ordered" ? `${cell.startNumber ?? "1"}.` : "-"
      } ${text}`;

    case "provider":
      try {
        const intent = parseIntent(cell.intent);

        let text = `[${formatProviderType(intent.providerType)}]`;
        if (cell.output) {
          text += `\n\n${exportCellsAsMarkdown(state, cell.output, {
            type: "all",
          })}`;
        }

        return text;
      } catch (error) {
        return `[Could not format provider cell: ${error}]`;
      }

    case "table":
      return getAllRichTextFromTableCell(cell).text;
  }
}

function getHeadingPrefix(cell: HeadingCell): string {
  switch (cell.headingType) {
    case "h1":
      return "#";
    case "h2":
      return "##";
    case "h3":
      return "###";
  }
}

function getSeparator(cell: Cell, previousCell?: Cell): string {
  if (!previousCell) {
    return "";
  }

  if (cell.type === "checkbox" && previousCell.type === "checkbox") {
    return "\n";
  } else if (cell.type === "list_item" && previousCell.type === "list_item") {
    return "\n";
  } else {
    return "\n\n";
  }
}
