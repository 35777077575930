import { castDraft } from "immer";

import {
  ADD_DATA_SOURCE_ACTION,
  type AppAction,
  FAIL_LOADING_DATA_SOURCE_LIST,
  FINISH_LOADING_DATA_SOURCE_LIST,
  SET_ACTIVE_WORKSPACE_NAME,
  SET_SUPPORTED_QUERY_TYPES,
  START_LOADING_DATA_SOURCE_LIST,
} from "../actions";
import type { DataSourcesState } from "../state";
import { createImmerReducer } from "../utils";

export const initialState: DataSourcesState = {
  dataSources: { loading: false },
  supportedQueryTypes: {},
};

export const dataSourcesReducer = createImmerReducer<
  AppAction,
  DataSourcesState
>(initialState, {
  [ADD_DATA_SOURCE_ACTION]: (state, action) => {
    state.dataSources.data?.push(action.payload);
  },
  [SET_ACTIVE_WORKSPACE_NAME]: () => initialState,
  [SET_SUPPORTED_QUERY_TYPES]: (state, action) => {
    const { dataSourceKey, queryTypes } = action.payload;
    state.supportedQueryTypes[dataSourceKey] = castDraft(queryTypes);
  },
  [START_LOADING_DATA_SOURCE_LIST]: (state) => {
    state.dataSources.loading = true;
  },
  [FINISH_LOADING_DATA_SOURCE_LIST]: (state, action) => {
    state.dataSources.loading = false;
    state.dataSources.error = undefined;
    state.dataSources.data = action.payload;
  },
  [FAIL_LOADING_DATA_SOURCE_LIST]: (state, action) => {
    state.dataSources.loading = false;
    state.dataSources.error = action.payload;
  },
});
