import { bytesToBase64 } from "byte-base64";

export const UUID64_LENGTH = 22;

/**
 * Returns a URL-safe[1] base64-encoded UUIDv4 identifier.
 *
 * - [1] https://tools.ietf.org/html/rfc3548#section-4
 */
export function uuid64(): string {
  // get sixteen unsigned 8 bit random values
  const uuid = window.crypto.getRandomValues(new Uint8Array(16));

  // set the version bit to v4
  uuid[6] = (uuid[6] || 0x00 & 0x0f) | 0x40;

  // set the variant bit to "don't care" (yes, the RFC
  // calls it that)
  uuid[8] = (uuid[8] || 0x00 & 0xbf) | 0x80;

  return bytesToBase64(uuid)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .slice(0, UUID64_LENGTH);
}

// Regex that matches a string terminating in a URL-safe[1] base64-encoded UUIDv4 identifier (see: `uuid64`)
const URL_PATH_UUID_REGEX = new RegExp(`[-_a-z0-9=]{${UUID64_LENGTH}}$`, "i");

/**
 * Simply extracts the ID from a path segment looking like "My-title-4ctu4l1D".
 *
 * NOTE - Assumes the `segment` terminates with the id
 */
export function extractNotebookIdFromPath(segment: string): string | undefined {
  const match = segment.match(URL_PATH_UUID_REGEX);

  return match?.[0];
}
