import { useEffect } from "react";
import { useSelector } from "react-redux";

import {
  selectActiveWorkspaceId,
  selectConnectionStatus,
} from "../../../selectors";
import { useAppDispatch } from "../../../store";
import {
  subscribeToWorkspace,
  unsubscribeFromWorkspace,
} from "../../../thunks";

export const useSubscribeToWorkspace = () => {
  const workspaceId = useSelector(selectActiveWorkspaceId);
  const connectionStatus = useSelector(selectConnectionStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!workspaceId || connectionStatus !== "connected") {
      return;
    }

    dispatch(subscribeToWorkspace(workspaceId));

    return () => {
      dispatch(unsubscribeFromWorkspace(workspaceId));
    };
  }, [dispatch, workspaceId, connectionStatus]);
};
