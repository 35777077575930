import { css, styled } from "styled-components";

import { AvatarIcon } from "./AvatarIcon";
import { useAvatarImage } from "./useAvatarImage";

type WorkspaceAvatarProps = {
  workspaceId?: string;
} & Omit<
  React.ComponentProps<typeof AvatarIcon>,
  "id" | "src" | "onLoad" | "onError"
>;

export function WorkspaceAvatar({
  workspaceId,
  size = 36,
  ...props
}: WorkspaceAvatarProps) {
  const image = useAvatarImage("workspace", workspaceId);

  return (
    <WorkspaceAvatarIcon
      id={workspaceId}
      size={size}
      showTooltip={false}
      {...image}
      {...props}
    />
  );
}

const WorkspaceAvatarIcon = styled(AvatarIcon)(
  ({ theme }) => css`
    border-radius: ${theme.radius.default};
    border: 1px solid ${theme.color.border.muted};
  `,
);
