import { memo } from "react";

import type { Label as LabelModel } from "../../../../types";
import { NotebookLabel } from "../../../UI";
import type { ExtendedAnnotation } from "../types";
import type { EditorNode, KeyedEditorNode } from "./EditorNode";
import type { RenderableAnnotation } from "./RenderableAnnotation";

type Props = EditorNode & {
  label: LabelModel;
};

export const LabelEditorNode = memo(function LabelEditorNode({
  startOffset,
  endOffset,
  label,
}: Props) {
  return (
    <span data-start-offset={startOffset} data-end-offset={endOffset}>
      <NotebookLabel label={label} />
    </span>
  );
});

type LabelAnnotation = ExtendedAnnotation & { type: "label" };

export const RENDERABLE_LABEL: RenderableAnnotation<LabelAnnotation> = {
  supports: (annotation: ExtendedAnnotation) => annotation.type === "label",
  getPlainText: (annotation: LabelAnnotation) => {
    return annotation.value
      ? `${annotation.key}:${annotation.value}`
      : `${annotation.key}`;
  },
  getElement: (annotation: LabelAnnotation, props: KeyedEditorNode) => {
    const label: LabelModel = {
      key: annotation.key,
      value: annotation.value,
    };

    return <LabelEditorNode {...props} label={label} />;
  },
};
