import { getRequest, parseJsonResponse } from "./utils";

type Trigger = {
  id: string;
  title: string;
  templateId: string;

  /**
   * This key must be passed in the URL to invoke the trigger
   */
  secretKey?: string;

  defaultArguments?: object;
  createdAt: Date;
  updatedAt: Date;
};

export const listTriggers = (workspaceId: string) =>
  getRequest<Array<Trigger>>(
    `/api/workspaces/${workspaceId}/triggers`,
    parseJsonResponse,
  );
