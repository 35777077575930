import { createSelector } from "reselect";

import type { RootState } from "../state";
import { noThreadItems } from "../utils";
import { makeCellSelector } from "./notebookSelectors";
import { selectActiveNotebook } from "./notebooksSelectors";

export const selectNotebookThreads = (state: RootState) =>
  selectActiveNotebook(state).threads;

export const selectNotebookFetchThreadsStatus = (state: RootState) =>
  state.discussions.fetchThreadsStatus;

export const makeNotebookThreadSelector = (threadId: string) =>
  createSelector([selectNotebookThreads], (threads) => threads[threadId]);

export const selectAppThreads = (state: RootState) => state.discussions.threads;

export const makeAppThreadSelector = (threadId: string) =>
  createSelector([selectAppThreads], (threads) => threads.get(threadId));

export const makeThreadItemsSelector = (cellId: string) =>
  createSelector(
    [makeCellSelector(cellId), selectNotebookThreads],
    (cell, threads) =>
      (cell?.type === "discussion" && threads[cell.threadId]?.items) ||
      noThreadItems,
  );
