import { useEffect } from "react";
import { type Control, useController } from "react-hook-form";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

import { selectLabelsEditor, selectViewEditor } from "../../selectors";
import { dispatch } from "../../store";
import type { Label, View } from "../../types";
import { LabelsEditor } from "../LabelsEditor";
import {
  onCreateLabel,
  onDeleteLabel,
  onEditLabel,
  onLabelsEditorBlur,
  onLabelsEditorKeyDown,
} from "./thunks";

type Props = {
  canMutate: boolean;
  control: Control<View>;
};

export function ViewLabelsEditor({ canMutate, control }: Props) {
  const {
    field: { value, onChange },
  } = useController({ control, name: "labels" });

  const labelsEditor = useSelector(selectLabelsEditor);
  const viewEditor = useSelector(selectViewEditor);

  useEffect(() => {
    if (viewEditor?.labels) {
      onChange(viewEditor.labels.value);
    }
  }, [onChange, viewEditor?.labels]);

  return (
    <StyledLabelsEditor
      type="view"
      editor={labelsEditor}
      labels={value}
      onBlur={onBlur}
      onCreate={onCreate}
      onDelete={onDelete}
      onEdit={onEdit}
      onKeyDown={onKeyDown}
      newLabelButtonLabel="Filter on label"
      readOnly={!canMutate}
      withContextMenu
      withToolbar
    />
  );
}

function onBlur(event: React.FocusEvent) {
  dispatch(onLabelsEditorBlur(event));
}

function onCreate(event: React.SyntheticEvent) {
  dispatch(onCreateLabel(event));
}

function onDelete(event: React.SyntheticEvent, label: Label) {
  dispatch(onDeleteLabel(event, label));
}

function onEdit(event: React.SyntheticEvent, label: Label) {
  dispatch(onEditLabel(event, label));
}

function onKeyDown(event: React.KeyboardEvent<HTMLUListElement>) {
  dispatch(onLabelsEditorKeyDown(event));
}

const StyledLabelsEditor = styled(LabelsEditor)`
  grid-area: labels;
`;
