import { type Draft, castDraft, produce } from "immer";

import {
  type AppAction,
  CLEAR_AUTHENTICATION,
  CLOSE_NOTEBOOK,
  type ForkNotebookAction,
  type NotebookAction,
  OPEN_NOTEBOOK,
  SET_ACTIVE_NOTEBOOK,
  WITH_NOTEBOOK,
  isActiveNotebookAction,
} from "../actions";
import { selectAllNotebooksLocal } from "../selectors";
import {
  type NotebookState,
  type NotebooksState,
  initialNotebookState,
} from "../state";
import type {
  CourierAction,
  SideEffectDescriptor,
  StateWithSideEffects,
} from "../types";
import { notebookReducer } from "./notebookReducer";

export const initialState: NotebooksState = {
  activeNotebook: initialNotebookState,
  otherNotebooks: {},
};

export function notebooksReducer(
  state = initialState,
  action: AppAction,
): StateWithSideEffects<NotebooksState> {
  if (action.type.startsWith("courier/")) {
    return handleCourierAction(state, action as CourierAction);
  }

  switch (action.type) {
    case CLEAR_AUTHENTICATION:
      return { state: handleSignOut(state) };

    case CLOSE_NOTEBOOK:
      return {
        state: handleCloseNotebook(state, action.payload.notebookId, {
          newActiveNotebookId: action.payload.newActiveNotebookId,
        }),
      };

    case "fork_notebook":
      return handleForkNotebook(state, action);

    case OPEN_NOTEBOOK:
      return { state: handleOpenNotebook(state, action.payload) };

    case SET_ACTIVE_NOTEBOOK:
      return { state: handleSetActiveNotebook(state, action.payload) };

    case WITH_NOTEBOOK:
      return handleNotebookAction(
        state,
        action.payload.notebookId,
        action.payload.action,
      );

    default:
      return isActiveNotebookAction(action)
        ? activeNotebookReducer(state, action.payload)
        : { state };
  }
}

function activeNotebookReducer(
  state: NotebooksState,
  action: NotebookAction,
): StateWithSideEffects<NotebooksState> {
  const { state: activeNotebook, sideEffects } = notebookReducer(
    state.activeNotebook,
    action,
  );
  return {
    state:
      activeNotebook === state.activeNotebook
        ? state
        : { ...state, activeNotebook },
    sideEffects,
  };
}

function handleCloseNotebook(
  state: NotebooksState,
  notebookId: string,
  options?: { newActiveNotebookId?: string },
): NotebooksState {
  return produce(state, (draft) => {
    if (draft.activeNotebook.id === notebookId) {
      const newActiveNotebook = options?.newActiveNotebookId
        ? draft.otherNotebooks[options.newActiveNotebookId]
        : undefined;
      if (newActiveNotebook) {
        draft.activeNotebook = newActiveNotebook;
        delete draft.otherNotebooks[newActiveNotebook.id];
      } else {
        draft.activeNotebook = castDraft(initialNotebookState);
      }
    } else {
      delete draft.otherNotebooks[notebookId];
    }
  });
}

function handleCourierAction(
  state: NotebooksState,
  courierAction: CourierAction,
): StateWithSideEffects<NotebooksState> {
  const action: NotebookAction = { type: "courier", payload: courierAction };

  let changed = false;
  const aggregatedSideEffects: Array<SideEffectDescriptor> = [];

  const newState = { otherNotebooks: {} } as Draft<NotebooksState>;
  for (const notebook of selectAllNotebooksLocal(state)) {
    const { state: newNotebook, sideEffects } = notebookReducer(
      notebook,
      action,
    );
    if (newNotebook !== notebook) {
      changed = true;
    }

    if (sideEffects && sideEffects.length > 0) {
      aggregatedSideEffects.push(...sideEffects);
      changed = true;
    }

    if (notebook.id === state.activeNotebook.id) {
      newState.activeNotebook = castDraft(newNotebook);
    } else {
      newState.otherNotebooks[newNotebook.id] = castDraft(newNotebook);
    }
  }

  return changed
    ? { state: newState, sideEffects: aggregatedSideEffects }
    : { state };
}

function handleForkNotebook(
  state: NotebooksState,
  action: ForkNotebookAction,
): StateWithSideEffects<NotebooksState> {
  const { notebook, originalNotebookId } = action.payload;

  if (state.activeNotebook.id === originalNotebookId) {
    const { state: activeNotebook, sideEffects } = notebookReducer(
      state.activeNotebook,
      action,
    );
    return { state: { ...state, activeNotebook }, sideEffects };
  }

  const { [originalNotebookId]: originalNotebook, ...otherNotebooks } =
    state.otherNotebooks;
  if (originalNotebook) {
    const { state: otherNotebook, sideEffects } = notebookReducer(
      originalNotebook,
      action,
    );
    return {
      state: {
        ...state,
        otherNotebooks: { ...otherNotebooks, [notebook.id]: otherNotebook },
      },
      sideEffects,
    };
  }

  return { state };
}

function handleNotebookAction(
  state: NotebooksState,
  notebookId: string,
  action: NotebookAction,
): StateWithSideEffects<NotebooksState> {
  if (state.activeNotebook.id === notebookId) {
    return activeNotebookReducer(state, action);
  }

  let changed = false;
  const otherNotebooks: { [id: string]: NotebookState } = {};
  const aggregatedSideEffects: Array<SideEffectDescriptor> = [];
  for (const [id, notebook] of Object.entries(state.otherNotebooks)) {
    if (id === notebookId) {
      const { state: newNotebook, sideEffects } = notebookReducer(
        notebook,
        action,
      );
      if (newNotebook !== notebook) {
        changed = true;
      }

      if (sideEffects && sideEffects.length > 0) {
        aggregatedSideEffects.push(...sideEffects);
        changed = true;
      }

      otherNotebooks[id] = newNotebook;
    } else {
      otherNotebooks[id] = notebook;
    }
  }

  return changed
    ? {
        state: { ...state, otherNotebooks },
        sideEffects: aggregatedSideEffects,
      }
    : { state };
}

function handleOpenNotebook(state: NotebooksState, id: string): NotebooksState {
  const { activeNotebook, otherNotebooks } = state;
  return {
    activeNotebook: { ...initialNotebookState, id },
    otherNotebooks: { ...otherNotebooks, [activeNotebook.id]: activeNotebook },
  };
}

function handleSetActiveNotebook(
  state: NotebooksState,
  id: string,
): NotebooksState {
  const { [id]: newActiveNotebook, ...otherNotebooks } = state.otherNotebooks;
  if (newActiveNotebook) {
    const oldActiveNotebook = state.activeNotebook;
    return {
      activeNotebook: newActiveNotebook,
      otherNotebooks: {
        ...otherNotebooks,
        [oldActiveNotebook.id]: oldActiveNotebook,
      },
    };
  } else {
    return state;
  }
}

function handleSignOut(state: NotebooksState): NotebooksState {
  for (const otherNotebook of Object.values(state.otherNotebooks)) {
    state = handleCloseNotebook(state, otherNotebook.id);
  }

  state = handleCloseNotebook(state, state.activeNotebook.id, {
    newActiveNotebookId: Object.keys(state.otherNotebooks)[0],
  });

  return state;
}
