import type React from "react";
import { useEffect } from "react";
import scrollIntoView from "scroll-into-view-if-needed";

export function useScrollIntoView(
  ref: React.RefObject<Element>,
  shouldScroll: boolean,
) {
  useEffect(() => {
    const container = ref.current;
    if (!(container?.parentElement && shouldScroll)) {
      return;
    }

    scrollIntoView(container, { scrollMode: "if-needed" });
  });
}
