import type { RouterState } from "redux-first-history";

import type { api } from "../api";
import {
  type IntegrationsState,
  type PagerDutyState,
  type ProfileState,
  integrationsSlice,
  pagerDutySlice,
  profileSlice,
} from "../slices";
import type { SideEffectDescriptor } from "../types";
import type { AuthenticationState } from "./authenticationState";
import type { AvatarState as AvatarsState } from "./avatarsState";
import type { CommandMenuState } from "./commandMenuState";
import type { CourierState } from "./courierState";
import type { DataSourcesState } from "./dataSourcesState";
import type { DiscussionsState } from "./discussionsState";
import type { EventsState } from "./eventsState";
import type { LabelsState } from "./labelsState";
import type { NotebooksState } from "./notebooksState";
import type { NotificationsState } from "./notificationsState";
import type { PresenceState } from "./presenceState";
import type { ProxiesState } from "./proxiesState";
import type { TriggerState } from "./triggersState";
import type { UiState } from "./uiState";
import type { ViewsState } from "./viewsState";
import type { WorkspaceState } from "./workspaceState";

export * from "./authenticationState";
export * from "./presenceState";
export * from "./avatarsState";
export * from "./commandMenuState";
export * from "./courierState";
export * from "./dataSourcesState";
export * from "./discussionsState";
export * from "./eventsState";
export * from "./labelsEditorState";
export * from "./labelsState";
export * from "./notebooksState";
export * from "./notebookState";
export * from "./notificationsState";
export * from "./proxiesState";
export * from "./triggersState";
export * from "./uiState";
export * from "./viewsState";
export * from "./workspaceState";

export type RootState = Readonly<{
  [api.reducerPath]: ReturnType<typeof api.reducer>;
  [integrationsSlice.name]: IntegrationsState;
  [pagerDutySlice.name]: PagerDutyState;
  [profileSlice.name]: ProfileState;
  authentication: AuthenticationState;
  avatars: AvatarsState;
  commandMenu: CommandMenuState;
  courier: CourierState;
  presence: PresenceState;
  dataSources: DataSourcesState;
  discussions: DiscussionsState;
  events: EventsState;
  labels: LabelsState;
  notebooks: NotebooksState;
  notifications: NotificationsState;
  proxies: ProxiesState;
  router: RouterState;
  sideEffects: ReadonlyArray<SideEffectDescriptor>;
  triggers: TriggerState;
  ui: UiState;
  views: ViewsState;
  workspaces: WorkspaceState;
}>;
