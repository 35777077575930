import { IconButton } from "@fiberplane/ui";
import { useSelector } from "react-redux";
import { css, styled } from "styled-components";

import { SET_VIEWS_DISPLAY_TYPE } from "../../actions";
import { selectViewsDisplayType } from "../../selectors";
import { dispatch } from "../../store";
import { ButtonBar } from "../UI";

export function DisplayTypeSwitch() {
  const displayType = useSelector(selectViewsDisplayType);

  return (
    <StyledButtonBar>
      <StyledIconButton
        iconType="squares_four"
        $isActive={displayType === "grid"}
        onClick={() =>
          dispatch({ type: SET_VIEWS_DISPLAY_TYPE, payload: "grid" })
        }
        aria-label="Grid view"
      />
      <StyledIconButton
        iconType="list"
        $isActive={displayType === "list"}
        onClick={() =>
          dispatch({ type: SET_VIEWS_DISPLAY_TYPE, payload: "list" })
        }
        aria-label="List view"
      />
    </StyledButtonBar>
  );
}

const StyledButtonBar = styled(ButtonBar)(
  ({ theme }) => css`
    background: ${theme.colorBase200};
    border-radius: ${theme.borderRadius600};
    padding: 2px 8px;
    gap: 8px;
  `,
);

const StyledIconButton = styled(IconButton)<{ $isActive: boolean }>(
  ({ theme, $isActive }) => css`
    color: ${theme.colorForeground};

    ${
      $isActive &&
      css`
        background: ${theme.colorForeground};
        color: ${theme.colorBackground};
      `
    }
  `,
);
