import type { ApiEvent, TimeRange } from "../../types";
import {
  binaryResponse,
  deleteRequest,
  getRequest,
  parseJsonResponse,
  postJsonRequestDeprecated,
  withQueryArgs,
} from "./utils";

export const createEvent = (
  workspaceId: string,
  event: Pick<ApiEvent, "title" | "labels"> & { time: string },
) =>
  postJsonRequestDeprecated<Uint8Array>(
    `/api/workspaces/${workspaceId}/events`,
    event,
    binaryResponse,
  );

export const deleteEvent = (eventId: string) =>
  deleteRequest(`/api/events/${encodeURIComponent(eventId)}`);

export const listEvents = (
  workspaceId: string,
  timeRange: TimeRange,
  labels?: { [key: string]: string },
) => {
  const queryArgs: { [name: string]: string } = {
    // biome-ignore lint/style/useNamingConvention: API field name is correct
    occurrence_time_start: timeRange.from,
    // biome-ignore lint/style/useNamingConvention: API field name is correct
    occurrence_time_end: timeRange.to,
    // biome-ignore lint/style/useNamingConvention: API field name is correct
    sort_by: "occurrence_time",
  };
  if (labels && Object.keys(labels).length > 0) {
    queryArgs.labels = JSON.stringify(labels);
  }

  return getRequest<Array<ApiEvent>>(
    withQueryArgs(`/api/workspaces/${workspaceId}/events`, queryArgs),
    parseJsonResponse,
  );
};
