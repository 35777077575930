import type {
  Blob,
  Cell,
  ConfigSchema,
  ProviderError,
  ProviderRequest,
  Result,
  SupportedQueryType,
} from ".";
import type { DataSources } from "../services";

export type DataSource = {
  id: string;
  name: string;
  protocolVersion: number;
  providerType: string;
  proxyName?: string;
  config?: object;
  description?: string;
  status?: DataSourceConnectionStatus;
  error?: DataSources.DataSourceError;
  createdAt?: string;
  updatedAt?: string;
};

export type DataSourceConnectionStatus = "connected" | "error";

export type Provider = {
  getConfigSchema(): Promise<ConfigSchema>;

  getSupportedQueryTypes(
    dataSource: DataSource,
  ): Promise<Array<SupportedQueryType>>;

  invoke(
    request: ProviderRequest,
    dataSource: DataSource,
  ): Promise<Result<Blob, ProviderError>>;

  createCells: (
    queryType: string,
    response: Blob,
    dataSource: DataSource,
  ) => Promise<Array<Cell>>;

  extractData(
    response: Blob,
    mimeType: string,
    query: string | null,
    dataSource: DataSource,
  ): Promise<Result<Blob, ProviderError>>;
};
