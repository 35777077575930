import { memo } from "react";
import { useSelector } from "react-redux";
import { css, styled } from "styled-components";

import { selectCurrentUser } from "../../../../selectors";
import { compact, getSupportColorSetForUser } from "../../../../utils";
import { defaultFormatting } from "../constants";
import type { ActiveExtendedFormatting } from "../types";
import type { EditorNode } from "./EditorNode";

export const resetCss = css`
  user-select: text;
  white-space: pre-wrap;

  caret-color: transparent;
  outline: none;

  &::selection,
  & img::selection,
  & svg::selection {
    background: transparent !important;
    color: inherit !important;
  }
`;

type Props = EditorNode & {
  text: string;
  // NOTE - `mergeLeftBorder` and `mergeRightBorder` are used to remove the left and right border attributes of spans,
  //        giving neighboring spans a "unified" look, and avoiding the issues we saw in FP-843
  mergeLeftBorder?: boolean;
  mergeRightBorder?: boolean;
};

export const TextEditorNode = memo(function TextEditorNode({
  text,
  activeFormatting = defaultFormatting,
  startOffset,
  endOffset,
  mergeLeftBorder,
  mergeRightBorder,
}: Props) {
  const currentUser = useSelector(selectCurrentUser);

  const props = {
    "data-start-offset": startOffset,
    "data-end-offset": endOffset,
    currentUserId: currentUser?.id,
    mergeLeftBorder,
    mergeRightBorder,
    ...activeFormatting,
  };

  if (activeFormatting.link) {
    const targetProps = activeFormatting.link.startsWith("provider:")
      ? {}
      : {
          target: "_blank",
          rel: "noopener noreferrer",
        };

    return (
      <Container
        as="a"
        href={activeFormatting.link}
        {...props}
        {...targetProps}
        aria-label={activeFormatting.link}
      >
        {text}
      </Container>
    );
  }

  return <Container {...props}>{text}</Container>;
});

const Container = styled.span<
  ActiveExtendedFormatting & {
    currentUserId?: string;
    mergeLeftBorder?: boolean;
    mergeRightBorder?: boolean;
  }
>(
  ({
    bold,
    code,
    color,
    currentUserId,
    highlight,
    italics,
    mergeLeftBorder,
    mergeRightBorder,
    selection,
    strikethrough,
    theme,
    underline,
  }) => {
    const textDecoration = getTextDecorationProperty(strikethrough, underline);

    const currentSelection = selection.map((userId: string) => {
      const isCurrentUser = userId === currentUserId;
      const color = isCurrentUser
        ? theme.color.bg["hover-primary"]
        : `var(--${getSupportColorSetForUser(userId)[200]})`;

      return css`
        background: ${color};
      `;
    });

    return css`
      ${resetCss}

      color: ${color ?? "inherit"};
      font-weight: ${bold ? "bold" : "inherit"};
      font-style: ${italics ? "italic" : "inherit"};

      ${
        textDecoration &&
        css`
          text-decoration: ${textDecoration};
        `
      }

      ${
        highlight &&
        css`
          background: ${theme.color.bg.warning};
          border-radius: ${theme.radius.minimal};
          padding: 2px 0;
        `
      }

      ${
        code &&
        css`
          font: ${theme.font.code.regular};
          background: ${theme.color.bg.subtle};
          border: 1px solid ${theme.color.border.muted};
          border-radius: ${theme.radius.minimal};
          padding: 2px 0;
        `
      }

      ${currentSelection}

      ${
        mergeLeftBorder &&
        css`
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: none;
        `
      }

      ${
        mergeRightBorder &&
        css`
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: none;
        `
      }
    `;
  },
);

function getTextDecorationProperty(strikethrough: boolean, underline: boolean) {
  if (strikethrough && underline) {
    return compact([
      strikethrough && "line-through",
      underline && "underline",
    ]).join(" ");
  }

  if (strikethrough) {
    return "line-through";
  }

  if (underline) {
    return "underline";
  }
}
