import { addNotification } from ".";
import { setFocus, withActiveNotebook } from "../actions";
import { isSurrogateId } from "../constants";
import { selectCell, selectCellText, selectFocusedCell } from "../selectors";
import type { Thunk } from "../store";
import { type Cell, type CursorUnit, isContentCell } from "../types";
import { charCount } from "../utils";

type FocusCellOptions = {
  cellId: string;
  field?: string;
  offset?: number;
  extendSelection?: boolean;
  highlight?: boolean | undefined;
  selectionUnit?: CursorUnit | undefined;
};

export const focusCell =
  (payload: FocusCellOptions): Thunk =>
  (dispatch, getState) => {
    const {
      cellId,
      extendSelection = false,
      field,
      highlight,
      selectionUnit,
    } = payload;

    const isSurrogate = isSurrogateId(cellId);
    const cell = isSurrogate ? undefined : selectCell(getState(), cellId);
    if (!(cell || isSurrogate)) {
      dispatch(
        addNotification({
          title: "Cell removed",
          description: "The cell doesn't exist (anymore)",
        }),
      );
      return;
    }

    let { offset } = payload;
    const shouldHaveCursorOffset = cell
      ? isContentCell(cell) || !!field
      : isSurrogate;

    if (shouldHaveCursorOffset && offset === undefined) {
      offset = 0;
    }

    dispatch(
      withActiveNotebook({
        type: "focus_cell",
        payload: {
          focus: { cellId, field, offset },
          extendSelection,
          highlight,
          selectionUnit,
        },
      }),
    );
  };

export const focusCellIfNotFocused =
  (payload: FocusCellOptions): Thunk =>
  (dispatch, getState) => {
    const focusedCell = selectFocusedCell(getState());
    if (focusedCell?.id !== payload.cellId) {
      dispatch(focusCell(payload));
    }
  };

export const selectCellFieldContent =
  (cell: Cell, field: string | undefined): Thunk =>
  (dispatch, getState) => {
    const cellId = cell.id;
    const contentLength = charCount(selectCellText(getState(), cell, field));
    dispatch(
      withActiveNotebook(
        contentLength === 0
          ? setFocus({ type: "collapsed", cellId, field, offset: 0 })
          : setFocus({
              type: "selection",
              anchor: { cellId, field, offset: 0 },
              focus: { cellId, field, offset: contentLength },
            }),
      ),
    );
  };
