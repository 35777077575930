import type { FilterMenuItem } from "../UI";

export const getPriority =
  (filterText: string) =>
  (item: FilterMenuItem): number => {
    const title = "title" in item ? item.title.toLowerCase() : "";
    if (title.startsWith(filterText)) {
      return 0;
    } else if (title.includes(filterText)) {
      return 1;
    } else {
      return 2;
    }
  };
