import { useMemo } from "react";

import type { CellFocus, Profile } from "../../../../types";
import type { ExtendedFormatting } from "../types";
import type { CellSubscriber } from "./useCellSubscribers";

export const noFormatting: ExtendedFormatting = [];

function getSelectionFormatting(
  focus: CellFocus,
  field: string | undefined,
  userId: string,
): ExtendedFormatting {
  switch (focus.type) {
    case "selection":
      return focus.start.field === field || focus.end.field === field
        ? [
            {
              type: "start_selection",
              userId,
              offset: focus.start.offset ?? 0,
            },
            { type: "end_selection", userId, offset: focus.end.offset ?? 0 },
          ]
        : noFormatting;

    default:
      return noFormatting;
  }
}

/**
 * Generates the formatting annotations for selections
 *
 * @param focus The current user's `CellFocus`.
 * @param field The name of the field for which the annotations are being generated.
 * @param subscribers An array of subscribers who are in the same cell.
 * @param currentUser The current `AuthUser`
 */
export function useSelectionFormatting(
  focus: CellFocus,
  field: string | undefined,
  currentUser?: Profile,
  subscribers?: Array<CellSubscriber>,
): ExtendedFormatting {
  return useMemo<ExtendedFormatting>(() => {
    const userSelection = currentUser
      ? getSelectionFormatting(focus, field, currentUser.id)
      : noFormatting;

    if (!subscribers || subscribers.length === 0) {
      return userSelection;
    }

    return [
      ...subscribers.flatMap((session) =>
        getSelectionFormatting(session.focus, field, session.user.id),
      ),
      ...userSelection,
    ];
  }, [currentUser, focus, field, subscribers]);
}
