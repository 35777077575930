import {
  type AppAction,
  LABELS_EDITOR_CLEAR_ORIGINAL,
  LABELS_EDITOR_DRAFT_CREATE,
  LABELS_EDITOR_DRAFT_UPDATE,
  LABELS_EDITOR_EDIT,
  LABELS_EDITOR_LOAD_SUGGESTIONS,
  LABELS_EDITOR_RESET,
  LABELS_EDITOR_SET_SUGGESTIONS,
  LABELS_EDITOR_SET_SUGGESTIONS_ERROR,
  type LabelsEditorAction,
} from "../actions";
import type { LabelsEditorState } from "../state";
import type { ActiveLabelsEditorType } from "../types";
import { formatLabel } from "../utils";

const initialState: LabelsEditorState | undefined = undefined;

function isLabelsEditorAction(action: AppAction): action is LabelsEditorAction {
  switch (action.type) {
    case LABELS_EDITOR_CLEAR_ORIGINAL:
    case LABELS_EDITOR_DRAFT_CREATE:
    case LABELS_EDITOR_DRAFT_UPDATE:
    case LABELS_EDITOR_EDIT:
    case LABELS_EDITOR_LOAD_SUGGESTIONS:
    case LABELS_EDITOR_RESET:
    case LABELS_EDITOR_SET_SUGGESTIONS:
    case LABELS_EDITOR_SET_SUGGESTIONS_ERROR:
      return true;

    default:
      return false;
  }
}

export function isLabelsEditorActionForTarget<
  Target extends ActiveLabelsEditorType,
>(
  action: AppAction,
  target: Target,
): action is LabelsEditorAction & {
  payload: { target: Target };
} {
  return isLabelsEditorAction(action)
    ? action.payload.target === target
    : false;
}

export function labelsEditorReducer(
  state = initialState,
  action: LabelsEditorAction,
): LabelsEditorState | undefined {
  switch (action.type) {
    case LABELS_EDITOR_CLEAR_ORIGINAL: {
      if (!state) {
        return initialState;
      }

      return { ...state, original: undefined };
    }
    case LABELS_EDITOR_DRAFT_CREATE:
      return {
        draft: "",
        suggestions: { loading: false },
        suggestionsKey: "",
      };

    case LABELS_EDITOR_DRAFT_UPDATE: {
      if (!state) {
        return initialState;
      }

      return {
        ...state,
        draft: action.payload.draft,
        suggestions: { loading: false },
        suggestionsKey: "",
      };
    }

    case LABELS_EDITOR_EDIT:
      return {
        draft: formatLabel(action.payload.label),
        original: action.payload.label,
        suggestions: { loading: false },
        suggestionsKey: "",
      };

    case LABELS_EDITOR_LOAD_SUGGESTIONS: {
      if (!state) {
        return initialState;
      }

      return {
        ...state,
        suggestions: { data: state.suggestions.data, loading: true },
        suggestionsKey: action.payload.key,
      };
    }

    case LABELS_EDITOR_RESET:
      return initialState;

    case LABELS_EDITOR_SET_SUGGESTIONS: {
      if (!state) {
        return initialState;
      }

      const { keys, values } = action.payload;

      if (keys.length === 0 && values.length === 0) {
        return { ...state, suggestions: { loading: false } };
      }

      return {
        ...state,
        suggestions: { data: { keys, values }, loading: false },
      };
    }

    case LABELS_EDITOR_SET_SUGGESTIONS_ERROR: {
      if (!state) {
        return initialState;
      }

      return {
        ...state,
        suggestions: { error: action.payload.error, loading: false },
      };
    }
  }
}
