import { useMemo } from "react";
import { useSelector } from "react-redux";
import { push } from "redux-first-history";

import { ROUTES } from "../../../../../constants";
import { selectIsAuthenticated } from "../../../../../selectors";
import { dispatch } from "../../../../../store";
import type { Command } from "../../../../../types";
import { useActiveNotebookCommands } from "./useActiveNotebookCommands";
import { useActiveViewCommands } from "./useActiveViewCommands";
import { useDefaultCommands } from "./useDefaultCommands";
import { useLabelSuggestionCommands } from "./useLabelSuggestionCommands";
import { useNotebookSearchCommands } from "./useNotebookSearchCommands";
import { useRecentNotebooksCommands } from "./useRecentNotebooksCommands";
import { useRouteCommands } from "./useRouteCommands";
import { useSettingsCommands } from "./useSettingsCommands";
import { useTemplateCommands } from "./useTemplateCommands";
import { useThemeCommands } from "./useThemeCommands";
import { useViewCommands } from "./useViewCommands";
import { useWebhookCommands } from "./useWebhookCommands";
import { useWorkspaceCommands } from "./useWorkspaceCommands";

export function useCommands(): Array<Command> {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const activeNotebookCommands = useActiveNotebookCommands();
  const activeViewCommands = useActiveViewCommands();
  const defaultCommands = useDefaultCommands();
  const labelSuggestionCommands = useLabelSuggestionCommands();
  const notebookSearchCommands = useNotebookSearchCommands();
  const recentNotebooksCommands = useRecentNotebooksCommands();
  const routeCommands = useRouteCommands();
  const settingsCommands = useSettingsCommands();
  const templateCommands = useTemplateCommands();
  const themeCommands = useThemeCommands();
  const viewCommands = useViewCommands();
  const webhookCommands = useWebhookCommands();
  const workspaceCommands = useWorkspaceCommands();

  return useMemo<Array<Command>>(() => {
    if (!isAuthenticated) {
      return [signInCommand];
    }

    return [
      ...activeNotebookCommands,
      ...activeViewCommands,
      ...defaultCommands,
      ...labelSuggestionCommands,
      ...notebookSearchCommands,
      ...recentNotebooksCommands,
      ...routeCommands,
      ...settingsCommands,
      ...templateCommands,
      ...themeCommands,
      ...viewCommands,
      ...webhookCommands,
      ...workspaceCommands,
    ];
  }, [
    activeNotebookCommands,
    activeViewCommands,
    defaultCommands,
    isAuthenticated,
    labelSuggestionCommands,
    notebookSearchCommands,
    recentNotebooksCommands,
    routeCommands,
    settingsCommands,
    templateCommands,
    themeCommands,
    viewCommands,
    webhookCommands,
    workspaceCommands,
  ]);
}

const signInCommand: Command = {
  key: "signIn",
  type: "commands",
  icon: "user_circle",
  title: "Sign in",
  onActivate: () => dispatch(push(ROUTES.SignIn)),
};
