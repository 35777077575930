import type { ReactEventHandler } from "react";
import { css, styled } from "styled-components";

import { getAccentColors } from "../../utils";

type Props = {
  name: string;
  image?: {
    src: string | undefined;
    onError: ReactEventHandler<HTMLImageElement>;
    onLoad: ReactEventHandler<HTMLImageElement>;
  };
  className?: string;
  size?: number;
};

export function WorkspaceIcon({
  image,
  name,
  className,
  size = 24,
  ...props
}: Props) {
  return (
    <Icon className={className} $size={size} $name={name} {...props}>
      {image ? (
        <Image
          src={image.src}
          onLoad={image.onLoad}
          onError={image.onError}
          alt={name}
        />
      ) : (
        name[0]?.toUpperCase()
      )}
    </Icon>
  );
}

const Icon = styled.div<{ $name: string; $size: number }>(
  ({ theme, $name, $size }) => {
    const colors = getAccentColors($name);

    return css`
      border-radius: ${theme.radius.default};
      width: ${$size}px;
      aspect-ratio: 1;
      font-size: ${$size / 2}px;
      line-height: 1;
      display: grid;
      place-items: center;
      overflow: hidden;
      user-select: none;

      ${colors}
      border: 1px solid currentColor;
    `;
  },
);

const Image = styled.img`
  width: 100%;
  height: 100%;
`;
