import { useEffect, useRef } from "react";
import { styled } from "styled-components";

export function NoiseCanvas() {
  const noiseCanvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = noiseCanvasRef.current;
    const canvasContext = canvas?.getContext("2d");

    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version (which is less readable)
    if (!canvas || !canvasContext) {
      return;
    }

    const containerDimensions = canvas.getBoundingClientRect();
    canvas.width = containerDimensions.width;
    canvas.height = containerDimensions.height;

    const canvasImageData = canvasContext.createImageData(
      canvas.width,
      canvas.height,
    );

    let pos = 0;
    for (let y = 0; y < canvas.height; y++) {
      for (let x = 0; x < canvas.width; x++) {
        const noise = Math.random() * 255;
        canvasImageData.data[pos++] = noise;
        canvasImageData.data[pos++] = noise;
        canvasImageData.data[pos++] = noise;
        canvasImageData.data[pos++] = 16 + Math.random() * 8;
      }
    }

    canvasContext.putImageData(canvasImageData, 0, 0);
  }, []);

  return <NoiseCanvasContainer ref={noiseCanvasRef} />;
}

const NoiseCanvasContainer = styled.canvas`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  inset: 0;
  mix-blend-mode: screen;
`;
