import { StatusBadge } from "./UI";

type WebhookDeliveryLabelProps = {
  successful: boolean;
};

export function WebhookDeliveryLabel({
  successful,
}: WebhookDeliveryLabelProps) {
  return (
    <StatusBadge $status={successful ? "success" : "danger"}>
      {successful ? "Delivered" : "Not delivered"}
    </StatusBadge>
  );
}
