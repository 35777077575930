import { useEffect, useRef } from "react";

import type { LabelsEditorState } from "../../../state";
import { dispatch } from "../../../store";
import { fetchLabelSuggestions } from "../thunks";

export function useSuggestions(editorState: LabelsEditorState) {
  const controller = useRef<AbortController>();
  if (!controller.current) {
    controller.current = new AbortController();
  }

  const { original, draft } = editorState;

  // biome-ignore lint/correctness/useExhaustiveDependencies: intentional refetching when the original changes
  useEffect(() => {
    const abortController = controller.current;
    if (abortController) {
      dispatch(fetchLabelSuggestions(draft, abortController));
    }
  }, [original, draft]);
}
