import { webhooksApi } from "../api";
import {
  selectActiveWorkspaceIdOrThrow,
  selectCanCreateWebhook,
  selectCanDeleteWebhook,
  selectCanUpdateWebhook,
} from "../selectors";
import type { Thunk } from "../store";
import { showModal } from "./modalThunks";
import { addNotification } from "./notificationsThunks";

export const createWebhook = (): Thunk => (dispatch, getState) => {
  const state = getState();
  const canCreateWebhook = selectCanCreateWebhook(state);

  if (!canCreateWebhook) {
    dispatch(
      addNotification({
        title:
          "You don't have permission to create a new webhook in this workspace",
      }),
    );
    return;
  }

  dispatch(showModal({ type: "newWebhook" }));
};

export const deleteWebhook =
  (webhookId: string): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const canDeleteWebhook = selectCanDeleteWebhook(state);

    if (!canDeleteWebhook) {
      dispatch(
        addNotification({
          title:
            "You don't have permission to delete webhooks in this workspace",
        }),
      );
      return;
    }

    const workspaceId = selectActiveWorkspaceIdOrThrow(state);
    dispatch(
      webhooksApi.endpoints.deleteWebhook.initiate({
        webhookId,
        workspaceId,
      }),
    );
  };

export const disableWebhook =
  (webhookId: string): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const canUpdateWebhook = selectCanUpdateWebhook(state);

    if (!canUpdateWebhook) {
      dispatch(
        addNotification({
          title:
            "You don't have permission to update webhooks in this workspace",
        }),
      );
      return;
    }

    const workspaceId = selectActiveWorkspaceIdOrThrow(state);
    dispatch(
      webhooksApi.endpoints.updateWebhook.initiate({
        updateWebhook: { enabled: false, regenerateSharedSecret: false },
        webhookId,
        workspaceId,
      }),
    );
  };
export const enableWebhook =
  (webhookId: string): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const canUpdateWebhook = selectCanUpdateWebhook(state);

    if (!canUpdateWebhook) {
      dispatch(
        addNotification({
          title:
            "You don't have permission to update webhooks in this workspace",
        }),
      );
      return;
    }

    const workspaceId = selectActiveWorkspaceIdOrThrow(state);
    dispatch(
      webhooksApi.endpoints.updateWebhook.initiate({
        updateWebhook: { enabled: true, regenerateSharedSecret: false },
        webhookId,
        workspaceId,
      }),
    );
  };
