import { useHandler } from "@fiberplane/hooks";
import { Icon, IconButton, stopPropagation } from "@fiberplane/ui";
import { type Variants, motion, useReducedMotion } from "framer-motion";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "redux-first-history";
import { css, styled } from "styled-components";

import { useNotebookIsPinnedQuery } from "../../../api";
import {
  DEFAULT_NOTEBOOK_TITLE,
  LARGE_SIZE_QUERY,
  MEDIUM_SIZE_QUERY,
} from "../../../constants";
import {
  selectActiveWorkspaceNameOrThrow,
  selectCanDeleteNotebook,
} from "../../../selectors";
import { useAppDispatch } from "../../../store";
import {
  addNotification,
  duplicateNotebook,
  pinNotebook,
  showNotebookDeleteConfirmation,
  unpinNotebook,
} from "../../../thunks";
import type {
  CreatedBy,
  Label as LabelModel,
  NotebookVisibility,
} from "../../../types";
import { createNotebookLink, hasFeature } from "../../../utils";
import {
  Box,
  LabelList,
  LinkMenuItem,
  MenuDivider,
  MenuItem,
  PageContentItem,
  Text,
  ToggleMenu,
} from "../../UI";
import { Presence } from "./Presence";

// TODO - adjust max labels based off of screen size
const MAX_LABELS = 2;

type Props = {
  id: string;
  createdAt?: string;
  createdBy?: CreatedBy;
  labels?: Array<LabelModel>;
  title: string;
  visibility?: NotebookVisibility;
};

export function NotebookPageItem({
  id,
  createdAt,
  // createdBy,
  title,
  visibility,
  labels,
}: Props): JSX.Element {
  const hasPresenceFeature = hasFeature("courier-workspaces");

  const dispatch = useAppDispatch();
  const shouldReduceMotion = useReducedMotion();

  const workspaceName = useSelector(selectActiveWorkspaceNameOrThrow);
  const canDeleteNotebook = useSelector(selectCanDeleteNotebook);

  const link = createNotebookLink(workspaceName, id, title);

  const onClick = () => dispatch(push(link));

  const copyLink = () => {
    const { location } = document;
    navigator.clipboard.writeText(`${location.origin}${link}`);
    dispatch(addNotification({ title: "Link copied to your clipboard" }));
  };

  const deleteNotebook = () => dispatch(showNotebookDeleteConfirmation(id));

  const { data: isPinned } = useNotebookIsPinnedQuery(id);

  const togglePin = useHandler(() => {
    if (isPinned) {
      dispatch(unpinNotebook(id));
    } else {
      dispatch(pinNotebook(id));
    }
  });

  const hasLabels = labels && labels.length > 0;

  const menuItems = [
    <LinkMenuItem
      key="open-in-new-tab"
      id="open-in-new-tab"
      href={link}
      iconLeft={<Icon iconType="arrow_out" width={20} />}
      title="Open in New Tab"
    />,
    <MenuItem
      key="copy-link"
      id="copy-link"
      iconLeft={<Icon iconType="copy" />}
      onActivate={copyLink}
      title="Copy Link"
    />,
    <MenuItem
      key="toggle-pin"
      id="toggle-pin"
      iconLeft={<Icon iconType={isPinned ? "push_pin_slash" : "push_pin"} />}
      onActivate={togglePin}
      title={isPinned ? "Unpin" : "Pin"}
    />,
    <MenuItem
      key="duplicate"
      id="duplicate"
      iconLeft={<Icon iconType="copy" />}
      onActivate={() => dispatch(duplicateNotebook(id))}
      title="Duplicate"
    />,
  ];

  if (canDeleteNotebook) {
    menuItems.push(
      <MenuDivider key="menu-divider" />,
      <MenuItem
        id="delete-notebook"
        key="delete-notebook"
        iconLeft={<Icon iconType="trash" />}
        onActivate={deleteNotebook}
        title="Delete"
      />,
    );
  }

  return (
    <RecentNotebookContainer
      data-testid="notebook-link"
      data-notebook-id={id}
      variants={shouldReduceMotion ? reducedMotionVariants : motionVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      onClick={onClick}
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === "Enter" || event.key === " ") {
          dispatch(push(link));
        }
      }}
      $hasLabels={hasLabels}
      $hasPresence={hasPresenceFeature}
    >
      <IconContainer>
        <StyledIcon iconType="notebook" width="20" />
      </IconContainer>
      <Title onClick={stopPropagation} to={link} data-testid="notebook-title">
        {isPinned && <PinnedIcon iconType="push_pin" data-testid="pin-icon" />}
        {title || DEFAULT_NOTEBOOK_TITLE}
      </Title>
      <Meta>
        <Text>
          {visibility === "public" && (
            <PublicIndicator>
              <GlobeIcon iconType="globe" />
            </PublicIndicator>
          )}
          {createdAt && formatDate(new Date(createdAt))}
        </Text>
      </Meta>
      <LabelsContainer>
        {hasLabels ? <LabelList labels={labels} max={MAX_LABELS} /> : null}
      </LabelsContainer>

      {hasPresenceFeature && <Presence notebookId={id} />}

      <MenuHandleContainer>
        <ToggleMenu
          data-notebook-menu
          offset={[0, 6]}
          onClick={stopPropagation}
          placement="bottom-start"
          toggleElement={({ anchorRef }) => (
            <div ref={anchorRef}>
              <IconButton
                iconType="ellipsis"
                buttonStyle="tertiary-grey"
                data-menu
              />
            </div>
          )}
        >
          {menuItems}
        </ToggleMenu>
      </MenuHandleContainer>
    </RecentNotebookContainer>
  );
}

const reducedMotionVariants: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { type: "tween" },
};

const motionVariants: Variants = {
  initial: {
    x: 0,
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
  exit: { x: -50, opacity: 0, height: 0 },
  transition: { type: "tween" },
};

const GlobeIcon = styled(Icon)`
  height: 14px;
  vertical-align: text-bottom;
  margin-right: 4px;
  width: 14px;
`;

const MenuHandleContainer = styled(Box)`
  grid-area: actions;
  align-self: center;
  border-radius: ${({ theme }) => theme.radius.default};
`;

const PublicIndicator = styled(Text)`
  color: currentColor;
`;

const RecentNotebookContainer = styled(motion.div).attrs({
  as: PageContentItem,
})<{ $hasLabels?: boolean; $hasPresence?: boolean }>(
  ({ theme, $hasLabels, $hasPresence }) => {
    // Style the large container when labels are present
    const titleCellWidthLarge = $hasLabels ? "240px" : "auto";
    const labelCellWidthLarge = $hasLabels ? "auto" : "0px";
    // Style the container when presence is enabled
    const labelsCellWidthSmall = $hasPresence ? "auto" : "1fr";
    const presenceCellWidthSmall = $hasPresence ? "1fr" : "0px";
    const presenceCellWidthLarge = $hasPresence ? "auto" : "0px";

    return css`
  /* HACK - Override PageContentItem styles */
  border: none;
  margin: 0;

  /* Style the container */
  grid:
    "icon title title title actions" 1fr
    "icon created labels presence actions" 1fr
    / 36px auto ${labelsCellWidthSmall} ${presenceCellWidthSmall} 36px;
  align-items: center;
  gap: 2px 16px;
  padding: 16px ${theme.height.xx.small};
  border-radius: ${theme.radius.default};

  @media ${MEDIUM_SIZE_QUERY} {
    grid:
    "icon title title title actions" 1fr
    "icon created labels presence actions" 1fr
    / 36px auto ${labelsCellWidthSmall} ${presenceCellWidthSmall} 36px;
  }

  @media ${LARGE_SIZE_QUERY} {
    grid:
      "icon title labels presence created actions" 1fr
      / 36px ${titleCellWidthLarge} ${labelCellWidthLarge} ${presenceCellWidthLarge} 84px 36px;
    gap: 0 ${({ theme }) => theme.height.xx.small};
  }
`;
  },
);

const Title = styled(Link)(
  ({ theme }) => css`
    grid-area: title;
    text-decoration: none;
    line-height: 1;
    color: ${theme.color.fg.default};
    font: ${theme.font.headings.h4};

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
);

const PinnedIcon = styled(Icon)`
  height: .8em;
  width: .8em;
  margin-right: 8px;
`;

const Meta = styled(Text)(
  ({ theme }) => css`
    grid-area: created;
    color: ${theme.color.fg.muted};
    font: ${theme.font.body.sm.regular};
    line-height: 1;
  `,
);

const LabelsContainer = styled.div`
  grid-area: labels;
  height: 100%;
  color: ${({ theme }) => theme.color.fg.muted};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;

  &:empty {
    display: none;
  }
`;

const IconContainer = styled.div`
  display: grid;
  place-content: center;
  grid-area: icon;
  background-color: ${({ theme }) => theme.color.bg.subtle};
  height: 36px;
  border-radius: ${({ theme }) => theme.radius.default};
`;

const StyledIcon = styled(Icon)``;

const dateFormat = new Intl.DateTimeFormat("en", {
  day: "numeric",
  month: "short",
  year: "numeric",
});

function formatDate(timestamp: Date): string {
  return dateFormat.format(timestamp);
}
