import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { useCreateWebhookMutation } from "../../../api";
import { getApiErrorMessage } from "../../../errors";
import { selectActiveWorkspaceIdOrThrow } from "../../../selectors";
import { dispatch } from "../../../store";
import { addNotification, showModal } from "../../../thunks";
import type { Webhook } from "../../../types";
import { Modal } from "../../UI";
import { WebhookForm } from "./WebhookForm";

export function NewWebhook() {
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<Webhook>({
    defaultValues: {
      enabled: true,
      endpoint: "",
      events: [],
    },
  });

  const [mutate] = useCreateWebhookMutation();

  const onSubmit = async (newWebhook: Webhook) => {
    try {
      const { id: webhookId, sharedSecret } = await mutate({
        newWebhook,
        workspaceId,
      }).unwrap();

      dispatch(showModal({ type: "editWebhook", webhookId }));

      if (sharedSecret) {
        dispatch(showModal({ type: "webhookSecret", secret: sharedSecret }));
      }

      dispatch(addNotification({ title: "Webhook has been created" }));
    } catch (error) {
      const message = await getApiErrorMessage(
        error,
        "An error occurred while saving your webhook. Please try again later.",
      );
      setError("root", { message });
      return Promise.reject(error);
    }
  };

  return (
    <Modal title="New webhook">
      <WebhookForm
        control={control}
        errors={errors}
        onSubmit={handleSubmit(onSubmit)}
      />
    </Modal>
  );
}
