/**
 * Like `Array#find()`, but traverses the array backwards.
 */
export function findLast<T>(
  array: ArrayLike<T>,
  predicate: (item: T) => boolean,
): T | undefined {
  for (let i = array.length - 1; i >= 0; i--) {
    const item = array[i] as T;
    if (predicate(item)) {
      return item;
    }
  }
}
