import type { FuseResultMatch } from "fuse.js";
import { memo } from "react";

import type { Command } from "../../../../types";
import type { Result as ResultModel } from "../types";
import { DefaultResult } from "./DefaultResult";
import { NotebookResult } from "./NotebookResult";
import { ViewResult } from "./ViewResult";

type Props = {
  index: number;
  result: ResultModel;
};

export const Result = memo(function Result({
  index,
  result: { item: command, matches },
}: Props) {
  return <SwitchedResult command={command} index={index} matches={matches} />;
});

export type SwitchedResultProps<T = Command> = {
  command: T;
  index: number;
  matches?: ReadonlyArray<FuseResultMatch>;
};

const SwitchedResult = memo(function SwitchedResult({
  command,
  ...props
}: SwitchedResultProps<Command>) {
  switch (command.type) {
    case "notebooks":
      return <NotebookResult command={command} {...props} />;
    case "views":
      return <ViewResult command={command} {...props} />;
    default:
      return <DefaultResult command={command} {...props} />;
  }
});
