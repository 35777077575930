import { useMemo } from "react";
import type { Action, Dispatch } from "redux";

import { withActiveNotebook } from "../actions";
import { useAppDispatch } from "./dispatch";
import type { AppDispatch, NotebookActionOrThunk } from "./types";

/**
 * Returns a dispatcher that will automatically wrap actions with
 * `withActiveNotebook()` for you.
 */
export function useActiveNotebookDispatch() {
  const dispatch = useAppDispatch();
  return useMemo(() => wrapDispatchWithActiveNotebook(dispatch), [dispatch]);
}

/**
 * Wraps a dispatcher so that it will wrap all actions with
 * `withActiveNotebook()` for you.
 *
 * Thunks can still be dispatched and won't be wrapped.
 */
export function wrapDispatchWithActiveNotebook(
  dispatch: AppDispatch | Dispatch<Action<unknown>>,
) {
  const appDispatch = dispatch as AppDispatch;
  return (action: NotebookActionOrThunk) =>
    appDispatch(
      typeof action === "function" ? action : withActiveNotebook(action),
    );
}
