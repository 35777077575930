import { styled } from "styled-components";

import { FeedbackConsole } from "../FeedbackConsole";
import { TopMenu } from "../TopMenu";
import { VerticalNav } from "./VerticalNav";

export function Layout({ children }: React.PropsWithChildren) {
  return (
    <LayoutContainer>
      <VerticalNav />

      <TopMenuContainer>
        <TopMenu />
      </TopMenuContainer>

      <MainContent id="main" data-testid="main">
        {children}
      </MainContent>

      <FeedbackConsoleContainer>
        <FeedbackConsole />
      </FeedbackConsoleContainer>
    </LayoutContainer>
  );
}

const LayoutContainer = styled.div`
  --nav-width: 68px;
  
  display: grid;
  grid:
    "nav-vertical nav-horizontal" var(--nav-width)
    "nav-vertical main" 1fr
    "nav-vertical feedback-console" auto
    / var(--nav-width) 1fr;

  position: relative;
  height: 100%;
`;

const MainContent = styled.main`
  grid-area: main;
  overflow-y: auto;
  position: relative;
`;

const TopMenuContainer = styled.nav`
  grid-area: nav-horizontal;
`;

const FeedbackConsoleContainer = styled.div`
  grid-area: feedback-console;
`;
