import { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { makePresenceSummaryByNotebookIdSelector } from "../../../selectors";
import type { SubscriberSession } from "../../../types";
import { PresenceContainer } from "../../UI";

type PresenceProps = {
  notebookId: string;
};

const EMPTY_SUBSCRIBE_SESSION_LIST: Array<SubscriberSession> = [];

export function Presence({ notebookId }: PresenceProps) {
  const stateSelector = useMemo(
    () => makePresenceSummaryByNotebookIdSelector(notebookId),
    [notebookId],
  );
  const sessions = useSelector(stateSelector);

  return (
    <StyledPresenceContainer
      sessions={sessions ?? EMPTY_SUBSCRIBE_SESSION_LIST}
      maxDisplayed={3}
    />
  );
}

const StyledPresenceContainer = styled(PresenceContainer)`
  grid-area: presence;
  justify-self: flex-end;
`;
