import { useMemo } from "react";
import { useSelector } from "react-redux";
import { generatePath } from "react-router";
import { createStructuredSelector } from "reselect";

import {
  type useListPinnedNotebooksQuery,
  useNotebookIsPinnedQuery,
} from "../../../../../api";
import { ROUTES } from "../../../../../constants";
import {
  selectActiveWorkspaceName,
  selectCanCreateNotebook,
  selectCanDeleteNotebook,
  selectCanUpdateNotebook,
  selectCommandMenuVariantType,
  selectHasActiveNotebook,
  selectNotebookId,
  selectNotebookReadOnly,
  selectNotebookVisibility,
} from "../../../../../selectors";
import { dispatch } from "../../../../../store";
import {
  convertNotebookToTemplate,
  forkActiveNotebook,
  pinNotebook,
  setNotebookVisibility,
  showNotebookDeleteConfirmation,
  showNotebookVisibilityConfirmation,
  unpinNotebook,
} from "../../../../../thunks";
import type { ActiveNotebookCommand } from "../../../../../types";
import { compact } from "../../../../../utils";

export function useActiveNotebookCommands(): Array<ActiveNotebookCommand> {
  const commandsState = useSelector(selectActiveNotebookCommandsState);
  const pinnedState = useNotebookIsPinnedQuery(commandsState.notebookId);

  return useMemo(() => {
    const { hasActiveNotebook, variantType } = commandsState;
    if (variantType !== "none" || !hasActiveNotebook) {
      return [];
    }

    return compact([
      createDownloadAsTemplateCommand(commandsState),
      createDuplicateNotebookCommand(commandsState),
      createToggleVisibilityCommand(commandsState),
      createPinUnpinCommand(commandsState, pinnedState),
      createDeleteNotebookCommand(commandsState),
    ]);
  }, [commandsState, pinnedState]);
}

const selectActiveNotebookCommandsState = createStructuredSelector({
  canCreateNotebook: selectCanCreateNotebook,
  canDeleteNotebook: selectCanDeleteNotebook,
  canUpdateNotebook: selectCanUpdateNotebook,
  hasActiveNotebook: selectHasActiveNotebook,
  notebookId: selectNotebookId,
  notebookReadOnly: selectNotebookReadOnly,
  notebookVisibility: selectNotebookVisibility,
  variantType: selectCommandMenuVariantType,
  workspaceName: selectActiveWorkspaceName,
});

type ActiveNotebookCommandsState = ReturnType<
  typeof selectActiveNotebookCommandsState
>;

function createDownloadAsTemplateCommand({
  notebookId,
}: ActiveNotebookCommandsState): ActiveNotebookCommand {
  return {
    key: `notebooks/download-template/${notebookId}`,
    type: "activeNotebook",
    title: "Download as template",
    icon: "file_arrow_down",
    onActivate: () => dispatch(convertNotebookToTemplate(notebookId)),
  };
}

function createDuplicateNotebookCommand({
  canCreateNotebook,
  notebookId,
}: ActiveNotebookCommandsState): ActiveNotebookCommand | null {
  if (!canCreateNotebook) {
    return null;
  }

  return {
    key: `notebooks/duplicate/${notebookId}`,
    type: "activeNotebook",
    title: "Duplicate the current notebook",
    description: "Creates a new copy of the active notebook",
    icon: "copy",
    onActivate: () => dispatch(forkActiveNotebook()),
  };
}

function createToggleVisibilityCommand({
  canUpdateNotebook,
  notebookId,
  notebookReadOnly,
  notebookVisibility,
}: ActiveNotebookCommandsState): ActiveNotebookCommand | null {
  if (!canUpdateNotebook || notebookReadOnly) {
    return null;
  }

  const isPublic = notebookVisibility === "public";
  const title = `Make the current notebook ${isPublic ? "private" : "public"}`;
  const description = `Changes the notebook's visibility from ${
    isPublic ? "public to private" : "private to public"
  }`;

  return {
    key: `notebooks/visibility/${notebookId}`,
    type: "activeNotebook",
    title,
    description,
    icon: "globe",
    onActivate: () =>
      isPublic
        ? dispatch(setNotebookVisibility(notebookId, "private"))
        : dispatch(showNotebookVisibilityConfirmation(notebookId)),
  };
}

function createPinUnpinCommand(
  { notebookId }: ActiveNotebookCommandsState,
  { data: isPinned, isLoading }: ReturnType<typeof useListPinnedNotebooksQuery>,
): ActiveNotebookCommand | null {
  if (isLoading) {
    return null;
  }

  const title = `${isPinned ? "Unpin" : "Pin"} the current notebook`;
  const description = `${
    isPinned ? "Unpins" : "Pins"
  } the current notebook from the top of your recent notebooks`;

  return {
    key: `notebooks/pin/${notebookId}`,
    type: "activeNotebook",
    title,
    description,
    icon: "push_pin",
    onActivate: () =>
      isPinned
        ? dispatch(unpinNotebook(notebookId))
        : dispatch(pinNotebook(notebookId)),
  };
}

function createDeleteNotebookCommand({
  canDeleteNotebook,
  notebookId,
  workspaceName,
}: ActiveNotebookCommandsState): ActiveNotebookCommand | null {
  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
  if (!canDeleteNotebook || !workspaceName) {
    return null;
  }

  return {
    key: `notebooks/delete/${notebookId}`,
    type: "activeNotebook",
    title: "Delete notebook",
    icon: "trash",
    onActivate: () =>
      dispatch(
        showNotebookDeleteConfirmation(
          notebookId,
          generatePath(ROUTES.Notebooks, { workspaceName }),
        ),
      ),
  };
}
