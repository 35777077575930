import type { ActiveExtendedFormatting } from "../types";

export type EditorNode = {
  startOffset: number;
  endOffset: number;
  activeFormatting: ActiveExtendedFormatting;
};

export type KeyedEditorNode = EditorNode & {
  key: number;
};
