import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  selectActiveWorkspaceId,
  selectDataSourcesList,
  selectNotebookRevision,
  selectSelectedDataSources,
} from "../../selectors";
import { dispatch } from "../../store";
import { loadDataSources, runAllCells } from "../../thunks";
import { isSameDataSource } from "../../utils";

/**
 * Component that verifies the data sources used in the notebook are actually
 * loaded. Triggers a reload of the workspace's data sources if any data source
 * appears to be missing.
 *
 * TODO: This does not yet take into account that provider cells can have
 *       non-default data sources configured. We also don't expose that
 *       functionality in the UI yet, so this should be fine for now.
 *
 * Note: This functionality is implemented as a component instead of a hook to
 *       avoid triggering rerenders in the parent component.
 */
export function DataSourcesLoader() {
  const {
    data: dataSources,
    error,
    loading,
  } = useSelector(selectDataSourcesList);
  const [mayLoad, setMayLoad] = useState(true);
  const selectedDataSources = useSelector(selectSelectedDataSources);
  const workspaceId = useSelector(selectActiveWorkspaceId);
  const notebookRevision = useSelector(selectNotebookRevision);

  useEffect(() => {
    if (error || loading || !workspaceId) {
      return;
    }

    const allDataSourcesLoaded = Object.entries(selectedDataSources).every(
      ([providerType, selectedDataSource]) =>
        dataSources?.some(
          (dataSource) =>
            dataSource.providerType === providerType &&
            isSameDataSource(selectedDataSource, dataSource),
        ),
    );
    if (allDataSourcesLoaded) {
      setMayLoad(true);

      // Make sure to run all cells on initial notebook loading
      if (notebookRevision === 1) {
        dispatch(runAllCells());
      }
    } else if (mayLoad) {
      dispatch(loadDataSources(workspaceId));
      setMayLoad(false);
    }
  }, [
    dataSources,
    error,
    loading,
    mayLoad,
    selectedDataSources,
    workspaceId,
    notebookRevision,
  ]);

  return null;
}
