//
// Legacy types are kept only for interaction with FPD instances that still host
// legacy providers. We should be able to query our production database to
// determine how many of those are still around, and when we feel the number has
// sufficiently dropped, we can remove this for good.
//

/**
 * Legacy `LogRecord` from the Provider 1.0 protocol.
 */
export type LegacyLogRecord = {
  timestamp: LegacyTimestamp;
  body: string;
  attributes: Record<string, string>;
  resource: Record<string, string>;
  traceId?: Uint8Array;
  spanId?: Uint8Array;
};

/**
 * Legacy `ProviderRequest` from the Provider 1.0 protocol.
 */
export type LegacyProviderRequest = {
  type: "logs";
  query: string;
  limit?: number;
  timeRange: LegacyTimeRange;
};

/**
 * Legacy `ProviderResponse` from the 1.0 protocol.
 */
export type LegacyProviderResponse =
  | { type: "error"; error: Error }
  | { type: "log_records"; logRecords: Array<LegacyLogRecord> }
  | { type: "status_ok" };

export type LegacyTimeRange = {
  from: LegacyTimestamp;
  to: LegacyTimestamp;
};

export type LegacyTimestamp = number;
