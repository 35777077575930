import { useMemo } from "react";

import styled, { css } from "styled-components";
import { type Integration, IntegrationType } from "../../../slices";
import type { Setting } from "../../../types";
import type { SettingsProps } from "./Settings";
import {
  DataSourcesList,
  ExperimentalContentBlock,
  IntegrationDetails,
  IntegrationsList,
  Members,
  Profile,
  ProxyList,
  ThemeSwitcher,
  Webhooks,
  Workspace,
} from "./sections";

type SettingsContentBlockProps = Pick<Setting, "description" | "title"> &
  SettingsProps;

export function SettingsContentBlock(props: SettingsContentBlockProps) {
  const { description, section, title } = props;
  const integrationId = ((section === "personalIntegrations" &&
    props.integrationId) ||
    (section === "workspaceIntegrations" && props.integrationId) ||
    undefined) as Integration["id"] | undefined;

  const settingsSection = useMemo(() => {
    switch (section) {
      case "data-sources":
        return <DataSourcesList />;
      case "experimental":
        return <ExperimentalContentBlock />;
      case "fpd":
        return <ProxyList />;
      case "personalIntegrations": {
        if (integrationId) {
          return (
            <IntegrationDetails
              type={IntegrationType.Personal}
              id={integrationId}
            />
          );
        }

        return <IntegrationsList type={IntegrationType.Personal} />;
      }
      case "workspaceIntegrations": {
        if (integrationId) {
          return (
            <IntegrationDetails
              type={IntegrationType.Workspace}
              id={integrationId}
            />
          );
        }

        return <IntegrationsList type={IntegrationType.Workspace} />;
      }
      case "workspace":
        return <Workspace />;
      case "members":
        return <Members />;
      case "profile":
        return <Profile />;
      case "theme":
        return <ThemeSwitcher />;
      case "webhooks":
        return <Webhooks />;
    }
  }, [integrationId, section]);

  return (
    <SettingsBlockContainer>
      {!integrationId && (
        <>
          <SettingsTitle as="h2">{title}</SettingsTitle>
          {description && (
            <SettingsDescription>{description}</SettingsDescription>
          )}
        </>
      )}
      {settingsSection}
    </SettingsBlockContainer>
  );
}

const SettingsBlockContainer = styled.div`
  display: grid;
  grid: auto-flow / 1fr;
  gap: 20px 0;
  align-content: start;
  position: relative;
`;

const SettingsTitle = styled.h1(
  ({ theme }) => css`
    color: ${theme.color.fg.default};
    font: ${theme.font.headings.h3};
    font-family: Inter;
    font-weight: 700;
    margin: 0;
  `,
);

const SettingsDescription = styled.p(
  ({ theme }) => css`
    margin: 0;
    font: ${theme.font.body.md.regular};
    color: ${theme.color.fg.muted};
  `,
);
