import { useEffect } from "react";

import { useIsLive } from "./useIsLive";

export type UseWhenLiveProps = {
  queryData: string | undefined;
  timeout: number;
  onInterval: () => void;
};

/**
 * Runs a specific handler every X ms for as long as the query is in 'live' mode.
 */
export function useWhenLive({
  queryData,
  timeout,
  onInterval,
}: UseWhenLiveProps) {
  const isLive = useIsLive(queryData);

  return useEffect(() => {
    if (!isLive) {
      return;
    }

    onInterval();

    const id = setInterval(onInterval, timeout);
    return () => {
      clearInterval(id);
    };
  }, [isLive, onInterval, timeout]);
}
