import { createSearchParams, generatePath } from "react-router-dom";
import { push, replace } from "redux-first-history";
import { HIDE_MODAL, SHOW_MODAL, clearOriginalLabel } from "../../actions";
import { templatesApi } from "../../api";
import {
  selectActiveLabelsEditorType,
  selectActiveNotebookId,
  selectActiveWorkspaceIdOrThrow,
  selectCell,
  selectCellIds,
  selectCellsInSelection,
  selectNotebookFocus,
  selectOverlayValue,
} from "../../selectors";
import { Sentry } from "../../services";
import type { Thunk } from "../../store";
import type {
  Cell,
  ModalType,
  ModalTypeProperties,
  SettingsSection,
} from "../../types";
import { getSelection } from "../../utils";
import { resetActiveLabelsEditor } from "../labelsEditorThunks";

export function showModal(
  modal: ModalTypeProperties,
  onAfterClose?: () => void,
): Thunk {
  return (dispatch) => {
    const onRequestClose = () => {
      dispatch(hideModal(modal.type));
    };

    dispatch({
      type: SHOW_MODAL,
      payload: {
        key: modal.type,
        modal: {
          modal,
          onAfterClose,
          onRequestClose,
        },
      },
    });
  };
}

type HistoryBehavior = "push" | "replace";
type BaseOptions = {
  overrideHistoryBehavior?: HistoryBehavior;
};

export type MainSettingsParams = {
  section?: Exclude<
    SettingsSection,
    "personalIntegrations" | "workspaceIntegrations"
  >;
};

export type PersonalIntegrationSettingsParams = {
  section: "personalIntegrations";
  integrationId?: string;
};

export type WorkspaceIntegrationSettingsParams = {
  section: "workspaceIntegrations";
  integrationId?: string;
};

export const MAIN_SECTION_PATH = "/settings?/:section";
export const PERSONAL_INTEGRATION_PATH =
  "/settings/personalIntegrations/:integrationId";
export const WORKSPACE_INTEGRATION_PATH =
  "/settings/workspaceIntegrations/:integrationId";

export function createSettingsPersonalIntegrationUri(integrationId: string) {
  return generatePath(PERSONAL_INTEGRATION_PATH, { integrationId });
}

export function createSettingsWorkspaceIntegrationUri(integrationId: string) {
  return generatePath(WORKSPACE_INTEGRATION_PATH, { integrationId });
}

export function createSettingsSectionUri(section: SettingsSection) {
  return generatePath(MAIN_SECTION_PATH, { section });
}

export type ShowSettingsOptions = BaseOptions &
  (
    | MainSettingsParams
    | PersonalIntegrationSettingsParams
    | WorkspaceIntegrationSettingsParams
  );

export function showSettingsModal(
  options: ShowSettingsOptions | undefined = {},
): Thunk {
  return (dispatch, getState) => {
    const { section = "profile" } = options;
    const integrationId =
      (options.section === "personalIntegrations" && options.integrationId) ||
      (options.section === "workspaceIntegrations" && options.integrationId) ||
      undefined;

    const uri = integrationId
      ? options.section === "personalIntegrations"
        ? createSettingsPersonalIntegrationUri(integrationId)
        : createSettingsWorkspaceIntegrationUri(integrationId)
      : createSettingsSectionUri(section);

    const currentOverlay = selectOverlayValue(getState());
    const overrideHistoryBehavior =
      options.overrideHistoryBehavior ??
      (currentOverlay?.startsWith("/settings/") ? "replace" : "push");

    dispatch(
      updateRoutedModal({
        uri,
        overrideHistoryBehavior,
      }),
    );
  };
}

export function hideSettingsModal(): Thunk {
  return (dispatch) => dispatch(updateRoutedModal({}));
}

type UpdateModalParams = {
  uri?: string;
  overrideHistoryBehavior?: HistoryBehavior;
};

export function updateRoutedModalQueryParams(
  params: URLSearchParams | ((previous: URLSearchParams) => URLSearchParams),
): Thunk {
  return (dispatch, getState) => {
    const overlayUri = selectOverlayValue(getState());
    if (overlayUri === null) {
      Sentry.captureWarning(
        "Attempting to update an overlay that is not open",
        { params },
      );
      return;
    }

    try {
      const url = new URL(overlayUri, window.location.origin);
      const newParams =
        typeof params === "function"
          ? params(new URLSearchParams(url.search))
          : params;
      const search = newParams.toString();
      const newUri = `${url.pathname}${search ? `?${search}` : ""}`;

      dispatch(
        updateRoutedModal({
          uri: newUri,
        }),
      );
    } catch (error) {
      Sentry.captureError("Failed to update overlay query params", { error });
      return;
    }
  };
}

export function updateRoutedModal(params: UpdateModalParams = {}): Thunk {
  return (dispatch) => {
    const { overrideHistoryBehavior = "push", uri } = params;
    const newParams = createSearchParams();
    if (uri) {
      newParams.set("overlay", uri);
    } else {
      newParams.delete("overlay");
    }

    const navigateParams = { search: newParams.toString() };
    dispatch(
      overrideHistoryBehavior === "push"
        ? push(navigateParams)
        : replace(navigateParams),
    );
  };
}

export function hideModal(key: ModalType): Thunk {
  return (dispatch) => {
    dispatch({
      type: HIDE_MODAL,
      payload: {
        key,
      },
    });
  };
}

export const showCreateSnippetModal = (): Thunk => (dispatch, getState) => {
  function containsUnsupportedCellType(cell: Cell) {
    return cell.type === "image" || cell.type === "discussion";
  }

  const state = getState();
  const notebookId = selectActiveNotebookId(state);
  const focus = selectNotebookFocus(state);
  const cellIds = selectCellIds(state);

  if (focus.type === "selection") {
    const selection = getSelection(cellIds, focus);
    const cellsFromSelection = selectCellsInSelection(state, selection);

    const isContainingUnsupportedCellType = cellsFromSelection.some(
      containsUnsupportedCellType,
    );

    dispatch(
      showModal({
        type: "createSnippet",
        startCellId: selection.start.cellId,
        endCellId: selection.end.cellId,
        notebookId,
        hasUnsupportedCellType: isContainingUnsupportedCellType,
      }),
    );
  }

  if (focus.type === "collapsed") {
    const { cellId } = focus;
    const cell = selectCell(state, cellId);
    const isUnsupportedCellType = !cell || containsUnsupportedCellType(cell);

    dispatch(
      showModal({
        type: "createSnippet",
        startCellId: cellId,
        endCellId: cellId,
        notebookId,
        hasUnsupportedCellType: isUnsupportedCellType,
      }),
    );
  }
};

type ShowLabelDeletedWarningParams = {
  draft: string;
  onContinueEditing: () => void;
};

export const showLabelDeletedWarning =
  ({ draft, onContinueEditing }: ShowLabelDeletedWarningParams): Thunk =>
  (dispatch, getState) => {
    const onAfterClose = () => {
      dispatch(resetActiveLabelsEditor());
    };

    const onConfirm = () => {
      dispatch(clearOriginalLabel(selectActiveLabelsEditorType(getState())));
      onContinueEditing();
    };

    dispatch(
      showModal(
        {
          type: "replaceLabelKeyWarning",
          draft,
          onConfirm,
        },
        onAfterClose,
      ),
    );
  };

export const showTemplateDetailModal =
  (templateName: string): Thunk =>
  (dispatch, getState) => {
    templatesApi.endpoints.getTemplateByName.initiate({
      workspaceId: selectActiveWorkspaceIdOrThrow(getState()),
      templateName,
    });

    dispatch(
      showModal({
        type: "templateDetail",
        templateName,
      }),
    );
  };
