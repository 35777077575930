import { useMemo } from "react";
import { useSelector } from "react-redux";
import { generatePath } from "react-router";
import { push } from "redux-first-history";
import { createSelector } from "reselect";

import { useListViewsQuery } from "../../../../../api";
import { ROUTES } from "../../../../../constants";
import {
  selectActiveWorkspaceIdOrThrow,
  selectActiveWorkspaceName,
  selectCanCreateView,
  selectCommandMenuVariantType,
} from "../../../../../selectors";
import { dispatch } from "../../../../../store";
import type { View, ViewCommand } from "../../../../../types";

const EMPTY_VIEW_LIST: Array<View> = [];

export function useViewCommands(): Array<ViewCommand> {
  const { canCreateView, variantType, workspaceId, workspaceName } =
    useSelector(selectViewCommandsState);

  const { data: views = EMPTY_VIEW_LIST } = useListViewsQuery(
    { workspaceId },
    { skip: !workspaceId, refetchOnMountOrArgChange: true },
  );

  return useMemo((): Array<ViewCommand> => {
    if (variantType !== "none" || !workspaceName) {
      return [];
    }

    const commands: Array<ViewCommand> = views.map(
      (view): ViewCommand => ({
        type: "views",
        key: `views/${view.name}`,
        view,
        title: view.displayName,
        description: view.description,
        icon: "views",
        onActivate: () =>
          dispatch(
            push(
              generatePath(ROUTES.View, { workspaceName, viewName: view.name }),
            ),
          ),
      }),
    );

    if (canCreateView) {
      commands.push({
        key: "views/new",
        type: "views",
        title: "Create a new View",
        keywords: ["add", "insert"],
        icon: "views",
        onActivate: () =>
          dispatch(push(generatePath(ROUTES.NewView, { workspaceName }))),
      });
    }

    return commands;
  }, [workspaceName, variantType, views, canCreateView]);
}

const selectViewCommandsState = createSelector(
  [
    selectActiveWorkspaceIdOrThrow,
    selectActiveWorkspaceName,
    selectCanCreateView,
    selectCommandMenuVariantType,
  ],
  (workspaceId, workspaceName, canCreateView, variantType) => ({
    canCreateView,
    variantType,
    workspaceId,
    workspaceName,
  }),
);
