import { Button } from "@fiberplane/ui";
import styled from "styled-components";

export { CopilotContainer, CopilotSidebar } from "./CopilotSidebar";

export const MiniButton = styled(Button)`
  padding: 8px 12px;
  font: ${({ theme }) => theme.font.buttons.sm};
  height: ${({ theme }) => theme.height.small};
  min-height: unset;
`;
