import type {
  Membership,
  NewWorkspace,
  NewWorkspaceInvite,
  UpdateWorkspace,
  UpdateWorkspaceUser,
  Workspace,
  WorkspaceInvite,
  WorkspaceInviteResponse,
} from "../types";
import { baseApi } from "./base";
import { jsonWithTextErrorResponseHandler } from "./utils";

export const workspacesApi = baseApi
  .injectEndpoints({
    endpoints: (builder) => ({
      acceptInvite: builder.mutation<
        Workspace,
        {
          invitationId: string;
          invitationSecret: string;
        }
      >({
        query: ({ invitationId, invitationSecret }) => ({
          url: `/invitations/${invitationId}/${invitationSecret}/accept`,
          method: "POST",
        }),
      }),
      createInvite: builder.mutation<
        WorkspaceInviteResponse,
        { workspaceId: string; newWorkspaceInvite: NewWorkspaceInvite }
      >({
        query: ({ workspaceId, newWorkspaceInvite }) => ({
          url: `/workspaces/${workspaceId}/invitations`,
          method: "POST",
          body: newWorkspaceInvite,
        }),
      }),
      createWorkspace: builder.mutation<Workspace, NewWorkspace>({
        query: (newWorkspace: NewWorkspace) => ({
          url: "/workspaces",
          method: "POST",
          body: newWorkspace,
          responseHandler: jsonWithTextErrorResponseHandler,
        }),
      }),
      deleteInvite: builder.mutation<void, { invitationId: string }>({
        query: ({ invitationId }) => ({
          url: `/invitations/${invitationId}`,
          method: "DELETE",
        }),
      }),
      deleteWorkspace: builder.mutation<void, { workspaceId: string }>({
        query: ({ workspaceId }) => ({
          url: `/workspaces/${workspaceId}`,
          method: "DELETE",
        }),
      }),
      deleteMember: builder.mutation<
        void,
        { workspaceId: string; userId: string }
      >({
        query: ({ workspaceId, userId }) => ({
          url: `/workspaces/${workspaceId}/users/${userId}`,
          method: "DELETE",
        }),
      }),
      leaveWorkspace: builder.mutation<void, { workspaceId: string }>({
        query: ({ workspaceId }) => ({
          url: `/workspaces/${workspaceId}/leave`,
          method: "POST",
        }),
      }),
      listInvites: builder.query<
        Array<WorkspaceInvite>,
        { workspaceId: string }
      >({
        query: ({ workspaceId }) => `/workspaces/${workspaceId}/invitations`,
      }),
      listMembers: builder.query<Array<Membership>, { workspaceId: string }>({
        query: ({ workspaceId }) => `/workspaces/${workspaceId}/users`,
      }),
      listWorkspaces: builder.query<Array<Workspace>, void>({
        query: () => "/workspaces",
      }),
      updateMember: builder.mutation<
        void,
        {
          workspaceId: string;
          userId: string;
          updateWorkspaceUser: UpdateWorkspaceUser;
        }
      >({
        query: ({ workspaceId, userId, updateWorkspaceUser }) => ({
          url: `/workspaces/${workspaceId}/users/${userId}`,
          method: "PATCH",
          body: updateWorkspaceUser,
        }),
      }),
      updateWorkspace: builder.mutation<
        Workspace,
        { workspaceId: string; updateWorkspace: UpdateWorkspace }
      >({
        query: ({ workspaceId, updateWorkspace }) => ({
          url: `/workspaces/${workspaceId}`,
          method: "PATCH",
          body: updateWorkspace,
        }),
      }),
      updateWorkspacePicture: builder.mutation<
        void,
        { workspaceId: string; picture: File }
      >({
        query: ({ workspaceId, picture }) => {
          const body = new FormData();
          body.append("picture", picture);

          return {
            url: `/workspaces/${workspaceId}/picture`,
            method: "POST",
            body,
          };
        },
      }),
    }),
    overrideExisting: false,
  })
  .enhanceEndpoints({
    addTagTypes: ["Invites", "Members", "Profile", "Workspaces"],
    endpoints: {
      acceptInvite: {
        invalidatesTags: [
          { id: "LIST", type: "Invites" },
          { id: "LIST", type: "Workspaces" },
          { type: "Profile" },
        ],
      },
      createInvite: {
        invalidatesTags: [{ id: "LIST", type: "Invites" }],
      },
      createWorkspace: {
        invalidatesTags: [
          { id: "LIST", type: "Workspaces" },
          { type: "Profile" },
        ],
      },
      deleteInvite: {
        invalidatesTags: [{ id: "LIST", type: "Invites" }],
      },
      deleteMember: {
        invalidatesTags: [{ id: "LIST", type: "Members" }],
      },
      deleteWorkspace: {
        invalidatesTags: [
          { id: "LIST", type: "Workspaces" },
          { type: "Profile" },
        ],
      },
      leaveWorkspace: {
        invalidatesTags: [
          { id: "LIST", type: "Workspaces" },
          { type: "Profile" },
        ],
      },
      listInvites: {
        providesTags: [{ id: "LIST", type: "Invites" }],
      },
      listMembers: {
        providesTags: [{ id: "LIST", type: "Members" }],
      },
      listWorkspaces: {
        providesTags: [{ id: "LIST", type: "Workspaces" }],
      },
      updateMember: {
        invalidatesTags: [{ id: "LIST", type: "Members" }],
      },
      updateWorkspace: {
        invalidatesTags: [
          { id: "LIST", type: "Workspaces" },
          { type: "Profile" },
        ],
      },
    },
  });

export const {
  useAcceptInviteMutation,
  useCreateInviteMutation,
  useCreateWorkspaceMutation,
  useDeleteInviteMutation,
  useDeleteMemberMutation,
  useDeleteWorkspaceMutation,
  useLeaveWorkspaceMutation,
  useListInvitesQuery,
  useListMembersQuery,
  useListWorkspacesQuery,
  useUpdateMemberMutation,
  useUpdateWorkspaceMutation,
} = workspacesApi;
