import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { FRONT_MATTER_CELL_ID } from "../../../constants";
import {
  makeCellFocusSelector,
  makeSafeFrontMatterValueSelector,
} from "../../../selectors";
import type { SafeFrontMatterValue } from "../../../types";

/**
 * Selects the value of the given front matter field.
 */
export function useFrontMatterValue<T extends SafeFrontMatterValue>(
  field: string,
  validator: (value: SafeFrontMatterValue) => value is T,
): T | undefined {
  const selector = useMemo(
    () => makeSafeFrontMatterValueSelector(field),
    [field],
  );

  const value = useSelector(selector);
  return validator(value) ? value : undefined;
}

/**
 * Create a selector that returns whether a front matter field
 * is focused or not
 */
const makeFocusedSelector = (field: string) => {
  return createSelector(
    makeCellFocusSelector(FRONT_MATTER_CELL_ID, field),
    (focus) => focus.type !== "none",
  );
};

/**
 * Selects whether the given front matter field is focused.
 */
export function useFocused(field: string) {
  const selector = useMemo(() => makeFocusedSelector(field), [field]);
  return useSelector(selector);
}
