import { Icon } from "@fiberplane/ui";
import { css, styled } from "styled-components";

import { Container, Info } from "./shared";

type Props = {
  hovered: boolean;
};

export function UploadReady({ hovered }: Props): JSX.Element {
  return (
    <UploadReadContainer hovered={hovered}>
      <Info>
        <Icon iconType="upload" /> Drop Image here...
      </Info>
    </UploadReadContainer>
  );
}

const UploadReadContainer = styled(Container)<{ hovered: boolean }>`
  ${({ hovered, theme }) =>
    hovered &&
    css`
      border-style: dashed;
      background: ${theme.colorSecondary100};
    `}
`;
