import { Icon, cancelEvent } from "@fiberplane/ui";
import type { FC } from "react";
import { css, styled } from "styled-components";

import type { Label as LabelModel } from "../../types";
import { formatLabel, getAccentColors } from "../../utils";

type Props = {
  label: LabelModel;
  onDeleteRequested?: (event: React.SyntheticEvent) => void;
  onClick?: (event: React.MouseEvent) => void;
};

export const NotebookLabel: FC<Props> = ({
  label,
  onDeleteRequested,
  onClick,
}) => {
  const clickable = typeof onClick === "function";

  return (
    <LabelVariant clickable={clickable} labelKey={label?.key} onClick={onClick}>
      {formatLabel(label)}
      {!!onDeleteRequested && (
        <DeleteIcon
          iconType="close"
          onClick={(event: React.MouseEvent<SVGElement>) => {
            cancelEvent(event);
            onDeleteRequested(event);
          }}
        />
      )}
    </LabelVariant>
  );
};

export const StyledLabel = styled.span(
  ({ theme }) => css`
    display: inline-flex;
    align-items: center;
    gap: 6px;
    cursor: default;
    background-color: ${theme.color.bg.subtle};
    font: ${theme.font.body.md.medium};
    padding: 0 4px;
    border-radius: ${theme.radius.lowest};
  `,
);

const LabelVariant = styled(StyledLabel)<{
  clickable: boolean;
  labelKey: string;
}>(
  ({ clickable, labelKey }) => css`
    height: min-content;
    ${
      clickable &&
      css`
        cursor: pointer;
      `
    }

    ${labelKey && getAccentColors(labelKey)}
  `,
);

const DeleteIcon = styled(Icon)`
  cursor: pointer;
  height: 12px;
  width: 12px;
`;
