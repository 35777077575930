import type { AppDispatch } from "../store";

export type NotificationType = "warning" | "danger" | "info";

export type Notification = {
  title: string;
  type?: NotificationType;
  description?: string;
  hideCloseButton?: boolean;
  disableAutoHide?: boolean;
  action?: (dispatch: AppDispatch) => Promise<void> | void;
};

export type NotificationsState = ReadonlyMap<string, Notification>;
