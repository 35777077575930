import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { Tab, TabList } from "@fiberplane/ui";
import {
  useListWebhooksQuery,
  useUpdateWebhookMutation,
} from "../../../../api";
import { getApiErrorMessage } from "../../../../errors";
import { selectActiveWorkspaceIdOrThrow } from "../../../../selectors";
import { dispatch } from "../../../../store";
import { addNotification } from "../../../../thunks";
import type { Webhook } from "../../../../types";
import { Modal } from "../../../UI";
import { WebhooksIntro } from "../../../WebhooksIntro";
import { WebhookForm } from "../WebhookForm";
import { DeliveryList } from "./DeliveryList";

type WebhookWithIdProps = {
  webhookId: string;
};

export function WebhookWithId({ webhookId }: WebhookWithIdProps) {
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const [activeTab, setActiveTab] = useState<"edit" | "deliveries">("edit");

  const { data, isLoading } = useListWebhooksQuery(
    { workspaceId },
    {
      selectFromResult: ({ data, ...other }) => {
        return { data: data?.find(({ id }) => id === webhookId), ...other };
      },
    },
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
  } = useForm<Webhook>({
    defaultValues: {
      enabled: false,
      endpoint: "",
      events: [],
    },
  });

  useEffect(() => {
    if (!isLoading && data) {
      reset(data);
    }
  }, [data, isLoading, reset]);

  const [mutate] = useUpdateWebhookMutation();

  const onSubmit = async (changes: Webhook) => {
    if (!webhookId) {
      throw new Error("No webhookId");
    }

    try {
      await mutate({
        updateWebhook: {
          ...changes,
          regenerateSharedSecret: false,
        },
        webhookId,
        workspaceId,
      }).unwrap();

      dispatch(addNotification({ title: "Webhook has been updated" }));
    } catch (error) {
      const message = await getApiErrorMessage(
        error,
        "An error occurred while saving your webhook. Please try again later.",
      );
      setError("root", { message });
    }
  };

  return (
    <Modal title="Manage Webhook" description={WebhooksIntro}>
      {!isLoading && data && (
        <>
          <TabList layoutId="webhookTabList">
            <Tab
              alias="edit"
              isActive={activeTab === "edit"}
              onClick={() => setActiveTab("edit")}
            >
              Edit webhook
            </Tab>
            <Tab
              alias="delivies"
              isActive={activeTab === "deliveries"}
              onClick={() => setActiveTab("deliveries")}
            >
              Recent deliveries
            </Tab>
          </TabList>

          {activeTab === "edit" ? (
            <WebhookForm
              control={control}
              errors={errors}
              onSubmit={handleSubmit(onSubmit)}
            />
          ) : (
            <DeliveryList webhookId={webhookId} />
          )}
        </>
      )}
    </Modal>
  );
}
