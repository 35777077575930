import type { IconType } from "@fiberplane/ui";
import { memo } from "react";
import { css, styled } from "styled-components";

import type { Command } from "../../../../types";
import {
  DEFAULT_ICON,
  DESCRIPTION_INDEX_KEY,
  TITLE_INDEX_KEY,
} from "../constants";
import { HighlightedText } from "./HighlightedText";
import type { SwitchedResultProps } from "./Result";
import { ResultContainer } from "./ResultContainer";
import { ResultHeader, ResultIcon, ResultTitle } from "./styled";

export const DefaultResult = memo(function Result({
  command,
  index,
  matches,
}: SwitchedResultProps) {
  const icon = getIcon(command);

  return (
    <ResultContainer command={command} index={index}>
      <ResultIcon iconType={icon} />
      <ResultHeader>
        <ResultTitle>
          <HighlightedText indexKey={TITLE_INDEX_KEY} matches={matches}>
            {command.title}
          </HighlightedText>
        </ResultTitle>

        {command.description && (
          <ResultDescription>
            <HighlightedText indexKey={DESCRIPTION_INDEX_KEY} matches={matches}>
              {command.description}
            </HighlightedText>
          </ResultDescription>
        )}
      </ResultHeader>
    </ResultContainer>
  );
});

function getIcon(command: Command): IconType {
  switch (command.type) {
    case "templates":
      return "templates";

    case "labelKeys":
    case "labelValues":
      return DEFAULT_ICON;

    default:
      return command.icon ?? DEFAULT_ICON;
  }
}

DefaultResult.whyDidYouRender = true;

const ResultDescription = styled.p`
  grid-row: 2 / 3;
  margin: 0;

  ${({ theme }) => css`
    font: ${theme.font.body.xs.regular};
    color: ${theme.color.fg.muted};
  `};
`;
