import { createSelector } from "reselect";

import type { NotebookState, NotebooksState, RootState } from "../state";

export const selectNotebooks = (state: RootState): NotebooksState =>
  state.notebooks;

export const selectActiveNotebook = (state: RootState): NotebookState =>
  selectNotebooks(state).activeNotebook;

export const selectActiveNotebookId = (state: RootState): string =>
  selectActiveNotebook(state).id;

export const selectAllNotebooksLocal = (
  state: NotebooksState,
): Array<NotebookState> =>
  state.activeNotebook.id
    ? [state.activeNotebook, ...Object.values(state.otherNotebooks)]
    : Object.values(state.otherNotebooks);

export const selectAllNotebooks: (state: RootState) => Array<NotebookState> =
  createSelector([selectNotebooks], selectAllNotebooksLocal);

export const selectHasActiveNotebook = (state: RootState): boolean =>
  !!selectActiveNotebookId(state);

export const selectOpenNotebookIds = createSelector(
  [selectAllNotebooks],
  (notebooks) => notebooks.map((notebook) => notebook.id),
);

export const selectNotebook = (state: RootState, id: string) =>
  selectNotebookLocal(selectNotebooks(state), id);

const selectNotebookLocal = (state: NotebooksState, id: string) =>
  state.otherNotebooks[id] ?? state.activeNotebook;

export const makeNotebookSelector = (id: string) =>
  createSelector([selectNotebooks], (state) => selectNotebookLocal(state, id));

export const makeNotebookIsActiveSelector = (id: string) =>
  createSelector([selectNotebooks], (state) => id === state.activeNotebook.id);

export const makeNotebookTitleSelector = (id: string) =>
  createSelector([makeNotebookSelector(id)], (notebook) => notebook.title);
