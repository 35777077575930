import { Input } from "@fiberplane/ui";
import { type Control, useController } from "react-hook-form";
import { styled } from "styled-components";

import type { Webhook } from "../../../../types";
import { ModalDescription } from "../../../UI";

export function EnabledField({ control }: { control: Control<Webhook> }) {
  const {
    field: { onChange, value: enabled = false },
  } = useController({ control, name: "enabled" });

  return (
    <>
      <ToggleContainer>
        <ModalDescription>
          When active, this webhook will receive HTTPS notifications about
          notebook changes
        </ModalDescription>

        <Input
          type="lightswitch"
          checked={enabled}
          onChange={({ target }) => onChange(target.checked)}
        />
      </ToggleContainer>
    </>
  );
}

const ToggleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
`;
