import { useThemeSelect } from "@fiberplane/hooks";
import { ErrorBoundary } from "@sentry/react";
import { memo, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { replace } from "redux-first-history";

import {
  useFeature,
  useFeaturesFromSearchParams,
  useWindowFocus,
} from "../../hooks";
import { selectIsAuthenticated } from "../../selectors";
import { Api } from "../../services";
import { dispatch, getState } from "../../store";
import {
  fetchAuthUser,
  initBroadcastChannel,
  showSettingsModal,
  signOut,
} from "../../thunks";
import { track } from "../../utils";
import { ConnectionMonitor } from "../ConnectionMonitor";
import { ContextMenu } from "../ContextMenu";
import { SlackIntegration } from "../Integrations";
import { Layout } from "../Layout";
import { Modals } from "../Modals";
import { NewNotebook } from "../Notebook";
import { Notifications } from "../Notifications";
import { RedirectToWorkspaceGraph } from "../PrometheusGraphAppend";
import { ActiveTooltip, useTextTooltips } from "../Tooltip";
import { WorkspaceInvitation } from "../WorkspaceInvitation";
import { Workspaces } from "../Workspaces";
import { NoMatch } from "./NoMatch";
import {
  useCloseTabProtection,
  useGlobalShortcuts,
  useHashParams,
} from "./hooks";

export const App = memo(function App() {
  useCloseTabProtection();
  useFeaturesFromSearchParams();
  useGlobalShortcuts();
  useTextTooltips();
  useThemeSelect();
  useWindowFocus();

  const [hasAutometricsFeature] = useFeature("autometrics");

  const { token, integrationInstallSuccess } = useHashParams();
  const hasToken = !!token;

  useEffect(() => {
    if (hasToken) {
      dispatch(replace(`${location.pathname}${location.search}`));
      track("login");
    }

    if (selectIsAuthenticated(getState())) {
      dispatch(fetchAuthUser());
    }

    if (integrationInstallSuccess) {
      dispatch(
        showSettingsModal({
          section: "workspaceIntegrations",
          integrationId: "githubapp",
        }),
      );
    }

    Api.addSessionExpiredListener(() => dispatch(signOut()));
  }, [hasToken, integrationInstallSuccess]);

  useEffect(() => {
    dispatch(initBroadcastChannel());
  }, []);

  return (
    <Layout>
      <ErrorBoundary>
        {!token && (
          <Routes>
            {hasAutometricsFeature && (
              <Route path="graph" element={<RedirectToWorkspaceGraph />} />
            )}
            <Route path="new" element={<NewNotebook />} />
            <Route path="integrations/slack" element={<SlackIntegration />} />
            <Route
              path="invitations/:invitationId/:invitationSecret"
              element={<WorkspaceInvitation />}
            />
            <Route
              path="workspaces/:workspaceName/*"
              element={<Workspaces />}
            />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        )}
      </ErrorBoundary>

      <ConnectionMonitor />
      <Notifications />
      <Modals />
      <ActiveTooltip />
      <ContextMenu />
    </Layout>
  );
});
