import type {
  Annotation,
  DeleteFromCursorPayload,
  MoveCursorPayload,
  NotebookAction,
  NotebookContextMenuInfo,
  NotebookContextMenuType,
  NotebookFocus,
  Rect,
  ReplaceCellsPayload,
  ReplaceTextPayload,
  SplitCellPayload,
} from "../types";
import { uuid64 } from "../utils";

export const blur = (): NotebookAction => ({ type: "blur" });

export const closeContextMenu = (): NotebookAction => ({
  type: "close_context_menu",
});

export const deleteFromCursor = (
  payload: DeleteFromCursorPayload,
): NotebookAction => ({ type: "delete_from_cursor", payload });

export const deleteLinkAtCursor = (): NotebookAction => ({
  type: "delete_link_at_cursor",
});

export const moveCursor = (payload: MoveCursorPayload): NotebookAction => ({
  type: "move_cursor",
  payload,
});

export const openContextMenu = (
  payload: NotebookContextMenuInfo,
): NotebookAction => ({
  type: "open_context_menu",
  payload: {
    ...payload,
    cursorRect: payload.cursorRect
      ? cloneClientRect(payload.cursorRect)
      : undefined,
  },
});

export const changeContextMenuType = (
  payload: NotebookContextMenuType,
): NotebookAction => ({
  type: "change_context_menu_type",
  payload,
});

export const replaceCells = (payload: ReplaceCellsPayload): NotebookAction => ({
  type: "replace_cells",
  payload,
});

export const replaceText = (payload: ReplaceTextPayload): NotebookAction => ({
  type: "replace_text",
  payload,
});

export const splitCell = ({
  newId = uuid64(),
  ...payload
}: Omit<SplitCellPayload, "newId"> &
  Partial<Pick<SplitCellPayload, "newId">>): NotebookAction => ({
  type: "split_cell",
  payload: { newId, ...payload },
});

export const toggleFormatting = (payload: Annotation): NotebookAction => ({
  type: "toggle_formatting",
  payload,
});

export const endActiveFormatting = (): NotebookAction => ({
  type: "end_active_formatting",
});

/**
 * Sets the focus directly.
 */
export function setFocus(focus: NotebookFocus): NotebookAction {
  return { type: "set_focus", payload: focus };
}

export const updateLinkAtCursor = (url: string): NotebookAction => ({
  type: "update_link_at_cursor",
  payload: url,
});

function cloneClientRect(rect: Rect): Rect {
  // This is very unfortunate, but Rect properties are not enumerable,
  // so if we don't make them explicit, we cannot serialize them down the line...
  return {
    width: rect.width,
    height: rect.height,
    x: rect.x,
    y: rect.y,
  };
}
