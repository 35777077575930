import type { FilterMenuItem } from "./types";

export function filterItems(
  items: Array<FilterMenuItem>,
  filterText: string,
): Array<FilterMenuItem> {
  const filteredItems = items.filter(filterChild(filterText.toLowerCase()));

  // Dividers did not yet get filtered out, but we want to get rid of
  // them if there are no items before or after them:
  for (let i = 0; i < filteredItems.length; i++) {
    if (filteredItems[i]?.type === "divider") {
      const isFirstOrLast = i === 0 || i === filteredItems.length - 1;
      const nextType = filteredItems[i + 1]?.type;
      if (
        isFirstOrLast ||
        nextType === "divider" ||
        nextType === "item_group"
      ) {
        filteredItems.splice(i, 1);
        i--;
      }
    }
  }

  return filteredItems.map(filterNestedItems(filterText));
}

const filterChild =
  (filterText: string) =>
  (item: FilterMenuItem): boolean => {
    if ("title" in item && filterTitle(item.title, filterText.toLowerCase())) {
      return true;
    }

    // Items match as long as one of the nested items matches:
    if ("items" in item && item.items.some(filterChild(filterText))) {
      return true;
    }

    return item.type === "divider";
  };

const filterNestedItems =
  (filterText: string) =>
  (item: FilterMenuItem): FilterMenuItem => {
    if (
      "items" in item &&
      // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version (which is less readable)
      (!("title" in item) || !filterTitle(item.title, filterText))
    ) {
      return {
        ...item,
        items: item.items
          .filter(filterChild(filterText))
          .map(filterNestedItems(filterText)),
      };
    }

    return item;
  };

function filterTitle(title: string, filterText: string): boolean {
  const character = filterText[0];
  if (!character) {
    return true;
  }

  const words = title.toLowerCase().split(wordBoundaryRegex);
  return words.some((word, i) => {
    let position = 0;
    while (true) {
      const index = word.indexOf(character, position);
      if (index === -1) {
        return false;
      }

      position = index + 1;
      if (filterMatches(words.slice(i), position, filterText.slice(1))) {
        return true;
      }
    }
  });
}

function filterMatches(
  words: Array<string>,
  firstWordPosition: number,
  filterText: string,
): boolean {
  const charCode = filterText.charCodeAt(0);
  if (!charCode) {
    return true; // No more characters to filter on, so we have a match!
  }

  const [firstWord, ...restWords] = words;
  if (!firstWord) {
    return false; // No more words left, there cannot be any match...
  }

  if (isWordBoundary(charCode)) {
    // If we're at a word boundary in the filter text, we must jump a word.
    return filterMatches(
      firstWordPosition > 0 ? restWords : words,
      0,
      filterText.slice(1),
    );
  }

  if (
    firstWord.charCodeAt(firstWordPosition) === charCode &&
    filterMatches(words, firstWordPosition + 1, filterText.slice(1))
  ) {
    return true; // Completing the first word lead to a match!
  }

  return restWords.some(
    (word, i) =>
      word.charCodeAt(0) === charCode &&
      filterMatches(restWords.slice(i), 1, filterText.slice(1)),
  );
}

function isWordBoundary(charCode: number): boolean {
  return charCode === 32 || charCode === 95;
}

const wordBoundaryRegex = /[ _]/;
