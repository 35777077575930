import { generatePath } from "react-router";
import { push } from "redux-first-history";

import { viewsApi } from "../api";
import { ROUTES } from "../constants";
import { NotFoundError, normalizeException } from "../errors";
import {
  selectActiveViewName,
  selectActiveWorkspaceIdOrThrow,
  selectActiveWorkspaceNameOrThrow,
} from "../selectors";
import type { Thunk } from "../store";
import { addNotification } from "./notificationsThunks";

export const deleteView =
  (viewName: string): Thunk =>
  async (dispatch, getState) => {
    const state = getState();
    const workspaceId = selectActiveWorkspaceIdOrThrow(state);

    try {
      await dispatch(
        viewsApi.endpoints.deleteView.initiate({ viewName, workspaceId }),
      ).unwrap();
    } catch (error) {
      const normalizedError = normalizeException(error);
      if (normalizedError instanceof NotFoundError) {
        dispatch(
          viewsApi.util.invalidateTags([
            { type: "PinnedView", id: viewName },
            { type: "View", id: viewName },
          ]),
        );
      } else {
        dispatch(
          addNotification({
            type: "danger",
            title: "An error ocurred while trying to delete the view",
          }),
        );
      }
    }

    const activeViewName = selectActiveViewName(state);
    if (activeViewName === viewName) {
      const workspaceName = selectActiveWorkspaceNameOrThrow(state);
      dispatch(push(generatePath(ROUTES.Views, { workspaceName })));
    }
  };

export const pinView =
  (viewName: string): Thunk =>
  async (dispatch, getState) => {
    const workspaceId = selectActiveWorkspaceIdOrThrow(getState());

    try {
      await dispatch(
        viewsApi.endpoints.pinView.initiate({ viewName, workspaceId }),
      ).unwrap();

      dispatch(
        addNotification({
          title: "View has been pinned",
        }),
      );
    } catch {
      dispatch(
        addNotification({
          type: "danger",
          title: "An error occurred while pinning the view, try again later",
        }),
      );
    }
  };

export const unpinView =
  (viewName: string): Thunk =>
  async (dispatch, getState) => {
    const workspaceId = selectActiveWorkspaceIdOrThrow(getState());

    try {
      await dispatch(
        viewsApi.endpoints.unpinView.initiate({ viewName, workspaceId }),
      ).unwrap();

      dispatch(
        addNotification({
          title: "View is no longer pinned",
        }),
      );
    } catch {
      dispatch(
        addNotification({
          type: "danger",
          title: "An error occurred while unpinning the view, try again later",
        }),
      );
    }
  };
