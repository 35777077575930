import { Icon, type IconType } from "@fiberplane/ui";
import { css, styled } from "styled-components";

type EmptyMessageProps = {
  action: React.ReactNode;
  description: React.ReactNode;
  iconType: IconType;
  title: string;
};

/**
 * Component used to display an empty message in a settings section.
 */
export function EmptyMessage({
  action,
  description,
  iconType,
  title,
}: EmptyMessageProps) {
  return (
    <Container>
      <SectionIconContainer>
        <SectionIcon iconType={iconType} />
      </SectionIconContainer>

      <Content>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Content>

      {action}
    </Container>
  );
}

const SectionIconContainer = styled.div(
  ({ theme }) => css`
    display: grid;
    place-items: center;
    padding: 10px;
    background-color: ${theme.color.primary.brand[50]};
    border-radius: ${theme.radius.default};
    box-shadow: ${theme.effect.shadow.xxs};
  `,
);

const SectionIcon = styled(Icon)(
  ({ theme }) => css`
    color: ${theme.color.fg.primary};
  `,
);

const Container = styled.div(
  ({ theme }) => css`
    background: ${theme.color.button.secondary.bg};
    border: 1px solid ${theme.color.primary.brand[300]};
    border-radius: ${theme.radius.rounded};
    display: grid;
    grid: auto-flow / 1fr;
    justify-items: center;
    gap: 12px;
    padding: 32px 12px;
  `,
);

const Content = styled.div`
  display: grid;
  gap: 8px;
  place-items: center;
`;

const Title = styled.h3(
  ({ theme }) => css`
    color: ${theme.color.fg.default};
    margin: 0;
    
    /* There's no variable defined in Figma for this use case */
    font: ${theme.font.headings.h1};
    line-height: 1.2;
    font-size: 20px;
  `,
);

const Description = styled.p(
  ({ theme }) => css`
    color: ${theme.color.fg.muted};
    font: ${theme.font.body.md.regular};
    margin: 0;
  `,
);
