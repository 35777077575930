import { useHandler } from "@fiberplane/hooks";
import { Button, IconButton, Input } from "@fiberplane/ui";
import { useContext, useState } from "react";
import type { FieldErrors } from "react-hook-form";
import { push } from "redux-first-history";
import styled from "styled-components";
import { useCopyToClipboard } from "../../../hooks";
import { createReceiver } from "../../../slices";
import { dispatch } from "../../../store";
import { addNotification } from "../../../thunks";
import type { PagerDutyReceiver } from "../../../types";
import { FiberForm, FiberFormTextInputField } from "../../FiberForm";
import { ButtonBar, Modal, ModalContext } from "../../UI";
import { InitialSecretForm } from "./InitialSecretForm";
import { TemplateField } from "./TemplateField";
import { CreateReceiverFormState } from "./schemas";

const defaultValues: CreateReceiverFormState = {
  incidentCreatedTemplateName: "",
  pagerDutyReceiverName: "",
  secret: null,
};

export function CreatePagerDutyReceiverModal() {
  const { requestClose } = useContext(ModalContext);

  const [receiver, setReceiver] = useState<PagerDutyReceiver>();
  const [errors, setErrors] = useState<FieldErrors<CreateReceiverFormState>>();

  const { handleCopy } = useCopyToClipboard(receiver?.webhookUrl ?? "");
  const handleCopyWebhookUrl = useHandler(() => {
    if (!receiver) {
      return;
    }

    handleCopy();

    dispatch(
      addNotification({
        title: "Webhook url has been copied to your clipboard",
      }),
    );
  });

  const onSubmit = useHandler(async (payload: CreateReceiverFormState) => {
    const result = await dispatch(createReceiver(payload)).unwrap();

    if ("error" in result) {
      switch (result.error) {
        case "duplicate_name":
          return setErrors((current) => ({
            ...current,
            pagerDutyReceiverName: {
              type: "error",
              message: "A receiver with this name already exists",
            },
          }));
        case "creation_template_not_found":
          return setErrors((current) => ({
            ...current,
            incidentCreatedTemplateName: {
              type: "error",
              message: "This template does not exists",
            },
          }));
      }

      return setErrors((current) => ({
        ...current,
        root: {
          type: "error",
          message: "An unexpected error occured",
        },
      }));
    }

    if (result instanceof Error) {
      return setErrors((current) => ({
        ...current,
        root: {
          type: "error",
          message: "An unexpected error occured",
        },
      }));
    }

    setReceiver(result);
    dispatch(push("?overlay=settings/workspaceIntegrations/pagerdutywebhook"));
  });
  if (receiver) {
    return (
      <Modal title="PagerDuty Webhook Receiver created">
        <Result>
          <ResultText>
            You can now configure your webhook in PagerDuty with the following
            url:
          </ResultText>
          <FormContainer>
            <InputContainer>
              <Input
                type="text"
                value={receiver.webhookUrl}
                readOnly
                id="webhookUrl"
              />
              <CopyIconButton
                iconType="copy"
                onClick={handleCopyWebhookUrl}
                buttonStyle="tertiary-grey"
              />
            </InputContainer>
          </FormContainer>
          <ResultText>
            To make sure we can receive requests from PagerDuty, configure the
            secret key:
          </ResultText>
          <InitialSecretForm
            name={receiver.name}
            onCancel={requestClose}
            onSuccess={requestClose}
          />
        </Result>
      </Modal>
    );
  }

  return (
    <Modal title="Create a new PagerDuty Receiver">
      <FiberForm
        defaultValues={defaultValues}
        errors={errors}
        schema={CreateReceiverFormState}
        submitErrorHandler={setErrors}
        submitHandler={onSubmit}
      >
        {(control) => (
          <>
            <FiberFormTextInputField
              control={control}
              description="An alphanumeric value that will be used as an identifier for the receiver"
              label="Name"
              name="pagerDutyReceiverName"
            />
            <TemplateField
              control={control}
              name="incidentCreatedTemplateName"
            />
            <ButtonBar>
              <Button
                type="button"
                buttonStyle="secondary"
                onClick={requestClose}
              >
                Cancel
              </Button>
              <Button type="submit">Create</Button>
            </ButtonBar>
          </>
        )}
      </FiberForm>
    </Modal>
  );
}

const Result = styled.div`
  display: grid;
  gap: 12px;
`;

const FormContainer = styled.div`
  display: grid;
  gap: 12px;
  margin-bottom: 32px;
`;

const ResultText = styled.p`
  margin: 0; 
`;

const InputContainer = styled.div`
  display: grid;
  position: relative;
`;

const CopyIconButton = styled(IconButton)`
  position: absolute;
  /* Offset the input's border */
  right: 1px;
  top: 1px;
`;
