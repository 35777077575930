import type { SelectedDataSource } from "../types";

/**
 * A selected data source can be formatted to a string using one of the
 * following two formats, depending on whether the data source is coming from
 * a proxy or not:
 *
 * - `${proxyName}:${dataSourceName}`
 * - dataSourceName
 */
export function formatDataSourceKey(
  selectedDataSource: SelectedDataSource,
): string {
  return selectedDataSource.proxyName
    ? `${selectedDataSource.proxyName}:${selectedDataSource.name}`
    : selectedDataSource.name;
}

/**
 * Tests whether two instance refer to the same data source, by checking their
 * name and proxy name.
 */
export function isSameDataSource(
  dataSource: SelectedDataSource,
  other: SelectedDataSource | undefined,
): boolean {
  return (
    dataSource.name === other?.name &&
    (dataSource.proxyName
      ? dataSource.proxyName === other.proxyName
      : !other.proxyName)
  );
}

/**
 * The opposite of `formatDataSourceKey()`.
 */
export function parseSelectedDataSource(
  selectedDataSource: string,
): SelectedDataSource {
  const colonIndex = selectedDataSource.indexOf(":");
  return colonIndex > -1
    ? {
        proxyName: selectedDataSource.slice(0, colonIndex),
        name: selectedDataSource.slice(colonIndex + 1),
      }
    : { name: selectedDataSource };
}
