import type { AvatarData, AvatarType } from "../state";

export const UPDATE_AVATAR_IMAGE = "avatars/updateImage";

type UpdateAvatarImage = {
  payload: {
    avatarType: AvatarType;
    data: Omit<AvatarData, "lastUpdated"> &
      Partial<Pick<AvatarData, "lastUpdated">>;
    id: string;
  };
  type: typeof UPDATE_AVATAR_IMAGE;
};

export type AvatarsAction = UpdateAvatarImage;
