import { charIndex, last } from "../../../../utils";

export function getContainerElForCellField(
  cellId: string,
  field: string | undefined,
) {
  return document.querySelector<HTMLElement>(
    field
      ? `[data-cell-id="${cellId}"] [data-field="${field}"][data-rte-container]`
      : `[data-cell-id="${cellId}"] [data-rte-container]`,
  );
}

// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: Complexity seems inherent...
export function getNodeAndIndexForOffset(
  containerEl: HTMLElement,
  text: string,
  offset: number,
): [Node, number] | null {
  try {
    const editorNodes = containerEl.firstChild?.childNodes;
    if (!editorNodes) {
      return null;
    }

    const lastEditorNode = editorNodes && last(editorNodes);
    const targetIndex = charIndex(text, offset);

    for (const editorNode of editorNodes) {
      if (editorNode instanceof HTMLElement) {
        const startOffset = Number.parseInt(
          editorNode.dataset.startOffset ?? "0",
        );
        const endOffset = Number.parseInt(editorNode.dataset.endOffset ?? "0");

        if (
          offset >= startOffset &&
          (editorNode === lastEditorNode
            ? offset <= endOffset
            : offset < endOffset)
        ) {
          const child = editorNode.childNodes[0];
          const textNode = child?.nodeType === Node.TEXT_NODE ? child : null;

          // It's either a text node, or it's some other renderable node like a mention
          return textNode
            ? [textNode, targetIndex - charIndex(text, startOffset)]
            : [editorNode, 0];
        }
      }
    }
  } catch (error: unknown) {
    console.warn(
      `Exception trying to determine node and index for offset ${offset} in text "${text}": ${error}`,
    );
  }

  return null;
}

// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: Complexity seems inherent...
export function getOffsetForNodeAndIndex(
  containerEl: HTMLElement,
  node: Node,
  index: number,
): number {
  // Is the 'node' we're trying to get the offset for the main RTE container itself?
  // (i.e. during a Firefox 'select all' this is what happens)
  if (node instanceof HTMLElement && node === containerEl.firstChild) {
    // Yes, so 'index' is dealing with full spans here
    if (index < node.childElementCount) {
      const child = node.childNodes[index] as HTMLElement;
      return Number.parseInt(child.dataset.startOffset ?? "0");
    }

    // Past end, so take the _end_ offset of the last child
    const child = node.lastChild as HTMLElement;
    return Number.parseInt(child.dataset.endOffset ?? "0");
  }

  try {
    const editorNodes = containerEl.firstChild?.childNodes;
    if (editorNodes) {
      for (const editorNode of editorNodes) {
        if (editorNode instanceof HTMLElement) {
          const nodeOffset = Number.parseInt(
            editorNode.dataset.startOffset ?? "0",
          );
          const [textNode] = editorNode.childNodes;
          if (node === textNode) {
            return nodeOffset + index;
          }
        }
      }
    }

    return index;
  } catch (error: unknown) {
    console.warn(
      `Exception trying to determine offset for node and index: ${error}`,
    );
    return 0;
  }
}
