import { styled } from "styled-components";

import { codePreStyling, preStyling } from "../../../../../UI";

export const Pre = styled.pre`
  grid-area: content;
  margin: 0;
  padding: 0;
  max-width: 100%;
  overflow-x: scroll;
  ${/* sc-declaration */ preStyling}
  ${/* sc-declaration */ codePreStyling}
`;
