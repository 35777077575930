import { type Draft, produce } from "immer";
import type { Action } from "redux";

type ReducerType<A extends Action, S> = {
  [K in A["type"]]?: (state: Draft<S>, action: Extract<A, { type: K }>) => void;
};

export const createImmerReducer =
  <A extends Action, S>(initialState: S, actionReducers: ReducerType<A, S>) =>
  // biome-ignore lint/style/useDefaultParameterLast: warranted here
  (state = initialState, action: A) => {
    const reducer = actionReducers[action.type as A["type"]];
    return reducer
      ? // biome-ignore lint/suspicious/noExplicitAny: FIXME
        produce(state, (draft) => reducer(draft, action as any))
      : state;
  };
