import { usePrevious } from "@fiberplane/hooks";
import { Icon } from "@fiberplane/ui";
import { motion, useReducedMotion } from "framer-motion";
import { useContext, useLayoutEffect, useRef } from "react";
import { useSelector } from "react-redux";
import scrollIntoView from "scroll-into-view-if-needed";
import { css, styled } from "styled-components";

import { setCommandMenuSelectedIndex } from "../../../../actions";
import { selectCommandMenuSelectedIndex } from "../../../../selectors";
import { dispatch } from "../../../../store";
import type { Command } from "../../../../types";
import { CommandMenuContext } from "../CommandMenu";

type Props = {
  children: React.ReactNode;
  command: Command;
  index: number;
};

export function ResultContainer({ children, command, index }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const { activateCommand } = useContext(CommandMenuContext);

  const selectedIndex = useSelector(selectCommandMenuSelectedIndex);
  const previousSelectedIndex = usePrevious(selectedIndex);

  const selected = index === selectedIndex || undefined;
  const shouldReduceMotion = useReducedMotion();

  const isLabelSuggestion =
    command.type === "labelKeys" || command.type === "labelValues";

  useLayoutEffect(() => {
    if (selected && ref.current) {
      const block: ScrollLogicalPosition =
        previousSelectedIndex && previousSelectedIndex > selectedIndex
          ? "start"
          : "end";

      scrollIntoView(ref.current, {
        behavior: "smooth",
        block,
        scrollMode: "if-needed",
      });
    }
  }, [previousSelectedIndex, selected, selectedIndex]);

  return (
    <StyledResultContainer ref={ref}>
      <Button
        data-selected={selected}
        data-testid={`command-${command.key}`}
        onClick={() => activateCommand(command)}
        onMouseMove={() => dispatch(setCommandMenuSelectedIndex(index))}
        reducedPadding={isLabelSuggestion}
        tabIndex={index}
      >
        {children}
        {command.keyboardShortcut && (
          <Shortcut animate={selected ? "hidden" : "visible"}>
            {command.keyboardShortcut}
          </Shortcut>
        )}
      </Button>
      {selected && (
        <Selection
          transition={{ duration: shouldReduceMotion ? 0 : 0.125 }}
          layoutId="selection"
        >
          <SubmitIcon iconType="key_return" />
        </Selection>
      )}
    </StyledResultContainer>
  );
}

export const StyledResultContainer = styled.div`
  display: flex;
  position: relative;
`;

const Button = styled.button<{ reducedPadding?: boolean }>`
  flex: 0 0 100%;
  position: relative;
  display: grid;
  grid-template-columns: 20px 1fr 20px;
  grid-auto-rows: auto;
  grid-gap: 4px 20px;
  align-items: center;
  text-align: inherit;
  background: transparent;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  z-index: 2;

  ${({ reducedPadding }) =>
    reducedPadding &&
    css`
      padding: 8px 24px;
    `}
`;

const Shortcut = styled(motion.span)`
  grid-row: 1 / 3;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;

  ${({ theme }) => css`
    background: ${theme.color.bg.subtle};
    color: ${theme.color.fg.muted};
    border-radius: ${theme.radius.default};
  `}
`;

Shortcut.defaultProps = {
  transition: { duration: 0.125 },
  variants: {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  },
};

const Selection = styled(motion.div)`
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  left: 4px;
  z-index: 1;

  ${({ theme }) => css`
    background: ${theme.color.bg.hover};
    border-radius: ${theme.radius.default};
  `}
`;

const SubmitIcon = styled(Icon)`
  position: absolute;
  top: 0;
  right: 24px;
  height: 100%;
  width: 20px;
  color: ${({ theme }) => theme.color.fg.default};
`;
