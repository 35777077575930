import {
  type AppAction,
  FAIL_LOADING_PROXY,
  FAIL_LOADING_PROXY_LIST,
  FINISH_LOADING_PROXY,
  FINISH_LOADING_PROXY_LIST,
  SET_ACTIVE_WORKSPACE_NAME,
  START_LOADING_PROXY,
  START_LOADING_PROXY_LIST,
} from "../actions";
import type { ProxiesState } from "../state";
import { createImmerReducer } from "../utils";

export const initialState: ProxiesState = {
  proxyList: {
    loading: false,
    data: undefined,
  },
  proxies: new Map(),
};

export const proxiesReducer = createImmerReducer<AppAction, ProxiesState>(
  initialState,
  {
    [SET_ACTIVE_WORKSPACE_NAME]: () => initialState,
    [START_LOADING_PROXY_LIST]: (state) => {
      state.proxyList.loading = true;
    },
    [FINISH_LOADING_PROXY_LIST]: (state, action) => {
      state.proxyList.loading = false;
      state.proxyList.error = undefined;
      state.proxyList.data = action.payload;
    },
    [FAIL_LOADING_PROXY_LIST]: (state, action) => {
      state.proxyList.loading = false;
      state.proxyList.error = action.payload;
    },
    [START_LOADING_PROXY]: (state, action) => {
      const proxyState = state.proxies.get(action.payload.name);
      if (proxyState) {
        proxyState.loading = true;
      } else {
        state.proxies.set(action.payload.name, {
          loading: true,
          data: undefined,
        });
      }
    },
    [FINISH_LOADING_PROXY]: (state, action) => {
      const proxyState = state.proxies.get(action.payload.name);
      if (proxyState) {
        proxyState.loading = false;
        proxyState.error = undefined;
        proxyState.data = action.payload;
      } else {
        state.proxies.set(action.payload.name, {
          loading: false,
          data: action.payload,
        });
      }
    },
    [FAIL_LOADING_PROXY]: (state, action) => {
      const proxyState = state.proxies.get(action.payload.name);
      if (proxyState) {
        proxyState.loading = false;
        proxyState.error = action.payload.error;
      }
    },
  },
);
