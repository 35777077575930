import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  makeIsSelectedSelector,
  selectCellIds,
  selectNotebookFocus,
} from "../selectors";
import { getSelection } from "../utils";

/**
 * Returns the position of the cell in the selection. If the cell is not part of
 * the selection, it returns undefined.
 * @param cellId
 * @returns "start" | "middle" | "end" | undefined
 */
export function useSelectionCellPosition(cellId: string) {
  const selectHookState = useMemo(
    () =>
      createStructuredSelector({
        isSelected: makeIsSelectedSelector(cellId),
        cellIds: selectCellIds,
        focus: selectNotebookFocus,
      }),
    [cellId],
  );
  const { cellIds, focus, isSelected } = useSelector(selectHookState);

  return useMemo(() => {
    if (isSelected && focus.type === "selection") {
      const selection = getSelection(cellIds, focus);
      return getSelectionCellPosition(selection, cellId);
    }
  }, [cellId, cellIds, focus, isSelected]);
}

function getSelectionCellPosition(
  { start, end }: ReturnType<typeof getSelection>,
  cellId: string,
) {
  // Return undefined if only the text of a single cell is selected.
  if (start.cellId === end.cellId) {
    return;
  }

  if (start.cellId === cellId) {
    return "start";
  }

  if (start.cellId !== cellId && end.cellId !== cellId) {
    return "middle";
  }

  if (end.cellId === cellId) {
    return "end";
  }
}
