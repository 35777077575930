import { flushSync } from "react-dom";

import type { AppAction } from "../actions";
import { selectNotebookFocus } from "../selectors";
import type { Thunk } from "../store";
import { getFocusCellId } from "../utils";

let mainEl: HTMLElement | undefined;

/**
 * Maintains the scroll position of the currently focused cell, while
 * dispatching the given action.
 *
 * Assumes the element with ID "main" is also our main scroll container for the
 * notebook.
 */
export const fixScrollPosition =
  (action: AppAction): Thunk =>
  (dispatch, getState) => {
    const focusedCellId = getFocusCellId(selectNotebookFocus(getState()));
    const cellEl = focusedCellId && getCellEl(focusedCellId);
    if (!cellEl) {
      return dispatch(action);
    }

    if (!mainEl) {
      mainEl = document.getElementById("main") as HTMLElement;
    }

    const initialOffsetTop = cellEl.offsetTop;
    const initialScrollTop = mainEl.scrollTop;

    const cellIsAboveViewport =
      initialOffsetTop + cellEl.offsetHeight < initialScrollTop;
    const cellIsBelowViewport =
      initialOffsetTop > initialScrollTop + mainEl.offsetHeight;
    if (cellIsAboveViewport || cellIsBelowViewport) {
      // If the focused cell is not within the viewport, there is no need to
      // try to maintain the scroll position.
      return dispatch(action);
    }

    let result: unknown;
    flushSync(() => {
      result = dispatch(action);
    });

    const newFocusedCellId = getFocusCellId(selectNotebookFocus(getState()));
    if (newFocusedCellId === focusedCellId) {
      const newOffsetTop = getCellEl(focusedCellId)?.offsetTop;
      const contentAboveFocusedElementChanged =
        newOffsetTop !== undefined && newOffsetTop !== initialOffsetTop;

      const scrollTopWasAdjusted = mainEl.scrollTop !== initialScrollTop;

      if (contentAboveFocusedElementChanged && !scrollTopWasAdjusted) {
        mainEl.scrollTop += newOffsetTop - initialOffsetTop;
      }
    }

    return result;
  };

function getCellEl(cellId: string): HTMLElement | null {
  return document.querySelector<HTMLElement>(`[data-cell-id="${cellId}"]`);
}
