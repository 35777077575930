import {
  type AppAction,
  CLEAR_AUTHENTICATION,
  DELETE_THREAD,
  FETCH_THREADS_FULFILLED,
  FETCH_THREADS_PENDING,
  FETCH_THREADS_REJECTED,
  SET_ACTIVE_WORKSPACE_NAME,
  SET_THREAD_DELETION_PROMPT,
  SUBMIT_COMMENT_FULFILLED,
  SUBMIT_COMMENT_PENDING,
  SUBMIT_COMMENT_REJECTED,
  TOGGLE_THREAD_SHOW_REPLIES,
} from "../actions";
import type { DiscussionsState, ThreadState } from "../state";

const initialState: DiscussionsState = {
  fetchThreadsStatus: "none",
  threads: new Map<string, ThreadState>(),
};

export function discussionsReducer(
  state = initialState,
  action: AppAction,
): DiscussionsState {
  switch (action.type) {
    case CLEAR_AUTHENTICATION:
    case SET_ACTIVE_WORKSPACE_NAME:
      return initialState;

    case FETCH_THREADS_PENDING:
      return {
        ...state,
        fetchThreadsStatus: "pending",
      };

    case FETCH_THREADS_FULFILLED:
      return {
        ...state,
        fetchThreadsStatus: "success",
      };

    case FETCH_THREADS_REJECTED:
      return {
        ...state,
        fetchThreadsStatus: "error",
      };

    case SUBMIT_COMMENT_PENDING: {
      const previousThreads = state.threads.get(action.payload);

      const threads = new Map(state.threads).set(action.payload, {
        showReplies: !!previousThreads?.showReplies,
        showThreadDeletionPrompt: !!previousThreads?.showThreadDeletionPrompt,
        submitCommentStatus: "pending",
      });

      return {
        ...state,
        threads,
      };
    }

    case SUBMIT_COMMENT_FULFILLED: {
      const previousThreads = state.threads.get(action.payload);

      const threads = new Map(state.threads).set(action.payload, {
        showReplies: !!previousThreads?.showReplies,
        showThreadDeletionPrompt: !!previousThreads?.showThreadDeletionPrompt,
        submitCommentStatus: "success",
      });

      return {
        ...state,
        threads,
      };
    }

    case SUBMIT_COMMENT_REJECTED: {
      const previousThreads = state.threads.get(action.payload);

      const threads = new Map(state.threads).set(action.payload, {
        showReplies: !!previousThreads?.showReplies,
        showThreadDeletionPrompt: !!previousThreads?.showThreadDeletionPrompt,
        submitCommentStatus: "error",
      });

      return {
        ...state,
        threads,
      };
    }

    case SET_THREAD_DELETION_PROMPT: {
      const previousThreads = state.threads.get(action.payload.threadId);

      const threads = new Map(state.threads).set(action.payload.threadId, {
        showReplies: !!previousThreads?.showReplies,
        showThreadDeletionPrompt: action.payload.showThreadDeletionPrompt,
        submitCommentStatus: previousThreads?.submitCommentStatus ?? "none",
      });

      return {
        ...state,
        threads,
      };
    }

    case TOGGLE_THREAD_SHOW_REPLIES: {
      const previousThreads = state.threads.get(action.payload);

      const threads = new Map(state.threads).set(action.payload, {
        showReplies: !state.threads.get(action.payload)?.showReplies,
        showThreadDeletionPrompt: !!state.threads.get(action.payload)
          ?.showThreadDeletionPrompt,
        submitCommentStatus: previousThreads?.submitCommentStatus ?? "none",
      });

      return {
        ...state,
        threads,
      };
    }

    case DELETE_THREAD: {
      const threads = new Map(state.threads);
      threads.delete(action.payload);

      return {
        ...state,
        threads,
      };
    }

    default:
      return state;
  }
}
