import type { CellFocus } from "../types";
import { charCount, getFocusFromInput } from "../utils";

export type InputFocusHandlersParams = {
  field: string;
  focus: CellFocus;
  readOnly: boolean;
  text: string;
  onFocusChange(focus: CellFocus): void;
};

/**
 * A hook for handling focus changes on input element (i.e. elements that don't use the rich text input)
 */

type InputLikeElement = HTMLInputElement | HTMLTextAreaElement;

export function useInputFocusHandlers({
  field,
  focus,
  readOnly,
  text,
  onFocusChange,
}: InputFocusHandlersParams): {
  onClick: React.MouseEventHandler<InputLikeElement>;
  onFocus: React.FocusEventHandler<InputLikeElement>;
} {
  function onClick(event: React.MouseEvent<InputLikeElement>) {
    if (readOnly) {
      return;
    }

    event.stopPropagation();

    const focus = getFocusFromInput(event.currentTarget, field);
    onFocusChange(focus);
  }

  function onFocus(event: React.FocusEvent<InputLikeElement>) {
    if (focus.type !== "none" && getCellFocusField(focus) === field) {
      return;
    }

    if (event.currentTarget.selectionStart !== null) {
      event.stopPropagation();
      onFocusChange({
        type: "collapsed",
        field,
        offset: charCount(text, event.currentTarget.selectionStart),
      });
    }
  }

  return { onClick, onFocus };
}

export function getCellFocusField(focus: CellFocus): string | undefined {
  switch (focus.type) {
    case "none":
      return undefined;

    case "collapsed":
      return focus.field;

    case "selection":
      return focus.start.field;
  }
}
