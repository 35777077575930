import { useMemo } from "react";
import { css, styled } from "styled-components";

import {
  Menu,
  MenuDivider,
  MenuItem,
  MenuItemGroup,
  MenuItemWithDescription,
  MenuItemWithSubMenu,
  type MenuProps,
} from "../Menu";
import { filterItems } from "./filterItems";
import { createFilterKeyboardListener } from "./keyboardListener";
import type { FilterMenuItem } from "./types";

export type FilterMenuProps = Omit<MenuProps, "children"> & {
  /**
   * Optional filter text. If provided, only items that match the filter text
   * are displayed.
   */
  filterText?: string;

  /**
   * The items to display in the menu.
   *
   * Only items that match `filterText` are actually shown.
   */
  items: Array<FilterMenuItem>;

  /**
   * Function that creates a custom menu item. Useful for adding a "Create new" item
   * or perhaps an interactive No results item.
   */
  createExtraMenuItem?: (options: {
    filterText: string;
    exactMatchFound: boolean;
  }) => FilterMenuItem | undefined;

  /**
   * Optional text that will be shown if no results match `filterText`.
   */
  noResultsText?: string;
};

/**
 * A menu component that allows filtering of its items.
 */
export function FilterMenu(props: FilterMenuProps): JSX.Element | null {
  const { filterText = "", items, noResultsText, createExtraMenuItem } = props;

  const filteredItems = useMemo(() => {
    const result = filterItems(items, filterText);
    const exactMatch = result.some((item) =>
      matchesFilterText(item, filterText),
    );

    const extraItem = createExtraMenuItem?.({
      filterText,
      exactMatchFound: !!exactMatch,
    });

    if (extraItem) {
      return [...result, extraItem];
    }

    return result;
  }, [filterText, items, createExtraMenuItem]);

  const children = useMemo(
    () =>
      filteredItems.length > 0
        ? filteredItems.map(toElement)
        : noResultsText && <NoResultsHeading>{noResultsText}</NoResultsHeading>,
    [filteredItems, noResultsText],
  );

  return (
    <Menu createKeyboardListener={createFilterKeyboardListener} {...props}>
      {children}
    </Menu>
  );
}

function matchesFilterText(item: FilterMenuItem, filterText: string): boolean {
  if (item.type === "divider") {
    return false;
  }

  const hasTitleMatch =
    item.title.toLowerCase().trim() === filterText.trim().toLowerCase();
  if (hasTitleMatch) {
    return true;
  }

  return (
    (item.type === "item_group" || item.type === "item_with_submenu") &&
    item.items.some((item) => matchesFilterText(item, filterText))
  );
}

function toElement(item: FilterMenuItem): JSX.Element {
  switch (item.type) {
    case "divider":
      return <MenuDivider key={item.id} />;

    case "item_group": {
      const { items, ...props } = item;
      return (
        <MenuItemGroup key={item.id} {...props}>
          {items.map(toElement)}
        </MenuItemGroup>
      );
    }

    case "item_with_description":
      return <MenuItemWithDescription key={item.id} {...item} />;

    case "item_with_icons":
      return <MenuItem key={item.id} {...item} />;

    case "item_with_submenu": {
      const { items, ...props } = item;
      return (
        <MenuItemWithSubMenu key={item.id} {...props}>
          {items.map(toElement)}
        </MenuItemWithSubMenu>
      );
    }
  }
}

const NoResultsHeading = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    height: 32px;
    padding-left: 12px;
    font: ${theme.font.body.sm.medium};
  `,
);
