import { useContext, useRef } from "react";
import { css, styled } from "styled-components";

import { MenuContext } from "./MenuContext";
import { MenuItemContainer } from "./MenuItemContainer";
import { useScrollIntoView } from "./useScrollIntoView";

export type MenuItemWithDescriptionProps = {
  /**
   * ID of the menu item.
   */
  id: string;

  title: string;

  description?: string;

  /**
   * Called when the menu item is activated (clicked or Enter pressed).
   */
  onActivate: () => void;
};

export function MenuItemWithDescription({
  id,
  description,
  onActivate,
  title,
}: MenuItemWithDescriptionProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { close, selection, setSelection } = useContext(MenuContext);
  const isSelected = selection.itemId === id;
  const shouldScroll = selection.selectedBy === "key";

  useScrollIntoView(containerRef, isSelected && shouldScroll);

  return (
    <Container
      data-menu-item-id={id}
      onClick={() => {
        onActivate();
        close({ reason: "item_activated" });
      }}
      onMouseMove={() => setSelection({ itemId: id, selectedBy: "mouse" })}
      ref={containerRef}
      isSelected={isSelected}
      data-selected={isSelected}
    >
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
}

const Container = styled(MenuItemContainer)`
  gap: 4px;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.h5(
  ({ theme }) => css`
    margin: 0;
    color: ${theme.color.fg.default};
  `,
);

const Description = styled.div(
  ({ theme }) => css`
    grid-area: desc;
    font: ${theme.font.body.sm.regular};
    color: ${theme.color.fg.muted};
  `,
);
