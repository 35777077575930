import { Button, Icon, IconButton } from "@fiberplane/ui";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { css, styled } from "styled-components";

import { useListInvitesQuery, useListMembersQuery } from "../../../../../api";
import {
  selectActiveWorkspaceIdOrThrow,
  selectActiveWorkspaceOwnerId,
  selectActiveWorkspaceType,
  selectCanDeleteInvites,
  selectCanInviteWorkspaceMember,
  selectCurrentUser,
} from "../../../../../selectors";
import { dispatch } from "../../../../../store";
import {
  showDeleteInviteConfirmation,
  showModal,
  showWorkspaceLeaveConfirmation,
} from "../../../../../thunks";
import type { Membership, WorkspaceInvite } from "../../../../../types";
import { sortBy } from "../../../../../utils";
import { ProfileAvatar } from "../../../../Avatar";
import { StatusBadge } from "../../../../UI";
import { SettingsPageActionButton } from "../shared";
import { RoleToggle } from "./RoleToggle";

const EMPTY_MEMBERS_LIST: Array<Membership> = [];
const EMPTY_INVITATIONS_LIST: Array<WorkspaceInvite> = [];

export function Members() {
  const {
    currentUser,
    ownerId,
    canInviteWorkspaceMember,
    canDeleteInvites,
    type,
    workspaceId,
  } = useSelector(selectMembersState);

  const { data: members = EMPTY_MEMBERS_LIST, isLoading: isLoadingUsers } =
    useListMembersQuery(
      { workspaceId },
      {
        refetchOnMountOrArgChange: true,
      },
    );

  const {
    data: invitations = EMPTY_INVITATIONS_LIST,
    isFetching: isFetchingInvitations,
  } = useListInvitesQuery(
    { workspaceId },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const sortedMembers = useMemo(() => {
    if (currentUser) {
      const users = [...members];
      const currentUserIndex = users.findIndex(
        ({ id }) => id === currentUser.id,
      );

      const currentUserInArray = users[currentUserIndex];
      if (currentUserInArray) {
        users.splice(currentUserIndex, 1);
        return [currentUserInArray, ...sortBy(users, ({ name }) => name)];
      }
    }

    return [];
  }, [currentUser, members]);

  if (isLoadingUsers) {
    return null;
  }

  const now = Date.now();

  return (
    <>
      {type !== "personal" && (
        <MembersTitle>{members.length} members</MembersTitle>
      )}
      {canInviteWorkspaceMember && (
        <SettingsPageActionButton
          onClick={() => dispatch(showModal({ type: "inviteWorkspaceMember" }))}
        >
          <Icon iconType="plus" /> Invite member
        </SettingsPageActionButton>
      )}

      <List>
        {sortedMembers.map((user) => (
          <Item key={user.id}>
            <StyledProfileAvatar
              userId={user.id}
              name={user.name}
              size={32}
              showTooltip={false}
            />

            <Name>
              {user.name}{" "}
              {user.id === currentUser?.id && (
                <CurrentUserLabel as="span">(you)</CurrentUserLabel>
              )}
            </Name>

            {ownerId !== user.id && user.id === currentUser?.id && (
              <LeaveButton
                onClick={() => dispatch(showWorkspaceLeaveConfirmation())}
                buttonStyle="secondary"
              >
                Leave team
              </LeaveButton>
            )}

            <Email as="p">{user.email}</Email>
            <RoleToggle member={user} />
          </Item>
        ))}

        {!isFetchingInvitations &&
          invitations?.map((invitation) => {
            const expired = now > new Date(invitation.expiresAt).getTime();

            return (
              <InvitationItem key={invitation.id}>
                <StyledProfileAvatar
                  userId={invitation.id}
                  name={invitation.receiver}
                  size={32}
                  showTooltip={false}
                />

                <Name>{invitation.receiver}</Name>

                <StatusBadge $status={expired ? "danger" : "neutral"}>
                  {expired ? "expired" : "pending"}
                </StatusBadge>

                {canDeleteInvites && (
                  <TrashIconButton
                    iconType="trash"
                    buttonStyle="tertiary-grey"
                    onClick={() =>
                      dispatch(showDeleteInviteConfirmation(invitation.id))
                    }
                  />
                )}
              </InvitationItem>
            );
          })}
      </List>
    </>
  );
}

const selectMembersState = createSelector(
  [
    selectCurrentUser,
    selectCanInviteWorkspaceMember,
    selectCanDeleteInvites,
    selectActiveWorkspaceOwnerId,
    selectActiveWorkspaceType,
    selectActiveWorkspaceIdOrThrow,
  ],
  (
    currentUser,
    canInviteWorkspaceMember,
    canDeleteInvites,
    ownerId,
    type,
    workspaceId,
  ) => {
    return {
      currentUser,
      canInviteWorkspaceMember,
      canDeleteInvites,
      ownerId,
      type,
      workspaceId,
    };
  },
);

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const MembersTitle = styled.h3(
  ({ theme }) => css`
    color: ${theme.color.fg.default};
    font: ${theme.font.headings.h5};
    margin: 0;
  `,
);

const Item = styled.li`
  display: grid;
  grid:
    "avatar name actions role" auto
    "avatar email actions role" auto
    / auto 1fr auto auto;
  gap: 0 20px;
  margin: 0;
`;

const InvitationItem = styled(Item)`
  grid: "avatar name status role" auto / auto auto auto 1fr;
  align-items: center;
`;

const StyledProfileAvatar = styled(ProfileAvatar)`
  grid-area: avatar;
  align-self: center;
`;

const Name = styled.h6(
  ({ theme }) => css`
    grid-area: name;
    margin: 0;
    font: ${theme.font.headings.h6};
    color: ${theme.color.fg.default};
  `,
);

const CurrentUserLabel = styled(Name)(
  ({ theme }) => css`
    display: inline-block;
    color: ${theme.color.fg.muted};
  `,
);

const Email = styled(CurrentUserLabel)`
  grid-area: email;
`;

const TrashIconButton = styled(IconButton)`
  grid-area: role;
  justify-self: end;
`;

const LeaveButton = styled(Button)`
  grid-area: actions;
`;
