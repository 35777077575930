import { Input, stopPropagation } from "@fiberplane/ui";
import {
  type FocusEventHandler,
  type InputHTMLAttributes,
  type KeyboardEventHandler,
  useLayoutEffect,
  useRef,
} from "react";

import { useKeyboardHandlers } from "../../../hooks";
import type { CellFocus } from "../../../types";
import { cellFocusesAreEqual, getFocusFromInput } from "../../../utils";

type SelectionDirection = HTMLInputElement["selectionDirection"];

type Props = InputHTMLAttributes<HTMLInputElement> & {
  focus: CellFocus;
  onFocusChange(focus: CellFocus): void;
};

export function TimeRangeInput(props: Props): JSX.Element {
  const {
    focus,
    onKeyPress,
    onKeyDown,
    onKeyUp,
    onFocusChange,
    onFocus,
    onBlur,
    ...inputProps
  } = props;

  const ref = useRef<null | HTMLInputElement>(null);
  const hasFocusRef = useRef<boolean>(false);

  const handleFocus: FocusEventHandler<HTMLInputElement> = (event) => {
    if (onFocus) {
      onFocus(event);
    }

    hasFocusRef.current = true;
  };
  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    if (onBlur) {
      onBlur(event);
    }

    hasFocusRef.current = false;
  };

  useLayoutEffect(() => {
    if (ref.current === null) {
      return;
    }

    if (focus.type === "none") {
      hasFocusRef.current = false;
      return;
    }

    if (hasFocusRef.current === false) {
      ref.current.focus();
      hasFocusRef.current = true;
    }

    const currentFocus = getFocusFromInput(ref.current);
    if (cellFocusesAreEqual(focus, currentFocus)) {
      return;
    }

    if (focus.type === "collapsed") {
      const { offset } = focus;
      if (offset !== undefined) {
        ref.current.setSelectionRange(offset, offset);
      }

      return;
    }

    const { start, end } = focus;
    if (start.offset !== undefined && end.offset !== undefined) {
      let direction: SelectionDirection = "none";
      if (focus.focus) {
        direction = focus.focus === end ? "forward" : "backward";
      }

      ref.current.setSelectionRange(start.offset, end.offset, direction);
    }
  }, [focus]);

  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (event) => {
    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
    if (!ref.current || !hasSelection(ref.current)) {
      return;
    }

    if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
      const focus = getFocusFromInput(ref.current);
      onFocusChange(focus);
    }

    onKeyPress?.(event);
  };

  const keyHandlers = useKeyboardHandlers<HTMLInputElement>({
    onKeyDown(event) {
      // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
      if (!ref.current || !hasSelection(ref.current)) {
        return;
      }

      if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
        stopPropagation(event);
      }

      onKeyDown?.(event);
    },
    onKeyUp(event) {
      // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
      if (!ref.current || !hasSelection(ref.current)) {
        return;
      }

      // Just to be sure we update the position.
      // This is because we don't always know which keys the user pressed
      // like ctrl/command + arrow-left will typically result in only
      // a keyup event for the ctrl/command (if it's the last key that was released for this combination)
      const focus = getFocusFromInput(ref.current);
      if (event.key !== "ArrowDown") {
        onFocusChange(focus);
      }

      if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
        stopPropagation(event);
      }

      onKeyUp?.(event);
    },
  });

  return (
    <Input
      ref={ref}
      type="text"
      {...inputProps}
      onKeyPress={handleKeyPress}
      onKeyDown={keyHandlers.onKeyDown}
      onKeyUp={keyHandlers.onKeyUp}
      onFocus={handleFocus}
      onBlur={handleBlur}
      data-prevent-rte
    />
  );
}

function hasSelection(input: HTMLInputElement): boolean {
  return input.selectionStart !== null && input.selectionEnd !== null;
}
