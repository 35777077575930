import { type RefObject, useRef } from "react";

import type { TimeRange } from "../../types";
import {
  MenuItem,
  MenuItemWithPopup,
  MenuItemWithSubMenu,
  type PositionedMenuProps,
  TimeRangePicker,
  ToggleMenu,
  type ToggleMenuMethods,
  type ToggleMenuProps,
} from "../UI";

type Props = {
  children: ToggleMenuProps["toggleElement"];
  onChange: (timeRange: TimeRange) => void;
  placement?: ToggleMenuProps["placement"];
  value: TimeRange;
  absolutePositioned?: boolean;
  disabled?: boolean;
  offset?: PositionedMenuProps["offset"];
  resetToGlobal?: () => void; // Forces time range to follow global notebook time
  useGlobally?: () => void; // Updates the global notebook time to use this time range's value
};

export function TimeRangeSelectorMenu(props: Props): JSX.Element {
  const {
    onChange,
    value,
    placement,
    children,
    absolutePositioned = false,
    disabled = false,
    offset,
    resetToGlobal,
    useGlobally,
  } = props;

  const menuRef = useRef<ToggleMenuMethods>(null);

  return (
    <ToggleMenu
      placement={placement ?? "bottom-start"}
      ref={menuRef}
      toggleElement={children}
      absolutePositioned={absolutePositioned}
      disabled={disabled}
      offset={offset}
    >
      <MenuItemWithSubMenu id="relative" title="Time range controls">
        {createMenuItemForRelativeMinutes(
          5,
          "Last 5 minutes",
          onChange,
          menuRef,
        )}
        {createMenuItemForRelativeMinutes(
          15,
          "Last 15 minutes",
          onChange,
          menuRef,
        )}
        {createMenuItemForRelativeMinutes(
          30,
          "Last 30 minutes",
          onChange,
          menuRef,
        )}
        {createMenuItemForRelativeMinutes(60, "Last 1 hour", onChange, menuRef)}
        {createMenuItemForRelativeMinutes(
          180,
          "Last 3 hours",
          onChange,
          menuRef,
        )}
        {createMenuItemForRelativeMinutes(
          360,
          "Last 6 hours",
          onChange,
          menuRef,
        )}
        {createMenuItemForRelativeMinutes(
          720,
          "Last 12 hours",
          onChange,
          menuRef,
        )}
        {createMenuItemForRelativeMinutes(
          1440,
          "Last 24 hours",
          onChange,
          menuRef,
        )}
        {createMenuItemForRelativeMinutes(
          1440 * 7,
          "Last 7 days",
          onChange,
          menuRef,
        )}
        {createMenuItemForRelativeMinutes(
          1440 * 30,
          "Last 30 days",
          onChange,
          menuRef,
        )}
      </MenuItemWithSubMenu>
      <MenuItemWithPopup id="absolute" title="Absolute time range">
        <TimeRangePicker
          value={value}
          onChange={(range: TimeRange) => {
            onChange(range);
            menuRef.current?.close();
          }}
        />
      </MenuItemWithPopup>
      {resetToGlobal && (
        <MenuItem
          id="reset-cell-timerange"
          title="Inherit notebook time range"
          onActivate={resetToGlobal}
        />
      )}
      {useGlobally && (
        <MenuItem
          id="set-notebook-timerange"
          title="Use as notebook time range"
          onActivate={useGlobally}
        />
      )}
    </ToggleMenu>
  );
}

function createMenuItemForRelativeMinutes(
  minutes: number,
  title: string,
  onChange: (timeRange: TimeRange) => void,
  menuRef: RefObject<ToggleMenuMethods>,
) {
  return (
    <MenuItem
      data-time-minutes={minutes}
      id={`${minutes}-minutes`}
      onActivate={() => {
        onChange(createRange(minutes));
        menuRef.current?.close();
      }}
      title={title}
    />
  );
}

function createRange(minutesDuration: number): TimeRange {
  const now = Date.now();
  return {
    from: new Date(now - minutesDuration * 60 * 1000).toISOString(),
    to: new Date(now).toISOString(),
  };
}
