import type { ModalTypeProperties } from "../../types";
import { AddGraphToNotebookModal } from "./AddGraphToNotebook";
import { AddProxyModal } from "./AddProxy";
import { AvatarUploader } from "./AvatarUploader";
import { CommandMenu } from "./CommandMenu";
import { ConfigureDirectAccessModal } from "./ConfigureDirectAccessModal";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { CreateGitHubPullRequestModal } from "./CreateGitHubPullRequestModal";
import { CreateProxyModal } from "./CreateProxyModal";
import { CreateWorkspaceModal } from "./CreateWorkspaceModal";
import { EditLinkModal } from "./EditLinkModal";
import { ExploreNotebooksModal } from "./ExploreNotebooksModal";
import { InstallCliModal } from "./InstallCliModal";
import { InviteWorkspaceMember } from "./InviteWorkspaceMember";
import {
  CreatePagerDutyReceiverModal,
  UpdatePagerDutyReceiverModal,
} from "./PagerDuty";
import { ReplaceLabelKeyWarningModal } from "./ReplaceLabelKeyWarningModal";
import { RoutedModal } from "./RoutedModal";
import { Shortcuts } from "./Shortcuts";
import { SnippetsModal } from "./Snippets";
import { TemplateDetail } from "./TemplateDetail";
import { TimelineEdit } from "./TimelineEdit";
import { NewWebhook, WebhookSecret, WebhookWithId } from "./Webhooks";

export function ModalContent({
  modal,
}: {
  modal: ModalTypeProperties;
}): JSX.Element {
  switch (modal.type) {
    case "addProxy":
      return <AddProxyModal />;
    case "addGraphToNotebook":
      return <AddGraphToNotebookModal />;
    case "avatarUploader":
      return <AvatarUploader {...modal} />;
    case "commandMenu":
      return <CommandMenu />;
    case "confirmationDialog":
      return <ConfirmationDialog {...modal} />;
    case "createProxy":
      return <CreateProxyModal />;
    case "createGitHubPullRequest":
      return <CreateGitHubPullRequestModal />;
    case "createWorkspace":
      return <CreateWorkspaceModal />;
    case "directAccess":
      return <ConfigureDirectAccessModal {...modal} />;
    case "editLink":
      return <EditLinkModal {...modal} />;
    case "editSnippet":
    case "createSnippet":
      return <SnippetsModal {...modal} />;
    case "createPagerDutyReceiver":
      return <CreatePagerDutyReceiverModal />;
    case "editPagerDutyReceiver":
      return <UpdatePagerDutyReceiverModal {...modal} />;
    case "editWebhook":
      return <WebhookWithId {...modal} />;
    case "exploreNotebooks":
      return <ExploreNotebooksModal />;
    case "installCli":
      return <InstallCliModal />;
    case "inviteWorkspaceMember":
      return <InviteWorkspaceMember />;
    case "newWebhook":
      return <NewWebhook />;
    case "replaceLabelKeyWarning":
      return <ReplaceLabelKeyWarningModal {...modal} />;
    case "routedModal":
      return <RoutedModal {...modal} />;
    case "shortcuts":
      return <Shortcuts />;
    case "templateDetail":
      return <TemplateDetail {...modal} />;
    case "timelineEdit":
      return <TimelineEdit />;
    case "webhookSecret":
      return <WebhookSecret {...modal} />;
  }
}
