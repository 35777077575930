import { css, styled } from "styled-components";

import { ProfileAvatar } from "../../Avatar";

export const PresenceProfileAvatar = styled(ProfileAvatar)(
  ({ theme }) => css`
    box-shadow: 0 0 0 1px ${theme.color.bg.default}, 0 0 0 2px currentColor;
    cursor: pointer;
    user-select: none;
  `,
);
