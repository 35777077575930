import type { Button, IconType } from "@fiberplane/ui";
import type { UpdatePagerDutyReceiver } from "./";
import type { DataSource } from "./dataSourceTypes";
import type { Feature } from "./features";
import type { IconVisualType } from "./iconVisualTypes";

export type ConfirmationDialogModalProps = React.PropsWithChildren<{
  iconVisual: IconVisualType;
  onCancel?: () => void;
  onCancelLabel?: string;
  onConfirm?: () => void;
  onConfirmButtonStyle?: React.ComponentProps<typeof Button>["buttonStyle"];
  onConfirmLabel?: string;
  title: string;
}>;

export type DeleteProxyModalProps = {
  name: string;
  onConfirm: () => void;
};

export type ConfigureDirectAccessModalProps = {
  /**
   * Optional existing data source to edit.
   */
  dataSource?: DataSource;

  /**
   * Optional callback that will be invoked if a new data source is created.
   */
  onCreate?: (dataSource: DataSource) => void;

  /**
   * Optional provider type to create a new data source for.
   *
   * Ignored if you provide an existing data source.
   */
  providerType?: string;
};

export type EditLinkModalProps = {
  initialUrl: string;
  isNewLink: boolean;
  onConfirm(url: string): void;
  onDelete(): void;
};

export type ReplaceLabelKeyWarningModalProps = {
  draft: string;
  onConfirm: () => void;
};

export type AvatarUploaderDone = (blob: Blob) => Promise<void>;

export type SettingsSection =
  | "data-sources"
  | "experimental"
  | "fpd"
  | "members"
  | "personalIntegrations"
  | "profile"
  | "theme"
  | "webhooks"
  | "workspace"
  | "workspaceIntegrations";

export type Setting = {
  category: "preferences" | "workspace";
  description?: React.ReactNode;
  feature?: Feature;
  iconType: IconType;
  section: SettingsSection;
  title: string;
};

export type ConfirmationDialogType = {
  type: "confirmationDialog";
} & ConfirmationDialogModalProps;

export type RoutedModalType = { type: "routedModal"; uri: string };

export type ModalTypeProperties =
  | { type: "addGraphToNotebook" }
  | { type: "addProxy" }
  | { type: "avatarUploader"; done: AvatarUploaderDone }
  | { type: "commandMenu" }
  | { type: "createGitHubPullRequest" }
  | { type: "createProxy" }
  | {
      type: "createSnippet";
      startCellId: string;
      endCellId: string;
      notebookId: string;
      hasUnsupportedCellType: boolean;
    }
  | { type: "createPagerDutyReceiver" }
  | { type: "createWorkspace" }
  | ({ type: "directAccess" } & ConfigureDirectAccessModalProps)
  | ({ type: "editLink" } & EditLinkModalProps)
  | ({ type: "replaceLabelKeyWarning" } & ReplaceLabelKeyWarningModalProps)
  | { type: "editSnippet"; snippetName: string }
  | {
      type: "editPagerDutyReceiver";
      name: string;
      receiver: UpdatePagerDutyReceiver;
    }
  | { type: "editWebhook"; webhookId: string }
  | { type: "exploreNotebooks" }
  | { type: "installCli" }
  | { type: "inviteWorkspaceMember" }
  | { type: "newWebhook" }
  | { type: "shortcuts" }
  | { type: "templateDetail"; templateName: string }
  | { type: "timelineEdit" }
  | { type: "webhookSecret"; secret: string }
  | RoutedModalType
  | ConfirmationDialogType;

export type ModalType = Pick<ModalTypeProperties, "type">["type"];
