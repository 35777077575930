import type { FocusPosition, NotebookFocus } from "../types";
import { getSelection, isCellInSelection } from "./selections";

export function focusPositionsAreEqual(
  position: FocusPosition,
  other: FocusPosition,
): boolean {
  return (
    position.cellId === other.cellId &&
    position.field === other.field &&
    position.offset === other.offset
  );
}

export function getAnchorCellId(focus?: NotebookFocus): string | undefined {
  return focus && getAnchorPosition(focus)?.cellId;
}

export function getAnchorOffset(focus: NotebookFocus): number {
  return getAnchorPosition(focus)?.offset ?? 0;
}

export function getAnchorPosition(
  focus: NotebookFocus,
): FocusPosition | undefined {
  switch (focus.type) {
    case "none":
      return undefined;

    case "collapsed":
      return focus;

    case "selection":
      return focus.anchor;
  }
}

export function getEndOffset(
  cellIds: ReadonlyArray<string>,
  focus: NotebookFocus,
): number {
  return getEndPosition(cellIds, focus)?.offset ?? 0;
}

export function getEndPosition(
  cellIds: ReadonlyArray<string>,
  focus: NotebookFocus,
): FocusPosition | undefined {
  switch (focus.type) {
    case "collapsed":
      return focus;

    case "selection":
      if (focus.anchor.cellId === focus.focus.cellId) {
        const anchorOffset = focus.anchor.offset ?? 0;
        const focusOffset = focus.focus.offset ?? 0;
        return anchorOffset > focusOffset ? focus.anchor : focus.focus;
      } else {
        const anchorCellIndex = cellIds.indexOf(focus.anchor.cellId);
        const focusCellIndex = cellIds.indexOf(focus.focus.cellId);
        return anchorCellIndex > focusCellIndex ? focus.anchor : focus.focus;
      }
  }
}

export function getFocusCellId(focus?: NotebookFocus): string | undefined {
  return focus && getFocusPosition(focus)?.cellId;
}

export function getFocusField(focus?: NotebookFocus): string | undefined {
  return focus && getFocusPosition(focus)?.field;
}

export function getFocusOffset(focus: NotebookFocus): number {
  return getFocusPosition(focus)?.offset ?? 0;
}

export function getFocusPosition(
  focus: NotebookFocus,
): FocusPosition | undefined {
  switch (focus.type) {
    case "none":
      return undefined;

    case "collapsed":
      return focus;

    case "selection":
      return focus.focus;
  }
}

/**
 * Returns all the cell IDs in the given focus.
 */
export function getSelectedCellIds(
  cellIds: ReadonlyArray<string>,
  focus: NotebookFocus,
): Array<string> {
  switch (focus.type) {
    case "none":
      return [];

    case "collapsed":
      return [focus.cellId];

    case "selection": {
      if (focus.anchor.cellId === focus.focus.cellId) {
        return [focus.anchor.cellId];
      }

      const anchorIndex = cellIds.indexOf(focus.anchor.cellId);
      const focusIndex = cellIds.indexOf(focus.focus.cellId);
      const startIndex = Math.min(anchorIndex, focusIndex);
      const endIndex = Math.max(anchorIndex, focusIndex);
      return cellIds.slice(startIndex, endIndex + 1);
    }
  }
}

export function getStartOffset(
  cellIds: ReadonlyArray<string>,
  focus: NotebookFocus,
): number {
  return getStartPosition(cellIds, focus)?.offset ?? 0;
}

export function getStartPosition(
  cellIds: ReadonlyArray<string>,
  focus: NotebookFocus,
): FocusPosition | undefined {
  switch (focus.type) {
    case "collapsed":
      return focus;

    case "selection":
      if (focus.anchor.cellId === focus.focus.cellId) {
        const anchorOffset = focus.anchor.offset ?? 0;
        const focusOffset = focus.focus.offset ?? 0;
        return anchorOffset < focusOffset ? focus.anchor : focus.focus;
      } else {
        const anchorCellIndex = cellIds.indexOf(focus.anchor.cellId);
        const focusCellIndex = cellIds.indexOf(focus.focus.cellId);
        return anchorCellIndex < focusCellIndex ? focus.anchor : focus.focus;
      }
  }
}

/**
 * Returns whether the given cell ID is within the given focus.
 *
 * @param cellIds Ordered list of all the cell IDs in the notebook.
 * @param cellId ID of the cell the check.
 * @param focus The focus to check.
 */
export function isCellInFocus(
  cellIds: ReadonlyArray<string>,
  cellId: string,
  focus: NotebookFocus,
): boolean {
  switch (focus.type) {
    case "none":
      return false;

    case "collapsed":
      return focus.cellId === cellId;

    case "selection": {
      const selection = getSelection(cellIds, focus);
      return isCellInSelection(cellIds, cellId, selection);
    }
  }
}
