/* GENERATED BY FIGMA PLUGIN. PLEASE DO NOT MODIFY */

export const theme = {
  color: {
    primary: {
      brand: {
        50: "var(--color-primary-brand-50)",
        100: "var(--color-primary-brand-100)",
        200: "var(--color-primary-brand-200)",
        300: "var(--color-primary-brand-300)",
        400: "var(--color-primary-brand-400)",
        500: "var(--color-primary-brand-500)",
        600: "var(--color-primary-brand-600)",
        700: "var(--color-primary-brand-700)",
        800: "var(--color-primary-brand-800)",
        900: "var(--color-primary-brand-900)",
        alpha: {
          50: "var(--color-primary-brand-alpha-50)",
          100: "var(--color-primary-brand-alpha-100)",
          200: "var(--color-primary-brand-alpha-200)",
          300: "var(--color-primary-brand-alpha-300)",
          400: "var(--color-primary-brand-alpha-400)",
        },
      },
    },
    neutral: {
      50: "var(--color-neutral-50)",
      100: "var(--color-neutral-100)",
      200: "var(--color-neutral-200)",
      300: "var(--color-neutral-300)",
      400: "var(--color-neutral-400)",
      500: "var(--color-neutral-500)",
      600: "var(--color-neutral-600)",
      700: "var(--color-neutral-700)",
      800: "var(--color-neutral-800)",
      900: "var(--color-neutral-900)",
      white: "var(--color-neutral-white)",
      alpha: {
        dark: {
          25: "var(--color-neutral-alpha-dark-25)",
          50: "var(--color-neutral-alpha-dark-50)",
          100: "var(--color-neutral-alpha-dark-100)",
          200: "var(--color-neutral-alpha-dark-200)",
          300: "var(--color-neutral-alpha-dark-300)",
          400: "var(--color-neutral-alpha-dark-400)",
        },
        light: {
          25: "var(--color-neutral-alpha-light-25)",
          50: "var(--color-neutral-alpha-light-50)",
          100: "var(--color-neutral-alpha-light-100)",
          200: "var(--color-neutral-alpha-light-200)",
          300: "var(--color-neutral-alpha-light-300)",
          400: "var(--color-neutral-alpha-light-400)",
        },
      },
      black: "var(--color-neutral-black)",
    },
    accent: {
      magenta: {
        100: "var(--color-accent-magenta-100)",
        300: "var(--color-accent-magenta-300)",
        500: "var(--color-accent-magenta-500)",
        700: "var(--color-accent-magenta-700)",
        900: "var(--color-accent-magenta-900)",
        alpha: {
          100: "var(--color-accent-magenta-alpha-100)",
          200: "var(--color-accent-magenta-alpha-200)",
          300: "var(--color-accent-magenta-alpha-300)",
          400: "var(--color-accent-magenta-alpha-400)",
        },
      },
      earth: {
        100: "var(--color-accent-earth-100)",
        300: "var(--color-accent-earth-300)",
        500: "var(--color-accent-earth-500)",
        700: "var(--color-accent-earth-700)",
        900: "var(--color-accent-earth-900)",
        alpha: {
          100: "var(--color-accent-earth-alpha-100)",
          200: "var(--color-accent-earth-alpha-200)",
          300: "var(--color-accent-earth-alpha-300)",
          400: "var(--color-accent-earth-alpha-400)",
        },
      },
      sea: {
        100: "var(--color-accent-sea-100)",
        300: "var(--color-accent-sea-300)",
        500: "var(--color-accent-sea-500)",
        700: "var(--color-accent-sea-700)",
        900: "var(--color-accent-sea-900)",
        alpha: {
          100: "var(--color-accent-sea-alpha-100)",
          200: "var(--color-accent-sea-alpha-200)",
          300: "var(--color-accent-sea-alpha-300)",
          400: "var(--color-accent-sea-alpha-400)",
        },
      },
      aqua: {
        100: "var(--color-accent-aqua-100)",
        300: "var(--color-accent-aqua-300)",
        500: "var(--color-accent-aqua-500)",
        700: "var(--color-accent-aqua-700)",
        900: "var(--color-accent-aqua-900)",
        alpha: {
          100: "var(--color-accent-aqua-alpha-100)",
          200: "var(--color-accent-aqua-alpha-200)",
          300: "var(--color-accent-aqua-alpha-300)",
          400: "var(--color-accent-aqua-alpha-400)",
        },
      },
      sky: {
        100: "var(--color-accent-sky-100)",
        300: "var(--color-accent-sky-300)",
        500: "var(--color-accent-sky-500)",
        700: "var(--color-accent-sky-700)",
        900: "var(--color-accent-sky-900)",
        alpha: {
          100: "var(--color-accent-sky-alpha-100)",
          200: "var(--color-accent-sky-alpha-200)",
          300: "var(--color-accent-sky-alpha-300)",
          400: "var(--color-accent-sky-alpha-400)",
        },
      },
      coral: {
        100: "var(--color-accent-coral-100)",
        300: "var(--color-accent-coral-300)",
        500: "var(--color-accent-coral-500)",
        700: "var(--color-accent-coral-700)",
        900: "var(--color-accent-coral-900)",
        alpha: {
          100: "var(--color-accent-coral-alpha-100)",
          200: "var(--color-accent-coral-alpha-200)",
          300: "var(--color-accent-coral-alpha-300)",
          400: "var(--color-accent-coral-alpha-400)",
        },
      },
      lime: {
        100: "var(--color-accent-lime-100)",
        300: "var(--color-accent-lime-300)",
        500: "var(--color-accent-lime-500)",
        700: "var(--color-accent-lime-700)",
        900: "var(--color-accent-lime-900)",
        alpha: {
          100: "var(--color-accent-lime-alpha-100)",
          200: "var(--color-accent-lime-alpha-200)",
          300: "var(--color-accent-lime-alpha-300)",
          400: "var(--color-accent-lime-alpha-400)",
        },
      },
      aubergine: {
        100: "var(--color-accent-aubergine-100)",
        300: "var(--color-accent-aubergine-300)",
        500: "var(--color-accent-aubergine-500)",
        700: "var(--color-accent-aubergine-700)",
        900: "var(--color-accent-aubergine-900)",
        alpha: {
          100: "var(--color-accent-aubergine-alpha-100)",
          200: "var(--color-accent-aubergine-alpha-200)",
          300: "var(--color-accent-aubergine-alpha-300)",
          400: "var(--color-accent-aubergine-alpha-400)",
        },
      },
      rust: {
        100: "var(--color-accent-rust-100)",
        300: "var(--color-accent-rust-300)",
        500: "var(--color-accent-rust-500)",
        700: "var(--color-accent-rust-700)",
        900: "var(--color-accent-rust-900)",
        alpha: {
          100: "var(--color-accent-rust-alpha-100)",
          200: "var(--color-accent-rust-alpha-200)",
          300: "var(--color-accent-rust-alpha-300)",
          400: "var(--color-accent-rust-alpha-400)",
        },
      },
    },
    support: {
      danger: {
        50: "var(--color-support-danger-50)",
        100: "var(--color-support-danger-100)",
        200: "var(--color-support-danger-200)",
        300: "var(--color-support-danger-300)",
        400: "var(--color-support-danger-400)",
        500: "var(--color-support-danger-500)",
        600: "var(--color-support-danger-600)",
        700: "var(--color-support-danger-700)",
        800: "var(--color-support-danger-800)",
        900: "var(--color-support-danger-900)",
        alpha: {
          50: "var(--color-support-danger-alpha-50)",
          100: "var(--color-support-danger-alpha-100)",
          200: "var(--color-support-danger-alpha-200)",
          300: "var(--color-support-danger-alpha-300)",
          400: "var(--color-support-danger-alpha-400)",
        },
      },
      warning: {
        50: "var(--color-support-warning-50)",
        100: "var(--color-support-warning-100)",
        200: "var(--color-support-warning-200)",
        300: "var(--color-support-warning-300)",
        400: "var(--color-support-warning-400)",
        500: "var(--color-support-warning-500)",
        600: "var(--color-support-warning-600)",
        700: "var(--color-support-warning-700)",
        800: "var(--color-support-warning-800)",
        900: "var(--color-support-warning-900)",
        alpha: {
          50: "var(--color-support-warning-alpha-50)",
          100: "var(--color-support-warning-alpha-100)",
          200: "var(--color-support-warning-alpha-200)",
          300: "var(--color-support-warning-alpha-300)",
          400: "var(--color-support-warning-alpha-400)",
        },
      },
      success: {
        50: "var(--color-support-success-50)",
        100: "var(--color-support-success-100)",
        200: "var(--color-support-success-200)",
        300: "var(--color-support-success-300)",
        400: "var(--color-support-success-400)",
        500: "var(--color-support-success-500)",
        600: "var(--color-support-success-600)",
        700: "var(--color-support-success-700)",
        800: "var(--color-support-success-800)",
        900: "var(--color-support-success-900)",
        alpha: {
          50: "var(--color-support-success-alpha-50)",
          100: "var(--color-support-success-alpha-100)",
          200: "var(--color-support-success-alpha-200)",
          300: "var(--color-support-success-alpha-300)",
          400: "var(--color-support-success-alpha-400)",
        },
      },
    },
    bg: {
      default: "var(--color-bg-default)",
      subtle: "var(--color-bg-subtle)",
      hover: "var(--color-bg-hover)",
      "hover-primary": "var(--color-bg-hover-primary)",
      disabled: "var(--color-bg-disabled)",
      "disabled-overlay": "var(--color-bg-disabled-overlay)",
      accent: {
        1: "var(--color-bg-accent-1)",
        2: "var(--color-bg-accent-2)",
        3: "var(--color-bg-accent-3)",
      },
      elevated: {
        default: "var(--color-bg-elevated-default)",
        hover: "var(--color-bg-elevated-hover)",
      },
      emphasis: {
        neutral: "var(--color-bg-emphasis-neutral)",
        "neutral-subtle": "var(--color-bg-emphasis-neutral-subtle)",
        primary: "var(--color-bg-emphasis-primary)",
        "primary-subtle": "var(--color-bg-emphasis-primary-subtle)",
      },
      danger: "var(--color-bg-danger)",
      success: "var(--color-bg-success)",
      warning: "var(--color-bg-warning)",
    },
    fg: {
      default: "var(--color-fg-default)",
      muted: "var(--color-fg-muted)",
      subtle: "var(--color-fg-subtle)",
      primary: "var(--color-fg-primary)",
      accent: {
        1: "var(--color-fg-accent-1)",
        2: "var(--color-fg-accent-2)",
        3: "var(--color-fg-accent-3)",
      },
      onemphasis: {
        default: "var(--color-fg-onemphasis-default)",
        subtle: "var(--color-fg-onemphasis-subtle)",
      },
      danger: "var(--color-fg-danger)",
      success: "var(--color-fg-success)",
      warning: "var(--color-fg-warning)",
    },
    border: {
      default: "var(--color-border-default)",
      muted: "var(--color-border-muted)",
      primary: "var(--color-border-primary)",
      danger: "var(--color-border-danger)",
      success: "var(--color-border-success)",
      warning: "var(--color-border-warning)",
      focus: {
        primary: "var(--color-border-focus-primary)",
        error: "var(--color-border-focus-error)",
      },
    },
    button: {
      primary: {
        bg: {
          default: "var(--color-button-primary-bg-default)",
          hover: "var(--color-button-primary-bg-hover)",
          disabled: "var(--color-button-primary-bg-disabled)",
        },
        fg: {
          default: "var(--color-button-primary-fg-default)",
          disabled: "var(--color-button-primary-fg-disabled)",
        },
        danger: {
          bg: {
            default: "var(--color-button-primary-danger-bg-default)",
            hover: "var(--color-button-primary-danger-bg-hover)",
            disabled: "var(--color-button-primary-danger-bg-disabled)",
          },
          fg: {
            default: "var(--color-button-primary-danger-fg-default)",
            disabled: "var(--color-button-primary-danger-fg-disabled)",
          },
        },
      },
      secondary: {
        bg: "var(--color-button-secondary-bg)",
        fg: "var(--color-button-secondary-fg)",
        border: "var(--color-button-secondary-border)",
        danger: {
          bg: {
            default: "var(--color-button-secondary-danger-bg-default)",
            hover: "var(--color-button-secondary-danger-bg-hover)",
          },
          fg: {
            default: "var(--color-button-secondary-danger-fg-default)",
            disabled: "var(--color-button-secondary-danger-fg-disabled)",
          },
        },
      },
    },
    input: {
      bg: "var(--color-input-bg)",
      fg: {
        input: "var(--color-input-fg-input)",
        placeholder: "var(--color-input-fg-placeholder)",
      },
      border: {
        default: "var(--color-input-border-default)",
        hover: "var(--color-input-border-hover)",
      },
    },
    notebook: {
      cell: {
        bg: {
          default: "var(--color-notebook-cell-bg-default)",
          query: "var(--color-notebook-cell-bg-query)",
          emphasis: {
            neutral: "var(--color-notebook-cell-bg-emphasis-neutral)",
          },
          text: {
            selected: "var(--color-notebook-cell-bg-text-selected)",
            highlighted: "var(--color-notebook-cell-bg-text-highlighted)",
          },
          cell: {
            selected: "var(--color-notebook-cell-bg-cell-selected)",
          },
        },
      },
    },
    tag: {
      fg: {
        default: "var(--color-tag-fg-default)",
        placeholder: "var(--color-tag-fg-placeholder)",
      },
      bg: "var(--color-tag-bg)",
    },
    tab: {
      bg: {
        default: "var(--color-tab-bg-default)",
        selected: "var(--color-tab-bg-selected)",
      },
      text: {
        selected: "var(--color-tab-text-selected)",
        default: "var(--color-tab-text-default)",
      },
      border: {
        selected: "var(--color-tab-border-selected)",
      },
    },
    nav: {
      bar: {
        bg: {
          selected: "var(--color-nav-bar-bg-selected)",
        },
      },
    },
  },
  radius: {
    none: "var(--radius-none)",
    lowest: "var(--radius-lowest)",
    minimal: "var(--radius-minimal)",
    default: "var(--radius-default)",
    rounded: "var(--radius-rounded)",
    full: "var(--radius-full)",
  },
  height: {
    default: "var(--height-default)",
    small: "var(--height-small)",
    x: {
      small: "var(--height-x-small)",
    },
    xx: {
      small: "var(--height-xx-small)",
    },
  },
  font: {
    headings: {
      h1: "var(--font-headings-h1)",
      h2: "var(--font-headings-h2)",
      h3: "var(--font-headings-h3)",
      h4: "var(--font-headings-h4)",
      h5: "var(--font-headings-h5)",
      h6: "var(--font-headings-h6)",
    },
    body: {
      xl: {
        regular: "var(--font-body-xl-regular)",
        medium: "var(--font-body-xl-medium)",
      },
      l: {
        regular: "var(--font-body-l-regular)",
        medium: "var(--font-body-l-medium)",
      },
      md: {
        regular: "var(--font-body-md-regular)",
        medium: "var(--font-body-md-medium)",
      },
      sm: {
        regular: "var(--font-body-sm-regular)",
        medium: "var(--font-body-sm-medium)",
      },
      xs: {
        regular: "var(--font-body-xs-regular)",
        medium: "var(--font-body-xs-medium)",
      },
    },
    buttons: {
      md: "var(--font-buttons-md)",
      sm: "var(--font-buttons-sm)",
    },
    code: {
      regular: "var(--font-code-regular)",
      medium: "var(--font-code-medium)",
    },
  },
  effect: {
    focus: {
      primary: "var(--focus-primary)",
      error: "var(--focus-error)",
    },
    shadow: {
      xxs: "var(--shadow-xxs)",
      xs: "var(--shadow-xs)",
      s: "var(--shadow-s)",
      m: "var(--shadow-m)",
      l: "var(--shadow-l)",
      xl: "var(--shadow-xl)",
    },
  },
} as const;
