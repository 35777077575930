import Dexie from "dexie";

// biome-ignore lint/suspicious/noEmptyBlockStatements: this is a no-op function
function noop() {}

const db = new Dexie("token-studio");
db.version(4).stores({
  tokens: "type",
});

export type Token = {
  value: string;
  type: "api";
};

const makeTokenMethods = (tokens: Dexie.Table<Token, string>) => ({
  getToken(type: Token["type"]): Promise<Token | undefined> {
    return tokens
      .where("type")
      .equals(type)
      .limit(1)
      .toArray((results) => results.map(({ value, type }) => ({ value, type })))
      .then((tokens) => tokens[0]);
  },
  setToken(type: Token["type"], value: Token["value"]): Promise<void> {
    return tokens
      .where("type")
      .equals(type)
      .delete()
      .then(() => tokens.add({ type, value }))
      .then(noop);
  },

  clearToken(type: Token["type"]): Promise<void> {
    return tokens.where("type").equals(type).delete().then(noop);
  },
});

const tokensTable: Dexie.Table<Token, string> = db.table("tokens");
const tokenMethods = makeTokenMethods(tokensTable);

export const { getToken, setToken, clearToken } = tokenMethods;
