import { Button } from "@fiberplane/ui";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { push } from "redux-first-history";
import { css, styled } from "styled-components";

import { ROUTES } from "../../../constants";
import { useQuery } from "../../../hooks";
import { selectIsAuthenticated } from "../../../selectors";
import { Api } from "../../../services";
import { useAppDispatch } from "../../../store";
import { addNotification } from "../../../thunks";
import { ButtonBar, useShake } from "../../UI";
import FiberplaneIcon from "./icons/FiberplaneLogo.svg";
import SlackIcon from "./icons/Slack.svg";

export function SlackIntegration(): JSX.Element {
  const params = useQuery();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  // We expect the url to contain the following query string parameters ?code=[slack_code]&state=[state_id]
  const code = params.get("code");
  const state = params.get("state");
  const error = params.get("error");

  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { shake, shakeClassName } = useShake();

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(push(ROUTES.SignIn));
    }
  }, [isAuthenticated, dispatch]);

  const handleConfirm = useCallback(async () => {
    setLoading(true);

    try {
      await Api.slackOauth(code ?? "", error ?? undefined, state ?? "");
      dispatch(addNotification({ title: "Successfully connected to Slack!" }));
      dispatch(push(ROUTES.Root));
    } catch (storeError) {
      dispatch(
        addNotification({
          title: "Failed to enable slack integration",
          description:
            storeError instanceof Error ? storeError.message : "Unknown error",
          disableAutoHide: true,
          type: "danger",
        }),
      );
      shake();
    } finally {
      setLoading(false);
    }
  }, [code, state, shake, dispatch, error]);

  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
  if (!code || !state) {
    return (
      <StyledContainer>
        Unable to enable the slack integration. Some parameters are missing.
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <StyledConfirmationFrame>
        <IconContainer>
          <StyledSlackIcon />
          <TextContainer>+</TextContainer>
          <StyledFiberplaneIcon />
        </IconContainer>
        <StyledHeading>
          Would you like to link your Fiberplane account with Slack?
        </StyledHeading>
        <p>
          Doing so will make it possible to show details about notebooks that
          you post in slack
        </p>
        <ButtonBar>
          <Button
            buttonStyle="secondary"
            onClick={() => dispatch(push(ROUTES.Root))}
          >
            No
          </Button>
          <Button
            onClick={() => handleConfirm()}
            disabled={loading}
            className={shakeClassName}
          >
            Yes
          </Button>
        </ButtonBar>
      </StyledConfirmationFrame>
    </StyledContainer>
  );
}

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TextContainer = styled.div`
  font-size: 48px;
  flex: 0 0%;
  font-weight: bold;
`;

const StyledSlackIcon = styled(SlackIcon)`
  width: 45%;
`;

const StyledFiberplaneIcon = styled(FiberplaneIcon)`
  width: 25%;
  padding: 12% 10% 8%;
`;

const StyledContainer = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledConfirmationFrame = styled.div(
  ({ theme }) => css`
    max-width: 390px;
    border: 1px solid ${theme.colorBase400};
    border-radius: ${theme.borderRadius700};
    padding: 6px 24px 24px;
  `,
);

const StyledHeading = styled.h4`
  margin-top: 0;
`;
