import { forwardRef } from "react";
import { css, styled } from "styled-components";
import { AvatarIconContainer } from "../AvatarIconContainer";

type PresenceOverflowIconProps = React.HTMLAttributes<HTMLDivElement> & {
  count: number;
  size?: number;
};

export const PresenceOverflowIcon = forwardRef<
  HTMLDivElement,
  PresenceOverflowIconProps
>(function PresenceOverflowIcon({ count, size = 20, ...restProps }, ref) {
  return (
    <OverflowIcon ref={ref} $size={size} {...restProps}>
      +{count}
    </OverflowIcon>
  );
});

const OverflowIcon = styled(AvatarIconContainer)(
  ({ theme }) => css`
    box-shadow: 0 0 0 1px ${theme.color.bg.default}, 0 0 0 2px currentColor;
    cursor: pointer;
    user-select: none;
    font-size: 0.6rem;
  `,
);
