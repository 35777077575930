import { useEffect, useRef, useState } from "react";
import { css, styled } from "styled-components";

import { stopPropagation } from "@fiberplane/ui";
import { formatTableRowValueId } from "../../../utils";
import { TableRowMenu } from "./TableRowMenu";
import { rowHandleMarker } from "./constants";
import { useFieldIsSelected } from "./hooks";
import { focusField } from "./utils";

type Props = {
  cellId: string;
  onHover: (field: string) => void;
  rowId: string;
};

export function TableCellRowHandle({
  cellId,
  onHover,
  rowId,
}: Props): JSX.Element {
  const field = formatTableRowValueId(rowId, rowHandleMarker);
  const ref = useRef<HTMLDivElement | null>(null);
  const [menuOpened, setMenuOpened] = useState(false);
  const selected = useFieldIsSelected(cellId, field);

  useEffect(() => setMenuOpened(selected), [selected]);

  const onClick = (event: React.MouseEvent) => {
    stopPropagation(event);
    if (selected) {
      setMenuOpened(!menuOpened);
    } else {
      focusField(cellId, field);
    }
  };

  return (
    <>
      <StyledRowHandle
        onClick={onClick}
        onMouseEnter={() => onHover(field)}
        ref={ref}
      />
      {menuOpened && ref.current && (
        <TableRowMenu
          cellId={cellId}
          element={ref.current}
          onClose={() => setMenuOpened(false)}
          rowId={rowId}
        />
      )}
    </>
  );
}

const StyledRowHandle = styled.div`${({ theme }) => css`
  position: absolute;
  left: 0;
  top: 25%;
  width: 5px;
  height: 50%;
  border: 1px solid ${theme.color.border.default};
  border-radius: ${theme.radius.lowest};
  background-color: ${theme.color.border.default};
  cursor: pointer;
  z-index: 2;
}`}`;
