import { useHandler } from "@fiberplane/hooks";
import { Button } from "@fiberplane/ui";
import { useContext } from "react";
import { generatePath } from "react-router";
import { push } from "redux-first-history";

import { ROUTES } from "../../constants";
import { useCreateWorkspaceForm } from "../../hooks";
import { dispatch } from "../../store";
import { addNotification } from "../../thunks";
import {
  ButtonBar,
  Modal,
  ModalContext,
  ModalErrorHint,
  ModalForm,
  ModalLabel,
  ModalLabelText,
  ModalTextInput,
  Nudge,
} from "../UI";

export function CreateWorkspaceModal() {
  const { requestClose } = useContext(ModalContext);

  const onSuccess = useHandler((workspace) => {
    dispatch(
      push(
        generatePath(ROUTES.GettingStarted, {
          workspaceName: workspace.name,
        }),
      ),
    );
    dispatch(addNotification({ title: "Your new workspace has been created" }));
    requestClose();
  });

  const {
    displayNameOptions,
    errors,
    isLoading,
    nameOptions,
    onSubmit,
    register,
  } = useCreateWorkspaceForm(onSuccess);

  return (
    <Modal icon="folder_plus" title="Create a new workspace">
      <ModalForm onSubmit={onSubmit}>
        <ModalLabel>
          <ModalLabelText>Display name</ModalLabelText>
          <ModalTextInput
            autoFocus
            aria-invalid={errors.displayName !== undefined}
            data-testid="workspace-display-name"
            {...register("displayName", displayNameOptions)}
          />
          {errors.displayName && (
            <Nudge
              element={errors.displayName.ref as HTMLElement}
              placement="bottom-start"
            >
              {errors.displayName.message}
            </Nudge>
          )}
        </ModalLabel>
        <ModalLabel>
          <ModalLabelText>Name</ModalLabelText>
          <ModalTextInput
            prefix="studio.fiberplane.com/workspace/"
            aria-invalid={errors.name !== undefined}
            data-testid="workspace-name"
            {...register("name", nameOptions)}
          />
          {errors.name && (
            <Nudge
              element={errors.name.ref as HTMLElement}
              placement="bottom-start"
            >
              {errors.name.message}
            </Nudge>
          )}
        </ModalLabel>
        {errors.root && <ModalErrorHint>{errors.root.message}</ModalErrorHint>}
        <ButtonBar>
          <Button
            type="button"
            disabled={isLoading}
            onClick={requestClose}
            buttonStyle="secondary"
          >
            Cancel
          </Button>
          <Button disabled={isLoading} type="submit">
            Create workspace
          </Button>
        </ButtonBar>
      </ModalForm>
    </Modal>
  );
}
