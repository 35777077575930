#version 300 es

precision highp float;

uniform float time;
uniform vec2 size;
uniform sampler2D rampTexture;

out vec4 fragmentColor;

const highp float NOISE_GRANULARITY = 0.5 / 255.0;

highp float random(highp vec2 coords) {
	return fract(sin(dot(coords.xy, vec2(12.9898, 78.233))) * 43758.5453);
}

void main(void) {
    float t = time * 0.1;
	vec2 uv = gl_FragCoord.xy / (size.xy / 16.0);

	// Generate a nice pattern
	float r1 = sin(length(vec2(cos((uv.y + uv.x) / 98.0), sin((t + uv.y) / 178.0))));
	float r2 = cos(length(vec2(sin((t + uv.x) / 58.0), sin((t + uv.y) / -33.0))));
	float r3 = sin(length(vec2(cos(uv.x / -52.0), sin(uv.y / 33.0))));
	float result = r1 + r2 + r3;

	// Look up color in color ramp
	vec3 color = texture(
		rampTexture,
		vec2(result, 0.5)
	).rgb;

	// Fade the result to white the lower we go down the screen
	color = mix(color, vec3(1.0), 0.8 - (gl_FragCoord.y / size.y));

	// Add a tiny amount of noise to reduce color banding
	color += mix(-NOISE_GRANULARITY, NOISE_GRANULARITY, random(uv));

	// Generate output color
	fragmentColor = vec4(color, 0.7);
}
