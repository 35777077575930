import { useHandler } from "@fiberplane/hooks";
import { useContext, useEffect, useState } from "react";

import { FileDropContext } from "./context";

export type DropZoneState = "initial" | "hover" | "valid" | "invalid";

type Validator = (
  items: Array<Pick<DataTransferItem, "type" | "kind">> | DataTransferItemList,
) => boolean;

type Options = {
  validator: Validator;
  onSelectFile(files: FileList): void;
};

export function useFileDropZone<T extends HTMLElement>({
  validator,
  onSelectFile,
}: Options) {
  const { files, setFiles } = useContext(FileDropContext);
  const [state, setState] = useState<DropZoneState>("initial");

  useEffect(() => {
    if (files.length === 0 && state !== "initial") {
      setState("initial");
      return;
    }

    if (state === "initial") {
      setState(validator(files) ? "hover" : "initial");
    }
  }, [files, state, validator]);

  const onDrop = useHandler((event: React.DragEvent<T>) => {
    const isFile = event.dataTransfer.items.length > 0;
    if (isFile) {
      event.preventDefault();
      if (!validator(event.dataTransfer.items)) {
        return;
      }

      // Prevent the event from bubbling up, given that we intend
      // to use it ourself
      event.stopPropagation();
      // And while we're at it: clear all the items that are related
      // to the drag/
      setFiles([]);

      onSelectFile(event.dataTransfer.files);
      setState("initial");
    }
  });

  const onDragEnter = useHandler((event: React.DragEvent<T>) => {
    const isFile = event.dataTransfer.items.length > 0;
    if (isFile) {
      event.preventDefault();

      const valid = validator(event.dataTransfer.items);

      if (valid) {
        // Prevent the event from bubbling up, given that we intend
        // to use it ourself
        event.stopPropagation();
      }

      setState(valid ? "valid" : "invalid");
    }
  });

  const onDragLeave = useHandler((event: React.DragEvent<T>) => {
    if (
      event.relatedTarget instanceof Node &&
      event.currentTarget.contains(event.relatedTarget)
    ) {
      return;
    }

    setState("hover");
  });

  return { onDragLeave, onDragEnter, onDrop, state };
}
