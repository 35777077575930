import { useThemeSelect } from "@fiberplane/hooks";
import { css, styled } from "styled-components";

import { AttachedPopup } from "@fiberplane/ui";
import type { TooltipState } from "../../../state";
import type { ShowTooltipFn } from "../../../types";

type TooltipProps = {
  showTooltip: ShowTooltipFn;
  tip: TooltipState;
};

export function Tooltip({ showTooltip, tip }: TooltipProps) {
  const { anchor, content, options } = tip;
  const { hideOnHover, placement = "bottom", onEnterDelayMs } = options;

  const onMouseOver = hideOnHover
    ? undefined
    : () => showTooltip(anchor(), content(), options);

  return (
    <AttachedPopup
      element={anchor()}
      placement={placement}
      role="tooltip"
      onEnterDelayMs={onEnterDelayMs}
    >
      <TooltipContent hideOnHover={hideOnHover} onMouseOver={onMouseOver}>
        {content()}
      </TooltipContent>
    </AttachedPopup>
  );
}

type TooltipContentProps = {
  children: React.ReactNode;
  hideOnHover?: boolean;
  onMouseOver?: () => void;
};

/**
 * The real content of a tooltip. Declared separately because we
 * need to use the ArrowContext
 */
function TooltipContent({
  children,
  hideOnHover = true,
  onMouseOver,
}: TooltipContentProps) {
  const { currentTheme } = useThemeSelect();

  return (
    <StyledToolTip
      onMouseOver={onMouseOver}
      style={{ pointerEvents: hideOnHover ? "none" : "visible" }}
    >
      <StyledContent $isDarkTheme={currentTheme === "dark"}>
        {children}
      </StyledContent>
    </StyledToolTip>
  );
}

const StyledToolTip = styled.div`
  flex-grow: 0;
  border-radius: ${({ theme }) => theme.radius.none};
  padding: 0;
  max-width: min(600px, 90vw);
  position: relative;
`;

const StyledContent = styled.div<{ $isDarkTheme: boolean }>(
  ({ theme, $isDarkTheme }) => css`
    display: flex;
    flex-direction: row;
    position: relative;
    gap: 8px;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    border-radius: ${theme.radius.default};
    border: 1px solid ${$isDarkTheme ? theme.color.border.muted : "transparent"};
    padding: 4px 12px;
    box-shadow: ${theme.effect.shadow.s};
    background-color: ${theme.color.bg.emphasis.neutral};
    color: ${theme.color.fg.onemphasis.default};
    font: ${theme.font.body.md.medium};
    min-height: 36px;

    [data-popper-placement="top"] & {
      margin-bottom: 11px;
    }

    [data-popper-placement="right"] & {
      margin-left: 11px;
    }

    [data-popper-placement="bottom"] & {
      margin-top: 11px;
    }

    [data-popper-placement="left"] & {
      margin-right: 11px;
    }
  `,
);
