import { css, styled } from "styled-components";

import { GridContainer } from "../Container";
import { CONTAINER_PADDING, PAGE_MAX_WIDTH } from "./constants";

type Props = React.ComponentProps<typeof Header>;

export const PageHeader = ({ children, ...props }: Props) => {
  return (
    <Container>
      <Header {...props}>{children}</Header>
    </Container>
  );
};

const Container = styled(GridContainer)(
  ({ theme }) => css`
    /* z-index is set so the sticky PageContent component will always be below the header */
    z-index: 2;
    grid-area: header;

    position: sticky;
    top: 0;
    width: 100%;
    background: ${theme.color.bg.default};
    padding: 0 ${CONTAINER_PADDING};
  `,
);

const Header = styled(GridContainer).attrs({ as: "header" })`
  grid:
    "title" auto
    "subtitle" auto
    / 1fr;
  width: 100%;
  max-width: ${PAGE_MAX_WIDTH};
  justify-self: center;
  gap: 12px;
`;
