export function downloadFile(
  filename: string,
  mimeType: string,
  content: string,
) {
  const dataUri = `data:${mimeType},${encodeURIComponent(content)}`;

  const element = document.createElement("a");
  element.setAttribute("href", dataUri);
  element.setAttribute("download", filename);
  element.click();
}
