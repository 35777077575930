import type { ComponentProps } from "react";
/* stylelint-disable scale-unlimited/declaration-strict-value */
import { styled } from "styled-components";

import type { Anchor, Direction } from "./types";
import { anchorToDirection } from "./utils";

const HandleButton = styled.button<{
  direction?: Direction;
  left: number;
  top: number;
}>`
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 20%);
  box-sizing: border-box;
  cursor: ${({ direction = "nesw" }) => `${direction}-resize`};
  left: ${({ left }) => `${left}%`};
  top: ${({ top }) => `${top}%`};
  display: block;
  height: 10px;
  margin-left: -5px;
  margin-top: -5px;
  overflow: hidden;
  position: absolute;
  width: 10px;
`;

export function ResizeHandler({
  anchor,
  scale,
  left,
  top,
  ...props
}: Omit<ComponentProps<typeof HandleButton>, "direction"> & {
  anchor: Anchor;
  scale: number;
}) {
  const direction = anchorToDirection(anchor);
  return (
    <HandleButton
      data-anchor={anchor}
      direction={direction}
      data-x={`${left}`}
      data-y={`${top}`}
      data-scale={scale}
      left={left}
      top={top}
      {...props}
    />
  );
}
