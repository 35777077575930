import { Icon } from "@fiberplane/ui";
import { useSelector } from "react-redux";
import { css, styled } from "styled-components";

import { ROUTES } from "../../constants";
import {
  selectActiveWorkspaceRole,
  selectIsAuthenticated,
} from "../../selectors";
import { Container, Text } from "../UI";

export function ReadOnlyBanner(): JSX.Element {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const role = useSelector(selectActiveWorkspaceRole);

  return (
    <Banner data-testid="read-only-banner">
      <Icon iconType="book" />
      <Text>
        {isAuthenticated ? (
          role === "read" ? (
            "Your account has read-only access in this workspace."
          ) : (
            // TODO: FP-916
            // Support global notebook locks
            "This notebook is read-only."
          )
        ) : (
          <>
            This notebook is public and read-only.{" "}
            <Link href={ROUTES.SignIn}>Sign up or sign in</Link>.
          </>
        )}
      </Text>
    </Banner>
  );
}

const Banner = styled(Container)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    padding: 16px 24px;
    gap: 24px;

    background: ${theme.colorSecondary300};
    color: ${theme.colorSecondary800};
  `,
);

const Link = styled.a`
  color: ${({ theme }) => theme.colorBackground};
`;
