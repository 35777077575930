import isPropValid from "@emotion/is-prop-valid";
import { ThemeProvider as FiberplaneThemeProvider } from "@fiberplane/ui/dist/theme";
import merge from "lodash.merge";
import type React from "react";
import {
  StyleSheetManager,
  ThemeProvider as StyledComponentsThemeProvider,
} from "styled-components";

import { useFeature } from "../hooks";
import { GlobalStyle } from "./GlobalStyle";
import {
  GlobalStyle as LegacyGlobalStyle,
  GlobalStyleVariables as LegacyGlobalStyleVariables,
} from "./legacy";
import { theme } from "./theme";

// biome-ignore lint/style/noNamespaceImport: import all legacy theme values
import * as legacyTheme from "./values";

export { LinkStyle } from "./legacy";

export function ThemeProvider({ children }: React.PropsWithChildren) {
  // We use the theme switcher feature to determine whether to use the new
  // global style or the legacy one.
  const [hasThemeSwitcher] = useFeature("theme-switcher");

  return (
    <FiberplaneThemeProvider>
      <StyleSheetManager
        shouldForwardProp={shouldForwardProp}
        enableVendorPrefixes
      >
        <StyledComponentsThemeProvider
          theme={(inheritedTheme) => merge(inheritedTheme, legacyTheme, theme)}
        >
          <LegacyGlobalStyleVariables />

          {hasThemeSwitcher ? <GlobalStyle /> : <LegacyGlobalStyle />}

          {children}
        </StyledComponentsThemeProvider>
      </StyleSheetManager>
    </FiberplaneThemeProvider>
  );
}

// Implements the default behavior from styled-components v5: instead of having
// to change all our styled props to transient props, we maintain v5 behavior.
function shouldForwardProp(propName: string, target: unknown) {
  if (typeof target === "string") {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }

  // For other elements, forward all props
  return true;
}
