import type { QuerySchema, TimeRange } from "../types";
import { timestampToMs } from "./timestamps";

/**
 * Formats a time range to be included in the query data.
 */
export function formatTimeRange(timeRange: TimeRange): string {
  return `${timeRange.from} ${timeRange.to}`;
}

/**
 * Returns the name of a possible `date_time_range` field in the schema, if
 * there is any.
 */
export function getTimeRangeFieldName(schema: QuerySchema): string | undefined {
  return schema.find((field) => field.type === "date_time_range")?.name;
}

/**
 * Parses a time range from the query data into our `TimeRange` type.
 */
export function parseTimeRange(timeRange: string): TimeRange | undefined {
  const [from, to] = timeRange.split(" ");
  return from && to ? { from, to } : undefined;
}

export const isSameTimeRange = (a: TimeRange, b: TimeRange): boolean =>
  a === b ||
  (a.from === b.from && a.to === b.to) ||
  (timestampToMs(a.from) === timestampToMs(b.from) &&
    timestampToMs(a.to) === timestampToMs(b.to));
