import { Sentry } from "../services";
import type { Thunk } from "../store";

export function captureSentryErrorWithNotebookState(
  notebookId: string,
  message: string,
): Thunk {
  return () =>
    Sentry.captureError(message, Sentry.getSensitiveNotebookInfo(notebookId));
}
