import type { SerializedError } from "@reduxjs/toolkit";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import {
  isFetchBaseQueryHttpError,
  isFetchBaseQueryWrappedError,
  isRtkApiError,
  isSerializedError,
} from "./api";
import type { Error as FiberplaneError, HttpRequestError } from "./types";

export class ApiUnavailableError extends Error {
  public response?: Response;

  constructor(responseOrData?: Response | unknown) {
    if (responseOrData instanceof Response) {
      super(`API unavailable (URL: ${responseOrData.url})`);
      this.response = responseOrData;
    } else if (typeof responseOrData === "string") {
      super(`API unavailable: ${responseOrData}`);
    } else {
      super("API unavailable");
    }
  }
}

export class BadDataError extends Error {
  public response?: Response;
  // The reason this type is unknown is that API error types are stored in the API
  // crate instead of being fiberplane-models for now. This means that the API error
  // types currently aren’t converted to typescript types that Studio can use.
  public responseObject?: unknown;

  constructor(responseOrData?: Response | unknown) {
    if (responseOrData instanceof Response) {
      super(`Bad data (URL: ${responseOrData.url})`);
      this.response = responseOrData;
    } else if (
      responseOrData &&
      typeof responseOrData === "object" &&
      "message" in responseOrData &&
      typeof responseOrData.message === "string"
    ) {
      super(responseOrData.message);
    } else if (typeof responseOrData === "string") {
      super(`Bad data: ${responseOrData}`);
    } else if (responseOrData && typeof responseOrData === "object") {
      super("Bad data: complex error");
      this.responseObject = responseOrData;
    } else {
      super("Bad data");
    }
  }
}

export class ForbiddenError extends Error {
  public response?: Response;

  constructor(responseOrData?: Response | unknown) {
    if (responseOrData instanceof Response) {
      super(`Forbidden (URL: ${responseOrData.url})`);
      this.response = responseOrData;
    } else if (typeof responseOrData === "string") {
      super(`Forbidden: ${responseOrData}`);
    } else {
      super("Forbidden");
    }
  }
}

export class NotFoundError extends Error {
  public response?: Response;

  constructor(responseOrData?: Response | unknown) {
    if (responseOrData instanceof Response) {
      super(`Not found (URL: ${responseOrData.url})`);
      this.response = responseOrData;
    } else if (typeof responseOrData === "string") {
      super(`Not found: ${responseOrData}`);
    } else {
      super("Not found");
    }
  }
}

export class ValidationError extends Error {
  public reason: string;
  public property?: string;
  constructor(reason: string, property?: string) {
    super(
      `Validation failed for ${
        property || "(value not set)"
      } reason: ${reason}`,
    );
    this.reason = reason;
    this.property = property;
  }
}

export class RequiredValidationError extends ValidationError {
  public property: string;
  constructor(property: string) {
    super("Value is required", property);
    this.property = property;
  }
}

export class UnauthenticatedError extends Error {
  public response?: Response;

  constructor(messageOrResponse?: string | Response | unknown) {
    if (messageOrResponse instanceof Response) {
      super(`Unauthenticated (URL: ${messageOrResponse.url})`);

      this.response = messageOrResponse;
    } else if (typeof messageOrResponse === "string") {
      super(messageOrResponse);
    } else {
      super("Unauthenticated");
    }
  }
}

export function normalizeException(exception: unknown): Error {
  if (exception instanceof Response) {
    const response = exception;
    switch (response.status) {
      case 400:
        return new BadDataError(response);
      case 401:
        return new UnauthenticatedError(response);
      case 403:
        return new ForbiddenError(response);
      case 404:
        return new NotFoundError(response);
      case 504:
        return new ApiUnavailableError(response);
      default:
        return new Error(
          `Unexpected response from ${response.url}: ${response.status}`,
        );
    }
  } else if (exception instanceof Error) {
    return exception;
  } else if (isRtkApiError(exception)) {
    const error = normalizeRtkApiError(exception);
    if (error) {
      return error;
    }
  }

  return new Error(`Unexpected error: ${exception}`);
}

function normalizeRtkApiError(
  error: FetchBaseQueryError | SerializedError,
): Error | undefined {
  if (isSerializedError(error)) {
    return new Error(`SerializedError: ${error.message}`);
  } else if (isFetchBaseQueryHttpError(error)) {
    switch (error.status) {
      case 400:
        return new BadDataError(error.data);
      case 401:
        return new UnauthenticatedError(error.data);
      case 403:
        return new ForbiddenError(error.data);
      case 404:
        return new NotFoundError(error.data);
      case 504:
        return new ApiUnavailableError(error.data);
      default:
        return new Error(String(error.data));
    }
  } else if (isFetchBaseQueryWrappedError(error)) {
    return new Error(error.data ? String(error.data) : error.error);
  }
}

/**
 * Helper to get error text from an API response
 *
 * @param exception - Error from making an api request
 * @param fallback - Default error message text, if the error does not provide us a message
 */
export async function getApiErrorMessage(
  exception: unknown,
  fallback: string,
): Promise<string> {
  if (exception instanceof BadDataError) {
    return await getApiBadDataErrorMessage(exception, fallback);
  }

  return fallback;
}

async function getApiBadDataErrorMessage(
  exception: BadDataError,
  fallback: string,
): Promise<string> {
  const payload = exception.response
    ? await exception.response.json()
    : exception.responseObject ?? exception.message;

  // HACK - Keep e2e tests working with new API error handling types
  if (typeof payload === "object" && "NameInUse" in payload) {
    return "This workspace url is already taken";
  }

  // HACK - Keep e2e tests working with new API error handling types
  if (typeof payload === "object" && "OwnTooManyWorkspaces" in payload) {
    return "You cannot create more than 5 workspaces";
  }

  if (
    typeof payload === "object" &&
    "error" in payload &&
    payload.error === "invalid_endpoint_url"
  ) {
    return "Invalid endpoint URL";
  }

  if (typeof payload === "string") {
    const apiMessage =
      payload.substring(0, 13).toLowerCase() === "bad request: "
        ? payload.replace("bad request: ", "").trim()
        : payload;

    // NOTE - if the message from the API is the empty string, we use the fallback
    return apiMessage || fallback;
  }

  return fallback;
}

function getErrorMessage(error: object): string {
  return "message" in error && typeof error.message === "string"
    ? error.message
    : JSON.stringify(error);
}

export function isFiberplaneError(error: unknown): error is FiberplaneError {
  return (
    error !== null &&
    typeof error === "object" &&
    "type" in error &&
    typeof error.type === "string"
  );
}

export function formatFiberplaneError(
  error: unknown,
  { showDetails = false } = {},
): string {
  if (typeof error === "string") {
    return error;
  }

  if (typeof error !== "object" || error == null) {
    return "Unknown error";
  }

  if (isFiberplaneError(error)) {
    switch (error.type) {
      case "config":
        return `A config error occurred.
${getErrorMessage(error)}`;
      case "data":
        return `A data error occurred.
${getErrorMessage(error)}`;
      case "deserialization":
        return `A deserialization error occurred.
${getErrorMessage(error)}`;
      case "http":
        return formatHttpErrorMessage(error.error, { showDetails });
      case "invocation":
        return `Error invoking provider: ${getErrorMessage(error)}`;
      case "not_found":
        return "Provider not found";
      case "other":
        return `${getErrorMessage(error)}`;
      case "proxy_disconnected":
        return "FPD disconnected";
      case "unsupported_request":
        return "Unsupported request";
      case "validation_error":
        return `A validation error occurred.
${
  "errors" in error && Array.isArray(error.errors)
    ? error.errors.map(formatValidationError).join(", ")
    : JSON.stringify(error)
}`;
    }
  }

  return `A unknown error occurred.
${JSON.stringify(error)}`;
}

function formatHttpErrorMessage(
  error: HttpRequestError,
  { showDetails = false } = {},
): string {
  switch (error.type) {
    case "connection_refused":
      return "Connection refused";
    case "no_route":
      return "No Route to Host";
    case "offline":
      return "You are offline";
    case "response_too_big":
      return "Returned response was too big";
    case "server_error":
      return `server returned an error (status code: ${error.statusCode}${
        showDetails ? `, response: ${error.response}` : ""
      }) `;
    case "other": {
      if (error.reason === "TypeError: Failed to fetch") {
        return "Request failed (typically due to a CORS issue or because you are offline)";
      }

      return error.reason;
    }
    case "timeout":
      return "A timeout occurred";
  }
}

function formatValidationError(error: unknown): string {
  if (typeof error === "string") {
    return error;
  }

  if (typeof error !== "object" || error == null) {
    return "Unknown validation error";
  }

  const message = getErrorMessage(error);
  return "fieldName" in error && typeof error.fieldName === "string"
    ? `Invalid ${error.fieldName}: ${message}`
    : message;
}
