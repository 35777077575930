import { shallowEqual } from "react-redux";

export function shallowEqualArraysOfObjects(
  a: ReadonlyArray<Record<string, unknown> | undefined>,
  b: ReadonlyArray<Record<string, unknown> | undefined>,
) {
  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0, len = a.length; i < len; i++) {
    const aCell = a[i];
    const bCell = b[i];

    if (aCell === undefined || bCell === undefined) {
      if (aCell !== bCell) {
        return false;
      }
    } else {
      return shallowEqual(aCell, bCell);
    }
  }

  return true;
}
