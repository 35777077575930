import type { IconType } from "@fiberplane/ui";

import type { NotebookSummary, View, Workspace } from "../types";

type CommandOptions = {
  key: string;
  title: string;
  icon?: IconType;
  description?: string;
  keywords?: Array<string>;
  onActivate: () => void | Promise<void>;
  focusInputAfterActivation?: boolean;
  keepOpenAfterActivation?: boolean;
  keepQueryAfterActivation?: boolean;
  keyboardShortcut?: string;
};

export type GenericCommand = CommandOptions & {
  type: "commands" | "navigation" | "settings";
};

export type ViewCommand = CommandOptions & {
  type: "views";
  view?: View;
};

/*
 * Commands only visible in a CommandMenu variant of a specific view
 */
export type ActiveViewCommand = CommandOptions & {
  type: "activeView";
};

/*
 * Commands only visible in a CommandMenu variant of a specific notebook
 */
export type ActiveNotebookCommand = CommandOptions & {
  type: "activeNotebook";
};

/**
 * Commands related to webhooks
 */
export type WebhookCommand = CommandOptions & {
  type: "webhooks";
};

/**
 * Commands related to workspaces
 */
export type WorkspaceCommand = CommandOptions & {
  type: "workspaces";
  workspace?: Workspace;
};

/**
 * Commands that are related to opening a notebook
 */
export type NotebooksCommand = CommandOptions & {
  type: "notebooks" | "recentNotebooks";
  notebook?: NotebookSummary;
};

/**
 * Commands related to the LabelsEditor suggestions
 */
export type SelectLabelSuggestionCommand = Omit<CommandOptions, "icon"> & {
  type: "labelKeys" | "labelValues";
};

/**
 * Commands that are used to create a notebook from a specific template
 */
export type SelectTemplateCommand = CommandOptions & {
  type: "templates";
};

export type Command =
  | ActiveNotebookCommand
  | ActiveViewCommand
  | GenericCommand
  | NotebooksCommand
  | SelectLabelSuggestionCommand
  | SelectTemplateCommand
  | ViewCommand
  | WebhookCommand
  | WorkspaceCommand;

export type CommandWithKey = Command & { key: string };
