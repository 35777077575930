import { useEffect } from "react";

import type { LabelsEditorState } from "../../state";
import { dispatch } from "../../store";
import { updateLabelsEditorDraft } from "../../thunks";
import type { Label, LabelsEditorType } from "../../types";
import { LabelsSearchInput } from "../UI";
import { useLabelDeletedWarning, useSuggestions } from "./hooks";

type Props = {
  compact?: boolean;
  editor: LabelsEditorState;
  inputRef: React.RefObject<HTMLInputElement>;
  labels: ReadonlyArray<Label>;
  onBlur: (event: React.FocusEvent) => void;
  type: LabelsEditorType;
};

export function LabelItemEditor({
  editor,
  inputRef,
  labels,
  onBlur,
  type,
}: Props) {
  useLabelDeletedWarning(inputRef, editor, labels, type);

  useSuggestions(editor);

  const { draft } = editor;

  useEffect(() => {
    const input = inputRef.current;
    if (draft && input && document.activeElement !== input) {
      // Bring back focus on input field after selecting a suggestion from the
      // context menu:
      input.focus();
    }
  }, [draft, inputRef]);

  return (
    <LabelsSearchInput
      onBlur={onBlur}
      onChange={(event) => {
        if (event.target instanceof HTMLInputElement) {
          dispatch(updateLabelsEditorDraft(event.target.value));
        }
      }}
      ref={inputRef}
      value={draft}
    />
  );
}
