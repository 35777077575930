import type { VirtualElement } from "@popperjs/core";
import { useEffect } from "react";
import { styled } from "styled-components";

import { closeContextMenu, withActiveNotebook } from "../../actions";
import { selectContextMenu } from "../../selectors";
import type { LabelsEditorState } from "../../state";
import { type Thunk, dispatch } from "../../store";
import type { ClientLabelValidationError, LabelsEditorType } from "../../types";
import { isPristine } from "../../utils";
import { Nudge } from "../UI";
import { useValidation } from "./hooks";

type Props = {
  editor: LabelsEditorState;
  element: HTMLElement | VirtualElement | null;
  type: LabelsEditorType;
};

export function ValidationNudge({ editor, element, type }: Props) {
  const errors = useValidation(editor);
  const hasErrors = errors.length > 0;

  useEffect(() => {
    if (type === "notebook" || type === "provider_field") {
      dispatch(closeContextMenuIfHasErrors(hasErrors));
    }
  }, [hasErrors, type]);

  if (!hasErrors || isPristine(editor)) {
    return null;
  }

  return (
    <Nudge element={element} placement="bottom" nudgeStyle="warning">
      <ValidationMessageList>
        {errors.map((error) => (
          <ValidationMessageListItem key={error}>
            {ValidationErrorMessages[error]}
          </ValidationMessageListItem>
        ))}
      </ValidationMessageList>
    </Nudge>
  );
}

const closeContextMenuIfHasErrors =
  (hasErrors: boolean): Thunk =>
  (dispatch, getState) => {
    if (!hasErrors) {
      return;
    }

    if (selectContextMenu(getState())) {
      dispatch(withActiveNotebook(closeContextMenu()));
    }
  };

const ValidationErrorMessages: Record<ClientLabelValidationError, string> = {
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  duplicate_key: "The key already exists",
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  empty_key: "A key is required",
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  empty_name: "A key is required after a prefix",
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  empty_prefix: "The prefix is empty",
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  name_invalid_characters: "The key contains invalid characters",
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  name_too_long: "The key is too long",
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  prefix_invalid_characters: "The prefix contains invalid characters",
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  prefix_too_long: "The prefix is too long",
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  value_invalid_characters: "The value contains invalid characters",
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  value_too_long: "The value is too long",
};

const ValidationMessageList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ValidationMessageListItem = styled.li`
  margin: 8px 0 0;
  line-height: 1em;

  &:first-child {
    margin: 0;
  }
`;
