import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { useListPinnedViewsQuery } from "../../../../../api";
import {
  selectActiveViewName,
  selectActiveWorkspaceIdOrThrow,
  selectCanDeleteView,
  selectCommandMenuVariantType,
  selectHasActiveView,
} from "../../../../../selectors";
import { dispatch } from "../../../../../store";
import {
  pinView,
  showDeleteViewConfirmation,
  unpinView,
} from "../../../../../thunks";
import type { ActiveViewCommand } from "../../../../../types";

export function useActiveViewCommands(): Array<ActiveViewCommand> {
  const { canDeleteView, hasActiveView, variantType, viewName, workspaceId } =
    useSelector(selectActiveViewCommandsState);

  const { isPinned } = useListPinnedViewsQuery(
    { workspaceId },
    {
      selectFromResult: ({ data, ...rest }) => ({
        isPinned: data?.some(({ name }) => name === viewName) ?? false,
        ...rest,
      }),
      // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
      skip: !workspaceId || !hasActiveView,
    },
  );

  return useMemo((): Array<ActiveViewCommand> => {
    if (variantType === "none" && hasActiveView && viewName) {
      const commands: Array<ActiveViewCommand> = [
        {
          key: "views/pin",
          type: "activeView",
          title: isPinned ? "Unpin view" : "Pin view",
          icon: isPinned ? "push_pin_slash" : "push_pin",
          onActivate: () =>
            dispatch((isPinned ? unpinView : pinView)(viewName)),
        },
      ];

      if (canDeleteView) {
        commands.push({
          key: "views/delete",
          type: "activeView",
          title: "Delete view",
          icon: "trash",
          onActivate: () => dispatch(showDeleteViewConfirmation(viewName)),
        });
      }

      return commands;
    }

    return [];
  }, [canDeleteView, hasActiveView, isPinned, variantType, viewName]);
}

const selectActiveViewCommandsState = createSelector(
  [
    selectActiveViewName,
    selectActiveWorkspaceIdOrThrow,
    selectCanDeleteView,
    selectCommandMenuVariantType,
    selectHasActiveView,
  ],
  (viewName, workspaceId, canDeleteView, variantType, hasActiveView) => ({
    canDeleteView,
    hasActiveView,
    variantType,
    viewName,
    workspaceId,
  }),
);
