import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import {
  makeCellSelector,
  makeThreadItemsSelector,
  selectCellIds,
  selectUniqueNotebookSubscriberSessions,
} from "../../../../selectors";
import type { CellFocus, User } from "../../../../types";
import {
  emptyDrafts,
  getCellFocus,
  isCellInFocus,
  noNotebookFocus,
  shallowEqualArrays,
} from "../../../../utils";

const noSubscribers: Array<CellSubscriber> = [];

export type CellSubscriber = {
  focus: CellFocus;
  user: User;
};

export function useCellSubscribers(cellId: string): Array<CellSubscriber> {
  const selectCellSubscribers = useMemo(
    () => makeCellSubscribersSelector(cellId),
    [cellId],
  );
  return useSelector(selectCellSubscribers, shallowEqualArrays);
}

const makeCellSubscribersSelector = (cellId: string) =>
  createSelector(
    [
      makeCellSelector(cellId),
      selectCellIds,
      makeThreadItemsSelector(cellId),
      makeFilteredNotebookSubscriberSessionsSelector(cellId),
    ],
    (cell, cellIds, threadItems, filteredNotebookSubscribers) =>
      cell && filteredNotebookSubscribers.length > 0
        ? filteredNotebookSubscribers.map((subscriber) => ({
            focus: getCellFocus(
              cellIds,
              cell,
              emptyDrafts,
              threadItems,
              subscriber.focus ?? noNotebookFocus,
            ),
            user: subscriber.user,
          }))
        : noSubscribers,
  );

const makeFilteredNotebookSubscriberSessionsSelector = (cellId: string) =>
  createSelector(
    [selectCellIds, selectUniqueNotebookSubscriberSessions],
    (cellIds, notebookSubscribers) =>
      notebookSubscribers.filter(
        (subscriber) =>
          subscriber.focus && isCellInFocus(cellIds, cellId, subscriber.focus),
      ),
    { memoizeOptions: { resultEqualityCheck: shallowEqualArrays } },
  );
