import { useHandler } from "@fiberplane/hooks";
import { Icon } from "@fiberplane/ui";
import { useCallback } from "react";
import styled from "styled-components";
import type { FrontMatterStringValue } from "../../../../../types";
import { isString } from "../../../../../utils";
import type { FilterMenuItem } from "../../../../UI";
import { useFocused, useFrontMatterValue } from "../../hooks";
import {
  FieldContentContainer,
  OptionSelectorSingle,
  renderTitle,
} from "../shared";

type Props = Readonly<{
  id: string;
  onChange: (
    value: string | undefined,
    options?: { newValue?: FrontMatterStringValue },
  ) => void;
  options: Array<FrontMatterStringValue>;
  readOnly?: boolean;
  allowExtraValues?: boolean;
}>;

export function FieldStringList({
  id,
  options,
  onChange,
  readOnly,
  allowExtraValues,
}: Props) {
  const value = useFrontMatterValue(id, isString);
  const focused = useFocused(id);

  const onAddAndActivate = useHandler((value: string) => {
    onChange(value, { newValue: value });
  });

  const createExtraMenuItem = useCallback(
    (options: { filterText: string; exactMatchFound: boolean }):
      | FilterMenuItem
      | undefined => {
      if (options.exactMatchFound || options.filterText.trim() === "") {
        return;
      }

      return {
        type: "item_with_icons",
        id: "add-result",
        title: `"${options.filterText}"`,
        renderTitle: () => (
          <Container>
            <span>Add new option:</span>
            {renderTitle(options.filterText)}
          </Container>
        ),
        onActivate: () => {
          onAddAndActivate(options.filterText);
        },
        iconRight: {
          icon: <Icon iconType="key_return" />,
          showOnlyOnSelection: true,
        },
      };
    },
    [onAddAndActivate],
  );

  return (
    <FieldContentContainer>
      <OptionSelectorSingle
        focused={focused}
        readOnly={readOnly === true}
        currentValue={value}
        options={options}
        onChange={onChange}
        createExtraMenuItem={allowExtraValues ? createExtraMenuItem : undefined}
      />
    </FieldContentContainer>
  );
}

const Container = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-left: -6px;
`;
