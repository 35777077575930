import type { Formatting } from "../../../types";

export const noFormatting: Formatting = [];
export const noHover = [] as const;

/**
 * Marker that indicates the entire column is selected (columns are selected by
 * clicking the heading row).
 */
export const headingRowMarker = "__heading__";

/**
 * Marker that indicates the entire row is selected (rows are selected by
 * clicking the row handle).
 */
export const rowHandleMarker = "__handle__";
