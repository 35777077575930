import { useHandler } from "@fiberplane/hooks";
import { useContext, useRef, useState } from "react";

import { Button } from "@fiberplane/ui";
import { readFile, validateImageFileType } from "../../../utils";
import {
  ButtonBar,
  FileDropContainer,
  ImageCropper,
  type ImageCropperRefApi,
  Modal,
  ModalContext,
  useFileDropZone,
  useShake,
} from "../../UI";
import { ImageSelector } from "./ImageSelector";
import { ResizeConstraint } from "./ResizeConstraint";

type Props = {
  done(blob: Blob): void;
};

export function AvatarUploader(props: Props) {
  const { done } = props;
  const { requestClose } = useContext(ModalContext);
  const [image, setImage] = useState<string>();
  const { shake, shakeClassName } = useShake();
  const [hint, showHint] = useState(false);
  const cropperRef = useRef<ImageCropperRefApi>(null);
  const [cropping, setCropping] = useState(false);

  const onSelectFile = useHandler((files: FileList) => {
    const file = files[0];
    if (!file) {
      return;
    }

    readFile(file).then((result) => setImage(result));
  });

  const { state, onDragEnter, onDragLeave, onDrop } = useFileDropZone({
    validator: validateImageFileType,
    onSelectFile,
  });

  const content =
    !image || state === "hover" || state === "valid" ? (
      <ImageSelector setImage={setImage} hint={hint} state={state} />
    ) : (
      <ImageCropper ref={cropperRef} imageUrl={image} />
    );

  const handleOk = () => {
    if (!image) {
      shake();
      showHint(true);
    }

    if (!cropperRef.current) {
      return;
    }

    setCropping(true);
    cropperRef.current.crop().then((blob) => {
      setCropping(false);
      done(blob);
      requestClose();
    });
  };

  return (
    <FileDropContainer>
      <Modal title="Update profile picture">
        <div
          className={shakeClassName}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <ResizeConstraint>{content}</ResizeConstraint>
        </div>

        <ButtonBar>
          <Button buttonStyle="secondary" onClick={requestClose}>
            Cancel
          </Button>
          <Button onClick={handleOk} disabled={cropping}>
            Ok
          </Button>
        </ButtonBar>
      </Modal>
    </FileDropContainer>
  );
}
