import { AttachedPopup, Icon } from "@fiberplane/ui";
import type { ComponentProps } from "react";
import { css, styled } from "styled-components";

type ErrorHintProps = Pick<ComponentProps<typeof AttachedPopup>, "element"> & {
  children: string;
  id: string;
};

export function ErrorHint({ children, element, id }: ErrorHintProps) {
  return (
    <AttachedPopup
      element={element}
      placement="bottom-start"
      offset={[0, 6]}
      preventMainAxisOverflow={false}
    >
      <StyledErrorHint id={id}>
        <StyledErrorIcon iconType="warning_circle" />
        {children}
      </StyledErrorHint>
    </AttachedPopup>
  );
}

const StyledErrorHint = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 6px;

  ${({ theme }) =>
    css`
      font: ${theme.fontStudioStrongExtraSmallShortHand};
      letter-spacing: ${theme.fontStudioStrongExtraSmallLetterSpacing};
      color: ${theme.colorDanger600};
    `}
`;

const StyledErrorIcon = styled(Icon)`
  width: 13px;
  height: 13px;
`;
