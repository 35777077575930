import { useMemo, useRef } from "react";
import { styled } from "styled-components";

import { useFilePaste, useFocus } from "../../../hooks";
import type { ProviderCell } from "../../../types";
import { parseIntent } from "../../../utils";
import { noOutlineStyling } from "../CellContainer";
import { ErrorContainer } from "./ErrorContainer";
import { ProviderCellInnerContent } from "./ProviderCellInnerContent";

export type ProviderCellContentProps = {
  cell: { type: "provider" } & ProviderCell;
  readOnly: boolean;
};

export function ProviderCellContent(props: ProviderCellContentProps) {
  const { id, intent } = props.cell;

  const onPaste = useFilePaste(id);
  const ref = useRef(null);
  // Fixme: this cell assumes there's no RTE editor and nothing that grabs focus
  useFocus(id, ref);

  const parseIntentResult = useMemo(() => {
    try {
      return parseIntent(intent);
    } catch (error: unknown) {
      return error as Error;
    }
  }, [intent]);

  return (
    <Container onPaste={onPaste} tabIndex={-1} ref={ref}>
      {parseIntentResult instanceof Error ? (
        <ErrorContainer>
          There was an error initializing the provider:
          {` ${parseIntentResult}`}
        </ErrorContainer>
      ) : (
        <ProviderCellInnerContent {...props} intent={parseIntentResult} />
      )}
    </Container>
  );
}

const Container = styled.div`
  ${noOutlineStyling}
`;
