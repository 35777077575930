import { useHandler } from "@fiberplane/hooks";
import { Button } from "@fiberplane/ui";
import {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

import { useFeature } from "../../../hooks";
import { selectActiveWorkspaceId, selectDataSources } from "../../../selectors";
import { dispatch } from "../../../store";
import {
  addDataSource,
  loadDataSources,
  removeDataSource,
  updateDataSource,
} from "../../../thunks";
import type { ConfigureDirectAccessModalProps } from "../../../types";
import { formatProviderType, noop } from "../../../utils";
import {
  ButtonBar,
  Modal,
  ModalContext,
  ModalErrorHint,
  ModalForm,
  ModalLabel,
  ModalLabelText,
  ModalTextInput,
  StyledInput,
} from "../../UI";
import type { ConfigEditor, ConfigEditorHandle } from "./ConfigEditor";
import { ConfigEditorFromSchema } from "./ConfigEditorFromSchema";
import { LegacyProviderConfigEditor } from "./LegacyProviderConfigEditor";
import { PrometheusProviderConfigEditor } from "./PrometheusProviderConfigEditor";
import { SentryProviderConfigEditor } from "./SentryProviderConfigEditor";
import {
  type ValidationStatus,
  ValidationStatusIcon,
} from "./ValidationStatusIcon";

export function ConfigureDirectAccessModal(
  props: ConfigureDirectAccessModalProps,
): JSX.Element {
  const [hasAutometrics] = useFeature("autometrics-provider");
  const [hasHttps] = useFeature("https-provider");
  const [hasSentry] = useFeature("sentry");
  const [hasCloudwatch] = useFeature("cloudwatch");
  const { dataSource, onCreate } = props;
  const { requestClose } = useContext(ModalContext);

  const initialProviderType = dataSource?.providerType ?? props.providerType;
  const [providerType, setProviderType] = useState(initialProviderType);
  const [name, setName] = useState(dataSource?.name ?? "");

  const [submitting, setSubmitting] = useState(false);
  const [validationStatus, setValidationStatus] =
    useState<ValidationStatus>("initial");

  const ConfigEditor = getConfigEditorForProvider(providerType);
  const configEditorRef = useRef<ConfigEditorHandle>(null);

  const workspaceId = useSelector(selectActiveWorkspaceId);
  const dataSources = useSelector(selectDataSources);
  const dataSourceNames = useMemo(
    () => dataSources.map(({ name }) => name),
    [dataSources],
  );
  const validateDataSourceName = useHandler((name: string) => {
    if (dataSourceNames.includes(name)) {
      setNameValidationError(
        "This workspace already contains a data source with this name.",
      );
    } else if (name.includes(" ")) {
      setNameValidationError("The data source name cannot include spaces.");
    } else if (name.toLowerCase() !== name) {
      setNameValidationError(
        "The data source name can only contain lowercase characters.",
      );
    } else if (name.endsWith("-")) {
      setNameValidationError("The data source name cannot end with a dash");
    } else if (name.startsWith("-")) {
      setNameValidationError("The data source name cannot start with a dash");
    } else if (/^[\da-z]([\da-z-]*[\da-z])?$/.test(name) === false) {
      setNameValidationError(
        "The data source name can only contain lowercase characters (a - z) and dashes",
      );
    } else {
      setNameValidationError(null);
    }
  });
  const [nameValidationError, setNameValidationError] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (workspaceId) {
      dispatch(loadDataSources(workspaceId));
    }
  }, [workspaceId]);

  const onDelete = () => {
    const result = window.confirm(
      "Are you sure you want to delete this data source?",
    );

    if (result && dataSource) {
      dispatch(removeDataSource(dataSource));
      requestClose();
    }
  };

  const onSubmit = (config: object) => {
    if (submitting) {
      return;
    }

    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version (which is less readable)
    if (!providerType || !name || nameValidationError) {
      setValidationStatus("invalid");
      return;
    }

    // The config is implicitly valid, or the `ConfigEditor` shouldn't have
    // called `onSubmit(config)`.
    setValidationStatus("valid");

    const dataSourceProperties = {
      name,
      protocolVersion,
      providerType,
      config,
    };

    if (dataSource) {
      dispatch(
        updateDataSource({
          ...dataSource,
          ...dataSourceProperties,
        }),
      );
    } else {
      dispatch(addDataSource(dataSourceProperties)).then(onCreate);
    }

    setSubmitting(true);
    requestClose();
  };

  return (
    <Modal
      data-testid="direct-access-modal"
      title={`Set up direct access to ${
        providerType ? formatProviderType(providerType) : "a data source"
      }`}
      description="Direct access allows you to set up a data source without using FPD. This means your browser needs to be able to access it directly."
    >
      <ModalForm onSubmit={noop}>
        {!initialProviderType && (
          <ModalLabel>
            <ModalLabelText> Select the data source type</ModalLabelText>
            <StyledSelect
              aria-invalid={
                validationStatus === "invalid" && !providerType
                  ? true
                  : undefined
              }
              onChange={(event) => setProviderType(event.target.value)}
            >
              <option value="">Select a data source</option>
              {hasAutometrics && (
                <option value="autometrics">Autometrics</option>
              )}
              <option value="prometheus">Prometheus</option>
              <option value="elasticsearch">Elasticsearch</option>
              {hasHttps && <option value="https">HTTPS</option>}
              <option value="loki">Loki</option>
              {hasSentry && <option value="sentry">Sentry</option>}
              {hasCloudwatch && <option value="cloudwatch">Cloudwatch</option>}
            </StyledSelect>
            {!providerType && (
              <ValidationStatusIcon status={validationStatus} />
            )}
            {validationStatus === "invalid" && !providerType && (
              <ModalErrorHint>This is a required field.</ModalErrorHint>
            )}
          </ModalLabel>
        )}

        {providerType && (
          <ModalLabel>
            <ModalLabelText>Enter a name</ModalLabelText>

            <ModalTextInput
              autoFocus={!dataSource}
              data-testid="name"
              aria-invalid={
                validationStatus === "invalid" && !name ? true : undefined
              }
              trailingIcon={() =>
                name ? (
                  <Fragment />
                ) : (
                  <ValidationStatusIcon
                    status={
                      !name || nameValidationError
                        ? validationStatus
                        : "initial"
                    }
                  />
                )
              }
              onChange={(event) => {
                setName(event.target.value);
                validateDataSourceName(event.target.value);
              }}
              readOnly={!!dataSource}
              value={name}
            />

            {validationStatus === "invalid" && !name && (
              <ModalErrorHint>This is a required field.</ModalErrorHint>
            )}

            {nameValidationError && (
              <ModalErrorHint>{nameValidationError}</ModalErrorHint>
            )}
          </ModalLabel>
        )}
        {providerType && ConfigEditor && (
          <ConfigEditor
            autoFocus={!!dataSource}
            config={dataSource?.config}
            onSubmit={onSubmit}
            onValidationStatusChange={setValidationStatus}
            providerType={providerType}
            ref={configEditorRef}
            validationStatus={validationStatus}
          />
        )}
      </ModalForm>

      <ButtonBar>
        <Button onClick={requestClose} buttonStyle="secondary">
          Cancel
        </Button>
        <Button
          disabled={
            validationStatus === "pending" ||
            !ConfigEditor ||
            !!nameValidationError
          }
          onClick={() => configEditorRef.current?.validateAndSubmit()}
        >
          {dataSource ? "Update" : "Add"}
        </Button>

        {dataSource && (
          <Button onClick={onDelete} data-testid="delete">
            Delete
          </Button>
        )}
      </ButtonBar>
    </Modal>
  );
}

function getConfigEditorForProvider(type: string | undefined): ConfigEditor {
  switch (type) {
    case "autometrics":
    case "prometheus":
      return PrometheusProviderConfigEditor;
    case "elasticsearch":
    case "loki":
      // TODO: We should not need the legacy config editor anymore, because
      //       the providers now support the new protocol, including config
      //       schema. However, URL validation is not supported for the config
      //       schema yet, so we would have a slight regression if we dropped
      //       this now. Instead, we should remove this as part of FP-3030.
      return LegacyProviderConfigEditor;
    case "sentry":
      return SentryProviderConfigEditor;
    default:
      return ConfigEditorFromSchema;
  }
}

// All bundled providers support version 2.
const protocolVersion = 2;

const StyledSelect = styled(StyledInput).attrs({ as: "select" })``;
