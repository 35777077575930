// ============================================= //
// Theme file                                    //
//                                               //
// Generated by style-dictionary                 //
// This file is generated. PLEASE DO NOT MODIFY. //
// ============================================= //

export const colorBackground = "#ffffff";
export const colorForeground = "#000000";
export const colorBase100 = "#f9f9f9";
export const colorBase200 = "#f3f3f3";
export const colorBase300 = "#e7e7e7";
export const colorBase400 = "#c4c4c4";
export const colorBase500 = "#a4a4a4";
export const colorBase600 = "#606266";
export const colorBase700 = "#383b40";
export const colorBase800 = "#1f2023";
export const colorBaseAlpha100 = "rgba(96, 98, 102, 0.2)";
export const colorBaseAlpha200 = "rgba(96, 98, 102, 0.4)";
export const colorBaseAlpha300 = "rgba(96, 98, 102, 0.6)";
export const colorBaseAlpha400 = "rgba(96, 98, 102, 0.8)";
export const colorPrimary50 = "#edf2ff";
export const colorPrimary100 = "#dbe4ff";
export const colorPrimary200 = "#b7caff";
export const colorPrimary300 = "#7094ff";
export const colorPrimary400 = "#4c7aff";
export const colorPrimary500 = "#285fff";
export const colorPrimary600 = "#0039dd";
export const colorPrimary700 = "#002693";
export const colorPrimary800 = "#00134a";
export const colorPrimaryAlpha50 = "rgba(40, 95, 255, 0.1)";
export const colorPrimaryAlpha100 = "rgba(40, 95, 255, 0.2)";
export const colorPrimaryAlpha200 = "rgba(40, 95, 255, 0.4)";
export const colorPrimaryAlpha300 = "rgba(40, 95, 255, 0.6)";
export const colorPrimaryAlpha400 = "rgba(40, 95, 255, 0.8)";
export const colorSecondary100 = "#e5dcfe";
export const colorSecondary200 = "#cbb9fe";
export const colorSecondary300 = "#b097fd";
export const colorSecondary400 = "#9674fd";
export const colorSecondary500 = "#7c51fc";
export const colorSecondary600 = "#6341ca";
export const colorSecondary700 = "#4a3197";
export const colorSecondary800 = "#322065";
export const colorSecondarySecondary100 = "rgba(124, 81, 252, 0.2)";
export const colorSecondarySecondary200 = "rgba(124, 81, 252, 0.4)";
export const colorSecondarySecondary300 = "rgba(124, 81, 252, 0.6)";
export const colorSecondarySecondary400 = "rgba(124, 81, 252, 0.8)";
export const colorSuccess100 = "#cbf8e4";
export const colorSuccess200 = "#97f1c9";
export const colorSuccess300 = "#63eaad";
export const colorSuccess400 = "#2ee492";
export const colorSuccess500 = "#19be74";
export const colorSuccess600 = "#138f57";
export const colorSuccess700 = "#104e32";
export const colorSuccess800 = "#06130d";
export const colorSuccessAlpha100 = "rgba(25, 190, 116, 0.2)";
export const colorSuccessAlpha200 = "rgba(25, 190, 116, 0.4)";
export const colorSuccessAlpha300 = "rgba(25, 190, 116, 0.6)";
export const colorSuccessAlpha400 = "rgba(25, 190, 116, 0.8)";
export const colorDanger100 = "#ffdeda";
export const colorDanger200 = "#ffbdb5";
export const colorDanger300 = "#ff9c91";
export const colorDanger400 = "#ff7b6c";
export const colorDanger500 = "#ff5a47";
export const colorDanger600 = "#f41900";
export const colorDanger700 = "#a31100";
export const colorDanger800 = "#520800";
export const colorDangerAlpha100 = "rgba(255, 90, 71, 0.2)";
export const colorDangerAlpha200 = "rgba(255, 90, 71, 0.4)";
export const colorDangerAlpha300 = "rgba(255, 90, 71, 0.6)";
export const colorDangerAlpha400 = "rgba(255, 90, 71, 0.8)";
export const colorWarning100 = "#fef1d4";
export const colorWarning200 = "#fce3a9";
export const colorWarning300 = "#fbd57f";
export const colorWarning400 = "#f9c754";
export const colorWarning500 = "#f8b929";
export const colorWarning600 = "#d29407";
export const colorWarning700 = "#8c6305";
export const colorWarning800 = "#463102";
export const colorWarningAlpha100 = "rgba(248, 185, 41, 0.2)";
export const colorWarningAlpha200 = "rgba(248, 185, 41, 0.4)";
export const colorWarningAlpha300 = "rgba(248, 185, 41, 0.6)";
export const colorWarningAlpha400 = "rgba(248, 185, 41, 0.8)";
export const colorSupport1100 = "#fbe6f9";
export const colorSupport1200 = "#ecb7e7";
export const colorSupport1300 = "#d356c6";
export const colorSupport1400 = "#c00eae";
export const colorSupport2100 = "#e9eaed";
export const colorSupport2200 = "#bdc1c9";
export const colorSupport2300 = "#364b74";
export const colorSupport2400 = "#23304a";
export const colorSupport3100 = "#fff0ec";
export const colorSupport3200 = "#f1c2b8";
export const colorSupport3300 = "#de7159";
export const colorSupport3400 = "#cf3411";
export const colorSupport4100 = "#ffefc8";
export const colorSupport4200 = "#cfc7b5";
export const colorSupport4300 = "#8f7d53";
export const colorSupport4400 = "#5f4509";
export const colorSupport5100 = "#e9eff2";
export const colorSupport5200 = "#bcd0d7";
export const colorSupport5300 = "#6192a0";
export const colorSupport5400 = "#1e6378";
export const colorSupport6100 = "#ecf0e6";
export const colorSupport6200 = "#c7d3b3";
export const colorSupport6300 = "#84b044";
export const colorSupport6400 = "#446e02";
export const colorSupport7100 = "#c1f2dc";
export const colorSupport7200 = "#b8d6c8";
export const colorSupport7300 = "#589e7f";
export const colorSupport7400 = "#117548";
export const colorSupport8100 = "#fbd4e2";
export const colorSupport8200 = "#dfc5ce";
export const colorSupport8300 = "#b4788d";
export const colorSupport8400 = "#943e5c";
export const colorSupport9100 = "#f0d1d9";
export const colorSupport9200 = "#d1bbbf";
export const colorSupport9300 = "#946068";
export const colorSupport9400 = "#661c28";
export const colorSupport10100 = "#f5ecf5";
export const colorSupport10200 = "#d9bed6";
export const colorSupport10300 = "#a667a0";
export const colorSupport10400 = "#802677";
export const colorSupport11100 = "#ede8fe";
export const colorSupport11200 = "#cabafc";
export const colorSupport11300 = "#845ef8";
export const colorSupport11400 = "#4f18f4";
export const colorViews1100 = "#f6b623";
export const colorViews1200 = "#e3ab2a";
export const colorViews2100 = "#e5744b";
export const colorViews2200 = "#cf653f";
export const colorViews3100 = "#db69a6";
export const colorViews3200 = "#c94c8f";
export const colorViews4100 = "#a774da";
export const colorViews4200 = "#925bca";
export const colorViews5100 = "#6666e0";
export const colorViews5200 = "#4e4ecb";
export const colorViews6100 = "#6ca8ee";
export const colorViews6200 = "#4f90dd";
export const colorViews7100 = "#5aae9f";
export const colorViews7200 = "#3b9585";
export const colorViews8100 = "#6fb872";
export const colorViews8200 = "#4f9e52";
export const colorViews9100 = "#a4a4a4";
export const colorViews9200 = "#8d8d8d";
export const fontNotebooksBaseFontSize = "16px";
export const fontNotebooksBaseTextDecoration = "none";
export const fontNotebooksBaseFontFamily = "Inter";
export const fontNotebooksBaseFontWeight = "400";
export const fontNotebooksBaseFontStyle = "normal";
export const fontNotebooksBaseFontStretch = "normal";
export const fontNotebooksBaseFontStyleOld = "Regular";
export const fontNotebooksBaseLetterSpacing = "-0.1%";
export const fontNotebooksBaseLineHeight = "28px";
export const fontNotebooksBaseParagraphIndent = "0";
export const fontNotebooksBaseParagraphSpacing = "0";
export const fontNotebooksBaseTextCase = "none";
export const fontNotebooksBaseShortHand =
  "normal 400 16px / 28px Inter, sans-serif";
export const fontNotebooksH1FontSize = "30px";
export const fontNotebooksH1TextDecoration = "none";
export const fontNotebooksH1FontFamily = "Inter";
export const fontNotebooksH1FontWeight = "700";
export const fontNotebooksH1FontStyle = "normal";
export const fontNotebooksH1FontStretch = "normal";
export const fontNotebooksH1FontStyleOld = "Bold";
export const fontNotebooksH1LetterSpacing = "0";
export const fontNotebooksH1LineHeight = "38px";
export const fontNotebooksH1ParagraphIndent = "0";
export const fontNotebooksH1ParagraphSpacing = "0";
export const fontNotebooksH1TextCase = "none";
export const fontNotebooksH1ShortHand =
  "normal 700 30px / 38px Inter, sans-serif";
export const fontNotebooksH2FontSize = "24px";
export const fontNotebooksH2TextDecoration = "none";
export const fontNotebooksH2FontFamily = "Inter";
export const fontNotebooksH2FontWeight = "500";
export const fontNotebooksH2FontStyle = "normal";
export const fontNotebooksH2FontStretch = "normal";
export const fontNotebooksH2FontStyleOld = "Medium";
export const fontNotebooksH2LetterSpacing = "0";
export const fontNotebooksH2LineHeight = "32px";
export const fontNotebooksH2ParagraphIndent = "0";
export const fontNotebooksH2ParagraphSpacing = "0";
export const fontNotebooksH2TextCase = "none";
export const fontNotebooksH2ShortHand =
  "normal 500 24px / 32px Inter, sans-serif";
export const fontNotebooksH3FontSize = "20px";
export const fontNotebooksH3TextDecoration = "none";
export const fontNotebooksH3FontFamily = "Inter";
export const fontNotebooksH3FontWeight = "500";
export const fontNotebooksH3FontStyle = "normal";
export const fontNotebooksH3FontStretch = "normal";
export const fontNotebooksH3FontStyleOld = "Medium";
export const fontNotebooksH3LetterSpacing = "0.03%";
export const fontNotebooksH3LineHeight = "28px";
export const fontNotebooksH3ParagraphIndent = "0";
export const fontNotebooksH3ParagraphSpacing = "0";
export const fontNotebooksH3TextCase = "none";
export const fontNotebooksH3ShortHand =
  "normal 500 20px / 28px Inter, sans-serif";
export const fontNotebooksSmallFontSize = "14px";
export const fontNotebooksSmallTextDecoration = "none";
export const fontNotebooksSmallFontFamily = "Inter";
export const fontNotebooksSmallFontWeight = "400";
export const fontNotebooksSmallFontStyle = "normal";
export const fontNotebooksSmallFontStretch = "normal";
export const fontNotebooksSmallFontStyleOld = "Regular";
export const fontNotebooksSmallLetterSpacing = "-0.03%";
export const fontNotebooksSmallLineHeight = "24px";
export const fontNotebooksSmallParagraphIndent = "0";
export const fontNotebooksSmallParagraphSpacing = "0";
export const fontNotebooksSmallTextCase = "none";
export const fontNotebooksSmallShortHand =
  "normal 400 14px / 24px Inter, sans-serif";
export const fontNotebooksSmallerFontSize = "12px";
export const fontNotebooksSmallerTextDecoration = "none";
export const fontNotebooksSmallerFontFamily = "Inter";
export const fontNotebooksSmallerFontWeight = "400";
export const fontNotebooksSmallerFontStyle = "normal";
export const fontNotebooksSmallerFontStretch = "normal";
export const fontNotebooksSmallerFontStyleOld = "Regular";
export const fontNotebooksSmallerLetterSpacing = "-0.03%";
export const fontNotebooksSmallerLineHeight = "24px";
export const fontNotebooksSmallerParagraphIndent = "0";
export const fontNotebooksSmallerParagraphSpacing = "0";
export const fontNotebooksSmallerTextCase = "none";
export const fontNotebooksSmallerShortHand =
  "normal 400 12px / 24px Inter, sans-serif";
export const fontNotebooksSmallestFontSize = "10px";
export const fontNotebooksSmallestTextDecoration = "none";
export const fontNotebooksSmallestFontFamily = "Inter";
export const fontNotebooksSmallestFontWeight = "400";
export const fontNotebooksSmallestFontStyle = "normal";
export const fontNotebooksSmallestFontStretch = "normal";
export const fontNotebooksSmallestFontStyleOld = "Regular";
export const fontNotebooksSmallestLetterSpacing = "0";
export const fontNotebooksSmallestLineHeight = "12px";
export const fontNotebooksSmallestParagraphIndent = "0";
export const fontNotebooksSmallestParagraphSpacing = "0";
export const fontNotebooksSmallestTextCase = "none";
export const fontNotebooksSmallestShortHand =
  "normal 400 10px / 12px Inter, sans-serif";
export const fontNotebooksCodeFontSize = "14px";
export const fontNotebooksCodeTextDecoration = "none";
export const fontNotebooksCodeFontFamily = "Inconsolata";
export const fontNotebooksCodeFontWeight = "400";
export const fontNotebooksCodeFontStyle = "normal";
export const fontNotebooksCodeFontStretch = "normal";
export const fontNotebooksCodeFontStyleOld = "Regular";
export const fontNotebooksCodeLetterSpacing = "0";
export const fontNotebooksCodeLineHeight = "20px";
export const fontNotebooksCodeParagraphIndent = "0";
export const fontNotebooksCodeParagraphSpacing = "0";
export const fontNotebooksCodeTextCase = "none";
export const fontNotebooksCodeShortHand =
  "normal 400 14px / 20px Inconsolata, monospace";
export const fontStudioHeadingsH1FontSize = "38px";
export const fontStudioHeadingsH1TextDecoration = "none";
export const fontStudioHeadingsH1FontFamily = "Literata";
export const fontStudioHeadingsH1FontWeight = "400";
export const fontStudioHeadingsH1FontStyle = "normal";
export const fontStudioHeadingsH1FontStretch = "normal";
export const fontStudioHeadingsH1FontStyleOld = "Regular";
export const fontStudioHeadingsH1LetterSpacing = "0";
export const fontStudioHeadingsH1LineHeight = "54px";
export const fontStudioHeadingsH1ParagraphIndent = "0";
export const fontStudioHeadingsH1ParagraphSpacing = "0";
export const fontStudioHeadingsH1TextCase = "none";
export const fontStudioHeadingsH1ShortHand =
  "normal 400 38px / 54px Literata, serif";
export const fontStudioHeadingsH2FontSize = "26px";
export const fontStudioHeadingsH2TextDecoration = "none";
export const fontStudioHeadingsH2FontFamily = "Literata";
export const fontStudioHeadingsH2FontWeight = "400";
export const fontStudioHeadingsH2FontStyle = "normal";
export const fontStudioHeadingsH2FontStretch = "normal";
export const fontStudioHeadingsH2FontStyleOld = "Regular";
export const fontStudioHeadingsH2LetterSpacing = "0";
export const fontStudioHeadingsH2LineHeight = "38px";
export const fontStudioHeadingsH2ParagraphIndent = "0";
export const fontStudioHeadingsH2ParagraphSpacing = "0";
export const fontStudioHeadingsH2TextCase = "none";
export const fontStudioHeadingsH2ShortHand =
  "normal 400 26px / 38px Literata, serif";
export const fontStudioHeadingsH3FontSize = "20px";
export const fontStudioHeadingsH3TextDecoration = "none";
export const fontStudioHeadingsH3FontFamily = "Literata";
export const fontStudioHeadingsH3FontWeight = "500";
export const fontStudioHeadingsH3FontStyle = "normal";
export const fontStudioHeadingsH3FontStretch = "normal";
export const fontStudioHeadingsH3FontStyleOld = "Medium";
export const fontStudioHeadingsH3LetterSpacing = "0";
export const fontStudioHeadingsH3LineHeight = "12px";
export const fontStudioHeadingsH3ParagraphIndent = "0";
export const fontStudioHeadingsH3ParagraphSpacing = "0";
export const fontStudioHeadingsH3TextCase = "none";
export const fontStudioHeadingsH3ShortHand =
  "normal 500 20px / 12px Literata, serif";
export const fontStudioHeadingsH4FontSize = "16px";
export const fontStudioHeadingsH4TextDecoration = "none";
export const fontStudioHeadingsH4FontFamily = "Inter";
export const fontStudioHeadingsH4FontWeight = "500";
export const fontStudioHeadingsH4FontStyle = "normal";
export const fontStudioHeadingsH4FontStretch = "normal";
export const fontStudioHeadingsH4FontStyleOld = "Medium";
export const fontStudioHeadingsH4LetterSpacing = "0";
export const fontStudioHeadingsH4LineHeight = "20px";
export const fontStudioHeadingsH4ParagraphIndent = "0";
export const fontStudioHeadingsH4ParagraphSpacing = "0";
export const fontStudioHeadingsH4TextCase = "none";
export const fontStudioHeadingsH4ShortHand =
  "normal 500 16px / 20px Inter, sans-serif";
export const fontStudioHeadingsH5FontSize = "14px";
export const fontStudioHeadingsH5TextDecoration = "none";
export const fontStudioHeadingsH5FontFamily = "Inter";
export const fontStudioHeadingsH5FontWeight = "500";
export const fontStudioHeadingsH5FontStyle = "normal";
export const fontStudioHeadingsH5FontStretch = "normal";
export const fontStudioHeadingsH5FontStyleOld = "Medium";
export const fontStudioHeadingsH5LetterSpacing = "0";
export const fontStudioHeadingsH5LineHeight = "18px";
export const fontStudioHeadingsH5ParagraphIndent = "0";
export const fontStudioHeadingsH5ParagraphSpacing = "0";
export const fontStudioHeadingsH5TextCase = "none";
export const fontStudioHeadingsH5ShortHand =
  "normal 500 14px / 18px Inter, sans-serif";
export const fontStudioHeadingsH6FontSize = "12px";
export const fontStudioHeadingsH6TextDecoration = "none";
export const fontStudioHeadingsH6FontFamily = "Inter";
export const fontStudioHeadingsH6FontWeight = "400";
export const fontStudioHeadingsH6FontStyle = "normal";
export const fontStudioHeadingsH6FontStretch = "normal";
export const fontStudioHeadingsH6FontStyleOld = "Regular";
export const fontStudioHeadingsH6LetterSpacing = "0";
export const fontStudioHeadingsH6LineHeight = "16px";
export const fontStudioHeadingsH6ParagraphIndent = "0";
export const fontStudioHeadingsH6ParagraphSpacing = "0";
export const fontStudioHeadingsH6TextCase = "none";
export const fontStudioHeadingsH6ShortHand =
  "normal 400 12px / 16px Inter, sans-serif";
export const fontStudioBodyCopyLargeFontSize = "16px";
export const fontStudioBodyCopyLargeTextDecoration = "none";
export const fontStudioBodyCopyLargeFontFamily = "Inter";
export const fontStudioBodyCopyLargeFontWeight = "400";
export const fontStudioBodyCopyLargeFontStyle = "normal";
export const fontStudioBodyCopyLargeFontStretch = "normal";
export const fontStudioBodyCopyLargeFontStyleOld = "Regular";
export const fontStudioBodyCopyLargeLetterSpacing = "0";
export const fontStudioBodyCopyLargeLineHeight = "24px";
export const fontStudioBodyCopyLargeParagraphIndent = "0";
export const fontStudioBodyCopyLargeParagraphSpacing = "0";
export const fontStudioBodyCopyLargeTextCase = "none";
export const fontStudioBodyCopyLargeShortHand =
  "normal 400 16px / 24px Inter, sans-serif";
export const fontStudioBodyCopyRegularFontSize = "14px";
export const fontStudioBodyCopyRegularTextDecoration = "none";
export const fontStudioBodyCopyRegularFontFamily = "Inter";
export const fontStudioBodyCopyRegularFontWeight = "400";
export const fontStudioBodyCopyRegularFontStyle = "normal";
export const fontStudioBodyCopyRegularFontStretch = "normal";
export const fontStudioBodyCopyRegularFontStyleOld = "Regular";
export const fontStudioBodyCopyRegularLetterSpacing = "0";
export const fontStudioBodyCopyRegularLineHeight = "24px";
export const fontStudioBodyCopyRegularParagraphIndent = "0";
export const fontStudioBodyCopyRegularParagraphSpacing = "0";
export const fontStudioBodyCopyRegularTextCase = "none";
export const fontStudioBodyCopyRegularShortHand =
  "normal 400 14px / 24px Inter, sans-serif";
export const fontStudioBodyCopySmallFontSize = "12px";
export const fontStudioBodyCopySmallTextDecoration = "none";
export const fontStudioBodyCopySmallFontFamily = "Inter";
export const fontStudioBodyCopySmallFontWeight = "400";
export const fontStudioBodyCopySmallFontStyle = "normal";
export const fontStudioBodyCopySmallFontStretch = "normal";
export const fontStudioBodyCopySmallFontStyleOld = "Regular";
export const fontStudioBodyCopySmallLetterSpacing = "0";
export const fontStudioBodyCopySmallLineHeight = "20px";
export const fontStudioBodyCopySmallParagraphIndent = "0";
export const fontStudioBodyCopySmallParagraphSpacing = "0";
export const fontStudioBodyCopySmallTextCase = "none";
export const fontStudioBodyCopySmallShortHand =
  "normal 400 12px / 20px Inter, sans-serif";
export const fontStudioBodyCopyExtraSmallFontSize = "10px";
export const fontStudioBodyCopyExtraSmallTextDecoration = "none";
export const fontStudioBodyCopyExtraSmallFontFamily = "Inter";
export const fontStudioBodyCopyExtraSmallFontWeight = "400";
export const fontStudioBodyCopyExtraSmallFontStyle = "normal";
export const fontStudioBodyCopyExtraSmallFontStretch = "normal";
export const fontStudioBodyCopyExtraSmallFontStyleOld = "Regular";
export const fontStudioBodyCopyExtraSmallLetterSpacing = "0";
export const fontStudioBodyCopyExtraSmallLineHeight = "16px";
export const fontStudioBodyCopyExtraSmallParagraphIndent = "0";
export const fontStudioBodyCopyExtraSmallParagraphSpacing = "0";
export const fontStudioBodyCopyExtraSmallTextCase = "none";
export const fontStudioBodyCopyExtraSmallShortHand =
  "normal 400 10px / 16px Inter, sans-serif";
export const fontStudioButtonsButtonCopyRegularFontSize = "14px";
export const fontStudioButtonsButtonCopyRegularTextDecoration = "none";
export const fontStudioButtonsButtonCopyRegularFontFamily = "Inter";
export const fontStudioButtonsButtonCopyRegularFontWeight = "600";
export const fontStudioButtonsButtonCopyRegularFontStyle = "normal";
export const fontStudioButtonsButtonCopyRegularFontStretch = "normal";
export const fontStudioButtonsButtonCopyRegularFontStyleOld = "Semi Bold";
export const fontStudioButtonsButtonCopyRegularLetterSpacing = "0";
export const fontStudioButtonsButtonCopyRegularLineHeight = "16px";
export const fontStudioButtonsButtonCopyRegularParagraphIndent = "0";
export const fontStudioButtonsButtonCopyRegularParagraphSpacing = "0";
export const fontStudioButtonsButtonCopyRegularTextCase = "none";
export const fontStudioButtonsButtonCopyRegularShortHand =
  "normal 600 14px / 16px Inter, sans-serif";
export const fontStudioButtonsButtonCopySmallFontSize = "12px";
export const fontStudioButtonsButtonCopySmallTextDecoration = "none";
export const fontStudioButtonsButtonCopySmallFontFamily = "Inter";
export const fontStudioButtonsButtonCopySmallFontWeight = "600";
export const fontStudioButtonsButtonCopySmallFontStyle = "normal";
export const fontStudioButtonsButtonCopySmallFontStretch = "normal";
export const fontStudioButtonsButtonCopySmallFontStyleOld = "Semi Bold";
export const fontStudioButtonsButtonCopySmallLetterSpacing = "0";
export const fontStudioButtonsButtonCopySmallLineHeight = "12px";
export const fontStudioButtonsButtonCopySmallParagraphIndent = "0";
export const fontStudioButtonsButtonCopySmallParagraphSpacing = "0";
export const fontStudioButtonsButtonCopySmallTextCase = "none";
export const fontStudioButtonsButtonCopySmallShortHand =
  "normal 600 12px / 12px Inter, sans-serif";
export const fontStudioStrongRegularFontSize = "14px";
export const fontStudioStrongRegularTextDecoration = "none";
export const fontStudioStrongRegularFontFamily = "Inter";
export const fontStudioStrongRegularFontWeight = "500";
export const fontStudioStrongRegularFontStyle = "normal";
export const fontStudioStrongRegularFontStretch = "normal";
export const fontStudioStrongRegularFontStyleOld = "Medium";
export const fontStudioStrongRegularLetterSpacing = "0";
export const fontStudioStrongRegularLineHeight = "20px";
export const fontStudioStrongRegularParagraphIndent = "0";
export const fontStudioStrongRegularParagraphSpacing = "0";
export const fontStudioStrongRegularTextCase = "none";
export const fontStudioStrongRegularShortHand =
  "normal 500 14px / 20px Inter, sans-serif";
export const fontStudioStrongSmallFontSize = "12px";
export const fontStudioStrongSmallTextDecoration = "none";
export const fontStudioStrongSmallFontFamily = "Inter";
export const fontStudioStrongSmallFontWeight = "500";
export const fontStudioStrongSmallFontStyle = "normal";
export const fontStudioStrongSmallFontStretch = "normal";
export const fontStudioStrongSmallFontStyleOld = "Medium";
export const fontStudioStrongSmallLetterSpacing = "0";
export const fontStudioStrongSmallLineHeight = "16px";
export const fontStudioStrongSmallParagraphIndent = "0";
export const fontStudioStrongSmallParagraphSpacing = "0";
export const fontStudioStrongSmallTextCase = "none";
export const fontStudioStrongSmallShortHand =
  "normal 500 12px / 16px Inter, sans-serif";
export const fontStudioStrongExtraSmallFontSize = "10px";
export const fontStudioStrongExtraSmallTextDecoration = "none";
export const fontStudioStrongExtraSmallFontFamily = "Inter";
export const fontStudioStrongExtraSmallFontWeight = "500";
export const fontStudioStrongExtraSmallFontStyle = "normal";
export const fontStudioStrongExtraSmallFontStretch = "normal";
export const fontStudioStrongExtraSmallFontStyleOld = "Medium";
export const fontStudioStrongExtraSmallLetterSpacing = "0";
export const fontStudioStrongExtraSmallLineHeight = "12px";
export const fontStudioStrongExtraSmallParagraphIndent = "0";
export const fontStudioStrongExtraSmallParagraphSpacing = "0";
export const fontStudioStrongExtraSmallTextCase = "none";
export const fontStudioStrongExtraSmallShortHand =
  "normal 500 10px / 12px Inter, sans-serif";
export const effectFocusOutline = "rgb(183, 201, 255) solid 2px";
export const effectFocus = "0px 0px 0px 2px rgb(183, 201, 255)";
export const effectShadowHoverFilter =
  "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05))";
export const effectShadowHover = "0px 4px 4px 0px rgba(0, 0, 0, 0.05)";
export const effectShadowMenu0 = "0px 8px 4px 0px rgba(0, 0, 0, 0.1)";
export const effectShadowMenu1 = "0px 0px 2px 0px rgba(0, 0, 0, 0.05)";
export const effectShadowMenu0Filter =
  "drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.1))";
export const effectShadowMenu1Filter =
  "drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.05))";
export const effectShadowMainFilter =
  "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2))";
export const effectShadowMain = "0px 4px 4px 0px rgba(0, 0, 0, 0.2)";
export const effectNone = "0";
export const borderRadius300 = "2px";
export const borderRadius400 = "3px";
export const borderRadius500 = "6px";
export const borderRadius600 = "8px";
export const borderRadius700 = "12px";
export const borderRadiusNone = "0";
export const borderRadiusRound = "9999px";
export const spacingHeadingH1 = "34px 0 20px";
export const spacingHeadingH2 = "24px 0 20px";
export const spacingHeadingH3 = "22px 0 16px";
export const spacingListItem = "0 0 2px";
export const spacingParagraph = "16px 0";
