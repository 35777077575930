import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { createSelector } from "reselect";

import { CellById } from "../../../CellById";
import { withActiveNotebook } from "../../../actions";
import { selectActiveEditor, selectCellIds } from "../../../selectors";
import { dispatch } from "../../../store";
import { getStartPosition } from "../../../utils";
import { FOCUS_HIGHLIGHT_DURATION } from "../../highlight";

/**
 * Scrolls to the cell that has received a highlight, and discards the highlight
 * after a timeout.
 */
export function useFocusHighlight() {
  const { focusedCellId, hasHighlight } = useSelector(selector);

  useEffect(() => {
    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version (which is less readable)
    if (!focusedCellId || !hasHighlight) {
      return;
    }

    CellById.get(focusedCellId)?.scrollIntoView();

    const timeout = setTimeout(() => {
      dispatch(withActiveNotebook({ type: "remove_focus_highlight" }));
    }, FOCUS_HIGHLIGHT_DURATION);

    return () => clearTimeout(timeout);
  }, [focusedCellId, hasHighlight]);
}

const selector = createSelector(
  [selectCellIds, selectActiveEditor],
  (cellIds, editor) => ({
    focusedCellId: getStartPosition(cellIds, editor.focus)?.cellId,
    hasHighlight: editor.highlightFocus,
  }),
  { memoizeOptions: { resultEqualityCheck: shallowEqual } },
);
