import { useRef } from "react";
import { styled } from "styled-components";

import { useFilePaste, useFocus } from "../../hooks";
import type { DividerCell } from "../../types";
import { HorizontalRule } from "../UI";
import { noOutlineStyling } from "./CellContainer";

type Props = {
  cell: { type: "divider" } & DividerCell;
  readOnly?: boolean;
};

export function DividerCellContent({ cell }: Props) {
  const ref = useRef(null);
  const onPaste = useFilePaste(cell.id);

  useFocus(cell.id, ref);

  return (
    <DividerCellHrWrapper ref={ref} onPaste={onPaste} tabIndex={-1}>
      <DividerHorizontalRule />
    </DividerCellHrWrapper>
  );
}

const DividerCellHrWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  ${noOutlineStyling}
`;

const DividerHorizontalRule = styled(HorizontalRule)`
  width: 100%;
  margin: 0;
`;
