/**
 * This function returns the current pathname of the window, enhanced with query params and hash.
 */
export function getCurrentPathnameUriComponent() {
  const { location } = document;
  return encodeURIComponent(
    location.pathname + location.search + location.hash,
    // We don't need the leading slash
  ).substring(1);
}
