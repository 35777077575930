import { Button } from "@fiberplane/ui";
import { matchPath } from "react-router";
import { dispatch } from "../../../store";
import { hideSettingsModal } from "../../../thunks";
import type { RoutedModalType } from "../../../types";
import {
  MainSettingsRoute,
  PersonalIntegrationSettingsRoute,
  WorkspaceIntegrationSettingsRoute,
} from "./routes";

function getPathname(uri: string) {
  try {
    const url = new URL(uri, window.location.origin);
    return url.pathname;
  } catch {
    return "";
  }
}

const routes = [
  MainSettingsRoute,
  PersonalIntegrationSettingsRoute,
  WorkspaceIntegrationSettingsRoute,
] as const;

export function RoutedModal({ uri }: RoutedModalType) {
  const pathname = getPathname(uri);
  for (const route of routes) {
    const match = matchPath(route.path, pathname);
    if (match) {
      return <route.component {...match.params} />;
    }
  }

  return (
    <div>
      Not found
      <Button onClick={() => dispatch(hideSettingsModal())}>Close</Button>
    </div>
  );
}
