import { originalSourcePlugin } from "@analytics/original-source-plugin";
import segmentPlugin from "@analytics/segment";
import { Analytics, type AnalyticsInstance } from "analytics";

import { getFeatures, noop } from ".";
import {
  ANALYTICS_ENVIRONMENTS,
  COOKIE_CONSENT_NAME,
  COOKIE_CONSENT_VALUE,
  VERSION,
  getEnvironment,
} from "../constants";
import { getCookieValue, subscribeToCookieValue } from "./cookies";

function getWriteKey(): string {
  const environment = getEnvironment();

  return ANALYTICS_ENVIRONMENTS[environment] || ANALYTICS_ENVIRONMENTS.local;
}

let _analytics: ReturnType<typeof Analytics> | undefined;

function getInstance(): ReturnType<typeof Analytics> {
  if (!_analytics) {
    _analytics = Analytics({
      app: "Studio",
      version: VERSION,
      debug: process.env.NODE_ENV === "development",
      plugins: [
        originalSourcePlugin(),
        segmentPlugin({
          writeKey: getWriteKey(),
          enabled: getAnalyticsEnabled() === true,
        }),
      ],
    });
  }

  return _analytics;
}

function getDefaultPayload() {
  return {
    version: VERSION,
    features: getFeatures(),
  };
}

function getAnalyticsEnabled(): boolean | undefined {
  const value = getCookieValue(COOKIE_CONSENT_NAME);
  if (typeof value !== "string" || value === "") {
    return undefined;
  }

  return isEnabled(value);
}

function isEnabled(value: string | undefined) {
  return value === COOKIE_CONSENT_VALUE;
}

function monitorConsent(cookieValue: string | undefined): void {
  if (isEnabled(cookieValue)) {
    getInstance().plugins.enable("segment");
  } else {
    getInstance().plugins.disable("segment", noop);
  }
}

subscribeToCookieValue(COOKIE_CONSENT_NAME, monitorConsent);

export const track: AnalyticsInstance["track"] = async (
  eventName,
  payload,
  options,
  callback,
) => {
  await getInstance().track(
    eventName,
    {
      ...getDefaultPayload(),
      ...payload,
    },
    options,
    callback,
  );
};

export const page: AnalyticsInstance["page"] = async (
  data,
  options,
  callback,
) => {
  await getInstance().page(
    {
      ...getDefaultPayload(),
      ...data,
    },
    options,
    callback,
  );
};

export const identify: AnalyticsInstance["identify"] = async (
  userId: string,
  traits,
  options,
  callback,
) => {
  await getInstance().identify(userId, traits, options, callback);
};
