import { Icon, cancelEvent } from "@fiberplane/ui";
import { nanoid } from "@reduxjs/toolkit";
import { AnimatePresence, motion, useReducedMotion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css"; // Import the styles for the resizable component
import useUnmount from "react-use/lib/useUnmount";
import { styled } from "styled-components";
import { MiniButton } from ".";
import { hideSideMenu } from "../../../actions";
import { CONSOLE_COLLAPSED_HEIGHT } from "../../../constants";
import {
  selectActiveWorkspaceNameOrThrow,
  selectConsole,
  selectNotebookTimeRange,
} from "../../../selectors";
import { useAppDispatch } from "../../../store";
import { createNotebookLink } from "../../../utils";
import { BetaBadge } from "../../UI";
import { ChatMessage } from "./ChatMessage";
import {
  fakeCopilotService,
  parseFakeActionChunk,
  parseFakeNotebookChunk,
  parseFakeUserChunk,
} from "./fakeService";
import { processStreamingResponse, promqlWriter } from "./service";
import type { CopilotAction, Message } from "./types";
import { useActionSideEffectHandler } from "./useActionSideEffectHandler";
import {
  useCopilotChat,
  useCopilotChatFrontmatterResolvedEffect,
  useInitializeCopilot,
} from "./useCopilotChat";
import {
  RESIZABLE_COPILOT_DIMENSIONS,
  useCopilotContainerWidth,
} from "./useCopilotContainerWidth";
import { NotebookReference, UserReference } from "./zhuzh";

export const CopilotSidebar = ({
  isSideMenuOpen,
}: { isSideMenuOpen: boolean }) => {
  const dispatch = useAppDispatch();

  const notebookTimeRange = useSelector(selectNotebookTimeRange);
  const workspaceName = useSelector(selectActiveWorkspaceNameOrThrow);

  const { handleResize, width } = useCopilotContainerWidth(
    RESIZABLE_COPILOT_DIMENSIONS,
  );

  const shouldReduceMotion = useReducedMotion();
  const { expanded, height } = useSelector(selectConsole);

  const {
    ids,
    messages,
    prompt,
    isLocked,
    setPrompt,
    setIsLocked,
    addMessage,
    updateMessage,
  } = useCopilotChat();

  // Add or update the initial message in the sidebar
  // This will modify the first message depending on the frontmatter: status property
  useInitializeCopilot({
    ids,
    addMessage,
    updateMessage,
    isSideMenuOpen,
  });

  // HACK - This allows us to add a follow-on message when the frontmatter status updates to "Resolved"
  useCopilotChatFrontmatterResolvedEffect({
    messages,
    addMessage,
    isLocked,
    ids,
  });

  // Hide menu on unmount
  useUnmount(() => dispatch(hideSideMenu()));

  const handleActionSideEffect = useActionSideEffectHandler();

  const handleActionSubmit = useCallback(
    async (action: CopilotAction) => {
      if (isLocked) {
        return;
      }

      // E.g., assigning a user as a commander
      handleActionSideEffect(action);

      // Add the action as a message from the user
      addMessage({
        id: nanoid(),
        isFromUser: true,
        isLoading: false,
        type: "text",
        content: action.label,
      });

      // Add a temporary message to indicate that the response is being processed.
      // We will update this message with the actual response down the line
      const responseMessageId = nanoid();
      const actionType = action.type === "SUMMARIZE" ? "summary" : "text";
      // NOTE - this timeout can cause initial chunks to be dropped in extremely low latency situations
      setTimeout(() => {
        addMessage({
          id: responseMessageId,
          isFromUser: false,
          isLoading: true,
          type: actionType,
          content: "",
        });
      }, 10);

      try {
        // Use mocked Fetch API to send a mocked POST request for response streaming. See https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
        const response = await fakeCopilotService(
          {
            prompt: action.label,
            action,
          },
          notebookTimeRange,
        );
        processStreamingResponse(response, {
          onDone: () => {
            updateMessage(responseMessageId, (message) => ({
              ...message,
              isLoading: false,
              type: actionType,
            }));
            setIsLocked(false);
          },
          // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: demo code, will remove or refactor later
          onChunk: (chunk: string) => {
            // HACK - Allow responses to specify actions and render rich text responses
            const query = chunk?.startsWith("%QUERY")
              ? chunk.split("|")[1]?.trim() ?? ""
              : undefined;
            const action = chunk?.startsWith("%ACTION")
              ? parseFakeActionChunk(chunk)
              : undefined;
            const notebook = chunk?.startsWith("%NOTEBOOK")
              ? parseFakeNotebookChunk(chunk)
              : undefined;
            const user = chunk?.startsWith("%USER")
              ? parseFakeUserChunk(chunk)
              : undefined;
            if (action) {
              updateMessage(responseMessageId, (message) => ({
                ...message,
                actions: action
                  ? [...(message.actions ?? []), action]
                  : message.actions,
              }));
            } else if (user) {
              updateMessage(responseMessageId, (message) => ({
                ...message,
                content: [
                  ...(typeof message.content === "string"
                    ? [message.content]
                    : message.content),
                  <UserReference key={message.id} user={user} />,
                ],
              }));
            } else if (notebook) {
              const link = createNotebookLink(
                workspaceName,
                notebook.id,
                notebook.title,
              );

              updateMessage(responseMessageId, (message) => ({
                ...message,
                content: [
                  ...(typeof message.content === "string"
                    ? [message.content]
                    : message.content),
                  <NotebookReference
                    to={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={message.id}
                  >
                    <StyledNotebookIcon iconType="notebook_duotone" />
                    {notebook.title}
                  </NotebookReference>,
                ],
              }));
            } else if (query) {
              addMessage({
                id: nanoid(),
                content: query,
                isLoading: false,
                type: "query",
                isFromUser: false,
              });
            } else {
              updateMessage(responseMessageId, (message) => ({
                ...message,
                content:
                  typeof message.content === "string"
                    ? message.content + chunk
                    : [...message.content, chunk],
              }));
            }
          },
        });
      } catch (error) {
        console.error(error);
        updateMessage(responseMessageId, (message) => ({
          ...message,
          isLoading: false,
          content: "An error occurred while processing your request.",
        }));
        setIsLocked(false);
      }
    },
    [
      isLocked,
      setIsLocked,
      addMessage,
      updateMessage,
      handleActionSideEffect,
      workspaceName,
      notebookTimeRange,
    ],
  );

  const handlePromptSubmit = useCallback(
    async (prompt: string) => {
      if (isLocked || !prompt.trim()) {
        return;
      }

      setIsLocked(true);
      setPrompt("");
      addMessage({
        id: nanoid(),
        isFromUser: true,
        isLoading: false,
        type: "text",
        content: prompt,
      });

      // Add a temporary message to indicate that the response is being processed.
      // We will update this message with the actual response down the line
      const responseMessageId = nanoid();
      setTimeout(() => {
        addMessage({
          id: responseMessageId,
          isFromUser: false,
          isLoading: true,
          type: "text",
          content: "",
        });
      }, 100);

      try {
        // Uses Fetch API to send a POST request for response streaming. See https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
        const response = await promqlWriter(prompt);
        processStreamingResponse(response, {
          onChunk: (chunk) => {
            updateMessage(responseMessageId, (message) => ({
              ...message,
              content: message.content + chunk,
            }));
          },
          onDone: () => {
            updateMessage(responseMessageId, (message) => ({
              ...message,
              isLoading: false,
              type: "query",
            }));
            setIsLocked(false);
          },
        });
      } catch (error) {
        console.error(error);
        updateMessage(responseMessageId, (message) => ({
          ...message,
          isLoading: false,
          content: "An error occurred while processing your request.",
        }));
        setIsLocked(false);
      } // TODO -- add finally
    },
    [addMessage, isLocked, updateMessage, setIsLocked, setPrompt],
  );

  return (
    <AnimatePresence>
      {isSideMenuOpen && (
        <Resizable
          width={width}
          axis="x" // Restrict resizing to the horizontal axis
          onResize={handleResize}
          resizeHandles={["w"]} // Limit resize handle to just the west (left) handle
          handle={(_, ref) => (
            // Render a custom handle component, so we can indicate "resizability"
            // along the entire left side of the container
            <ResizableHandle ref={ref} />
          )}
        >
          <CopilotSidebarContainer
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ duration: shouldReduceMotion ? 0 : 0.2 }}
            style={{
              bottom: CONSOLE_COLLAPSED_HEIGHT + (expanded ? height : 0),
            }}
            $width={width}
          >
            <CopilotHeader>
              AI Copilot
              <BetaBadge />
            </CopilotHeader>
            <ScrollToBottomChatHistory messages={messages}>
              {ids.map((id, index) => {
                const message = messages[id];
                if (!message) {
                  return null;
                }
                // Only show associated actions with latest message
                const showActions = index === ids.length - 1;
                return (
                  <ChatMessage
                    key={id}
                    message={message}
                    showActions={showActions}
                    handleActionSubmit={handleActionSubmit}
                  />
                );
              })}
            </ScrollToBottomChatHistory>
            <PromptContainer>
              <PromptInput
                placeholder="Ask anything…"
                value={prompt}
                disabled={isLocked}
                onKeyDown={(event) => {
                  if (event.key === "Enter" && !event.shiftKey) {
                    cancelEvent(event);
                    handlePromptSubmit(prompt);
                  }
                }}
                onChange={(event) => setPrompt(event.target.value)}
              />
              <MiniButton
                onClick={() => handlePromptSubmit(prompt)}
                disabled={!prompt || isLocked}
                aria-label="Send message"
                data-shortcut="enter"
              >
                Send ↵
              </MiniButton>
            </PromptContainer>
          </CopilotSidebarContainer>
        </Resizable>
      )}
    </AnimatePresence>
  );
};

const ScrollToBottomChatHistory = ({
  children,
  messages,
}: { messages: Record<string, Message>; children: React.ReactNode }) => {
  const [isAutoScrollActive, setIsAutoScrollActive] = useState(true);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const onScroll = useCallback(() => {
    if (containerRef.current) {
      const isAtBottom =
        containerRef.current.scrollTop + containerRef.current.clientHeight ===
        containerRef.current.scrollHeight;
      setIsAutoScrollActive(isAtBottom);
    }
  }, []);

  useEffect(() => {
    if (isAutoScrollActive && messages) {
      scrollToBottom();
    }
  }, [isAutoScrollActive, scrollToBottom, messages]);

  return (
    <ChatHistory ref={containerRef} onScroll={onScroll}>
      {children}
      <div ref={messagesEndRef} />
    </ChatHistory>
  );
};

export const CopilotContainer = styled.div<{ isOpen: boolean; width?: number }>`
  padding-right: ${({ isOpen, width = 500 }) => (isOpen ? `${width}px` : "0")};
`;

const CopilotSidebarContainer = styled(motion.aside)<{ $width: number }>`
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${({ theme }) => theme.color.border.muted};
  position: fixed;
  top: 68px;
  right: 0;
  width: ${({ $width }) => `${$width}px`};
  overflow: hidden;
  background: ${({ theme }) => theme.color.bg.default};
`;

const CopilotHeader = styled.header`
  user-select: none;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 24px 24px 0px 32px;
  font: ${({ theme }) => theme.font.headings.h3};
  border-bottom: 1px solid ${({ theme }) => theme.color.border.muted};
  padding-bottom: 24px;
`;

const ChatHistory = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 24px 24px 32px;
  flex: 1;
  overflow-y: auto;
  font: ${({ theme }) => theme.font.body.md.regular};
`;

const PromptInput = styled.textarea`
  resize: none;
  width: 100%;
  font: ${({ theme }) => theme.font.body.md.regular};
  color: ${({ theme }) => theme.color.input.fg.input};
  background: ${({ theme }) => theme.color.input.bg};
  border: 1px solid ${({ theme }) => theme.color.border.muted};
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: 12px;
  :placeholder {
    color: ${({ theme }) => theme.color.input.fg.placeholder};
  }
`;

const PromptContainer = styled.footer`
  border-top: 1px solid ${({ theme }) => theme.color.border.muted};
  padding: 24px 24px 16px;
  position: relative;
  button {
    position: absolute;
    right: 36px;
    bottom: 36px;
  }
`;

const ResizableHandle = styled.div`
  width: 15px;
  height: 100%;
  cursor: ew-resize;
  top: 0;
  left: -8px;
  position: absolute;
  z-index: 1;
`;

const StyledNotebookIcon = styled(Icon)`
  height: 1em;
  width: 1em;
  margin-right: 4px;
  display: inline-flex;
  align-items: center;

`;
