import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import useAsync from "react-use/lib/useAsync";

import {
  selectActiveWorkspaceId,
  selectDataSourcesList,
  selectSelectedDataSources,
} from "../selectors";
import { dispatch } from "../store";
import {
  type ProviderForIntentResult,
  getProviderForIntentWithDataSources,
  loadDataSources,
} from "../thunks";
import type { Intent } from "../utils";

export type UseProviderResult = ProviderForIntentResult | Error | undefined;

/**
 * Returns the provider to use for a given intent.
 *
 * @see getProviderForIntent()
 *
 * Returns `undefined` as long as the provider is not yet initialized, and will
 * return an `Error` if something goes wrong.
 */
export function useProvider(intent: Intent): UseProviderResult {
  // Get loaded data source information from store
  const { data: dataSources, error: loadingErrorText } = useSelector(
    selectDataSourcesList,
  );
  const workspaceId = useSelector(selectActiveWorkspaceId);

  // Load data sources if there are no data sources yet
  useEffect(() => {
    if (!dataSources && workspaceId) {
      dispatch(loadDataSources(workspaceId));
    }
  }, [dataSources, workspaceId]);

  // Get data sources that are selected on the notebook model
  const selectedDataSources = useSelector(selectSelectedDataSources);

  const { error, value } = useAsync(() => {
    if (dataSources) {
      return dispatch(
        getProviderForIntentWithDataSources(
          dataSources,
          selectedDataSources,
          intent,
        ),
      );
    }

    return Promise.resolve(undefined);
  }, [dataSources, selectedDataSources, intent]);

  const loadingError = useMemo(
    (): Error | undefined =>
      typeof loadingErrorText === "string"
        ? new Error(loadingErrorText)
        : loadingErrorText,
    [loadingErrorText],
  );

  return error || value || loadingError;
}
