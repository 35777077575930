import type { Message } from "react-hook-form";

type ValueType = string | null | undefined;

export const WORKSPACE_DISPLAY_NAME_MAX_CHARACTERS = 256;

export const validateEndsAlphaNumerical = (
  value: ValueType,
  fieldLabel: string,
): Message | undefined => {
  if (!value) {
    return;
  }

  const lastChar = value[value.length - 1];
  if (lastChar && !isAlphaNumerical(lastChar)) {
    return `The ${fieldLabel} must end with a lower case ASCII letter or a number.`;
  }
};

export const validateMaxLength = (
  value: ValueType,
  maxLength: number,
  fieldLabel: string,
): Message | undefined =>
  value && value.length > maxLength
    ? `The ${fieldLabel} can't be longer than ${maxLength} characters.`
    : undefined;

export const validateRequired = (
  value: ValueType,
  fieldLabel: string,
): Message | undefined =>
  value ? undefined : `The ${fieldLabel} is required.`;

export const validateResourceName = (
  value: ValueType,
  fieldName: string,
): Message | undefined => {
  const required = validateRequired(value, fieldName);
  if (required) {
    return required;
  }

  const startsAlphaNumerical = validateStartsAlphaNumerical(value, fieldName);
  if (startsAlphaNumerical) {
    return startsAlphaNumerical;
  }

  const specialCharacters = validateSpecialCharacters(value, fieldName);
  if (specialCharacters) {
    return specialCharacters;
  }

  const endsAlphaNumerical = validateEndsAlphaNumerical(value, fieldName);
  if (endsAlphaNumerical) {
    return endsAlphaNumerical;
  }
};

export const validateSpecialCharacters = (
  value: ValueType,
  fieldLabel: string,
): Message | undefined =>
  value && /^[\da-z-]*$/.test(value)
    ? undefined
    : `The ${fieldLabel} can only include lowercase ASCII letters, numbers, and dashes`;

export const validateStartsAlphaNumerical = (
  value: ValueType,
  fieldLabel: string,
): Message | undefined => {
  if (!value) {
    return;
  }

  const firstChar = value[0];
  if (firstChar && !isAlphaNumerical(firstChar)) {
    return `The ${fieldLabel} must start with a lower case ASCII letter or a number.`;
  }
};

export const validateUrl = (
  value: ValueType,
  fieldLabel: string,
): Message | undefined => {
  if (!value) {
    return `The ${fieldLabel} is not a valid URL.`;
  }

  try {
    const parsedUrl = new URL(value);

    if (
      !(parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:") &&
      parsedUrl.hostname !== "localhost" &&
      parsedUrl.hostname !== "127.0.0.1"
    ) {
      throw new Error("Invalid url");
    }
  } catch {
    return `The ${fieldLabel} is not a valid URL.`;
  }
};

const isAlphaNumerical = (input: string) => /^[\da-z]$/.test(input);
