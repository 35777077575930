import { css, styled } from "styled-components";
import { getAccentColors } from "../../utils";

export const AvatarIconContainer = styled.figure<{
  $hasImage?: boolean;
  $size: number;
  $id?: string;
}>(({ $size, $id, theme }) => {
  const colors = $id && getAccentColors($id);

  return css`
    display: grid;
    place-items: center;
    position: relative;
    isolation: isolate;
    overflow: hidden;
    width: ${$size}px;
    aspect-ratio: 1;
    margin: 0;
    border-radius: ${theme.radius.full};
    text-transform: uppercase;
    font: ${theme.font.body.sm.medium};
    font-size: ${Math.round($size * 0.55)}px;
    line-height: 1;

    /* Default colors */
    color: ${theme.color.fg.muted};
    background-color: ${theme.color.bg.default};

    /* Accent colors */
    ${colors}

    & > svg {
      width: ${$size * 0.9}px;
      height: ${$size * 0.9}px;
    }

    & > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    /*
      Use before & after elements for a layered background so the icons aren't
      transparent when placed on top of each other.
    */
    &::before, &::after {
      content: "";
      display: block;
      position: absolute;
      inset: 0;
    }

    &::before {
      z-index: -2;
      background-color: ${theme.color.bg.default};
    }

    &::after {
      z-index: -1;
      ${colors}
    }
  `;
});
