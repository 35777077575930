import type { Label } from "../../types";

export const LABEL_ITEM_OPTION_KEYS = [
  "compact",
  "onDelete",
  "onEdit",
  "withContextMenu",
  "withToolbar",
] as const;

export type LabelItemOptions = Pick<
  LabelsEditorOptions,
  (typeof LABEL_ITEM_OPTION_KEYS)[number]
>;

export type LabelsEditorOptions = {
  compact?: boolean;
  onBlur: (event: React.FocusEvent) => void;
  onCreate?: (event: React.SyntheticEvent) => void;
  onDelete?: (event: React.SyntheticEvent, label: Label) => void;
  onEdit?: (event: React.SyntheticEvent, label: Label) => void;
  withContextMenu?: boolean;
  withDeleteIcon?: boolean;
  withToolbar?: boolean;
};
