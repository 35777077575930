import classNames from "classnames";
import {
  type FocusEvent,
  type FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { styled } from "styled-components";

import { cancelEvent, stopPropagation } from "@fiberplane/ui";
import { useInputFocusHandlers } from "../../../hooks";
import type { CellFocus, TimeRange, Timestamp } from "../../../types";
import {
  cellFocusesAreEqual,
  charCount,
  mergeHandlers,
  modifiersPressed,
  noCellFocus,
  validateTimeRange,
  withField,
} from "../../../utils";
import { ErrorHint } from "./ErrorHint";
import { TimeRangeInput } from "./TimeRangeInput";

export type TimeRangeSelectorField = "to" | "from";

type Props = {
  from: Timestamp;
  to: Timestamp;
  focus: CellFocus;
  readOnly: boolean;
  onChange(timeRange: TimeRange): void;
  onFocusChange(focus: CellFocus): void;
};

export function TimeRangeForm(props: Props) {
  const { focus, from, to, readOnly, onChange, onFocusChange } = props;
  const startRef = useRef<HTMLDivElement | null>(null);
  const endRef = useRef<HTMLDivElement | null>(null);

  const [fromText, setFromText] = useState(from);
  const [toText, setToText] = useState(to);

  const [startError, setStartError] = useState<undefined | string>();
  const [endError, setEndError] = useState<undefined | string>();
  const [globalError, setGlobalError] = useState<undefined | string>();

  useEffect(() => {
    setFromText(from);
    setToText(to);
    setStartError(undefined);
    setEndError(undefined);
    setGlobalError(undefined);
  }, [from, to]);

  const field = getTimeRangeFocusField(focus);

  const updateFocus = (newFocus: CellFocus) => {
    // Make sure the new focus is really different, before
    // calling `onFocusChange`:
    const currentFocus = withField(focus, field ?? "from");

    // FIXME: support for offset / fields for global time range cells
    // For now we assume that if the focus type is collapsed that
    // one of the inputs has focus
    if (currentFocus.type === "collapsed") {
      // We're assuming that you're at the first field (if no field is specified already)
      currentFocus.field ??= "from";
      // We're assuming that you're at position 0 if no position is specified
      currentFocus.offset ??= 0;
    }

    const focusWithField = withField(newFocus, field ?? "from");
    if (!cellFocusesAreEqual(focusWithField, currentFocus)) {
      onFocusChange(focusWithField);
    }
  };

  const handleSubmit = (
    event: FormEvent<HTMLFormElement> | FocusEvent<HTMLInputElement>,
  ) => {
    cancelEvent(event);

    const { errors, values } = validateTimeRange({
      from: fromText,
      to: toText,
    });

    // Update state
    setStartError(errors.from);
    setEndError(errors.to);
    setGlobalError(errors.global);

    const hasErrors = Object.values(errors).some((value) => !!value);
    if (hasErrors) {
      return;
    }

    const newTimeRange = { from: values.from, to: values.to };
    onChange(newTimeRange);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    // filter events triggered by menu clicks
    if (
      event.relatedTarget &&
      (event.relatedTarget as HTMLInputElement).dataset.contextMenu === "true"
    ) {
      return;
    }

    handleSubmit(event);
  };

  const { onClick: onClickFromInput, onFocus: onFocusFromInput } =
    useInputFocusHandlers({
      field: "from",
      focus,
      readOnly,
      text: fromText,
      onFocusChange,
    });

  const { onClick: onClickToInput, onFocus: onFocusToInput } =
    useInputFocusHandlers({
      field: "to",
      focus,
      readOnly,
      text: toText,
      onFocusChange,
    });

  return (
    <Form onSubmit={handleSubmit}>
      <InputContainer ref={startRef}>
        <TimeRangeInput
          data-testid="time-range-from"
          className={classNames((startError || globalError) && "error")}
          disabled={readOnly}
          value={fromText}
          name="from"
          onClick={onClickFromInput}
          placeholder="from"
          aria-invalid={startError ? true : undefined}
          aria-describedby={
            (globalError && "global-error") || (startError && "start-error")
          }
          focus={field === "from" ? focus : noCellFocus}
          onFocus={mergeHandlers([onFocusFromInput, stopPropagation])}
          onFocusChange={updateFocus}
          onBlur={handleBlur}
          onChange={(event) => {
            if (event.target.value !== fromText) {
              setFromText(event.target.value);
            }
          }}
          onKeyDown={(event) => {
            // Using special keys?
            // don't switch inputs
            if (modifiersPressed(event)) {
              return;
            }

            if (
              event.key === "ArrowRight" &&
              event.currentTarget.selectionStart === fromText.length
            ) {
              cancelEvent(event);
              onFocusChange({
                type: "collapsed",
                field: "to",
                offset: 0,
              });
            }
          }}
        />

        {startError && (
          <ErrorHint element={startRef.current} id="start-error">
            {startError}
          </ErrorHint>
        )}
      </InputContainer>

      <label htmlFor="time-range-to">to</label>
      <InputContainer ref={endRef}>
        <TimeRangeInput
          data-testid="time-range-to"
          className={classNames((globalError || endError) && "error")}
          disabled={readOnly}
          value={toText}
          key={to}
          name="to"
          onFocus={mergeHandlers([onFocusToInput, stopPropagation])}
          onBlur={handleBlur}
          placeholder="to"
          aria-invalid={endError ? true : undefined}
          aria-describedby={
            (globalError && "global-error") || (endError && "end-error")
          }
          focus={field === "to" ? focus : noCellFocus}
          onFocusChange={updateFocus}
          onClick={onClickToInput}
          onChange={(event) => {
            if (event.target.value !== toText) {
              setToText(event.target.value);
            }
          }}
          onKeyDown={(event) => {
            if (modifiersPressed(event)) {
              return;
            }

            if (
              event.key === "ArrowLeft" &&
              event.currentTarget.selectionStart === 0
            ) {
              cancelEvent(event);
              onFocusChange({
                type: "collapsed",
                field: "from",
                offset: charCount(fromText),
              });
            }
          }}
        />

        {endError && (
          <ErrorHint element={endRef.current} id="end-error">
            {endError}
          </ErrorHint>
        )}
      </InputContainer>

      {globalError && (
        <ErrorHint element={endRef.current} id="global-error">
          {globalError}
        </ErrorHint>
      )}

      <input type="submit" hidden />
    </Form>
  );
}

/**
 * Used to determine whether the field `to` or `from` has focus
 */
export function getTimeRangeFocusField(
  focus: CellFocus,
): TimeRangeSelectorField | null {
  switch (focus.type) {
    case "none":
      return null;
    case "collapsed":
      return focus.field === "to" ? "to" : "from";
    case "selection":
      return focus.start.field === "to" ? "to" : "from";
  }
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`;

const InputContainer = styled(Container)`
  display: grid;
  grid-template-columns: auto;
  gap: 4px;

  &:last-of-type {
    margin-right: 4px;
  }

  input {
    width: 14em;
    box-sizing: border-box;
    font: ${({ theme }) => theme.fontNotebooksCodeShortHand};
    letter-spacing: ${({ theme }) => theme.fontNotebooksCodeLetterSpacing};
  }
`;

const Form = styled(Container).attrs({ as: "form" })`
  display: flex;
  align-items:center;

  font: ${({ theme }) => theme.fontStudioStrongSmallShortHand};
  line-height: ${({ theme }) => theme.fontStudioStrongSmallLineHeight};
`;
