import favIconUnrecoverable from "url:./images/favicon-unrecoverable.raw.svg";
import { Button, Icon } from "@fiberplane/ui";
import { useSelector } from "react-redux";
import useFavicon from "react-use/lib/useFavicon";
import useMedia from "react-use/lib/useMedia";
import { css, styled } from "styled-components";

import { MEDIUM_SIZE_QUERY } from "../../constants";
import {
  selectCell,
  selectCellIds,
  selectSideMenuIsOpen,
} from "../../selectors";
import { type Thunk, dispatch } from "../../store";
import { exportCellsAsMarkdown, showTooltip } from "../../thunks";
import { compact } from "../../utils";
import { ButtonBar, Container, Text } from "../UI";

export function UnrecoverableErrorBanner(): JSX.Element {
  useFavicon(favIconUnrecoverable);

  const mediumSize = useMedia(MEDIUM_SIZE_QUERY);
  const isMenuOpen = useSelector(selectSideMenuIsOpen) ?? mediumSize;

  const isNarrow = useMedia("(max-width: 768px)");
  const isWide = useMedia("(max-width: 1024px)");

  return (
    <Banner
      data-testid="unrecoverable-error-banner"
      isNarrow={isNarrow}
      isWide={isWide}
      sideMenuIsOpen={isMenuOpen}
    >
      <StyledIcon iconType="plugs" />
      <StyledText>
        There was an internal issue syncing your notebook. Your notebook is now
        read-only to prevent data loss.
      </StyledText>
      <AlignedButtonBar>
        <DelayedBannerButton
          buttonSize="small"
          onClick={() => {
            window.open(
              "mailto:support@fiberplane.com?subject=Internal%20syncing%20error",
              "_blank",
              "noopener noreferrer",
            );
          }}
        >
          <StyledIcon iconType="bug" /> Contact support
        </DelayedBannerButton>
        <Button
          buttonSize="small"
          onClick={(event) => dispatch(copyAsMarkdown(event))}
        >
          <StyledIcon iconType="download" /> Copy as MD
        </Button>
      </AlignedButtonBar>
    </Banner>
  );
}

const copyAsMarkdown =
  (event: React.MouseEvent): Thunk =>
  async (dispatch, getState) => {
    let tooltip = "Could not copy to clipboard";

    const state = getState();
    const cellIds = selectCellIds(state);

    if (cellIds.length > 0) {
      // Copy the entire notebook.
      const cells = compact(cellIds.map((cellId) => selectCell(state, cellId)));
      const markdown = exportCellsAsMarkdown(state, cells, { type: "all" });
      if (markdown) {
        await navigator.clipboard.writeText(markdown);
        tooltip = "Copied to clipboard";
      }
    }

    dispatch(
      showTooltip(event.target as HTMLElement, tooltip, {
        placement: "bottom",
      }),
    );
  };

const Banner = styled(Container)<{
  isNarrow: boolean;
  isWide: boolean;
  sideMenuIsOpen: boolean;
}>(
  ({ theme, isNarrow, isWide, sideMenuIsOpen }) => css`
    align-items: center;
    padding: 16px 20px;
    grid-gap: 16px 20px;

    background: ${theme.colorWarning200};
    color: ${theme.colorWarning700};

    display: grid;
    ${
      isNarrow || (isWide && sideMenuIsOpen)
        ? css`
          grid-template-columns: auto 1fr;
          grid-auto-rows: auto;
          grid-template-areas: "icon text" ". buttonbar";
        `
        : css`
          grid-template-columns: auto 1fr auto;
          grid-template-areas: "icon text buttonbar";
        `
    }
  `,
);

const StyledIcon = styled(Icon)`
  grid-area: icon;
`;

const StyledText = styled(Text)`
  grid-area: text;
  font-size: ${({ theme }) => theme.fontNotebooksSmallFontSize};
`;

const AlignedButtonBar = styled(ButtonBar)`
  grid-area: buttonbar;
  margin-right: auto;
`;

const DelayedBannerButton = styled(Button)`
  animation: fadeIn 2.5s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    80% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;
