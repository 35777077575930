import { createSelector } from "reselect";

import type { RootState } from "../state";
import type { Label } from "../types";
import { convertApiLabelToClientLabel } from "../utils";

const DEFAULT_LIST: Readonly<Array<Label>> = [];

export const selectEventLabelsState = (state: RootState) => {
  return state.events.activeEvent.labels;
};

export const selectEventLabels = createSelector(
  [selectEventLabelsState],
  (labels) => (labels ? convertApiLabelToClientLabel(labels) : DEFAULT_LIST),
);
