import { Icon } from "@fiberplane/ui";
import { useMemo } from "react";
import styled from "styled-components";

import { FieldSelectionElement } from "..";
import { sortBy } from "../../../../../../utils";
import { getPriority } from "../../../../../ContextMenu";
import {
  type FilterMenuItem,
  PositionedEmbeddedSearchMenu,
  type PositionedFilterMenuProps,
} from "../../../../../UI";

type Props = {
  onActivate: (value: string) => void;
  /**
   * Function that creates a custom menu item. Useful for adding a "Create new" item
   * or perhaps an interactive No results item.
   */
  createExtraMenuItem?: (options: {
    filterText: string;
    exactMatchFound: boolean;
  }) => FilterMenuItem | undefined;
  selectedList?: Array<string>;
  options: Array<string>;
} & Omit<PositionedFilterMenuProps, "items">;

const EMPTY_LIST: Array<string> = [];

export function SelectionFrontMatterMenu({
  onActivate,
  filterText = "",
  selectedList = EMPTY_LIST,
  options,
  ...menuProps
}: Props): JSX.Element | null {
  const items = useMemo((): Array<FilterMenuItem> => {
    return [
      {
        id: "options",
        type: "item_group",
        title: "Options",
        items: options.map(
          (option): FilterMenuItem => ({
            type: "item_with_icons",
            id: option,
            title: option,
            renderTitle,
            iconRight: {
              icon: (
                <Icon
                  iconType={
                    selectedList.includes(option) ? "check" : "key_return"
                  }
                />
              ),
              showOnlyOnSelection: !selectedList.includes(option),
            },
            onActivate: () => onActivate(option),
          }),
        ),
      },
    ];
  }, [options, onActivate, selectedList]);

  const filtered = useMemo(
    () => sortBy(items, getPriority(filterText)),
    [items, filterText],
  );

  return (
    <StyledPositionedMenu
      {...menuProps}
      placeholder={
        menuProps.createExtraMenuItem ? "Search or add new option" : undefined
      }
      filterText={filterText}
      items={filtered}
      noResultsText="No results"
      placement="bottom-start"
    />
  );
}

export function renderTitle(title: string) {
  return (
    <MenuItemElement singleColumn colorKey={title}>
      {title}
    </MenuItemElement>
  );
}

/// Styles

const StyledPositionedMenu = styled(PositionedEmbeddedSearchMenu).attrs({
  offset: [0, 5],
  grabsFocus: true,
})`
  max-height: 300px;
  width: 292px;
`;

const MenuItemElement = styled(FieldSelectionElement)`
  width: fit-content;
`;
