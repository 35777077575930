import { decode } from "blurhash";
import { useEffect, useRef, useState } from "react";
import { css, keyframes, styled } from "styled-components";

import { blurhashSize } from "../../../utils";

type Props = {
  notebookId: string;
  fileId: string;
  width?: number;
  height?: number;
  preview?: string;
};

export function Done(props: Props): JSX.Element {
  const { preview, width, height, fileId, notebookId } = props;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (canvasRef.current && preview && width && height) {
      const blurSize = blurhashSize(width, height);
      decodeToCanvas({
        destinationCanvas: canvasRef.current,
        hash: {
          preview,
          ...blurSize,
        },
      });
    }
  }, [preview, width, height]);

  // If the fileId is a url, use it directly, otherwise construct the image url
  const imageSource = isUrl(fileId)
    ? fileId
    : `/api/notebooks/${notebookId}/files/${fileId}`;

  return (
    <Container>
      <SizeConstraint>
        <Sizer $width={width}>
          {preview && !loaded && (
            <StyledCanvas ref={canvasRef} width={width} height={height} />
          )}
          <StyledImage
            src={imageSource}
            width={width}
            height={height}
            onLoad={() => setLoaded(true)}
            alt=""
            loading="lazy"
          />
        </Sizer>
      </SizeConstraint>
    </Container>
  );
}

function decodeToCanvas(params: {
  destinationCanvas: HTMLCanvasElement;
  hash: {
    preview: string;
    width: number;
    height: number;
  };
}): void {
  const {
    destinationCanvas,
    hash: { preview, width, height },
  } = params;

  // Decode
  const pixels = decode(preview, width, height);

  // Prepare canvas/context for drawing preview on
  const previewCanvas = document.createElement("canvas");
  previewCanvas.width = width;
  previewCanvas.height = height;

  const previewContext = previewCanvas.getContext("2d");
  if (!previewContext) {
    throw new Error("Unable to create context");
  }

  const imageData = previewContext.createImageData(width, height);
  imageData.data.set(pixels);
  previewContext.putImageData(imageData, 0, 0);

  const context = destinationCanvas.getContext("2d");
  if (!context) {
    throw new Error("Unable to create context");
  }

  context.drawImage(
    previewCanvas,
    0,
    0,
    destinationCanvas.width,
    destinationCanvas.height,
  );
}

function isUrl(input: string): boolean {
  return input.startsWith("/") || input.startsWith("https://");
}

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeInAnimation} 0.3s cubic-bezier(0.37, 0.05, 0.18, 0.94)
    forwards;
`;

const SizeConstraint = styled.div`
  height: auto;
  width: auto;
  max-width: 100%;
`;

const Sizer = styled.div<{ $width?: number }>`
  width: ${({ $width = 100 }) => $width}px;
  position: relative;
  max-width: 100%;
  max-height: 100%;
`;

const imageStyling = css`
  max-width: 100%;
  width: auto;
  height: auto;
`;

const StyledCanvas = styled.canvas`
  position: absolute;
  ${imageStyling}
`;

const StyledImage = styled.img`
  position: relative;
  display: block;
  ${imageStyling}
`;
