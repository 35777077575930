import { useHandler } from "@fiberplane/hooks";
import { Icon } from "@fiberplane/ui";
import { forwardRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

import { type TemplateSummary, useListTemplatesQuery } from "../../api";
import { useFeature } from "../../hooks";
import {
  selectActiveWorkspaceIdOrThrow,
  selectActiveWorkspaceRole,
  selectCanCreateNotebook,
} from "../../selectors";
import { dispatch } from "../../store";
import {
  addNotification,
  createNotebookAndRedirect,
  showTemplateDetailModal,
} from "../../thunks";
import { noop } from "../../utils";
import {
  MenuItem,
  MenuItemGroup,
  MenuItemWithDescription,
  MenuItemWithSubMenu,
  ToggleMenu,
} from "../UI";
import { NavButton } from "./NavElement";
import { MENU_OFFSET } from "./common";

const NewNotebookToggleElement = forwardRef<
  HTMLDivElement,
  { opened: boolean }
>(function NewNotebookToggleElement({ opened }, ref) {
  return (
    <div ref={ref}>
      <NavButton
        as="button"
        data-create-notebook
        aria-label="New Notebook"
        data-tooltip-placement="right"
        $isActive={opened}
        aria-pressed={opened}
      >
        <Icon iconType="new_notebook" />
      </NavButton>
    </div>
  );
});

export function NewNotebook() {
  const [hasNewNotebookFeature] = useFeature("nav-new-notebook");
  const canCreateNotebook = useSelector(selectCanCreateNotebook);
  const role = useSelector(selectActiveWorkspaceRole);
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const {
    data: templates,
    error,
    isFetching,
    refetch,
  } = useListTemplatesQuery({ workspaceId });

  const onCreate = useHandler(() => {
    if (!canCreateNotebook) {
      return dispatch(
        addNotification({
          type: "danger",
          title:
            "You don't have permission to create notebooks in this workspace",
        }),
      );
    }

    dispatch(createNotebookAndRedirect());
  });

  const onActivate = useHandler((template: TemplateSummary) => {
    if (role === "read") {
      return dispatch(
        addNotification({
          type: "danger",
          title:
            "You don't have permission to create notebooks in this workspace",
        }),
      );
    }

    dispatch(showTemplateDetailModal(template.name));
  });

  const content = useMemo(() => {
    if (isFetching) {
      return <MenuItem onActivate={noop} id="loading" title="Loading" />;
    }

    if (error) {
      return <MenuItem onActivate={noop} id="error" title="Error" />;
    }

    if (!templates) {
      return [];
    }

    return (
      <MenuItemGroup title="New notebook from template">
        {templates.map((template) => (
          <MenuItemWithDescription
            id={template.name}
            onActivate={() => onActivate(template)}
            key={template.name}
            title={template.name}
            description={template.description}
          />
        ))}
      </MenuItemGroup>
    );
  }, [error, isFetching, templates, onActivate]);

  if (hasNewNotebookFeature) {
    return (
      <NavButton
        as="button"
        data-create-notebook
        aria-label="New Notebook"
        data-tooltip-placement="right"
        onClick={onCreate}
      >
        <Icon iconType="new_notebook" />
      </NavButton>
    );
  }

  return (
    <StyledToggleMenu
      offset={MENU_OFFSET}
      placement="right-start"
      onClick={refetch}
      toggleElement={({ anchorRef, opened }) => (
        <NewNotebookToggleElement opened={opened} ref={anchorRef} />
      )}
      data-testid="new-notebook-menu"
    >
      <MenuItemGroup title="New Notebook">
        <MenuItem
          id="new-notebook"
          iconLeft={<Icon iconType="plus" />}
          title="New empty notebook"
          onActivate={onCreate}
          data-testid="new-empty-notebook"
        />

        <MenuItemWithSubMenu
          id="new-notebook-from-template"
          title="New notebook from template"
          iconLeft={<Icon iconType="plus" />}
        >
          {content}
        </MenuItemWithSubMenu>
      </MenuItemGroup>
    </StyledToggleMenu>
  );
}

const StyledToggleMenu = styled(ToggleMenu)`
  width: 320px;
`;
