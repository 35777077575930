import { useEffect } from "react";
import { useSelector } from "react-redux";
import { generatePath } from "react-router";
import { push, replace } from "redux-first-history";
import { createSelector } from "reselect";

import { useSearchParams } from "react-router-dom";
import { ROUTES } from "../../constants";
import {
  selectActiveWorkspaceName,
  selectIsAuthenticated,
} from "../../selectors";
import { dispatch } from "../../store";

export function NoMatch() {
  const { isAuthenticated, activeWorkspaceName } =
    useSelector(selectNoMatchState);

  const [search] = useSearchParams();
  const hasSettingsModal = search.get("overlay")?.includes("settings");

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(push(`${ROUTES.SignIn}?redirect=${document.location.pathname}`));
    } else if (activeWorkspaceName && !hasSettingsModal) {
      dispatch(
        replace(
          generatePath(ROUTES.GettingStarted, {
            workspaceName: activeWorkspaceName,
          }),
        ),
      );
    }
  }, [isAuthenticated, activeWorkspaceName, hasSettingsModal]);

  return null;
}

const selectNoMatchState = createSelector(
  [selectIsAuthenticated, selectActiveWorkspaceName],
  (isAuthenticated, activeWorkspaceName) => ({
    isAuthenticated,
    activeWorkspaceName,
  }),
);
