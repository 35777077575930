import { Button, Icon, IconButton, cancelEvent } from "@fiberplane/ui";
import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { selectPagerDutyReceivers } from "../../../../../../selectors";
import { loadReceivers } from "../../../../../../slices";
import { dispatch } from "../../../../../../store";
import {
  addNotification,
  showModal,
  showPagerDutyReceiverDeleteConfirmation,
} from "../../../../../../thunks";
import { ButtonBar } from "../../../../../UI";
import { EmptyMessage } from "../../shared";

export function ReceiverList() {
  const { data: receivers } = useSelector(selectPagerDutyReceivers);

  useEffect(() => {
    dispatch(loadReceivers());
  }, []);

  if (!receivers || receivers.length === 0) {
    return (
      <EmptyMessage
        iconType="webhook"
        title="No PagerDuty webhook receivers"
        description="Add your first receiver"
        action={
          <Button
            onClick={() =>
              dispatch(showModal({ type: "createPagerDutyReceiver" }))
            }
          >
            <Icon iconType="plus" />
            New webhook receiver
          </Button>
        }
      />
    );
  }

  return (
    <>
      <List>
        {receivers.map((receiver) => (
          <Fragment key={receiver.name}>
            <ListItem
              onClick={() =>
                dispatch(
                  showModal({
                    type: "editPagerDutyReceiver",
                    name: receiver.name,
                    receiver,
                  }),
                )
              }
            >
              <Content>
                <ContentLine>
                  <Name>{receiver.name}</Name>
                </ContentLine>
                {!receiver.secretSet && (
                  <ContentLine>
                    <SecretMissingIcon iconType="warning" />
                    <SecretMissingWarning>
                      Secret is missing, please provide the secret provided by
                      PagerDuty
                    </SecretMissingWarning>
                  </ContentLine>
                )}
                <ContentLine>
                  <Icon iconType="templates" />
                  {receiver.incidentCreatedTemplateName}
                </ContentLine>
                <ContentLine>
                  <Icon iconType="link" />
                  <Url>{receiver.webhookUrl}</Url>
                  <IconButton
                    iconType="copy"
                    buttonStyle="secondary"
                    onClick={(event) => {
                      cancelEvent(event);
                      navigator.clipboard.writeText(receiver.webhookUrl);
                      dispatch(
                        addNotification({
                          title:
                            "Webhook url has been copied to your clipboard",
                        }),
                      );
                    }}
                  />
                </ContentLine>
              </Content>
              <ItemButtonBar>
                <IconButton
                  buttonStyle="tertiary-grey"
                  iconType="pencil_simple"
                  onClick={() =>
                    dispatch(
                      showModal({
                        type: "editPagerDutyReceiver",
                        name: receiver.name,
                        receiver,
                      }),
                    )
                  }
                  type="button"
                />
                <IconButton
                  buttonStyle="tertiary-grey"
                  iconType="trash"
                  onClick={(event) => {
                    cancelEvent(event);
                    dispatch(
                      showPagerDutyReceiverDeleteConfirmation({
                        name: receiver.name,
                      }),
                    );
                  }}
                  type="button"
                />
              </ItemButtonBar>
            </ListItem>
          </Fragment>
        ))}
      </List>
      <ButtonBar>
        <Button
          onClick={() =>
            dispatch(showModal({ type: "createPagerDutyReceiver" }))
          }
        >
          Create new webhook receiver
        </Button>
      </ButtonBar>
    </>
  );
}

const List = styled.ul`
  display: grid;
  gap: 12px;
  width: 100%;
`;

const ListItem = styled.li(
  ({ theme }) => css`
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;

  background-color: ${theme.color.button.secondary.bg};
  border: 1px solid ${theme.color.border.muted};
  border-radius: ${theme.radius.default};
  margin: 0;
  padding: 12px;
  box-shadow: ${theme.effect.shadow.xxs};

  &:first-child {
    margin-right: auto;
  }

  &:hover {
    background-color: inherit;
    border-color: ${theme.color.border.muted};
  }
`,
);

const ItemButtonBar = styled(ButtonBar)`
  align-self: flex-start;
`;

const Content = styled.div`
  display: grid;
  gap: 4px;
  margin-right: auto;
`;

const ContentLine = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Url = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 320px;
`;

const Name = styled.span(
  ({ theme }) => `
    color: ${theme.color.fg.default};
    font:${theme.font.headings.h4};
`,
);

const SecretMissingIcon = styled(Icon)(
  ({ theme }) => css`
    color: ${theme.color.fg.danger};
`,
);

const SecretMissingWarning = styled.span(
  ({ theme }) => css`
    color: ${theme.color.fg.danger};
`,
);
