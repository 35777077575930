import { useMemo } from "react";
import { css, styled } from "styled-components";

import { Input } from "@fiberplane/ui";
import { DEFAULT_FIELDS } from "../../../constants";
import { type EventWithIndex, useLogRecordsData } from "../../../hooks";
import { dispatch } from "../../../store";
import {
  toggleExpandIndex,
  toggleSelectAllRows,
  toggleSelectedIndex,
  updateCell,
} from "../../../thunks";
import type { LogCell } from "../../../types";
import { recordIndexMatches } from "../../../utils";
import { Details } from "./Details";
import { RowControls } from "./RowControls";
import { formatRow } from "./formatRow";
import {
  containsExportedLogs,
  formatField,
  getKeysWithSameValue,
} from "./utils";

type Row = {
  children: Array<React.ReactNode>;
  highlighted?: boolean;
};

export function useRows(cell: LogCell, records: Array<EventWithIndex>) {
  const isExportedLogs = containsExportedLogs(cell);

  const {
    id,
    displayFields = DEFAULT_FIELDS,
    expandedIndices = [],
    hideSimilarValues = true,
    highlightedIndices = [],
    selectedIndices = [],
    visibilityFilter,
  } = cell;

  const similarValuedKeys = hideSimilarValues
    ? getKeysWithSameValue(records)
    : [];

  let numRows = 0;
  const rows: Array<Row> = [];

  for (const record of records) {
    const { recordIndex } = record;
    const matchesRecordIndex = recordIndexMatches(recordIndex);

    const expanded = expandedIndices.some(matchesRecordIndex);
    const selected = selectedIndices.some(matchesRecordIndex);
    const highlighted = highlightedIndices.some(matchesRecordIndex);

    if (
      (visibilityFilter === "selected" && !selected) ||
      (visibilityFilter === "highlighted" && !highlighted)
    ) {
      continue;
    }

    rows.push({
      children: formatRow({
        displayFields,
        expanded,
        record,
        selected,
        similarValuedKeys,
        toggleExpand: () => dispatch(toggleExpandIndex(id, recordIndex)),
        toggleSelected: isExportedLogs
          ? undefined
          : () => dispatch(toggleSelectedIndex(id, recordIndex)),
      }),
      highlighted,
    });
    numRows++;

    if (expanded) {
      rows.push({ children: [<Details key="details" record={record} />] });
    }
  }

  return { numRows, totalRows: records.length, rows };
}

export function useHeaders(cell: LogCell) {
  const isExportedLogs = containsExportedLogs(cell);
  const {
    id,
    displayFields = DEFAULT_FIELDS,
    hideSimilarValues = true,
    selectedIndices = [],
  } = cell;

  const itemsSelected = selectedIndices.length > 0;
  const records = useLogRecordsData(cell);

  return useMemo(() => {
    return [
      <RowControls
        key="row-controls"
        hideCount
        selected={itemsSelected}
        toggleSelected={
          isExportedLogs
            ? undefined
            : () => dispatch(toggleSelectAllRows(id, records))
        }
      />,
      ...displayFields.map((key) => {
        const children = formatField(key);
        if (key !== "document") {
          return children;
        }

        return (
          <HeaderContainer key={key}>
            {children}
            <ToggleLabel>
              <Input
                type="lightswitch"
                role="switch"
                checked={hideSimilarValues}
                aria-checked={hideSimilarValues}
                onChange={() =>
                  dispatch(
                    updateCell(id, {
                      hideSimilarValues: !hideSimilarValues,
                    }),
                  )
                }
              />
              Unique values only
            </ToggleLabel>
          </HeaderContainer>
        );
      }),
    ];
  }, [
    displayFields,
    hideSimilarValues,
    id,
    isExportedLogs,
    itemsSelected,
    records,
  ]);
}

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ToggleLabel = styled.span(
  ({ theme }) => css`
    font: ${theme.font.body.xs.medium};
    display: flex;
    align-items: center;
    gap: 8px;
  `,
);
