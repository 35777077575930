import { Input } from "@fiberplane/ui";
import { type Control, useController } from "react-hook-form";
import { css, styled } from "styled-components";

import type { Webhook } from "../../../../types";
import { validateRequired, validateUrl } from "../../../../utils";
import { Label, ModalLabel } from "../../../UI";

export function EndpointField({ control }: { control: Control<Webhook> }) {
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name: "endpoint",
    rules: {
      validate: {
        validateRequired: (value) => validateRequired(value, "URL"),
        validateUrl: (value) => validateUrl(value, "provided url"),
      },
    },
  });

  return (
    <Container>
      <ModalLabel htmlFor="endpoint">
        URL
        <Input
          type="text"
          autoFocus
          id="endpoint"
          placeholder="Webhook URL"
          {...field}
        />
      </ModalLabel>

      {error !== undefined && <ErrorHint>{error.message}</ErrorHint>}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ErrorHint = styled(Label)(
  ({ theme }) => css`
    color: ${theme.colorDanger600};
    font: ${theme.fontStudioStrongExtraSmallShortHand};
    letter-spacing: ${theme.fontStudioStrongExtraSmallLetterSpacing};
  `,
);
