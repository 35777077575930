import { createSelector } from "reselect";

import type { RootState } from "../state";
import type {
  FrontMatterNumberSchema,
  FrontMatterStringSchema,
  FrontMatterUserSchema,
  FrontMatterValueSchema,
  FrontMatter as NotebookFrontMatter,
  SafeFrontMatterValue,
} from "../types";
import {
  isArrayOfType,
  isFrontMatterUser,
  isGithubPullRequest,
  isNumberAsString,
  isPagerDutyIncident,
  isString,
  isTimestamp,
} from "../utils";
import { selectNotebookFrontMatterSchema } from "./notebookSelectors";
import { selectActiveNotebook } from "./notebooksSelectors";

/**
 * This selector returns the *raw* front matter (`FrontMatter` type) of the active notebook,
 * without validating that it is in the form we expect in the UI.
 */
export const selectDangerousNotebookFrontMatter = (
  state: RootState,
): NotebookFrontMatter => selectActiveNotebook(state).frontMatter;

/**
 * Selector that decodes the front matter of the active notebook into a type safe mapping between keys and values that we can understand in the UI.
 * If you're writing UI that deals with front-matter, you should use this selector.
 */
export const selectStudioSafeFrontMatter = (state: RootState) => {
  const notebookFrontMatter = selectDangerousNotebookFrontMatter(state);
  const schema = selectNotebookFrontMatterSchema(state);

  const values: Record<string, ReturnType<typeof constrainValue>> = {};
  for (const entry of schema) {
    const rawValue = notebookFrontMatter[entry.key];

    values[entry.key] = constrainValue(rawValue, entry.schema);
  }

  return values;
};

export const makeSafeFrontMatterValueSelector = (key: string) => {
  return createSelector(
    [selectStudioSafeFrontMatter],
    (frontMatter) => frontMatter[key],
  );
};

function constrainValue(
  value: unknown,
  schema: FrontMatterValueSchema,
): SafeFrontMatterValue {
  const { type: schemaType } = schema;

  switch (schemaType) {
    case "string":
      return constrainStringValue(value, schema);

    case "number":
      return constrainNumberValue(value, schema);

    case "user":
      return constrainUserValue(value, schema);

    case "date_time":
      return isTimestamp(value) ? value : undefined;

    case "pagerduty_incident":
      return isPagerDutyIncident(value) ? value : undefined;

    case "github_pull_request":
      return isGithubPullRequest(value) ? value : undefined;
    default: {
      const exhaustiveCheck: never = schemaType;
      throw new Error(`Unhandled schema type: ${exhaustiveCheck}`);
    }
  }
}

function constrainStringValue(value: unknown, schema: FrontMatterStringSchema) {
  const { options, multiple = false } = schema;

  if (multiple && Array.isArray(value)) {
    return constrainMultipleStringValues(value, options);
  }

  const stringValue = isString(value) ? value : undefined;
  if (Array.isArray(options) && options.length > 0) {
    return isValidOption(stringValue, options) ? stringValue : undefined;
  }

  return stringValue;
}

function constrainMultipleStringValues(
  value: Array<unknown>,
  options?: Array<string>,
) {
  if (Array.isArray(options) && options.length > 0) {
    return isArrayOfType(
      value,
      (value): value is string =>
        isString(value) && isValidOption(value, options),
    )
      ? value
      : undefined;
  }

  return isArrayOfType(value, isString) ? value : undefined;
}

function constrainNumberValue(value: unknown, schema: FrontMatterNumberSchema) {
  const { options } = schema;
  const stringValue = isNumberAsString(value) ? value : undefined;
  if (Array.isArray(options) && options.length > 0) {
    return isValidOption(stringValue, options) ? stringValue : undefined;
  }

  return stringValue;
}

function constrainUserValue(value: unknown, schema: FrontMatterUserSchema) {
  const { multiple = false } = schema;
  if (multiple) {
    return isArrayOfType(value, isFrontMatterUser) ? value : undefined;
  }

  return isFrontMatterUser(value) ? value : undefined;
}

export function isValidOption<T>(
  value: unknown,
  options: Array<T>,
): value is T {
  return options.some((option) => option === value);
}
