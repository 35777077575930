import { captureException } from "@sentry/react";
import { generatePath } from "react-router";
import { push, replace } from "redux-first-history";

import {
  showError,
  startForking,
  withActiveNotebook,
  withNotebook,
} from "../actions";
import { notebooksApi } from "../api";
import { DEFAULT_NOTEBOOK_TITLE, ROUTES } from "../constants";
import { normalizeException } from "../errors";
import {
  selectActiveNotebook,
  selectActiveNotebookId,
  selectActiveView,
  selectActiveWorkspaceIdOrThrow,
  selectActiveWorkspaceNameOrThrow,
  selectCurrentUser,
  selectHasActiveNotebook,
  selectLastCell,
  selectNotebook,
  selectNotebookError,
  selectNotebookFocused,
} from "../selectors";
import type { RootState } from "../state";
import type { Thunk } from "../store";
import type { Cell, Notebook, NotebookVisibility } from "../types";
import { compact, createNotebookLink, pick, uuid64 } from "../utils";
import { subscribeToNotebook, unsubscribeFromNotebook } from "./courierThunks";
import { loadDataSources } from "./dataSourcesThunks";
import { fetchThreads } from "./discussionsThunks";
import { focusCell } from "./editorThunks";
import { addCell } from "./notebookCellThunks";
import { addNotification } from "./notificationsThunks";
import { invokeProviderCell } from "./providerThunks";

export const createNotebook =
  (): Thunk<Promise<string>> => async (dispatch, getState) => {
    const state = getState();
    const workspaceId = selectActiveWorkspaceIdOrThrow(state);

    try {
      dispatch(withActiveNotebook({ type: "start_loading", payload: "" }));

      const now = new Date();
      const oneHourAgo = new Date(now);
      oneHourAgo.setHours(oneHourAgo.getHours() - 1);

      // If there's an active view, we automatically attach the views labels to the new notebook
      const labels = selectActiveView(state)?.labels ?? [];

      const notebookResponse = await dispatch(
        notebooksApi.endpoints.createNotebook.initiate({
          workspaceId,
          newNotebook: {
            cells: [
              {
                id: uuid64(),
                type: "text",
                content: "",
                formatting: [],
              },
            ],
            frontMatter: {},
            frontMatterSchema: [],
            labels,
            selectedDataSources: {},
            timeRange: {
              from: oneHourAgo.toISOString(),
              to: now.toISOString(),
            },
            title: DEFAULT_NOTEBOOK_TITLE,
          },
        }),
      ).unwrap();

      return dispatch(loadNotebookFromResponse(notebookResponse));
    } catch (error) {
      dispatch(
        withNotebook(
          "",
          showError({
            type: "other",
            message: `Cannot create notebook: ${error}`,
          }),
        ),
      );
      throw error;
    }
  };

export const createNotebookAndRedirect =
  (options?: { useHistoryReplace?: boolean }): Thunk<Promise<string>> =>
  async (dispatch, getState) => {
    const state = getState();
    const workspaceName = selectActiveWorkspaceNameOrThrow(state);

    const id = await dispatch(createNotebook());

    const navigate = options?.useHistoryReplace ? replace : push;
    dispatch(
      navigate(createNotebookLink(workspaceName, id, DEFAULT_NOTEBOOK_TITLE)),
    );

    return id;
  };

export const createNotebookAndAddGraph =
  (query: string): Thunk<Promise<string>> =>
  async (dispatch, getState) => {
    const notebookId = await dispatch(
      createNotebookAndRedirect({ useHistoryReplace: true }),
    );

    const relatedId = selectLastCell(getState())?.id || "";
    const cellId = dispatch(
      addCell({
        relatedId,
        position: "after",
        properties: {
          type: "provider",
          intent: "prometheus,timeseries",
          queryData: `application/x-www-form-urlencoded,query=${encodeURIComponent(
            query,
          )}`,
        },
      }),
    );

    const workspaceId = selectActiveWorkspaceIdOrThrow(getState());
    await dispatch(loadDataSources(workspaceId));
    await dispatch(invokeProviderCell(cellId));

    // Delay the focus cell so we can draw the users attention
    // TODO: Do this the correct way, but if focus cell is called too early
    // scrolling doesn't work well properly
    await new Promise((resolve) => setTimeout(resolve, 300));
    dispatch(
      focusCell({
        cellId,
        highlight: true,
      }),
    );

    return notebookId;
  };

export const loadNotebookAndAddGraph =
  (notebookId: string, query: string): Thunk<Promise<void>> =>
  async (dispatch, getState) => {
    await dispatch(loadNotebookById(notebookId));

    const workspaceId = selectActiveWorkspaceIdOrThrow(getState());
    dispatch(
      replace(
        generatePath(ROUTES.Notebook, {
          workspaceName: workspaceId,
          notebookId,
        }),
      ),
    );

    const relatedId = selectLastCell(getState())?.id || "";
    const cellId = dispatch(
      addCell({
        relatedId,
        position: "after",
        properties: {
          type: "provider",
          intent: "prometheus,timeseries",
          queryData: `application/x-www-form-urlencoded,query=${encodeURIComponent(
            query,
          )}`,
        },
      }),
    );

    await dispatch(loadDataSources(workspaceId));
    await dispatch(invokeProviderCell(cellId));

    // Delay the focus cell so we can draw the users attention
    // TODO: Do this the correct way, but if focus cell is called too early
    // scrolling doesn't work well properly
    await new Promise((resolve) => setTimeout(resolve, 300));
    dispatch(
      focusCell({
        cellId,
        highlight: true,
      }),
    );
  };

/**
 * Forks the currently active notebook so that all new operations get applied
 * to the fork instead of the original notebook.
 */
export const forkActiveNotebook = (): Thunk => async (dispatch, getState) => {
  const state = getState();
  const workspaceId = selectActiveWorkspaceIdOrThrow(state);
  const workspaceName = selectActiveWorkspaceNameOrThrow(state);
  const originalNotebook = selectActiveNotebook(state);

  if (originalNotebook.forkedAt) {
    return; // Notebook is already in the process of being forked.
  }

  dispatch(
    withNotebook(originalNotebook.id, startForking(originalNotebook.revision)),
  );

  try {
    const notebookFactory = () => {
      // Make sure we reselect the state so updates across async calls
      // are picked up:
      const state = getState();
      const notebook = selectNotebook(state, originalNotebook.id);
      const user = selectCurrentUser(state);
      return {
        cells: notebook.cellIds.map(
          (cellId) => notebook.cellsById[cellId]?.cell as Cell,
        ),
        frontMatter: notebook.frontMatter,
        frontMatterSchema: notebook.frontMatterSchema,
        labels: notebook.labels,
        selectedDataSources: notebook.selectedDataSources,
        timeRange: notebook.timeRange,
        title: `Copy of ${notebook.title} by ${user?.name ?? "Anonymous"}`,
      };
    };

    const { id, createdAt, createdBy, revision, updatedAt } = await dispatch(
      notebooksApi.endpoints.forkNotebook.initiate({
        workspaceId,
        newNotebook: notebookFactory(),
      }),
    ).unwrap();
    const forkedNotebook: Notebook = {
      ...notebookFactory(),
      id,
      workspaceId,
      createdAt,
      createdBy,
      readOnly: false,
      revision,
      updatedAt,
      visibility: "private",
    };

    dispatch({
      type: "fork_notebook",
      payload: {
        notebook: forkedNotebook,
        originalNotebookId: originalNotebook.id,
      },
    });
    dispatch(
      replace(
        createNotebookLink(
          workspaceName,
          forkedNotebook.id,
          forkedNotebook.title,
        ),
      ),
    );
    dispatch(subscribeToNotebook(forkedNotebook.id));
    dispatch(addNotification({ title: "Notebook has been duplicated" }));
  } catch (error) {
    dispatch(
      withNotebook(originalNotebook.id, {
        type: "notebook_js_error",
        payload: { error: normalizeException(error) },
      }),
    );
    dispatch(
      addNotification({
        title: "Something went wrong while creating a copy of your Notebook",
        type: "danger",
      }),
    );
  }
};

export const runAllCells = (): Thunk => (dispatch, getState) => {
  const notebook = selectActiveNotebook(getState());

  const providerCellIds = Object.values(notebook.cellsById)
    .filter(({ cell }) => cell.type === "provider" && !cell.readOnly)
    .map(({ cell }) => cell.id);
  for (const cellId of providerCellIds) {
    dispatch(invokeProviderCell(cellId));
  }
};

export const loadNotebookById =
  (id: string): Thunk<Promise<void>> =>
  async (dispatch, getState) => {
    try {
      const currentNotebookId = selectActiveNotebookId(getState());
      if (currentNotebookId) {
        dispatch(unsubscribeFromNotebook(currentNotebookId));
      }

      dispatch(
        withNotebook(currentNotebookId, { type: "start_loading", payload: id }),
      );

      const notebookResponse = await dispatch(
        notebooksApi.endpoints.getNotebook.initiate({ notebookId: id }),
      ).unwrap();
      dispatch(loadNotebookFromResponse(notebookResponse));
    } catch (error) {
      if (error instanceof Error) {
        captureException(error);
      }

      dispatch(
        withNotebook(id, {
          type: "notebook_js_error",
          payload: {
            error: normalizeException(error),
          },
        }),
      );
    }
  };

const loadNotebookFromResponse =
  (notebookResponse: Uint8Array): Thunk<string> =>
  (dispatch, getState) => {
    dispatch(
      withActiveNotebook({
        type: "load_notebook",
        payload: { notebookResponse },
      }),
    );

    const state = getState();
    const error = selectNotebookError(state);
    if (error) {
      throw error instanceof Error ? error : new Error(error);
    }

    const activeNotebook = selectActiveNotebook(state);
    const notebook: Notebook = {
      ...pick(
        activeNotebook,
        "createdAt",
        "createdBy",
        "id",
        "frontMatter",
        "frontMatterSchema",
        "labels",
        "readOnly",
        "revision",
        "selectedDataSources",
        "timeRange",
        "title",
        "updatedAt",
        "visibility",
        "workspaceId",
      ),
      cells: compact(
        activeNotebook.cellIds.map((id) => activeNotebook.cellsById[id]?.cell),
      ),
    };

    dispatch(subscribeToNotebook(notebook.id));
    dispatch(fetchThreads());

    return notebook.id;
  };

export const setNotebookVisibility =
  (notebookId: string, visibility: NotebookVisibility): Thunk<Promise<void>> =>
  (dispatch, getState) => {
    const currentVisibility = selectNotebook(getState(), notebookId).visibility;
    if (visibility === currentVisibility) {
      return Promise.resolve(); // Nothing to do.
    }

    // Optimistically set the new value -- we'll revert if the call fails:
    dispatch(
      withNotebook(notebookId, { type: "set_visibility", payload: visibility }),
    );

    return dispatch(
      notebooksApi.endpoints.patchNotebook.initiate({
        notebookId,
        updateNotebook: { visibility },
      }),
    )
      .unwrap()
      .then((notebook) => {
        dispatch(
          addNotification({
            title: `Notebook visibility changed to ${visibility}`,
          }),
        );
        return notebook;
      })
      .catch((error) => {
        dispatch(
          addNotification({
            title: "Failed to update notebook visibility",
            type: "danger",
          }),
        );
        dispatch(
          withNotebook(notebookId, {
            type: "set_visibility",
            payload: currentVisibility,
          }),
        );
        throw error;
      });
  };

// Selector is defined here as it's very specific and was
// causing circular dependency issues
function selectNotebookUndoRedoSupported(state: RootState): boolean {
  return (
    // Check if a notebook is active
    selectHasActiveNotebook(state) &&
    // Check if notebook has focus
    selectNotebookFocused(state) === true
  );
}

/**
 * Returns true if a redo action is dispatched
 *
 * This is useful in case you need to cancel an event based on whether
 * we're handling redo ourselves (as opposed to relying on the browser)
 */
export const redo = (): Thunk<boolean> => (dispatch, getState) => {
  if (selectNotebookUndoRedoSupported(getState())) {
    dispatch(withActiveNotebook({ type: "redo" }));
    return true;
  }

  return false;
};

/**
 * Returns true if an undo action is dispatched
 *
 * This is useful in case you need to cancel an event based on whether
 * we're handling undo ourselves (as opposed to relying on the browser)
 */
export const undo = (): Thunk<boolean> => (dispatch, getState) => {
  if (selectNotebookUndoRedoSupported(getState())) {
    dispatch(withActiveNotebook({ type: "undo" }));
    return true;
  }

  return false;
};
