import { useMedia, useThemeSelect } from "@fiberplane/hooks";
import { useFeature } from "../../../../../hooks";
import type { Command } from "../../../../../types";

const EMPTY_COMMAND_LIST: Array<Command> = [];

export function useThemeCommands() {
  const commands: Array<Command> = [];
  const [hasThemeSwitcherEnabled] = useFeature("theme-switcher");
  const {
    currentTheme,
    isSystemPreference,
    setToSystemTheme,
    setThemePreference,
  } = useThemeSelect();
  const systemPrefersDarkTheme = useMedia("(prefers-color-scheme: dark)");

  if (!hasThemeSwitcherEnabled) {
    return EMPTY_COMMAND_LIST;
  }

  if (currentTheme === "light") {
    commands.push({
      key: "setLightTheme",
      title: "Switch to dark theme",
      onActivate: () => setThemePreference("dark"),
      type: "commands",
      icon: "moon_duotone",
    });
  } else {
    commands.push({
      key: "setDarkTheme",
      title: "Switch to light theme",
      onActivate: () => setThemePreference("light"),
      type: "commands",
      icon: "sun_duotone",
    });
  }

  if (!isSystemPreference) {
    commands.push({
      key: "setSystemTheme",
      title: "Switch to system theme",
      onActivate: () => setToSystemTheme(),
      type: "commands",
      icon: systemPrefersDarkTheme ? "moon_duotone" : "sun_duotone",
    });
  }

  return commands;
}
