import FontFaceObserver from "fontfaceobserver";
import { useEffect, useState } from "react";

/**
 * Hook that determines what font is used and sees if it is still being loaded.
 *
 * There are a couple of assumptions with the current implementation:
 * 1. styling will never change on the container element
 * 2. the first font is the one we have a font face definition for as being
 * loaded i.e. is the preferred font. If the first font isn't something we have
 * a font face it will never become "true"
 */
export function useFontLoadedState(container: HTMLElement) {
  const [fontInfo, setFontInfo] = useState(() => getFontInformation(container));

  const { loaded, preferred } = fontInfo;

  useEffect(() => {
    if (loaded) {
      return;
    }

    const observer = new FontFaceObserver(preferred);

    let updater: typeof setFontInfo | undefined = setFontInfo;

    observer.load().then(() => {
      updater?.({ loaded: true, preferred });
    });

    return () => {
      updater = undefined;
    };
  }, [preferred, loaded]);

  return loaded;
}

function getFontInformation(container: HTMLElement): {
  preferred: string;
  loaded: boolean;
} {
  const declaration = getComputedStyle(container);
  const fontFamily = declaration.fontFamily;
  const fontSize = declaration.fontSize;
  const fonts = fontFamily.split(",");
  const [preferred = "sans-serif"] = fonts;
  const used =
    fonts.find((font) => {
      return document.fonts.check(`${fontSize} ${font}`);
    }) || "default";

  return {
    preferred,
    loaded: used === preferred,
  };
}
