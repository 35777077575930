import type {
  ContextMenuInfo,
  DragInfo,
  DropTarget,
  ModalState,
  UiState,
  UserActionKind,
} from "../state";
import type { TooltipAnchor, TooltipContent, TooltipOptions } from "../types";

export const HIDE_ACTIVE_CONTEXT_MENU = "ui/hideActiveContextMenu";
export const HIDE_ACTIVE_TOOLTIP = "ui/hideActiveTooltip";
export const HIDE_ENGAGE_MENU = "ui/hideEngageMenu";
export const HIDE_MODAL = "ui/hideModal";
export const HIDE_ALL_MODALS = "ui/hideAllModals";
export const HIDE_SIDE_MENU = "ui/hideSideMenu";
export const SET_ACTIVE_CONTEXT_MENU = "ui/setActiveContextMenu";
export const SET_ACTIVE_TOOLTIP = "ui/setActiveTooltip";
export const SET_LAST_USER_ACTION = "ui/setLastUserAction";
export const SET_NOTEBOOK_FOCUSED = "ui/setNotebookFocused";
export const SET_VIEWS_DISPLAY_TYPE = "ui/setViewsDisplayType";
export const SET_WINDOW_FOCUSED = "ui/setWindowFocused";
export const SHOW_ENGAGE_MENU = "ui/showEngageMenu";
export const SHOW_MODAL = "ui/showModal";
export const SHOW_SIDE_MENU = "ui/showSideMenu";
export const START_DRAG = "ui/startDrag";
export const STOP_DRAG = "ui/stopDrag";
export const UPDATE_DROP_TARGET = "ui/updateDropTarget";
export const UPDATE_MODAL = "ui/updateModal";

export type UiAction =
  | HideActiveContextMenu
  | HideActiveTooltipAction
  | HideAllModalsAction
  | HideEngageMenuAction
  | HideModalAction
  | HideSideMenuAction
  | SetActiveContextMenu
  | SetActiveTooltipAction
  | SetLastUserAction
  | SetNotebookFocusedAction
  | SetViewsDisplayType
  | SetWindowFocusedAction
  | ShowEngageMenuAction
  | ShowModalAction
  | ShowSideMenuAction
  | StartDragAction
  | StopDragAction
  | UpdateDropTargetAction
  | UpdateModalAction;

type HideActiveContextMenu = {
  type: typeof HIDE_ACTIVE_CONTEXT_MENU;
};

type HideActiveTooltipAction = {
  type: typeof HIDE_ACTIVE_TOOLTIP;
};

type HideEngageMenuAction = {
  type: typeof HIDE_ENGAGE_MENU;
};

export type HideModalAction = {
  type: typeof HIDE_MODAL;
  payload: {
    key: string;
  };
};

export type HideAllModalsAction = {
  type: typeof HIDE_ALL_MODALS;
};

type HideSideMenuAction = {
  type: typeof HIDE_SIDE_MENU;
};

type SetActiveContextMenu = {
  type: typeof SET_ACTIVE_CONTEXT_MENU;
  payload: ContextMenuInfo;
};

type SetActiveTooltipAction = {
  type: typeof SET_ACTIVE_TOOLTIP;
  payload: {
    anchor: TooltipAnchor;
    content: TooltipContent;
    options: TooltipOptions;
  };
};

type SetLastUserAction = {
  type: typeof SET_LAST_USER_ACTION;
  payload: UserActionKind;
};

type SetNotebookFocusedAction = {
  type: typeof SET_NOTEBOOK_FOCUSED;
  payload: boolean;
};

type SetViewsDisplayType = {
  type: typeof SET_VIEWS_DISPLAY_TYPE;
  payload: UiState["viewsDisplayType"];
};

type SetWindowFocusedAction = {
  type: typeof SET_WINDOW_FOCUSED;
  payload: boolean;
};

type ShowEngageMenuAction = {
  type: typeof SHOW_ENGAGE_MENU;
};

export type ShowModalAction = {
  type: typeof SHOW_MODAL;
  payload: {
    key: string;
    modal: ModalState;
  };
};

type ShowSideMenuAction = {
  type: typeof SHOW_SIDE_MENU;
};

type StartDragAction = {
  type: typeof START_DRAG;
  payload: DragInfo;
};

type StopDragAction = {
  type: typeof STOP_DRAG;
};

type UpdateDropTargetAction = {
  type: typeof UPDATE_DROP_TARGET;
  payload: DropTarget;
};

export type UpdateModalAction = {
  type: typeof UPDATE_MODAL;
  payload: {
    key: string;
    modal: ModalState;
  };
};

export function hideActiveTooltip(): UiAction {
  return {
    type: HIDE_ACTIVE_TOOLTIP,
  };
}

export function hideContextMenu(): UiAction {
  return { type: HIDE_ACTIVE_CONTEXT_MENU };
}

export function hideEngageMenu(): UiAction {
  return {
    type: HIDE_ENGAGE_MENU,
  };
}

export function hideSideMenu(): UiAction {
  return {
    type: HIDE_SIDE_MENU,
  };
}

export function setActiveTooltip(
  tooltip: SetActiveTooltipAction["payload"],
): UiAction {
  return {
    type: SET_ACTIVE_TOOLTIP,
    payload: tooltip,
  };
}

export function setLastUserAction(userAction: UserActionKind): UiAction {
  return {
    type: SET_LAST_USER_ACTION,
    payload: userAction,
  };
}

export function setNotebookFocused(isNotebookFocused: boolean): UiAction {
  return {
    type: SET_NOTEBOOK_FOCUSED,
    payload: isNotebookFocused,
  };
}

export function setWindowFocused(isWindowFocused: boolean): UiAction {
  return {
    type: SET_WINDOW_FOCUSED,
    payload: isWindowFocused,
  };
}

export function showEngageMenu(): UiAction {
  return {
    type: SHOW_ENGAGE_MENU,
  };
}

export function showSideMenu(): UiAction {
  return {
    type: SHOW_SIDE_MENU,
  };
}

export function startDrag(dragInfo: DragInfo): UiAction {
  return { type: START_DRAG, payload: dragInfo };
}

export function stopDrag(): UiAction {
  return { type: STOP_DRAG };
}

export function updateDropTarget(dropTarget: DropTarget): UiAction {
  return { type: UPDATE_DROP_TARGET, payload: dropTarget };
}

export function updateModal(key: string, modal: ModalState): UiAction {
  return {
    type: UPDATE_MODAL,
    payload: { key, modal },
  };
}
