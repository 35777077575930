import { css, styled } from "styled-components";

import type { IconVisualType } from "../../../types";
import { getIcon } from "./icons";
import {
  getBackElementBackground,
  getFrontElementBackgroundColor,
} from "./utils";

type IconVisualProps = React.HTMLAttributes<HTMLDivElement> & {
  /**
   * Optional <Icon iconType="..." /> to override the default icon based on
   * type.
   */
  type: IconVisualType;
};

export function IconVisual({ children, type, ...attributes }: IconVisualProps) {
  const Icon = getIcon(type);

  return (
    <IconVisualWrapper {...attributes}>
      <IconVisualContainer>
        <FrontElement $type={type}>{children || <Icon />}</FrontElement>
        <BackElement $type={type} />
      </IconVisualContainer>
    </IconVisualWrapper>
  );
}

// As the icon visual contains an absolute positioned back element, we wrap the
// component in a container to ensure it is centered.
const IconVisualWrapper = styled.div`
  width: 100%;
  max-width: 96px;
  aspect-ratio: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

const IconVisualContainer = styled.div`
  position: relative;
  width: 79%;
  aspect-ratio: 1;
`;

const FrontElement = styled.div<{ $type: IconVisualType }>(
  ({ $type, theme }) => {
    const backgroundColor = getFrontElementBackgroundColor($type, theme);

    return css`
      height: 100%;
      width: 100%;
      background-color: ${backgroundColor};
      backdrop-filter: blur(10px);
      position: relative;
      border-radius: 25%;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 1px -1px 2px rgb(255 255 255 / 10%);
    `;
  },
);

const BackElement = styled.div<{ $type: IconVisualType }>(
  ({ $type, theme }) => {
    const [left, right] = getBackElementBackground($type, theme);

    return css`
      @keyframes animate {
        to {
          height: 84%;
          width: 84%;
          right: -21%;
          top: -13%;
          rotate: 15deg;
        }
      }

      position: absolute;
      top: 0;
      right: 0;
      z-index: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(220.53deg, ${left} 0.01%, ${right} 76.95%);
      border-radius: 25%;
      animation: animate 0.4s ease-in-out forwards;
    `;
  },
);
