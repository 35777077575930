import { useHandler } from "@fiberplane/hooks";
import { decode } from "@msgpack/msgpack";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useProvider } from "../../../hooks";
import { selectNotebookTimeRange } from "../../../selectors";
import type {
  DataSource,
  Provider,
  TimeRange,
  Timeseries,
} from "../../../types";
import { formatTimeRange, toQueryDataBlob, unwrap } from "../../../utils";

// NOTE - We want this memoized so that `useProvider` doesn't rerender ad infinitum
const PROVIDER_QUERY_TYPE = "timeseries";
const PROMETHEUS_PROVIDER_INTENT = {
  providerType: "prometheus",
  queryType: PROVIDER_QUERY_TYPE,
};

export function usePrometheusQuery(query: string) {
  const timeRange = useSelector(selectNotebookTimeRange);
  const promProvider = usePrometheusProvider();

  // TODO - use a proper hook for this request lifecycle management
  const [data, setData] = useState<null | Array<Timeseries>>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | Error | unknown>(null);

  const fetchData = useHandler(async () => {
    setLoading(true);
    try {
      if (promProvider.status === "ready") {
        const { provider, dataSource } = promProvider.result;
        const result = await executeQueryWithProvider(
          provider,
          dataSource,
          query,
          timeRange,
        );
        setData(result);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  });

  // TODO - Re-execute when provide dataSource changes?
  useEffect(() => {
    if (promProvider.status === "ready") {
      fetchData();
    }
    if (promProvider.status === "error") {
      setError(promProvider.result);
    }
  }, [promProvider.status, promProvider.result, fetchData]);

  return {
    data,
    loading,
    error,
  };
}

function executeQueryWithProvider(
  provider: Provider,
  dataSource: DataSource,
  query: string,
  timeRange: TimeRange,
) {
  return provider
    .invoke(
      {
        queryType: PROVIDER_QUERY_TYPE,
        queryData: toQueryDataBlob({
          query,
          // NOTE - ade's solution that imports withNotebookTimeRange is probably better
          //        than manually formatting like this
          // biome-ignore lint/style/useNamingConvention: field name is correct
          time_range: formatTimeRange(timeRange),
        }),
        config: dataSource.config,
      },
      dataSource,
    )
    .then((result) => {
      const { data: msgPackTimeseries } = unwrap(result);
      // NOTE - could also decode asynchronously with `decodeAsync`
      const timeseries = decode(msgPackTimeseries) as Array<Timeseries>;
      return timeseries;
    });
}

/**
 * Helper hook to load the Prometheus provider with currently selected data source.
 * Uses some hacky typing so that we can do type narrowing off of the `status` of the response.
 */
function usePrometheusProvider() {
  const loadProviderResult = useProvider(PROMETHEUS_PROVIDER_INTENT);
  const providerIsLoading = !loadProviderResult;
  const providerIsError = loadProviderResult instanceof Error;
  // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version
  const providerIsReady = !providerIsLoading && !providerIsError;

  // HACK - This early return with a simulated nominal type functions as a type hint for ts
  if (providerIsReady) {
    return {
      status: "ready" as const,
      result: loadProviderResult,
    };
  }

  return {
    status: providerIsError
      ? ("error" as const) // HACK - these simulated nominal type hints allow for type-narrowing on the status field
      : providerIsLoading
        ? ("loading" as const)
        : ("unknown" as const),
    result: loadProviderResult,
  };
}
