import { useMemo } from "react";
import { useSelector } from "react-redux";
import { push } from "redux-first-history";
import { createSelector } from "reselect";

import { setCommandMenuVariant } from "../../../../../actions";
import {
  useListNotebooksQuery,
  useSearchNotebooksQuery,
} from "../../../../../api";
import { DEFAULT_NOTEBOOK_TITLE } from "../../../../../constants";
import {
  selectActiveWorkspaceIdOrThrow,
  selectActiveWorkspaceName,
  selectCommandMenuLabels,
  selectCommandMenuVariantType,
} from "../../../../../selectors";
import { dispatch } from "../../../../../store";
import type { NotebookSummary, NotebooksCommand } from "../../../../../types";
import { createNotebookLink } from "../../../../../utils";

const EMPTY_NOTEBOOK_LIST: Array<NotebookSummary> = [];

export function useNotebookSearchCommands(): Array<NotebooksCommand> {
  const { commandMenuLabels, variantType, workspaceId, workspaceName } =
    useSelector(selectNotebookSearchCommandsState);

  const { data: notebooks = EMPTY_NOTEBOOK_LIST } = useListNotebooksQuery(
    { workspaceId },
    { skip: !workspaceId || commandMenuLabels.length > 0 },
  );

  const { data: searchedNotebooks = EMPTY_NOTEBOOK_LIST } =
    useSearchNotebooksQuery(
      { workspaceId, labels: commandMenuLabels },
      { skip: !workspaceId || commandMenuLabels.length === 0 },
    );

  return useMemo(() => {
    if (!workspaceName || variantType !== "search") {
      return [];
    }

    return (commandMenuLabels.length > 0 ? searchedNotebooks : notebooks).map(
      (notebook): NotebooksCommand => ({
        key: `notebooks/open/${notebook.id}`,
        type: "notebooks",
        title: notebook.title || DEFAULT_NOTEBOOK_TITLE,
        notebook,
        icon: "notebook",
        onActivate: () => {
          dispatch(
            push(
              createNotebookLink(workspaceName, notebook.id, notebook.title),
            ),
          );
          dispatch(setCommandMenuVariant({ type: "none" }));
        },
      }),
    );
  }, [
    commandMenuLabels.length,
    variantType,
    notebooks,
    searchedNotebooks,
    workspaceName,
  ]);
}

const selectNotebookSearchCommandsState = createSelector(
  [
    selectActiveWorkspaceIdOrThrow,
    selectActiveWorkspaceName,
    selectCommandMenuLabels,
    selectCommandMenuVariantType,
  ],
  (workspaceId, workspaceName, commandMenuLabels, variantType) => ({
    commandMenuLabels,
    variantType,
    workspaceId,
    workspaceName,
  }),
);
