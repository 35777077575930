import { Button } from "@fiberplane/ui";
import { useContext } from "react";
import { css, styled } from "styled-components";

import { useKeyboardHandlers } from "../../hooks";
import type { ConfirmationDialogModalProps } from "../../types";
import { IconVisual, Modal, ModalContext } from "../UI";

export function ConfirmationDialog({
  children,
  iconVisual,
  onCancel,
  onCancelLabel = "Cancel",
  onConfirm,
  onConfirmButtonStyle,
  onConfirmLabel = "Confirm",
  title,
}: ConfirmationDialogModalProps) {
  const { requestClose } = useContext(ModalContext);

  const handleOnCancel = () => {
    onCancel?.();
    requestClose();
  };

  const handleOnConfirm = () => {
    onConfirm?.();
    requestClose();
  };

  useKeyboardHandlers(({ key }) => {
    if (key === "Enter") {
      handleOnConfirm();
      return;
    }

    if (key === "Escape") {
      handleOnCancel();
    }
  });

  return (
    <ConfirmationDialogModal data-testid="confirmation-modal">
      <ConfirmationDialogContent>
        <IconVisual type={iconVisual} />

        <ConfirmationDialogContentWrapper>
          <Title>{title}</Title>
          <Paragraph>{children}</Paragraph>
        </ConfirmationDialogContentWrapper>

        <ButtonGroup>
          <StyledButton
            onClick={handleOnCancel}
            buttonStyle="secondary"
            type="button"
          >
            {onCancelLabel}
          </StyledButton>
          <StyledButton
            onClick={handleOnConfirm}
            data-testid="confirm"
            type="submit"
            buttonStyle={onConfirmButtonStyle}
          >
            {onConfirmLabel}
          </StyledButton>
        </ButtonGroup>
      </ConfirmationDialogContent>
    </ConfirmationDialogModal>
  );
}

const ConfirmationDialogModal = styled(Modal)`
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h3`
  padding: 0;
  margin: 0;
`;

const ConfirmationDialogContentWrapper = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-gap: 8px;
    width: 100%;
    text-align: center;
    padding-block: ${theme.height.xx.small} ${theme.height.x.small};
  `,
);

const Paragraph = styled.p(
  ({ theme }) => css`
    color: ${theme.color.fg.muted};
    padding: 0;
    margin: 0;
  `,
);

const ConfirmationDialogContent = styled.div`
  display: grid;
  justify-items: center;
`;

// Button group that renders the buttons either horizontally or vertically
// depending on the text length of the buttons.
const StyledButton = styled(Button)``;
const ButtonGroup = styled.div`
  display: flex;
  justify-self: stretch;
  gap: 8px;
  flex-wrap: wrap;

  ${StyledButton} {
    flex: 1;
    white-space: nowrap;
  }
`;
