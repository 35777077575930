import { type Middleware, isRejectedWithValue } from "@reduxjs/toolkit";

import { dispatch, getState } from ".";
import { selectIsAuthenticated } from "../selectors";
import { signOut } from "../thunks";

export const apiAuthMiddleware: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    switch (action.type) {
      case "api/executeQuery/rejected": {
        const isAuthenticated = selectIsAuthenticated(getState());
        const requestUnauthenticated = action.payload.originalStatus === 401;

        if (isAuthenticated && requestUnauthenticated) {
          const redirect = getRedirect(isAuthenticated);
          dispatch(signOut(redirect));

          break;
        }
      }
    }
  }

  return next(action);
};

function getRedirect(isAuthenticated: boolean) {
  // If there's already a `redirect` query param, we don't want to override it
  const urlParams = new URLSearchParams(window.location.search);
  const currentRedirect = urlParams.get("redirect");

  // If the user was already authenticated, we shoot them straight to the login
  // page, but if the user was not authenticated, we want to redirect them back
  // to the page they were trying to access
  const fallbackRedirect = isAuthenticated
    ? undefined
    : window.location.pathname;

  return currentRedirect ?? fallbackRedirect;
}
