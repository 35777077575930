import { Icon } from "@fiberplane/ui";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useSelector } from "react-redux";
import { useListTemplatesQuery } from "../../../api";
import { selectActiveWorkspaceIdOrThrow } from "../../../selectors";
import {
  type FiberFormFieldProps,
  FiberFormSelectField,
} from "../../FiberForm";

type TemplateFieldProps<
  Values extends FieldValues,
  FieldName extends FieldPath<Values>,
> = FiberFormFieldProps<Values, FieldName>;

export function TemplateField<
  Values extends FieldValues,
  FieldName extends FieldPath<Values>,
>({ control, name }: TemplateFieldProps<Values, FieldName>) {
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const { data: templates } = useListTemplatesQuery({ workspaceId });

  return (
    <FiberFormSelectField
      control={control}
      description="The template that will be used to create a new notebook upon receiving a webhook from PagerDuty"
      label="Template"
      name={name}
      options={
        templates?.map((template) => {
          return {
            children: (
              <>
                <Icon iconType="templates" />
                {template.name}
              </>
            ),
            value: template.name,
          };
        }) ?? []
      }
      placeholder="Select a template"
    />
  );
}
