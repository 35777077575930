import type { CellTypeProperties, ContentCell } from "../../types";

export function getNewCellProperties(cell: ContentCell): CellTypeProperties {
  switch (cell.type) {
    case "checkbox":
      return { type: "checkbox", level: cell.level };

    case "list_item":
      return { type: "list_item", listType: cell.listType, level: cell.level };

    default:
      return { type: "text" };
  }
}
