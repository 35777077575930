import { Button } from "@fiberplane/ui";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { css, styled } from "styled-components";

import { useFeature } from "../../../../hooks";
import {
  selectActiveWorkspaceIdOrThrow,
  selectDataSourcesList,
} from "../../../../selectors";
import { dispatch } from "../../../../store";
import { loadDataSources, showSettingsModal } from "../../../../thunks";
import type {
  ConfigureDirectAccessModalProps,
  DataSource,
} from "../../../../types";
import { DataSourceDropdownEmpty } from "./DataSourceDropdownEmpty";
import { DataSourceSelectorGroup } from "./DataSourceSelectorGroup";

type Props = {
  onConfigureDirectAccess: (params?: ConfigureDirectAccessModalProps) => void;
  onSelect: (dataSource: DataSource) => void;
};

export function DataSourceSelector({
  onConfigureDirectAccess,
  onSelect,
}: Props): JSX.Element {
  const workspaceId = useSelector(selectActiveWorkspaceIdOrThrow);
  const {
    data: dataSources,
    error,
    loading,
  } = useSelector(selectDataSourcesList);
  useEffect(() => {
    dispatch(loadDataSources(workspaceId));
  }, [workspaceId]);

  const providerTypes = useProviderTypes(dataSources);

  if (dataSources?.length === 0) {
    return (
      <DataSourceDropdownEmpty
        loading={loading}
        onConfigureDirectAccess={onConfigureDirectAccess}
      />
    );
  }

  return (
    <Container data-loading={loading}>
      <Intro>
        <IntroHeading>Select data sources</IntroHeading>
        <IntroBody>
          Manage your data sources{" "}
          <DataSourceLink
            onClick={() =>
              dispatch(showSettingsModal({ section: "data-sources" }))
            }
            buttonType="textButton"
            buttonStyle="tertiary-color"
          >
            here
          </DataSourceLink>
        </IntroBody>
      </Intro>
      {error && <StyledError>Unable to load data sources</StyledError>}
      {!loading && (
        <DataSourceTypes>
          {[...providerTypes].sort().map((providerType) => (
            <DataSourceSelectorGroup
              key={providerType}
              providerType={providerType}
              onConfigureDirectAccess={onConfigureDirectAccess}
              onSelect={onSelect}
            />
          ))}
        </DataSourceTypes>
      )}
    </Container>
  );
}

function useProviderTypes(dataSources?: Array<DataSource>): Set<string> {
  const providerTypes: Set<string> = new Set([
    "prometheus",
    "elasticsearch",
    "loki",
  ]);

  const [hasSentry] = useFeature("sentry");
  const [hasHttpsProvider] = useFeature("https-provider");
  const [hasCloudWatch] = useFeature("cloudwatch");

  if (hasSentry) {
    providerTypes.add("sentry");
  }
  if (hasHttpsProvider) {
    providerTypes.add("https");
  }
  if (hasCloudWatch) {
    providerTypes.add("cloudwatch");
  }

  if (dataSources) {
    for (const { providerType } of dataSources) {
      providerTypes.add(providerType);
    }
  }

  return providerTypes;
}

const DataSourceLink = styled(Button)`
  font: inherit;
  text-decoration: underline;
  margin-left: -4px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Intro = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 12px;
`;

const IntroHeading = styled.h4`
  margin: 0;
  padding: 0;
  font: ${({ theme }) => theme.fontStudioHeadingsH4ShortHand};
`;

const IntroBody = styled.p`
  margin: 0;
  padding: 0;
  font: ${({ theme }) => theme.fontStudioStrongSmallShortHand};
`;

const StyledError = styled.div(
  ({ theme }) => css`
    margin: auto;
    background-color: ${theme.color.bg.danger};
    color: ${theme.color.fg.danger};
    border-radius: ${theme.radius.minimal};
    padding: 4px 8px;
  `,
);

const DataSourceTypes = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;
