import styled, { css } from "styled-components";
import { MEDIUM_SIZE_QUERY } from "../../../constants";

export const KeyValueCellContentRow = styled.div(
  ({ theme }) => css`
    font: ${theme.font.body.md.medium};

    margin: 0;
    display: inline-grid;
    gap: 20px;
    align-items: start;

    @media ${MEDIUM_SIZE_QUERY} {
      grid-template-columns: 200px 1fr;
    }
  `,
);

export const KeyValueCellContentValueContainer = styled.div`
  display: grid;
  grid-template: "value remove" auto / 1fr auto;
  align-items: center;
`;

export const KeyValueCellContentValueContent = styled.div`
  grid-area: value;
`;

export const KeyValueCellContentKey = styled.div(
  ({ theme }) => css`
    color: ${theme.color.fg.muted};
    display: flex;
    grid-template-columns: 20px 1fr;
    gap: 12px;
    padding: 6px 0;
  `,
);
