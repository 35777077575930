import { useHandler } from "@fiberplane/hooks";
import { AttachedPopup } from "@fiberplane/ui";
import { memo, useEffect, useRef, useState } from "react";
import { useClickAway } from "react-use";
import styled, { css } from "styled-components";
import { MEDIUM_SIZE_QUERY } from "../../../../../constants";
import { isPagerDutyIncident } from "../../../../../utils";
import { useFocused, useFrontMatterValue } from "../../hooks";
import { PillShape } from "../shared";
import { PagerDutyMetaData } from "./PagerDutyMetaData";
import { StatusPill } from "./StatusPill";

type Props = Readonly<{
  id: string;
}>;

export const FieldPagerDutyIncident = memo(function FieldPagerDutyIncident({
  id,
}: Props) {
  const value = useFrontMatterValue(id, isPagerDutyIncident);
  const focus = useFocused(id);

  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  const [visible, setVisible] = useState(false);
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout>>();
  const popupRef = useRef<HTMLDivElement | null>(null);

  const handleClose = useHandler(() => {
    setVisible(false);
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = undefined;
    }
  });

  useClickAway(popupRef, () => {
    handleClose();
  });

  useEffect(() => {
    if (ref && focus) {
      ref.focus();
    }

    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = undefined;
    }
    if (focus) {
      timeoutIdRef.current = setTimeout(() => {
        setVisible(true);
        timeoutIdRef.current = undefined;
      }, 500);
    } else {
      handleClose();
    }
  }, [ref, focus, handleClose]);

  if (!value) {
    return null;
  }

  return (
    <>
      <Container
        ref={setRef}
        tabIndex={focus ? 0 : undefined}
        className={focus ? "active" : undefined}
        onClick={() => {
          if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
          }
          setVisible(true);
        }}
      >
        <NumberElement>#{value.number}</NumberElement>
        <span>{value.title}</span>
        <StatusPill status={value.status} />
        {value.service && (
          <div>
            <ConstrainedPill aria-label={`service: ${value.service.summary}`}>
              {value.service.summary}
            </ConstrainedPill>
          </div>
        )}
        {value.priority && (
          <ConstrainedPill aria-label={`Priority: ${value.priority.summary}`}>
            {value.priority.summary}
          </ConstrainedPill>
        )}
      </Container>
      {visible && ref && (
        <AttachedPopup element={ref} placement="bottom-start" offset={[0, 4]}>
          <div ref={popupRef}>
            <PagerDutyMetaData {...value} requestClose={handleClose} />
          </div>
        </AttachedPopup>
      )}
    </>
  );
});

/// Styles

const Container = styled.div(
  ({ theme }) => css`
  display: flex;
  gap: 8px;
  height: 100%;
  border-radius: ${theme.radius.default};
  padding: 8px;
  margin-top: -4px;
  margin-bottom: -4px;
  cursor: pointer;

  flex-direction: column;
  @media ${MEDIUM_SIZE_QUERY} {
    flex-direction: row;
    align-items: center;
  }

  &.active,
  &:hover {
    background: ${theme.color.bg.hover};
  }

  &:focus {
    outline: none;
  }
`,
);

const NumberElement = styled.span(
  ({ theme }) => css`
  color: ${theme.color.fg.muted};
`,
);

const ConstrainedPill = styled(PillShape)`
  /* Constrain width to max 25px and show ellipsis if text overflows */
  display: block;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
