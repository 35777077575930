import { css, styled } from "styled-components";

import { useCellFocus } from "../../../hooks";
import type { RichText } from "../../../types";
import { formatTableRowValueId } from "../../../utils";
import { RichTextInput } from "../RTE";
import { TableCellRowHandle } from "./TableCellRowHandle";
import { headingRowMarker, rowHandleMarker } from "./constants";
import { useFieldIsSelected } from "./hooks";
import { focusField } from "./utils";

type Props = {
  cellId: string;
  columnId: string;
  first?: boolean;
  hoveredColumnId: string | undefined;
  hoveredRowId: string | undefined;
  index: number;
  onHover: (field: string) => void;
  readOnly: boolean;
  rowId: string;
  value: RichText;
};

export function TableCellField({
  cellId,
  columnId,
  first = false,
  hoveredColumnId,
  hoveredRowId,
  index,
  onHover,
  readOnly,
  rowId,
  value,
}: Props): JSX.Element {
  const field = formatTableRowValueId(rowId, columnId);
  const focus = useCellFocus(cellId, field);
  const isSelected = useFieldIsSelected(cellId, field);

  const hasFocus = focus.type !== "none";
  const hovered =
    (hoveredColumnId === columnId || hoveredColumnId === rowHandleMarker) &&
    (hoveredRowId === rowId || hoveredRowId === headingRowMarker);
  const isFirstColumn = index === 0;

  return (
    <StyledTableCellField
      firstColumn={isFirstColumn}
      firstRow={first}
      focused={hasFocus}
      hovered={hovered}
      onClick={() => focusField(cellId, field, { offset: 0 })}
      onMouseEnter={() => onHover(field)}
      selected={isSelected}
    >
      {hoveredRowId === rowId && isFirstColumn && (
        <TableCellRowHandle cellId={cellId} onHover={onHover} rowId={rowId} />
      )}
      <RichTextInput
        cellId={cellId}
        field={field}
        focus={focus}
        formatting={value.formatting}
        readOnly={readOnly}
        value={value.text}
      />
    </StyledTableCellField>
  );
}

const StyledTableCellField = styled.div<{
  firstColumn: boolean;
  firstRow: boolean;
  focused: boolean;
  hovered: boolean;
  selected: boolean;
}>`
  grid-column: auto / span 1;
  display: flex;
  cursor: text;
  align-content: center;
  position: relative;
  margin: 0;

  ${({ firstColumn, firstRow, focused, theme }) => {
    if (focused) {
      return css`
        left: ${firstColumn ? "-1px" : 0};
        top: ${firstRow ? "-1px" : 0};
        width: ${firstColumn ? "calc(100% + 2px)" : "calc(100% + 1px)"};
        height: ${firstRow ? "calc(100% + 2px)" : "calc(100% + 1px)"};
        padding: 7px 13px 5px 15px;
        border: 2px solid ${theme.colorPrimary400};
        z-index: 1;
      `;
    }

    return css`
      left: 0;
      top: 0;
      width: ${firstColumn ? "100%" : "calc(100% - 1px)"};
      height: ${firstRow ? "100%" : "calc(100% - 1px)"};
      padding: 8px 16px;
      ${firstColumn ? "" : `border-left: 1px solid ${theme.colorBase300};`}
      ${firstRow ? "" : `border-top: 1px solid ${theme.colorBase300};`}
    `;
  }}

  ${({ hovered, selected, theme }) =>
    (selected || hovered) &&
    css`background-color: ${
      selected ? theme.colorPrimary100 : theme.colorPrimary50
    };`}
`;
