import { css, styled } from "styled-components";

import type { MenuItemProps } from "./MenuItem";

export const MenuItemContainer = styled.div<{
  isSelected: boolean;
  disabled?: boolean;
  textStyle?: MenuItemProps["textStyle"];
}>(
  ({ isSelected, disabled, textStyle, theme }) => css`
    cursor: pointer;
    display: flex;
    width: 100%;
    padding: 6px 12px;
    gap: 12px;
    font: ${theme.font.body.md.medium};
    /* Stylelint trips over "danger" :( */
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    color: ${
      textStyle === "danger" ? theme.color.fg.danger : theme.color.fg.default
    };
    border-radius: ${theme.radius.minimal};
    align-items: center;

    &:focus {
      outline: none;
    }

    ${
      isSelected &&
      !disabled &&
      css`
        background-color: ${theme.color.bg.elevated.hover};

        &:active {
          background-color: ${theme.color.bg.emphasis.neutral};
          color: ${theme.color.fg.onemphasis.default};
        }
      `
    }

    ${
      disabled &&
      css`
        pointer-events: none;
        color: ${theme.color.fg.muted};
      `
    }
  `,
);
