const NAME_SPLIT_CHARACTER = "-";

/**
 * Formats a resource's displayName into a valid name
 */
export const formatName = (displayName: string) => {
  let name = displayName
    .replace(/'/g, "")
    .replace(/\W/g, NAME_SPLIT_CHARACTER)
    .replace(/_/g, NAME_SPLIT_CHARACTER)
    .replace(/-{2,}/g, NAME_SPLIT_CHARACTER)
    .toLowerCase();

  if (name.charAt(0) === NAME_SPLIT_CHARACTER) {
    name = name.slice(1);
  }

  if (name.charAt(name.length - 1) === NAME_SPLIT_CHARACTER) {
    name = name.slice(0, -1);
  }

  return name;
};
