import { UnauthenticatedError } from "../../errors";

const sessionExpiredListeners: Array<() => void> = [];

let token = "";

export function createAuthHeaders(): Record<string, string> {
  // biome-ignore lint/style/useNamingConvention: header name is correct
  return token ? { Authorization: `Bearer ${token}` } : {};
}

export function addSessionExpiredListener(listener: () => void) {
  sessionExpiredListeners.push(listener);
}

export function informSessionExpiredListeners(error: Error) {
  if (token && error instanceof UnauthenticatedError) {
    for (const listener of sessionExpiredListeners) {
      listener();
    }
  }
}

/**
 * Sets authentication for the Store service using the given token.
 */
export function authenticate(token_: string) {
  token = token_;
}

/**
 * Clear the Store service's authentication token.
 */
export function signOut() {
  token = "";
}
