import type {
  ConsoleMessage,
  Error as FiberplaneError,
  LocationInfo,
  NotebookAction,
} from "../types";

export const clearAllMessages = (): NotebookAction => ({
  type: "clear_console_messages",
});

export const expandConsole = (): NotebookAction => ({
  type: "expand_console",
});

export const setHeight = (height: number): NotebookAction => ({
  type: "set_console_height",
  payload: height,
});

export const showError = (error: FiberplaneError, location?: LocationInfo) =>
  showMessage({ severity: "error", error, location });

export const showMessage = (message: ConsoleMessage): NotebookAction => ({
  type: "show_console_message",
  payload: message,
});

export const showNotice = (message: string) =>
  showMessage({ severity: "notice", message });

export const showWarning = (message: string) =>
  showMessage({ severity: "warning", message });

export const toggleExpanded = (): NotebookAction => ({
  type: "toggle_console_expanded",
});
