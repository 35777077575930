import { useMemo } from "react";
import { useSelector } from "react-redux";
import useAsync from "react-use/lib/useAsync";

import { makeProviderCellDataSelector } from "../selectors";
import { dispatch } from "../store";
import { getProviderForIntent } from "../thunks";
import type { Blob } from "../types";
import {
  decodeBlob,
  isOk,
  matchesMimeTypeWithEncoding,
  parseIntent,
} from "../utils";

/**
 * Returns all the data referenced by the given links that conforms to the given
 * MIME types, parsing it using the given `parseBlob()` function.
 */
export function useProviderData<T>(
  providerCellId: string | null,
  links: Array<string>,
  mimeType: string,
  parseBlob: (blob: Blob) => T,
): Array<T | null> {
  const allCellData = useAllCellProviderData(
    providerCellId,
    links,
    mimeType,
    parseBlob,
  );

  return useMemo(() => {
    const { dataLinks, linkOrder } = splitLinksByType(links, mimeType);
    const linkData = dataLinks.map((url) => parseDataUrl(url, parseBlob));

    return linkOrder.map(([type, index]) => {
      switch (type) {
        case "cell":
          return Array.isArray(allCellData) ? allCellData[index] ?? null : null;

        case "link":
          return linkData[index] ?? null;

        default:
          return null;
      }
    });
  }, [allCellData, links, mimeType, parseBlob]);
}

/**
 * Returns all the data referenced by the given links that conforms to the given
 * MIME types, within a provider cell,
 * parsing it using the given `parseBlob()` function.
 *
 * Returns `undefined` as long as the provider "data extraction" is necessary but not over;
 * returns an `Error` if something goes wrong.
 */
export function useAllCellProviderData<T>(
  providerCellId: string | null,
  links: Array<string>,
  mimeType: string,
  parseBlob: (blob: Blob) => T,
): Array<T | null> | Error | undefined {
  const selectProviderData = useMemo(
    () => makeProviderCellDataSelector(providerCellId, links, mimeType),
    [providerCellId, links, mimeType],
  );
  const relevantCells = useSelector(selectProviderData);

  const { value, error } = useAsync(() => {
    return Promise.all(
      relevantCells.map(async (cellData) => {
        // Ignore non-provider cells, and cells without response data
        // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version (which is less readable)
        if (!cellData || !cellData.response) {
          return null;
        }

        // Allow {mimeType}+json or {mimeType}+msgpack
        if (
          matchesMimeTypeWithEncoding(cellData.response?.mimeType, mimeType)
        ) {
          return parseBlob(decodeBlob(cellData.response));
        }

        // Here, we test blob extraction for each serialization format in order
        const intent = parseIntent(cellData.intent);
        const { dataSource, provider } = await dispatch(
          getProviderForIntent(intent),
        );
        const blobResult = await provider.extractData(
          decodeBlob(cellData.response),
          mimeType,
          null,
          dataSource,
        );
        if (isOk(blobResult)) {
          return parseBlob(blobResult.Ok);
        }

        // No valid extraction has been found for that data,
        // and it didn't already have the correct mime type
        return null;
      }),
    );
  }, [relevantCells, mimeType, parseBlob]);

  return error || value;
}

/**
 * Returns `true` if the given URL is a `cell-data:` URL suitable for the given
 * MIME types.
 *
 * Returns `false` otherwise.
 */
function isCellLinkForMimeType(url: string, mimeType: string): boolean {
  if (!url.startsWith("cell-data:")) {
    return false;
  }

  const commaIndex = url.indexOf(",", 10);
  if (commaIndex === -1) {
    console.warn(`Invalid cell-data URL: ${url}`);
    return false;
  }

  return url.slice(10, commaIndex) === mimeType;
}

/**
 * Returns `true` if the given URL is a `data:` URL suitable for the given
 * MIME type.
 *
 * Returns `false` otherwise.
 */
function isDataLinkForMimeType(url: string, mimeType: string): boolean {
  if (!url.startsWith("data:")) {
    return false;
  }

  const commaIndex = url.indexOf(",", 5);
  if (commaIndex === -1) {
    console.warn(`Invalid data URL: ${url}`);
    return false;
  }

  let urlMimeType = url.slice(5, commaIndex);
  if (urlMimeType.endsWith(";base64")) {
    urlMimeType = urlMimeType.slice(0, -7);
  }

  return matchesMimeTypeWithEncoding(urlMimeType, mimeType);
}

/**
 * Extracts the data segment of a `data:` URL and invokes `parseBlob()` on it.
 *
 * Throws if the `data:` URL is invalid or the data segment cannot be parsed.
 */
function parseDataUrl<T>(url: string, parseBlob: (blob: Blob) => T): T {
  if (!url.startsWith("data:")) {
    throw new TypeError(`Expected data URL, received: ${url}`);
  }

  const commaIndex = url.indexOf(",", 5);
  if (commaIndex === -1) {
    throw new TypeError(`Invalid data URL, received: ${url}`);
  }

  const data = url.slice(commaIndex + 1);
  const mimeType = url.slice(5, commaIndex);

  if (mimeType.endsWith(";base64")) {
    return parseBlob(decodeBlob({ data, mimeType: mimeType.slice(0, -7) }));
  }

  if (mimeType.endsWith("+json")) {
    return parseBlob({ data: new TextEncoder().encode(data), mimeType });
  }

  throw new Error(`Could not parse data URL: ${url}`);
}

type LinkOrder = Array<[LinkType, number]>;

type LinkType = "cell" | "link" | "null";

/**
 * Divide the given links into two sets:
 * - A list of applicable links to (provider) cells.
 * - A list of applicable links with embedded data segments.
 */
function splitLinksByType(links: Array<string>, mimeType: string) {
  const cellLinks = [];
  const dataLinks = [];

  // We keep track of the link order to be able to return the result array
  // in the same order as the input links.
  const linkOrder: LinkOrder = [];

  for (const url of links) {
    if (isCellLinkForMimeType(url, mimeType)) {
      linkOrder.push(["cell", cellLinks.length]);
      cellLinks.push(url);
    } else if (isDataLinkForMimeType(url, mimeType)) {
      linkOrder.push(["link", cellLinks.length]);
      dataLinks.push(url);
    } else {
      console.warn(`Ignoring non-matching URL from dataLinks: ${url}`);
      linkOrder.push(["null", 0]);
    }
  }

  return { cellLinks, dataLinks, linkOrder };
}
