import {
  addCellAtIndex,
  replaceText,
  withActiveNotebook,
  withNotebook,
} from "../actions";
import { CELLS_MIME_TYPE } from "../constants";
import { isFiberplaneError } from "../errors";
import {
  selectActiveNotebook,
  selectCell,
  selectCellIds,
  selectCellIndex,
  selectNotebookTimeRange,
} from "../selectors";
import type { RootState } from "../state";
import type { Thunk } from "../store";
import type {
  Blob,
  Cell,
  NotebookFocus,
  ProviderCell,
  ProviderRequest,
  QuerySchema,
} from "../types";
import {
  charCount,
  decodeBlob,
  formatTimeRange,
  getQueryField,
  getTimeRangeFieldName,
  matchesMimeTypeWithEncoding,
  omit,
  parseBlob,
  parseIntent,
  setQueryField,
  stringifyIntent,
  unwrap,
  uuid64,
} from "../utils";
import { getProviderForIntent } from "./dataSourcesThunks";

function getFocusForNewProviderCell(
  cell: ProviderCell,
  schema: QuerySchema,
): NotebookFocus {
  const firstField = schema[0];
  return {
    type: "collapsed",
    cellId: cell.id,
    field: firstField?.name,
    offset:
      firstField?.type === "text"
        ? charCount(getQueryField(cell.queryData, firstField.name))
        : undefined,
  };
}

/**
 * Invokes the Provider for a `ProviderCell`.
 */
export const invokeProviderCell =
  (cellId: string): Thunk<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const cell = selectCell(state, cellId);
    if (cell?.type !== "provider") {
      throw new Error("Cell not found or has incorrect type");
    }

    // We need to be careful to use `withNotebook()` rather than
    // `withActiveNotebook()` in this thunk, because the active notebook might
    // change during an `await`, which would otherwise result in dispatching
    // results to the wrong notebook.
    const notebook = selectActiveNotebook(state);

    try {
      const intent = parseIntent(cell.intent);
      const { queryType } = intent;

      dispatch(
        withNotebook(notebook.id, {
          type: "fetch_cell_pending",
          payload: { cellIds: [cellId] },
        }),
      );

      const { dataSource, provider, schema } = await dispatch(
        getProviderForIntent(intent),
      );

      // TODO FP-1896: Validate query data.
      const request: ProviderRequest = {
        queryType,
        queryData: fromCellQueryData(
          withNotebookTimeRange(state, schema, cell.queryData ?? ""),
        ),
        config: dataSource.config,
        previousResponse: cell.response ? decodeBlob(cell.response) : undefined,
      };

      const result = await provider.invoke(request, dataSource);
      const response = unwrap(result);
      const cells =
        getCellsFromResponse(response) ??
        (await provider.createCells(queryType, response, dataSource));

      dispatch(
        withNotebook(notebook.id, {
          type: "provider_response",
          payload: {
            cellId,
            output: cells.map(prefixOutputCellId(cellId)),
            response: isCellsMimeType(response.mimeType) ? undefined : response,
          },
        }),
      );
    } catch (error: unknown) {
      if (isFiberplaneError(error)) {
        dispatch(
          withNotebook(notebook.id, {
            type: "provider_error",
            // TODO: JF make this a proper provider error
            payload: {
              cellId,
              error,
            },
          }),
        );
      } else {
        dispatch(
          withNotebook(notebook.id, {
            type: "provider_error",
            // TODO: JF make this a proper provider error
            payload: {
              cellId,
              error: {
                type: "other",
                message: "An unexpected error occurred",
              },
            },
          }),
        );
      }
    }
  };

/**
 * Returns the cells in the response, if the response contains one of the
 * recognized
 */
function getCellsFromResponse(response: Blob): Array<Cell> | undefined {
  if (!isCellsMimeType(response.mimeType)) {
    return;
  }

  // TODO: Validation
  return parseBlob(response) as Array<Cell>;
}

function fromCellQueryData(queryData?: string): Blob {
  if (!queryData) {
    return {
      mimeType: "application/x-www-form-urlencoded",
      data: new Uint8Array(),
    };
  }

  const commaIndex = queryData.indexOf(",");
  if (commaIndex === -1) {
    throw new Error("Invalid query data");
  }

  return {
    mimeType: queryData.slice(0, commaIndex),
    data: new TextEncoder().encode(queryData.slice(commaIndex + 1)),
  };
}

function isCellsMimeType(mimeType: string): boolean {
  return matchesMimeTypeWithEncoding(mimeType, CELLS_MIME_TYPE);
}

/**
 * Maps a cell created by a provider to an output cell to be stored in the
 * provider cell.
 */
function prefixOutputCellId(providerCellId: string) {
  return (cell: Cell) => ({ ...cell, id: `${providerCellId}/${cell.id}` });
}

type OpenProviderLinkOptions = {
  /**
   * ID of the cell from which the link was opened.
   */
  cellId?: string;
};

/**
 * Handles the opening of a `provider:` link.
 */
export const openProviderLink =
  (url: string, options?: OpenProviderLinkOptions): Thunk =>
  async (dispatch, getState) => {
    if (!url.startsWith("provider:")) {
      throw new Error("Provider link must start with `provider:`");
    }

    const intent = parseIntent(url.slice(9));

    const cell: Cell = {
      type: "provider",
      id: uuid64(),
      intent: stringifyIntent(omit(intent, "queryData")),
      output: [],
      queryData: intent.queryData,
    };

    const { schema } = await dispatch(getProviderForIntent(intent));

    const state = getState();
    const index = options?.cellId
      ? // Insert the new cell after the one from which the link was opened:
        selectCellIndex(state, options.cellId) + 1
      : // Simply append the new cell:
        selectCellIds(state).length;

    dispatch(
      withActiveNotebook(
        addCellAtIndex({
          cell,
          focus: getFocusForNewProviderCell(cell, schema),
          index,
        }),
      ),
    );

    // TODO FP-1896: Validate query data?
    dispatch(invokeProviderCell(cell.id));
  };

type UpdateQueryDataOptions = {
  /**
   * If `true`, will automatically trigger a re-invocation of the provider,
   * but only if all of the schema's required fields have a value.
   */
  autoSubmit?: boolean;
};

/**
 * Updates a single field in a cell's query data.
 *
 * @param cellId ID of the Provider cell.
 * @param fieldName Name of the field in the query data.
 * @param value The new value. Use `null` to remove a value from the query data.
 * @param options Additional options.
 */
export const updateQueryField =
  (
    cellId: string,
    fieldName: string,
    value: string | null,
    options?: UpdateQueryDataOptions,
  ): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const cell = selectCell(state, cellId);
    if (cell?.type !== "provider") {
      throw new Error("Cell not found or has incorrect type");
    }

    dispatch(
      withActiveNotebook(
        replaceText({
          cellId,
          field: fieldName,
          offset: 0,
          newText: value ?? "",
          oldText: getQueryField(cell.queryData, fieldName),
        }),
      ),
    );

    if (options?.autoSubmit) {
      // TODO FP-1896: Validate query data?
      dispatch(invokeProviderCell(cellId));
    }
  };

/**
 * Injects the notebook time range into the query data, if the schema specifies
 * there should be a `time_range` field, but the query data has none.
 */
function withNotebookTimeRange(
  state: RootState,
  schema: QuerySchema,
  queryData: string,
): string {
  const timeRangeFieldName = getTimeRangeFieldName(schema);
  if (timeRangeFieldName && !getQueryField(queryData, timeRangeFieldName)) {
    return setQueryField(
      queryData,
      timeRangeFieldName,
      formatTimeRange(selectNotebookTimeRange(state)),
    );
  }

  return queryData;
}
