import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  selectActiveWorkspaceName,
  selectCommandMenuVariantType,
} from "../../../../../selectors";
import { dispatch } from "../../../../../store";
import { showSettingsModal } from "../../../../../thunks";
import type { Command } from "../../../../../types";

export function useSettingsCommands(): Array<Command> {
  const { variantType, workspaceName } = useSelector(
    selectSettingsCommandsState,
  );

  return useMemo(() => {
    if (variantType === "none") {
      const commands: Array<Command> = [
        {
          key: "profile",
          type: "settings",
          title: "Open Profile",
          keywords: ["visit"],
          description: "Manage your profile",
          icon: "user_circle",
          onActivate: () => {
            dispatch(showSettingsModal({ section: "profile" }));
          },
        },
      ];

      if (workspaceName) {
        commands.push(
          {
            type: "settings",
            key: "workspace/settings",
            title: "Open Settings",
            keywords: ["visit"],
            icon: "settings_duotone",
            description: "Update your workspace name and logo",
            onActivate: () =>
              dispatch(showSettingsModal({ section: "workspace" })),
          },
          {
            type: "settings",
            key: "workspace/members",
            title: "Open Members",
            keywords: ["users", "visit"],
            icon: "users_three",
            description: "List members in your workspace and manage user roles",
            onActivate: () =>
              dispatch(showSettingsModal({ section: "members" })),
          },
          {
            type: "settings",
            key: "workspaces/data-sources",
            title: "Open Datasources",
            description: "Manage your workspace data sources",
            keywords: [
              "prometheus",
              "elasticsearch",
              "loki",
              "sentry",
              "visit",
            ],
            icon: "source",
            onActivate: () =>
              dispatch(showSettingsModal({ section: "data-sources" })),
          },
          {
            type: "settings",
            key: "workspaces/fpd",
            description: "Manage your workspace FPD instances",
            title: "Open FPD",
            keywords: ["proxy", "visit"],
            icon: "proxy",
            onActivate: () => dispatch(showSettingsModal({ section: "fpd" })),
          },
          {
            type: "settings",
            key: "workspaces/webhooks",
            title: "Open Webhooks",
            keywords: ["visit"],
            description: "Manage your workspace Webhooks",
            icon: "webhook",
            onActivate: () =>
              dispatch(showSettingsModal({ section: "webhooks" })),
          },
          {
            type: "settings",
            key: "personalIntegrations",
            title: "Go to Personal Integrations",
            keywords: ["visit"],
            description: "Manage your personal integrations",
            icon: "plugs",
            onActivate: () =>
              dispatch(showSettingsModal({ section: "personalIntegrations" })),
          },
          {
            type: "settings",
            key: "workspaceIntegrations",
            title: "Go to Workspace Integrations",
            keywords: ["visit"],
            description: "Manage your workspace integrations",
            icon: "plugs",
            onActivate: () =>
              dispatch(showSettingsModal({ section: "workspaceIntegrations" })),
          },
        );
      }

      return commands;
    }

    return [];
  }, [variantType, workspaceName]);
}
const selectSettingsCommandsState = createSelector(
  [selectActiveWorkspaceName, selectCommandMenuVariantType],
  (workspaceName, variantType) => ({
    variantType,
    workspaceName,
  }),
);
