import { Icon } from "@fiberplane/ui";
import { useContext, useEffect, useRef, useState } from "react";
import useMedia from "react-use/lib/useMedia";

import {
  AttachedPopup,
  type AttachedPopupProps,
  type OffsetsFunction,
} from "@fiberplane/ui";
import { MenuContainer } from "./MenuContainer";
import { MenuContext } from "./MenuContext";
import { MenuItem, type MenuItemProps } from "./MenuItem";

export type MenuItemWithPopupProps = Omit<MenuItemProps, "onActivate"> & {
  children: JSX.Element;
  grabsFocus?: boolean;
} & Pick<AttachedPopupProps, "offset">;

export function MenuItemWithPopup(props: MenuItemWithPopupProps) {
  const {
    children,
    offset = offsetFunction,
    grabsFocus = true,
    ...menuItemProps
  } = props;

  const anchorRef = useRef<HTMLDivElement>(null);
  const { selection, setSelection } = useContext(MenuContext);
  const { itemId, autoOpen } = selection;
  const isSelected = itemId === menuItemProps.id;
  const [contentShown, setContentShown] = useState(false);

  const isWide = useMedia("(min-width: 768px)");

  useEffect(() => {
    if (isSelected === contentShown) {
      return;
    }

    setContentShown(!!autoOpen && isSelected);
  }, [autoOpen, isSelected, contentShown]);

  return (
    <div
      onBlur={() => {
        if (contentShown && isSelected) {
          setSelection({
            itemId: menuItemProps.id,
            autoOpen: false,
            selectedBy: "mouse",
            grabsFocus,
          });
        }
      }}
    >
      <MenuItem
        {...menuItemProps}
        ref={anchorRef}
        disableCloseOnActivate
        onActivate={() => {
          setSelection({
            itemId: menuItemProps.id,
            selectedBy: "mouse",
            autoOpen: true,
            grabsFocus,
          });
        }}
        iconRight={{ icon: <Icon iconType="triangle_right" /> }}
      />
      {contentShown && (
        <AttachedPopup
          element={anchorRef.current}
          placement={isWide ? "right-start" : "bottom"}
          offset={offset}
        >
          <MenuContainer>{children}</MenuContainer>
        </AttachedPopup>
      )}
    </div>
  );
}

const offsetFunction: OffsetsFunction = ({ placement }) => {
  // If it's not the default (right-start) placement
  // return an offset that moves the menu down a bit
  if (placement !== "right-start") {
    return [0, 0];
  }

  // Otherwise move the menu up a bit
  return [-14, 0];
};
