import type { Timestamp } from "@fiberplane/prometheus-query";

import type {
  Cell,
  FrontMatterGitHubPullRequest,
  FrontMatterPagerDutyIncident,
  HeadingCell,
  ListItemCell,
  LogCell,
  TableColumnDefinition,
  TableRow,
} from ".";
import { isSurrogateId } from "../constants";
import type { User } from "../types";

export type CellType = Cell["type"];

export type CellTypeProperties =
  | { type: "checkbox"; checked?: boolean; level?: number }
  | { type: "code" }
  | { type: "divider" }
  | { type: "discussion"; threadId: string }
  | { type: "heading"; headingType: HeadingCell["headingType"] }
  | { type: "image" }
  | {
      type: "list_item";
      listType: ListItemCell["listType"];
      level?: number;
      startNumber?: number;
    }
  | { type: "provider"; intent: string; queryData?: string }
  | { type: "log"; displayFields?: LogCell["displayFields"] }
  | {
      type: "table";
      columnDefs: Array<TableColumnDefinition>;
      rows: Array<TableRow>;
    }
  | { type: "text" }
  | { type: "timeline" };

/**
 * Content cell types.
 */
const contentCellTypes = [
  "checkbox",
  "code",
  "heading",
  "list_item",
  "text",
] as const;

export type ContentCellType = (typeof contentCellTypes)[number];

export type ContentCell = Extract<Cell, { type: ContentCellType }>;

export function isContentCell(cell: Cell): cell is ContentCell {
  return isContentCellType(cell.type);
}

export function isContentCellType(
  cellType: string,
): cellType is ContentCellType {
  return contentCellTypes.includes(
    cellType as (typeof contentCellTypes)[number],
  );
}

/**
 * Cell types with custom fields that may have text.
 */
const cellWithTextFieldTypes = ["discussion", "provider", "table"] as const;

export type CellTypeWithTextField = (typeof cellWithTextFieldTypes)[number];

export type CellWithTextField = Extract<Cell, { type: CellTypeWithTextField }>;

export function hasTextField(cell: Cell): cell is CellWithTextField {
  return isCellTypeWithTextField(cell.type);
}

export function isCellTypeWithTextField(
  cellType: string,
): cellType is CellTypeWithTextField {
  return cellWithTextFieldTypes.includes(
    cellType as (typeof cellWithTextFieldTypes)[number],
  );
}

/**
 * Cell types that have a `formatting` field.
 */
const CELL_TYPES_WITH_FORMATTING_FIELD = [
  "checkbox",
  "heading",
  "list_item",
  "text",
] as const;

export type FormattedCellType =
  (typeof CELL_TYPES_WITH_FORMATTING_FIELD)[number];

export type CellWithFormatting = Cell & { type: FormattedCellType };

export function hasFormatting(cell: Cell): cell is CellWithFormatting {
  return isCellWithFormattingField(cell.type) && !isSurrogateId(cell.id);
}

export function isCellWithFormattingField(
  type: string,
): type is FormattedCellType {
  return CELL_TYPES_WITH_FORMATTING_FIELD.includes(type as FormattedCellType);
}

/**
 * Cell types that may embed entities (mentions, timestamps) in their
 * formatting.
 */
const CELL_TYPES_WITH_ENTITY_FORMATTING = [
  "checkbox",
  "discussion",
  "list_item",
  "table",
  "text",
] as const;

export type EntityFormattingCellType =
  (typeof CELL_TYPES_WITH_ENTITY_FORMATTING)[number];

export type CellWithEntityFormatting = Cell & {
  type: EntityFormattingCellType;
};

export function supportsEntityFormatting(
  cell: Cell,
): cell is CellWithEntityFormatting {
  return (
    CELL_TYPES_WITH_ENTITY_FORMATTING.includes(
      cell.type as EntityFormattingCellType,
    ) && !isSurrogateId(cell.id)
  );
}

export type FrontMatterUser = Pick<User, "id" | "name">;

export type SafeFrontMatterValue =
  | string
  | number
  | FrontMatterUser
  | Array<FrontMatterUser>
  | Array<string>
  | Timestamp
  | FrontMatterPagerDutyIncident
  | FrontMatterGitHubPullRequest
  | undefined;
