import Fuse, { type IFuseOptions, type FuseResult } from "fuse.js";
import { useEffect, useRef, useState } from "react";

export interface UseFuseOptions<T> extends IFuseOptions<T> {
  /*
   * Determines if all items should be returned when no or an empty query is passed.
   */
  shouldFilter?: boolean;
}

export function useFuse<T>(
  items: Array<T>,
  query?: string,
  { shouldFilter, ...options }: UseFuseOptions<T> = {},
) {
  const fuse = useRef(new Fuse(items, options));
  useEffect(() => fuse.current.setCollection(items), [items]);

  const [results, setResults] = useState<Array<FuseResult<T>>>([]);

  // Remove the # when filtering labels
  const strippedQuery = query?.startsWith("#") ? query.slice(1) : query;

  useEffect(() => {
    if (strippedQuery) {
      setResults(fuse.current.search(strippedQuery));
    } else if (shouldFilter) {
      // Fuse.js doesn't return any results if no query has been passed.
      // This makes sense if you're searching, but if you're filtering items,
      // having no query usually means you want all results.
      //
      // See:
      // https://github.com/krisk/Fuse/issues/229

      setResults(
        items.map((item, refIndex) => ({
          item,
          refIndex,
        })),
      );
    }
  }, [strippedQuery, items, shouldFilter]);

  return results;
}
