import { Icon, type IconType } from "@fiberplane/ui";
import React from "react";
import { css, styled, useTheme } from "styled-components";

import { IconVisual, PlasmaBackground } from "../UI";
import FoldIcon from "./icons/Fold.svg";

export function FeatureList() {
  const { colorBackground } = useTheme();

  return (
    <FeatureListWrapper>
      <PlasmaBackground />

      <ContentWrapper>
        <FeatureListTitle>
          Collaborative Notebooks for DevOps and SREs
        </FeatureListTitle>

        <FeatureListContainer>
          {FEATURE_LIST_CONTENT.map(({ iconType, title, description }) => (
            <React.Fragment key={title}>
              <FeatureListItem>
                <ListItemIconVisual type="default">
                  <Icon iconType={iconType} color={colorBackground} />
                </ListItemIconVisual>

                <ListItemTitle>{title}</ListItemTitle>
                <ListItemDescription>{description}</ListItemDescription>
              </FeatureListItem>
            </React.Fragment>
          ))}

          <FoldIconVisual />
        </FeatureListContainer>
      </ContentWrapper>
    </FeatureListWrapper>
  );
}

const FeatureListWrapper = styled.div`
  display: grid;
  place-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 40px 0 0;
  padding: 0 16px 120px;
  overflow-y: scroll;

  @media (min-width: 1024px) {
    padding: 0 40px 120px;
  }
`;

const ContentWrapper = styled.div`
  max-width: 492px;
`;

const FeatureListTitle = styled.h1(
  ({ theme }) => css`
    position: relative;
    z-index: 1;
    margin: 0;
    padding: 60px 0;
    text-align: center;
    font: ${theme.fontStudioHeadingsH1ShortHand};
    font-weight: ${theme.fontStudioButtonsButtonCopyRegularFontWeight};
    letter-spacing: ${theme.fontStudioHeadingsH1LetterSpacing};
    color: ${theme.colorBackground};

    @media (min-width: 1024px) {
      /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
      font-size: 40px;
    }
  `,
);

const FoldIconVisual = styled(FoldIcon)`
  position: absolute;
  top: -20px;
  right: -24px;
`;

const FeatureListContainer = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  z-index: 1;
  margin: 0;
  padding: 50px 40px 60px;
  list-style: none;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colorBackground};
  box-shadow: 0 4px 34px rgba(0, 0, 0, 15%);
`;

const FeatureListItem = styled.li`
  display: grid;
  grid:
    "icon title" auto
    "icon description" auto / 50px 1fr;
  grid-gap: 6px 16px;
  margin: 0;
  padding: 0;
`;

const ListItemTitle = styled.h3(
  ({ theme }) => css`
    grid-area: title;
    margin: 0;
    font: ${theme.fontStudioBodyCopyRegularShortHand};
    letter-spacing: ${theme.fontStudioBodyCopyRegularLetterSpacing};
    line-height: 1;
    font-weight: ${theme.fontNotebooksH1FontWeight};
  `,
);

const ListItemDescription = styled.p(
  ({ theme }) => css`
    grid-area: description;
    margin: 0;
    font: ${theme.fontStudioBodyCopyRegularShortHand};
    letter-spacing: ${theme.fontStudioBodyCopyRegularLetterSpacing};
  `,
);

const ListItemIconVisual = styled(IconVisual)`
  grid-area: icon;
  align-self: center;
`;

type FeatureListContent = Array<{
  iconType: IconType;
  title: string;
  description: string;
}>;

const FEATURE_LIST_CONTENT: FeatureListContent = [
  {
    iconType: "chart_bar",
    title: "Extend at will",
    description:
      "Integrate the tools you need to query and visualize your metrics, logs, and other infrastructure data with Providers.",
  },
  {
    iconType: "users_three",
    title: "Collaborate in real time",
    description:
      "Resolve incidents, write post-mortems, and work with your team in one space.",
  },
  {
    iconType: "lightning",
    title: "Automate as needed",
    description:
      "Leverage the API, CLI, Jsonnet templates, and Webhooks to build streamlined debugging workflows.",
  },
  {
    iconType: "magnifying_glass_plus",
    title: "Keep a track record",
    description:
      "Document, preserve, and share crucial insights about your infrastructure with real, live data.",
  },
];
