import { useCallback, useEffect, useState } from "react";

type TextGetter = () => string;

/**
 * Hook that returns:
 *   - A handler (`handleCopy`) that can copy text to the user's clipboard
 *   - A boolean (`isCopied`) that indicates if the text was copied within the past `throttleIsCopiedMs` milliseconds, useful for throttling
 *
 * @param getText  - Either a string or a function that returns a string
 * @param throttleIsCopiedMs - How long to throttle the `isCopied` state
 */
export function useCopyToClipboard(
  getText: string | TextGetter,
  throttleMs = 3000,
) {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined;

    if (isCopied && !timeout) {
      timeout = setTimeout(() => {
        setIsCopied(false);
      }, throttleMs);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isCopied, throttleMs]);

  const handleCopy = useCallback(() => {
    const text = typeof getText === "string" ? getText : getText();

    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(true);
    });
  }, [getText]);

  return { isCopied, handleCopy };
}
