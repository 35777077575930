import { encode } from "@msgpack/msgpack";

import { MESSAGEPACK_MIME_TYPE } from "../../constants";
import type {
  CreateCellsRequest,
  DataSource,
  ExtractDataRequest,
  LegacyProviderRequest,
  ProviderRequest,
} from "../../types";
import {
  binaryResponse,
  deleteRequest,
  getRequest,
  parseJsonResponse,
  postJsonRequestDeprecated,
  postRawRequest,
  withQueryArgs,
} from "./utils";

export type NewProxy = Pick<Proxy, "name" | "description">;

export type Proxy = {
  id: string;
  status: ProxyConnectionStatus;
  name: string;

  /**
   * Data-sources associated with this proxy.
   */
  dataSources: Array<DataSource>;

  description?: string;

  token?: string;
};

export type ProxyConnectionStatus = "connected" | "disconnected";

export type ProxySummary = Pick<Proxy, "id" | "name" | "status">;

export const createProxy = (workspaceId: string, proxy: NewProxy) =>
  postJsonRequestDeprecated<Proxy>(
    `/api/workspaces/${workspaceId}/proxies`,
    proxy,
    parseJsonResponse,
  );

export const deleteProxy = (workspaceId: string, proxyName: string) =>
  deleteRequest(`/api/workspaces/${workspaceId}/proxies/${proxyName}`);

export const getProxy = (workspaceId: string, proxyName: string) =>
  getRequest<Proxy>(
    `/api/workspaces/${workspaceId}/proxies/${proxyName}`,
    parseJsonResponse,
  );

export const invokeLegacyRelay = (
  workspaceId: string,
  proxyName: string,
  dataSourceName: string,
  request: LegacyProviderRequest,
) =>
  invokeRelay(
    workspaceId,
    proxyName,
    dataSourceName,
    request as unknown as ProviderRequest,
    { version: 1 },
  );

export const invokeRelay = (
  workspaceId: string,
  proxyName: string,
  dataSourceName: string,
  request: ProviderRequest,
  { version = 2 }: { version?: number } = {},
) =>
  postRawRequest({
    url: withQueryArgs(
      `/api/workspaces/${workspaceId}/proxies/${proxyName}/data_sources/${dataSourceName}/relay`,
      { version },
    ),
    headers: {
      // biome-ignore lint/style/useNamingConvention: API field name is correct
      Accept: MESSAGEPACK_MIME_TYPE,
      "Content-type": MESSAGEPACK_MIME_TYPE,
    },
    body: encode(request),
    responseHandler: binaryResponse,
  });

export const listProxies = (workspaceId: string) =>
  getRequest<Array<ProxySummary>>(
    `/api/workspaces/${workspaceId}/proxies`,
    parseJsonResponse,
  );

export const relayCreateCells = (
  workspaceId: string,
  proxyName: string,
  dataSourceName: string,
  request: CreateCellsRequest,
) =>
  postRawRequest({
    url: `/api/workspaces/${workspaceId}/proxies/${proxyName}/data_sources/${dataSourceName}/relay/v2/create_cells`,
    headers: {
      // biome-ignore lint/style/useNamingConvention: API field name is correct
      Accept: MESSAGEPACK_MIME_TYPE,
      "Content-type": MESSAGEPACK_MIME_TYPE,
    },
    body: encode(request),
    responseHandler: binaryResponse,
  });

export const relayExtractData = (
  workspaceId: string,
  proxyName: string,
  dataSourceName: string,
  request: ExtractDataRequest,
) =>
  postRawRequest({
    url: `/api/workspaces/${workspaceId}/proxies/${proxyName}/data_sources/${dataSourceName}/relay/v2/extract_data`,
    headers: {
      // biome-ignore lint/style/useNamingConvention: API field name is correct
      Accept: MESSAGEPACK_MIME_TYPE,
      "Content-type": MESSAGEPACK_MIME_TYPE,
    },
    body: encode(request),
    responseHandler: binaryResponse,
  });

export const relayGetSupportedQueryTypes = (
  workspaceId: string,
  proxyName: string,
  dataSourceName: string,
) =>
  getRequest(
    `/api/workspaces/${workspaceId}/proxies/${proxyName}/data_sources/${dataSourceName}/relay/v2/supported_query_types`,
    binaryResponse,
    // biome-ignore lint/style/useNamingConvention: API field name is correct
    { headers: { Accept: MESSAGEPACK_MIME_TYPE } },
  );
