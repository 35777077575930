import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectActiveWorkspaceId } from "../../selectors";
import type { NotebookSummary } from "../../types";
import { partition, sortBy } from "../../utils";
import {
  notebooksApi,
  useListNotebooksQuery,
  useListPinnedNotebooksQuery,
} from "./notebooks";

/**
 * Fetches notebooks and pinned notebooks and combines them into a single sorted list of recent notebooks
 */
export const useListRecentNotebooksQuery = (workspaceId: string) => {
  const { data: pinnedNotebooks, isFetching: isFetchingPinnedNotebooks } =
    useListPinnedNotebooksQuery(
      { workspaceId },
      { refetchOnMountOrArgChange: true },
    );

  const { data: notebooks, isFetching: isFetchingNotebooks } =
    useListNotebooksQuery({ workspaceId }, { refetchOnMountOrArgChange: true });

  const recentNotebooks = useMemo(() => {
    if (!notebooks) {
      return;
    }

    if (!pinnedNotebooks) {
      return notebooks;
    }

    const pinnedIds = new Set(pinnedNotebooks.map(({ id }) => id));
    const [pinned, unpinned] = partition(notebooks, ({ id }) =>
      pinnedIds.has(id),
    );

    return [...sortNotebooks(pinned), ...sortNotebooks(unpinned)];
  }, [notebooks, pinnedNotebooks]);

  return {
    data: recentNotebooks,
    isFetching: isFetchingNotebooks || isFetchingPinnedNotebooks,
  };
};

function sortNotebooks(
  notebooks: Array<NotebookSummary>,
): Array<NotebookSummary> {
  return sortBy(notebooks, ({ updatedAt }) => updatedAt, true);
}

/**
 * Determines if given notebook id has been pinned
 */
export const useNotebookIsPinnedQuery = (notebookId?: string) => {
  const workspaceId = useSelector(selectActiveWorkspaceId) ?? "";

  return notebooksApi.useListPinnedNotebooksQuery(
    { workspaceId },
    {
      selectFromResult: ({ data, ...rest }) => ({
        data: data?.some(({ id }) => id === notebookId),
        ...rest,
      }),
      skip: !(workspaceId && notebookId),
    },
  );
};
