import { useHandler } from "@fiberplane/hooks";
import type React from "react";
import { type MouseEvent, memo, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";

import { cancelEvent } from "@fiberplane/ui";
import { createSelector } from "reselect";
import {
  closeContextMenu,
  openContextMenu,
  startDrag,
  stopDrag,
  withActiveNotebook,
} from "../../actions";
import {
  makeNotebookContextMenuOfTypeIsOpenSelector,
  makeNotebookContextMenuSelector,
  selectActiveDrag,
  selectActiveNotebookId,
  selectCellIds,
  selectNotebookFocus,
} from "../../selectors";
import { dispatch, getState } from "../../store";
import { toggleLockFocusedCells } from "../../thunks";
import type { Cell } from "../../types";
import { isCellInFocus, isMac, track } from "../../utils";
import { CellIconButton } from "../UI";
import { CellFocusIndicatorWithButtons } from "./CellFocusIndicatorWithButtons";

type Props = {
  cell: Cell;
  focused: boolean;
  lockIconClassName?: string;
  selected: boolean;
};

export const CellControls = memo(function CellControls({
  cell,
  focused,
  lockIconClassName,
  selected,
}: Props): JSX.Element {
  const { id, readOnly, type: cellType } = cell;

  const selectIsDragging = useMemo(() => makeIsDraggingSelector(id), [id]);
  const isDragging = useSelector(selectIsDragging);

  const cellActionsRef = useRef<HTMLButtonElement>(null);

  const selectContextMenuOfTypeIsOpen = useMemo(
    () => makeNotebookContextMenuOfTypeIsOpenSelector(id, "cell_actions"),
    [id],
  );
  const menuOpen: boolean = useSelector(selectContextMenuOfTypeIsOpen);

  const handleDragStart = () => {
    track("notebook | drag cell - start", {
      notebookId: selectActiveNotebookId(getState()),
      cellType,
      source: "cell controls ",
    });

    dispatch(
      startDrag({
        draggedCellId: id,
        isFormatSupported: true,
        target: { type: "before_cell", cellId: id },
      }),
    );
  };

  const handleDragEnd = () => {
    track("notebook | drag cell - stop", {
      notebookId: selectActiveNotebookId(getState()),
      cellType,
      source: "cell controls ",
    });

    dispatch(stopDrag());
    cellActionsRef.current?.blur();
  };

  const toggleMenu = useHandler((event: React.MouseEvent) => {
    const state = getState();
    const cellIds = selectCellIds(state);
    const focus = selectNotebookFocus(state);

    // This check is necessary to prevent setting the focus to this particular
    // cell when it's part of the selection. cancelEvent prevents bubbling
    // which otherwise will call focusCell in Cell.tsx.
    const cellInFocus = isCellInFocus(cellIds, id, focus);
    if (cellInFocus) {
      cancelEvent(event);
    }

    const contextMenu = makeNotebookContextMenuSelector(id)(state);
    if (contextMenu) {
      dispatch(withActiveNotebook(closeContextMenu()));
      return;
    }

    const currentRect = cellActionsRef.current?.getBoundingClientRect();
    if (currentRect) {
      dispatch(
        withActiveNotebook(
          openContextMenu({
            cellId: id,
            menuType: "cell_actions",
            cursorRect: currentRect,
          }),
        ),
      );
    }
  });

  return (
    <>
      <CellIconButtonsContainer>
        <CellIconButton
          className={lockIconClassName}
          data-lock-toggle
          onClick={(event: MouseEvent) => {
            event.stopPropagation();
            dispatch(toggleLockFocusedCells(id));
          }}
          aria-label={readOnly ? "Locked" : "Unlocked"}
          data-tooltip-show-delay={500}
          data-shortcut={`${isMac ? "cmd" : "ctrl"} + shift + l`}
          $isAlwaysVisible={readOnly}
          iconType={readOnly ? "lock_simple" : "lock_simple_open"}
        />

        <CellIconMenuButton
          data-cell-actions
          onClick={toggleMenu}
          ref={cellActionsRef}
          // NOTE - This aria-label will be really weird with screen readers
          //        We should find a way to fix this.
          aria-label="*Drag* to move \n *Click* to open menu"
          data-tooltip-show-delay={500}
          data-dragging={isDragging ? true : undefined}
          draggable={readOnly ? undefined : true}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          $isAlwaysVisible={menuOpen}
          className={menuOpen ? "active" : undefined}
          iconType="dots_six_vertical"
        />

        <CellFocusIndicatorWithButtons
          cellId={id}
          focused={focused}
          selected={selected}
        />
      </CellIconButtonsContainer>
    </>
  );
});

function makeIsDraggingSelector(cellId: string) {
  return createSelector(
    selectActiveDrag,
    (activeDrag) => activeDrag?.draggedCellId === cellId,
  );
}

const CellIconButtonsContainer = styled.div`
  flex: 1;
  display: grid;
  grid-auto-flow: column;
`;

const CellIconMenuButton = styled(CellIconButton)`
  cursor: grab;
`;
