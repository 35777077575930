import { Icon } from "@fiberplane/ui";
import type React from "react";
import { useCallback, useContext, useEffect, useRef } from "react";
import useMedia from "react-use/lib/useMedia";

import type { OffsetsFunction } from "@fiberplane/ui";
import { useTimeout } from "../../../hooks";
import { MenuContext } from "./MenuContext";
import { MenuItem, type MenuItemProps } from "./MenuItem";
import { ToggleMenu, type ToggleMenuMethods } from "./ToggleMenu";

export type MenuItemWithSubMenuProps = Omit<MenuItemProps, "onActivate"> & {
  children?: React.ReactElement | Array<React.ReactElement>;
  grabsFocus?: boolean;
};

export function MenuItemWithSubMenu(props: MenuItemWithSubMenuProps) {
  const { children, grabsFocus = true, ...menuItemProps } = props;
  const menuRef = useRef<ToggleMenuMethods>(null);

  const { close, selection, setSelection } = useContext(MenuContext);
  const { autoOpen, itemId } = selection;
  const isSelected = itemId === menuItemProps.id;

  const isWide = useMedia("(min-width: 768px)");

  // biome-ignore lint/correctness/useExhaustiveDependencies: looks intentional?
  useEffect(() => {
    if (isSelected && autoOpen) {
      menuRef.current?.open();
    }
  }, [isSelected, selection, autoOpen]);

  // Use a delay before closing again, so it's not immediately a problem if
  // the cursor moves over other menu items while moving to the submenu.
  const selectionChanged = useCallback(() => {
    // biome-ignore lint/complexity/useSimplifiedLogicExpression: Prefer this logic over the "simplified" version (which is less readable)
    if (!isSelected || !autoOpen) {
      menuRef.current?.close();
    }
  }, [isSelected, autoOpen]);
  useTimeout(selectionChanged, 150);

  return (
    <ToggleMenu
      offset={offsetFunction}
      isSubmenu={true}
      onClose={({ reason }) =>
        reason === "item_activated"
          ? close({ reason })
          : setSelection({ ...selection, grabsFocus: false, autoOpen: false })
      }
      onSelectionChange={() => {
        // Make sure we get the selection back to ourselves if the cursor
        // moved over another item before reaching the submenu:
        if (!isSelected) {
          setSelection({
            ...selection,
            itemId: menuItemProps.id,
            grabsFocus,
          });
        }
      }}
      placement={isWide ? "right-start" : "bottom"}
      ref={menuRef}
      toggleElement={({ anchorRef }) => (
        <MenuItem
          {...menuItemProps}
          ref={anchorRef}
          disableCloseOnActivate
          onActivate={() => {
            setSelection({
              ...selection,
              itemId: menuItemProps.id,
              grabsFocus,
              autoOpen: true,
            });
          }}
          iconRight={{ icon: <Icon iconType="triangle_right" /> }}
        />
      )}
    >
      {children}
    </ToggleMenu>
  );
}

const offsetFunction: OffsetsFunction = ({ placement }) => {
  // If it's not the default (right-start) placement
  // return an offset that moves the menu down a bit
  if (placement !== "right-start") {
    return [-5, 0];
  }

  // Otherwise move the menu up a bit
  return [-14, 0];
};
