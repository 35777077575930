// biome-ignore lint/nursery/useImportRestrictions: generated types
import type { LabelValidationError } from "../services/FiberKit/ts-bindings/types";

/**
 * The Labels Editor can be used in various contexts. Depending on context,
 * some behavior might change, and we use different slices to maintain their
 * state, to keep them from inadvertently interacting with one another.
 *
 * The `selectActiveLabelsEditorType()` selector is used to identify which type
 * of labels editor is active, which is then used by the
 * `selectLabelsEditorLabels()` selector.
 */
export type ActiveLabelsEditorType = LabelsEditorType | "none";

export type ClientLabelValidationError = LabelValidationError | "duplicate_key";

export type LabelsEditorType =
  | "command_menu"
  | "notebook"
  | "provider_field"
  | "view"
  | "event_modal";
