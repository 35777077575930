import { Input } from "@fiberplane/ui";
import { useEffect, useMemo } from "react";
import { css, styled } from "styled-components";

import { useSelector } from "react-redux";
import { MEDIUM_SIZE_QUERY } from "../../../../../constants";
import { selectProfileConnections } from "../../../../../selectors";
import { listConnections } from "../../../../../slices";
import { dispatch } from "../../../../../store";
import type { Profile } from "../../../../../types";
import { AvatarUpdater } from "../../../../AvatarUpdater";
import { ProviderConnection } from "./ProviderConnection";

export function ProfileInfo(props: Profile): JSX.Element {
  const { id, name, email } = props;

  const { data } = useSelector(selectProfileConnections);
  const numberOfActiveConnections = useMemo(
    () =>
      data?.reduce(
        (count, connection) => count + (connection.linked ? 1 : 0),
        0,
      ) ?? 0,
    [data],
  );

  useEffect(() => {
    const promise = dispatch(listConnections());
    return () => promise.abort();
  }, []);

  return (
    <Page data-testid="settings-profile">
      <PersonalDataContainer>
        <AvatarContainer>
          <AvatarUpdater id={id} name={name} avatarType="profile" />
        </AvatarContainer>

        <FormContainer>
          <InputLabel>
            Full name
            <Input type="text" name="full-name" value={name} readOnly />
          </InputLabel>

          <InputLabel>
            Email
            <Input type="text" name="email" value={email} readOnly />
          </InputLabel>
        </FormContainer>
      </PersonalDataContainer>

      <Section>
        <Heading>Connections</Heading>
        <ConnectionsContainer>
          {data?.map((connection) => {
            return (
              <ProviderConnection
                key={connection.provider}
                {...connection}
                hasMultipleConnections={numberOfActiveConnections > 1}
              />
            );
          })}
        </ConnectionsContainer>
      </Section>
    </Page>
  );
}

const Page = styled.div`
  display: grid;
  gap: 32px;
`;

const Heading = styled.h4(
  ({ theme }) => css`
    margin: 0;
    font: ${theme.font.headings.h4};
    color: ${theme.color.fg.default};
  `,
);

const AvatarContainer = styled.div`
  display: grid;
  place-items: center;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
`;

const ConnectionsContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 8px;

  @media ${MEDIUM_SIZE_QUERY} {
    flex-direction: row;
  }
`;

const InputLabel = styled.h4(
  ({ theme }) => css`
    display: grid;
    gap: 16px;
    margin: 0;
    font: ${theme.font.headings.h4};
    color: ${theme.color.fg.default};
  `,
);

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${Heading} {
    margin-bottom: 4px;
  }
`;

const PersonalDataContainer = styled.div(
  ({ theme: { media } }) => css`
    display: flex;
    flex-direction: column;
    gap: 40px;

    ${media.md`
      flex-direction: row;
    `}
  `,
);
