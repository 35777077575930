import { Icon, IconButton, type IconType, isIconType } from "@fiberplane/ui";
import type React from "react";
import { useContext } from "react";
import { css, styled } from "styled-components";

import bgGradient1 from "url:./images/bg-gradient-1.jpeg";
import bgGradient2 from "url:./images/bg-gradient-2.jpeg";

import { BaseModal, ModalContent, ModalContext } from "../Modal";
import { Text } from "../Text";

type Props = {
  gradient?: Gradient;
  // NOTE - Either a string for the icon to add, or a component
  illustration: IconType | React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
};

export function GradientModal({
  gradient,
  illustration,
  children,
  footer,
}: Props) {
  const { requestClose } = useContext(ModalContext);
  const headerIllustration = isIconType(illustration) ? (
    <IconWrapper>
      <Icon iconType={illustration} height="70" width="70" />
    </IconWrapper>
  ) : (
    illustration
  );
  return (
    <StyledModal>
      <StyledModalContent gradient={gradient}>
        <IllustrationContainer>
          <ModalClose>
            <ModalCloseIconButton iconType="close" onClick={requestClose} />
          </ModalClose>
          {headerIllustration}
        </IllustrationContainer>
        {children}
      </StyledModalContent>
      {footer}
    </StyledModal>
  );
}

const StyledModal = styled(BaseModal)`
  width: 100%;
  max-width: 600px;
  min-height: 400px;
  max-height: max(768px, 80vh);
  position: relative;
`;

type Gradient = "1" | "2";

const StyledModalContent = styled(ModalContent)<{ gradient?: Gradient }>(
  ({ gradient }) => {
    const img = gradient === "1" ? bgGradient1 : bgGradient2;
    return css`
      /* NOTE - in the future, prefer using <img/> tags instead of css background images */
      background-image: url("${img}");
      background-position: top;
      background-repeat: no-repeat;
      background-attachment: local;
      background-size: 100% 60%;
    `;
  },
);

const IllustrationContainer = styled.div`
  padding-bottom: 0;
  border-bottom: 0;
  min-height: 180px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  border-radius: 32px;
  height: 120px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalClose = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
`;

const ModalCloseIconButton = styled(IconButton)`
  background: ${({ theme }) => theme.colorBaseAlpha400};
  border-radius: 50%;
  color: ${({ theme }) => theme.colorBase100};
`;

export const GradientModalHeading = styled.h2`
  font: ${({ theme }) => theme.fontStudioHeadingsH2ShortHand};
  text-align: center;
`;

export const GradientModalBodyRegularText = styled(Text)(
  ({ theme }) => css`
    color: ${theme.colorBase600};
    font: ${theme.fontStudioBodyCopyRegularShortHand};
    line-height: ${theme.fontStudioBodyCopyRegularLineHeight};
  `,
);
