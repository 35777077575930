import { Icon, type IconType } from "@fiberplane/ui";

type Props = React.SVGProps<SVGSVGElement> & {
  offline?: boolean;
  type: string;
};

export function ProviderTypeIcon({
  type,
  width = 20,
  offline,
  ...restProps
}: Props): JSX.Element {
  return (
    <Icon
      iconType={getIconTypeForProviderType(type, offline)}
      width={width ? undefined : width}
      height={width}
      {...restProps}
    />
  );
}

function getIconTypeForProviderType(
  providerType: string,
  offline?: boolean,
): IconType {
  switch (providerType) {
    case "elasticsearch":
    case "loki":
    case "prometheus":
    case "cloudwatch":
      return offline ? providerType : `${providerType}_color`;
    case "proxy":
      return "proxy";
    case "fiberplane":
      return "timeseries";
    default:
      return "source";
  }
}
