// ============================================= //
// Types for WebAssembly runtime                 //
//                                               //
// This file is generated. PLEASE DO NOT MODIFY. //
// ============================================= //

/**
 * Acknowledgement that the server has received and successfully processed an
 * operation sent by the client.
 *
 * Acknowledgement are only sent for client message that include some `op_id`.
 */
export type AckMessage = {
    /**
     * ID of the operation being acknowledged. This matches the `op_id` sent by
     * the client.
     */
    opId: string;
};

/**
 * A struct that represents all the formatting that is active at any given
 * character offset.
 */
export type ActiveFormatting = {
    bold: boolean;
    code: boolean;
    highlight: boolean;
    italics: boolean;
    link?: string;
    strikethrough: boolean;
    underline: boolean;
    label?: Label;
    mention?: Mention;
    timestamp?: Timestamp;
};

export type AddCommentPayload = {
    cellId: string;
    commentResponse: Uint8Array;
};

/**
 * Specifies the given label must be added.
 */
export type AddLabelChange = {
    /**
     * The label that was added.
     */
    label: Label;
};

/**
 * Add an label to an notebook.
 */
export type AddLabelOperation = {
    /**
     * The new label
     */
    label: Label;
};

export type AddThreadPayload = {
    thread: Thread;
};

/**
 * A rich-text annotation.
 *
 * Annotations are typically found inside a `Formatting` vector.
 */
export type Annotation =
    | { type: "start_bold" }
    | { type: "end_bold" }
    | { type: "start_code" }
    | { type: "end_code" }
    | { type: "start_highlight" }
    | { type: "end_highlight" }
    | { type: "start_italics" }
    | { type: "end_italics" }
    | { type: "label" } & Label
    | { type: "start_link"; url: string }
    | { type: "end_link" }
    | { type: "mention" } & Mention
    | { type: "start_strikethrough" }
    | { type: "end_strikethrough" }
    | { type: "timestamp"; timestamp: Timestamp }
    | { type: "start_underline" }
    | { type: "end_underline" };

/**
 * An annotation at a specific offset in the text. Offsets are always
 * calculated by Unicode scalar values rather than byte indices.
 *
 * Used inside the `Formatting` vector.
 */
export type AnnotationWithOffset = {
    offset: number;
} & Annotation;

export type ApplyOperationBatchMessage = {
    /**
     * ID of the notebook.
     */
    notebookId: string;

    /**
     * The operations to apply.
     */
    operations: Array<Operation>;

    /**
     * The revision assigned to the operation.
     *
     * If a client sends this message, it *requests* this revision to be
     * assigned and the operations may be rejected if the revision is already
     * assigned.
     *
     * When a client receives this message, it is the actual revision.
     */
    revision: number;

    /**
     * Operation ID.
     *
     * Only messages with an operation ID will receive an `Ack` from the
     * server.
     */
    opId?: string;
};

export type ApplyOperationMessage = {
    /**
     * ID of the notebook.
     */
    notebookId: string;

    /**
     * The operation to apply.
     */
    operation: Operation;

    /**
     * The revision assigned to the operation.
     *
     * If a client sends this message, it *requests* this revision to be
     * assigned and the operation may be rejected if the revision is already
     * assigned.
     *
     * When a client receives this message, it is the actual revision.
     */
    revision: number;

    /**
     * Operation ID.
     *
     * Only messages with an operation ID will receive an `Ack` from the
     * server.
     */
    opId?: string;
};

/**
 * Applies an operation generated by this client.
 */
export type ApplyOwnOperationPayload = {
    /**
     * The context menu that should be closed as a result of applying this operation.
     */
    closeMenu?: NotebookContextMenuInfo;

    /**
     * The new focus information after applying the operation.
     *
     * If omitted, FiberKit will attempt to determine a sensible new focus based on the operations.
     */
    focus?: NotebookFocus;

    /**
     * The operation to apply.
     */
    operation: Operation;

    /**
     * Options related to undo state of this operation.
     */
    undoOptions: UndoOptions;
};

export type AuthError =
    | { type: "unauthenticated" }
    | { type: "unauthorized" };

export type AuthRole =
    | "read"
    | "write"
    | "admin";

export type AuthenticateMessage = {
    /**
     * Bearer token.
     */
    token: string;

    /**
     * Operation ID.
     *
     * Only messages with an operation ID will receive an `Ack` from the
     * server.
     */
    opId?: string;
};

/**
 * A request for a provider to provide auto-suggestions.
 */
export type AutoSuggestRequest = {
    /**
     * The value of the field being typed by the user, up to the focus offset.
     */
    query: string;

    /**
     * The query type of the provider we're requesting suggestions for.
     */
    query_type: string;

    /**
     * The field in the query form we're requesting suggestions for.
     */
    field: string;

    /**
     * Some other fields of the cell data.
     * The choice of which other fields are sent in the request is
     * left to the caller.
     * The encoding of the other fields is left to the implementation
     * in Studio, and follows the format of
     * cells [Query Data](crate::ProviderCell::query_data).
     */
    other_field_data?: string;
};

/**
 * Binary blob for passing data in arbitrary encodings.
 *
 * Binary blobs are both consumed and produced by providers. Note that for many
 * use-cases, we use agreed on MIME types as defined in
 * [RFC 47](https://www.notion.so/fiberplane/RFC-47-Data-Model-for-Providers-2-0-0b5b1716dbc8450f882d33effb388c5b).
 * Providers are able to use custom MIME types if they desire.
 *
 * We can also store blobs in cells, but for this we use [EncodedBlob] to allow
 * JSON serialization.
 */
export type Blob = {
    /**
     * Raw data.
     */
    data: Uint8Array;

    /**
     * MIME type to use for interpreting the raw data.
     *
     * We keep track of this, so that we can elide unnecessary calls to
     * `extract_data()`, and are able to perform migrations on data specified
     * in any of the `application/vnd.fiberplane.*` types. For other types of
     * data, providers are responsible for migrations, and they are able to
     * include version numbers in their MIME type strings, if desired.
     */
    mimeType: string;
};

/**
 * Representation of a single notebook cell.
 */
export type Cell =
    | { type: "checkbox" } & CheckboxCell
    | { type: "code" } & CodeCell
    | { type: "discussion" } & DiscussionCell
    | { type: "divider" } & DividerCell
    | { type: "graph" } & GraphCell
    | { type: "heading" } & HeadingCell
    | { type: "image" } & ImageCell
    | { type: "list_item" } & ListItemCell
    | { type: "log" } & LogCell
    | { type: "provider" } & ProviderCell
    | { type: "table" } & TableCell
    | { type: "timeline" } & TimelineCell
    | { type: "text" } & TextCell;

export type CellWithIndex = {
    cell: Cell;
    index: number;
};

export type CellWithMetadata = {
    cell: Cell;

    /**
     * Map from field names to drafts the user may have typed.
     */
    drafts: Record<string, Draft>;

    /**
     * Optional error that occurred when trying to fetch data based on this cell.
     */
    error?: Error;

    /**
     * Whether the cell is currently busy fetching data.
     */
    isRunning: boolean;
};

export type CellsUpdate = {
    cellIds: Array<string>;
    changedCells: Array<CellWithMetadata>;
};

export type Change =
    | { type: "insert_cell" } & InsertCellChange
    | { type: "delete_cell" } & DeleteCellChange
    | { type: "move_cells" } & MoveCellsChange
    | { type: "update_cell" } & UpdateCellChange
    | { type: "update_cell_text" } & UpdateCellTextChange
    | { type: "update_notebook_time_range" } & UpdateNotebookTimeRangeChange
    | { type: "update_notebook_title" } & UpdateNotebookTitleChange
    | { type: "set_selected_data_source" } & SetSelectedDataSourceChange
    | { type: "add_label" } & AddLabelChange
    | { type: "replace_label" } & ReplaceLabelChange
    | { type: "remove_label" } & RemoveLabelChange
    | { type: "update_front_matter" } & UpdateFrontMatterChange
    | { type: "clear_front_matter" }
    | { type: "insert_front_matter_schema" } & InsertFrontMatterSchemaChange
    | { type: "update_front_matter_schema" } & UpdateFrontMatterSchemaChange
    | { type: "move_front_matter_schema" } & MoveFrontMatterSchemaChange
    | { type: "remove_front_matter_schema" } & RemoveFrontMatterSchemaChange;

export type ChangeThreadStatusPayload = {
    threadId: string;

    /**
     * The API returns the whole thread
     */
    threadResponse: Uint8Array;
};

export type CheckboxCell = {
    id: string;
    checked: boolean;
    content: string;

    /**
     * Optional formatting to be applied to the cell's content.
     */
    formatting?: Formatting;
    level?: number;
    readOnly?: boolean;
};

/**
 * Removes front matter in a notebook
 */
export type ClearFrontMatterOperation = {
    frontMatter: FrontMatter;
};

/**
 * Real-time message sent by the client over a WebSocket connection.
 */
export type ClientRealtimeMessage =
    /**
     * Authenticate this client
     */
    | { type: "authenticate" } & AuthenticateMessage
    /**
     * Subscribe to changes from a specific Notebook.
     */
    | { type: "subscribe" } & SubscribeMessage
    /**
     * Unsubscribe to changes from a specific Notebook.
     */
    | { type: "unsubscribe" } & UnsubscribeMessage
    /**
     * Apply an operation to a specific Notebook.
     */
    | { type: "apply_operation" } & ApplyOperationMessage
    /**
     * Apply multiple operations to a specific Notebook.
     */
    | { type: "apply_operation_batch" } & ApplyOperationBatchMessage
    /**
     * Request a DebugResponse from the server.
     */
    | { type: "debug_request" } & DebugRequestMessage
    | { type: "focus_info" } & FocusInfoMessage
    /**
     * User started typing a comment.
     */
    | { type: "user_typing_comment" } & UserTypingCommentClientMessage
    /**
     * Subscribe to workspace activities
     */
    | { type: "subscribe_workspace" } & SubscribeWorkspaceMessage
    /**
     * Unsubscribe from workspace activities
     */
    | { type: "unsubscribe_workspace" } & UnsubscribeWorkspaceMessage;

export type CodeCell = {
    id: string;
    content: string;
    readOnly?: boolean;

    /**
     * Optional MIME type to use for syntax highlighting.
     */
    syntax?: string;
};

export type Comment = {
    id: string;
    createdBy: UserSummary;
    content: string;
    formatting: Formatting;
    createdAt: Timestamp;
    updatedAt: Timestamp;
};

export type CommentDelete = {
    id: string;
    createdBy: UserSummary;

    /**
     * Timestamp when the original comment was created.
     */
    createdAt: Timestamp;
    deletedAt: Timestamp;
};

/**
 * Different connection statuses.
 *
 * Note that "idle" means there are no active subscribers, so no connection is
 * necessary (whether there is one is considered an implementation detail).
 */
export type ConnectionStatus =
    | "connecting"
    | "connected"
    | "disconnected"
    | "idle";

export type ConsoleMessage =
    | { severity: "notice"; message: string }
    | { severity: "warning"; message: string }
    | { severity: "error"; location?: LocationInfo; error: Error };

export type ConsoleState = {
    expanded: boolean;
    height: number;
    messages: Array<ConsoleMessage>;
};

export type CourierAction =
    | { type: "courier/incoming_message"; payload: string }
    | { type: "courier/set_connection_status"; payload: ConnectionStatus };

export type CourierReducerResult = {
    state: CourierState;
    sideEffects: Array<SideEffectDescriptor>;
};

/**
 * The state of the notebook itself, plus book-keeping state, such as for editing and presence.
 * Properties that may be expensive to clone or serialize are wrapped in `Rc` to avoid
 * synchronization overhead.
 */
export type CourierState = {
    backoffStep: number;
    connectionStatus: ConnectionStatus;
};

export type CreateCellsRequest = {
    response: Blob;
    queryType: string;
};

export type CreatedBy =
    | { type: "user" } & UserSummary
    | { type: "trigger" } & TriggerSummary
    | { type: "onboarding" }
    | { type: "unknown" };

export type CursorDirection =
    | "backward"
    | "forward";

export type CursorUnit =
    | "grapheme_cluster"
    | "word";

export type DebugRequestMessage = {
    /**
     * Operation ID.
     *
     * Only messages with an operation ID will receive an `Ack` from the
     * server.
     */
    opId?: string;
};

export type DebugResponseMessage = {
    /**
     * Session ID.
     */
    sid: string;

    /**
     * Notebooks that the user is subscribed to.
     */
    subscribedNotebooks: Array<string>;

    /**
     * Operation ID.
     *
     * Only messages with an operation ID will receive an `Ack` from the
     * server.
     */
    opId?: string;
};

/**
 * Specifies the cell with the given ID must be deleted.
 */
export type DeleteCellChange = {
    cellId: string;
};

export type DeleteCommentPayload = {
    threadId: string;
    commentDelete: CommentDelete;
};

export type DeleteFromCursorPayload = {
    /**
     * The direction in which to delete from the current cursor position.
     * `Backward` will behave like a Backspace, `Forward` will behave like a Delete.
     */
    cursorDirection: CursorDirection;

    /**
     * Default behavior would be to remove a single grapheme cluster, but if the user presses
     * Ctrl, entire words may be removed. In either case, if the cursor is at the boundary of a
     * mention, the mention will be removed in its entirety.
     */
    unit: CursorUnit;
};

export type DeleteThreadPayload = {
    threadId: string;
};

export type DiscussionCell = {
    id: string;
    threadId: string;
    readOnly?: boolean;
};

export type DividerCell = {
    id: string;
    readOnly?: boolean;
};

export type Draft = {
    text: string;
    formatting?: Formatting;
};

export type DuplicateLabelRejectReason = {
    /**
     * The key of the label that was already present.
     */
    key: string;
};

export type EditCommentPayload = {
    threadId: string;
    commentResponse: Uint8Array;
};

export type EditorState = {
    /**
     * The formatting that should be applied when the user types.
     */
    activeFormatting: ActiveFormatting;

    /**
     * State about the context menu, if currently opened.
     */
    contextMenu?: NotebookContextMenuInfo;

    /**
     * The position in the notebook that is currently focused by the user. May
     * include a selection.
     */
    focus: NotebookFocus;

    /**
     * Whether the focus position should be highlighted. This is done through
     * a short animation, and will also scroll the focus position into view,
     * if necessary.
     */
    highlightFocus: boolean;
};

/**
 * Used for communicating updates to the `EditorState` back to TypeScript.
 */
export type EditorStateUpdate = {
    activeFormatting?: ActiveFormatting;
    contextMenu?: NotebookContextMenuInfo | null;
    focus?: NotebookFocus;
    highlightFocus?: boolean;
};

/**
 * base64-encoded version of [Blob].
 */
export type EncodedBlob = {
    /**
     * Raw data, encoded using base64 so it can be serialized using JSON.
     */
    data: string;

    /**
     * MIME type to use for interpreting the raw data.
     *
     * See [Blob::mime_type].
     */
    mimeType: string;
};

export type ErrMessage = {
    /**
     * Error message.
     */
    errorMessage: string;

    /**
     * Operation ID.
     *
     * This will match the operation ID of the client message that triggered
     * the error.
     */
    opId?: string;
};

export type Error =
    | { type: "unsupported_request" }
    | {
        type: "validation_error";

        /**
         * List of errors, so all fields that failed validation can
         * be highlighted at once.
         */
        errors: Array<ValidationError>;
    }
    | { type: "http"; error: HttpRequestError }
    | { type: "data"; message: string }
    | { type: "deserialization"; message: string }
    | { type: "config"; message: string }
    | { type: "not_found" }
    | { type: "proxy_disconnected" }
    | { type: "invocation"; message: string }
    | { type: "other"; message: string };

export type Event = {
    /**
     * Unique identifier associated with this event.
     */
    id: string;

    /**
     * The title describing the event.
     */
    title: string;

    /**
     * Labels associated with the event.
     */
    labels: Record<string, string | null>;

    /**
     * The moment the event occurred.
     */
    occurrenceTime: Timestamp;
    createdAt: Timestamp;
    updatedAt: Timestamp;
};

export type EventAddedMessage = {
    /**
     * ID of workspace in which the event was added.
     */
    workspaceId: string;

    /**
     * The event that was added.
     */
    event: Event;
};

export type EventDeletedMessage = {
    /**
     * ID of workspace in which the event was deleted.
     */
    workspaceId: string;

    /**
     * ID of the event that was deleted.
     */
    eventId: string;
};

export type EventUpdatedMessage = {
    /**
     * ID of workspace in which the event was updated.
     */
    workspaceId: string;

    /**
     * The event that was updated.
     */
    event: Event;
};

export type ExpandThreadPayload = {
    threadId: string;
    threadResponse: Uint8Array;
};

export type ExtractDataRequest = {
    response: Blob;
    mimeType: string;
    query?: string;
};

export type FetchCellPendingPayload = {
    /**
     * IDs of all the cells that start fetching.
     */
    cellIds: Array<string>;
};

export type FocusCellPayload = {
    /**
     * Position to place the new focus.
     */
    focus: FocusPosition;

    /**
     * Whether or not to select the selection. If there is no current selection, one will be
     * created from the previous cursor position.
     */
    extendSelection: boolean;
    highlight?: boolean;

    /**
     * If set to `Some(Word)`, the word around the cursor position will be selected.
     */
    selectionUnit?: CursorUnit;
};

export type FocusInfoMessage = {
    /**
     * ID of the notebook.
     */
    notebookId: string;

    /**
     * User's focus within the notebook.
     */
    focus: NotebookFocus;

    /**
     * Operation ID.
     *
     * Only messages with an operation ID will receive an `Ack` from the
     * server.
     */
    opId?: string;
};

/**
 * A single focus position within a notebook.
 *
 * Focus can be placed within a cell, and optionally within separate fields
 * within the cell. An offset can be specified to indicate the exact position
 * of the cursor within a text field.
 */
export type FocusPosition = {
    /**
     * ID of the focused cell.
     *
     * May be the ID of an actual cell, or a so-called "surrogate ID", such as
     * the ID that indicates focus is on the title field.
     */
    cellId: string;

    /**
     * Key to identify which field inside a cell has focus.
     * May be `None` for cells that have only one (or no) text field.
     * E.g.: For time range cells, “to” or “from” could be used.
     *
     * Note that fields do not necessarily have to be text fields. For example,
     * we could also use this to indicate the user has focused a button for
     * graph navigation.
     */
    field?: string;

    /**
     * Offset within the text field.
     * May be `None` if the focus is not inside a text field.
     */
    offset?: number;
};

export type ForkNotebookPayload = {
    notebook: Notebook;
    originalNotebookId: string;
};

export type Formatting = Array<AnnotationWithOffset>;

export type FrontMatter = Record<string, any>;

export type FrontMatterDateTimeList = Array<FrontMatterDateTimeValue>;

export type FrontMatterDateTimeSchema = {
    displayName: string;
    iconName?: string;
    defaultValue?: FrontMatterDateTimeValue;
};

export type FrontMatterDateTimeValue = Timestamp;

export type FrontMatterGitHubPullRequest = {
    /**
     * HTML url of this GitHub pull request
     */
    htmlUrl: string;

    /**
     * Global unique ID of the GitHub pull request. GitHub assigns this ID.
     */
    id: number;

    /**
     * The owner of the repository where this pull request was created on
     */
    repoOwner: string;

    /**
     * The name of the repository where this pull request was created on
     */
    repoName: string;

    /**
     * The pull request number within this repository. Please note that GitHub
     * treats pull request and issue numbers as the same
     */
    number: number;

    /**
     * Title of the pull request
     */
    title: string;

    /**
     * Branch name of this PR
     */
    branch: string;

    /**
     * Amount of commits in this PR
     */
    commits: number;

    /**
     * Creator of the pull request
     */
    author: string;

    /**
     * GitHub Avatar URL of the author
     */
    authorAvatarUrl: string;

    /**
     * Assignee of this pull request
     */
    assignee: string | null;

    /**
     * GitHub avatar URL of the assignee
     */
    assigneeAvatarUrl: string | null;

    /**
     * Labels attached to this pull request
     */
    labels: Array<string>;

    /**
     * Reviewers requested for this pull request
     */
    reviewers: Array<string>;

    /**
     * State of the pull request
     */
    state: string;

    /**
     * Whenever the pull request is a draft
     */
    draft: boolean;

    /**
     * Whenever the pull request was merged
     */
    merged: boolean;

    /**
     * Timestamp when this pull request was created
     */
    createdAt: Timestamp;

    /**
     * Timestamp of the last update made to this pull request. Please note that
     * this includes both changes made to the pull request (e.g label added)
     * as well as actual code changes
     */
    updatedAt: Timestamp;
};

export type FrontMatterGitHubPullRequestSchema = {
    displayName: string;
    iconName?: string;
};

export type FrontMatterNumberList = Array<FrontMatterNumberValue>;

export type FrontMatterNumberSchema = {
    displayName: string;
    iconName?: string;
    allowExtraValues?: boolean;

    /**
     * The list of valid "pre-filled" options one can choose for the field.
     *
     * There is a functional difference between `None` and `Some(Vec::new())`:
     * - When `options.is_none()`, that means the current number field should
     *   not propose pre-filled values at all: this front matter field is a
     *   freeform field
     * - When `options == Some(Vec::new())` (arguably with `allow_extra_values` being true),
     *   that means that the field is supposed to be a "choose value from an enumerated list"-kind
     *   of field, but without any pre-existing values being present.
     *
     * The difference of intent between those two cases can be used on the front-end side to decide
     * how to render the front matter cell
     */
    options?: Array<FrontMatterNumberValue>;
    defaultValue?: FrontMatterNumberValue;
    prefix?: string;
    suffix?: string;
};

export type FrontMatterNumberValue = number;

export type FrontMatterPagerDutyIncident = {
    /**
     * Unique incident ID
     */
    incidentId: string;

    /**
     * User friendly identifier for the incident
     */
    number: number;

    /**
     * Title as indicated by PagerDuty
     */
    title: string;

    /**
     * The status of the incident
     */
    status: string;

    /**
     * The date the incident was created
     */
    createdAt: Timestamp;

    /**
     * Timestamp that the PagerDuty receiver was last updated.
     */
    updatedAt: Timestamp;

    /**
     * Timestamp when this incident was resolved
     */
    resolvedAt: Timestamp | null;

    /**
     * URL to the incident api endpoint
     */
    apiUrl: string;

    /**
     * URL to the incident web page
     */
    htmlUrl: string;

    /**
     * The incident key is used to deduplicate incidents.
     */
    incidentKey: string;

    /**
     * The service that the incident is associated with
     */
    service: PagerDutyResourceReference | null;
    escalationPolicy: PagerDutyResourceReference | null;
    priority: PagerDutyResourceReference | null;
    urgency: string | null;
    resolveReason: string | null;
    assignees: Array<string>;
    teams: Array<string>;
};

export type FrontMatterPagerDutyIncidentSchema = {
    displayName: string;
    iconName?: string;
};

/**
 * Front Matter Schema representation.
 *
 * The order of the elements in the schema drives the order of
 * rendering elements.
 */
export type FrontMatterSchema = Array<FrontMatterSchemaEntry>;

export type FrontMatterSchemaEntry = {
    /**
     * The key to use to target the front matter value in a notebook storage (Notebook::frontmatter).
     *
     * Currently, this key is also used to decide the "display" name of the front matter key
     */
    key: string;
    schema: FrontMatterValueSchema;
};

export type FrontMatterSchemaRow = {
    key: string;
    schema: FrontMatterValueSchema;
    value?: FrontMatterValue;
};

export type FrontMatterStringList = Array<FrontMatterStringValue>;

export type FrontMatterStringSchema = {
    displayName: string;
    iconName?: string;

    /**
     * Whether the field can have multiple values
     */
    multiple?: boolean;
    allowExtraValues?: boolean;

    /**
     * The list of valid "pre-filled" options one can choose for the field.
     *
     * There is a functional difference between `None` and `Some(Vec::new())`:
     * - When `options.is_none()`, that means the current number field should
     *   not propose pre-filled values at all: this front matter field is a
     *   freeform field
     * - When `options == Some(Vec::new())` (arguably with `allow_extra_values` being true),
     *   that means that the field is supposed to be a "choose value from an enumerated list"-kind
     *   of field, but without any pre-existing values being present.
     *
     * The difference of intent between those two cases can be used on the front-end side to decide
     * how to render the front matter cell
     */
    options?: Array<FrontMatterStringValue>;
    defaultValue?: FrontMatterStringValue;
};

export type FrontMatterStringValue = string;

export type FrontMatterUserList = Array<FrontMatterUserValue>;

export type FrontMatterUserSchema = {
    displayName: string;
    iconName?: string;
    defaultValue?: FrontMatterUserValue;

    /**
     * Whether the field can have multiple values
     */
    multiple?: boolean;
};

export type FrontMatterUserValue = {
    id: string;
    name: string;
};

/**
 * Error from validating a JSON object as a correct front matter value
 */
export type FrontMatterValidationError =
    /**
     * Impossible to deserialize the data
     */
    | { type: "format"; message: string }
    /**
     * Obtained the wrong variant
     */
    | { type: "variant"; got: string; expected: string };

/**
 * Known variants of front-matter values for runtime validation.
 *
 * Front matter values can hold extra type information to allow the API and
 * the operational transform operations to validate values before storing them.
 *
 * The usual pattern to use these values is to use the `validate_value` method
 * of `FrontMatterSchemaEntry` to check if the value has the expected type:
 *
 * ```rust
 * # use fiberplane_models::front_matter_schemas::{FrontMatterSchemaEntry, FrontMatterNumberSchema};
 * # use serde_json::json;
 * // An existing schema to check values against
 * let schema = FrontMatterSchemaEntry::builder()
 *     .key("foo")
 *     .schema(FrontMatterNumberSchema::builder()
 *         .display_name("A number field that accepts single numbers")
 *         .build())
 *     .build();
 *
 * // A value that came from an API boundary
 * let good_value_from_api = json!(42);
 * assert!(schema.validate_value(good_value_from_api).is_ok());
 *
 * // Another value (that has the wrong type)
 * let bad_value_from_api = json!("2022-10-08T13:29:00.78Z");
 * assert!(schema.validate_value(bad_value_from_api).is_err());
 * ```
 *
 * This can be used to validate the obtained value format.
 */
export type FrontMatterValue =
    /**
     * A timestamp front matter value
     */
    | FrontMatterDateTimeValue
    /**
     * A list-of-timestamps front matter value
     */
    | FrontMatterDateTimeList
    /**
     * A user front matter value
     */
    | FrontMatterUserValue
    /**
     * A list-of-users front matter value
     */
    | FrontMatterUserList
    /**
     * A string front matter value
     */
    | FrontMatterStringValue
    /**
     * A list-of-strings front matter value
     */
    | FrontMatterStringList
    /**
     * A number front matter value
     */
    | FrontMatterNumberValue
    /**
     * A list-of-numbers front matter value
     */
    | FrontMatterNumberList
    /**
     * A PagerDuty incident front matter value
     */
    | FrontMatterPagerDutyIncident
    /**
     * A GitHub pull request front matter value
     */
    | FrontMatterGitHubPullRequest;

export type FrontMatterValueSchema =
    | { type: "number" } & FrontMatterNumberSchema
    | { type: "string" } & FrontMatterStringSchema
    | { type: "date_time" } & FrontMatterDateTimeSchema
    | { type: "user" } & FrontMatterUserSchema
    | { type: "pagerduty_incident" } & FrontMatterPagerDutyIncidentSchema
    | { type: "github_pull_request" } & FrontMatterGitHubPullRequestSchema;

export type GraphCell = {
    id: string;

    /**
     * Links to the data to render in the graph.
     */
    dataLinks?: Array<string>;
    graphType: GraphType;
    readOnly?: boolean;
    stackingType: StackingType;
};

export type GraphType =
    | "bar"
    | "line";

export type HeadingCell = {
    id: string;
    headingType: HeadingType;
    content: string;

    /**
     * Optional formatting to be applied to the cell's content.
     */
    formatting?: Formatting;
    readOnly?: boolean;
};

export type HeadingType =
    | "h1"
    | "h2"
    | "h3";

/**
 * Possible errors that may happen during an HTTP request.
 */
export type HttpRequestError =
    | { type: "offline" }
    | { type: "no_route" }
    | { type: "connection_refused" }
    | { type: "timeout" }
    | { type: "response_too_big" }
    | { type: "server_error"; statusCode: number; response: Uint8Array }
    | { type: "other"; reason: string };

export type ImageCell = {
    id: string;
    fileId?: string;

    /**
     * Used to indicates the upload progress.
     * If file_id is set this shouldn't be set
     * Also: if no progress is set and no file_id exists
     * it means the cell is in the initial state (ready for upload)
     */
    progress?: number;
    readOnly?: boolean;
    width?: number;
    height?: number;

    /**
     * Will contain a hash to show as a preview for the image
     */
    preview?: string;

    /**
     * URL of the image if it was originally hosted on a remote server.
     * This will not be set if the image was uploaded through the
     * Fiberplane Studio.
     */
    url?: string;
};

/**
 * Specifies the given cell must be inserted at the given index.
 */
export type InsertCellChange = {
    cell: Cell;
    index: number;
};

/**
 * Inserts front matter keys in a notebook, with optional initial values
 */
export type InsertFrontMatterSchemaChange = {
    toIndex: number;
    insertions: Array<FrontMatterSchemaRow>;
};

/**
 * Adds front matter entries in a notebook
 */
export type InsertFrontMatterSchemaOperation = {
    /**
     * The Front Matter Schema key that is just before the insertion point. This
     * is solely used for consistency checks when validating the operation.
     */
    keyOfEntryBeforeInsertionLocation?: string;

    /**
     * The Front Matter Schema key that is just after the insertion point. This
     * is solely used for consistency checks when validating the operation.
     */
    keyOfEntryAfterInsertionLocation?: string;

    /**
     * The index to insert the new front matter schema into
     */
    toIndex: number;

    /**
     * The new entries to add to the front matter schema, with their new values
     */
    insertions: Array<FrontMatterSchemaRow>;
};

export type InsertTableColumnOperation = {
    /**
     * ID of the table cell.
     */
    cellId: string;

    /**
     * Definition for the column.
     */
    columnDef: TableColumnDefinition;

    /**
     * The index at which to insert the column.
     */
    index: number;

    /**
     * The values to insert in the column.
     *
     * The amount of values should match the amount of rows in the table.
     */
    values: Array<TableRowValue>;
};

export type InsertTableRowOperation = {
    /**
     * ID of the table cell.
     */
    cellId: string;

    /**
     * The row being inserted.
     */
    row: TableRow;

    /**
     * The index at which to insert the row.
     */
    index: number;
};

export type IntegrationStatus =
    | { type: "connected" }
    | { type: "disconnected" }
    | { type: "attention_required"; reason: string };

export type InvalidFrontMatterRejectReason = {
    /**
     * The front matter key that has an issue
     */
    problemKey: string;

    /**
     * The inner problem encountered
     */
    error: FrontMatterValidationError;
};

export type InvalidLabelRejectReason = {
    /**
     * The key of the label that was invalid.
     */
    key: string;

    /**
     * The specific reason why the label was invalid.
     */
    validationError: LabelValidationError;
};

/**
 * Labels that are associated with a Notebook.
 */
export type Label = {
    /**
     * The key of the label. Should be unique for a single Notebook.
     */
    key: string;

    /**
     * The value of the label. Can be left empty.
     */
    value: string;
};

export type LabelValidationError =
    | "empty_key"
    | "empty_name"
    | "name_too_long"
    | "name_invalid_characters"
    | "empty_prefix"
    | "prefix_too_long"
    | "prefix_invalid_characters"
    | "value_too_long"
    | "value_invalid_characters";

export type ListItemCell = {
    id: string;
    content: string;

    /**
     * Optional formatting to be applied to the cell's content.
     */
    formatting?: Formatting;
    listType: ListType;
    level?: number;
    readOnly?: boolean;
    startNumber?: number;
};

export type ListType =
    | "ordered"
    | "unordered";

export type LoadNotebookPayload = {
    /**
     * JSON response of the notebook, as sent by the API.
     *
     * Will be parsed into a `Notebook` struct.
     */
    notebookResponse: Uint8Array;
};

export type LoadThreadsPayload = {
    /**
     * JSON response of the notebook, as sent by the API.
     *
     * Will be parsed into a `Vec<ThreadSummary>`.
     */
    threadsResponse: Uint8Array;
};

export type LocationInfo = {
    cellId: string;
};

export type LogCell = {
    id: string;

    /**
     * Links to the data to render in the log.
     */
    dataLinks?: Array<string>;
    readOnly?: boolean;
    displayFields?: Array<string>;
    hideSimilarValues?: boolean;
    expandedIndices?: Array<LogRecordIndex>;
    visibilityFilter?: LogVisibilityFilter;
    selectedIndices?: Array<LogRecordIndex>;
    highlightedIndices?: Array<LogRecordIndex>;
};

/**
 * A single expanded row of log records, as identified by [key] and [index]
 * pointing into the source data of the LogCell.
 */
export type LogRecordIndex = {
    /**
     * Index of the data link that produced the log record.
     */
    linkIndex: number;

    /**
     * Index of the record within the data of a single data link.
     */
    recordIndex: number;
};

export type LogVisibilityFilter =
    | "all"
    | "selected"
    | "highlighted";

export type MarkPendingOperationsSentPayload = {
    /**
     * The revisions of the pending operations that have been sent.
     */
    revisions: Array<number>;

    /**
     * The operation ID associated with the submitted operations.
     */
    opId: string;
};

export type Membership = {
    id: string;
    email: string;
    name: string;
    role: AuthRole;
};

/**
 * Annotation for the mention of a user.
 *
 * Mentions do not have a start and end offset. Instead, they occur at the
 * start offset only and are expected to run up to the end of the name of
 * the mentioned user. If however, for unforeseen reasons, the plain text
 * being annotated does not align with the name inside the mention, the
 * mention will stop at the first non-matching character. Mentions for
 * which the first character of the name does not align must be ignored in
 * their entirety.
 */
export type Mention = {
    name: string;
    userId: string;
};

export type MentionMessage = {
    /**
     * ID of the notebook in which the user was mentioned.
     */
    notebookId: string;

    /**
     * ID of the cell in which the user was mentioned.
     */
    cellId: string;

    /**
     * Who mentioned the user?
     */
    mentionedBy: MentionedBy;
};

export type MentionedBy = {
    name: string;
};

/**
 * A single metric value.
 *
 * Metric values are taken at a specific timestamp and contain a floating-point
 * value as well as OpenTelemetry metadata.
 */
export type Metric = {
    time: Timestamp;
    value: number;
} & OtelMetadata;

/**
 * Moves the cells with the given IDs to the given index.
 */
export type MoveCellsChange = {
    /**
     * One or more IDs of cells to move. If multiple IDs are given, they must be consecutive.
     */
    cellIds: Array<string>;

    /**
     * The index where the cells will be reinserted.
     *
     * This is the index excluding the moved cells themselves. This makes it impossible for the
     * index to refer to the range of cells being moved.
     */
    index: number;
};

/**
 * Moves one or more cells.
 */
export type MoveCellsOperation = {
    /**
     * IDs of all the cells to be moved.
     *
     * These must be adjacent and given in the order they appear in the notebook.
     */
    cellIds: Array<string>;

    /**
     * Index the cells will be moved from. This is the index of the first cell before the move.
     */
    fromIndex: number;

    /**
     * Index the cells will be moved to. This is the index of the first cell after the move.
     */
    toIndex: number;
};

export type MoveCursorPayload = {
    /**
     * The delta to move.
     *
     * `1` means to move one position to the right, while `-1` means to move one position to the
     * left. Currently only `1` and `-1` are supported as values, but other values may be supported
     * in the future.
     */
    delta: number;

    /**
     * Whether or not to select the selection. If there is no current selection, one will be
     * created from the previous cursor position.
     */
    extendSelection: boolean;

    /**
     * Default cursor navigation moves one grapheme cluster at a time, but other units (such as
     * words) may be desirable. For instance, when modifier keys are used.
     */
    unit: CursorUnit;
};

/**
 * Moves a front matter entry in a notebook
 */
export type MoveFrontMatterSchemaChange = {
    keys: Array<string>;

    /**
     * Index the key will be moved from. This is the index of the first front matter key before the move.
     */
    fromIndex: number;

    /**
     * Index the key will be moved to. This is the index of the first front matter key after the move.
     */
    toIndex: number;
};

/**
 * Moves front matter entries in a notebook
 */
export type MoveFrontMatterSchemaOperation = {
    /**
     * The keys that will be moved in the front matter. They should be a range of
     * consecutive front matter entries, matching the existing front matter schema
     * at the index pointed to by `from_index`
     */
    keys: Array<string>;

    /**
     * Index the key will be moved from. This is the index of the first front matter key before the move.
     */
    fromIndex: number;

    /**
     * Index the key will be moved to. This is the index of the first front matter key after the move.
     */
    toIndex: number;
};

/**
 * This is a user-specified name for a Fiberplane resource.
 *
 * Names must:
 * - be between 1 and 63 characters long
 * - start and end with an alphanumeric character
 * - contain only lowercase alphanumeric ASCII characters and dashes
 *
 * Names must be unique within a namespace such as a Workspace.
 */
export type Name = string;

export type NewNotebook = {
    title: string;
    cells: Array<Cell>;
    timeRange: NewTimeRange;
    selectedDataSources: SelectedDataSources;
    labels: Array<Label>;
    frontMatter: FrontMatter;

    /**
     * The "inline" front matter schema for the notebook.
     *
     * It will always be expanded first and have priority over anything defined in
     * `front_matter_collections`. The keys in common will be ignored.
     */
    frontMatterSchema: FrontMatterSchema;

    /**
     * A list of front matter schema names that exist in the target workspace.
     *
     * If `front_matter_collections` and `front_matter_schema` are both mentioned, then:
     * - the `front_matter_schema` will be the first elements of the notebook front matter,
     * - and keys from the named schema that already exist in `front_matter_schema` (or an earlier
     *   entry in the list) will be ignored.
     */
    frontMatterCollections?: Array<Name>;
};

/**
 * A new PagerDuty receiver. This will be used in the create endpoint.
 */
export type NewPagerDutyReceiver = {
    /**
     * A reference to a template that will be expanded when a incident is
     * created. If this is empty then no template will be expanded.
     */
    incidentCreatedTemplateName: Name | null;

    /**
     * A secret as defined by PagerDuty when creating the webhook. This secret
     * will be use to verify that any incoming webhooks are valid.
     */
    secret: string | null;
};

export type NewSnippet = {
    name: Name;
    description: string;
    body: string;
};

export type NewTimeRange =
    | TimeRange
    | RelativeTimeRange;

export type NewView = {
    name: Name;
    displayName?: string;
    description: string;
    color: number;
    labels: Array<Label>;
    relativeTime?: RelativeTime;
    sortBy?: NotebookSortFields;
    sortDirection?: SortDirection;
};

export type NewWebhook = {
    endpoint: string;
    events: Array<WebhookCategory>;
    enabled: boolean;
};

/**
 * Payload to create a new organization workspace.
 */
export type NewWorkspace = {
    name: Name;

    /**
     * The display name of the workspace. The `name` will be used if none is provided.
     */
    displayName?: string;
    defaultDataSources?: SelectedDataSources;
    frontMatterSchemas?: WorkspaceFrontMatterSchemas;
};

/**
 * Payload to be able to invite someone to a workspace.
 */
export type NewWorkspaceInvite = {
    email: string;
    role: AuthRole;
};

export type Notebook = {
    id: string;
    workspaceId: string;
    createdAt: Timestamp;
    updatedAt: Timestamp;
    timeRange: TimeRange;
    title: string;
    cells: Array<Cell>;
    revision: number;
    visibility: NotebookVisibility;
    readOnly: boolean;
    createdBy: CreatedBy;
    selectedDataSources: SelectedDataSources;
    labels: Array<Label>;
    frontMatter: FrontMatter;
    frontMatterSchema: FrontMatterSchema;
};

export type NotebookAction =
    | { type: "add_comment"; payload: AddCommentPayload }
    | { type: "add_thread"; payload: AddThreadPayload }
    | { type: "apply_own_operation"; payload: ApplyOwnOperationPayload }
    | { type: "blur" }
    | { type: "change_context_menu_type"; payload: NotebookContextMenuType }
    | { type: "change_thread_status"; payload: ChangeThreadStatusPayload }
    | { type: "clear_console_messages" }
    | { type: "close_context_menu" }
    | { type: "courier"; payload: CourierAction }
    | { type: "delete_comment"; payload: DeleteCommentPayload }
    | { type: "delete_from_cursor"; payload: DeleteFromCursorPayload }
    | { type: "delete_link_at_cursor" }
    | { type: "delete_thread"; payload: DeleteThreadPayload }
    | { type: "edit_comment"; payload: EditCommentPayload }
    | { type: "expand_console" }
    | { type: "expand_thread"; payload: ExpandThreadPayload }
    | { type: "end_active_formatting" }
    | { type: "fetch_cell_pending"; payload: FetchCellPendingPayload }
    | { type: "focus_cell"; payload: FocusCellPayload }
    | { type: "fork_notebook"; payload: ForkNotebookPayload }
    | { type: "load_notebook"; payload: LoadNotebookPayload }
    | { type: "load_threads"; payload: LoadThreadsPayload }
    | { type: "mark_pending_operations_sent"; payload: MarkPendingOperationsSentPayload }
    | { type: "move_cursor"; payload: MoveCursorPayload }
    | { type: "notebook_error"; payload: NotebookErrorPayload }
    | { type: "open_context_menu"; payload: NotebookContextMenuInfo }
    | { type: "provider_error"; payload: ProviderErrorPayload }
    | { type: "provider_response"; payload: ProviderResponsePayload }
    | { type: "redo" }
    | { type: "remove_focus_highlight" }
    | { type: "replace_cells"; payload: ReplaceCellsPayload }
    | { type: "replace_text"; payload: ReplaceTextPayload }
    | { type: "resolve_cell_errors"; payload: ResolveCellErrorsPayload }
    | { type: "set_cell_live"; payload: SetCellLivePayload }
    | { type: "set_console_height"; payload: number }
    | { type: "set_focus"; payload: NotebookFocus }
    | { type: "set_visibility"; payload: NotebookVisibility }
    | { type: "show_console_message"; payload: ConsoleMessage }
    | { type: "split_cell"; payload: SplitCellPayload }
    | { type: "start_forking"; payload: StartForkingPayload }
    | { type: "start_loading"; payload: string }
    | { type: "stop_user_typing"; payload: StopUserTypingPayload }
    | { type: "toggle_console_expanded" }
    | { type: "toggle_formatting"; payload: Annotation }
    | { type: "toggle_timeseries_visibility"; payload: ToggleTimeseriesVisibilityPayload }
    | { type: "undo" }
    | { type: "update_link_at_cursor"; payload: string };

export type NotebookContextMenuInfo = {
    cellId: string;
    cursorRect?: Rect;
    field?: string;
    menuType: NotebookContextMenuType;
    typeAheadOffset?: number;
    typeAheadText?: string;
};

export type NotebookContextMenuType =
    | "at_menu"
    | "auto_suggest"
    | "auto_suggest_labels"
    | "cell_actions"
    | "date_picker"
    | "slash_command";

export type NotebookErrorPayload = {
    error: string;
};

/**
 * Specifies the user's focus and optional selection within the notebook.
 */
export type NotebookFocus =
    /**
     * The user has no focus within the notebook.
     */
    | { type: "none" }
    /**
     * The user focus is within the notebook and the focus is on a single
     * position. I.e. there is no selection.
     */
    | { type: "collapsed" } & FocusPosition
    /**
     * The user has a selection within the notebook that started at the given
     * anchor position and ends at the given focus position.
     */
    | { type: "selection"; anchor: FocusPosition; focus: FocusPosition };

export type NotebookReducerResult = {
    stateUpdate: NotebookStateUpdate;
    sideEffects: Array<SideEffectDescriptor>;
};

/**
 * Notebook search parameters
 */
export type NotebookSearch = {
    labels?: Record<string, string | null>;
    relativeTime?: RelativeTime;
    view?: Name;
};

export type NotebookSortFields =
    | "title"
    | "created_at"
    | "updated_at";

/**
 * The state of the notebook itself, plus book-keeping state, such as for editing and presence.
 * Properties that may be expensive to clone or serialize are wrapped in `Rc` to avoid
 * synchronization overhead.
 *
 * ## About revisions
 *
 * This state contains three different types of revisions: `revision`, `confirmed_revision` and
 * `remote_revision`. The first is easiest to explain: `revision` (could also be called
 * `local_revision`) is the revision of the notebook as the user sees it. It contains any changes
 * they applied locally, even if they have not yet been confirmed by the server.
 *
 * This leads us to `confirmed_revision`. The confirmed revision is the latest revision that has
 * both been applied to the local state (meaning it must be smaller than or equal to `revision`),
 * and which has been confirmed by the server.
 *
 * Finally, there's `remote_revision`, which is optional. `remote_revision` is set if-and-only-if
 * we know the server is ahead of our confirmed revision (meaning it must be greater than
 * `confirmed_revision`), while we have not yet been able to apply those revisions to our local
 * state.
 *
 * ### Examples
 *
 * When a notebook is loaded from the REST API, the server response includes a `revision` property.
 * Based on this response, we initialize both our own `revision` and `confirmed_revision` fields,
 * because we know them to be equal.
 *
 * If we then apply local changes, we first bump `revision`, while `confirmed_revision` stays the
 * same until we receive the corresponding `ack` messages from the server. Only then does
 * `confirmed_revision` get increased.
 *
 * If we receive an incoming operation from the server that we can apply cleanly, we bump both
 * `revision` and `confirmed_revision` again. But if we cannot apply the incoming operation,
 * and we cannot rebase *yet*, the incoming operation gets queued into
 * `pending_incoming_operations` and the `remote_revision` gets bumped accordingly. If at a later
 * time, we catch up to the server revision again, we reset `remote_revision` back to `None`.
 */
export type NotebookState = {
    /**
     * Notebook cells.
     */
    cells: Array<CellWithMetadata>;

    /**
     * The latest revision that has been confirmed by the server and that we have applied to our
     * local state.
     */
    confirmedRevision: number;

    /**
     * State belonging to the console we use for displaying feedback.
     */
    console: ConsoleState;

    /**
     * When was the notebook created?
     */
    createdAt: Timestamp;

    /**
     * Who created the notebook?
     */
    createdBy: CreatedBy;

    /**
     * State specific to the editing functionality, which does not get
     * communicated through operations, such as cursor position and whether a
     * context menu is opened.
     */
    editor: EditorState;

    /**
     * Error that occurred during loading, or (in a really exceptional case) a
     * fatal error that prevents further editing.
     */
    error: string | null;

    /**
     * Revision at which the notebook was forked. This is set at the start of a fork operation so
     * that we know which operations still need to be synced once the forking is complete.
     */
    forkedAt?: number;
    frontMatter: FrontMatter;
    frontMatterSchema: FrontMatterSchema;

    /**
     * Notebook ID.
     */
    id: string;
    labels: Array<Label>;

    /**
     * Is the notebook currently loading?
     */
    loading: boolean;

    /**
     * Operations received by the Courier, which have not yet been applied to the notebook.
     */
    pendingIncomingOperations: Array<VersionedOperation>;

    /**
     * Operations applied to the notebook, but which have not yet been confirmed by the server.
     */
    pendingOperations: Array<PendingOperation>;

    /**
     * Whether the notebook is read-only for the current user.
     */
    readOnly: boolean;

    /**
     * Revision that is set only when we know the server is ahead of the confirmed revision, but
     * we have not yet caught up to this revision locally.
     */
    remoteRevision?: number;

    /**
     * Our (local) notebook revision.
     */
    revision: number;

    /**
     * Selected data sources as a map from provider type to data source name.
     */
    selectedDataSources: SelectedDataSources;

    /**
     * User sessions of people that are subscribed to the notebook.
     */
    subscriberSessions: Array<SubscriberSession>;
    threads: Record<string, ThreadDetails>;

    /**
     * Notebook time range.
     */
    timeRange: TimeRange;

    /**
     * Notebook title.
     */
    title: string;

    /**
     * Undo/redo state.
     */
    undoRedo: UndoRedo;

    /**
     * Whether the notebook is in an unrecoverable state due to sync errors.
     */
    unrecoverable: boolean;

    /**
     * When was the notebook last updated?
     */
    updatedAt: Timestamp;

    /**
     * Specifies who can access the notebook.
     */
    visibility: NotebookVisibility;
    workspaceId: string;
};

/**
 * Used for communicating updates to the `NotebookState` back to TypeScript.
 *
 * It contains the same properties as `NotebookState` (with a few exceptions), but wrapped in
 * `Option`. All properties with `Some` value have been updated, while the others should be
 * considered unchanged.
 *
 * TODO (Arend): Should we generate this type and the accompanying diffing logic using a macro?
 *               It would save us quite some repetition, and generating the type looks like
 *               it would be easy enough (bar exceptions), but I'm afraid the macro might become
 *               quite complex for the types for which we wanted to do nested diffing, and it
 *               might feel too magical if you still need to implement the diffing manually for
 *               a generated type...
 */
export type NotebookStateUpdate = {
    /**
     * Cells are communicated through a dedicated `CellsUpdate` type for efficiency.
     */
    cells?: CellsUpdate;
    confirmedRevision?: number;
    console?: ConsoleState;
    createdAt?: string;
    createdBy?: CreatedBy;

    /**
     * Editor state is communicated through a dedicated `EditorStateUpdate` type for efficiency.
     */
    editor?: EditorStateUpdate;
    error?: string | null;
    forkedAt?: number | null;
    frontMatter?: FrontMatter;
    frontMatterSchema?: FrontMatterSchema;
    id?: string;
    labels?: Array<Label>;
    loading?: boolean;
    numPendingIncomingOperations?: number;

    /**
     * Pending operations are communicated through a dedicated type for efficiency.
     */
    pendingOperations?: PendingOperationsUpdate;
    readOnly?: boolean;
    remoteRevision?: number | null;
    revision?: number;
    selectedDataSources?: SelectedDataSources;
    subscriberSessions?: Array<SubscriberSession>;
    threads?: Record<string, ThreadDetails>;
    timeRange?: TimeRange;
    title?: string;
    unrecoverable?: boolean;
    updatedAt?: string;
    visibility?: NotebookVisibility;
    workspaceId?: string;
};

export type NotebookSummary = {
    id: string;
    workspaceId: string;
    createdAt: Timestamp;
    updatedAt: Timestamp;
    title: string;
    visibility: NotebookVisibility;
    createdBy: CreatedBy;
    labels: Array<Label>;
};

export type NotebookVisibility =
    | "private"
    | "public";

/**
 * An operation is the representation for a mutation to be performed to a notebook.
 *
 * Operations are intended to be atomic (they should either be performed in their entirety or not
 * at all), while also capturing the intent of the user.
 *
 * For more information, please see RFC 8:
 *   https://www.notion.so/fiberplane/RFC-8-Notebook-Operations-f9d18676d0d9437d81de30faa219deb4
 */
export type Operation =
    | { type: "move_cells" } & MoveCellsOperation
    | { type: "replace_cells" } & ReplaceCellsOperation
    | { type: "replace_text" } & ReplaceTextOperation
    | { type: "update_notebook_time_range" } & UpdateNotebookTimeRangeOperation
    /**
     * **Deprecated:** Please use `ReplaceText` with `cell_id == TITLE_CELL_ID` instead.
     */
    | { type: "update_notebook_title" } & UpdateNotebookTitleOperation
    | { type: "set_selected_data_source" } & SetSelectedDataSourceOperation
    | { type: "add_label" } & AddLabelOperation
    | { type: "replace_label" } & ReplaceLabelOperation
    | { type: "remove_label" } & RemoveLabelOperation
    | { type: "clear_front_matter" } & ClearFrontMatterOperation
    | { type: "insert_front_matter_schema" } & InsertFrontMatterSchemaOperation
    | { type: "update_front_matter_schema" } & UpdateFrontMatterSchemaOperation
    | { type: "move_front_matter_schema" } & MoveFrontMatterSchemaOperation
    | { type: "remove_front_matter_schema" } & RemoveFrontMatterSchemaOperation
    /**
     * **Deprecated:** Full front matter updates should be avoided, granular update operations are
     * better for conflict handling.
     */
    | { type: "update_front_matter" } & UpdateFrontMatterOperation
    | { type: "insert_table_column" } & InsertTableColumnOperation
    | { type: "remove_table_column" } & RemoveTableColumnOperation
    | { type: "update_table_column_definition" } & UpdateTableColumnDefinitionOperation
    | { type: "insert_table_row" } & InsertTableRowOperation
    | { type: "remove_table_row" } & RemoveTableRowOperation;

/**
 * Metadata following the OpenTelemetry metadata spec.
 *
 * See also: https://github.com/open-telemetry/opentelemetry-specification/
 */
export type OtelMetadata = {
    /**
     * OpenTelemetry attributes.
     *
     * See also: https://github.com/open-telemetry/opentelemetry-specification/blob/main/specification/common/README.md
     */
    attributes: Record<string, any>;

    /**
     * Resource metadata.
     *
     * See also: https://github.com/open-telemetry/opentelemetry-specification/blob/main/specification/resource/semantic_conventions/README.md
     */
    resource: Record<string, any>;
    traceId?: OtelTraceId;
    spanId?: OtelSpanId;
};

/**
 * SeverityNumber, as specified by OpenTelemetry:
 *  https://github.com/open-telemetry/opentelemetry-specification/blob/main/specification/logs/data-model.md#field-severitynumber
 */
export type OtelSeverityNumber = number;

/**
 * Span ID, as specified by OpenTelemetry:
 *  https://opentelemetry.io/docs/reference/specification/overview/#spancontext
 */
export type OtelSpanId = Uint8Array;

/**
 * Trace ID, as specified by OpenTelemetry:
 *  https://opentelemetry.io/docs/reference/specification/overview/#spancontext
 */
export type OtelTraceId = Uint8Array;

export type OutdatedRejectReason = {
    /**
     * The current revision for the notebook.
     */
    currentRevision: number;
};

/**
 * PagerDutyReceiver represents a single PagerDuty receiver in Fiberplane.
 */
export type PagerDutyReceiver = {
    /**
     * Unique identifier for the PagerDuty receiver for a workspace.
     */
    name: Name;

    /**
     * A reference to a template that will be expanded when a incident is
     * created. If this is empty then no template will be expanded.
     */
    incidentCreatedTemplateName: Name | null;

    /**
     * The URL that should be set in the PagerDuty webhook.
     */
    webhookUrl: string;

    /**
     * A secret is set on the PagerDuty receiver. This will verify any incoming
     * webhooks against this secret and drops requests that do no pass.
     */
    secretSet: boolean;

    /**
     * Timestamp that the PagerDuty receiver was created.
     */
    createdAt: Timestamp;

    /**
     * Timestamp that the PagerDuty receiver was last updated.
     */
    updatedAt: Timestamp;
};

/**
 * Errors that can occur when creating a new PagerDuty receiver.
 */
export type PagerDutyReceiverCreateError =
    | { error: "duplicate_name" }
    | { error: "creation_template_not_found" }
    | { error: "internal_server_error" }
    /**
     * Common auth errors.
     */
    | { error: "auth"; details: AuthError };

export type PagerDutyResourceReference = {
    id: string;
    summary: string;
    apiUrl: string;
    htmlUrl: string;
    type: PagerDutyResourceReferenceType;
};

export type PagerDutyResourceReferenceType =
    | "EscalationPolicy"
    | "Incident"
    | "IncidentWorkflow"
    | "Priority"
    | "Service"
    | "Team"
    | "User"
    | "WorkflowTrigger";

/**
 * A struct that represents the pagination of a list endpoint.
 *
 * Some list endpoints support pagination by passing in querystring parameters,
 * which are `page` and `limit`. This Pagination object is used to be used
 * within api client when making a request.
 *
 * Note that not all endpoints support pagination and some endpoints might
 * ignore values that are too high.
 */
export type Pagination = {
    page: number;
    limit: number;
};

export type PendingOperation = {
    operation: Operation;
    revision: number;
    state: PendingOperationState;
    errorMessage: string | null;
    undoOptions: UndoOptions;
};

export type PendingOperationState =
    | { type: "queued" }
    | { type: "sent"; opId: string };

/**
 * We only send back how many operations there are, because the operations themselves can cause
 * quite some serialization overhead.
 *
 * `get_unsent_pending_operations_for_notebook()` can be used to get the unsent pending operations
 * out of the state when it is needed.
 */
export type PendingOperationsUpdate = {
    numOperations: number;
    numUnsentOperations: number;
};

export type PersonalIntegrationId =
    | "github";

export type PersonalIntegrationSummary = {
    id: PersonalIntegrationId;
    status: IntegrationStatus;
    createdAt?: Timestamp;
    updatedAt?: Timestamp;
};

export type PresenceAction =
    | { type: "courier"; payload: CourierAction };

export type PresenceReducerResult = {
    state: PresenceState;
    sideEffects: Array<SideEffectDescriptor>;
};

/**
 *\n * Contains the workspace related presence of users on notebooks.\n * Unlike the notebook_reducer, which tracks the presence of a specific active notebook and implements\n * proper handling of it's cell / focus changes as well.\n 
 */
export type PresenceState = {
    notebookPresence: Record<string, Array<SubscriberSession>>;
};

export type Profile = {
    id: string;
    email: string;
    name: string;
    defaultWorkspaceId: string;
    defaultWorkspaceName: Name;
    roles: Record<string, AuthRole>;
};

export type ProviderCell = {
    id: string;

    /**
     * The intent served by this provider cell.
     *
     * See: https://www.notion.so/fiberplane/RFC-45-Provider-Protocol-2-0-Revised-4ec85a0233924b2db0010d8cdae75e16#c8ed5dfbfd764e6bbd5c5b79333f9d6e
     */
    intent: string;

    /**
     * Query data encoded as `"<mime-type>,<data>"`, where the MIME type is
     * either `"application/x-www-form-urlencoded"` or `"multipart/form-data"`.
     * This is used for storing data for the Query Builder.
     *
     * Note: The format follows the specification for data URLs, without the
     *       `data:` prefix. See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URLs
     */
    queryData?: string;

    /**
     * Optional response data from the provider.
     */
    response?: EncodedBlob;

    /**
     * Optional list of generated output cells.
     */
    output?: Array<Cell>;
    readOnly?: boolean;
};

export type ProviderErrorPayload = {
    /**
     * ID of the cell we were invoking the provider of.
     */
    cellId: string;
    error: Error;
};

/**
 * A single event that is used within providers.
 *
 * Events occur at a given time and optionally last until a given end time.
 * They may contain both event-specific metadata as well as OpenTelemetry
 * metadata.
 */
export type ProviderEvent = {
    time: Timestamp;
    endTime?: Timestamp;
    title: string;
    description?: string;
    severity?: OtelSeverityNumber;
    labels: Record<string, string>;
} & OtelMetadata;

export type ProviderResponsePayload = {
    /**
     * ID of the cell we were invoking the provider of.
     */
    cellId: string;

    /**
     * The new output cells.
     */
    output: Array<Cell>;

    /**
     * The provider response to store in the cell.
     *
     * May be omitted if the provider responded with one of the cells MIME
     * types.
     */
    response?: Blob;
};

export type ProviderType = string;

export type Rect = {
    height: number;
    width: number;
    x: number;
    y: number;
};

/**
 * Reason why the apply operation was rejected.
 */
export type RejectReason =
    /**
     * The operation referenced an invalid cell index.
     */
    | { type: "cell_index_out_of_bounds" }
    /**
     * The operation referenced a non-existing cell.
     */
    | { type: "cell_not_found"; cellId: string }
    /**
     * The operation tried to insert a cell with a non-unique ID.
     */
    | { type: "duplicate_cell_id"; cellId: string }
    /**
     * A label was submitted for already exists for the notebook.
     */
    | { type: "duplicate_label" } & DuplicateLabelRejectReason
    /**
     * The operation tried to insert a table row or column with a non-unique ID.
     */
    | { type: "duplicate_table_id"; tableId: string }
    /**
     * The operation failed some miscellaneous precondition.
     */
    | { type: "failed_precondition"; message: string }
    /**
     * A label was submitted that was invalid.
     */
    | { type: "invalid_label" } & InvalidLabelRejectReason
    /**
     * Current notebook state does not match old state in operation.
     */
    | { type: "inconsistent_state" }
    /**
     * A table operation specified a column index that is out of bounds.
     */
    | { type: "invalid_table_column_index" }
    /**
     * A table operation specified a row index that is out of bounds.
     */
    | { type: "invalid_table_row_index" }
    /**
     * A table operation specified values that didn't match the expected amount
     * of columns or rows.
     */
    | { type: "invalid_table_dimensions" }
    /**
     * A table operation tried to reference a row or column with a non-existing
     * ID.
     */
    | { type: "invalid_table_id"; table_id: string }
    /**
     * Current notebook state does not match old state in operation.
     */
    | { type: "inconsistent_front_matter"; message: string }
    /**
     * The front matter updates in the operation does not apply cleanly to the notebook.
     *
     * The most common occurrence of this will be trying to insert/modify values that don’t
     * match the schema given in the operation.
     */
    | { type: "invalid_front_matter_update" } & InvalidFrontMatterRejectReason
    /**
     * Attempted to perform a table operation on a non-table cell.
     */
    | { type: "no_table_cell"; cellId: string }
    /**
     * Attempted to perform a text operation on a non-text cell.
     */
    | { type: "no_text_cell"; cellId: string }
    /**
     * The requested apply operation was for an old version.
     */
    | { type: "outdated" } & OutdatedRejectReason
    /**
     * The operation is unknown, and cannot be validated.
     */
    | { type: "unknown_operation"; operationSummary: string };

/**
 * Message sent when an apply operation was rejected by the server.
 */
export type RejectedMessage = {
    /**
     * The reason why the operation was rejected.
     */
    reason: RejectReason;

    /**
     * Operation ID.
     *
     * Only messages with an operation ID will receive an `Ack` from the
     * server.
     */
    opId?: string;
};

export type RelativeTime = {
    unit: TimeUnit;
    value: number;
};

/**
 * A relative time range specified in minutes.
 *
 * A negative value means the time range starts at the given amount of
 * `minutes` of to *now*. A positive value (including zero) means the time
 * range starts now and ends `minutes` from now.
 *
 * Relative time ranges are expanded to absolute time ranges upon instantiation
 * of a notebook.
 */
export type RelativeTimeRange = {
    minutes: number;
};

/**
 * Removes a front matter key in a notebook
 */
export type RemoveFrontMatterSchemaChange = {
    keys: Array<string>;
    oldValues: Array<FrontMatterValue | null>;
};

/**
 * Removes front matter entries in a notebook
 */
export type RemoveFrontMatterSchemaOperation = {
    /**
     * The key of the front matter schema element lying just before the deletion range, i.e. _not_ removed.
     * This is used to make consistency checks when validating operation.
     */
    keyOfEntryBeforeDeletionRange?: string;

    /**
     * The key of the front matter schema element lying just after the deletion range, i.e. _not_ removed.
     * This is used to make consistency checks when validating operation.
     */
    keyOfEntryAfterDeletionRange?: string;

    /**
     * The index to start removing elements from. This is the index of the first element that will be
     * deleted, and should match the first element of the `deletions` array.
     */
    fromIndex: number;

    /**
     * Elements that should be deleted, with their last known values
     */
    deletions: Array<FrontMatterSchemaRow>;
};

/**
 * Specifies the given label must be removed.
 */
export type RemoveLabelChange = {
    /**
     * The label that was removed.
     */
    label: Label;
};

/**
 * Remove an label in an notebook.
 */
export type RemoveLabelOperation = {
    label: Label;
};

export type RemoveTableColumnOperation = {
    /**
     * ID of the table cell.
     */
    cellId: string;

    /**
     * Definition of the column being removed.
     */
    columnDef: TableColumnDefinition;

    /**
     * The index of the column being removed.
     */
    index: number;

    /**
     * The values that are being removed together with the column.
     *
     * The amount of values should match the amount of rows in the table.
     */
    values: Array<TableRowValue>;
};

export type RemoveTableRowOperation = {
    /**
     * ID of the table cell.
     */
    cellId: string;

    /**
     * The row being removed.
     */
    row: TableRow;

    /**
     * The index of the row being removed.
     */
    index: number;
};

/**
 * Replaces one or more cells at once.
 *
 * Note: This operation is relatively coarse and can be (ab)used to perform
 * `ReplaceText` operations as well. In order to preserve intent as much as
 * possible, please use `ReplaceText` where possible.
 *
 * Note: This operation may not be used to move cells, other than the necessary
 * corrections in cell indices that account for newly inserted and removed
 * cells. Attempts to move cells to other indices will cause validation to
 * fail.
 */
export type ReplaceCellsOperation = {
    /**
     * Vector of the new cells, including their new indices.
     *
     * Indices of the new cells must be ordered incrementally to form a single,
     * cohesive range of cells.
     *
     * Note that "new" does not imply "newly inserted". If a cell with the same
     * ID is part of the `old_cells` field, it will merely be updated. Only
     * cells in the `new_cells` field that are not part of the `old_cells` will
     * be newly inserted.
     */
    newCells?: Array<CellWithIndex>;

    /**
     * Vector of the old cells, including their old indices.
     *
     * Indices of the old cells must be ordered incrementally to form a single,
     * cohesive range of cells.
     *
     * Note that "old" does not imply "removed". If a cell with the same
     * ID is part of the `new_cells` field, it will merely be updated. Only
     * cells in the `old_cells` field that are not part of the `new_cells` will
     * be removed.
     */
    oldCells?: Array<CellWithIndex>;

    /**
     * Offset at which to split the first of the old cells.
     *
     * In this context, splitting means that the text of the cell in the
     * notebook is split in two at the split offset. The first part is kept,
     * while the second part (which must match the cell's text in `old_cells`)
     * is replaced with the text given in the first of the `new_cells`.
     *
     * If `None`, no cell is split.
     */
    splitOffset?: number;

    /**
     * Offset from which to merge the remainder of the last old cell.
     *
     * In this context, merging means that the text of the new cell is merged
     * from two parts. The first part comes the last of the `new_cells`, while
     * the second part is what remains of the cell in the notebook after the
     * merge offset.
     *
     * If `None`, no cells are merged.
     */
    mergeOffset?: number;

    /**
     * Optional cells which are updated as a result of the replacing of other
     * cells. This is intended to be used for cells that reference the
     * `new_cells` and which now need to be updated as a result of the
     * operation being applied to those cells.
     *
     * These referencing cells may also be newly inserted if they are not
     * included in the `old_referencing_cells`.
     *
     * Indices of new referencing cells do not need to form a cohesive range,
     * but they should still be ordered in ascending order.
     */
    newReferencingCells?: Array<CellWithIndex>;

    /**
     * Optional cells which are updated as a result of the replacing of other
     * cells. This is intended to be used for cells that reference the
     * `old_cells` and which now need to be updated as a result of the
     * operation being applied to those cells.
     *
     * These referencing cells may also be removed if they are not included in
     * the `new_referencing_cells`.
     *
     * Indices of old referencing cells do not need to form a cohesive range,
     * but they should still be ordered in ascending order.
     */
    oldReferencingCells?: Array<CellWithIndex>;
};

export type ReplaceCellsPayload = {
    /**
     * The context menu that should be closed together with replacing the text.
     */
    closeMenu?: NotebookContextMenuInfo;
    focus?: NotebookFocus;

    /**
     * Optional list of IDs of cells to replace. The cells must form a single continuous range.
     * Note you should never have to specify referencing cells. The reducer will take care of these
     * for you.
     */
    oldCellIds?: Array<string>;

    /**
     * Optional list of cells to insert or replace with.
     *
     * If a split offset is given, the first new cell should only contain the text and formatting
     * from the split offset.
     *
     * If a merge offset is given, the last new cell should only contain the text and formatting
     * up to (and including) the merge offset.
     */
    newCells?: Array<CellWithIndex>;
    splitOffset?: number;
    mergeOffset?: number;
};

/**
 * Specifies the given label must be updated.
 */
export type ReplaceLabelChange = {
    /**
     * The key of the existing label that will be replaced.
     */
    key: string;

    /**
     * The new values of the label (Note: it is possible that the key changes).
     */
    label: Label;
};

/**
 * Replace an label in an notebook.
 */
export type ReplaceLabelOperation = {
    oldLabel: Label;
    newLabel: Label;
};

/**
 * Replaces the part of the content in any content type cell or the title of a graph cell.
 */
export type ReplaceTextOperation = {
    /**
     * ID of the cell whose text we're modifying.
     */
    cellId: string;

    /**
     * Field to update the text of.
     */
    field?: string;

    /**
     * Starting offset where we will be replacing the text.
     *
     * Please be aware this offset refers to the position of a Unicode Scalar Value (non-surrogate
     * codepoint) in the cell text, which may require additional effort to determine correctly.
     */
    offset: number;

    /**
     * The new text value we're inserting.
     */
    newText: string;

    /**
     * Optional formatting that we wish to apply to the new text.
     *
     * Offsets in the formatting are relative to the start of the new text.
     */
    newFormatting?: Formatting;

    /**
     * The old text that we're replacing.
     */
    oldText: string;

    /**
     * Optional formatting that was applied to the old text. This should be **all** the formatting
     * annotations that were *inside* the `old_text` before this operation was applied. However,
     * it is at the operation's discretion whether or not to include annotations that are at the
     * old text's boundaries.
     *
     * Offsets in the formatting are relative to the start of the old text.
     */
    oldFormatting?: Formatting;
};

export type ReplaceTextPayload = {
    cellId: string;

    /**
     * The context menu that should be closed together with replacing the text.
     */
    closeMenu?: NotebookContextMenuInfo;
    field?: string;
    offset: number;
    newText?: string;
    newFormatting?: Formatting;
    oldText?: string;
};

export type ResolveCellErrorsPayload = {
    cellIds: Array<string>;
};

/**
 * Struct that contains text with associated formatting.
 */
export type RichText = {
    text: string;
    formatting: Formatting;
};

export type SelectedDataSource = {
    /**
     * The name of the selected data source
     */
    name: Name;

    /**
     * If this is a proxy data source, the name of the proxy
     */
    proxyName?: Name;
};

export type SelectedDataSources = Record<ProviderType, SelectedDataSource>;

/**
 * Real-time message sent by the server over a WebSocket connection.
 */
export type ServerRealtimeMessage =
    /**
     * Apply an operation to a specific Notebook.
     */
    | { type: "apply_operation" } & ApplyOperationMessage
    /**
     * An Ack message will be sent once an operation is received and processed.
     * No Ack message will sent if the op_id of the original message was empty.
     */
    | { type: "ack" } & AckMessage
    /**
     * An Err message will be sent once an operation is received, but could not
     * be processed. It includes the op_id if that was present.
     */
    | { type: "err" } & ErrMessage
    /**
     * Response from a DebugRequest. Contains some useful data regarding the
     * connection.
     */
    | { type: "debug_response" } & DebugResponseMessage
    /**
     * New event was added to the workspace
     */
    | { type: "event_added" } & EventAddedMessage
    /**
     * Event was updated in the workspace
     */
    | { type: "event_updated" } & EventUpdatedMessage
    /**
     * Event was deleted from the workspace
     */
    | { type: "event_deleted" } & EventDeletedMessage
    /**
     * Notifies a mentioned user of the fact they've been mentioned by someone
     * else.
     */
    | { type: "mention" } & MentionMessage
    /**
     * An apply operation got rejected by the server, see message for the
     * reason.
     */
    | { type: "rejected" } & RejectedMessage
    /**
     * A user has joined as a subscriber to a notebook.
     */
    | { type: "subscriber_added" } & SubscriberAddedMessage
    /**
     * A previously subscribed user has left a notebook.
     */
    | { type: "subscriber_removed" } & SubscriberRemovedMessage
    | { type: "subscriber_changed_focus" } & SubscriberChangedFocusMessage
    /**
     * A new comment thread was added to the notebook.
     */
    | { type: "thread_added" } & ThreadAddedMessage
    /**
     * A new item was added to a comment thread (e.g. a comment or a thread status change).
     */
    | { type: "thread_item_added" } & ThreadItemAddedMessage
    /**
     * A new item was added to a comment thread (e.g. a comment or a thread status change).
     */
    | { type: "thread_item_updated" } & ThreadItemUpdatedMessage
    /**
     * A comment thread was deleted
     */
    | { type: "thread_deleted" } & ThreadDeletedMessage
    /**
     * A user started typing a comment
     */
    | { type: "user_typing_comment" } & UserTypingCommentServerMessage;

export type SetCellLivePayload = {
    cellId: string;
    live: boolean;
};

/**
 * Specifies a new selected data source for the notebook.
 */
export type SetSelectedDataSourceChange = {
    providerType: string;
    selectedDataSource: SelectedDataSource | null;
};

export type SetSelectedDataSourceOperation = {
    providerType: string;
    oldSelectedDataSource?: SelectedDataSource;
    newSelectedDataSource?: SelectedDataSource;
};

/**
 * A descriptor that can be returned by a reducer in order to trigger a
 * side effect without sacrificing the functional purity of the reducer.
 *
 * Thunks are dispatched by a special event listener on our store, which uses
 * the `thunkForDescriptor()` function to map the descriptors to actual thunks
 * to dispatch.
 */
export type SideEffectDescriptor =
    | { type: "apply_pending_changes"; notebookId: string; changes: Array<Change> }
    | { type: "queue_send_focus_update"; notebookId: string }
    | { type: "queue_send_pending_notebook_operations"; notebookId: string }
    | {
        type: "reconnect_with_backoff";

        /**
         * Milliseconds to wait before reconnecting.
         */
        backoff: number;
    }
    | { type: "register_sentry_error"; notebookId: string; message: string }
    | { type: "send_all_pending_operations" }
    | { type: "send_pending_notebook_operations"; notebookId: string }
    | { type: "show_mention_notification" } & MentionMessage
    | { type: "revalidate_events" }
    | { type: "show_error"; message: string };

export type Snippet = {
    id: string;
    name: Name;
    description: string;
    body: string;
    createdAt: Timestamp;
    updatedAt: Timestamp;
};

export type SnippetSummary = {
    id: string;
    name: Name;
    description: string;
    createdAt: Timestamp;
    updatedAt: Timestamp;
};

export type SortDirection =
    | "ascending"
    | "descending";

export type SplitCellPayload = {
    /**
     * The notebook focus where to split.
     */
    focus: NotebookFocus;

    /**
     * ID of the new cell to create.
     */
    newId: string;
};

export type StackingType =
    | "none"
    | "stacked"
    | "percentage";

export type StartForkingPayload = {
    forkedAt: number;
};

export type StopUserTypingPayload = {
    threadId: string;
    userId: string;
};

export type SubscribeMessage = {
    /**
     * ID of the notebook.
     */
    notebookId: string;

    /**
     * The current revision that the client knows about. If this is not the
     * current revision according to the server, than the server will sent
     * all operations starting from this revision.
     */
    revision?: number;

    /**
     * Operation ID.
     *
     * Only messages with an operation ID will receive an `Ack` from the
     * server.
     */
    opId?: string;
};

export type SubscribeWorkspaceMessage = {
    /**
     * ID of the workspace.
     */
    workspaceId: string;

    /**
     * Operation ID.
     *
     * Only messages with an operation ID will receive an `Ack` from the
     * server.
     */
    opId?: string;
};

export type SubscriberAddedMessage = {
    /**
     * The ID of the notebook that the user subscribed to.
     */
    notebookId: string;

    /**
     * ID associated with the newly connected session. There can be multiple
     * sessions for a single (notebook|user) pair. The ID can be used multiple
     * times for different (notebook|user) pairs. The combination of notebook,
     * user and session will be unique.
     */
    sessionId: string;

    /**
     * The moment the session was created.
     */
    createdAt: Timestamp;

    /**
     * The last time the user was active in this session.
     */
    updatedAt: Timestamp;

    /**
     * User details associated with the session.
     */
    user: User;

    /**
     * User's focus within the notebook.
     */
    focus: NotebookFocus;
};

export type SubscriberChangedFocusMessage = {
    /**
     * ID of the session.
     */
    sessionId: string;

    /**
     * ID of the notebook.
     */
    notebookId: string;

    /**
     * User's focus within the notebook.
     */
    focus: NotebookFocus;
    updatedAt: Timestamp;
};

export type SubscriberRemovedMessage = {
    /**
     * The ID of the notebook that the user unsubscribed from.
     */
    notebookId: string;

    /**
     * ID of the session that was removed.
     */
    sessionId: string;
};

export type SubscriberSession = {
    sessionId: string;
    focus?: NotebookFocus;
    user: User;
    updatedAt: Timestamp;
};

/**
 * A suggestion for a provider's auto-suggest functionality.
 */
export type Suggestion = {
    /**
     * The offset to start applying the suggestion,
     *
     * All text should be replaced from that offset up to the end of the
     * query in AutoSuggestRequest.
     *
     * When missing, append the suggestion to the cursor
     */
    from?: number;
    text: string;
    description?: string;
};

/**
 * Cell used for displaying tables in a notebook.
 *
 * Tables have columns, which are tracked using [TableColumnDefinition]. The
 * column definition may specify a specific schema to be used for all values
 * in that column.
 *
 * Tables also have [rows](TableRow), which are used for tracking all the data
 * in the table. Each row has multiple "row values" (we intentionally avoid the
 * term "cell" here, because it would be too confusing with the table cell
 * itself).
 *
 * Row values have a specific data type, which should correspond to the type
 * specified in the [TableColumnDefinition].
 *
 * Every row and every column inside a table has a unique ID. Those IDs can be
 * combined to create a [TableRowValueId]. [TableRowValueId] can be serialized
 * to be used inside the `field` of
 * [some operations](crate::notebooks::operations::ReplaceTextOperation::field)
 * as well as [focus types](crate::realtime::FocusPosition::field).
 */
export type TableCell = {
    id: string;
    readOnly?: boolean;

    /**
     * Describes the types used for the columns and the order they should be
     * rendered in.
     */
    columnDefs: Array<TableColumnDefinition>;

    /**
     * Holds the table rows and their values.
     */
    rows: Array<TableRow>;
};

export type TableColumnDefinition = {
    /**
     * ID of the column.
     */
    id: TableColumnId;

    /**
     * Heading text to be displayed at the top of the column.
     */
    title: string;
};

/**
 * This is an automatically generated ID that is added to every column in a
 * table cell.
 *
 * Table column IDs are used to refer to column definitions in the table.
 * They can also be combined with a [TableRowId] to create a [TableRowItemId].
 *
 * Column IDs may only contain alphanumeric characters and must be unique
 * within a table.
 */
export type TableColumnId = string;

export type TableRow = {
    /**
     * ID of the row.
     */
    id: TableRowId;

    /**
     * The values inside this row.
     *
     * The types, order, and amount of the values should match the table's
     * [column definitions](TableCell::column_defs).
     */
    values: Array<TableRowValue>;
};

/**
 * This is an automatically generated ID that is added to every row in a table
 * cell.
 *
 * Table row IDs are used to refer to rows in the table. They can also be
 * combined with a [TableColumnId] to create a [TableRowValueId].
 *
 * Row IDs may only contain alphanumeric characters and must be unique within a
 * table.
 */
export type TableRowId = string;

/**
 * One of the values stored in a [TableRow].
 *
 * We intentionally avoid the term "cell" here, because it would be too
 * confusing with the table cell itself.
 *
 * Row values can be looked up by [ID](TableRowValueId), although the ID is not
 * stored in the row value itself. Instead, it can be created from the row ID
 * and column ID.
 */
export type TableRowValue =
    | { type: "text" } & RichText;

export type Template = {
    id: string;
    name: Name;
    description: string;
    body: string;
    createdAt: Timestamp;
    updatedAt: Timestamp;
    parameters: Array<TemplateParameter>;
};

export type TemplateListSortFields =
    | "name"
    | "created_at"
    | "updated_at";

export type TemplateParameter = {
    name: string;
    type: TemplateParameterType;
    defaultValue: any | null;
};

export type TemplateParameterType =
    | "string"
    | "number"
    | "boolean"
    | "object"
    | "array"
    /**
     * We can only extract the parameter type from function parameters
     * that have default values
     */
    | "unknown";

export type TextCell = {
    id: string;
    content: string;

    /**
     * Optional formatting to be applied to the cell's content.
     */
    formatting?: Formatting;
    readOnly?: boolean;
};

export type Thread = {
    id: string;
    items: Array<ThreadItem>;
    status: ThreadStatus;
    createdBy: UserSummary;
    createdAt: Timestamp;
    updatedAt: Timestamp;
};

export type ThreadAddedMessage = {
    notebookId: string;
    thread: Thread;
};

export type ThreadDeletedMessage = {
    notebookId: string;
    threadId: string;
};

/**
 * All of the Thread details, without the actual ThreadItems
 * that the Thread/ThreadSummary models from fiberplane contain
 */
export type ThreadDetails = {
    id: string;
    status: ThreadStatus;
    itemCount: number;
    items: Array<ThreadItem>;
    usersTyping: Record<string, UserTyping>;
    createdBy: UserSummary;
    createdAt: Timestamp;
    updatedAt: Timestamp;
};

export type ThreadItem =
    | { type: "comment" } & Comment
    | { type: "status_change" } & ThreadStatusChange
    | { type: "comment_delete" } & CommentDelete;

export type ThreadItemAddedMessage = {
    notebookId: string;
    threadId: string;
    threadItem: ThreadItem;
};

export type ThreadItemUpdatedMessage = {
    notebookId: string;
    threadId: string;
    threadItem: ThreadItem;
};

export type ThreadStatus =
    | "open"
    | "resolved";

export type ThreadStatusChange = {
    id: string;
    status: ThreadStatus;
    createdBy: UserSummary;
    createdAt: Timestamp;
};

/**
 * A range in time from a given timestamp (inclusive) up to another timestamp
 * (exclusive).
 */
export type TimeRange = {
    from: Timestamp;
    to: Timestamp;
};

export type TimeUnit =
    | "seconds"
    | "minutes"
    | "hours"
    | "days";

/**
 * A timeline of events. It is shown split by days, so we store
 * events days as an array of day strings in the format "YYYY-MM-DD"
 * and a map of day strings to  all events that happen that day.
 */
export type Timeline = {
    days: Array<string>;
    eventsByDay: Record<string, Array<Event>>;
} & OtelMetadata;

export type TimelineCell = {
    id: string;

    /**
     * Links to the data to render in the timeline.
     */
    dataLinks?: Array<string>;
    readOnly?: boolean;
};

/**
 * A series of metrics over time, with metadata.
 */
export type Timeseries = {
    name: string;
    labels: Record<string, string>;
    metrics: Array<Metric>;

    /**
     * Whether the series should be rendered. Can be toggled by the user.
     */
    visible: boolean;
} & OtelMetadata;

export type Timestamp = string;

export type ToggleTimeseriesVisibilityPayload = {
    cellId: string;

    /**
     * Name of the timeseries to toggle visibility of.
     */
    seriesName: string;

    /**
     * Labels of the timeseries to toggle visibility of.
     */
    seriesLabels: Record<string, string>;

    /**
     * If `true`, all timeseries are toggled *except* the one specified.
     */
    toggleOthers: boolean;
};

export type TriggerSummary = {
    id: string;
    title: string;
    templateId: string;
    createdAt: Timestamp;
    updatedAt: Timestamp;
};

/**
 * Undo options used to track undo state for pending operations.
 *
 * Pending operations are added to the undo stack when they are
 * confirmed by the server, but only when `can_undo` is `true` *and*
 * `is_undo` is `false`.
 *
 * During merging of operations, we verify that only operations with
 * the same `can_undo` value are eligible for merging. Otherwise, we
 * risk undoing non-undoable operations once they're merged.
 */
export type UndoOptions = {
    /**
     * If `true`, this operation can be undone by the user.
     */
    canUndo: boolean;

    /**
     * If `true`, this operation is itself an undo operation.
     *
     * Note that undo operations themselves are typically undoable. This
     * is a what we call a Redo operation :)
     */
    isUndo: boolean;

    /**
     * If `true`, this is an undo operation that should be popped from the
     * pending operations rather than added to it.
     */
    popFromPendingOperations: boolean;
};

export type UndoRedo = {
    /**
     * Stack containing operations to redo.
     *
     * When the user performs an undo operation, the operation is moved onto
     * this stack from either the undo stack or the list of pending operations.
     *
     * When the user performs any new operation, the redo stack is cleared.
     */
    redoStack: Array<Operation>;

    /**
     * Stack containing operations to undo.
     *
     * Note that before popping operations from the undo stack, we first empty
     * the list of pending operations. Once confirmed by the server, operations
     * are moved from the list of pending operations onto the undo stack.
     */
    undoStack: Array<Operation>;
};

export type UnsubscribeMessage = {
    /**
     * ID of the notebook.
     */
    notebookId: string;

    /**
     * Operation ID.
     *
     * Only messages with an operation ID will receive an `Ack` from the
     * server.
     */
    opId?: string;
};

export type UnsubscribeWorkspaceMessage = {
    /**
     * ID of the workspace.
     */
    workspaceId: string;

    /**
     * Operation ID.
     *
     * Only messages with an operation ID will receive an `Ack` from the
     * server.
     */
    opId?: string;
};

/**
 * Specifies the given cell must be updated.
 */
export type UpdateCellChange = {
    cell: Cell;
};

/**
 * Specifies the text field of the given cell (`content` or `title`, depending on the cell type)
 * must be updated.
 *
 * Optionally updates the formatting as well.
 */
export type UpdateCellTextChange = {
    /**
     * ID of the cell we're updating.
     */
    cellId: string;

    /**
     * Optional cell field that we're updating.
     */
    field: string | null;

    /**
     * The new text string to store.
     */
    text: string;

    /**
     * Optional formatting to store.
     *
     * If the formatting is omitted, it means the cell had no formatting and
     * will continue to not have it. In any other case, formatting will be
     * provided, even if unchanged.
     */
    formatting?: Formatting;
};

/**
 * Specifies the given front-matter that must be updated
 */
export type UpdateFrontMatterChange = {
    /**
     * The front-matter that was updated
     */
    change: FrontMatter;
};

/**
 * Replaces front matter in a notebook
 */
export type UpdateFrontMatterOperation = {
    oldFrontMatter: FrontMatter;
    newFrontMatter: FrontMatter;
};

/**
 * Changes the expected schema of a front matter key in a notebook
 */
export type UpdateFrontMatterSchemaChange = {
    key: string;
    oldSchema: FrontMatterValueSchema;
    newSchema: FrontMatterValueSchema | null;
    oldValue: FrontMatterValue | null;
    newValue: FrontMatterValue | null;
    deleteValue: boolean;
};

/**
 * Changes the expected schema of a front matter key in a notebook and/or the
 * value attached to a schema
 */
export type UpdateFrontMatterSchemaOperation = {
    /**
     * The key of the front matter schema to update.
     */
    key: string;

    /**
     * The previous schema used for that front matter key. The old value is used
     * to make consistency checks, as well as revert the operation.
     */
    oldSchema: FrontMatterValueSchema;

    /**
     * The previous value for that front matter key. It is used for consistency checks,
     * as well as making reverting operations possible.
     */
    oldValue?: FrontMatterValue;

    /**
     * The new schema to use, if unspecified the operation will leave the schema
     * untouched (so the operation is only being used to edit the associated value).
     *
     * If a new schema is specified, and the data type does _not_ match between the
     * old and the new one, then the old value will be wiped anyway.
     */
    newSchema?: FrontMatterValueSchema;

    /**
     * The new value to set for the front matter entry.
     *
     * If this attribute is `None` or `null` it can mean multiple things depending on
     * the other attributes:
     * - if `delete_value` is `false`, this means we want to keep the `old_value`
     *   + it is impossible to keep the `old_value` if the schemas are incompatible. In that
     *     case we use the `default_value` of the new schema (or nothing if there’s no default)
     * - if `delete_value` is `true`, this means we want to wipe the value from the front
     *   matter in all cases.
     */
    newValue?: FrontMatterValue;

    /**
     * Switch that controls front matter value edition alongside `new_value`, when
     * `new_value` is None.
     */
    deleteValue?: boolean;
};

export type UpdateNotebook = {
    visibility: NotebookVisibility;
};

/**
 * Specifies that the time range for a notebook (aka global time) must be updated
 */
export type UpdateNotebookTimeRangeChange = {
    timeRange: TimeRange;
};

/**
 * Updates the notebook time range.
 */
export type UpdateNotebookTimeRangeOperation = {
    oldTimeRange: TimeRange;
    timeRange: TimeRange;
};

/**
 * Specifies the title of the notebook must be updated.
 */
export type UpdateNotebookTitleChange = {
    title: string;
};

/**
 * Updates the notebook title.
 */
export type UpdateNotebookTitleOperation = {
    oldTitle: string;
    title: string;
};

export type UpdatePagerDutyReceiver = {
    /**
     * A reference to a template that will be expanded when a incident is
     * created. If this is empty then no template will be expanded.
     */
    incidentCreatedTemplateName?: Name | null;

    /**
     * A secret as defined by PagerDuty when creating the webhook. This secret
     * will be use to verify that any incoming webhooks are valid.
     *
     * Set this to `None` to remove the secret and disable verification.
     */
    secret?: string | null;
};

export type UpdateSnippet = {
    description?: string;
    body?: string;
};

export type UpdateTableColumnDefinitionOperation = {
    /**
     * ID of the table cell.
     */
    cellId: string;

    /**
     * ID of the column being updated.
     */
    columnId: TableColumnId;

    /**
     * New heading text.
     */
    newTitle: string;

    /**
     * Old heading text.
     */
    oldTitle: string;
};

export type UpdateView = {
    displayName?: string;
    description?: string | null;
    color?: number;
    labels?: Array<Label>;
    relativeTime?: RelativeTime | null;
    sortBy?: NotebookSortFields | null;
    sortDirection?: SortDirection | null;
};

export type UpdateWebhook = {
    endpoint?: string;
    events?: Array<WebhookCategory>;
    regenerateSharedSecret: boolean;
    enabled?: boolean;
};

/**
 * Payload to update workspace settings
 */
export type UpdateWorkspace = {
    displayName?: string;
    owner?: string;
    defaultDataSources?: SelectedDataSources;
    frontMatterSchemas?: Record<string, FrontMatterSchema>;
};

/**
 * Payload to update a workspace members' role
 */
export type UpdateWorkspaceUser = {
    role?: AuthRole;
};

export type User = {
    /**
     * The ID of the user. Will always be the same for the same user, so can be
     * used for de-dupping or input for color generation.
     */
    id: string;

    /**
     * Name of the user
     */
    name: string;
};

export type UserSummary = {
    id: string;
    name: string;
};

export type UserTyping = {
    user: UserSummary;
    startedAt: Timestamp;
    updatedAt: Timestamp;
};

export type UserTypingCommentClientMessage = {
    notebookId: string;
    threadId: string;

    /**
     * Operation ID.
     *
     * Only messages with an operation ID will receive an `Ack` from the
     * server.
     */
    opId?: string;
};

export type UserTypingCommentServerMessage = {
    notebookId: string;
    threadId: string;
    user: UserSummary;
    updatedAt: Timestamp;
};

export type ValidationError = {
    /**
     * Refers to a field from the query schema.
     */
    fieldName: string;

    /**
     * Description of why the validation failed.
     */
    message: string;
};

export type VersionedOperation = {
    operation: Operation;
    revision: number;
};

export type View = {
    id: string;
    name: Name;
    displayName: string;
    description: string;
    color: number;
    labels: Array<Label>;
    relativeTime?: RelativeTime;
    sortBy?: NotebookSortFields;
    sortDirection?: SortDirection;
    createdAt: Timestamp;
    updatedAt: Timestamp;
};

export type ViewSortFields =
    | "name"
    | "display_name"
    | "description"
    | "labels"
    | "created_at"
    | "updated_at";

export type Webhook = {
    id: string;
    workspaceId: string;
    endpoint: string;
    events: Array<WebhookCategory>;
    sharedSecret?: string;
    enabled: boolean;

    /**
     * Whether the last delivery was successful
     */
    successful: boolean;
    createdBy?: string;
    createdAt: Timestamp;
    updatedAt: Timestamp;
};

export type WebhookCategory =
    | "ping"
    | "front_matter";

export type WebhookDelivery = {
    id: string;
    webhookId: string;
    event: string;
    statusCode?: number;
    statusText?: string;
    requestHeaders: string;
    requestBody: string;
    responseHeaders?: string;
    responseBody?: string;
    sentRequestAt: Timestamp;
    receivedResponseAt?: Timestamp;
};

export type WebhookDeliverySummary = {
    id: string;
    event: string;
    successful: boolean;
    timestamp: Timestamp;
};

/**
 * Workspace representation.
 */
export type Workspace = {
    id: string;
    name: Name;
    displayName: string;
    type: WorkspaceType;
    ownerId: string;
    defaultDataSources: SelectedDataSources;
    createdAt: Timestamp;
    updatedAt: Timestamp;
    frontMatterSchemas: Record<string, FrontMatterSchema>;
};

export type WorkspaceFrontMatterSchemas = Record<string, FrontMatterSchema>;

export type WorkspaceIntegrationId =
    | "pagerdutywebhook"
    | "githubapp";

export type WorkspaceIntegrationSummary = {
    id: WorkspaceIntegrationId;
    status: IntegrationStatus;
    createdAt?: Timestamp;
    updatedAt?: Timestamp;
};

export type WorkspaceInvite = {
    /**
     * ID of the invitation.
     */
    id: string;

    /**
     * ID of the user who sent the invitation.
     */
    sender: string;

    /**
     * Email address of the invitee.
     */
    receiver: string;

    /**
     * Timestamp at which the invitation was created.
     */
    createdAt: Timestamp;

    /**
     * Timestamp at which the invitation expires.
     */
    expiresAt: Timestamp;
};

/**
 * Response received from create a new workspace endpoint.
 */
export type WorkspaceInviteResponse = {
    url: string;
};

export type WorkspaceType =
    | "personal"
    | "organization";
