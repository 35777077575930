import { type ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { debounce } from "throttle-debounce";

import { formatFiberplaneError } from "../../../../../errors";
import {
  makeCellErrorSelector,
  makeCellQueryFieldErrorSelector,
  makeIsRunningSelector,
} from "../../../../../selectors";
import { QueryError } from "./QueryError";

function useQueryError<T extends Element>(
  cellId: string,
  errorContent: ReactNode,
) {
  const isRunningSelector = useMemo(
    () => makeIsRunningSelector(cellId),
    [cellId],
  );

  const isRunning = useSelector(isRunningSelector);

  const [visible, setVisible] = useState(errorContent === undefined);
  const ref = useRef<T>(null);

  useEffect(() => {
    if (isRunning === false) {
      setVisible(!!errorContent);
    }
  }, [errorContent, isRunning]);

  const update = useMemo(
    () => debounce(33, () => nudgeRef.current?.update()),
    [],
  );

  const nudgeRef = useRef<{
    update: () => Promise<void>;
  } | null>(null);

  const errorOutput = ref.current !== null &&
    isRunning === false &&
    visible === true &&
    !!errorContent && (
      <QueryError
        ref={nudgeRef}
        onClose={() => setVisible(false)}
        element={ref.current}
      >
        {errorContent}
      </QueryError>
    );

  return {
    errorOutput,
    ref,
    update,
  };
}

export function useQueryFormError<T extends Element>(cellId: string) {
  const errorSelector = useMemo(() => makeCellErrorSelector(cellId), [cellId]);
  const error = useSelector(errorSelector);
  const errorContent = useMemo(
    () =>
      error && error.type !== "validation_error"
        ? formatFiberplaneError(error)
        : undefined,
    [error],
  );

  return useQueryError<T>(cellId, errorContent);
}

export function useQueryFieldValidationError<T extends Element>(
  cellId: string,
  fieldName: string,
) {
  const errorSelector = useMemo(
    () => makeCellQueryFieldErrorSelector(cellId, fieldName),
    [cellId, fieldName],
  );
  const error = useSelector(errorSelector);

  return useQueryError<T>(cellId, error?.message);
}
