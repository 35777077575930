import type { Variants } from "framer-motion";
import { motion, useAnimationControls } from "framer-motion";
import { useEffect } from "react";
import { styled } from "styled-components";

// Base SVG x & y values
const BASE_CX = 3;
const BASE_CY = 11;

export function TypingIndicator() {
  const controls = useAnimationControls();

  useEffect(() => {
    (async () => {
      await controls.start("unfold");

      controls.start("bob");
    })();

    return () => {
      controls.stop();
    };
  }, [controls]);

  return (
    <Svg
      width="26"
      height="14"
      viewBox="0 0 26 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {[1, 2, 3].map((c, i) => (
        <motion.circle
          key={c}
          custom={i}
          variants={variants}
          initial="initial"
          animate={controls}
          cx={BASE_CX}
          cy={BASE_CY}
          r="3"
          fill="currentColor"
        />
      ))}
    </Svg>
  );
}

const variants: Variants = {
  initial: {
    cx: BASE_CX,
    cy: BASE_CY,
    opacity: 0,
  },
  unfold: (i: number) => ({
    cx: BASE_CX + i * 10,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  }),
  bob: (i: number) => ({
    cy: [BASE_CY, BASE_CY - 8, BASE_CY],
    transition: {
      delay: 0.3 * i,
      repeat: Number.POSITIVE_INFINITY,
      duration: 0.45,
      repeatDelay: 1.25,
    },
  }),
};

const Svg = styled.svg`
  color: ${({ theme }) => theme.color.fg.muted};
`;
