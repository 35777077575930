/* stylelint-disable scale-unlimited/declaration-strict-value */
import { useHandler } from "@fiberplane/hooks";
import {
  type ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
} from "react";
import useMeasure from "react-use/lib/useMeasure";
import { styled } from "styled-components";

import { mergeRefs } from "../../../utils";
import { Overlay } from "./Overlay";
import { cropPositionReducer } from "./reducer";
import type { CropPosition } from "./types";

type Props = { imageUrl: string; className?: string };

const Container = styled.div`
  align-items: center;
  background-color: #c4c4c4;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
  position: relative;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
`;

const Image = styled.img`
  display: block;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;
`;

function getInitialState(ratio: number): CropPosition {
  const maxWidth = ratio < 1 ? 1 : 1 / ratio;
  const maxHeight = ratio < 1 ? ratio : 1;
  const scale = 0.8;

  return {
    left: 0.5 * (1 - scale * maxWidth),
    top: 0.5 * (1 - scale * maxHeight),
    scale,
  };
}

export type ImageCropperRefApi = { crop(): Promise<Blob> };

function ImageCropperComponent(
  props: Props,
  ref: ForwardedRef<ImageCropperRefApi>,
) {
  const [resizeRef, { width, height }] = useMeasure<HTMLImageElement>();
  const imageRef = useRef<HTMLImageElement>(null);
  const mergedReferences = mergeRefs([resizeRef, imageRef]);
  const ratio = height === 0 ? 1 : width / height;

  const [state, dispatch] = useReducer(
    cropPositionReducer,
    getInitialState(ratio),
  );

  const setState = useHandler((position: CropPosition) =>
    dispatch({
      type: "UPDATE",
      payload: position,
    }),
  );

  const updatePosition = useHandler((position: Omit<CropPosition, "scale">) =>
    dispatch({
      type: "UPDATE_POSITION",
      payload: position,
    }),
  );

  useEffect(() => {
    setState(getInitialState(ratio));
  }, [ratio, setState]);

  useImperativeHandle(ref, () => ({
    crop: () => {
      if (!imageRef.current) {
        throw new Error("Reference found for image");
      }

      const minDimension = Math.min(
        imageRef.current.naturalWidth,
        imageRef.current.naturalHeight,
      );

      const size = minDimension * state.scale;
      const canvas = document.createElement("canvas");

      canvas.width = 200;
      canvas.height = 200;

      const context = canvas.getContext("2d");
      if (context === null) {
        throw new Error("Unable to get valid context");
      }

      const sx = state.left * imageRef.current.naturalWidth;
      const sy = state.top * imageRef.current.naturalHeight;
      context.drawImage(imageRef.current, sx, sy, size, size, 0, 0, 200, 200);

      return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (blob === null) {
            reject(new Error("Unable to extract image"));
            return;
          }

          resolve(blob);
        }, "image/jpeg");
      });
    },
  }));

  return (
    <Container>
      <Center className={props.className}>
        <Image
          data-testid="image-preview"
          ref={mergedReferences}
          src={props.imageUrl}
          alt="New profile"
          height="100%"
        />
      </Center>
      {width && height && (
        <Overlay
          width={width}
          height={height}
          {...state}
          updatePosition={updatePosition}
          setState={setState}
        />
      )}
    </Container>
  );
}

export const ImageCropper = forwardRef<ImageCropperRefApi, Props>(
  ImageCropperComponent,
);
