import { Icon } from "@fiberplane/ui";
import { useSelector } from "react-redux";

import { selectNotebookTitle } from "../../../../selectors";
import { BreadcrumbLabel } from "./BreadcrumbLabel";

export function ActiveNotebookBreadcrumb() {
  const notebookTitle = useSelector(selectNotebookTitle);

  return (
    <BreadcrumbLabel>
      <Icon iconType="notebook" />
      Notebook - {notebookTitle}
    </BreadcrumbLabel>
  );
}
