import { Icon } from "@fiberplane/ui";
import { css, styled } from "styled-components";

export function ExportedLabel() {
  return (
    <Container>
      <Icon iconType="export" /> Exported logs
    </Container>
  );
}

const Container = styled.div(
  ({ theme }) => css`
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 8px;
    gap: 12px;

    width: max-content;
    background: ${theme.colorBase600};
    border-radius: ${theme.borderRadius500};
    font: ${theme.fontStudioStrongRegularShortHand};
    letter-spacing: ${theme.fontStudioStrongRegularLetterSpacing};
    color: ${theme.colorBackground};

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  `,
);
