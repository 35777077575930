import { motion } from "framer-motion";
import { Children } from "react";
import useMeasure from "react-use/lib/useMeasure";
import { styled } from "styled-components";

import { PageElement } from "./PageElement";

type Props = {
  children: React.ReactNode;
  page: number;
  renderControls?: (page: number) => React.ReactNode;
};

export function Paginator(props: Props) {
  const [ref, dimensions] = useMeasure<HTMLDivElement>();

  const { width } = dimensions;

  const children = Children.map(props.children, (child, index) => (
    <PageElement active={index === props.page}>{child}</PageElement>
  ));

  return (
    <Base ref={ref}>
      <RootContainer
        animate={{ x: -props.page * width }}
        transition={{ ease: "easeOut", duration: 0.2 }}
      >
        {children}
      </RootContainer>
      {props.renderControls?.(props.page)}
    </Base>
  );
}

const Base = styled.div`
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

const RootContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
`;
