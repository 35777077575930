import { useEffect } from "react";

import { setWindowFocused } from "../actions";
import { dispatch } from "../store";
import { useFeature } from "./features";

/**
 * Registers handlers for tracking whether the window has focused.
 *
 * If you want to know whether the window is focused, use the
 * `selectWindowFocused()` selector.
 */
export function useWindowFocus() {
  // The `assume_window_focus` feature flag will cause the listeners from this
  // hook to never be registered. This is useful for e2e tests that would
  // otherwise fail if you don't keep the window focused at all times.
  const [assumeWindowFocus] = useFeature("assume_window_focus");

  useEffect(() => {
    if (assumeWindowFocus) {
      return;
    }

    const onWindowFocus = () => dispatch(setWindowFocused(true));
    const onWindowBlur = () => dispatch(setWindowFocused(false));

    window.addEventListener("focus", onWindowFocus);
    window.addEventListener("blur", onWindowBlur);
    return () => {
      window.removeEventListener("focus", onWindowFocus);
      window.removeEventListener("blur", onWindowBlur);
    };
  }, [assumeWindowFocus]);
}
