type FileFilter = {
  extensions: Array<string>;
  name: string;
};

type OpenFileOptions = {
  filters?: Array<FileFilter>;
};

export function openFile(options: OpenFileOptions = {}): Promise<string> {
  return new Promise((resolve, reject) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");

    if (options.filters) {
      input.setAttribute(
        "accept",
        options.filters
          .flatMap((filter) =>
            filter.extensions.map((extension) => `.${extension}`),
          )
          .join(","),
      );
    }

    input.addEventListener("change", () => {
      const file = input.files?.[0];
      if (file) {
        resolve(file.text());
      } else {
        reject(new Error("No file selected"));
      }
    });

    input.click();
  });
}
