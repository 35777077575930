import { useHandler } from "@fiberplane/hooks";
import { Button, Icon, cancelEvent } from "@fiberplane/ui";
import FocusTrap from "focus-trap-react";
import { AnimatePresence, motion } from "framer-motion";
import { css, styled } from "styled-components";

import { dispatch } from "../../../store";
import { deleteCellAndThread, setThreadDeletionPrompt } from "../../../thunks";
import type { ThreadDetails, User } from "../../../types";
import { DiscussionStatus } from "./DiscussionStatus";
import { Placeholder } from "./Placeholder";

type DiscussionCellContainerProps = React.PropsWithChildren<{
  cellId: string;
  renderPlaceholder: boolean;
  showThreadDeletionPrompt?: boolean;
  thread: ThreadDetails;
  user: User;
}>;

export function DiscussionCellContainer({
  cellId,
  children,
  renderPlaceholder,
  showThreadDeletionPrompt,
  thread,
  user,
}: DiscussionCellContainerProps) {
  const handleDeleteThread = useHandler(() => {
    dispatch(deleteCellAndThread(cellId));
  });

  const handleCancelDeleteThread = useHandler(() => {
    dispatch(
      setThreadDeletionPrompt({
        showThreadDeletionPrompt: false,
        threadId: thread.id,
      }),
    );
  });

  return (
    <DiscussionContainer $isPlaceholder={renderPlaceholder}>
      {renderPlaceholder ? (
        <Placeholder createdBy={thread.createdBy} />
      ) : (
        <>
          <DiscussionStatus thread={thread} user={user} />
          {children}
        </>
      )}

      <AnimatePresence>
        {showThreadDeletionPrompt && (
          <FocusTrap
            focusTrapOptions={{
              onPostDeactivate: handleCancelDeleteThread,
              clickOutsideDeactivates: true,
              returnFocusOnDeactivate: true,
            }}
          >
            <PromptContainer
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <PromptContent>
                <PromptTitle>Delete this discussion?</PromptTitle>

                <WarningContainer>
                  <WarningIconContainer>
                    <WarningIcon iconType="warning_circle" />
                  </WarningIconContainer>

                  <WarningMessage>This action cannot be undone!</WarningMessage>
                </WarningContainer>

                <PromptButtonContainer>
                  <Button
                    onClick={handleDeleteThread}
                    onKeyDown={(event) => {
                      if (event.key === "Enter" || event.key === " ") {
                        cancelEvent(event);
                        handleDeleteThread();
                      }
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    onClick={handleCancelDeleteThread}
                    buttonStyle="secondary"
                  >
                    Cancel
                  </Button>
                </PromptButtonContainer>
              </PromptContent>
            </PromptContainer>
          </FocusTrap>
        )}
      </AnimatePresence>
    </DiscussionContainer>
  );
}

const DiscussionContainer = styled.div<{ $isPlaceholder: boolean }>(
  ({ $isPlaceholder, theme }) => css`
    background-color: ${theme.color.notebook.cell.bg.default};
    border: 1px ${$isPlaceholder ? "dashed" : "solid"} ${
      theme.color.border.default
    };
    border-radius: ${theme.radius.rounded};
    padding: 20px;
    position: relative;
    overflow: hidden;
  `,
);

const PromptContainer = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.color.bg["disabled-overlay"]};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const PromptContent = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    border-radius: ${theme.radius.full};
    background: ${theme.color.bg.default};
    box-shadow: 0 0 56px 40px ${theme.color.bg.default};
  `,
);

const PromptTitle = styled.h3`
  margin: 0;
`;

const WarningContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin: 24px 0;
    border: 1px solid ${theme.color.border.warning};
    border-radius: ${theme.radius.default};
    overflow: hidden;
  `,
);

const WarningIconContainer = styled.div`
  background-color: ${({ theme }) => theme.color.bg.warning};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 36px;
`;

const WarningMessage = styled.span`
  padding: 0 12px 0 8px;
`;

const PromptButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
`;

const WarningIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.fg.warning};
`;
