import React from "react";
import {
  SET_ACTIVE_CONTEXT_MENU,
  hideActiveTooltip,
  hideSideMenu,
  setActiveTooltip,
  showSideMenu,
} from "../actions";
import { selectActiveTooltip, selectSideMenuIsOpen } from "../selectors";
import type { ContextMenuType } from "../state";
import type { Thunk } from "../store";
import type {
  CloseTooltipFn,
  TooltipAnchor,
  TooltipContent,
  TooltipOptions,
} from "../types";

let hideTooltipTimeout: ReturnType<typeof setTimeout> | null = null;

export const hideTooltipWithTimeout =
  (timeout: number): Thunk =>
  (dispatch) => {
    if (hideTooltipTimeout != null) {
      clearTimeout(hideTooltipTimeout);
    }

    hideTooltipTimeout = setTimeout(() => {
      hideTooltipTimeout = null;
      dispatch(hideActiveTooltip());
    }, timeout);
  };

export const showContextMenu =
  (element: HTMLElement, menuType: ContextMenuType): Thunk =>
  (dispatch) => {
    dispatch({
      type: SET_ACTIVE_CONTEXT_MENU,
      payload: {
        element,
        menuType,
      },
    });
  };

export const showTooltip =
  (
    anchor: TooltipAnchor,
    content: TooltipContent,
    options: TooltipOptions = {},
  ): Thunk<CloseTooltipFn> =>
  (dispatch, getState) => {
    if (hideTooltipTimeout != null) {
      clearTimeout(hideTooltipTimeout);
      hideTooltipTimeout = null;
    }

    const currentAnchor = selectActiveTooltip(getState())?.anchor();
    const currentContent = selectActiveTooltip(getState())?.content();

    // We will skip updating the tooltip if its content has not changed
    const contentMatches =
      React.isValidElement(content) &&
      React.isValidElement(currentContent) &&
      content.props.text === currentContent.props.text;

    if (!contentMatches && anchor !== currentAnchor) {
      dispatch(setActiveTooltip({ anchor, content, options }));
    }

    return () => dispatch(hideActiveTooltip());
  };

export const toggleSideMenu = (): Thunk => (dispatch, getState) => {
  const sideMenuIsOpen = selectSideMenuIsOpen(getState());
  dispatch(sideMenuIsOpen ? hideSideMenu() : showSideMenu());
};
