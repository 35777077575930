import { styled } from "styled-components";

import type { NotebookContextMenuInfo } from "../../../types";
import { PositionedFilterMenu, type PositionedFilterMenuProps } from "../../UI";
import { useSlashCommandItems } from "./useSlashCommandItems";

type Props = {
  contextMenu: NotebookContextMenuInfo;
} & Omit<PositionedFilterMenuProps, "items">;

export function SlashCommandMenu({
  contextMenu,
  ...menuProps
}: Props): JSX.Element | null {
  const items = useSlashCommandItems({ contextMenu, source: "slash command" });

  return (
    <StyledPositionedMenu
      {...menuProps}
      items={items}
      noResultsText="No results"
      placement="bottom-start"
      grabsFocus={false}
    />
  );
}

const StyledPositionedMenu = styled(PositionedFilterMenu)`
  max-height: 300px;
  width: 345px;
`;
