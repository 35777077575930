import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { selectActiveNotebook, selectNotebookTitle } from "../../../selectors";
import { track } from "../../../utils";

type TrackOnboardingOptions = {
  didLoad: boolean;
  activeNotebookId?: string;
};

export function useTrackOnboardingNotebookOpened({
  didLoad,
}: TrackOnboardingOptions) {
  const notebook = useSelector(selectActiveNotebook);
  const wasCreatedForOnboarding = notebook.createdBy.type === "onboarding";
  const firstLoadedNotebookTitleRef = useFirstLoadedNotebookTitleRef(didLoad);

  // NOTE - This hook uses a reference of the notebook's initially loaded title
  //        in order to generate the segment tracking key.
  //        This prevents us from firing several tracking events when the title is updated.
  //
  useEffect(() => {
    if (didLoad && wasCreatedForOnboarding) {
      const trackingKey = getOnboardingNotebookType(
        firstLoadedNotebookTitleRef.current ?? "",
      );
      track(`notebook | open onboarding notebook | ${trackingKey}`);
    }
  }, [didLoad, wasCreatedForOnboarding, firstLoadedNotebookTitleRef]);
}

/**
 * Hook that stores a ref to the first loaded notebook title.
 * I.e., subsequent edits to the notebook title after the notebook is loaded
 *       will not be stored in the ref.
 * (This helps prevent us from firing several tracking events when the title is updated.)
 */
function useFirstLoadedNotebookTitleRef(didLoad: boolean) {
  const notebookTitle = useSelector(selectNotebookTitle);
  const firstLoadedNotebookTitleRef = useRef<string | null>(notebookTitle);
  useEffect(() => {
    firstLoadedNotebookTitleRef.current = didLoad ? notebookTitle : null;
  }, [didLoad, notebookTitle]);

  return firstLoadedNotebookTitleRef;
}

type OnboardingNotebookType =
  | "introduction"
  | "data sources"
  | "templates"
  | "unknown type";

/**
 * Create the proper segment tracker given a notebook's label and title.
 *
 * - `introduction` if the title contains "welcome"
 * - `data sources` if the title contains "data sources"
 * - `templates` if the title contains "templates"
 * - `unknown type` otherwise
 */
function getOnboardingNotebookType(
  notebookTitle: string,
): OnboardingNotebookType {
  const isWelcome = /welcome/i.test(notebookTitle);
  const isDataSourcesOnboarding = /data sources/i.test(notebookTitle);
  const isTemplateOnboarding = /fiberplane templates/i.test(notebookTitle);

  if (isWelcome) {
    return "introduction";
  }

  if (isDataSourcesOnboarding) {
    return "data sources";
  }

  if (isTemplateOnboarding) {
    return "templates";
  }

  return "unknown type";
}
