import { css, styled } from "styled-components";

export type MenuItemGroupProps = {
  children: React.ReactNode;
  title: string;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
};

export function MenuItemGroup({
  children,
  title,
  iconLeft,
  iconRight,
}: MenuItemGroupProps) {
  return (
    <Container>
      <Header>
        {iconLeft}
        <Title>{title}</Title>
        {iconRight}
      </Header>
      <Items>{children}</Items>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  & ~ & {
    margin-top: 6px;
  }

  &:not(:last-child) {
    padding-bottom: 8px;
  }

  &:not(:last-child)::after {
    content: "";
    height: 2px;
    background-color: ${({ theme }) => theme.colorBase300};
    margin: 2px 12px 0;
    width: calc(100% - 24px);
  }
`;

const Header = styled.h5(
  ({ theme }) => css`
    margin: 0 0 4px;
    display: flex;
    align-items: center;
    color: ${theme.color.fg.muted};
    padding: 0 12px 16px;
    gap: 12px;
    border-bottom: 1px solid ${theme.color.border.default};
  `,
);

const Title = styled.span`
  flex: 1 0 min-content;
`;

const Items = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;
