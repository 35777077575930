import { Icon, IconButton, Input, cancelEvent } from "@fiberplane/ui";
import { css, styled } from "styled-components";

import { dispatch } from "../../../../../store";
import {
  disableWebhook,
  enableWebhook,
  showModal,
  showWebhookDeleteConfirmation,
} from "../../../../../thunks";
import type { Webhook } from "../../../../../types";
import { PageContentItem, RelativeTimestamp } from "../../../../UI";
import { WebhookDeliveryLabel } from "../../../../WebhookDeliveryLabel";

type WebhookListProps = {
  webhooks: Array<Webhook>;
};

export function WebhookList({ webhooks }: WebhookListProps) {
  return (
    <Container>
      {webhooks.map((webhook) => (
        <WebhookListItem
          key={webhook.id}
          onClick={() => editWebhook(webhook.id)}
          data-testid="webhook-list-item"
        >
          <IconContainer>
            <Icon iconType="webhook" />
          </IconContainer>

          <Content>
            <Endpoint>
              <EndpointWrapper>{webhook.endpoint}</EndpointWrapper>
              <InlineWebhookDeliveryLabel successful={webhook.successful} />
            </Endpoint>

            <Description>
              Created <RelativeTimestamp timestamp={webhook.createdAt} />
            </Description>
          </Content>

          <Status>
            <WebhookDeliveryLabel successful={webhook.successful} />
          </Status>

          <Enabled onClick={(event) => cancelEvent(event)}>
            <Input
              type="lightswitch"
              checked={webhook.enabled}
              onChange={() => toggleEnabled(webhook)}
            />
            {webhook.enabled ? "Enabled" : "Disabled"}
          </Enabled>

          <Actions>
            <IconButton
              iconType="pencil_simple"
              buttonStyle="tertiary-grey"
              onClick={() => editWebhook(webhook.id)}
            />
            <IconButton
              iconType="trash"
              buttonStyle="tertiary-grey"
              onClick={(event) => {
                cancelEvent(event);
                dispatch(showWebhookDeleteConfirmation(webhook.id));
              }}
            />
          </Actions>
        </WebhookListItem>
      ))}
    </Container>
  );
}

const toggleEnabled = (webhook: Webhook) =>
  dispatch((webhook.enabled ? disableWebhook : enableWebhook)(webhook.id));

const editWebhook = (webhookId: string) =>
  dispatch(showModal({ type: "editWebhook", webhookId }));

const Container = styled.div`
  display: grid;
  gap: 12px;
`;

const Status = styled.div`
  grid-area: status;
  display: flex;
  justify-content: flex-end;
  height: max-content;
`;

const InlineWebhookDeliveryLabel = styled(WebhookDeliveryLabel)`
  display: none;

  @media (min-width: 834px) {
    display: inline-flex;
    flex: 0 0 auto;
  }
`;

const WebhookListItem = styled(PageContentItem)(
  ({ theme }) => css`
    grid:
      "icon content status" auto
      "icon content ." auto
      ". enabled actions" auto
      / auto 1fr auto;
    gap: 12px 20px;
    background-color: ${theme.color.button.secondary.bg};
    border: 1px solid ${theme.color.border.muted};
    border-radius: ${theme.radius.default};
    margin: 0;
    padding: 12px;
    box-shadow: ${theme.effect.shadow.xxs};

    &:hover {
      background-color: inherit;
      border-color: ${theme.color.border.muted};
    }

    @media (min-width: 834px) {
      align-items: center;
      grid: "icon content status enabled actions" auto / 40px 1fr auto auto auto;

      ${Status} {
        display: none;
      }
    }
  `,
);

const IconContainer = styled.div(
  ({ theme }) => css`
    grid-area: icon;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    aspect-ratio: 1;
    border-radius: ${theme.radius.default};
    background-color: ${theme.color.bg.emphasis.neutral};
    color: ${theme.color.fg.default};
  `,
);

const Content = styled.div`
  grid-area: content;
  display: grid;
`;

const Endpoint = styled.strong(
  ({ theme }) =>
    css`
      display: flex;
      gap: 8px;
      font: ${theme.font.body.l.medium};
      color: ${theme.color.fg.default};
      min-width: 100px;
      max-width: calc(100vw - 220px);

      @media (min-width: 834px) {
        max-width: 520px;
      }
    `,
);

const EndpointWrapper = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Description = styled.span(
  ({ theme }) => css`
    font: ${theme.font.body.sm.regular};
    color: ${theme.color.fg.muted};
  `,
);

const Enabled = styled.div(
  ({ theme }) => css`
    grid-area: enabled;
    display: flex;
    align-items: center;
    gap: 12px;
    font: ${theme.font.body.md.regular};
    color: ${theme.color.fg.default};
  `,
);

const Actions = styled.div`
  grid-area: actions;
  display: flex;
  gap: 6px;
  justify-content: flex-end;
`;
