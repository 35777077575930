import { cancelEvent } from "@fiberplane/ui";
import { resetLabelsEditor } from "../../../../actions";
import {
  selectLabelsEditorExistingLabelKeys,
  selectLabelsEditorState,
} from "../../../../selectors";
import type { Thunk } from "../../../../store";
import { saveLabelsEditorDraft } from "../../../../thunks";
import { parseLabel, validateLabelsEditor } from "../../../../utils";
import type { Result } from "../types";

export const onLabelsEditorKeyDown =
  (event: React.KeyboardEvent, selectedResult: Result | undefined): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const editorState = selectLabelsEditorState(state);
    if (!editorState) {
      return;
    }

    const label = parseLabel(editorState.draft);

    if (
      event.key === "Escape" ||
      event.key === "Tab" ||
      (event.key === "Backspace" && label.key.length === 0)
    ) {
      cancelEvent(event);
      dispatch(resetLabelsEditor("command_menu"));
    } else if (
      event.key === "Enter" &&
      !selectedResult?.item.type.startsWith("label")
    ) {
      cancelEvent(event);

      const existingKeys = selectLabelsEditorExistingLabelKeys(state);
      const errors = validateLabelsEditor(editorState, existingKeys);
      if (errors.length === 0) {
        dispatch(saveLabelsEditorDraft());
      }
    }
  };
