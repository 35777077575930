import {
  FETCH_TRIGGERS_FULFILLED,
  FETCH_TRIGGERS_PENDING,
  FETCH_TRIGGERS_REJECTED,
} from "../actions";
import { normalizeException } from "../errors";
import { selectIsLoadingTriggers } from "../selectors";
import { Api } from "../services";
import type { Trigger } from "../state";
import type { Thunk } from "../store";

export function loadTriggers(workspaceId: string): Thunk {
  return async (dispatch, getState) => {
    const isLoading = selectIsLoadingTriggers(getState());

    if (isLoading) {
      return;
    }

    dispatch({
      type: FETCH_TRIGGERS_PENDING,
    });

    try {
      const triggers = await Api.listTriggers(workspaceId);

      dispatch({
        type: FETCH_TRIGGERS_FULFILLED,
        payload: triggers.map(
          (trigger): Trigger => ({
            id: trigger.id,
            title: trigger.title,
            defaultArguments: trigger.defaultArguments,
            secretKey: trigger.secretKey,
            createdAt: trigger.createdAt.toISOString(),
            templateId: trigger.templateId,
            updatedAt: trigger.updatedAt.toISOString(),
          }),
        ),
      });
    } catch (error) {
      dispatch({
        type: FETCH_TRIGGERS_REJECTED,
        payload: normalizeException(error),
      });
    }
  };
}
