import { useHandler } from "@fiberplane/hooks";
import { Button, Icon } from "@fiberplane/ui";
import type React from "react";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { css, styled } from "styled-components";
import { throttle } from "throttle-debounce";

import { replaceText } from "../../../actions";
import { COMMENT_INITIAL, COMMENT_REPLY } from "../../../constants";
import { useCellFocus } from "../../../hooks";
import {
  makeAppThreadSelector,
  makeCellDraftSelector,
  selectNotebookId,
} from "../../../selectors";
import { useActiveNotebookDispatch } from "../../../store";
import {
  addComment,
  focusCell,
  sendUserTyping,
  toggleThreadShowReplies,
} from "../../../thunks";
import type { User } from "../../../types";
import { ProfileAvatar } from "../../Avatar";
import { RichTextInput } from "../RTE";
import { StyledRichTextInput } from "./StyledRichTextInput";

type CommentReplyInputProps = {
  cellId: string;
  field: string;
  firstComment: boolean;
  readOnly: boolean;
  threadId: string;
  user: User;
};

type CommentInputHandle = {
  submitComment: () => void;
};

/**
 * Renders the input field for either the initial comment or comment replies
 */
export function CommentInput({
  cellId,
  firstComment,
  field,
  readOnly,
  threadId,
  user,
}: CommentReplyInputProps) {
  const dispatch = useActiveNotebookDispatch();
  const focus = useCellFocus(cellId, field);

  const cellStateSelector = useMemo(
    () =>
      createStructuredSelector({
        draft: makeCellDraftSelector(cellId, field),
        notebookId: selectNotebookId,
        threads: makeAppThreadSelector(threadId),
      }),
    [cellId, field, threadId],
  );
  const {
    draft: { text, formatting },
    notebookId,
    threads,
  } = useSelector(cellStateSelector);

  const showThreadReplies = threads?.showReplies;

  useEffect(() => {
    dispatch(focusCell({ cellId, field }));
  }, [cellId, dispatch, field]);

  const submitComment = useHandler(() => {
    if (text.length > 0) {
      dispatch(addComment({ cellId, text, threadId, formatting }));
      dispatch(
        focusCell({
          cellId,
          field: field !== COMMENT_INITIAL ? field : undefined,
        }),
      );
    }
  });

  useEffect(() => {
    CommentInputById.set(cellId, { submitComment });
    return () => {
      CommentInputById.delete(cellId);
    };
  }, [cellId, submitComment]);

  const handleUserTyping = useMemo(
    () =>
      throttle(
        1000,
        () => {
          dispatch(sendUserTyping(notebookId, threadId));
        },
        // Setting `noTrailing` prevents the typing indicator to get rendered
        // after a comment is sent within 1 second.
        { noTrailing: true },
      ),
    [notebookId, threadId, dispatch],
  );

  const handleKeyDown = useHandler(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === "Escape" && showThreadReplies) {
        dispatch(toggleThreadShowReplies(threadId));

        dispatch(
          replaceText({
            cellId,
            field: COMMENT_REPLY,
            oldText: text,
            newText: "",
            offset: 0,
          }),
        );
      }
    },
  );

  useEffect(() => {
    if (text) {
      handleUserTyping();
    }
  }, [handleUserTyping, text]);

  if (firstComment) {
    return (
      <>
        <ReplyInputContainer
          $hasBlockMargin={firstComment}
          onKeyDown={handleKeyDown}
        >
          <RichTextInput
            cellId={cellId}
            field={field}
            focus={focus}
            formatting={formatting}
            value={text}
            placeholder="Describe the discussion topic..."
            readOnly={readOnly}
          />
        </ReplyInputContainer>

        <ButtonsContainer>
          <Button buttonStyle="secondary">Cancel</Button>
          <Button disabled={text.length === 0} onClick={submitComment}>
            Start discussion
          </Button>
        </ButtonsContainer>
      </>
    );
  }

  return (
    <ReplyInputContainer onKeyDown={handleKeyDown}>
      <StyledProfileAvatar
        name={user.name}
        userId={user.id}
        showTooltip={false}
      />

      <StyledRichTextInput
        cellId={cellId}
        field={field}
        focus={focus}
        formatting={formatting}
        value={text}
        readOnly={readOnly}
      />

      <IconButton $containsText={text.length > 0} onClick={submitComment}>
        <Icon iconType="paper_plane_tilt" />
      </IconButton>
    </ReplyInputContainer>
  );
}

export const CommentInputById = new Map<string, CommentInputHandle>();

const ReplyInputContainer = styled.div<{ $hasBlockMargin?: boolean }>(
  ({ $hasBlockMargin }) => css`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0 16px;
    position: relative;
    width: 100%;

    ${
      $hasBlockMargin &&
      css`
        margin-block: 32px;
      `
    }
  `,
);

const StyledProfileAvatar = styled(ProfileAvatar)`
  align-self: center;
`;

const IconButton = styled.button<{ $containsText: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  height: 100%;
  width: 48px;
  cursor: pointer;
  outline: 0;
  border: 0;
  background: none;
  color: ${
    /* sc-custom "ignore this" */
    ({ $containsText, theme }) =>
      $containsText ? theme.color.fg.primary : theme.color.fg.muted
  };
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;
