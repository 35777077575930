export const CLIPPED_ROCKET_ICON_ID = "clipped_rocket_icon";

export function ClippedRocketIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="96"
      height="88"
      viewBox="0 0 96 88"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <clipPath id={CLIPPED_ROCKET_ICON_ID} clipPathUnits="objectBoundingBox">
          <path d="M0.302,0.714 L0.264,0.682 C0.23,0.67,0.193,0.687,0.153,0.734 L0.131,0.789 C0.12,0.84,0.12,0.871,0.131,0.883 C0.144,0.895,0.175,0.895,0.227,0.884 L0.282,0.863 C0.328,0.822,0.346,0.785,0.333,0.751 L0.302,0.714 M0.991,0.024 C0.931,0.006,0.86,0,0.778,0.006 C0.615,0.018,0.479,0.085,0.372,0.205 C0.34,0.193,0.306,0.185,0.27,0.18 C0.197,0.171,0.148,0.185,0.121,0.223 L0.051,0.322 C0.009,0.393,0,0.442,0.024,0.467 C0.034,0.478,0.051,0.488,0.075,0.498 C0.124,0.519,0.182,0.53,0.25,0.531 L0.264,0.583 C0.279,0.623,0.301,0.657,0.33,0.684 C0.357,0.713,0.391,0.735,0.432,0.75 L0.484,0.765 C0.484,0.798,0.487,0.828,0.492,0.857 C0.495,0.874,0.499,0.89,0.504,0.906 C0.507,0.917,0.512,0.928,0.516,0.939 C0.526,0.963,0.537,0.981,0.547,0.991 C0.573,1,0.621,1,0.693,0.964 L0.792,0.894 C0.829,0.867,0.843,0.817,0.834,0.745 C0.83,0.708,0.821,0.674,0.81,0.643 C0.93,0.535,0.996,0.4,1,0.236 C1,0.155,1,0.084,0.991,0.024 L0.991,0.024" />
        </clipPath>
      </defs>
    </svg>
  );
}
