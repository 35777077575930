import type { AuthRole, CellTypeProperties } from "./types";

export const WORKSPACE_ROUTE = "/workspaces/:workspaceName";

export const ROUTES = {
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  Root: "/",
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  SignIn: "/signin",
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  SlackIntegration: "/integrations/slack",
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  NewNotebook: "/new",
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  Invitation: "/invitations/:invitationId/:invitationSecret",
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  GettingStarted: `${WORKSPACE_ROUTE}`,
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  Graph: `${WORKSPACE_ROUTE}/graph`,
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  Notebooks: `${WORKSPACE_ROUTE}/notebooks`,
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  Notebook: `${WORKSPACE_ROUTE}/notebooks/:notebookId`,
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  Snippets: `${WORKSPACE_ROUTE}/snippets`,
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  Templates: `${WORKSPACE_ROUTE}/templates`,
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  NewTemplate: `${WORKSPACE_ROUTE}/templates/new`,
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  Template: `${WORKSPACE_ROUTE}/templates/:templateName`,
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  ExpandTemplate: `${WORKSPACE_ROUTE}/templates/:templateName/expand`,
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  NewView: `${WORKSPACE_ROUTE}/views/new`,
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  View: `${WORKSPACE_ROUTE}/views/:viewName`,
  // biome-ignore lint/style/useNamingConvention: TODO: rename to camelcase
  Views: `${WORKSPACE_ROUTE}/views`,
} as const;

export const ROLES: Array<AuthRole> = ["read", "write", "admin"];

export const AUTHENTICATE_URL = "/api/oidc/authorize";

export const VERSION = process.env.GITHUB_SHA || "local";

// Be careful to keep this one in sync with the value inside FiberKit!
export const DEFAULT_NOTEBOOK_TITLE = "Untitled notebook";

export const COOKIE_CONSENT_NAME = "fp-consent";
export const COOKIE_CONSENT_VALUE = "all";
export const COOKIE_REJECT_VALUE = "minimal";

export const FEEDBACK_NOTICE_COLOR = "#4797FF";
export const FEEDBACK_WARNING_COLOR = "#F8B929";
export const FEEDBACK_ERROR_COLOR = "#F95959";

export const ONE_MINUTE = 60; // seconds
export const ONE_HOUR = 60 * ONE_MINUTE; // seconds
export const ONE_DAY = 24 * ONE_HOUR; // seconds

export const MAX_LIST_ITEM_LEVEL = 2;

export const NOTEBOOK_FILE_EXTENSION = "fpf";

export const MAX_FILE_UPLOAD_SIZE = 10 * 1024 * 1024;
export const IMAGE_MIME_TYPES = ["image/jpeg", "image/png", "image/gif"];
export const DEFAULT_FIELDS = ["timestamp", "document"];

// FIXME: For now this is just sufficiently large, because it's the only
//        overview we have right now...
export const RECENT_NOTEBOOKS_LIMIT = 50;

/**
 * Key used for storing field suggestions for the log cell in LocalStorage.
 */
export const LOG_CELL_SUGGESTED_FIELDS_KEY = "log_cell_suggested_fields";

export const SUGGESTED_FIELDS_LIMIT = 40;

/**
 * Name of the key used of storing features in `localStorage`
 */
export const FEATURES_KEY = "features";

/**
 * List of all features that can be enabled/disabled
 */
export const FEATURES = [
  "ai-copilot",
  "assume_window_focus",
  "autometrics-provider",
  "autometrics",
  "cloudwatch",
  "courier-workspaces",
  "delete-account",
  "https-provider",
  "nav-new-notebook",
  "sentry",
  "table-cell",
  "notebook-table-of-contents",
  "theme-switcher",
] as const;

/**
 * Timeout we use for sending updates such as pending operations and focus info
 * to other clients.
 */
export const SEND_UPDATE_THROTTLE_TIMEOUT = 600; // ms

// Be careful to keep this one in sync with the value inside FiberKit!
export const TITLE_CELL_ID = "__Title_cell__";

export const LABELS_CELL_ID = "__Labels_cell__";
export const FRONT_MATTER_CELL_ID = "__Frontmatter_cell__";

export const GLOBAL_TIME_RANGE_ID = "__Global_time_selector__";
export const ADD_BUTTON_FIELD_ID = "__Add_button__";

type Environment = "production" | "dev" | "demo" | "local";

export function getEnvironment(): Environment {
  if (document.location.hostname.includes("dev")) {
    return "dev";
  }

  if (document.location.hostname.includes("demo")) {
    return "demo";
  }

  if (document.location.hostname.includes("fiberplane.com")) {
    return "production";
  }

  return "local";
}

export const ANALYTICS_ENVIRONMENTS: Record<Environment, string> = {
  production: "BTT61nRx3RfB4k1Krt9zQ7F2kwsFyeOW",
  dev: "GDevQD8Teoug6YoI0DouXJ4YqnCwtyBP",
  demo: "SQ24Y1xvtbHilRuL78B6gINW3ifMQfMS",
  local: "omIewxpX1oGZJKLPcoaCDEFMtudIsPHd",
};

/**
 * Returns whether the cell ID refers to a real cell ID or a surrogate (title,
 * time range, etc..).
 */
export function isSurrogateId(cellId: string) {
  return (
    cellId === TITLE_CELL_ID ||
    cellId === GLOBAL_TIME_RANGE_ID ||
    cellId === FRONT_MATTER_CELL_ID ||
    cellId === LABELS_CELL_ID
  );
}

export const CELL_TYPE_SHORTCUTS: Array<[string, CellTypeProperties]> = [
  ["*", { type: "list_item", listType: "unordered" }],
  ["-", { type: "list_item", listType: "unordered" }],
  ["[ ]", { type: "checkbox", checked: false }],
  ["[x]", { type: "checkbox", checked: true }],
  ["#", { type: "heading", headingType: "h1" }],
  ["##", { type: "heading", headingType: "h2" }],
  ["###", { type: "heading", headingType: "h3" }],
];

export const LOGS_PAGE_SIZE = 30;

export const CELLS_MIME_TYPE = "application/vnd.fiberplane.cells";

export const RICH_TEXT_MIME_TYPE = "application/vnd.fiberplane.rich-text";

export const COMMENT_REPLY = "comment-reply";
export const COMMENT_INITIAL = "comment-initial";
export const THREAD_DELETE_CONFIRM = "thread-delete-confirm";

export const FORM_ENCODED_MIME_TYPE = "application/x-www-form-urlencoded";

export const MESSAGEPACK_MIME_TYPE = "application/x-msgpack";

export const EVENTS_MIME_TYPE = "application/vnd.fiberplane.events";
export const EVENTS_QUERY_TYPE = "events";

export const SUGGESTIONS_MIME_TYPE = "application/vnd.fiberplane.suggestions";
export const SUGGESTIONS_QUERY_TYPE = "suggestions";

export const TIMESERIES_MIME_TYPE = "application/vnd.fiberplane.timeseries";
export const TIMESERIES_QUERY_TYPE = "timeseries";

export const TIMELINE_MIME_TYPE = "application/vnd.fiberplane.timeline";
export const TIMELINE_QUERY_TYPE = "timeline";

export const SUPPORTED_ENCODINGS = ["json", "msgpack"];

export const SMALL_SIZE_QUERY = "(min-width: 375px)";
export const MEDIUM_SIZE_QUERY = "(min-width: 600px)";
export const LARGE_SIZE_QUERY = "(min-width: 960px)";

export const LABEL_SPLIT_CHARACTER = "=";
export const CONSOLE_COLLAPSED_HEIGHT = 39;
