import {
  type AppAction,
  CLEAR_AUTHENTICATION,
  SET_ACTIVE_WORKSPACE_NAME,
} from "../actions";
import type { LabelsState } from "../state";
import {
  isLabelsEditorActionForTarget,
  labelsEditorReducer,
} from "./labelsEditorReducer";

const initialState: LabelsState = {
  activeEditorType: "none",
  editor: undefined,
};

export function labelsReducer(
  state = initialState,
  action: AppAction,
): LabelsState {
  if (
    action.type === CLEAR_AUTHENTICATION ||
    action.type === SET_ACTIVE_WORKSPACE_NAME
  ) {
    return initialState;
  }

  if (
    isLabelsEditorActionForTarget(action, "notebook") ||
    isLabelsEditorActionForTarget(action, "provider_field") ||
    isLabelsEditorActionForTarget(action, "view") ||
    isLabelsEditorActionForTarget(action, "event_modal")
  ) {
    const editor = labelsEditorReducer(state.editor, action);
    return {
      activeEditorType: editor ? action.payload.target : "none",
      editor,
    };
  }

  return state;
}
