// biome-ignore lint/style/noNamespaceImport: This is the recommended way to import sentry
import * as Sentry from "@sentry/react";

import { getEnvironment } from "../../constants";
import { getNotebookState } from "../FiberKit";

export function captureError(message: string, extra?: Record<string, unknown>) {
  console.error(message, extra);

  Sentry.captureEvent({
    message,
    level: "error",
    environment: getEnvironment(),
    extra,
  });
}

export function captureWarning(
  message: string,
  extra?: Record<string, unknown>,
) {
  console.warn(message, extra);

  Sentry.captureEvent({
    message,
    level: "warning",
    environment: getEnvironment(),
    extra,
  });
}

export function getSensitiveNotebookInfo(notebookId: string) {
  if (!hasSensitiveDataCollection()) {
    return { notebookId };
  }

  try {
    const state = getNotebookState(notebookId);
    if (!state) {
      return { notebookId, message: "No notebook state found" };
    }

    // Limit the amount of undo/redo data we send:
    const undoRedo = JSON.stringify(
      {
        redoStack: state.undoRedo.redoStack.slice(0, 10),
        undoStack: state.undoRedo.undoStack.slice(-10),
      },
      null,
      2,
    );

    return {
      frontMatter: JSON.stringify(state.frontMatter, null, 2),
      frontMatterSchema: JSON.stringify(state.frontMatterSchema, null, 2),
      notebookCells: JSON.stringify(state.cells, null, 2),
      notebookId,
      pendingOperations: JSON.stringify(state.pendingOperations, null, 2),
      timeRange: JSON.stringify(state.timeRange, null, 2),
      title: state.title,
      undoRedo,
    };
  } catch (error) {
    return {
      notebookId,
      error,
      message: "Could not retrieve notebook info.",
    };
  }
}

export function hasSensitiveDataCollection(): boolean {
  const environment = getEnvironment();
  return environment === "dev" || environment === "demo";
}
