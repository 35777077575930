import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import {
  selectCommandMenuLabelsEditor,
  selectCommandMenuVariantType,
} from "../../../../../selectors";
import { dispatch } from "../../../../../store";
import {
  addCommandMenuLabelKeySuggestion,
  addCommandMenuLabelValueSuggestion,
} from "../../../../../thunks";
import type { SelectLabelSuggestionCommand } from "../../../../../types";

export function useLabelSuggestionCommands() {
  const { labelsEditor, keys, values, variantType } = useSelector(
    selectLabelSuggestionCommandsState,
  );

  return useMemo(() => {
    const suggestions = [];
    if (variantType === "search" && labelsEditor) {
      const { original } = labelsEditor;

      if (original?.key && values) {
        suggestions.push(
          ...values.map<SelectLabelSuggestionCommand>((value) => ({
            key: `labels/values/${value}`,
            title: value,
            type: "labelValues",
            onActivate: () => {
              dispatch(addCommandMenuLabelValueSuggestion(value));
            },
            focusInputAfterActivation: true,
            keepOpenAfterActivation: true,
            keepQueryAfterActivation: true,
          })),
        );
      }

      if (keys) {
        suggestions.push(
          ...keys.map<SelectLabelSuggestionCommand>((key) => ({
            key: `labels/keys/${key}`,
            title: key,
            type: "labelKeys",
            onActivate: () => {
              dispatch(addCommandMenuLabelKeySuggestion(key));
            },
            keepQueryAfterActivation: true,
            keepOpenAfterActivation: true,
          })),
        );
      }
    }

    return suggestions;
  }, [keys, labelsEditor, values, variantType]);
}

const selectLabelSuggestionCommandsState = createSelector(
  [selectCommandMenuLabelsEditor, selectCommandMenuVariantType],
  (labelsEditor, variantType) => ({
    keys: labelsEditor?.suggestions.data?.keys,
    labelsEditor,
    values: labelsEditor?.suggestions.data?.values,
    variantType,
  }),
);
