import { IconButton, Input } from "@fiberplane/ui";
import { css, styled } from "styled-components";

type Props = {
  toggleExpand?: () => void;
  expanded?: boolean;
  selected: boolean;
  toggleSelected?: () => void;
  hideCount?: boolean;
};

export function RowControls(props: Props): JSX.Element {
  const {
    toggleExpand,
    expanded,
    selected,
    toggleSelected,
    hideCount = false,
  } = props;

  return (
    <FirstItem>
      {toggleSelected && (
        <StyledInput
          type="checkbox"
          checked={selected}
          onChange={toggleSelected}
        />
      )}

      <Count $disabled={hideCount === true} />

      {expanded !== undefined && toggleExpand && (
        <StyledIconButton
          onClick={toggleExpand}
          buttonStyle="tertiary-grey"
          data-testid="expand-row"
          aria-label={expanded ? "Collapse message" : "Expand log message"}
          iconType="caret_right"
          $expanded={expanded}
        />
      )}
    </FirstItem>
  );
}

/*
  FIXME: The radius tokens need to be updated, until then we use this as a
  temporary fix
*/
const StyledInput = styled(Input)`
  & > div {
    border-radius: ${({ theme }) => theme.radius.lowest};
  }
`;

const StyledIconButton = styled(IconButton)<{ $expanded: boolean }>`
  svg {
    transition: rotate 0.1s ease-in-out;

    ${({ $expanded }) =>
      $expanded &&
      css`
        rotate: 90deg;
      `}
  }
`;

const FirstItem = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  min-width: 68px;
  gap: 8px;
`;

const Count = styled.div<{ $disabled: boolean }>(
  ({ $disabled, theme }) => css`
    display: flex;
    flex-direction: row;
    padding: 2px 0;
    width: 24px;
    height: 24px;
    font: ${theme.font.code.regular};
    align-items: center;
    justify-content: center;
    color: ${theme.color.fg.subtle};
    flex: none;
    order: 0;
    flex-grow: 0;

    ${
      !$disabled &&
      css`
        &::before {
          counter-increment: elements;
          content: counter(elements);
        }
      `
    }
  `,
);
