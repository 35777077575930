import { useEffectOnce } from "@fiberplane/hooks";
import { Button, Input, stopPropagation } from "@fiberplane/ui";
import { useRef, useState } from "react";
import styled from "styled-components";

import { applyOwnOperation, withActiveNotebook } from "../../../actions";
import { selectCell } from "../../../selectors";
import { dispatch, getState } from "../../../store";
import {
  compact,
  formatTableRowValueId,
  getUniqueColumnId,
} from "../../../utils";
import {
  Container,
  MenuDivider,
  MenuItem,
  MenuItemGroup,
  PositionedMenu,
} from "../../UI";
import { headingRowMarker, noFormatting } from "./constants";

type MenuKind = "column_options" | "edit_column_def";

type Props = {
  cellId: string;
  columnId: string;
  element: HTMLElement;
  onClose: () => void;
};

export function TableColumnMenu({
  cellId,
  columnId,
  element,
  onClose,
}: Props): JSX.Element {
  const [title, setTitle] = useState(() => getInitialTitle(cellId, columnId));
  const [menuKind, setMenuKind] = useState<MenuKind>(
    title === "Untitled" ? "edit_column_def" : "column_options",
  );

  const onCancel = () => setMenuKind("column_options");

  const onSave = () => {
    saveTitle(cellId, columnId, title);
    onClose();
  };

  return (
    <PositionedMenu
      element={element}
      placement="bottom-start"
      onClick={stopPropagation}
      onClose={onClose}
      offset={[3, 3]}
    >
      {menuKind === "edit_column_def" ? (
        <EditColumnDef
          onCancel={onCancel}
          onChangeTitle={setTitle}
          onSave={onSave}
          title={title}
        />
      ) : (
        <ColumnOptions
          onDeleteColumn={() => deleteColumn(cellId, columnId)}
          onDuplicateColumn={() => duplicateColumn(cellId, columnId)}
          onEditColumn={() => setMenuKind("edit_column_def")}
          onInsertColumnAfter={() => insertColumn(cellId, columnId, 1)}
          onInsertColumnBefore={() => insertColumn(cellId, columnId, 0)}
        />
      )}
    </PositionedMenu>
  );
}

type ColumnOptionsProps = {
  onDeleteColumn: () => void;
  onDuplicateColumn: () => void;
  onEditColumn: () => void;
  onInsertColumnAfter: () => void;
  onInsertColumnBefore: () => void;
};

function ColumnOptions({
  onDeleteColumn,
  onDuplicateColumn,
  onEditColumn,
  onInsertColumnAfter,
  onInsertColumnBefore,
}: ColumnOptionsProps): JSX.Element {
  return (
    <>
      <MenuItem
        id="edit_column"
        title="Edit column"
        onActivate={onEditColumn}
        disableCloseOnActivate={true}
      />
      <MenuDivider />
      <MenuItem
        id="insert_column_before"
        title="Insert column before"
        onActivate={onInsertColumnBefore}
      />
      <MenuItem
        id="insert_column_after"
        title="Insert column after"
        onActivate={onInsertColumnAfter}
      />
      <MenuDivider />
      <MenuItem
        id="duplicate_column"
        title="Duplicate column"
        onActivate={onDuplicateColumn}
      />
      <MenuItem
        id="delete_column"
        title="Delete column"
        onActivate={onDeleteColumn}
      />
    </>
  );
}

type EditColumnDefProps = {
  onCancel: () => void;
  onChangeTitle: (title: string) => void;
  onSave: () => void;
  title: string;
};

function EditColumnDef({
  onCancel,
  onChangeTitle,
  onSave,
  title,
}: EditColumnDefProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffectOnce(() => {
    inputRef.current?.select();
  });

  const onKeyDown = (event: React.KeyboardEvent) => {
    stopPropagation(event);

    if (event.key === "Enter") {
      onSave();
    }
  };

  return (
    <>
      <MenuItemGroup title="Column title">
        <Input
          data-prevent-rte
          name="title"
          onChange={(event) => onChangeTitle(event.target.value)}
          onKeyDown={onKeyDown}
          onKeyUp={stopPropagation}
          placeholder="Title"
          ref={inputRef}
          type="text"
          value={title}
        />
      </MenuItemGroup>
      <StyledButtonContainer>
        <Button buttonType="button" buttonStyle="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button buttonType="button" buttonStyle="primary" onClick={onSave}>
          Save
        </Button>
      </StyledButtonContainer>
    </>
  );
}

function deleteColumn(cellId: string, columnId: string) {
  const cell = selectCell(getState(), cellId);
  if (cell?.type !== "table") {
    return;
  }

  const columnDef = cell.columnDefs.find(
    (columnDef) => columnDef.id === columnId,
  );
  if (!columnDef) {
    return;
  }

  const index = cell.columnDefs.indexOf(columnDef);
  const values = compact(cell.rows.map((row) => row.values[index]));

  dispatch(
    withActiveNotebook(
      applyOwnOperation({
        type: "remove_table_column",
        cellId,
        columnDef,
        index,
        values,
      }),
    ),
  );
}

function duplicateColumn(cellId: string, columnId: string) {
  const cell = selectCell(getState(), cellId);
  if (cell?.type !== "table") {
    return;
  }

  const columnDef = cell.columnDefs.find(
    (columnDef) => columnDef.id === columnId,
  );
  if (!columnDef) {
    return;
  }

  const index = cell.columnDefs.indexOf(columnDef);
  const values = compact(cell.rows.map((row) => row.values[index]));
  const newColumnId = getUniqueColumnId(cell);

  dispatch(
    withActiveNotebook(
      applyOwnOperation(
        {
          type: "insert_table_column",
          cellId,
          columnDef: { id: newColumnId, title: `${columnDef.title} (Copy)` },
          index: index + 1,
          values,
        },
        {
          focus: {
            type: "collapsed",
            cellId,
            field: formatTableRowValueId(headingRowMarker, newColumnId),
          },
        },
      ),
    ),
  );
}

function getInitialTitle(cellId: string, columnId: string): string {
  const cell = selectCell(getState(), cellId);
  if (cell?.type !== "table") {
    return "";
  }

  return (
    cell.columnDefs.find((columnDef) => columnDef.id === columnId)?.title ?? ""
  );
}

function insertColumn(cellId: string, columnId: string, delta: 0 | 1) {
  const cell = selectCell(getState(), cellId);
  if (cell?.type !== "table") {
    return;
  }

  const index = cell.columnDefs.findIndex(
    (columnDef) => columnDef.id === columnId,
  );
  if (index === -1) {
    return;
  }

  const newColumnId = getUniqueColumnId(cell);

  dispatch(
    withActiveNotebook(
      applyOwnOperation(
        {
          type: "insert_table_column",
          cellId,
          columnDef: { id: newColumnId, title: "Untitled" },
          index: index + delta,
          values: cell.rows.map(() => ({
            type: "text",
            text: "",
            formatting: noFormatting,
          })),
        },
        {
          focus: {
            type: "collapsed",
            cellId,
            field: formatTableRowValueId(headingRowMarker, newColumnId),
          },
        },
      ),
    ),
  );
}

function saveTitle(cellId: string, columnId: string, title: string) {
  const cell = selectCell(getState(), cellId);
  const columnDef =
    cell?.type === "table"
      ? cell.columnDefs.find((columnDef) => columnDef.id === columnId)
      : undefined;
  if (!columnDef) {
    return;
  }

  dispatch(
    withActiveNotebook(
      applyOwnOperation({
        type: "update_table_column_definition",
        cellId,
        columnId,
        newTitle: title,
        oldTitle: columnDef.title,
      }),
    ),
  );
}

const StyledButtonContainer = styled(Container)`
  gap: 6px;
  justify-content: end;
`;
