import { useEffect } from "react";
import { useSelector } from "react-redux";

import { cancelEvent } from "@fiberplane/ui";
import { selectCurrentUser } from "../../../selectors";
import { dispatch } from "../../../store";
import { toggleCommandMenu, toggleSideMenu } from "../../../thunks";
import { isMac } from "../../../utils";

/**
 * Register listeners for global shortcuts for the Application
 */
export function useGlobalShortcuts() {
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === "k" && (isMac ? event.metaKey : event.ctrlKey)) {
        cancelEvent(event);
        dispatch(toggleCommandMenu());
      } else if (
        event.key === "\\" &&
        event.shiftKey &&
        (isMac ? event.metaKey : event.ctrlKey)
      ) {
        cancelEvent(event);
        dispatch(toggleSideMenu());
      }
    };

    document.body.addEventListener("keydown", onKeyDown);

    return () => document.body.removeEventListener("keydown", onKeyDown);
  }, [currentUser]);
}
