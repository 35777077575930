import { stopPropagation } from "@fiberplane/ui";
import { applyOwnOperation, withActiveNotebook } from "../../../actions";
import { selectCell } from "../../../selectors";
import { dispatch, getState } from "../../../store";
import { formatTableRowValueId, getUniqueRowId } from "../../../utils";
import { MenuDivider, MenuItem, PositionedMenu } from "../../UI";
import { noFormatting, rowHandleMarker } from "./constants";

type Props = {
  cellId: string;
  rowId: string;
  element: HTMLElement;
  onClose: () => void;
};

export function TableRowMenu({
  cellId,
  element,
  onClose,
  rowId,
}: Props): JSX.Element {
  return (
    <PositionedMenu
      element={element}
      placement="bottom-start"
      onClick={stopPropagation}
      onClose={onClose}
      offset={[3, 3]}
    >
      <MenuItem
        id="insert_row_before"
        title="Insert row before"
        onActivate={() => insertRow(cellId, rowId, 0)}
      />
      <MenuItem
        id="insert_row_after"
        title="Insert row after"
        onActivate={() => insertRow(cellId, rowId, 1)}
      />
      <MenuDivider />
      <MenuItem
        id="duplicate_row"
        title="Duplicate row"
        onActivate={() => duplicateRow(cellId, rowId)}
      />
      <MenuItem
        id="delete_row"
        title="Delete row"
        onActivate={() => deleteRow(cellId, rowId)}
      />
    </PositionedMenu>
  );
}

function deleteRow(cellId: string, rowId: string) {
  const cell = selectCell(getState(), cellId);
  if (cell?.type !== "table") {
    return;
  }

  const row = cell.rows.find((row) => row.id === rowId);
  if (!row) {
    return;
  }

  dispatch(
    withActiveNotebook(
      applyOwnOperation({
        type: "remove_table_row",
        cellId,
        row,
        index: cell.rows.indexOf(row),
      }),
    ),
  );
}

function duplicateRow(cellId: string, rowId: string) {
  const cell = selectCell(getState(), cellId);
  if (cell?.type !== "table") {
    return;
  }

  const row = cell.rows.find((row) => row.id === rowId);
  if (!row) {
    return;
  }

  const index = cell.rows.indexOf(row);
  const newRowId = getUniqueRowId(cell);

  dispatch(
    withActiveNotebook(
      applyOwnOperation(
        {
          type: "insert_table_row",
          cellId,
          index: index + 1,
          row: { id: newRowId, values: row.values },
        },
        {
          focus: {
            type: "collapsed",
            cellId,
            field: formatTableRowValueId(newRowId, rowHandleMarker),
          },
        },
      ),
    ),
  );
}

function insertRow(cellId: string, rowId: string, delta: 0 | 1) {
  const cell = selectCell(getState(), cellId);
  if (cell?.type !== "table") {
    return;
  }

  const index = cell.rows.findIndex((row) => row.id === rowId);
  if (index === -1) {
    return;
  }

  const newRowId = getUniqueRowId(cell);

  dispatch(
    withActiveNotebook(
      applyOwnOperation(
        {
          type: "insert_table_row",
          cellId,
          index: index + delta,
          row: {
            id: newRowId,
            values: cell.columnDefs.map(() => ({
              type: "text",
              text: "",
              formatting: noFormatting,
            })),
          },
        },
        {
          focus: {
            type: "collapsed",
            cellId,
            field: formatTableRowValueId(newRowId, rowHandleMarker),
          },
        },
      ),
    ),
  );
}
