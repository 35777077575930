import { useEffect } from "react";
import { useSelector } from "react-redux";
import { generatePath } from "react-router";
import { useSearchParams } from "react-router-dom";
import { push, replace } from "redux-first-history";

import { ROUTES } from "../../constants";
import {
  selectActiveWorkspaceName,
  selectIsAuthenticated,
} from "../../selectors";
import { dispatch } from "../../store";
import { getCurrentPathnameUriComponent } from "../../utils";

export function RedirectToWorkspaceGraph() {
  const workspaceName = useSelector(selectActiveWorkspaceName);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const [params] = useSearchParams();
  // Get the query param that contains the query to be sent to Prometheus
  const query = params.get("g0.expr");

  useEffect(() => {
    if (isAuthenticated === false) {
      const redirect = getCurrentPathnameUriComponent();
      dispatch(push(`${ROUTES.SignIn}?redirect=${redirect}`));
      return;
    }

    if (workspaceName) {
      dispatch(
        replace(
          `${generatePath(ROUTES.Notebooks, {
            workspaceName,
          })}`,
          {
            query,
          },
        ),
      );
    }
  }, [isAuthenticated, workspaceName, query]);

  return null;
}
